import React from 'react';
import './Glacier.css';

export default function Glacier(props) {
    
    return (
		<div className={`glacier-container ${props.size}`}>
			<svg  xmlns="http://www.w3.org/2000/svg" id={props.id}>
			<g className="glacier-image">
	
			<path d="M971.81,725c0,3.67,0,7.33,0,11c-2.07,5.68-5.99,11.43-5.77,17.01c0.29,7.72,3.7,15.33,5.77,22.99c0,2.67,0,5.33,0,8
				c-4.16,0.25-8.43,1.19-12.46,0.58c-4.31-0.65-4.53,1.22-4.22,4.31c0.29,2.8,1.09,5.62,0.88,8.37c-0.34,4.48,1.86,4.95,5.43,4.51
				c3.44-0.43,6.91-0.52,10.37-0.76c0,1.33,0,2.67,0,4c-5.81,0.12-11.69-0.1-16.92,3c5.64,0,11.28,0,16.92,0c0,0.67,0,1.33,0,2
				c-6.37,0.79-12.74,1.58-18.54,2.3c-1.34,5.19-2.51,9.76-3.96,15.39c3.56,0,6.93-0.09,10.29,0.02c6.6,0.2,7.63,1.61,5.79,7.8
				c2.16,0.17,4.29,0.33,6.42,0.49c0,0.67,0,1.33,0,2c-10.95,1.13-11.36,0.83-8.85-7.29c-5.69,0-11.19,0-15.14,0
				c-3.86,5.52-6.93,10.74-10.85,15.21c-2.17,2.48-3.29,4.53-2.99,7.83c0.91,9.97,1.52,19.96,2.29,30.54
				c11.11,4.39,22.66,9.4,35.54,9.7c0,1.33,0,2.67,0,4c-1.46-0.2-2.92-0.4-4.36-0.6c-0.58,2.43-1.12,4.7-1.79,7.53
				c2.34-0.35,4.24-0.64,6.15-0.93c0,1.33,0,2.67,0,4c-18.43,0.9-32.95,12.44-49.55,18.18c16.52,2.61,33.03,5.21,49.55,7.82
				c0,2,0,4,0,6c-12.2,0.93-24.41,1.86-36.61,2.78c0.99,1.34,2.02,1.9,3.1,2.01c11.17,1.11,22.34,2.15,33.51,3.21c0,0.67,0,1.33,0,2
				c-12.69-1.17-25.39-2.24-38.05-3.66c-1.59-0.18-2.98-2.18-4.47-3.34c1.69-1.04,3.31-2.81,5.1-3c6.6-0.71,13.26-0.84,19.89-1.29
				c4.78-0.32,9.55-0.8,14.33-1.21c-1.59-1.49-3.14-2.17-4.75-2.34c-19.89-2.13-39.4-6.73-59.72-6.21c-23.6,0.6-47.2-1.73-70.06-8.27
				c-16.03-4.58-31.75-6.58-47.67,0.31c-2.92,1.26-6.52,1.19-9.82,1.27c-1.4,0.03-2.83-1.15-4.25-1.77c0.68-1.23,1.23-2.56,2.07-3.66
				c2.71-3.57,5.53-7.05,8.6-10.93c-5.24-1.74-9.26-2.83-13.03-4.46c-1.59-0.69-2.68-2.54-4-3.86c1.64-0.91,3.2-2.35,4.94-2.63
				c4.67-0.77,9.42-1.04,14.14-1.51c-1.53-1.74-3.2-2.74-4.99-3.04c-5.23-0.88-10.54-1.32-15.76-2.25
				c-7.64-1.36-15.29-2.8-22.82-4.66c-1.68-0.42-2.9-2.74-4.33-4.19c1.41-1.27,2.65-2.84,4.27-3.75c2.96-1.66,6.14-2.92,10.02-4.72
				c-1.97-1.62-2.96-2.77-4.21-3.42c-13.41-6.93-27.35-10.91-42.74-8.81c-4.25,0.58-8.84-0.6-13.15-1.55
				c-1.6-0.36-2.84-2.39-4.24-3.65c1.56-0.95,3.02-2.41,4.71-2.75c3.87-0.79,7.86-1.05,12.24-1.57
				c-12.32-8.64-24.95-13.75-39.46-14.28c-6.53-0.24-13.02-2.34-19.44-3.95c-1.47-0.37-2.57-2.22-3.84-3.38
				c1.43-0.91,2.78-2.35,4.32-2.62c3.75-0.65,7.58-0.83,11.39-1.08c8.49-0.55,16.98-1.03,25.46-1.54c0.03-0.67,0.06-1.33,0.1-2
				c-10.64-1.32-21.25-3.32-31.92-3.82c-20.09-0.94-40.22-0.84-60.32-1.55c-5.22-0.18-10.46-1.47-15.55-2.81
				c-1.45-0.38-2.39-2.72-3.56-4.16c1.51-0.82,2.98-2.26,4.53-2.35c7.79-0.46,15.61-0.52,23.41-0.82c1.95-0.08,3.89-0.54,5.84-0.82
				c-1.64-1.87-3.22-2.75-4.91-3.29c-2.84-0.91-5.83-1.38-8.57-2.48c-1.12-0.45-1.76-2.09-2.62-3.19c1.16-0.72,2.24-1.7,3.51-2.1
				c1.41-0.44,2.98-0.35,4.48-0.5c-10.36-1.19-20.62-1.5-30.87-2.16c-2.67-0.17-5.27-1.44-7.9-2.2c0.04-0.77,0.08-1.55,0.13-2.32
				c2.58-0.58,5.14-1.58,7.74-1.67c7.98-0.26,15.97-0.09,23.95-0.28c1.72-0.04,3.43-0.86,5.14-1.33c-0.13-0.67-0.26-1.33-0.39-2
				c-6.73-0.35-13.46-0.64-20.18-1.07c-1.95-0.13-3.97-0.39-5.78-1.08c-2.96-1.12-5.87-2.46-8.62-4.04
				c-1.52-0.87-2.65-2.41-3.95-3.65c1.58-0.92,3.11-2.52,4.75-2.63c4.63-0.33,9.32,0.18,13.96-0.13c2.34-0.16,4.6-1.4,6.9-2.15
				c-7.68-2.21-15.58-0.53-22.47-5.71c19.54-2.82,38.97-5.69,58.84-5.25c-0.84-1.26-1.76-1.55-2.49-2.1c-1.6-1.2-3.11-2.53-4.66-3.8
				c1.94-0.98,3.79-2.52,5.83-2.83c3.59-0.54,7.3-0.46,10.96-0.4c26.42,0.41,52.85,0.83,79.27,1.39c2.24,0.05,4.46,1.14,6.69,1.75
				c-0.07,0.68-0.13,1.37-0.2,2.05c-13.5,2.98-27.47,0.77-42.3,3.09c20.83,2.17,40.48,4.22,59.38,6.19
				c1.33-3.63,2.54-6.96,3.68-10.07c-2.19-0.97-4.31-1.86-6.38-2.84c-2.07-0.98-4.08-2.88-6.15-2.93
				c-26.85-0.71-53.72-1.19-80.58-1.66c-9.78-0.17-19.57-0.15-29.35-0.23c-0.5,0-0.99-0.07-1.49-0.13
				c-6.98-0.97-13.96-2.73-20.95-2.78c-42.09-0.29-84.18-0.17-126.27-0.24c-1.94,0-3.88-0.44-5.82-0.68c0.03-0.75,0.07-1.5,0.1-2.25
				c29.55,0,59.1,0,88.65,0c-0.01-0.58-0.02-1.15-0.04-1.73c-54.88,0-109.77,0-164.65,0c3.39,2.07,9.44-2.52,10.05,4.65
				c0.59,0.19,0.8,0.33,0.89,0.27c5.91-4,7.99,5.91,13.48,3.8c0.36-0.14,1.8,1.21,1.92,2c0.6,3.8,3.29,3.47,6.01,3.46
				c15.64-0.02,31.28-0.11,46.92,0.07c18.12,0.21,36.24,0.62,54.35,1.09c1.89,0.05,3.76,1.05,5.64,1.6
				c-0.04,0.64-0.09,1.28-0.13,1.92c-8.94,3.85-18.99,1.84-26.42,3.78c-1.5,2.42-2.02,4.41-2.98,4.65
				c-10.24,2.59-20.43,5.89-30.86,7.05c-13.14,1.46-26.78-1.23-39.25,6.1c0.32,2.61,2.82,2.77,5.67,2.77
				c13.64-0.02,27.29-0.03,40.92,0.2c3.24,0.06,6.46,1.08,9.69,1.66c0.05,0.76,0.1,1.51,0.14,2.27c-3.9,1.44-7.8,2.88-11.71,4.31
				c5.78,2.64,11.55,4,17.16,5.88c1.94,0.65,3.44,2.58,5.15,3.93c-1.77,1.3-3.42,3.52-5.32,3.75c-8.02,0.99-16.1,1.66-24.18,1.93
				c-9.18,0.31-18.37,0.07-27.56,0.07c1.27,2.12,3.03,3.07,4.37,4.43c1.41,1.43,2.43,3.25,3.62,4.9c-1.94,0.71-3.82,1.79-5.82,2.06
				c-3.56,0.49-7.18,0.48-10.23,0.66c1.33,9.86-7.05,4.65-9.88,7.46c1.5,1.06,2.98,2.1,4.47,3.15c-0.04,0.59-0.08,1.18-0.12,1.77
				c-5.8,0.9-11.6,1.79-18.09,2.79c7.89,2.24,15.21,4.12,22.36,6.49c1.68,0.56,2.82,2.75,4.21,4.19c-1.73,0.79-3.42,2.16-5.18,2.25
				c-5.39,0.3-10.81,0.11-17.33,0.11c4.99,5.14,1.38,5.9-2.04,7.1c3.95,0.96,7.66,1.51,11.4,1.85c16.3,1.49,33.01-1.38,48.87,4.74
				c0.89,0.34,1.98,0.22,2.97,0.2c24.27-0.51,48.54-1.11,72.82-1.53c14.63-0.25,29.27-0.47,43.9-0.15c2.95,0.06,5.84,2.61,8.76,4.01
				c-2.97,1.01-5.89,2.74-8.91,2.92c-11.29,0.67-22.61,0.73-33.9,1.27c-2.53,0.12-5.1,1.01-7.48,1.97c-2.65,1.07-5,3.48-7.66,3.82
				c-13.01,1.64-26.09,2.74-39.13,4.14c-5.89,0.63-11.75,1.53-17.62,2.31c0.03,0.56,0.05,1.12,0.08,1.67
				c5.24,0.51,10.48,1.21,15.73,1.51c13.94,0.78,27.97,0.76,41.82,2.34c7,0.8,13.57,4.82,20.53,6.59
				c10.62,2.69,21.47,4.46,32.09,7.14c2.6,0.66,4.7,3.34,7.02,5.1c-2.6,1.11-5.14,2.99-7.82,3.21c-22.35,1.87-44.74,3.38-67.1,5.14
				c-6.97,0.55-13.91,1.52-20.87,2.3c-0.04,0.58-0.07,1.16-0.11,1.73c2.77,0.84,5.55,1.63,8.3,2.54c4.55,1.5,9.1,2.97,13.59,4.63
				c3.48,1.29,6.75,3.42,10.33,4.13c5.02,1.01,10.23,1.15,15.37,1.44c1.54,0.09,3.49-0.11,4.61-0.99c7.63-6.01,16.37-6.33,25.19-4.62
				c13.69,2.65,27.23,6.04,40.9,8.79c3.81,0.77,7.99,1.13,11.74,0.38c6.64-1.32,13.01-4.02,19.63-5.48
				c26.16-5.76,51.86-5.87,77.36,4.42c14.11,5.69,28.99,9.63,43.74,13.56c13.6,3.63,26.8,8.08,38.51,15.93
				c6.39,4.28,12.6,6.89,20.38,5.07c2.67-0.63,5.64-0.22,8.45,0.01c3.8,0.32,7.37,1.11,5.24,6.25c4.29,0.81,8.05,1.43,11.77,2.26
				c4.16,0.93,6.88,2.86,4.57,7.08c6.28,4.34,12.29,8.48,18.29,12.64c0.4,0.28,0.72,0.68,1.04,1.06c4.41,5.15,8.87,10.24,13.17,15.48
				c1.16,1.41,1.81,3.25,2.69,4.9c-1.85,0.83-3.67,2.29-5.54,2.37c-3.94,0.17-7.92-0.45-11.88-0.64c-5.4-0.26-10.88,0.04-16.21-0.72
				c-7.04-1-13.91-3.56-20.95-4.07c-9.92-0.72-19.93-0.18-29.61-0.18c1.56,1.69,3.05,3.32,4.55,4.94c-2.62,1.26-5.23,2.52-7.77,3.74
				c2.71,3.5,2.32,5.08-2.37,5.6c-6.23,0.68-12.52,0.77-19.9,1.18c3.41,2.69,6.95,4.87-0.27,7.64c3.86,0.99,6.17,1.93,8.55,2.14
				c12.24,1.11,24.53,1.73,36.73,3.15c7.15,0.83,14.2,2.68,21.21,4.41c2.84,0.7,5.36,2.69,8.2,3.39c6.45,1.58,12.98,2.87,19.52,3.99
				c8.78,1.51,15.84,4.55,19.53,13.9c2.99,7.58,3.54,7.82-4.74,9.31c-0.82,0.15-1.65,0.24-2.47,0.31
				c-12.25,1.02-24.51,1.96-36.76,3.07c-5.14,0.47-10.25,1.31-15.38,1.98c-0.02,0.68-0.03,1.37-0.05,2.05
				c5.47,0.82,10.95,1.58,16.4,2.48c6.53,1.09,13.09,2.09,19.52,3.62c1.48,0.35,2.48,2.73,3.71,4.18c-1.61,0.88-3.16,2.32-4.85,2.52
				c-3.45,0.42-6.97,0.19-10.46,0.21c-2.71,0.02-5.42,0-8.13,0c-0.1,0.59-0.19,1.19-0.29,1.78c2.6,1.1,5.77,1.61,7.69,3.43
				c4.04,3.81,8.68,4.29,13.77,4.52c4.09,0.19,8.27,0.84,12.17,2.05c1.58,0.49,2.53,3.01,3.76,4.6c-1.69,0.81-3.32,2.15-5.07,2.33
				c-3.52,0.37-7.1,0.11-10.66,0.11c0.61,1.28,1.3,1.7,2.01,1.76c7.94,0.64,15.88,1.49,23.83,1.77c15.62,0.55,31.27,0.64,46.89,1.22
				c4.26,0.16,8.56,1.13,12.67,2.32c1.29,0.37,2.04,2.64,3.04,4.05c-1.36,0.69-2.67,1.54-4.11,2c-1.17,0.38-2.48,0.29-3.73,0.41
				c-0.1,0.49-0.2,0.99-0.3,1.48c2.45,0.67,5.16,0.91,7.29,2.14c2.24,1.29,3.93,3.54,5.86,5.37c-1.94,0.94-3.85,2.61-5.82,2.68
				c-8.07,0.31-16.16,0.13-25.24,0.13c4.28,5.27,1.31,6.29-2.02,6.6c-5.98,0.56-11.98,0.79-17.97,1.16c0.72,1.05,1.46,1.47,2.22,1.52
				c8.61,0.64,17.21,1.43,25.83,1.78c9.3,0.37,18.61,0.3,27.92,0.43c0,0.67,0,1.33,0,2c-14.8,0.01-29.6,0.24-44.39-0.09
				c-4.56-0.1-9.14-1.51-13.6-2.71c-1.29-0.35-2.22-2.06-3.32-3.15c1.28-0.81,2.5-2.19,3.86-2.34c6.06-0.66,12.16-0.96,18.3-1.4
				c-1.98-4.75-1.78-5.49,3.32-5.88c6.62-0.51,13.29-0.36,19.93-0.55c2.32-0.07,4.63-0.29,6.94-0.44c0.02-0.49,0.05-0.98,0.07-1.47
				c-1.5-0.39-3.05-0.68-4.51-1.2c-2-0.72-4.2-1.27-5.83-2.53c-1.28-0.98-1.83-2.91-2.71-4.42c1.54-0.69,3.05-1.47,4.63-2.05
				c0.92-0.33,1.95-0.34,2.94-0.5c-1.94-1.25-3.85-1.89-5.78-1.99c-8.46-0.47-16.94-0.79-25.4-1.14
				c-16.61-0.68-33.23-1.15-49.81-2.21c-2.59-0.16-5-2.95-7.5-4.52c2.53-1.04,4.98-2.49,7.61-3.01c2.63-0.52,5.44-0.11,8.17-0.11
				c0.01-0.58,0.03-1.16,0.04-1.74c-1.87-0.45-3.74-1.23-5.62-1.28c-7.98-0.22-16.09,0.28-22.36-6.16c-0.42-0.43-1.33-0.34-1.83-0.72
				c-2.45-1.87-4.84-3.81-7.25-5.73c2.73-0.97,5.41-2.53,8.2-2.79c4.98-0.46,10.04-0.13,15.07-0.13c0.06-0.57,0.12-1.13,0.18-1.7
				c-2.31-0.62-4.58-1.48-6.93-1.82c-8.21-1.18-16.53-1.81-24.64-3.45c-2.98-0.6-5.51-3.42-8.24-5.23c2.73-1.25,5.37-3.29,8.21-3.62
				c11.55-1.34,23.16-2.09,34.74-3.18c6.67-0.63,13.31-1.5,19.8-2.24c-2.16-10.77-8.48-15.72-18.07-16.94
				c-0.49-0.06-0.98-0.16-1.47-0.27c-10.45-2.35-21.52-2.58-30.77-9.09c-1.01-0.71-2.51-0.9-3.81-1.01
				c-12.08-1.03-24.2-1.7-36.24-3.07c-7.69-0.87-15.43-2.21-22.86-4.35c-4.95-1.42-4.81-3.67-0.48-6.64c-3.35-3.73-3.02-5,1.81-5.91
				c2.27-0.43,4.63-0.34,6.95-0.47c3.78-0.21,7.57-0.41,11.83-0.65c-5.96-8.98,3.91-4.97,5.52-7.93c-3.94-2.18-7.88-3.14-12.35-1.62
				c-2.65,0.9-5.48,1.47-8.27,1.71c-12.58,1.09-25.17,2.09-37.77,2.95c-4.28,0.29-7.97,1.1-10.95,4.69
				c-4.51,5.44-9.45,10.54-14.25,15.74c-3.11,3.37-6.88,6.36-4.7,11.94c0.47,1.2-0.14,2.97-0.6,4.35c-2,5.89-2.41,12.52-7.9,16.85
				c-0.84,0.66-0.61,2.54-1.17,3.72c-3.9,8.31-7.83,16.6-11.87,24.84c-0.48,0.97-1.77,1.5-2.52,2.37c-1.75,2.03-3.38,4.16-5.09,6.22
				c-2.78,3.35-5.51,6.75-8.41,10c-1.5,1.69-3.87,2.8-4.86,4.69c-2.79,5.33-4.84,11.2-12.41,10.75c-0.99,2.79-1.85,5.25-2.97,8.41
				c4.49,0.49,8.52,0.46,12.29,1.44c10.74,2.79,21.39,5.95,32.05,9.07c4.14,1.22,6.22,4.44,6.66,8.53c0.51,4.79,2.31,8.11,7.36,9.42
				c1.4,0.36,3.44,2.5,3.3,3.58c-0.24,1.84-1.6,4.2-3.17,5.02c-2.07,1.08-4.82,0.87-7.27,1.25c-1.97,0.31-3.93,0.67-5.89,1.01
				c-0.04,0.63-0.08,1.26-0.13,1.89c4.06,0.94,8.09,1.97,12.17,2.79c8.23,1.65,17.7,0.7,23.89,7.23c6.98,7.36,14.93,8.69,24.23,7.98
				c6.45-0.49,12.97-0.09,19.46-0.09c8.91,0,17.82,0,26.73,0c-4.09-2.56-8.33-4.31-12.63-5.87c-7.64-2.78-15.38-5.33-22.95-8.28
				c-1.58-0.62-2.62-2.6-3.91-3.95c1.76-0.99,3.41-2.35,5.29-2.86c2.11-0.57,4.43-0.38,6.66-0.52c-0.82-1.36-1.69-1.79-2.58-2.13
				c-4.96-1.9-9.98-3.63-14.84-5.77c-1.3-0.57-3.02-2.51-2.85-3.55c0.23-1.44,1.87-2.93,3.27-3.82c1.34-0.85,3.12-1.2,4.75-1.34
				c5.46-0.48,10.93-0.74,16.39-1.16c3.78-0.29,7.55-0.72,11.33-1.08c-0.02-0.55-0.04-1.11-0.06-1.66c-1.9-0.24-3.79-0.65-5.7-0.68
				c-6.82-0.1-13.65,0.17-20.46-0.2c-3.38-0.19-7.78-0.42-9.82-2.5c-3.53-3.61-7.51-3.78-11.73-4.3c-3.46-0.43-6.96-0.63-10.37-1.29
				c-1.05-0.2-2.49-1.52-2.62-2.49c-0.14-0.98,0.86-2.54,1.81-3.16c1.34-0.87,3.06-1.43,4.66-1.54c6.96-0.47,13.93-0.66,20.89-1.08
				c3.42-0.21,6.83-0.7,10.25-1.06c-0.05-0.53-0.1-1.06-0.15-1.59c-3.13,0-6.27-0.03-9.4,0.01c-6.98,0.09-13.98,0.44-20.94,0.17
				c-1.82-0.07-3.57-2.06-5.35-3.17c1.57-1.65,2.95-3.58,4.78-4.87c1.52-1.07,4.5-0.86,5.21-2.13c2.27-4.09,5.99-4.52,9.77-4.84
				c4.14-0.35,8.32-0.17,12.46-0.45c1.78-0.12,3.52-0.82,5.28-1.26c-0.05-0.48-0.1-0.96-0.16-1.43c-1.87-0.18-3.77-0.25-5.61-0.58
				c-1.75-0.31-4.03-0.35-5.07-1.46c-2.89-3.1-5.26-6.68-8.51-10.96c4.89-0.82,9.82-1.65,14.64-2.46c-1.42-2.26-2.57-4.11-3.73-5.96
				c2.37-0.89,4.73-2.48,7.11-2.53c7.12-0.13,14.34-0.26,21.37,0.73c13.14,1.85,26.17,4.54,39.26,6.76c6.3,1.07,8.64,6.28,5.04,11.59
				c-2.52,3.71-1.9,5.58,2.51,6.69c2.24,0.56,4.64,0.46,6.88,1c2.04,0.49,5.16,0.92,5.73,2.27c2.07,4.87,6,4.39,9.96,4.83
				c3.58,0.4,8.99-0.24,8.93,4.86c-0.05,4.74-5.25,4.09-8.71,4.4c-7,0.62-14.03,0.95-21.05,1.4c-0.02,0.42-0.04,0.83-0.06,1.25
				c2.51,0.3,5.01,0.85,7.52,0.86c12.62,0.06,25.24-0.22,37.85,0.11c2.37,0.06,4.67,2.35,7.01,3.62c-2.28,1.24-4.47,3.29-6.87,3.58
				c-8.74,1.06-17.54,1.49-26.32,2.22c-7.31,0.6-14.61,1.28-21.91,1.93c0.02,0.75,0.05,1.5,0.07,2.25c6.35,0,12.69-0.08,19.04,0.03
				c4.47,0.07,9.04-0.08,13.37,0.81c2.16,0.44,3.89,3,5.81,4.61c-2.06,1.08-4.02,2.64-6.21,3.13c-3.51,0.79-7.17,0.89-10.77,1.27
				c0,0.54,0.01,1.07,0.01,1.61c2.89,0.33,5.93,1.41,8.64,0.87c12.23-2.43,22.86,3.61,33.41,7.36c7.35,2.61,14.21,3.44,21.61,3.86
				c3.7,0.21,7.58,1.13,10.9,2.73c2.74,1.32,6.46,3.86,1.06,7.12c-0.57,0.34-1.04,1.39-1.01,2.09c0.15,3.78-2.28,4.76-5.31,5.33
				c-1.44,0.27-2.82,0.87-4.22,1.32c0.11,0.47,0.21,0.95,0.32,1.42c13.58,0,27.16,0,40.74,0c0,1.33,0,2.67,0,4
				c-153.49,0.01-306.97,0.04-460.46,0.04c-170.28,0-340.56-0.02-510.85-0.04c0-1.33,0-2.67,0-4c45.97,0,91.94,0,138.47,0
				c-1.18-3.17-2.42-5.33-2.74-7.63c-0.53-3.86-0.19-7.85-0.78-11.7c-0.41-2.65-1.7-5.18-2.71-7.72c-2.43-6.17-4.93-12.31-7.38-18.47
				c-0.11-0.27,0.08-0.65,0.08-0.98c0.01-4.26,0-8.53,0-12.79c-1.48,3.78-1.47,7.71-2.34,11.43c-0.5,2.16-2.34,4.05-3.79,5.89
				c-0.15,0.2-2.36-0.55-2.6-1.22c-1.08-3.01-2.04-6.12-2.57-9.27c-0.74-4.38-1.06-8.84-1.57-13.26c-0.39,0.01-0.78,0.02-1.17,0.02
				c-2.28,5.81-4.55,11.62-6.83,17.43c-0.61-0.04-1.22-0.08-1.83-0.13c-0.55-2.26-0.92-4.58-1.72-6.75c-0.36-0.96-1.58-1.6-2.41-2.39
				c-0.74,0.89-1.87,1.68-2.14,2.7c-1.51,5.6-0.15,11.78-2.96,17.04c-0.8,1.49-3.46,2.32-5.41,2.7c-0.75,0.15-2.7-1.74-2.76-2.78
				c-0.28-5.52-0.13-11.07-0.13-16.58c-5.85-0.2-6.91,0.94-6.15,5.82c0.27,1.7,0.38,4.82-0.21,5.02c-3.6,1.22-1.82,3.46-1.77,5.63
				c0.04,2.05-0.85,4.12-1.31,6.18c-0.23,1.04-0.89,2.28-0.56,3.11c2.15,5.38-2.62,7.66-4.61,11.4c-1.25-1.72-1.84-3.38-2.38-5.06
				c-1.06-3.29-2.24-6.56-3.07-9.91c-0.56-2.26-0.59-4.65-0.86-6.98c0.42-0.08,0.83-0.16,1.25-0.24c-1.1-2.71-2.21-5.41-3.49-8.57
				c-0.53,1.2-1.4,2.2-1.2,2.88c0.81,2.73,0.28,5.12-2.65,5.02c-1.39-0.05-2.83-2.63-3.98-4.25c-0.52-0.72-0.21-1.99-0.59-2.87
				c-2.09-4.82-0.46-11.33-6.24-14.15c-6.13,1.8-2.29,10.72-8.71,12.54c-2.87-10.55-5.65-20.76-8.43-30.97
				c-0.52,0.05-1.04,0.11-1.56,0.16c-1.64,5.88-3.1,11.82-4.98,17.63c-2.02,6.26,1.52,14.03-5.29,19.04
				c-6.39-3.02-7.71-10.02-7.28-15.08c0.49-5.9-0.71-11.23-1.28-16.79c-0.23-2.26-1.17-4.44-1.83-6.83
				c-0.45,0.75-1.48,1.69-1.48,2.63c0.02,5.24-5.34,9.36-3.09,15.15c0.2,0.52-1.92,1.94-2.96,2.95c0-4,0-8,0-12
				c0.93-2.16,1.95-4.28,2.78-6.48c0.98-2.61-0.26-3.99-2.78-4.52c0-1,0-2,0-3c1.52,0.39,3.03,0.79,3.64,0.95
				c1.89-2.5,3.19-4.2,4.48-5.91c0.54,0.29,1.07,0.59,1.61,0.88c0.8,10.05,9.96,7.15,14.97,11.17c1.42-3.23,2.55-5.78,3.96-8.99
				c4.55,5.61,1.3,16.08,13.46,15.68c-4.37-6.7-7.56-13.22-12.3-18.27c-3.25-3.46-8.47-6.11-13.18-6.86
				c-5.34-0.86-11.08,0.79-16.64,1.35c0-1,0-2,0-3c5.23-0.42,10.47-0.84,15.7-1.26c0.1-0.6,0.19-1.2,0.29-1.79
				c-5.33-5.32-10.66-10.63-15.99-15.95c0-1,0-2,0-3c6.2,3.87,11.21,9.94,19.25,10.46c0.78,0.05,1.49,0.78,2.28,0.95
				c0.94,0.2,2.07,0.45,2.89,0.12c7.62-3.08,15.27-1.77,22.99-0.52c3.12,0.51,6.34,0.36,9.52,0.51c0.11-0.52,0.21-1.04,0.32-1.56
				c-6.27-3.98-12.48-8.04-18.81-11.91c-5.49-3.36-10.91-6.96-16.73-9.65c-7.03-3.24-14.44-5.63-21.69-8.39c0-1.67,0-3.33,0-5
				c1.48-3.69,2.97-7.37,4.56-11.31c-1.69-0.25-3.12-0.47-4.56-0.69c0-0.67,0-1.33,0-2c3.52,0.1,5.46-2.08,6.6-4.99
				c1.92-4.87,3.51-9.88,5.5-14.72c0.55-1.33,2.03-2.28,3.09-3.4c0.93,1.07,1.98,2.05,2.75,3.23c0.84,1.29,1.41,2.76,2.1,4.15
				c0.57-0.27,1.14-0.53,1.72-0.8c-1.91-3.87-3.05-8.48-5.91-11.43c-4.35-4.49-11.12-6.03-15.37-10.98
				c-0.08-0.09-0.32-0.05-0.48-0.07c0-1,0-2,0-3c2.39,1.19,4.78,2.37,7.8,3.88c-2.66-5.42-5.23-10.65-7.8-15.88c0-15,0-30,0-45
				c3.08-0.7,6.15-1.4,9.23-2.1c0.11-0.54,0.22-1.07,0.33-1.61c-3.19-2.1-6.37-4.2-9.56-6.3c0-1.33,0-2.67,0-4
				c10.66,7.35,23.3,7.93,35.56,10.49c-3.21-3.9-3.63-10.17-10.23-10.68c-0.97-0.08-2.12-0.94-2.74-1.78
				c-5.07-6.84-10.45-7.43-17.02-1.98c-1.58,1.31-3.69,1.99-5.56,2.96c0-1,0-2,0-3c1.04-0.58,2.09-1.17,4.01-2.24
				c-1.99-0.38-3-0.57-4.01-0.76c0-1,0-2,0-3c4.2-0.08,8.41-0.25,12.61-0.2c1.79,0.02,3.83,0.05,5.3,0.87
				c5.06,2.83,9.93,6,14.85,9.08c0.39,0.25,0.61,0.78,0.9,1.18c3.7,5.26,5.03,12.42,11.99,15.11c0.55,0.21,1.06,0.89,1.31,1.47
				c3,6.86,9.73,10.01,14.78,14.79c6.69,6.35,10.72,17.86,4.12,26.94c-1.62,2.23-4.28,3.82-5.47,6.2c-1.97,3.95-4.82,5.47-9,5.9
				c-6.71,0.68-13.47,1.4-20.04,2.88c-5.21,1.17-10.16,3.51-15.43,5.4c1.85,2.52,4.63,4.75,5.28,7.5c2.07,8.7,3.74,17.54,4.81,26.42
				c1.1,9.11,3.97,17.07,11.79,22.29c10.1,6.74,20.26,13.42,30.78,19.45c4.13,2.37,9.21,3.58,14,4.15
				c10.26,1.22,20.33,2.65,29.22,8.44c2.58,1.68,4.89,4.1,7.7,5.03c13.52,4.5,27.09,8.87,40.79,12.79
				c21.35,6.11,42.82,11.82,64.24,17.69c16.94,4.65,33.8,9.6,50.84,13.86c32.81,8.2,65.7,16.07,98.59,23.93
				c22.87,5.47,45.88,10.39,68.68,16.13c13.5,3.4,26.85,7.55,40.04,12.04c9.75,3.32,19.48,4.9,29.78,4.67
				c17.62-0.4,35.26-0.13,52.9-0.13c1.5,0,3.01,0,4.51,0c-0.79-0.92-1.54-1.26-2.34-1.46c-82.28-20.73-164.62-41.29-246.84-62.24
				c-52.46-13.37-104.91-26.83-157.08-41.29c-21.71-6.02-43.14-13.66-63.89-22.46c-33.44-14.18-40.74-56.99-14.76-78.27
				c1.27-1.04,2.24-3.01,2.45-4.67c1.04-8.24,5.39-13.42,13.41-15.62c1.19-0.32,2.38-0.97,3.32-1.77c3.11-2.64,6.25-5.27,9.06-8.22
				c0.97-1.01,1.35-2.93,1.38-4.44c0.14-7-0.3-14.02,0.18-20.99c0.4-5.84-0.81-9.61-7.36-10.26c-3.64-0.36-3.92-2.3-0.65-4.83
				c-9.98-1.11-19.24-2.01-28.46-3.24c-2.36-0.31-6.18-1.19-6.6-2.61c-2.08-7.05-7.77-8.61-13.53-10.45
				c-0.74-0.24-1.32-0.98-1.99-1.47c-4.63-3.47-12.11-3.37-15.47-0.19c-1.81-1.39-3.35-3.41-5.27-3.86c-3.84-0.91-7.9-0.9-11.85-1.4
				c-4.34-0.55-7.25,0.26-6.48,5.64c0.12,0.87-0.64,1.86-1.17,3.23c-1.42-3.41-1.87-7.42-3.87-8.46c-1.82-0.95-5.32,1.31-8.7,2.35
				c-0.43-0.38-1.9-1.64-3.36-2.91c0-1,0-2,0-3c0.85-0.49,1.71-0.98,2.56-1.47c-0.85-0.51-1.71-1.02-2.56-1.53c0-0.67,0-1.33,0-2
				c12.01-1.14,24.01-2.28,37.33-3.54c-9.54-4.53-17.69-8.62-26.04-12.26c-3.55-1.55-7.52-2.16-11.29-3.2c0-0.67,0-1.33,0-2
				c0.88-0.99,1.77-1.98,2.65-2.98c-0.88-0.34-1.77-0.68-2.65-1.02c0-1,0-2,0-3c0.8-1.03,2.39-2.18,2.26-3.08
				C2.4,856.56,1.3,854.3,0.5,852c0-3,0-6,0-9c0.63,0.46,1.64,0.79,1.83,1.39c1.96,6.18,3.98,12.36,5.54,18.65
				c0.53,2.11-0.73,4.99,2.55,5.49c3.21,0.48,6.5,0.4,9.75,0.56c0.02-0.48,0.05-0.95,0.07-1.43c-6.23-1.6-6.4-2.27-1.97-7.05
				c2.72-2.93,5.22-6.08,8.1-8.83c5.48-5.22,9.48-12.16,17.11-14.95c0.72-0.26,1.62-1.55,1.55-2.26c-0.79-7.06,3.99-6.41,8.31-5.96
				c4.48,0.47,8.95,2.15,13.37,1.97c14.28-0.58,28.53-1.75,42.79-2.79c1.17-0.09,2.78-0.59,3.33-1.45c2.81-4.33,7.51-3.45,10.05-1.73
				c3.89,2.62,7.51,2.3,11.29,1.62c4.42-0.79,7.44,0.45,9.47,4.39c2,3.87,5.44,5.17,9.5,6.32c12.16,3.45,24.48,1.31,36.72,1.92
				c2.48,0.12,6.03,1.24,7.13,3.07c5.5,9.18,13.45,11.42,23.28,9.74c1.96-0.33,3.97-0.36,5.96-0.51c2.93-0.22,6.18,0.23,6.6-4.2
				c0.06-0.61,1.72-1.46,2.69-1.53c3.53-0.26,7.48-1.42,9.24,3.45c0.21,0.58,1.7,1.02,2.58,0.99c6.81-0.22,13.63-0.47,20.42-0.99
				c1.05-0.08,2.92-1.72,2.83-2.44c-0.53-4.32,1.63-6.39,5.47-7.2c-0.3-3.17-1.23-4.74-4.66-4.67c-17.96,0.36-35.92,0.57-53.89,0.61
				c-4.46,0.01-8.99-0.48-13.35-1.38c-1.91-0.39-3.49-2.4-5.21-3.67c1.84-1.13,3.56-2.84,5.55-3.26c3.12-0.66,6.42-0.46,9.64-0.62
				c-0.94-0.92-1.85-0.97-2.76-1.02c-5.97-0.34-11.96-0.54-17.91-1.13c-1.53-0.15-2.93-1.57-4.4-2.41c1.38-0.98,2.65-2.49,4.18-2.84
				c4.2-0.96,8.48-1.79,12.77-2.03c15.27-0.84,30.55-1.41,45.83-2.11c1.29-0.06,2.58-0.29,3.87-0.44c0-0.47,0-0.93,0-1.4
				c-2.02-0.3-4.1-0.39-6.05-0.95c-2.15-0.61-4.17-1.67-6.26-2.5c-2.03-0.8-4.12-2.32-6.1-2.2c-8.78,0.56-17.54,1.52-26.28,2.5
				c-6.1,0.68-12.76-1.13-17.96,3.9c-0.48,0.47-1.59,0.36-2.41,0.38c-17.94,0.44-35.88,0.75-53.82,1.33
				c-12.75,0.41-25.49,1.1-38.23,1.86c-5.92,0.35-11.82,1.13-17.73,1.77c-8.54,0.93-17.08,1.93-25.62,2.89
				c-3.96,0.45-7.95,1.47-11.86,1.23c-14.62-0.9-28.83,1.17-42.89,4.88c0-1.33,0-2.67,0-4c13.77-3.3,27.54-5.58,41.9-4.73
				c6.92,0.41,14.02-2.2,21.03-3.45c3.36-0.6,3.44-2.85,1.38-4.5c-3.16-2.53-5.52-6.26-10.88-5.77c-2.56,0.24-5.82-2.49-8.04-4.64
				c-1.88-1.82-2.71-4.72-4.48-8.03c-2.64,2.64-4.6,5.02-7.01,6.82c-1.11,0.83-3.81,1.28-4.53,0.58c-2.63-2.51-4.88-5.47-7.01-8.45
				c-5.79-8.09-10.17-8.7-18.2-2.6c-1,0.76-2.75,0.53-4.16,0.76c0-2.33,0-4.67,0-7c0.66,1.07,1.31,2.14,2.03,3.31
				c0.49-0.36,0.86-0.67,1.26-0.91c4-2.4,7.35-5.62,12.94-2.1c3.4,2.14,8.36,3.2,12.5-0.74c3.04-2.9,6.64-5.22,10.09-7.66
				c0.87-0.61,2.1-0.78,3.2-0.98c3.56-0.65,7.17-1.06,10.69-1.87c3.46-0.8,6.83-4.02,10.25,0.23c0.38,0.47,2.25,0.51,2.69,0.05
				c3.38-3.57,5.83,0.01,8.31,1.19c3.14,1.5,5.91,3.76,8.84,5.7c0.04-0.71,0.09-1.42,0.13-2.13c1.93-0.43,3.85-1.1,5.8-1.23
				c1.83-0.12,3.84,0.88,5.51,0.42c3.83-1.05,9.58,0.54,9.98,3.97c0.77,6.57,5.46,7.35,10.29,8.52c4.19,1.02,8.24,6.49,7.96,12.07
				c1.33-3.21,1.86-5.34,3-7.06c1.6-2.43,3.55-6.31,5.54-6.43c3.41-0.21,3.65-2.1,4.99-4.06c1.4-2.05,3.89-5.07,5.54-4.86
				c2.74,0.35,6.83,1.13,6.56,5.96c-0.05,0.94,1.28,2.62,2.25,2.86c6.22,1.58,5.74,6.78,6.5,9.81c-2.54,2.1-4.5,3.3-5.89,4.96
				c-2.65,3.18-4.98,6.64-7.21,9.67c-0.36,0,0.58,0.03,1.51-0.01c13.6-0.58,27.2-1.1,40.79-1.83c1.84-0.1,3.63-1.26,5.45-1.92
				c-1.53-2.61-3.06-7.44-4.57-7.43c-4.85,0.03-5.35-3.21-6.48-6.16c-1.93-5.06-4.24-9.39-9.58-12.06c-2.88-1.44-5.22-4.81-6.76-7.84
				c-2.05-4.04-9.59-8.4-14.08-7.56c-2.5,0.47-5.22,0.47-7.73,0.03c-5.03-0.89-9.92-2.77-14.97-3.27c-8.7-0.88-17.49-0.93-26.22-1.54
				c-2.17-0.15-4.29-1.69-6.41-1.63c-6.35,0.19-12.63,2.19-19.06-0.07c-1.37-0.48-3.37,0.71-5.05,1.21
				c-2.81,0.85-5.68,2.67-8.39,2.44c-4.53-0.39-9.23-3.62-12.46,2.37c-5.87-2.76-11.59,1.78-17.15,0.56
				c-4.11-0.9-7.48-1.32-11.28,0.76c-1.75,0.96-4.52,1.39-6.28,0.68c-3.76-1.5-7.03-0.72-10.55,0.41c-1.36,0.44-2.96,0.13-4.45,0.17
				c0-1,0-2,0-3c5.77,0.16,11.45-3.09,17.34-0.36c0.79,0.37,2.32-0.22,3.21-0.82c3.96-2.65,7.91-2.67,12.19-0.8
				c1.1,0.48,2.7-0.16,4.05-0.36c3.16-0.46,6.31-0.98,9.47-1.43c0.99-0.14,2.37,0.28,2.94-0.25c3.31-3.03,6.94-2.79,10.69-1.36
				c4.99,1.9,8.21-0.63,11.75-3.71c-2.34-1.61-3.98-2.86-5.74-3.93c-4.3-2.62-8.54-5.36-13-7.66c-2.73-1.41-5.78-2.25-8.76-3.07
				c-2.55-0.69-5.22-0.9-7.81-1.47c-2.46-0.54-5.48-0.57-7.18-2.06c-3.17-2.78-6.45-3.73-10.47-3.49c-2.61,0.15-6.29,0.93-7.66-0.42
				c-3.58-3.52-7.2-2.38-11.04-1.79c0-0.67,0-1.33,0-2c2.84-0.48,6.89-2.39,8.31-1.19c4.45,3.79,9.38,2.1,14.05,2.93
				c2.51,0.44,5.21,1.28,7.21,2.77c5.84,4.32,12.29,2.35,18.02,1.13c4.26-0.91,7.81-4.93,12.48-1.06c0.4,0.33,1.47-0.24,2.23-0.28
				c7.98-0.36,15.21-6.22,23.83-2.68c4.34,1.79,9.69,1.35,13.79,3.46c7.17,3.69,14.41,2.51,21.5,1.49c4.43-0.64,8.12-0.47,11.86,1.72
				c1.43,0.84,2.89,1.99,4.43,2.18c13.78,1.71,27.6,3.18,41.39,4.85c5.73,0.69,11.44,1.63,17.14,2.57c5.1,0.85,10.18,2.68,15.25,2.63
				c11.34-0.11,22.68-1.09,34.01-1.8c1.72-0.11,3.4-0.68,5.67-1.16c-6.14-8.47-12.28-15.04-23.43-11.34
				c-1.16,0.39-2.64,0.12-3.91-0.16c-2.83-0.62-5.59-1.96-8.42-2.07c-6.06-0.25-11.98,0.21-17.89-2.51
				c-7.17-3.3-14.46-5.79-18.58-13.41c-4-7.39-7.65-7.28-12.74-0.47c-1.18,1.57-3,3.38-4.75,3.67c-4.32,0.71-7,2.42-8.6,6.79
				c-0.88,2.4-3.79,5.84-5.59,5.73c-3.76-0.24-8.41-0.94-9.12-4.61c-2.59-0.26-5.04,0.23-6.07-0.75c-3.57-3.44-6.65-7.4-9.99-11.25
				c-3.86,1.95-6.65,2.77-11.17,1.28c-4.31-1.42-10.77,1.46-15.39,4.04c-3.36,1.88-5.23,1.85-7.51-0.53
				c-4.86,3.82-13.87,3.47-18.17-1.08c-3.88,1.85-7.97,3.87-12.14,5.72c-0.81,0.36-2.13,0.42-2.86,0c-2.25-1.3-4.34-2.9-5.7-3.84
				c-5.05,0.25-9.73-0.18-14.07,0.85c-6.4,1.52-12.46,3.64-18.7-0.55c-0.88-0.59-3,0.45-4.45,0.97c-1.31,0.47-2.49,1.82-3.74,1.83
				c-2.72,0.02-5.45-0.56-8.18-0.89c0-2,0-4,0-6c1.68,0.53,3.42,1.64,5.02,1.47c2.41-0.25,4.67-1.8,7.08-2.07
				c5.46-0.62,10.54,3.3,16.23,1.52c1.54-0.48,3.32-0.18,5-0.23c2.09-0.06,4.18-0.13,6.26-0.17c1.25-0.02,2.81,0.52,3.7-0.04
				c4.93-3.11,8.91-2.78,11.62,2.91c0.98-1.21,1.97-3,2.38-2.87c6.02,1.83,12.2-6.99,18.1,0.24c0.27,0.33,1.23,0.31,1.79,0.17
				c2.96-0.78,6.68,1.71,8.98-2.15c0.26-0.44,1.66-0.61,2.32-0.35c4.91,1.87,9.35-0.62,13.95-1.39c3.64-0.61,7.52-2.53,10.79-1.76
				c2.47,0.59,4.01,0.59,6.23-0.24c1.7-0.64,4.73-0.99,5.6-0.03c2.85,3.13,6.02,2.41,8.55,0.83c3.25-2.03,6.01-2.18,9.27-0.58
				c1,0.49,2.33,0.75,3.4,0.53c7.08-1.44,14.26-2.59,21.14-4.69c7.55-2.3,9.75-1.83,14.68,4.85c0.62,0.85,4.03,0.96,4.14,0.66
				c1.82-4.89,5.98-3.27,9.21-3.2c3.34,0.08,6.66,1.16,9.99,1.8c0.44,1.11,1.22,1.07,1.67,0.66c3.99-3.56,7.99-4.51,12.33-0.52
				c0.63,0.58,1.85,0.67,2.8,0.7c4.66,0.12,9.33,0.16,14,0.2c1.27,0.01,2.73,0.34,3.79-0.15c10.14-4.76,20.13-3.14,29.77,1.12
				c3.52,1.56,5.36,1.55,7.25-1.83c0.6-1.07,2.22-2.18,3.39-2.2c7.34-0.12,14.65,0.01,21.54,3.36c3.83,1.87,7.81,2.47,12.23,0.81
				c3.14-1.18,6.58-0.64,8.27,3.41c0.3,0.72,2.59,1.49,3.14,1.1c4.36-3.15,8.44-0.9,12.71,0.11c2.63,0.62,5.46,0.32,8.18,0.61
				c1.43,0.15,2.82,0.74,4.24,1.02c2.12,0.42,4.25,0.94,6.4,1.07c6.19,0.38,13.04-2.41,17.9,4.14c0.6,0.81,2.4,1.02,3.65,1.03
				c14.14,0.09,28.28,0.13,42.42,0.02c1.42-0.01,2.98-0.9,4.23-1.75c3.2-2.17,6.26-4.55,9.39-6.83c1.69-1.23,3.69-2.18,5.02-3.71
				c1.65-1.9,2.75-4.28,4.69-7.42c-2.26-0.44-5.05-1.92-6.5-1.08c-3.27,1.9-5.29,0.56-7.63-1.17c-1.96-1.45-3.55-2.55-6.57-1.2
				c-3.36,1.5-7.69,3.14-10.79-1.34c-0.3-0.43-1.22-0.69-1.81-0.63c-7.25,0.77-10.72-4.22-14.74-10.01
				c-0.19,7.6-5.64,9.42-10.83,11.66c-3.78,1.63-6.2,0.56-7.26-3.25c-0.95-3.41-2.38-4.81-6.1-3.21c-1.25,0.54-3.45-0.18-4.77-1.03
				c-1.66-1.08-2.86-2.88-3.38-3.42c-1.83,2.33-3.52,6.08-5.02,6.01c-2.45-0.11-4.79-2.73-7.81-4.71c-0.47,2.02-0.55,3.88-1.36,5.33
				c-0.68,1.21-2.52,2.95-3.33,2.71c-4.13-1.22-8.09-3.01-12.16-4.62c-0.84,0.64-2.26,2.65-3.77,2.73c-3.7,0.18-7.55-0.11-11.16-0.98
				c-5.73-1.37-11.06-0.87-16.57,1.08c-1.98,0.7-4.75,0.17-6.82-0.64c-3.28-1.28-5.84-2.85-9.41,0.17c-2.09,1.77-5.82,2.12-8.83,2.19
				c-6.08,0.14-12.18-3.76-18.29,0.26c-0.69,0.45-1.99,0.2-2.93-0.03c-3.62-0.87-6.83-1.99-11.03-1.31
				c-5.3,0.86-10.93-4.15-16.65,0.01c-0.44,0.32-2.02-0.76-2.96-1.37c-3.91-2.51-7.65-2.79-12.2-1.02
				c-3.79,1.48-8.23,1.32-12.39,1.76c-0.53,0.06-1.1-0.78-1.71-0.9c-4.85-0.94-9.71-1.84-14.58-2.64c-0.42-0.07-1.12,0.61-1.46,1.1
				c-2.52,3.68-4.63,1.92-6.63-0.5c-4.28,2.4-11.48,2.43-16.43,2.01c-5.69-0.49-12.19-0.99-17.11,1.24
				c-6.19,2.8-13.3-0.46-18.73,4.18c-0.16,0.14-0.65-0.03-0.96-0.14c-5.12-1.86-10.18-3.98-15.85-2.18
				c-0.59,0.19-1.96-0.71-2.31-1.43c-1.66-3.4-3.06-2.45-5.67-0.66c-2.67,1.83-6.48,3.69-9.37,3.18c-7.73-1.38-15.21-4.16-22.86-6.1
				c-2.04-0.52-5.01-0.9-6.41,0.16c-7.91,6.01-9.03,6.06-14.68-1.34c-5.9,3.44-12.15,3.03-16.52-1.26c-5.26,4.89-7.3-2.3-11.26-3.53
				c-2.07,4.76-4.8,6.93-8.95,2.53c-3.41,5.6-3.49,5.59-8.07,0.96c-0.42-0.43-1.2-0.51-1.82-0.75c0-2,0-4,0-6
				c1.42,0.72,2.85,1.44,4.53,2.3c2.86-7.15,6.03-2.7,8.52-0.09c2.27-0.97,4.15-2.56,5.72-2.3c5.01,0.82,10.27,1.6,14.75,3.79
				c4.17,2.04,7.41,3.07,11.57,0.37c1.01-0.65,3.62,0.38,5,1.35c1.22,0.85,1.74,2.7,2.67,4.3c1.25-0.42,2.88-0.45,3.33-1.19
				c3.66-6.06,8.85-4.12,14.04-3.18c1.19,0.22,3.48-0.57,3.8-1.44c2.09-5.62,5.66-4.58,8.92-2.67c2.28-1.27,4.16-3.19,5.84-3.03
				c1.9,0.18,3.61,2.23,5.36,3.45c0.06-0.3-0.1-2.05,0.7-2.95c1.34-1.51,3.13-3.1,5-3.57c3.85-0.98,8.38,1.82,11.81-2.13
				c0.31-0.36,1.27-0.17,1.92-0.21c4.04-0.28,8.09-0.43,12.1-0.88c4.31-0.48,8.58-1.64,12.88-1.73c2.43-0.05,4.89,1.44,7.38,2.25
				c1.81-6.94,2.09-6.47,7.59-4.29c3.72,1.47,8.39,0.94,12.57,0.57c1.24-0.11,2-3.12,3.46-4.03c1.34-0.84,3.32-0.81,5.02-0.83
				c1.23-0.02,2.47,0.51,4.97,1.09c-2.57-1.96-3.69-3.29-5.1-3.77c-2.19-0.74-4.59-1.27-6.89-1.22c-5.17,0.13-10.69,2-15.39,0.7
				c-5.56-1.54-9.91-3.62-16.09-0.66c-4.57,2.19-10.8,1.42-16.25,1.13c-7.61-0.4-15.24-2.54-22.45,1.89
				c-0.69,0.42-1.93-0.11-2.91-0.09c-3.98,0.08-7.96,0.26-11.93,0.28c-1.4,0.01-3.32,0.16-4.1-0.64c-3.45-3.53-6.57-3.51-10.55-0.67
				c-1.33,0.95-4.05,0.12-6.1-0.16c-1.26-0.17-2.49-1.3-3.68-1.22c-7.91,0.58-15.41-0.89-22.92-3.39c-7.18-2.39-14.3-5.86-22.31-4
				c-0.58,0.13-1.42-0.06-1.89-0.42c-3.68-2.87-7.29-5.81-10.92-8.73c0-1,0-2,0-3c3.9,2.45,9.14,2.84,11.55,7.7
				c0.34,0.69,2.13,0.78,3.28,0.93c4.7,0.62,9.53,0.67,14.08,1.83c7.96,2.02,15.75,4.72,23.63,7.09c0.6,0.18,1.41,0.24,1.95-0.02
				c5.14-2.44,11.14,3.33,16.05-1.7c0.31-0.32,1.56,0.21,2.34,0.45c2.95,0.91,6.67,3.48,8.69,2.5c6.66-3.21,14.13,3.01,20.33-2.35
				c0.62-0.54,2.22,0.14,3.36,0.13c7.82-0.07,15.63-0.17,23.45-0.26c0.04-0.69,0.07-1.39,0.11-2.08c-7.42-5.37-16.39-3.51-24.72-4.55
				c-0.55-0.07-1.16,1.22-1.88,1.45c-1.3,0.42-2.73,0.83-4.05,0.69c-4.16-0.45-8.49-2.21-12.41-1.52c-6.6,1.17-11.52-1.03-15.19-4.38
				c-3.23,0.4-5.8,1.51-7.7,0.8c-5.52-2.06-10.77-4.86-15.44-7.04c-2.52,0.9-4.32,2.07-6.12,2.09c-4.14,0.03-8.71-1.74-12.32-0.49
				c-4.63,1.59-5.51-0.97-7.04-3.75c-6.45,1.13-12.82,2.67-18.54-2.09c-0.74-0.62-2.25-0.31-3.4-0.43c0-1,0-2,0-3
				c1.91,0.35,4.32,0.09,5.63,1.17c4.01,3.31,8.47,3.31,12.69,1.72c4.06-1.53,6.41-1.06,7.2,2.58c3.06,0.51,5.55,0.92,7.75,1.29
				c11.65-3.48,21.96-0.37,31.72,6.08c2.86-3.51,5.36-3.53,8.51-0.5c1.77,1.7,4.62,3.15,7,3.17c8.57,0.08,17.14-0.63,25.72-0.75
				c3.43-0.05,6.95,0.34,10.3,1.09c4.7,1.05,9.27,2.63,13.91,3.96c2.61,0.75,5.19,1.63,7.84,2.16c3.62,0.72,7.3,1.19,10.96,1.74
				c2.84,0.43,5.68,1.17,8.52,1.13c4.84-0.06,9.68-1,14.5-0.86c3.82,0.11,7.6,1.3,11.27,1.98c3.38-8.24,7.27-9.2,12.94-4.73
				c3.04-0.58,6.03-2.13,7.36-1.22c3.32,2.26,5.41,0.46,7.49-1.2c2.87-2.28,5.38-5.02,7.64-7.17C146.86,635.52,73.68,615.26,0.5,595
				c0-0.67,0-1.33,0-2c1.97,0.15,4.02,0.02,5.9,0.5c23.62,6.09,46.98,12.98,70.34,20.02c46.57,14.04,93.98,25.28,141.06,37.62
				c1.17,0.31,2.78,0.41,3.72-0.16c2.68-1.6,5.24-3.46,7.62-5.49c3.19-2.72,6.23-3.87,8.69-0.97c3.66-1.12,6.67-2.07,9.71-2.95
				c1.25-0.37,3.45-0.28,3.68-0.94c1.59-4.55,5-3.3,8.14-2.67c1.83-4.8,4.54-4.95,8.24-2.32c0.93,0.66,3.65,0.63,3.81,0.23
				c0.61-1.57,0.95-3.51,0.54-5.1c-0.23-0.88-2.17-1.9-3.28-1.83c-1.73,0.11-3.6,0.79-5.05,1.76c-2.66,1.77-4.33,0.82-4.71-1.8
				c-0.47-3.25-2.62-3.9-5.1-4.47c-1.12-0.26-2.97-0.13-3.27-0.76c-2.32-4.81-5.23-6.09-10.9-5.16c-2.52,0.41-5.86-0.93-9.07,0.41
				c-1.38,0.58-3.43-0.19-5.11-0.6c-4.45-1.08-9.02-3.59-13.25-3.08c-3.72,0.44-4.66,0.03-5.37-3.22c-0.7-3.22-4.2-4.27-7-2.43
				c-0.86,0.57-2.12,1.28-2.94,1.05c-3.52-0.99-6.94-2.32-10.39-3.52c-0.94,0.45-2.3,1.73-3.4,1.53c-3.34-0.61-6.61-1.69-9.85-2.76
				c-5.32-1.76-10.58-3.7-15.91-5.45c-0.96-0.32-2.37-0.55-3.1-0.08c-6.16,3.96-9.78,2.82-12.7-3.81c-0.54-1.22-2.47-1.92-3.87-2.64
				c-1.93-0.99-3.97-1.76-5.96-2.63c-1.5-0.7-4.01,0.31-5.1-0.53c-1.32-1.02-1.45-3.6-2.22-5.87c-4.47,3.83-5.95-0.28-7.81-2.92
				c-3.82,2.01-3.76,1.97-5.95-1.56c-0.57-0.92-1.49-1.76-2.45-2.28c-3.73-2.04-7.62-3.81-11.3-5.94c-1.9-1.11-3.28-3.22-5.24-4.09
				c-4.55-2.01-10.68-1.6-11.38-8.42c-8.12,2.06-15.65,3.29-21.27-4.69c-2.43,2.15-4.55,5.59-7.34,0.54
				c-0.54-0.98-3.85-1.43-5.2-0.75c-3.81,1.91-5.91-0.16-8.06-2.55c-0.93-1.03-1.66-2.25-2.73-3.71c-2.41,4-5.08,3.49-7.96,1.78
				c-1.13-0.67-2.52-1.94-3.48-1.7c-7.76,1.96-14.21-2.46-21.22-4.05c-0.84,0.14-1.68,0.28-2.52,0.42c0-1.33,0-2.67,0-4
				c10.18,2.45,20.35,4.9,30.27,7.28c7.02-7.4,8.39,2.31,12.22,3.96c3.9-4.53,10.14-3.97,11.82-0.12c3.03,0.43,7.05,0.04,7.58,1.23
				c3.31,7.39,9.73,5.31,14.14,3.78c5.27-1.83,6.55-0.04,7.42,3.42c2.16,0.44,4.29,0.28,5.57,1.25c3.12,2.38,5.76,5.37,8.76,7.91
				c1.09,0.92,3.31,2.15,3.91,1.72c3.87-2.81,5.31,0.65,7.69,2.33c1.72,1.21,2.77,5.13,6.21,2.39c0.05-0.04,1.6,1.99,2.59,2.9
				c1.14,1.05,2.53,2.83,3.65,2.72c4-0.4,6.61,0.47,6.48,4.62c2.26-0.17,5.11-1.09,5.67-0.32c3.16,4.32,9.87,5.35,10.77,11.84
				c0.12,0.88,3.8,2.29,4.06,1.97c3.89-4.7,7.8-2.22,12.55-1.65c4.64,0.55,8.71,0.21,11.68,4.33c3.38-2.71,14.15-0.49,18.59,3.82
				c0.5-0.36,1-0.99,1.57-1.07c7.99-1.04,15.16,0.6,21.28,6.22c1.5,1.38,3.64,2.62,5.59,2.83c4.82,0.5,9.75,3.51,14.62-0.24
				c0.62-0.48,2.38,0.54,3.62,0.86c0.71,0.18,1.69,0.75,2.1,0.47c5.38-3.69,7.44,1.19,10.38,3.89c0.97,0.89,2.18,2.33,3.16,2.24
				c4.15-0.4,6.49,1.46,7.44,5.43c4.97-1.24,9.77-3.07,13.39,2.44c3-4.03,6.03-3.68,8.96-0.3c2.49-7.25,2.38-6.93,9.05-4.82
				c3.27,1.03,7.17,1.67,9-3.38c0.47-1.29,3.51-1.85,5.47-2.35c2.43-0.62,5.22-0.3,7.36-1.37c1.8-0.9,2.89-3.21,4.63-5.29
				c-8.4-3.57-16.58-7.22-24.89-10.52c-12.65-5.03-25.97-8.72-38-14.93c-34.23-17.68-70.12-30.21-107.8-37.81
				c-15.14-3.05-30.21-6.4-45.89-5.33c-7.76,0.53-15.59,0.02-23.39-0.21c-1.34-0.04-2.64-1.05-3.97-1.62
				c0.02-0.53,0.05-1.06,0.07-1.59c2.27-0.69,4.49-1.68,6.81-2.02c18.4-2.69,36.81-6.13,55.27-0.3c0.62,0.2,1.32,0.15,1.98,0.2
				c12.07,0.91,23.89,2.56,35.6,6.28c11.3,3.6,23.24,5.2,34.9,7.67c-2.93-2.06-6.18-3.09-9.39-4.23
				c-12.49-4.44-25.34-8.1-37.39-13.54c-25.53-11.53-51.93-18.37-80.01-17.72c-10.99,0.25-21.42-1.38-31.62-5.59
				c-8.88-3.67-18.4-5.93-26.99-10.14c-6.56-3.22-13.07-5.53-20.22-6.45c-1.46-0.19-2.88-0.73-4.32-1.11c0-0.67,0-1.33,0-2
				c1.64,0.12,3.29,0.18,4.93,0.37c14.93,1.67,27.64,9.88,41.6,14.45c11.24,3.67,22.03,8.35,34.2,7.45c2.47-0.18,4.97,0.26,7.46,0.33
				c2.28,0.06,4.56,0.01,8.02,0.01C64.18,496.57,30.29,485.44,0.5,466c0-1,0-2,0-3c1.2,0.57,2.42,1.09,3.59,1.7
				c15.17,7.89,30.23,15.99,45.54,23.61c10.56,5.25,21.63,9.48,32.15,14.79c13.76,6.94,28.25,11.26,43.16,14.91
				c12.35,3.02,24.34,7.6,36.39,11.81c14.08,4.92,28.09,10.06,42.03,15.35c4.92,1.87,9.65,4.31,14.27,6.85
				c1.34,0.74,1.99,2.73,2.96,4.15c-0.36,0.42-0.72,0.85-1.09,1.27c-3.42-0.98-6.82-2.07-10.28-2.92
				c-16.27-3.98-32.55-7.91-48.85-11.78c-1.91-0.45-3.94-0.42-5.92-0.61c-5.61-0.55-11.43-0.32-16.79-1.78
				c-17.09-4.67-33.98-2.73-49.56-0.11c8.25,0,17.98-0.9,27.5,0.16c35.82,3.99,70.68,12.34,104.07,26.05
				c16.58,6.81,32.51,15.17,49.01,22.19c17.57,7.47,35.45,14.21,53.24,21.16c1.39,0.54,3.55,0.98,4.56,0.29
				c3.96-2.72,7.3-2.02,10.79,0.76c2.1,1.67,4.08,2.26,6.99,0.49c2.19-1.33,5.57-1.09,8.39-0.94c3.85,0.2,6.44-1.21,8.7-4.85
				c-7.68-4.14-15.37-7.43-22.11-12.09c-13.17-9.1-25.82-18.96-38.68-28.51c-5.16-3.83-11.24-6.9-15.21-11.72
				c-3.62-4.39-10.59-6.37-10.73-13.54c-0.01-0.48-0.8-0.96-1.25-1.41c-4.44-4.42-9.07-8.67-13.28-13.3
				c-2.46-2.71-4.07-6.17-6.39-9.02c-3-3.69-9.16-3.79-10.7-9.46c-0.28-1.02-2.54-1.5-5.21-2.95c2.14,7.76,4.19,13.77,8.63,18.7
				c10,11.11,20.13,22.12,29.98,33.36c1.91,2.18,2.81,5.26,4.17,7.92c-0.38,0.36-0.77,0.72-1.15,1.08c-2.03-0.35-5.35-0.07-5.88-1.16
				c-3.01-6.1-10.64-3.15-14.59-8.42c-3.28-4.37-9.68-6.32-11.24-12.61c-0.15-0.59-0.62-1.16-1.08-1.59
				c-3.76-3.56-7.59-7.04-11.31-10.64c-0.66-0.65-0.95-1.73-1.29-2.65c-2.05-5.44-5.9-6.55-11.14-4.55
				c-6.38,2.43-13.58,6.32-19.6,5.19c-8.02-1.5-16.7-3.35-23.79-8.96c-2.75-2.18-7.31-1.96-10.95-3.13
				c-2.32-0.75-4.53-1.93-6.67-3.14c-6.29-3.57-11.46-9.17-19.35-9.81c-2.26-0.18-4.38-2.04-6.6-3.06c-1.13-0.52-2.32-0.93-3.52-1.26
				c-4.37-1.18-8.41-4.24-13.33-2.44c-0.12,0.04-0.39-0.07-0.44-0.18c-1.47-3.26-4.37-1.73-6.74-2.14c-1.9-0.32-3.66-1.45-5.56-1.88
				c-6.09-1.4-11.36-6.59-18.36-4.02c-0.64,0.24-1.58-0.28-2.36-0.49c-3.99-1.07-7.97-2.15-11.96-3.25c-2.06-0.57-4.19-1-6.15-1.81
				c-4.74-1.97-9.36-4.22-14.11-6.17c-5.65-2.32-11.37-4.46-17.06-6.66c-3.3-1.27-6.55-3.19-9.95-3.54
				c-3.51-0.36-5.58-1.65-5.69-4.86c-0.12-3.72,2.98-4.75,5.91-4.45c3.25,0.33,6.47,1.39,9.61,2.4c8.37,2.71,16.5,6.66,25.07,8.15
				c26.42,4.61,52.47,10.13,76.9,21.74c2.57,1.22,5.66,1.34,8.52,1.98c-4.1-2.35-8.14-4.03-12.01-6.03
				c-1.62-0.84-2.76-2.62-4.38-3.48c-2.05-1.1-4.36-1.7-6.56-2.51c-1.44-1.34-4.36-1.01-4.98-2.05c-2.98-5-9.95-2.15-12.75-6.99
				c-9.09-0.89-16.19-8.18-25.63-8.04c-0.77,0.01-1.68-0.4-2.29-0.91c-5.36-4.48-11.73-5.81-18.45-6.26
				c-1.46-0.1-2.95-0.52-4.31-1.08c-6.48-2.66-12.88-5.52-19.4-8.08c-2.98-1.17-6.81-0.83-7.19-5.51c-0.05-0.63-1.82-1.13-2.79-1.68
				c-2.99-1.71-5.96-3.45-8.97-5.11c-1.44-0.8-2.98-2.12-4.44-2.07c-5.36,0.18-9.02-2.41-12.07-6.37c0-1,0-2,0-3
				c1.18,0.52,2.96,0.73,3.43,1.62c1.9,3.64,4.68,4.81,8.58,4.3c0.87-0.11,1.89,0.55,2.76,1c4.81,2.46,9.62,4.93,14.37,7.5
				c0.8,0.43,1.83,1.37,1.88,2.12c0.2,3.52,2.96,3.42,5.25,4.18c3.73,1.25,7.34,2.86,11.11,3.91c5.75,1.61,10.46,5.67,17.1,5.6
				c4.36-0.04,8.77,2.91,13.13,4.57c2.51,0.96,4.94,2.17,7.5,2.98c1.53,0.48,3.4,0.03,4.82,0.67c7.27,3.25,14.43,6.74,21.66,10.09
				c0.54,0.25,1.35-0.1,1.93,0.11c2.09,0.77,4.15,1.64,6.2,2.51c0.81,0.34,1.57,0.82,2.38,1.18c2.06,0.9,4.13,1.77,6.19,2.65
				c1.82,1.18,4.01,1.56,5.75,2.65c1.98,1.25,3.34,3.56,5.38,4.63c2.35,1.24,5.24,1.39,7.7,2.46c1.67,0.73,3,2.23,4.48,3.39
				c-1.56,1.24-3.07,3.43-4.69,3.52c-3.18,0.19-6.83-0.01-9.58-1.43c-15.39-7.92-31.96-12.14-48.61-16.11
				c-12.9-3.07-25.96-5.47-38.81-8.68c-6.58-1.64-12.83-4.57-19.33-6.6c-2.12-0.66-4.56-0.34-6.85-0.46
				c6.04,3.09,11.81,5.44,17.46,8.05c2.5,1.15,4.59,3.45,7.16,4.15c7.64,2.08,15.74,2.69,22.14,8.17c0.66,0.56,1.98,0.83,2.84,0.61
				c6.5-1.68,12.12,2.55,18.57,2.63c5.53,0.06,10.97,4.02,16.52,6.08c4.07,1.51,8.89-1.29,12.82,2.49c1.29,1.24,4.26,0.5,6.24,1.26
				c4.58,1.75,10.22,1.15,13.49,5.95c0.27,0.4,1.24,0.31,1.88,0.47c2.36,0.61,4.96,0.78,7.01,1.94c5.08,2.89,9.87,6.28,14.85,9.35
				c1.08,0.67,2.45,0.92,3.71,1.21c2.49,0.57,5.14,0.67,7.47,1.61c6.97,2.8,13.99,5.57,20.67,8.99c6.8,3.48,13.09,2.62,19.51-0.58
				c1.93-0.96,3.89-1.89,5.82-2.84c5.19-2.55,11.27-0.5,14.21,4.47c2.4,4.05,4.35,9.75,7.98,11.37c6.04,2.69,6.49,9.11,11.11,12.66
				c2.56,1.96,5.41,1.56,7.46,3.37c4.42,3.9,4.37,3.96,9.46,3.51c-8.02-9.09-15.35-18.43-23.8-26.6
				c-5.47-5.29-9.52-10.96-12.03-17.98c-1.62-4.54-3.47-9-5.07-13.55c-0.48-1.38-0.44-2.94-0.64-4.42c0.57-0.32,1.13-0.65,1.7-0.97
				c1.93,2.22,3.75,4.55,5.81,6.65c3.78,3.83,7.69,7.53,11.56,11.27c3.11,3.01,6.37,5.89,9.32,9.06c2.41,2.6,4.24,5.76,6.71,8.29
				c3.31,3.39,7.02,6.39,10.51,9.61c0.46,0.43,0.92,1.12,0.95,1.71c0.21,4.4,3.41,6.35,6.47,8.73c5.43,4.23,10.32,9.17,15.82,13.31
				c2.63,1.98,6.02,3.2,9.25,4.08c9.34,2.53,19.3,2.9,27.24,9.73c3.98,3.42,8.8,5.86,13.24,8.74c0.25-0.3,0.51-0.6,0.76-0.9
				c-4.48-3.98-8.97-7.97-13.97-12.41c6.34-1.86,10.73,0.02,15.18,2.49c7.58,4.21,15.3,7.77,24.51,5.8
				c3.62-0.78,7.69-1.03,11.25-0.22c8.34,1.92,16.5,4.65,24.75,6.99c1.45,0.41,3,0.46,4.51,0.67c0.09-0.47,0.19-0.94,0.28-1.4
				c-1.91-0.91-3.78-1.9-5.74-2.7c-7.36-3.03-14.81-5.83-22.1-9.02c-11.07-4.84-21.92-10.22-33.08-14.86
				c-17.27-7.18-34.41-14.72-47.89-28.03c-7.77-7.68-16.39-13.15-26.31-17.46c-5.86-2.55-12.2-6.44-15.65-11.54
				c-5.46-8.08-12.18-12.56-21.23-15.16c-9.05-2.6-17.98-5.85-26.64-9.58c-18.6-8.02-36.71-17.26-55.56-24.61
				c-13.66-5.33-24.92-13.29-34.15-24.23c-11-13.04-24.19-22.68-40.3-28.18c-6.08-2.07-10.89-5.6-14.53-10.82
				c-3.48-5-8.37-7.58-14.01-9.76c-10.81-4.17-21.69-8.49-31.78-14.12C18.3,358.5,9.61,351.37,0.5,345c0-0.67,0-1.33,0-2
				c4.91,2.67,10.54,4.54,14.61,8.16c13.92,12.39,30.57,19.11,47.74,25.2c4.03,1.43,8.27,4.08,10.85,7.39
				c5.35,6.87,12,11.13,19.95,14.22c6.31,2.45,12.79,5.17,18.13,9.18c6.72,5.05,12.49,11.4,18.42,17.43
				c9.14,9.28,18.36,17.99,31.06,22.76c15.05,5.66,29.42,13.09,44.29,19.28c14.22,5.92,28.59,11.53,43.1,16.69
				c8.18,2.91,15.03,6.84,19.08,14.88c0.92,1.82,2.49,3.62,4.22,4.67c5.24,3.15,10.74,5.87,16.08,8.86
				c5.05,2.83,11.07,4.81,14.84,8.86c12.07,12.97,26.83,21.59,42.39,29.36c5.71,2.85,11.36,3.14,17.39,2.63
				c1.99-0.17,4.13,0.04,6.04,0.62c4.63,1.4,9.34,2.75,13.68,4.82c4.4,2.1,10.1,1.28,13.29,6.47c1.04,1.69,4.26,2.04,6.48,3
				c-2.83-4.16-6.1-7.48-9.12-11.01c-1.57-1.83-2.4-4.34-4.08-6.03c-5.75-5.77-11.81-11.24-17.52-17.05
				c-0.71-0.72-0.65-3.51,0.07-4.12c4.12-3.45,9.77-2.99,14.52,0.27c2.65,1.82,5.76,2.97,8.65,4.43c1.5,0.76,3.46,1.17,4.4,2.38
				c6.85,8.78,15.27,14.83,26.53,16.4c4.3,0.6,8.69,2.33,12.84,1.83c4.01-0.48,7.37-2.14,9.37,2.98c0.26,0.68,2.08,0.61,2.96,1.2
				c3.7,2.51,8.05,4.54,10.74,7.9c2.63,3.28,1.87,3.89,4.75,3.96c-0.54-2.92-1.13-6.04-1.71-9.16c0.43-0.17,0.87-0.34,1.31-0.52
				c-5.19-4.06-9.88-9.21-15.69-11.97c-11.98-5.7-24.61-10-36.84-15.19c-1.97-0.84-3.17-3.4-4.91-4.94c-1.2-1.06-2.65-1.91-4.12-2.55
				c-2.86-1.26-5.96-2.01-8.67-3.52c-3.4-1.89-6.43-4.42-9.74-6.48c-4.13-2.58-8.43-4.9-12.57-7.47c-2.01-1.25-3.64-3.39-5.78-4.1
				c-6.9-2.3-14.08-3.84-18.62-10.41c-0.48-0.69-2.11-0.55-3.19-0.86c-2.34-0.66-5.06-0.79-6.93-2.12
				c-9.09-6.46-19.26-10.76-29.33-15.26c-7.18-3.21-14.37-6.41-21.52-9.68c-0.88-0.4-1.54-1.27-2.39-1.77
				c-5.39-3.18-10.64-6.67-16.26-9.38c-5.98-2.89-13.49-2.33-18.16-8.36c-0.75-0.96-2.75-0.94-4.16-1.4c-3.12-1.01-6.29-1.9-9.34-3.1
				c-9.09-3.56-18.12-7.25-27.18-10.89c-0.35,0.58-0.71,1.15-1.06,1.73c4.04,3.6,8.15,7.12,12.07,10.85c0.93,0.89,1.22,2.46,1.8,3.72
				c-1.33,0.42-2.65,1.03-4.01,1.21c-0.93,0.12-1.99-0.23-2.91-0.57c-7.15-2.62-14.09-6.44-21.47-7.73
				c-21.59-3.8-40.98-13.42-60.96-21.53c-2.75-1.11-5.35-2.71-7.81-4.38c-3.48-2.36-2.36-4.19,1.54-5.51
				c-12.5-2.17-21.85-8.98-30.37-17.12c-1.03-0.98-1.27-2.8-1.88-4.22c1.5-0.12,3.06-0.55,4.5-0.3c3.42,0.59,6.83,1.35,10.15,2.34
				c3.75,1.12,7.59,2.18,11.05,3.94c6.35,3.24,12.55,6.79,18.61,10.55c6.87,4.27,13.48,8.95,20.25,13.39
				c2.07,1.36,4.16,2.8,6.45,3.67c6.2,2.36,12.51,4.43,17.68,6.23c-0.59-2.21-1.6-6-2.61-9.79c0.61-0.44,1.23-0.89,1.84-1.33
				c-7.79-4.34-15.55-8.75-23.39-13.02c-13.67-7.46-27.41-14.78-41.06-22.28c-3.76-2.07-7.19-4.74-10.94-6.82
				c-7.78-4.32-15.69-8.43-23.53-12.63c-2.63-1.41-5.17-2.99-7.86-4.26c-6.28-2.98-12.58-5.91-18.96-8.66
				c-1.59-0.68-3.51-0.59-5.6-0.9c0.84,4.27,7.53,5.59,2.77,10.32c9.3,7.14,19.66,13.06,26.37,23.28
				c-28.93-12.89-52.07-34.42-79.03-51.31c2.3-1.64,3.56-2.53,5.29-3.76c-2.56-2.07-4.73-3.82-6.9-5.58c0-2.33,0-4.67,0-7
				c6.37,4.16,12.54,8.7,19.2,12.32c4.02,2.18,8.81,2.94,13.25,4.34c-2.98-4.27-5.88-7.89-9.62-10.11
				c-6.66-3.96-14.35-6.22-19.44-12.68c-0.74-0.94-2.24-1.26-3.4-1.87c0-1.33,0-2.67,0-4c2.63,1.58,5.63,2.77,7.82,4.82
				c5.35,5.02,11.85,7.5,18.06,10.28c1.75-8.96,2.65-17.42,5.18-25.36c2.37-7.45-1.64-13.25-3.02-19.71
				c-1.19-5.57-3.94-10.87-4.7-16.46c-0.58-4.26-2.58-4.66-5.56-4.02c-2.03,0.44-4.19,1.41-5.73,2.78C8.38,258.03,4.5,262.08,0.5,266
				c0-1.33,0-2.67,0-4c0.58-0.59,1.23-1.11,1.73-1.76c5.47-7.2,11.81-12.4,21.74-11.62c2.73,0.22,5.63-1.8,8.46-2.79
				c-0.08-0.53-0.16-1.06-0.25-1.59c-10.56-1.74-21.12-3.49-31.68-5.23c0-1,0-2,0-3c2.65,0.08,5.34-0.11,7.93,0.3
				c8.69,1.37,17.32,3.17,26.03,4.38c4.46,0.62,7.23,2.51,8.89,6.74c2.38,6.07,3.87,6.8,10.23,5.01c5.27-1.48,10.43-3.39,15.61-5.18
				c7.71-2.66,15.56-3.14,22.69,1.03c7.88,4.61,15.35,6,24.41,3.18c4.76-1.48,9.2-2.8,13.49-5.43c2.96-1.82,6.74-2.78,10.25-3.08
				c5.16-0.44,10.45,0.21,13.24-5.63c0.25-0.51,1.01-0.95,1.62-1.1c5.22-1.24,10.45-2.47,15.71-3.55c2.59-0.53,5.51-0.15,7.84-1.18
				c10.27-4.5,18.81,1.64,27.44,4.85c6.27,2.33,8.53,3.13,12-2.77c2.8-4.76,6.12-8.96,11.81-9.94c7.18-1.23,14.51-3,21.65-2.56
				c7.66,0.47,15.03,1.2,22.55-1.04c3.84-1.15,7.66,0.66,7.78,4.81c0.23,8.08,6.47,11.35,10.51,15.81c4.08-2.34,7.87-4.31,11.44-6.62
				c2.32-1.5,4.81-3.14,6.36-5.33c3.6-5.11,8.62-7.3,14.42-7.7c4.85-0.33,6.53,3.61,8.41,7.29c0.64,1.25,2.78,2.51,4.19,2.46
				c5.75-0.2,10.62,0.86,12.67,7.05c3.18-2.49,5.46-5.5,8.38-6.35c10.26-2.99,20.61-6.12,31.15-7.58
				c16.39-2.27,15.45-5.44,23.84,14.99c0.81,1.98,1.44,4.19,2.78,5.75c2.65,3.09,5.74,5.81,9.23,9.24
				c5.28-5.19,11.6-10.65,16.98-16.9c4.59-5.33,9.38-7.67,16.12-5.13c1.55,0.58,3.2,0.92,4.74,1.52c2.63,1.01,4.27,0.52,6.33-1.83
				c1.73-1.97,5.08-3.59,7.68-3.54c10.12,0.21,20.23,1.03,30.32,1.93c1.74,0.16,3.6,1.57,4.94,2.88c3.6,3.53,6.94,7.31,10.06,10.64
				c7.14-2.09,10.33-6.09,10.53-13.24c0.28-10.04,5.67-15.87,15.84-18.19c5.93-1.35,11.33-4.98,16.99-7.55
				c1.2-0.54,2.47-1.19,3.74-1.28c9.39-0.64,17.72-3.39,24.14-10.83c1.17-1.36,3.42-2.06,5.3-2.48c3.83-0.87,7.63-1.24,9.59-5.62
				c0.56-1.25,3.04-2.23,4.68-2.3c4.25-0.19,6.37-1.91,6.59-6.3c0.19-3.83,2.3-6.84,6.06-7.7c5.93-1.36,11.96-2.68,18.01-3.07
				c8.83-0.57,13.87-4.96,14.46-13.84c0.84-12.7,0.84-12.7,13.36-16.43c0.48-0.14,0.93-0.36,1.38-0.56
				c8.79-3.97,17.93-5.84,27.63-5.38c1.87,0.09,4.49-1.34,5.63-2.9c6.08-8.29,7.17-8.74,16.87-4.88c1.23,0.49,2.49,0.98,3.77,1.27
				c18.98,4.26,36.5,12.46,53.7,21.07c12.24,6.13,24.79,8.57,38.07,7.33c6.43-0.6,11.17,1,15.53,5.71c1.77,1.91,4.96,3.18,7.64,3.47
				c11.58,1.26,22.61,3.77,31.99,11.23c0.9,0.71,1.89,1.46,2.96,1.78c7.72,2.31,15.42,4.75,23.24,6.68
				c9.13,2.25,15.46,8.39,21.21,15.06c14.98,17.36,31.54,32.88,50.68,45.56c0,1.33,0,2.67,0,4c-9.51-6.94-19.67-13.16-28.37-21
				c-10.47-9.44-19.49-20.48-29.59-30.35c-3.82-3.73-8.57-7.13-13.53-8.94c-8.1-2.96-16.72-4.46-25.1-6.66
				c-0.89-0.23-1.74-0.92-2.43-1.58c-9.37-8.94-21.01-11.13-34.18-11.75c1.43,2.82,1.96,5.97,3.72,6.97
				c7.06,4.03,14.58,7.25,21.74,11.11c2.33,1.26,4.85,3.2,5.97,5.47c2.44,5,6.42,7.72,11.04,10.31c9.41,5.27,18.62,10.93,27.78,16.63
				c2.06,1.28,3.99,3.15,5.29,5.19c1.33,2.09,1.81,4.71,2.67,7.1c-2.6-0.28-5.3-0.25-7.79-0.94c-2.2-0.61-4.24-1.91-6.24-3.09
				c-14.47-8.5-28.85-17.18-43.4-25.55c-10.74-6.17-21.7-11.95-32.57-17.9c-0.25,0.38-0.51,0.76-0.76,1.15
				c5.21,5.04,13.31,7.42,15.64,16.75c-2.58-0.82-4.28-1.17-5.81-1.87c-21.86-10.13-43.71-20.28-65.51-30.53
				c-1.33-0.63-2.3-2.04-3.43-3.09c1.53-0.57,3.03-1.31,4.61-1.67c1.18-0.27,2.48-0.05,3.72-0.05c0.05-0.27,0.1-0.54,0.15-0.8
				c-17.6-6.52-35.2-13.04-52.8-19.55c2.84,5.3,6.71,9.56,9.98,14.24c4.16,5.95,8.28,12.02,11.55,18.48
				c1.62,3.19,3.13,5.74,6.51,6.89c3.93,1.34,7.86,2.68,11.82,3.94c6,1.92,12.14,3.48,18.02,5.73c15.79,6.05,31.42,12.54,47.26,18.45
				c2.58,0.96,5.93-0.21,8.93-0.33c2.92-0.12,6.2-1.07,8.69-0.07c6.9,2.78,14,5.6,20.04,9.82c13.51,9.42,28.31,14.99,44.21,18.66
				c6.94,1.61,13.88,4.66,19.9,8.49c8.53,5.42,17.64,8.87,27.48,12.17c-1.17-4.2,0.22-5.27,3.59-4.03c3.74,1.38,7.46,2.8,11.2,4.2
				c0,0.67,0,1.33,0,2c-3.87-0.05-7.99-2.71-11.53,1.16c-0.28,0.31-1.35,0.13-1.93-0.11c-9.63-4-19.67-7.28-28.7-12.36
				c-6.28-3.53-12.21-7.2-19.36-8.64c-16.78-3.36-32.6-8.99-46.56-19.36c-4.36-3.24-9.48-5.51-14.42-7.86
				c-4.47-2.13-9.08-2.83-13.91-0.39c-1.58,0.8-4.05,0.84-5.74,0.22c-15.6-5.68-31.1-11.65-46.67-17.41
				c-10.32-3.82-20.72-7.45-31.08-11.16c-0.25,0.48-0.51,0.95-0.77,1.43c3.56,3.15,6.71,7.03,10.76,9.35
				c23.67,13.55,50,20.27,75.6,28.86c13.2,4.43,26.4,8.85,39.6,13.3c0.93,0.31,1.94,0.74,2.61,1.42c0.86,0.87,1.41,2.05,2.1,3.1
				c-1.08,0.59-2.15,1.63-3.24,1.67c-2.29,0.06-4.68-0.11-6.9-0.71c-17.07-4.6-32.72-13.42-50.5-16.15
				c-10.96-1.68-21.6-5.48-32.38-8.32c1.69,2.66,3.88,4.1,5.93,5.73c3.61,2.86,6.7,6.96,10.78,8.5c7.75,2.93,14.61,8.88,23.69,7.89
				c0.9-0.1,2.01,0.39,2.82,0.92c9.17,5.95,20.15,4.87,30.13,7.76c8.85,2.57,18.29,3.17,27.1,5.84c8.96,2.73,17.52,6.83,26.16,10.56
				c2.18,0.94,3.99,2.73,5.97,4.13c-2.72,0.71-5.68,2.48-8.1,1.92c-8.2-1.9-16.55,0.71-24.82-1.91c-5.75-1.82-12.31-1.05-16.51-6.7
				c-0.45-0.61-1.34-1.14-2.09-1.25c-7.54-1.07-15.09-2.03-22.63-3.03c0.01,1.62,0.17,1.98,0.43,2.07c0.62,0.22,1.28,0.35,1.93,0.45
				c8.09,1.18,15.7,3.29,23.07,7.32c10.19,5.59,21.02,10.05,33.23,8.85c2.22-0.22,4.87,0.09,6.79,1.14
				c8.73,4.79,17.28,9.91,25.87,14.96c0.98,0.58,2.06,1.35,2.57,2.31c0.8,1.5,1.98,3.61,1.46,4.77c-0.54,1.2-2.85,1.9-4.5,2.24
				c-1.82,0.37-3.78,0.09-5.67,0.09c4.19,3.49,8.49,7.11,13.38,8.33c2.62,0.65,6.25-2.88,9.47-4.42c1.94-0.92,3.96-1.72,5.99-2.42
				c1.76-0.61,3.6-1.02,6.65-1.87c-5.68-2.43-10.12-4.15-14.39-6.22c-5.21-2.52-9.75-5.68-10.79-12.14
				c-0.21-1.29-2.05-2.31-3.14-3.46c-2.45-2.55-5.65-4.72-7.18-7.74c-2.77-5.44-6.83-8.23-12.35-10.67
				c-9.96-4.4-19.4-10.01-28.87-15.45c-1.52-0.87-2.09-3.42-3.09-5.2c2.12-0.49,4.54-1.91,6.3-1.33c10.84,3.63,22.07,6.68,32.14,11.9
				c14.42,7.47,29.28,12.47,45.36,14.07c0,1,0,2,0,3c-14.64-1.62-28.48-5.79-41.9-11.95c-11.4-5.23-23.29-9.41-34.98-14
				c-0.88-0.35-1.92-0.29-2.89-0.43c8.51,8.07,18.25,13.65,29.11,16.96c6.2,1.89,10.2,5.52,13.28,11.1c1.64,2.98,4.82,5.11,7.24,7.68
				c1.42,1.51,3.64,3.01,3.87,4.74c0.71,5.22,4.07,7.99,8.21,9.84c5.89,2.64,12.03,4.72,18.06,7.04c0,1.33,0,2.67,0,4
				c-3.94,1.68-5.82,3.12-4.29,8.74c2.22,8.17,1.73,17.06,2.58,25.62c0.22,2.25,1.12,4.43,1.71,6.64c0,2,0,4,0,6
				c-0.76-1.07-1.77-2.04-2.24-3.23c-1.77-4.45-4.55-6.11-9.34-4.47c-1.4,0.48-3.48-0.08-4.9-0.83c-3.5-1.84-4.91-0.71-5.13,2.92
				c-0.35,5.61-0.76,11.22-1.07,16.84c-0.28,4.98-0.48,9.97-0.77,16.22c2.55-2.36,3.91-4.1,5.68-5.13c2.34-1.36,4.94-2.79,7.55-3.06
				c3.35-0.34,6.81,0.44,10.22,0.74c0,0.67,0,1.33,0,2c-2.42,0.3-5.11,1.41-7.22,0.72c-4.88-1.6-8.16,0.41-10.79,3.85
				c-1.05,1.38-1.65,3.98-1.07,5.5c0.57,1.51,2.83,3.24,4.44,3.33c4.86,0.27,9.75-0.21,14.63-0.4c0,1.33,0,2.67,0,4
				c-1.91-0.27-4.17-1.32-5.66-0.66c-2.86,1.28-5.18,2.13-8.12,0.19c-0.5-0.33-3.52,1.59-3.82,2.84c-1.02,4.25-1.34,8.67-1.88,12.63
				c6.63,0.68,13.05,1.34,19.48,2c0,0.67,0,1.33,0,2c-3.47,0.91-6.63,1.51-10.35-0.29c-2.46-1.19-6.03-0.06-9.11,0.03
				c1.67,3.57-0.03,7.83,4.89,8.11c4.88,0.28,9.72,1.4,14.57,2.15c0,1,0,2,0,3c-4.53-0.86-9.02-2-13.58-2.52
				c-6.82-0.78-10.03,1.3-11.97,7.76c-3.87,12.86-7.36,25.83-11.29,38.67c-1.93,6.31-2.98,13.83-7.12,18.3
				c-5.92,6.4-9.51,13.14-11.79,21.29c-1,3.6-1.11,6.64,2.13,7.88c3.33,1.27,5.94,6.5,10.75,2.53c2.69-2.22,5.26-1.86,6.39,2.04
				c0.3,1.03,0.74,2.01,1.66,4.46c4-6.15,7.31-11.25,11.44-17.59c0.84,3.04,1.88,4.75,1.62,6.25c-0.88,5.15-2.01,10.27-3.47,15.28
				c-0.67,2.31-1.23,3.83,1.28,5.4c7.47,4.67,15.24,8.56,23.96,10.26c0,1,0,2,0,3c-8.96-1.95-17.18-5.54-24.93-10.52
				c-4-2.57-5.09-4.96-2.85-9.38c1.88-3.69,2.81-7.87,4.16-11.83c-0.42-0.12-0.84-0.24-1.26-0.35c-1.37,2.07-2.46,4.43-4.18,6.15
				c-2.01,2.01-4.4,4.05-6.99,4.82c-0.81,0.24-2.83-3.6-4.49-5.84c-5.5,5.35-10.42,0.58-14.93-2.42c-2.8-1.86-4.23-1.76-6.25,0.32
				c-2.43,2.5-4.72,5.2-7.44,7.35c-1.17,0.92-3.26,0.67-4.93,0.95c-0.16-1.52-0.71-3.12-0.41-4.55c1.92-9.33,4.17-18.6,5.93-27.96
				c0.37-1.97-0.18-5.27-1.5-6.12c-1.71-1.11-4.77-0.97-6.95-0.33c-3.39,1-6.52,2.87-8.43,3.75c3.76,2.81,8.07,5.98,12.28,9.28
				c0.51,0.4,0.84,1.68,0.6,2.31c-1.2,3.27-2.29,6.66-4.05,9.63c-1.48,2.51-4.41,4.22-5.64,6.79c-2.53,5.25-6.42,8.82-11.39,11.45
				c-2.9,1.53-5.86,2.95-9.01,4.54c4.91,2.68,5.19,4.29,0.98,7.54c-6.43,4.95-13.1,9.59-19.67,14.37c9.01-2.28,17.35-5.96,24.82-11.6
				c5.3-4.01,10.66-7.95,16.26-11.51c1.49-0.95,3.94-0.4,5.95-0.54c-0.42,1.99-0.71,4.02-1.31,5.96c-0.28,0.9-1.07,1.68-1.73,2.42
				c-3.16,3.5-6.37,6.96-9.55,10.44c0.14,0.45,0.28,0.91,0.42,1.36c4.7-4.98,10.08-5.83,15.96-3.89c0.37,0.12,1.06-0.15,1.37-0.47
				c4.34-4.34,8.34-2.46,12.4,0.51c0.81,0.59,1.99,0.7,3.41,1.16c0.1-2.03-0.13-3.6,0.31-4.95c0.65-2.02,1.78-3.9,2.7-5.83
				c1.69,1.53,3.88,2.78,4.97,4.66c2.37,4.07,4.17,8.46,6.21,12.72c0.37-5.33,0.83-9.96,6.91-11.43c0.39-0.09,0.77-0.66,0.95-1.09
				c1.83-4.4,4.81-3.15,7.1-0.94c6.82,6.57,14.97,9.56,24.22,10.09c0,1,0,2,0,3c-10.58-0.64-19.85-4.48-27.26-12.14
				c-1.57,1.65-2.32,3.14-3.41,3.47c-6.47,1.95-5.46,6.46-4.18,11.21c0.74,2.73,1.6,5.45,1.93,8.24c0.14,1.21-0.92,2.56-1.43,3.85
				c-1.18-0.75-2.8-1.25-3.44-2.32c-1.08-1.82-1.39-4.07-2.27-6.04c-2.98-6.65-6.06-13.26-9.11-19.88c-1.02,2.32-1.03,4.42-1.27,6.49
				c-0.49,4.17-2.7,5.59-6.04,3.28c-4.23-2.93-8.08-5.14-12.75-1.06c-0.4,0.35-1.37,0.38-1.94,0.17c-5.04-1.86-9.2-0.2-13.47,2.59
				c-1.33,0.87-3.92-0.21-5.94-0.39c0.7-1.82,1.03-3.93,2.18-5.38c1.84-2.32,4.35-4.11,6.36-6.31c1.78-1.95,3.27-4.16,4.89-6.25
				c-0.37-0.35-0.74-0.71-1.1-1.06c-3.9,2.74-8.04,5.2-11.65,8.28c-11.02,9.41-23.82,15.09-37.7,18.48
				c-15.25,3.72-31.11,4.86-45.74,11.16c-3.33,1.44-6.76,2.71-10.24,3.74c-12.28,3.64-24.6,7.15-36.9,10.71
				c0.11,0.59,0.23,1.19,0.34,1.78c1.62-0.1,3.79,0.43,4.76-0.41c6.57-5.74,14.99-3.02,22.36-4.5c5.57-1.12,11.6-1.18,17.35-2.82
				c5.65-1.61,11.46-2.63,17.2-3.88c0.48-0.1,1.04-0.18,1.48-0.03c11.49,4.08,21.24-2.4,31.34-5.64c7.23-2.31,13.82-6.6,20.78-9.83
				c0.98-0.45,2.41,0.07,3.63,0.14c-0.15,1.07-0.16,2.19-0.51,3.19c-0.26,0.74-1.2,1.25-1.45,1.99c-0.48,1.45-1.23,3.17-0.81,4.44
				c0.25,0.78,2.44,1.29,3.76,1.3c3.12,0.03,6.25-0.31,9.5-0.51c2.89-4.43,4.72-10.17,11.1-13.11c0,3.12,0,5.24,0,7.08
				c2.21,1.27,4.13,2.56,6.21,3.49c1.27,0.57,2.95,1.14,4.17,0.78c6.54-1.95,12.72-0.49,18.81,1.69c7.45,2.67,14.82,5.55,22.23,8.31
				c0.25,0.09,0.62-0.12,0.74-0.15c1.54-2.8,2.39-6.33,4.62-7.92c3.14-2.23,5.8,2.93,7.97,2.75c6.34-0.54,9.99,3.99,14.89,6.04
				c0,0.67,0,1.33,0,2c-7.17-2.64-14.34-5.28-22.1-8.14c-0.43,1.35-0.52,3.64-1.71,4.88c-1.46,1.53-4.16,3.41-5.75,2.99
				c-6.26-1.68-12.22-4.43-18.43-6.36c-4.7-1.46-9.6-3.26-14.39-3.2c-7.96,0.11-15.16-1.68-22.6-5.6c-0.84,1.81-1.48,3.66-2.52,5.25
				c-0.78,1.19-2.04,2.85-3.14,2.89c-4.28,0.16-8.59-0.14-12.87-0.5c-1.14-0.1-2.74-0.71-3.22-1.59c-1.21-2.22-1.91-4.72-2.82-7.11
				c-2.42,1.19-4.79,2.54-7.29,3.56c-5.95,2.42-12.33,4.04-17.82,7.21c-4.15,2.39-7.99,3.58-12.63,3.29c-4.25-0.27-8.64-0.75-12.76,0
				c-7.89,1.44-15.63,3.72-23.46,5.57c-1.43,0.34-2.97,0.27-4.46,0.35c-6.51,0.38-13.02,0.74-19.53,1.16
				c-0.64,0.04-1.4,0.23-1.88,0.62c-4.62,3.74-9.87,2.93-15.37,3.14c-13.92,0.54-27.8,2.37-41.69,3.69c3.74,1.43,7.51,1.5,11.22,2.03
				c2.2,0.31,4.3,1.35,6.46,2.04c5.62,1.81,11.23,3.63,16.86,5.41c0.25,0.08,0.61-0.28,0.92-0.28c6.82-0.01,14-1.36,20.38,0.33
				c9.67,2.55,18.94,4.84,28.95,2.34c4.66-1.17,9.57-2.19,14.31-1.96c4.7,0.23,9.02,0.73,13.19-2.16c1.12-0.77,3.24-0.48,4.79-0.15
				c4.07,0.87,8.05,2.95,12.1-0.22c0.63-0.49,2.04-0.46,2.88-0.14c4.75,1.78,9.38,3.73,14.71,2.51c0.99-0.23,2.29,1.12,3.49,1.61
				c3.46,1.4,6.86,3.46,10.46,3.91c4.19,0.53,8.6-0.92,12.83-0.52c5.49,0.52,10.88,2.03,16.31,3.11c2.72,0.54,5.96,2.25,8.06,1.34
				c4.58-2,8.52-3.48,12.41,0.95c3.5-8.53,4.94-9.13,9.96-4.13c5.19-3.3,10.57-5.65,15.11-1.02c3.19-0.99,5.72-1.78,9.04-2.81
				c2.32-5.58,3.03-5.71,8.34-0.74c2.66-1.62,5.34-2.97,7.68-4.76c2.66-2.03,4.49-1.68,5.98,1.21c0,2,0,4,0,6
				c-2.83-1.01-5.43-1.69-8.43,0.19c-2.43,1.53-5.5,2.06-8.28,3.04c-0.17-0.44-0.33-0.87-0.5-1.31c-3.79,4.51-3.79,4.51-8.41,2.36
				c-3.41,4.74-4.46,4.79-8.06,0.63c-1.71-1.98-3.38-2.97-6.35-1.43c-3.23,1.67-6.75,5.07-10.6,0.68c-0.44,0.89-0.72,1.31-0.85,1.77
				c-1.71,6.09-3.57,6.69-8.26,2.64c-2.73-2.36-5.46-1.61-6.89,1.9c-2.11,5.17-7,8.01-11.92,7.08c-3.08-0.58-6.2-1.27-8.18,2.49
				c-0.42,0.79-2.29,1.13-3.5,1.15c-7.96,0.1-15.93,0.05-23.63,0.05c-1.85,8.27-5.61,9.63-11.2,5.08c-2.63-2.14-4.44-1.48-5.68,1.59
				c-1.55,3.82-4.75,4.98-8.49,4.7c-4.19-0.31-8.35-0.94-12.52-1.44c-2.24,4.84-3.3,5.37-9.42,4.83c-5.89-0.52-11.78-1.05-17.68-1.47
				c-0.95-0.07-2.23,0.13-2.85,0.72c-4.33,4.13-8.39,8.56-12.81,12.59c-3.63,3.32-7.63,6.23-11.49,9.29
				c-0.22,0.17-0.65,0.07-0.97,0.12c-5.64,0.9-8.2,3.8-9.31,9.44c-0.35,1.8-2.42,4.85-3.25,4.7c-7.62-1.32-8.23,5.99-12,8.42
				c3.53,0,7.47-1,10.55,0.21c6.57,2.58,13.15,5.45,19.24,9.01c2.02,1.19,3.2,3.16,6.1,2.73c4.27-0.64,8.62-0.78,12.92-1.22
				c0.59-0.06,1.11-0.68,1.68-1.02c3.01-1.81,6.02-3.62,9.55-5.73c0.86-1.78,1.85-4.6,3.47-7.02c4.36-6.53,8.88-12.97,13.57-19.27
				c1.64-2.21,3.97-3.91,5.98-5.84c0.36,0.33,0.72,0.67,1.08,1c2.79-6.75,3.56-7.23,11.25-7.17c0.46,0,0.96-0.26,1.39-0.49
				c6.28-3.32,11-0.19,14.69,2.82c3.4,0,5.96,0,9.46,0c2-1.82,4.73-4.73,7.88-7.06c3.01-2.23,6.14-5.63,10.4-1.55
				c0.53,0.51,2.48-0.54,3.79-0.75c2.45-0.39,5.53-1.8,7.24-0.82c3.73,2.13,6.93,3.48,10.62,0.52c1.95-1.56,3.76-3.29,5.63-4.95
				c1.18-1.04,2.84-3.21,3.46-2.95c4.34,1.87,5.89-1.71,8-3.83c1.9-1.9,3.34-4.27,4.99-6.42c1.85,1.9,3.7,3.8,5.16,5.31
				c2.4-0.67,4.53-1.27,6.85-1.93c1.84-4.96,2.11-5.01,6.53-1.52c0.7,0.55,2.07,1.12,2.67,0.8c2.61-1.39,5.25-2.86,7.49-4.76
				c3.1-2.63,5.06-8.27,10.56-2.28c1.28-6.38,9.29-7.05,9.38-13.64c0,3,0,6,0,9c-3.01,1.21-5.73,2.53-7.45,5.74
				c-0.67,1.24-4.17,2.45-5.01,1.83c-3.16-2.36-4,0.23-5.35,1.85c-4.73,5.66-8.78,6.76-14.51,4.54c-3.81,1.71-6.8,3.81-10.02,4.24
				c-2.14,0.29-4.59-1.81-6.63-2.71c-4.73,2.04-5.65,10.21-12.96,7.52c-3.92,4-3.25,7.18,1.24,10.43c4.83,3.49,9.23,7.58,13.25,10.93
				c4.42-1.28,8.48-3.35,12.58-3.42c6.15-0.11,10.85,4.17,14.87,8.29c3.37,3.45,5.84,7.71,11.49,8.09c0.41-1.01,0.67-2.19,1.3-3.11
				c2.33-3.45,4.78-6.81,7.19-10.21c0,1,0,2,0,3c-1.91,3.85-3.66,7.79-5.81,11.49c-0.7,1.2-3.02,2.83-3.65,2.49
				c-5.93-3.18-7.52,2.43-10.74,4.86c-2.35,1.78-4.88,3.86-7.6,4.38c-1.22,0.23-3.1-3.05-5.6-5.73c-1.75,2.18-3.6,4.72-5.7,7.05
				c-2.18,2.42-2.04,7.71-7.5,6.3c-0.27-0.07-0.82,0.85-1.2,1.33c-5.9,7.32-11.83,14.61-17.66,21.98c-2.25,2.85-4.5,4.53-7.38,0.82
				c-0.96,2.34-1.73,4.21-2.5,6.08c-0.64,0.14-1.27,0.28-1.91,0.42c-1.94-3.66-3.87-7.31-6.14-11.59c-4.35,1.29-9.89,2.97-15.45,4.58
				c-1.75,0.5-3.55,0.79-5.32,1.24c-0.75,0.19-1.78,0.31-2.13,0.84c-2.09,3.09-4.03,6.29-6.05,9.51c1.47,1.76,3.09,3.7,4.59,5.51
				c1.51-1.11,2.87-2.12,4.22-3.12c0.79,1.53,1.83,2.98,2.33,4.6c1.34,4.32,2.45,8.71,3.78,13.53c2.1-6.04,1.18-12.54,5.82-17.89
				c1.57,3.51,2.84,6.35,4.27,9.52c4.03-3.7,5.75-0.58,6.84,2.38c1.45,3.97,2.27,8.17,3.17,11.58c2.89-8.77,1.28-19.53,8.18-29.13
				c1.45,2.7,2.63,4.44,3.36,6.36c1.35,3.54,2.44,7.18,3.64,10.78c-0.12,1.13-0.24,2.26-0.36,3.38c0.5-0.04,1-0.08,1.5-0.12
				c-0.24-1.19-0.48-2.38-0.72-3.58c1.91-5.06,3.77-10.13,5.77-15.15c0.32-0.79,1.24-1.81,1.94-1.84c0.66-0.04,1.69,0.94,2.01,1.7
				c0.68,1.63,0.99,3.42,1.49,5.13c0.61,2.06,1.27,4.1,2.15,6.95c3.1-5.74,5.61-10.39,8.76-16.22c2.53,4.65,4.28,7.88,6.21,11.43
				c1.66-3.99,3.13-7.53,5.17-12.46c1.54,3.03,2.36,4.64,3.33,6.57c1.62-2.84,3.07-5.55,4.71-8.14c0.66-1.03,1.75-1.79,2.64-2.68
				c0.92,1.04,2.25,1.94,2.67,3.16c1.39,4.06,2.17,8.35,3.76,12.32c0.94,2.35,2.95,4.26,4.76,6.75
				C967.88,735.13,969.85,730.07,971.81,725z M408.78,252.02c-10.56,2.42-21.41,3.98-30.8,9.99c1.93,0.4,3.59-0.11,5.27-0.5
				c4.17-0.95,8.33-2.53,12.52-2.6c5.78-0.1,10.82-0.5,12.96-6.95c2.23-0.66,4.47-1.32,6.7-1.98c-0.28-0.58-0.56-1.17-0.84-1.75
				C412.65,249.49,410.72,250.76,408.78,252.02z M281.02,331.99c9.87-1.39,19.73-2.49,29.47,1.63c0.86-1.87,1.51-3.29,2.16-4.72
				c-6.43-2.86-22.76-2.63-28.22,0.33c-1.26,0.68-2.24,1.87-3.35,2.82c-1.7-0.74-3.69-1.13-5.04-2.27c-3.5-2.98-8.86-2.41-10.81,1.15
				c2.89,1.27,5.82,2.56,9.29,4.09C276.24,334.21,278.63,333.1,281.02,331.99z M795.08,425.01c2.22,0.59,4.45,1.19,6.67,1.78
				c0.08-0.58,0.15-1.15,0.23-1.73c-2.32,0-4.63,0-6.94,0c-2.17-1.73-4.33-3.46-6.59-5.26c-4.88,7.96-8.76,0.05-13.07,0.46
				c-2.77,0.86-5.51,1.27-8.25,1.19c-3.55-0.1-7.25-1.47-10.37,2.12c3.34,4.29,5.13,10.33,12.46,9.34c0.7-0.1,1.74,0.39,2.24,0.94
				c6.4,7.05,15.08,2.24,22.68,4.18c-2.81-2.84-6.13-4.67-9.25-6.77c-1.21-0.81-1.98-2.27-2.95-3.43c1.08-0.91,2.09-2.46,3.26-2.6
				C788.45,424.85,791.78,425.05,795.08,425.01z M876.97,302.03c-2.95-1.41-5.89-2.82-8.84-4.23c-0.14,0.35-0.29,0.69-0.43,1.04
				c3.11,1.04,6.22,2.09,9.33,3.13c1.63,1.88,3.41,3.65,4.84,5.67c2.41,3.41,5.27,4.91,9.65,5.78c5.61,1.11,10.8,4.55,16.04,7.25
				c0.82,0.42,0.9,2.25,1.33,3.43c-1.16,0.39-2.42,1.28-3.47,1.08c-3.73-0.71-7.41-1.75-11.08-2.74c-6.48-1.73-12.95-3.51-20.28-5.5
				c5.7,2.92,10.33,6.04,15.44,7.74c8.47,2.81,17.22,4.78,25.91,6.91c1.46,0.36,3.56,0.23,4.7-0.6c5.56-4.03,10.9-8.38,17.4-13.46
				c-6.84-1.03-11.01-6.21-17.05-3.51c-0.44,0.2-1.49-0.15-1.8-0.57c-4.85-6.56-12.62-6.44-18.83-8.69c-0.4,2.22-0.51,5.88-1.16,5.98
				c-3.13,0.47-8.35,1.15-9.27-0.38C886.37,305.32,880.79,305.16,876.97,302.03z M465.71,494.02c-7.42,8.27-14.6,16.77-22.34,24.72
				c-5.89,6.05-4.8,6.12-11.93-1.04c3.99-6.11,7.7-12.35,8.53-19.83c7.61-5.15,0.22-15.16,7.08-20.73
				c-15.33,15.47-27.72,33.68-48.33,43.68c5.9,1.93,13.69-0.05,14.07,8.93c9.48-2.6,17.74-2.55,25.23,3.63
				c0.97,0.8,3.81,0.48,5.03-0.39c3.09-2.2,5.41-5.61,8.65-7.46c7.95-4.53,10.97-12.61,15.3-19.78c0.43-0.71,0.22-1.79,0.32-2.81
				c-2.02,0.38-3.67,0.69-6.65,1.26c2.05-4.17,3.53-7.19,5.02-10.21c1.14-1.46,2.27-2.92,3.41-4.38c-0.29-0.18-0.58-0.37-0.87-0.55
				C467.37,490.72,466.54,492.37,465.71,494.02z M251.05,394.96c6.49-7.79,15.06-13.9,19.42-23.56
				c-16.13-6.17-30.63,1.64-43.13,11.52c-3.06,2.42-6.05,5.18-9.54,6.72c-5.47,2.42-9.79,5.71-11.92,11.31
				c-2.71,7.12-7.88,11.29-14.96,13.48c-1.01,0.31-1.91,0.96-2.99,1.52c5.06,4.85,8,5.88,13.65,3.39c6.78-2.99,13.02-7.27,19.91-9.91
				c7.24-2.78,12.86-7.41,18.28-12.58c1.32-1.26,2.64-2.58,4.15-3.57c2.9-1.89,5.61-2.24,7.18,1.74c-1.8,1.53-3.6,3.06-5.4,4.59
				c0.39,0.36,0.79,0.73,1.18,1.09C248.28,398.8,249.66,396.88,251.05,394.96z M404.77,272.99l0.07,0.07
				c-0.81-1.25-1.68-2.48-2.42-3.77c-0.95-1.67-1.81-3.41-2.7-5.11c1.9-0.46,3.81-1.27,5.71-1.26c1.36,0.01,2.85,0.93,4.05,1.77
				c3.44,2.39,6.77,4.94,11.45,8.39c-0.89-7.83-1.64-14.39-2.5-21.92c-3.7,2.11-7.58,2.99-8.75,5.27c-2.12,4.14-5.15,4.44-8.89,4.92
				c-5.89,0.75-11.72,1.96-17.55,3.07c-1.62,0.31-3.16,1.28-4.76,1.36c-1.32,0.07-3.06-0.38-3.89-1.27c-0.59-0.63-0.36-2.64,0.22-3.6
				c0.91-1.5,2.21-3.33,3.72-3.79c7.73-2.34,15.62-4.15,23.37-6.41c3.19-0.93,6.19-2.54,9.27-3.85c-0.29-0.85-0.58-1.69-0.87-2.54
				c-6.36,1.45-13.01,2.18-19.03,4.52c-11.12,4.34-22.19,7.39-34.33,6.45c-5.71-0.44-11.65,0.44-17.31,1.56
				c-11.81,2.34-23.46,5.48-35.26,7.8c-6.81,1.34-10.08,6.03-12.88,10.36c7.04-0.73,14.29-1.6,21.56-2.15
				c1.83-0.14,4.36,0.16,5.4,1.31c0.85,0.95-0.02,3.46-0.08,4.45c6.86-4.94,12.67-12.75,23.12-12.39c2.6,0.09,3.75-1.01,4.16-3.77
				c0.2-1.32,2.16-3.09,3.57-3.36c3.89-0.75,7.91-1,11.88-1.1c4.27-0.11,5.91,2.14,4.28,6.04c-0.84,1.99-2.64,3.58-4,5.36
				c-1,1.3-1.98,2.61-3.12,4.12c3.84-1.98,7-4.24,10.51-5.24c3.44-0.98,4.77,1.26,3.38,4.63c-1.01,2.45-2.31,4.89-2.74,7.46
				c-1.96,11.91-7.84,12.85-16.98,11.5c0.23-1.82,0.45-3.64,0.68-5.46c-0.39-0.29-0.78-0.57-1.17-0.86c-1.37,1.3-2.68,2.68-4.13,3.89
				c-1.8,1.49-3.56,3.74-5.6,4.08c-7.63,1.25-11.14,8.77-6.56,16.22c1.13-1.64,2.19-3.02,3.08-4.51c1.28-2.13,2.2-4.55,3.81-6.37
				c0.57-0.65,3.45-0.44,4.04,0.33c3,3.9,4.73,4.29,8.78,1.23c2.13-1.6,4.29-3.15,7.51-5.52c0,4.84,0,8.04,0,11.53
				c1.23,0.84,2.68,1.82,4.12,2.8c4.98-7.13,4.98-7.13,10.61-4.73c-0.28-1.18-1.03-1.89-1.78-2.61c-0.87-0.84-1.74-1.68-2.62-2.52
				c0.92-0.87,1.7-2.1,2.79-2.51c1.31-0.5,3.01-0.75,4.34-0.37c8.51,2.42,16.96,5.04,25.8,7.71c2.82-3.78,2.74-3.73-0.4-7.95
				c-0.68-0.91-1.04-2.52-0.76-3.61C406.61,286.05,407.01,279.52,404.77,272.99z M686.3,173.02c-2.68-0.73-5.37-1.46-8.05-2.19
				c-0.1,0.58-0.19,1.15-0.29,1.73c2.8,0.13,5.59,0.27,8.39,0.4c2.93,2.22,5.61,4.96,8.86,6.54c4.9,2.38,9.99,3.77,15.83,3.51
				c6.52-0.29,13.2,1.08,19.66,2.43c1.76,0.37,2.99,3.29,4.46,5.04c-1.93,0.51-3.87,1.45-5.81,1.45c-4.3,0.01-8.6-0.71-12.9-0.66
				c-7.31,0.09-14.61,0.71-21.91,0.83c-11.06,0.18-22.14-0.14-33.18,0.32c-4.13,0.17-8.14,2-11.17,6.68
				c4.72-0.88,8.24-2.04,11.79-2.11c12.89-0.26,25.78-0.06,38.67-0.25c6.97-0.1,13.94-0.66,20.91-0.96
				c6.59-0.28,13.17-0.53,19.76-0.72c1.25-0.04,2.52,0.31,3.78,0.48c0.01,0.49,0.03,0.97,0.04,1.46c-1.53,0.38-3.05,1.05-4.59,1.1
				c-7.43,0.25-14.87,0.35-22.3,0.49c-9.46,0.17-18.92,0.24-28.37,0.51c-10.45,0.29-20.92,0.5-31.34,1.21
				c-6.99,0.47-8.51,2.98-7.28,8.93c38.92-1.08,77.7-2.16,117.62-3.27c-2.41-2.3-3.88-3.61-5.24-5.02
				c-9.15-9.49-20.35-17.35-24.4-30.98c-0.38-1.27-3.29-2.48-5.05-2.52c-4.93-0.11-9.87,0.41-15.43,0.72
				c-0.17,3.38-2.64,3.52-6.16,3.53C703.83,171.73,695.07,172.53,686.3,173.02z M703.28,596.98c4.55,4.39,9.73,1.79,15.44,1.53
				c-5.65-0.54-10.57-1-15.49-1.47c-0.69-0.45-1.33-1.16-2.08-1.29c-9.74-1.77-19.49-3.46-29.24-5.16c-0.32-0.06-0.73-0.13-0.97,0.02
				c-4.17,2.51-8.07,0.36-11.99-0.75c-3.39-0.95-6.41-3.21-10.39-1.19c-1.15,0.58-3.53-1.18-5.31-1.96
				c-2.23-0.97-4.95-3.43-6.54-2.82c-6.74,2.55-12.97-0.7-19.4-0.89c-3.19-0.09-6.27-2.32-9.51-2.77c-4.79-0.67-9.68-0.59-14.52-0.91
				c-0.97-0.06-2.21-0.17-2.83-0.78c-3.52-3.43-7.52-3.48-11.98-2.42c-1.69,0.4-3.61-0.24-5.43-0.35c-3.93-0.24-7.86-0.41-11.78-0.68
				c-4.73-0.33-10.33,0.77-14-1.4c-7.79-4.59-16.32-2.9-24.32-4.89c-4.02-1-8.32-0.86-12.46-1.45c-2.72-0.39-5.38-1.19-8.07-1.77
				c-2.72-0.59-5.63-0.73-8.14-1.81c-4.44-1.91-7.06-7.5-13.09-6.44c-0.76,0.13-1.97-0.67-2.54-1.38
				c-4.38-5.46-11.33-6.62-16.99-9.77c-6.48-3.6-14.18-4.97-21.32-7.4c-6.84-2.32-12.37-9.37-20.97-5.59c2.15,0.91,4.3,1.76,6.4,2.72
				c13.75,6.29,29.12,9.33,40.04,21.33c2.82,3.1,5.33,4.78,1.56,8.47c1.94,2.51,3.37,5.1,5.49,6.86c1.87,1.56,4.51,3.1,6.79,3.08
				c9.4-0.12,18.78-0.87,28.18-1.31c1.11-0.05,2.48,0.08,3.34,0.69c5,3.53,9.98,6.73,16.57,6.5c1.91-0.07,3.89,1.66,5.82,2.59
				c1.04,0.5,2,1.38,3.07,1.53c7.83,1.1,15.68,2.13,23.54,3c1.77,0.19,3.62-0.57,5.42-0.5c7.24,0.28,14.5,0.44,21.7,1.2
				c5.32,0.56,10.5,2.55,15.82,2.9c9.31,0.62,18.67,0.51,28.01,0.63c6.1,0.08,12.21-0.01,18.31,0.02c1.14,0.01,2.28,0.29,3.42,0.37
				c3.37,0.24,6.73,0.43,10.1,0.66c1.29,0.09,3.12-0.25,3.77,0.46c2.97,3.22,6.63,2.3,10.23,2.37c3.91,0.08,7.81,0.72,11.72,0.74
				C693.53,597.51,698.41,597.16,703.28,596.98z M352.18,1098.54c-1.42-0.66-2.21-0.9-2.85-1.36c-1.53-1.1-2.99-2.29-4.48-3.45
				c1.61-1.2,3.06-2.96,4.87-3.51c6.66-2.04,13.39-3.91,20.19-5.42c19.89-4.42,39.13-10.5,56.87-20.84
				c9.77-5.69,10.41-12.97,1.74-20.5c-5.9-5.13-11.79-10.29-17.39-15.75c-7.6-7.41-14.71-15.35-22.43-22.63
				c-5.69-5.37-5.49-8.23,1.66-11.97c1.41-0.74,3.04-2.28,3.33-3.7c1.53-7.55,2.38-8.8,10.04-9.86c4.87-0.67,9.83-0.65,14.7-0.93
				c0.26-6.16,1.79-7.75,9.36-8.78c20.69-2.83,41.46-2.81,62.2-0.77c6.22,0.61,12.62,1.49,16.79,7.4c0.68,0.96,2.6,1.19,4.01,1.45
				c1.95,0.36,4.15-0.02,5.88,0.75c1.73,0.78,3,2.57,4.47,3.93c-1.59,1.18-3.08,3.19-4.78,3.38c-6.1,0.69-12.27,0.77-18.42,1.07
				c-3.22,0.16-6.43,0.31-9.65,0.46c0,0.43,0,0.85,0,1.28c2.55,0.3,5.11,0.83,7.66,0.87c7.98,0.12,15.97,0,23.95,0.12
				c2.13,0.03,4.86-0.14,6.26,1.04c2.89,2.43,5.07,5.71,7.54,8.64c-2.86,1.23-5.69,2.5-8.59,3.64c-0.73,0.29-1.65,0.11-2.48,0.13
				c-12.63,0.34-25.27,0.65-37.9,1.05c-2.39,0.08-4.77,0.55-7.16,0.84c2.72,1.2,5.36,1.56,7.99,1.58
				c14.47,0.06,28.95-0.07,43.42,0.14c3.05,0.04,6.13,1.11,9.12,1.95c3.11,0.87,6.07,2.57,9.22,2.99c7.23,0.96,14.57,1.19,21.8,2.14
				c1.54,0.2,2.84,2.23,4.25,3.41c-1.42,1.23-2.69,3.19-4.31,3.53c-4.03,0.86-8.21,1.06-12.35,1.39c-7.53,0.6-15.07,1.11-22.42,1.65
				c-0.43,5.9-1,6.59-5.4,8.3c-8.1,3.16-16.64,2.12-24.98,3.02c-14.47,1.57-28.74,5.1-43.06,7.95c-1.68,0.33-3.17,1.57-4.75,2.39
				c0.11,0.5,0.22,0.99,0.33,1.49c7.04,0.91,14.08,1.8,21.12,2.73c9.21,1.22,18.43,2.35,27.6,3.85c1.59,0.26,2.91,2.16,4.36,3.3
				c-1.41,1.27-2.74,2.66-4.28,3.74c-0.75,0.53-1.89,0.58-2.88,0.68c-9.08,0.95-18.17,1.83-27.25,2.84
				c-1.58,0.18-4.18,0.56-4.48,1.46c-1.99,6-7,5.12-11.49,5.67c-2.56,0.31-5.09,0.9-8.88,1.59c1.94,2.44,2.86,4.79,4.45,5.41
				c9.7,3.76,19.65,6.23,30.19,3.91c13.22-2.91,26.59-4.36,40.11-3.35c4.58,0.34,9.2,1.33,13.59,2.7c3.96,1.23,5.1,4.5,3.7,8.46
				c-0.5,1.41-1.08,2.8-1.72,4.15c-1.56,3.26-0.7,5.59,2.77,6.56c3.18,0.89,6.43,1.62,9.7,2.07c8.69,1.21,17.97,0.74,25.97,3.73
				c9.4,3.51,18.71,4.09,28.3,4.23c1.22,0.02,3.39-1.8,3.56-3c1.93-13.33,3.4-26.73,5.35-40.06c0.78-5.37,1.85-10.84,3.86-15.84
				c1.95-4.85,2.81-9.45,1.82-14.55c-1.97-10.1-0.58-19.54,5.91-27.89c0.79-1.01,1.31-2.31,1.68-3.56c1.82-6.09,3.54-12.2,5.65-19.49
				c-8.14-3.74-16.97-9.11-26.54-11.96c-26.93-8-54.51-12.99-82.56-15.16c-3.69-0.29-8.87,0.31-9.55-4.29
				c-0.76-5.09,4.54-6.62,8.34-7.75c10.2-3.03,16.04-9.96,19.52-19.98c-3.7-0.37-7.18-0.54-10.6-1.1c-4.56-0.75-10.04-0.49-13.4-3
				c-6.09-4.54-13.01-5.54-19.75-7.59c-1.74-0.53-3.61-1-5.05-2.02c-1.23-0.86-2.77-2.59-2.62-3.72c0.15-1.1,2.18-2.5,3.57-2.75
				c5.39-0.98,10.82-1.83,16.28-2.22c20.89-1.5,41.8-2.74,62.69-4.23c4.29-0.31,8.54-1.26,12.81-1.92c0.01-0.53,0.02-1.06,0.03-1.59
				c-5.78-1.31-11.56-2.58-17.34-3.92c-8.4-1.95-17.38-2.7-25.01-6.31c-6.64-3.14-13.12-4.27-20.03-4.66
				c-14.28-0.8-28.57-1.35-42.84-2.19c-3.11-0.18-6.24-0.98-9.23-1.9c-1.22-0.38-2.1-1.85-3.14-2.83c1.14-1.04,2.13-2.51,3.47-3.04
				c2.13-0.83,4.49-1.24,6.79-1.47c14.05-1.41,28.12-2.6,42.15-4.14c4.22-0.46,9.52-0.51,12.2-3.02c3.09-2.89,6-3.72,9.59-3.89
				c9.3-0.43,18.6-0.67,27.9-1.04c3.62-0.15,7.23-0.47,10.84-0.71c-0.01-0.45-0.02-0.9-0.03-1.35c-10.41,0-20.83,0.15-31.24-0.03
				c-34.74-0.61-69.49,6.76-104.29-1.24c-9.85-2.27-20.54-0.69-30.82-1.36c-5.92-0.38-11.82-1.52-17.62-2.78
				c-1.62-0.35-2.87-2.39-4.28-3.65c1.33-1.03,2.66-2.07,4.1-3.19c-0.72-3.84-0.54-4.32,3.49-4.68c5.71-0.51,11.45-0.72,17.17-1.06
				c0.05-0.29,0.1-0.57,0.14-0.86c-9.87-2.74-19.74-5.48-29.61-8.22c0.05-0.81,0.1-1.62,0.15-2.43c6.34-0.95,12.69-1.91,18.66-2.81
				c-0.81-1.42-1.69-2.99-2.58-4.55c1.78-0.67,3.52-1.62,5.36-1.92c2.04-0.34,4.19-0.08,6.91-0.08c-2.68-5.76,0.65-6.51,4.23-6.93
				c3.6-0.42,7.22-0.69,12.12-1.15c-3.04-2.15-4.91-3.26-6.5-4.69c-1.03-0.92-2.55-2.77-2.24-3.4c0.6-1.23,2.3-2.57,3.63-2.67
				c6.44-0.48,12.92-0.73,19.38-0.73c7.97,0,15.95,0.58,23.91,0.42c3.35-0.07,6.68-1.4,10.02-2.15c-0.03-0.51-0.07-1.03-0.1-1.54
				c-4.78-1.25-9.66-2.21-14.29-3.86c-2.76-0.99-5.13-3.06-7.68-4.65c2.22-1.51,4.44-3.02,6.65-4.54c0.25-0.17,0.46-0.42,1.26-1.15
				c-16.37,0-32.15,0-48.01,0c0.56,3.16,0.69,6,1.6,8.58c1.64,4.64-1.01,6.13-4.61,6.85c-4.33,0.87-8.87,0.9-13.05,2.17
				c-10.22,3.12-20.59,3.31-31.09,2.89c-1.39-0.06-3.93,0.83-4.06,1.59c-0.77,4.57-4.02,4.21-7.21,4.21
				c-17.97,0.01-35.94-0.19-53.9,0.13c-9.88,0.17-19.75,1.21-29.62,1.84c-1.23,0.08-2.48,0.01-3.81,0.01
				c0.44,4.22,2.74,4.57,6.01,4.52c25.26-0.33,50.53-0.57,75.8-0.56c4.42,0,8.87,1.18,13.25,2.11c1.07,0.23,1.89,1.62,2.82,2.48
				c-0.89,0.86-1.65,2.23-2.69,2.5c-3.69,0.94-7.45,1.67-11.22,2.18c-7.64,1.03-15.31,1.86-22.97,2.78
				c-0.21,0.55-0.42,1.11-0.63,1.66c0.94,0.63,1.96,1.16,2.8,1.9c3.1,2.77,2.2,5.12-1.25,6.33c-9.05,3.17-18.59,3.63-27.41,0.51
				c-4.23-1.5-8.09-2.22-12.3-2.1c-4.15,0.11-8.31,0.43-12.43,0.96c-1.14,0.15-2.86,1.24-3.03,2.15c-0.2,1.05,0.76,3.18,1.64,3.44
				c4.02,1.22,4.52,4.12,4,7.45c-0.72,4.6,1.09,7.19,5.51,8.4c1.37,0.37,3.29,1.25,3.66,2.34c2.23,6.68,7.56,5.81,12.7,5.73
				c4.49-0.07,8.99-0.13,13.47,0.03c27.06,0.95,54.12,1.88,81.18,3c11.74,0.49,23.49,1.16,35.19,2.25
				c5.74,0.53,11.39,2.13,17.06,3.38c1.3,0.29,2.48,1.12,4.99,2.3c-19.95,10.5-40.45,6.53-60.18,8.47
				c7.83,3.72,18.48-2.05,24.85,8.33c-1.95,0.68-3.51,1.65-5.12,1.73c-9.13,0.41-18.26,0.63-27.39,0.87
				c-19.91,0.53-39.82,1.03-59.74,1.54c5.7,2.89,12.42,1.29,17.43,6.44c-5.37,1.28-10.7,2.01-16.02,1.95
				c-11.8-0.12-23.59-0.71-35.38-0.91c-1.34-0.02-3.89,1.27-3.84,1.74c0.19,1.73,0.97,3.55,2.01,4.98c0.96,1.3,2.55,2.14,3.91,3.22
				c-1.68,1.76-2.82,2.97-3.97,4.18c1.72,0.81,3.4,1.71,5.17,2.41c0.74,0.3,1.64,0.27,2.47,0.25c7.48-0.17,14.96-0.31,22.44-0.57
				c24.6-0.85,49.19-1.85,73.79-2.49c3.83-0.1,7.7,1.41,11.56,2.17c-0.08,0.78-0.17,1.57-0.25,2.35
				c-29.34,1.11-58.69,2.22-88.03,3.33c-0.03,0.42-0.05,0.85-0.08,1.27c6.99,1.26,13.95,2.81,20.99,3.7
				c15.26,1.93,29.47,6.66,42.23,15.31c5.97,4.05,7.07,8.97,2.04,13.72c-6.73,6.36-8.01,15.09-11.38,22.82
				c-1.95,4.47-3.96,8.22-7.96,10.99c-2.22,1.54-4.13,3.53-5.94,5.1c5.53,4.6,10.59,8.56,15.33,12.86c2.69,2.44,4.63,5.68,7.3,8.15
				c9.28,8.59,18.73,17,28.13,25.46c6.38,5.74,5.99,10.74-1.35,15.03c-15.49,9.04-32.1,15.13-49.57,19.16
				c-8.78,2.03-17.97,2.85-25.65,8.68c-3.43,2.6-7.88,3.9-11.91,5.69c-3.88,1.72-7.82,3.32-12.98,5.5c2.21,4.05,4.02,7.21,5.64,10.47
				c0.38,0.76,0.3,1.88,0.12,2.78c-1.37,6.88,1.08,11.57,7.71,13.63c7.45,2.31,14.99,4.37,22.57,6.21c1.26,0.31,2.92-1.05,4.4-1.63
				c-0.73-1.51-1.22-3.22-2.27-4.46c-0.63-0.74-2.28-0.52-3.2-1.14c-1.43-0.95-3.53-2.14-3.68-3.43c-0.16-1.34,1.35-3.4,2.7-4.27
				c2.34-1.52,5.11-2.36,8.61-3.89c-4.68-1.93-8.69-3.43-12.56-5.26c-1.21-0.57-2.91-2.14-2.78-3.01c0.2-1.3,1.59-2.83,2.88-3.47
				C346.96,1099.7,349.27,1099.36,352.18,1098.54z M609.98,1114.87c-9.14-1.23-18.47-1.03-26.67-3.99
				c-8.86-3.2-17.61-4.27-26.72-4.78c-3.95-0.22-7.97-0.79-11.78-1.85c-8.08-2.24-9.29-5.04-6.12-12.72c2.27-5.51,1.6-6.94-4.1-8.52
				c-1.43-0.4-2.92-0.6-4.39-0.83c-14.54-2.25-28.92-0.65-43.05,2.63c-12.01,2.79-23.16,0.25-33.99-4.25
				c-2.22-0.92-3.81-4.75-4.38-7.52c-0.19-0.94,3.49-3.25,5.71-3.96c2.79-0.89,5.92-0.62,8.86-1.16c1.12-0.2,2.11-1.21,3.11-1.91
				c2.03-1.42,3.88-3.81,6.06-4.16c6.87-1.11,13.87-1.38,20.8-2.15c4.05-0.45,8.07-1.23,12.1-1.86c-0.05-0.48-0.11-0.97-0.16-1.45
				c-1.76-0.41-3.49-0.98-5.27-1.19c-9.07-1.07-18.17-1.92-27.23-3.09c-5.75-0.74-11.47-1.76-17.15-2.94
				c-1.22-0.25-2.91-1.64-3.07-2.7c-0.16-1.08,1.02-2.89,2.11-3.53c2.25-1.33,4.75-2.5,7.29-3.03c13.33-2.75,26.7-5.33,40.09-7.81
				c2.23-0.41,4.69-0.35,6.92,0.11c7.8,1.6,14.63-1.35,21.8-3.87c-0.57-1.66-1-2.94-1.5-4.39c12.72-6.95,27.18-2.04,39.93-7
				c-5.63-1.06-11.24-1.27-16.81-1.85c-4.43-0.46-9.45-0.24-13.07-2.32c-5.12-2.95-10.26-3.5-15.72-3.53
				c-13.81-0.07-27.61,0-41.42-0.09c-2.3-0.02-4.68-0.31-6.85-1c-1.19-0.38-2.04-1.85-3.05-2.82c1.02-0.91,1.9-2.25,3.09-2.64
				c2.02-0.65,4.23-0.9,6.37-0.97c12.3-0.4,24.6-0.64,36.89-1.08c3.85-0.14,7.68-0.74,11.74-1.15c-2.15-5.68-6.06-7.04-10.59-7.15
				c-5.15-0.13-10.31-0.03-15.47-0.1c-4.99-0.07-10.01,0.09-14.93-0.53c-1.88-0.24-3.53-2.36-5.29-3.62c1.76-1.14,3.45-3.11,5.3-3.28
				c7.27-0.68,14.6-0.74,21.9-1.13c2.01-0.11,4.01-0.55,6.01-0.84c-0.01-0.49-0.02-0.97-0.03-1.46c-2.04-0.11-4.13,0.02-6.1-0.41
				c-2.2-0.47-5.21-0.8-6.26-2.32c-3.38-4.87-8.24-6.3-13.4-6.44c-18.1-0.5-36.2-0.75-54.3-0.79c-4.1-0.01-8.26,0.96-12.27,1.96
				c-1.27,0.32-3.23,2.41-3.04,3.32c0.95,4.71-2.12,5.09-5.2,5.27c-4.31,0.25-8.73-0.31-12.94,0.42c-2.38,0.41-6.34,2.72-6.32,4.12
				c0.09,5.86-3.06,8.22-7.7,10.21c-0.87,0.37-1.63,3.82-1,4.48c7.55,7.93,15.26,15.72,23.16,23.3c6.37,6.1,13.08,11.83,19.6,17.77
				c8.67,7.89,7.55,18.02-2.61,23.73c-15.45,8.68-31.69,15.28-49.02,19.22c-10.26,2.34-20.38,5.26-31.69,8.21
				c2.35,1.03,3.63,1.4,4.69,2.1c1.56,1.03,2.98,2.29,4.46,3.45c-1.74,0.97-3.41,2.16-5.25,2.85c-2.01,0.75-4.2,1.04-7.01,1.69
				c5.24,2.7,9.56,4.75,13.67,7.18c1,0.59,1.76,2.44,1.7,3.67c-0.03,0.66-1.98,1.36-3.16,1.8c-2.69,1.02-5.44,1.89-8.17,2.82
				c-0.14,0.66-0.28,1.31-0.42,1.97c7.59,0.73,10.92,4.48,9.05,11.96c6.49,1.67,12.55,3.3,18.65,4.79
				c19.17,4.68,38.35,9.31,57.52,13.97c9.51,2.31,18.96,4.85,28.51,6.98c14.24,3.18,28.62,5.74,42.79,9.19
				c16.26,3.96,32.32,8.79,48.54,12.91c14.94,3.8,30.02,7.07,44.99,10.77c16.48,4.07,32.95,6.95,50.15,6.54
				c30.59-0.73,61.21-0.22,91.82-0.25c1.42,0,2.85-0.16,5.28-0.31c-5.26-5.97-10.83-9.23-17.84-10.33c-7.2-1.13-14.4-2.43-21.48-4.15
				c-1.57-0.38-3.75-2.98-3.62-4.37c0.14-1.51,2.43-3.47,4.17-4.02c2.62-0.84,5.59-0.48,8.37-0.94c1.15-0.19,2.17-1.16,3.24-1.78
				c-0.79-0.89-1.42-2.3-2.39-2.6c-4.8-1.48-6.99-4.88-7.42-9.58c-0.46-5.05-3.17-7.49-8.03-8.71c-7.54-1.89-14.81-4.95-22.41-6.51
				c-6.74-1.39-13.96-2.66-20.57-1.58c-3.7,0.6-6.74,2.51-10.2-0.55c-1.74,2.66-2.97,5.31-4.9,7.27c-1.82,1.84-4.38,2.95-6.62,4.38
				c-1.11-2.24-2.23-4.48-3.34-6.73c-0.27-0.56-0.5-1.13-0.64-1.45c-2.14,4.47-3.92,9.16-6.58,13.28c-1.23,1.9-4.2,2.67-6.39,3.95
				c-1.05-2.1-2.09-4.2-3.17-6.29c-0.12-0.24-0.46-0.37-0.1-0.09c-2.6,0.63-5.64,2.24-6.63,1.4c-1.51-1.28-2.14-4.23-2.2-6.49
				c-0.12-4.31,0.36-8.64,0.71-12.95C608.58,1130.78,609.29,1122.87,609.98,1114.87z M911.65,807.42c-0.03-0.6-0.06-1.19-0.09-1.79
				c-1.91-0.11-3.81-0.29-5.72-0.33c-17.46-0.37-34.93-0.34-52.36-1.21c-9.39-0.47-18.75-2.23-28.01-4c-1.94-0.37-4.8-3.37-4.76-5.1
				c0.07-3.19-1.65-3.9-3.64-4.35c-5.17-1.19-10.4-2.13-15.64-3c-14.74-2.46-29.5-5.77-44.52-4.19c-13.59,1.42-26.88,0.14-40.15-2.3
				c-4.05-0.75-8.04-2.04-11.91-3.47c-1.07-0.4-2.16-2.18-2.23-3.39c-0.05-0.83,1.48-2.22,2.56-2.58c2.66-0.89,5.44-1.45,8.21-1.93
				c5.31-0.92,10.65-1.68,15.98-2.51c0.17-0.57,0.35-1.14,0.52-1.71c-1.53-1.01-2.94-2.64-4.6-2.93c-5.88-1.05-11.82-1.75-17.76-2.41
				c-12.38-1.37-24.79-2.54-37.16-3.99c-2.06-0.24-3.99-1.47-5.99-2.25c0.09-0.52,0.19-1.04,0.28-1.56c5.15-2.81,11-2.22,16.6-2.51
				c5.79-0.29,11.61-0.06,17.42-0.06c0-0.38,0-0.76-0.01-1.13c-27.3,0-54.6,0-81.91,0c0.99,1.45,2.27,1.84,3.25,2.61
				c1.1,0.86,1.92,2.07,2.86,3.13c-1.07,0.92-2.02,2.38-3.25,2.66c-2.87,0.68-5.87,0.96-8.83,1.11c-14.32,0.71-28.65,1.33-42.98,1.98
				c9.13,1.12,18.25,1.75,27.34,2.59c2.03,0.19,3.99,1.12,5.98,1.7c-0.04,0.95-0.08,1.89-0.13,2.84c-13.51,0.07-26.36,4.77-40.05,3.3
				c2.74,1.37,5.38,2.25,8,3.18c2.66,0.95,5.24,2.54,7.96,2.81c5.28,0.53,10.66,0.04,15.94,0.57c1.92,0.19,4.7,1.69,5.29,3.26
				c1.46,3.87-0.8,5.77-4.81,5.75c-8.59-0.03-17.19-0.01-25.78-0.01c0,0.62,0,1.24,0,1.86c6.92,0,13.85-0.2,20.76,0.07
				c4.62,0.18,9.24,0.88,13.79,1.73c1.01,0.19,1.72,2.01,2.56,3.07c-1.03,0.7-1.98,1.83-3.09,2.02c-2.44,0.42-4.97,0.37-7.65,0.53
				c4.8,2.01,9.24,3.72,13.52,5.77c0.94,0.45,1.98,2.23,1.78,3.13c-0.21,0.94-1.76,1.99-2.89,2.24c-2.25,0.5-4.6,0.57-6.92,0.66
				c-7.87,0.31-15.74,0.56-23.62,0.84c-0.05,0.64-0.09,1.28-0.14,1.92c3.58,0.58,7.15,1.54,10.75,1.67
				c22.76,0.78,45.55,1.01,68.29,2.19c9.55,0.5,19.02,2.75,28.47,4.52c1.63,0.31,2.97,2.21,4.44,3.37c-1.69,1.06-3.3,2.87-5.07,3.04
				c-7.6,0.73-15.26,0.93-22.89,1.42c-4.35,0.28-8.68,0.73-13.02,1.1c1.19,1.45,2.38,1.98,3.63,2.31c1.76,0.47,3.55,1.01,5.35,1.13
				c17.27,1.13,33.93,4.31,48.52,14.43c1.65,1.14,2.97,2.75,4.45,4.15c-1.97,0.94-3.86,2.29-5.93,2.72
				c-3.51,0.73-7.13,0.87-11.8,1.38c6.55,3.74,12.56,2.52,18.31,1.96c14.94-1.45,28.2,3.45,40.85,10.48
				c4.84,2.69,4.54,6.38-0.34,8.96c-2.87,1.52-5.91,2.69-10.28,4.65c3.55,1.47,5.68,2.82,7.97,3.2c7.36,1.21,14.79,2,22.18,3.04
				c7.52,1.06,15.3,1.3,21.36,6.98c0.66,0.62,2.14,0.65,3.19,0.51c9.56-1.2,18.84-0.81,27.76,3.56c1.78,0.87,4.76,0.83,6.52-0.09
				c4.36-2.27,8.33-5.28,12.86-8.27c-1.31-1.63-2.25-3.24-3.59-4.36c-7.89-6.61-15.34-13.94-23.97-19.4
				c-8.7-5.51-11.18-9.34-8.54-20.74c1.23-5.31,3.51-10.39,5.41-15.53c0.9-2.45,2.01-4.82,3.29-7.83c-4.82-0.59-9.13-0.98-13.38-1.71
				c-1.52-0.26-3.61-1-4.2-2.16c-3.34-6.6-9.08-6.66-15.7-6.47c3.86,2.99,2.98,4.64-0.79,5.36c-2.44,0.46-4.92,0.71-7.39,0.99
				c-7,0.78-14,1.81-21.02,2.17c-3.36,0.17-6.71-1.05-6.61-5.16c-4.22-0.56-7.96-1.05-12.11-1.6c2.15-4.22,3.7-7.27,5.23-10.27
				c-4.13-1.09-7.98-2.11-11.83-3.13c-0.14-0.57-0.27-1.14-0.41-1.7c1.14-0.56,2.27-1.11,3.41-1.67c-2.96-0.85-5.61-0.26-7.83-1
				c-2.9-0.96-5.46-2.95-8.17-4.5c2.62-0.96,5.19-2.58,7.86-2.75c7.79-0.5,15.62-0.42,23.44-0.55c13.27-0.22,26.57-1,39.8-0.4
				c7.68,0.35,15.35,2.61,22.82,4.72c2.05,0.58,4.96,3.97,4.71,5.68c-0.52,3.63,1.18,4.77,3.69,5.24c8.14,1.51,16.3,3.29,24.53,3.9
				c9.58,0.71,19.25,0.08,28.87,0.42c12.62,0.45,25.23,1.31,37.86,1.9C908.29,808.05,909.98,807.62,911.65,807.42z M486.33,1197.89
				c-1.02-0.76-1.35-1.16-1.76-1.28c-14.3-4.31-28.6-8.61-42.92-12.86c-6.33-1.88-12.64-3.92-19.07-5.36
				c-12.63-2.83-25.39-5.04-37.99-7.96c-10.97-2.54-21.72-6.05-32.7-8.51c-9.87-2.21-20.05-3.05-29.89-5.39
				c-21.15-5.02-42.21-10.41-63.2-16.04c-11.04-2.96-21.77-7.07-32.81-10.04c-12.17-3.28-24.69-5.29-36.76-8.84
				c-21.45-6.32-42.68-13.41-64.02-20.13c-1.89-0.59-3.88-0.84-5.83-1.25c-0.18,0.52-0.35,1.05-0.53,1.57
				c1.83,0.77,3.59,1.79,5.49,2.26c7.75,1.92,15.65,5.36,17.05,13.31c1.38,7.82,6.79,10.51,11.66,13.97
				c8.53,6.06,9.04,14.11-0.13,19.15c-6.47,3.56-13.64,5.84-21.27,9.02c1.88,4.63,4.04,9.95,6.14,15.13
				c2.31-3.17,4.56-6.24,7.68-10.52c1.36,2.51,2.14,3.95,3.38,6.24c3.05-5.15,5.42-9.15,8.27-13.97c1.94,3.84,3.52,6.98,5.15,10.21
				c1.81-0.59,3.44-1.11,5.07-1.64c-0.49-0.25-0.98-0.49-1.48-0.74c0.53-3.82,0.8-7.71,1.76-11.43c0.32-1.23,2.34-2.19,3.77-2.87
				c0.47-0.22,1.79,0.64,2.29,1.33c1.73,2.34,3.27,4.82,4.96,7.37c-0.9,1.64-2.03,3.71-3.32,6.07c1.69,1.09,3.01,1.94,4.58,2.96
				c2.13-3.72,4.02-7.04,6.51-11.4c0.99,2.33,1.33,3.92,2.22,5.08c3,3.91,6.12,7.75,9.5,11.33c0.28,0.29,2.81-1.82,4.44-2.41
				c0.61-0.22,1.74,0.58,2.46,1.14c0.85,0.66,1.51,1.55,2.57,2.67c1.86-4.74,3.54-9.01,5.22-13.27c0.71,0.05,1.42,0.09,2.12,0.14
				c1.16,7.34,2.33,14.69,3.49,22.03c0.46,0.02,0.93,0.05,1.39,0.07c2.04-6.07,4.09-12.15,6.49-19.27
				c5.82,8.45,5.59,17.15,7.05,25.58c1.6-3.81,2.82-7.67,3.96-11.55c0.88-3,1.34-6.19,2.61-9c1.17-2.59,3.08-2.34,4.22,0.29
				c4.05,9.31,8.03,18.65,12.38,28.79c0.85-0.17,4.3-0.13,4.58-1.05c1.54-5.1,3.02-10.42,3.09-15.68c0.04-3.47-0.74-6.22,3.21-6.75
				c0-2.11-0.34-4,0.09-5.7c0.49-1.96,1.58-3.79,2.56-5.59c0.51-0.93,1.55-2.47,1.98-2.35c1.1,0.3,2.89,1.49,2.8,2.11
				c-0.52,3.95,4.95,6.34,2.46,10.97c-0.81,1.5-0.12,5.06,1.19,6.23c4.39,3.92,2.57,7.81,1.35,12.18c-0.46,1.66,1.01,3.86,1.93,6.91
				c1.19-1.83,1.51-2.1,1.51-2.36c0.02-3.26-1.35-7.62,0.24-9.57c5.3-6.53,5.02-14.23,6.35-21.65c0.25-1.37,1.18-2.62,1.79-3.92
				c0.54,0.08,1.08,0.15,1.62,0.23c0.66,5.02,1.33,10.04,1.99,15.06c0.25,0.01,0.49,0.01,0.74,0.02c1.09-4.28,2.17-8.56,3.26-12.84
				c0.77-0.01,1.53-0.03,2.3-0.04c2.87,11.09,5.73,22.18,8.6,33.27c0.56-3.8,0.31-7.55,0.21-11.31c-0.04-1.72-0.34-4.59,0.44-4.97
				c4.8-2.35,3.46-6.58,4.16-10.42c0.63-3.44,2.67-6.61,4.09-9.91c1.03,0.45,2.63,0.64,2.96,1.4c0.79,1.78,0.83,3.89,1.45,5.77
				c1.44,4.39,3.64,8.62,4.35,13.11c0.41,2.57-0.89,5.29,1.92,7.32c0.38,0.27,0,1.89-0.32,2.78c-1.27,3.44,0.87,5.62,3.36,6.5
				c2.45,0.87,5.58,1.15,6.51-3.02c0.96-4.26,2.55-8.38,3.75-12.59c0.59-2.07,0.71-4.31,1.51-6.27c0.25-0.61,2.07-0.56,3.18-0.81
				c0.73,4.53,1.61,9.04,2.15,13.59c0.36,2.95,0.24,5.96,0.33,8.94c0.39-0.04,0.78-0.09,1.16-0.13c2.95-7.16,5.89-14.33,8.84-21.49
				c0.75,0.04,1.5,0.08,2.25,0.12c1.19,5.2,2.78,10.35,3.46,15.62c0.63,4.89,2.51,6.52,7.61,6.49c34.76-0.24,69.53-0.14,104.29-0.15
				C480.43,1197.89,483.02,1197.89,486.33,1197.89z M774.79,1097.51c1.14,1.98,2.2,3.83,3.27,5.69c-2.34,0.99-4.63,2.16-7.05,2.91
				c-1.56,0.48-3.31,0.35-5.5,0.54c3.41,4.15,4.46,9.65,11.09,8.35c1.54-0.3,3.65,2.35,5.5,3.64c-1.73,1.5-3.27,3.88-5.24,4.31
				c-4.18,0.91-8.57,0.85-12.88,1.08c-4.23,0.23-8.72-0.54-11.09,4.58c-0.46,0.99-2.55,1.28-3.92,1.77c-1.58,0.56-3.2,0.99-4.8,1.48
				c0.06,0.48,0.12,0.97,0.18,1.45c1.71,0.15,3.43,0.48,5.13,0.42c6.81-0.23,13.61-0.86,20.41-0.77c3.98,0.05,10.54-1.5,10.58,4.08
				c0.04,4.77-6.37,3.87-10.34,4.13c-7.13,0.46-14.28,0.61-21.41,1.03c-1.82,0.11-3.61,0.73-5.42,1.12
				c4.29,1.58,8.55,1.72,12.68,2.54c2.98,0.59,5.8,2.03,8.65,3.18c2.16,0.88,4.22,2.6,6.4,2.74c8.45,0.53,16.93,0.48,25.4,0.84
				c3.05,0.13,7.35,0.05,7.08,4.15c-0.1,1.57-4.63,3.74-7.34,4.1c-6.9,0.9-13.92,0.8-20.87,1.38c-2.63,0.22-5.19,1.2-8.76,2.07
				c7.68,6.37,17.54,5.39,23.74,11.54c-0.01,0.61-0.02,1.22-0.03,1.82c-4.43,0.85-8.86,1.71-14.15,2.73c1.68,0.83,2.5,1.35,3.4,1.67
				c13.72,5.01,27.44,10.01,41.19,14.93c1.53,0.55,3.24,0.78,4.87,0.79c32.59,0.06,65.19,0.34,97.77-0.22
				c4.66-0.08,9.26-4.24,13.87-6.55c0.21-0.1,0.22-0.6,0.33-0.91c0.73-2.09,1.45-4.17,2.47-7.1c-2.66-0.67-5.26-1.7-7.93-1.92
				c-4.3-0.35-8.82,0.63-12.92-0.35c-7.92-1.89-15.95-4.04-23.28-7.48c-4.73-2.22-9.17-3.37-14.22-3.41
				c-4.63-0.04-9.37,0.04-13.85-0.91c-2.31-0.49-4.17-3.11-6.23-4.77c2.2-1.11,4.29-2.69,6.62-3.22c3.37-0.77,6.91-0.77,10.38-1.1
				c0-0.49,0-0.98,0-1.46c-2.54-0.4-5.08-1.09-7.63-1.14c-6.98-0.14-13.97,0.07-20.95-0.05c-2.29-0.04-4.7-0.34-6.79-1.17
				c-1.46-0.58-2.49-2.26-3.7-3.46c1.26-0.93,2.4-2.23,3.82-2.71c2.01-0.69,4.21-0.94,6.35-1.1c10.44-0.76,20.89-1.36,31.32-2.16
				c4.52-0.35,9.01-1.06,13.51-1.61c-0.01-0.43-0.02-0.86-0.03-1.28c-1.74-0.19-3.49-0.57-5.23-0.55
				c-9.64,0.09-19.28,0.44-28.92,0.34c-4.94-0.05-9.91-0.61-14.77-1.46c-1.34-0.24-2.36-2.34-3.53-3.59
				c1.46-0.89,2.85-2.41,4.38-2.56c6.28-0.61,12.6-0.72,18.9-1.17c2.86-0.2,5.7-0.77,8.55-1.18c0.01-0.51,0.02-1.01,0.03-1.52
				c-1.78-0.35-3.57-0.67-5.35-1.05c-3.38-0.73-8.45-0.38-9.79-2.45c-3.37-5.22-8-4.83-12.82-5.55c-7.11-1.05-9.07-5.66-5.27-11.5
				c2.22-3.42,0.8-6.61-3.17-7.26c-9.99-1.63-20.01-3.07-29.93-5.04C796.6,1097.02,785.73,1095.34,774.79,1097.51z M611.05,423.68
				c0.92-0.22,1.9-0.3,2.75-0.67c9.69-4.22,19.29-8.66,29.07-12.66c4.1-1.68,8.5-2.98,12.88-3.54c15.26-1.94,30.29-4.83,44.01-12
				c13.57-7.1,27.61-12.41,42.52-15.96c12.24-2.91,24.15-7.19,36.2-10.89c0.76-0.23,1.89-0.53,2.1-1.08c1.74-4.54,5.77-3.63,9.27-4.2
				c4.86-0.79,9.71-1.66,14.52-2.74c5.12-1.15,10.11-3.09,15.28-3.76c10.34-1.34,20.76-2.17,31.17-2.95c2.66-0.2,5.39,0.56,8.09,0.88
				c0.01,0.52,0.01,1.04,0.02,1.55c-2.01,1.73-4.01,3.46-6.02,5.19c0.25,0.55,0.49,1.11,0.74,1.66c14.56-3.79,29.13-7.53,43.67-11.37
				c5.7-1.5,9.86-5.15,13.52-10.49c-1.48,0.52-2.38,0.86-3.29,1.16c-12.95,4.26-25.88,8.31-39.78,8.51
				c-8.93,0.13-17.89,1.04-26.73,2.28c-5.52,0.78-10-0.43-14.6-3.31c-4.58-2.87-9.68-4.9-14.37-7.61c-2.37-1.37-4.48-3.28-6.48-5.19
				c-1.24-1.19-2.05-2.83-3.09-4.33c-1.35,0.8-2.17,1.67-3.05,1.72c-1.79,0.1-4.49,0.4-5.18-0.56c-0.86-1.2-0.76-4.14,0.19-5.32
				c1.29-1.61,3.76-3.14,5.73-3.15c3.2-0.02,6.55,0.85,9.58,2.03c5.04,1.96,12.71,2.66,19.18,0.85c-16.94-6.94-33.8-10.3-51.57-8.13
				c-4.45,0.54-8.94,0.86-13.35,1.61c-4.05,0.68-8.22,1.32-12,2.83c-9.01,3.58-8.93,3.78-2.42,10.43c0.35,0.36,0.85,0.69,0.97,1.12
				c1.2,4.55,5.06,4.49,8.6,5.19c9.45,1.86,18.88,3.8,28.28,5.85c1.35,0.29,2.54,1.35,3.8,2.06c-1.74,1.46-3.18,2.07-4.56,1.97
				c-12.08-0.95-24.32-1.11-36.19-3.25c-27.92-5.04-55.6-11.36-83.51-16.51c-13.19-2.43-26.64-3.44-40-4.88
				c-2.41-0.26-4.94,0.56-7.41,0.88c0.76,1.49,1.47,1.78,2.2,1.98c10.71,2.97,21.39,6.09,32.15,8.85c8.79,2.25,17.62,4.48,26.55,5.98
				c12.91,2.18,25.98,3.37,38.88,5.59c11.9,2.05,23.67,4.88,35.48,7.46c1.79,0.39,3.47,1.3,5.2,1.97c0.01,0.6,0.02,1.2,0.03,1.8
				c-2.19,0.56-4.41,1.71-6.56,1.59c-10.42-0.56-20.85-1.19-31.22-2.34c-8.19-0.91-16.29-2.63-24.44-3.97
				c-9.08-1.5-18.16-2.98-25.58-4.19c-2.32,4.75-3.48,8.98-6.06,11.98c-3.65,4.22-8.34,7.52-12.61,11.2
				c-1.24,1.07-3.34,1.86-3.66,3.13c-1.21,4.78-4.91,4.65-8.53,5.3c-3.68,0.66-7.26,1.83-10.89,2.77c0.11,0.58,0.22,1.15,0.34,1.73
				c1.76,0.13,3.53,0.28,5.29,0.4c4.81,0.32,9.65,0.4,14.41,1.07c1.45,0.2,2.68,1.98,4.01,3.04c-1.15,1.24-2.08,2.91-3.49,3.62
				c-2.2,1.1-4.77,1.42-7.09,2.29c-5.22,1.95-7.35,5.23-6.99,11.05C625.83,408.95,618.99,417.09,611.05,423.68z M361.82,554.37
				c-0.04,0.54-0.09,1.07-0.13,1.61c4.74,1.99,9.52,3.87,14.2,5.98c11.77,5.31,23.56,10.56,35.18,16.18c3.4,1.64,6.22,4.48,9.39,6.65
				c0.72,0.5,1.9,0.91,2.66,0.68c4.05-1.2,7.15,0.19,11.03,1.79c9.1,3.74,18.65,6.95,28.92,5.59c3.37-0.44,6.27,0.08,8.85,3.16
				c1.37,1.64,4.66,1.84,7.15,2.31c3.23,0.61,6.53,0.83,10.88,1.34c2.96-2.08,4.93-2.11,10.23,0.55c1.15,0.57,2.34,1.14,3.58,1.42
				c2.76,0.62,5.56,1.05,8.35,1.56c2.65-0.51,5.36-2.18,7.91,1.77c0.47,0.73,2.26,0.76,3.45,0.81c2.65,0.12,5.34-0.21,7.95,0.1
				c6.13,0.74,12.21,3.53,18.39,0.1c0.59-0.32,1.63,0.07,2.43,0.26c5.59,1.4,11.11,3.15,16.77,4.17c5.77,1.04,11.68,1.33,17.51,2.04
				c2.78,0.34,6.25,0,8.17,1.54c6.3,5.04,12.64,1.12,18.95,1.12c2.22,0,4.48,1.3,6.67,1.17c6.84-0.42,13.64-1.35,20.47-1.85
				c2.76-0.2,5.28,0.37,8.19-1.21c1.98-1.08,5.58,1.31,8.44,1.27c9.67-0.13,19.27-2.23,29.02,0.22c5.42,1.36,11.17,1.5,16.79,1.96
				c1.91,0.16,4.18,0.16,5.77-0.71c6.17-3.4,12.74-2.17,18.85-1.1c5.4,0.94,10.42,2.33,16.06,1.19c4.22-0.85,8.76-0.41,13.13-0.18
				c7.53,0.39,15.14,2.03,22.54,1.32c5.75-0.56,12.26-0.04,17.29-4.82c1.49-1.42,4.65-1.38,7.06-1.47c3.14-0.12,6.3,0.43,9.45,0.46
				c8.72,0.07,17.43,0.03,26.91,0.03c-5.8-2.5-11.22-5.08-16.83-7.11c-1.7-0.61-3.94,0.32-5.94,0.51c-4.17,0.38-8.33,0.78-12.5,1.08
				c-2.41,0.17-5.05-0.45-7.21,0.34c-8.8,3.23-17.8,4.73-26.87,2.26c-10.26-2.8-20.41-5.06-31.13-3.13
				c-1.58,0.28-3.45-0.82-5.15-1.41c-1.23-0.43-2.37-1.46-3.59-1.55c-9.64-0.69-19.29-1.29-28.95-1.75
				c-4.4-0.21-8.82,0.07-13.23-0.04c-4.89-0.12-9.79-0.38-14.67-0.71c-2.13-0.14-4.64,0.05-6.29-0.99
				c-5.78-3.68-12.49-2.2-18.46-2.74c-12.2-1.1-24.66,1.37-36.94-1.39c-2.06-0.46-4.3-0.03-6.44-0.21c-5.99-0.5-11.98-1.03-17.96-1.7
				c-6.69-0.75-13.33-1.93-20.03-2.44c-10.45-0.79-20.95-1.09-31.41-1.82c-1.68-0.12-3.25-1.51-4.93-2.17
				c-2.43-0.95-4.88-1.84-7.37-2.63c-3.17-1.02-6.78-1.28-9.47-3.01c-3.82-2.46-7.22-3.97-12-2.98c-3.79,0.79-7.91-0.02-11.88-0.05
				c-2.63-0.02-5.25,0.12-7.88,0.13c-3.05,0-6.2,0.39-9.12-0.23c-3.98-0.84-7.85-2.3-11.63-3.85c-1.18-0.48-2.64-2.07-2.69-3.21
				c-0.24-5.79-6.44-5.31-8.99-8.81c-1.56-2.15-5.34-2.68-9.25-4.45c-2.5,2.09-6.58,1.83-10.72-0.8c-0.62-0.4-1.62-0.25-2.45-0.25
				c-3.18-0.01-6.87,1.06-9.43-0.19c-4.98-2.43-9.22-6.31-13.96-9.3c-4.32-2.73-5.78-9.64-12.66-8.72c-0.46,0.06-1.04-1.18-1.64-1.73
				c-6.75-6.22-9.58-6.95-16.46-4.28c3.88,4.44,7.67,8.96,11.68,13.27c1.06,1.14,2.8,1.65,4.21,2.47c0.95,0.56,2.1,0.97,2.78,1.78
				c4.98,5.9,9.88,11.87,14.78,17.83c0.77,0.94,1.44,1.95,2.37,3.22c-1.18,0.49-1.9,1.09-2.32,0.93c-5.16-2.01-10.3-4.08-15.4-6.25
				c-1.28-0.54-2.31-1.73-3.6-2.19c-4.23-1.51-8.52-2.83-12.8-4.21C371.19,557.38,366.5,555.88,361.82,554.37z M921.06,472.21
				c-4.17,0-7.97,0.28-11.7-0.11c-2.04-0.21-3.96-1.54-5.93-2.36c1.73-1.32,3.31-3.44,5.23-3.81c4.54-0.86,9.25-0.89,13.81-1.25
				c-8.7-6.3,0.61-9.06,3.54-13.99c-2.53,0-4.25-0.01-5.97,0c-10.5,0.1-21.08,3.67-31.48-1.12c-2.79-1.29-4.74,0.24-6.15,3.22
				c-5.67,11.98-14.2,21.02-25.99,27.66c-8.48,4.77-15.72,11.78-23.43,17.88c-0.85,0.67-1.28,1.92-1.83,2.95
				c-3.66,6.9-7.32,13.81-10.92,20.74c-0.77,1.49-1.31,3.09-2.22,5.28c5.85-1.03,10.77-1.48,15.45-2.81
				c5.68-1.62,11.11-4.1,16.74-5.96c1.58-0.52,3.46-0.15,5.2-0.19c0.02,1.72,0.62,3.73-0.05,5.11c-2.65,5.45-3.31,10.33,2.23,14.41
				c0.13,0.09-0.05,0.6-0.05,0.56c-3.69,1.8-7.26,3.39-10.67,5.25c-4.94,2.69-6.54,2.26-9.06-2.85c-5.57,1.99-11.15,3.98-16.72,5.97
				c-0.32-0.52-0.63-1.04-0.95-1.56c0.99-1.93,1.98-3.87,3.12-6.09c-4.76,2.47-9.14,6.39-13.83,6.8c-7.18,0.63-12.24,4.59-17.89,7.78
				c-10.28,5.8-20.37,11.93-30.66,17.7c-1.98,1.11-4.53,1.19-6.81,1.75c-0.34-0.71-0.68-1.43-1.01-2.14
				c14.96-10.61,30.48-20.59,41.64-35.58c-5.23,3.17-10.46,6.34-15.7,9.51c-0.51-0.62-1.02-1.23-1.53-1.85
				c3.83-4.02,7.65-8.04,11.48-12.07c-2.91,1.27-5.55,2.87-7.96,4.76c-11.13,8.73-22.39,17.3-33.21,26.41
				c-9.25,7.78-19.74,12.84-31.14,16.27c-1.6,0.48-3.38,0.39-5.07,0.56c-0.32-0.47-0.63-0.95-0.95-1.42
				c1.62-1.85,3.02-3.99,4.91-5.49c7.82-6.2,15.8-12.2,23.72-18.29c1.19-0.91,2.37-1.83,3.52-2.79c1.11-0.93,2.18-1.91,3.23-2.84
				c-8.66,4.16-17.21,8.16-25.65,12.38c-4.15,2.08-8.57,4.02-12.05,6.99c-8.56,7.3-18.84,10.33-29.3,13.07
				c-1.67,0.44-3.56,0.06-6.28,0.06c12.1-9.12,23.26-17.52,34.42-25.93c-0.35-0.5-0.71-1-1.06-1.51c-5.82,3.5-11.56,7.15-17.5,10.44
				c-4.92,2.72-10.02,5.13-15.17,7.39c-1.34,0.59-3.15,0.09-5.07,0.09c1.57-3.62,2.78-6.4,3.99-9.18c-0.4-0.19-0.8-0.37-1.2-0.56
				c-10.16,6.27-20.33,12.53-30.71,18.93c3,1.57,5.97,4.47,8.02,3.93c4.78-1.27,8.35,1.24,12.48,1.94
				c6.08,1.02,12.47,0.58,18.41,2.03c9.17,2.25,18.12,3.27,27.37,0.85c2.2-0.58,4.66-0.1,6.95-0.43c9.98-1.43,20.16-2.15,29.87-4.68
				c15.35-4,30.39-9.23,45.55-13.97c1.41-0.44,2.73-1.17,4.14-1.62c9.92-3.21,19.67-8.09,29.82-9.13
				c12.3-1.26,22.13-6.48,31.81-12.98c4.8-3.22,9.34-6.82,15.07-11.04c-4.31,0-7,0-9.7,0c-0.21-0.64-0.43-1.29-0.64-1.93
				c3.92-2.21,7.9-4.32,11.75-6.65c4.37-2.66,9.13-4.97,12.78-8.43c4.24-4.03,7.71-8.97,10.95-13.89c1.01-1.53,0.34-4.29,0.12-6.45
				c-0.06-0.61-14.41-6.65-15.39-6.87c-0.64-0.15-1.41-0.23-1.86-0.64c-0.9-0.81-1.62-1.82-2.41-2.74c0.9-0.62,1.73-1.46,2.72-1.82
				c5.12-1.85,10.34-3.42,15.42-5.38c7.65-2.96,12.82,0.63,12.53,8.78c-0.28,7.93-5.56,15.08-3.55,23.4c0.2,0.83-1.09,1.97-1.54,3.03
				c-0.45,1.06-0.71,2.19-1.14,3.56c12.71-7.43,14.84-20.2,17.95-31.43C911.94,480.97,919.79,477.74,921.06,472.21z M440.95,612.24
				c4.88-5.84,13.54-4.39,16.31-0.29c4.45,6.57,11.35,4.25,16.82,6.47c4.38,1.78,10.13-0.14,15.21,0.27
				c8.88,0.72,17.76,1.64,26.54,3.08c8.17,1.34,15.64,6.31,24.65,3.12c4.01-1.42,8.81-0.61,13.25-0.85
				c4.42-0.23,8.91-1.17,13.23-0.62c10.77,1.38,21.46,3.45,32.19,5.18c3.38,0.54,6.87,1.52,10.18,1.13c4.92-0.58,9.35-0.19,13.79,2.1
				c1.67,0.86,3.84,0.8,5.79,1.02c5.58,0.65,12.03-0.3,16.54,2.24c5.11,2.88,10.6,2.85,15.67,4.89c2.15,0.87,5.04-0.25,7.59-0.24
				c6.32,0.01,12.64,0.02,18.95,0.27c2.96,0.12,5.93,1.25,8.85,1.11c7.28-0.34,14.56-1.82,21.79-1.56
				c6.47,0.23,12.91,2.05,19.31,3.42c3.85,0.82,8.32,1.2,11.22,3.44c4.21,3.25,6.86,1.74,9.77-0.24c-5.26-3.21-10.39-6.37-15.56-9.46
				c-1.03-0.62-2.2-1.1-3.36-1.38c-5.23-1.26-10.66,1-16-1.53c-5.3-2.52-10.56-2.58-16.51,0.46c-3.17,1.61-8.58-1.66-13.12-0.38
				c-4.73-5.63-11.75-1.29-17.24-3.95c-2.47-1.2-5.36-1.54-8.06-2.29c-1.06-0.3-2.79-1.21-3.06-0.89c-3.97,4.76-9.21,1.25-11.87-0.06
				c-8.51-4.2-18.49-2.09-26.72-7.66c-2.97-2.01-8.59,0.06-12.99,0.03c-5.89-0.05-11.88,2.8-17.66-1.14c-3.81-2.59-8.15-3.45-12.85-2
				c-1.65,0.51-3.75,0.37-5.42-0.17c-7.15-2.35-15.05-1.56-21.85-5.53c-1.72-1-4.47-1.54-6.24-0.89c-6.23,2.29-12.12,0.44-18.15-0.5
				c-2.27-0.36-4.62-0.26-6.91-0.5c-2.92-0.31-5.82-0.76-8.73-1.15c0.07-0.73,0.13-1.46,0.2-2.2c-3.28,2.95-6.17,3.17-8.83-0.21
				c-0.4-0.51-1.84-0.15-2.78-0.3c-1.72-0.28-3.64-0.26-5.1-1.06c-3.19-1.74-6.04-3.32-9.53-0.41c-0.78,0.65-2.8-0.04-4.2-0.34
				c-1.7-0.36-3.38-1.42-5.03-1.34c-5.07,0.25-9.5-0.6-12.92-4.83c-0.62-0.76-2.38-0.82-3.61-0.82c-9.43-0.03-18.6-0.5-27.57-4.58
				c-4.48-2.04-9.42-4.19-15.3-3.13c-4.34,0.78-9.32-1.04-13.84-2.32c-8.57-2.42-16.73-6.63-26.08-5.15
				c-3.88,0.61-8.06,2.05-11.64,1.19c-6.86-1.64-13.4-4.63-20.06-7.12c-1.54-0.58-2.99-1.38-4.97-2.31
				c5.68,6.65,10.85,12.72,16.03,18.79c-0.27,0.4-0.54,0.81-0.8,1.21c-1.71-0.66-3.59-1.04-5.09-2.02
				c-6.36-4.12-12.98-7.96-18.8-12.77c-7.97-6.57-17.47-7.94-28.13-10.27c21.6,14.31,39.5,32.35,64.74,37.92
				c3.79-6.47,4.46-6.3,11.79-2.57c3.3,1.68,8.57,3.44,11.05,1.97c5.74-3.4,7.23,0.68,10,3.47c0.92,0.93,2.36,2,3.52,1.95
				c4.33-0.2,8.69-1.5,12.93-1.14C428.22,609.3,434.08,610.91,440.95,612.24z M274.29,779.51c3.56-0.17,7.08-1.43,8.67-0.19
				c3.79,2.94,7.67,6.61,9.59,10.86c1.36,3.01,3.38,3.7,5.25,3.65c1.9-0.04,3.76-1.98,5.63-3.1c1.67-1.01,3.22-2.56,5.02-2.94
				c2.41-0.51,5.01-0.13,7.87-0.13c0.26-0.86,0.4-2.26,1.05-3.35c2.44-4.1,2.32-10.23,9.13-11.03c1.09-0.13,2.48-3.42,2.45-5.23
				c-0.01-1.04-2.52-2.15-4.02-3.04c-1.05-0.63-2.94-0.64-3.32-1.45c-1.55-3.37-3.81-4.19-7.22-3.19c-0.97,0.29-2.21-0.32-3.32-0.5
				c-5.12-0.83-10.22-1.79-15.36-2.43c-6.79-0.84-13.66-1.11-20.39-2.24c-4.96-0.83-9.77-3.8-14.64-3.79
				c-12.41,0.04-24.83,1.04-37.23,2.01c-8.22,0.64-16.11,1.04-24.45-0.94c-12.01-2.85-24.5-3.65-36.78-5.34
				c-2.55-0.35-5.09-0.78-7.64-1.15c-8.41-1.22-17.26-0.29-24.86-5.49c-1.16-0.8-3.21-0.81-4.72-0.52c-9.21,1.8-17.8,2.47-27.23-1.48
				c-8.1-3.39-17.47-6.26-26.93-2c-3.43,1.54-8.43,3.31-11.14,1.92c-5.17-2.65-7.76-0.23-10.73,1.86c4.19,2.07,8.24,4.1,12.33,6.05
				c0.72,0.35,1.68,0.2,2.41,0.53c1.45,0.67,2.99,1.32,4.17,2.34c2.57,2.21,4.62,5.25,7.5,6.77c1.92,1.01,3.72,2.99,7.34,1.66
				c4.37-1.6,9.93-1.25,14.65-0.23c4.93,1.06,9.42,3.86,14.61,0.87c0.79-0.46,2.43,0.57,3.68,0.88c2.37,0.59,4.72,1.35,7.13,1.71
				c5.07,0.76,10.18,1.25,15.25,1.98c3.37,0.49,6.84,2.12,10.02,1.63c5.6-0.85,10.45-0.93,14.82,3.39c0.9,0.89,3.32,0.19,5.02,0.35
				c1.75,0.17,3.49,0.41,5.21,0.77c2.02,0.42,4.88,2.09,5.87,1.31c4.86-3.86,6.65-0.86,7.98,2.57c3.76,0.34,7.07,1.05,10.31,0.82
				c3.37-0.24,7.42-0.49,9.78-2.43c2.31-1.9,4.05-2.86,6.9-2.57c2.6,0.26,5.27-0.18,7.85,0.18c4.48,0.63,9.85,0.36,13.16,2.77
				c5.11,3.73,13.3,6.18,19.3,3.68c1.39-0.58,4.4-0.1,5.04,0.92c2.02,3.21,4.93,3.22,7.36,2.08
				C270.37,776.61,272.99,776.93,274.29,779.51z M382.99,704.34c5.23-1.94,10.51-3.17,11.44-9.83c0.15-1.05,1.93-2.58,2.99-2.6
				c1.06-0.03,2.47,1.35,3.18,2.47c2.61,4.08,5.41,7.62,10.93,7.47c1.23-0.03,2.93,0.31,3.64,1.14c3.12,3.65,6.11,1.71,9.36,0.37
				c1.48-0.61,4.14-1.25,4.76-0.54c2.97,3.36,6.2,4.3,10.42,2.98c1.18-0.37,2.89-0.29,3.9,0.33c3.04,1.87,5.27,0.59,7.59-1.27
				c0.89-0.71,1.91-1.57,2.96-1.71c8-1.05,14.74-9.27,23.82-4.28c0.23,0.13,0.67-0.03,0.97-0.16c4.76-2.04,9.59-3.95,14.22-6.25
				c1.52-0.76,3.83-3.23,3.55-3.77c-1.15-2.23-3.22-5.75-4.82-5.68c-6.9,0.31-10.72-3.13-12.76-7.98c-8,0.11-12.4-5.5-18.14-7.74
				c-3.19,0.05-6.32-0.39-9.42-0.93c-2.72-0.48-5.46-1.66-8.13-1.52c-4.82,0.26-9.63,1.1-14.38,2.05c-4.22,0.85-8.77,1.45-12.38,3.54
				c-3.38,1.96-5.54,2.61-7.98-1.19c-1.76,1.47-3.13,2.96-4.79,3.92c-2.79,1.62-5.63,3.47-8.7,4.21c-4.11,0.99-8.44,1.29-12.69,1.41
				c-6.68,0.18-13.31,5.66-19.77-0.01c-6.06,5.01-12.03,8.92-20.13,6.64c-1.41-0.4-3.22,0.36-4.78,0.79
				c-4.62,1.28-8.91,2.4-13.73-0.4c-6.32-3.66-12.42-7.06-21.02-5.13c-4.75,1.06-10.66-0.86-15.89,1.51
				c-0.42,0.19-1.03,0.21-1.48,0.07c-8.6-2.68-15.77,2.72-21.95,6.24c-7.07,4.04-14.6,3.18-21.52,5.99
				c-4.48,1.82-9.54,2.2-14.34,3.23c0.19,0.75,0.39,1.5,0.58,2.25c1.16-0.34,2.29-0.79,3.47-1.01c1.9-0.36,4.33-1.45,5.65-0.69
				c6.58,3.76,14.44-0.38,20.89,3.71c0.54,0.34,1.81-0.69,2.76-0.73c3.5-0.13,7.05-0.48,10.5-0.07c4.76,0.56,10.03,2.57,13.84-0.98
				c4.07-3.78,7.45-1.87,11.4-0.88c2.36,0.59,4.94,0.53,7.41,0.43c5.05-0.21,10.11-1.11,15.12-0.84c4.9,0.26,9.82,2.36,13.73-2.64
				c0.46-0.59,3.24-0.47,3.51,0.05c2.34,4.59,6.57,3.97,10.19,3.99c1.36-2.39,2.51-4.94,4.14-7.13c1.81-2.43,3.73-2.47,5.23,0.6
				c0.68,1.4,1.77,2.59,2.64,3.84c1.53-2.73,2.66-4.75,3.79-6.76c2.32,1.58,4.68,3.12,6.94,4.79c0.56,0.42,0.79,1.29,1.15,1.9
				C377.33,694.91,377.99,695.28,382.99,704.34z M399.72,426.03c-5.18-1.07-9.03-1.95-12.92-2.61c-0.77-0.13-1.66,0.77-2.53,0.9
				c-2.92,0.43-5.86,0.96-8.79,0.99c-2.23,0.02-4.49-0.55-6.69-1.05c-4.99-1.15-9.68-0.73-14.34,1.59c-1.29,0.64-3.47,0.44-4.83-0.22
				c-6.32-3.06-11.92-2.66-17.49,1.93c-3.4,2.8-6.22,1.47-8.53-2.29c-1.17-1.91-3.47-4.12-5.48-4.35
				c-7.78-0.91-15.66-1.44-23.49-1.29c-2.28,0.04-5.71,2.36-6.54,4.45c-3.54,8.9-6.41,18.08-9.23,27.25
				c-0.41,1.32,0.32,3.96,1.36,4.6c8.8,5.34,17.49,11.04,26.81,15.29c10.01,4.57,20.67,7.64,29.64,14.44
				c2.41,1.83,5.57,3.66,8.39,3.68c3.59,0.02,5.89,0.96,8.08,3.62c1.51,1.82,3.64,3.12,6.28,5.3c-0.26-2.23-0.4-3.33-0.51-4.43
				c-0.73-7.72-1.55-15.43-2.08-23.16c-0.1-1.42,0.99-2.93,1.53-4.39c1.18,0.96,2.59,1.75,3.49,2.93c1.08,1.43,1.73,3.19,2.56,4.81
				c1.68-8.23,7.28-9.97,14.33-9.9c-4.01-4.76-3.38-12.27,1.44-14.17c4.77-1.88,8.07-4.83,10.64-8.86
				C393.82,436.37,396.52,431.47,399.72,426.03z M824.32,722.41c1.97-1.66,3.6-3.84,5.77-4.7c5.77-2.31,7.04-8.24,10.67-12.22
				c2.25-2.47,4.58-4.89,7-7.2c0.88-0.84,2.5-1.07,3.11-2.01c3.69-5.78,3.63-5.83,9.49-2.36c0.84,0.5,1.61,1.11,2.41,1.67
				c7.84-0.25,14.45-7.57,22.42-3.93c2.17-2.57,5.23-4.75,6.12-7.61c1.38-4.44,4.31-4.76,7.83-5.09c0.72-0.07,1.67-0.67,2.01-1.3
				c3.21-6.02,9.81-6.76,14.94-9.76c1.84-1.07,3.68-2.14,5.52-3.21c-1.66-2.12-3.13-4.43-5.03-6.3c-1.69-1.67-3.92-2.79-5.8-4.3
				c-2.32-1.86-4.43-3.99-6.81-5.77c-0.78-0.58-2.74-0.96-3.07-0.55c-3.54,4.41-6.81,1.13-9.61-0.59c-2.94-1.81-5.08-2.07-7.19,0.76
				c-1.76,2.36-3.49,2.47-5.87,0.67c-3.17-2.4-5.99-1.2-8.57,1.44c-2.63,2.7-5.38,5.3-8.23,7.77c-0.77,0.66-2.68,1.28-3.03,0.92
				c-1.64-1.71-3.04-1.77-5.05-0.65c-0.66,0.36-2.1-0.48-3.09-0.97c-3.12-1.54-6.45-4.81-9.2-4.35c-3.34,0.55-6.52,1.95-10.09,1.26
				c-0.39-0.08-1.1,0.74-1.45,1.27c-2.26,3.48-3.03,8.57-8.91,8.07c-0.15-0.01-0.35,0.53-0.53,0.8c-4.55,6.63-9.3,13.13-13.55,19.94
				c-1.77,2.83-3.73,6.54-3.28,9.49c0.5,3.26,3.11,7.85,5.81,8.72c3.45,1.11,4.42,2.73,5.01,5.5c0.65,3.01,1.13,6.05,1.7,9.17
				C820.56,714.67,821.51,715.35,824.32,722.41z M406.11,418.59c-0.97-3.51-2.56-6.96-2.75-10.49c-0.42-7.67-2.06-9.31-9.76-8.81
				c-2.54,0.17-5.66,0.28-7.61-0.98c-4.34-2.82-8.78-3.72-13.74-3.2c-3.55,0.37-5.92-1.07-7.68-4.28c-0.68-1.24-2.66-1.94-4.19-2.49
				c-2.68-0.96-5.47-1.61-9.13-2.65c-1.91,2.16-5.21,3.32-9.6,1.64c-1.27-0.49-3.37,1.56-5.19,2.05c-3.01,0.81-6.37,2.33-9.12,1.66
				c-3.83-0.93-2.59-4.69-1.93-7.64c0.09-0.4-0.14-0.88-0.25-1.5c-6.44,2.85-6.63,16.92-18.06,9.73c-1.12,1.27-1.89,2.92-3.13,3.41
				c-2.89,1.16-6,1.76-9.03,2.59c-0.35-2.84-1.14-5.73-0.87-8.52c0.17-1.77,1.88-3.42,2.99-5.06c1.14-1.68,2.38-3.29,4.1-5.65
				c-7.74,1.78-10.43,4.85-10.91,12.59c-0.35,5.65-3.52,9.24-7.74,12.08c-1.2,0.81-4.54,0.86-4.8,0.3c-2.48-5.49-6.72-4.12-11.02-3.5
				c-4.45,0.64-6.89-2.02-6.4-6.51c0.12-1.08,0.24-2.16,0.36-3.24c-3.99,3.28-6.93,7.24-10.37,10.7c-1.47,1.47-3.93,2.33-6.04,2.57
				c-0.67,0.08-1.67-2.72-1.82-2.98c-13,7.54-26.08,15.14-39.36,22.85c4.93,2.41,10.22,5.51,15.88,7.59
				c4.18,1.54,9.1,3.03,13.25,2.25c7.07-1.33,13.73-4.71,20.69-6.79c7.56-2.26,12.28,1.5,12.13,9.13c-0.01,0.36,0.45,0.72,0.81,1.26
				c3.15-5.4,6.2-10.6,9.75-16.68c0-1.17-1.11-4.89,0.21-6.28c4.02-4.25,8.57-8.38,13.68-11.12c8.18-4.38,9.32-3.39,9.1,6.12
				c4.63-6.27,9.37-11.14,18.53-8.07c-5.27,6.01-9.87,11.25-14.39,16.4c1.73,0,4.42,0.21,7.06-0.04c6.09-0.58,11.97,0.02,15.49,5.54
				c2.41,3.79,4.4,3.84,7.47,1.34c4.39-3.57,9.03-3.94,14.48-2.13c3.03,1.01,6.7,0.13,10.08,0.04c5.62-0.15,11.58-1.58,16.77-0.16
				c4.67,1.27,8,3.38,9.8-2.8C391.78,422.22,391.78,422.22,406.11,418.59z M774.66,560.38c8.96-5.24,17.83-10.46,26.75-15.61
				c1.56-0.9,3.3-1.55,5.02-2.13c3.47-1.18,7.09-1.97,10.44-3.41c3.03-1.3,5.74-3.36,8.69-4.87c1.47-0.75,3.19-1.03,4.8-1.52
				c0.29,0.35,0.57,0.71,0.86,1.06c-1.48,2.45-2.96,4.91-4.78,7.93c6.01-2.17,11.22-4.05,16.44-5.94c0.24,0.48,0.48,0.95,0.72,1.43
				c-0.58,1.6-1.16,3.19-2.27,6.24c4.2-2.58,7.21-4.43,10.21-6.27c-0.52-0.19-1.05-0.37-1.57-0.56c0.81-5.28,1.62-10.56,2.4-15.67
				c-6.15,2.21-12.54,4.79-19.1,6.77c-3.51,1.06-7.31,1.17-11,1.53c-1.87,0.18-3.77-0.03-5.66-0.06c0.04-2.19-0.45-4.57,0.24-6.54
				c1.02-2.94,2.87-5.59,4.01-7.69c-3.42,0.91-6.89,2.2-10.47,2.66c-3.05,0.39-6.3-0.71-9.31-0.21c-4.03,0.67-7.92,2.23-11.86,3.44
				c-4.04,1.24-7-0.24-5.76-3.05c2.44-5.51-3.44-9.06-2.5-14.16c-1.47,3.46-1.91,6.95-2.6,10.38c-0.26,1.31-0.75,2.92-1.7,3.7
				c-3.22,2.66-6.74,4.96-10.07,7.49c-2.89,2.19-5.49,6.06-8.55,6.43c-4.75,0.57-7.64,3.28-11.12,5.59c-6.62,4.4-8.89,4-12.22-0.57
				c-4.91-0.56-8.58-1.12-12.27-1.31c-1.34-0.07-2.89,0.52-4.08,1.25c-4.8,2.95-9.49,6.07-14.22,9.13c-3.89,2.52-6.71,1.52-7.91-2.83
				c-0.29-1.04-0.4-2.14-0.79-4.3c-4.24,5.1-9.05,5.82-14.59,5.92c-3.54,0.06-7.72,1.99-8.37,7.07c-0.13,1-1.89,2.35-3.06,2.53
				c-2.74,0.43-5.61,0.03-8.37,0.38c-2.26,0.28-4.43,1.24-6.67,1.76c-2.67,0.61-5.7,2.13-7.99,1.4c-7.08-2.26-10.37-1.21-13.33,5.65
				c1.39-0.58,2.74-1.25,4.15-1.7c4.94-1.55,6.73-0.22,5.28,4.73c-0.83,2.84-3.06,5.65-5.4,7.58c-2.7,2.23-6.22,3.49-9.38,5.16
				c0.15,0.52,0.3,1.04,0.45,1.56c2.39-0.48,5.11-0.39,7.12-1.54c10.38-5.92,20.53-12.26,30.89-18.22c2.27-1.3,5.08-1.66,7.65-2.45
				c0.09,2.62,0.16,5.25,0.27,7.87c0.01,0.3,0.18,0.6,0.58,1.84c4.43-2.46,8.69-4.85,12.96-7.2c6.39-3.51,12.73-7.13,19.23-10.41
				c1.74-0.88,4.02-0.67,6.06-0.96c0.35,0.77,0.71,1.54,1.06,2.31c-9.45,7.77-18.89,15.55-28.34,23.32c0.24,0.47,0.49,0.95,0.73,1.42
				c21.51-11.77,42.59-24.44,65.35-34.61c0.34,1.83,0.67,2.58,0.48,2.75c-11.26,9.86-22.56,19.68-33.81,29.46
				c0.16,0.07,0.49,0.34,0.73,0.27c1.27-0.35,2.52-0.78,3.78-1.18c12.88-4.13,22.46-13.32,32.83-21.33
				c11.31-8.74,22.73-17.34,34.22-25.85c1.93-1.43,4.43-2.09,6.66-3.11c0.44,0.56,0.89,1.12,1.33,1.68c-2,2.69-4,5.39-6.57,8.84
				c3.27-1.68,5.56-2.98,7.97-4.02c0.77-0.33,1.84,0.01,2.77,0.05c-0.04,1.06,0.19,2.23-0.2,3.14c-0.43,1.03-1.34,1.89-2.17,2.71
				C791.42,543.85,783.05,552.1,774.66,560.38z M670.33,497.97c1.63-0.28,2.94-0.42,4.2-0.75c5.28-1.39,10.4-2.31,16.08-1.46
				c5.1,0.76,10.6,0.07,15.73-0.96c5.63-1.13,11.11-3.2,16.52-5.21c2.29-0.85,4.26-2.57,6.35-3.94c6.09-3.98,12.15-7.99,18.25-11.95
				c0.38-0.24,1.01-0.32,1.45-0.2c8.36,2.21,16.69-3.86,25.21,0.17c4,1.89,8.64,2.44,13,3.55c0.93,0.24,1.96,0.48,2.88,0.34
				c4.48-0.66,8.95-1.44,14.78-2.41c-3.02-3.05-5.42-6.91-8.88-8.63c-5.93-2.95-8.68-8.45-12.5-13.05
				c-4.19-5.04-7.51-10.81-11.65-15.89c-2.42-2.96-7.97-3.12-9.66-0.07c-2.38,4.31-6.08,4.52-9.94,5.39
				c-4.29,0.96-8.46,2.46-12.73,3.52c-1.16,0.29-2.77,0.23-3.72-0.37c-5.25-3.35-9.93,0.22-14.82,0.99
				c-7.03,1.1-6.73,6.89-7.11,11.92c-0.28,3.74-1.18,6.75-4.64,8.77c-1.69,0.99-3.2,2.44-4.48,3.95c-3.9,4.61-8.14,5.15-13.77,2.84
				c-3.03-1.24-7.44-1.3-10.42-0.01C674,477.3,668.87,489.99,670.33,497.97z M91.15,362.95c8.48,3.8,16.39,7.83,24.68,10.84
				c4.03,1.47,9.7,2.82,12.96,1.08c5.87-3.14,11.38-2.59,17.23-2.07c8.07,0.72,16.22,1.06,24.17,2.46c4.05,0.71,5.71-0.39,6.3-3.9
				c1.77-10.54,5.69-20.08,12.58-28.32c0.52-0.63,1.28-1.56,1.13-2.15c-0.32-1.25-1.16-3.38-1.79-3.38c-5.93-0.02-11.52-0.62-16.14-5
				c-0.79-0.75-2.55-0.65-3.86-0.64c-9.57,0.08-19.3-0.76-28.64,0.76c-5.39,0.88-9.93,0.72-14.82-0.92c-1.02-0.34-2.27,0.11-3.39,0
				c-1.43-0.14-3.51,0.02-4.14-0.83c-3.19-4.26-7.74-3.84-12.15-4.17c-7.12-0.55-8.43,0.18-8.46,7.25
				C96.76,343.69,95.99,353.18,91.15,362.95z M556.29,370.68c-9.59,1.9-17.46,5.55-22.25,15.28c-4.31,8.74-10.27,16.72-16,24.67
				c-8.62,11.97-18.93,22.9-26.22,35.6c-9.43,16.45-16.6,34.2-24.46,50.79c7.89,8.53-2.06,13.35-2.91,21.26
				c2.94-2.72,5.51-4.32,6.98-6.62c4.04-6.29,7.8-12.78,11.33-19.37c4.52-8.43,9.74-16.2,17.1-22.5c2.98-2.55,5.86-5.64,7.64-9.08
				c3.53-6.82,8.66-11.09,15.91-13.35c2.37-0.74,4.81-2.39,6.41-4.3c5.77-6.87,10.63-14.59,16.91-20.92
				c6.15-6.21,9.81-12.9,10.96-21.55c0.99-7.49,3.9-14.73-0.27-22.23C556.33,376.37,556.67,373.56,556.29,370.68z M654.95,696.62
				c-3.1,5.5-4.19,12.22-11.88,13.15c-0.9,0.11-2.35,2.51-2.16,3.59c1.12,6.3-3.97,10.17-7.27,12.45c-5.29,3.66-3.66,8.01-4.89,12.4
				c12.37,0,24.19,0,36.26,0c0-1.61-0.15-2.62,0.03-3.58c0.93-4.99,0.55-11.04,3.33-14.63c2.52-3.25,4.35-10.61,11.7-7.74
				c0.34,0.13,1.39-0.58,1.55-1.07c0.97-3.05,3.35-3.38,6.05-4.16c2.45-0.71,4.27-3.37,6.64-4.68c4.06-2.26,8.73-3.69,10.03-9
				c0.03-0.12,0.26-0.26,0.4-0.27c5.62-0.32,8.9-3.95,11.83-8.2c0.45-0.65,1.72-0.82,2.65-1.04c1.86-0.43,3.73-0.87,5.62-1.07
				c1.38-0.14,2.91-0.22,4.17,0.24c4.55,1.64,9.37,1.92,11.8-2.51c2.79-5.08,6.69-6.74,11.08-6.47c1.24-3.54,1.4-7.31,3.33-8.85
				c5.01-3.99,10.91-6.83,16.21-10.5c1.85-1.28,3.17-3.53,4.19-5.61c0.18-0.37-2.24-2.13-3.56-3.1c-0.91-0.67-1.99-1.1-2.09-1.15
				c-6.7,3.39-12.65,6.63-18.82,9.37c-1.76,0.78-4.17,0.12-6.08,0.12c-4.54,2.72-9.21,5.58-13.96,8.3c-0.56,0.32-1.7,0.1-2.33-0.27
				c-3.26-1.94-5.46-0.86-8.17,1.57c-5.32,4.76-6.77,4.43-9.98,0.22c-2.49,1.49-5.11,2.64-7.21,4.41c-3.13,2.64-6.21,4.92-10.54,5.03
				c-0.76,0.02-1.75,1.48-2.16,2.47c-2.73,6.56-4.82,7.77-11.86,6.43c0.06,6.36-3.76,9.27-9.47,10.12c-1.51,0.22-3.16-0.54-4.99-0.91
				C661.65,697.94,659.32,699.35,654.95,696.62z M412.58,668.47c5.23-2.2,9.94-4.21,14.69-6.12c0.51-0.2,1.25,0.23,1.89,0.29
				c1.86,0.16,3.74,0.47,5.59,0.36c4.23-0.24,8.46-0.59,12.67-1.09c1.35-0.16,2.71-0.76,3.91-1.44c2.06-1.15,4.01-2.52,6.01-3.79
				c-2.97-1.15-6.02-1.03-9.06-1.21c-1.4-0.08-2.78-0.76-4.16-1.17c-0.87-0.26-2.01-1.03-2.56-0.73c-3.76,2.06-5.92,0.2-7.03-1.86
				c-4.84-0.82-8.84-1.52-12.84-2.15c-1.1-0.17-2.78-0.67-3.24-0.16c-3.68,4.08-7.84,1.69-10.98,0.1
				c-6.37-3.23-13.19-1.37-19.62-2.97c-7.54-1.87-15.12-3.47-23-2.34c-1.03,0.15-2.23-0.56-3.29-1c-5.94-2.49-11.73-2.85-17.82-0.07
				c-1.92,0.88-4.68-0.1-7.65-0.26c-1.85,2.05-4.31,3.72-7.39,0.32c-0.38-0.41-1.71,0.06-2.59,0.08c-1.43,0.04-3.2,0.61-4.23-0.02
				c-2.52-1.54-4.67-1.85-7.36-0.38c-1.13,0.62-3.29,0.67-4.31-0.02c-7.93-5.35-16.6-1.26-24.73-1.45
				c-7.11-0.17-14.1,1.16-21.11-2.63c-1.41,5.54-5.09,3.86-8.37,3.65c-2.18,1.47-4.53,2.3-6.94,2.81c-2.49,0.52-5.75-0.67-6.61,3.25
				c2.48,0.94,4.78,1.82,7.28,2.77c1.06-4.52,4.35-5.44,8.21-3.53c2.95,1.46,5.34,1.7,8.26,0.06c2.52-1.41,4.76-1.53,6.3,2.11
				c1.19,2.82,3.68,3.75,6.78,1.23c1.18-0.96,4.9-1.05,5.33-0.3c2.22,3.86,5.91,3.83,9.41,4.59c1.94,0.42,3.8,1.19,5.75,1.54
				c1.88,0.34,3.85,0.63,5.73,0.45c3.09-0.29,7.59-2.51,8.94-1.23c4.83,4.62,11.11,2.37,16.26,4.71c1.92,0.87,4.42,1.25,6.47,0.87
				c4.57-0.84,11.07,1.74,13.58,5.83c2.54-0.23,5.32,0.24,7.32-0.8c4.16-2.16,7.14-2.51,9.96,2.03c1.43,2.3,3.83,4.25,6.21,5.62
				c1.98,1.15,4.62,1.79,6.9,1.64c3.74-0.25,7.52-2.2,11.13-1.84c4.95,0.49,7.92-1.86,11.25-4.65
				C406.4,667.14,409.74,664.54,412.58,668.47z M152.61,934.94c-0.4-0.04-0.8-0.09-1.21-0.13c0.2,0.25,0.36,0.65,0.62,0.72
				c14.72,4.08,29.44,8.17,44.19,12.14c1.13,0.3,3.05,0.27,3.65-0.43c4.9-5.71,9.57-11.61,14.9-18.17
				c-8.95-2.38-16.66-4.42-24.37-6.46c-0.06-0.64-0.12-1.27-0.18-1.91c18.33-8.63,36.66-17.27,56.16-26.45
				c-2.73-2.08-4.35-4.22-6.38-4.72c-12.08-2.99-24.24-5.71-36.43-8.26c-2.06-0.43-4.64-0.11-6.55,0.78
				c-13.25,6.19-26.38,12.65-39.56,18.99c-5.56,2.68-11.16,5.29-16.73,7.94c1.02,0.88,1.67,0.88,2.33,0.88
				c10.62-0.08,11.27,0.56,11.26,11.09c0,1.17,0.14,2.35-0.03,3.5C153.79,927.95,153.18,931.44,152.61,934.94z M131.52,865.05
				c1.93,2.38,3.76,4.14,4.98,6.26c1.99,3.45,3.71,7.07,5.31,10.72c1.3,2.98,3.16,4.21,6.54,3.97c8.45-0.6,16.93-1.09,25.4-1.16
				c7.63-0.06,14.71-1.45,21.6-4.97c2.78-1.42,6.83-1.84,9.85-1.03c11.44,3.06,22.91,3.76,34.57,2.15c1.13-0.16,2.65-0.06,3.31-0.74
				c5.57-5.81,12.14-3.13,18.46-2.33c3.02,0.38,5.99,1.54,8.99,1.57c4.97,0.06,9.95-0.46,14.93-0.8c5.9-0.41,11.79-0.88,18.43-1.39
				c-2.14-3.91-4.15-7.13-5.73-10.55c-3.7-7.99-9.8-11.05-18.47-10.19c-2.64,0.26-5.44-0.93-8.13-1.61c-1.8-0.46-3.52-1.64-5.31-1.7
				c-3.98-0.14-7.98,0.05-11.96,0.39c-9.78,0.84-9.99,0.9-9.94,10.96c0.02,4.49-2.23,5.58-5.82,5.29c-4.27-0.35-5.7-3.48-6.16-7.23
				c-0.27-2.22-0.38-4.46-0.56-6.75c-2.28,0-3.93-0.06-5.58,0.01c-25.75,1.04-51.49,2.33-77.24,2.99
				C142.02,859.12,136.56,861.73,131.52,865.05z M480.6,504.67c6.66-0.57,12.28-0.46,17.64-1.62c10.83-2.34,21.48-5.9,32.62-1.6
				c0.85,0.33,1.96,0.22,2.9,0.03c16.66-3.36,33.12-8.56,50.47-3.78c3.5,0.96,7.77,1.04,9.38-4.02c1.55-4.87-1.44-7.49-4.71-9.9
				c-1.14-0.84-2.71-1.2-4.14-1.56c-8-1.99-8.71-3.81-5.54-11.6c1.39-3.41,1.72-7.24,2.53-10.89c-4.01-0.39-8.02-1.09-12.02-1.07
				c-3.42,0.02-6.84,1.26-10.26,1.25c-6.49-0.03-12.64,0.02-18.02,4.83c-1.99,1.78-5.93,2.96-8.41,2.29
				c-8.81-2.37-16.93-0.45-24.77,2.97C493.43,476.47,488.19,490.83,480.6,504.67z M85.83,273.31c-5.73,0-10.2,0.15-14.64-0.09
				c-1.44-0.08-2.83-1.17-4.25-1.79c0.9-1.43,1.48-3.41,2.77-4.18c3.71-2.18,7.64-4.04,11.61-5.71c4.32-1.82,2.93-5.5,2.98-8.6
				c0.02-1.18-0.87-3-1.84-3.46c-3-1.41-11.79,1.44-13.47,4.61c-2.48,4.66-4.28,9.68-6.5,14.5c-1.51,3.27-3.13,6.5-4.87,9.65
				c-2.68,4.87-5.58,9.61-8.24,14.49c-1.81,3.3-3.38,6.74-5.06,10.12c-0.8-0.24-1.59-0.48-2.39-0.72c0.66-4.57,1.37-9.14,1.95-13.72
				c0.74-5.89,2.05-11.82,1.82-17.68c-0.17-4.47-2.35-8.87-3.65-13.3c-1.15-3.91-2.33-7.81-3.7-12.42c-3.1,1.9-5.19,3.63-7.59,4.56
				c-3.62,1.4-4.9,3.92-4.05,7.37c1.1,4.45,2.59,8.81,4.03,13.17c1.63,4.94,3.46,9.81,5.05,14.76c0.27,0.85-0.22,1.95-0.39,2.93
				c-1.69,9.62-3.21,19.27-5.15,28.84c-0.98,4.83,1.84,6.77,5.13,8.97c1.78,1.19,3.19,3.06,4.51,4.82c1.54,2.04,2.32,5,4.26,6.32
				c4.77,3.23,9.99,5.81,15.11,8.49c0.64,0.34,2.11,0.09,2.61-0.44c5.38-5.6,9.54-11.8,10.35-19.9c0.44-4.36,1.65-8.64,2.52-12.95
				c0.43-2.11,0.85-4.23,1.33-6.33c1.14-4.98,2.06-10.03,3.53-14.91C81.28,285.21,83.46,279.86,85.83,273.31z M596.31,487.82
				c3.93-4.71,8.3-5.69,13.48-4.76c2.2,0.39,4.66,0.01,6.87-0.54c15.92-3.97,32.07-5.4,48.43-4.75c1.83,0.07,3.66,0,5.49-0.03
				c3.75-0.07,5.2-2.02,5.14-5.74c-0.05-3.1-0.42-6.72,0.98-9.19c2.71-4.8,3.53-9.35,2.52-14.74c-0.96-5.17-0.96-5.39-6.26-3.95
				c-2.32,0.63-5.64,1.75-7.1,0.7c-6.74-4.86-12.44-2.37-16.83,0.36c-5.24-0.49-9.61-2.16-12.87-0.91c-5.5,2.1-10.58,1.47-16.05,1.13
				c-4.5-0.28-10-0.35-13.43,1.94c-4.7,3.12-9.34,4.48-14.63,5.13c-3.58,0.44-5.77,2.36-6.59,6.33c-1.04,5-2.9,9.83-4.51,14.7
				c-0.93,2.8-0.89,4.93,2.71,5.44C589.1,479.69,593.25,482.47,596.31,487.82z M452.61,876.51c-0.05-0.72-0.1-1.44-0.15-2.16
				c-49.38-7.43-99.12-7.45-149.73-7.8c1.32,2.61,2.02,4.08,2.79,5.52c0.87,1.61,1.79,3.19,2.69,4.78c1.76,3.13,1.23,4.88-2.7,5.29
				c-9.11,0.96-9.1,1.05-9.17,10.24c-0.01,1.17,0.08,2.34-0.04,3.5c-0.34,3.06,1.05,4.08,4.01,4.12c5.48,0.07,10.95,0.59,16.43,0.68
				c7.3,0.13,14.61,0.03,21.91,0.03c-2.42-0.76-4.89-1.05-7.3-1.62c-2.26-0.53-4.44-1.35-6.66-2.04c0.07-0.81,0.13-1.62,0.2-2.44
				c30.96-3.96,62.22-3.36,93.56-4.82c-10.52-2.54-21.48-2.86-31.57-7.63c2.61-1.37,5.22-2.19,7.85-2.24
				c11.14-0.22,22.3,0.15,33.42-0.42C436.33,879.1,444.46,877.55,452.61,876.51z M801.19,232.82c1.44,4.35,2.16,7.77,3.66,10.8
				c2.53,5.1,5.46,10.01,8.43,14.87c1.18,1.92,2.57,4.37,4.44,5.1c12.21,4.75,24.5,9.33,36.91,13.51c9.74,3.28,19.86,5.48,29.48,9.06
				c3.71,1.38,7.8,4.63,9.4,8.1c2.24,4.87,5.51,7.13,10.08,8.65c4.39,1.46,8.78,2.98,13.05,4.75c1.6,0.66,3.34,3.34,4.22,3.01
				c5.81-2.24,9.88,2.18,14.58,3.61c3.76,1.15,5.82,0.73,7.69-2.6c-4.2-2.69-8.34-5.14-12.23-7.95c-1.17-0.84-1.64-2.64-2.44-4.01
				c1.53-0.73,3.01-1.82,4.62-2.11c2.07-0.37,4.25-0.09,6.39-0.09c-0.77-2.33-1.67-3.54-2.88-4.27c-6.69-4.02-13.3-8.22-20.24-11.74
				c-3.54-1.8-7.73-3.19-11.63-3.22c-9.19-0.07-17.64-2.23-25.92-6.07c-9.57-4.44-18.7-10.02-29.8-10.62
				c-4.21-0.23-8.25-2.64-10.51-7.24c3.68-0.35,7.22,0.53,10.64,0.18c10.35-1.06,20.09-0.47,28.18,7.23c1,0.95,2.67,1.43,4.1,1.67
				c11.1,1.82,22.28,2.9,34.24,2.75c-7.5-2.59-15-3.91-21.11-7.57c-9.39-5.61-19.73-6.28-29.85-8.38
				c-9.07-1.88-18.54-2.57-27.17-5.66c-10.72-3.84-22.37-4.99-32.28-11.13C804.76,233.14,803.99,233.24,801.19,232.82z
				M728.77,740.16c2.2-5.23,4.09-10.46,6.56-15.4c2.4-4.82,5.38-9.35,8.11-14.01c2.03-3.46,4.98-3.95,7.99-1.75
				c3.47,2.54,4.62,0.9,6.22-2.15c2.2-4.23,3.61-9.32,9.27-11.35c-5.25-0.19-10.3-0.52-13.05-5.84c-0.31-0.6-1.8-0.64-2.76-0.86
				c-5.43-1.22-11.73,4.67-16.53-2c-0.11-0.15-0.63,0.03-0.95,0c-2.43-0.19-4.85-0.39-7.4-0.6c-2.24,0.75-4.82,1.63-8.06,2.72
				c-1.33,3.63-6.99,7.39-11.89,7.57c-1.96,2.73-3.24,5.69-5.48,7.33c-3.15,2.31-7,3.69-10.59,5.39c-1.74,0.82-4.28,0.98-5.19,2.31
				c-2.55,3.73-5.83,5-9.85,4.34c-0.91,1.58-1.34,3.33-2.45,4.09c-5.38,3.7-4.43,9.44-5.09,14.68c-0.53,4.22,1.3,5.61,5.47,5.03
				c4.09-0.57,8.27-0.85,12.39-0.76C700.08,739.21,714.66,739.73,728.77,740.16z M371.56,678.7c0.24-0.66,0.47-1.31,0.71-1.97
				c-4.83-1.79-6.04-11.72-14.45-5.57c-0.41,0.3-1.29-0.03-1.95-0.09c-0.47-0.04-9.31-4.21-9.92-4.51c-1.05-0.5-2.14-1.15-3.25-1.22
				c-3.23-0.2-6.94,0.85-9.62-0.4c-6.36-2.98-14.48,0.37-19.83-5.8c-0.32-0.37-1.66-0.17-2.35,0.14c-5.36,2.44-10.57,3.01-15.57-0.87
				c-0.23-0.18-0.66-0.06-0.97-0.16c-4.32-1.43-8.63-2.87-13.69-4.56c-3.27,3.01-6.93,2.6-11.06-2.15c-0.55-0.63-2.59-1.03-2.74-0.79
				c-3,4.89-6.09,1.84-9.25,0.32c-1.1-0.53-2.41-0.64-3.74-0.98c-0.38,1.06-0.62,1.66-0.82,2.28c-1.6,4.89-2.42,4.64-6.5,1.5
				c-3.65-2.81-8.27-4.37-12.51-6.38c-0.43-0.2-1.38,0.14-1.79,0.55c-2.93,2.86-8.02,3.63-8.37,9.11c-0.07,1.16-2.18,2.25-3.45,3.26
				c-1.15,0.91-2.98,1.37-3.57,2.51c-4.56,8.87-14.14,3.58-20.5,7.33c-0.39,0.23-1.82-1.82-2.97-2.2c-1.2-0.4-3.14-0.59-3.85,0.09
				c-0.92,0.88-0.94,2.7-1.34,4.08c1.73,1.09,3.16,2.31,4.81,2.99c6.23,2.55,12.3,3.66,18.45-0.81c1.7-1.23,4.71-0.7,7.13-0.87
				c1.04-0.07,2.12,0.29,3.16,0.18c3.26-0.35,6.5-1,9.78-1.23c3.65-0.25,7.91,2.38,10.45-2.78c0.3-0.61,3.41-0.53,4.67,0.19
				c2.23,1.27,4.04,3.28,6.03,4.98c2.77-0.2,5.12,0.72,7.53,1.44c1.6,0.47,3.28,0.91,4.93,0.91c3.08,0,6.61-1.41,9.14-0.33
				c6.62,2.82,12.66,4.04,18.99-0.52c0.95-0.68,3.15,0.03,4.63,0.52c3.46,1.14,6.67,2.7,10.3,0.02c1.1-0.81,3.52-0.43,5.07,0.14
				c2.63,0.97,5.13,2.38,7.53,3.86c5.34,3.31,10.73,4.7,16.89,2.12c1.99-0.83,4.62-0.84,6.78-0.4c6.06,1.24,10.82-0.82,14.57-5.32
				c1.88-2.26,3.41-2.56,5.91-1.09C366.97,677.4,369.35,677.9,371.56,678.7z M819.2,719.85c-3.87,1.02-6.41,0.2-6.76-5.09
				c-0.22-3.39-0.47-7.72-5.61-8.39c-0.69-0.09-1.25-1.43-1.82-2.23c-2.45-3.38-4.88-6.77-7.56-10.49c-2,1.65-3.13,2.89-4.52,3.66
				c-1.97,1.08-4.05,2.24-6.21,2.59c-3.84,0.62-8.61-0.63-11.46,1.24c-4.46,2.92-6.62,0.48-9.81-2.24c-1.99,3.42-3.94,6.45-5.55,9.65
				c-2.57,5.1-4.19,5.84-9.21,3.08c-2.8-1.54-4.41-0.43-5.75,1.78c-2.81,4.65-5.31,9.5-8.33,14c-3.63,5.41-3.28,11.48-3.43,17.86
				c2.19-1.36,3.48-3.24,4.84-5.08c0.84-1.13,1.78-2.18,2.67-3.26c0.9,1.07,1.93,2.07,2.67,3.25c0.7,1.11,1.04,2.44,1.62,3.64
				c1.33,2.77,3.27,2.58,5.08,0.69c0.79-0.83,0.66-2.5,1.39-3.44c1.96-2.51,4.16-4.84,6.26-7.24c1.52,2.37,3.13,4.69,4.53,7.14
				c0.62,1.09,0.81,2.42,1.19,3.65c1.62-2.32,1.85-4.63,2.84-6.54c1.19-2.29,3.03-4.23,4.59-6.32c0.82,2.22,1.48,4.51,2.5,6.63
				c1.07,2.23,2.24,5.96,3.76,6.17c7.29,1.02,14.72,1.08,21.1,1.42c1.89-7,3.4-12.97,5.19-18.86c0.41-1.34,1.83-2.37,2.78-3.55
				c0.96,1.26,2.4,2.38,2.8,3.8c1.27,4.57,3.32,9.36,2.92,13.87c-0.58,6.47,3.04,6.33,7.15,6.47c2.8,0.1,5.6,0.02,9.54,0.02
				c-0.27-2.91,0.05-5.92-0.91-8.43C825.24,732.89,822.23,726.69,819.2,719.85z M572.8,721.64c0.25-0.73,0.6-1.99,1.09-3.2
				c2.45-6.06-2.75-12.88-9.17-11.96c-0.58,0.08-1.17,0.57-1.63,1.01c-5.09,4.87-5.08,4.88-9.13,0.81
				c-6.75-0.31-11.99,7.21-19.04,2.2c-0.18-0.13-0.64,0.21-0.95,0.17c-2.97-0.35-5.93-0.7-8.89-1.13c-4.44-0.65-9.27-2.81-13.19-1.69
				c-4.94,1.42-8.58,1.9-11.37-1.57c-3.06,0-5.54,0.45-7.78-0.08c-7.56-1.81-15.03-4.01-22.56-5.95c-0.8-0.21-1.86,0.11-2.67,0.47
				c-2.65,1.17-5.14,3.18-7.88,3.6c-8.27,1.25-11.68,8.33-16.13,13.65c-2.32,2.78-4.34,4.98-7.6,6.34c-2.43,1.02-4.68,2.48-7.01,3.75
				c0.15,0.52,0.3,1.05,0.46,1.57c1.88,0.12,3.76,0.34,5.64,0.34c36.1,0.02,72.2-0.08,108.29,0.1c6.48,0.03,12.41-0.85,18.12-4.14
				C564.64,724.06,568.45,723.23,572.8,721.64z M33.87,794.97c-0.65,0.67-1.29,1.35-1.94,2.02c1.55-1.48,3.04-3.03,4.67-4.42
				c4.52-3.85,5.11-3.29,8.92,1.52c2.37,2.99,2.33,8.73,7.9,8.56c7.86-0.24,12.39,3.96,15.46,10.63c0.36,0.78,1.85,1.53,2.78,1.5
				c8.59-0.31,17.18-0.68,25.76-1.26c10.07-0.69,20.25-3,30.14-2.05c11.04,1.06,17.05-3.8,21.92-12.25c0.49-0.85,0.88-1.83,1.55-2.51
				c3.83-3.9,3.68-4.1-1.36-6.85c-1.81-0.99-3.12-2.95-4.53-4.58c-0.96-1.12-1.7-2.43-2.54-3.65c-1.24,0.93-3.16,1.62-3.61,2.85
				c-1.38,3.78-2.73,6.97-7.63,7.08c-1.02,0.02-2.17,2.28-2.93,3.69c-1.19,2.18-1.95,4.59-3.17,6.74c-0.65,1.15-1.88,1.96-2.85,2.93
				c-0.97-1.41-2.19-2.73-2.82-4.28c-0.37-0.89,0.2-2.16,0.24-2.49c-3.2-2.47-5.65-4.94-8.58-6.51c-4.6-2.45-8.94-4.66-9.64-10.74
				c-0.09-0.74-3.99-1.74-5.92-1.41c-3.7,0.63-7.22,2.28-10.9,3.16c-1,0.24-2.37-0.64-3.44-1.24c-3.98-2.22-7.9-4.54-12.05-6.94
				c-1.03,3.86-2.58,5.41-6.02,2.03c-3.13-3.08-6.49-2.98-10.55-0.82c-3.31,1.76-7.42,1.99-12.02,3.1c-4.71,2.98-10.34,6.54-15.5,9.8
				C28.43,790.95,31.15,792.96,33.87,794.97z M818.27,772.1c-2.98,0.69-6.07,1.69-9.22,2.03c-3.2,0.34-6.47,0.06-9.71-0.01
				c-2.75-0.07-5.5-0.29-5.59-4.04c-0.06-2.49-0.01-4.98-0.01-7.97c-5.7,0.76-10.64,1.42-15.87,2.12c0.47,1.43,0.73,2.04,0.88,2.67
				c0.58,2.43,1.12,4.88,1.68,7.32c-3.11,0.46-6.22,0.99-9.35,1.33c-1.45,0.16-3.15-0.57-4.37-0.03c-7.83,3.45-15.18,3.3-23.44,0.23
				c-6.26-2.33-13.62-0.09-20.52-3.09c-3.53-1.54-8.83,1.01-13.32,1.71c1.31,1.36,2.63,2.08,4.01,2.22
				c12.2,1.18,24.39,2.55,36.62,3.27c8.42,0.49,16.9-0.21,25.33,0.08c5.12,0.18,10.23,1.05,15.29,1.93
				c9.31,1.62,18.64,3.24,27.83,5.39c2.43,0.57,6.26,3.13,6.24,4.73c-0.07,5.28,3.3,5.43,6.67,5.97c4.58,0.74,9.18,1.64,13.8,1.82
				c20.07,0.78,40.16,1.02,60.21,2.09c7.78,0.41,13.92-1.02,18.3-7.88c1.41-2.22,3.3-4.23,5.29-5.96c5.29-4.6,4.53-10.7,5.24-17.28
				c-1.55,0.64-2.87,0.94-2.86,1.21c0.14,5.1-2.98,3.68-6.04,3.49c-3.07-0.19-7.21-0.56-9.1,1.14c-4.15,3.72-8.69,3.37-13.41,3.69
				c-1.26,0.08-2.52,0.96-3.67,1.66c-1.56,0.95-2.94,2.8-4.54,2.99c-5.39,0.65-10.89,1.23-16.28,0.9
				c-4.63-0.28-10.31-0.21-10.32-6.84c-3.87-0.18-7.32-0.73-10.69-0.39c-2.7,0.28-5.4,1.4-7.88,2.6c-2,0.96-3.55,3.52-5.51,3.78
				c-7.81,1.03-15.8,2.26-23.16-2.98c1.45-7.11,2.95-14.54,4.65-22.87c-3.59,0.27-5.89,0.44-7.19,0.54
				C818.27,764.12,818.27,767.78,818.27,772.1z M943.78,683.92c11.59-5.46,12.31-7.54,5.64-16.45c-0.61-0.81-2.04-0.99-3.07-1.51
				c-1.37-0.7-2.71-1.45-4.07-2.16c-2.02-1.05-4.39-3.34-6-2.89c-3.59,1-6.8,3.39-10.19,5.15c-3.09,1.61-6.25,3.1-9.33,4.71
				c-4.11,2.15-8.31,4.15-12.21,6.64c-3.2,2.04-6.02,4.68-9.01,7.05c-1.88,1.45-3.53,3.41-5.06,5.45c-0.85,1.14-1.47,2.43-2.23,3.64
				c-0.93,1.47-1.89,2.91-2.84,4.36c-1.22-1.09-2.44-2.17-4.34-3.86c-1.42,0.23-4.18-0.01-6.05,1.16c-2.67,1.67-4.29,6.09-8.11,1.68
				c-4.74,3.64-8.14-1.13-10.32-1.67c-3.53,2.6-6.08,4.21-8.3,6.19c-1.88,1.68-3.5,3.7-5.04,5.71c-2.97,3.87-5.5,8.14-8.81,11.68
				c-2.89,3.1-6.6,5.43-9.82,8c2.13,4,3.79,7.14,5.51,10.36c1.46-3.43,2.79-6.55,4.45-10.44c2.07,2.58,3.33,4.16,4.96,6.2
				c1.84-5.76,3.47-10.87,5.1-15.97c0.61,0,1.22,0,1.83,0c1.7,3.98,3.41,7.96,5.14,11.99c5.4-0.66,9-3.88,11.25-9.2
				c0.61-1.43,2.73-2.54,4.39-3.14c5.42-1.94,11.08-3.24,16.39-5.42c6.39-2.62,8.83-1.38,9.5,5.63c0.1,1.03,0.65,2.01,1.1,3.3
				c3.87-5.46,3.87-5.46,9.15-2.72c0.47-0.65,1.04-1.46,1.63-2.26c2.58-3.47,5.1-6.99,7.78-10.39c2.92-3.71,5.9-7.4,9.04-10.92
				c1.4-1.57,3.27-2.72,4.92-4.06c3.67-3.45,7.07-7.15,10.54-10.77c2.81-2.94,4.57-1.82,5.6,1.62
				C943.21,681.71,943.47,682.79,943.78,683.92z M475.18,671.89c3.67-2.55,5.8-0.38,7.49,3.39c1.49,3.33,4.06,4.8,7.95,4.51
				c1.66-0.12,3.78,1,5.09,2.21c4.92,4.53,7.51,4.48,12.21-0.49c1.05-1.11,2.5-2.17,3.94-2.51c3.87-0.92,7.83-1.48,11.76-2.11
				c1.23-0.2,2.5-0.21,3.75-0.3c2.09-0.14,4.54,0.37,6.19-0.55c2.85-1.58,4.58-1.45,6.02,1.51c0.26,0.53,0.74,1.17,1.25,1.33
				c9.11,2.87,18.1-0.41,27.15-0.67c0.42-0.01,0.79-0.63,1.25-0.75c1.98-0.55,3.96-1.28,5.99-1.48c5.46-0.54,10.94-0.85,14.94,4.1
				c0.16,0.19,0.64,0.11,0.95,0.19c2.58,0.7,5.16,1.4,7.81,2.12c2.29-0.11,4.86-0.23,7.43-0.34c4.22-4.01,9.11-7.49,14.15-10.72
				c0.53-0.34,1.95,0.89,3.01,1.17c0.98,0.26,2.38,0.67,3,0.21c1.06-0.78,1.66-2.2,2.46-3.35c-1.72-1.13-3.37-2.37-5.17-3.36
				c-1.98-1.09-4.3-1.66-6.09-2.97c-2.4-1.74-4.47-3.28-7.38-1.04c-3.24,2.5-6.53,4.52-9.09-0.93c-0.2-0.43-1.51-0.39-2.31-0.47
				c-4.21-0.4-8.25-2.49-12.72-0.69c-1.04,0.42-3-1.29-4.46-2.14c-1.95-1.12-3.85-3.4-5.73-3.36c-6.19,0.14-12.43,0.71-18.52,1.84
				c-6.85,1.27-14.16-0.33-20.64,3.7c-1.92,1.19-4.79,0.84-7.23,1.2c-5.62,0.82-11.5,0.35-16.39,4.42c-1.48,1.23-4.62,2.16-6.04,1.41
				c-4.47-2.37-8.4-5.72-12.71-8.42c-1.37-0.86-3.51-1.58-4.91-1.13c-4.94,1.57-9.71,3.68-14.56,5.57c-2.82,1.1-5.66,2.17-8.99,3.45
				C469.48,668.47,472.13,670.06,475.18,671.89z M338.69,979.19c0.64-0.15,1.28-0.3,1.92-0.45c0.95-2.64,1.8-5.32,2.9-7.89
				c0.47-1.09,1.48-1.94,2.25-2.89c0.9,0.99,2.13,1.83,2.63,2.99c1.52,3.55,2.75,7.23,4.49,11.94c3.94-4.92,7.47-8.87,10.45-13.2
				c2.78-4.06,1.99-7.51-2.12-10.32c-3.28-2.24-7.04-3.79-10.25-6.11c-1.7-1.22-3.86-3.46-3.8-5.17c0.21-5.54-2.69-7.27-7.37-7.93
				c-2.12-0.3-4.17-1.07-6.25-1.65c-12.09-3.38-24.18-6.76-37.96-10.61c1.14,6.26,2.07,11.39,3.15,17.37
				c-3.93,7.21-2.37,15.07,3.38,22.94c-5.06,0.5-5.38,3.33-5.59,6.78c-0.27,4.29-1.47,8.51-1.96,12.79c-0.19,1.68,0.08,3.64,0.8,5.16
				c1.01,2.13,2.63,3.96,4.22,6.24c4.1-2.5,7.04-1.52,8.66,3.6c0.53,1.68,2.37,2.95,4.12,5c1.23-5.74,2.04-10.78,3.43-15.66
				c1.1-3.88,1.93-8.6,4.6-11.09c4.61-4.29,7.73-8.72,8.49-14.99c0.25-2.08,1.47-4.05,2.25-6.06c0.7-0.02,1.4-0.04,2.09-0.06
				C335.04,966.34,336.86,972.76,338.69,979.19z M772.59,809.34c8.35-4.33,19.91-1.95,25.19,4.68c0.5,0.62,1.1,1.48,1.77,1.6
				c4.33,0.79,8.69,1.76,13.06,1.88c1.51,0.04,3.51-1.87,4.56-3.4c3.09-4.45,6.69-5.87,10.43-3.16c7.82,5.67,15.4,11.65,23.23,17.3
				c1.51,1.09,3.67,1.26,6.06,2.03c-0.55-6.87,0-7.5,5.81-7.51c4.32,0,8.7,0.67,12.94,0.15c3.79-0.47,7.84-1.51,11.03-3.49
				c3.81-2.38,6.95-3.55,10.9-0.72c0.77,0.55,2.73,0.56,3.36-0.03c2.2-2.04,4.07-4.46,7.06-7.87c-17.5-0.62-33.42-1.17-49.34-1.76
				c-9.12-0.34-18.28-0.36-27.34-1.27c-6.22-0.62-12.41-2.22-18.41-4.04c-1.91-0.58-4.8-3.8-4.45-4.96c1.48-4.86-1.73-5.91-4.73-6.36
				c-10.96-1.67-21.97-3.93-32.98-4.09c-16.75-0.23-33.51,1.1-50.27,1.79c-0.47,0.02-0.93,0.3-2.17,0.73
				c6.39,2.35,12.92-1,18.85,3.17c-1.9,1.55-3.02,2.46-4.3,3.5c3.49,0.8,6.6,1.38,9.6,2.29c0.87,0.26,1.78,1.38,2.04,2.29
				c0.13,0.45-1.16,1.33-1.83,1.98c-1.58,1.52-3.18,3.02-4.73,4.5c4.22,3.35,12.08-2.78,11,8.06c9.3-0.89,18.13-1.74,28.01-2.69
				C774.84,811.74,773.88,810.72,772.59,809.34z M460.21,382.34c-1.16,0.53-2.62,0.76-3.42,1.62c-3.23,3.47-6.11,7.28-9.4,10.68
				c-5.53,5.71-10.44,12.85-17.18,16.31c-6.75,3.46-6.89,8.85-8.82,14.6c3.57-0.6,6.9-1.69,10.19-2.9c2.98-1.09,5.83-2.95,8.88-3.32
				c4.69-0.57,9.5-0.15,14.31-0.15c0.85-4.88,4.6-7.68,11.1-7.25c1.94,0.13,4.57,1.54,5.39,3.13c0.61,1.19-0.92,3.8-2,5.45
				c-1.42,2.19-3.28,4.09-5.01,6.06c-1.96,2.24-3.65,4.93-6.05,6.51c-5.12,3.38-10.66,6.11-15.95,9.23c-1.5,0.88-2.74,2.2-4.64,3.76
				c9.11,2.32,9.76,4.1,6.62,16.17c6.34-6.08,12.15-11.92,18.28-17.4c3.54-3.16,5.45-6.74,6.24-11.44
				c1.17-6.86,2.43-13.86,7.89-19.01c10.06-9.49,18.62-20.12,24.63-32.68c0.56-1.18,1.34-2.42,2.36-3.18
				c6.87-5.14,7.14-12.48,7.06-20.99c-1.62,2.75-2.61,4.84-4.01,6.62c-0.83,1.06-2.41,2.38-3.44,2.21c-0.99-0.16-2.38-2.04-2.43-3.22
				c-0.63-13.7-6.64-28.08,4.73-40.37c0.07-0.08-0.01-0.3-0.12-1.69c-3,0.7-6.57,0.51-8.61,2.19c-6.29,5.16-11.77,5.07-17.29-0.8
				c-0.04-0.04-0.25,0.07-1.08,0.34c1.87,5.36,8.22,9.47,5.7,14.5c2.34,3.92,4.25,6.56,5.56,9.48c1.4,3.13,1.56,6.97,3.44,9.69
				c4.14,6,6.07,12.49,6.11,19.59c0.02,4.14-4.79,10.44-9.04,10.83c-4.25,0.39-9.16,1.77-12.59-3.09c-2.69-3.81-5.5-3.56-8.95,0.12
				C464.35,388.53,462.76,388.29,460.21,382.34z M297.83,922.23c-0.34,0.63-0.68,1.26-1.02,1.89c1.46,0.79,2.84,1.89,4.4,2.34
				c13.73,3.9,27.5,7.68,41.26,11.49c3.22,0.89,7,1.49,6.95,5.84c-0.06,5.22,3.09,7.35,7.1,9.51c13.85,7.46,15.01,12.91,4.27,24.35
				c-5.33,5.68-9.88,10.73-6.02,18.97c0.19,0.41,0.22,1.07,0.04,1.47c-1.56,3.4,0.59,5.05,2.88,6.97
				c11.7,9.84,23.42,19.67,34.89,29.79c8.6,7.58,7.45,16.3-3.16,20.43c-10.32,4.02-21.18,6.63-31.79,9.93
				c-1.51,0.47-3.61,0.92-4.21,2.04c-1.71,3.18-2.78,6.71-4.36,10.75c6.01-1.38,12.04-2.85,18.11-4.16
				c16.23-3.48,31.37-9.7,45.75-17.92c4.6-2.63,4.84-5.16,0.89-8.86c-0.73-0.68-1.59-1.21-2.32-1.89
				c-10.44-9.76-20.85-19.55-31.32-29.29c-4.21-3.92-8.2-8.19-12.89-11.44c-7.08-4.9-7.5-7.48-0.78-13.07
				c0.89-0.74,2.08-1.22,2.76-2.12c2.23-2.94,4.54-5.85,6.35-9.05c4.33-7.67,4.82-17.19,12.05-23.59c3.65-3.23,2.93-6.63-1.97-9.1
				c-8.26-4.17-16.61-8.69-25.46-11.06c-15.68-4.2-31.77-6.84-47.66-10.28C307.6,925.1,302.74,923.56,297.83,922.23z M912.66,224.56
				c0.8-0.96,1.61-1.93,2.41-2.89c-2.62-1.76-5.18-3.63-7.88-5.27c-9.08-5.51-18.08-11.19-27.38-16.31
				c-5.11-2.81-9.55-5.82-12.12-11.31c-0.84-1.78-2.65-3.45-4.44-4.37c-7.82-4.03-15.85-7.67-23.66-11.72
				c-1.28-0.66-2.57-2.58-2.67-4.01c-0.41-5.77-8.96-14.48-14.76-14.44c-4.97,0.04-9.98,0.73-14.91,0.38
				c-10.18-0.72-19.69-3.59-28.96-8.29c-18.4-9.31-37.57-16.81-57.5-22.41c-7.56-2.12-8.23-2.43-11.23,4.74
				c3.56,2.39,6.76,5.48,10.58,6.93c18.61,7.06,37.41,13.62,56.06,20.57c1.66,0.62,2.77,2.74,4.14,4.17
				c-1.66,0.68-3.31,1.37-4.97,2.04c-0.29,0.12-0.6,0.18-1.31,0.39c20.5,9.67,40.48,19.09,60.47,28.52c0.19-0.34,0.39-0.68,0.58-1.02
				c-6.93-4.75-13.85-9.5-20.78-14.24c0.19-0.47,0.37-0.94,0.56-1.4c1.52,0,3.15-0.38,4.52,0.07c5.19,1.7,10.65,3.04,15.36,5.66
				c19.61,10.9,38.99,22.25,58.47,33.39C899.67,217.41,906.18,220.96,912.66,224.56z M15.74,1088.01c-0.37,0.49-0.74,0.98-1.12,1.47
				c3.22,3.24,6.23,6.73,9.72,9.64c4.26,3.55,8.82,6.77,13.46,9.81c3.18,2.08,6.21,4.41,10.79,2.37c6.91-3.08,13.66-1.03,18.56,4.13
				c3.4,3.58,6.86,5.3,11.47,5.31c13.1,0.04,26.2-0.16,39.29,0.18c3.48,0.09,6.91,1.86,10.38,2.77c2.93,0.77,5.85,1.63,8.83,2.08
				c2.45,0.36,3.09-1,2.55-3.4c-2.17-9.8-7.09-13.4-15.71-15.07c-5.12-0.99-8.28-3.27-9.61-8.28c-0.33-1.23-0.76-2.82-1.69-3.45
				c-9.88-6.69-21.2-10.71-32.67-8.63c-7.88,1.43-15.64,3.07-23.66,3.35c-1.95,0.07-4.02,1-5.81,0.59
				c-8.58-1.96-17.05-3.99-25.71-0.24c-1.12,0.49-2.88-0.24-4.26-0.67C18.91,1089.47,17.34,1088.68,15.74,1088.01z M76.27,699.98
				c1.98,0.21,4.19-0.23,5.36,0.67c5.52,4.26,12.68,2.76,16.21,0.02c5.81-4.51,10.51,1.81,15.32-0.82c0.14-0.08,0.55,0.3,0.82,0.49
				c4.02,2.7,10.06,3.17,14.53,1.5c1.25-0.47,2.87,0.06,4.32,0.08c1.89,0.02,4.03,0.58,5.61-0.12c8.27-3.64,16.56-4.17,25.43-2.34
				c3.38,0.7,7.27-1.32,10.96-1.82c2.14-0.29,4.36-0.05,7.09-0.05c1.11-2.25,3.58-1.49,6.34-0.84c4.49,1.06,9.08,1.73,13.63,2.5
				c0.37,0.06,0.97-0.26,1.23-0.59c3.03-3.98,5.25-1.54,6.51,0.1c4.65-0.68,8.42-1.23,12.19-1.8c0.98-0.15,2.04-0.21,2.9-0.64
				c12.38-6.26,27.44-3.49,39.25-11.68c0.25-0.17,0.66-0.1,0.96-0.23c2.71-1.25,5.42-2.53,8.12-3.8c-5.43-1.77-10.8,3.39-15.2-2.5
				c-5.99,1.38-9.62-2.62-11.9-4.92c-3.99,1.18-6.59,2.34-9.29,2.66c-5.04,0.61-10.63-3.53-15.18,1.92c-0.32,0.39-2.08-0.68-3.13-0.6
				c-2.19,0.17-4.4,0.51-6.51,1.09c-1.2,0.33-2.31,1.94-3.33,1.83c-5.79-0.63-11.54-1.69-17.32-2.41c-2.25-0.28-4.69-0.54-6.81,0.04
				c-7.34,2.01-14.51,4.67-21.88,6.51c-3.24,0.81-8.04-1.11-9.97,0.62c-2.8,2.51-5.57,3.38-8.83,4.49c-2.93,1-4.13,0.47-5.61-1.93
				c-0.73-1.17-2.94-2.7-3.73-2.35c-7.59,3.32-16.81,1.53-23.55,7.52c-0.48,0.43-1.88,0.02-2.77-0.25c-2.74-0.85-5.44-1.82-8.21-2.75
				c-2.44,9.18-8.43,11.47-11.1,7.09c-3.58-0.29-6.5-0.53-9.72-0.8C78.28,696.91,77.32,698.37,76.27,699.98z M532.35,445.91
				c3.46,0.92,5.68,1.94,7.93,1.99c2.23,0.05,4.59-0.59,6.69-1.44c3.91-1.58,7.51-4.48,11.52-5.14c8.09-1.34,16.38-1.54,24.58-2.26
				c1.26-0.11,2.68-0.17,3.73-0.77c3-1.72,6.14-3.39,8.72-5.66c7.8-6.87,18.02-11.69,20.13-23.57c0.42-2.38,2.02-4.54,3.02-6.83
				c1.67-3.84,0.63-7.56-2.09-10.08c-1.28-1.19-4.44-0.35-6.75-0.41c-2.8-0.08-5.61-0.05-8.4-0.22
				c-10.92-0.66-21.91-1.59-28.42,10.16c-0.39,0.7-1.16,1.18-1.74,1.77c-8.9,8.93-17.99,17.69-26.63,26.87
				C540.24,434.96,536.66,440.37,532.35,445.91z M793.58,227.2c-0.02-0.4-0.03-0.8-0.05-1.19c-5.44-4.35-11.02-8.55-16.27-13.12
				c-3.07-2.67-6.17-4.02-10.32-3.78c-12.92,0.74-25.85,1.4-38.78,1.68c-17.4,0.38-34.81,0.39-52.21,0.54
				c-3.8,0.03-7.6-0.15-11.39,0.05c-3.28,0.18-6.69,0.32-9.74,1.36c-1.56,0.53-3.37,2.91-3.51,4.6c-0.57,6.62-0.6,13.29-0.65,19.95
				c-0.01,1.03,0.91,2.07,1.41,3.1c2.49-6.91,7.13-9.26,13.64-9.66c16.19-0.98,32.24-3.87,48.6-2.1c5.86,0.64,11.91-0.52,17.88-0.69
				c4.79-0.13,9.58,0.08,14.37-0.01c8.6-0.18,17.21-0.55,25.81-0.7C779.43,227.1,786.51,227.2,793.58,227.2z M270.99,272.65
				c-0.63-0.84-0.83-1.29-1.18-1.55c-8.18-6.09-17.22-7.68-27.15-5.63c-3.01,0.62-6.24,0.56-9.33,0.3c-8.41-0.7-16.81-1.6-25.2-2.55
				c-3.77-0.42-7.64-0.72-11.24-1.81c-4.23-1.27-4.46-3.42-1.18-6.25c0.59-0.51,1.21-1,2.71-2.23c-14.01-4.29-27.01-8.89-40.63-2.04
				c-6.5,3.27-13.2,5.33-20.51,5.33c-1.46,0-3.01,0.32-4.36,0.88c-12.59,5.25-25.76,5.87-39.13,5.17
				c-7.44-0.39-13.01,4.19-19.27,6.97c32.76,1.7,65.35,3.55,97.97,4.05C205.15,273.79,237.85,272.92,270.99,272.65z M210.2,1182.22
				c-0.46,0.35-0.92,0.69-1.37,1.04c-1.77-2.81-3.55-5.62-5.32-8.44c-3.07,3.85-3.17,3.85-7.11,1.3c-4.01-2.6-8.66-4.63-8.82-10.5
				c-0.02-0.66-0.39-1.32-0.6-1.98c-0.51,0.24-1.01,0.47-1.52,0.71c-0.64,2.13-0.94,4.48-2.04,6.33c-0.87,1.47-2.88,3.4-4.13,3.24
				c-1.8-0.23-4.34-1.88-4.84-3.48c-1.12-3.54-1.07-7.44-1.71-11.16c-0.2-1.16-1.05-2.21-1.6-3.31c-0.44,7.47-0.53,14.74-9.37,18.41
				c-1.61-3.64-3.1-7.02-4.75-10.76c-2.62,3.75-5.08,7.26-8.52,12.16c-1.38-2.66-2.21-4.26-3.51-6.78c-1.98,4.29-3.09,7.24-4.65,9.93
				c-2.14,3.7,0.65,6.93,0.56,10.22c-0.22,7.52,0.31,8.73,7.55,8.73c27.6,0.01,55.19,0,82.79,0c4.99,0,9.98,0.15,14.96-0.11
				c1.27-0.07,3.61-1.56,3.54-2.17c-0.96-8.12-2.88-15.96-8.32-22.9c-1.09,5.13-1.74,10.07-3.3,14.71c-0.68,2.02-3.19,4.7-4.99,4.79
				c-3.18,0.16-4.26-2.36-4.06-5.94c0.24-4.29-0.74-8.64-1.25-13.48c-2.84,5.86-5.24,10.79-7.76,15.99
				c-7.75-5.03-4.34-13.06-8.33-18.69C211.22,1174.15,210.71,1178.18,210.2,1182.22z M361.83,625.26c2.32-0.74,4.72-2.4,6.52-1.9
				c3.3,0.92,6.64,2.65,9.26,4.88c5.2,4.41,10.75,7.12,17.7,6.54c1.07-0.09,2.19,0.74,3.32,0.84c2.85,0.27,5.77,0.78,8.57,0.45
				c6.82-0.82,13.34-3.12,20.5-0.51c6.28,2.3,13.14,2.96,19.69,4.57c8.65,2.12,17.37,4.06,25.81,6.83c7.31,2.4,9.83,2.47,13.97-2.92
				c-2.81-0.68-5.24-1.23-7.65-1.87c-1.08-0.29-2.12-0.73-3.17-1.12c-5.28-1.94-10.65-5.72-15.8-5.43c-8.96,0.5-15.2-4.22-22.24-7.81
				c-8.29-4.23-16.31-9.31-25.03-12.26c-4.87-1.65-9.67-2.42-11.79-5.47c-3.22,0.37-6.15,1.57-7.17,0.64
				c-1.94-1.76-3.16-2.76-5.89-1.71c-1.06,0.41-2.86-0.99-4.27-1.69c-1.82-0.9-3.57-1.93-5.4-2.93c-3.25,7.36-3.2,7.25-10.11,4.19
				c-1.2-0.53-3.42-0.15-4.49,0.68c-4.26,3.35-8.03,7.59-14.35,4.52c-0.8-0.39-2.59,0.03-3.22,0.72c-4.22,4.63-8.11,2.19-11.75-0.57
				c-2.95-2.24-4.12-1.81-5.92,1.53c-1.29,2.39-4.25,4.34-6.9,5.4c-2.48,0.99-5.53,0.55-8.78,0.78c3.63,8,9.65,11.09,16.06,10.42
				c6.01-0.63,12.06-3.45,17.47-6.45C353.09,622.09,355.94,621.48,361.83,625.26z M838.46,632.61c-6.76-1.6-12.02-2.84-17.28-4.09
				c-4.89-1.17-9.74-2.54-14.67-3.49c-4.2-0.81-8.59-0.79-12.72-1.81c-4.09-1.01-9.17,3.66-12.37-2.18c-0.15-0.27-0.88-0.28-1.35-0.3
				c-5.57-0.23-11.14-0.4-16.7-0.67c-4.18-0.2-8.36-0.67-12.54-0.75c-5.3-0.1-10.6,0.03-15.9,0.13c-5.62,0.11-11.08-4.81-16.89-0.54
				c-0.29,0.21-1.35-0.84-2.11-1.04c-1.03-0.26-2.42-0.73-3.14-0.28c-4.14,2.57-8.4,2.2-12.95,1.47c-4.81-0.78-9.74-0.75-14.62-1.12
				c-0.65-0.05-1.27-0.47-1.91-0.48c-3.49-0.09-6.99-0.11-10.48-0.15c-1.44-0.02-2.89,0.02-4.32-0.06c-2.26-0.13-4.51-0.45-6.76-0.48
				c-2.15-0.02-4.29,0.29-6.44,0.37c-6.12,0.23-12.24,0.38-18.35,0.66c-0.69,0.03-1.35,0.69-2.03,1.06c2.32,1.37,4.63,1.35,6.83,1.85
				c4.1,0.94,8.08,2.6,12.21,3.16c3.28,0.45,6.72-0.35,10.09-0.45c1.39-0.04,3.41-0.27,4.1,0.51c4.27,4.88,10.45,0.91,15.35,3.69
				c3.47,1.98,8.72-1.68,12.12,2.63c0.12,0.15,0.8-0.24,1.21-0.23c1.91,0.08,3.82,0.23,5.74,0.31c1.65,0.06,3.44,0.46,4.95,0
				c5.18-1.6,10.18-2.48,15.44-0.05c2.63,1.21,6.02,2.79,8.42,2.06c6.29-1.91,11.07-0.8,15.82,3.67c2.06,1.94,6.25,1.72,8.09,3.74
				c2.63,2.9,6.15,3.21,8.37,2.28c6.37-2.68,11.06,0.12,15.55,3.12c5.32-2.09,6.77-9.3,13.53-8.64c3.33,0.33,6.77,1.4,10.01-0.67
				c0.38-0.24,1.23,0.29,1.87,0.43c3.45,0.76,6.9,1.51,10.54,2.3c1.23-4.12,1.33-8.27,7.01-6.58c4.42,1.31,9.16,1.56,13.77,2.2
				C836.28,634.26,836.74,633.71,838.46,632.61z M676.43,922.33c2.99,4.63,6.57,8.98,8.78,13.95c1.88,4.23,4.71,6.16,8.77,7.08
				c3.56,0.81,7.11,1.9,10.72,2.25c7.06,0.69,14.19,1.58,21.24,1.23c9.97-0.49,18.15,4.45,27.11,7.2
				c12.34,3.79,19.6,12.01,23.7,23.66c2.66,7.58,2.55,8.16,10.96,8c11.3-0.22,22.13,1.47,32.74,5.19c2.19,0.77,4.44,1.37,6.65,2.11
				c4.71,1.58,8.61-0.19,12.69-2.57c-3.34-6.58-10.28-4.63-13.47-6.82c-2.92,1.16-4.92,2.78-6.28,2.35
				c-7.75-2.38-15.54-4.82-22.93-8.11c-4.89-2.18-5.02-4.66-1.97-9.83c-11.02-10.77-22.55-20.98-37.51-26.23
				c-11.28-3.95-22.92-6.92-34.07-11.19C707.99,924.65,692.22,920.91,676.43,922.33z M597.79,687.15
				c-3.47-5.18-10.58-7.18-15.85-7.83c-7.87-0.97-16.19,2.43-24.39,3.2c-6.07,0.57-12.76,1.29-18.22-0.68
				c-3.45-1.24-5.59-3.07-8.92-0.57c-0.45,0.34-1.66-0.03-2.38-0.37c-6.37-2.96-12.52,0.48-17.5,2.64
				c-7.95,3.46-14.67,9.77-21.89,14.9c-0.71,0.5-1.3,1.16-1.94,1.75c0.07,0.59,0.14,1.18,0.21,1.77c4.19,0.18,8.38,0.35,12.57,0.54
				c0.66,0.03,1.68-0.1,1.91,0.25c3.56,5.47,7.75,2.98,11.99,1.16c0.83-0.36,1.98-0.13,2.95,0.05c6.67,1.27,12.62,4.72,20,4.42
				c3.7-0.15,7.06,0.09,10.51-1.53c4.19-1.97,8.57-4.09,11.19,0.45c2.94-1.43,5.43-3.64,7.83-3.55c2.92,0.12,5.94,1.89,8.61,3.47
				c4.18,2.47,4.92,2.01,5.94-2.94c0.48-2.3,2.16-4.51,3.77-6.34c1.33-1.51,3.46-2.29,5.08-3.58
				C592,692.19,594.62,689.85,597.79,687.15z M606.28,657.59c-0.03,0.55-0.06,1.09-0.1,1.64c0.93-0.34,2.04-0.47,2.74-1.07
				c3.22-2.77,5.87-2.21,9.24,0.24c3.67,2.66,8.04,4.38,12.21,6.29c0.77,0.35,2.18-0.08,3.02-0.6c5.7-3.53,10.46-2.84,14.45,2.29
				c6.22-0.99,12.1-8.54,19.32-1.41c-0.78-1.8-1.83-3.14-3.17-3.58c-3.25-1.07-6.84-1.27-9.93-2.64c-4.65-2.07-8.96-4.92-13.49-7.29
				c-2.04-1.07-4.56-2.92-6.36-2.46c-6.24,1.59-11.85-0.79-17.67-1.81c-1.64-0.29-3.09-1.57-4.68-2.28
				c-1.43-0.64-2.89-1.42-4.41-1.64c-5.9-0.86-11.84-1.46-17.74-2.33c-1.03-0.15-2.44-0.94-2.8-1.8c-1.37-3.26-4-2.91-6.69-2.93
				c-1.79-0.01-3.6-0.29-5.37-0.63c-4.19-0.8-8.34-1.8-12.54-2.53c-3.59-0.62-7.83,3.93-10.97-1.5c-0.33-0.57-2.8,0.09-4.28,0.19
				c-1.47,0.1-3.04,0.61-4.38,0.26c-9.07-2.41-18.01-5.36-27.16-7.41c-7.23-1.63-14.66-2.64-22.06-3.17
				c-7.32-0.53-14.7-0.12-22.06-0.12c-0.02,0.46-0.03,0.93-0.05,1.39c8.21,2.24,16.48,4.31,24.6,6.84c2.19,0.68,3.95,2.74,5.9,4.18
				c0.7,0.52,1.31,1.42,2.07,1.58c4.42,0.94,8.94,2.63,13.3,2.31c5.32-0.39,8.9,1.58,13.03,4.39c3.55,2.42,7.42,4.87,12.94,3.14
				c3.93-1.23,8.83,0.59,13.29,1.15c0.55,0.07,0.99,0.84,1.52,1.22c3.29,2.39,6.39,5.58,10.94,2.3c0.68-0.49,2-0.55,2.85-0.27
				c4.6,1.47,10.79-0.76,13.35,5.61c0.1,0.24,0.7,0.55,0.79,0.48c4.52-3.6,8.52-1.05,12.8,0.75
				C601.03,657.33,603.76,657.22,606.28,657.59z M642.56,677.92c0.7-1.68,1.56-2.76,1.51-3.79c-0.12-2.33-0.13-4.98-1.29-6.8
				c-0.63-0.99-4.01-1.35-5.27-0.57c-2.08,1.28-3.73,3.54-5.07,5.7c-3,4.81-7.65,6.41-11.15,4.43c-3.51,1.96-6.46,3.61-10.63,5.94
				c-0.71,2.32-1.58,7-5.94,5.14c-3.28,2.21-5.63,3.81-8,5.38c-0.8,0.53-1.92,0.82-2.41,1.55c-3.48,5.16-10.69,7.48-11.29,14.85
				c-0.03,0.43-0.59,0.79-0.81,1.24c-1.64,3.32-4.21,6.53-4.62,10c-0.48,4.03-2.42,4.57-5.56,5.53c-6.17,1.88-12.13,4.46-18.17,6.74
				c0.17,0.74,0.33,1.48,0.5,2.23c18,0,36.01,0,53.88,0c2.06-5.47,4.14-11.56,6.71-17.43c0.68-1.54,3.02-3.49,4.41-3.35
				c3.67,0.36,3.36-1.72,4-4.11c1.99-7.41,4.39-14.71,6.66-22.04c0.14-0.45,0.5-1.14,0.83-1.18c3.63-0.43,3.44-3.48,4.09-5.91
				C635.98,677.54,638.18,675.84,642.56,677.92z M520.42,402.61c-7.39,0.68-9.38,2.07-11.45,9.28c-1.53,5.31-4.45,9.24-8.99,12.03
				c-0.91,0.56-2.35,0.24-3.54,0.33c0.1-1.07-0.04-2.24,0.33-3.21c1.64-4.17,3.65-8.2,5.02-12.45c0.35-1.08-1.39-4.05-1.78-3.98
				c-4.16,0.74-9.21,0.76-12.17,3.21c-7.88,6.53-15.59,13.63-16.21,25.15c-0.15,2.79-0.92,5.59-1.76,8.28
				c-3.53,11.25-7.08,22.15-16.29,30.82c-4.22,3.98-5.95,11.29-7.28,17.42c-0.81,3.74,1.76,8.25-3.51,10.53
				c-0.28,0.12-0.25,0.89-0.42,1.33c-1.07,2.87-2.12,5.75-3.23,8.6c-0.77,1.97-1.77,3.85-2.38,5.87c-0.27,0.89,0.19,1.99,0.31,3
				c1.12-0.34,2.53-0.36,3.31-1.07c12.63-11.52,24.04-24.28,31.1-39.87c10.1-22.31,23.65-42.29,38.16-61.75
				C512.95,411.7,516.52,407.48,520.42,402.61z M427.52,298.42c4.34-2.64,7.53-4.87,10.98-6.59c1.99-1,4.62-0.74,6.55-1.81
				c11.24-6.2,23.6-4.24,35.52-4.84c7.18-0.36,14.46,0.95,21.69,1.56c3.2,0.27,6.39,0.67,9.3,0.98c-1.08-6.51-3.63-12.71-2.7-18.32
				c1.51-9.14-5.02-14.94-6.69-22.6c-0.25,1.32,0,2.6-0.15,3.83c-1.07,8.66-4.48,17.04-3.05,26.02c0.16,1.01-0.38,2.45-1.1,3.2
				c-0.94,0.97-2.34,1.85-3.62,1.97c-0.55,0.05-1.59-1.84-1.88-2.98c-1.43-5.8-2.46-11.71-4.18-17.42c-0.42-1.39-2.93-3.02-4.46-3.01
				c-3.09,0.02-6.21,0.95-9.24,1.76c-4.69,1.25-6.23,3.96-4.57,8.51c1.9,5.19,0.16,9.83-1.75,14.44c-0.36,0.86-1.75,1.86-2.58,1.79
				c-0.75-0.06-1.64-1.39-2.04-2.34c-1.8-4.27-2.95-8.9-5.26-12.84c-3.95-6.73-8.36-13.09-9.94-21c-0.5-2.52-2.7-4.71-4.17-7.11
				c-9.25,7.66-18.04,14.89-17.62,27.87c1.6-2.26,2.6-4.88,4.08-7.19c1.35-2.1,3.21-3.86,4.85-5.77c-2.19,7.18-6.24,13.3-7.64,19.98
				C426.39,283.39,427.52,290.83,427.52,298.42z M711.85,514.84c4.28-1.63,7.6-3.08,11.03-4.15c9.03-2.81,13.97-9.84,17.97-17.63
				c1.42-2.77,0.03-5.26-3.51-4.39c-8.84,2.18-18.02,3.76-26.28,7.37c-7.83,3.42-15.41,2.69-23.34,2.81
				c-5.95,0.09-11.97,1.61-17.75,3.23c-1.55,0.43-2.98,3.44-3.39,5.48c-1.81,9.03-3.43,18.12-4.73,27.24
				c-0.79,5.49-0.88,11.08-1.29,16.79c3.19,0,5.96,0,8.67,0c1.48-6.15,7.11-10.4,13.74-9.72c3.51,0.36,5.28-0.86,6.6-3.69
				c0.99-2.11,2.3-4.11,2.96-6.31c2.02-6.78,4.57-12.76,11.46-16.48C707.34,513.59,709.5,511.62,711.85,514.84z M239.99,801.96
				c-0.11-0.5-0.22-1.01-0.33-1.51c-1.3-0.11-2.84,0.18-3.83-0.44c-0.93-0.58-2.07-2.47-1.75-3.17c1.96-4.31,4.19-8.46,10.05-8.31
				c4.58,0.11,9.19-0.49,13.73-0.12c1.99,0.16,3.85,1.98,5.77,3.04c-1.16,1.35-2.33,2.69-2.71,3.14c3.15,1.56,7.74,2.54,10.27,5.37
				c3.76,4.22,7.81,5.37,12.97,5.15c3.14-0.13,6.31,0.41,9.45,0.36c1.68-0.03,3.34-0.68,5.38-1.14c-1.02-2.47-1.39-4.59-2.61-5.97
				c-1.2-1.36-3.68-1.6-4.85-2.97c-3.9-4.56-7.47-9.41-11.38-14.41c-3.4,2.93-6.5,4.09-9.26-0.17c-0.21-0.33-1.54-0.16-2.23,0.09
				c-4.81,1.75-9.01,1.17-11.94-2.94c-2.58,1.04-5.78,1.3-6.9,2.97c-3.09,4.62-7.88,4.64-12.18,5.13
				c-6.72,0.77-13.28,0.49-19.15-4.49c-2.49-2.11-7.61-0.91-10.49-2.81c-5.52-3.63-11.47-2.27-16.99-2.16
				c-4.41,0.09-7.06,0.12-7.68-4.68c-4.93,2-9.56,4.46-13.31-1.68c-3.08,4.29,0.84,5.55,2.51,7.55c2.89,3.46,6.08,6.7,8.77,10.3
				c1.74,2.32,2.46,7,4.29,7.37c4.92,0.99,5.7,5.02,8.14,7.91c1.2,1.41,3.29,3.11,4.85,2.99c12.41-0.89,24.79-2.16,37.18-3.38
				C237.21,802.85,238.59,802.31,239.99,801.96z M182.75,308.65c-7.42-7.83-3.64-16.77-3.85-25.27c-0.1-4.22-1.98-6.25-6.85-6.3
				c-16.44-0.17-32.86-0.96-49.3-1.38c-1.15-0.03-2.78,0.76-3.41,1.7c-3.61,5.31-7.02,10.75-10.44,16.19
				c-1.37,2.17-2.59,4.44-4.18,7.18c11.93,0.47,21.8,4.46,31.51,10.86c3.16-5.89,8.86-5.03,14.61-4.49c2.43,0.23,4.98,0.03,7.38-0.44
				C166.24,305.14,174.02,305.52,182.75,308.65z M789.63,481.49c-0.49-0.08-0.98-0.15-1.47-0.23c-1.37,3.62-2.41,7.42-4.25,10.79
				c-1.08,1.97-3.44,3.23-5.22,4.81c-1.31-1.77-3.05-3.38-3.87-5.36c-2.51-6.1-5.96-9.27-11.08-8.84c-3.78,0.32-7.64,0.91-11.22,2.11
				c-2.26,0.76-4.58,2.49-6,4.43c-4.09,5.59-7.78,11.49-11.54,17.32c-0.89,1.38-1.75,2.89-2.17,4.47c-1.51,5.66-5.3,9.21-9.8,12.7
				c-3.46,2.68-5.78,6.82-9.23,11.08c4.36-1.18,8.05-3.42,9.74-2.38c3.61,2.22,6.01,1.15,9.17-0.21c1.08-0.47,4.17,0.81,4.37,1.72
				c1.04,4.79,3.48,3.93,6.5,2.21c4.55-2.59,7.82-7.78,14.13-6.91c0.74,0.1,1.88-0.83,2.44-1.58c5.96-7.89,17.68-11.67,17.21-24.16
				c-0.01-0.3,0.19-0.69,0.42-0.89c1.05-0.91,2.17-2.48,3.24-2.46c1.15,0.02,2.83,1.33,3.27,2.47c0.76,1.96,0.74,4.23,1.08,6.62
				c2.6-4.4,5.01-8.46,7.2-12.18c-2.14-0.22-4.45-0.45-6.75-0.68c0.73-2.3,1.43-4.61,2.21-6.88c0.32-0.94,1.06-1.77,1.22-2.71
				C789.51,485.01,789.51,483.24,789.63,481.49z M668.69,442.16c2.6-0.42,5.65-1.19,8.72-1.29c1.33-0.04,3.83,1.22,3.89,2.02
				c0.41,6.15,3.01,12.32-0.71,18.49c-1.57,2.6-1.75,6.05-2.63,9.39c4.49,0,8.43-1.11,11.22,0.22c7.03,3.33,12.01,1.46,16.48-4.13
				c0.71-0.88,1.81-1.44,2.7-2.18c3.44-2.86,4.22-13.68,1.65-17.31c-4.32-6.08-9.61-11.93-12.07-18.75
				c-2.52-6.96-6.59-12.51-10.37-18.51c-3.11-4.94-6.69-6.19-12.01-4c-2.57,1.06-5.43,1.68-8.21,1.92c-3.1,0.28-3.61,1.8-3.79,4.55
				C662.87,422.84,663.84,432.76,668.69,442.16z M114.76,1012.07c2.71-1.68,4.87-4.17,6.82-4.02c6.16,0.49,6.78-4,8.37-7.86
				c0.56-1.37,0.95-2.95,1.9-3.99c6.75-7.43,15.07-11.56,25.25-11.54c1.52,0,3.05,0,5.06,0c-3.41-5.4-5.57-10.78-9.39-14.38
				c-5.37-5.07-12.13-7.78-19.93-4.45c-1.47,0.63-3.51-0.06-5.28-0.21c-6.19-0.5-12.69-3.27-17.84,2.98
				c-1.8,2.18-3.88,5.36-6.16,5.67c-6.03,0.81-9.43,4.44-12.03,9.06c-3.15,5.59,0.38,14.82,6.58,16.7
				C104.92,1002.1,113.33,1002.13,114.76,1012.07z M182.31,1045.2c0.14-3,0.55-4.84,0.24-6.56c-0.59-3.36-0.49-8.03-2.61-9.72
				c-6.82-5.44-9.56-16.11-20.4-16.43c-0.28-0.01-0.63-0.29-0.81-0.55c-2.9-4.07-6.73-2.86-10.77-2.38
				c-3.57,0.42-7.42,0.1-10.91-0.81c-6.4-1.65-11.53,1.9-17.1,3.49c-2.08,0.59-3.88,2.41-5.62,3.89c-1.14,0.97-2.24,2.2-2.85,3.55
				c-0.94,2.09-1.34,4.42-2.17,6.57c-1.49,3.88-0.49,9.32,2.82,10.59c5.28,2.03,10.79,4.14,16.34,4.58
				c5.39,0.43,10.94-1.76,16.42-1.8c7.48-0.05,15.03,0.48,22.44,1.49C172.28,1041.79,177.05,1043.72,182.31,1045.2z M904.32,322.01
				c0.15-0.54,0.3-1.07,0.46-1.61c-5.11-1.42-10.24-2.78-15.33-4.28c-2.12-0.63-5.11-0.95-6.08-2.46
				c-4.83-7.53-11.92-11.1-20.14-13.45c-2.24-0.64-3.97-3.06-5.93-4.67c0.37-0.73,0.73-1.47,1.1-2.2c5.04,1.15,10.4,1.58,15.06,3.62
				c6.17,2.71,12.63,5.24,17.42,10.55c0.99,1.1,3.49,0.85,5.89,1.35c-2.02-4.39-4.09-7.41-4.76-10.72c-0.99-4.92-3.94-7.7-8.17-9.19
				c-7.82-2.75-15.77-5.11-23.66-7.65c-8.03-2.58-16.08-5.1-24.06-7.82c-4.62-1.57-9.14-3.48-13.7-5.23
				c-0.28,0.31-0.55,0.61-0.83,0.92c9.85,8.89,14.33,20.69,18.26,32.71c1.18,3.6,3.05,5.82,6.3,7.73c2.36,1.39,4.8,3.97,5.53,6.51
				c1.13,3.91,3.23,6.11,6.87,6.91c3.34,0.73,6.78,1.42,10.18,1.42c5.18,0.01,9.74,0.52,13.33,5.09c1.31,1.66,4.19,2.45,6.5,2.89
				c6.49,1.22,13.04,2.1,19.59,2.99c1.69,0.23,3.45,0.03,5.17,0.03c0.02-0.48,0.04-0.97,0.06-1.45c-15.69-4.43-31.75-7.96-45.18-18.2
				C881.1,313.17,892.18,320.75,904.32,322.01z M375.84,504.99c8.07-8.05,16.6-15.28,23.54-23.8c9.17-11.26,21.77-12.48,34.43-14.57
				c2.32-0.38,5.01-1.48,6.48-3.17c1.51-1.73,1.79-4.57,2.52-6.95c1.39-4.56,0.57-5.94-4.29-6.42c-4.12-0.41-5.05-2.31-2.78-5.52
				c1.28-1.81,3.12-3.32,4.96-4.61c1.83-1.28,3.96-2.12,6.38-3.37c-3.84-2.54-7.55-4.28-10.29-7c-3.04-3.01-5.86-3.93-9.72-2.24
				c-1.19,0.52-2.55,0.78-3.85,0.9c-10.33,0.89-22.88,9.04-24.15,23.08c-0.23,2.48-0.43,5.02-1.1,7.4
				c-1.39,4.93-2.42,10.13-4.8,14.57c-5.17,9.62-11.14,18.82-16.68,28.25C375.9,502.55,376.02,503.96,375.84,504.99z M553.74,957.6
				c0.02,0.18,0.03,0.37,0.05,0.55c21.3,4.57,42.6,9.13,63.87,13.69c-6.02-13.58,0.14-26.28,6.07-38.95
				c-12.51-2.47-24.61-5.34-36.88-7.12c-7.98-1.16-16.28-1.59-24.27-0.77c-9.15,0.94-11.6,7.86-6.54,15.56
				c1.91,2.91,4.08,5.72,5.55,8.85c1.9,4.05-0.07,7.16-4.52,7.89C555.97,957.49,554.85,957.51,553.74,957.6z M943.91,421.06
				c-13.65-2.48-27.11-3.14-40.42-1.17c-8.89,1.32-17.5,4.54-26.22,6.98c-7,1.96-14.05,3.77-20.93,6.08
				c-9.27,3.12-18.47,6.5-27.57,10.08c-2.9,1.14-6.32,2.65-7.91,5.05c-3.78,5.68-6.6,12-9.82,18.06c0.57,0.49,1.14,0.98,1.7,1.47
				c2.35-1.78,4.77-3.46,7.03-5.36c2.14-1.8,3.8-4.85,6.2-5.56c11.56-3.39,21.6-9.89,32.39-14.81c6.2-2.83,13.52-3.15,19.1-7.84
				c0.73-0.62,2.13-0.45,4.43-0.86c-0.93,3.44-1.64,6.08-2.54,9.42c2.42-0.87,4.46-1.2,6.02-2.25c5.46-3.69,10.67-7.74,16.17-11.37
				c1.26-0.83,3.28-0.49,4.95-0.69c-0.07,1.57,0.37,3.43-0.31,4.67c-1.74,3.19-4.02,6.09-6.01,9.16c-2.09,3.22-0.69,7.32,2.63,7.13
				c7.36-0.43,14.69-1.31,22.04-2.01c-2.95-1.65-6.11-1.89-9.14-2.67c-1.86-0.48-3.5-1.78-5.25-2.71c1.47-1.61,2.7-4.24,4.44-4.64
				c4.47-1.03,9.16-1.32,13.78-1.46C939.6,435.44,942.5,432.86,943.91,421.06z M216.02,1021.39c0-9.28,0.9-19.44-0.23-29.38
				c-1.36-12.02-6.91-19.84-20.91-19.02c-2.51,0.15-5.47,0.25-7.54-0.87c-8.56-4.65-17.67-2.99-26.63-2.91
				c-3.94,0.03-4.58,1.66-2.41,5.04c3.8,5.93,7.6,11.85,11.13,17.34c2.65-0.2,6.22-1.6,8.03-0.38c4.36,2.94,8.94,6.03,8.79,12.82
				c-0.17,7.88,6.2,13.92,13.96,14.85C205.31,1019.47,210.37,1020.47,216.02,1021.39z M535.22,370.02
				c5.79-5.65,11.6-10.09,15.77-15.73c4.08-5.52,6.47-12.28,9.68-18.46c2.26-4.35,4.66-8.63,6.83-12.63
				c-2.43-0.94-4.39-1.34-5.94-2.37c-4.77-3.16-8.95-2.04-12.5,2.65c-1.71,2.26-4.78,3.5-7.24,5.2c-0.27-1.79-0.51-3.58-0.82-5.36
				c-0.05-0.29-0.34-0.54-0.75-1.16c-5.41,1.6-11.01,3.02-16.41,5c-1.58,0.58-3.3,2.6-3.72,4.27c-2.32,9.28-4.3,18.65-6.31,28
				c-1.21,5.66-2.21,11.37-3.4,17.04c-0.42,2.01-1.12,3.97-1.7,5.95c0.35,0.24,0.71,0.48,1.06,0.72
				c5.99-5.08,11.98-10.18,17.98-15.25C530.27,365.79,532.78,364.01,535.22,370.02z M185.86,1096.93c2.21,0.64,4.4,1.35,6.62,1.92
				c62.64,15.84,125.27,31.7,187.92,47.48c67.16,16.92,134.31,33.91,201.56,50.44c7.71,1.89,16.08,1.06,24.14,1.49
				c0.04-0.44,0.07-0.87,0.11-1.31c-10.96-2.61-21.93-5.23-32.89-7.83c-8.55-2.03-17.15-3.88-25.66-6.06
				c-16.38-4.2-32.65-8.89-49.1-12.8c-21.77-5.18-43.71-9.67-65.5-14.74c-17.28-4.02-34.47-8.45-51.69-12.72
				c-18.53-4.59-37.04-9.26-55.59-13.79c-11.12-2.72-22.31-5.18-33.44-7.86c-9.16-2.21-18.26-4.69-27.43-6.86
				c-10.13-2.39-20.51-3.91-30.42-6.96C218.62,1102.46,202.74,1098.03,185.86,1096.93z M648.87,275.36
				c-10.18,1.75-19.46,5.2-26.93,12.88c-1.28,1.31-3.79,2.81-5.19,2.4c-8.61-2.52-16.34-0.11-24,3.37
				c-5.7,2.59-11.17,6.58-17.12,7.52c-9.22,1.46-19.14-1.45-27.45,5.26c-1.05,0.85-2.87,0.69-4.3,1.12
				c-7.76,2.35-13.3,7.27-17.47,15.29c4.05-1.16,7.26-1.89,10.32-3.02c4.15-1.52,7.56-1.94,8.09,4.88c0.88-1.3,1.17-1.71,1.44-2.13
				c4.88-7.73,9.42-8.66,17.57-4.01c1.16,0.66,3.77,0.65,4.53-0.16c4.33-4.63,9.67-6.38,15.75-6.67c0.74-0.03,1.41-0.95,2.19-1.12
				c2.87-0.65,5.75-1.27,8.66-1.7c1.74-0.25,3.97,0.55,5.22-0.28c5.08-3.35,10.59-3.37,16.3-3.35c2.1,0.01,4.96-0.69,6.18-2.15
				c4.24-5.1,10.25-6.76,15.96-8.53C646.76,292.47,651.63,284.43,648.87,275.36z M796.91,236.31c-9.06,0-18.65-0.11-28.25,0.03
				c-14.23,0.21-28.46,0.57-42.68,0.9c-7.12,0.16-14.24,0.47-21.37,0.55c-3.64,0.04-7.31-0.65-10.91-0.36
				c-9.76,0.76-19.52,1.68-29.21,2.98c-2.49,0.33-6.05,1.73-6.88,3.63c-1.91,4.34-4.38,6.74-9.14,6.9c-4.1,0.14-5.35,2.68-4.82,6.43
				c0.27,1.92,0.53,3.83,0.98,6.99c4.07-9.45,12.7-10.32,19.61-12.86c9.24-3.39,19.73-0.39,29.38-3.58c2.39-0.79,5.28-0.07,7.94,0.01
				c5.97,0.19,11.93,0.36,17.89,0.66c3.28,0.17,6.55,0.7,9.83,0.87c8.61,0.44,17.25,1.32,25.84,1.03
				c13.87-0.47,27.71-1.75,41.57-2.66c1.71-0.11,3.44-0.02,5.16-0.02C800.14,243.82,798.69,240.45,796.91,236.31z M243.61,1061.38
				c4.94,2.52,9.88,0.76,14.53-0.81c3.7-1.25,6.84-4.33,10.56-5.3c3.13-0.81,4.27-2.3,5.18-4.98c1.91-5.63,3.96-11.08,1.09-17.13
				c-0.65-1.37,0.51-3.59,0.8-5.43c0.29-1.89,1.24-4.04,0.68-5.64c-2.49-7.13-9.7-11.71-17.48-11.86c-3.83-0.07-7.03,0.61-9.72,3.79
				c-3.46,4.08-7.07,8.17-11.25,11.44c-3.74,2.92-6.74,5.91-7.93,10.59c-0.22,0.86-1.39,1.42-1.89,2.26
				c-1.02,1.73-1.89,3.56-2.3,4.35c6,2.71,11.45,4.93,16.64,7.63c2.11,1.1,4.58,2.97,5.17,5
				C248.07,1056.62,245.4,1058.85,243.61,1061.38z M668.6,688.72c0.4,0.53,0.8,1.06,1.21,1.6c2.11-2.81,4.88-5.34,6.18-8.48
				c1.97-4.78,3.74-5.53,8.14-1.2c1.27-1.65,3.02-3.05,3.58-4.83c1.34-4.27,4.22-5.63,8.31-6.51c2.89-0.62,5.43-2.88,8.33-4.52
				c-0.98-0.78-1.87-1.49-1.61-1.28c-3.68,0.58-6.72,2.05-8.66,1.14c-3.4-1.58-5.47-0.46-8.12,1.16c-1.65,1.02-4.32,2.2-5.67,1.54
				c-4.03-1.95-7.28-0.69-10.88,0.83c-1.64,0.69-4.67,1.2-5.43,0.34c-2.98-3.36-6.31-2.01-9.03-0.77c-2.86,1.31-6.42,3.5-7.33,6.12
				c-1.76,5.1-4.52,7.66-9.09,7.46c-1.24,3.21-1.69,6.26-3.35,8.32c-5.01,6.21-8.19,13.04-8.86,21c-0.51,6.11-2,7.47-8.43,7.5
				c-2.33,6.53-6.74,12.51-5.8,20.6c2.8-0.28,5.25-0.84,7.65-0.68c4.67,0.31,6.92-1.1,6.89-6.3c-0.02-2.49,1.27-6.05,3.15-7.27
				c4.38-2.82,6.88-6.31,8.38-11.22c1.14-3.72,1.08-9.42,7.94-7.98c0.76,0.16,2.28-1.37,2.81-2.44c1.31-2.64,1.94-5.67,3.49-8.13
				c0.77-1.23,3.49-2.59,4.43-2.12c3.4,1.71,3.9-0.31,4.86-2.66c0.57-1.41,1.72-3.53,2.73-3.59
				C665.74,686.25,667.19,687.85,668.6,688.72z M475.12,649.59c0.03,0.37,0.05,0.74,0.08,1.12c-2.11-0.86-4.15-1.98-6.33-2.53
				c-10.32-2.61-20.68-5.08-31.04-7.52c-1.05-0.25-2.26,0.25-3.38,0.19c-1.44-0.08-3.16,0.08-4.25-0.64
				c-5.25-3.51-11.01-4.35-16.64-2.16c-8.5,3.31-16.93,1.11-24.79-0.82c-7.69-1.89-14.62-6.89-20.78-9.96
				c-3.56,0-7.02,1.05-8.54-0.22c-3.03-2.53-5.8-1.57-8.35-0.59c-4.9,1.89-9.6,4.33-14.25,6.8c-6.61,3.52-15.45,2.37-20.8-2.85
				c-2.89-2.82-5.89-5.51-9.01-8.41c-3.32,6.53-10.74,8.63-17.71,5.69c-2.91,7.11-3.67,7.37-9.86,3.22c-0.8,1.03-1.83,1.97-2.36,3.13
				c-0.51,1.12-1.11,2.9-0.6,3.62c0.74,1.02,2.46,1.86,3.75,1.85c4.51-0.04,9.43,0.57,13.45-0.99c8.74-3.39,16.94,3.75,25.45,0.43
				c0.66-0.26,1.75,0.82,2.7,1.05c1.06,0.25,2.37,0.66,3.25,0.27c3.8-1.65,8.24,2.87,11.72-1.28c0.11-0.14,0.72-0.05,0.94,0.13
				c3.15,2.51,6.07,0.94,9.29-0.05c2.56-0.79,5.56-0.8,8.25-0.39c4.36,0.66,8.8,3.22,12.9,2.67c5.66-0.75,10.7-0.83,15.78,1.86
				c1.2,0.64,2.95,0.14,4.35,0.51c6.89,1.82,14.58-1.26,21.06,3.6c1.34,1.01,4.87,1.47,5.45,0.71c2.71-3.58,5.6-1.69,8.57-1.12
				c2.26,0.44,4.78,1.41,6.79,0.82c4.09-1.2,6.63,0.12,8.76,2.91c2.34-0.21,4.5,0.21,6.62,0.76c0.72,0.19,1.69,1.19,1.99,0.99
				c3.83-2.56,6.96,0.29,10.36,1.12c1.23,0.3,2.66,0.55,3.83,0.23C466.27,652.49,470.68,650.99,475.12,649.59z M378.41,508.3
				c6.37,3.42,11.75,6.36,17.21,9.15c0.53,0.27,1.63-0.22,2.31-0.63c5.65-3.42,12.01-6.09,16.72-10.53
				c8.68-8.17,16.37-17.4,24.49-26.16c1.69-1.82,3.38-3.65,5.27-5.25c3.99-3.39,8.85-6.04,11.97-10.07c3.68-4.77,5.9-10.68,8.74-16.1
				c-0.55-0.34-1.1-0.69-1.65-1.03c-5.2,5.06-10.68,9.86-15.51,15.26c-4.14,4.62-9.14,5.66-14.91,6.6
				c-8.22,1.34-16.22,4.05-24.26,6.35c-1.16,0.33-2.1,1.58-3.04,2.51c-5.78,5.71-11.7,11.31-17.24,17.25
				C384.95,499.45,381.97,503.79,378.41,508.3z M731.8,285.25c2.43,0.52,3.95,1.31,5.26,1.04c9.68-1.98,19.29-2.74,28.89,0.4
				c0.84,0.27,1.91-0.15,2.87-0.3c3.71-0.58,7.4-1.49,11.13-1.69c5.5-0.29,11.04-0.07,16.55-0.07c-0.72-1.29-1.88-2.22-3.12-2.34
				c-5.28-0.5-10.58-0.7-15.87-1.12c-1.4-0.11-2.77-0.59-4.15-0.9c0.06-0.41,0.12-0.83,0.18-1.24c1.24-0.18,2.49-0.55,3.72-0.49
				c5.64,0.24,11.27,0.73,16.91,0.85c1.51,0.03,3.04-0.91,4.56-1.4c-0.18-0.44-0.35-0.89-0.53-1.33c-3.05-0.75-6.09-1.49-9.14-2.24
				c0.06-0.46,0.13-0.91,0.19-1.37c2.5,0,5.03-0.23,7.5,0.05c4.69,0.53,7.33-1.01,7.95-5.02c0.81-5.28-1.59-10.36-5.59-10.36
				c-11.76,0-23.53,0.44-35.29,0.83c-6.9,0.23-13.79,0.74-20.69,1.04c-3.06,0.13-6.12,0.02-9.84,0.02
				C735.49,268.41,740.11,276.82,731.8,285.25z M786.4,518.9c2.29-0.9,3.85-1.81,5.51-2.12c5.48-1,11.11-2.82,16.47-2.28
				c3.81,0.38,6.61,0.44,9.43-2.02c2.66-2.31,6.35-4.01,7.94-6.89c6.18-11.19,11.76-22.71,17.43-34.17
				c1.57-3.18,2.69-6.58,4.37-10.77c-9.84,2.49-18.38,5.02-27.09,6.74c-8.73,1.72-13.82,6.44-15.34,15.2
				c-1.09,6.3-4.3,11.46-9.06,15.67C790.15,503.51,788.28,510.66,786.4,518.9z M826.34,440.52c0.64-0.14,1.27-0.21,1.85-0.4
				c16.18-5.34,32.37-10.68,48.54-16.06c5.16-1.72,10.17-4.25,15.46-5.16c8.79-1.51,17.74-2.57,26.65-2.76
				c8.25-0.17,16.52,1.39,24.79,1.43c2.3,0.01,6.1-2.26,6.64-4.2c1.52-5.43-3.61-10.88-9.34-11.18c-18.91-1.01-37.49,0.41-55.51,6.82
				c-16.98,6.04-34.17,11.52-51.05,17.85C825.17,430.3,825.39,430.87,826.34,440.52z M561.51,339.99c5.1-0.44,9.81-0.83,14.52-1.26
				c4.26-0.38,8.52-0.88,12.79-1.19c3.76-0.27,7.71-1.13,11.27-0.34c4.41,0.98,6.5-0.48,8.15-4.17c2.81-6.3,7.86-9.96,14.6-10.6
				c5.2-0.5,10.49-0.1,15.75-0.1c1.21-8.47,2.38-16.6,3.63-25.34c-6.34,1.44-11.74,3.72-16.65,7.54c-2.44,1.9-5.69,4.27-8.38,4.04
				c-5.9-0.49-11.2-0.12-16.11,3.37c-0.77,0.55-1.84,0.79-2.8,0.93c-7.19,1.06-14.42,1.94-21.58,3.17c-1.24,0.21-2.61,1.58-3.3,2.78
				C569.33,325.82,565.45,332.93,561.51,339.99z M315.82,276.3c-8.71-1.32-19.15,0.16-26.09,3.66c-0.96,0.49-2,1.36-2.41,2.32
				c-2.42,5.68-4.8,11.27-9.93,15.27c-0.94,0.73-0.85,3.38-0.45,4.94c0.15,0.6,2.88,1.32,3.77,0.81c2.55-1.45,4.8-3.46,7.1-5.33
				c2.34-1.89,4.61-3.87,6.91-5.81c0.69,0.52,1.38,1.04,2.06,1.56c-1.52,4.02-3.03,8.04-4.55,12.05c2.31-1.5,3.74-3.62,5.65-5.11
				c2.55-1.98,5.52-4.93,8.24-4.86c5.36,0.13,9.26-0.83,12.1-5.65c0.61-1.03,2.9-1.54,4.31-1.38c0.65,0.08,1.11,2.33,1.55,3.63
				c0.24,0.7,0.25,1.47,0.39,2.37c3.69-0.63,7.05-1.06,10.34-1.82c1.99-0.46,3.92-1.29,5.75-2.22c1.74-0.87,3.95-1.71,4.82-3.22
				c2.16-3.77,5.43-5.22,9.14-4.33c1.31,0.31,1.89,3.69,2.94,5.93c6.87,0.32,9.21-3.18,11.22-18.65c-2.26,1.53-3.96,2.61-5.57,3.8
				c-2.03,1.5-4.21,4.81-6.51,1.9c-1.07-1.35,0.59-5.02,1.32-7.55c0.22-0.76,1.66-1.07,2.24-1.83c1.14-1.49,2.07-3.14,3.89-5.97
				c-5.72,0.5-9.67,0.7-13.56,1.31c-0.89,0.14-2.14,1.6-2.25,2.57c-0.42,3.77-2.82,4.31-5.93,4.61c-3.2,0.31-6.94,0.32-9.39,2
				c-5.1,3.5-9.51,8.02-14.2,12.13c-0.38,0.33-0.71,0.86-1.13,0.95c-1.35,0.28-2.74,0.39-4.11,0.57c0.02-1.23-0.21-2.53,0.13-3.67
				C314.12,279.59,315.03,278.04,315.82,276.3z M683.61,940.85c-2.42-3.5-4.28-6.19-6.13-8.9c-0.55-0.81-1.61-1.83-1.43-2.47
				c0.98-3.46-1.57-3.77-3.68-4.54c-12.56-4.57-23.94,1.51-36.67,3.38c3.94,5.65,2.38,10.54,0.5,15.45
				c-2.02,5.27-4.32,10.47-5.88,15.88c-3.64,12.65-1.15,19.78,7.59,24.61c2.4,1.33,3.69,0.86,5.58-0.77
				c7.13-6.16,10.28-13.51,10-23.22c-0.38-13.18,8.74-21.07,21.99-20.43C677.78,939.96,680.06,940.4,683.61,940.85z M662.7,439.61
				c-0.6-8.65-1.15-16.59-1.69-24.54c-0.04-0.66-0.04-1.34,0.04-1.99c0.4-3.2-1.29-4.46-4.1-3.74c-4.94,1.27-10.14,2.22-14.6,4.53
				c-11.05,5.73-22.37,10.49-34.64,12.82c-0.85,0.16-1.59,1.03-2.34,1.62c-3.24,2.52-6.14,5.8-9.77,7.41
				c-6.56,2.9-8.81,7.48-7.78,14.82c3.72-0.83,7.44-1.38,10.95-2.54c2.4-0.8,4.38-3.11,6.78-3.6c5.1-1.04,10.34-1.41,15.54-1.91
				c6.65-0.64,13.32-1.22,19.99-1.68C648.52,440.31,655.97,439.98,662.7,439.61z M742.3,650.81c-0.1-0.66-0.19-1.31-0.29-1.97
				c-3.07-1.24-6.07-2.77-9.24-3.65c-3.41-0.94-6.98-1.72-10.49-1.78c-8.21-0.13-16.43,0.34-24.65,0.3c-3.79-0.02-7.57-0.64-11.35-1
				c-0.81-0.08-1.62-0.4-2.4-0.32c-6.09,0.64-12.45-2.38-18.33,1.34c-0.37,0.23-1.01,0.21-1.47,0.11
				c-8.16-1.87-16.33-3.76-24.48-5.67c-7.07-1.66-14.13-3.37-21.2-5.05c-0.14-0.03-0.31,0.16-0.46,0.14
				c-6.9-0.67-13.82-1.23-20.7-2.07c-4.51-0.55-9.06-2.44-13.44-2.04c-8.27,0.75-15.65-4.52-24.4-1.95
				c-5.68,1.67-12.4-0.2-18.65-0.49c-0.04,0.48-0.08,0.95-0.11,1.43c1.22,0.47,2.42,1.31,3.65,1.36c6.02,0.24,12.07-0.04,18.05,0.49
				c4.4,0.39,8.71,1.76,13.06,2.68c1.42,0.3,2.87,0.81,4.28,0.76c3.9-0.16,7.62-0.31,10.14,3.59c0.49,0.76,1.99,1.08,3.08,1.23
				c6.23,0.87,12.53,1.35,18.69,2.57c2.34,0.47,4.22,2.93,6.56,3.85c7.51,2.95,16.1-0.35,23.47,3.85c0.42,0.24,1.24-0.33,1.89-0.36
				c7.08-0.41,14.4-2.1,21.19-0.86c10.01,1.84,19.88,3.99,30.11,3.85c2.38-0.03,5.25,1.18,7.03,0.21c3.72-2.03,6.26-0.43,9.11,1.31
				c1.06,0.65,2.24,1.25,3.44,1.51c5.44,1.17,10.92,2.22,15.99,3.23C734.43,655.14,738.37,652.98,742.3,650.81z M103.39,1058.08
				c9.38,4.59,17.98,8.84,26.64,12.98c1.14,0.54,2.58,0.74,3.85,0.64c9.03-0.7,18.05-1.53,26.2-2.23c-0.91-0.95-3.02-2.24-2.87-3.16
				c0.31-1.91,1.42-4.67,2.9-5.23c6.65-2.53,13.54-4.42,20.36-6.51c3.5-1.07,7.01-2.07,11.13-3.28
				c-22.59-8.36-44.63-12.54-67.57-5.65c2.65,6.18,1.46,8.01-5.19,7.48C113.23,1052.66,108.51,1054.42,103.39,1058.08z M650.92,393.3
				c-0.06-0.45-0.13-0.9-0.19-1.35c-2.28-0.24-4.55-0.59-6.83-0.7c-4.31-0.21-8.65-0.1-12.94-0.54c-1.4-0.14-3.47-1.38-3.76-2.5
				c-0.28-1.08,1.01-3.34,2.17-3.89c3.38-1.6,7.03-2.66,10.61-3.83c2.01-0.65,4.59-0.51,6.03-1.75c3-2.56,5.41-5.81,8.49-9.26
				c-3.21-3.85-8.11-3.18-12.74-2.91c-6.61,0.37-13.22,0.9-19.81,1.61c-5.59,0.6-11.02,2.87-16.8,1.19
				c-0.76-0.22-1.82,0.59-2.73,0.94c-3.48,1.3-6.98,2.53-10.42,3.92c-1.88,0.76-4.44,1.35-5.3,2.83c-2.28,3.91-3.8,8.27-5.4,11.94
				c8.54,0,16.77,0.01,24.99-0.01c1.33,0,2.78,0.13,3.96-0.35c5.65-2.29,8.48,0.31,10.9,5.23c2.17,4.41,0.79,8.02-0.95,11.86
				c-1.01,2.23-1.88,4.52-3.24,7.81c6-2.85,12.39-3.83,14.79-7.46C636.55,398.8,642.48,394.64,650.92,393.3z M295.99,943.09
				c-3.95-5.37-6.73-9.74-10.14-13.53c-1.52-1.69-4.36-3.23-6.54-3.15c-19.43,0.74-38.85,1.85-58.27,2.89
				c-0.96,0.05-2.27,0.12-2.8,0.73c-5,5.77-9.87,11.66-15.57,18.45C234.43,946.65,264.57,944.91,295.99,943.09z M101.5,258.65
				c0.1,0.58,0.2,1.17,0.3,1.75c18.58-1.81,36.94-5.5,54.32-12.2c14.78-5.7,28.33-2.92,42.09,1.96c3.96,1.4,3.9,3.79,0.57,6.61
				c-0.54,0.46-1.03,0.98-2.03,1.93c2.13,0.47,3.55,0.92,5,1.07c11.69,1.27,23.38,2.63,35.1,3.61c2.69,0.23,5.5-0.91,8.38-1.45
				c-3.75-7-12.65-8.12-13.95-16.1c-0.05-0.28-0.81-0.53-1.27-0.64c-4.69-1.05-9.6-3.48-14.02-2.79c-7.99,1.25-14.56-2.87-20.38-5.78
				c-10.5-5.24-19.41,0.65-28.99,0.68c-2.66,0.01-5.33,1.33-7.97,2.13c-1.06,0.32-2.58,0.55-2.99,1.31
				c-3.22,5.92-9.04,5.88-14.38,5.99c-4.6,0.09-8.32,1.45-11.99,4.01c-2.4,1.67-5.12,3.2-7.92,3.9
				C114.82,256.26,108.14,257.35,101.5,258.65z M787.18,427.34c4.74,4.91,9.76,9.4,13.86,14.62c2.64,3.37,4.92,4.32,8.81,2.82
				c2.1-0.81,4.57-1.25,6.8-1.04c4.56,0.44,5.91-2.09,6.25-5.83c0.19-2.15-0.2-4.39,0.26-6.46c1.62-7.32,3.48-14.59,5.26-21.88
				c-1.88,1.2-2.84,2.72-3.86,4.21c-3.09,4.5-6.85,4.57-8.94-0.49c-1.42-3.44-1.56-7.62-1.42-11.44c0.12-3.42,1.82-6.76,2.09-10.2
				c0.38-4.89-1.35-9.1-5.66-12.38c0.37,4.07,1.03,7.5,0.91,10.91c-0.22,6.26-1.02,12.5-1.35,18.76c-0.28,5.26-2.78,7.52-7.76,5.82
				c-3.72-1.27-7.53-2.98-10.55-5.42c-5.79-4.68-12.06-4.77-17.93-3.85c-0.92,2.39-1.84,5.8-2.27,5.74c-4.3-0.6-8.59-1.57-12.69-3.01
				c-0.58-0.2-0.08-3.47-0.08-5.87c-0.52-0.26-1.85-0.92-2.66-1.32c-0.44,1.99-0.24,4.5-1.4,5.7c-4.42,4.58-1.58,9.43-1.8,12.87
				c5.36-0.29,10.09-0.54,14.83-0.83c0.94-0.06,2.5,0.02,2.7-0.44c2.19-4.98,5.1-1.99,7.75-0.77c1.79,0.82,3.44,1.95,5.52,3.17
				c1.8-6.07,5.03-4.09,8.28-1.76c2.39,1.72,4.45,3.08,7.99,2.84c2.06-0.14,4.41,3.96,6.63,6.15c-2.14,0.44-4.27,1.17-6.42,1.24
				c-1.77,0.06-3.56-0.75-5.36-1C792.18,427.82,789.35,427.58,787.18,427.34z M2.8,960.08c0,1.71,0.18,3.67-0.04,5.59
				c-0.56,4.84,0.74,7.41,5.98,9.43c6.98,2.68,14.2,6.25,16.83,14.57c1.51,4.77,4.35,6.86,9.39,4.36c0.77-0.38,1.96,0.18,2.93,0.08
				c5.26-0.54,10.56-0.91,15.74-1.89c1.63-0.31,3.44-2.07,4.29-3.65c4.33-7.99,2.84-14.78-4.6-21.43c-1.78-1.6-3.88-3.46-6.08-3.85
				c-4.87-0.86-9.89-0.88-14.85-1.21C22.89,961.42,13.38,960.79,2.8,960.08z M254.19,296.71c2.67,0,6.14,0.33,9.51-0.12
				c2.21-0.29,5.09-1.1,6.27-2.71c4.17-5.71,8.02-11.69,11.43-17.89c2.79-5.07,4.37-10.81,7.19-15.85
				c5.05-9.04,4.44-14.89-3.86-21.28c-3.59-2.77-5.76-5.8-6.03-10.28c-0.24-3.87-2.44-5.24-6.09-4.39c-2.01,0.47-4,1.07-6.03,1.62
				c4.66,13.19-1.48,24.22-5.1,35.36c4.26,2.8,8.39,5.24,12.19,8.13c1.36,1.04,1.91,3.15,2.83,4.78c-1.67,0.77-3.3,2.05-5.03,2.2
				c-3.63,0.32-7.32-0.14-10.96,0.12c-1.11,0.08-2.88,1.26-3.08,2.2C256.16,284.49,255.26,290.47,254.19,296.71z M735.2,311.28
				c-0.68-6.97-1.34-14.03-2.11-21.08c-0.08-0.71-0.7-1.65-1.33-1.95c-4.33-2.06-2.81-4.31-0.55-6.94c1.15-1.34,2.13-3.05,2.57-4.75
				c1.25-4.85-0.89-8.87-4.6-9.27c-14.78-1.56-18.06,0.57-22.22,14.41c-0.14,0.48-0.15,1.1-0.46,1.41c-1.1,1.1-2.31,2.98-3.47,2.98
				c-4.56-0.02-5.64,2.81-5.99,6.38c-0.31,3.14-0.51,6.3-0.49,9.45c0.03,7.29,0.1,7.03,7.61,7.08c4.26,0.03,8.5,1.11,12.75,1.71
				c-0.06,0.19-0.11,0.38-0.17,0.57C722.57,311.28,728.39,311.28,735.2,311.28z M871.5,388.37c4.26-1.63,8.01-3.21,11.87-4.49
				c2.09-0.69,4.68-0.3,6.47-1.37c8.05-4.81,16.77-7.2,25.99-7.37c8.32-0.15,16.66,0.48,24.99,0.59c1.45,0.02,3.67-0.39,4.24-1.37
				c3.49-5.91,0.18-13.59-6.37-14.72c-3.49-0.6-5.18-2.15-5.97-5.58c-0.25-1.07-2.98-2.72-3.68-2.36
				c-8.26,4.27-19.03,1.99-25.89,9.89c-0.45,0.52-1.5,0.61-2.29,0.75c-4.23,0.77-8.46,1.53-12.69,2.23
				c-5.11,0.85-9.07,3.4-11.07,8.23C875.06,377.78,873.44,382.92,871.5,388.37z M193.45,1094.21c1.02,0.51,1.42,0.85,1.85,0.9
				c10.55,1.21,21.04,2.56,30.87,7.09c1.06,0.49,3.11-1.01,4.63-1.75c4.35-2.13,8.52-4.75,13.04-6.41c3.66-1.35,7.86-1.22,11.59-2.43
				c6.15-1.99,12.08-4.68,18.2-6.78c3.85-1.32,7.39-2.42,8.63-7.14c0.38-1.43,2.83-2.77,4.59-3.3c5.38-1.63,10.86-2.94,16.35-4.17
				c2.91-0.65,6.18-0.27,8.81-1.44c2.73-1.21,6.66-1.86,7.5-6.8c-4.48,1.01-8.22,1.63-11.83,2.71c-7.37,2.21-14.57,5.03-22.02,6.89
				c-5.22,1.31-10.78,1.24-16.11,2.18c-9.64,1.71-19.19,3.88-28.84,5.55C224.44,1082.12,209.16,1087.92,193.45,1094.21z
				M850.95,897.66c0.07,0.33-0.07,1.68,0.53,2.38c3.79,4.33,6.99,9.44,13.04,11.08c9.25,2.52,18.39,5.77,27.81,7.28
				c12.61,2.01,25.4,3.21,36.13-6.86c3.63-3.41,4.3-4.08,0.79-7.73c-2.21-2.3-4.32-3.95-7.77-1.98c-1.26,0.72-3.28,0.43-4.88,0.18
				c-6.39-1.02-12.74-2.31-19.13-3.3c-12.28-1.9-24.49-4.7-36.88,0.18C858.12,899.85,854.62,898.19,850.95,897.66z M354.66,1061.84
				c0.01,0.55,0.01,1.1,0.02,1.65c10.13-3.06,20.42-5.68,30.35-9.3c13.01-4.75,13.72-10.85,3.02-19.59
				c-6.05-4.94-11.94-10.07-17.87-15.16c-4.74-4.07-9.43-8.21-14.68-12.78c-0.65,2-1.35,3.07-1.27,4.09
				c0.38,5.05,1.18,10.07,1.42,15.12c0.21,4.49,1.7,8.11,4.53,11.67c3.46,4.36,2.47,8.22-2.35,11.36c-2.86,1.86-6.05,3.23-8.75,4.64
				C351.02,1056.43,352.84,1059.14,354.66,1061.84z M811.04,317.32c-0.57-2.91-1.23-5.57-1.58-8.26c-0.72-5.46-3.46-8.14-9.21-8.22
				c-5.77-0.08-11.58-0.62-17.28-1.55c-3.88-0.63-7.38-1.19-10.94,1.21c-1.11,0.75-3.11,0.29-4.69,0.22
				c-8.19-0.36-16.37-1.06-24.56-1c-2.51,0.02-5.57,1.4-5.36,5.32c0.17,3.25,0.03,6.52,0.02,9.78c0,3.82,0,7.64,0,11.99
				C761.49,313.76,785.64,313.69,811.04,317.32z M526.2,513.94c-6.45-3.55-12.62-2.11-18.56-1.23c-6.01,0.89-12.05,4.17-17.81,3.63
				c-16.86-1.59-29.24,6.44-41.01,16.61c-1.15,0.99-2.12,2.2-3.17,3.31c0.17,0.49,0.35,0.98,0.52,1.47c5.2,0,10.41,0.2,15.6-0.05
				c6.75-0.32,13.72-0.08,20.18-1.73c11.31-2.89,22.42-5.67,34.53-2.43C519.63,527.16,522.79,520.81,526.2,513.94z M592.44,502.15
				c8.85,2.18,16.53,1.29,24.34-0.92c4.34-1.23,9.11-1.94,13.57-1.52c10.94,1.03,21.82,2.26,32.65-0.4c1.52-0.37,3.63-1.86,3.91-3.17
				c1.09-5.06,1.55-10.25,2.13-14.58c-10.07,0-21.49-0.47-32.85,0.14c-10.31,0.55-20.12,5.53-30.85,3.83
				c-1.65-0.26-4.37,1.39-5.4,2.95C597.14,492.71,595.01,497.35,592.44,502.15z M323.65,356.11c2.31,0.9,4.97,1.93,7.82,3.03
				c1.15-0.52,2.81-2.01,4.11-1.74c7.92,1.64,15.05-0.87,22.21-3.57c0.29-0.11,0.78,0.11,0.97-0.06c1.05-0.94,2.71-1.82,2.94-2.97
				c2.39-11.54,5.51-23.05,3.45-35.01c-0.34-1.96-1.46-3.78-2.22-5.67c-1.68,0.78-3.48,1.38-5.01,2.39
				c-3.71,2.43-7.05,6.67-10.98,7.27c-8.81,1.35-13.41,5.57-14.25,13.88c1.05,1.26,2.95,2.92,2.71,3.33
				C331.62,343.48,327.58,349.81,323.65,356.11z M730.25,187.39c-7.35-0.6-14.94-2.61-22-1.44c-8.6,1.42-13.44-4.2-19.8-7.1
				c-2.09-0.95-4.14-2-6.29-2.78c-1.21-0.44-2.82-0.07-3.83-0.72c-1.94-1.26-3.58-2.98-5.35-4.51c2.3-0.88,4.6-2.51,6.9-2.52
				c10.21-0.08,20.43,0.34,30.65,0.45c1.37,0.02,3.65-0.3,3.98-1.09c1.52-3.66,4.45-2.85,7.18-2.85c4.5-0.01,8.99,0,13.49,0
				c0.04-0.64,0.09-1.28,0.13-1.92c-1.63-0.24-3.25-0.67-4.88-0.68c-11.3-0.04-22.59-0.1-33.89,0.05c-12.1,0.16-24.2,0.37-36.28,0.96
				c-2.21,0.11-5.99,1.93-6.21,3.39c-1.27,8.45-1.7,17.02-2.35,24.85C678.04,187.7,704.15,189.39,730.25,187.39z M640.43,256.24
				c-6.45,3.41-12.17,6.55-18,9.46c-2.59,1.3-5.33,2.45-8.13,3.13c-2.97,0.72-6.1,0.85-9.16,1.17c-2.28,0.24-4.58,0.29-6.86,0.56
				c-1.25,0.14-2.8,0.17-3.65,0.9c-8.86,7.61-19.39,9.54-30.67,11c-9.61,1.24-18.89,5.32-28.22,8.46c-2.39,0.8-4.21,3.35-6.6,4.11
				c-5.72,1.8-7.18,4.05-5.46,10.95c2.11-0.39,4.47-0.37,6.42-1.25c8.67-3.92,17.18-8.2,25.87-12.07c1.65-0.74,3.87-0.11,5.8-0.3
				c6.15-0.61,12.57-0.4,18.37-2.19c7.77-2.4,13.49-9.9,22.88-9.06c4.49,0.41,9.12-0.75,15-1.33c4.76-7.51,15.54-8.63,23.81-13.97
				C641.44,263.06,641.04,260.34,640.43,256.24z M351.59,728.46c-0.86-0.4-2.2-1.53-2.79-1.22c-4.92,2.59-9.05-0.95-13.25-2.01
				c-4.49-1.13-7.04-0.58-9.54,3.24c-3.39,5.18-6.31,5.73-11.36,2.26c-3.16-2.17-6.2-3.4-10.13-2.56c-3.53,0.75-7.24,1.43-10.79,1.13
				c-8.38-0.7-15.96-0.21-20.43,8.43c-0.37,0.71-1.63,1.48-2.38,1.39c-2.98-0.36-6.59-0.19-8.73-1.82c-3.44-2.63-5.47-1.27-8.24,0.58
				c-1.5,1.01-3.6,1.12-5.41,1.63c1.36,1.53,2.64,4.11,4.67,5c5.83,2.55,11.95,4.42,17.96,6.56c2.9,1.03,5.12,0.41,7.7-1.6
				c4.82-3.76,9.88-7.02,16.51-2.6c2.05,1.37,5.97,1.27,8.47,0.33c5.98-2.26,11.74-5.18,17.36-8.27c5.61-3.08,11.04-5.51,17.66-3.16
				c1.27,0.45,3.38-0.86,4.85-1.77C346.29,732.44,348.68,730.54,351.59,728.46z M215.87,977.34c1.22,8.09,2.47,14.92,3.21,21.8
				c0.47,4.39,0.8,8.95,0.13,13.26c-1.38,8.79,0.05,15.89,7.85,19.29c3.05-2.76,5.77-5.11,8.36-7.59
				c5.55-5.32,11.13-10.61,16.45-16.16c0.81-0.85,0.5-2.99,0.34-4.5c-0.14-1.26-1.05-2.43-1.22-3.69
				c-1.01-7.35-6.61-12.75-13.93-13.61c-2.2-0.26-4.43-0.95-6.47-1.85C225.68,982.13,220.88,979.73,215.87,977.34z M829.61,424.99
				c38.81-13.74,76.72-31.23,120.03-24.76c0.36-2.51,0.57-4.62,0.97-6.7c0.61-3.16-0.66-4.4-3.8-4.9
				c-12.14-1.95-24.34-1.05-36.14,1.1c-11.1,2.03-21.71,6.69-32.58,10.01c-9.45,2.89-19.03,5.38-28.47,8.33
				c-5.81,1.82-11.58,3.87-17.11,6.38C828.44,416.31,827.63,420.81,829.61,424.99z M93.02,1142.42c1.04-2.78,1.87-5.87,3.33-8.63
				c1.37-2.58,0.31-6.94,4.94-8.06c1.05,5.14,1.97,9.62,3.03,14.79c2.32-5.05,4.38-9.52,6.78-14.75c6.69,7.31,5.02,15.22,6.81,23.68
				c1.14-3.35,2.01-5.51,2.6-7.74c0.93-3.51,1.54-7.11,2.56-10.59c0.25-0.86,1.44-1.67,2.38-1.98c0.35-0.12,1.63,1.19,1.74,1.96
				c1.14,7.95,2.14,15.92,3.18,23.96c6.58,0.01,19.04-4.25,23.55-8.72c1.36-1.35,2.53-3.65,2.5-5.49
				c-0.08-4.8-10.14-12.95-14.86-12.48c-0.65,0.06-1.29,0.57-1.91,0.51c-3.76-0.34-8.24,0.38-11.1-1.44
				c-5.6-3.57-11.36-4.15-17.56-4.12c-11.38,0.05-22.76,0.02-34.76,0.02c0.25,4.86,0.49,9.3,0.72,13.73c0.65,0.02,1.3,0.05,1.95,0.07
				c0.43-1.14,0.69-2.39,1.33-3.39c0.64-1.01,1.66-1.77,2.52-2.63c0.64,1.02,1.79,2.03,1.83,3.08
				C84.76,1139.08,87.6,1141.24,93.02,1142.42z M589.08,370.67c0.3,0.53,0.61,1.07,0.91,1.6c18.62-8.84,38.89-5.62,58.62-9.2
				c-2.54-4.34-1.76-11.45-10.06-10.12c-0.97,0.16-2.44-1.06-3.26-2.01c-2.95-3.37-6.16-6.62-8.44-10.42
				c-3.1-5.18-8.04-6.94-13.39-9.22C605.22,344.59,597.15,357.63,589.08,370.67z M359.63,473.35c0.86,7.55,1.54,14.58,2.54,21.56
				c0.2,1.42,1.31,3.09,2.51,3.91c6.09,4.19,8.4,3.62,12.11-2.9c3.78-6.64,6.74-13.86,11.23-19.95c4.47-6.07,6.44-12.64,7.54-19.75
				c1.5-9.72,2.53-19.51,12.73-24.98c3.45-1.85,4.03-9.67,2.6-11.23c-3.94,1.14-7.25,2.09-10.85,3.13c3.4,2.83,1.85,5.69-0.35,8.53
				c-0.71,0.92-1.52,1.84-1.91,2.9c-2.83,7.63-6.67,14.14-15.02,17.11c-4.54,1.61-4.98,6.1-1.35,9.63c2.12,2.06,1.39,3.62-0.71,4.7
				c-1.42,0.73-3.19,1.25-4.75,1.13c-4.34-0.32-6.22,1.99-7.57,5.68c-0.67,1.83-2.34,3.29-3.55,4.92
				C363.39,476.53,361.96,475.31,359.63,473.35z M90.2,359.9c1.25-6.05,2.7-12.4,3.84-18.8c1.07-5.99,1.79-12.04,2.72-18.06
				c0.36-2.29,1.23-4.56,1.26-6.84c0.14-10.9,4.49-19.89,11.74-27.79c3.34-3.63,6.31-7.62,6.43-12.9c-7.38-2.75-10.28-2.16-12.7,4.65
				c-2.28,6.45-3.41,13.42-4.18,20.26c-0.6,5.28-2.59,8.94-7.05,11.76c-13.55,8.59-20.13,22.71-28.25,36.13
				C72.45,352.06,80.72,355.71,90.2,359.9z M365.1,797.6c-6.19,0.15-11.52-5.4-14.04-14.1c-8.73-1.56-14.11-6.67-17.13-16
				c-1.28,2.32-1.89,3.75-2.79,4.97c-1.45,1.96-2.79,4.89-4.73,5.43c-4.56,1.27-5.02,4.97-6.43,8.25c-0.33,0.76-0.5,1.59-0.82,2.35
				c-1.18,2.76-2.52,4.39-6.06,2.67c-1.77-0.86-5.71-0.9-6.25,0.06c-1.51,2.66-2.44,5.28-6,5.7c-0.3,0.04-0.85,2.54-0.48,2.83
				c1.71,1.28,3.69,3.07,5.6,3.09c18.42,0.21,36.84,0.04,55.26,0.12C365.07,802.96,366.49,801.66,365.1,797.6z M511.34,752.45
				c0-0.19,0.01-0.38,0.01-0.57c-34.19,0-68.37,0-102.56,0c6.57,2.35,7.29,8.41,9,13.55c1.44,4.34,5.9,6.25,10.07,4.06
				c2.61-1.37,5.52-2.7,8.39-2.97c17.04-1.59,34.81,2.31,50.37-8.16c-1.03-2.15,0.7-3.28,3.44-3.59
				C497.15,753.96,504.25,753.22,511.34,752.45z M687.78,403.62c4.43,8.58,9.1,17.58,13.74,26.6c2.34,4.55,5.46,6.49,10.8,4.56
				c4.16-1.5,8.69-1.96,13.02-3.03c0.97-0.24,2.5-1.03,2.56-1.68c0.32-3.43,1.64-7.86,0.05-10.18c-4.23-6.18-6.59-12.66-8.15-19.92
				c-1.21-5.6-7.58-8.73-11.3-6.88C701.25,396.69,694.07,400.41,687.78,403.62z M188.44,329.37c0.32-0.52,0.64-1.04,0.96-1.56
				c-1.04-6-6.75-15.82-12.03-17.2c-5.88-1.54-12.34-0.98-18.55-1.13c-3.78-0.09-7.62,0.65-11.33,0.19
				c-3.51-0.43-6.21,0.11-8.35,2.92c-2.53,3.34-4.73,3.28-7.62,0.06c-1.52-1.7-4.21-2.4-6.45-3.38c-1.17-0.51-3.07-0.21-3.66-1.01
				c-4.17-5.64-10.19-4.11-15.61-3.99c-5.66,0.13-3.72,5.19-4.4,8.39c-0.7,3.27,1.06,4.22,4.15,4.51c5.43,0.51,11.55-0.78,15.4,4.92
				c0.26,0.38,1.22,0.28,1.87,0.38c3.22,0.49,6.43,1.02,9.66,1.42c1.57,0.2,3.19,0.34,4.76,0.18c2.74-0.28,5.45-0.95,8.2-1.19
				c4.43-0.39,8.9-0.93,13.32-0.73c6.04,0.28,12.16,0.71,18.03,2.02C180.86,325.09,184.57,327.59,188.44,329.37z M232.03,342.09
				c-0.83-0.61-1.66-1.23-2.49-1.84c-0.78,1.63-1.36,3.39-2.36,4.87c-6.62,9.9-15.38,18.43-19.15,30.17
				c-0.29,0.91-0.92,1.9-1.69,2.44c-2.4,1.69-4.93,3.19-7.41,4.76c0.1-2.95-0.35-6.05,0.43-8.81c1.16-4.16,3.18-8.07,4.83-12.09
				c-0.31-0.13-0.62-0.25-0.93-0.38c-5.61,6.64-11.22,13.28-16.64,19.7c1.94,0.87,4.67,1.2,5.38,2.57
				c3.48,6.68,1.26,12.39-5.65,15.75c-1.78,0.86-3.69,1.49-5.59,2.09c-3.05,0.97-6.14,1.82-10.04,2.97
				c4.66,3.13,8.48,5.73,12.36,8.26c0.65,0.42,1.74,0.97,2.23,0.71c5.19-2.75,11.13-4.79,15.2-8.75c4.87-4.73,8.71-10.82,11.93-16.88
				c6.27-11.82,11.94-23.97,17.54-36.13C231.3,348.65,231.38,345.24,232.03,342.09z M487.96,353.81c0.35-5.58-1.59-8.96-6.09-11.88
				c-1-0.65-1.81-3.24-1.31-4.27c2.51-5.29-1.08-7.93-4.37-10.32c-5.03-3.67-12.49-3.94-15.18-1.06c-0.68,0.73-1.11,1.78-1.9,2.28
				c-0.89,0.55-2.08,0.96-3.1,0.88c-3.05-0.23-6.07-0.89-9.13-1.08c-4.98-0.31-6.53,1.59-5.94,6.55c0.47,3.91,0.67,7.86,1.1,11.78
				c0.14,1.32,0.61,2.61,0.72,3.04c3.63-1.14,6.67-2.17,9.75-3.05c4.38-1.26,8.72-2.77,13.19-3.49c3.49-0.57,7.15-0.11,10.74-0.11
				c0.01,0.65,0.02,1.31,0.02,1.96c-1.17,0.16-2.36,0.23-3.5,0.48c-3.27,0.73-6.51,1.57-9.78,2.28c-1.72,0.38-3.47,0.77-5.22,0.82
				c-10.84,0.34-13.23,2.48-12.91,11.18C458.72,354.83,472.95,353.18,487.96,353.81z M653.45,514.02
				c-10.52,0.18-10.51,0.18-17.65,6.18c-0.49,0.41-1.15,0.94-1.7,0.9c-3.62-0.24-4.19,2.48-5.17,4.99
				c-1.72,4.45-3.81,8.76-5.31,13.28c-0.75,2.27-0.69,4.86-0.72,7.31c-0.03,2.92,0.28,5.85,0.38,8.78c0.25,7.35-3.7,13.25-6.65,19.51
				c-0.47,1-0.64,2.9-0.06,3.45c0.74,0.7,2.61,0.97,3.59,0.52c5.87-2.7,11.75-5.45,17.34-8.68c1.43-0.82,1.83-3.44,2.7-5.23
				c-4.26,0.43-7.26,1.77-10.37,2.76c-0.86,0.27-2.27-0.3-3.01-0.96c-0.37-0.32-0.12-2.06,0.41-2.57c4.24-4.03,6.94-9.78,13.57-11.18
				c4.43-0.94,5.21-4.5,5.22-8.87c0.02-6.71,0.96-13.42,1.65-20.12c0.09-0.85,1.15-1.57,1.63-2.43
				C650.56,519.46,651.74,517.2,653.45,514.02z M336.96,832.77c0.1-0.53,0.2-1.07,0.3-1.6c-0.55-0.58-1.09-1.17-1.66-1.74
				c-3.81-3.84-3.26-5.99,2.13-7.24c3.21-0.74,6.54-1.01,9.82-1.41c6.85-0.83,13.71-1.6,20.56-2.4c-0.03-0.49-0.06-0.99-0.09-1.48
				c-30.1-3.3-60.21-1.54-89.89-0.38c2.27,5.04,4.2,9.33,6.29,13.96c5.78,0,12.54-0.04,19.3,0.03c1.79,0.02,3.93-0.23,5.32,0.62
				C318.3,836.8,327.64,833.28,336.96,832.77z M895.51,850.36c12.56,21.81,10.9,29.54,3.96,44.2c8.48,2.93,9.7,1.95,13.8-5.72
				c4.69-8.78,2.11-18.07,3.19-27.08c0.1-0.81-0.62-1.85-1.2-2.59c-2.18-2.76-4.46-5.44-6.83-8.3c3.39-5.14,6.58-9.87,9.62-14.69
				c0.66-1.04,0.81-2.42,1.14-3.66c0.7-2.6,1.32-5.21,2.07-7.8c0.67-2.31,2.03-4.58,2.06-6.89c0.04-2.87-0.9-5.77-1.53-8.63
				c-0.35-1.6-0.93-3.16-1.41-4.73c-0.51-0.12-1.01-0.23-1.52-0.35c-2.68,3.81-6.96,7.33-7.72,11.49c-1.99,10.92-2.65,22.01-11.84,30
				C897.85,846.9,896.83,848.69,895.51,850.36z M219.77,238.72c3.71,0.8,6.95,1.37,10.12,2.23c1.66,0.45,4.44,1.17,4.6,2.12
				c1.3,7.86,9.06,9.67,13.3,14.72c1.49,1.77,4.11,2.98,6.43,3.5c1.34,0.3,4.11-0.96,4.5-2.11c2.61-7.66,5.16-15.39,6.9-23.29
				c1.21-5.52-1.91-8.84-7.54-10.29c-8.16-2.1-16.11-0.35-24.14,0.8C226.92,227.4,222.74,231.57,219.77,238.72z M770.09,738.48
				c-1.8,3.69-1.28,9.24-6.75,9.12c-5.89-0.13-3.19-6.66-6.41-11.04c-2.18,6.16-3.98,11.33-5.83,16.47c-0.55,1.52-1,3.15-1.92,4.42
				c-0.57,0.79-2.24,1.64-2.85,1.34c-0.98-0.49-1.85-1.83-2.13-2.96c-1.1-4.49-1.95-9.04-2.89-13.56c-0.38-0.03-0.76-0.05-1.14-0.08
				c-3.19,10.04-6.37,20.08-9.72,30.61c2.73-0.16,5.19-0.39,7.65-0.43c2.46-0.05,5.65-0.88,7.25,0.33c4.29,3.22,8.98,2.95,13.34,2.03
				c5.69-1.2,11.1-3.55,17.02-1.2c0.46,0.18,1.35-0.73,1.11-0.59C774.5,761.07,772.3,749.78,770.09,738.48z M364.54,768.93
				c0.73-1.62,1.04-2.18,1.23-2.78c1.19-3.6,2.24-7.11,7.48-5.8c0.89,0.22,3.5-2.25,3.33-2.7c-0.81-2.06-1.9-4.54-3.65-5.6
				c-6.2-3.77-14.74-3.4-18.93-10.83c-0.22-0.39-1.12-0.44-1.72-0.57c-4.37-0.92-8.47-4.46-13.32-1.37
				c-0.42,0.27-1.49-0.69-2.27-0.71c-2.87-0.07-6.09-0.84-8.54,0.19c-7.71,3.24-15.01,7.45-22.71,10.73
				c-3.22,1.37-6.79,3.32-10.67,0.89c-5.74-3.6-10.25-2.61-15.58,3.34c1.79,0.35,3.38,0.8,5,0.96c9.69,0.96,19.37,2.07,29.08,2.66
				c3.01,0.18,6.3-0.65,9.13-1.82c4.15-1.72,7.93-4.3,12.01-6.19c1.12-0.52,2.92-0.24,4.15,0.28c2.81,1.19,5.33,3.43,8.21,4.01
				c5.67,1.15,9.88,3.87,12.93,8.7C360.99,764.37,362.56,766.25,364.54,768.93z M863.12,1009.04c0.08-0.3,0.17-0.6,0.25-0.91
				c-9.38-3.17-18.86-6.1-28.13-9.57c-18.45-6.89-37.33-11.12-57.11-10.27c-6.73,13.31-19.05,18.67-32.92,21.87
				c0.74,0.35,1.39,0.56,2,0.47c17.5-2.61,35.19-2.49,52.75-6.2c18.79-3.97,38.34-2.39,57.15,3.18
				C859.08,1008.19,861.12,1008.56,863.12,1009.04z M403.78,264.66c-0.37,0.53-0.75,1.07-1.12,1.6c4.44,0.9,5.63,3.67,5.51,7.66
				c-0.11,3.94,0.38,7.9,0.59,11.86c0.16,3.04-0.92,7.38,0.67,8.86c4.47,4.16,0.77,7.26-0.21,10.68c-0.9,3.14-3.41,3.38-6.02,2.37
				c-7.74-2.99-15.44-6.1-22.4-8.86c-0.12,2.65-0.09,4.59-0.33,6.5c-0.27,2.21-1.73,5.83-1,6.32c2.29,1.53,5.33,2.09,8.16,2.62
				c2.04,0.38,4.29-0.27,6.29,0.2c4.04,0.96,7.83,4.22,12.15,0.7c0.37-0.3,1.28-0.06,1.9,0.09c3.91,0.96,7.8,2.01,11.71,2.94
				c3.08,0.73,5.93,0.66,7.6-2.72c1.65-3.33,2.41-6.49-0.93-9.38c-0.8-0.69-1.47-1.9-1.58-2.94c-0.52-4.94-1.33-9.93-1.07-14.85
				c0.3-5.5-1.25-9.66-5.58-12.98c-1.7-1.3-2.89-3.28-4.62-4.52C410.38,268.59,407.03,266.69,403.78,264.66z M337,981.54
				c-6.12,5.62-5.61,12.84-6.8,19.4c-1.67,9.22-2.95,18.51-4.26,27.79c-0.46,3.26-1.1,6.69-0.49,9.82c0.43,2.2,2.66,5.55,4.36,5.73
				c5.42,0.57,11.01,0.17,16.46-0.44c2.51-0.28,3.41-2.34,2.96-5.29c-1.38-8.99-2.29-18.04-3.47-27.06
				C344.41,1001.21,342.76,990.99,337,981.54z M929.66,328.16c5.16,0.99,5.7,4.19,5.38,7.73c-0.3,3.3-1.8,6.74-1.21,9.82
				c0.74,3.83,2.55,7.79,4.98,10.83c1.27,1.59,4.86,1.31,7.7,1.95c0.31-4.75,0.49-9.55,0.96-14.32c0.75-7.63,2.26-8.52,9.47-5.97
				c4.85,1.71,10.84-0.94,10.57-4.97c-0.5-7.26-1.1-14.56-2.39-21.72c-1.06-5.89-3.41-6.89-8.34-3.47
				C947.54,314.47,938.68,321.43,929.66,328.16z M588.92,509.92c-5.01-0.24-8.88-0.4-12.74-0.62c-5.6-0.32-11.25-1.37-16.78-0.89
				c-8.4,0.73-16.75,2.32-25.04,3.96c-2.07,0.41-4.52,1.97-5.59,3.74c-3.12,5.13-5.64,10.64-8.27,15.77
				c14.35-1.08,28.18-2.09,42-3.21c1.02-0.08,2.45-0.64,2.9-1.43c2.33-4.03,6.23-4.82,10.23-5.14
				C583.03,521.48,585.26,515.7,588.92,509.92z M406.66,318.24c-3.66,5.26-5.05,10.67-4.55,16.74c0.31,3.74-0.05,7.54-0.06,11.31
				c-0.02,7.27-0.01,14.54-0.01,21.12c6.32,2.73,12.75-4.26,17.98,2.22c0.12,0.15,0.63-0.04,0.96-0.04c6.35,0,12.69,0,19.29,0
				c-0.5-4-4.02-5.25-7.17-6.84c-1.4-0.71-2.87-1.28-4.31-1.92c1.26-1.32,2.49-2.66,3.78-3.94c0.52-0.51,1.17-0.89,1.93-1.46
				c-8.98-3.71-9.09-15.03-17.42-18.9c-0.65-0.3-0.7-1.8-1.14-2.69c-2.51-4.97-5.05-9.93-7.63-14.86
				C408.15,318.66,407.47,318.6,406.66,318.24z M663.96,502.48c-1.13,0-2.27-0.03-3.41,0.01c-7.41,0.25-14.83,0.69-22.24,0.7
				c-5.62,0.01-11.62-1.9-16.77-0.46c-8.62,2.4-17.04,3.98-25.97,3.45c-1.27-0.08-3.03,1.77-3.93,3.14c-1.87,2.85-3.04,6.18-5,8.96
				c-5.22,7.43-7.21,15.94-9.39,24.75c4.61-0.48,5.99-3.33,6.98-6.71c2.19-7.49,8.57-11.44,14.74-14.09
				c5.39-2.31,12.07-1.51,18.11-2.49c1.69-0.28,4-1.68,4.55-3.13c1.48-3.94,4.29-5.38,8.01-5.58c9.06-0.48,18.14-1.27,27.2-1.04
				C662.71,510.14,664.51,507.88,663.96,502.48z M852.38,356.39c-1.89,0-3.17-0.1-4.43,0.02c-9.39,0.88-18.76,1.99-28.16,2.66
				c-6.63,0.47-6.6,0.22-6.69,7.1c-0.03,1.97-0.65,3.93-0.65,5.89c0,1.74-0.14,4.09,0.86,5.13c7.25,7.57,6.72,15.96,3.77,25.1
				c-0.87,2.71-0.3,6.24,0.59,9.08c0.89,2.87,2.92,3.34,4.92,0.28c1.62-2.48,3.46-4.9,5.57-6.96c5.7-5.56,7.79-14.37,4.88-21.77
				c-2.67-6.77,0.31-13.07,7.3-15.45C845.8,365.62,849.18,361.56,852.38,356.39z M235.79,329.77c0.69,0.21,1.37,0.41,2.06,0.62
				c-2.67,10.42-5.34,20.84-7.96,31.09c5.52-2.94,9.01-8.46,10.86-14.55c2-6.61,6.18-9.64,12.29-11.65c3.38-1.11,6.31-3.63,9.44-5.51
				c-0.18-0.73-0.36-1.45-0.53-2.18c-4.31-0.31-8.61-0.68-12.92-0.9c-3.11-0.17-6.25,0.05-9.34-0.25c-6.2-0.61-12.37-1.85-18.57-2.09
				c-5.6-0.23-11.3,0.14-16.85,0.97c-3.82,0.56-7.81,1.81-10.98,3.89c-1.07,0.7,0.15,4.9,0.31,7.51c0.09,1.47,0.14,2.94,0.24,5.12
				c2.72-0.25,5.36-0.19,7.84-0.78c7.81-1.84,15.53-3.06,23.63-1.49c3.93,0.76,7.52-0.79,8.61-5.54
				C234.26,332.54,235.15,331.19,235.79,329.77z M219.77,348.88c-1.8-0.21-3.77-0.82-5.64-0.61c-7.22,0.84-14.41,2.64-21.63,2.72
				c-5.4,0.06-7.89,2.38-9.48,6.78c-0.73,2.02-1.25,4.12-1.76,6.21c-2.74,11.19-5.59,22.29-14.61,30.5c-1.64,1.49-2.71,3.62-4.06,5.5
				c0.82,0.6,1.17,1,1.61,1.15c4.61,1.62,22.06-3.44,25.07-7.3c0.44-0.57,0.8-1.45,0.73-2.14c-0.25-2.37-0.71-4.73-1-6.5
				c-2.42-0.67-4.64-1.29-6.87-1.91c1.18-2.05,2.12-4.29,3.6-6.1c3.99-4.88,8.23-9.57,12.34-14.35c1.3-1.51,2.34-3.32,3.86-4.53
				c0.98-0.79,3.06-1.41,3.86-0.89c0.88,0.58,1.3,2.59,1.14,3.88c-0.24,1.94-1.09,3.83-1.81,5.68c-1.5,3.86-3.08,7.69-4.63,11.54
				c0.45,0.23,0.91,0.46,1.36,0.68C207.69,369.32,213.53,359.44,219.77,348.88z M182.63,1027.37c0.6-0.25,1.2-0.5,1.79-0.76
				c0.47-3.87,2.49-8.5,1.06-11.45c-2.24-4.62-1.37-9.33-2.74-13.67c-0.94-2.97-3.84-5.54-6.41-7.61c-0.65-0.52-3.61,1.25-5.1,2.46
				c-2.84,2.31-3.73,0.71-4.43-1.78c-1.13-4-3.18-6.38-7.84-6.93c-7.2-0.86-13.61,1.3-19.01,5.18c-3.59,2.58-8.79,5.44-7.18,12.8
				c4.84,0.25,10.25,1.66,15.07,0.55c8.83-2.04,14.32,5.2,21.83,6.41c0.66,0.11,1,1.64,1.66,2.37
				C175.08,1019.1,178.87,1023.23,182.63,1027.37z M412.35,427.49c6.87-1.15,7.4-6.29,7.73-9.56c0.43-4.18,2.32-5.86,5.43-7.32
				c9.27-4.36,15.77-10.42,14.73-21.91c-0.27-2.97-1.1-4.38-3.9-4.48c-8.76-0.33-17.53-0.81-26.29-0.78
				c-5.93,0.02-7.04,2.16-5.59,7.95c0.87,3.47,1.74,7.12,1.58,10.64c-0.29,6.26,1.66,11.08,6.48,15.1
				C415.59,419.67,416.97,422.98,412.35,427.49z M836.61,382.82c5.76-2.18,11.38-4.59,17.18-6.42c5.84-1.84,11.9-3.01,17.79-4.7
				c1.61-0.46,3.49-1.44,4.35-2.77c3.13-4.79,7.75-6.26,12.98-7.33c5.96-1.21,12.71-0.6,17.03-6.45c0.59-0.79,2.03-1.06,3.14-1.34
				c4.96-1.24,9.98-2.23,14.89-3.65c2.27-0.66,5.69-1.64,6.21-3.25c1.46-4.52,2.67-9.38,1.7-14.39
				c-12.28,2.46-12.55,2.97-20.04,11.41c-3.21,3.62-7.22,7.61-11.6,8.89c-15.09,4.42-30.51,7.67-45.78,11.5
				c-2.66,0.67-5.21,1.87-7.74,3c-1.75,0.78-3.26,2.21-5.05,2.75C835.38,371.95,833.36,377.15,836.61,382.82z M576.98,220.4
				c1.33,0.21,2.33,0.27,3.27,0.54c3.49,1,8.78,8.93,7.07,11.28c-2.39,3.27-5.28,6.85-8.79,8.42c-3.22,1.44-4.01,3.23-4.71,6.05
				c-0.79,3.18-2.02,6.24-3.06,9.36c-1.77,5.33-3.53,10.67-5.39,16.3c4.34-0.22,7.31-0.33,10.27-0.52
				c5.58-0.35,12.64-5.42,13.62-9.22c2.38-9.25,5.21-18.38,7.41-27.67c0.67-2.82,1.05-7.66-0.43-8.68c-4.54-3.15-5.26-7.22-5.7-11.98
				c-0.21-2.21-1.3-4.35-1.98-6.51C581.45,210.05,578.81,212.92,576.98,220.4z M591.78,262.52c6.8-1.22,12.84-2.79,18.95-3.2
				c3.13-0.21,3.7-1.38,4.59-3.78c1.24-3.34,3.15-6.43,4.79-9.63c0.6-1.17,1.6-2.26,1.81-3.5c1.8-10.48-0.79-20.6-1.91-31.09
				c-2.96,0.67-5.06,1.13-7.14,1.61c-6.82,1.6-7.27,2.68-6.59,9.58c0.27,2.71-0.13,6.66-1.86,8.21
				C594.83,239.33,595.2,251.39,591.78,262.52z M810.08,299.62c1.1,4.83,2.38,9.45,3.17,14.16c0.6,3.59,2.23,5.38,5.84,6.3
				c9.06,2.31,16.92,6.38,22.06,14.97c2.15,3.58,5.97,6.18,9.12,9.13c3.06,2.87,6.63,3.13,10.76,1.93c-0.4-0.94-0.57-1.57-0.9-2.1
				c-5.23-8.32-10.28-16.68-12.06-26.62c-0.3-1.66-2.06-3.39-3.59-4.43c-4.94-3.35-10.33-6.08-15.11-9.64
				C823.53,298.99,817.24,298.57,810.08,299.62z M725.98,952.49c0.1-0.13,0.19-0.26,0.29-0.39c-1.56-0.44-3.1-1.01-4.69-1.3
				c-15.19-2.73-30.36-5.57-45.6-8.01c-6.06-0.97-11.54,1.27-15.92,5.57c-2.81,2.76-4.49,6.13-3.29,9.55c4.37,0,8.34-0.38,12.22,0.08
				c9.06,1.06,18.15,2.16,27.07,3.97c3.98,0.81,8.45,2.58,11.11,5.41c1.94,2.06,1.29,6.57,1.79,10c3.38,0,7.65,0,13.28,0
				c-2.06-1.22-3.07-1.56-3.71-2.25c-0.92-0.99-1.57-2.23-2.34-3.36c1.21-0.69,2.36-1.7,3.66-1.97c1.59-0.33,3.36-0.32,4.96,0.02
				c10.54,2.23,21.06,4.59,31.58,6.92c1.29,0.29,2.56,0.68,3.83,1.02c0.18-0.51,0.37-1.02,0.55-1.54c-2.48-0.96-4.89-2.27-7.46-2.81
				c-6.17-1.3-12.46-2.06-18.61-3.42c-2.3-0.51-4.4-2.03-6.53-3.2c-1.99-1.1-3.75-2.96-5.85-3.44c-9.7-2.21-19.53-3.89-29.21-6.16
				c-2.29-0.54-4.2-2.66-6.29-4.05c2.45-0.83,4.93-2.43,7.35-2.36C704.77,951.07,715.37,951.87,725.98,952.49z M273.06,305.95
				c0.43-0.73,0.85-1.45,1.28-2.18c-1.71-1.17-3.34-3.16-5.16-3.36c-4.88-0.54-9.85-0.41-14.78-0.35c-4.82,0.06-5.91-1.34-3.97-5.39
				c0.89-1.85,1.64-3.78,2.53-5.87c-8.98-0.77-17.26-1.8-25.56-2.08c-6.94-0.23-13.91,0.33-20.85,0.76
				c-1.73,0.11-3.79,0.58-5.05,1.66c-4.93,4.2-7.23,9.92-9.4,17c3.31-0.83,5.48-1.44,7.68-1.91c2.71-0.59,5.43-1.3,8.18-1.55
				c5.77-0.52,11.56-0.84,17.35-1.09c2.61-0.12,5.24,0,7.85,0.21c7.62,0.61,15.24,1.32,22.86,1.99c2.79,0.25,5.59,0.42,8.36,0.77
				C267.29,304.94,270.17,305.48,273.06,305.95z M272.55,308.1c-6.77-0.37-12.77-0.68-18.77-1.02c-3.13-0.17-6.26-0.29-9.38-0.59
				c-6.69-0.64-13.36-1.7-20.06-1.97c-5.42-0.22-10.95-0.01-16.29,0.88c-5.81,0.96-11.58,2.56-17.11,4.61
				c-2.52,0.93-4.23,3.31-2.82,6.95c1.48,3.84,3.92,3.38,6.77,2.63c3.49-0.92,6.91-2.32,10.46-2.76c5.75-0.71,11.57-0.97,17.36-1.11
				c2.91-0.07,5.83,0.55,8.75,0.83c5.99,0.58,11.98,1.19,17.98,1.68c3.1,0.25,6.32-0.17,9.31,0.49c9.18,2.02,9.16,2.17,12.7-6.71
				C271.75,311.25,271.9,310.44,272.55,308.1z M402.86,396.8c-0.48-3.61-0.77-7.23-1.51-10.75c-0.39-1.86-1.15-4-2.48-5.24
				c-5.85-5.46-11.93-10.59-20.71-10.46c-1.75,0.03-3.53-0.87-5.28-1.38c-15.31-4.46-14.78-1-16.74,11.82c-0.02,0.16,0,0.33-0.01,0.5
				c-0.28,3,0.55,4.86,4.09,4.42c3.47-0.44,6.03,0.53,7.44,4.27c0.41,1.09,2.91,1.55,4.54,2c4.84,1.34,9.72,2.54,14.6,3.77
				c1.58,0.4,3.18,0.94,4.78,1C395.34,396.9,399.11,396.8,402.86,396.8z M651.81,288.64c3.82-0.17,8-0.08,12.1-0.6
				c5.79-0.74,11.52-2.77,17.26-2.73c6.88,0.05,13.69,0.76,20.31-1.65c0.83-0.3,2.03-0.93,2.13-1.56c0.11-0.7-0.66-2.06-1.34-2.29
				c-3.84-1.32-7.76-3.36-11.66-3.37c-10.76-0.03-12.23-0.23-7.08-10.29c2.25-4.39,0.69-7.02-4.11-6.89
				c-8.35,0.24-16.28,2.66-23.43,6.9c-2.45,1.46-4.33,3.94-3.72,7.94C652.97,278.57,652.06,283.28,651.81,288.64z M928.02,898.39
				c2.13,2.35,4.38,4.22,5.76,6.61c0.81,1.4,0.99,4.68,0.14,5.3c-5.4,3.92-10.75,9.12-16.9,10.56c-14.63,3.42-29.37,1.16-43.62-3.52
				c-5.26-1.72-10.38-3.86-15.57-5.8c-1.08,3.35-2.03,6.3-3.05,9.49c1.03,0.3,1.78,0.64,2.56,0.71c11.39,1.07,22.83,1.73,34.15,3.27
				c11.96,1.63,23.13-0.63,34.03-5.12c9.2-3.8,18.32-7.78,27.42-11.82c2.87-1.27,5.69-2.76,8.25-4.56c1.18-0.83,2.47-2.78,2.26-3.95
				c-0.21-1.17-2.1-2.42-3.51-2.93c-7.16-2.59-14.41-4.97-21.63-7.39c-1.37-0.46-2.78-0.78-4.07-1.13
				C932.03,891.74,930.07,895,928.02,898.39z M846.2,725.14c-1.63,5.24-4.33,9.62-4.01,13.77c0.57,7.38,2.97,14.62,4.59,21.92
				c0.24,1.1,0.58,2.21,0.61,3.33c0.08,3.13-0.39,6.33,0.1,9.39c1.05,6.68,5.32,1.07,7.98,1.8c1.42,0.39,2.96,0.54,4.44,0.5
				c2.61-0.07,5.39,0.12,7.76-0.73c1.49-0.54,2.92-2.63,3.39-4.3c1.25-4.48,2.41-8.76,0.29-13.77c-2.03-4.79-2.06-10.44-2.95-15.62
				c-1.77,1.39-3.1,2.44-4.43,3.49c-0.91-1.44-2-2.8-2.69-4.33c-0.92-2.05-1.52-4.25-2.26-6.38c-0.63,0.04-1.27,0.08-1.9,0.12
				c-0.72,3.84-1.36,7.7-2.19,11.52c-0.25,1.15-1.01,2.18-1.53,3.27c-0.86-0.82-2.25-1.5-2.48-2.47
				C849.28,739.91,847.91,733.12,846.2,725.14z M751.85,360.05c0.03-0.29,0.06-0.58,0.09-0.87c-6.18-0.97-12.4-1.74-18.53-2.98
				c-5.66-1.15-11.14-3.27-16.83-4.17c-17.02-2.69-34.22-4.34-51.11-7.67c-12.28-2.42-24.17-6.86-36.24-10.38
				c-1.13-0.33-2.32-0.46-4.62-0.9c4.56,5.75,8.18,10.69,12.25,15.21c1.19,1.32,3.82,2.24,5.58,1.96c3.81-0.6,4.98,0.63,6.27,4.23
				c1.56,4.36,4.62,8.22,7.3,12.12c0.41,0.6,2.79,0.8,3.26,0.28c3.8-4.22,10-7.18,7.96-14.73c-0.22-0.82,1.61-2.89,2.82-3.23
				c1.63-0.46,3.61,0.04,5.39,0.39c10.54,2.09,21.01,4.6,31.61,6.29c9.79,1.56,19.71,2.33,29.58,3.33
				C741.7,359.44,746.78,359.69,751.85,360.05z M730.67,158.7c-4.48-9.7-8.86-11.98-18.12-10.77c-3.7,0.49-7.56-0.08-11.33-0.37
				c-3.76-0.29-7.63-1.65-11.24-1.12c-7.24,1.08-13.3,0.17-18.59-5.46c-2.98-3.17-7.77-1.74-8.54,2.36
				c-0.98,5.29-1.18,10.72-1.81,17.02C684.58,159.81,707.38,159.26,730.67,158.7z M112.36,831.65c-16.3,1.03-32.68,2.04-49.04,3.19
				c-1.23,0.09-2.4,1.26-3.58,1.96c-1.89,1.14-3.64,2.84-5.68,3.33c-2.42,0.58-5.08,0.14-7.64,0.14c1.2,0.93,2.48,1.46,3.68,1.32
				c5.52-0.66,8.89,1.94,11.73,6.47c3.25,5.2,7.13,10.02,10.85,14.92c3.19,4.2,6.71,3.97,9.31-0.57c3.31-5.77,6.57-11.57,9.77-17.4
				c5.29-9.65,5.27-9.67,15.95-7.74C112.8,838.19,113.39,837.56,112.36,831.65z M907.51,821.35c-6.26,5.41-14.27,11.69-21.38,18.87
				c-2.34,2.37-4.27,8.1-3,10.51c2.84,5.41,1.72,10.93,2.91,16.26c1.87,8.39,3.68,16.62-2.77,24c-0.23,0.27-0.03,0.92-0.03,1.64
				c3.14,0.41,6.46,1.66,9.31,0.97c2.61-0.63,5.41-2.92,6.87-5.26c1.57-2.51,1.75-5.93,2.38-8.98c0.33-1.58,0.87-3.43,0.37-4.82
				c-2.88-8.04-6.04-15.98-9.62-25.28C900.19,841.55,908.02,832,907.51,821.35z M945.08,752.84c-0.26-0.09-0.52-0.19-0.78-0.28
				c0.28,1.54,1.34,3.64,0.71,4.54c-6.64,9.48-7.38,11.16-8.07,21.91c2.25-2.56,4.34-4.9,6.39-7.27c2.76-3.19,5.52-2.7,7.95,0.23
				c1.24,1.5,2.38,3.32,2.85,5.18c0.99,3.93,3.72,4.79,7.1,4.7c2.57-0.07,5.13-0.43,8.37-0.72c-3.14-12.12-6.28-24.2-9.41-36.29
				c-0.37,0.09-0.74,0.18-1.11,0.28c0.79-1.17,1.58-2.34,2.36-3.49c-6.04-4.58-4.78-11.86-8.12-19.14
				c-2.65,8.18-4.89,15.1-7.11,22.03c-0.09,0.29,0.13,0.66,0.08,0.98C945.91,747.95,945.49,750.4,945.08,752.84z M519.24,642.49
				c-3.01-0.62-6.06-2.03-8.88-1.66c-6.32,0.84-9.64-0.34-12.37-5.42c-0.46-0.85-1.98-1.29-3.09-1.58c-1.21-0.31-2.62,0.04-3.78-0.37
				c-4.48-1.6-8.77-4.2-13.36-4.93c-9.1-1.45-15.34-7.3-21.98-12.66c-2.82-2.27-6.6-5.22-9.06-2.41c-5.12,5.87-9.51,4.02-14.51,0.73
				c-0.26-0.17-0.64-0.15-0.97-0.19c-4.93-0.62-9.86-1.23-16.06-2.01c1.96,1.75,2.81,2.89,3.95,3.46
				c7.59,3.85,15.27,7.54,22.87,11.37c3.04,1.53,5.81,4.29,8.95,4.75c6.74,0.99,13.7,0.59,19.91,4.13c0.13,0.07,0.34-0.01,0.49,0.05
				c5.79,2.32,11.57,4.68,17.38,6.95c3.49,1.36,7.11,3.83,10.53,3.6C505.97,645.85,512.56,643.86,519.24,642.49z M130.21,413.05
				c-0.11,0.56-0.22,1.12-0.34,1.67c15.1,6.03,30.11,12.29,45.33,18c12.07,4.53,25.44,5.05,36.88,11.76c1.56,0.91,3.65,0.92,6.56,1.6
				c-3.84-3.93-7.84-6.48-9.61-10.12c-4.98-10.29-14.44-12.01-24.87-14.61c1.54,2.53,2.86,4.22,3.62,6.13
				c0.73,1.82,0.88,3.88,1.28,5.83c-1.95-0.46-4.28-0.4-5.78-1.49c-5.04-3.64-10.16-6.52-16.56-7.41c-3.21-0.45-6.23-2.93-9.12-4.81
				c-6.09-3.95-11.93-8.32-18.11-12.13c-4.37-2.69-9.73-4.01-13.62-7.2c-7.2-5.92-15.42-7.85-24.96-8.63
				c1.39,1.29,2.35,2.31,3.43,3.18c7.47,6.05,15.64,10.51,25.31,12.14c4.91,0.83,9.64,2.69,13.13,6.44
				c-0.14,0.49-0.29,0.97-0.43,1.46C138.32,414.26,134.26,413.66,130.21,413.05z M179.23,1095c0.09-0.25,0.17-0.49,0.26-0.74
				c-4.1-1.61-8.17-3.29-12.29-4.83c-9.6-3.58-19.44-6.6-28.77-10.77c-12.11-5.41-23.93-11.52-35.68-17.69
				c-7.84-4.12-15.06-9.07-17.85-18.35c-0.85-2.83-3.13-5.29-3.69-8.13c-0.95-4.77-2.35-10.05-1.21-14.51
				c1.81-7.12,5.31-13.87,8.6-20.53c1.26-2.56,1.38-3.69-1.38-4.79c-11.34,10.01-16.26,22.18-13.93,37.1
				c2.79,17.88,13.24,30.07,29.39,36.85c14.67,6.15,29.89,10.99,44.95,16.18C158.09,1088.39,168.69,1091.61,179.23,1095z
				M585.43,442.51c-2.28-0.15-3.89-0.42-5.49-0.32c-5.4,0.31-10.82,0.55-16.19,1.19c-5.88,0.7-11.76,1.26-16.29,6.25
				c-1.34,1.47-4.86,1.25-7.38,1.27c-4.05,0.02-8.17-1-12.14-0.52c-9.47,1.15-15.85,6.94-17.38,14.96c3.86-0.74,7.7-1.97,11.56-2.07
				c4.02-0.1,8.07,1.2,12.11,1.29c1.9,0.04,3.88-1.22,5.75-2.05c3.55-1.57,6.92-4.24,10.59-4.7c10.69-1.34,21.48-1.82,32.68-2.67
				C583.9,451.39,584.62,447.21,585.43,442.51z M660.13,1015.59c5.57,8.62,11.42,10.96,20.07,7.91c2.83-1,5.41-2.84,7.93-4.55
				c6.23-4.21,12.43-8.49,18.52-12.9c2.09-1.52,4.78-3.19,5.54-5.37c2.05-5.9,7.02-9.63,9.84-14.87c0.64-1.19,0.97-2.55,1.48-3.94
				c-5.03-1.52-7.8-0.91-8.53,4.58c-0.39,2.96-1.99,6.13-3.97,8.4c-1.59,1.82-4.56,2.44-6.92,3.59c-0.58-2.52-1.6-5.05-1.6-7.57
				c0-2.34,1.09-4.68,1.77-7.29c-3.55,3.09-6.76,5.45-9.43,8.32c-3.47,3.73-8.35,5.59-10.78,11.01c-1.87,4.17-6.77,7.55-11.07,9.89
				C669.41,1014.75,664.74,1014.68,660.13,1015.59z M453.03,662.26c0.04,0.47,0.07,0.93,0.11,1.4c0.96,0.6,2.05,1.84,2.86,1.68
				c4.06-0.8,8.13-1.76,12.05-3.07c3.2-1.07,6.15-2.87,9.32-4.08c2.46-0.94,5.09-1.44,7.64-2.15c3.05-0.85,6.77-3.26,8.99-2.27
				c4.48,1.99,8.29,5.65,12.09,8.95c2.18,1.89,3.59,2.11,5.92,0.19c2.71-2.23,5.49-3.82,9.73-3.81c3.35,0,6.98-0.88,10.65-1.07
				c6.38-0.32,11.68-6.81,18.97-3.88c0.56,0.23,1.89-0.3,2.19-0.85c0.35-0.65,0.08-2.45-0.1-2.47c-3.57-0.49-7.16-0.82-10.75-1.12
				c-2.07-0.17-4.29,0.2-6.2-0.42c-4.34-1.41-8.48-4.51-12.78-4.64c-5.55-0.17-11.13,2.12-16.75,2.91c-4.65,0.66-9.4,0.55-14.08,1.03
				c-2.31,0.24-4.61,0.92-6.82,1.67c-3.25,1.1-6.83,4.09-9.53,3.39C466.17,651,461.22,660.47,453.03,662.26z M943.8,732.96
				c-0.32,0.04-0.64,0.07-0.96,0.11c0,2.08,0.36,4.26-0.12,6.22c-0.34,1.39-1.83,2.51-2.8,3.75c-1.03-1.06-2.3-1.98-3.03-3.22
				c-1.57-2.63-2.86-5.43-4.26-8.16c-0.48-0.02-0.97-0.04-1.45-0.07c-1.21,3.77-2.32,7.57-3.68,11.28c-0.61,1.65-1.72,3.11-2.61,4.66
				c-1.31-1.24-3.2-2.26-3.8-3.78c-1.36-3.44-2.07-7.12-3.05-10.71c-4.18,13.1-7.44,26.1-2.27,39.67c3.53-0.21,7.26-0.43,11.38-0.67
				c-0.97-3.71,1.36-4.34,4.68-4.23c1.16,0.04,3.05-0.41,3.43-1.2c3.01-6.27,6.44-12.48,8.32-19.12
				C944.83,742.99,943.8,737.83,943.8,732.96z M647.86,195.56c-0.37-0.1-0.73-0.2-1.1-0.29c-3.89,6.65-8.19,13.09-11.55,20
				c-3.47,7.14-5.97,14.75-8.99,22.11c-2.83,6.88-5.8,13.7-8.84,20.86c8.08-3.79,15.24-7.09,22.33-10.53
				c1.25-0.61,2.77-1.61,3.19-2.78c1.85-5.14,4.4-10.35,4.74-15.66C648.36,218.08,647.86,206.8,647.86,195.56z M465.08,383.38
				c6.65-6.62,8.45-6.27,15.53-0.92c2.32,1.75,7.05,1.96,9.96,0.9c4.14-1.51,6.74-5.28,5.81-10.4c-0.89-4.94-4.74-6.18-8.75-6.73
				c-13.19-1.82-25.96,1.22-38.71,3.93c-1.81,0.38-4.39,2.42-4.64,3.99c-0.99,6.47-1.22,13.06-1.73,19.61
				c0.54,0.09,1.07,0.17,1.61,0.26c3.4-3.94,6.83-7.84,10.18-11.83C457.47,378.47,463.37,378.87,465.08,383.38z M203.68,830.52
				c22.98,3.6,45.97,2.03,69.24,0.44c-1.45-1.9-3.24-3.09-4.87-4.46c-1.45-1.21-3.95-2.72-3.86-3.94c0.4-5.55-2.93-5.46-6.7-5.3
				c-16.61,0.71-33.22,1.3-49.82,2.12c-3.83,0.19-7.62,1.1-11.44,1.67c9.33,1.56,18.92,0.31,27.71,4.48
				C217.83,831.07,210.23,828.01,203.68,830.52z M217.54,386.85c1.93-1.07,3.98-1.97,5.76-3.24c5.07-3.62,9.85-7.68,15.08-11.03
				c7.77-4.98,15.52-9.75,18.9-19.11c0.64-1.76,2.36-3.15,3.65-4.66c3.37-3.94,6.78-7.84,10.17-11.76c-0.26-0.5-0.53-1.01-0.79-1.51
				c-2.04-0.1-4.19-0.67-6.11-0.22c-4.84,1.14-9.79,2.27-14.26,4.35c-2.37,1.1-4.52,3.87-5.57,6.38c-3.14,7.55-6.9,14.52-14.19,18.74
				C221.89,369.6,220.46,378.42,217.54,386.85z M505.64,380.96c-4.65,7.25-9.06,14.14-14.14,22.07c2.61-0.4,3.85-0.78,5.08-0.74
				c2.32,0.08,5.44-0.43,6.73,0.84c1.31,1.29,1.33,4.49,1.01,6.73c-0.4,2.78-1.74,5.43-2.67,8.13c0.44,0.17,0.88,0.34,1.32,0.52
				c1.3-3.12,2.72-6.18,3.86-9.36c1.48-4.15,3.79-7.82,8.22-8.61c7.58-1.35,11.48-6.52,14.87-12.59c1.86-3.33,3.68-6.72,5.87-9.83
				c1.39-1.98,3.16-4.51,5.21-5.05c6.35-1.69,7.22-6.41,7.79-11.76c-10.29,10.43-16.63,13.48-20.74,9.92
				c-5.81,5.02-11.61,10.08-17.48,15.07c-0.8,0.68-2.06,1.59-2.78,1.37c-0.83-0.26-1.56-1.59-1.87-2.6
				C505.58,383.89,505.73,382.57,505.64,380.96z M307.7,336.53c-14.42-9.93-44.88,3.88-49.21,21.83c6.84,0.67,13.77,1.36,20.71,1.98
				c0.75,0.07,1.68-0.12,2.29-0.53C291.23,353.17,301.87,347.54,307.7,336.53z M327.68,387.97c0.36,0.47,0.72,0.94,1.08,1.41
				c2.15-0.85,4.67-1.25,6.35-2.66c2.82-2.37,5.46-3.4,9.1-2.22c1.26,0.41,3.04-0.16,4.39-0.74c7.2-3.11,4.89-10.32,7.36-15.99
				c-7.43-1.91-10.76,5.38-16.69,6.18c-0.42-1.4-0.78-2.61-1.3-4.35c-2.81,0.72-5.69,0.94-8.03,2.19c-2.39,1.27-3.85,1.52-6.14-0.33
				c-4.66-3.76-11.93-2.53-15.5,2.2c-3.3,4.38-6.75,8.68-9.75,13.26c-1.41,2.16-1.94,4.9-2.97,7.64c8.53,1.55,8.56-8.59,15.14-9.19
				c0.36,1.98,0.65,3.53,1.15,6.28c1.99-1.8,3.47-2.9,4.65-4.26c2.59-3.02,4.92-6.27,7.61-9.21c0.72-0.78,2.66-1.36,3.42-0.93
				c0.8,0.44,1.22,2.24,1.16,3.41C328.59,383.12,328.05,385.54,327.68,387.97z M137.86,1074.1c-0.08,0.38-0.15,0.77-0.23,1.15
				c2.34,1.16,4.61,2.54,7.05,3.43c12.12,4.42,24.32,8.64,36.44,13.08c4.15,1.52,7.88,1.02,12.03-0.41
				c14.58-5,29.18-9.98,44.02-14.11c9.36-2.6,19.14-3.67,28.56-5.39c0.71-10.08,0.72-10.05-8.2-7.79c-1.76,0.45-3.63,0.45-5.4,0.85
				c-3.74,0.84-9.06-1.39-10.04,5.05c-0.05,0.3-0.8,0.54-1.25,0.73c-9.2,3.7-18.35,7.52-27.62,11.02c-4.8,1.82-9.79,3.16-14.74,4.55
				c-1.71,0.48-3.6,0.77-5.36,0.6c-7.76-0.72-15.49-2.05-23.26-2.36c-7.55-0.3-7.71-1.5-7.9-8.24c-0.04-1.35-1.9-3.82-2.85-3.79
				C152,1072.74,144.93,1073.5,137.86,1074.1z M806.97,257.25c-0.3,2.03-0.6,2.71-0.45,3.27c1.67,6.04,0.74,11.07-3.72,16.05
				c-4.54,5.07-3.63,9,1.34,14c0.82,0.82,1.32,2.07,2.25,2.63c1.92,1.16,4.05,2.82,6.08,2.8c7.04-0.07,13.53,1.09,19.17,5.61
				c1.04,0.83,2.37,1.29,4.05,2.18C832.65,284.03,818.48,272.26,806.97,257.25z M276.09,1053.3c4.47,0.56,8.28,0.82,11.98,1.6
				c1.47,0.31,3.68,1.64,3.84,2.75c0.48,3.46,2.32,3.81,4.84,2.8c2.36-0.94,5.64-0.95,4.5-5.51c-1.4-5.58-2.96-11.53-2.39-17.1
				c0.89-8.61-3.17-15.54-5.45-23.1c-0.35-1.16-1.13-2.2-2.1-4.03C281.35,1023.56,280.81,1038.83,276.09,1053.3z M687.87,1097.92
				c-0.9-2.49-1.16-4.24-2.07-5.54c-7.97-11.44-15.98-22.85-24.25-34.07c-0.63-0.86-4.73-0.6-4.85-0.17
				c-0.97,3.33-2.67,7.29-1.66,10.18c2.17,6.19,5.68,11.95,8.92,17.72c4.29,7.64,8.97,15.07,13.21,22.73
				c1.42,2.56,3.07,2.91,4.43,0.8C684.08,1105.74,685.96,1101.54,687.87,1097.92z M475.04,511.8c0.37,0.96,0.74,1.93,1.11,2.89
				c7.1-1.08,14.18-2.3,21.29-3.2c9.88-1.25,19.75-5.39,29.73-0.36c0.53,0.27,1.3,0.31,1.89,0.17c4.49-1,8.93-2.34,13.47-3.06
				c12.39-1.97,24.62-5.39,37.38-1.01c5.93,2.03,9.53-1.13,9.24-5.84c-2.84-0.48-5.57-1.1-8.34-1.38c-5.4-0.56-10.95-1.89-16.21-1.19
				c-10.45,1.38-20.75,3.96-31.12,5.94c-1.05,0.2-2.23-0.3-3.35-0.42c-5.17-0.55-10.35-1.66-15.5-1.5
				c-10.71,0.33-20.73,5.49-31.77,4.67C480.37,507.31,477.65,510.28,475.04,511.8z M518.57,304.62c-0.54-4.49-3.91-8.56-10.02-9.14
				c-11.65-1.1-23.39-1.76-35.09-1.76c-9.52,0-18.77,1.24-28.05,4.89c-9.2,3.61-13.54,6.69-13.65,16.72
				c5.87-7.73,14.82-9.21,22.82-11.14c9.22-2.22,19.11-2.42,28.68-2.24C494.96,302.14,506.63,303.65,518.57,304.62z M313.64,1093.93
				c5.38-1.99,10.12-3.75,14.86-5.51c0.31-0.12,0.81-0.22,0.87-0.43c0.49-1.79,1.86-4.23,1.16-5.29c-2.91-4.42-6.29-8.58-9.9-12.45
				c-0.62-0.67-3.23,0.47-4.91,0.85c-1.4,0.32-2.76,0.92-4.17,1.12c-1.67,0.24-3.44-0.06-5.08,0.29c-5.23,1.11-10.38,2.59-15.63,3.57
				c-5.14,0.96-8.87,5.07-7.45,9.02c0.94,2.61,2.31,5.08,3.6,7.55c0.4,0.76,1.13,1.8,1.82,1.9c4.03,0.56,8.09,0.88,11.62,1.23
				c0.55-2.73,0.81-4.73,1.38-6.64c0.89-2.97,1.87-5.94,3.07-8.8c0.58-1.37,1.77-2.48,2.68-3.71c1.07,1.34,2.68,2.53,3.12,4.06
				C311.93,1085,312.68,1089.44,313.64,1093.93z M201.29,854.02c-4.73-9.13-7.41-10.94-15.97-10.74c-13.12,0.31-26.24,0.73-39.35,1.2
				c-3.89,0.14-7.77,0.62-11.76,0.95c3.37,10.3,4.41,11.01,14.19,10.62C165.81,855.34,183.23,854.7,201.29,854.02z M746.42,1006.01
				c2.26-0.31,5.13,0,7.26-1.12c5.77-3.03,11.46-6.33,16.71-10.18c4.16-3.05,5.75-7.94,4.45-12.97c-2.63-10.17-7.62-18.78-17.8-23.22
				c-5.29-2.31-10.6-4.61-16.02-6.59c-2.84-1.04-5.93-1.38-8.91-2.04c0.7,1.48,1.71,1.92,2.68,2.45c7.72,4.19,15.31,8.66,23.19,12.51
				c5.31,2.59,5.15,7.88,7.07,12.15c0.21,0.47-0.74,1.69-1.4,2.28c-4,3.64-8.19,7.08-12.07,10.84c-1.36,1.31-2.4,3.22-2.94,5.05
				C747.7,998.37,747.26,1001.71,746.42,1006.01z M63.06,982.04c5.2-6.59,5.31-14.95-0.48-20.92c-4.18-4.3-9.11-7.97-14.06-11.41
				c-7.2-5-15.88-4.67-22.61-0.93c-5.17,2.87-8.37,2.42-12.78,0.32c-1.56-0.75-3.57-0.49-5.28-1.01c-2.26-0.7-4.59-1.31-4.89,1.89
				c-0.24,2.52-1.5,6.1,2.82,6.75c3.98,0.6,8.02,0.87,12.03,1.22c8,0.7,16,1.87,24.01,1.86c5.75-0.01,10.63,1.07,14.04,5.69
				C59.47,970.37,64.81,974.62,63.06,982.04z M691.55,1095.22c6.79-4.65,8.47-12.51,3.74-19.42c-2.34-3.42-4.78-6.79-7.48-9.93
				c-6.44-7.49-13.07-14.82-19.56-22.15c-6.76,5.06-7.55,7.9-3.34,13.99C673.55,1070.23,682.5,1082.52,691.55,1095.22z
				M807.33,255.23c0.22-0.42,0.44-0.83,0.66-1.25c-1.22-0.91-2.41-2.57-3.66-2.62c-9.1-0.31-18.23-0.64-27.32-0.3
				c-9.38,0.35-18.74,2.03-28.11,2.05c-10.13,0.02-20.26-1.3-30.4-1.84c-6.47-0.35-12.96-0.66-19.42-0.41
				c-4.33,0.17-8.61,1.39-12.91,2.11c-1.43,0.24-3.02,0.87-4.28,0.49c-10.99-3.31-20.09,2.72-29.7,5.99
				c-1.65,0.56-2.92,2.67-3.97,4.32c-0.45,0.7-0.26,2.39,0.31,3c0.45,0.47,2.31,0.36,2.89-0.19c5.85-5.52,12.7-8.61,20.72-8.94
				c4.01-0.17,8.04-0.04,12.05-0.12c1.12-0.02,2.25-0.27,3.36-0.49c3.84-0.75,7.65-2.08,11.5-2.18c8.61-0.24,17.25,0.09,25.87,0.27
				c1.64,0.03,3.27,0.52,4.91,0.64c8.91,0.62,17.98,2.55,26.69,1.48c14.34-1.77,28.58-3.1,43.01-2.4
				C802.13,254.96,804.73,255.1,807.33,255.23z M515.99,315.93c-0.18-0.63-0.36-1.27-0.54-1.9c-0.6-0.21-1.2-0.59-1.81-0.62
				c-15.29-0.71-30.58-1.66-45.88-1.96c-9.69-0.19-18.61,3.65-27.37,7.39c-1.12,0.48-2.42,3.08-2.02,3.99
				c0.58,1.32,2.58,2.69,4.06,2.8c4.84,0.35,9.72,0.13,15.12,0.13c4.24-7.46,11.4-2.81,18.06-2.78c0-3.31,0-6.12,0-10.1
				c4.67,4.87,8.32,8.68,12.16,12.68c6.08-5.32,12.21-7.33,19.41-6.98C510.07,318.71,513.05,316.87,515.99,315.93z M120.74,377.85
				c2.04,1.58,3.9,3.48,6.14,4.7c7.72,4.21,15.52,8.31,23.39,12.23c8.6,4.29,8.65,4.17,14.85-2.93c1.09-1.25,2.54-2.23,3.45-3.59
				c1.88-2.79,3.5-5.76,5.4-8.93c-1.57-0.6-2.15-0.97-2.76-1.03c-8.55-0.9-17.11-1.81-25.67-2.61c-3.44-0.32-7.37-1.5-10.24-0.28
				C130.55,377.4,126.21,379.27,120.74,377.85z M373.64,305.64c-3.81,3.85-5.59,7.44-5.58,12c0.01,6.12,0.5,12.34-0.37,18.35
				c-1.37,9.44-3.69,18.75-5.59,28.01c7.3,1.41,14.59,2.82,23.45,4.53c-2.23-8.4-3.58-15.28-5.93-21.81
				c-1.85-5.15-5.86-9.27-4.73-15.69c1.09-6.25,0.75-12.78,0.73-19.18C375.61,310.05,374.5,308.25,373.64,305.64z M279.65,401.49
				c4.94-2.81,7.34-6.39,7.83-11.45c0.72-7.48,4.31-12.75,12.03-14.63c1.81-0.44,3.48-1.45,3.99-1.68
				c-4.61-1.67-9.79-3.39-14.83-5.42c-4.67-1.88-9.26-1.23-12.48,2.01c-5.6,5.63-10.49,11.98-12.28,20.13
				c-1.33,6.09-1.05,6.47,5.29,6.32C273.27,396.66,277.81,395.55,279.65,401.49z M299.98,257.8c5.44-2.05,10.67-4.57,16.18-5.98
				c13.88-3.54,13.93-3.34,13.55-17.47c-0.01-0.33-0.01-0.66-0.03-1c-0.45-8.02-5.14-10.64-12.01-6.43
				c-1.85,1.13-2.99,3.37-4.66,4.87c-2.23,2-4.57,3.91-7.04,5.59c-3.23,2.2-6.78,3.97-9.88,6.33c-0.85,0.65-1.19,2.96-0.76,4.14
				C296.51,251.09,298.22,254.13,299.98,257.8z M813.29,835.95c-0.64,0.02-1.28,0.04-1.92,0.06c-1,3.82-2.17,7.6-2.94,11.47
				c-1.2,6.03,1.08,9.8,6.44,13.52c9.63,6.67,18.29,14.75,27.26,22.36c1.99,1.68,2.84,5.15,6.62,3.22c2.46-1.25,5.56-1.65,7.52-3.39
				c3.45-3.07,2.82-6.59-0.34-9.93c-8.43-8.92-19.28-11.97-30.94-12.98c-6.99-0.6-8.17-1.41-9.49-8.36c-0.4-2.11-0.41-4.3-0.71-6.43
				C814.35,842.3,813.8,839.13,813.29,835.95z M785.34,619.03c0.05,0.29,0.09,0.58,0.14,0.87c5.07,0,10.2-0.52,15.18,0.14
				c5.44,0.72,10.73,2.57,16.11,3.84c2.75,0.65,5.83,0.53,8.28,1.75c5.74,2.86,11.17,4.36,17.55,1.63c1.78-0.76,4.78,1.23,7.18,2.09
				c0.94,0.34,1.76,1.05,1.52,0.91c2.98-3.1,5.39-5.62,8.35-8.7c-0.7-0.31-2.28-1.04-3.88-1.74c-1.45-0.63-3.49-0.8-4.27-1.89
				c-2.28-3.2-5.08-3.79-8.62-2.98c-1.27,0.29-2.65,0.27-3.97,0.21c-12.26-0.58-24.52-1.49-36.78-1.57
				c-2.56-0.02-4.97,3.51-7.76,4.46C791.57,618.98,788.37,618.74,785.34,619.03z M674.81,1111.56c-0.76-2.59-0.93-4.34-1.73-5.73
				c-7.07-12.4-14.21-24.76-21.5-37.02c-0.42-0.71-3.37-0.88-3.45-0.67c-0.96,2.58-2.75,5.6-2.11,7.88
				c2.33,8.29,5.34,16.42,8.42,24.48c2.89,7.58,6.25,14.97,9.28,22.5c1.16,2.89,3.02,4.22,4.87,1.26
				C671.2,1120.11,672.97,1115.43,674.81,1111.56z M460.07,539.81c-0.04,0.38-0.09,0.76-0.13,1.14c5.43,2.46,10.87,4.88,16.27,7.39
				c1.18,0.55,2.13,1.6,3.32,2.09c1.9,0.78,3.96,1.93,5.87,1.79c9.15-0.66,18.29-3.73,27.39,0.47c0.77-0.84,1.47-1.54,2.1-2.3
				c3.51-4.25,0.45-8.68-0.27-12.8c-0.23-1.3-4.71-2.42-7.32-2.61c-3.59-0.26-7.31,0.12-10.87,0.79c-5.81,1.1-11.48,3.24-17.32,3.88
				C472.82,540.34,466.41,539.81,460.07,539.81z M304.21,1001.06c-8.02,2.69-9.82,7.32-7.09,14.3c2.13,5.43,3.82,11.15,4.79,16.89
				c1.06,6.29,0.99,12.76,1.58,19.13c0.11,1.13,0.78,2.77,1.66,3.19c5.32,2.56,10.81-0.61,10.49-6.54
				c-0.4-7.48-1.83-14.89-2.78-22.34c-0.43-3.35-0.1-6.97-1.28-10.02C309.66,1010.68,306.79,1006.05,304.21,1001.06z M586.47,231.67
				c-3.27-3.38-4.97-6.52-7.49-7.47c-5.81-2.2-5.97-1.76-4.13-7.64c0.59-1.9,1.32-3.79,1.61-5.74c0.14-0.91-0.3-2.34-0.99-2.88
				c-2.75-2.16-7.82-0.49-9.65,2.61c-4.07,6.9-2.24,14.45-1.64,21.45c1,11.72,0.28,22.72-5.36,33.21c-1.18,2.2-1.48,4.87-2.38,8
				c2.8-0.37,4.67-0.62,5.98-0.8c1.21-3.82,2.21-6.96,3.21-10.1c2.04-6.41,3.99-12.85,6.2-19.2c0.55-1.59,1.62-3.3,2.95-4.25
				C578.14,236.49,581.78,234.51,586.47,231.67z M274.85,453.09c2.54-7.09,4.64-13.53,7.18-19.78c1.23-3.04,3.09-5.86-0.36-8.4
				c-2.32-1.71-6.83,0.63-8.62,4.29c-1.31,2.68-2.21,5.65-3.92,8.03c-0.97,1.34-3.23,1.75-4.9,2.57c-0.77-1.64-1.8-3.22-2.21-4.94
				c-0.31-1.3,0.19-2.8,0.46-5.59c-5.34,0.47-9.98,0.46-14.42,1.39c-4.66,0.98-9.14,2.85-13.7,4.34c0.03,0.77,0.05,1.53,0.08,2.3
				c4.78,1.52,9.56,3.03,14.34,4.57c1.57,0.51,3.66,0.64,4.57,1.73C258.9,450.24,267.49,449.34,274.85,453.09z M379.62,315.77
				c-0.65,6.63-2.35,12.85-1.5,18.7c0.73,5,4.33,9.61,6.85,14.29c0.29,0.54,1.75,0.66,2.65,0.63c3.43-0.14,6.86-0.43,10.76-0.69
				c0.33-1.21,1.02-2.76,1.12-4.34c0.47-7.39,0.9-14.78,1-22.18c0.01-1.02-1.73-2.71-2.92-2.98
				C391.78,317.88,385.89,316.93,379.62,315.77z M641.64,1074.53c-0.65,0.03-1.3,0.06-1.96,0.09c-0.37,2.76-1.26,5.57-1.01,8.27
				c0.46,4.94,1.44,9.85,2.49,14.71c2.77,12.79,5.64,25.55,8.63,38.29c0.42,1.78,1.72,3.36,2.64,5.07c0.99-0.46,1.52-0.56,1.84-0.88
				c0.59-0.57,1.16-1.21,1.56-1.93c4.42-7.99,6.5-15.77,2.08-24.95c-5.59-11.61-9.84-23.87-14.69-35.84
				C642.81,1076.37,642.17,1075.47,641.64,1074.53z M363.18,233.71c-3-0.49-6.47,2.41-9.57,6.49c-1.44,1.89-3.34,4-5.45,4.68
				c-4.94,1.6-9.95,5.15-15.06,0.46c-3.74,6.79-9.91,7.49-15.87,9.1c-5.64,1.52-10.99,4.15-16.47,6.28c0.13,0.45,0.25,0.9,0.38,1.35
				c2.56-0.34,5.17-0.46,7.67-1.06c8.33-2.01,16.52-4.96,24.96-6.11c11.11-1.5,22.42-1.53,33.63-2.41c3.73-0.29,7.4-1.27,11.68-2.03
				C373.56,244.68,369.09,239.96,363.18,233.71z M872.38,781.6c7.61,1.55,15.36,3.13,23.17-0.45c-2.21-11.87-4.4-23.6-6.74-36.13
				c-2.14,2.62-3.35,4.1-5.08,6.23c-1.24-3.97-2.14-6.86-3.03-9.75c-0.42-0.01-0.83-0.02-1.25-0.03
				C877.13,754.65,874.81,767.83,872.38,781.6z M880.26,451.67c-0.43-0.39-0.87-0.78-1.3-1.18c-6.32,1.14-12.62,2.45-18.97,3.36
				c-5.51,0.78-8.25,4.51-10.42,8.95c-3.05,6.23-5.94,12.53-8.95,18.78c-1.59,3.29-3.28,6.54-4.92,9.8c0.43,0.34,0.86,0.67,1.28,1.01
				c2.93-2.54,5.91-5.02,8.78-7.63c1.96-1.78,3.45-4.5,5.71-5.44c8.12-3.38,15.13-8.06,20.31-15.22
				C874.72,460.04,877.44,455.82,880.26,451.67z M661.06,962.21c-0.79-0.38-1.59-0.77-2.38-1.15c-0.87,1.98-2.34,3.92-2.48,5.94
				c-0.39,5.85-2.94,10.45-6.97,14.46c-3.3,3.28-6.4,6.77-9.84,9.89c-5.1,4.61-5.93,10.61-5.6,16.84c0.07,1.41,2.19,3.14,3.76,3.91
				c1.16,0.57,3.11,0.14,4.46-0.44c2.56-1.1,5.11-2.39,7.37-4.01c2.98-2.15,5.97-4.45,8.25-7.27c0.44-0.55-2.38-3.74-3.74-5.78
				c7.21-6.86,7.21-6.86,1.05-12.15c7.92-8.06,7.93-8.09,3.2-14.12C659.17,966.12,660.11,964.17,661.06,962.21z M28.24,1063.54
				c-0.19-2.52-0.14-4.31-0.49-6.04c-1.34-6.72-3.42-13.36-4.04-20.14c-0.65-7.13-3.66-12.67-8.06-17.78
				c-0.38,0.16-0.64,0.2-0.64,0.27c-0.98,7.84-7.81,13.81-7.07,22.25c0.07,0.84-1.15,1.85-1.86,2.7c-3.83,4.62-3.07,9.59,2.36,11.83
				C14.66,1059.2,21.15,1061.1,28.24,1063.54z M694.18,306.16c0-4.62,0.19-9.6-0.14-14.54c-0.08-1.18-1.79-3.08-2.92-3.22
				c-8.74-1.09-17.4,0.07-25.79,2.44c-1.94,0.55-3.98,3.33-4.59,5.49c-1.88,6.57,3.3,10.03,5.52,12.69
				C676.2,307.99,684.74,307.12,694.18,306.16z M226.08,960.86c-0.19,0.51-0.38,1.03-0.58,1.54c3.38,1.99,3.48,7.53,9.05,7.23
				c18.92-1.04,37.84-1.96,56.76-2.98c1.69-0.09,3.35-0.64,5.64-1.11c-3.16-4.05-4.17-8.74-10.31-8.17
				c-10.75,1-21.56,1.29-32.34,1.89C244.89,959.78,235.48,960.32,226.08,960.86z M806.01,408.15c1.01-6.45,2.05-11.95,2.66-17.5
				c0.27-2.4-0.51-4.9-0.52-7.36c-0.01-2.62,0.25-5.24,0.53-7.85c0.5-4.67,1.11-9.33,1.62-13.52c-8.76,1.67-16.65,3.18-23.49,4.48
				c1.22,3.38,2.77,6.75,3.65,10.29C793.3,388.14,799.24,397.95,806.01,408.15z M855.52,833.25c0.52,7.81,6.96,11.37,11.26,16.34
				c1.72,1.98,3.64,1.15,5.33-1.02c2.23-2.86,4.92-5.37,7.49-7.96c5.9-5.96,11.84-11.88,17.73-17.78c-3.05-3.7-5.78-3.62-8.68-1.15
				c-4.45,3.78-9.64,4.93-15.35,4.45c-4.07-0.34-8.17-0.46-13.18-0.73C863.54,831.76,857.13,830.8,855.52,833.25z M331.14,297.2
				c-1.32,0-2.8-0.17-4.23,0.03c-6.23,0.87-6.22,0.91-6.21-4.36c-2.54,1.66-5.45,5.16-8.23,5.06c-6.84-0.25-11.14,2.58-15.27,7.5
				c-2.41,2.88-6.15,4.64-9.29,6.9c-0.54-0.53-1.08-1.07-1.62-1.6c2.07-4.37,4.14-8.75,6.21-13.12c-4.92,5.89-4.96,5.78-13.45,9.07
				c-1.58,0.61-2.68,2.47-3.94,3.69c4.09,1.73,7.17,3.03,10.74,4.53c14.31-8.39,29.78-2.68,45.28-0.52
				C331.14,308.54,331.14,303.2,331.14,297.2z M519.58,550.47c1.02,0.05,2.32,0.27,3.58,0.13c9.85-1.05,19.7-2.15,29.54-3.34
				c0.83-0.1,1.92-0.79,2.29-1.51c2.3-4.52,4.42-9.13,6.65-13.8c-0.68-0.28-1.14-0.66-1.55-0.61c-12.66,1.35-25.33,2.59-37.95,4.25
				c-2.96,0.39-4.81,2.57-3.74,6.28C519.17,544.51,519.18,547.35,519.58,550.47z M845.34,781.59c-1.07-9.22-1.7-17.93-3.2-26.5
				c-1.15-6.59-0.29-14.23-7.69-18.9c-0.83,3.29-1.79,6.39-2.39,9.57c-1.58,8.29-2.93,16.63-4.56,24.91
				c-0.37,1.88-1.39,3.68-2.34,5.39c-1.43,2.57-1.01,4.14,1.78,5.49C833.51,784.73,839.75,781.42,845.34,781.59z M331.24,325.98
				c-6.1,1.17-11.6-2.08-15.48,4.05c-3.34,5.27-7.43,10.07-11.17,15.08c-1.96,2.62-3.88,5.27-6.09,8.27
				c2.17,0.95,3.83,1.62,5.45,2.39c8.45,3.98,12.23,3.14,18.06-4.02c0.84-1.03,1.88-1.89,2.72-2.91c2.58-3.12,5.12-6.28,8.25-10.13
				c-5.74-0.09-5.57-2.25-3.9-5.5C330.06,331.33,330.35,329.1,331.24,325.98z M819.77,965.41c16.76,21.38,41.38,35.78,60.89,35.59
				c-3.23-1.86-6.56-3.57-10.02-4.94c-2.88-1.14-5.27-2.26-3.03-5.73c-3.72-2.62-9.73-1.43-10.18-7.92c-0.06-0.79-3.52-1.31-5.39-2
				c-1.76-0.65-3.5-1.36-5.95-2.32c2.27-1.67,3.76-2.76,5.95-4.37c-2.55-0.61-4.26-1.19-6-1.41c-3.79-0.46-7.61-0.63-11.39-1.14
				c-1.31-0.18-2.53-1.04-3.8-1.59c0.89-1.04,1.79-2.07,2.66-3.12c0.25-0.31,0.39-0.7,0.76-1.4c-2.53,0-4.69-0.04-6.84,0.01
				C825.13,965.13,822.84,965.27,819.77,965.41z M637.2,1149.51c7.37-5.88,10.29-14.87,8.39-22.75c-3.37-14.01-6.36-28.12-9.59-42.17
				c-0.43-1.87-1.32-3.63-2-5.44c-0.72,0.03-1.45,0.06-2.17,0.09c-0.49,2.31-1.53,4.65-1.39,6.91c0.92,14.41,1.9,28.83,3.28,43.2
				C634.37,1136.02,635.98,1142.6,637.2,1149.51z M831.38,410.78c7.37-2.23,14.33-4.22,21.17-6.52c1.36-0.46,2.83-1.95,3.35-3.31
				c2.47-6.53,4.71-13.15,6.88-19.78c1.06-3.24-0.33-4.5-3.55-3.48c-5.06,1.61-10.27,2.93-15.04,5.17c-2.4,1.13-4.82,3.68-5.78,6.15
				C835.71,395.92,833.77,403.15,831.38,410.78z M737.28,297.37c5.9-0.46,11.34-0.6,16.7-1.36c7.81-1.11,15.61,4.11,23.43-0.15
				c0.98-0.53,2.6,0.08,3.92,0.22c7.97,0.86,15.94,1.74,23.91,2.61c0.34-0.59,0.69-1.18,1.03-1.77c-3.26-2.77-6.2-7.32-9.84-7.98
				c-9.1-1.65-18.61-4.14-27.71,0.75c-0.78,0.42-1.92,0.31-2.87,0.23c-7.36-0.62-14.7-1.68-22.06-1.85
				C736.32,287.9,735,290.29,737.28,297.37z M279.71,839.24c0-3.14,0.14-5.79-0.04-8.42c-0.17-2.59,0.28-6.46-1.18-7.52
				c-5.06-3.67-4.35-9.41-5.72-14.12c-2.34-8.03-4.73-10.72-13.27-10.9c-1.84-0.04-3.68-0.34-5.52-0.53
				c-0.34-0.62-0.68-1.23-1.02-1.85c2.1-1.17,4.2-2.35,6.3-3.52c-0.29-0.21-0.59-0.42-0.88-0.63c-6.03,0-12.06,0-19.53,0
				c1.62,2.67,2.09,4.52,3.2,5.08c5.71,2.89,5.77,2.78,0.31,7.46c2.38,1.62,4.35,3.6,6.72,4.43c4.44,1.57,9.16,2.31,13.67,3.71
				c2.84,0.88,6.72,8.31,6.03,11.38C277.17,825.22,276.6,833.27,279.71,839.24z M830.08,981.14c-14.83-17.92-34.08-28.04-55.54-34.66
				c1.25,1.62,2.89,2.69,4.35,3.97c6.11,5.33,12.31,10.58,18.18,16.16c2.2,2.09,3.61,5.01-1.5,8.14c8.26,3.17,15.27,5.86,22.47,8.62
				C821.06,975.07,825.75,981.45,830.08,981.14z M769.35,409c0.28-6.11,0.48-6.3,5.58-6.83c6.88-0.71,13.11,0.57,18.69,5.04
				c3.32,2.65,6.97,5.18,12.08,4.83c-2.34-3.2-4.18-5.96-6.3-8.49c-1-1.19-2.44-2.54-3.86-2.78c-6.52-1.08-13.09-2.2-19.68-2.56
				c-16.03-0.88-32.07-1.35-48.11-1.97c-1.75-0.07-3.51-0.01-5.26-0.01c-0.2,0.52-0.41,1.04-0.61,1.56c1.53,0.93,3.02,1.94,4.6,2.78
				c2.39,1.28,6.08,1.84,7.04,3.79c3.26,6.6,10.73,6.97,15.24,11.48c0.08,0.08,0.55-0.24,0.68-0.3c0.34-2.92-0.07-6.16,1.22-8.43
				c1.2-2.11,2.95-3.31,1.02-5.92c-0.38-0.51,1.15-3.24,1.28-3.2c3.39,1.04,6.88,2.01,9.94,3.74c0.71,0.4-0.07,3.47-0.19,5.73
				C764.53,407.89,766.73,408.4,769.35,409z M329.43,317.5c-5.56-1.29-10.88-2.99-16.33-3.67c-8.77-1.08-17.57-1.1-25.62,3.61
				c-2.23,1.31-3.96,1.57-5.91-1.01c-2.33-3.09-7.13-2.84-8.9,0.19c-1.36,2.33-2.16,4.99-3.03,7.06c3.88,1.62,7.13,2.99,9.44,3.95
				c5.88-1.32,11.14-2.62,16.43-3.68c8.51-1.7,16.92,2.62,25.79-0.13C325.12,322.65,327.99,322.27,329.43,317.5z M404.88,793.29
				c0.33,0.04,0.67,0.08,1,0.12c-0.96-4.37-0.48-10.55-3.19-12.71c-4.86-3.87-3.53-12.63-11.38-13.43c-0.21-0.02-0.51-0.52-0.52-0.8
				c-0.16-5.32-4.42-6.99-7.38-8.77c-3.12,2.18-6.14,6-8.77,5.74c-4.53-0.44-5.42,1.32-6.39,4.72c-0.72,2.52-1.77,5.06-3.23,7.21
				c-0.73,1.07-2.71,1.31-4.15,1.86c-0.61,0.23-1.63,0.04-1.9,0.43c-1.49,2.12-3.66,4.29-3.88,6.6c-0.13,1.39,2.88,3.08,4.68,4.83
				c4.16-9.03,7.37-10.05,13.44-4.74c1.28-3.66,2.37-7.25,3.82-10.68c0.58-1.38,1.96-2.42,2.97-3.62c1.02,1.07,2.07,2.12,3.03,3.24
				c0.39,0.45,0.58,1.07,1.08,2.02c0.79-0.93,1.27-1.53,1.79-2.09c3.39-3.58,5.24-3.07,6.8,1.73c0.41,1.26,1.28,2.38,2.04,3.5
				C398.11,783.4,401.51,788.34,404.88,793.29z M564.69,570.55c4.29,0.44,8.98,2.43,11.96,0.95c9.51-4.7,18.37-10.7,27.51-16.16
				c1.33-0.79,2.76-1.41,4.14-2.1c0.3,1.67,0.8,3.32,0.86,5c0.13,3.73,0.04,7.46,0.04,11.13c6.45,0.68,9.21-1.26,10.15-6.88
				c0.52-3.11,0.81-6.26,1.45-9.34c0.6-2.89-0.34-4.78-3.26-4.88c-6-0.2-12.01-0.06-18.56-0.06c1.59,4.74-1.02,6.6-4.29,8.12
				c-3.75,1.75-7.41,3.71-11.19,5.38c-1.1,0.48-2.63,0.66-3.76,0.31C573.47,560.14,567.07,563.47,564.69,570.55z M291.7,933.76
				c0.71-0.12,1.42-0.25,2.14-0.37c0-6.28,0.06-12.55-0.06-18.82c-0.02-1.01-0.61-2.49-1.39-2.93c-7.96-4.48-15.96-8.91-24.11-13.03
				c-1.65-0.83-4.75-1.33-4.83,2.15c-0.18,7.57-0.05,15.14-0.05,22.71c0.42,0.43,0.84,0.86,1.26,1.29c0.43-6.07,4.23-4.84,7.39-5.02
				c3.65-0.2,7.32-0.26,10.95-0.68c4.37-0.5,6.39,0.76,6.34,5.63C289.28,927.7,290.85,930.73,291.7,933.76z M798.58,232.99
				c0.18-0.56,0.35-1.12,0.53-1.68c-1.53-0.61-3.05-1.74-4.58-1.74c-4.63-0.02-9.27,0.48-13.9,0.61c-11.9,0.35-23.8,0.71-35.71,0.89
				c-12.77,0.19-25.55-0.18-38.3,0.32c-14.85,0.58-29.69,1.76-44.51,2.9c-1.65,0.13-3.2,1.69-5.37,2.92
				c21.45-0.82,41.7-1.79,61.96-2.31c22.36-0.57,44.74-0.69,67.11-1.09C790.07,233.73,794.32,233.27,798.58,232.99z M215.1,1047.67
				c-3.02-3.89-6.18-7.21-8.45-11.06c-4.39-7.46-8.25-15.25-16.73-19.1c-1.4,4.08-3.48,7.98-3.94,12.06
				c-0.59,5.28,0.06,10.71,0.39,16.07c0.03,0.56,1.49,1.08,2.32,1.54C197.65,1052.09,206.5,1046.12,215.1,1047.67z M673.75,1027.63
				c-0.15,0.62-0.31,1.24-0.46,1.85c11.7,11.14,23.39,22.28,35.58,33.9c3.31-5.95,5.68-12.11,4.87-19.09
				c-0.13-1.08-1.68-2.38-2.87-2.89c-9.7-4.17-19.43-8.27-29.25-12.16C679.18,1028.27,676.39,1028.15,673.75,1027.63z M520.55,312.45
				c0.36,0.42,0.73,0.84,1.09,1.27c3.18-0.65,6.61-0.75,9.51-2.05c8.68-3.88,17.06-8.43,25.76-12.25c2.21-0.97,5.2-0.07,7.8-0.29
				c5.38-0.47,11.02-0.27,16.05-1.89c8.06-2.59,14.04-10.22,23.7-9.51c4.5,0.33,9.11-0.78,15.01-1.37c6.83-7.55,17.49-11.51,29-14.89
				c-1.57-1.15-3.55-2.51-4.98-2.07c-8.48,2.61-16.94,5.34-23.21,12.35c-1.22,1.37-4.06,2.53-5.7,2.09
				c-8.04-2.17-15.46-0.27-22.45,3.17c-7.53,3.71-14.25,9.16-23.44,8.34c-4.25-0.38-8.83-0.85-12.79,0.31
				c-6.26,1.84-12.11,5.08-18.18,7.6c-4.65,1.93-7.83,7.5-14.15,5.63C522.97,308.71,521.58,311.2,520.55,312.45z M388.05,775.63
				c-0.97,0.82-2.55,2.15-4.28,3.61c-1.41-1.6-2.45-2.78-3.57-4.05c-2.18,5.18-4.27,10.13-6.73,15.97c-2.32-3.19-3.82-5.26-6.01-8.27
				c-2.39,3.94-4.39,7.24-5.71,9.42c2.81,1.84,4.6,3.06,6.45,4.18c0.75,0.46,1.63,0.95,2.47,0.99c4.12,0.16,8.26,0.42,12.37,0.19
				c6-0.34,11.97-1.06,19.3-1.74c-1.69-3.14-2.17-5.59-3.52-6.23c-2.47-1.18-3.63-2.47-3.54-5.21c0.03-0.81-0.87-1.72-1.5-2.45
				C391.98,779.93,390.09,777.9,388.05,775.63z M730.92,264.86c0.36-0.67,0.72-1.35,1.08-2.02c-1.76-1.55-3.43-4.31-5.29-4.44
				c-10.25-0.72-20.54-1.04-30.81-0.93c-2.68,0.03-6.53,1.36-7.74,3.37c-2.19,3.62-2.8,8.2-3.9,11.83c6.97,1.21,13.7,2.37,20.95,3.63
				c0.05-0.13,0.58-1.13,0.89-2.19c2.24-7.45,4.07-8.94,12-9.22C722.37,264.74,726.65,264.86,730.92,264.86z M323.17,759.57
				c3.1,1.87,5.74,4.82,7.99,4.55c5.51-0.67,5.66,3.24,7.04,6.33c1.68,3.78,4.33,6.35,8.51,7.03c1.93,0.32,3.89,0.45,6.34,0.72
				c2.46-1.94,5.68-4.48,9.39-7.4c-2.86-3.37-5.5-8.04-9.46-10.74c-5.22-3.55-11.49-5.56-16.51-7.86
				C331.64,754.86,327.37,757.24,323.17,759.57z M268.73,1104.07c1.85,1.54,4.26,2.59,4.93,4.31c1.73,4.5,4.54,6.58,9.31,6.62
				c1.56,0.01,3.08,1.18,4.68,1.58c10.67,2.73,21.36,5.4,32.72,8.26c-1.99-2.4-3.4-4.1-5.38-6.51c-4.49,0-9.71,0.12-14.92-0.05
				c-2.57-0.09-5.26-0.57-4.03-4.42c0.88-2.78,1.55-5.64,2.55-8.38c0.97-2.68,2.83-6.13-1.25-6.93
				C287.31,1096.59,277.25,1096.05,268.73,1104.07z M119.97,928.89c-0.59,0.41-1.19,0.83-1.78,1.24c0,7.45,0,14.9,0,22.35
				c0,8.12,0,8.12,7.74,9.51c0.49,0.09,0.96,0.27,1.43,0.41c7.46,2.19,9.79,0.53,9.82-6.98c0.01-3.33,0.02-6.66,0-9.99
				c-0.02-3.16,0.07-6.34-0.24-9.47c-0.08-0.81-1.47-1.48-2.56-2.49c-0.69,2.19-1.12,3.53-1.45,4.58
				C125.98,938.9,121.94,935.55,119.97,928.89z M701.77,1078.28c3.33-4.03,4.99-11.82,1.97-14.93
				c-10.25-10.57-20.87-20.78-31.35-31.14c-4.97,5.21-4.87,6.51-1.06,10.87C681.49,1054.72,691.54,1066.44,701.77,1078.28z
				M64.57,370.84c0.2-0.37,0.41-0.75,0.61-1.12c-7.23-6.95-17.73-10.42-22.63-19.83c0.29-0.39,0.59-0.78,0.88-1.17
				c1.51,0.44,3.02,0.89,4.3,1.26c0.59-1.62,1.16-3.21,1.49-4.11c-4.36-3.96-8.32-7.92-12.7-11.36c-1.66-1.3-4.3-1.29-6.43-2.03
				c-4.94-1.72-9.9-3.4-14.73-5.37c-2.05-0.83-3.82-2.36-5.48-3.41c-0.43,2.75-0.81,5.18-1.16,7.41
				C27.37,344.37,45.97,357.61,64.57,370.84z M124.48,913.13c-3.81-0.79-6.48-1.35-9.14-1.89c-5.84-1.19-12.4-1.14-17.32-3.95
				c-4.3-2.46-8.14-2.91-12.58-2.96c-5.28-0.06-10.55-0.33-15.83-0.58c-2.08-0.1-4.14-0.4-4.79-0.47c1.96,1.65,5.87,3.43,7.1,6.34
				c1.74,4.11,4.21,5.63,8.1,6.05c9.24,1,18.5,1.85,27.72,3.05C113.83,919.51,118.56,916.9,124.48,913.13z M314.48,1020.17
				c1.52,6.66,3.01,13.33,4.63,19.96c0.13,0.55,1.14,0.89,1.73,1.33c0.51-0.88,1.34-1.72,1.46-2.64c0.58-4.5,0.8-9.05,1.47-13.53
				c0.66-4.5,1.84-8.92,2.51-13.42c0.36-2.4-0.19-4.94,0.21-7.33c1.02-6.04,2.67-12.05-0.68-17.9c-0.77-1.34-1.66-2.6-2.5-3.9
				c-1.07,1.3-2.82,2.47-3.09,3.91C318.17,997.72,312.89,1008.31,314.48,1020.17z M270.48,735.45c4.29-5.15,8.8-9.05,15.67-9.18
				c5.21-0.1,10.51,0.13,15.61-0.71c5.73-0.95,10.68-0.42,15.43,3.14c1.1,0.82,3.34,0.12,6.04,0.12c-3.17-4.67-4.53-9.75-10.11-6.96
				c-5.46,2.73-9.99-0.24-14.87-1.51c-3.84-1-7.85-2.83-11.57-2.39c-5.23,0.62-10.31,2.86-15.35,4.68c-2.29,0.83-4.35,2.29-6.51,3.48
				c-2.85,1.57-5.54,3.58-8.57,4.61c-4.07,1.39-8.4,2.03-12.62,2.99c0.19,0.86,0.37,1.73,0.56,2.59c3.27-0.72,6.86-0.82,9.74-2.3
				C260.02,730.88,264.98,735.5,270.48,735.45z M194.8,1076.65c1.1-4-1.24-6.45-5.37-7.97c-2.33-0.86-4.68-1.86-6.71-3.26
				c-6.17-4.24-11.72-0.67-16.63,2.07c-1.58,0.88-0.67,6.26-0.89,9.58c-0.01,0.16,0.03,0.33,0.03,0.5c-0.14,3.01,1.48,3.99,4.28,4.2
				c6.12,0.47,12.23,1.13,18.33,1.82C194.58,1084.36,194.79,1084.21,194.8,1076.65z M834.56,603.49c0.06,0.46,0.11,0.93,0.17,1.39
				c1.91,1.16,3.8,2.35,5.73,3.48c4.76,2.78,9.53,5.55,14.33,8.28c0.38,0.22,1.05-0.08,1.46,0.12c1.91,0.91,3.76,1.94,5.65,2.88
				c2.34,1.16,4.82,3.37,7.02,3.12c2.95-0.33,5.65-2.65,8.53-3.97c0.83-0.38,2.47-0.71,2.74-0.34c2.21,3.11,4.69,1.13,7.18,0.73
				c1.78-0.29,3.9-0.64,5.4,0.05c3.39,1.57,5.2-0.03,7.26-2.85c-7.19-3.45-14.15-6.79-22.42-5.79c-7.52,0.91-15.11,2.12-22.03-2.62
				c-1.56-1.07-3.44-1.88-5.28-2.24c-3.24-0.64-6.57-0.81-9.85-1.25C838.47,604.21,836.52,603.82,834.56,603.49z M122.34,1042.26
				c-9.85-2.67-18.91-5.22-28.03-7.53c-2-0.51-4.25-0.47-6.29-0.11c-0.9,0.16-2.2,1.73-2.21,2.67c-0.03,4.5,9.28,17.07,13.33,18.29
				c0.72,0.21,1.75,0.19,2.36-0.17c6.3-3.76,12.79-6.7,21.05-5.1C118.46,1047.4,120.1,1044.85,122.34,1042.26z M342.09,1079.37
				c6.16-4.56,8.73-10.74,8.48-18.17c-0.03-0.99-0.69-2.39-1.48-2.84c-3.2-1.82-6.47-4.37-9.91-4.75c-5.49-0.6-11.83-0.91-15.35,4.61
				c-1.38,2.17-0.97,5.83-0.49,8.66c0.19,1.11,2.84,2.57,4.34,2.54C336.73,1069.25,339.31,1070.85,342.09,1079.37z M528.12,315.26
				c-10.28,1.94-17.7,6.03-23.19,13.82c-3.16,4.49-4.41,8.79-3.29,14.35c1.22,6.02,1.25,12.29,1.8,18.44
				c0.55,0.12,1.1,0.24,1.65,0.36c2.43-5.1,5.41-10.01,7.15-15.33c3.22-9.81,4.23-20.55,12.97-27.77
				C526.07,318.43,526.6,317.32,528.12,315.26z M596.91,349.78c-2.9-0.36-4.84-0.94-6.71-0.77c-9.33,0.85-18.64,1.89-27.95,2.89
				c-0.98,0.11-1.96,0.33-2.91,0.6c-5.82,1.71-7.33,4.52-5.67,11.63c7.16-0.68,14.45-1.42,21.76-2.05c3.24-0.28,6.5-0.2,9.73-0.51
				c2-0.19,4.78-0.15,5.79-1.38C593.25,357.36,594.69,353.81,596.91,349.78z M646.77,324c5.3,0,9.07,0.51,12.64-0.12
				c5.41-0.95,10.61-3.14,16.03-3.94c4.66-0.68,9.5-0.14,14.51-0.14c0.74-2.89,1.5-5.91,2.27-8.91c-6.65-2.07-16.32-1.45-22.07,1.53
				c-2.7,1.4-4.65,0.86-6.42-1.56c-1.41-1.93-3.09-3.66-5.49-6.44C654.36,311.04,651,316.79,646.77,324z M657.62,1018.01
				c-1.12-1.66-1.98-3.57-3.4-4.86c-1.28-1.17-3.44-2.6-4.74-2.24c-2.09,0.58-4.57,2.15-5.5,4c-2.78,5.5-4.96,11.3-7.21,17.05
				c-1.5,3.83-3.23,7.69-3.9,11.7c-0.78,4.61-1.77,7.96-7.7,5.98c-1.13,2.71-2.15,5.15-3.72,8.91
				C638.83,1048.64,648.52,1034,657.62,1018.01z M76.32,1139.76c-12.32-4.01-25.7-8.36-39.67-12.91c1.24,6.3,2.2,11.23,3,15.3
				c1.58-2.57,3.35-5.68,5.37-8.63c0.55-0.8,2.48-1.73,2.66-1.54c2.42,2.55,5.33,4.97,6.72,8.06c1.59,3.54,1.79,7.71,2.59,11.61
				c1.75-2.26,2.33-4.61,3.1-6.89c0.68-2.03,1.57-4,2.37-5.99c0.6,0.17,1.21,0.34,1.81,0.51c0.15,1.56,0.12,3.17,0.48,4.67
				c0.99,4.13,1.72,8.41,3.39,12.26c1.27,2.94,3.1,5.27-0.7,6.85c1.35,3.84,2.54,7.22,3.74,10.61
				C70.1,1162,76.72,1151.83,76.32,1139.76z M387.1,739c-5.76,3.06-11.08,5.89-15.72,8.35c2.87,1.96,5.58,3.82,8.3,5.69
				c1.01,0.71,3.54,0,4.23,0.83c2.89,3.46,9.63,3.41,9.19,9.92c-0.02,0.25,0.88,0.57,1.34,0.86c1.76,1.09,3.52,2.18,5.22,3.24
				c0.37-0.66,0.68-2.07,1.53-2.6c7.19-4.44,3.23-10.9,3.02-16.61c-0.02-0.63-1.58-1.37-2.55-1.77c-2.29-0.94-4.66-1.71-8.2-2.98
				C390.72,745.49,388.5,743.36,387.1,739z M848.41,348.06c-3.66-3.66-7.55-6.65-10.23-10.48c-3.17-4.53-7.31-6.39-12.27-6.12
				c-7.4,0.4-14.48,0.04-21.2-3.51c-1.35-0.71-3.36-0.85-4.83-0.43c-1.37,0.39-2.43,1.87-3.62,2.87c0.31,0.31,0.63,0.62,0.94,0.93
				c5.69-2.71,6.67-3.02,8.06-0.25c2.99,5.97,7.92,8.52,13.98,10.04c1.99,0.5,3.98,1.86,5.49,3.32
				C831.59,351.1,839.43,350.87,848.41,348.06z M729.97,512.41c-0.35-0.35-0.69-0.7-1.04-1.06c-4.02,1.49-8.6,2.23-11.9,4.68
				c-3.47,2.58-5.98,2.73-8.31,0.66c-9.26,2.94-12.39,9.93-12.57,17.76c1.42,3.33,2.83,6.67,4.31,10.15
				c6.24-3.17,10.29-8.01,13.04-13.75c2.32-4.84,5.52-8.42,10-11.34C726.09,517.82,727.84,514.82,729.97,512.41z M876.53,439.08
				c-4.9,1.47-10.39,3.02-15.82,4.77c-2.5,0.81-5.32,1.51-7.22,3.15c-6.39,5.52-14.04,7.95-21.88,10.32
				c-3.17,0.96-5.95,3.22-8.91,4.89c0.24,0.43,0.48,0.85,0.72,1.28c0.48,0.03,0.99,0.18,1.42,0.07c10.17-2.75,19.95-6.52,29.8-10.25
				c12.21-4.62,25.56-9.55,39.47-5.19c0.68,0.21,1.46,0.1,2.67,0.17c-3.52-7.89,5.89-10.26,6.32-17.19c-1.87,1.61-3.1,2.95-4.58,3.89
				c-5.14,3.23-10.26,6.54-15.64,9.34c-1.61,0.84-4.61,0.86-5.91-0.13C875.97,443.41,876.6,440.47,876.53,439.08z M798.27,913.26
				c0.01-0.44,0.03-0.88,0.04-1.32c7.46,0.61,14.91,1.39,22.38,1.71c1.9,0.08,5.13-0.85,5.53-2.07c1.05-3.12,2.18-7.34-1.76-9.34
				c-9.17-4.65-18.91-7.27-29.25-5.01c-2.08,0.45-5.09,2.29-5.37,3.89c-0.36,2.07,1.23,4.81,2.65,6.77
				C794.01,910,796.31,911.5,798.27,913.26z M695.86,659.85c0.1,0.6,0.21,1.2,0.31,1.81c2.48-2.99,5.14-4.65,8.63-1.88
				c0.46,0.36,1.73,0.37,2.15,0c3.85-3.31,6.23-1.47,8.31,2.89c1.87-1.67,3.44-3.06,4.57-4.07c-4.33-1.56-8.19-2.99-12.09-4.34
				c-0.91-0.32-2.19-0.85-2.82-0.47c-4.15,2.44-8.32,0.61-12.5,0.44c-3.23-0.13-6.88,1.16-9.63,0.05
				c-11.72-4.72-24.04-2.04-36.2-3.41c3.67,2.47,7.44,4.85,11.56,6.15c6.62,2.09,13.61,3.08,20.17,5.34
				c2.92,1.01,4.52,0.45,7.08-0.71C688.51,660.23,692.34,660.38,695.86,659.85z M535.91,235.35c-0.21,5.98-4.88,7.87-8.95,10.49
				c-1.72,1.11-3.88,3.19-3.93,4.9c-0.15,4.74,0.69,9.5,1.16,14.48c0.76-0.14,1.43-0.14,2-0.38c6.53-2.78,13.07-5.54,19.52-8.48
				c0.8-0.37,1.51-1.74,1.61-2.71c0.54-5.22-0.26-9.91-5.28-13.02C539.98,239.34,538.3,237.44,535.91,235.35z M104.85,1033.72
				c1.1-4.13,2.15-7.36,2.77-10.68c0.26-1.41,0.12-3.92-0.69-4.35c-4.81-2.61-9.73-5.18-14.91-6.86c-2.04-0.66-6.24,0.28-7.03,1.77
				c-3.09,5.86-4.79,12.25-1.08,19.72C91.21,1027.49,98.06,1034.53,104.85,1033.72z M626.96,1083.66c-0.78-0.2-1.57-0.41-2.35-0.61
				c-0.7,2.36-2.07,4.75-1.99,7.09c0.65,18.94,2.34,37.87-1.02,56.75c-0.63,3.57-0.72,7.23-1.06,10.85c0.56,0.1,1.11,0.2,1.67,0.3
				c5.58-8.72,8.15-18.62,8.56-28.84c0.32-7.92-1-15.91-1.69-23.86C628.45,1098.11,627.67,1090.89,626.96,1083.66z M866.9,398.67
				c0.44,0.41,0.88,0.83,1.32,1.24c12.84-3.99,25.63-8.11,38.53-11.9c3.91-1.15,8.26-0.89,12.07-2.23c1.95-0.69,3.16-3.48,4.7-5.33
				c-1.54-0.78-3.07-2.23-4.62-2.24c-10.17-0.09-20.12,0.88-28.27,8.06c-0.44,0.39-1.27,0.63-1.83,0.52
				c-7.9-1.55-13.43,3.54-19.4,7.14C868.09,394.73,867.71,397.05,866.9,398.67z M40.34,1116.26c2.24,3.35,3.9,8.39,7.34,10.4
				c6.04,3.55,13.13,5.34,19.85,7.65c1.6,0.55,3.48,0.27,5.14,0.36c1.69-7.88,0.71-10.37-5.16-14.29c-0.8-0.53-1.41-1.35-2.09-2.05
				c-3.68-3.78-7.63-6.5-13.38-4.71C48.61,1114.7,45.03,1115.23,40.34,1116.26z M719.93,145.68c-5.08-10.93-6.21-11.72-16.22-12.49
				c-0.99-0.08-1.96-0.35-2.94-0.44c-9.59-0.85-18.64,0.91-27.23,5.45c3.03,5.1,7.45,6.03,12.59,5.72c4.26-0.25,8.56-0.23,12.81,0.03
				C705.68,144.35,712.39,145.04,719.93,145.68z M440.11,363.37c0.51-0.2,1.02-0.4,1.53-0.6c-0.44-3.44-0.36-7.04-1.41-10.28
				c-2.56-7.84-5.89-15.43-8.45-23.27c-2.61-8.03-12.06-11.61-19.48-7.04c2.84,4.89,5.62,9.8,8.55,14.63
				c0.47,0.78,1.68,1.08,2.3,1.82c2.01,2.39,3.93,4.86,5.85,7.32c1.33,1.69,2.25,4.13,3.98,5c5.35,2.72,5.76,3.62,1.97,9.1
				C436.68,361.16,438.39,362.27,440.11,363.37z M707.3,437.67c4.64,9.3,4.64,9.3,12.12,6.83c0.63-0.21,1.25-0.46,1.9-0.59
				c5.25-1.04,10.09-2.03,15.92-0.96c5.91,1.09,12.74-1.86,18.92-3.81c1.71-0.54,2.97-3.73,3.54-5.94c0.2-0.77-2.15-2.9-3.59-3.22
				c-1.6-0.36-3.5,0.67-5.28,1.05c-6.59,1.38-13.14,3.02-19.79,4.03C723.44,436.18,715.77,436.75,707.3,437.67z M938.84,437.78
				c-8.33,1.18-16.74-0.49-25.88,3.35c4.86,0.78,8.33,1.04,11.6,2c1.66,0.49,4.22,2.3,4.18,3.44c-0.14,3.81,0.75,8.33-4.37,10.38
				c-2.27,0.91-3.34,2.67-0.58,4.87c1.38,1.1,2.11,3,3.14,4.54c-1.89,0.61-3.74,1.62-5.66,1.76c-3.91,0.28-7.85,0.08-11.77,0.08
				c3.59,1.01,7.18,0.77,10.72,1.06c4.66,0.39,5.5,2.32,2.95,6.19c-1.61,2.44-3.34,4.8-5.02,7.2c0.28,0.49,0.56,0.98,0.85,1.47
				c1.71-0.53,3.99-0.55,5.01-1.7c2.02-2.29,4-5,4.84-7.87C932.38,462.47,935.49,450.26,938.84,437.78z M201.35,276.17
				c-0.13,8.37,0.08,8.69,7.36,8.54c4.13-0.09,8.26-0.87,12.38-0.81c9.34,0.14,18.68,0.62,28.02,0.94c2.55,0.09,5.08,0.3,5.13-3.56
				c0.05-3.77-1.95-4.16-5.07-4.21c-9.56-0.14-19.12-0.66-28.68-0.88C214.12,276.05,207.73,276.17,201.35,276.17z M211.86,730.93
				c20.38,6.36,38.13,0.48,54.72-11.53c-6.23-0.96-12.42-1.27-18.61-1.13c-1.67,0.04-3.5,1.22-4.91,2.32
				c-3.29,2.58-6.26,5.24-11.09,4.38c-1.99-0.35-5.2,0.31-6.26,1.72c-2.38,3.18-5.05,2.72-7.98,2.01
				C215.09,728.05,212.76,727.76,211.86,730.93z M881.65,854.66c-0.69-0.09-1.38-0.18-2.07-0.28c-4.78,12.79-9.57,25.58-14.56,38.93
				c4-0.54,7.09-1.06,10.21-1.36c4.88-0.47,11.13-9.39,10.27-14.24c-1.09-6.14-1.72-12.36-2.63-18.54
				C882.64,857.65,882.07,856.17,881.65,854.66z M618.82,1085.75c-0.54-0.01-1.08-0.02-1.62-0.03c-2.32,22.59-4.65,45.19-6.98,67.78
				c6.64-4.08,9.83-9.66,9.89-16.67c0.11-13.65,0.04-27.29-0.13-40.94C619.93,1092.5,619.22,1089.13,618.82,1085.75z M407.26,236.13
				c-2.07-7.38-4.17-10.18-7.92-9.74c-8.53,1-17.01,2.36-25.51,3.62c-3.53,0.52-5.43,2.36-4.53,6.16c0.84,3.54,4.32,5.5,7.86,4.84
				c3.86-0.72,7.79-1.05,11.67-1.68C394.97,238.32,401.09,237.2,407.26,236.13z M625.44,977.65c0.41-0.19,0.81-0.39,1.22-0.58
				c-1.87-12.13,1.91-23.05,6.66-33.78c0.73-1.66,1.67-3.46,1.63-5.17c-0.06-2.26-1-4.49-1.57-6.74c-2.16,0.97-4.85,1.41-6.4,2.98
				c-8.22,8.39-10.99,28.48-5.5,38.96C622.36,975,624.1,976.22,625.44,977.65z M525.33,291.21c0.21,0.38,0.41,0.76,0.62,1.14
				c1.29-0.21,2.58-0.37,3.85-0.65c0.46-0.1,0.88-0.45,1.29-0.73c10.98-7.33,23.16-11.55,36.22-11.96
				c9.83-0.31,17.81-3.93,25.33-9.67c1.32-1.01,3.27-1.46,4.99-1.67c6.37-0.77,12.77-1.31,19.15-2c0.79-0.09,1.56-0.51,2.28-0.89
				c6.27-3.33,12.56-6.62,18.77-10.07c1.29-0.72,2.24-2.04,3.34-3.09c-0.38-0.5-0.76-0.99-1.15-1.49
				c-5.67,2.94-11.26,6.06-17.04,8.76c-3.18,1.48-6.63,2.58-10.08,3.27c-8.4,1.68-17.14,1.39-24.66,7.02
				c-3.79,2.84-8.97,5.29-13.55,5.35c-13.62,0.17-26.05,4.18-38.14,9.71C532.59,286.06,529.06,288.86,525.33,291.21z M716.32,391.46
				c26.18,4.42,52.87,1.4,78.93,6.77c0.27-0.8,0.55-1.59,0.82-2.39c-1.63-1.19-3.17-3.28-4.89-3.43c-15.6-1.34-31.24-2.35-46.86-3.43
				c-3.95-0.27-7.92-0.66-11.87-0.64C727.01,388.39,721.27,386.8,716.32,391.46z M723.32,403.62c0.67,2.43,0.65,4.76,1.79,6.09
				c5.23,6.13,7.02,13.18,6.61,21.01c-0.01,0.22,0.4,0.47,0.85,0.96c7.16-1.42,14.48-2.88,22.3-4.43c-2.85-3.38-4.61-6.36-7.16-8.3
				c-3.71-2.82-8.05-4.78-11.97-7.35c-1.45-0.95-2.92-2.39-3.55-3.94C730.65,403.8,727.88,403.09,723.32,403.62z M665.11,988.03
				c6.72-7.29,6.72-7.29,0.37-12.31c6.85-6.09,6.85-6.09-0.17-10.98c1.3-1.13,2.54-2.21,4.59-3.99c-2.58-0.19-4.13-0.31-6.59-0.5
				c0.32,4.48,0.69,8.42,0.85,12.37c0.05,1.29-0.06,2.79-0.67,3.87c-1.81,3.2-3.28,6.08-1.09,9.92c0.67,1.18-0.98,3.61-1.38,5.51
				c-0.36,1.68-0.34,3.44-0.69,5.12c-0.58,2.79-0.99,5.7-2.14,8.26c-0.55,1.21-2.63,1.73-4.75,3.01c3,1.76,4.19,2.67,5.53,3.2
				c4.39,1.75,8.88-0.06,10.94-4.21c2.36-4.77-2.32-4.79-4.38-6.59c-0.1-0.09-0.09-0.3-0.14-0.45
				C672.87,992.24,672.88,991.87,665.11,988.03z M760.72,971.66c-11.25-13.07-47.07-22.05-68.33-17.31
				c10.63,2.41,21.35,4.39,32.05,6.47c1.9,0.37,5.05,0.84,5.34,1.89c1.33,4.83,5.24,4.49,8.65,5.12
				C745.55,969.15,752.7,970.3,760.72,971.66z M912.14,775.9c-2.89-13.4-5.73-26.55-8.57-39.7c-0.67,0.02-1.33,0.04-2,0.06
				c-1.49,9.7-3.39,19.37-4.38,29.12c-1.21,11.88,0.63,13.27,12.56,11.41C910.23,776.72,910.67,776.46,912.14,775.9z M662.18,514
				c-5.77-2.07-6.58-1.62-7.46,3.55c-0.25,1.45-0.19,3.45-1.1,4.2c-6.19,5.11-4.53,12.61-5.26,18.98c-0.44,3.88,0.87,7.85-1.33,11.83
				c-1.29,2.33,0.58,3.67,2.79,2.44c3.61-2.01,7.69-3.71,8.29-8.78C659.39,535.43,660.82,524.66,662.18,514z M439.74,381.09
				c1.27-6.95,0.71-7.92-5.81-9.34c-1.58-0.34-3.28-0.09-4.91-0.26c-4.79-0.5-10.13,4.46-14.41-1.4c-0.1-0.14-0.63,0.01-0.96,0.05
				c-3.12,0.36-6.23,0.84-9.35,1.06c-3.42,0.24-3.85,2.79-3.12,4.93c0.65,1.9,2.66,4.69,4.15,4.74
				C416.68,381.27,428.04,381.09,439.74,381.09z M602.3,229.4c0.38-1.48,1.12-3.4,1.32-5.38c0.42-4.07,0.38-8.19,0.85-12.25
				c0.75-6.32,1.77-12.62,2.69-19.06c-10.37,0.45-14.4,2.68-14.94,9.93c-0.43,5.77,1.21,11.71,2.06,17.55
				c0.1,0.66,1.09,1.18,1.62,1.8C597.89,224.26,599.84,226.54,602.3,229.4z M102.2,848.39c5.4,1.03,9.56,1.44,13.47,2.67
				c5.4,1.7,9.3,0.74,11.91-4.56c1.72-3.5,4.72-4.78,8.61-4.62c4.95,0.2,9.92,0.05,14.88,0.05c-9.34-3.01-18.79-5.24-28.26-7.36
				c-0.83-0.19-2.02,0.19-2.74,0.71C114.43,839.32,108.86,843.48,102.2,848.39z M552.39,216.41c-0.42-0.28-0.84-0.56-1.25-0.84
				c-0.8,0.21-1.6,0.41-2.39,0.62c-4.68,1.29-9.79,2.28-10.77,8.14c-1.01,6.05,0.83,11.13,6.32,14.57c4.54,2.84,5.9,7.34,6.16,12.42
				c0.14,2.8-0.43,6.97,1.11,8.13c4.84,3.65,2.25,7.23,1.22,11.04c-0.3,1.1-0.41,2.25-0.61,3.38c2.56-4.95,5.04-9.78,6.68-14.87
				c0.53-1.63-0.53-4.96-1.9-5.8c-3.11-1.89-3.34-4.32-3.79-7.45c-0.7-4.86-1.01-10.14-6.3-13.05c-3.06-1.69-1.83-3.93-0.27-6.27
				C548.74,223.22,550.48,219.76,552.39,216.41z M797.79,770.89c3.09,0,6.58,0.15,10.05-0.06c2.11-0.12,4.99-0.12,6.06-1.4
				c1.19-1.43,1.79-5.06,0.81-6.32c-5.01-6.52-5.15-14.17-6.24-21.69c-0.42-2.91-1.61-5.71-2.44-8.55c-0.55,0.01-1.1,0.01-1.65,0.02
				c-0.42,3.56-0.93,7.1-1.22,10.67c-0.24,2.88,0.57,6.09-0.45,8.6C800.28,758.15,797.36,763.92,797.79,770.89z M671.68,962.46
				c3.07,7.23,3.06,7.45-0.46,13.13c3.78,3.74,2.93,7.58-0.51,11.45c4.32,4.11,3.42,8.31,1.67,12.48c0.18,3.57,0.36,7.15,0.54,10.73
				c1.32-1.56,2.6-3.9,4.56-5.24c6.83-4.67,6.92-6.35,0.03-10.5c-0.33-0.2-0.4-0.82-0.4-0.81c2-2.54,5.05-4.73,5.22-7.13
				c0.15-2.2-2.8-4.61-4.47-7.07c6.56-6.73,6.56-6.73-0.59-11.69c1.41-1.25,2.77-2.46,4.13-3.67c-0.1-0.42-0.21-0.84-0.31-1.26
				C677.94,962.74,674.81,962.6,671.68,962.46z M99.78,323.14c5.49,0.67,11.44-0.33,14.71,2.18c5.25,4.02,11.36,2.73,16.59,5.24
				c1.08,0.52,2.6,0.24,3.91,0.14c3.56-0.26,7.11-0.64,10.67-0.95c3.13-0.26,6.27-0.38,9.39-0.74c6.97-0.81,13.79-1.13,20.49,1.87
				c4.3,1.92,8.91,3.14,13.38,4.68c0.36-0.76,0.73-1.53,1.09-2.29c-1.01-0.7-1.93-1.59-3.04-2.07c-3.7-1.59-7.46-3.03-11.2-4.51
				c-1.22-0.49-2.46-1.33-3.7-1.34c-10.22-0.06-20.43,0.03-30.65,0.11c-0.47,0-0.93,0.44-1.4,0.46c-3.18,0.15-6.37,0.41-9.54,0.3
				c-2.28-0.08-4.6-0.49-6.81-1.08c-2.1-0.56-4.37-1.17-6.07-2.42c-2.79-2.07-13.76-3.75-16.6-1.95
				C100.44,321.11,100.29,322.1,99.78,323.14z M182.08,1056.99c3.73,1.99,6.15,3.38,8.66,4.59c2.81,1.35,5.97,3.98,8.52,3.51
				c8.37-1.53,16.53-4.21,26.22-6.84c-5.38-2.58-9.3-4.62-13.36-6.33c-1.9-0.8-4.23-1.68-6.09-1.26
				C198.48,1052.37,191.03,1054.57,182.08,1056.99z M419.36,792.02c-1.83-8.88-3.56-17.39-5.35-25.88c-0.27-1.29-0.66-2.62-1.34-3.73
				c-0.72-1.17-1.85-2.1-2.8-3.13c-0.9,1.2-1.87,2.35-2.68,3.61c-0.52,0.81-0.72,1.81-1.18,2.66c-1.17,2.18-2.35,4.36-3.64,6.46
				c-2.16,3.53,1.3,4.31,2.73,6.25c1.11,1.5,1.68,3.62,1.88,5.53C407.91,792.53,409.47,793.73,419.36,792.02z M817.01,446.38
				c-5.82-0.29-9.85,3.89-14.73,5.3c-4.87,1.41-10.3,0.9-17.15,1.33c7.58,6.7,14.08,12.44,21.07,18.61
				C809.84,463.11,813.27,455.09,817.01,446.38z M2.25,943.63c5.9,1.6,10.59,2.99,15.36,4.09c1.4,0.32,3.05,0.04,4.48-0.36
				c2.64-0.74,5.13-2.26,7.79-2.58c5.11-0.61,10.29-0.61,16.47-0.91c-10.03-7.91-21.84-7.36-33.87-11.48c3.11,4.15,2.03,5.46-1.06,6
				c-2.11,0.37-4.27,0.89-6.17,1.84C4.07,940.82,3.36,942.33,2.25,943.63z M623.08,199.31c2.85-0.51,5.45-0.88,8-1.45
				c2.74-0.61,5.41-1.59,8.17-2.06c6.92-1.18,10.43-5.73,9.49-13.09c-1.36-0.13-2.85-0.58-4.24-0.36c-4.23,0.68-8.4,1.79-12.63,2.44
				c-10.43,1.6-9.29,1.26-9.17,12.27C622.7,197.54,622.86,198.02,623.08,199.31z M509.51,255.17c0.62-0.27,1.25-0.53,1.87-0.8
				c-0.97-3.38-2.24-6.72-2.81-10.17c-0.52-3.12-2.2-3.1-4.6-2.84c-1.85,0.2-4.67,0.68-5.47-0.28c-3.72-4.47-6.46-2.94-9.8,0.41
				c-4.02,4.03-8.63,2.86-10.93-2.44c-0.52-1.21-0.59-2.68-1.3-3.74c-0.74-1.1-2.01-1.85-3.05-2.75c-0.74,1.52-2.44,3.39-2.04,4.5
				c1.76,4.94,3.87,9.79,6.33,14.42c2.86,5.38,4.31,5.29,8.92,1.04c3.15-2.9,6.37-5.88,10.02-8.04c4.86-2.88,9.02-0.33,10.15,5.25
				C507.18,251.66,508.57,253.36,509.51,255.17z M693.98,319.22c6.96,1.78,13.72-0.31,20.7,2.68c5.68,2.44,13.24,0.5,19.95,0.5
				c0.63-7.38-0.59-8.19-8.02-8.67c-5.62-0.36-11.49,2.8-17.08-1.81c-1.82-1.51-6.04,0.06-9.15-0.08
				C695.22,311.59,693.55,313.34,693.98,319.22z M778.63,493.78c0.59-0.04,1.17-0.08,1.76-0.13c1.25-3.69,2.76-7.33,3.68-11.1
				c0.8-3.28-0.51-5.32-4.34-5.58c-4.02-0.27-8.04-1.69-12.01-1.53c-7.1,0.28-14.44,0.47-21.18,2.44c-4.89,1.43-8.97,5.66-12.53,8.04
				c2.78,0.24,6.37,0.55,10.12,0.87c4.53-6.92,12.58-5.96,19.46-6.77C772.75,478.93,776.39,485.92,778.63,493.78z M288.4,1008.62
				c-3.28-6.27-7.58-11.6-15.63-10.13c-4.55,0.83-7.62,4.2-9.3,9.33c9.69,1.7,14.01,8.44,16.81,16.21
				C282.86,1019.14,285.53,1014.07,288.4,1008.62z M306.42,1197.52c-1.78-8.6-3.46-16.74-5.17-25c-3.85,3.38,0.55,9.84-6.38,11.6
				c-1.1-2.86-2.14-5.59-3.51-9.16c-2.1,8.01-3.96,15.09-5.92,22.57C292.93,1197.52,299.46,1197.52,306.42,1197.52z M623.21,183.01
				c8.35-1.14,16.38-2.07,24.34-3.38c4.72-0.78,1.95-4.83,2.57-7.35c0.51-2.09-0.01-4.13-2.94-3.91c-5.13,0.37-10.33,0.36-15.35,1.31
				C624.4,171.08,622.36,174.64,623.21,183.01z M231.65,1104.69c14.06,3.07,27.14,5.94,40.22,8.8c0.29-0.59,0.57-1.17,0.86-1.76
				c-1.47-1.18-2.79-2.65-4.43-3.5c-5.54-2.85-12.38-3.63-15.44-10.31c-0.38-0.83-1.88-1.69-2.8-1.63
				C243.52,1096.72,236.82,1096.98,231.65,1104.69z M518.05,310.88c0.15-0.72,0.3-1.43,0.45-2.15c-4.36-0.85-8.69-2.22-13.08-2.44
				c-10.66-0.55-21.38-4.11-32.04,0.13c-0.63,0.25-1.57-0.41-2.39-0.52c-6.96-0.93-13.81-0.85-20.29,2.4
				c-1.46,0.73-2.98,1.68-4.53,1.83c-7.02,0.67-10.85,5.96-15.46,10.52C458.24,302.78,488.29,308.14,518.05,310.88z M4.08,976.25
				c-0.58,0.32-1.17,0.64-1.75,0.96c3.36,8.16,6.73,16.33,9.86,23.93c3.09-0.49,5.92-1.27,8.78-1.33c4.09-0.09,4.94-1.97,3.73-5.44
				C21.2,984.35,13.29,979.53,4.08,976.25z M586.87,538.31c11.22,0,21.89,0.28,32.51-0.28c1.7-0.09,3.2-3.94,4.79-6.06
				c-1.41-0.97-2.71-2.2-4.26-2.81c-1.14-0.45-2.61-0.06-3.93-0.05c-6.03,0.06-12.07,0.22-18.1,0.14
				C592.47,529.19,588.66,531.93,586.87,538.31z M315.58,365.77c4.23,1.14,8.1,2.18,11.74,3.16c5.38,0.2,10.35-5.59,15.73-0.68
				c3.61-1.39,7.15-3.92,10.58-3.79c5.68,0.21,5.86-3.1,7.11-8.33c-3.76,1.19-6.4,2.15-9.11,2.84c-2.73,0.7-5.51,1.22-8.29,1.68
				c-3.59,0.59-7.19,1.13-10.8,1.48c-1.33,0.13-3,0.11-4.03-0.58C322.54,357.56,318.66,358.76,315.58,365.77z M852.9,889
				c-5.03,1.99-9.81,3.7-14.4,5.82c-1.19,0.55-2.58,2.4-2.53,3.59c0.25,6.12,0.94,12.23,1.52,18.95c4.62,1.71,6.42,1.26,9.06-3.94
				c1.17-2.3,2.42-5.11,2.13-7.5c-0.71-5.72-1.31-11.04,4.19-14.88C853.09,890.88,852.9,890.14,852.9,889z M356.84,1038.28
				c-3.3,2.87-6.17,6.95-10,8.34c-6.97,2.52-14.46,3.31-21.47-1.07c-1.52-0.95-3.59-1-5.72-1.55c-0.59,3.15-0.56,5.82-1.63,7.93
				c-1.06,2.09-2.93,4.32-5,5.24c-4.73,2.1-9.8,3.44-14.74,5.08c0.16,0.54,0.33,1.08,0.49,1.63c5.42-1.29,10.91-2.36,16.23-3.99
				c2.36-0.72,5.07-2.07,6.44-3.99c4.65-6.52,11.98-6.09,17.76-5.25c6.9,1.01,12.02-1.05,17.22-4.23
				C360.39,1043.98,360.26,1042.06,356.84,1038.28z M561.31,250.82c0-13.01,0-25.53,0-38.32c-9.95,1.95-6.59,13.12-13.59,16.79
				c1.83,1.43,3.57,2.09,4.02,3.26c1.67,4.32,3.51,8.73,4.13,13.26C556.3,248.93,557.19,250.31,561.31,250.82z M445.17,367.85
				c16.28-3.26,31.92-7.49,48.94-3.21c-2.28-5.41-6.26-8.4-10.58-8.14c-10.83,0.66-21.62,2.27-32.35,4.02
				C447.85,361.07,444.69,363.23,445.17,367.85z M947.93,814.19c-6.58,2.42-13.17,4.48-19.44,7.27c-5.31,2.36-7.16,7.33-5.56,12.49
				C937.31,832.98,949.16,826.42,947.93,814.19z M198.42,969.27c7.61,0.89,13,8.04,21.37,5.57c0-3.8,0-7.69,0-11.94
				c-1.31,0-2.26-0.02-3.21,0c-3.48,0.07-7.71,1.38-10.18-0.16c-1.93-1.2-1.51-5.98-2.45-9.07c-0.29-0.94-1.75-2.25-2.5-2.15
				c-1.09,0.15-2.84,1.39-2.89,2.24C198.28,958.86,198.42,963.98,198.42,969.27z M628.21,1046.39c4.03-9.89,8.05-19.76,12.13-29.77
				c-2.84-1.38-6.07-2.95-9.28-4.5C623.78,1020.62,622.49,1037.47,628.21,1046.39z M307.55,1083.37c-0.25-0.09-0.51-0.17-0.76-0.26
				c-2.35,10.73-4.71,21.47-6.97,31.75c3.89,0.29,7.26,0.81,10.61,0.69c1.77-0.07,4.22-0.79,5.05-2.07c0.75-1.16-0.16-3.51-0.6-5.27
				c-0.27-1.07-1.24-1.96-1.53-3.03C311.35,1097.93,309.47,1090.65,307.55,1083.37z M72.99,1085.31
				c-14.19-9.12-28.23-18.14-42.11-27.06c0.25,6.6,0.3,6.54,5.53,10.09c8.2,5.57,16.39,11.15,24.48,16.88
				C65.1,1088.2,69.01,1087.85,72.99,1085.31z M136.83,910.92c-9.07,4.38-17.34,8.37-25.61,12.35c0.12,0.4,0.25,0.79,0.37,1.19
				c8.22,2.2,16.45,4.41,25.25,6.76C136.83,924.2,136.83,918.13,136.83,910.92z M433.79,422.72c3.19,3.02,4.97,5.62,7.43,6.85
				c4.17,2.09,8.24,7.61,13.54,2.41c4.67-4.58,8.94-9.59,13.26-14.52c0.35-0.39,0.01-2.02-0.52-2.44c-1.88-1.49-8.67,0.92-9,3.26
				c-0.63,4.48-3.17,4.91-7.1,4.55C446.11,422.37,440.74,422.72,433.79,422.72z M602.27,340.17c-4.39,0-8.55-0.23-12.68,0.07
				c-3.76,0.27-7.45,1.25-11.2,1.7c-5.51,0.66-11.16,0.66-16.53,1.89c-2.35,0.54-4.17,3.41-6.23,5.22c0.28,0.37,0.56,0.74,0.85,1.1
				c10.67-1.4,21.35-2.83,32.03-4.18c1.29-0.16,2.76-0.27,3.94,0.17C597.74,348.06,600.58,345.82,602.27,340.17z M313.46,362.06
				c-4-1.37-7.7-2.39-11.19-3.89c-8.68-3.74-15.38-1.75-19.6,5.65c3.88,1.3,8.17,2.08,11.78,4.07
				C303.14,372.67,310.51,370.31,313.46,362.06z M347.27,734.08c4.5,2.95,8.12,5.47,11.87,7.74c3.33,2.02,6.78,4.13,10.88,2.02
				c2.95-1.52,5.84-3.17,8.76-4.76c-8.54,1.08-5.1-9.01-9.78-10.28c-1.99,0.79-4.5,2.42-5.06,1.91c-4.05-3.71-6.54-1.8-9.16,1.68
				c-0.49,0.65-2.12,0.41-3.21,0.64C350.23,733.33,348.89,733.68,347.27,734.08z M467.04,279.91c0.9-9.81,0.22-18.71-5.3-26.57
				c-4.12-5.86-8.68-11.42-13.52-17.74c-0.43,1.42-1.66,3.24-1.21,4.4c2.63,6.77,5.38,13.53,8.64,20.01
				C458.93,266.5,462.86,272.66,467.04,279.91z M222.15,1045.52c0.1-4.83,5.59-9.03-0.13-14.28c-5.45-5-11.65-8.04-18.68-9.67
				c-0.51-0.12-1.31,1-1.98,1.54C209.48,1029.58,208.71,1043.35,222.15,1045.52z M641.97,322.38c0.44,0.23,0.89,0.46,1.33,0.69
				c1.1-1.38,2.36-2.67,3.26-4.17c2.29-3.8,4.04-7.99,6.71-11.49c2.73-3.56,4.36-7.05,4.58-11.65c0.2-4.29-1.63-5.25-4.93-4.25
				c-2.08,0.63-4.59,1.95-5.59,3.71C642.52,303.65,642.69,313.12,641.97,322.38z M625.1,229.61c0.34-0.01,0.69-0.02,1.03-0.03
				c4.73-10.29,9.46-20.58,14.25-31c-4.81,1.07-9.8,2-14.65,3.38c-1.25,0.36-3.06,2.21-2.98,3.26
				C623.3,213.37,624.26,221.49,625.1,229.61z M360.28,300.78c-8.93,5.09-8.93,5.09-17.18,2.47c-1.51,2.53-2.84,5.2-4.57,7.58
				c-2.67,3.66-5.61,7.13-8.43,10.68c0.45,0.52,0.9,1.04,1.35,1.56c1.64-0.75,3.26-1.53,4.92-2.25c2.53-1.1,4.99-2.74,7.63-3.13
				C351.15,316.64,359.12,308.88,360.28,300.78z M74.88,322.14c0.57,0.26,1.15,0.52,1.72,0.78c2.53-5.19,5.56-10.21,7.48-15.62
				c2.77-7.81,1.85-16.85,8.03-23.6c0.98-1.07,1.3-3.2,1.12-4.74c-0.17-1.54-1.36-2.96-2.1-4.43c-1.21,1.01-3.12,1.79-3.52,3.06
				c-4.38,13.68-8.54,27.42-12.69,41.17C74.61,319.79,74.88,321,74.88,322.14z M346.08,974.27c-1.2,2.21-2.04,3.73-2.85,5.26
				c-2.75,5.19,1.43,9.46,1.98,14.23c1.09,9.43,2.69,18.8,4.21,28.18c0.81,5.01,1.88,9.98,2.83,14.97c0.61-0.1,1.23-0.2,1.84-0.3
				C350.17,1016.3,354.9,995.04,346.08,974.27z M790.24,389.83c0.44-0.47,0.87-0.94,1.31-1.41c-1.71-3.03-2.81-7.99-5.23-8.76
				c-10.16-3.22-19.58,1.51-29.02,4.69c-0.23,0.08-0.32,0.53-1.15,1.98C767.88,387.53,779.06,388.68,790.24,389.83z M282.89,422.25
				c7.02-3.49,12.75-6.25,18.39-9.18c1.84-0.95,3.84-2.01,5.11-3.56c1.67-2.03,2.7-4.59,4-6.92c-3.34,1.65-5.87,4.28-8.66,6.59
				c-1.4,1.16-3.26,1.77-4.91,2.63c-0.63-1.89-1.36-3.75-1.84-5.68c-0.17-0.67,0.28-1.5,0.57-2.78c-1.46,0.39-2.55,0.39-3.25,0.92
				c-4.18,3.13-8.27,6.38-12.39,9.6c-3.32,2.6-2.82,6.05,1.14,7.69C282.1,422.01,283.2,422.36,282.89,422.25z M731.29,384.31
				c0.08,0.46,0.16,0.93,0.23,1.39c8.01-0.98,16.54,3.64,24.06-3.56c1.5-1.44,4.18-1.67,6.35-2.36c1.72-0.54,3.47-0.98,5.23-1.37
				c3.96-0.87,7.89-1.97,11.9-2.42c2.7-0.3,5.51,0.36,8.36,0.59c-0.64-6.16-1.83-7.35-6.67-6.09c-7.68,2-15.25,4.44-22.9,6.56
				c-7.81,2.16-15.66,4.15-23.48,6.24C733.32,383.57,732.31,383.96,731.29,384.31z M107.21,840.96c-0.15-0.57-0.29-1.13-0.44-1.7
				c-3.09,0.52-7.75-0.06-8.98,1.74c-5.23,7.6-9.44,15.91-14.45,24.72c4.73,0.67,8.55,1.2,13.48,1.89
				c-2.97-7.12,8.21-11.98,0.39-18.97C100.76,845.91,103.98,843.44,107.21,840.96z M860.93,849.26c-2.29-3.85-5.66-6.08-10.53-4.56
				c-4.9,1.53-9.67,3.45-14.5,5.19c-3,1.08-6.12,2.73-4.4,6.28c0.85,1.75,4.08,2.72,6.41,3.23c1.31,0.29,3-1.03,4.49-1.71
				C848.4,854.99,854.38,852.25,860.93,849.26z M607.75,210.92c2.4-0.45,4.11-0.91,5.85-1.08c4-0.39,6.35-1.97,6.05-6.53
				c-0.31-4.6,0.04-9.25-0.29-13.86c-0.1-1.44-1.78-4.05-2.25-3.95c-2.37,0.53-6.28,1.44-6.56,2.84
				C609.12,195.54,608.64,202.93,607.75,210.92z M255.1,1094.69c2.95,6.15,7.49,9.46,10.3,7.96c5.95-3.18,11.81-6.52,17.9-9.91
				c-1.3-2.07-0.78-7.59-6.07-6.11C269.82,1088.7,262.72,1091.85,255.1,1094.69z M251.18,983.64c2.87,0.97,4.7,2.14,6.49,2.11
				c7.79-0.16,15.57-0.66,23.34-1.1c0.77-0.04,1.87-0.3,2.23-0.84c2.98-4.5,6.2-3.12,10.02,0.37c0-4.91,0-8.92,0-13.94
				c-0.08,1.63-0.14,2.29-0.15,2.94c-0.05,3.17,0.46,6.52-4.42,6.66c-5.14,0.14-3.82-3.57-3.83-6.25c-0.01-3-1.48-3.54-4.06-3.55
				c-6.66-0.03-7.04,0.01-6.83,6.38c0.15,4.48-1.33,6.25-5.91,6.19C262.95,982.53,257.82,983.19,251.18,983.64z M582.11,376.11
				c-6.29-1.84-12.54-3.85-18.91-5.34c-0.88-0.21-3.41,2.22-3.35,3.33c0.15,2.89,0.62,6.28,2.31,8.39c1.27,1.59,4.63,1.52,7.06,2.17
				c9.1,2.43,9.1,2.42,12.39-6.31C581.78,377.91,581.83,377.41,582.11,376.11z M260.77,911.98c0-2.82,0.14-5.64-0.05-8.44
				c-0.12-1.68-0.76-3.33-1.16-5c-1.82,0.53-4.08,0.56-5.34,1.7c-1.52,1.38-2.87,3.63-3.08,5.62c-0.45,4.43-0.08,8.93-0.16,13.4
				c-0.05,3.21-0.1,5.77,4.56,5.62c4.07-0.13,5.72-1.34,5.28-5.46C260.56,916.97,260.77,914.46,260.77,911.98z M236.84,721.78
				c0-0.49,0-0.98,0-1.47c-4.26-0.49-8.45-3.32-12.85,0.36c-0.63,0.53-2.65,0.45-3.2-0.13c-5.09-5.33-9.63-1.85-14.44,0.48
				c-3.87,1.87-7.98,3.24-12.61,5.07c4.42,3.12,8.21,6.78,12.74,3.96c4.7-2.92,8.91-5.96,14.74-3.89c0.24,0.09,0.63-0.15,0.92-0.29
				c2.8-1.35,5.49-3.04,8.42-3.97C232.48,721.29,234.74,721.78,236.84,721.78z M555.74,923.66c-6.59,2.88-10.08,8.37-11.7,15.04
				c-2.2,9.09,0.66,13.82,9.29,15.59c1.69,0.35,4.05-0.1,5.3-1.14c0.66-0.55-0.06-3.36-0.78-4.83c-1.09-2.21-2.77-4.12-4.13-6.2
				C548.65,934.35,549.13,930.37,555.74,923.66z M835.71,224.98c-0.01-0.33-0.01-0.67-0.02-1c-4.96-1.83-9.78-4.38-14.91-5.37
				c-15.14-2.93-29.31-8.34-43.01-15.21c-1.48-0.74-3-1.41-4.53-2.06c-1.45-0.63-2.93-1.19-4.39-1.78c-0.29,0.54-0.58,1.08-0.86,1.62
				c6.04,3.85,11.64,9.03,18.22,11.26c13.62,4.62,27.76,7.7,41.72,11.32C830.46,224.41,833.12,224.59,835.71,224.98z M721.21,971.62
				c-0.26,0.78-0.53,1.56-0.79,2.34c8.12,3.94,16.04,8.43,24.44,11.65c5.92,2.27,8.04,0.61,11.81-6.6
				C744.61,976.5,732.91,974.06,721.21,971.62z M551.01,265.44c-3.08,0.88-5.16,1.23-7.03,2.07c-3.91,1.76-7.66,3.87-11.56,5.65
				c-4.54,2.07-7.52,7.35-6.91,13.29c2.05-0.71,4.07-1.22,5.93-2.07c4.73-2.15,9.44-4.35,14.05-6.75c1.31-0.68,2.7-1.91,3.23-3.23
				C549.71,272,550.07,269.34,551.01,265.44z M428.92,304.36c11.82-9.65,25.63-13.23,40.28-13.42c14.31-0.19,28.63,0.56,42.95,0.89
				c-7.31-1.85-14.75-3.26-22.23-3.52c-16.32-0.59-32.72-0.98-48.36,5.14C436.18,295.56,431.53,298.66,428.92,304.36z M326.2,1197.69
				c-0.61-5.03-1.14-9.62-1.73-14.2c-0.14-1.1-0.45-2.18-0.73-3.26c-0.37-1.4-1.38-2.94-1.07-4.15c0.95-3.79-0.77-5.36-4-6.39
				c-0.58,4.22-0.88,8.14-1.74,11.93c-0.5,2.23-2.45,4.19-2.73,6.4c-0.4,3.1,0.15,6.33,0.29,9.67
				C319.01,1197.69,322.8,1197.69,326.2,1197.69z M84.9,314.27c2.18-1.65,5.51-4.01,8.64-6.62c1.08-0.9,2.11-2.32,2.36-3.65
				c1.8-9.45,3.39-18.94,5.06-28.52c-2.35-0.55-3.79-0.32-4.67,3.25c-1.22,4.94-4.27,9.4-5.8,14.29
				C88.21,300.31,86.57,307.8,84.9,314.27z M741.04,330.22c-5.44,3.05-10.22,5.72-15.13,8.47c15.45,4.43,30.86,8.23,47,7.75
				c-5.45-1.36-11-2.16-16.47-3.33c-2.34-0.5-5.01-1.25-6.67-2.8C746.5,337.24,743.84,333.53,741.04,330.22z M142.02,913.54
				c-0.56,0.29-1.12,0.57-1.69,0.86c0,14.95-0.04,29.89,0.11,44.84c0.01,0.8,1.58,1.59,2.42,2.38c0.71-1.07,2.03-2.12,2.05-3.2
				c0.14-13.66,0.17-27.32-0.05-40.98C144.84,916.12,143.01,914.84,142.02,913.54z M836.58,995.77c19.73,8.84,42.29,14.76,50,12.76
				c-2.93-1.59-5.23-3.3-7.82-4.15c-10.05-3.31-20.31-6.03-30.25-9.63C844.17,993.18,840.5,992.05,836.58,995.77z M584.12,571.21
				c25.48,5.93,27.75,6.07,29.37,1.49c-0.9,0.07-1.7,0.16-2.5,0.19c-3.71,0.12-6.71,0.02-5.05-5.42c0.78-2.56-0.14-5.64-0.31-8.84
				C598.13,563.01,591.16,567.09,584.12,571.21z M15.29,1148.78c0.66,0.09,1.31,0.19,1.97,0.28c0.78-1.22,2.01-2.35,2.25-3.67
				c1.15-6.14,0.2-12.77,4.49-18.17c0.33-0.41,0.1-1.3,0.06-1.96c-0.27-5.59-3.43-7.75-11.92-8.1c-0.2,0.41-0.67,0.9-0.58,1.27
				c1.36,6.02,2.8,12.01,4.18,18.02c0.25,1.08,0.47,2.22,0.4,3.31C15.94,1142.77,15.58,1145.77,15.29,1148.78z M553.22,550.52
				c-1.99-0.21-2.81-0.44-3.58-0.35c-10.05,1.15-20.11,2.2-30.13,3.62c-3.33,0.47-6.04,2.37-6.28,6.83
				c5.41-0.83,10.45-1.7,15.51-2.36c3.94-0.51,8.08-1.64,11.83-0.93C546.6,558.47,549.28,554.92,553.22,550.52z M211.9,771.49
				c2.04,2.01,3.88,4.05,5.96,5.81c2.22,1.88,4.45,4.25,7.08,5.05c6.97,2.12,14.01,1.92,20.78-2.12c-3.45-1.5-7.01-2.43-10.55-3.4
				c-1.56-0.42-4.39-0.79-4.45-1.39c-0.49-5-4.6-3.43-7.06-3.8C219.69,771.06,215.57,771.49,211.9,771.49z M134.46,725.98
				c0.27-0.35,0.54-0.7,0.82-1.05c2.03,0.67,5.52,0.96,5.81,2.08c0.79,3.04,2.67,4.72,5.07,4.34c1.81-0.28,4.26-2.43,4.74-4.21
				c1.43-5.29,5.14-6.63,9.75-7.53c1.54-0.3,2.82-1.94,4.21-2.97c-0.34-0.52-0.69-1.05-1.03-1.57c-4.51,1.4-9.01,2.86-13.55,4.15
				c-1.52,0.43-3.17,0.48-4.76,0.53c-4.83,0.16-9.67,0.24-14.67,0.35C132.51,722.82,133.49,724.4,134.46,725.98z M191.85,322.55
				c0.18,0.89,0.36,1.78,0.54,2.68c1.92-0.5,3.84-1.01,5.77-1.5c2.07-0.53,4.2-1.65,6.19-1.45c10.46,1.08,20.81-2.3,31.47,0.33
				c8.53,2.1,17.64,1.86,26.5,2.56c1.08,0.09,2.22-0.47,3.33-0.73c-0.03-0.62-0.06-1.25-0.09-1.87c-4.84-0.52-9.67-1.14-14.52-1.54
				c-3.93-0.33-7.89-0.37-11.84-0.58c-7.81-0.41-15.47-3.47-23.46-1.22c-2.03,0.57-4.28,0.35-6.4,0.66
				C203.51,320.72,197.69,321.65,191.85,322.55z M276.78,1197.66c0-2.43-0.59-4.52,0.12-5.98c1.77-3.67,2.77-6.71-1.9-8.93
				c-0.57-0.27-0.7-1.76-0.78-2.7c-0.32-3.94-0.54-7.88-0.79-11.82c-0.49-0.23-0.97-0.45-1.46-0.68c-0.57,0.95-1.84,2.47-1.61,2.75
				c4.05,4.89-1.29,6.34-2.21,9.12c-0.56,6.08-1.11,12.15-1.67,18.25C269.37,1197.66,272.97,1197.66,276.78,1197.66z M567.14,558.64
				c-6.78,0.36-12.48,0.08-17.85,1.27c-1.88,0.42-4.43,4.73-4.09,6.85c0.73,4.56,13.32,6.58,16.1,2.75
				C563.38,566.64,564.72,563.22,567.14,558.64z M855.41,894.47c0.54,0.48,1.08,0.97,1.62,1.45c1.66-0.68,4.26-0.92,4.81-2.11
				c2.63-5.65,4.68-11.57,6.97-17.38c3.47-8.76,6.96-17.5,10.45-26.25c0.51-1.28,1.06-2.54,1.6-3.81c-0.64-0.29-1.28-0.59-1.91-0.88
				C868.37,860.5,865.08,879.02,855.41,894.47z M827.91,857.31c-0.01-9.46-2.58-17.65-8.19-24.73c-1.07-1.35-3.45-1.66-4.95-2.34
				c1.23,7.06,2.44,13.13,3.31,19.25C819.14,856.85,819.22,857.04,827.91,857.31z M54.09,875.27c0-9.54,0.12-18.68-0.15-27.8
				c-0.03-1.08-2.18-2.09-3.34-3.13c-0.94,1.2-2.64,2.37-2.68,3.59c-0.25,7.65-0.03,15.31-0.18,22.97
				C47.65,875.18,49.83,875.97,54.09,875.27z M65.16,252.84c-4.95,1.55-9.9,3.1-15.47,4.84c6.1,8.2,1.25,16.09,0.71,24.44
				c0.93-0.37,1.09-1.38,1.67-1.86C60.51,273.35,60.12,262.01,65.16,252.84z M776.31,748.99c-0.39,7.07,1.21,10.78,4.74,10.63
				c4.6-0.19,9.27-0.65,13.7-1.79c1.65-0.42,2.73-3.09,4.07-4.73c-1.44-1.1-2.79-2.99-4.32-3.15
				C788.46,749.33,782.37,749.26,776.31,748.99z M904.72,338.93c-0.03-0.69-0.06-1.37-0.09-2.06c-4.9-0.55-9.79-1.45-14.71-1.55
				c-4.62-0.1-8.29-1.22-11.38-4.91c-1.32-1.58-3.82-2.73-5.92-3c-5.96-0.78-11.98-1-18.02-1.45c0.08,0.26,0.22,1.08,0.58,1.78
				c1.17,2.27,11.02,6.51,13.56,6.05c1.41-0.25,2.96-0.41,4.34-0.12c8.42,1.78,16.78,3.8,25.21,5.49
				C900.34,339.57,902.57,339.03,904.72,338.93z M462.57,241.69c0.69-0.44,1.38-0.88,2.06-1.33c3.27,4.72,6.54,9.44,10.63,15.34
				c-2.67-13.39-8.92-20.75-23.38-20.18c6.01,8.3,11.53,15.91,17.23,23.77c1.19-0.67,2.56-1.44,3.5-1.96
				C469.15,251.94,465.86,246.82,462.57,241.69z M267.3,882.5c-6.09,0.93-3.65,5.17-3.82,7.89c-0.21,3.42,1.33,4.56,4.83,2.65
				c5.85-3.17,9.21-1.16,9.99,5.51c0.05,0.45,0.48,0.86,1,1.74c1.37-9.25,2.37-9.96,11.52-7.91c0,4.74,0,9.7,0,14.66
				c0.47-0.01,0.94-0.03,1.41-0.04c0-8.39,0-16.79,0-25.18c-1.41,2-1.02,4.89-2.27,6.12c-1.48,1.46-4.88,2.62-6.51,1.87
				c-2.17-1-3.44-3.96-4.74-5.64c-1.97,6.65-2.98,7.27-10.73,5.63C267.78,887.57,267.56,885.3,267.3,882.5z M189.39,969.99
				c0-7.45,0-13.94,0-20.34c-0.87-0.09-1.22-0.23-1.51-0.14c-3.35,0.97-6.7,1.94-10.02,2.97c-6.95,2.17-7.49,2.95-6.49,10.22
				c0.14,1.04,1.21,1.95,1.84,2.91c0.63-0.98,1.67-1.89,1.82-2.94c0.92-6.22,2.38-7.47,8.75-7.13
				C186.84,959.69,180.93,967.09,189.39,969.99z M558.63,545.66c4.99-0.49,10.83,0.58,14-1.85c3.1-2.37,3.49-8.31,5.08-12.69
				C568.92,528.48,564.01,530.35,558.63,545.66z M596.66,552.23c-3.32-5.09-6.51-5.39-20.95-2.04c-4.65,1.08-3.97,5.65-5.3,9.09
				c2.67-0.53,5.23-1.91,7.05-1.25C585.7,561.02,590.06,554,596.66,552.23z M683.05,967.55c4.31,3.99,2.88,7.99-0.27,11.97
				c3.27,3.83,3.79,7.7,0.71,11.84c-0.51,0.68-0.55,2.42-0.05,2.95c0.56,0.58,2.09,0.68,3.01,0.39c6.02-1.92,6.46-6.68,1.32-12.39
				c1.76-2.09,4.84-4.15,4.88-6.26c0.04-1.97-3.15-4.01-4.94-6.04c1.59-1.33,3.35-2.81,6.14-5.14c-3.74-0.67-6.11-1.09-8.64-1.54
				C684.46,964.8,683.8,966.1,683.05,967.55z M86.23,1008.84c6.82,0.02,12,0.87,16.59,5.02c1.57,1.42,5.34,0.38,8.1,0.47
				c-0.68-2.54-0.6-6.52-2.18-7.36c-5-2.66-10.6-4.22-16.04-5.98c-0.73-0.24-2.22,0.55-2.75,1.3
				C88.65,1004.1,87.7,1006.18,86.23,1008.84z M257.27,1008.77c3.58-4.15,6.79-7.4,9.29-11.12c0.55-0.82-0.94-4.13-2.28-4.9
				c-3.73-2.13-7.85-3.58-11.86-5.19c-1.84-0.74-3.78-1.26-5.68-1.88C254.03,991.35,253.87,1000.13,257.27,1008.77z M186.38,308.64
				c0.41,0.11,0.83,0.23,1.24,0.34c0.69-1.54,1.33-3.11,2.07-4.63c2.38-4.9,4.99-9.7,7.1-14.71c1.09-2.59,1.7-5.53,1.82-8.34
				c0.13-2.82-1.33-4.52-4.73-4.04c-3.05,0.43-5.49,1.33-4.67,4.83c1.75,7.49-0.16,14.42-2.43,21.4
				C186.26,305.09,186.49,306.92,186.38,308.64z M923.71,805.38c0.43,0.41,0.87,0.83,1.3,1.24c7.94-5.54,16.1-10.8,23.72-16.77
				c3.15-2.47,3.89-6.58,1.84-10.37c-7.36,6.59-14.58,12.93-21.62,19.46C926.94,800.81,925.44,803.22,923.71,805.38z M292.39,1007.91
				c4.75-6.47,4.72-6.46,0.1-13.38c-0.51-0.77-0.46-1.89-0.79-2.8c-0.99-2.74-2.04-5.45-3.07-8.17c-0.56,0.06-1.12,0.12-1.69,0.18
				c-1.95,4.18-3.91,8.36-5.54,11.86C285.24,999.9,288.72,1003.8,292.39,1007.91z M258.97,894.6c0.06-0.29,0.12-0.58,0.19-0.88
				c-2.39,0.36-5.1,0.12-7.12,1.19c-4.07,2.16-7.81,4.97-11.62,7.62c-10.16,7.08-20.28,14.22-30.41,21.33
				c0.24,0.53,0.47,1.06,0.71,1.58c1.74-0.66,3.7-1.01,5.19-2.04c11.32-7.82,22.5-15.83,33.82-23.64
				C252.63,897.78,255.89,896.31,258.97,894.6z M243.5,923.63c0-4.54,0-9.88,0-16.04c-9.07,6.4-17.5,12.34-25.92,18.28
				c0.21,0.47,0.42,0.94,0.62,1.42c1.88-0.8,3.81-1.5,5.62-2.43c1.7-0.87,3.18-2.56,4.93-2.87c3.06-0.53,6.28-0.52,9.4-0.27
				C239.96,921.87,241.69,922.95,243.5,923.63z M694.99,969.97c0,3.02,1.19,6.94-0.25,8.87c-2.84,3.78-1.04,6.09,1.01,10.39
				c3.03-4.79,5.32-8.7,7.9-12.42c3.35-4.84,3.22-7.64-0.84-10.32C698.91,963.93,696.68,964.78,694.99,969.97z M697.97,1017.31
				c7.55,2.96,14.66,5.96,21.93,8.51c4.06,1.42,7.04-0.7,9.12-3.75c-7.41-2.64-14.65-4.91-21.63-7.84
				C703.66,1012.66,700.92,1012.49,697.97,1017.31z M393.01,365.85c6.34-0.1,7.05-0.68,6.88-5.58c-0.23-6.63-1.9-8.74-7.58-8.23
				c-2.58,0.23-7.16-1.78-7.14,3.6C385.18,362.81,387.76,365.93,393.01,365.85z M706.06,1010.61c7.79,3.64,15.41,7.14,23.95,7.41
				c1.89,0.06,3.83-1.66,6.77-3.04c-9.43-3.47-17.41-6.42-25.22-9.3C709.8,1007.27,708.13,1008.76,706.06,1010.61z M700.55,322.7
				c-1.56-0.22-2.15-0.5-2.53-0.32c-1.51,0.67-2.96,1.48-4.43,2.24c1.4,0.69,2.75,1.86,4.21,2c8.15,0.81,16.33,1.42,24.5,2.02
				c3.13,0.23,6.27,0.31,9.41,0.34c0.99,0.01,1.99-0.35,2.99-0.54c-0.72-0.94-1.38-2.63-2.17-2.69c-4.99-0.42-10.01-0.53-15.02-0.6
				c-1.24-0.02-3.17,1.13-3.64,0.63C709.7,321.45,704.62,321.98,700.55,322.7z M509.62,238.17c0.3-0.48,0.59-0.97,0.89-1.45
				c-1.77-1.14-3.4-2.81-5.33-3.3c-3.02-0.77-6.23-0.82-9.37-1.03c-5.5-0.37-11-0.62-16.74-0.94c0.08,1.55,0.03,2.21,0.17,2.83
				c1.67,7.27,3.5,7.7,9.38,3.48c2.1-1.51,6.68-2.64,8.05-1.48C500.97,239.92,505.34,237.66,509.62,238.17z M925.81,811.99
				c7.47-2.99,15.02-5.77,22.36-9.04c4.73-2.1,5.35-5.13,2.88-11.03C943.17,799.38,932.32,802.74,925.81,811.99z M271.01,296.59
				c0.71,0.57,1.43,1.14,2.14,1.71c3.17-4.15,6.62-8.11,9.45-12.48c3.77-5.8,6.53-12.32,10.7-17.78c3.7-4.83,4.67-7.43,1.57-12.69
				C284.32,267.57,285.12,286.19,271.01,296.59z M546.3,956.71c0.27-0.47,0.53-0.94,0.8-1.4c-4.77-2.66-7.85-6.27-6.77-11.58
				c-6.74,3.45-12.98,6.65-19.22,9.84c0.11,0.5,0.22,1,0.33,1.5C529.72,955.62,538.01,956.17,546.3,956.71z M721.59,1030.35
				c-7.62-3.41-14.91-6.56-22.08-9.94c-4.5-2.12-4.43-2.29-8.92,2.19c7.54,3.31,14.75,6.66,22.11,9.63
				C715.81,1033.48,719.19,1033.69,721.59,1030.35z M233.62,983.13c5.69,0,10.02,0,15.37,0c-0.63-4.26-0.2-9.71-1.81-10.4
				c-3.31-1.42-8.23-1.37-11.45,0.21C233.98,973.8,234.36,979.01,233.62,983.13z M741.78,1006.34c-8.53-2.39-16.43-4.6-24.7-6.92
				c-0.62,1.21-1.32,2.56-2.52,4.9c7.66,2.3,14.66,4.66,21.8,6.43C739.57,1011.55,742.37,1010.43,741.78,1006.34z M950.09,385.86
				c0.19-0.63,0.39-1.26,0.58-1.89c-3.57-8.39-11.77-4.49-17.97-5.62c-4.08-0.75-6.52,1.51-6.91,6.14
				C934.05,384.95,942.07,385.4,950.09,385.86z M597.46,524.98c0.08,0.66,0.15,1.33,0.23,1.99c2.88-0.17,5.76-0.41,8.64-0.5
				c4.93-0.16,9.93-0.72,14.78-0.15c3.27,0.39,4.69-0.15,6.11-3.13c0.9-1.88,3.34-3.01,5.06-4.51c1.17-1.03,2.28-2.12,3.9-3.64
				c-8.72-1.77-8.75-1.54-12.74,4.91c-0.97,1.57-3.59,2.67-5.6,2.95C611.08,523.86,604.26,524.33,597.46,524.98z M716.34,1037.55
				c-9.18-4.43-17-7.9-24.5-11.96c-3.31-1.79-5.73-1.8-9.36,1.94c9.53,3.8,18.44,7.48,27.48,10.83
				C711.44,1038.91,713.49,1037.96,716.34,1037.55z M488.91,254.14c2.37,8.46,4.46,15.88,6.35,22.61c1.01-6.98,2.1-14.4,3.16-21.82
				c0.16-1.15,0.34-2.33,0.27-3.48c-0.07-1.02-0.2-2.59-0.8-2.88c-0.82-0.39-2.37-0.14-3.14,0.45
				C492.51,250.71,490.52,252.7,488.91,254.14z M581.11,543.82c0.15,0.46,0.31,0.92,0.46,1.39c12.5,0,25.01,0,37.97,0
				c-0.08-5.71-4.12-4.76-6.88-4.76c-6.71,0.01-13.44,0.23-20.12,0.84C588.68,541.65,584.91,542.95,581.11,543.82z M843.41,859.65
				c0.06,0.83,0.12,1.66,0.19,2.5c2.87,0.43,5.91,1.66,8.58,1.1c4.63-0.96,9.07-2.95,13.52-4.68c0.92-0.36,2.06-1.43,2.13-2.26
				c0.06-0.68-1.21-1.9-2.09-2.14c-1.87-0.52-4.07-1.2-5.77-0.66C854.36,855.28,848.91,857.56,843.41,859.65z M801.94,448.46
				c-4.2-7.06-12.32-9.47-21.58-6.4C784.66,449.63,795.04,452.7,801.94,448.46z M196.8,920.89c5.76,3.06,10.37,2.82,14.61-1.43
				c1.62-1.62,3.81-2.66,5.7-4c4.33-3.07,8.64-6.17,12.96-9.26c-9.7,3.4-18.83,7.67-27.95,11.97
				C200.5,918.94,198.92,919.8,196.8,920.89z M830.71,848.68c7.78-2.83,15.17-5.51,22.56-8.2c-1.53-4.62-3.88-5.45-9.65-3.41
				C834.34,840.36,829.25,844.94,830.71,848.68z M826.69,836.49c2.96-3.2,5.85-6.15,8.51-9.29c1.05-1.24,2.59-3.67,2.15-4.25
				c-1.39-1.87-3.45-3.43-5.58-4.4c-0.55-0.25-2.48,2.02-3.44,3.38c-1.23,1.75-1.94,3.87-3.18,5.61
				C822.79,830.84,823.26,833.61,826.69,836.49z M653.61,1055.83c-1.39-2.75-3.02-6.5-5.15-9.95c-0.87-1.4-2.81-2.14-4.27-3.17
				c-0.36,2.09-1.39,4.34-0.93,6.22c1.03,4.17,2.76,8.16,4.3,12.19c0.43,1.13,1.18,2.14,1.78,3.21c1.03-0.92,2.39-1.67,3.01-2.81
				C653.04,1060.28,653.04,1058.66,653.61,1055.83z M216.99,1050.44c9.62,6.79,16.63,7.51,23.79,2.39
				C233.41,1047.97,225.78,1045.11,216.99,1050.44z M872.5,376.06c-0.53-0.55-1.06-1.09-1.59-1.64c-1.62,1.11-4.11,1.89-4.69,3.4
				c-1.77,4.59-2.74,9.48-4.14,14.21c-0.95,3.21-2.1,6.37-3.16,9.55c0.57,0.35,1.13,0.7,1.7,1.05c1.18-1.2,2.79-2.2,3.45-3.64
				c2.03-4.46,3.78-9.06,5.48-13.66C870.66,382.3,871.52,379.16,872.5,376.06z M929.37,849.46c-5.46,1.15-10.18,2.15-15.1,3.19
				c3.88,7.19,8.56,9.92,13.21,7.75C932.94,857.86,929.39,853.63,929.37,849.46z M154.85,939.28c0,9.64,0,18.5,0,27.36
				c0.46,0.01,0.92,0.01,1.39,0.02c0-4.53,0.09-9.07-0.03-13.6c-0.08-2.91,1.02-4.6,3.88-5.44c3.32-0.97,6.54-2.28,11.05-3.9
				C164.93,942.03,160.19,940.74,154.85,939.28z M661.71,1044.5c6.61-4.31,4.57-11.15-4.75-17.38c-5.16,3.58-5.22,3.97-1.63,8.73
				C657.42,1038.62,659.45,1041.42,661.71,1044.5z M729,331.44c-0.97-0.05-1.95-0.08-2.92-0.14c-5.97-0.41-11.84,1.04-17.97-1.51
				c-3.98-1.66-9.27-0.15-13.98-0.06c0.02,0.63,0.04,1.25,0.07,1.88C705.8,333.41,717.31,337.85,729,331.44z M409.07,241.25
				c-0.06-0.68-0.13-1.35-0.19-2.03c-10.14,1.38-20.28,2.75-30.85,4.19c1.62,2.25,2.79,3.88,3.62,5.03
				C391.04,245.97,400.05,243.61,409.07,241.25z M894.25,342.74c0.04-0.71,0.08-1.41,0.12-2.12c-9.26-0.4-17.8-5.45-27.49-3.27
				c-1.04,0.23-2.42-0.63-3.52-1.21c-1.33-0.71-2.53-1.67-3.79-2.51c-0.33,0.62-0.66,1.24-0.99,1.86c2.91,1.38,5.69,3.23,8.74,4.05
				c5.27,1.41,10.68,2.42,16.09,3.13C886.97,343.12,890.64,342.74,894.25,342.74z M524.01,275.78c2.25-1.56,4.71-3.65,7.5-5.13
				c5.08-2.7,10.38-4.98,15.53-7.56c0.65-0.33,1.41-1.5,1.27-2.06c-0.18-0.69-1.31-1.67-1.94-1.6c-1.76,0.18-3.58,0.59-5.18,1.34
				c-3.13,1.46-5.96,3.77-9.21,4.7C526.04,267.15,523.53,270.72,524.01,275.78z M521,260.18c-1.06-6.49,0.13-13.06-5.01-17.95
				c-0.93-0.89-2.53-1.07-3.82-1.57c-0.16,1.5-0.64,3.06-0.4,4.5c0.48,2.88,2.03,5.73,1.91,8.54
				C513.46,258.84,516.4,259.65,521,260.18z M849.44,831.22c-3.15-1.45-5.9-3.54-8.84-3.84c-3.24-0.33-11.58,10.34-12.04,14.3
				C835.07,838.42,841.6,835.14,849.44,831.22z M543.09,559.47c-9.84,1.3-19.08,2.51-28.31,3.73c0.09,0.73,0.19,1.47,0.28,2.2
				c2.06-0.34,4.1-0.74,6.17-1.01c1.25-0.16,2.57-0.37,3.79-0.16c3.56,0.63,7.11,1.35,10.61,2.23
				C540.28,567.63,542.49,565.71,543.09,559.47z M790.03,493.58c9.96-2.41,12.14-4.97,12.04-15.15c-3.13,0.91-7.35,0.88-8.5,2.74
				C791.47,484.59,791.18,489.12,790.03,493.58z M225.69,343.14c-11.05-2.75-33.39,0.64-35.61,5.08c1.42,0,2.7,0.19,3.92-0.03
				c9.09-1.61,18.08-4.23,27.49-1.99C222.37,346.42,223.73,344.64,225.69,343.14z M576.38,389.1c-4.72-0.45-8.79-1.55-12.49-0.81
				c-1.3,0.26-1.69,5.05-2.48,7.72C570.7,399.02,571.94,398.48,576.38,389.1z M346.57,241.41c-2.1-6.65-2.16-6.68-14.18-6.38
				c0.28,2.53-0.36,6.14,1,7.23C337.47,245.5,342.17,245.21,346.57,241.41z M55.5,898.18c1.55-12.88,1.28-10.96-8.96-10.98
				C44.55,897.07,46.79,899.81,55.5,898.18z M77.03,883c0.09-0.73,0.18-1.47,0.27-2.2c-16.84-1.56-33.69-3.13-50.53-4.69
				c-0.05,0.54-0.1,1.08-0.16,1.62C43.42,879.49,60.23,881.25,77.03,883z M183.58,302.51c3.65-6.73,4.17-13.68,2.38-20.82
				c-0.32-1.29-1.56-2.35-2.37-3.51c-0.68,1.39-1.8,2.72-1.95,4.17c-0.32,3.06,0.61,6.43-0.34,9.19
				C179.78,295.93,180.84,299.19,183.58,302.51z M277.32,363.65c-7.92-3.85-18.25-2.73-23.6,2.15c5.12,0.63,10.62,0.97,15.96,2.08
				C273.85,368.75,275.8,366.98,277.32,363.65z M225.81,1071.78c0.45,0.04,0.97,0.23,1.4,0.11c2.56-0.69,5.12-1.41,7.66-2.19
				c5.72-1.77,6.09-2.55,4.25-8.5C229.13,1062.08,224.77,1065.52,225.81,1071.78z M518.83,297.45c0.17-2.83,0.41-4.4,0.33-5.95
				c-0.54-10.13-6.97-19.16-5.58-29.67c0-0.03-0.3-0.1-0.45-0.15C509.7,267.45,512.79,290.38,518.83,297.45z M553.74,555.48
				c15.25,1.17,15.98,0.45,16.41-6.89C558.83,547.32,556.91,548.08,553.74,555.48z M227.15,1061.97c-0.09-0.35-0.19-0.69-0.28-1.04
				c-4.77,1.26-9.77,2-14.28,3.88c-10.04,4.19-19.18,3.28-27.95-3.13c-3.74-2.73-7.62-4.21-12.45-1.85
				c-3.86,1.89-8.19,2.8-12.31,4.15c0.15,0.48,0.3,0.95,0.45,1.43c0.87-0.2,1.84-0.22,2.59-0.64c10.64-5.91,20.52-3.79,30.15,2.56
				c1.72,1.13,4.18,2.43,5.92,2.03C208.45,1067.18,217.78,1064.49,227.15,1061.97z M159.35,952.53c0,3.66,0.06,6.94-0.02,10.21
				c-0.07,3.04,1.61,3.72,4.15,3.23c1.44-0.28,2.85-0.77,4.23-1.16C169.09,954.23,168.76,953.7,159.35,952.53z M950.98,806.83
				c-0.34-0.39-0.67-0.78-1.01-1.17c-4.3,1.57-8.63,3.06-12.89,4.75c-3.07,1.22-6.09,2.58-9,4.14c-0.71,0.38-0.89,1.75-1.31,2.66
				c0.83,0.21,1.8,0.81,2.48,0.57c5.78-1.98,11.6-3.9,17.2-6.32C948.29,810.65,949.49,808.41,950.98,806.83z M39.48,882.05
				c-0.12,0.71-0.24,1.42-0.37,2.13c1.48,0.07,2.96,0.12,4.44,0.21c4.7,0.29,9.62-0.1,13.95,1.31c1.27,0.42,0.87,5.98,1.21,9.19
				c0.09,0.82-0.21,1.78,0.12,2.45c0.44,0.89,1.32,1.56,2.02,2.32c0.83-0.79,2.31-1.52,2.38-2.37c0.28-3.45,0.25-6.95,0.04-10.41
				c-0.05-0.88-1.08-2.35-1.8-2.44C54.16,883.52,46.81,882.82,39.48,882.05z M926.8,873.03c4.43,0.52,5.05-2.09,4.95-5.57
				c-0.1-3.54-1.7-4.77-5.11-3.93c-1.28,0.31-2.7,0.15-3.91,0.61c-1.33,0.51-3.6,1.65-3.51,2.21c0.29,1.87,1.06,4.06,2.41,5.26
				C922.82,872.68,925.04,872.6,926.8,873.03z M638.75,1049.73c-2.17,7.62,1.96,14.51,2.49,22.37
				C646.81,1067.68,645.89,1058.76,638.75,1049.73z M652.14,1035.6c-4.7,2.12-4.61,4.18-2.36,7.07c1.92,2.47,3.17,5.44,4.96,8.02
				c0.9,1.3,2.34,2.22,3.53,3.31c0.5-1.87,1.95-4.19,1.33-5.54C657.54,1044.03,654.74,1039.96,652.14,1035.6z M745.21,999.8
				c-7.42-1.89-14.45-3.78-21.54-5.4c-1.1-0.25-2.47,0.69-4.51,1.32C729.05,1001.91,741.34,1003.75,745.21,999.8z M148.86,964.74
				c0.76-0.08,1.53-0.16,2.29-0.24c0-7.9,0.08-15.8-0.1-23.7c-0.03-1.09-1.36-2.16-2.08-3.23c-0.47,0.21-0.93,0.41-1.4,0.62
				C148.27,947.01,146.25,956.05,148.86,964.74z M269.76,1059.7c-1.7,9.1,0.6,11.25,9.53,9.3
				C280.69,1060.14,279.73,1059.18,269.76,1059.7z M821.19,829.01c2.03-3.76,4.07-7,5.48-10.49c0.45-1.1-0.44-3.31-1.43-4.22
				c-0.58-0.52-3.31,0.11-3.84,0.98c-2.21,3.59-3.94,7.47-5.98,11.5C817.6,827.62,819.09,828.2,821.19,829.01z M634.86,1055.02
				c-3.63-0.45-5.06,0.57-4.18,4.03c0.82,3.2,1.52,6.44,2.2,9.68c0.55,2.62,0.98,5.26,1.46,7.9c0.5-0.05,0.99-0.1,1.49-0.16
				C639.8,1069.44,636.04,1062.53,634.86,1055.02z M742.08,996.36c1.19-0.74,2.42-1.5,3.65-2.27c-0.51-0.73-0.91-1.96-1.55-2.11
				c-6.09-1.37-12.22-2.62-18.37-3.73c-0.67-0.12-1.54,0.8-2.32,1.25c0.62,0.78,1.11,2.02,1.89,2.25
				C730.75,993.36,736.16,994.75,742.08,996.36z M260.53,881.91c-9.46-1.7-11.02,0.14-8.46,9.03c2.78,0,5.5,0,8.46,0
				C260.53,888.07,260.53,885.12,260.53,881.91z M280.94,987.42c-5.83,0.43-11.21,0.82-16.39,1.2c1.88,2.02,3.42,3.82,5.12,5.45
				c0.73,0.7,1.76,1.39,2.72,1.51C277.5,996.22,279.51,994.33,280.94,987.42z M293.43,954.62c-0.72-3.13,3.36-8.02-2.97-8.27
				c-3.08-0.12-7.67-1.47-7.13,4.5C284.06,958.87,290.19,952.54,293.43,954.62z M205.18,1071.63c-8.15-0.03-10.31,3.72-6.56,11.39
				C206.7,1081.48,207.58,1079.96,205.18,1071.63z M90.63,886.82c0,4.22-0.17,8.19,0.12,12.11c0.07,0.99,1.93,2.76,2.5,2.6
				c1.2-0.35,2.79-1.64,2.97-2.75c0.44-2.75,0.16-5.61,0.19-8.42C96.45,886.67,94.56,885.73,90.63,886.82z M918.14,874.5
				c1.14,3.32,1.38,6.6,2.59,7.02c2.99,1.02,6.44,0.9,9.69,0.78c0.48-0.02,1.19-2.51,1.17-3.85c-0.01-0.75-1.2-1.97-2.03-2.15
				C926.39,875.6,923.13,875.24,918.14,874.5z M216.56,951.17c-9.36-1-11.02,0.82-8.44,8.83
				C217.29,960.39,217.6,960.09,216.56,951.17z M936.79,840.95c-5.84,0.87-11.04,1.63-16.23,2.42c-2.97,0.45-5.92,0.96-5.45,5.45
				C923.27,848.77,930.46,846.74,936.79,840.95z M622,1061.57c-2.9,4.28-3.52,17.75-0.97,21.04
				C624.74,1078.94,625.23,1068.35,622,1061.57z M271.07,955.38c3.4-1.31,8.9,2.74,9.65-4.16c0.6-5.51-3.44-3.92-6.24-3.97
				C267.58,947.11,271.67,952.19,271.07,955.38z M125.81,864.76c4.29-2.49,7.84-4.55,11.39-6.61c-0.04-0.68-0.08-1.36-0.13-2.04
				c-3.56-0.83-7.12-1.66-11.26-2.63C125.81,857.52,125.81,860.58,125.81,864.76z M853.93,865.54c2.6,3,4.82,5.56,7.33,8.46
				c1.55-3.95,3.06-7.79,5.15-13.12C861.05,862.88,857.16,864.33,853.93,865.54z M327.61,1072.88c2.73,4.04,5.15,7.64,7.6,11.27
				c3.07-1.07,4.28-3.62,3.25-7.08C337.41,1073.53,333.37,1071.85,327.61,1072.88z M251.04,956.53c3.14-1.7,8.71,2.73,9.42-4.05
				c0.54-5.16-3.3-4.3-6.36-4.18C247.75,948.55,251.18,953.09,251.04,956.53z M329.51,986.16c7-5.59,4.6-12.48,2.34-20.63
				C331.91,973.48,323.13,978.81,329.51,986.16z M230.29,980.71c2.99-7.59-2.59-11.04-6.33-15.69
				C221.96,974.54,223.16,977.56,230.29,980.71z M663.4,326.05c8.72,0,17.45,0,26.19,0C688.58,321.95,675.26,321.39,663.4,326.05z
				M832.22,898.42c-1.33,1.86-2.89,3.08-3,4.41c-0.34,4.16-0.12,8.37-0.12,12.56C834.96,915.57,836.86,907.76,832.22,898.42z
				M239.39,950.31c-0.55-0.57-1.1-1.13-1.65-1.7c-2.77,0.95-5.78,1.52-8.16,3.06c-0.74,0.48-0.25,4.99,0.49,5.24
				c2.17,0.73,5.38,1.14,6.99-0.01C238.61,955.8,238.67,952.6,239.39,950.31z M361.65,1183.41c-0.59-0.08-1.17-0.16-1.76-0.24
				c-1.25,4.78-2.5,9.56-3.75,14.32c3.18,0,5.42,0,8.03,0C363.27,1192.5,362.46,1187.95,361.65,1183.41z M551.96,367.32
				c11.7,0,23.4,0,35.1,0c-0.05-1.02-0.1-2.03-0.16-3.05C575.19,364.41,563.65,367.38,551.96,367.32z M705.34,995.33
				c5.06-2.31,6.95-6.02,7.42-14.6C707.45,980.92,704.15,987.4,705.34,995.33z M926.75,796.24c21.01-17.37,21.39-17.79,20.79-23.18
				C940.33,781.1,933.54,788.67,926.75,796.24z M77.26,896.15c1.35-5.97-1.52-7.7-10.18-6.15C65.73,896.08,65.69,895.73,77.26,896.15
				z M918.83,838.98c10.7,1.78,19.91-1.37,22.18-6.46C933.47,834.72,926.15,836.85,918.83,838.98z M115.6,908.75
				c0.52-0.26,1.04-0.52,1.55-0.78c0-3.95,0.14-7.9-0.1-11.84c-0.06-0.97-1.38-1.85-2.12-2.78c-0.74,0.88-2.03,1.7-2.12,2.64
				c-0.27,2.97-0.38,6.02,0.05,8.95C113.06,906.32,114.65,907.49,115.6,908.75z M627.96,1059.21c-0.63,0.02-1.27,0.03-1.9,0.05
				c0.31,6.9,0.62,13.79,0.93,20.69c0.5-0.02,1-0.03,1.5-0.05C632.79,1072.92,629.15,1066.09,627.96,1059.21z M80.98,868.69
				c0,3.17,0,5.89,0,8.9c2.99-0.25,6.42,2.08,7-3.11C88.58,869.13,85.54,868.43,80.98,868.69z M163.44,949.72
				c8.01,3.96,13.71-0.71,20.38-1.91C176.5,943.27,170.64,947.18,163.44,949.72z M105.85,890.95c0,5.37-0.11,9.49,0.1,13.59
				c0.04,0.74,1.35,1.57,2.24,2.01c0.28,0.14,1.61-0.84,1.63-1.34c0.15-3.82,0.27-7.67-0.04-11.47
				C109.72,892.96,107.84,892.31,105.85,890.95z M185.96,722.19c2.78,3.81,2.79,3.82,6.24,1.87c2.98-1.68,5.94-3.38,8.85-5.04
				C198.67,715.98,194.98,716.57,185.96,722.19z M147.75,932.65c3.21-3.15,4.58-15.8,2.81-17.41c-0.37-0.33-2.67,0.53-2.68,0.89
				C147.69,921.18,147.75,926.25,147.75,932.65z M44.35,861.27c0.46-0.43,0.92-0.87,1.37-1.3c-0.97-1.26-1.78-3.41-2.94-3.62
				c-3.82-0.69-8.54-3.12-10.71,3.75C36.36,860.51,40.35,860.89,44.35,861.27z M873.98,238.56c0.16-0.5,0.33-1,0.49-1.5
				c-11.07-3.96-22.14-7.92-33.21-11.88c5.27,2.97,10.7,5.46,16.27,7.62C862.95,234.9,868.5,236.66,873.98,238.56z M100.21,885.09
				c-0.15,0.74-0.3,1.48-0.45,2.23c7.85,1.54,15.69,3.07,23.54,4.61c0.14-0.72,0.27-1.45,0.41-2.17
				C115.87,888.19,108.04,886.64,100.21,885.09z M912.54,896.48c5.99,2.91,9.33,2.33,12.51-2.18
				C917.45,890.87,915.21,891.26,912.54,896.48z M125.41,832.28c5.27,1.16,10.54,2.32,16.44,3.62
				C138.76,829.66,133.81,828.53,125.41,832.28z M670.25,1028.27c-3.33-2.34-5.95-4.33-8.74-6.06c-0.72-0.45-1.94-0.09-2.94-0.11
				c0.13,0.86-0.06,2.02,0.43,2.52c2.73,2.74,5.63,5.32,8.81,8.27C668.74,1031.13,669.48,1029.73,670.25,1028.27z M770.26,897.68
				c8.82,5.79,12.82,5.36,15.86-1.24C781.1,896.84,776.31,897.21,770.26,897.68z M916.96,888.31c7.77,3.67,10.93,3.36,12.34-1.24
				C922.2,883.9,918.72,884.25,916.96,888.31z M282.13,1068.3c0.53,0.36,1.07,0.72,1.6,1.08c1.67-0.9,4.41-1.5,4.78-2.78
				c0.61-2.11-0.23-4.68-0.76-6.99c-0.04-0.18-3.32-0.18-3.5,0.32C283.27,1062.62,282.78,1065.49,282.13,1068.3z M101.14,890.33
				c-0.54,0.22-1.08,0.44-1.62,0.66c0,3.92-0.13,7.85,0.09,11.75c0.05,0.96,1.26,1.86,1.93,2.78c0.51-0.89,1.4-1.76,1.45-2.68
				c0.17-3.12,0.21-6.26-0.05-9.37C102.87,892.39,101.77,891.38,101.14,890.33z M121.69,894.46c-0.41,0.17-0.82,0.35-1.22,0.52
				c0,4.2-0.08,8.41,0.09,12.6c0.03,0.66,1.18,1.27,1.82,1.91c0.58-0.64,1.63-1.26,1.67-1.93c0.17-3.44,0.25-6.91-0.03-10.34
				C123.91,896.25,122.49,895.38,121.69,894.46z M846.98,918.9c0.11,0.45,0.22,0.9,0.33,1.35c1.75-0.75,4.29-1.02,5.09-2.35
				c2.12-3.52,3.69-7.49,0.37-11.58C850.78,910.66,848.88,914.78,846.98,918.9z M193.37,969.6c0.48,0,0.96,0,1.44,0
				c0-6.36,0-12.72,0-19.08c-0.48,0-0.96,0-1.44,0C193.37,956.88,193.37,963.24,193.37,969.6z M221.46,1067.21
				c-5.76,0.34-6.84,2.68-4.56,9.92C222.59,1076.3,223.13,1075.12,221.46,1067.21z M146.39,898.79c0.17-0.79,0.34-1.58,0.51-2.37
				c-6.3-1.24-12.6-2.47-18.91-3.71c-0.15,0.78-0.31,1.55-0.46,2.33C133.82,896.3,140.11,897.54,146.39,898.79z M284.96,844.63
				c-3.85-0.01-7.05-0.7-6.92,4.03c0.06,2.28,0.09,4.42,3.32,4.09C287.18,852.15,283.66,847.82,284.96,844.63z M518.36,279.12
				c0.6-0.07,1.21-0.14,1.81-0.2c0.67-13.3,0.67-13.3-3.92-16.04C517.01,268.75,517.68,273.94,518.36,279.12z M40.73,844.98
				c-0.49-0.42-0.98-0.84-1.48-1.25c-5.36,5.77-10.72,11.55-16.09,17.32c0.47,0.44,0.95,0.88,1.42,1.33
				C29.97,856.57,35.35,850.78,40.73,844.98z M287.71,905.11c0-3.15,0.15-5.79-0.09-8.4c-0.08-0.84-1.07-1.93-1.88-2.24
				c-0.58-0.22-1.93,0.49-2.33,1.15C281.62,898.58,283.48,903.24,287.71,905.11z M238.95,866.38c0.47,0,0.95,0,1.42,0
				c0-5.6,0-11.2,0-16.8c-0.47,0-0.95,0-1.42,0C238.95,855.18,238.95,860.78,238.95,866.38z M827.36,754.37
				c-0.04-0.61-0.08-1.22-0.12-1.83c-3.91-0.47-7.82-0.93-11.96-1.43c0,1.8,0,3.06,0,5.01C819.47,755.52,823.42,754.94,827.36,754.37
				z M509,561.48c-4.07-4.71-9.3-3.21-14.58-3.05C498.36,565.42,504.56,558.74,509,561.48z M95.66,878.24
				c1.62-7.31,0.85-8.48-4.81-7.24C90.64,874.45,89.17,878.56,95.66,878.24z M218.75,957.57c5.27,0.79,7.57,0.39,7.33-3.79
				c-0.14-2.38-1.13-4.58-3.24-3.31C221.02,951.58,220.35,954.61,218.75,957.57z M89.52,1143.43c-3.29,7.18-2.99,9.61,2.03,13.38
				c0-3.79,0.14-6.65-0.07-9.48C91.4,1146.28,90.49,1145.3,89.52,1143.43z M645.24,248.1c7.06-0.6,7.06-0.6,8.15-3.48
				c-2.01-1.42-3.91-2.76-6.15-4.34C646.54,243,645.91,245.48,645.24,248.1z M83.92,897.89c1.97-2.51,2.96-3.78,3.96-5.05
				c-1.23-0.88-2.46-2.47-3.67-2.45c-1.19,0.02-3.03,1.49-3.33,2.65C80.64,893.98,82.27,895.4,83.92,897.89z M249.31,953.24
				c-1.96-1.98-3.01-3.04-4.06-4.1c-1.05,1.25-2.76,2.42-2.94,3.79c-0.16,1.16,1.44,2.56,2.27,3.86
				C245.83,955.85,247.1,954.9,249.31,953.24z M511.69,556.26c-7.29-5.29-14.28-2.16-21.15-1.21
				C497.21,555.43,503.88,555.81,511.69,556.26z M100.1,870.38c-0.13,0.71-0.26,1.42-0.4,2.12c5.51,1.01,11.01,2.02,16.52,3.03
				c0.12-0.64,0.24-1.28,0.35-1.91C111.09,872.54,105.59,871.46,100.1,870.38z M69.79,863.84c-1.9-2.47-2.58-4.12-3.76-4.68
				c-1.51-0.71-3.45-0.7-5.19-0.63c-0.39,0.01-1.2,2.13-0.99,2.32c1.11,0.96,2.42,1.87,3.81,2.31
				C65.14,863.62,66.79,863.54,69.79,863.84z M121.33,864.51c0.1-0.54,0.2-1.08,0.3-1.62c-6.56-1.26-13.12-2.52-19.68-3.79
				c-0.1,0.52-0.2,1.04-0.29,1.56C108.22,861.95,114.77,863.23,121.33,864.51z M438.53,341.41c0.37-0.06,0.74-0.11,1.12-0.17
				c-1.33-5.95-2.67-11.9-4-17.85c-0.79,0.2-1.57,0.41-2.36,0.61C435.03,329.81,436.78,335.61,438.53,341.41z M878.47,345.83
				c0.07-0.48,0.15-0.97,0.22-1.45c-4.64-0.7-9.27-1.4-13.91-2.1C868.55,348.57,873.92,344.6,878.47,345.83z M154.51,899.2
				c-0.76-3.68-1.42-6.89-2.09-10.1c-0.63,0.1-1.27,0.2-1.9,0.31c0,3.68,0,7.35,0,11.49C152.16,900.19,153.34,899.69,154.51,899.2z
				M209.83,1079.59c4.99-1.72,2.49-5.57,2.9-8.35c0.07-0.49-1.15-1.17-1.77-1.77c-0.61,2.19-1.35,4.36-1.75,6.58
				C209.04,1077.06,209.56,1078.18,209.83,1079.59z M140.14,891.63c-1.26-3.32-2.18-5.73-3.1-8.14c-0.77,0.6-2.16,1.16-2.21,1.81
				C134.66,887.87,133.61,891.18,140.14,891.63z M48.14,269.71c0.48-0.12,0.95-0.25,1.43-0.37c1.91-5.08-1.33-8.35-3.54-11.99
				c-0.43,0.14-0.86,0.28-1.29,0.42C45.87,261.74,47.01,265.73,48.14,269.71z M132.06,906.21c-0.69-3.08,1.68-7.19-4.87-7.93
				C128.03,901.87,125.33,906.14,132.06,906.21z M127.58,869.79c-0.56-0.49-1.12-0.97-1.68-1.46c-2.3,2.85-4.59,5.7-7.53,9.34
				C126.06,878.24,124.43,871.88,127.58,869.79z M133.32,886.02c-1.63-1.62-2.69-2.67-3.75-3.72c-0.75,1.2-2.16,2.46-2.06,3.59
				c0.11,1.35,1.52,2.59,2.37,3.87C130.8,888.76,131.73,887.75,133.32,886.02z M269.02,952.13c-1.51-1.83-2.4-2.91-3.29-3.99
				c-0.82,1.28-2.19,2.51-2.3,3.85c-0.1,1.17,1.21,2.46,1.89,3.7C266.38,954.68,267.44,953.66,269.02,952.13z M52.63,838.35
				c0.68-0.64,1.36-1.28,2.04-1.92c-1.03-1.42-1.82-3.22-3.2-4.07c-0.54-0.33-2.39,1.46-3.64,2.28
				C49.43,835.88,51.03,837.11,52.63,838.35z M87.84,259.42c2.39-2.01,3.86-3.25,5.32-4.48c-1.41-1.47-2.83-2.93-4.24-4.4
				c-0.36,0.26-0.72,0.53-1.09,0.79C87.84,253.41,87.84,255.49,87.84,259.42z M134.1,900.22c0.85,2.58,1.59,4.83,2.33,7.09
				c0.89-0.73,2.41-1.36,2.54-2.21C139.36,902.72,139.89,899.77,134.1,900.22z M155.51,894.52c4.89,1.16,5.98-0.59,5.47-3.38
				c-0.19-1.04-1.59-1.86-2.44-2.79C157.6,890.25,156.67,892.14,155.51,894.52z M786.91,906.31c-0.44,0.34-0.89,0.68-1.33,1.02
				c1.58,2.18,2.98,4.53,4.84,6.44c0.69,0.71,2.44,0.37,3.71,0.51c-1.06-2.21-2.44-3.07-3.62-4.15
				C789.21,908.95,788.1,907.59,786.91,906.31z M783.93,911.58c-1.78,2.47-3.21,4.46-5.17,7.19c3.68-0.85,6.33-1.46,9.48-2.18
				C786.69,914.79,785.54,913.45,783.93,911.58z M44.1,865.84c0.05-0.52,0.11-1.04,0.16-1.56c-5.04-0.44-10.08-0.87-15.12-1.31
				c-0.04,0.47-0.08,0.93-0.12,1.4C34.05,864.86,39.08,865.35,44.1,865.84z M181.92,958.9c-0.72-0.19-1.44-0.37-2.16-0.56
				c-0.49,1.7-1.26,3.38-1.36,5.09c-0.05,0.94,1.03,1.94,1.61,2.91c0.64-0.89,1.63-1.71,1.83-2.68
				C182.14,962.13,181.92,960.49,181.92,958.9z M108.25,884.02c0.75-0.37,1.49-0.73,2.24-1.1c-0.72-1.85-1.45-3.7-2.17-5.55
				c-0.84,0.8-2.5,1.8-2.36,2.37C106.3,881.27,107.43,882.61,108.25,884.02z M40.11,869c0.82,2,1.57,3.82,2.32,5.63
				c0.89-0.65,2.42-1.17,2.57-1.96C45.54,869.9,44.41,868.25,40.11,869z M285.04,923.38c-0.05-0.21-0.1-0.41-0.15-0.62
				c-5.12,0-10.24,0-15.36,0c0.01,0.21,0.03,0.41,0.04,0.62C274.73,923.38,279.89,923.38,285.04,923.38z M75.07,871.57
				c-0.72,0.6-1.44,1.19-2.16,1.79c0.99,1.44,1.98,2.88,2.97,4.32c0.68-0.91,2.09-2.08,1.88-2.67
				C77.3,873.71,76.02,872.7,75.07,871.57z M579.26,206.71c0.36,0.52,0.73,1.04,1.09,1.56c2.96-1.68,5.93-3.36,8.89-5.04
				C585.12,202.98,581.37,203.36,579.26,206.71z M34.45,867.37c-0.77,0.52-1.54,1.04-2.3,1.56c0.81,1.59,1.63,3.18,2.44,4.77
				c0.96-0.8,2.87-2.02,2.73-2.33C36.67,869.9,35.46,868.68,34.45,867.37z M584.68,370.17c-3.58-0.25-6.88-0.49-10.18-0.72
				C582.25,373.81,582.81,373.83,584.68,370.17z M270.19,970.9c-0.41-0.06-0.83-0.13-1.24-0.19c0,2.88,0,5.76,0,8.63
				c0.41,0.01,0.83,0.01,1.24,0.02C270.19,976.54,270.19,973.72,270.19,970.9z M288.19,884.74c0.43-0.77,0.86-1.55,1.29-2.32
				c-1.21-0.35-2.54-1.17-3.6-0.91c-1.05,0.26-2.47,1.56-2.54,2.5c-0.07,0.85,1.32,2.18,2.34,2.6
				C286.18,886.81,287.33,885.41,288.19,884.74z M251.9,980.24c0.45,0.03,0.91,0.06,1.36,0.08c0-2.88,0-5.77,0-8.65
				c-0.45,0-0.91,0.01-1.36,0.01C251.9,974.53,251.9,977.38,251.9,980.24z M71.4,874.78c0.49-0.83,0.97-1.65,1.46-2.48
				c-1.27-0.32-2.6-1.02-3.79-0.84c-0.89,0.14-2.32,1.47-2.26,2.15c0.11,1.12,1.24,2.14,1.93,3.2
				C69.63,876.14,70.51,875.46,71.4,874.78z M67.15,898.58c-0.09,0.62-0.17,1.25-0.26,1.87c3.43,0.41,6.86,0.81,10.29,1.22
				c0.08-0.66,0.16-1.32,0.23-1.99C73.99,899.32,70.57,898.95,67.15,898.58z M276.21,885.14c-1.72-1.35-2.62-2.54-3.66-2.69
				c-0.61-0.09-2.15,1.59-2.04,2.29c0.16,1.06,1.23,2.35,2.23,2.77C273.28,887.74,274.49,886.37,276.21,885.14z M57.06,848.41
				c-0.06-0.04-0.13-0.08-0.19-0.12c0,8.36,0,16.71,0,25.07c0.06,0,0.13,0,0.19,0C57.06,865.04,57.06,856.73,57.06,848.41z
				M60.86,866.09c-0.03,0.46-0.07,0.91-0.1,1.37c3.74,0.37,7.48,0.74,11.21,1.1c0.04-0.52,0.08-1.03,0.12-1.55
				C68.34,866.7,64.6,866.39,60.86,866.09z M25.51,893.14c-0.11,0.73-0.22,1.46-0.33,2.19c3.2,0.35,6.4,0.7,9.61,1.05
				c0.07-0.59,0.14-1.17,0.21-1.76C31.84,894.12,28.68,893.63,25.51,893.14z M346.5,1197.95c0.35-0.2,0.69-0.41,1.04-0.61
				c-0.55-2.22-1.11-4.45-1.83-7.34c-1.43,1.89-2.43,3.22-3.63,4.8C343.8,1196.02,345.15,1196.99,346.5,1197.95z M243.46,887.08
				c0.09-0.58,0.18-1.17,0.27-1.75c-3.1-2.6-6.85-0.43-10.35-0.15C236.74,885.81,240.1,886.44,243.46,887.08z M257.88,979.5
				c0.45,0,0.91,0.01,1.36,0.01c0-2.65,0-5.29,0-7.94c-0.45,0-0.91-0.01-1.36-0.01C257.88,974.21,257.88,976.86,257.88,979.5z
				M265.07,971.56c-0.4,0-0.8,0.01-1.2,0.01c0,2.57,0,5.15,0,7.72c0.4,0,0.8-0.01,1.2-0.01
				C265.07,976.7,265.07,974.13,265.07,971.56z M100.85,882.56c0.86-0.27,1.72-0.54,2.58-0.82c-0.64-2.01-1.27-4.01-1.91-6.02
				c-0.74,0.24-1.48,0.47-2.22,0.71C99.82,878.47,100.33,880.52,100.85,882.56z M112.81,878.03c0.04,4.12-1.13,7.58,5.3,6.45
				C116.29,882.26,114.97,880.66,112.81,878.03z M738.31,988.08c0.03-0.41,0.07-0.82,0.1-1.23c-3.6-1.14-7.2-2.28-10.8-3.43
				c-0.16,0.63-0.33,1.26-0.49,1.9C730.85,986.24,734.58,987.16,738.31,988.08z M30.63,869.79c-1.28-1.05-2.14-1.77-3.01-2.48
				c-0.66,0.92-1.95,1.96-1.81,2.74c0.19,1.09,1.45,2,2.25,2.99C28.77,872.14,29.49,871.23,30.63,869.79z M61.3,876.68
				c0.79-0.27,1.57-0.54,2.36-0.81c-0.62-1.88-1.25-3.76-1.87-5.64c-0.77,0.27-1.54,0.54-2.31,0.8
				C60.08,872.91,60.69,874.79,61.3,876.68z M131.85,868.63c-1.42,2.45-2.18,3.75-3.37,5.81c2.44,0.16,4.09,0.27,6.6,0.44
				C133.93,872.63,133.16,871.15,131.85,868.63z M164.03,893.34c0.73,0.25,1.45,0.5,2.18,0.75c0.55-1.48,1.21-2.94,1.54-4.46
				c0.05-0.24-1.39-0.8-2.14-1.21C165.08,890.06,164.56,891.7,164.03,893.34z M128.38,877.22c-0.08,0.54-0.15,1.07-0.23,1.61
				c2.91,0.58,5.81,1.16,8.72,1.75c0.08-0.61,0.15-1.23,0.23-1.84C134.19,878.23,131.28,877.72,128.38,877.22z M80.93,899.6
				c0.07,0.72,0.15,1.44,0.22,2.16c2.08-0.25,4.17-0.49,6.25-0.74c-0.1-0.64-0.19-1.28-0.29-1.92
				C85.05,899.27,82.99,899.43,80.93,899.6z M121.9,886.79c0.79-0.25,1.59-0.51,2.38-0.76c-0.45-1.54-0.9-3.07-1.36-4.61
				c-0.78,0.39-2.27,0.87-2.23,1.15C120.88,884,121.46,885.39,121.9,886.79z M579.53,526.54c-0.14-0.68-0.27-1.36-0.41-2.04
				c-2.72,0.46-5.45,0.91-8.17,1.37c0.06,0.47,0.12,0.93,0.18,1.4C573.93,527.02,576.73,526.78,579.53,526.54z M247.09,922.8
				c0.17,0.07,0.34,0.13,0.5,0.2c0-5.77,0-11.55,0-17.32c-0.17,0-0.34,0-0.5,0C247.09,911.39,247.09,917.1,247.09,922.8z
				M561.03,399.95c-0.04,0.8-0.07,1.6-0.11,2.4c2.04-0.12,4.07-0.25,6.11-0.37c-0.02-0.55-0.05-1.11-0.07-1.66
				C564.99,400.2,563.01,400.08,561.03,399.95z M115.23,871.03c0.74-0.52,1.48-1.04,2.22-1.56c-0.88-1.06-1.7-2.18-2.71-3.09
				c-0.16-0.15-1.19,0.67-1.82,1.04C113.69,868.62,114.46,869.83,115.23,871.03z M130.48,935.25c0.22-0.69,0.44-1.37,0.66-2.06
				c-1.94-0.63-3.89-1.27-5.83-1.9c-0.21,0.58-0.42,1.17-0.63,1.75C126.61,933.78,128.55,934.51,130.48,935.25z M248.12,881.6
				c-0.3,0.01-0.59,0.01-0.89,0.02c0,3.04,0,6.09,0,9.13c0.3,0,0.59,0,0.89-0.01C248.12,887.7,248.12,884.65,248.12,881.6z
				M143.44,894.57c0.69-0.47,1.37-0.93,2.06-1.4c0.49-0.65,1.52-1.57,1.35-1.9c-0.41-0.82-1.34-1.86-2.09-1.88
				c-0.71-0.02-1.95,1.05-2.07,1.79C142.52,892.24,143.15,893.44,143.44,894.57z M238.76,925.59c0-0.47-0.01-0.94-0.01-1.41
				c-3.26,0.24-6.51,0.49-9.77,0.73c0.02,0.22,0.05,0.45,0.07,0.67C232.28,925.59,235.52,925.59,238.76,925.59z M67.82,885.08
				c-0.01,0.48-0.02,0.95-0.03,1.43c3.04,0.22,6.08,0.44,9.12,0.66c0.04-0.37,0.07-0.75,0.11-1.12
				C73.95,885.72,70.88,885.4,67.82,885.08z M23.02,893.39c-1.39-1.1-2.1-2.11-2.68-2.04c-0.88,0.11-1.66,0.98-2.49,1.52
				c0.56,0.63,1.08,1.72,1.7,1.78C20.4,894.75,21.33,894.06,23.02,893.39z M109.17,869.27c0.41-0.46,0.82-0.92,1.23-1.38
				c-0.76-0.86-1.53-1.72-2.29-2.59c-0.72,0.68-1.45,1.36-2.17,2.03C107.02,867.98,108.1,868.62,109.17,869.27z M101.18,863.25
				c-0.62,0.31-1.23,0.62-1.85,0.93c0.57,1.12,1.14,2.23,1.72,3.35c0.62-0.35,1.82-0.94,1.79-1.03
				C102.38,865.38,101.76,864.32,101.18,863.25z M104.56,851.83c-0.64,0.12-1.29,0.24-1.93,0.36c0.31,1.43,0.61,2.85,0.92,4.28
				c0.53-0.1,1.06-0.21,1.59-0.31C104.95,854.71,104.76,853.27,104.56,851.83z M109.08,857.53c0.56-0.07,1.12-0.13,1.68-0.2
				c-0.14-1.42-0.28-2.84-0.42-4.27c-0.57,0.07-1.15,0.13-1.72,0.2C108.77,854.68,108.92,856.1,109.08,857.53z M114.94,858.6
				c0.58-0.07,1.16-0.15,1.73-0.22c-0.16-1.4-0.31-2.8-0.47-4.2c-0.59,0.07-1.18,0.15-1.77,0.22
				C114.6,855.8,114.77,857.2,114.94,858.6z M854.8,731.56c-0.47-0.11-0.94-0.22-1.4-0.33c-0.4,1.68-0.81,3.35-1.21,5.03
				c0.41,0.1,0.82,0.19,1.23,0.29C853.88,734.88,854.34,733.22,854.8,731.56z M119.28,831.36c0.58,0.05,1.15,0.09,1.73,0.14
				c0-1.29,0-2.57,0-3.86c-0.5-0.01-1-0.02-1.49-0.03C119.43,828.86,119.35,830.11,119.28,831.36z M289.49,970.16
				c-0.28,0.01-0.57,0.01-0.85,0.02c0,1.9,0,3.79,0,5.69c0.28-0.01,0.57-0.01,0.85-0.02C289.49,973.95,289.49,972.06,289.49,970.16z
				M87.04,882.53c-0.02-0.51-0.03-1.01-0.05-1.52c-1.86,0.16-3.72,0.32-5.58,0.48c0.03,0.35,0.06,0.69,0.09,1.04
				C83.35,882.53,85.19,882.53,87.04,882.53z M91.43,881.12c-0.1,0.53-0.21,1.07-0.31,1.6c1.53,0.25,3.07,0.5,4.6,0.74
				c0.09-0.54,0.17-1.09,0.26-1.63C94.46,881.6,92.94,881.36,91.43,881.12z M391.3,369.38c0.03,0.45,0.06,0.91,0.09,1.36
				c2.08-0.16,4.16-0.33,6.24-0.49c-0.02-0.29-0.04-0.58-0.06-0.87C395.48,369.38,393.39,369.38,391.3,369.38z M235.48,861.67
				c0.05,0,0.11,0,0.16,0c0-3.43,0-6.87,0-10.3c-0.05,0-0.11,0-0.16,0C235.48,854.8,235.48,858.24,235.48,861.67z M175.63,888.8
				c-0.28-0.49-0.55-0.98-0.83-1.47c-1.24,0.69-2.48,1.38-3.73,2.07c0.29,0.5,0.57,1.01,0.86,1.51
				C173.17,890.2,174.4,889.5,175.63,888.8z M130.96,852.04c0.61-0.26,1.22-0.52,1.83-0.78c-0.52-1.22-1.03-2.43-1.55-3.65
				c-0.62,0.26-1.23,0.52-1.85,0.78C129.91,849.61,130.43,850.83,130.96,852.04z M146.46,902.83c-0.25-0.49-0.5-0.98-0.75-1.47
				c-1.07,0.54-2.14,1.09-3.21,1.63c0.26,0.5,0.51,1,0.77,1.5C144.33,903.94,145.4,903.38,146.46,902.83z M120.05,859.55
				c0.57-0.05,1.14-0.1,1.71-0.15c-0.11-1.3-0.22-2.59-0.33-3.89c-0.57,0.05-1.13,0.1-1.7,0.15
				C119.84,856.95,119.95,858.25,120.05,859.55z M274.2,898.06c0.28-0.54,0.55-1.09,0.83-1.63c-1.15-0.36-2.3-0.74-3.47-1.05
				c-0.11-0.03-0.35,0.45-0.7,0.94C272,896.91,273.1,897.49,274.2,898.06z M38.93,851.73c-0.01,0.31-0.02,0.61-0.03,0.92
				c1.71,0.08,3.41,0.15,5.12,0.23c0.01-0.38,0.02-0.77,0.03-1.15C42.34,851.73,40.63,851.73,38.93,851.73z M422.69,264.9
				c0.26,0.06,0.52,0.12,0.78,0.18c0.52-1.88,1.05-3.75,1.57-5.63c-0.4-0.09-0.79-0.18-1.19-0.28
				C423.46,261.09,423.08,263,422.69,264.9z M688.98,330.75c0.01-0.44,0.03-0.88,0.04-1.33c-1.89-0.1-3.78-0.21-5.67-0.31
				c-0.02,0.17-0.03,0.35-0.05,0.52C685.2,330.01,687.09,330.38,688.98,330.75z M7.77,896.09c1.26-1.68,1.69-2.24,2.12-2.81
				c-0.48-0.35-1.09-1.07-1.43-0.96c-0.61,0.2-1.31,0.82-1.5,1.41C6.8,894.21,7.33,894.91,7.77,896.09z M131.11,910.38
				c-0.14-0.44-0.28-0.89-0.43-1.33c-1.05,0.41-2.11,0.82-3.16,1.23c0.17,0.41,0.33,0.82,0.5,1.23
				C129.05,911.13,130.08,910.76,131.11,910.38z M27.94,890.26c-0.07,0.12-0.14,0.25-0.22,0.37c1.88,0,3.77,0,5.65,0
				c-0.01-0.12-0.02-0.25-0.02-0.37C31.54,890.26,29.74,890.26,27.94,890.26z M21.66,875.32c0.2-0.49,0.39-0.99,0.59-1.48
				c-1.24-0.23-2.49-0.46-3.73-0.7c-0.08,0.25-0.15,0.51-0.23,0.76C19.42,874.37,20.54,874.84,21.66,875.32z M45.41,846.8
				c-0.41-0.35-0.82-0.69-1.22-1.04c-0.62,0.79-1.23,1.58-1.85,2.37c0.34,0.29,0.68,0.58,1.02,0.87
				C44.04,848.27,44.73,847.53,45.41,846.8z M81.52,886.22c0.04,0.39,0.08,0.78,0.12,1.17c1.64-0.22,3.28-0.43,4.93-0.65
				c-0.02-0.26-0.05-0.53-0.07-0.79C84.83,886.04,83.18,886.13,81.52,886.22z M14.18,891.78c-0.27,0.02-0.54,0.03-0.8,0.05
				c0,0.91,0,1.83,0,2.74c0.33-0.02,0.66-0.04,0.99-0.06C14.31,893.6,14.25,892.69,14.18,891.78z M42.78,896.03c0.08,0,0.16,0,0.24,0
				c0-1.67,0-3.35,0-5.02c-0.08,0-0.16,0-0.24,0C42.78,892.69,42.78,894.36,42.78,896.03z M558.95,407.36
				c0.22,0.13,0.44,0.26,0.66,0.39c0.4-0.65,0.79-1.3,1.19-1.95c-0.24-0.13-0.47-0.27-0.71-0.4
				C559.71,406.05,559.33,406.7,558.95,407.36z M279.71,1028.66c-0.22-0.1-0.44-0.2-0.65-0.3c-0.43,0.85-0.85,1.71-1.28,2.56
				c0.26,0.11,0.51,0.23,0.77,0.34C278.94,1030.4,279.32,1029.53,279.71,1028.66z M274.76,846.36c-0.13,0.06-0.25,0.13-0.38,0.19
				c0.06,1.03,0.12,2.07,0.18,3.1C274.63,848.56,274.7,847.46,274.76,846.36z"/>
			<path onClick={() => props.onFill(0)} fill={props.fillColors[0]}  d="M971.81,240c-19.14-12.68-35.7-28.19-50.68-45.56c-5.75-6.67-12.09-12.81-21.21-15.06
				c-7.82-1.93-15.51-4.37-23.24-6.68c-1.07-0.32-2.06-1.07-2.96-1.78c-9.37-7.46-20.41-9.97-31.99-11.23
				c-2.68-0.29-5.87-1.55-7.64-3.47c-4.36-4.71-9.1-6.31-15.53-5.71c-13.29,1.24-25.83-1.2-38.07-7.33
				c-17.21-8.61-34.73-16.8-53.7-21.07c-1.29-0.29-2.54-0.78-3.77-1.27c-9.7-3.85-10.79-3.41-16.87,4.88
				c-1.14,1.56-3.77,2.99-5.63,2.9c-9.7-0.46-18.84,1.41-27.63,5.38c-0.45,0.21-0.91,0.42-1.38,0.56
				c-12.52,3.73-12.52,3.72-13.36,16.43c-0.59,8.88-5.62,13.28-14.46,13.84c-6.05,0.39-12.07,1.71-18.01,3.07
				c-3.75,0.86-5.86,3.86-6.06,7.7c-0.22,4.39-2.33,6.11-6.59,6.3c-1.65,0.07-4.12,1.05-4.68,2.3c-1.96,4.39-5.76,4.75-9.59,5.62
				c-1.88,0.43-4.13,1.12-5.3,2.48c-6.41,7.44-14.75,10.19-24.14,10.83c-1.27,0.09-2.54,0.73-3.74,1.28
				c-5.66,2.56-11.06,6.2-16.99,7.55c-10.17,2.32-15.57,8.16-15.84,18.19c-0.2,7.15-3.39,11.14-10.53,13.24
				c-3.12-3.33-6.46-7.12-10.06-10.64c-1.34-1.31-3.21-2.72-4.94-2.88c-10.09-0.91-20.2-1.72-30.32-1.93
				c-2.59-0.05-5.94,1.57-7.68,3.54c-2.06,2.34-3.7,2.84-6.33,1.83c-1.55-0.6-3.19-0.93-4.74-1.52c-6.74-2.54-11.53-0.2-16.12,5.13
				c-5.39,6.26-11.7,11.71-16.98,16.9c-3.48-3.43-6.57-6.15-9.23-9.24c-1.34-1.56-1.96-3.78-2.78-5.75
				c-8.39-20.44-7.45-17.26-23.84-14.99c-10.54,1.46-20.9,4.58-31.15,7.58c-2.92,0.85-5.2,3.86-8.38,6.35
				c-2.04-6.19-6.92-7.24-12.67-7.05c-1.41,0.05-3.55-1.21-4.19-2.46c-1.88-3.68-3.56-7.63-8.41-7.29c-5.8,0.4-10.82,2.59-14.42,7.7
				c-1.55,2.19-4.04,3.83-6.36,5.33c-3.57,2.31-7.36,4.28-11.44,6.62c-4.04-4.46-10.28-7.73-10.51-15.81
				c-0.12-4.15-3.94-5.96-7.78-4.81c-7.52,2.25-14.89,1.51-22.55,1.04c-7.14-0.44-14.48,1.33-21.65,2.56
				c-5.69,0.97-9.01,5.18-11.81,9.94c-3.47,5.9-5.72,5.1-12,2.77c-8.64-3.21-17.17-9.34-27.44-4.85c-2.34,1.02-5.25,0.64-7.84,1.18
				c-5.26,1.08-10.49,2.31-15.71,3.55c-0.6,0.14-1.37,0.58-1.62,1.1c-2.79,5.85-8.08,5.19-13.24,5.63c-3.51,0.3-7.29,1.27-10.25,3.08
				c-4.29,2.63-8.74,3.95-13.49,5.43c-9.06,2.82-16.54,1.43-24.41-3.18c-7.13-4.18-14.97-3.7-22.69-1.03
				c-5.18,1.79-10.34,3.7-15.61,5.18c-6.37,1.79-7.86,1.06-10.23-5.01c-1.66-4.23-4.42-6.12-8.89-6.74
				c-8.71-1.2-17.34-3.01-26.03-4.38c-2.59-0.41-5.28-0.22-7.93-0.3c0-6.67,0-13.33,0-20c13.93,0.16,27.85,0.31,41.78,0.49
				c6.14,0.08,12.29,0.25,18.43,0.3c17.43,0.12,34.97,1.47,52.25-0.08c15.59-1.4,31.02-3.21,46.68-1.86c2.26,0.2,5.76,0.36,6.7-0.92
				c3.58-4.92,8.27-2.36,12.46-2.65c4.8-0.33,9.63-0.12,14.44-0.36c1.84-0.09,3.65-0.75,5.48-1.15c0.1-0.46,0.21-0.92,0.31-1.38
				c-1.51-1.01-2.9-2.38-4.56-2.96c-3.28-1.14-6.71-1.86-10.08-2.72c-4-1.02-9.45-0.71-11.72-3.25c-5.62-6.33-11.31-5.29-17.91-3.12
				c-1.68,0.55-3.87-0.01-5.71-0.51c-3.26-0.9-6.42-2.17-9.64-3.23c-3.27-1.08-6.54-2.18-9.86-3.09c-2.01-0.55-4.28-1.44-6.15-1
				c-4.47,1.06-8.69,3.3-13.18,4.16c-5.08,0.97-10.46,1.82-15.49,1.11c-3.99-0.57-7.64-3.57-12.06-5.8
				c-0.58-7.44-4.22-9.75-12.76-6.96c-0.87,0.29-2.02,0.58-2.77,0.26c-4.93-2.15-9.96-4.16-14.63-6.81
				c-4.82-2.73-9.64-3.08-14.89-2.01c-7.19,1.47-14.28,2.56-21.4-1.22c-2.07-1.1-5.45-0.06-8.15,0.44c-2.58,0.48-5.05,1.53-7.56,2.33
				c0-24,0-48,0-72c37.92,0.06,75.85-0.04,113.77,0.3c7.83,0.07,16.11-2.56,23.43,3.86c2.53,2.22,7.63,1.52,11.57,2.08
				c4.72,0.68,9.45,1.27,14.18,1.9c-0.01,0.6-0.03,1.21-0.04,1.81c-2.35,0.62-4.66,1.53-7.04,1.81c-9.4,1.11-18.83,1.94-28.23,3.05
				c-6.1,0.72-11.95,2.32-14.95,8.43c-3.27,6.65-8.75,10.3-15.54,12.28c-4.3,1.26-8.69,2.19-13.02,3.35
				c-8.97,2.39-17.96,4.73-26.86,7.35c-1.34,0.39-3.06,2.13-3.13,3.34c-0.07,1.04,1.79,2.91,3.08,3.23
				c14.57,3.63,29.2,5.4,44.11,1.56c6.02-1.55,12.26-2.54,18.45-3c6.95-0.52,13.97-0.37,20.95-0.06c3.74,0.16,7.42,1.43,11.17,1.88
				c5.35,0.64,10.92,2.07,16.08,1.24c13.84-2.24,27.57-4.5,41.59-2.18c9.99,1.66,19.94,3.53,29.93,5.17
				c1.89,0.31,4.18,0.59,5.79-0.16c6.1-2.86,12.39-2.74,18.54-1.19c15.24,3.84,30.34,3.8,45.89,1.46c12.71-1.91,25.75-2.11,38.65-2.1
				c8.92,0.01,17.87,1.5,26.76,2.67c2.8,0.37,5.67,1.44,8.13,2.86c4.95,2.86,9.75,2.65,14.37-0.53c1.68-1.16,4.19-1.6,6.3-1.54
				c12.25,0.32,24.48,1.25,36.73,1.29c15.11,0.05,30.24-1.05,45.33-0.69c7.8,0.19,15.55,2.46,23.35,3.8
				c-1.5,19.85,6.8,31.71,22.52,32.09c7.24,0.18,13.38-2.17,18.36-7.42c6.94-7.32,7.96-16.08,5.37-25.68
				c1.54-0.41,2.61-0.83,3.71-0.96c6.6-0.77,13.22-1.38,19.8-2.27c1.68-0.23,3.25-1.27,4.87-1.94c-2.97-4.3-5.94-8.6-8.91-12.9
				c-3.91,2.31-5.4-1.79-8.04-2.94c-4-1.74-8.14-3.29-12.36-4.35c-2.51-0.63-5.43,0.31-7.91-0.36c-1.81-0.49-3.24-2.44-4.79-3.8
				c-1.83-1.61-3.37-4.11-5.47-4.72c-3.1-0.91-6.49-0.77-8.63,2.98c-2.57,4.51-7.15,4.11-9.62-0.41c-0.65-1.18-2.01-2.74-3.1-2.8
				c-8.07-0.38-15.6-6.71-24.13-2.21c-0.77,0.41-1.99,0.18-2.95-0.03c-6.97-1.53-13.94-3.07-20.88-4.76
				c-1.74-0.42-3.35-1.38-5.02-2.09c8.47-2.59,17.25-1.01,25.17-5.39c-1.91-1.72-3.83-2.97-5.92-3.34
				c-5.23-0.92-10.5-1.78-15.79-2.06c-17.74-0.96-35.51-1-52.89-5.73c-7.87-2.14-15.7-1.6-22.16,5.09c-3.34,3.46-8.4,3.88-12.64,1.83
				c-8.79-4.24-15.53-1.42-22.13,4.63c-3.51,3.22-7.58,6-11.8,8.2c-3.99,2.08-9.76-1.32-9.98-5.22c-0.48-8.37-3.04-10.86-11.39-10.96
				c-4.16-0.05-8.33,0.22-12.47-0.07c-2.89-0.2-5.88-0.92-6.25-5.51c2.95,0,5.56,0,8.18,0c217.42,0,434.84,0,652.25-0.01
				c2.33,0,4.65-0.11,6.98-0.17C971.81,148,971.81,194,971.81,240z"/>
			<path onClick={() => props.onFill(1)} fill={props.fillColors[1]}  d="M971.81,1147c-9.31-0.12-18.62-0.06-27.92-0.43c-8.62-0.34-17.22-1.13-25.83-1.78
				c-0.77-0.06-1.5-0.47-2.22-1.52c5.99-0.37,12-0.61,17.97-1.16c3.34-0.31,6.31-1.32,2.02-6.6c9.08,0,17.17,0.18,25.24-0.13
				c1.97-0.08,3.88-1.75,5.82-2.68c-1.93-1.83-3.62-4.08-5.86-5.37c-2.13-1.23-4.84-1.47-7.29-2.14c0.1-0.49,0.2-0.99,0.3-1.48
				c1.25-0.12,2.57-0.03,3.73-0.41c1.43-0.46,2.74-1.32,4.11-2c-1-1.4-1.75-3.68-3.04-4.05c-4.11-1.18-8.41-2.16-12.67-2.32
				c-15.62-0.58-31.26-0.67-46.89-1.22c-7.96-0.28-15.89-1.13-23.83-1.77c-0.71-0.06-1.4-0.48-2.01-1.76c3.56,0,7.14,0.26,10.66-0.11
				c1.75-0.18,3.39-1.52,5.07-2.33c-1.24-1.59-2.19-4.11-3.76-4.6c-3.9-1.21-8.07-1.86-12.17-2.05c-5.09-0.23-9.73-0.71-13.77-4.52
				c-1.93-1.82-5.09-2.33-7.69-3.43c0.1-0.59,0.19-1.19,0.29-1.78c2.71,0,5.42,0.01,8.13,0c3.49-0.02,7.02,0.21,10.46-0.21
				c1.7-0.21,3.24-1.64,4.85-2.52c-1.22-1.45-2.22-3.83-3.71-4.18c-6.43-1.54-12.99-2.54-19.52-3.62c-5.45-0.91-10.93-1.66-16.4-2.48
				c0.02-0.68,0.03-1.37,0.05-2.05c5.13-0.67,10.24-1.51,15.38-1.98c12.24-1.12,24.5-2.05,36.76-3.07c0.83-0.07,1.66-0.16,2.47-0.31
				c8.28-1.49,7.73-1.73,4.74-9.31c-3.69-9.35-10.75-12.39-19.53-13.9c-6.54-1.13-13.07-2.42-19.52-3.99
				c-2.84-0.69-5.36-2.69-8.2-3.39c-7.01-1.73-14.06-3.57-21.21-4.41c-12.2-1.42-24.49-2.04-36.73-3.15
				c-2.38-0.22-4.69-1.15-8.55-2.14c7.22-2.77,3.68-4.95,0.27-7.64c7.38-0.4,13.67-0.49,19.9-1.18c4.69-0.51,5.08-2.1,2.37-5.6
				c2.54-1.22,5.16-2.48,7.77-3.74c-1.5-1.63-3-3.25-4.55-4.94c9.68,0,19.69-0.54,29.61,0.18c7.04,0.51,13.92,3.07,20.95,4.07
				c5.33,0.76,10.8,0.46,16.21,0.72c3.97,0.19,7.94,0.81,11.88,0.64c1.88-0.08,3.7-1.54,5.54-2.37c-0.88-1.64-1.53-3.48-2.69-4.9
				c-4.3-5.24-8.76-10.33-13.17-15.48c-0.32-0.38-0.64-0.78-1.04-1.06c-6-4.16-12.01-8.31-18.29-12.64c2.31-4.22-0.41-6.15-4.57-7.08
				c-3.72-0.83-7.49-1.44-11.77-2.26c2.14-5.14-1.44-5.93-5.24-6.25c-2.81-0.24-5.78-0.64-8.45-0.01c-7.78,1.83-14-0.78-20.38-5.07
				c-11.7-7.85-24.9-12.3-38.51-15.93c-14.75-3.93-29.62-7.87-43.74-13.56c-25.49-10.29-51.2-10.18-77.36-4.42
				c-6.63,1.46-12.99,4.15-19.63,5.48c-3.75,0.75-7.93,0.38-11.74-0.38c-13.67-2.75-27.21-6.14-40.9-8.79
				c-8.83-1.71-17.56-1.39-25.19,4.62c-1.12,0.88-3.08,1.08-4.61,0.99c-5.14-0.29-10.35-0.43-15.37-1.44
				c-3.57-0.72-6.84-2.85-10.33-4.13c-4.49-1.66-9.04-3.14-13.59-4.63c-2.75-0.9-5.53-1.7-8.3-2.54c0.04-0.58,0.07-1.16,0.11-1.73
				c6.95-0.78,13.89-1.75,20.87-2.3c22.36-1.76,44.75-3.27,67.1-5.14c2.68-0.22,5.22-2.1,7.82-3.21c-2.33-1.75-4.42-4.44-7.02-5.1
				c-10.62-2.68-21.47-4.45-32.09-7.14c-6.96-1.77-13.53-5.79-20.53-6.59c-13.85-1.58-27.87-1.56-41.82-2.34
				c-5.26-0.3-10.49-0.99-15.73-1.51c-0.03-0.56-0.05-1.12-0.08-1.67c5.87-0.78,11.74-1.68,17.62-2.31
				c13.04-1.4,26.12-2.49,39.13-4.14c2.67-0.34,5.01-2.75,7.66-3.82c2.38-0.96,4.96-1.85,7.48-1.97c11.3-0.54,22.62-0.6,33.9-1.27
				c3.02-0.18,5.94-1.9,8.91-2.92c-2.92-1.4-5.81-3.94-8.76-4.01c-14.63-0.32-29.27-0.11-43.9,0.15
				c-24.27,0.42-48.54,1.02-72.82,1.53c-0.99,0.02-2.09,0.14-2.97-0.2c-15.86-6.13-32.58-3.25-48.87-4.74
				c-3.74-0.34-7.45-0.89-11.4-1.85c3.42-1.2,7.03-1.96,2.04-7.1c6.52,0,11.94,0.19,17.33-0.11c1.76-0.1,3.46-1.47,5.18-2.25
				c-1.39-1.44-2.53-3.63-4.21-4.19c-7.16-2.37-14.48-4.25-22.36-6.49c6.49-1,12.29-1.9,18.09-2.79c0.04-0.59,0.08-1.18,0.12-1.77
				c-1.49-1.05-2.97-2.1-4.47-3.15c2.83-2.81,11.21,2.39,9.88-7.46c3.05-0.17,6.67-0.17,10.23-0.66c2-0.27,3.88-1.35,5.82-2.06
				c-1.19-1.65-2.21-3.47-3.62-4.9c-1.34-1.36-3.1-2.31-4.37-4.43c9.19,0,18.39,0.24,27.56-0.07c8.08-0.27,16.16-0.94,24.18-1.93
				c1.9-0.23,3.56-2.45,5.32-3.75c-1.7-1.35-3.21-3.28-5.15-3.93c-5.61-1.88-11.38-3.24-17.16-5.88c3.9-1.44,7.8-2.88,11.71-4.31
				c-0.05-0.76-0.1-1.51-0.14-2.27c-3.23-0.58-6.45-1.61-9.69-1.66c-13.64-0.24-27.28-0.22-40.92-0.2c-2.86,0-5.35-0.16-5.67-2.77
				c12.48-7.33,26.11-4.64,39.25-6.1c10.42-1.16,20.62-4.46,30.86-7.05c0.96-0.24,1.48-2.22,2.98-4.65
				c7.43-1.93,17.48,0.07,26.42-3.78c0.04-0.64,0.09-1.28,0.13-1.92c-1.88-0.56-3.75-1.56-5.64-1.6
				c-18.11-0.47-36.23-0.88-54.35-1.09c-15.64-0.18-31.28-0.08-46.92-0.07c-2.71,0-5.41,0.34-6.01-3.46c-0.12-0.78-1.56-2.13-1.92-2
				c-5.49,2.11-7.56-7.8-13.48-3.8c-0.08,0.06-0.3-0.08-0.89-0.27c-0.61-7.17-6.66-2.58-10.05-4.65c54.88,0,109.77,0,164.65,0
				c0.01,0.58,0.02,1.15,0.04,1.73c-29.55,0-59.1,0-88.65,0c-0.03,0.75-0.07,1.5-0.1,2.25c1.94,0.24,3.88,0.68,5.82,0.68
				c42.09,0.07,84.18-0.05,126.27,0.24c6.99,0.05,13.96,1.81,20.95,2.78c0.49,0.07,0.99,0.13,1.49,0.13
				c9.78,0.08,19.57,0.06,29.35,0.23c26.86,0.47,53.72,0.95,80.58,1.66c2.07,0.05,4.08,1.96,6.15,2.93c2.07,0.98,4.19,1.87,6.38,2.84
				c-1.14,3.11-2.35,6.44-3.68,10.07c-18.89-1.97-38.54-4.02-59.38-6.19c14.84-2.32,28.81-0.11,42.3-3.09
				c0.07-0.68,0.13-1.37,0.2-2.05c-2.23-0.61-4.45-1.7-6.69-1.75c-26.42-0.56-52.85-0.98-79.27-1.39c-3.66-0.06-7.36-0.14-10.96,0.4
				c-2.04,0.3-3.9,1.84-5.83,2.83c1.55,1.28,3.05,2.6,4.66,3.8c0.73,0.55,1.65,0.84,2.49,2.1c-19.87-0.44-39.29,2.43-58.84,5.25
				c6.88,5.18,14.79,3.5,22.47,5.71c-2.3,0.75-4.56,1.99-6.9,2.15c-4.63,0.32-9.33-0.2-13.96,0.13c-1.64,0.12-3.17,1.71-4.75,2.63
				c1.3,1.24,2.44,2.78,3.95,3.65c2.74,1.58,5.65,2.92,8.62,4.04c1.81,0.68,3.83,0.95,5.78,1.08c6.72,0.44,13.45,0.73,20.18,1.07
				c0.13,0.67,0.26,1.33,0.39,2c-1.71,0.46-3.42,1.29-5.14,1.33c-7.98,0.19-15.97,0.02-23.95,0.28c-2.6,0.08-5.16,1.09-7.74,1.67
				c-0.04,0.77-0.08,1.55-0.13,2.32c2.63,0.76,5.23,2.03,7.9,2.2c10.24,0.66,20.51,0.96,30.87,2.16c-1.5,0.14-3.07,0.06-4.48,0.5
				c-1.26,0.4-2.35,1.38-3.51,2.1c0.86,1.1,1.5,2.74,2.62,3.19c2.74,1.11,5.74,1.58,8.57,2.48c1.69,0.54,3.27,1.42,4.91,3.29
				c-1.95,0.28-3.88,0.75-5.84,0.82c-7.8,0.3-15.62,0.37-23.41,0.82c-1.55,0.09-3.02,1.53-4.53,2.35c1.17,1.44,2.11,3.78,3.56,4.16
				c5.09,1.34,10.33,2.63,15.55,2.81c20.1,0.7,40.23,0.61,60.32,1.55c10.68,0.5,21.28,2.5,31.92,3.82c-0.03,0.67-0.06,1.33-0.1,2
				c-8.49,0.51-16.98,0.99-25.46,1.54c-3.81,0.25-7.64,0.42-11.39,1.08c-1.55,0.27-2.89,1.71-4.32,2.62
				c1.27,1.17,2.37,3.01,3.84,3.38c6.42,1.61,12.91,3.71,19.44,3.95c14.52,0.53,27.14,5.64,39.46,14.28
				c-4.39,0.53-8.37,0.78-12.24,1.57c-1.69,0.35-3.15,1.8-4.71,2.75c1.4,1.27,2.64,3.3,4.24,3.65c4.31,0.96,8.9,2.13,13.15,1.55
				c15.38-2.1,29.33,1.88,42.74,8.81c1.25,0.65,2.25,1.8,4.21,3.42c-3.89,1.8-7.06,3.06-10.02,4.72c-1.61,0.91-2.86,2.48-4.27,3.75
				c1.43,1.45,2.64,3.77,4.33,4.19c7.53,1.86,15.17,3.3,22.82,4.66c5.22,0.93,10.53,1.36,15.76,2.25c1.79,0.3,3.47,1.3,4.99,3.04
				c-4.72,0.47-9.47,0.74-14.14,1.51c-1.74,0.29-3.3,1.72-4.94,2.63c1.32,1.32,2.41,3.17,4,3.86c3.78,1.63,7.79,2.71,13.03,4.46
				c-3.07,3.88-5.89,7.36-8.6,10.93c-0.84,1.11-1.39,2.43-2.07,3.66c1.42,0.63,2.85,1.81,4.25,1.77c3.3-0.07,6.89,0,9.82-1.27
				c15.92-6.89,31.64-4.89,47.67-0.31c22.86,6.54,46.46,8.87,70.06,8.27c20.31-0.51,39.83,4.08,59.72,6.21
				c1.6,0.17,3.15,0.85,4.75,2.34c-4.78,0.41-9.55,0.89-14.33,1.21c-6.63,0.45-13.29,0.58-19.89,1.29c-1.79,0.19-3.4,1.96-5.1,3
				c1.48,1.16,2.87,3.16,4.47,3.34c12.66,1.41,25.36,2.48,38.05,3.66c0,5.67,0,11.33,0,17c-3.07,0.66-6.21,1.12-9.18,2.09
				c-1.2,0.39-2.83,1.9-2.84,2.91c-0.02,1.36,1.02,3.47,2.17,3.97c3.13,1.37,6.55,2.06,9.85,3.02c0,4.67,0,9.33,0,14
				c-13.6-2.58-26.94-1.13-40.24,1.14c-0.06,0.77-0.12,1.53-0.18,2.3c7.34,1.66,14.67,3.33,22.44,5.09
				c-2.16,4.12-6.95,5.96-3.87,10.83c3.11,4.92,2.21,6.62-3.69,7.4c-6.91,0.91-13.89,1.26-20.81,2.15
				c-5.08,0.65-10.17,1.54-15.12,2.81c-1.39,0.36-3.39,2.61-3.23,3.72c0.22,1.55,1.99,3.18,3.5,4.17c1.31,0.86,3.15,1.07,4.78,1.22
				c5.79,0.54,11.62,0.76,17.38,1.49c1.95,0.25,5.14,1.41,5.36,2.61c0.75,4.06,3.22,5.46,6.62,6.49c0.93,0.28,2.21,1.49,2.25,2.33
				c0.3,5.89,4.68,7.03,9.08,8.34c6.72,2.01,7.06,3.49,2.15,8.72c-0.45,0.48-1.07,1.02-1.14,1.6c-0.81,6.17-5.49,6.07-10.04,6.51
				c-4.62,0.44-9.3,0.87-13.75,2.07c-1.8,0.48-4.05,2.85-4.29,4.6c-0.23,1.71,1.74,3.81,2.95,5.6c0.69,1.02,2.4,1.67,2.57,2.66
				c1.11,6.54,5.98,8.84,11.42,9.33c8.58,0.77,17.23,0.61,25.86,0.85C971.81,1113.67,971.81,1130.33,971.81,1147z M597.32,744.66
				c0-0.45,0.01-0.9,0.01-1.34c-17.26,0-34.52,0-51.78,0c0,0.45,0,0.9,0,1.34C562.8,744.66,580.06,744.66,597.32,744.66z"/>
			<path onClick={() => props.onFill(2)} fill={props.fillColors[2]}  d="M971.81,1198c-13.58,0-27.16,0-40.74,0c-0.11-0.47-0.21-0.95-0.32-1.42c1.41-0.45,2.78-1.04,4.22-1.32
				c3.03-0.58,5.46-1.55,5.31-5.33c-0.03-0.7,0.44-1.75,1.01-2.09c5.4-3.26,1.68-5.8-1.06-7.12c-3.32-1.6-7.2-2.52-10.9-2.73
				c-7.4-0.42-14.26-1.26-21.61-3.86c-10.56-3.74-21.18-9.79-33.41-7.36c-2.72,0.54-5.75-0.54-8.64-0.87c0-0.54-0.01-1.07-0.01-1.61
				c3.6-0.39,7.27-0.48,10.77-1.27c2.2-0.5,4.15-2.05,6.21-3.13c-1.92-1.6-3.65-4.16-5.81-4.61c-4.33-0.89-8.9-0.74-13.37-0.81
				c-6.34-0.11-12.69-0.03-19.04-0.03c-0.02-0.75-0.05-1.5-0.07-2.25c7.3-0.65,14.6-1.33,21.91-1.93c8.78-0.73,17.59-1.16,26.32-2.22
				c2.4-0.29,4.58-2.34,6.87-3.58c-2.33-1.26-4.64-3.55-7.01-3.62c-12.61-0.33-25.23-0.05-37.85-0.11c-2.51-0.01-5.01-0.56-7.52-0.86
				c0.02-0.42,0.04-0.83,0.06-1.25c7.02-0.45,14.05-0.78,21.05-1.4c3.46-0.31,8.66,0.34,8.71-4.4c0.06-5.1-5.36-4.46-8.93-4.86
				c-3.96-0.45-7.89,0.03-9.96-4.83c-0.57-1.34-3.69-1.78-5.73-2.27c-2.25-0.54-4.65-0.44-6.88-1c-4.4-1.11-5.03-2.98-2.51-6.69
				c3.61-5.32,1.26-10.53-5.04-11.59c-13.09-2.22-26.12-4.91-39.26-6.76c-7.03-0.99-14.25-0.86-21.37-0.73
				c-2.38,0.04-4.74,1.64-7.11,2.53c1.16,1.85,2.31,3.69,3.73,5.96c-4.81,0.81-9.75,1.64-14.64,2.46c3.25,4.28,5.62,7.87,8.51,10.96
				c1.04,1.11,3.32,1.15,5.07,1.46c1.85,0.33,3.74,0.4,5.61,0.58c0.05,0.48,0.1,0.96,0.16,1.43c-1.76,0.44-3.5,1.14-5.28,1.26
				c-4.14,0.28-8.32,0.1-12.46,0.45c-3.79,0.32-7.5,0.76-9.77,4.84c-0.71,1.27-3.69,1.06-5.21,2.13c-1.83,1.29-3.2,3.22-4.78,4.87
				c1.78,1.11,3.53,3.1,5.35,3.17c6.97,0.28,13.96-0.08,20.94-0.17c3.13-0.04,6.27-0.01,9.4-0.01c0.05,0.53,0.1,1.06,0.15,1.59
				c-3.42,0.36-6.82,0.86-10.25,1.06c-6.96,0.42-13.93,0.62-20.89,1.08c-1.6,0.11-3.32,0.67-4.66,1.54
				c-0.95,0.62-1.95,2.18-1.81,3.16c0.13,0.96,1.57,2.28,2.62,2.49c3.41,0.66,6.91,0.86,10.37,1.29c4.21,0.52,8.2,0.69,11.73,4.3
				c2.03,2.08,6.43,2.31,9.82,2.5c6.8,0.37,13.64,0.1,20.46,0.2c1.9,0.03,3.8,0.44,5.7,0.68c0.02,0.55,0.04,1.11,0.06,1.66
				c-3.78,0.36-7.55,0.79-11.33,1.08c-5.46,0.42-10.94,0.68-16.39,1.16c-1.63,0.14-3.41,0.49-4.75,1.34
				c-1.4,0.89-3.04,2.38-3.27,3.82c-0.17,1.04,1.55,2.98,2.85,3.55c4.85,2.14,9.88,3.87,14.84,5.77c0.9,0.34,1.76,0.77,2.58,2.13
				c-2.23,0.14-4.55-0.05-6.66,0.52c-1.88,0.51-3.54,1.88-5.29,2.86c1.29,1.35,2.34,3.34,3.91,3.95c7.57,2.96,15.31,5.5,22.95,8.28
				c4.3,1.57,8.54,3.31,12.63,5.87c-8.91,0-17.82,0-26.73,0c-6.49,0-13.01-0.4-19.46,0.09c-9.3,0.71-17.25-0.62-24.23-7.98
				c-6.19-6.53-15.66-5.57-23.89-7.23c-4.08-0.82-8.12-1.85-12.17-2.79c0.04-0.63,0.08-1.26,0.13-1.89c1.96-0.34,3.93-0.7,5.89-1.01
				c2.45-0.38,5.19-0.17,7.27-1.25c1.57-0.82,2.93-3.19,3.17-5.02c0.14-1.08-1.9-3.22-3.3-3.58c-5.05-1.31-6.85-4.63-7.36-9.42
				c-0.44-4.08-2.51-7.31-6.66-8.53c-10.65-3.13-21.31-6.28-32.05-9.07c-3.77-0.98-7.8-0.95-12.29-1.44
				c1.12-3.17,1.98-5.62,2.97-8.41c7.57,0.45,9.61-5.42,12.41-10.75c0.99-1.89,3.36-3.01,4.86-4.69c2.89-3.25,5.63-6.65,8.41-10
				c1.71-2.06,3.35-4.19,5.09-6.22c0.75-0.87,2.04-1.41,2.52-2.37c4.04-8.24,7.98-16.53,11.87-24.84c0.55-1.18,0.33-3.06,1.17-3.72
				c5.49-4.34,5.9-10.96,7.9-16.85c0.47-1.39,1.07-3.16,0.6-4.35c-2.18-5.58,1.59-8.57,4.7-11.94c4.8-5.21,9.73-10.3,14.25-15.74
				c2.98-3.6,6.67-4.4,10.95-4.69c12.6-0.85,25.19-1.86,37.77-2.95c2.79-0.24,5.62-0.81,8.27-1.71c4.47-1.51,8.41-0.56,12.35,1.62
				c-1.61,2.96-11.48-1.05-5.52,7.93c-4.26,0.23-8.04,0.43-11.83,0.65c-2.32,0.13-4.68,0.05-6.95,0.47
				c-4.83,0.91-5.16,2.18-1.81,5.91c-4.34,2.97-4.47,5.21,0.48,6.64c7.43,2.14,15.16,3.47,22.86,4.35
				c12.04,1.37,24.16,2.04,36.24,3.07c1.3,0.11,2.8,0.3,3.81,1.01c9.25,6.52,20.32,6.74,30.77,9.09c0.49,0.11,0.98,0.21,1.47,0.27
				c9.59,1.22,15.91,6.17,18.07,16.94c-6.49,0.74-13.14,1.61-19.8,2.24c-11.58,1.09-23.19,1.85-34.74,3.18
				c-2.84,0.33-5.48,2.37-8.21,3.62c2.73,1.81,5.27,4.63,8.24,5.23c8.12,1.64,16.43,2.27,24.64,3.45c2.35,0.34,4.62,1.2,6.93,1.82
				c-0.06,0.57-0.12,1.13-0.18,1.7c-5.03,0-10.08-0.33-15.07,0.13c-2.79,0.26-5.47,1.82-8.2,2.79c2.41,1.92,4.8,3.86,7.25,5.73
				c0.5,0.38,1.41,0.3,1.83,0.72c6.27,6.44,14.38,5.94,22.36,6.16c1.88,0.05,3.75,0.83,5.62,1.28c-0.01,0.58-0.03,1.16-0.04,1.74
				c-2.73,0-5.54-0.41-8.17,0.11c-2.63,0.52-5.08,1.97-7.61,3.01c2.5,1.57,4.92,4.36,7.5,4.52c16.58,1.06,33.21,1.53,49.81,2.21
				c8.47,0.35,16.94,0.68,25.4,1.14c1.93,0.11,3.84,0.75,5.78,1.99c-0.98,0.16-2.02,0.17-2.94,0.5c-1.58,0.58-3.09,1.35-4.63,2.05
				c0.88,1.51,1.43,3.44,2.71,4.42c1.63,1.25,3.83,1.81,5.83,2.53c1.46,0.52,3,0.81,4.51,1.2c-0.02,0.49-0.05,0.98-0.07,1.47
				c-2.31,0.15-4.63,0.38-6.94,0.44c-6.65,0.19-13.31,0.04-19.93,0.55c-5.11,0.39-5.3,1.12-3.32,5.88c-6.14,0.44-12.24,0.74-18.3,1.4
				c-1.35,0.15-2.58,1.53-3.86,2.34c1.1,1.09,2.02,2.8,3.32,3.15c4.46,1.2,9.04,2.61,13.6,2.71c14.79,0.32,29.6,0.1,44.39,0.09
				C971.81,1165.33,971.81,1181.67,971.81,1198z"/>
			<path d="M0.5,1250c0-0.67,0-1.33,0-2c12.04-0.22,21.81-6.54,31.6-12.37c2.41-1.43,3.96-5.17,4.81-8.15
				c1.34-4.68,4.29-6.46,8.12-3.38c4.39,3.53,10.94,3.82,13.88,9.57c0.53,1.03,2.58,1.59,4,1.77c4.24,0.55,8.53,1.12,12.79,1.08
				c6.85-0.06,12.88,1.08,18.38,5.92c8.3,7.32,18.19,9.58,29.31,7.64c5.61-0.98,11.51-0.66,17.25-0.38
				c9.44,0.46,18.84,1.85,28.28,1.99c15,0.22,29.82,0.95,44.47,4.8c13.6,3.57,26.48,2.11,38.25-7.02c3.75-2.91,9.43-4.04,14.36-4.51
				c5.01-0.48,10.28,0.43,15.28,1.5c8.42,1.79,16.62,4.8,25.1,6.18c13.8,2.24,27.07-1.4,40.18-5.34c16.14-4.85,32.44-6.78,49.21-3.65
				c14.13,2.64,28.39,3.4,42.67,1.13c11.73-1.86,22.77,0.52,33.61,4.81c4.47,1.77,9.03,3.4,13.68,4.65
				c9.71,2.6,15.84-0.14,22.38-7.76c3.07-3.58,7.7-5.95,11.88-8.42c2.52-1.49,5.63-1.95,8.35-3.16c13.07-5.8,26.09-11.7,39.12-17.57
				c1.04-0.47,2.04-1.03,4.21-2.12c-5.22,0-9.18,0.65-12.84-0.13c-8.02-1.71-14.52,1.34-21.19,5.01c-3.42,1.88-7.37,2.98-11.21,3.91
				c-15.56,3.77-31.51,4.24-47.38,5.41c-10.5,0.77-21.35-0.52-31.12,5.21c-11.13,6.53-22.54,6.41-33.71,0.12
				c-14.06-7.92-29.05-11.12-45.07-9.77c-9.04,0.76-18.06,1.75-27.11,2.29c-9.44,0.56-19.27-0.84-28.28,1.35
				c-20.43,4.97-40.21,2.91-60.06-2.09c-7.89-1.99-16.4-1.84-22.56,2.47c-6.64,4.64-13.48,6.78-20.95,8.27
				c-6.48,1.29-13.3,1.7-19.37,4.03c-5.14,1.98-9.82,2.71-14.93,1.24c-16.28-4.7-32.7-5.68-49.24-1.64
				c-10.91,2.67-20.1-2.32-27.36-8.67c-6.2-5.43-10.61-7.58-18.28-3.8c-3.63,1.79-10.35,2.63-12.69,0.55
				c-4.73-4.2-9.37-6.25-15.37-6.22c-0.61,0-1.26-0.42-1.84-0.73c-9.26-5.04-19.21-6.96-29.69-7.01c-6.97-0.03-13.93-0.63-20.9-0.98
				c0-1,0-2,0-3c25.42-0.08,50.84-0.18,76.26-0.23c17.27-0.03,34.55-0.02,51.82,0.02c31.91,0.08,63.82,0.21,95.73,0.27
				c21.25,0.04,42.5,0.12,63.74-0.05c8.29-0.07,16.58-1.18,24.87-1.14c40.06,0.17,80.11,0.75,120.16,0.8
				c26.08,0.03,52.17-0.6,78.25-0.89c2.32-0.03,4.64,0.25,6.96,0.35c5.78,0.26,11.56,0.61,17.34,0.73
				c7.12,0.16,14.25,0.12,21.38,0.18c5.13,0.05,10.26,0.31,15.39,0.14c9.63-0.31,19.26-1.34,28.87-1.21
				c14.59,0.2,29.16,1.43,43.74,1.67c10.74,0.18,21.49-0.58,32.24-0.76c2.97-0.05,5.95,0.7,8.92,0.64
				c10.12-0.21,20.24-0.93,30.36-0.91c41.2,0.1,82.4,0.42,123.6,0.62c40.73,0.2,81.47,0.34,122.2,0.52c3.15,0.01,6.31,0.16,9.46,0.25
				c0,1.33,0,2.67,0,4c-3.14,1.83-6.11,4.12-9.46,5.4c-7.7,2.94-15.49,7.2-23.41,7.59c-9.85,0.48-19.89-2.24-29.8-3.9
				c-4.69-0.79-9.26-2.35-13.88-3.55c-15.45-4-30.31,0.43-45.26,3.29c-1.6,0.31-3.36,0.9-4.56,1.92c-5.8,4.96-12.72,6.09-19.98,6.51
				c-1.36,0.08-2.66,1.39-3.97,2.17c-2.11,1.25-4.19,3.6-6.31,3.63c-11.44,0.18-22.89-0.07-34.33-0.47
				c-6.46-0.23-13.05-0.34-19.33-1.7c-15.56-3.35-30.95-2.33-46.43,0.14c-5.87,0.93-11.78,1.95-17.7,2.08
				c-6.66,0.15-13.34-0.65-20.01-1.03c5.97,2.87,12.23,4.53,18.12,7.07c6.63,2.86,11.95,2.32,18-2.12
				c6.51-4.79,14.56-9.42,22.63-4.45c8.65,5.32,17.28,5.13,26.37,3.3c6.97-1.41,13.47-0.46,19.94,2.27
				c5.03,2.12,10.08,4.57,15.37,5.61c13.92,2.73,27.25,0.45,40.32-5.32c19.55-8.63,39.64-13.41,61.52-9.96
				c22.06,3.49,44.09,2.52,64.97-7.18c2.14-0.99,4.78-0.89,7.19-1.29c0,0.67,0,1.33,0,2c-2.99,0.98-6.08,1.74-8.96,2.99
				c-17.55,7.64-35.96,8.87-54.7,7.37c-6.28-0.5-12.52-1.63-18.81-2.09c-18.45-1.36-35.42,4.26-52.03,11.44
				c-17.98,7.77-35.96,8.7-53.91-0.05c-7.13-3.48-14.43-4.38-22.27-2.58c-8.99,2.07-17.62,1.51-25.91-3.67
				c-2.67-1.67-7.19-1.47-10.52-0.67c-3.75,0.9-7.45,2.97-10.55,5.35c-5.77,4.44-11.57,5.22-18.17,2.29
				c-3.32-1.48-6.82-2.69-10.36-3.53c-6.3-1.5-12.63-1.25-18.42,1.86c-1.44,0.78-3.14,3.46-2.79,4.75c1.55,5.69-1.09,8.53-5.85,10.34
				c-2.01,0.77-4.07,1.45-6.13,2.06c-11.44,3.36-22.88,6.75-34.36,9.98c-1.69,0.47-3.6,0.22-5.4,0.2c-3.62-0.05-7.24-0.4-10.85-0.24
				c-13.75,0.63-27.53,2.4-41.21,1.78c-7.98-0.36-13.82,2.47-20.07,5.98c4.88,0.67,9.69,1.1,14.49,1.07
				c22.95-0.13,45.9-0.37,68.85-0.6c4.82-0.05,9.65-0.03,14.46-0.33c4.53-0.28,8.62-1.28,11.8-5.32c2.39-3.03,5.65-6.72,9.03-7.35
				c8.98-1.66,16.78-8.75,26.74-5.87c1.18,0.34,2.7-0.53,3.35-0.68c-1.45-1.72-2.83-3.35-4.21-4.99c1.89-1.57,3.81-4.5,5.67-4.46
				c5.5,0.12,11.13,0.89,16.43,2.4c5.39,1.54,10.42,4.3,15.65,6.44c1.05,0.43,2.28,0.74,3.4,0.66c7.07-0.51,14.12-1.3,21.19-1.69
				c12.41-0.69,24.85-1.82,37.25-1.56c14.96,0.31,29.88,1.86,44.52-2.7c1.71-0.53,3.67-0.22,5.41-0.7c3.74-1.05,7.59-2,11.07-3.67
				c6.27-3,12.11-6.94,18.47-9.7c9.34-4.06,23.13,0.17,29.07,8.46c6.81,9.52,16.11,12.71,27.43,11.55c3.76-0.39,7.63-0.42,11.37,0.01
				c9.73,1.11,19.42,2.63,29.14,3.83c3.54,0.44,7.14,0.43,10.71,0.63c0,0.67,0,1.33,0,2c-4.95-0.16-9.92-0.15-14.86-0.51
				c-12.88-0.95-25.75-2.75-38.63-2.92c-11.31-0.14-20.45-3.37-27.2-12.6c-5.48-7.48-17.77-11.46-26.24-7.76
				c-6.35,2.77-12.21,6.66-18.39,9.85c-2.9,1.5-5.95,3.23-9.1,3.67c-11.93,1.67-23.86,3.12-35.33,7.13
				c-9.21,3.22-18.79,3.75-28.49,3.25c-5.43-0.28-10.88-0.05-16.32-0.05c3.43,0.81,6.86,1.34,10.31,1.44
				c16.57,0.48,33.19,1.79,49.69,0.89c9.19-0.5,17.37,0.86,25.6,4.11c3.09,1.22,6.05,2.88,9.24,3.7c4.94,1.27,10,2.73,15.03,2.81
				c34.89,0.52,69.79,0.69,104.69,0.98c0,1.33,0,2.67,0,4c-24.93-0.58-49.87-1.38-74.8-1.65c-13.79-0.15-27.59,0.82-41.39,0.84
				c-10.96,0.02-21.91-0.87-32.87-0.93c-7.79-0.04-15.59,0.88-23.38,0.94c-20.27,0.15-40.54,0.03-60.81,0.05
				c-23.08,0.03-46.16,0.25-69.24,0.11c-46.22-0.28-92.45-0.86-138.67-1.07c-18.95-0.09-37.9,0.67-56.85,0.65
				c-26.26-0.02-52.53-0.57-78.79-0.6c-16.62-0.02-33.25,0.84-49.86,0.68c-29.6-0.28-59.19-1.45-88.79-1.45
				c-29.42,0-58.84,1.32-88.26,1.47c-45.41,0.22-90.81-0.01-136.22-0.18c-10.46-0.04-20.91-0.57-31.37-0.87c0-1,0-2,0-3
				c3.97-1.6,8.11-2.86,11.88-4.84c22.09-11.62,45.83-17.9,70.48-16.56c20.06,1.1,38.4-6.11,57.75-8.01
				c-5.36-0.64-10.78-1-15.91,0.07c-12.11,2.53-23.01-0.03-32.19-7.81c-6.12-5.18-12.54-5.37-19.86-5.48
				c-5.52-0.08-12.5-1.07-16.11-4.52c-4.26-4.05-10.12-4.53-13.85-8.73c-0.38-0.43-1.51-0.18-2.86-0.3c0.48,7.91-4.36,11.94-10.44,15
				c-3.85,1.94-7.82,3.68-11.62,5.72C12.33,1249.48,6.65,1250.85,0.5,1250z M615.39,1215.98c-5.58-0.79-11.16-2.17-16.76-2.23
				c-6.42-0.07-13.29-0.24-19.17,1.86c-12.17,4.35-23.81,10.2-35.69,15.38c-7.3,3.19-14.54,6.59-22.01,9.3
				c-5.02,1.82-9.13,4.12-12.29,8.68c-5.23,7.56-12.78,10.53-21.79,8.45c-5.32-1.22-10.58-2.9-15.65-4.93
				c-11.68-4.69-23.48-5.98-36.11-4.72c-10.95,1.09-22.3,0.96-33.19-0.62c-20.1-2.93-39.6-2.52-59.07,3.84
				c-12.99,4.24-26.43,7.1-40.23,4.17c-6.48-1.37-12.78-3.56-19.2-5.23c-13.13-3.41-25.55-4.16-37.17,5.52
				c-7.3,6.08-16.88,6.67-26.19,5.29c-5.73-0.85-11.34-2.58-17.07-3.49c-26.86-4.28-53.67-3.19-80.33,1.63
				c-12.58,2.28-24.95,5.26-37.95,4.47c-15.55-0.95-30.91,1.31-45.74,5.91c-9.77,3.03-19.16,7.31-28.72,11.04
				c0.18,0.58,0.36,1.16,0.54,1.74c16.63,0,33.28-0.56,49.88,0.16c16.5,0.71,31.39-4.72,46.22-10.43c7.56-2.91,14.99-4.87,23.13-5.14
				c10.09-0.34,20.28-0.9,30.19-2.71c21.15-3.86,42.19-4.54,63.46-1.3c15.26,2.33,30.56,4.54,45.92,6.03
				c21.58,2.09,42.1-4.93,62.97-8.6c22.05-3.88,44.01-6.05,66.42-3.33c12.14,1.47,24.59,2.75,36.69,1.68
				c15.43-1.37,30.42-0.51,45.58,2c9.56,1.58,19.22,1.18,28.36-3.03c6.79-3.13,13.76-5.86,20.5-9.09
				c27.28-13.05,54.69-25.76,84.44-32.33c8.72-0.01,17.14,1.28,25.22,4.83c4.38,1.93,8.98,3.96,13.65,4.5
				c15.97,1.87,32.03,4.38,48.03,4.21c14.46-0.15,28.99-2.92,43.29-5.61c8.76-1.65,17.15-5.33,25.7-8.1
				c-0.06-0.49-0.12-0.97-0.18-1.46c-10.13,0-20.29-0.54-30.36,0.21c-5.6,0.41-11.51,2.09-16.45,4.75
				c-7.97,4.29-16.14,7.54-25.09,6.79c-9.88-0.83-19.75-2.49-29.47-4.5c-6.94-1.43-13.55-5.28-20.48-5.83
				C638.02,1214.86,626.67,1215.8,615.39,1215.98z M46.35,1215.28c-0.11,0.67-0.22,1.35-0.33,2.02c4.45,0.91,8.93,1.66,13.32,2.79
				c2.18,0.56,4.86,1.24,6.14,2.81c5.03,6.18,10.88,4.56,16.64,2.12c7.83-3.31,11.36-1.83,17.66,3.65c7.28,6.33,15.5,11.94,26.4,9.38
				c16.53-3.88,33-3.21,49.28,1.53c5.18,1.51,10.01,1.89,14.74-1.5c1.35-0.97,3.47-0.82,5.24-1.22c11.78-2.62,24.78-1.46,34.36-11.01
				c0.45-0.45,1.13-0.81,1.75-0.91c8.15-1.31,16.02-2.88,24.59-0.77c18.13,4.47,36.53,7.16,55.16,1.84
				c4.09-1.17,8.53-1.47,12.82-1.54c9.9-0.16,19.66,0.01,29.64-1.35c20.69-2.81,41.52-2.92,60.49,8.57
				c11.3,6.85,22.56,5.95,34.14-0.06c4.88-2.54,10.66-4.32,16.12-4.59c19.88-0.96,39.84-0.89,59.34-5.53
				c5.88-1.4,11.53-3.74,17.29-5.65c-5.45-0.76-10.42-0.66-15.39-0.78c-7.6-0.19-15.19-0.71-22.78-0.74
				c-5.97-0.03-11.94,0.72-17.91,0.68c-10.14-0.07-20.29-0.79-30.43-0.71c-16.96,0.14-33.92,1.14-50.87,0.99
				c-44.68-0.4-89.36-1.22-134.05-1.87c-1.16-0.02-2.33-0.02-3.49,0.02c-14.46,0.57-28.92,1.08-43.37,1.81
				c-1.79,0.09-3.89,0.92-5.22,2.11c-13.88,12.45-29.11,20.12-48.37,14.44c-1.42-0.42-3-0.26-4.42-0.67
				c-11.14-3.19-23.45-4.47-33.03-10.26c-9.04-5.47-17.54-5.62-26.87-5.59c-3.83,0.01-7.65-0.02-11.48-0.02
				C77.77,1215.28,62.06,1215.28,46.35,1215.28z M806.18,1214.29c-10.06,0.96-19.24,0.9-27.96,2.89
				c-13.72,3.13-26.98,8.32-40.72,11.4c-14.23,3.19-28.7,5.69-43.49,3.66c-8.87-1.22-17.78-2.52-26.7-2.88
				c-9.94-0.4-19.44-1.85-28.48-6.21c-8.99-4.33-18.77-5.68-28.22-3.09c-13.74,3.77-27.39,8.21-40.55,13.62
				c-19.04,7.83-37.64,16.73-56.38,25.27c-9.47,4.32-19.21,6.25-29.59,4.49c-15.94-2.7-31.85-3.95-48.1-2.29
				c-10.12,1.03-20.49-0.13-30.73-0.69c-8.44-0.46-16.84-1.88-25.29-2.2c-22.46-0.83-44.16,4.57-66.07,8.38
				c-12.72,2.21-25.63,5.17-38.39,4.85c-16.51-0.41-32.96-3.67-49.42-5.85c-15.19-2.01-30.38-3.66-45.69-1.59
				c-12.5,1.69-24.95,4.58-37.48,4.97c-14.13,0.44-27.74,1.4-40.61,7.97c-4.37,2.23-9.49,2.99-14.26,4.42
				c6.65,0.8,13.42,3.02,19.21-1.92c7.57-6.45,16.37-7.92,26.06-7.76c8.58,0.14,17.37-0.21,25.73-1.98
				c22.86-4.84,45.37-4.46,68.17,0.62c13.9,3.1,28.16,4.81,42.35,6.27c21.45,2.21,41.89-3.87,62.21-9.47
				c23.85-6.57,47.5-9.26,71.62-1.39c8.73,2.85,17.9,3.81,27.1,2.1c17.7-3.28,35.24-2.86,52.94,0.61c8.97,1.76,18.18,0.87,26.89-2.99
				c26.1-11.56,52.22-23.06,78.43-34.36c3.41-1.47,7.35-2.19,11.09-2.35c7.97-0.34,16.02-0.7,23.93,0.05
				c19.31,1.83,38.53,4.57,57.84,6.38c9.04,0.85,18.57,2.08,27.22,0.16c14.84-3.29,29.62-3.79,44.57-3.38
				C772.64,1232.55,789.83,1227.04,806.18,1214.29z M109.11,1280.81c0.27,0.62,0.53,1.25,0.8,1.87c1.82,0,3.65,0.02,5.47,0
				c42.23-0.66,84.46-1.64,126.7-1.89c27.1-0.16,54.21,0.76,81.32,1.09c2.23,0.03,5.04-0.05,6.61-1.31
				c6.33-5.08,13.53-6.57,21.31-8.37c13.85-3.21,27.04,1.23,40.55,0.93c9.61-0.21,19.1,5.06,29.1,1c1.86-0.76,4.51,0.45,6.8,0.72
				c5.39,0.63,10.8,1.85,16.18,1.75c17.85-0.36,35.69-1.24,53.54-1.82c2.13-0.07,4.31,0.29,6.42,0.71
				c12.05,2.38,21.8-3.01,30.89-9.67c5.71-4.18,10.67-9.38,15.96-14.14c0.62-0.56,1.15-1.22,2.35-2.51
				c-12.73,5.76-24.7,10.72-36.24,16.52c-12.63,6.35-25.55,7.7-39.23,4.88c-12.45-2.57-25.06-3.56-37.64-1.07
				c-13.65,2.7-27.16,2.51-40.42-1.62c-12.13-3.77-24.52-5.42-36.94-3.55c-13.58,2.04-26.96,5.52-40.41,8.41
				c-15.73,3.38-31.32,8.42-47.62,7.11c-13.55-1.08-27.09-2.95-40.48-5.32c-16.87-2.98-33.63-7.06-50.89-5.25
				c-20.43,2.15-40.55,6.96-61.35,6.27C117.68,1275.41,113.37,1278.96,109.11,1280.81z M764.2,1234.17c0.01,0.3,0.02,0.6,0.03,0.9
				c15.42,0.58,30.84,1.22,46.26,1.7c3.78,0.12,7.79,0.35,9.42-4.52c0.28-0.83,1.88-1.79,2.82-1.74c9.39,0.47,17.42-3.1,25.14-7.91
				c1.07-0.67,2.53-0.76,3.82-1.03c6.33-1.32,12.65-2.72,19.01-3.89c17.06-3.12,33.3,2.48,49.34,6.27
				c13.05,3.08,24.79,2.39,36.69-2.51c2.79-1.15,5.15-3.36,8.84-5.84c-2.06-0.14-2.65-0.22-3.24-0.22
				c-27.28-0.12-54.57-0.26-81.85-0.34c-15.47-0.05-31.09,1.32-46.37-0.36c-15.57-1.71-28.63,2.94-40.82,11.04
				c-6.54,4.34-13.25,6.65-20.78,7.47C769.74,1233.5,766.97,1233.84,764.2,1234.17z M663.4,1281.84c62.87,0,125.14,0,187.16,0
				c-11.03-4.66-22.06-10.7-35.26-6.96c-2.62,0.74-5.62,0.22-8.44,0.18c-7.29-0.1-14.59-0.16-21.88-0.42
				c-4.95-0.18-9.88-0.73-14.83-0.99c-7.77-0.42-15.71-1.92-23.27-0.83c-12.4,1.79-24.66,3.11-37.15,1.64
				c-7.58-0.89-15.21-2.17-22.79-2C678.3,1272.66,669.84,1274.63,663.4,1281.84z M464.37,1279.99c0.06,0.65,0.12,1.31,0.19,1.96
				c1.63,0,3.25,0.03,4.88,0c17.78-0.36,35.58-1.31,53.33-0.83c7.86,0.21,14.61-1.08,21.22-4.99c4.97-2.94,10.33-3.59,16.24-3.43
				c11.57,0.32,23.2-0.12,34.75-0.95c12.86-0.92,25.83-0.67,38.22-5.9c7.57-3.19,15.99-4.32,23.98-6.56
				c3.54-0.99,5.96-2.85,4.27-7.29c-1.3-3.39,0.48-5.88,3.46-7.34c2.81-1.38,5.8-2.39,9.07-3.7c-7.22-2.53-15.57-2.48-21.32,0.46
				c-1.65,0.84-3.73,2.9-3.79,4.47c-0.34,8.76-0.54,9.4-8.55,12.33c-8.41,3.08-16.83,6.13-25.28,9.07c-1.33,0.46-2.87,0.43-4.32,0.41
				c-4.46-0.08-9.1-1.12-13.36-0.25c-15.87,3.25-31.37,1.27-46.94-1.85c-5.22-1.04-9.9-0.52-14.87,3.07
				c-9.45,6.84-19.72,12.17-32.42,9.68c-6.28-1.23-12.91-1.23-19.34-0.93C477.29,1277.72,470.84,1279.09,464.37,1279.99z
				M628.39,1236.43c3.51,8.14,1.97,12.7-5.5,16.38c-3.09,1.52-6.49,2.94-9.86,3.28c-10.05,1.03-20.16,1.73-30.26,2.12
				c-12.62,0.49-13.4-0.53-11.54-12.95c-11.5,1.63-19.33,8.98-28.22,16.2c6.89,1.23,12.75,3.04,18.64,3.19
				c17.15,0.42,34.32,0.18,51.47,0.2c0.33,0,0.67-0.04,0.98-0.16c9.33-3.4,18.67-6.79,27.99-10.24c3.39-1.25,5.25-3.47,3.92-7.3
				c-1.24-3.57,0.54-5.74,3.41-7.33c1.8-1,3.71-1.8,5.57-2.69c-0.05-0.23-0.09-0.47-0.14-0.7
				C646.18,1236.43,637.51,1236.43,628.39,1236.43z M688.24,1256.86c0.03,0.45,0.07,0.9,0.1,1.36c0.6,0.17,1.27,0.22,1.78,0.53
				c1.46,0.88,2.85,1.87,4.26,2.82c-1.2,1.19-2.22,3.04-3.64,3.44c-2.63,0.74-5.51,0.59-8.27,0.9c-3.03,0.34-6.11,0.54-9.05,1.27
				c-2.39,0.59-4.57,2.6-6.9,2.72c-8.57,0.45-12.5,6.98-17.86,12.43c6.04,0.82,11.21,1.15,14.89-4.58c1.22-1.89,3.91-2.99,6.12-4.05
				c12.05-5.8,24.59-4.05,37.19-2.48c15.25,1.91,30.45,2.51,45.06-3.91c2.49-1.09,5.48-1.38,8.25-1.41
				c7.98-0.1,15.97,0.37,23.94,0.13c5.05-0.15,10.08-1.15,15.11-1.76c-0.04-0.45-0.07-0.9-0.11-1.35c-16.52-0.78-33-2.01-49.56,0.3
				c-7.85,1.1-15.81,1.42-23.73,1.99c-1.29,0.09-2.73-0.04-3.91-0.52c-5.53-2.26-11.05-4.54-16.46-7.08
				C699.66,1254.88,693.99,1254.02,688.24,1256.86z M334.35,1280.71c0.11,0.41,0.23,0.82,0.34,1.23c4.4,0,8.8,0.06,13.2-0.01
				c13.78-0.23,27.56-0.8,41.34-0.7c20.25,0.15,40.5,0.97,60.75,1.05c4.27,0.02,8.55-1.94,12.83-2.99c-0.14-0.65-0.28-1.3-0.42-1.95
				c-1.9,0.16-3.8,0.3-5.7,0.48c-9.34,0.86-18.53,3.42-28.12,0.54c-4.01-1.2-8.7-0.12-13.07-0.1c-3.05,0.01-6.11,0.09-9.16-0.08
				c-1.96-0.11-3.89-0.77-5.85-0.94c-8.2-0.71-16.68-0.24-24.54-2.23c-11.37-2.89-22.07,0.16-32.98,1.46
				C339.97,1276.83,337.22,1279.24,334.35,1280.71z M581.28,1255.68c0-0.34,0.01-0.67,0.01-1.01c7.79,0,15.65,0.63,23.35-0.2
				c6.14-0.67,12.44-2.38,18.04-4.97c6.88-3.18,6.96-9.12,0.34-12.78c-9.76-5.4-20.34-7.38-31.19-3.76c-8,2.66-14.15,8-17.98,15.63
				c-2.23,4.45-0.94,6.56,3.94,7.08C578.95,1255.78,580.12,1255.68,581.28,1255.68z M215.11,1216.94c-5.61-2.75-9.35-1.58-13.75,1.6
				c-8.61,6.23-18.2,11.11-29.61,7.3c-1.39-0.47-3-0.27-4.4-0.71c-8.79-2.8-17.77-5.19-26.24-8.77c-4.45-1.88-8.37-2.17-13.01-0.65
				c4.14,2.63,8.36,5.24,12.95,6.45c13.11,3.47,26.35,6.44,39.57,9.43c1.67,0.38,3.68,0.04,5.36-0.5c5.2-1.69,10.52-3.22,15.4-5.6
				C206.21,1223.15,210.55,1219.83,215.11,1216.94z M658.11,1214.64c-0.04,0.53-0.08,1.05-0.13,1.58
				c15.69,2.85,30.97,8.32,47.36,6.69c7.97-0.79,15.41-4.02,19.4-8.26C702.7,1214.64,680.4,1214.64,658.11,1214.64z M146.17,1214.98
				c-0.09,0.36-0.18,0.71-0.27,1.07c1.48,0.61,2.92,1.41,4.46,1.79c7.85,1.91,15.77,3.57,23.59,5.58
				c10.59,2.73,18.76-2.25,26.76-7.99c0.02-0.02-0.27-0.47-0.26-0.45C182.25,1214.98,164.21,1214.98,146.17,1214.98z M633.24,1233.21
				c-0.05-0.33-0.11-0.66-0.16-0.99c-4.2-0.37-8.4-0.73-12.61-1.1c-0.03,0.49-0.06,0.98-0.1,1.47
				C624.66,1232.8,628.95,1233,633.24,1233.21z M568.25,1242.04c0.08,0.26,0.16,0.53,0.25,0.79c2.48-0.7,4.96-1.4,7.44-2.1
				c-0.1-0.33-0.2-0.66-0.29-0.99C573.18,1240.5,570.71,1241.27,568.25,1242.04z"/>
			<path d="M971.81,51c0,1,0,2,0,3c-13.25-2.24-23.66,4.87-34.52,10.54c-3.86,2.02-6.11,5.13-6.11,9.55c0,3.78-2.39,4.98-5.21,3.99
				c-4.42-1.56-9.91-2.88-12.52-6.21c-5.9-7.55-13.13-7.82-21.37-7.18c-6.23,0.48-12.07-0.78-17.09-5.25
				c-8.56-7.6-18.5-10.37-30.03-8.44c-5.94,0.99-12.17,0.62-18.24,0.32c-8.94-0.44-17.84-1.9-26.77-1.94
				c-15.51-0.08-30.8-0.92-45.95-4.83c-13.27-3.43-25.85-1.97-37.21,7.09c-3.49,2.78-8.78,4.11-13.37,4.45
				c-5.7,0.41-11.62-0.43-17.27-1.57c-8.6-1.72-16.95-4.84-25.6-6.14c-12.04-1.8-23.82,0.9-35.32,4.51
				c-17.86,5.6-35.89,8.17-54.52,4.41c-13.98-2.83-28.11-3.01-42.17-0.85c-10.23,1.57-19.8-0.31-29.29-3.78
				c-5.61-2.06-11.22-4.19-16.99-5.73c-9.79-2.62-15.92,0-22.21,8.01c-2.18,2.77-5.11,5.43-8.27,6.88
				c-13.43,6.15-27.07,11.84-40.63,17.67c-4.49,1.93-9.01,3.81-14.65,6.18c13.81,0.15,26.59,2.38,37.59-6.35
				c1.68-1.33,4.37-1.46,6.63-1.99c17.71-4.11,35.77-5.24,53.83-5.6c10.05-0.2,19.39-1.78,28.31-6.44
				c9.31-4.86,18.83-4.69,28.32-0.24c3.01,1.41,6.23,2.43,9.09,4.08c13.18,7.63,27.5,8.08,42.08,6.97c9.21-0.71,18.4-2.22,27.6-2.23
				c12.17-0.02,24.24-0.03,36.03-3.55c1.56-0.47,3.29-0.48,4.94-0.55c15.05-0.64,29.84,1.02,44.37,5.04
				c7.56,2.09,15.76,1.48,21.6-2.55c6.94-4.79,14.1-7.02,21.95-8.48c6.17-1.14,12.68-1.47,18.4-3.76c5.11-2.05,9.74-2.9,14.9-1.42
				c16.61,4.78,33.28,5.39,50.24,1.63c11.3-2.5,20.37,3.21,28.19,10.3c5.14,4.66,7.57,5.44,14.43,3.12
				c4.68-1.58,11.64-3.69,14.57-1.5c5.04,3.75,9.66,6.22,15.78,6.37c0.93,0.02,1.87,0.66,2.75,1.12c8.24,4.34,17.03,6.18,26.32,6.31
				c9.14,0.13,18.27,0.67,27.4,1.04c0,1,0,2,0,3c-1.82,0.1-3.64,0.29-5.46,0.29c-22.93,0.02-45.86,0.02-68.79,0.01
				c-22.6-0.01-45.19,0.03-67.79-0.11c-13.97-0.09-27.94-0.84-41.89-0.66c-27.92,0.36-55.83,1.27-83.75,1.69
				c-8.78,0.13-17.57-0.82-26.35-0.85c-7.46-0.02-14.92,0.88-22.38,0.83c-37.23-0.22-74.45-0.66-111.68-0.92
				c-8.14-0.06-16.28,0.42-24.42,0.5c-17.93,0.19-35.86,0.3-53.8,0.4c-3.99,0.02-7.97-0.12-11.96-0.21
				c-7.12-0.15-14.24-0.34-21.36-0.47c-3.96-0.07-7.93,0-11.89-0.08c-6.62-0.13-13.25-0.56-19.87-0.46
				c-10.46,0.17-20.91,1.02-31.36,0.94c-8.43-0.06-16.86-1.52-25.29-1.54c-16.57-0.04-33.13,0.58-49.7,0.75
				c-19.1,0.2-38.2,0.31-57.31,0.27c-34.71-0.07-69.42-0.25-104.13-0.43c-30.42-0.16-60.84-0.4-91.27-0.62
				C27.75,89.26,14.12,89.12,0.5,89c0-2,0-4,0-6c3.72-2.13,7.38-4.39,11.18-6.38c15.71-8.2,31.64-3.07,47.47-0.53
				c7.81,1.26,15.52,3.14,23.26,4.8c10.9,2.33,21.01-2.21,31.43-3.79c4.24-0.64,8.15-3.32,12.27-4.91c3.36-1.29,6.8-2.37,10.23-3.47
				c4.08-1.3,9.61,0.93,11.79-5.15c0.33-0.91,2.57-1.52,3.96-1.6c5.3-0.31,10.65-0.83,15.92-0.39c15.02,1.23,29.98,3.18,45,4.3
				c15.11,1.12,30.24,1.34,45.03-3.04c2.2-0.65,4.61-1.1,6.88-0.96c17.24,1.08,34.5,2.05,51.7,3.62c9.23,0.84,18.31,3.38,27.54,4.12
				c19.17,1.54,37.41-3.68,54.91-10.5c18.39-7.17,36.21-15.88,54.05-24.39c11.78-5.62,23.75-6.81,36.39-4.31
				c12.79,2.52,25.72,3.86,38.63,1.21c14.71-3.02,29.07-1.92,43.32,2.41c14.31,4.35,28.86,4.73,43.4,1.76
				c12.03-2.46,23.83-6.09,35.88-8.39c13.5-2.58,27.19-5.97,40.77-5.84c15.82,0.15,31.75,2.98,47.36,6.03
				c20.92,4.08,41.68,6.48,62.81,2.17c12.55-2.56,25.09-4.55,38.07-3.3c7.2,0.69,13.95-1.84,19.84-7.87
				c-14.85,0-28.93-0.25-42.99,0.05c-26.1,0.55-52.2,1.98-78.3,2.03c-31.1,0.05-62.2-0.99-93.3-1.47c-2.24-0.03-5.02,0.02-6.64,1.24
				c-6.4,4.81-13.44,6.63-21.24,8.51c-13.39,3.22-26.05-1.15-39.08-0.72c-10.38,0.34-20.72-5.01-31.55-1.19
				c-1.91,0.67-4.49-0.74-6.78-0.94c-5.88-0.51-11.78-1.23-17.67-1.2c-6.28,0.03-12.56,0.97-18.85,1.23
				c-2.6,0.11-5.24-0.77-7.86-0.72c-9.29,0.16-18.83,2.01-27.8,0.47c-13.26-2.28-23.78,2.22-34.05,9.18
				c-4.63,3.14-9.64,4.44-15.12,3.39c-14.91-2.88-29.75-4.12-45.05-2.45c-12.76,1.39-25.51,1.7-37.43,7.22
				c-3.6,1.67-7.62,2.46-11.11,4.31c-1.46,0.77-2.99,3.31-2.84,4.9c0.65,6.97-0.42,8.53-7.47,8.82c-10.94,0.46-21.62-1.19-31.78-5.12
				c-10.3-3.99-19.48-4.31-29.04,2.82c-6.26,4.67-14.43,8.4-22.33,3.26c-7.56-4.92-15.48-5.02-23.48-3.29
				c-8.58,1.85-16.38,0.82-24.22-2.79c-4.48-2.06-9.22-3.94-14.03-4.85c-14-2.64-27.21,0.09-40.27,5.77
				c-17.64,7.67-35.9,13.19-55.62,10.21c-5.74-0.87-11.52-1.73-17.31-2C37.06,64.35,18.23,66.57,0.5,75c0-1,0-2,0-3
				c19.4-8.93,39.84-11.07,60.86-9.18c5.45,0.49,10.87,1.47,16.32,1.95c18.81,1.66,36.13-3.94,53.07-11.22
				c18.17-7.81,36.29-8.89,54.4,0.15c6.82,3.4,13.89,4.14,21.33,2.52c9.18-2,18.01-1.89,26.38,3.6c2.27,1.49,6.23,1.78,8.91,0.97
				c4.84-1.46,9.56-3.77,13.88-6.45c5.97-3.7,12.05-5.5,18.94-3.59c2.88,0.8,5.65,2.06,8.57,2.61c10.07,1.9,20.14,3.99,30.3,5.18
				c5.46,0.64,5.79-0.78,5.57-6.15c-0.09-2.11,1.27-4.68,2.75-6.34c1.33-1.5,3.67-2.22,5.68-2.97c8.56-3.16,17.14-6.25,25.76-9.23
				c1.33-0.46,2.9-0.33,4.36-0.32c4.8,0.03,9.78,0.98,14.38,0.04c14.88-3.04,29.44-1.18,44.01,1.7c6.32,1.25,11.9,0.36,17.48-3.41
				c9.16-6.18,18.78-11.04,30.71-9.18c7.65,1.19,15.57,1.17,23.34,0.89c5.53-0.21,11.02-1.76,16.52-2.72
				c-0.1-0.55-0.19-1.09-0.29-1.64c-0.98,0-1.96-0.02-2.93,0c-16.62,0.35-33.28,1.42-49.86,0.79c-10.63-0.41-20.25,0.71-29.45,6.21
				c-2.71,1.62-6.41,2.78-9.44,2.41c-12.99-1.61-25.85-1.05-38.76,0.65c-12.45,1.64-25.15,0.66-37.25,5.8
				c-8.01,3.41-16.93,4.65-25.39,7.02c-3.55,0.99-5.02,3.14-3.9,7.18c1.31,4.73-1.17,6.98-6.05,5.61c-4.14-1.16-8.02-3.35-12.18-4.35
				c-4.22-1.02-8.67-1.02-12.97-1.72c-4.69-0.76-9.47-1.38-13.95-2.85c-5.36-1.76-10.42-4.4-15.65-6.55
				c-1.04-0.43-2.3-0.53-3.44-0.46c-7.08,0.39-14.17,0.83-21.24,1.33c-8.6,0.61-17.18,1.71-25.78,1.87
				c-15.27,0.3-30.55-0.21-45.81,0.17c-5.39,0.13-10.77,1.69-16.08,2.94c-3.62,0.85-7.29,1.9-10.61,3.52
				c-6.25,3.05-12.29,6.53-18.38,9.91C94.76,64.06,79.76,56.4,75,50.06C68.15,40.94,59.27,37.4,48.04,38.7
				c-4.24,0.49-8.63,0.28-12.9-0.11C23.58,37.54,12.04,36.22,0.5,35c0-0.67,0-1.33,0-2c2.76-0.31,5.62-1.22,8.28-0.82
				c14.78,2.23,29.48,4.56,44.57,3.61c8.96-0.57,16.54,3.57,22.3,10.35c10.19,12,21.31,14.39,31.88,8.21
				c4.29-2.51,8.48-5.27,12.98-7.33c4.42-2.03,9.06-3.82,13.8-4.82c7.92-1.67,16.51-1.28,23.87-4.17
				c14.56-5.73,29.27-6.91,44.51-5.55c2.63,0.23,5.29,0.03,7.94,0.03c-0.01-0.67-0.03-1.35-0.04-2.02
				c-19.18-0.43-38.43-2.03-57.51-0.87c-11.86,0.72-22.28-1.32-32.68-6.02c-7.22-3.26-14.67-4.53-22.7-4.55
				C65.3,18.99,32.9,18.4,0.5,18c0-1.33,0-2.67,0-4c26.43,0.59,52.85,1.43,79.28,1.69c15.95,0.16,31.92-0.72,47.88-0.83
				c8.96-0.06,17.92,0.72,26.88,0.76c9.13,0.03,18.25-0.58,27.38-0.56c11.47,0.03,22.93,0.55,34.4,0.64
				c17.27,0.13,34.55,0.19,51.82,0.03c12.28-0.12,24.56-0.89,36.84-0.95c23.29-0.11,46.57-0.01,69.86,0.16
				c21.28,0.16,42.55,0.51,63.83,0.83c10.14,0.15,20.27,0.77,30.4,0.61c15.13-0.24,30.24-1.4,45.36-1.4
				c33.08,0,66.16,0.56,99.25,0.72c13.8,0.06,27.6-0.62,41.39-0.45c19.95,0.24,39.9,1.43,59.85,1.32c30.92-0.17,61.83-1.38,92.76-1.6
				c37.42-0.27,74.85-0.04,112.27,0.14c17.29,0.08,34.58,0.58,51.87,0.89c0,1,0,2,0,3c-5.51-0.26-10.25,1.85-14.99,4.43
				C934.76,35.45,910.89,41.06,886,40.77c-12.42-0.14-24.42,0.61-36.46,3.43c-7.42,1.74-15.03,2.65-22.56,3.93
				c0.05,0.49,0.1,0.97,0.15,1.46c5.72-0.36,11.55-0.17,17.15-1.2c11.34-2.08,21.84-0.15,30.41,7.26c6.34,5.48,13.02,6.24,20.71,6.18
				c4.82-0.04,10.65,0.3,14.24,2.92c4.57,3.33,9.49,6,14.07,9.24c2.84,2.01,4.32,2.78,5.11-1.83c0.49-2.87,2.07-6.56,4.34-7.95
				C944.99,56.95,956.71,48.83,971.81,51z M197.93,85.32c-0.05,0.47-0.09,0.93-0.14,1.4c9.42,0,18.85-0.08,28.27,0.03
				c7.01,0.08,13.28-1.88,19.43-5.3c4.56-2.54,9.67-4.82,14.77-5.53c18.57-2.59,36.14,2.86,53.73,7.83c3.29,0.93,6.71,1.79,10.1,1.93
				c14.93,0.59,29.88,0.9,44.82,1.3c4.3,0.12,8.82,1.11,12.84,0.1c7.49-1.88,14.83-4.6,21.98-7.57c15.79-6.56,31.5-13.35,47.08-20.39
				c2.84-1.28,5.36-3.86,7.3-6.4c5.42-7.12,12.27-10.92,21.15-9.09c6.46,1.33,12.85,3.39,18.99,5.82
				c11.31,4.49,22.75,4.92,34.75,3.82c10.16-0.93,20.51-0.1,30.75,0.39c6.12,0.29,12.19,1.53,18.3,2.14
				c15.72,1.58,30.73-1.97,45.59-6.53c17.51-5.38,34.84-5.93,52.3,0.71c5.66,2.15,11.95,2.81,18.01,3.65
				c6.1,0.85,12.25-0.11,17.07-4.02c12.21-9.9,25.78-11.45,40.45-7.75c27.95,7.04,56.1,5.83,84.12,1.18
				c17.06-2.83,33.89-5.99,51.37-5.39c15.35,0.53,30.43-2.77,44.91-8.01c7.25-2.62,14.18-6.14,21.26-9.25
				c-0.15-0.43-0.3-0.86-0.46-1.28c-15.8,0-31.64,0.69-47.39-0.18c-17.53-0.97-33.43,4.46-49.17,10.63
				c-7.75,3.03-15.38,4.71-23.68,4.91c-9.27,0.23-18.51,1.46-27.76,2.35c-13.7,1.32-27.44,4.15-41.08,3.72
				c-17.03-0.54-34.01-3.51-50.95-5.86c-14.21-1.97-28.38-3.86-42.66-1.26c-14.38,2.61-28.68,5.65-43.05,8.29
				c-20.51,3.77-41.05,5.53-61.96,2.76c-12.44-1.65-25.22-2.59-37.69-1.7c-13.37,0.95-26.47,1.35-39.65-1.23
				c-13.73-2.69-26.68-1.28-39.75,4.78C426,58.33,400.33,70.96,373.12,80.02c-13.39,4.46-26.9,7.72-40.38,2.12
				c-11.58-4.81-23.26-7.25-35.73-7.66c-9.75-0.33-19.44-2.86-29.19-3.1C243.56,70.79,220.42,76.57,197.93,85.32z M922.01,85.68
				c0.11-0.55,0.21-1.11,0.32-1.66c-4.33-0.97-8.69-1.84-12.98-2.95c-2.18-0.56-4.97-0.96-6.23-2.48c-5.48-6.6-11.68-5.15-17.86-1.96
				c-4.95,2.56-9.94,2.49-13.33-1.3c-4.53-5.07-9.93-8.27-16.01-10.39c-3.74-1.3-8.12-2.64-11.81-1.9
				c-16.76,3.36-33.37,3.75-49.83-1.03c-6.07-1.77-11.6-2.55-17.05,1.45c-0.95,0.7-2.56,0.47-3.84,0.76
				c-12.09,2.71-25.47,1.41-35.26,11.33c-0.43,0.44-1.18,0.77-1.79,0.8c-7.71,0.3-15.48,1.27-23.11,0.62
				c-11.37-0.97-22.62-4.15-33.97-4.42c-9.47-0.22-19.02,2.3-28.5,3.78c-11.95,1.86-23.9-0.42-36.11,1.53
				c-20.73,3.32-41.77,3.11-60.94-8.5c-10.64-6.45-21.55-6.13-32.73-0.33c-5.05,2.62-10.9,4.59-16.52,5
				c-19.17,1.38-38.45,1.05-57.41,5.18c-6.9,1.5-13.91,2.16-18.99,6.92c6.22,0,12.67-0.06,19.13,0.02c8.25,0.1,16.5,0.41,24.76,0.45
				c4.14,0.02,8.29-0.55,12.43-0.52c19.62,0.12,39.24,0.45,58.85,0.5c21.44,0.06,42.87-0.16,64.31-0.13
				c22.76,0.03,45.51,0.2,68.27,0.33c6.28,0.03,12.56,0.06,18.84,0.23c4.31,0.11,8.62,0.78,12.92,0.64
				c12.79-0.41,25.58-1.03,38.36-1.76c1.63-0.09,3.73-0.91,4.71-2.13c8.32-10.36,20.89-12.43,32.27-16.74
				c1.88-0.71,4.35-0.41,6.41,0.04c19.62,4.3,39.26,8.37,59.45,9.05c8.43,0.28,16.44,2.25,23.12,7.96c0.96,0.82,2.35,1.57,3.55,1.58
				C890.96,85.72,906.48,85.68,922.01,85.68z M880.08,19.69c-6.52-0.08-13.39-3-19.24,2.07c-6.93,6.01-15.09,7.51-24.1,7.64
				c-10.41,0.15-20.97,0.74-31.16,2.68c-20.25,3.86-40.17,3.63-60.37-0.42c-15.93-3.19-32.08-5.63-48.25-7.25
				c-20.4-2.04-39.92,3.84-59.26,9.26c-25.02,7.01-49.66,9.02-75.11,1.4c-9.03-2.71-19.51-2.17-29.09-1.06
				c-14.16,1.64-28.03,2.42-42.11-0.41c-11-2.21-22.16-2.97-32.84,1.8c-11.83,5.29-23.84,10.28-35.32,16.26
				c-17.27,9-35.43,15.23-54.42,18.9c-7.6,1.47-15.59,2.38-23.26,1.74c-20.49-1.71-40.87-4.7-61.36-6.56
				c-8.71-0.79-17.58-0.49-26.32,0.1c-9.23,0.63-18.37,2.54-27.6,3.28c-11.9,0.96-23.85,1.28-35.78,1.94
				c-6.83,0.38-12.8,2.73-18.26,7.07c-5.29,4.2-11.23,6.34-18.53,5.5c-5.81-0.67-11.86,0.86-17.81,1.41
				c0.98,1.39,1.92,1.72,2.84,1.69c8.44-0.23,16.92-1.15,25.31-0.65c14.69,0.87,28.21-3.26,41.8-7.88
				c20.14-6.86,40.83-10.76,62.19-9.36c12.74,0.84,25.46,2.2,38.14,3.74c5.05,0.61,10.39,1.51,14.88,3.72
				c15.77,7.77,31.54,7.34,47.29,0.81c26.7-11.06,53.42-22.1,79.79-33.91c12.38-5.55,24.65-7.26,37.81-4.73
				c12.8,2.46,25.62,2.73,38.69,1.69c11.49-0.91,23.16,0.09,34.73,0.62c7.29,0.34,14.53,1.67,21.81,2.04
				c23.67,1.18,46.6-3.97,69.58-8.53c15.5-3.08,30.97-6.44,46.92-4.41c12.85,1.64,25.69,3.35,38.5,5.3
				c16.67,2.54,33.38,4.01,50.16,1.63c17.42-2.46,34.7-6.23,52.46-5.21c0.81,0.05,1.67-0.17,2.45-0.43
				C856.87,27.36,868.48,23.52,880.08,19.69z M3.78,84.48c0.19,0.48,0.38,0.96,0.58,1.45c37.91,0,75.81,0.09,113.72-0.1
				c5.4-0.03,10.79-1.41,16.19-2.11c7.88-1.02,15.78-2.77,23.66-2.73c6.97,0.03,12.36-2.34,18.02-5.85
				c4.66-2.9,10.09-4.75,15.38-6.37c3.8-1.16,7.96-1.18,11.95-1.7c-10.87-1.33-21.63-2.23-32.41-2.7
				c-5.79-0.25-11.63,0.09-17.42,0.46c-1.41,0.09-3.45,1.05-3.96,2.18c-1.63,3.57-4.45,3.83-7.76,4.17
				c-4.23,0.43-9.03,0.49-12.46,2.57c-13.49,8.16-28.47,10.47-43.46,10.04c-12.56-0.36-25.34-2.85-37.43-6.42
				c-12.36-3.66-23.78-2.95-35.29,1.74C9.8,80.45,6.88,82.66,3.78,84.48z M119.21,19.25c9.65,4.4,18.87,9.23,30.51,7.8
				c12.09-1.49,24.5-0.5,36.76-0.44c3.79,0.02,7.58,0.62,11.38,0.86c7.61,0.47,15.46,2.23,22.8,0.99c14.58-2.47,28.95-3.34,43.57-1
				c11.25,1.8,22.55,2.7,33.23-2.6c2.73-1.36,5.04-3.55,7.88-5.6C242.57,19.25,180.89,19.25,119.21,19.25z M303.47,53.09
				c0.4-1.35,0.69-1.83,0.65-2.27c-0.82-8.63-0.23-9.55,8.21-12.33c2.36-0.78,4.77-1.43,7.16-2.13c10.17-3,20.33-6.05,30.53-8.94
				c1.68-0.48,3.57-0.33,5.36-0.33c4.28,0,8.57,0.28,12.85,0.1c13.24-0.55,26.51-2.12,39.7-1.58c7.92,0.33,13.81-2.48,19.98-6.22
				c-9.38-0.5-18.67-0.54-27.96-0.5c-22.62,0.1-45.24,0.17-67.85,0.4c-5.54,0.06-10.6,1.11-14.65,5.93
				c-2.74,3.26-6.59,6.37-10.56,7.67c-8.98,2.95-17.87,7.16-27.81,5.63c-0.16-0.02-0.4,0.44,0.03-0.06c1.52,1.93,2.89,3.62,4.21,5.34
				c0.47,0.61,0.67,1.69,1.25,1.92C290.78,48.24,297.06,50.62,303.47,53.09z M279.74,44.39c-2.35-2.18-4.1-3.81-5.85-5.44
				c2.35-1.32,4.65-2.72,7.06-3.91c0.68-0.34,1.71-0.33,2.46-0.09c5.56,1.83,10.37,0,15.19-2.64c1.83-1.01,4.09-1.35,6.2-1.71
				c6.71-1.16,9.54-6.78,14.41-11.54c-5.72-0.99-9.8-1.57-13.41,3.05c-2.11,2.7-5.66,4.56-8.91,6c-12,5.32-24.41,2.44-36.83,1.86
				c-12.34-0.57-25.04-0.7-37.05,1.68c-7.76,1.54-15.24,3.91-23.3,3.58c-6.14-0.25-12.32-0.36-18.45-0.07
				c-3.84,0.18-7.63,1.23-11.45,1.89c0.04,0.54,0.08,1.08,0.11,1.62c12.05,0.21,24.12,0.96,36.15,0.49
				c11.39-0.44,22.73-2.19,34.11-3.24c1.94-0.18,4.11-0.14,5.88,0.54c5.6,2.16,11.1,4.58,16.56,7.08
				C268.38,46.21,274.08,47,279.74,44.39z M753.58,84.34c7.92,2.2,7.79,2.01,13.3-1.98c6.97-5.04,14.88-9.05,23.45-8.24
				c8.33,0.78,16.58,3.49,22.89,9.91c0.95,0.97,2.59,1.77,3.92,1.78c16.71,0.12,33.43,0.08,50.69,0.08c-4.42-4.88-7.84-6-16.58-6.71
				c-10.92-0.89-21.89-1.62-32.71-3.26c-10.31-1.56-20.46-4.24-30.7-6.32c-1.41-0.29-3.07-0.3-4.41,0.16
				c-6.73,2.35-13.56,4.55-20.02,7.51C759.82,78.91,756.84,81.94,753.58,84.34z M504.93,22.2c0.12,0.54,0.24,1.08,0.36,1.62
				c2.72-0.22,5.46-0.31,8.16-0.67c8.55-1.14,17.17-3.33,25.63-0.21c4.88,1.8,9.3,1.34,14.06-0.06c2.4-0.71,5.15-0.54,7.71-0.35
				c2.61,0.19,5.16,1.1,7.77,1.35c8.06,0.76,16.42,0.25,24.12,2.29c11.41,3.03,22.09-0.26,33-1.6c2.91-0.36,5.56-2.85,8.33-4.36
				c-6.98-0.83-13.46-0.89-19.93-0.82c-11.13,0.12-22.26,0.49-33.39,0.44c-21.92-0.09-43.85-0.51-65.77-0.46
				C511.63,19.36,508.28,21.2,504.93,22.2z M242.77,86.47c23.1,0,45.73,0,69.81,0c-2.46-1.03-3.48-1.66-4.58-1.88
				c-10.88-2.17-21.71-4.77-32.69-6.26C263.76,76.75,252.65,78.63,242.77,86.47z M810.8,86.05c-11.56-11.57-34.8-10.85-43.05,0
				C781.59,86.05,795.56,86.05,810.8,86.05z"/>
			<path onClick={() => props.onFill(3)} fill={props.fillColors[3]}  d="M0.5,345c9.11,6.37,17.8,13.5,27.45,18.89c10.09,5.63,20.97,9.95,31.78,14.12
				c5.64,2.18,10.53,4.76,14.01,9.76c3.63,5.22,8.45,8.74,14.53,10.82c16.11,5.5,29.3,15.14,40.3,28.18
				c9.23,10.94,20.5,18.9,34.15,24.23c18.85,7.35,36.96,16.59,55.56,24.61c8.65,3.73,17.58,6.98,26.64,9.58
				c9.05,2.6,15.78,7.08,21.23,15.16c3.45,5.1,9.79,9,15.65,11.54c9.93,4.31,18.54,9.78,26.31,17.46
				c13.48,13.31,30.62,20.85,47.89,28.03c11.16,4.64,22,10.01,33.08,14.86c7.29,3.19,14.74,5.99,22.1,9.02
				c1.95,0.8,3.83,1.8,5.74,2.7c-0.09,0.47-0.19,0.94-0.28,1.4c-1.5-0.22-3.06-0.26-4.51-0.67c-8.25-2.33-16.41-5.07-24.75-6.99
				c-3.56-0.82-7.63-0.56-11.25,0.22c-9.2,1.97-16.93-1.59-24.51-5.8c-4.45-2.47-8.84-4.35-15.18-2.49c5,4.44,9.48,8.43,13.97,12.41
				c-0.25,0.3-0.51,0.6-0.76,0.9c-4.44-2.88-9.26-5.32-13.24-8.74c-7.94-6.83-17.9-7.2-27.24-9.73c-3.23-0.88-6.62-2.1-9.25-4.08
				c-5.5-4.14-10.39-9.07-15.82-13.31c-3.06-2.38-6.25-4.33-6.47-8.73c-0.03-0.59-0.49-1.28-0.95-1.71
				c-3.49-3.22-7.2-6.22-10.51-9.61c-2.47-2.53-4.3-5.69-6.71-8.29c-2.94-3.17-6.2-6.05-9.32-9.06c-3.87-3.74-7.78-7.44-11.56-11.27
				c-2.06-2.09-3.88-4.42-5.81-6.65c-0.57,0.32-1.13,0.65-1.7,0.97c0.2,1.48,0.16,3.04,0.64,4.42c1.59,4.55,3.44,9.01,5.07,13.55
				c2.51,7.02,6.56,12.69,12.03,17.98c8.45,8.17,15.77,17.51,23.8,26.6c-5.09,0.44-5.04,0.38-9.46-3.51
				c-2.05-1.81-4.9-1.41-7.46-3.37c-4.62-3.55-5.07-9.97-11.11-12.66c-3.64-1.62-5.59-7.32-7.98-11.37
				c-2.94-4.97-9.02-7.03-14.21-4.47c-1.94,0.95-3.89,1.88-5.82,2.84c-6.41,3.2-12.7,4.06-19.51,0.58
				c-6.68-3.42-13.7-6.19-20.67-8.99c-2.33-0.94-4.99-1.04-7.47-1.61c-1.27-0.29-2.64-0.54-3.71-1.21
				c-4.98-3.07-9.77-6.46-14.85-9.35c-2.04-1.16-4.65-1.33-7.01-1.94c-0.64-0.17-1.61-0.07-1.88-0.47c-3.27-4.8-8.91-4.2-13.49-5.95
				c-1.98-0.76-4.96-0.02-6.24-1.26c-3.93-3.78-8.75-0.98-12.82-2.49c-5.55-2.06-10.99-6.02-16.52-6.08
				c-6.45-0.07-12.07-4.31-18.57-2.63c-0.85,0.22-2.18-0.04-2.84-0.61c-6.4-5.48-14.51-6.09-22.14-8.17c-2.57-0.7-4.66-3-7.16-4.15
				c-5.65-2.61-11.42-4.95-17.46-8.05c2.29,0.13,4.72-0.2,6.85,0.46c6.5,2.03,12.75,4.95,19.33,6.6c12.86,3.21,25.92,5.61,38.81,8.68
				c16.65,3.96,33.22,8.19,48.61,16.11c2.75,1.41,6.4,1.61,9.58,1.43c1.63-0.09,3.13-2.28,4.69-3.52c-1.48-1.16-2.81-2.66-4.48-3.39
				c-2.46-1.07-5.36-1.22-7.7-2.46c-2.04-1.07-3.4-3.39-5.38-4.63c-1.74-1.1-3.93-1.48-5.75-2.65c-2.07-0.88-4.14-1.74-6.19-2.65
				c-0.81-0.35-1.56-0.83-2.38-1.18c-2.05-0.87-4.11-1.74-6.2-2.51c-0.58-0.21-1.39,0.14-1.93-0.11
				c-7.23-3.35-14.39-6.84-21.66-10.09c-1.42-0.64-3.29-0.18-4.82-0.67c-2.56-0.81-4.99-2.02-7.5-2.98
				c-4.37-1.66-8.77-4.61-13.13-4.57c-6.64,0.07-11.35-3.99-17.1-5.6c-3.77-1.06-7.39-2.67-11.11-3.91
				c-2.28-0.76-5.05-0.66-5.25-4.18c-0.04-0.75-1.07-1.68-1.88-2.12c-4.75-2.57-9.56-5.04-14.37-7.5c-0.88-0.45-1.9-1.11-2.76-1
				c-3.9,0.51-6.69-0.66-8.58-4.3c-0.47-0.89-2.25-1.1-3.43-1.62c0-11,0-22,0-33c10.66,6.42,21.29,12.88,31.98,19.24
				c10.58,6.3,19.91,14.79,31.48,19.34c0.51-0.59,1.03-1.18,1.54-1.77c-3.11-4.09-6.21-8.19-9.32-12.28
				c13.17,4.82,25.8,10.55,38.4,16.33c4.08,1.87,8.05,3.98,12.17,5.74c1.56,0.66,3.41,0.62,5.13,0.9c0.29-0.69,0.58-1.38,0.87-2.07
				c-13.21-8.6-26.42-17.2-39.21-25.52c1.15-1.12,2.54-2.47,3.93-3.83c-1.54-1.41-2.86-3.52-4.67-4.09
				c-4.55-1.43-9.32-2.11-13.98-3.19c-2.67-0.62-5.29-1.45-7.93-2.18c1.18-1.23,2.07-1.1,2.81-1.37c1.44-0.52,2.8-1.26,4.19-1.91
				c-0.83-1.34-1.49-2.84-2.53-3.98c-0.87-0.95-2.19-1.48-3.32-2.18c-11.33-6.96-22.65-13.95-34.02-20.86
				C12.23,361.8,6.35,358.43,0.5,355C0.5,351.67,0.5,348.33,0.5,345z"/>
			<path onClick={() => props.onFill(4)} fill={props.fillColors[4]}  d="M0.5,898c1.46,1.27,2.92,2.54,3.36,2.91c3.38-1.04,6.88-3.3,8.7-2.35c2,1.05,2.46,5.06,3.87,8.46
				c0.52-1.38,1.29-2.37,1.17-3.23c-0.77-5.38,2.14-6.19,6.48-5.64c3.95,0.5,8.01,0.49,11.85,1.4c1.92,0.46,3.46,2.48,5.27,3.86
				c3.37-3.18,10.85-3.28,15.47,0.19c0.66,0.5,1.24,1.24,1.99,1.47c5.76,1.84,11.45,3.4,13.53,10.45c0.42,1.42,4.24,2.3,6.6,2.61
				c9.22,1.23,18.48,2.13,28.46,3.24c-3.28,2.52-3,4.46,0.65,4.83c6.54,0.65,7.76,4.42,7.36,10.26c-0.48,6.97-0.04,13.99-0.18,20.99
				c-0.03,1.51-0.41,3.43-1.38,4.44c-2.81,2.94-5.95,5.58-9.06,8.22c-0.94,0.8-2.14,1.44-3.32,1.77c-8.03,2.2-12.37,7.37-13.41,15.62
				c-0.21,1.65-1.18,3.63-2.45,4.67c-25.98,21.28-18.67,64.1,14.76,78.27c20.75,8.8,42.18,16.45,63.89,22.46
				c52.17,14.46,104.62,27.91,157.08,41.29c82.23,20.96,164.56,41.51,246.84,62.24c0.79,0.2,1.55,0.54,2.34,1.46
				c-1.5,0-3.01,0-4.51,0c-17.63,0.01-35.27-0.26-52.9,0.13c-10.3,0.23-20.03-1.34-29.78-4.67c-13.19-4.49-26.53-8.65-40.04-12.04
				c-22.8-5.74-45.81-10.66-68.68-16.13c-32.89-7.86-65.78-15.74-98.59-23.93c-17.04-4.26-33.9-9.21-50.84-13.86
				c-21.42-5.87-42.89-11.59-64.24-17.69c-13.7-3.92-27.27-8.29-40.79-12.79c-2.81-0.94-5.12-3.35-7.7-5.03
				c-8.89-5.79-18.96-7.22-29.22-8.44c-4.79-0.57-9.87-1.79-14-4.15c-10.52-6.03-20.68-12.72-30.78-19.45
				c-7.83-5.22-10.7-13.18-11.79-22.29c-1.07-8.88-2.74-17.72-4.81-26.42c-0.65-2.74-3.44-4.98-5.28-7.5
				c5.27-1.89,10.22-4.23,15.43-5.4c6.57-1.48,13.32-2.19,20.04-2.88c4.18-0.43,7.04-1.95,9-5.9c1.19-2.38,3.85-3.98,5.47-6.2
				c6.6-9.08,2.57-20.59-4.12-26.94c-5.04-4.79-11.78-7.94-14.78-14.79c-0.25-0.58-0.76-1.25-1.31-1.47
				c-6.96-2.7-8.28-9.85-11.99-15.11c-0.29-0.41-0.5-0.94-0.9-1.18c-4.92-3.08-9.79-6.25-14.85-9.08c-1.48-0.83-3.52-0.85-5.3-0.87
				c-4.2-0.05-8.41,0.12-12.61,0.2C0.5,908.67,0.5,903.33,0.5,898z M55.18,919.69c-0.11,0.3-0.22,0.59-0.33,0.89
				c-3.25,0.56-6.69,0.65-9.7,1.82c-2.37,0.92-4.23,3.17-6.31,4.82c1.88,1.87,3.43,4.58,5.69,5.48c12.47,4.93,25.13,9.41,37.71,14.06
				c6.63,2.45,12.06,0.04,16.75-4.45c5.57-5.33,4.57-11.26-2.77-13.2C82.65,925.52,68.87,922.78,55.18,919.69z"/>
			<path onClick={() => props.onFill(5)} fill={props.fillColors[5]}  d="M971.81,16c-17.29-0.31-34.58-0.8-51.87-0.89c-37.42-0.18-74.85-0.41-112.27-0.14
				c-30.92,0.22-61.83,1.44-92.76,1.6c-19.95,0.11-39.9-1.08-59.85-1.32c-13.79-0.16-27.59,0.52-41.39,0.45
				c-33.08-0.15-66.16-0.72-99.25-0.72c-15.12,0-30.24,1.17-45.36,1.4c-10.13,0.16-20.27-0.46-30.4-0.61
				c-21.28-0.33-42.55-0.67-63.83-0.83c-23.29-0.18-46.57-0.28-69.86-0.16c-12.28,0.06-24.56,0.83-36.84,0.95
				c-17.27,0.16-34.54,0.1-51.82-0.03c-11.47-0.08-22.93-0.6-34.4-0.64c-9.13-0.03-18.26,0.59-27.38,0.56
				c-8.96-0.03-17.92-0.81-26.88-0.76c-15.96,0.1-31.92,0.99-47.88,0.83C53.35,15.43,26.93,14.59,0.5,14c0-4.67,0-9.33,0-14
				c323.77,0,647.54,0,971.31,0C971.81,5.33,971.81,10.67,971.81,16z"/>
			<path onClick={() => props.onFill(6)} fill={props.fillColors[6]}  d="M0.5,493c1.44,0.38,2.86,0.92,4.32,1.11c7.14,0.91,13.66,3.23,20.22,6.45
				c8.59,4.21,18.11,6.47,26.99,10.14c10.2,4.21,20.63,5.85,31.62,5.59c28.08-0.65,54.48,6.19,80.01,17.72
				c12.05,5.44,24.89,9.1,37.39,13.54c3.21,1.14,6.46,2.17,9.39,4.23c-11.66-2.48-23.6-4.08-34.9-7.67
				c-11.7-3.72-23.53-5.37-35.6-6.28c-0.66-0.05-1.36-0.01-1.98-0.2c-18.45-5.83-36.87-2.39-55.27,0.3
				c-2.32,0.34-4.54,1.33-6.81,2.02c-0.02,0.53-0.05,1.06-0.07,1.59c1.32,0.56,2.63,1.58,3.97,1.62c7.79,0.23,15.63,0.74,23.39,0.21
				c15.68-1.07,30.75,2.28,45.89,5.33c37.67,7.6,73.57,20.12,107.8,37.81c12.03,6.21,25.34,9.9,38,14.93
				c8.31,3.31,16.49,6.96,24.89,10.52c-1.74,2.09-2.83,4.4-4.63,5.29c-2.14,1.07-4.93,0.76-7.36,1.37c-1.96,0.5-5.01,1.05-5.47,2.35
				c-1.82,5.05-5.73,4.42-9,3.38c-6.67-2.11-6.57-2.43-9.05,4.82c-2.93-3.38-5.97-3.73-8.96,0.3c-3.63-5.51-8.42-3.68-13.39-2.44
				c-0.95-3.96-3.29-5.82-7.44-5.43c-0.98,0.09-2.19-1.35-3.16-2.24c-2.94-2.7-5-7.58-10.38-3.89c-0.4,0.28-1.39-0.29-2.1-0.47
				c-1.24-0.32-3-1.34-3.62-0.86c-4.88,3.75-9.8,0.74-14.62,0.24c-1.96-0.2-4.09-1.45-5.59-2.83c-6.13-5.62-13.3-7.26-21.28-6.22
				c-0.57,0.07-1.06,0.71-1.57,1.07c-4.44-4.31-15.22-6.53-18.59-3.82c-2.97-4.13-7.03-3.78-11.68-4.33
				c-4.75-0.57-8.66-3.05-12.55,1.65c-0.26,0.31-3.94-1.09-4.06-1.97c-0.9-6.49-7.61-7.52-10.77-11.84
				c-0.56-0.77-3.41,0.15-5.67,0.32c0.13-4.15-2.48-5.02-6.48-4.62c-1.12,0.11-2.51-1.67-3.65-2.72c-0.99-0.91-2.54-2.94-2.59-2.9
				c-3.43,2.74-4.49-1.19-6.21-2.39c-2.38-1.67-3.81-5.13-7.69-2.33c-0.6,0.44-2.82-0.8-3.91-1.72c-3-2.54-5.65-5.53-8.76-7.91
				c-1.27-0.97-3.4-0.81-5.57-1.25c-0.87-3.46-2.15-5.25-7.42-3.42c-4.41,1.53-10.83,3.61-14.14-3.78c-0.53-1.18-4.55-0.8-7.58-1.23
				c-1.68-3.85-7.92-4.41-11.82,0.12c-3.83-1.65-5.2-11.37-12.22-3.96c-9.91-2.39-20.09-4.83-30.27-7.28C0.5,525,0.5,509,0.5,493z"/>
			<path onClick={() => props.onFill(7)} fill={props.fillColors[7]}  d="M0.5,321c2.17,1.75,4.34,3.51,6.9,5.58c-1.73,1.23-2.99,2.12-5.29,3.76
				c26.96,16.88,50.1,38.42,79.03,51.31c-6.71-10.22-17.07-16.14-26.37-23.28c4.76-4.73-1.94-6.04-2.77-10.32
				c2.09,0.31,4.01,0.22,5.6,0.9c6.38,2.75,12.68,5.68,18.96,8.66c2.69,1.28,5.23,2.85,7.86,4.26c7.85,4.21,15.75,8.31,23.53,12.63
				c3.75,2.08,7.18,4.75,10.94,6.82c13.65,7.5,27.39,14.83,41.06,22.28c7.83,4.27,15.59,8.68,23.39,13.02
				c-0.61,0.44-1.23,0.89-1.84,1.33c1.01,3.79,2.02,7.58,2.61,9.79c-5.17-1.8-11.48-3.87-17.68-6.23c-2.28-0.87-4.38-2.31-6.45-3.67
				c-6.77-4.44-13.38-9.11-20.25-13.39c-6.05-3.76-12.26-7.32-18.61-10.55c-3.46-1.76-7.3-2.83-11.05-3.94
				c-3.32-0.99-6.73-1.75-10.15-2.34c-1.44-0.25-3,0.18-4.5,0.3c0.61,1.43,0.85,3.24,1.88,4.22c8.53,8.14,17.87,14.94,30.37,17.12
				c-3.9,1.32-5.02,3.14-1.54,5.51c2.46,1.67,5.06,3.27,7.81,4.38c19.98,8.11,39.37,17.73,60.96,21.53
				c7.38,1.3,14.31,5.11,21.47,7.73c0.93,0.34,1.98,0.69,2.91,0.57c1.37-0.18,2.68-0.78,4.01-1.21c-0.58-1.26-0.87-2.83-1.8-3.72
				c-3.92-3.73-8.03-7.25-12.07-10.85c0.35-0.58,0.71-1.15,1.06-1.73c9.06,3.64,18.09,7.33,27.18,10.89c3.05,1.2,6.22,2.08,9.34,3.1
				c1.41,0.46,3.41,0.43,4.16,1.4c4.67,6.03,12.19,5.47,18.16,8.36c5.62,2.72,10.87,6.2,16.26,9.38c0.85,0.5,1.51,1.37,2.39,1.77
				c7.16,3.27,14.34,6.47,21.52,9.68c10.08,4.5,20.24,8.8,29.33,15.26c1.87,1.33,4.59,1.46,6.93,2.12c1.08,0.3,2.71,0.17,3.19,0.86
				c4.55,6.57,11.73,8.11,18.62,10.41c2.14,0.72,3.77,2.86,5.78,4.1c4.14,2.57,8.44,4.89,12.57,7.47c3.31,2.06,6.34,4.6,9.74,6.48
				c2.71,1.5,5.81,2.26,8.67,3.52c1.47,0.65,2.92,1.49,4.12,2.55c1.75,1.55,2.94,4.1,4.91,4.94c12.23,5.19,24.86,9.5,36.84,15.19
				c5.81,2.76,10.49,7.9,15.69,11.97c-0.43,0.17-0.87,0.34-1.31,0.52c0.58,3.12,1.16,6.24,1.71,9.16c-2.87-0.07-2.12-0.68-4.75-3.96
				c-2.69-3.35-7.04-5.39-10.74-7.9c-0.88-0.6-2.7-0.53-2.96-1.2c-2-5.12-5.36-3.46-9.37-2.98c-4.14,0.5-8.53-1.23-12.84-1.83
				c-11.26-1.57-19.69-7.61-26.53-16.4c-0.94-1.2-2.9-1.62-4.4-2.38c-2.9-1.46-6-2.62-8.65-4.43c-4.74-3.25-10.39-3.72-14.52-0.27
				c-0.72,0.61-0.78,3.4-0.07,4.12c5.71,5.81,11.77,11.28,17.52,17.05c1.68,1.69,2.51,4.2,4.08,6.03c3.02,3.53,6.29,6.85,9.12,11.01
				c-2.22-0.96-5.44-1.31-6.48-3c-3.19-5.19-8.89-4.36-13.29-6.47c-4.34-2.08-9.06-3.42-13.68-4.82c-1.91-0.58-4.05-0.79-6.04-0.62
				c-6.03,0.51-11.69,0.22-17.39-2.63c-15.56-7.77-30.32-16.39-42.39-29.36c-3.77-4.05-9.79-6.03-14.84-8.86
				c-5.34-2.99-10.84-5.71-16.08-8.86c-1.73-1.04-3.3-2.84-4.22-4.67c-4.05-8.04-10.9-11.97-19.08-14.88
				c-14.51-5.17-28.88-10.77-43.1-16.69c-14.86-6.19-29.24-13.62-44.29-19.28c-12.71-4.78-21.92-13.48-31.06-22.76
				c-5.94-6.03-11.71-12.39-18.42-17.43c-5.35-4.02-11.83-6.73-18.13-9.18c-7.95-3.09-14.6-7.35-19.95-14.22
				c-2.58-3.31-6.82-5.96-10.85-7.39c-17.17-6.09-33.82-12.8-47.74-25.2c-4.07-3.62-9.7-5.49-14.61-8.16
				C0.5,335.67,0.5,328.33,0.5,321z M263.06,464.12c-11.62,4.86-14.42,4.84-27.61,0.83c-1.74-0.53-3.89,0.3-5.84,0.49
				c1.23,1.34,2.21,3.24,3.73,3.93c14.36,6.45,28.82,12.67,43.22,19.02c5.16,2.28,10.62,4.17,15.29,7.23
				c18.5,12.1,39.35,19.16,59.21,28.32c1.43,0.66,3.59-0.26,5.41-0.44c-0.83-1.47-1.29-3.6-2.54-4.3
				c-6.61-3.72-13.33-7.27-20.21-10.48c-3.73-1.74-8.17-2.12-11.7-4.14c-5.09-2.91-12.3-3.18-14.24-10.48
				c-1.41-5.28-5.02-8.45-10.06-10.2c-2.51-0.87-5.37-1.39-7.32-2.98c-1.54-1.26-3.19-4.49-2.59-5.88c2.38-5.42-0.44-7.73-4.53-9.77
				c-5.95-2.96-11.89-5.93-17.86-8.86c-1.49-0.73-2.99-1.59-4.58-1.86c-1.11-0.19-2.37,0.52-3.56,0.84c0.27,1.05,0.27,2.3,0.87,3.11
				C259.62,460.45,261.36,462.2,263.06,464.12z"/>
			<path onClick={() => props.onFill(8)} fill={props.fillColors[8]}  d="M0.5,1285c10.46,0.3,20.91,0.83,31.37,0.87c45.41,0.16,90.82,0.4,136.22,0.18
				c29.42-0.14,58.84-1.46,88.26-1.47c29.6,0,59.19,1.17,88.79,1.45c16.62,0.15,33.24-0.7,49.86-0.68
				c26.26,0.03,52.53,0.57,78.79,0.6c18.95,0.02,37.9-0.74,56.85-0.65c46.22,0.21,92.45,0.8,138.67,1.07
				c23.08,0.14,46.16-0.08,69.24-0.11c20.27-0.02,40.54,0.09,60.81-0.05c7.8-0.06,15.59-0.98,23.38-0.94
				c10.96,0.06,21.91,0.94,32.87,0.93c13.8-0.02,27.6-0.99,41.39-0.84c24.94,0.27,49.87,1.07,74.8,1.65c0,3.33,0,6.67,0,10
				c-323.77,0-647.54,0-971.31,0C0.5,1293,0.5,1289,0.5,1285z"/>
			<path onClick={() => props.onFill(9)} fill={props.fillColors[9]}  d="M0.5,425c3.05,3.97,6.71,6.56,12.07,6.37c1.46-0.05,3,1.27,4.44,2.07c3.01,1.67,5.98,3.4,8.97,5.11
				c0.97,0.56,2.74,1.05,2.79,1.68c0.38,4.68,4.21,4.34,7.19,5.51c6.52,2.55,12.92,5.42,19.4,8.08c1.36,0.56,2.85,0.98,4.31,1.08
				c6.72,0.45,13.09,1.78,18.45,6.26c0.61,0.51,1.52,0.92,2.29,0.91c9.44-0.14,16.53,7.15,25.63,8.04c2.8,4.84,9.77,1.99,12.75,6.99
				c0.62,1.04,3.54,0.71,4.98,2.05c2.2,0.81,4.51,1.41,6.56,2.51c1.62,0.86,2.75,2.64,4.38,3.48c3.87,2,7.91,3.68,12.01,6.03
				c-2.85-0.63-5.94-0.76-8.52-1.98c-24.43-11.61-50.48-17.14-76.9-21.74c-8.57-1.49-16.7-5.44-25.07-8.15
				c-3.14-1.02-6.36-2.07-9.61-2.4c-2.93-0.3-6.04,0.73-5.91,4.45c0.11,3.21,2.18,4.5,5.69,4.86c3.4,0.35,6.65,2.27,9.95,3.54
				c5.7,2.19,11.42,4.33,17.06,6.66c4.75,1.95,9.37,4.21,14.11,6.17c1.96,0.81,4.09,1.24,6.15,1.81c3.98,1.1,7.97,2.18,11.96,3.25
				c0.79,0.21,1.72,0.72,2.36,0.49c6.99-2.56,12.26,2.62,18.36,4.02c1.9,0.44,3.65,1.56,5.56,1.88c2.37,0.4,5.27-1.12,6.74,2.14
				c0.05,0.11,0.33,0.23,0.44,0.18c4.92-1.8,8.95,1.26,13.33,2.44c1.2,0.32,2.39,0.74,3.52,1.26c2.22,1.01,4.33,2.87,6.6,3.06
				c7.89,0.64,13.07,6.24,19.35,9.81c2.13,1.21,4.35,2.39,6.67,3.14c3.64,1.18,8.2,0.96,10.95,3.13c7.09,5.61,15.77,7.46,23.79,8.96
				c6.02,1.13,13.22-2.76,19.6-5.19c5.24-2,9.09-0.88,11.14,4.55c0.35,0.92,0.63,2.01,1.29,2.65c3.71,3.61,7.55,7.09,11.31,10.64
				c0.46,0.43,0.93,1.01,1.08,1.59c1.56,6.3,7.97,8.25,11.24,12.61c3.95,5.27,11.58,2.31,14.59,8.42c0.54,1.09,3.85,0.81,5.88,1.16
				c0.38-0.36,0.77-0.72,1.15-1.08c-1.36-2.66-2.26-5.74-4.17-7.92c-9.85-11.24-19.98-22.25-29.98-33.36
				c-4.44-4.93-6.49-10.94-8.63-18.7c2.67,1.45,4.93,1.93,5.21,2.95c1.54,5.66,7.7,5.76,10.7,9.46c2.32,2.85,3.93,6.32,6.39,9.02
				c4.21,4.63,8.83,8.88,13.28,13.3c0.45,0.45,1.24,0.93,1.25,1.41c0.14,7.17,7.11,9.15,10.73,13.54
				c3.97,4.82,10.05,7.89,15.21,11.72c12.86,9.55,25.51,19.41,38.68,28.51c6.75,4.66,14.43,7.96,22.11,12.09
				c-2.27,3.63-4.85,5.05-8.7,4.85c-2.82-0.15-6.19-0.4-8.39,0.94c-2.91,1.77-4.89,1.18-6.99-0.49c-3.49-2.78-6.83-3.47-10.79-0.76
				c-1,0.69-3.17,0.25-4.56-0.29c-17.78-6.96-35.67-13.69-53.24-21.16c-16.5-7.02-32.44-15.38-49.01-22.19
				c-33.38-13.71-68.25-22.06-104.07-26.05c-9.51-1.06-19.25-0.16-27.5-0.16c15.58-2.62,32.47-4.56,49.56,0.11
				c5.36,1.46,11.18,1.24,16.79,1.78c1.98,0.19,4.01,0.15,5.92,0.61c16.3,3.87,32.58,7.81,48.85,11.78
				c3.46,0.84,6.85,1.94,10.28,2.92c0.36-0.42,0.72-0.85,1.09-1.27c-0.97-1.42-1.62-3.41-2.96-4.15c-4.62-2.54-9.35-4.98-14.27-6.85
				c-13.94-5.29-27.95-10.43-42.03-15.35c-12.04-4.2-24.03-8.79-36.39-11.81c-14.91-3.64-29.4-7.96-43.16-14.91
				c-10.53-5.31-21.59-9.54-32.15-14.79c-15.31-7.61-30.37-15.72-45.54-23.61c-1.18-0.61-2.39-1.14-3.59-1.7
				C0.5,450.33,0.5,437.67,0.5,425z"/>
			<path d="M971.81,102c-2.33,0.06-4.65,0.17-6.98,0.17c-217.42,0.01-434.84,0.01-652.25,0.01c-2.61,0-5.23,0-8.18,0
				c0.37,4.59,3.36,5.31,6.25,5.51c4.14,0.29,8.31,0.02,12.47,0.07c8.35,0.1,10.92,2.6,11.39,10.96c0.22,3.9,5.99,7.3,9.98,5.22
				c4.22-2.2,8.29-4.98,11.8-8.2c6.6-6.05,13.34-8.87,22.13-4.63c4.25,2.05,9.3,1.63,12.64-1.83c6.46-6.69,14.29-7.24,22.16-5.09
				c17.39,4.73,35.16,4.77,52.89,5.73c5.29,0.29,10.57,1.14,15.79,2.06c2.08,0.37,4.01,1.62,5.92,3.34
				c-7.93,4.37-16.7,2.79-25.17,5.39c1.67,0.71,3.28,1.67,5.02,2.09c6.94,1.69,13.91,3.23,20.88,4.76c0.95,0.21,2.18,0.43,2.95,0.03
				c8.52-4.5,16.06,1.83,24.13,2.21c1.09,0.05,2.45,1.61,3.1,2.8c2.46,4.52,7.05,4.92,9.62,0.41c2.14-3.75,5.53-3.88,8.63-2.98
				c2.1,0.61,3.64,3.11,5.47,4.72c1.55,1.36,2.98,3.31,4.79,3.8c2.49,0.68,5.4-0.27,7.91,0.36c4.22,1.06,8.36,2.61,12.36,4.35
				c2.64,1.15,4.13,5.25,8.04,2.94c2.97,4.3,5.94,8.6,8.91,12.9c-1.62,0.67-3.19,1.72-4.87,1.94c-6.58,0.89-13.2,1.5-19.8,2.27
				c-1.1,0.13-2.17,0.55-3.71,0.96c2.59,9.6,1.57,18.36-5.37,25.68c-4.97,5.25-11.12,7.59-18.36,7.42
				c-15.73-0.38-24.02-12.23-22.52-32.09c-7.8-1.34-15.55-3.61-23.35-3.8c-15.1-0.36-30.22,0.74-45.33,0.69
				c-12.24-0.04-24.48-0.96-36.73-1.29c-2.11-0.06-4.62,0.38-6.3,1.54c-4.61,3.19-9.41,3.39-14.37,0.53
				c-2.46-1.42-5.33-2.49-8.13-2.86c-8.89-1.16-17.83-2.66-26.76-2.67c-12.89-0.01-25.94,0.19-38.65,2.1
				c-15.54,2.34-30.65,2.38-45.89-1.46c-6.16-1.55-12.44-1.67-18.54,1.19c-1.61,0.76-3.9,0.48-5.79,0.16
				c-9.99-1.64-19.94-3.52-29.93-5.17c-14.02-2.33-27.76-0.06-41.59,2.18c-5.16,0.84-10.73-0.6-16.08-1.24
				c-3.75-0.45-7.43-1.72-11.17-1.88c-6.97-0.3-14-0.45-20.95,0.06c-6.2,0.46-12.43,1.45-18.45,3c-14.92,3.83-29.54,2.07-44.11-1.56
				c-1.3-0.32-3.15-2.19-3.08-3.23c0.08-1.21,1.8-2.95,3.13-3.34c8.9-2.62,17.89-4.96,26.86-7.35c4.33-1.15,8.72-2.09,13.02-3.35
				c6.8-1.98,12.28-5.63,15.54-12.28c3-6.11,8.85-7.71,14.95-8.43c9.4-1.1,18.83-1.94,28.23-3.05c2.39-0.28,4.7-1.19,7.04-1.81
				c0.01-0.6,0.03-1.21,0.04-1.81c-4.73-0.63-9.46-1.23-14.18-1.9c-3.94-0.56-9.04,0.13-11.57-2.08c-7.32-6.42-15.6-3.79-23.43-3.86
				c-37.92-0.34-75.85-0.24-113.77-0.3c0-1.33,0-2.67,0-4c44.07,0.03,88.13,0.08,132.2,0.08c277.38,0.02,554.75,0.04,832.13,0.05
				c2.33,0,4.65-0.09,6.98-0.13C971.81,99.33,971.81,100.67,971.81,102z M124.58,132.17c1.19-0.88,1.55-1.21,1.98-1.44
				c0.44-0.23,0.91-0.43,1.4-0.52c16.98-3.11,33.63-2.77,49.93,3.88c7.01,2.86,14.41,4.75,21.63,7.08c0.2-0.54,0.39-1.08,0.59-1.62
				c-1.69-0.91-3.34-1.89-5.07-2.71c-4.96-2.33-10.01-4.48-14.88-6.97c-1.07-0.55-1.57-2.24-2.33-3.4c1.13-0.53,2.26-1.51,3.4-1.51
				c18.39-0.04,36.74-2.74,55.18-0.13c12.48,1.76,25.12,2.46,37.65,3.94c5.63,0.67,11.18,2.02,14.45,7.6
				c0.47,0.79,2.03,0.88,2.97,1.47c1.56,0.98,4.12,1.97,4.27,3.19c0.2,1.71-1.24,3.76-2.33,5.45c-1.12,1.74-2.66,3.22-4.01,4.81
				c1.23,0.48,1.72,0.37,2.15,0.14c1.45-0.8,2.94-1.56,4.31-2.49c11.92-8.16,25.03-10.79,39.34-9.46
				c10.06,0.94,20.31,1.73,30.33,0.86c13.86-1.2,27.55-4.34,41.38-6.07c14.92-1.86,29.88-1.97,44.47,2.51
				c2.39,0.73,4.39,2.75,6.57,4.18c-2.55,0.76-5.07,2.08-7.64,2.16c-6.94,0.21-13.99-0.87-20.82-0.05
				c-10.21,1.22-20.29,3.63-30.42,5.53c0,0.42,0,0.83,0,1.25c12.45,0,24.9-0.12,37.35,0.03c15.62,0.2,31.23,0.69,46.85,0.92
				c10.43,0.15,20.85,0.02,31.28,0.06c8.28,0.03,16.81-1.15,24.77,0.49c11.12,2.28,22.03,2.57,33.15,1.54
				c1.12-0.1,2.22-0.45,4.11-0.84c-3.45-1.91-6.33-2.89-8.41-4.77c-4.61-4.16-9.66-6.16-15.9-5.58c-2.6,0.24-5.43,0.06-7.89-0.74
				c-1.79-0.58-3.86-2.2-4.54-3.87c-0.89-2.21-0.96-4.41-4.05-4.76c-3.05-0.35-4.35,1.14-5.72,3.6c-2.24,4.03-11.29,4.54-12.79,0.48
				c-1.62-4.36-4.67-4.46-7.99-5.1c-6.05-1.16-12-4.07-18.31-0.92c-0.65,0.32-1.67,0.12-2.46-0.08c-9.63-2.47-19.26-4.93-28.84-7.56
				c-1.57-0.43-2.9-1.7-5.37-3.22c8.65-4.5,17.18-2.6,24.98-4.76c-2.73-0.81-5.44-1.29-8.18-1.52c-19.99-1.67-40.19-0.95-59.82-6.55
				c-6.2-1.77-12.64-0.99-18.02,4.35c-4.06,4.03-9.76,5.57-15.26,2.95c-8.05-3.83-14.21-1.57-20.27,4.21
				c-3.43,3.28-7.51,6.18-11.78,8.22c-6.45,3.06-15.21-2.02-14.27-8.17c0.84-5.51-2.32-6.88-6.16-7.49c-3.21-0.51-6.6,0.09-9.91,0.16
				c-5.25,0.1-10.78,0.08-13.64-5.18c-1.56-2.87-3.18-3.6-6.13-3.59c-51.07,0.08-102.13,0.07-153.2,0.1c-1.38,0-2.76,0.25-4.13,0.38
				c1.78,1.44,3.62,1.94,5.48,2.2c6.58,0.91,13.19,1.61,19.73,2.71c1.34,0.23,3.29,1.76,3.43,2.88c0.14,1.12-1.39,3.18-2.62,3.61
				c-3.87,1.38-7.89,2.59-11.95,3.14c-8.88,1.22-17.82,1.97-26.73,2.97c-4.8,0.54-8.51,2.92-10.66,7.27
				c-3.18,6.42-8.45,10.15-15.06,12.19c-4.44,1.37-8.92,2.6-13.44,3.69c-7.85,1.9-15.74,3.66-23.61,5.47
				c14.79-1.45,29.37-4.06,44.06-5.22c14.67-1.16,29.44-0.86,44.17-1.2c0.02-0.32,0.03-0.65,0.05-0.97
				C142.67,138.37,133.96,135.39,124.58,132.17z M63.78,152.46c0.03,0.77,0.06,1.54,0.09,2.31c9.86,0,19.74,0.44,29.56-0.18
				c5.35-0.33,10.54-3.32,15.88-3.6c12.27-0.63,24.59-0.43,36.89-0.34c2.23,0.02,4.43,1.34,6.7,1.72c4.2,0.7,8.68,2.3,12.62,1.49
				c22.05-4.56,43.88-3.93,65.76,0.92c4.62,1.02,9.85,2.53,14.09,1.32c7.2-2.04,14.06-2.11,21.1-0.63
				c11.24,2.36,22.37,4.39,34.03,2.45c11.61-1.93,23.39-3.85,35.1-3.87c14.23-0.02,28.48,1.85,42.7,3.16
				c2.15,0.2,4.36,1.48,6.23,2.72c4.14,2.74,7.91,2.41,12.34-0.18c2.35-1.38,5.73-2.12,8.39-1.66c15.58,2.7,31.05,1.59,46.68,0.34
				c14.85-1.19,29.72-0.33,44.65,1.88c10.2,1.51,20.96,3.62,31.44-0.31c2.19-0.82,5.21-1.02,7.33-0.19
				c7.7,3.01,15.43,1.64,23.16,0.87c6.96-0.69,13.88-1.74,20.83-2.64c-0.01-0.55-0.03-1.1-0.04-1.66c-1.99-0.16-3.98-0.48-5.97-0.46
				c-10.58,0.1-21.05,0.99-31.7-1.33c-7.97-1.73-16.5-1.12-24.79-1.12c-9.92,0-19.83,0.64-29.75,0.65c-5.13,0-10.25-1.04-15.39-1.13
				c-19.95-0.36-39.91-0.46-59.86-0.82c-5.31-0.09-10.63-0.55-15.88-1.28c-1.43-0.2-2.65-1.96-3.96-3.01
				c1.29-0.85,2.49-2.23,3.88-2.43c3.76-0.56,7.78,0,11.38-1c13.75-3.82,27.61-5.79,41.87-4.34c0.2,0.02,0.44-0.31,0.99-0.71
				c-1.46-0.45-2.66-0.92-3.9-1.18c-19.59-4.16-39.04-1.78-58.41,1.59c-16.24,2.83-32.34,5.05-48.97,2.72
				c-14.95-2.09-29.57-0.25-42.28,9.25c-1.71,1.28-3.68,2.35-5.7,3.05c-1.22,0.42-2.96,0.33-4.05-0.28c-0.53-0.3-0.53-2.59,0.01-3.52
				c1.85-3.16,4.05-6.12,6.13-9.19c-2.25-1.22-4.54-2.39-6.74-3.68c-3.45-2.03-6.63-5.34-10.3-6c-9.77-1.78-19.73-2.59-29.64-3.5
				c-20.44-1.88-40.9-4.28-61.44,0.42c1.45,0.75,2.98,1.61,4.56,2.36c3.74,1.77,7.71,3.18,11.18,5.36c1.66,1.04,2.4,3.53,3.56,5.36
				c-1.99,0.36-4.08,1.32-5.94,0.97c-4.55-0.85-9-2.26-13.46-3.53c-9.23-2.62-18.32-5.93-27.7-7.71c-6.26-1.19-12.92-0.23-19.41-0.23
				c-0.11,0.58-0.22,1.15-0.34,1.73c6.98,2.45,13.97,4.89,20.95,7.34c0,0.46,0,0.93,0,1.39c-1.53,0.69-3.02,1.85-4.6,1.99
				c-8.25,0.7-16.55,1.85-24.79,1.52C106.76,144.76,85.33,148.98,63.78,152.46z M554.45,173.25c-0.05-7.97-0.94-8.75-9.02-8.89
				c-2.44-0.04-4.87-1.07-7.31-1.06c-4.14,0.01-8.28,0.45-12.41,0.76c-2.58,0.19-5.24,0.98-7.71,0.56c-3.44-0.58-4.83,0.52-5.53,3.78
				c-1.81,8.45,0.6,15.54,6.96,21.07c6.76,5.88,14.62,6.97,22.72,3.16C550.2,188.85,554.44,182.31,554.45,173.25z"/>
			<path onClick={() => props.onFill(10)} fill={props.fillColors[10]}  d="M3.02,544.58c7.01,1.59,13.46,6.01,21.22,4.05c0.96-0.24,2.35,1.03,3.48,1.7
				c2.88,1.71,5.55,2.22,7.96-1.78c1.06,1.46,1.79,2.68,2.73,3.71c2.15,2.39,4.25,4.46,8.06,2.55c1.35-0.68,4.66-0.23,5.2,0.75
				c2.8,5.04,4.91,1.6,7.34-0.54c5.62,7.98,13.15,6.76,21.27,4.69c0.7,6.82,6.83,6.41,11.38,8.42c1.96,0.87,3.34,2.98,5.24,4.09
				c3.68,2.14,7.56,3.9,11.3,5.94c0.95,0.52,1.88,1.36,2.45,2.28c2.19,3.54,2.14,3.57,5.95,1.56c1.86,2.64,3.34,6.74,7.81,2.92
				c0.77,2.27,0.9,4.85,2.22,5.87c1.09,0.84,3.6-0.17,5.1,0.53c1.99,0.87,4.03,1.64,5.96,2.63c1.4,0.72,3.33,1.42,3.87,2.64
				c2.92,6.63,6.54,7.77,12.7,3.81c0.73-0.47,2.14-0.23,3.1,0.08c5.32,1.76,10.58,3.7,15.91,5.45c3.24,1.07,6.51,2.15,9.85,2.76
				c1.1,0.2,2.46-1.07,3.4-1.53c3.45,1.2,6.88,2.52,10.39,3.52c0.83,0.23,2.08-0.48,2.94-1.05c2.8-1.85,6.29-0.79,7,2.43
				c0.71,3.25,1.65,3.66,5.37,3.22c4.23-0.51,8.8,2,13.25,3.08c1.68,0.41,3.73,1.18,5.11,0.6c3.21-1.35,6.55,0,9.07-0.41
				c5.67-0.93,8.59,0.35,10.9,5.16c0.3,0.63,2.15,0.5,3.27,0.76c2.48,0.57,4.64,1.22,5.1,4.47c0.38,2.62,2.05,3.57,4.71,1.8
				c1.46-0.97,3.33-1.65,5.05-1.76c1.11-0.07,3.05,0.95,3.28,1.83c0.41,1.58,0.08,3.53-0.54,5.1c-0.16,0.4-2.88,0.43-3.81-0.23
				c-3.7-2.63-6.41-2.48-8.24,2.32c-3.15-0.64-6.55-1.89-8.14,2.67c-0.23,0.66-2.43,0.57-3.68,0.94c-3.03,0.88-6.05,1.83-9.71,2.95
				c-2.46-2.9-5.5-1.75-8.69,0.97c-2.38,2.03-4.94,3.88-7.62,5.49c-0.95,0.57-2.55,0.47-3.72,0.16
				c-47.08-12.34-94.49-23.59-141.06-37.62c-23.36-7.04-46.73-13.94-70.34-20.02c-1.89-0.49-3.93-0.35-5.9-0.5c0-12,0-24,0-36
				c2.71,1.28,5.42,2.56,9.08,4.29c-0.01-0.05-0.01,1.51,0.76,2.42c0.73,0.88,3.02,1.82,3.32,1.5c4.19-4.44,5.56-0.72,7.69,2.2
				c1.64,2.25,2.67,6.52,7.11,3.6c0.79,6.37,6.36,6.41,12.57,1.63c0.19,4.07,2.43,4.1,5.79,3.88c2.93-0.2,6.04,1.1,8.93,2.14
				c1.89,0.68,3.53,2.09,5.5,3.32c0,0.37-0.18,1.1,0.03,1.68c0.52,1.46,1.22,2.86,1.85,4.28c1.22-0.84,2.43-1.68,3.65-2.51
				c1.12-0.76,2.25-1.51,2.67-1.78c2.33,3.54,4.29,6.53,6.25,9.52c1.65-1.15,3.31-2.31,5.2-3.63c0.87,0.43,2.54,2.01,3.64,1.67
				c4.25-1.29,4.72,1.34,6.01,4.24c0.66,1.49,3.33,2.65,5.23,2.89c2.87,0.36,5.86-0.2,8.79-0.42c1.99-0.16,3.98-0.42,5.97-0.64
				c-1.23-1.76-2.29-3.68-3.74-5.23c-0.85-0.91-2.33-1.22-3.52-1.81c-7.73-3.84-15.46-7.69-23.18-11.54
				c-15.88-7.9-31.72-15.87-47.67-23.62c-3.25-1.58-6.93-2.26-10.36-3.5C15.69,549.32,9.36,546.93,3.02,544.58z"/>
			<path onClick={() => props.onFill(11)} fill={props.fillColors[11]}  d="M971.81,1212c-3.15-0.09-6.31-0.23-9.46-0.25c-40.73-0.18-81.47-0.33-122.2-0.52
				c-41.2-0.2-82.4-0.52-123.6-0.62c-10.12-0.02-20.24,0.7-30.36,0.91c-2.97,0.06-5.95-0.69-8.92-0.64
				c-10.75,0.19-21.5,0.95-32.24,0.76c-14.59-0.25-29.16-1.47-43.74-1.67c-9.62-0.13-19.24,0.9-28.87,1.21
				c-5.12,0.17-10.26-0.09-15.39-0.14c-7.13-0.06-14.25-0.02-21.38-0.18c-5.78-0.13-11.56-0.47-17.34-0.73
				c-2.32-0.11-4.64-0.38-6.96-0.35c-26.08,0.3-52.17,0.93-78.25,0.89c-40.05-0.05-80.11-0.64-120.16-0.8
				c-8.29-0.03-16.58,1.07-24.87,1.14c-21.25,0.17-42.5,0.1-63.74,0.05c-31.91-0.06-63.82-0.2-95.73-0.27
				c-17.27-0.04-34.55-0.05-51.82-0.02c-25.42,0.05-50.84,0.15-76.26,0.23c0-3,0-6,0-9c170.28,0.01,340.56,0.04,510.85,0.04
				c153.49,0,306.97-0.02,460.46-0.04C971.81,1205.33,971.81,1208.67,971.81,1212z"/>
			<path onClick={() => props.onFill(12)} fill={props.fillColors[12]}  d="M971.81,87c-9.13-0.36-18.26-0.91-27.4-1.04c-9.29-0.13-18.08-1.97-26.32-6.31
				c-0.88-0.46-1.82-1.1-2.75-1.12c-6.11-0.15-10.74-2.63-15.78-6.37c-2.94-2.18-9.9-0.08-14.57,1.5c-6.86,2.32-9.29,1.53-14.43-3.12
				c-7.82-7.09-16.89-12.81-28.19-10.3c-16.96,3.76-33.63,3.14-50.24-1.63c-5.15-1.48-9.78-0.62-14.9,1.42
				c-5.72,2.29-12.23,2.62-18.4,3.76c-7.84,1.45-15,3.69-21.95,8.48c-5.84,4.03-14.03,4.64-21.6,2.55
				c-14.53-4.01-29.32-5.67-44.37-5.04c-1.66,0.07-3.38,0.08-4.94,0.55c-11.8,3.51-23.86,3.53-36.03,3.55
				c-9.2,0.01-18.39,1.53-27.6,2.23c-14.58,1.12-28.9,0.66-42.08-6.97c-2.86-1.65-6.08-2.67-9.09-4.08
				c-9.49-4.45-19.01-4.62-28.32,0.24c-8.92,4.66-18.27,6.24-28.31,6.44c-18.06,0.35-36.12,1.49-53.83,5.6
				c-2.26,0.52-4.95,0.65-6.63,1.99c-10.99,8.73-23.77,6.5-37.59,6.35c5.63-2.38,10.15-4.25,14.65-6.18
				c13.57-5.84,27.21-11.52,40.63-17.67c3.16-1.45,6.09-4.1,8.27-6.88c6.3-8,12.43-10.62,22.21-8.01c5.76,1.54,11.38,3.67,16.99,5.73
				c9.49,3.48,19.06,5.36,29.29,3.78c14.06-2.16,28.19-1.98,42.17,0.85c18.63,3.77,36.67,1.19,54.52-4.41
				c11.49-3.6,23.28-6.3,35.32-4.51c8.64,1.29,17,4.41,25.6,6.14c5.66,1.13,11.57,1.98,17.27,1.57c4.59-0.33,9.89-1.67,13.37-4.45
				c11.37-9.06,23.94-10.52,37.21-7.09c15.14,3.91,30.44,4.75,45.95,4.83c8.93,0.05,17.83,1.51,26.77,1.94
				c6.07,0.3,12.3,0.67,18.24-0.32c11.53-1.93,21.47,0.83,30.03,8.44c5.03,4.47,10.86,5.73,17.09,5.25
				c8.24-0.64,15.46-0.37,21.37,7.18c2.6,3.33,8.1,4.66,12.52,6.21c2.82,0.99,5.21-0.21,5.21-3.99c0-4.42,2.25-7.53,6.11-9.55
				c10.85-5.66,21.26-12.77,34.52-10.54c0,1.67,0,3.33,0,5c-12.78-2.23-22.37,5.5-32.7,10.73c-0.98,0.5-0.99,2.91-1.45,4.43
				c2.06,0.43,4.32,1.63,6.12,1.13c3.27-0.9,6.15-3.75,9.38-4.11C959.3,70.5,965.58,71,971.81,71
				C971.81,76.33,971.81,81.67,971.81,87z M686.13,64.1c0.06-0.61,0.12-1.22,0.18-1.83c-4.87-2.18-9.58-4.89-14.64-6.43
				c-8.18-2.49-16.31-6.38-25.16-4.54c-8.59,1.78-17.05,4.22-25.52,6.56c-1.2,0.33-2.11,1.72-3.15,2.62
				c1.27,0.88,2.47,2.36,3.83,2.54c5.26,0.7,10.56,1.22,15.86,1.39c13.4,0.43,26.81,0.71,40.22,0.86
				C680.55,65.3,683.34,64.51,686.13,64.1z"/>
			<path onClick={() => props.onFill(13)} fill={props.fillColors[13]}  d="M0.5,1214c6.97,0.35,13.93,0.95,20.9,0.98c10.48,0.05,20.43,1.97,29.69,7.01
				c0.58,0.32,1.23,0.74,1.84,0.73c6.01-0.04,10.64,2.01,15.37,6.22c2.35,2.09,9.07,1.24,12.69-0.55c7.67-3.79,12.08-1.63,18.28,3.8
				c7.26,6.35,16.45,11.33,27.36,8.67c16.54-4.04,32.96-3.06,49.24,1.64c5.11,1.48,9.79,0.74,14.93-1.24
				c6.08-2.33,12.89-2.74,19.37-4.03c7.47-1.49,14.3-3.63,20.95-8.27c6.16-4.31,14.68-4.46,22.56-2.47
				c19.85,5.01,39.63,7.06,60.06,2.09c9-2.19,18.84-0.79,28.28-1.35c9.05-0.54,18.08-1.52,27.11-2.29c16.02-1.35,31,1.85,45.07,9.77
				c11.16,6.29,22.58,6.4,33.71-0.12c9.77-5.73,20.61-4.44,31.12-5.21c15.88-1.17,31.82-1.63,47.38-5.41
				c3.84-0.93,7.78-2.02,11.21-3.91c6.67-3.67,13.17-6.72,21.19-5.01c3.66,0.78,7.62,0.13,12.84,0.13c-2.17,1.1-3.17,1.65-4.21,2.12
				c-13.03,5.87-26.06,11.77-39.12,17.57c-2.72,1.21-5.82,1.67-8.35,3.16c-4.18,2.47-8.81,4.84-11.88,8.42
				c-6.54,7.62-12.67,10.36-22.38,7.76c-4.64-1.24-9.2-2.88-13.68-4.65c-10.84-4.29-21.88-6.67-33.61-4.81
				c-14.28,2.27-28.54,1.5-42.67-1.13c-16.77-3.13-33.07-1.2-49.21,3.65c-13.11,3.94-26.38,7.58-40.18,5.34
				c-8.47-1.38-16.67-4.39-25.1-6.18c-5-1.06-10.27-1.97-15.28-1.5c-4.94,0.47-10.61,1.6-14.36,4.51
				c-11.77,9.12-24.65,10.59-38.25,7.02c-14.66-3.85-29.47-4.57-44.47-4.8c-9.43-0.14-18.84-1.53-28.28-1.99
				c-5.74-0.28-11.64-0.6-17.25,0.38c-11.11,1.94-21-0.33-29.31-7.64c-5.5-4.85-11.53-5.99-18.38-5.92
				c-4.26,0.04-8.55-0.54-12.79-1.08c-1.42-0.18-3.47-0.75-4-1.77c-2.95-5.75-9.49-6.04-13.88-9.57c-3.83-3.08-6.78-1.3-8.12,3.38
				c-0.86,2.99-2.41,6.72-4.81,8.15c-9.79,5.84-19.56,12.15-31.6,12.37c0-2,0-4,0-6c11.62,1.4,19.78-6.54,29.2-11.03
				c0.78-0.37,1.17-3.54,1-3.62c-1.94-0.81-4.91-2.42-5.93-1.64c-7.36,5.66-15.78,5.41-24.26,5.28C0.5,1225.33,0.5,1219.67,0.5,1214z
				M282.12,1236.63c-0.06,0.65-0.12,1.3-0.18,1.96c2.22,1.19,4.31,2.8,6.67,3.5c9.59,2.84,19.24,7.37,28.91,7.51
				c10.09,0.14,20.26-4.01,30.35-6.46c0.95-0.23,1.61-1.7,2.4-2.59c-1.15-0.82-2.25-2.27-3.45-2.36c-11.7-0.84-23.42-1.54-35.14-2.08
				c-5.59-0.26-11.2-0.17-16.8-0.08C290.63,1236.09,286.38,1236.42,282.12,1236.63z"/>
			<path onClick={() => props.onFill(14)} fill={props.fillColors[14]}  d="M971.81,98c-2.33,0.05-4.65,0.13-6.98,0.13c-277.38-0.01-554.75-0.02-832.13-0.05
				c-44.07,0-88.13-0.05-132.2-0.08c0-3,0-6,0-9c13.62,0.12,27.25,0.26,40.87,0.36c30.42,0.22,60.84,0.45,91.27,0.62
				c34.71,0.19,69.42,0.37,104.13,0.43c19.1,0.04,38.21-0.07,57.31-0.27c16.57-0.17,33.13-0.8,49.7-0.75
				c8.43,0.02,16.86,1.48,25.29,1.54c10.45,0.07,20.9-0.77,31.36-0.94c6.62-0.11,13.25,0.32,19.87,0.46
				c3.96,0.08,7.93,0.01,11.89,0.08c7.12,0.13,14.24,0.31,21.36,0.47c3.99,0.08,7.97,0.23,11.96,0.21c17.93-0.1,35.86-0.21,53.8-0.4
				c8.14-0.08,16.28-0.56,24.42-0.5c37.23,0.25,74.45,0.7,111.68,0.92c7.46,0.04,14.92-0.86,22.38-0.83
				c8.79,0.03,17.58,0.98,26.35,0.85c27.92-0.42,55.83-1.33,83.75-1.69c13.96-0.18,27.93,0.57,41.89,0.66
				c22.59,0.14,45.19,0.1,67.79,0.11c22.93,0.01,45.86,0.01,68.79-0.01c1.82,0,3.64-0.19,5.46-0.29
				C971.81,92.67,971.81,95.33,971.81,98z"/>
			<path onClick={() => props.onFill(15)} fill={props.fillColors[15]}  d="M0.5,75c17.73-8.43,36.56-10.65,55.88-9.74c5.79,0.27,11.57,1.13,17.31,2
				c19.72,2.97,37.98-2.54,55.62-10.21c13.06-5.68,26.28-8.41,40.27-5.77c4.82,0.91,9.55,2.79,14.03,4.85
				c7.85,3.61,15.65,4.63,24.22,2.79c7.99-1.72,15.91-1.62,23.48,3.29c7.9,5.14,16.07,1.41,22.33-3.26
				c9.56-7.13,18.74-6.81,29.04-2.82c10.16,3.94,20.84,5.58,31.78,5.12c7.05-0.29,8.12-1.85,7.47-8.82c-0.15-1.58,1.38-4.13,2.84-4.9
				c3.49-1.84,7.51-2.64,11.11-4.31c11.92-5.53,24.68-5.83,37.43-7.22c15.3-1.67,30.14-0.43,45.05,2.45
				c5.48,1.06,10.48-0.25,15.12-3.39c10.27-6.96,20.79-11.46,34.05-9.18c8.97,1.54,18.51-0.31,27.8-0.47
				c2.62-0.05,5.25,0.83,7.86,0.72c6.29-0.27,12.56-1.2,18.85-1.23c5.89-0.03,11.79,0.69,17.67,1.2c2.29,0.2,4.87,1.62,6.78,0.94
				c10.82-3.82,21.17,1.53,31.55,1.19c13.03-0.43,25.69,3.94,39.08,0.72c7.79-1.87,14.84-3.69,21.24-8.51
				c1.62-1.22,4.4-1.27,6.64-1.24c31.1,0.47,62.2,1.52,93.3,1.47c26.1-0.04,52.19-1.47,78.3-2.03c14.06-0.3,28.14-0.05,42.99-0.05
				c-5.9,6.02-12.64,8.56-19.84,7.87c-12.98-1.25-25.52,0.74-38.07,3.3c-21.13,4.31-41.89,1.91-62.81-2.17
				c-15.61-3.05-31.54-5.88-47.36-6.03c-13.58-0.13-27.27,3.26-40.77,5.84c-12.05,2.3-23.85,5.94-35.88,8.39
				c-14.54,2.97-29.09,2.59-43.4-1.76c-14.25-4.33-28.61-5.43-43.32-2.41c-12.91,2.65-25.84,1.31-38.63-1.21
				c-12.64-2.49-24.61-1.31-36.39,4.31c-17.84,8.51-35.66,17.22-54.05,24.39c-17.49,6.82-35.74,12.04-54.91,10.5
				c-9.23-0.74-18.31-3.28-27.54-4.12c-17.2-1.57-34.46-2.55-51.7-3.62c-2.27-0.14-4.68,0.31-6.88,0.96
				C243.24,67.22,228.11,67,213,65.87c-15.02-1.12-29.98-3.07-45-4.3c-5.26-0.43-10.62,0.08-15.92,0.39
				c-1.39,0.08-3.63,0.69-3.96,1.6c-2.18,6.08-7.71,3.85-11.79,5.15c-3.43,1.1-6.87,2.18-10.23,3.47c-4.12,1.59-8.03,4.27-12.27,4.91
				c-10.42,1.58-20.53,6.12-31.43,3.79c-7.74-1.66-15.45-3.54-23.26-4.8c-15.83-2.55-31.76-7.67-47.47,0.53
				C7.88,78.61,4.22,80.87,0.5,83C0.5,80.33,0.5,77.67,0.5,75z M374.44,39.67c-0.07-0.4-0.14-0.79-0.21-1.19
				c-10.73,1.75-21.53,3.16-32.14,5.49c-2.57,0.57-6.23,4.2-6.21,6.39c0.02,2.28,3.55,5.85,6.15,6.53
				c7.48,1.95,15.24,3.56,22.92,3.74c10.25,0.24,19.65-3.34,26.84-11.04c1.56-1.67,2.76-4.93,2.21-6.94c-0.4-1.44-3.9-2.63-6.13-2.85
				C383.44,39.37,378.92,39.67,374.44,39.67z"/>
			<path onClick={() => props.onFill(16)} fill={props.fillColors[16]}  d="M0.5,595c73.18,20.26,146.37,40.52,218.97,60.62c-2.26,2.16-4.77,4.89-7.64,7.17
				c-2.08,1.66-4.18,3.46-7.49,1.2c-1.33-0.91-4.33,0.64-7.36,1.22c-5.68-4.47-9.56-3.51-12.94,4.73c-3.67-0.68-7.45-1.86-11.27-1.98
				c-4.82-0.14-9.66,0.8-14.5,0.86c-2.83,0.04-5.68-0.71-8.52-1.13c-3.66-0.55-7.34-1.01-10.96-1.74c-2.65-0.53-5.24-1.41-7.84-2.16
				c-4.63-1.33-9.21-2.91-13.91-3.96c-3.35-0.75-6.87-1.13-10.3-1.09c-8.58,0.12-17.15,0.83-25.72,0.75c-2.38-0.02-5.23-1.47-7-3.17
				c-3.15-3.03-5.65-3.01-8.51,0.5c-9.76-6.46-20.07-9.56-31.72-6.08c-2.21-0.37-4.7-0.78-7.75-1.29c-0.8-3.64-3.15-4.1-7.2-2.58
				c-4.22,1.59-8.68,1.59-12.69-1.72c-1.31-1.08-3.72-0.82-5.63-1.17C0.5,627.67,0.5,611.33,0.5,595z"/>
			<path onClick={() => props.onFill(17)} fill={props.fillColors[17]}  d="M0.5,697c0.61,0.24,1.39,0.32,1.82,0.75c4.58,4.63,4.66,4.64,8.07-0.96c4.16,4.39,6.88,2.23,8.95-2.53
				c3.97,1.22,6,8.42,11.26,3.53c4.37,4.29,10.63,4.69,16.52,1.26c5.65,7.41,6.76,7.35,14.68,1.34c1.4-1.06,4.37-0.68,6.41-0.16
				c7.65,1.94,15.13,4.71,22.86,6.1c2.89,0.52,6.7-1.34,9.37-3.18c2.61-1.79,4.01-2.74,5.67,0.66c0.35,0.72,1.72,1.61,2.31,1.43
				c5.67-1.79,10.73,0.32,15.85,2.18c0.31,0.11,0.79,0.28,0.96,0.14c5.43-4.64,12.54-1.37,18.73-4.18
				c4.92-2.23,11.42-1.73,17.11-1.24c4.95,0.43,12.15,0.39,16.43-2.01c2,2.43,4.1,4.19,6.63,0.5c0.34-0.49,1.03-1.17,1.46-1.1
				c4.87,0.8,9.73,1.7,14.58,2.64c0.61,0.12,1.18,0.96,1.71,0.9c4.16-0.45,8.6-0.29,12.39-1.76c4.55-1.78,8.29-1.5,12.2,1.02
				c0.94,0.6,2.53,1.68,2.96,1.37c5.72-4.16,11.36,0.85,16.65-0.01c4.19-0.68,7.41,0.44,11.03,1.31c0.95,0.23,2.25,0.48,2.93,0.03
				c6.11-4.02,12.21-0.12,18.29-0.26c3.01-0.07,6.74-0.42,8.83-2.19c3.57-3.02,6.13-1.45,9.41-0.17c2.08,0.81,4.85,1.34,6.82,0.64
				c5.51-1.95,10.84-2.46,16.57-1.08c3.61,0.87,7.45,1.16,11.16,0.98c1.52-0.08,2.94-2.08,3.77-2.73c4.07,1.61,8.03,3.4,12.16,4.62
				c0.81,0.24,2.65-1.5,3.33-2.71c0.81-1.45,0.88-3.31,1.36-5.33c3.02,1.98,5.36,4.59,7.81,4.71c1.51,0.07,3.19-3.68,5.02-6.01
				c0.52,0.55,1.72,2.35,3.38,3.42c1.31,0.85,3.52,1.57,4.77,1.03c3.72-1.6,5.14-0.2,6.1,3.21c1.06,3.81,3.48,4.88,7.26,3.25
				c5.19-2.24,10.63-4.06,10.83-11.66c4.02,5.8,7.49,10.79,14.74,10.01c0.59-0.06,1.51,0.2,1.81,0.63c3.1,4.48,7.43,2.84,10.79,1.34
				c3.02-1.35,4.6-0.25,6.57,1.2c2.34,1.73,4.36,3.08,7.63,1.17c1.44-0.84,4.23,0.64,6.5,1.08c-1.94,3.14-3.04,5.52-4.69,7.42
				c-1.33,1.53-3.34,2.48-5.02,3.71c-3.13,2.28-6.19,4.66-9.39,6.83c-1.25,0.85-2.81,1.74-4.23,1.75
				c-14.14,0.11-28.28,0.07-42.42-0.02c-1.25-0.01-3.04-0.22-3.65-1.03c-4.87-6.55-11.71-3.75-17.9-4.14
				c-2.15-0.13-4.28-0.65-6.4-1.07c-1.43-0.28-2.81-0.87-4.24-1.02c-2.72-0.29-5.55,0.01-8.18-0.61c-4.27-1.01-8.34-3.26-12.71-0.11
				c-0.55,0.4-2.84-0.37-3.14-1.1c-1.69-4.05-5.13-4.59-8.27-3.41c-4.41,1.67-8.39,1.06-12.23-0.81c-6.88-3.35-14.19-3.48-21.54-3.36
				c-1.17,0.02-2.79,1.14-3.39,2.2c-1.89,3.37-3.73,3.38-7.25,1.83c-9.63-4.26-19.63-5.88-29.77-1.12c-1.06,0.5-2.52,0.17-3.79,0.15
				c-4.67-0.04-9.33-0.08-14-0.2c-0.95-0.03-2.18-0.12-2.8-0.7c-4.35-3.99-8.34-3.03-12.33,0.52c-0.45,0.4-1.23,0.44-1.67-0.66
				c-3.33-0.64-6.65-1.73-9.99-1.8c-3.23-0.07-7.39-1.69-9.21,3.2c-0.11,0.3-3.52,0.18-4.14-0.66c-4.92-6.69-7.12-7.16-14.68-4.85
				c-6.89,2.1-14.07,3.25-21.14,4.69c-1.06,0.22-2.4-0.05-3.4-0.53c-3.26-1.59-6.02-1.45-9.27,0.58c-2.53,1.58-5.7,2.3-8.55-0.83
				c-0.87-0.96-3.9-0.61-5.6,0.03c-2.22,0.84-3.76,0.83-6.23,0.24c-3.27-0.78-7.15,1.15-10.79,1.76c-4.61,0.77-9.05,3.26-13.95,1.39
				c-0.66-0.25-2.06-0.09-2.32,0.35c-2.3,3.86-6.02,1.37-8.98,2.15c-0.56,0.15-1.52,0.17-1.79-0.17c-5.9-7.24-12.08,1.59-18.1-0.24
				c-0.41-0.13-1.4,1.66-2.38,2.87c-2.72-5.69-6.69-6.02-11.62-2.91c-0.89,0.56-2.45,0.02-3.7,0.04c-2.09,0.03-4.18,0.1-6.26,0.17
				c-1.67,0.05-3.46-0.25-5,0.23c-5.69,1.78-10.77-2.14-16.23-1.52c-2.41,0.27-4.68,1.82-7.08,2.07c-1.61,0.17-3.34-0.94-5.02-1.47
				C0.5,712.33,0.5,704.67,0.5,697z"/>
			<path onClick={() => props.onFill(18)} fill={props.fillColors[18]}  d="M0.5,1137c1.04-1,3.16-2.43,2.96-2.95c-2.25-5.78,3.11-9.91,3.09-15.15c0-0.94,1.03-1.88,1.48-2.63
				c0.66,2.39,1.6,4.57,1.83,6.83c0.57,5.56,1.77,10.89,1.28,16.79c-0.42,5.06,0.89,12.06,7.28,15.08c6.81-5,3.26-12.78,5.29-19.04
				c1.88-5.81,3.33-11.75,4.98-17.63c0.52-0.05,1.04-0.11,1.56-0.16c2.78,10.21,5.56,20.41,8.43,30.97
				c6.42-1.82,2.58-10.74,8.71-12.54c5.78,2.82,4.15,9.33,6.24,14.15c0.38,0.88,0.07,2.15,0.59,2.87c1.15,1.62,2.59,4.2,3.98,4.25
				c2.93,0.1,3.46-2.29,2.65-5.02c-0.2-0.68,0.67-1.67,1.2-2.88c1.29,3.16,2.39,5.87,3.49,8.57c-0.42,0.08-0.83,0.16-1.25,0.24
				c0.27,2.33,0.3,4.72,0.86,6.98c0.83,3.35,2.01,6.62,3.07,9.91c0.54,1.68,1.13,3.33,2.38,5.06c1.98-3.74,6.76-6.01,4.61-11.4
				c-0.33-0.83,0.33-2.07,0.56-3.11c0.46-2.06,1.36-4.13,1.31-6.18c-0.05-2.18-1.83-4.41,1.77-5.63c0.58-0.2,0.47-3.32,0.21-5.02
				c-0.76-4.88,0.29-6.02,6.15-5.82c0,5.52-0.15,11.06,0.13,16.58c0.05,1.03,2,2.92,2.76,2.78c1.95-0.38,4.61-1.21,5.41-2.7
				c2.81-5.25,1.46-11.43,2.96-17.04c0.27-1.02,1.41-1.8,2.14-2.7c0.83,0.79,2.05,1.43,2.41,2.39c0.8,2.16,1.18,4.49,1.72,6.75
				c0.61,0.04,1.22,0.08,1.83,0.13c2.28-5.81,4.55-11.62,6.83-17.43c0.39-0.01,0.78-0.02,1.17-0.02c0.5,4.42,0.82,8.88,1.57,13.26
				c0.53,3.15,1.49,6.25,2.57,9.27c0.24,0.67,2.45,1.42,2.6,1.22c1.45-1.84,3.29-3.73,3.79-5.89c0.87-3.73,0.86-7.66,2.34-11.43
				c0,4.26,0,8.53,0,12.79c0,0.33-0.19,0.71-0.08,0.98c2.45,6.16,4.95,12.3,7.38,18.47c1,2.54,2.3,5.07,2.71,7.72
				c0.59,3.85,0.24,7.84,0.78,11.7c0.32,2.29,1.56,4.46,2.74,7.63c-46.53,0-92.5,0-138.47,0C0.5,1177.67,0.5,1157.33,0.5,1137z"/>
			<path onClick={() => props.onFill(19)} fill={props.fillColors[19]}  d="M971.81,600c-1.49-2.89-3.31-3.25-5.98-1.21c-2.34,1.79-5.02,3.14-7.68,4.76
				c-5.31-4.97-6.02-4.85-8.34,0.74c-3.32,1.03-5.85,1.82-9.04,2.81c-4.54-4.62-9.92-2.28-15.11,1.02c-5.02-5-6.47-4.4-9.96,4.13
				c-3.89-4.43-7.83-2.95-12.41-0.95c-2.09,0.91-5.34-0.8-8.06-1.34c-5.43-1.08-10.83-2.58-16.31-3.11
				c-4.22-0.4-8.64,1.04-12.83,0.52c-3.59-0.45-6.99-2.51-10.46-3.91c-1.21-0.49-2.51-1.83-3.49-1.61
				c-5.32,1.23-9.96-0.72-14.71-2.51c-0.85-0.32-2.25-0.36-2.88,0.14c-4.05,3.17-8.03,1.08-12.1,0.22c-1.55-0.33-3.67-0.62-4.79,0.15
				c-4.17,2.88-8.49,2.39-13.19,2.16c-4.74-0.23-9.65,0.8-14.31,1.96c-10,2.5-19.27,0.21-28.95-2.34
				c-6.38-1.68-13.56-0.34-20.38-0.33c-0.31,0-0.67,0.36-0.92,0.28c-5.63-1.78-11.25-3.59-16.86-5.41c-2.15-0.69-4.25-1.73-6.46-2.04
				c-3.71-0.53-7.48-0.6-11.22-2.03c13.89-1.32,27.77-3.15,41.69-3.69c5.5-0.21,10.75,0.6,15.37-3.14c0.48-0.39,1.24-0.58,1.88-0.62
				c6.51-0.41,13.02-0.77,19.53-1.16c1.49-0.09,3.02-0.02,4.46-0.35c7.82-1.84,15.56-4.13,23.46-5.57c4.12-0.75,8.51-0.26,12.76,0
				c4.64,0.29,8.49-0.89,12.63-3.29c5.5-3.17,11.88-4.8,17.82-7.21c2.5-1.02,4.86-2.36,7.29-3.56c0.91,2.39,1.6,4.89,2.82,7.11
				c0.48,0.88,2.08,1.5,3.22,1.59c4.28,0.36,8.59,0.66,12.87,0.5c1.1-0.04,2.37-1.7,3.14-2.89c1.04-1.59,1.67-3.44,2.52-5.25
				c7.44,3.92,14.64,5.71,22.6,5.6c4.79-0.06,9.7,1.73,14.39,3.2c6.2,1.93,12.17,4.68,18.43,6.36c1.59,0.43,4.29-1.46,5.75-2.99
				c1.19-1.24,1.28-3.53,1.71-4.88c7.76,2.86,14.93,5.5,22.1,8.14C971.81,586.67,971.81,593.33,971.81,600z"/>
			<path onClick={() => props.onFill(20)} fill={props.fillColors[20]}  d="M971.81,256c-3.73-1.4-7.46-2.82-11.2-4.2c-3.37-1.24-4.76-0.17-3.59,4.03
				c-9.84-3.3-18.96-6.75-27.48-12.17c-6.03-3.83-12.96-6.89-19.9-8.49c-15.9-3.68-30.7-9.25-44.21-18.66
				c-6.04-4.21-13.15-7.04-20.04-9.82c-2.49-1-5.77-0.05-8.69,0.07c-3,0.12-6.35,1.29-8.93,0.33c-15.84-5.91-31.47-12.4-47.26-18.45
				c-5.88-2.25-12.02-3.81-18.02-5.73c-3.95-1.27-7.89-2.6-11.82-3.94c-3.38-1.16-4.89-3.7-6.51-6.89
				c-3.27-6.46-7.39-12.52-11.55-18.48c-3.27-4.68-7.14-8.94-9.98-14.24c17.6,6.52,35.2,13.04,52.8,19.55
				c-0.05,0.27-0.1,0.54-0.15,0.8c-1.24,0-2.54-0.22-3.72,0.05c-1.58,0.36-3.08,1.09-4.61,1.67c1.14,1.05,2.1,2.46,3.43,3.09
				c21.81,10.24,43.65,20.4,65.51,30.53c1.53,0.71,3.23,1.05,5.81,1.87c-2.33-9.33-10.44-11.71-15.64-16.75
				c0.25-0.38,0.51-0.76,0.76-1.15c10.86,5.95,21.83,11.73,32.57,17.9c14.55,8.36,28.93,17.04,43.4,25.55c2,1.18,4.04,2.48,6.24,3.09
				c2.49,0.69,5.18,0.66,7.79,0.94c-0.85-2.39-1.34-5.02-2.67-7.1c-1.3-2.04-3.23-3.91-5.29-5.19c-9.17-5.7-18.37-11.36-27.78-16.63
				c-4.62-2.59-8.6-5.31-11.04-10.31c-1.11-2.28-3.63-4.21-5.97-5.47c-7.16-3.86-14.68-7.08-21.74-11.11c-1.76-1-2.29-4.16-3.72-6.97
				c13.17,0.62,24.81,2.82,34.18,11.75c0.69,0.66,1.54,1.34,2.43,1.58c8.38,2.2,17,3.7,25.1,6.66c4.96,1.81,9.71,5.2,13.53,8.94
				c10.11,9.87,19.12,20.92,29.59,30.35c8.7,7.84,18.86,14.06,28.37,21C971.81,248,971.81,252,971.81,256z"/>
			<path onClick={() => props.onFill(21)} fill={props.fillColors[21]}  d="M971.81,608c-0.08,6.59-8.1,7.26-9.38,13.64c-5.49-5.99-7.46-0.35-10.56,2.28
				c-2.24,1.9-4.88,3.37-7.49,4.76c-0.6,0.32-1.96-0.24-2.67-0.8c-4.42-3.49-4.7-3.44-6.53,1.52c-2.33,0.65-4.45,1.25-6.85,1.93
				c-1.47-1.51-3.31-3.41-5.16-5.31c-1.64,2.16-3.09,4.52-4.99,6.42c-2.12,2.12-3.67,5.7-8,3.83c-0.62-0.27-2.28,1.9-3.46,2.95
				c-1.87,1.65-3.68,3.39-5.63,4.95c-3.68,2.96-6.89,1.61-10.62-0.52c-1.7-0.97-4.79,0.43-7.24,0.82c-1.31,0.21-3.25,1.26-3.79,0.75
				c-4.26-4.08-7.38-0.68-10.4,1.55c-3.15,2.33-5.88,5.24-7.88,7.06c-3.5,0-6.05,0-9.46,0c-3.69-3.01-8.4-6.14-14.69-2.82
				c-0.43,0.23-0.93,0.5-1.39,0.49c-7.69-0.07-8.46,0.41-11.25,7.17c-0.36-0.33-0.72-0.67-1.08-1c-2.01,1.93-4.34,3.63-5.98,5.84
				c-4.69,6.3-9.21,12.74-13.57,19.27c-1.61,2.41-2.61,5.24-3.47,7.02c-3.53,2.12-6.54,3.92-9.55,5.73
				c-0.57,0.34-1.09,0.96-1.68,1.02c-4.3,0.45-8.65,0.58-12.92,1.22c-2.89,0.44-4.07-1.54-6.1-2.73c-6.09-3.57-12.67-6.43-19.24-9.01
				c-3.08-1.21-7.02-0.21-10.55-0.21c3.77-2.43,4.39-9.74,12-8.42c0.83,0.14,2.9-2.91,3.25-4.7c1.11-5.64,3.68-8.54,9.31-9.44
				c0.33-0.05,0.76,0.05,0.97-0.12c3.86-3.06,7.86-5.98,11.49-9.29c4.42-4.03,8.48-8.45,12.81-12.59c0.63-0.6,1.91-0.79,2.85-0.72
				c5.9,0.42,11.79,0.95,17.68,1.47c6.12,0.54,7.18,0.01,9.42-4.83c4.17,0.49,8.34,1.13,12.52,1.44c3.74,0.28,6.94-0.88,8.49-4.7
				c1.24-3.06,3.05-3.73,5.68-1.59c5.59,4.55,9.35,3.19,11.2-5.08c7.69,0,15.66,0.05,23.63-0.05c1.21-0.01,3.09-0.35,3.5-1.15
				c1.98-3.76,5.1-3.07,8.18-2.49c4.92,0.92,9.81-1.91,11.92-7.08c1.43-3.51,4.16-4.26,6.89-1.9c4.69,4.05,6.55,3.45,8.26-2.64
				c0.13-0.46,0.42-0.88,0.85-1.77c3.85,4.39,7.37,0.99,10.6-0.68c2.96-1.54,4.64-0.54,6.35,1.43c3.6,4.16,4.65,4.11,8.06-0.63
				c4.62,2.15,4.62,2.15,8.41-2.36c0.17,0.44,0.33,0.87,0.5,1.31c2.78-0.98,5.85-1.51,8.28-3.04c3-1.89,5.6-1.2,8.43-0.19
				C971.81,606.67,971.81,607.33,971.81,608z"/>
			<path onClick={() => props.onFill(22)} fill={props.fillColors[22]}  d="M0.5,828c14.06-3.71,28.27-5.78,42.89-4.88c3.92,0.24,7.91-0.79,11.86-1.23
				c8.54-0.97,17.08-1.96,25.62-2.89c5.9-0.65,11.8-1.42,17.73-1.77c12.74-0.76,25.48-1.45,38.23-1.86
				c17.93-0.58,35.88-0.89,53.82-1.33c0.82-0.02,1.93,0.08,2.41-0.38c5.21-5.03,11.87-3.21,17.96-3.9c8.75-0.98,17.5-1.94,26.28-2.5
				c1.98-0.13,4.08,1.39,6.1,2.2c2.09,0.83,4.11,1.89,6.26,2.5c1.95,0.56,4.03,0.65,6.05,0.95c0,0.47,0,0.93,0,1.4
				c-1.29,0.15-2.58,0.38-3.87,0.44c-15.28,0.7-30.56,1.26-45.83,2.11c-4.29,0.24-8.57,1.07-12.77,2.03
				c-1.53,0.35-2.79,1.86-4.18,2.84c1.46,0.84,2.87,2.25,4.4,2.41c5.95,0.59,11.94,0.79,17.91,1.13c0.91,0.05,1.82,0.1,2.76,1.02
				c-3.22,0.16-6.52-0.04-9.64,0.62c-1.99,0.42-3.71,2.13-5.55,3.26c1.73,1.27,3.3,3.28,5.21,3.67c4.36,0.9,8.89,1.39,13.35,1.38
				c17.96-0.04,35.93-0.25,53.89-0.61c3.43-0.07,4.36,1.5,4.66,4.67c-3.84,0.8-6,2.88-5.47,7.2c0.09,0.72-1.78,2.36-2.83,2.44
				c-6.79,0.52-13.61,0.77-20.42,0.99c-0.88,0.03-2.37-0.41-2.58-0.99c-1.77-4.87-5.71-3.71-9.24-3.45
				c-0.97,0.07-2.63,0.92-2.69,1.53c-0.41,4.43-3.67,3.98-6.6,4.2c-1.99,0.15-4,0.18-5.96,0.51c-9.83,1.67-17.78-0.56-23.28-9.74
				c-1.1-1.83-4.64-2.95-7.13-3.07c-12.23-0.61-24.55,1.53-36.72-1.92c-4.05-1.15-7.5-2.45-9.5-6.32c-2.03-3.94-5.05-5.18-9.47-4.39
				c-3.77,0.68-7.4,1-11.29-1.62c-2.55-1.72-7.25-2.6-10.05,1.73c-0.56,0.86-2.17,1.36-3.33,1.45c-14.26,1.04-28.51,2.21-42.79,2.79
				c-4.42,0.18-8.89-1.5-13.37-1.97c-4.32-0.46-9.1-1.11-8.31,5.96c0.08,0.71-0.82,1.99-1.55,2.26c-7.64,2.79-11.63,9.73-17.11,14.95
				c-2.88,2.75-5.38,5.9-8.1,8.83c-4.43,4.78-4.26,5.44,1.97,7.05c-0.02,0.48-0.05,0.95-0.07,1.43c-3.25-0.16-6.54-0.08-9.75-0.56
				c-3.27-0.49-2.02-3.38-2.55-5.49c-1.56-6.29-3.58-12.47-5.54-18.65c-0.19-0.6-1.2-0.93-1.83-1.39C0.5,838,0.5,833,0.5,828z"/>
			<path onClick={() => props.onFill(23)} fill={props.fillColors[23]}  d="M971.81,1267c-3.57-0.2-7.17-0.19-10.71-0.63c-9.72-1.2-19.41-2.72-29.14-3.83
				c-3.75-0.43-7.62-0.39-11.37-0.01c-11.31,1.16-20.62-2.04-27.43-11.55c-5.93-8.29-19.72-12.52-29.07-8.46
				c-6.36,2.76-12.2,6.7-18.47,9.7c-3.48,1.67-7.33,2.61-11.07,3.67c-1.73,0.49-3.7,0.17-5.41,0.7c-14.64,4.55-29.56,3.01-44.52,2.7
				c-12.4-0.26-24.84,0.87-37.25,1.56c-7.07,0.39-14.12,1.19-21.19,1.69c-1.12,0.08-2.34-0.23-3.4-0.66
				c-5.22-2.14-10.26-4.91-15.65-6.44c-5.29-1.51-10.92-2.28-16.43-2.4c-1.86-0.04-3.78,2.89-5.67,4.46
				c1.38,1.63,2.76,3.27,4.21,4.99c-0.65,0.15-2.18,1.02-3.35,0.68c-9.96-2.88-17.77,4.21-26.74,5.87c-3.38,0.63-6.64,4.32-9.03,7.35
				c-3.18,4.03-7.28,5.04-11.8,5.32c-4.81,0.29-9.64,0.28-14.46,0.33c-22.95,0.22-45.9,0.47-68.85,0.6c-4.8,0.03-9.61-0.4-14.49-1.07
				c6.25-3.51,12.09-6.34,20.07-5.98c13.68,0.62,27.46-1.15,41.21-1.78c3.6-0.17,7.23,0.19,10.85,0.24c1.81,0.03,3.71,0.28,5.4-0.2
				c11.48-3.23,22.92-6.61,34.36-9.98c2.07-0.61,4.12-1.29,6.13-2.06c4.77-1.81,7.4-4.65,5.85-10.34c-0.35-1.28,1.35-3.97,2.79-4.75
				c5.79-3.12,12.12-3.37,18.42-1.86c3.54,0.84,7.03,2.05,10.36,3.53c6.6,2.93,12.4,2.15,18.17-2.29c3.1-2.38,6.8-4.46,10.55-5.35
				c3.33-0.8,7.85-1,10.52,0.67c8.3,5.18,16.92,5.74,25.91,3.67c7.84-1.8,15.14-0.9,22.27,2.58c17.95,8.75,35.93,7.82,53.91,0.05
				c16.61-7.17,33.58-12.79,52.03-11.44c6.29,0.46,12.52,1.59,18.81,2.09c18.75,1.49,37.15,0.27,54.7-7.37
				c2.88-1.25,5.97-2.01,8.96-2.99c0,2,0,4,0,6c-2.06,0.51-4.28,0.68-6.17,1.57c-19.53,9.12-40.22,9.04-61.07,8.04
				c-2.05-0.1-4.15,1.03-6.23,1.59c0.91,1.95,1.33,4.42,2.8,5.75c7.77,7.02,17.08,9.87,27.5,9.51c5.63-0.19,11.58-1.2,16.85,0.19
				c8.76,2.31,17.27,4.49,26.32,2.36C971.81,1264.33,971.81,1265.67,971.81,1267z M729.24,1256.93c0.04-0.19,0.09-0.39,0.13-0.58
				c1.16,0.13,2.39,0.57,3.45,0.32c1.94-0.46,3.79-1.34,5.68-2.04c-0.72-1.91-0.97-4.23-2.24-5.64c-4.31-4.81-12.43-5.64-17.89-2.22
				c-3.6,2.25-3.61,5.9,0.39,7.6C722.03,1255.76,725.73,1256.12,729.24,1256.93z"/>
			<path onClick={() => props.onFill(24)} fill={props.fillColors[24]}  d="M971.81,264c-16.08-1.6-30.94-6.6-45.36-14.07c-10.08-5.22-21.3-8.27-32.14-11.9
				c-1.76-0.59-4.18,0.83-6.3,1.33c1.01,1.77,1.57,4.33,3.09,5.2c9.47,5.43,18.91,11.04,28.87,15.45c5.52,2.44,9.58,5.22,12.35,10.67
				c1.54,3.02,4.74,5.18,7.18,7.74c1.09,1.14,2.93,2.17,3.14,3.46c1.04,6.46,5.59,9.62,10.79,12.14c4.27,2.07,8.71,3.78,14.39,6.22
				c-3.05,0.84-4.88,1.25-6.65,1.87c-2.03,0.71-4.05,1.5-5.99,2.42c-3.22,1.53-6.85,5.07-9.47,4.42c-4.89-1.22-9.19-4.84-13.38-8.33
				c1.9,0,3.85,0.29,5.67-0.09c1.65-0.34,3.96-1.04,4.5-2.24c0.52-1.16-0.66-3.27-1.46-4.77c-0.51-0.96-1.59-1.73-2.57-2.31
				c-8.58-5.06-17.14-10.18-25.87-14.96c-1.91-1.05-4.56-1.36-6.79-1.14c-12.21,1.2-23.03-3.26-33.23-8.85
				c-7.37-4.04-14.98-6.15-23.07-7.32c-0.65-0.1-1.31-0.23-1.93-0.45c-0.25-0.09-0.42-0.45-0.43-2.07c7.55,1,15.1,1.96,22.63,3.03
				c0.75,0.11,1.64,0.64,2.09,1.25c4.2,5.65,10.76,4.88,16.51,6.7c8.28,2.62,16.63,0.02,24.82,1.91c2.42,0.56,5.39-1.22,8.1-1.92
				c-1.98-1.4-3.79-3.19-5.97-4.13c-8.64-3.73-17.2-7.83-26.16-10.56c-8.81-2.68-18.25-3.28-27.1-5.84
				c-9.97-2.89-20.96-1.81-30.13-7.76c-0.81-0.53-1.91-1.02-2.82-0.92c-9.08,0.99-15.94-4.96-23.69-7.89
				c-4.08-1.54-7.17-5.65-10.78-8.5c-2.05-1.62-4.24-3.07-5.93-5.73c10.78,2.85,21.42,6.64,32.38,8.32
				c17.79,2.73,33.44,11.55,50.5,16.15c2.21,0.6,4.6,0.77,6.9,0.71c1.09-0.03,2.16-1.08,3.24-1.67c-0.68-1.05-1.24-2.23-2.1-3.1
				c-0.67-0.68-1.67-1.11-2.61-1.42c-13.19-4.46-26.39-8.88-39.6-13.3c-25.6-8.58-51.92-15.31-75.6-28.86
				c-4.04-2.31-7.19-6.19-10.76-9.35c0.26-0.48,0.51-0.95,0.77-1.43c10.36,3.71,20.76,7.34,31.08,11.16
				c15.57,5.76,31.07,11.73,46.67,17.41c1.7,0.62,4.17,0.57,5.74-0.22c4.83-2.44,9.45-1.73,13.91,0.39
				c4.94,2.35,10.06,4.63,14.42,7.86c13.96,10.37,29.78,16,46.56,19.36c7.15,1.43,13.08,5.11,19.36,8.64
				c9.03,5.08,19.07,8.36,28.7,12.36c0.58,0.24,1.65,0.42,1.93,0.11c3.53-3.86,7.65-1.21,11.53-1.16
				C971.81,260,971.81,262,971.81,264z"/>
			<path onClick={() => props.onFill(25)} fill={props.fillColors[25]}  d="M971.81,540c-8.72-1.7-16.48-5.59-23.96-10.26c-2.52-1.57-1.95-3.1-1.28-5.4
				c1.46-5.01,2.59-10.14,3.47-15.28c0.26-1.49-0.78-3.21-1.62-6.25c-4.13,6.34-7.44,11.44-11.44,17.59
				c-0.92-2.44-1.37-3.43-1.66-4.46c-1.12-3.91-3.7-4.26-6.39-2.04c-4.82,3.97-7.42-1.26-10.75-2.53c-3.24-1.23-3.13-4.28-2.13-7.88
				c2.27-8.15,5.86-14.89,11.79-21.29c4.14-4.47,5.19-11.99,7.12-18.3c3.93-12.84,7.43-25.81,11.29-38.67
				c1.94-6.45,5.15-8.53,11.97-7.76c4.56,0.52,9.06,1.66,13.58,2.52c0,12.33,0,24.67,0,37c-5.72-4.41-11.39-8.9-17.23-13.15
				c-0.94-0.69-2.67-0.29-4.03-0.39c0.03,1.38-0.49,3.2,0.2,4.05c2.24,2.8,4.93,5.23,7.42,7.83c1.09,1.14,3.21,2.62,2.99,3.45
				c-1.32,4.97,2.4,5.9,5.64,7.56c-1.08,4.31,1.48,6.32,5.02,7.65C971.81,496,971.81,518,971.81,540z"/>
			<path onClick={() => props.onFill(26)} fill={props.fillColors[26]}  d="M0.5,35c11.54,1.22,23.08,2.54,34.63,3.59c4.27,0.39,8.66,0.6,12.9,0.11C59.27,37.4,68.15,40.94,75,50.06
				c4.76,6.34,19.76,14,29.53,8.59c6.09-3.37,12.13-6.86,18.38-9.91c3.33-1.62,6.99-2.67,10.61-3.52c5.31-1.24,10.7-2.8,16.08-2.94
				c15.26-0.38,30.54,0.12,45.81-0.17c8.6-0.17,17.19-1.26,25.78-1.87c7.08-0.5,14.16-0.94,21.24-1.33c1.14-0.06,2.4,0.04,3.44,0.46
				c5.24,2.15,10.3,4.79,15.65,6.55c4.48,1.47,9.27,2.09,13.95,2.85c4.31,0.7,8.76,0.7,12.97,1.72c4.17,1.01,8.04,3.19,12.18,4.35
				c4.89,1.37,7.36-0.88,6.05-5.61c-1.12-4.04,0.36-6.19,3.9-7.18c8.46-2.37,17.38-3.61,25.39-7.02c12.1-5.15,24.8-4.17,37.25-5.8
				c12.91-1.7,25.77-2.25,38.76-0.65c3.03,0.37,6.74-0.79,9.44-2.41c9.2-5.49,18.82-6.61,29.45-6.21
				c16.59,0.63,33.24-0.44,49.86-0.79c0.98-0.02,1.96,0,2.93,0c0.1,0.55,0.19,1.09,0.29,1.64c-5.51,0.96-10.99,2.51-16.52,2.72
				c-7.77,0.29-15.69,0.31-23.34-0.89c-11.93-1.86-21.55,3.01-30.71,9.18c-5.58,3.76-11.16,4.65-17.48,3.41
				c-14.57-2.88-29.13-4.73-44.01-1.7c-4.6,0.94-9.57-0.01-14.38-0.04c-1.46-0.01-3.02-0.14-4.36,0.32
				c-8.62,2.98-17.2,6.07-25.76,9.23c-2.01,0.74-4.35,1.47-5.68,2.97c-1.48,1.66-2.84,4.23-2.75,6.34c0.22,5.38-0.11,6.79-5.57,6.15
				c-10.16-1.19-20.23-3.28-30.3-5.18c-2.91-0.55-5.69-1.81-8.57-2.61c-6.88-1.91-12.96-0.11-18.94,3.59
				c-4.32,2.68-9.04,4.99-13.88,6.45c-2.69,0.81-6.64,0.52-8.91-0.97c-8.37-5.49-17.2-5.6-26.38-3.6
				c-7.44,1.62-14.51,0.89-21.33-2.52c-18.1-9.04-36.22-7.96-54.4-0.15c-16.93,7.28-34.26,12.88-53.07,11.22
				c-5.46-0.48-10.87-1.46-16.32-1.95C40.34,60.93,19.9,63.07,0.5,72c0-2,0-4,0-6c5.64-1.91,11.16-4.35,16.93-5.64
				c15.35-3.43,30.9-4.17,46.59-2.63c1.87,0.18,3.88-1.04,5.83-1.6c-0.75-1.86-1.26-3.88-2.32-5.54c-0.76-1.2-2.2-2-3.42-2.86
				c-9.05-6.35-19.18-7.52-29.95-6.91c-5.5,0.31-11.19-0.57-16.62-1.7C11.85,37.92,6.37,35.66,0.5,38C0.5,37,0.5,36,0.5,35z
				M238.44,44.1c-0.05,0.17-0.09,0.34-0.14,0.51c-1,0-2.05-0.21-2.98,0.04c-1.93,0.53-3.79,1.3-5.68,1.97
				c0.87,2.04,1.28,4.51,2.71,6.03c3.98,4.23,11.94,4.9,17.16,1.91c3.97-2.28,4.13-6.02-0.1-7.83C246,45.27,242.12,44.94,238.44,44.1
				z"/>
			<path onClick={() => props.onFill(27)} fill={props.fillColors[27]}  d="M909.68,744.15c-1.2-3.6-2.28-7.24-3.64-10.78c-0.73-1.91-1.91-3.65-3.36-6.36
				c-6.9,9.59-5.29,20.36-8.18,29.13c-0.9-3.41-1.72-7.61-3.17-11.58c-1.09-2.96-2.81-6.08-6.84-2.38c-1.42-3.17-2.69-6.01-4.27-9.52
				c-4.64,5.35-3.71,11.85-5.82,17.89c-1.33-4.82-2.44-9.21-3.78-13.53c-0.5-1.62-1.54-3.07-2.33-4.6c-1.36,1-2.71,2-4.22,3.12
				c-1.5-1.8-3.12-3.74-4.59-5.51c2.02-3.22,3.95-6.42,6.05-9.51c0.36-0.52,1.39-0.65,2.13-0.84c1.77-0.45,3.57-0.73,5.32-1.24
				c5.56-1.61,11.1-3.28,15.45-4.58c2.26,4.28,4.2,7.93,6.14,11.59c0.64-0.14,1.27-0.28,1.91-0.42c0.77-1.87,1.54-3.75,2.5-6.08
				c2.88,3.71,5.12,2.03,7.38-0.82c5.83-7.37,11.76-14.66,17.66-21.98c0.39-0.48,0.93-1.4,1.2-1.33c5.46,1.41,5.32-3.88,7.5-6.3
				c2.1-2.33,3.95-4.87,5.7-7.05c2.5,2.67,4.38,5.96,5.6,5.73c2.72-0.51,5.25-2.6,7.6-4.38c3.22-2.44,4.81-8.05,10.74-4.86
				c0.63,0.34,2.95-1.29,3.65-2.49c2.15-3.7,3.9-7.64,5.81-11.49c0,20.33,0,40.67,0,61c-1.96,5.07-3.92,10.13-6.33,16.34
				c-1.8-2.49-3.82-4.4-4.76-6.75c-1.59-3.97-2.36-8.26-3.76-12.32c-0.42-1.22-1.75-2.11-2.67-3.16c-0.89,0.88-1.98,1.64-2.64,2.68
				c-1.64,2.59-3.09,5.3-4.71,8.14c-0.98-1.92-1.8-3.54-3.33-6.57c-2.05,4.93-3.52,8.47-5.17,12.46c-1.93-3.56-3.68-6.78-6.21-11.43
				c-3.15,5.83-5.66,10.48-8.76,16.22c-0.89-2.85-1.54-4.89-2.15-6.95c-0.51-1.71-0.81-3.5-1.49-5.13c-0.32-0.76-1.35-1.74-2.01-1.7
				c-0.69,0.04-1.62,1.05-1.94,1.84c-2,5.02-3.87,10.09-5.77,15.15C909.96,743.94,909.82,744.05,909.68,744.15z"/>
			<path onClick={() => props.onFill(28)} fill={props.fillColors[28]}  d="M0.5,769c1.49-0.03,3.09,0.27,4.45-0.17c3.51-1.14,6.79-1.91,10.55-0.41c1.76,0.7,4.53,0.27,6.28-0.68
				c3.8-2.08,7.17-1.66,11.28-0.76c5.56,1.22,11.28-3.32,17.15-0.56c3.23-6,7.92-2.76,12.46-2.37c2.7,0.23,5.57-1.6,8.39-2.44
				c1.68-0.51,3.67-1.69,5.05-1.21c6.43,2.26,12.71,0.25,19.06,0.07c2.12-0.06,4.24,1.47,6.41,1.63c8.74,0.61,17.52,0.67,26.22,1.54
				c5.05,0.51,9.94,2.38,14.97,3.27c2.5,0.44,5.23,0.44,7.73-0.03c4.5-0.84,12.04,3.52,14.08,7.56c1.54,3.03,3.89,6.41,6.76,7.84
				c5.34,2.67,7.65,7.01,9.58,12.06c1.13,2.96,1.63,6.19,6.48,6.16c1.51-0.01,3.05,4.82,4.57,7.43c-1.81,0.67-3.6,1.82-5.45,1.92
				c-13.59,0.73-27.19,1.25-40.79,1.83c-0.93,0.04-1.87,0.01-1.51,0.01c2.23-3.03,4.56-6.49,7.21-9.67c1.39-1.67,3.35-2.86,5.89-4.96
				c-0.76-3.04-0.29-8.23-6.5-9.81c-0.97-0.25-2.3-1.92-2.25-2.86c0.27-4.83-3.82-5.61-6.56-5.96c-1.65-0.21-4.14,2.81-5.54,4.86
				c-1.34,1.96-1.58,3.85-4.99,4.06c-1.99,0.12-3.94,4-5.54,6.43c-1.14,1.72-1.67,3.85-3,7.06c0.28-5.57-3.77-11.05-7.96-12.07
				c-4.83-1.17-9.52-1.96-10.29-8.52c-0.4-3.44-6.14-5.02-9.98-3.97c-1.67,0.46-3.68-0.54-5.51-0.42c-1.95,0.13-3.87,0.79-5.8,1.23
				c-0.04,0.71-0.09,1.42-0.13,2.13c-2.93-1.93-5.7-4.2-8.84-5.7c-2.47-1.18-4.92-4.76-8.31-1.19c-0.44,0.46-2.31,0.43-2.69-0.05
				c-3.42-4.25-6.79-1.03-10.25-0.23c-3.52,0.81-7.13,1.22-10.69,1.87c-1.1,0.2-2.33,0.37-3.2,0.98c-3.45,2.44-7.05,4.76-10.09,7.66
				c-4.14,3.94-9.1,2.88-12.5,0.74c-5.59-3.52-8.94-0.3-12.94,2.1c-0.4,0.24-0.77,0.55-1.26,0.91c-0.72-1.17-1.37-2.24-2.03-3.31
				C0.5,778.33,0.5,773.67,0.5,769z"/>
			<path onClick={() => props.onFill(29)} fill={props.fillColors[29]}  d="M0.5,726c2.73,0.33,5.46,0.91,8.18,0.89c1.25-0.01,2.43-1.36,3.74-1.83c1.46-0.53,3.57-1.57,4.45-0.97
				c6.24,4.19,12.3,2.06,18.7,0.55c4.34-1.03,9.03-0.6,14.07-0.85c1.36,0.94,3.45,2.54,5.7,3.84c0.73,0.42,2.04,0.36,2.86,0
				c4.17-1.85,8.26-3.87,12.14-5.72c4.3,4.55,13.31,4.9,18.17,1.08c2.28,2.39,4.15,2.41,7.51,0.53c4.63-2.58,11.08-5.46,15.39-4.04
				c4.52,1.49,7.31,0.67,11.17-1.28c3.34,3.85,6.42,7.81,9.99,11.25c1.02,0.99,3.48,0.49,6.07,0.75c0.71,3.66,5.37,4.36,9.12,4.61
				c1.8,0.12,4.71-3.33,5.59-5.73c1.6-4.36,4.28-6.07,8.6-6.79c1.76-0.29,3.58-2.09,4.75-3.67c5.1-6.81,8.74-6.92,12.74,0.47
				c4.13,7.62,11.42,10.11,18.58,13.41c5.91,2.72,11.83,2.26,17.89,2.51c2.83,0.12,5.59,1.45,8.42,2.07
				c1.26,0.28,2.74,0.54,3.91,0.16c11.15-3.7,17.29,2.87,23.43,11.34c-2.27,0.48-3.96,1.06-5.67,1.16
				c-11.33,0.71-22.67,1.69-34.01,1.8c-5.07,0.05-10.15-1.78-15.25-2.63c-5.7-0.95-11.41-1.88-17.14-2.57
				c-13.79-1.67-27.61-3.14-41.39-4.85c-1.55-0.19-3.01-1.35-4.43-2.18c-3.74-2.2-7.43-2.37-11.86-1.72
				c-7.09,1.03-14.33,2.2-21.5-1.49c-4.1-2.11-9.45-1.67-13.79-3.46c-8.62-3.54-15.85,2.32-23.83,2.68
				c-0.76,0.03-1.84,0.61-2.23,0.28c-4.68-3.87-8.23,0.15-12.48,1.06c-5.73,1.22-12.18,3.2-18.02-1.13
				c-2.01-1.48-4.71-2.32-7.21-2.77c-4.68-0.83-9.61,0.85-14.05-2.93c-1.41-1.2-5.46,0.7-8.31,1.19C0.5,729.33,0.5,727.67,0.5,726z"
				/>
			<path onClick={() => props.onFill(30)} fill={props.fillColors[30]}  d="M971.81,1283c-34.9-0.29-69.8-0.46-104.69-0.98c-5.03-0.07-10.09-1.54-15.03-2.81
				c-3.19-0.82-6.15-2.48-9.24-3.7c-8.23-3.25-16.41-4.61-25.6-4.11c-16.5,0.9-33.12-0.41-49.69-0.89c-3.45-0.1-6.89-0.63-10.31-1.44
				c5.44,0,10.89-0.23,16.32,0.05c9.7,0.5,19.28-0.03,28.49-3.25c11.47-4.01,23.4-5.46,35.33-7.13c3.15-0.44,6.19-2.17,9.1-3.67
				c6.18-3.19,12.04-7.08,18.39-9.85c8.47-3.7,20.76,0.28,26.24,7.76c6.75,9.23,15.89,12.45,27.2,12.6
				c12.89,0.17,25.75,1.97,38.63,2.92c4.94,0.37,9.9,0.35,14.86,0.51C971.81,1273.67,971.81,1278.33,971.81,1283z M871.4,1270.45
				c6.01,0.01,7.73-2.09,5.8-7.81c-0.83-2.47-2.18-4.85-3.72-6.96c-2.22-3.04-5.76-4.18-8.81-2.22c-2.49,1.61-4.91,4.5-5.69,7.29
				c-1.04,3.72,1.54,6.71,5.17,8.05C866.46,1269.65,868.98,1269.92,871.4,1270.45z"/>
			<path onClick={() => props.onFill(31)} fill={props.fillColors[31]}  d="M0.5,655c3.63,2.92,7.25,5.87,10.92,8.73c0.47,0.36,1.31,0.56,1.89,0.42c8.01-1.86,15.12,1.61,22.31,4
				c7.5,2.5,15,3.97,22.92,3.39c1.19-0.09,2.42,1.04,3.68,1.22c2.05,0.28,4.77,1.11,6.1,0.16c3.97-2.83,7.09-2.86,10.55,0.67
				c0.78,0.8,2.7,0.65,4.1,0.64c3.98-0.02,7.96-0.2,11.93-0.28c0.98-0.02,2.22,0.51,2.91,0.09c7.21-4.43,14.84-2.29,22.45-1.89
				c5.45,0.29,11.68,1.06,16.25-1.13c6.18-2.96,10.52-0.88,16.09,0.66c4.7,1.3,10.22-0.57,15.39-0.7c2.3-0.06,4.69,0.48,6.89,1.22
				c1.41,0.47,2.53,1.81,5.1,3.77c-2.5-0.58-3.74-1.1-4.97-1.09c-1.7,0.02-3.68-0.01-5.02,0.83c-1.46,0.91-2.22,3.92-3.46,4.03
				c-4.18,0.37-8.85,0.9-12.57-0.57c-5.5-2.18-5.78-2.65-7.59,4.29c-2.49-0.81-4.95-2.3-7.38-2.25c-4.3,0.09-8.57,1.25-12.88,1.73
				c-4.02,0.45-8.07,0.6-12.1,0.88c-0.66,0.05-1.61-0.15-1.92,0.21c-3.43,3.94-7.96,1.15-11.81,2.13c-1.87,0.48-3.66,2.06-5,3.57
				c-0.79,0.9-0.64,2.64-0.7,2.95c-1.75-1.21-3.47-3.26-5.36-3.45c-1.68-0.16-3.56,1.75-5.84,3.03c-3.27-1.92-6.84-2.95-8.92,2.67
				c-0.32,0.87-2.61,1.66-3.8,1.44c-5.19-0.94-10.38-2.88-14.04,3.18c-0.45,0.75-2.08,0.77-3.33,1.19c-0.94-1.59-1.46-3.44-2.67-4.3
				c-1.38-0.97-4-2-5-1.35c-4.16,2.7-7.4,1.67-11.57-0.37c-4.48-2.19-9.74-2.97-14.75-3.79c-1.57-0.26-3.45,1.33-5.72,2.3
				c-2.5-2.61-5.67-7.06-8.52,0.09c-1.69-0.86-3.11-1.58-4.53-2.3C0.5,679,0.5,667,0.5,655z"/>
			<path onClick={() => props.onFill(32)} fill={props.fillColors[32]}  d="M0.5,18c32.4,0.4,64.8,0.99,97.2,1.05c8.03,0.01,15.48,1.29,22.7,4.55c10.4,4.7,20.82,6.74,32.68,6.02
				c19.08-1.16,38.33,0.44,57.51,0.87c0.01,0.67,0.03,1.35,0.04,2.02c-2.65,0-5.31,0.2-7.94-0.03c-15.24-1.36-29.95-0.18-44.51,5.55
				c-7.36,2.9-15.96,2.5-23.87,4.17c-4.73,1-9.38,2.79-13.8,4.82c-4.5,2.07-8.69,4.82-12.98,7.33c-10.57,6.18-21.69,3.79-31.88-8.21
				c-5.76-6.78-13.34-10.92-22.3-10.35c-15.09,0.96-29.79-1.38-44.57-3.61C6.12,31.78,3.26,32.69,0.5,33C0.5,28,0.5,23,0.5,18z
				M109.51,39.78c-0.16-5.74-6-9.31-14.45-8.94c-3.27,0.15-4.93,1.68-4.74,5.01c0.38,6.61,5.59,12.73,10.61,12.52
				C105.84,48.16,109.64,44.35,109.51,39.78z"/>
			<path onClick={() => props.onFill(33)} fill={props.fillColors[33]}  d="M971.81,578c-4.9-2.05-8.55-6.58-14.89-6.04c-2.17,0.19-4.83-4.98-7.97-2.75
				c-2.23,1.59-3.08,5.12-4.62,7.92c-0.12,0.03-0.49,0.24-0.74,0.15c-7.41-2.76-14.79-5.64-22.23-8.31
				c-6.09-2.18-12.27-3.64-18.81-1.69c-1.22,0.36-2.9-0.21-4.17-0.78c-2.08-0.93-4-2.22-6.21-3.49c0-1.84,0-3.95,0-7.08
				c-6.38,2.93-8.22,8.68-11.1,13.11c-3.25,0.2-6.38,0.54-9.5,0.51c-1.32-0.01-3.51-0.52-3.76-1.3c-0.41-1.27,0.33-2.98,0.81-4.44
				c0.25-0.74,1.18-1.25,1.45-1.99c0.35-1,0.36-2.12,0.51-3.19c-1.22-0.07-2.65-0.6-3.63-0.14c-6.96,3.23-13.55,7.52-20.78,9.83
				c-10.1,3.23-19.85,9.71-31.34,5.64c-0.44-0.16-1-0.08-1.48,0.03c-5.74,1.26-11.55,2.28-17.2,3.88c-5.75,1.64-11.79,1.7-17.35,2.82
				c-7.36,1.48-15.78-1.24-22.36,4.5c-0.97,0.85-3.15,0.31-4.76,0.41c-0.11-0.59-0.23-1.19-0.34-1.78
				c12.3-3.56,24.62-7.06,36.9-10.71c3.48-1.03,6.91-2.3,10.24-3.74c14.63-6.3,30.5-7.44,45.74-11.16
				c13.89-3.39,26.68-9.06,37.7-18.48c3.61-3.08,7.75-5.54,11.65-8.28c0.37,0.35,0.74,0.71,1.1,1.06c-1.62,2.09-3.11,4.3-4.89,6.25
				c-2.01,2.2-4.52,3.99-6.36,6.31c-1.15,1.45-1.48,3.56-2.18,5.38c2.02,0.18,4.61,1.26,5.94,0.39c4.26-2.8,8.42-4.45,13.47-2.59
				c0.56,0.21,1.53,0.18,1.94-0.17c4.67-4.08,8.52-1.87,12.75,1.06c3.34,2.31,5.55,0.9,6.04-3.28c0.24-2.07,0.25-4.17,1.27-6.49
				c3.04,6.62,6.13,13.23,9.11,19.88c0.88,1.96,1.2,4.21,2.27,6.04c0.64,1.08,2.26,1.57,3.44,2.32c0.51-1.29,1.58-2.64,1.43-3.85
				c-0.33-2.78-1.19-5.51-1.93-8.24c-1.28-4.74-2.29-9.26,4.18-11.21c1.09-0.33,1.84-1.82,3.41-3.47
				c7.41,7.66,16.68,11.5,27.26,12.14c0,2,0,4,0,6c-1.09,0.74-2.19,1.48-3.28,2.22c1.09,1.26,2.19,2.52,3.28,3.78
				C971.81,569.33,971.81,573.67,971.81,578z"/>
			<path onClick={() => props.onFill(34)} fill={props.fillColors[34]}  d="M0.5,203c0-9,0-18,0-27c2.71-0.53,5.39-1.21,8.12-1.56c2.52-0.32,5.57-1.23,7.55-0.23
				c6.41,3.23,12.81,3.18,19.36,1.32c9.86-2.81,18.32,2,26.55,5.68c3.84,1.72,6.19,4,10.86,2.07c4.47-1.85,6.29,0.42,5.81,5.28
				c-0.1,1,0.76,2.89,1.47,3.04c5.06,1.13,9.72,2.8,14.4,5.32c5.16,2.79,10.99,0.35,16.38-1.41c7.19-2.35,14.2-6.75,22.02-1.34
				c1.59,1.1,4.19,0.67,6.29,1.09c2.09,0.42,4.56,0.46,6.13,1.65c4.27,3.22,8.26,3.1,13.01,1.07c3.62-1.55,7.55-1.23,11.43,3.19
				c-3.41,0.36-5.61,0.8-7.82,0.79c-9.28-0.02-18.56-0.26-27.84-0.23c-4.64,0.02-9.28,0.41-13.91,0.71
				c-14.89,0.96-29.77,2.65-44.65,2.74c-16.03,0.1-32.07-3.38-48.09,0.43c-0.34,0.08-0.8-0.32-1.23-0.52
				c0.95-1.25,1.79-2.35,3.46-4.54C19.11,199.35,9.89,202.7,0.5,203z"/>
			<path onClick={() => props.onFill(35)} fill={props.fillColors[35]}  d="M971.81,1226c-2.41,0.4-5.05,0.3-7.19,1.29c-20.87,9.7-42.91,10.66-64.97,7.18
				c-21.88-3.46-41.97,1.32-61.52,9.96c-13.06,5.77-26.39,8.05-40.32,5.32c-5.29-1.04-10.34-3.49-15.37-5.61
				c-6.47-2.73-12.97-3.67-19.94-2.27c-9.08,1.83-17.72,2.02-26.37-3.3c-8.07-4.96-16.11-0.33-22.63,4.45
				c-6.05,4.45-11.37,4.98-18,2.12c-5.89-2.54-12.15-4.2-18.12-7.07c6.67,0.39,13.35,1.19,20.01,1.03c5.91-0.13,11.83-1.15,17.7-2.08
				c15.48-2.47,30.88-3.49,46.43-0.14c6.28,1.35,12.87,1.47,19.33,1.7c11.44,0.4,22.89,0.65,34.33,0.47c2.12-0.03,4.2-2.37,6.31-3.63
				c1.31-0.78,2.61-2.09,3.97-2.17c7.26-0.43,14.17-1.56,19.98-6.51c1.2-1.03,2.96-1.62,4.56-1.92c14.95-2.86,29.81-7.29,45.26-3.29
				c4.63,1.2,9.19,2.76,13.88,3.55c9.9,1.67,19.95,4.38,29.8,3.9c7.92-0.39,15.71-4.65,23.41-7.59c3.35-1.28,6.32-3.57,9.46-5.4
				C971.81,1219.33,971.81,1222.67,971.81,1226z"/>
			<path onClick={() => props.onFill(36)} fill={props.fillColors[36]}  d="M971.81,1020c0,25,0,50,0,75c-7.79-0.4-15.59-0.61-23.35-1.26c-5.27-0.44-10.27-1.62-11.34-8.32
				c-0.21-1.29-2.19-2.25-3.22-3.48c-0.78-0.93-1.95-2.12-1.82-3.04c0.13-0.97,1.47-2.27,2.5-2.52c2.72-0.67,5.57-0.73,8.34-1.23
				c6.59-1.2,14.99,1.01,17.09-8.93c0.13-0.62,1.26-1,1.88-1.55c3.85-3.48,3.27-8.77-1.39-11.03c-2.37-1.15-6.07-1-7.3-2.75
				c-3.43-4.88-8.04-8.55-11.67-13c-1.99-2.44-3.4-4.7-6.45-5.08c-6.58-0.82-13.21-1.26-19.8-2.03c-1.76-0.21-3.44-1.06-5.16-1.62
				c0.01-0.63,0.02-1.26,0.03-1.89c3.59-0.68,7.16-1.55,10.77-1.99c9.38-1.15,18.85-1.76,28.17-3.29c5.69-0.93,7.18-4.6,4.37-9.54
				c-1.54-2.71-1.41-4.27,0.88-6.37c3.76-3.45,3.31-5.69-1.48-7.48c-3.51-1.31-7.24-2.01-10.88-2.99c0.02-0.2,0.04-0.4,0.06-0.6
				c9.92,0,19.85,0,29.77,0c0,5.67,0,11.33,0,17c-2.34,1.23-6.6,2.39-6.65,3.71C965.01,1019.46,968.46,1020.22,971.81,1020z"/>
			<path onClick={() => props.onFill(37)} fill={props.fillColors[37]}  d="M971.81,51c-15.1-2.17-26.82,5.95-38.65,13.21c-2.27,1.39-3.85,5.08-4.34,7.95
				c-0.79,4.61-2.26,3.83-5.11,1.83c-4.58-3.23-9.5-5.91-14.07-9.24c-3.59-2.62-9.42-2.95-14.24-2.92
				c-7.69,0.06-14.37-0.7-20.71-6.18c-8.57-7.41-19.07-9.34-30.41-7.26c-5.6,1.03-11.43,0.84-17.15,1.2
				c-0.05-0.49-0.1-0.97-0.15-1.46c7.53-1.28,15.14-2.19,22.56-3.93c12.04-2.82,24.04-3.58,36.46-3.43
				c24.89,0.29,48.76-5.32,70.82-17.34c4.74-2.58,9.48-4.69,14.99-4.43C971.81,29.67,971.81,40.33,971.81,51z M880.05,44.16
				c4.32,4.96,8.58,5.64,13.03,6.15c3.55,0.41,7.07,1.14,10.59,1.84c5.93,1.18,12.02,3.95,17.71,3.28c6.52-0.77,12.8-4.3,18.93-7.17
				c2.4-1.12,4.05-3.78,6.15-5.6c1.44-1.25,3.08-2.27,4.63-3.39c1.31-0.96,2.62-1.92,3.93-2.88c-1.41-1.14-2.67-2.76-4.28-3.27
				c-1.6-0.51-3.73-0.35-5.32,0.29c-18.6,7.5-37.8,11.05-57.88,9.51C885.57,42.75,883.52,43.55,880.05,44.16z"/>
			<path onClick={() => props.onFill(38)} fill={props.fillColors[38]}  d="M971.81,550c-9.25-0.53-17.4-3.52-24.22-10.09c-2.29-2.2-5.26-3.45-7.1,0.94c-0.18,0.43-0.56,1-0.95,1.09
				c-6.08,1.47-6.54,6.09-6.91,11.43c-2.04-4.26-3.84-8.65-6.21-12.72c-1.1-1.88-3.28-3.13-4.97-4.66c-0.92,1.94-2.04,3.81-2.7,5.83
				c-0.44,1.35-0.21,2.92-0.31,4.95c-1.42-0.46-2.59-0.56-3.41-1.16c-4.06-2.97-8.06-4.85-12.4-0.51c-0.31,0.32-1,0.59-1.37,0.47
				c-5.88-1.94-11.26-1.09-15.96,3.89c-0.14-0.45-0.28-0.91-0.42-1.36c3.19-3.48,6.39-6.94,9.55-10.44c0.67-0.74,1.46-1.52,1.73-2.42
				c0.59-1.94,0.89-3.97,1.31-5.96c-2.01,0.14-4.45-0.41-5.95,0.54c-5.59,3.56-10.96,7.5-16.26,11.51
				c-7.47,5.65-15.8,9.32-24.82,11.6c6.57-4.77,13.24-9.41,19.67-14.37c4.21-3.24,3.93-4.86-0.98-7.54c3.15-1.58,6.11-3,9.01-4.54
				c4.97-2.63,8.87-6.2,11.39-11.45c1.24-2.57,4.17-4.28,5.64-6.79c1.76-2.98,2.85-6.36,4.05-9.63c0.23-0.63-0.09-1.9-0.6-2.31
				c-4.21-3.3-8.52-6.47-12.28-9.28c1.91-0.88,5.04-2.75,8.43-3.75c2.18-0.64,5.24-0.78,6.95,0.33c1.31,0.85,1.87,4.15,1.5,6.12
				c-1.76,9.36-4.01,18.63-5.93,27.96c-0.29,1.43,0.25,3.03,0.41,4.55c1.67-0.28,3.76-0.03,4.93-0.95c2.72-2.15,5.01-4.84,7.44-7.35
				c2.02-2.08,3.45-2.18,6.25-0.32c4.51,3,9.44,7.77,14.93,2.42c1.67,2.24,3.68,6.08,4.49,5.84c2.6-0.77,4.98-2.81,6.99-4.82
				c1.72-1.72,2.81-4.07,4.18-6.15c0.42,0.12,0.84,0.24,1.26,0.35c-1.35,3.96-2.28,8.14-4.16,11.83c-2.25,4.42-1.15,6.81,2.85,9.38
				c7.75,4.98,15.97,8.56,24.93,10.52C971.81,545.33,971.81,547.67,971.81,550z"/>
			<path onClick={() => props.onFill(39)} fill={props.fillColors[39]}  d="M0.5,1250c6.16,0.85,11.83-0.52,17.27-3.45c3.8-2.04,7.76-3.78,11.62-5.72c6.08-3.07,10.93-7.09,10.44-15
				c1.34,0.12,2.48-0.13,2.86,0.3c3.72,4.21,9.59,4.68,13.85,8.73c3.61,3.44,10.59,4.43,16.11,4.52c7.31,0.11,13.74,0.3,19.86,5.48
				c9.18,7.78,20.08,10.34,32.19,7.81c5.13-1.07,10.56-0.7,15.91-0.07c-19.36,1.89-37.69,9.1-57.75,8.01
				c-24.65-1.35-48.39,4.93-70.48,16.56c-3.77,1.98-7.91,3.25-11.88,4.84C0.5,1271.33,0.5,1260.67,0.5,1250z M69.35,1258.16
				c4.65,0,9.31,0.11,13.95-0.08c1.13-0.05,2.22-1.09,3.33-1.67c-0.84-1.07-1.46-2.61-2.55-3.12c-2.38-1.1-5.05-2.46-7.52-2.3
				c-7.78,0.5-14.47-2.95-21.6-4.89c-2.75-0.75-6.03-1.22-8.66-0.44c-6.01,1.79-11.87,4.2-17.56,6.84c-2.44,1.13-4.24,3.57-6.46,5.25
				c-2,1.51-4.22,2.71-6.23,4.2c-1.06,0.78-2.69,1.94-2.62,2.81c0.08,1.07,1.63,2.8,2.63,2.87c2.88,0.19,5.98,0.21,8.7-0.63
				C39.32,1262.51,53.57,1256.56,69.35,1258.16z"/>
			<path onClick={() => props.onFill(40)} fill={props.fillColors[40]}  d="M971.81,894c-12.89-0.3-24.43-5.31-35.54-9.7c-0.77-10.58-1.38-20.58-2.29-30.54
				c-0.3-3.3,0.81-5.35,2.99-7.83c3.92-4.47,7-9.69,10.85-15.21c3.95,0,9.45,0,15.14,0c-2.51,8.12-2.1,8.42,8.85,7.29
				C971.81,856.67,971.81,875.33,971.81,894z"/>
			<path onClick={() => props.onFill(41)} fill={props.fillColors[41]}  d="M971.81,661c-2.41,3.4-4.86,6.76-7.19,10.21c-0.63,0.93-0.89,2.1-1.3,3.11
				c-5.66-0.39-8.12-4.64-11.49-8.09c-4.02-4.12-8.72-8.4-14.87-8.29c-4.1,0.07-8.16,2.14-12.58,3.42
				c-4.02-3.35-8.42-7.44-13.25-10.93c-4.49-3.24-5.16-6.42-1.24-10.43c7.31,2.69,8.23-5.48,12.96-7.52c2.03,0.91,4.49,3,6.63,2.71
				c3.22-0.43,6.21-2.53,10.02-4.24c5.73,2.23,9.78,1.13,14.51-4.54c1.35-1.62,2.19-4.21,5.35-1.85c0.83,0.62,4.34-0.59,5.01-1.83
				c1.72-3.2,4.44-4.52,7.45-5.74c0,3.33,0,6.67,0,10c-8.24,5.11-16.49,10.22-24.73,15.33c0.4,0.87,0.81,1.74,1.21,2.6
				c7.84-2.31,15.68-4.62,23.52-6.93C971.81,645.67,971.81,653.33,971.81,661z"/>
			<path onClick={() => props.onFill(42)} fill={props.fillColors[42]}  d="M0.5,386c0-4.33,0-8.67,0-13c3.11,1.44,6.14,3.15,9.38,4.24c2.13,0.72,4.86-0.34,2.84-2.87
				C9.12,369.86,4.62,366.08,0.5,362c0-1.33,0-2.67,0-4c0.89,0.43,1.82,0.79,2.65,1.3c16.27,10.01,32.53,20.03,48.78,30.06
				c0.76,0.47,1.4,1.14,2.69,2.2c-1.66,0.08-2.52-0.05-3.26,0.18c-1.62,0.52-3.18,1.23-4.77,1.86c1.13,1.51,1.97,3.86,3.46,4.38
				c4.17,1.47,8.6,2.18,12.93,3.21c3.28,0.77,6.55,1.56,10.59,2.52c-6.02,4.4-5.89,5.74-0.16,9.12c6.97,4.11,13.94,8.22,20.91,12.33
				c-0.19,0.35-0.39,0.7-0.58,1.06c-1.72-0.87-3.4-1.82-5.17-2.58c-10.49-4.52-20.98-9.06-31.55-13.38c-1.23-0.5-3,0.33-4.51,0.55
				c0.31,1.38,0.31,2.94,0.99,4.1c1.17,2.02,2.76,3.8,4.77,6.47C38.37,409.2,19.43,397.6,0.5,386z"/>
			<path onClick={() => props.onFill(43)} fill={props.fillColors[43]}  d="M0.5,733c3.84-0.58,7.46-1.73,11.04,1.79c1.38,1.36,5.05,0.57,7.66,0.42c4.01-0.23,7.3,0.71,10.47,3.49
				c1.7,1.49,4.72,1.52,7.18,2.06c2.59,0.57,5.26,0.78,7.81,1.47c2.98,0.81,6.04,1.66,8.76,3.07c4.46,2.3,8.71,5.04,13,7.66
				c1.75,1.07,3.39,2.32,5.74,3.93c-3.54,3.08-6.77,5.61-11.75,3.71c-3.75-1.43-7.39-1.67-10.69,1.36c-0.57,0.52-1.95,0.11-2.94,0.25
				c-3.16,0.45-6.31,0.98-9.47,1.43c-1.36,0.2-2.96,0.83-4.05,0.36c-4.29-1.86-8.24-1.85-12.19,0.8c-0.89,0.6-2.42,1.19-3.21,0.82
				c-5.89-2.73-11.57,0.53-17.34,0.36C0.5,755,0.5,744,0.5,733z"/>
			<path d="M0.5,203c9.39-0.3,18.61-3.65,29.31-2.43c-1.67,2.19-2.51,3.29-3.46,4.54c0.44,0.19,0.89,0.6,1.23,0.52
				c16.01-3.81,32.06-0.33,48.09-0.43c14.89-0.09,29.77-1.78,44.65-2.74c4.63-0.3,9.27-0.69,13.91-0.71
				c9.28-0.03,18.56,0.21,27.84,0.23c2.2,0,4.41-0.43,7.82-0.79c-3.89-4.42-7.82-4.74-11.43-3.19c-4.75,2.04-8.74,2.16-13.01-1.07
				c-1.57-1.19-4.04-1.23-6.13-1.65c-2.11-0.42-4.71,0.01-6.29-1.09c-7.82-5.42-14.82-1.01-22.02,1.34
				c-5.39,1.76-11.22,4.2-16.38,1.41c-4.68-2.53-9.34-4.2-14.4-5.32c-0.71-0.16-1.57-2.05-1.47-3.04c0.48-4.86-1.34-7.13-5.81-5.28
				c-4.67,1.93-7.02-0.35-10.86-2.07c-8.23-3.69-16.69-8.49-26.55-5.68c-6.55,1.86-12.95,1.91-19.36-1.32
				c-1.98-1-5.03-0.09-7.55,0.23c-2.73,0.35-5.42,1.02-8.12,1.56c0-0.67,0-1.33,0-2c2.52-0.8,4.99-1.86,7.56-2.33
				c2.7-0.5,6.08-1.54,8.15-0.44c7.11,3.78,14.2,2.69,21.4,1.22c5.25-1.07,10.07-0.72,14.89,2.01c4.67,2.64,9.69,4.66,14.63,6.81
				c0.75,0.33,1.9,0.03,2.77-0.26c8.54-2.79,12.18-0.48,12.76,6.96c4.42,2.23,8.07,5.23,12.06,5.8c5.02,0.71,10.41-0.13,15.49-1.11
				c4.49-0.86,8.71-3.1,13.18-4.16c1.87-0.44,4.14,0.44,6.15,1c3.32,0.91,6.59,2.02,9.86,3.09c3.22,1.06,6.38,2.34,9.64,3.23
				c1.83,0.5,4.03,1.06,5.71,0.51c6.6-2.17,12.29-3.21,17.91,3.12c2.26,2.55,7.72,2.24,11.72,3.25c3.38,0.86,6.8,1.58,10.08,2.72
				c1.66,0.58,3.05,1.95,4.56,2.96c-0.1,0.46-0.21,0.92-0.31,1.38c-1.82,0.4-3.64,1.06-5.48,1.15c-4.81,0.23-9.64,0.03-14.44,0.36
				c-4.19,0.29-8.88-2.27-12.46,2.65c-0.93,1.28-4.43,1.11-6.7,0.92c-15.66-1.35-31.09,0.46-46.68,1.86
				c-17.28,1.55-34.83,0.2-52.25,0.08c-6.14-0.04-12.29-0.22-18.43-0.3c-13.92-0.18-27.85-0.33-41.78-0.49c0-0.67,0-1.33,0-2
				c4.47,0.13,8.95,0.33,13.42,0.36c6.97,0.06,13.95-0.01,20.92,0c10.45,0.02,20.91-0.03,31.36,0.12c8.13,0.12,16.3,1.07,24.39,0.63
				c14.25-0.78,28.43-2.64,42.68-3.45c8.93-0.51,17.93,0.12,26.9,0.13c1.37,0,3.75-0.29,3.95-0.96c1.19-4.06,4.33-2.97,6.98-3
				c6.64-0.08,13.28-0.03,19.92-0.03c-8.24-3.13-16.59-4.78-25.34-3.21c-2.09,0.38-4.28,0.24-6.43,0.24
				c-11.27-0.02-22.65-1.09-33.8,0.09c-21.2,2.24-42.27,4.19-63.65,2.54c-10.66-0.83-21.49,0.3-32.23,0.82
				c-4.18,0.21-7.87,0.36-8.22-5.07C13.98,204.2,7.24,205.1,0.5,206C0.5,205,0.5,204,0.5,203z"/>
			<path onClick={() => props.onFill(44)} fill={props.fillColors[44]}  d="M0.5,266c4-3.92,7.88-7.97,12.06-11.68c1.54-1.37,3.7-2.34,5.73-2.78c2.98-0.64,4.98-0.24,5.56,4.02
				c0.76,5.6,3.51,10.89,4.7,16.46c1.38,6.46,5.39,12.26,3.02,19.71c-2.53,7.94-3.43,16.4-5.18,25.36
				c-6.2-2.78-12.71-5.26-18.06-10.28c-2.19-2.05-5.19-3.24-7.82-4.82C0.5,290,0.5,278,0.5,266z"/>
			<path onClick={() => props.onFill(45)} fill={props.fillColors[45]}  d="M0.5,790c1.4-0.23,3.15,0,4.16-0.76c8.03-6.1,12.42-5.49,18.2,2.6c2.13,2.98,4.38,5.94,7.01,8.45
				c0.73,0.69,3.42,0.25,4.53-0.58c2.4-1.8,4.37-4.18,7.01-6.82c1.77,3.31,2.6,6.21,4.48,8.03c2.22,2.14,5.48,4.87,8.04,4.64
				c5.36-0.49,7.72,3.24,10.88,5.77c2.06,1.65,1.98,3.9-1.38,4.5c-7.01,1.26-14.11,3.86-21.03,3.45c-14.36-0.85-28.13,1.44-41.9,4.73
				C0.5,812.67,0.5,801.33,0.5,790z"/>
			<path onClick={() => props.onFill(46)} fill={props.fillColors[46]}  d="M0.5,466c29.79,19.44,63.68,30.57,96.22,47.61c-3.46,0-5.74,0.05-8.02-0.01c-2.49-0.07-5-0.51-7.46-0.33
				c-12.17,0.9-22.96-3.77-34.2-7.45c-13.97-4.56-26.67-12.78-41.6-14.45c-1.64-0.18-3.29-0.25-4.93-0.37
				C0.5,482.67,0.5,474.33,0.5,466z"/>
			<path onClick={() => props.onFill(47)} fill={props.fillColors[47]}  d="M0.5,206c6.74-0.9,13.48-1.8,20.85-2.78c0.35,5.43,4.04,5.27,8.22,5.07c10.74-0.53,21.57-1.65,32.23-0.82
				c21.38,1.65,42.45-0.29,63.65-2.54c11.14-1.18,22.52-0.11,33.8-0.09c2.14,0,4.34,0.14,6.43-0.24c8.75-1.57,17.1,0.07,25.34,3.21
				c-6.64,0-13.28-0.05-19.92,0.03c-2.65,0.03-5.78-1.06-6.98,3c-0.2,0.66-2.58,0.96-3.95,0.96c-8.97-0.01-17.97-0.64-26.9-0.13
				c-14.24,0.81-28.43,2.68-42.68,3.45c-8.09,0.44-16.26-0.52-24.39-0.63c-10.45-0.15-20.91-0.1-31.36-0.12
				c-6.97-0.01-13.95,0.06-20.92,0c-4.47-0.04-8.95-0.24-13.42-0.36C0.5,211.33,0.5,208.67,0.5,206z"/>
			<path onClick={() => props.onFill(48)} fill={props.fillColors[48]}  d="M971.81,298c-6.03-2.32-12.17-4.4-18.06-7.04c-4.15-1.86-7.5-4.63-8.21-9.84
				c-0.24-1.73-2.46-3.23-3.87-4.74c-2.42-2.57-5.59-4.7-7.24-7.68c-3.07-5.58-7.08-9.21-13.28-11.1
				c-10.86-3.31-20.6-8.89-29.11-16.96c0.97,0.13,2.01,0.08,2.89,0.43c11.69,4.59,23.57,8.77,34.98,14
				c13.42,6.16,27.26,10.33,41.9,11.95C971.81,277.33,971.81,287.67,971.81,298z"/>
			<path onClick={() => props.onFill(49)} fill={props.fillColors[49]}  d="M0.5,647c1.15,0.12,2.66-0.18,3.4,0.43c5.72,4.76,12.09,3.22,18.54,2.09c1.53,2.78,2.41,5.34,7.04,3.75
				c3.61-1.24,8.18,0.53,12.32,0.49c1.81-0.01,3.6-1.19,6.12-2.09c4.67,2.18,9.92,4.98,15.44,7.04c1.9,0.71,4.47-0.4,7.7-0.8
				c3.67,3.35,8.59,5.55,15.19,4.38c3.92-0.69,8.24,1.07,12.41,1.52c1.32,0.14,2.75-0.27,4.05-0.69c0.72-0.23,1.33-1.52,1.88-1.45
				c8.33,1.04,17.3-0.83,24.72,4.55c-0.04,0.69-0.07,1.39-0.11,2.08c-7.82,0.09-15.63,0.19-23.45,0.26
				c-1.14,0.01-2.75-0.67-3.36-0.13c-6.2,5.37-13.66-0.85-20.33,2.35c-2.03,0.98-5.74-1.59-8.69-2.5c-0.78-0.24-2.04-0.76-2.34-0.45
				c-4.91,5.03-10.91-0.74-16.05,1.7c-0.54,0.26-1.35,0.2-1.95,0.02c-7.87-2.37-15.66-5.07-23.63-7.09
				c-4.55-1.16-9.38-1.21-14.08-1.83c-1.15-0.15-2.94-0.24-3.28-0.93c-2.41-4.86-7.65-5.25-11.55-7.7C0.5,650.33,0.5,648.67,0.5,647z
				"/>
			<path onClick={() => props.onFill(50)} fill={props.fillColors[50]}  d="M971.81,1236c0,8,0,16,0,24c-6.33,0.29-12.55,2.49-18.99-0.29c-6.8-2.94-13.99-3.45-21.44-2.16
				c-7.37,1.27-14.57-0.43-21.21-3.76c-3.18-1.6-5.93-4.03-8.88-6.09c0.24-0.47,0.48-0.95,0.71-1.42
				C926.09,1248.29,949.69,1246.97,971.81,1236z"/>
			<path onClick={() => props.onFill(51)} fill={props.fillColors[51]}  d="M0.5,1056c7.25,2.76,14.67,5.15,21.69,8.39c5.82,2.69,11.24,6.29,16.73,9.65
				c6.33,3.87,12.55,7.93,18.81,11.91c-0.11,0.52-0.21,1.04-0.32,1.56c-3.18-0.15-6.4,0-9.52-0.51c-7.72-1.25-15.37-2.57-22.99,0.52
				c-0.82,0.33-1.95,0.08-2.89-0.12c-0.79-0.17-1.5-0.9-2.28-0.95c-8.04-0.52-13.05-6.59-19.25-10.46
				C0.5,1069.33,0.5,1062.67,0.5,1056z"/>
			<path onClick={() => props.onFill(52)} fill={props.fillColors[52]}  d="M0.5,63c0-7.33,0-14.67,0-22c4.71,0.12,9.64-0.6,14.09,0.54c7.14,1.83,14.14,3.57,21.51,2.24
				c8.22-1.48,15.83,0.46,23.02,4.19c2.78,1.44,5.12,3.74,7.66,5.65c-1.85,1.13-3.13,1.1-4.39,1.14
				c-11.89,0.33-23.87-0.15-35.63,1.27C17.85,57.09,9.24,60.58,0.5,63z"/>
			<path d="M0.5,386c18.94,11.6,37.87,23.2,57.76,35.39c-2.01-2.67-3.59-4.45-4.77-6.47c-0.68-1.17-0.68-2.72-0.99-4.1
				c1.52-0.22,3.29-1.05,4.51-0.55c10.58,4.32,21.06,8.86,31.55,13.38c1.77,0.76,3.45,1.72,5.17,2.58c0.19-0.35,0.39-0.7,0.58-1.06
				c-6.97-4.11-13.94-8.23-20.91-12.33c-5.73-3.38-5.87-4.72,0.16-9.12c-4.04-0.96-7.32-1.75-10.59-2.52
				c-4.33-1.02-8.76-1.74-12.93-3.21c-1.48-0.52-2.32-2.87-3.46-4.38c1.59-0.63,3.15-1.34,4.77-1.86c0.74-0.24,1.59-0.11,3.26-0.18
				c-1.28-1.06-1.92-1.73-2.69-2.2c-16.25-10.04-32.51-20.06-48.78-30.06c-0.84-0.51-1.77-0.87-2.65-1.3c0-1,0-2,0-3
				c5.85,3.43,11.73,6.8,17.53,10.32c11.37,6.91,22.68,13.9,34.02,20.86c1.13,0.7,2.45,1.23,3.32,2.18c1.05,1.14,1.71,2.63,2.53,3.98
				c-1.39,0.65-2.75,1.4-4.19,1.91c-0.74,0.27-1.63,0.13-2.81,1.37c2.64,0.74,5.26,1.56,7.93,2.18c4.66,1.09,9.44,1.77,13.98,3.19
				c1.81,0.57,3.13,2.68,4.67,4.09c-1.39,1.35-2.78,2.71-3.93,3.83c12.79,8.32,26,16.92,39.21,25.52c-0.29,0.69-0.58,1.38-0.87,2.07
				c-1.72-0.28-3.57-0.24-5.13-0.9c-4.12-1.75-8.09-3.87-12.17-5.74c-12.6-5.78-25.23-11.5-38.4-16.33
				c3.11,4.09,6.21,8.19,9.32,12.28c-0.51,0.59-1.03,1.18-1.54,1.77c-11.56-4.55-20.9-13.04-31.48-19.34
				C21.79,401.88,11.16,395.42,0.5,389C0.5,388,0.5,387,0.5,386z"/>
			<path d="M3.02,544.58c6.34,2.35,12.67,4.73,19.04,7.02c3.43,1.23,7.11,1.92,10.36,3.5c15.95,7.75,31.79,15.73,47.67,23.62
				c7.73,3.84,15.46,7.69,23.18,11.54c1.19,0.59,2.67,0.9,3.52,1.81c1.45,1.55,2.52,3.46,3.74,5.23c-1.99,0.22-3.97,0.48-5.97,0.64
				c-2.93,0.23-5.92,0.78-8.79,0.42c-1.9-0.23-4.57-1.4-5.23-2.89c-1.29-2.91-1.77-5.53-6.01-4.24c-1.1,0.33-2.77-1.24-3.64-1.67
				c-1.89,1.32-3.55,2.48-5.2,3.63c-1.96-2.99-3.92-5.98-6.25-9.52c-0.41,0.28-1.55,1.02-2.67,1.78c-1.22,0.83-2.44,1.67-3.65,2.51
				c-0.63-1.42-1.32-2.82-1.85-4.28c-0.21-0.59-0.03-1.31-0.03-1.68c-1.97-1.22-3.61-2.63-5.5-3.32c-2.89-1.04-6-2.34-8.93-2.14
				c-3.35,0.23-5.59,0.2-5.79-3.88c-6.21,4.78-11.77,4.74-12.57-1.63c-4.44,2.92-5.47-1.35-7.11-3.6c-2.14-2.92-3.51-6.63-7.69-2.2
				c-0.3,0.32-2.58-0.62-3.32-1.5c-0.76-0.92-0.77-2.47-0.76-2.42c-3.66-1.73-6.37-3.01-9.08-4.29c0-0.67,0-1.33,0-2
				c4.09,2.24,10.22-0.91,13.06,5.21c7.29-3.12,8.3,4.47,11.52,7.03c1.89-0.77,3.4-1.93,4.63-1.68c0.85,0.17,1.64,2.21,1.9,3.53
				c0.75,3.92,3.29,5.24,5.82,2.15c4.49-5.49,5.79-2.05,6.77,1.06c3.77,0.94,6.77,1.59,9.71,2.45c1.72,0.5,3.86,2.39,4.92,1.86
				c5.89-2.94,5.42,1.58,5.99,4.87c1.79-0.41,3.49-1.29,4.97-1c1.4,0.28,3,1.52,3.72,2.79c1.13,1.97,1.58,4.33,2.49,7.02
				c2.38-3.38,3.67-7.59,7.8-2.96c0.37,0.42,2.21-0.48,4.28-0.99c-6.49-3.55-11.77-6.69-17.27-9.4c-10.1-5-20.27-9.86-30.51-14.56
				c-6.46-2.96-13.06-5.66-19.7-8.22c-6.64-2.56-13.4-4.78-20.11-7.16c0-0.67,0-1.33,0-2C1.34,544.86,2.18,544.72,3.02,544.58z
				M94.43,595.77c1.7-0.32,3.14-0.67,4.61-0.85c1.58-0.2,3.18-0.26,4.78-0.38c0.07-0.26,0.14-0.53,0.21-0.79
				c-4.39-1.96-8.79-3.92-14.03-6.26C91.75,590.78,92.98,593.06,94.43,595.77z"/>
			<path d="M971.81,995c-9.92,0-19.85,0-29.77,0c-0.02,0.2-0.04,0.4-0.06,0.6c3.63,0.97,7.37,1.68,10.88,2.99
				c4.79,1.79,5.24,4.03,1.48,7.48c-2.29,2.1-2.42,3.66-0.88,6.37c2.81,4.95,1.32,8.61-4.37,9.54c-9.32,1.53-18.78,2.14-28.17,3.29
				c-3.62,0.44-7.18,1.32-10.77,1.99c-0.01,0.63-0.02,1.26-0.03,1.89c1.72,0.56,3.4,1.42,5.16,1.62c6.59,0.77,13.22,1.21,19.8,2.03
				c3.05,0.38,4.46,2.64,6.45,5.08c3.63,4.46,8.24,8.13,11.67,13c1.23,1.75,4.93,1.6,7.3,2.75c4.66,2.26,5.24,7.55,1.39,11.03
				c-0.61,0.55-1.74,0.93-1.88,1.55c-2.1,9.95-10.5,7.73-17.09,8.93c-2.76,0.5-5.62,0.57-8.34,1.23c-1.03,0.25-2.37,1.55-2.5,2.52
				c-0.13,0.92,1.05,2.11,1.82,3.04c1.03,1.23,3.01,2.19,3.22,3.48c1.07,6.7,6.07,7.87,11.34,8.32c7.76,0.65,15.57,0.86,23.35,1.26
				c0,0.67,0,1.33,0,2c-8.62-0.24-17.28-0.09-25.86-0.85c-5.44-0.49-10.31-2.78-11.42-9.33c-0.17-1-1.89-1.64-2.57-2.66
				c-1.2-1.79-3.18-3.89-2.95-5.6c0.24-1.75,2.49-4.11,4.29-4.6c4.45-1.19,9.13-1.62,13.75-2.07c4.55-0.44,9.23-0.34,10.04-6.51
				c0.08-0.57,0.69-1.12,1.14-1.6c4.91-5.23,4.57-6.71-2.15-8.72c-4.39-1.31-8.78-2.45-9.08-8.34c-0.04-0.84-1.32-2.05-2.25-2.33
				c-3.41-1.02-5.87-2.43-6.62-6.49c-0.22-1.19-3.41-2.36-5.36-2.61c-5.76-0.73-11.59-0.95-17.38-1.49
				c-1.64-0.15-3.48-0.36-4.78-1.22c-1.51-0.99-3.28-2.61-3.5-4.17c-0.16-1.11,1.84-3.36,3.23-3.72c4.96-1.27,10.04-2.16,15.12-2.81
				c6.91-0.88,13.9-1.23,20.81-2.15c5.91-0.78,6.8-2.48,3.69-7.4c-3.08-4.87,1.71-6.71,3.87-10.83c-7.76-1.76-15.1-3.42-22.44-5.09
				c0.06-0.77,0.12-1.53,0.18-2.3c13.3-2.27,26.64-3.72,40.24-1.14C971.81,993.67,971.81,994.33,971.81,995z"/>
			<path onClick={() => props.onFill(53)} fill={props.fillColors[53]}  d="M971.81,934c-16.52-2.61-33.03-5.21-49.55-7.82c16.59-5.74,31.11-17.29,49.55-18.18
				C971.81,916.67,971.81,925.33,971.81,934z"/>
			<path onClick={() => props.onFill(54)} fill={props.fillColors[54]}  d="M971.81,369c-3.41-0.3-6.87-1.08-10.22-0.74c-2.61,0.27-5.21,1.7-7.55,3.06
				c-1.77,1.03-3.13,2.77-5.68,5.13c0.29-6.25,0.49-11.24,0.77-16.22c0.32-5.61,0.73-11.22,1.07-16.84c0.22-3.63,1.63-4.76,5.13-2.92
				c1.42,0.75,3.5,1.31,4.9,0.83c4.79-1.65,7.57,0.02,9.34,4.47c0.47,1.19,1.48,2.16,2.24,3.23
				C971.81,355.67,971.81,362.33,971.81,369z"/>
			<path onClick={() => props.onFill(55)} fill={props.fillColors[55]}  d="M0.5,547c6.71,2.37,13.47,4.6,20.11,7.16c6.64,2.55,13.23,5.25,19.7,8.22
				c10.24,4.7,20.41,9.56,30.51,14.56c5.49,2.72,10.78,5.85,17.27,9.4c-2.07,0.51-3.91,1.4-4.28,0.99c-4.13-4.63-5.42-0.42-7.8,2.96
				c-0.91-2.69-1.37-5.04-2.49-7.02c-0.72-1.27-2.32-2.52-3.72-2.79c-1.48-0.29-3.18,0.59-4.97,1c-0.57-3.29-0.1-7.81-5.99-4.87
				c-1.06,0.53-3.2-1.36-4.92-1.86c-2.94-0.86-5.94-1.51-9.71-2.45c-0.98-3.12-2.28-6.56-6.77-1.06c-2.53,3.09-5.07,1.77-5.82-2.15
				c-0.25-1.31-1.04-3.36-1.9-3.53c-1.23-0.25-2.73,0.92-4.63,1.68c-3.22-2.56-4.23-10.15-11.52-7.03
				c-2.85-6.12-8.98-2.96-13.06-5.21C0.5,552.33,0.5,549.67,0.5,547z"/>
			<path onClick={() => props.onFill(56)} fill={props.fillColors[56]}  d="M0.5,1101c5.56-0.56,11.3-2.21,16.64-1.35c4.7,0.76,9.93,3.41,13.18,6.86c4.74,5.05,7.93,11.57,12.3,18.27
				c-12.16,0.4-8.91-10.07-13.46-15.68c-1.41,3.21-2.54,5.76-3.96,8.99c-5.01-4.03-14.16-1.12-14.97-11.17
				c-0.54-0.29-1.07-0.58-1.61-0.88c-1.29,1.71-2.59,3.41-4.48,5.91c-0.61-0.16-2.13-0.55-3.64-0.95
				C0.5,1107.67,0.5,1104.33,0.5,1101z"/>
			<path onClick={() => props.onFill(57)} fill={props.fillColors[57]}  d="M0.5,998c0.16,0.02,0.4-0.02,0.48,0.07c4.25,4.95,11.02,6.5,15.37,10.98c2.86,2.95,4,7.56,5.91,11.43
				c-0.57,0.27-1.14,0.53-1.72,0.8c-0.69-1.39-1.26-2.86-2.1-4.15c-0.76-1.18-1.82-2.16-2.75-3.23c-1.06,1.12-2.54,2.07-3.09,3.4
				c-1.99,4.84-3.58,9.85-5.5,14.72c-1.15,2.9-3.08,5.08-6.6,4.99C0.5,1024,0.5,1011,0.5,998z"/>
			<path onClick={() => props.onFill(58)} fill={props.fillColors[58]}  d="M971.81,836c-2.13-0.16-4.27-0.33-6.42-0.49c1.84-6.19,0.81-7.59-5.79-7.8c-3.36-0.1-6.72-0.02-10.29-0.02
				c1.45-5.62,2.62-10.19,3.96-15.39c5.8-0.72,12.17-1.51,18.54-2.3C971.81,818.67,971.81,827.33,971.81,836z"/>
			<path onClick={() => props.onFill(59)} fill={props.fillColors[59]}  d="M0.5,871c3.78,1.04,7.74,1.65,11.29,3.2c8.35,3.64,16.5,7.73,26.04,12.26
				c-13.32,1.26-25.32,2.4-37.33,3.54C0.5,883.67,0.5,877.33,0.5,871z"/>
			<path d="M971.81,1236c-22.12,10.97-45.71,12.29-69.81,10.28c-0.24,0.47-0.48,0.95-0.71,1.42c2.95,2.06,5.7,4.49,8.88,6.09
				c6.64,3.33,13.84,5.03,21.21,3.76c7.46-1.29,14.64-0.78,21.44,2.16c6.44,2.78,12.67,0.58,18.99,0.29c0,1,0,2,0,3
				c-9.05,2.13-17.56-0.05-26.32-2.36c-5.27-1.39-11.21-0.38-16.85-0.19c-10.42,0.36-19.73-2.49-27.5-9.51
				c-1.47-1.33-1.89-3.8-2.8-5.75c2.08-0.56,4.18-1.69,6.23-1.59c20.85,1,41.54,1.08,61.07-8.04c1.89-0.88,4.1-1.06,6.17-1.57
				C971.81,1234.67,971.81,1235.33,971.81,1236z"/>
			<path d="M0.5,63c8.74-2.42,17.35-5.91,26.25-6.99c11.76-1.42,23.74-0.94,35.63-1.27c1.27-0.03,2.54,0,4.39-1.14
				c-2.54-1.91-4.88-4.2-7.66-5.65c-7.19-3.73-14.8-5.67-23.02-4.19c-7.37,1.33-14.37-0.41-21.51-2.24C10.14,40.4,5.21,41.12,0.5,41
				c0-1,0-2,0-3c5.87-2.34,11.35-0.08,17.03,1.11c5.44,1.13,11.12,2.01,16.62,1.7c10.77-0.61,20.9,0.56,29.95,6.91
				c1.22,0.86,2.66,1.66,3.42,2.86c1.06,1.67,1.57,3.68,2.32,5.54c-1.95,0.57-3.95,1.79-5.83,1.6c-15.68-1.53-31.24-0.79-46.59,2.63
				C11.66,61.65,6.14,64.09,0.5,66C0.5,65,0.5,64,0.5,63z"/>
			<path onClick={() => props.onFill(60)} fill={props.fillColors[60]}  d="M0.5,923c1.87-0.97,3.98-1.64,5.56-2.96c6.57-5.45,11.95-4.86,17.02,1.98c0.62,0.84,1.78,1.7,2.74,1.78
				c6.61,0.51,7.02,6.79,10.23,10.68C23.8,931.93,11.16,931.35,0.5,924C0.5,923.67,0.5,923.33,0.5,923z"/>
			<path onClick={() => props.onFill(61)} fill={props.fillColors[61]}  d="M0.5,239c10.56,1.74,21.12,3.49,31.68,5.23c0.08,0.53,0.16,1.06,0.25,1.59c-2.82,0.99-5.73,3.01-8.46,2.79
				c-9.93-0.78-16.27,4.42-21.74,11.62c-0.49,0.65-1.15,1.18-1.73,1.76C0.5,254.33,0.5,246.67,0.5,239z"/>
			<path onClick={() => props.onFill(62)} fill={props.fillColors[62]}  d="M971.81,405c-6.42-0.66-12.85-1.32-19.48-2c0.54-3.97,0.86-8.38,1.88-12.63c0.3-1.25,3.32-3.17,3.82-2.84
				c2.94,1.94,5.26,1.1,8.12-0.19c1.49-0.67,3.75,0.38,5.66,0.66C971.81,393.67,971.81,399.33,971.81,405z"/>
			<path onClick={() => props.onFill(63)} fill={props.fillColors[63]}  d="M971.81,801c-3.46,0.24-6.94,0.33-10.37,0.76c-3.56,0.44-5.76-0.02-5.43-4.51
				c0.21-2.75-0.59-5.57-0.88-8.37c-0.32-3.08-0.09-4.96,4.22-4.31c4.03,0.61,8.3-0.32,12.46-0.58
				C971.81,789.67,971.81,795.33,971.81,801z"/>
			<path onClick={() => props.onFill(64)} fill={props.fillColors[64]}  d="M971.81,384c-4.88,0.19-9.78,0.67-14.63,0.4c-1.6-0.09-3.86-1.82-4.44-3.33c-0.58-1.52,0.02-4.12,1.07-5.5
				c2.63-3.44,5.91-5.45,10.79-3.85c2.1,0.69,4.79-0.43,7.22-0.72C971.81,375.33,971.81,379.67,971.81,384z"/>
			<path onClick={() => props.onFill(65)} fill={props.fillColors[65]}  d="M0.5,306c1.15,0.61,2.66,0.94,3.4,1.87c5.08,6.46,12.77,8.72,19.44,12.68c3.73,2.22,6.63,5.84,9.62,10.11
				c-4.44-1.4-9.24-2.15-13.25-4.34C13.04,322.7,6.87,318.16,0.5,314C0.5,311.33,0.5,308.67,0.5,306z"/>
			<path d="M971.81,71c-6.23,0-12.51-0.5-18.66,0.19c-3.23,0.36-6.11,3.21-9.38,4.11c-1.8,0.5-4.06-0.7-6.12-1.13
				c0.46-1.52,0.47-3.93,1.45-4.43c10.34-5.23,19.92-12.96,32.7-10.73c0,1,0,2,0,3c-11.4,0-26,5.12-31.74,11.89
				c3.09-1.65,5.01-2.75,6.98-3.72c7.96-3.93,16.34-3.31,24.75-2.17C971.81,69,971.81,70,971.81,71z"/>
			<path onClick={() => props.onFill(66)} fill={props.fillColors[66]}  d="M971.81,948c-11.17-1.06-22.35-2.1-33.51-3.21c-1.07-0.11-2.1-0.66-3.1-2.01
				c12.2-0.93,24.41-1.86,36.61-2.78C971.81,942.67,971.81,945.33,971.81,948z"/>
			<path onClick={() => props.onFill(67)} fill={props.fillColors[67]}  d="M0.5,1079c5.33,5.32,10.66,10.63,15.99,15.95c-0.1,0.6-0.19,1.2-0.29,1.79
				c-5.23,0.42-10.47,0.84-15.7,1.26C0.5,1091.67,0.5,1085.33,0.5,1079z"/>
			<path d="M0.5,1231c8.48,0.12,16.9,0.37,24.26-5.28c1.02-0.79,4,0.82,5.93,1.64c0.18,0.07-0.22,3.25-1,3.62
				c-9.42,4.49-17.58,12.43-29.2,11.03c0-1,0-2,0-3c10.99,1.98,18-6.41,26.81-10.29c-8.62,3.76-17.16,7.47-26.81,4.29
				C0.5,1232.33,0.5,1231.67,0.5,1231z"/>
			<path d="M971.81,474c-3.54-1.33-6.1-3.34-5.02-7.65c-3.24-1.66-6.96-2.59-5.64-7.56c0.22-0.83-1.9-2.32-2.99-3.45
				c-2.49-2.6-5.18-5.03-7.42-7.83c-0.68-0.85-0.16-2.67-0.2-4.05c1.36,0.1,3.09-0.29,4.03,0.39c5.84,4.25,11.51,8.74,17.23,13.15
				c0,0.67,0,1.33,0,2c-2-1.24-4.09-2.35-5.98-3.74c-3.48-2.54-6.85-5.24-10.27-7.87c-0.28,0.35-0.57,0.69-0.85,1.04
				c0.39,0.51,0.69,1.32,1.19,1.48c7.48,2.36,6.97,12.01,13.71,15.08c0.5,0.23,0.29,1.82,0.62,2.71c0.42,1.14,1.05,2.2,1.58,3.3
				C971.81,472,971.81,473,971.81,474z"/>
			<path onClick={() => props.onFill(68)} fill={props.fillColors[68]}  d="M971.81,417c-4.85-0.75-9.69-1.87-14.57-2.15c-4.91-0.28-3.22-4.55-4.89-8.11
				c3.07-0.09,6.65-1.22,9.11-0.03c3.73,1.8,6.88,1.21,10.35,0.29C971.81,410.33,971.81,413.67,971.81,417z"/>
			<path onClick={() => props.onFill(69)} fill={props.fillColors[69]}  d="M971.81,776c-2.07-7.66-5.48-15.26-5.77-22.99c-0.21-5.59,3.7-11.34,5.77-17.01
				C971.81,749.33,971.81,762.67,971.81,776z"/>
			<path d="M971.81,638c-7.84,2.31-15.68,4.62-23.52,6.93c-0.4-0.87-0.81-1.74-1.21-2.6c8.24-5.11,16.49-10.22,24.73-15.33
				c0,1,0,2,0,3c-3.98,2.5-7.97,5.01-11.95,7.51c0.25,0.46,0.51,0.91,0.76,1.37c3.73-1.29,7.46-2.59,11.19-3.88
				C971.81,636,971.81,637,971.81,638z"/>
			<path onClick={() => props.onFill(70)} fill={props.fillColors[70]}  d="M971.81,68c-8.41-1.14-16.79-1.76-24.75,2.17c-1.98,0.98-3.9,2.07-6.98,3.72
				C945.81,67.12,960.4,62,971.81,62C971.81,64,971.81,66,971.81,68z"/>
			<path onClick={() => props.onFill(71)} fill={props.fillColors[71]}  d="M971.81,343c-0.59-2.21-1.48-4.39-1.71-6.64c-0.85-8.56-0.36-17.45-2.58-25.62
				c-1.53-5.62,0.35-7.06,4.29-8.74C971.81,315.67,971.81,329.33,971.81,343z"/>
			<path onClick={() => props.onFill(72)} fill={props.fillColors[72]}  d="M0.5,1233c9.65,3.18,18.19-0.53,26.81-4.29c-8.82,3.88-15.82,12.27-26.81,10.29
				C0.5,1237,0.5,1235,0.5,1233z"/>
			<path onClick={() => props.onFill(73)} fill={props.fillColors[73]}  d="M971.81,471c-0.54-1.1-1.16-2.16-1.58-3.3c-0.33-0.88-0.11-2.48-0.62-2.71
				c-6.74-3.08-6.23-12.73-13.71-15.08c-0.5-0.16-0.8-0.97-1.19-1.48c0.28-0.35,0.57-0.69,0.85-1.04c3.42,2.63,6.79,5.33,10.27,7.87
				c1.89,1.38,3.98,2.5,5.98,3.74C971.81,463,971.81,467,971.81,471z"/>
			<path d="M0.5,362c4.12,4.08,8.62,7.86,12.21,12.37c2.02,2.54-0.71,3.59-2.84,2.87c-3.23-1.09-6.26-2.8-9.38-4.24c0-1,0-2,0-3
				c2.48,1.2,4.96,2.4,7.43,3.59c0.15-0.25,0.3-0.5,0.46-0.75c-2.63-2.62-5.26-5.23-7.89-7.85C0.5,364,0.5,363,0.5,362z"/>
			<path d="M971.81,979c-3.3-0.96-6.73-1.65-9.85-3.02c-1.15-0.5-2.19-2.61-2.17-3.97c0.02-1.02,1.64-2.52,2.84-2.91
				c2.97-0.97,6.11-1.43,9.18-2.09c0,1,0,2,0,3c-3.02,0.6-6.05,1.2-9.07,1.81c-0.01,0.64-0.03,1.27-0.04,1.91
				c3.04,0.76,6.07,1.52,9.11,2.29C971.81,977,971.81,978,971.81,979z"/>
			<path onClick={() => props.onFill(74)} fill={props.fillColors[74]}  d="M0.5,928c3.19,2.1,6.37,4.2,9.56,6.3c-0.11,0.54-0.22,1.07-0.33,1.61c-3.08,0.7-6.15,1.4-9.23,2.1
				C0.5,934.67,0.5,931.33,0.5,928z"/>
			<path onClick={() => props.onFill(75)} fill={props.fillColors[75]}  d="M0.5,983c2.57,5.23,5.14,10.46,7.8,15.88c-3.03-1.5-5.41-2.69-7.8-3.88C0.5,991,0.5,987,0.5,983z"/>
			<path onClick={() => props.onFill(76)} fill={props.fillColors[76]}  d="M971.81,808c-5.64,0-11.28,0-16.92,0c5.23-3.1,11.11-2.88,16.92-3C971.81,806,971.81,807,971.81,808z"/>
			<path onClick={() => props.onFill(77)} fill={props.fillColors[77]}  d="M971.81,635c-3.73,1.29-7.46,2.59-11.19,3.88c-0.25-0.46-0.51-0.91-0.76-1.37
				c3.98-2.5,7.97-5.01,11.95-7.51C971.81,631.67,971.81,633.33,971.81,635z"/>
			<path onClick={() => props.onFill(78)} fill={props.fillColors[78]}  d="M971.81,976c-3.04-0.76-6.07-1.52-9.11-2.29c0.01-0.64,0.03-1.27,0.04-1.91c3.02-0.6,6.05-1.2,9.07-1.81
				C971.81,972,971.81,974,971.81,976z"/>
			<path onClick={() => props.onFill(79)} fill={props.fillColors[79]}  d="M971.81,904c-1.91,0.29-3.82,0.58-6.15,0.93c0.67-2.83,1.21-5.1,1.79-7.53c1.44,0.2,2.9,0.4,4.36,0.6
				C971.81,900,971.81,902,971.81,904z"/>
			<path d="M971.81,1020c-3.35,0.22-6.79-0.54-6.65-4.29c0.05-1.32,4.31-2.48,6.65-3.71c0,0.67,0,1.33,0,2
				c-1.1,0.57-2.21,1.14-4.08,2.11c1.85,0.86,2.96,1.38,4.08,1.89C971.81,1018.67,971.81,1019.33,971.81,1020z"/>
			<path onClick={() => props.onFill(80)} fill={props.fillColors[80]}  d="M0.5,1039c1.44,0.22,2.87,0.43,4.56,0.69c-1.59,3.94-3.07,7.63-4.56,11.31C0.5,1047,0.5,1043,0.5,1039z"/>
			<path onClick={() => props.onFill(81)} fill={props.fillColors[81]}  d="M0.5,365c2.63,2.62,5.26,5.23,7.89,7.85c-0.15,0.25-0.3,0.5-0.46,0.75c-2.48-1.2-4.96-2.4-7.43-3.59
				C0.5,368.33,0.5,366.67,0.5,365z"/>
			<path onClick={() => props.onFill(82)} fill={props.fillColors[82]}  d="M0.5,1114c2.51,0.53,3.76,1.91,2.78,4.52c-0.83,2.2-1.84,4.32-2.78,6.48C0.5,1121.33,0.5,1117.67,0.5,1114
				z"/>
			<path onClick={() => props.onFill(83)} fill={props.fillColors[83]}  d="M0.5,852c0.8,2.3,1.9,4.56,2.26,6.92c0.13,0.89-1.46,2.04-2.26,3.08C0.5,858.67,0.5,855.33,0.5,852z"/>
			<path d="M971.81,565c-1.09-1.26-2.19-2.52-3.28-3.78c1.09-0.74,2.19-1.48,3.28-2.22C971.81,561,971.81,563,971.81,565z"/>
			<path onClick={() => props.onFill(84)} fill={props.fillColors[84]}  d="M971.81,1018c-1.11-0.52-2.23-1.03-4.08-1.89c1.87-0.97,2.97-1.54,4.08-2.11
				C971.81,1015.33,971.81,1016.67,971.81,1018z"/>
			<path onClick={() => props.onFill(85)} fill={props.fillColors[85]}  d="M0.5,865c0.88,0.34,1.77,0.68,2.65,1.02c-0.88,0.99-1.77,1.98-2.65,2.98C0.5,867.67,0.5,866.33,0.5,865z"
				/>
			<path onClick={() => props.onFill(86)} fill={props.fillColors[86]}  d="M0.5,917c1.01,0.19,2.02,0.38,4.01,0.76c-1.92,1.08-2.96,1.66-4.01,2.24C0.5,919,0.5,918,0.5,917z"/>
			<path onClick={() => props.onFill(87)} fill={props.fillColors[87]}  d="M0.5,892c0.85,0.51,1.71,1.02,2.56,1.53c-0.85,0.49-1.71,0.98-2.56,1.47C0.5,894,0.5,893,0.5,892z"/>
			<path onClick={() => props.onFill(88)} fill={props.fillColors[88]}  d="M352.18,1098.54c-2.91,0.82-5.22,1.16-7.2,2.15c-1.28,0.65-2.68,2.17-2.88,3.47
				c-0.13,0.87,1.57,2.44,2.78,3.01c3.86,1.83,7.88,3.33,12.56,5.26c-3.5,1.52-6.27,2.37-8.61,3.89c-1.34,0.87-2.85,2.93-2.7,4.27
				c0.15,1.28,2.25,2.47,3.68,3.43c0.92,0.61,2.57,0.39,3.2,1.14c1.05,1.24,1.54,2.95,2.27,4.46c-1.48,0.59-3.14,1.94-4.4,1.63
				c-7.58-1.84-15.12-3.9-22.57-6.21c-6.64-2.06-9.08-6.75-7.71-13.63c0.18-0.9,0.26-2.02-0.12-2.78c-1.62-3.26-3.44-6.42-5.64-10.47
				c5.16-2.18,9.1-3.78,12.98-5.5c4.03-1.79,8.47-3.08,11.91-5.69c7.68-5.83,16.88-6.65,25.65-8.68
				c17.47-4.03,34.08-10.11,49.57-19.16c7.34-4.29,7.73-9.29,1.35-15.03c-9.4-8.46-18.84-16.87-28.13-25.46
				c-2.67-2.46-4.61-5.71-7.3-8.15c-4.74-4.3-9.8-8.26-15.33-12.86c1.81-1.57,3.72-3.56,5.94-5.1c4-2.77,6.01-6.52,7.96-10.99
				c3.37-7.74,4.66-16.46,11.38-22.82c5.02-4.75,3.93-9.67-2.04-13.72c-12.76-8.65-26.97-13.39-42.23-15.31
				c-7.04-0.89-14-2.45-20.99-3.7c0.03-0.42,0.05-0.85,0.08-1.27c29.34-1.11,58.69-2.22,88.03-3.33c0.08-0.78,0.17-1.57,0.25-2.35
				c-3.85-0.76-7.73-2.27-11.56-2.17c-24.6,0.64-49.19,1.64-73.79,2.49c-7.48,0.26-14.96,0.4-22.44,0.57
				c-0.83,0.02-1.73,0.04-2.47-0.25c-1.76-0.7-3.45-1.59-5.17-2.41c1.15-1.21,2.29-2.41,3.97-4.18c-1.36-1.08-2.95-1.91-3.91-3.22
				c-1.05-1.43-1.83-3.25-2.01-4.98c-0.05-0.46,2.5-1.76,3.84-1.74c11.8,0.21,23.59,0.79,35.38,0.91c5.32,0.06,10.65-0.67,16.02-1.95
				c-5.01-5.15-11.73-3.55-17.43-6.44c19.91-0.51,39.82-1.01,59.74-1.54c9.13-0.24,18.27-0.46,27.39-0.87
				c1.6-0.07,3.17-1.04,5.12-1.73c-6.37-10.38-17.01-4.61-24.85-8.33c19.73-1.95,40.24,2.03,60.18-8.47
				c-2.51-1.18-3.69-2.01-4.99-2.3c-5.66-1.25-11.31-2.85-17.06-3.38c-11.7-1.08-23.44-1.76-35.19-2.25
				c-27.05-1.12-54.12-2.05-81.18-3c-4.49-0.16-8.98-0.1-13.47-0.03c-5.13,0.08-10.47,0.95-12.7-5.73c-0.36-1.09-2.29-1.97-3.66-2.34
				c-4.42-1.2-6.23-3.8-5.51-8.4c0.52-3.34,0.02-6.24-4-7.45c-0.88-0.27-1.84-2.39-1.64-3.44c0.18-0.91,1.89-2,3.03-2.15
				c4.12-0.53,8.28-0.84,12.43-0.96c4.21-0.11,8.06,0.61,12.3,2.1c8.82,3.12,18.36,2.65,27.41-0.51c3.45-1.21,4.35-3.56,1.25-6.33
				c-0.83-0.75-1.86-1.27-2.8-1.9c0.21-0.55,0.42-1.11,0.63-1.66c7.66-0.91,15.33-1.75,22.97-2.78c3.77-0.51,7.53-1.24,11.22-2.18
				c1.04-0.27,1.8-1.64,2.69-2.5c-0.93-0.86-1.75-2.25-2.82-2.48c-4.38-0.93-8.83-2.11-13.25-2.11c-25.27-0.01-50.53,0.23-75.8,0.56
				c-3.27,0.04-5.57-0.31-6.01-4.52c1.33,0,2.57,0.07,3.81-0.01c9.88-0.63,19.74-1.67,29.62-1.84c17.96-0.31,35.93-0.11,53.9-0.13
				c3.19,0,6.44,0.36,7.21-4.21c0.13-0.77,2.67-1.65,4.06-1.59c10.5,0.42,20.87,0.22,31.09-2.89c4.18-1.28,8.73-1.31,13.05-2.17
				c3.59-0.72,6.25-2.2,4.61-6.85c-0.91-2.57-1.04-5.42-1.6-8.58c15.86,0,31.64,0,48.01,0c-0.8,0.74-1.01,0.98-1.26,1.15
				c-2.21,1.52-4.43,3.03-6.65,4.54c2.55,1.59,4.92,3.66,7.68,4.65c4.63,1.65,9.51,2.61,14.29,3.86c0.03,0.51,0.07,1.03,0.1,1.54
				c-3.34,0.76-6.67,2.09-10.02,2.15c-7.96,0.16-15.94-0.42-23.91-0.42c-6.46,0-12.94,0.25-19.38,0.73c-1.33,0.1-3.03,1.45-3.63,2.67
				c-0.31,0.63,1.21,2.48,2.24,3.4c1.59,1.43,3.46,2.54,6.5,4.69c-4.9,0.46-8.52,0.73-12.12,1.15c-3.58,0.42-6.92,1.17-4.23,6.93
				c-2.72,0-4.87-0.26-6.91,0.08c-1.84,0.3-3.57,1.25-5.36,1.92c0.88,1.56,1.77,3.13,2.58,4.55c-5.97,0.9-12.31,1.85-18.66,2.81
				c-0.05,0.81-0.1,1.62-0.15,2.43c9.87,2.74,19.74,5.48,29.61,8.22c-0.05,0.29-0.1,0.57-0.14,0.86c-5.73,0.34-11.46,0.55-17.17,1.06
				c-4.03,0.36-4.21,0.84-3.49,4.68c-1.45,1.12-2.77,2.16-4.1,3.19c1.42,1.26,2.67,3.3,4.28,3.65c5.81,1.27,11.71,2.4,17.62,2.78
				c10.27,0.67,20.96-0.91,30.82,1.36c34.79,8,69.55,0.63,104.29,1.24c10.41,0.18,20.83,0.03,31.24,0.03
				c0.01,0.45,0.02,0.9,0.03,1.35c-3.61,0.24-7.22,0.56-10.84,0.71c-9.3,0.38-18.61,0.62-27.9,1.04c-3.58,0.17-6.49,0.99-9.59,3.89
				c-2.69,2.51-7.98,2.56-12.2,3.02c-14.03,1.53-28.1,2.73-42.15,4.14c-2.3,0.23-4.66,0.64-6.79,1.47c-1.35,0.52-2.33,1.99-3.47,3.04
				c1.04,0.97,1.92,2.45,3.14,2.83c2.99,0.93,6.12,1.72,9.23,1.9c14.27,0.84,28.56,1.39,42.84,2.19c6.91,0.39,13.39,1.52,20.03,4.66
				c7.63,3.61,16.61,4.35,25.01,6.31c5.77,1.34,11.56,2.62,17.34,3.92c-0.01,0.53-0.02,1.06-0.03,1.59
				c-4.27,0.66-8.51,1.61-12.81,1.92c-20.89,1.49-41.8,2.73-62.69,4.23c-5.45,0.39-10.89,1.24-16.28,2.22
				c-1.39,0.25-3.42,1.66-3.57,2.75c-0.16,1.13,1.39,2.86,2.62,3.72c1.44,1.02,3.31,1.49,5.05,2.02c6.75,2.06,13.66,3.05,19.75,7.59
				c3.35,2.5,8.84,2.25,13.4,3c3.42,0.56,6.9,0.73,10.6,1.1c-3.48,10.02-9.33,16.95-19.52,19.98c-3.8,1.13-9.1,2.66-8.34,7.75
				c0.68,4.6,5.86,4,9.55,4.29c28.05,2.17,55.64,7.16,82.56,15.16c9.57,2.85,18.4,8.21,26.54,11.96c-2.1,7.29-3.83,13.41-5.65,19.49
				c-0.37,1.25-0.89,2.55-1.68,3.56c-6.49,8.35-7.88,17.79-5.91,27.89c0.99,5.1,0.14,9.69-1.82,14.55
				c-2.01,4.99-3.08,10.47-3.86,15.84c-1.95,13.33-3.42,26.73-5.35,40.06c-0.17,1.2-2.34,3.02-3.56,3c-9.59-0.15-18.9-0.72-28.3-4.23
				c-8-2.99-17.28-2.52-25.97-3.73c-3.27-0.46-6.52-1.18-9.7-2.07c-3.46-0.97-4.32-3.3-2.77-6.56c0.64-1.35,1.22-2.74,1.72-4.15
				c1.4-3.96,0.26-7.23-3.7-8.46c-4.39-1.37-9.01-2.35-13.59-2.7c-13.52-1.01-26.89,0.44-40.11,3.35
				c-10.54,2.32-20.49-0.15-30.19-3.91c-1.59-0.62-2.51-2.97-4.45-5.41c3.79-0.69,6.32-1.28,8.88-1.59
				c4.48-0.55,9.49,0.34,11.49-5.67c0.3-0.9,2.9-1.28,4.48-1.46c9.08-1.02,18.17-1.89,27.25-2.84c0.98-0.1,2.13-0.16,2.88-0.68
				c1.54-1.08,2.87-2.48,4.28-3.74c-1.45-1.14-2.77-3.04-4.36-3.3c-9.16-1.5-18.39-2.63-27.6-3.85c-7.04-0.93-14.08-1.82-21.12-2.73
				c-0.11-0.5-0.22-0.99-0.33-1.49c1.58-0.82,3.07-2.06,4.75-2.39c14.32-2.85,28.59-6.38,43.06-7.95c8.34-0.91,16.88,0.14,24.98-3.02
				c4.4-1.72,4.97-2.41,5.4-8.3c7.35-0.53,14.89-1.04,22.42-1.65c4.13-0.33,8.32-0.53,12.35-1.39c1.61-0.34,2.88-2.31,4.31-3.53
				c-1.41-1.19-2.71-3.21-4.25-3.41c-7.24-0.95-14.57-1.18-21.8-2.14c-3.15-0.42-6.1-2.11-9.22-2.99c-2.99-0.84-6.07-1.91-9.12-1.95
				c-14.47-0.21-28.94-0.08-43.42-0.14c-2.63-0.01-5.27-0.38-7.99-1.58c2.39-0.29,4.77-0.76,7.16-0.84
				c12.63-0.41,25.27-0.71,37.9-1.05c0.83-0.02,1.75,0.15,2.48-0.13c2.89-1.13,5.73-2.41,8.59-3.64c-2.47-2.93-4.65-6.21-7.54-8.64
				c-1.4-1.18-4.13-1.01-6.26-1.04c-7.98-0.12-15.97,0-23.95-0.12c-2.56-0.04-5.11-0.56-7.66-0.87c0-0.43,0-0.85,0-1.28
				c3.22-0.15,6.43-0.3,9.65-0.46c6.14-0.3,12.32-0.38,18.42-1.07c1.7-0.19,3.19-2.21,4.78-3.38c-1.47-1.35-2.75-3.15-4.47-3.93
				c-1.72-0.77-3.93-0.39-5.88-0.75c-1.41-0.26-3.33-0.49-4.01-1.45c-4.17-5.9-10.57-6.78-16.79-7.4
				c-20.74-2.04-41.51-2.06-62.2,0.77c-7.57,1.03-9.1,2.63-9.36,8.78c-4.87,0.29-9.83,0.26-14.7,0.93c-7.66,1.05-8.51,2.3-10.04,9.86
				c-0.29,1.42-1.92,2.97-3.33,3.7c-7.15,3.74-7.35,6.6-1.66,11.97c7.72,7.28,14.83,15.22,22.43,22.63
				c5.6,5.46,11.48,10.62,17.39,15.75c8.67,7.53,8.02,14.8-1.74,20.5c-17.73,10.34-36.97,16.42-56.87,20.84
				c-6.8,1.51-13.53,3.38-20.19,5.42c-1.81,0.55-3.26,2.31-4.87,3.51c1.49,1.16,2.95,2.35,4.48,3.45
				C349.97,1097.64,350.76,1097.88,352.18,1098.54z"/>
			<path onClick={() => props.onFill(89)} fill={props.fillColors[89]}  d="M609.98,1114.87c-0.69,7.99-1.4,15.91-2.03,23.83c-0.34,4.31-0.83,8.64-0.71,12.95
				c0.06,2.27,0.68,5.22,2.2,6.49c0.99,0.84,4.04-0.77,6.63-1.4c-0.36-0.28-0.03-0.14,0.1,0.09c1.08,2.08,2.12,4.19,3.17,6.29
				c2.18-1.28,5.16-2.05,6.39-3.95c2.67-4.12,4.44-8.81,6.58-13.28c0.14,0.32,0.37,0.9,0.64,1.45c1.11,2.25,2.23,4.49,3.34,6.73
				c2.24-1.43,4.8-2.54,6.62-4.38c1.93-1.95,3.16-4.6,4.9-7.27c3.46,3.06,6.5,1.16,10.2,0.55c6.62-1.08,13.83,0.19,20.57,1.58
				c7.59,1.57,14.87,4.62,22.41,6.51c4.86,1.22,7.57,3.67,8.03,8.71c0.43,4.71,2.62,8.1,7.42,9.58c0.97,0.3,1.6,1.7,2.39,2.6
				c-1.08,0.62-2.1,1.59-3.24,1.78c-2.77,0.45-5.75,0.1-8.37,0.94c-1.74,0.56-4.03,2.51-4.17,4.02c-0.13,1.39,2.05,3.98,3.62,4.37
				c7.08,1.73,14.27,3.02,21.48,4.15c7.01,1.1,12.57,4.36,17.84,10.33c-2.43,0.15-3.86,0.31-5.28,0.31
				c-30.61,0.03-61.23-0.49-91.82,0.25c-17.2,0.41-33.67-2.47-50.15-6.54c-14.97-3.7-30.05-6.97-44.99-10.77
				c-16.23-4.13-32.28-8.95-48.54-12.91c-14.17-3.45-28.55-6.02-42.79-9.19c-9.55-2.13-19-4.66-28.51-6.98
				c-19.17-4.67-38.35-9.29-57.52-13.97c-6.1-1.49-12.16-3.12-18.65-4.79c1.87-7.48-1.46-11.23-9.05-11.96
				c0.14-0.66,0.28-1.31,0.42-1.97c2.73-0.93,5.48-1.8,8.17-2.82c1.17-0.44,3.13-1.14,3.16-1.8c0.06-1.23-0.7-3.08-1.7-3.67
				c-4.11-2.43-8.44-4.48-13.67-7.18c2.81-0.65,5-0.94,7.01-1.69c1.85-0.69,3.51-1.88,5.25-2.85c-1.48-1.16-2.89-2.42-4.46-3.45
				c-1.06-0.7-2.33-1.07-4.69-2.1c11.3-2.95,21.43-5.87,31.69-8.21c17.33-3.95,33.57-10.54,49.02-19.22
				c10.16-5.71,11.28-15.84,2.61-23.73c-6.52-5.94-13.24-11.67-19.6-17.77c-7.91-7.58-15.61-15.37-23.16-23.3
				c-0.63-0.66,0.13-4.11,1-4.48c4.64-1.99,7.79-4.35,7.7-10.21c-0.02-1.4,3.94-3.71,6.32-4.12c4.21-0.72,8.62-0.16,12.94-0.42
				c3.08-0.18,6.15-0.55,5.2-5.27c-0.18-0.91,1.77-3.01,3.04-3.32c4.01-1,8.17-1.97,12.27-1.96c18.1,0.04,36.21,0.29,54.3,0.79
				c5.16,0.14,10.02,1.58,13.4,6.44c1.05,1.51,4.06,1.85,6.26,2.32c1.97,0.42,4.06,0.29,6.1,0.41c0.01,0.49,0.02,0.97,0.03,1.46
				c-2,0.29-4,0.73-6.01,0.84c-7.3,0.39-14.62,0.46-21.9,1.13c-1.85,0.17-3.54,2.14-5.3,3.28c1.76,1.26,3.4,3.38,5.29,3.62
				c4.92,0.62,9.95,0.46,14.93,0.53c5.16,0.07,10.31-0.03,15.47,0.1c4.54,0.11,8.44,1.48,10.59,7.15c-4.06,0.41-7.89,1.01-11.74,1.15
				c-12.3,0.44-24.6,0.68-36.89,1.08c-2.14,0.07-4.35,0.32-6.37,0.97c-1.19,0.38-2.07,1.73-3.09,2.64c1.01,0.98,1.86,2.45,3.05,2.82
				c2.18,0.69,4.55,0.99,6.85,1c13.81,0.09,27.61,0.02,41.42,0.09c5.46,0.03,10.59,0.58,15.72,3.53c3.62,2.08,8.64,1.87,13.07,2.32
				c5.57,0.57,11.17,0.79,16.81,1.85c-12.75,4.95-27.21,0.04-39.93,7c0.5,1.45,0.93,2.73,1.5,4.39c-7.17,2.52-14,5.46-21.8,3.87
				c-2.23-0.46-4.69-0.52-6.92-0.11c-13.39,2.47-26.75,5.06-40.09,7.81c-2.54,0.52-5.04,1.7-7.29,3.03
				c-1.09,0.64-2.27,2.45-2.11,3.53c0.16,1.06,1.85,2.45,3.07,2.7c5.68,1.18,11.4,2.2,17.15,2.94c9.06,1.16,18.16,2.02,27.23,3.09
				c1.78,0.21,3.52,0.78,5.27,1.19c0.05,0.48,0.11,0.97,0.16,1.45c-4.03,0.63-8.05,1.41-12.1,1.86c-6.93,0.77-13.93,1.04-20.8,2.15
				c-2.19,0.35-4.04,2.74-6.06,4.16c-1,0.7-2,1.71-3.11,1.91c-2.93,0.53-6.07,0.27-8.86,1.16c-2.22,0.71-5.9,3.03-5.71,3.96
				c0.57,2.76,2.16,6.59,4.38,7.52c10.83,4.5,21.98,7.04,33.99,4.25c14.13-3.28,28.51-4.88,43.05-2.63c1.47,0.23,2.96,0.43,4.39,0.83
				c5.7,1.57,6.37,3,4.1,8.52c-3.16,7.68-1.96,10.48,6.12,12.72c3.81,1.06,7.83,1.62,11.78,1.85c9.12,0.52,17.86,1.58,26.72,4.78
				C591.51,1113.85,600.84,1113.64,609.98,1114.87z"/>
			<path onClick={() => props.onFill(90)} fill={props.fillColors[90]}  d="M911.65,807.42c-1.68,0.19-3.36,0.63-5.03,0.55c-12.62-0.6-25.23-1.46-37.86-1.9
				c-9.62-0.34-19.29,0.29-28.87-0.42c-8.23-0.61-16.39-2.38-24.53-3.9c-2.51-0.47-4.21-1.6-3.69-5.24c0.24-1.71-2.66-5.1-4.71-5.68
				c-7.47-2.12-15.14-4.37-22.82-4.72c-13.23-0.6-26.53,0.18-39.8,0.4c-7.82,0.13-15.65,0.06-23.44,0.55
				c-2.67,0.17-5.24,1.79-7.86,2.75c2.71,1.55,5.27,3.54,8.17,4.5c2.22,0.73,4.87,0.15,7.83,1c-1.14,0.56-2.27,1.11-3.41,1.67
				c0.14,0.57,0.27,1.14,0.41,1.7c3.85,1.02,7.7,2.04,11.83,3.13c-1.53,3-3.08,6.05-5.23,10.27c4.15,0.55,7.89,1.04,12.11,1.6
				c-0.11,4.1,3.25,5.33,6.61,5.16c7.03-0.36,14.02-1.39,21.02-2.17c2.47-0.28,4.95-0.52,7.39-0.99c3.77-0.72,4.65-2.36,0.79-5.36
				c6.62-0.19,12.36-0.13,15.7,6.47c0.58,1.16,2.68,1.89,4.2,2.16c4.25,0.74,8.56,1.12,13.38,1.71c-1.28,3.02-2.39,5.39-3.29,7.83
				c-1.9,5.15-4.18,10.22-5.41,15.53c-2.64,11.39-0.16,15.23,8.54,20.74c8.63,5.46,16.08,12.79,23.97,19.4
				c1.34,1.12,2.27,2.73,3.59,4.36c-4.53,2.98-8.5,6-12.86,8.27c-1.76,0.91-4.74,0.96-6.52,0.09c-8.92-4.37-18.19-4.76-27.76-3.56
				c-1.05,0.13-2.53,0.1-3.19-0.51c-6.06-5.69-13.84-5.92-21.36-6.98c-7.39-1.04-14.82-1.84-22.18-3.04
				c-2.29-0.37-4.42-1.73-7.97-3.2c4.37-1.96,7.41-3.13,10.28-4.65c4.88-2.58,5.18-6.27,0.34-8.96
				c-12.66-7.03-25.91-11.93-40.85-10.48c-5.75,0.56-11.76,1.78-18.31-1.96c4.67-0.51,8.3-0.65,11.8-1.38
				c2.08-0.43,3.96-1.78,5.93-2.72c-1.47-1.39-2.8-3-4.45-4.15c-14.58-10.12-31.25-13.3-48.52-14.43c-1.8-0.12-3.59-0.66-5.35-1.13
				c-1.25-0.33-2.44-0.87-3.63-2.31c4.34-0.37,8.68-0.83,13.02-1.1c7.63-0.49,15.28-0.69,22.89-1.42c1.77-0.17,3.39-1.98,5.07-3.04
				c-1.47-1.16-2.81-3.07-4.44-3.37c-9.45-1.77-18.92-4.02-28.47-4.52c-22.74-1.18-45.52-1.42-68.29-2.19
				c-3.6-0.12-7.17-1.09-10.75-1.67c0.05-0.64,0.09-1.28,0.14-1.92c7.87-0.27,15.74-0.52,23.62-0.84c2.32-0.09,4.67-0.16,6.92-0.66
				c1.13-0.25,2.68-1.3,2.89-2.24c0.2-0.9-0.84-2.68-1.78-3.13c-4.28-2.05-8.72-3.76-13.52-5.77c2.69-0.15,5.21-0.1,7.65-0.53
				c1.11-0.19,2.07-1.32,3.09-2.02c-0.85-1.07-1.55-2.88-2.56-3.07c-4.55-0.85-9.17-1.55-13.79-1.73
				c-6.91-0.27-13.84-0.07-20.76-0.07c0-0.62,0-1.24,0-1.86c8.59,0,17.19-0.02,25.78,0.01c4,0.01,6.27-1.88,4.81-5.75
				c-0.6-1.58-3.37-3.07-5.29-3.26c-5.28-0.53-10.66-0.05-15.94-0.57c-2.72-0.27-5.3-1.86-7.96-2.81c-2.62-0.94-5.26-1.81-8-3.18
				c13.69,1.47,26.54-3.23,40.05-3.3c0.04-0.95,0.08-1.89,0.13-2.84c-1.99-0.59-3.95-1.52-5.98-1.7c-9.1-0.84-18.21-1.46-27.34-2.59
				c14.33-0.65,28.65-1.26,42.98-1.98c2.96-0.15,5.96-0.44,8.83-1.11c1.22-0.29,2.17-1.74,3.25-2.66c-0.94-1.06-1.77-2.26-2.86-3.13
				c-0.98-0.77-2.25-1.16-3.25-2.61c27.3,0,54.6,0,81.91,0c0,0.38,0,0.76,0.01,1.13c-5.81,0-11.62-0.23-17.42,0.06
				c-5.6,0.28-11.45-0.31-16.6,2.51c-0.09,0.52-0.19,1.04-0.28,1.56c1.99,0.77,3.93,2.01,5.99,2.25c12.37,1.45,24.78,2.63,37.16,3.99
				c5.94,0.66,11.89,1.35,17.76,2.41c1.65,0.3,3.07,1.92,4.6,2.93c-0.17,0.57-0.35,1.14-0.52,1.71c-5.33,0.83-10.66,1.59-15.98,2.51
				c-2.77,0.48-5.56,1.04-8.21,1.93c-1.08,0.36-2.61,1.75-2.56,2.58c0.07,1.21,1.16,2.99,2.23,3.39c3.87,1.43,7.86,2.73,11.91,3.47
				c13.27,2.44,26.56,3.72,40.15,2.3c15.02-1.57,29.77,1.73,44.52,4.19c5.24,0.87,10.47,1.81,15.64,3c1.99,0.46,3.71,1.16,3.64,4.35
				c-0.04,1.73,2.82,4.73,4.76,5.1c9.26,1.77,18.62,3.53,28.01,4c17.43,0.87,34.91,0.84,52.36,1.21c1.91,0.04,3.81,0.22,5.72,0.33
				C911.59,806.23,911.62,806.82,911.65,807.42z"/>
			<path onClick={() => props.onFill(91)} fill={props.fillColors[91]}  d="M486.33,1197.89c-3.31,0-5.9,0-8.49,0c-34.76,0.01-69.53-0.1-104.29,0.15c-5.09,0.04-6.98-1.59-7.61-6.49
				c-0.68-5.27-2.27-10.42-3.46-15.62c-0.75-0.04-1.5-0.08-2.25-0.12c-2.95,7.16-5.89,14.33-8.84,21.49
				c-0.39,0.04-0.78,0.09-1.16,0.13c-0.09-2.98,0.02-5.99-0.33-8.94c-0.55-4.55-1.42-9.06-2.15-13.59c-1.1,0.25-2.93,0.21-3.18,0.81
				c-0.81,1.96-0.92,4.2-1.51,6.27c-1.2,4.21-2.8,8.33-3.75,12.59c-0.94,4.17-4.07,3.89-6.51,3.02c-2.49-0.88-4.62-3.06-3.36-6.5
				c0.33-0.89,0.7-2.5,0.32-2.78c-2.8-2.02-1.51-4.75-1.92-7.32c-0.71-4.49-2.91-8.72-4.35-13.11c-0.62-1.88-0.66-3.99-1.45-5.77
				c-0.34-0.76-1.94-0.95-2.96-1.4c-1.42,3.29-3.47,6.47-4.09,9.91c-0.7,3.84,0.64,8.07-4.16,10.42c-0.78,0.38-0.48,3.26-0.44,4.97
				c0.1,3.76,0.35,7.51-0.21,11.31c-2.87-11.09-5.73-22.18-8.6-33.27c-0.77,0.01-1.53,0.03-2.3,0.04c-1.09,4.28-2.17,8.56-3.26,12.84
				c-0.25-0.01-0.49-0.01-0.74-0.02c-0.66-5.02-1.33-10.04-1.99-15.06c-0.54-0.08-1.08-0.15-1.62-0.23c-0.61,1.3-1.55,2.55-1.79,3.92
				c-1.33,7.43-1.05,15.12-6.35,21.65c-1.59,1.96-0.21,6.32-0.24,9.57c0,0.27-0.32,0.53-1.51,2.36c-0.92-3.05-2.39-5.25-1.93-6.91
				c1.22-4.37,3.04-8.26-1.35-12.18c-1.3-1.16-1.99-4.72-1.19-6.23c2.48-4.63-2.98-7.02-2.46-10.97c0.08-0.62-1.71-1.82-2.8-2.11
				c-0.43-0.12-1.48,1.43-1.98,2.35c-0.98,1.8-2.07,3.64-2.56,5.59c-0.43,1.7-0.09,3.59-0.09,5.7c-3.95,0.52-3.16,3.27-3.21,6.75
				c-0.07,5.26-1.55,10.59-3.09,15.68c-0.28,0.92-3.73,0.87-4.58,1.05c-4.35-10.14-8.33-19.48-12.38-28.79
				c-1.15-2.64-3.05-2.89-4.22-0.29c-1.27,2.82-1.72,6-2.61,9c-1.14,3.88-2.36,7.75-3.96,11.55c-1.46-8.43-1.23-17.14-7.05-25.58
				c-2.4,7.12-4.44,13.2-6.49,19.27c-0.46-0.02-0.93-0.05-1.39-0.07c-1.16-7.34-2.33-14.69-3.49-22.03
				c-0.71-0.05-1.42-0.09-2.12-0.14c-1.68,4.27-3.36,8.53-5.22,13.27c-1.06-1.12-1.72-2.02-2.57-2.67c-0.72-0.56-1.84-1.36-2.46-1.14
				c-1.63,0.59-4.17,2.7-4.44,2.41c-3.38-3.58-6.49-7.42-9.5-11.33c-0.89-1.16-1.23-2.75-2.22-5.08c-2.49,4.36-4.39,7.68-6.51,11.4
				c-1.57-1.01-2.89-1.87-4.58-2.96c1.29-2.36,2.42-4.43,3.32-6.07c-1.69-2.54-3.23-5.02-4.96-7.37c-0.5-0.68-1.82-1.55-2.29-1.33
				c-1.43,0.68-3.45,1.64-3.77,2.87c-0.96,3.71-1.22,7.6-1.76,11.43c0.49,0.25,0.98,0.49,1.48,0.74c-1.63,0.53-3.26,1.05-5.07,1.64
				c-1.63-3.24-3.22-6.37-5.15-10.21c-2.86,4.82-5.22,8.82-8.27,13.97c-1.24-2.29-2.02-3.73-3.38-6.24
				c-3.13,4.28-5.37,7.35-7.68,10.52c-2.1-5.19-4.26-10.51-6.14-15.13c7.63-3.17,14.8-5.46,21.27-9.02
				c9.17-5.04,8.66-13.09,0.13-19.15c-4.87-3.46-10.29-6.15-11.66-13.97c-1.4-7.95-9.3-11.39-17.05-13.31
				c-1.9-0.47-3.67-1.49-5.49-2.26c0.18-0.52,0.35-1.05,0.53-1.57c1.94,0.41,3.94,0.65,5.83,1.25c21.34,6.72,42.57,13.8,64.02,20.13
				c12.07,3.56,24.6,5.57,36.76,8.84c11.04,2.97,21.77,7.08,32.81,10.04c20.99,5.63,42.05,11.02,63.2,16.04
				c9.83,2.33,20.02,3.18,29.89,5.39c10.99,2.45,21.73,5.97,32.7,8.51c12.6,2.92,25.37,5.14,37.99,7.96
				c6.44,1.44,12.74,3.48,19.07,5.36c14.32,4.24,28.62,8.55,42.92,12.86C484.98,1196.73,485.31,1197.14,486.33,1197.89z"/>
			<path onClick={() => props.onFill(92)} fill={props.fillColors[92]}  d="M774.79,1097.51c10.95-2.17,21.81-0.49,32.73,1.69c9.92,1.98,19.94,3.41,29.93,5.04
				c3.96,0.65,5.39,3.84,3.17,7.26c-3.8,5.84-1.84,10.44,5.27,11.5c4.82,0.72,9.45,0.33,12.82,5.55c1.34,2.07,6.41,1.72,9.79,2.45
				c1.78,0.38,3.57,0.7,5.35,1.05c-0.01,0.51-0.02,1.01-0.03,1.52c-2.85,0.4-5.69,0.97-8.55,1.18c-6.3,0.45-12.62,0.56-18.9,1.17
				c-1.53,0.15-2.92,1.67-4.38,2.56c1.17,1.25,2.18,3.35,3.53,3.59c4.86,0.85,9.83,1.41,14.77,1.46c9.64,0.1,19.28-0.26,28.92-0.34
				c1.74-0.02,3.48,0.36,5.23,0.55c0.01,0.43,0.02,0.86,0.03,1.28c-4.5,0.55-8.99,1.26-13.51,1.61c-10.43,0.8-20.88,1.4-31.32,2.16
				c-2.14,0.16-4.34,0.41-6.35,1.1c-1.42,0.48-2.56,1.78-3.82,2.71c1.22,1.19,2.24,2.88,3.7,3.46c2.1,0.83,4.51,1.13,6.79,1.17
				c6.98,0.12,13.97-0.09,20.95,0.05c2.55,0.05,5.09,0.74,7.63,1.14c0,0.49,0,0.98,0,1.46c-3.47,0.33-7.01,0.33-10.38,1.1
				c-2.33,0.53-4.42,2.11-6.62,3.22c2.06,1.66,3.92,4.28,6.23,4.77c4.48,0.95,9.22,0.87,13.85,0.91c5.05,0.04,9.49,1.19,14.22,3.41
				c7.33,3.44,15.36,5.59,23.28,7.48c4.1,0.98,8.62,0.01,12.92,0.35c2.67,0.21,5.28,1.25,7.93,1.92c-1.02,2.93-1.75,5.02-2.47,7.1
				c-0.11,0.31-0.12,0.8-0.33,0.91c-4.61,2.31-9.21,6.47-13.87,6.55c-32.58,0.56-65.18,0.28-97.77,0.22c-1.63,0-3.34-0.24-4.87-0.79
				c-13.75-4.92-27.47-9.93-41.19-14.93c-0.89-0.33-1.72-0.84-3.4-1.67c5.29-1.02,9.72-1.88,14.15-2.73
				c0.01-0.61,0.02-1.22,0.03-1.82c-6.2-6.15-16.05-5.17-23.74-11.54c3.57-0.87,6.13-1.85,8.76-2.07c6.95-0.58,13.97-0.48,20.87-1.38
				c2.71-0.35,7.24-2.52,7.34-4.1c0.26-4.1-4.03-4.02-7.08-4.15c-8.46-0.37-16.95-0.32-25.4-0.84c-2.18-0.14-4.24-1.86-6.4-2.74
				c-2.85-1.16-5.67-2.59-8.65-3.18c-4.13-0.82-8.39-0.97-12.68-2.54c1.81-0.39,3.6-1.01,5.42-1.12c7.13-0.42,14.28-0.57,21.41-1.03
				c3.97-0.26,10.38,0.64,10.34-4.13c-0.04-5.58-6.61-4.03-10.58-4.08c-6.8-0.09-13.61,0.54-20.41,0.77
				c-1.7,0.06-3.42-0.27-5.13-0.42c-0.06-0.48-0.12-0.97-0.18-1.45c1.6-0.49,3.23-0.91,4.8-1.48c1.38-0.49,3.47-0.78,3.92-1.77
				c2.37-5.12,6.86-4.36,11.09-4.58c4.31-0.23,8.71-0.18,12.88-1.08c1.96-0.43,3.51-2.81,5.24-4.31c-1.85-1.29-3.97-3.94-5.5-3.64
				c-6.62,1.3-7.67-4.2-11.09-8.35c2.19-0.19,3.94-0.06,5.5-0.54c2.42-0.75,4.71-1.92,7.05-2.91
				C776.99,1101.35,775.92,1099.49,774.79,1097.51z"/>
			<path onClick={() => props.onFill(93)} fill={props.fillColors[93]}  d="M611.05,423.68c7.93-6.59,14.78-14.73,25.94-14.49c-0.36-5.82,1.77-9.1,6.99-11.05
				c2.33-0.87,4.9-1.19,7.09-2.29c1.41-0.71,2.34-2.38,3.49-3.62c-1.33-1.06-2.56-2.84-4.01-3.04c-4.76-0.66-9.6-0.75-14.41-1.07
				c-1.77-0.12-3.53-0.26-5.29-0.4c-0.11-0.58-0.22-1.15-0.34-1.73c3.63-0.95,7.21-2.11,10.89-2.77c3.62-0.65,7.32-0.52,8.53-5.3
				c0.32-1.27,2.42-2.06,3.66-3.13c4.26-3.68,8.96-6.98,12.61-11.2c2.59-2.99,3.74-7.23,6.06-11.98c7.42,1.22,16.5,2.69,25.58,4.19
				c8.14,1.34,16.24,3.06,24.44,3.97c10.37,1.15,20.8,1.78,31.22,2.34c2.15,0.12,4.37-1.03,6.56-1.59c-0.01-0.6-0.02-1.2-0.03-1.8
				c-1.73-0.67-3.41-1.58-5.2-1.97c-11.81-2.58-23.58-5.41-35.48-7.46c-12.9-2.22-25.97-3.41-38.88-5.59
				c-8.93-1.51-17.76-3.73-26.55-5.98c-10.77-2.76-21.44-5.88-32.15-8.85c-0.73-0.2-1.44-0.49-2.2-1.98c2.47-0.32,5-1.14,7.41-0.88
				c13.36,1.43,26.81,2.45,40,4.88c27.9,5.15,55.59,11.47,83.51,16.51c11.88,2.14,24.11,2.3,36.19,3.25
				c1.38,0.11,2.82-0.51,4.56-1.97c-1.26-0.71-2.45-1.77-3.8-2.06c-9.41-2.05-18.84-3.99-28.28-5.85c-3.55-0.7-7.4-0.64-8.6-5.19
				c-0.11-0.43-0.62-0.77-0.97-1.12c-6.51-6.65-6.59-6.85,2.42-10.43c3.78-1.5,7.95-2.14,12-2.83c4.42-0.75,8.9-1.07,13.35-1.61
				c17.77-2.17,34.63,1.19,51.57,8.13c-6.47,1.81-14.14,1.11-19.18-0.85c-3.03-1.18-6.38-2.05-9.58-2.03
				c-1.97,0.01-4.44,1.54-5.73,3.15c-0.94,1.18-1.05,4.12-0.19,5.32c0.69,0.96,3.39,0.66,5.18,0.56c0.87-0.05,1.7-0.93,3.05-1.72
				c1.05,1.5,1.85,3.15,3.09,4.33c2,1.91,4.11,3.82,6.48,5.19c4.69,2.7,9.79,4.74,14.37,7.61c4.59,2.88,9.08,4.09,14.6,3.31
				c8.85-1.24,17.81-2.15,26.73-2.28c13.89-0.2,26.82-4.25,39.78-8.51c0.91-0.3,1.81-0.64,3.29-1.16
				c-3.66,5.34-7.83,8.99-13.52,10.49c-14.54,3.84-29.11,7.59-43.67,11.37c-0.25-0.55-0.49-1.11-0.74-1.66
				c2.01-1.73,4.01-3.46,6.02-5.19c-0.01-0.52-0.01-1.04-0.02-1.55c-2.7-0.32-5.43-1.08-8.09-0.88c-10.41,0.78-20.82,1.61-31.17,2.95
				c-5.17,0.67-10.16,2.62-15.28,3.76c-4.8,1.08-9.66,1.95-14.52,2.74c-3.49,0.57-7.52-0.34-9.27,4.2c-0.21,0.55-1.34,0.85-2.1,1.08
				c-12.05,3.7-23.97,7.98-36.2,10.89c-14.91,3.55-28.96,8.86-42.52,15.96c-13.71,7.17-28.75,10.07-44.01,12
				c-4.38,0.56-8.78,1.86-12.88,3.54c-9.78,4-19.38,8.44-29.07,12.66C612.95,423.38,611.97,423.47,611.05,423.68z"/>
			<path onClick={() => props.onFill(94)} fill={props.fillColors[94]}  d="M361.82,554.37c4.69,1.5,9.38,3,14.06,4.51c4.27,1.38,8.57,2.7,12.8,4.21c1.3,0.46,2.32,1.64,3.6,2.19
				c5.1,2.17,10.24,4.24,15.4,6.25c0.42,0.16,1.14-0.43,2.32-0.93c-0.93-1.27-1.6-2.28-2.37-3.22c-4.9-5.97-9.8-11.94-14.78-17.83
				c-0.68-0.8-1.83-1.22-2.78-1.78c-1.41-0.82-3.15-1.33-4.21-2.47c-4.01-4.31-7.81-8.83-11.68-13.28c6.88-2.67,9.71-1.94,16.46,4.28
				c0.6,0.55,1.18,1.79,1.64,1.73c6.88-0.92,8.34,5.98,12.66,8.72c4.73,2.99,8.98,6.87,13.96,9.3c2.56,1.25,6.25,0.18,9.43,0.19
				c0.83,0,1.83-0.14,2.45,0.25c4.14,2.64,8.22,2.89,10.72,0.8c3.91,1.77,7.69,2.3,9.25,4.45c2.55,3.5,8.75,3.02,8.99,8.81
				c0.05,1.14,1.51,2.73,2.69,3.21c3.78,1.55,7.65,3.01,11.63,3.85c2.93,0.62,6.08,0.23,9.12,0.23c2.63,0,5.25-0.15,7.88-0.13
				c3.97,0.03,8.09,0.84,11.88,0.05c4.78-1,8.18,0.52,12,2.98c2.69,1.73,6.3,2,9.47,3.01c2.48,0.8,4.94,1.68,7.37,2.63
				c1.68,0.66,3.25,2.05,4.93,2.17c10.46,0.74,20.95,1.03,31.41,1.82c6.7,0.51,13.35,1.69,20.03,2.44c5.97,0.67,11.97,1.2,17.96,1.7
				c2.14,0.18,4.38-0.25,6.44,0.21c12.28,2.75,24.74,0.28,36.94,1.39c5.97,0.54,12.67-0.94,18.46,2.74c1.65,1.05,4.16,0.85,6.29,0.99
				c4.89,0.32,9.78,0.58,14.67,0.71c4.41,0.11,8.83-0.17,13.23,0.04c9.66,0.46,19.31,1.06,28.95,1.75c1.23,0.09,2.36,1.12,3.59,1.55
				c1.69,0.59,3.56,1.7,5.15,1.41c10.72-1.93,20.86,0.34,31.13,3.13c9.08,2.47,18.07,0.97,26.87-2.26c2.15-0.79,4.8-0.17,7.21-0.34
				c4.17-0.3,8.34-0.7,12.5-1.08c2-0.18,4.24-1.12,5.94-0.51c5.61,2.03,11.03,4.61,16.83,7.11c-9.48,0-18.2,0.05-26.91-0.03
				c-3.15-0.03-6.31-0.58-9.45-0.46c-2.41,0.09-5.57,0.05-7.06,1.47c-5.03,4.78-11.54,4.26-17.29,4.82
				c-7.4,0.72-15.01-0.92-22.54-1.32c-4.38-0.23-8.91-0.67-13.13,0.18c-5.64,1.14-10.65-0.25-16.06-1.19
				c-6.11-1.06-12.68-2.3-18.85,1.1c-1.58,0.87-3.86,0.87-5.77,0.71c-5.62-0.46-11.37-0.59-16.79-1.96
				c-9.76-2.45-19.35-0.35-29.02-0.22c-2.86,0.04-6.46-2.35-8.44-1.27c-2.91,1.58-5.43,1.01-8.19,1.21
				c-6.83,0.51-13.64,1.43-20.47,1.85c-2.19,0.13-4.44-1.17-6.67-1.17c-6.31,0-12.65,3.92-18.95-1.12c-1.91-1.53-5.39-1.2-8.17-1.54
				c-5.84-0.71-11.74-1-17.51-2.04c-5.65-1.02-11.18-2.77-16.77-4.17c-0.8-0.2-1.84-0.59-2.43-0.26c-6.19,3.42-12.26,0.64-18.39-0.1
				c-2.62-0.32-5.3,0.02-7.95-0.1c-1.19-0.05-2.98-0.09-3.45-0.81c-2.55-3.95-5.26-2.29-7.91-1.77c-2.79-0.51-5.59-0.94-8.35-1.56
				c-1.24-0.28-2.44-0.84-3.58-1.42c-5.3-2.66-7.26-2.63-10.23-0.55c-4.35-0.51-7.66-0.74-10.88-1.34c-2.49-0.47-5.78-0.67-7.15-2.31
				c-2.58-3.08-5.48-3.6-8.85-3.16c-10.27,1.36-19.82-1.85-28.92-5.59c-3.88-1.59-6.99-2.99-11.03-1.79
				c-0.77,0.23-1.94-0.18-2.66-0.68c-3.17-2.17-5.99-5-9.39-6.65c-11.62-5.62-23.41-10.87-35.18-16.18
				c-4.68-2.11-9.46-3.99-14.2-5.98C361.73,555.45,361.77,554.91,361.82,554.37z"/>
			<path onClick={() => props.onFill(95)} fill={props.fillColors[95]}  d="M921.06,472.21c-1.27,5.54-9.12,8.77-3.17,15.6c-3.11,11.24-5.24,24-17.95,31.43
				c0.43-1.36,0.69-2.5,1.14-3.56c0.45-1.06,1.74-2.2,1.54-3.03c-2.01-8.32,3.27-15.47,3.55-23.4c0.29-8.15-4.87-11.74-12.53-8.78
				c-5.07,1.96-10.3,3.53-15.42,5.38c-1,0.36-1.82,1.2-2.72,1.82c0.79,0.93,1.51,1.94,2.41,2.74c0.45,0.4,1.22,0.49,1.86,0.64
				c0.99,0.23,15.33,6.26,15.39,6.87c0.22,2.16,0.89,4.92-0.12,6.45c-3.24,4.92-6.71,9.86-10.95,13.89
				c-3.64,3.46-8.41,5.77-12.78,8.43c-3.84,2.34-7.82,4.45-11.75,6.65c0.21,0.64,0.43,1.29,0.64,1.93c2.69,0,5.39,0,9.7,0
				c-5.73,4.22-10.27,7.82-15.07,11.04c-9.68,6.5-19.51,11.73-31.81,12.98c-10.14,1.04-19.9,5.92-29.82,9.13
				c-1.41,0.46-2.73,1.18-4.14,1.62c-15.16,4.74-30.19,9.97-45.55,13.97c-9.71,2.53-19.89,3.25-29.87,4.68
				c-2.29,0.33-4.75-0.15-6.95,0.43c-9.25,2.42-18.2,1.4-27.37-0.85c-5.94-1.46-12.33-1.01-18.41-2.03
				c-4.13-0.69-7.69-3.21-12.48-1.94c-2.05,0.55-5.02-2.36-8.02-3.93c10.38-6.4,20.55-12.67,30.71-18.93c0.4,0.19,0.8,0.37,1.2,0.56
				c-1.21,2.78-2.42,5.57-3.99,9.18c1.92,0,3.73,0.5,5.07-0.09c5.15-2.26,10.25-4.67,15.17-7.39c5.94-3.29,11.67-6.94,17.5-10.44
				c0.35,0.5,0.71,1,1.06,1.51c-11.16,8.41-22.32,16.81-34.42,25.93c2.72,0,4.61,0.38,6.28-0.06c10.47-2.74,20.74-5.77,29.3-13.07
				c3.48-2.97,7.89-4.91,12.05-6.99c8.44-4.22,16.99-8.22,25.65-12.38c-1.05,0.93-2.12,1.91-3.23,2.84
				c-1.15,0.96-2.33,1.88-3.52,2.79c-7.92,6.08-15.89,12.09-23.72,18.29c-1.9,1.5-3.29,3.64-4.91,5.49c0.32,0.47,0.63,0.95,0.95,1.42
				c1.7-0.17,3.47-0.07,5.07-0.56c11.4-3.44,21.89-8.49,31.14-16.27c10.82-9.1,22.08-17.68,33.21-26.41
				c2.41-1.89,5.04-3.49,7.96-4.76c-3.83,4.02-7.65,8.04-11.48,12.07c0.51,0.62,1.02,1.23,1.53,1.85c5.23-3.17,10.46-6.34,15.7-9.51
				c-11.15,14.99-26.67,24.97-41.64,35.58c0.34,0.71,0.68,1.43,1.01,2.14c2.29-0.56,4.83-0.64,6.81-1.75
				c10.29-5.77,20.38-11.9,30.66-17.7c5.65-3.19,10.71-7.15,17.89-7.78c4.69-0.41,9.07-4.33,13.83-6.8
				c-1.14,2.22-2.13,4.16-3.12,6.09c0.32,0.52,0.63,1.04,0.95,1.56c5.57-1.99,11.15-3.98,16.72-5.96c2.51,5.11,4.12,5.54,9.05,2.85
				c3.42-1.86,6.98-3.45,10.67-5.25c0,0.04,0.18-0.47,0.05-0.56c-5.55-4.08-4.88-8.97-2.23-14.41c0.67-1.38,0.07-3.39,0.05-5.11
				c-1.74,0.04-3.62-0.34-5.2,0.19c-5.62,1.85-11.05,4.33-16.74,5.96c-4.68,1.34-9.6,1.79-15.45,2.81c0.91-2.18,1.44-3.79,2.22-5.28
				c3.6-6.93,7.26-13.84,10.92-20.74c0.54-1.03,0.97-2.27,1.83-2.95c7.72-6.1,14.95-13.11,23.43-17.88
				c11.79-6.64,20.32-15.69,25.99-27.66c1.41-2.98,3.36-4.5,6.15-3.22c10.4,4.79,20.98,1.22,31.48,1.12c1.72-0.02,3.44,0,5.97,0
				c-2.93,4.92-12.24,7.69-3.54,13.99c-4.56,0.36-9.26,0.39-13.81,1.25c-1.91,0.36-3.5,2.48-5.23,3.81c1.97,0.82,3.9,2.15,5.93,2.36
				C913.09,472.48,916.89,472.21,921.06,472.21z"/>
			<path onClick={() => props.onFill(96)} fill={props.fillColors[96]}  d="M440.95,612.24c-6.87-1.32-12.73-2.94-18.69-3.44c-4.24-0.36-8.61,0.94-12.93,1.14
				c-1.16,0.05-2.59-1.01-3.52-1.95c-2.76-2.79-4.26-6.88-10-3.47c-2.48,1.47-7.75-0.29-11.05-1.97c-7.33-3.73-7.99-3.9-11.79,2.57
				c-25.24-5.57-43.14-23.61-64.74-37.92c10.67,2.33,20.17,3.7,28.13,10.27c5.82,4.8,12.44,8.64,18.8,12.77
				c1.5,0.97,3.39,1.36,5.09,2.02c0.27-0.4,0.54-0.81,0.8-1.21c-5.18-6.07-10.36-12.14-16.03-18.79c1.97,0.93,3.43,1.73,4.97,2.31
				c6.65,2.49,13.2,5.48,20.06,7.12c3.58,0.86,7.76-0.57,11.64-1.19c9.35-1.48,17.51,2.73,26.08,5.15c4.52,1.28,9.49,3.11,13.84,2.32
				c5.88-1.06,10.82,1.09,15.3,3.13c8.97,4.08,18.13,4.54,27.57,4.58c1.23,0,3,0.06,3.61,0.82c3.42,4.23,7.85,5.08,12.92,4.83
				c1.65-0.08,3.33,0.98,5.03,1.34c1.4,0.29,3.42,0.99,4.2,0.34c3.48-2.91,6.33-1.33,9.53,0.41c1.46,0.8,3.38,0.77,5.1,1.06
				c0.94,0.15,2.38-0.21,2.78,0.3c2.66,3.38,5.55,3.16,8.83,0.21c-0.07,0.73-0.13,1.46-0.2,2.2c2.91,0.39,5.81,0.84,8.73,1.15
				c2.3,0.25,4.64,0.15,6.91,0.5c6.03,0.94,11.92,2.79,18.15,0.5c1.77-0.65,4.53-0.11,6.24,0.89c6.79,3.98,14.7,3.19,21.85,5.53
				c1.67,0.55,3.77,0.68,5.42,0.17c4.7-1.46,9.04-0.6,12.85,2c5.79,3.94,11.77,1.09,17.66,1.14c4.41,0.04,10.03-2.03,12.99-0.03
				c8.23,5.57,18.21,3.46,26.72,7.66c2.67,1.31,7.9,4.82,11.87,0.06c0.27-0.33,2,0.59,3.06,0.89c2.7,0.75,5.58,1.09,8.06,2.29
				c5.49,2.66,12.51-1.68,17.24,3.95c4.54-1.29,9.95,1.99,13.12,0.38c5.95-3.03,11.21-2.97,16.51-0.46c5.34,2.54,10.77,0.27,16,1.53
				c1.17,0.28,2.33,0.77,3.36,1.38c5.17,3.09,10.3,6.25,15.56,9.46c-2.91,1.98-5.56,3.49-9.77,0.24c-2.9-2.24-7.37-2.62-11.22-3.44
				c-6.4-1.37-12.84-3.19-19.31-3.42c-7.23-0.26-14.51,1.22-21.79,1.56c-2.92,0.14-5.89-1-8.85-1.11
				c-6.31-0.25-12.63-0.26-18.95-0.27c-2.55,0-5.45,1.11-7.59,0.24c-5.07-2.04-10.56-2.01-15.67-4.89
				c-4.51-2.54-10.96-1.59-16.54-2.24c-1.96-0.23-4.13-0.16-5.79-1.02c-4.43-2.29-8.87-2.68-13.79-2.1
				c-3.31,0.39-6.8-0.59-10.18-1.13c-10.73-1.73-21.41-3.8-32.19-5.18c-4.31-0.55-8.81,0.39-13.23,0.62
				c-4.44,0.23-9.24-0.58-13.25,0.85c-9.01,3.19-16.49-1.78-24.65-3.12c-8.78-1.44-17.67-2.36-26.54-3.08
				c-5.09-0.41-10.83,1.5-15.21-0.27c-5.47-2.22-12.38,0.1-16.82-6.47C454.48,607.85,445.82,606.4,440.95,612.24z"/>
			<path onClick={() => props.onFill(97)} fill={props.fillColors[97]}  d="M274.29,779.51c-1.3-2.59-3.91-2.9-7.63-1.16c-2.43,1.14-5.35,1.13-7.36-2.08
				c-0.64-1.02-3.65-1.5-5.04-0.92c-6,2.5-14.19,0.06-19.3-3.68c-3.31-2.42-8.67-2.15-13.16-2.77c-2.57-0.36-5.25,0.08-7.85-0.18
				c-2.85-0.29-4.59,0.67-6.9,2.57c-2.36,1.94-6.42,2.18-9.78,2.43c-3.24,0.24-6.55-0.48-10.31-0.82c-1.32-3.43-3.12-6.43-7.98-2.57
				c-0.98,0.78-3.85-0.89-5.87-1.31c-1.72-0.35-3.46-0.6-5.21-0.77c-1.7-0.16-4.12,0.54-5.02-0.35c-4.37-4.32-9.22-4.25-14.82-3.39
				c-3.18,0.48-6.65-1.15-10.02-1.63c-5.07-0.74-10.18-1.22-15.25-1.98c-2.41-0.36-4.76-1.12-7.13-1.71
				c-1.25-0.31-2.89-1.34-3.68-0.88c-5.2,3-9.68,0.2-14.61-0.87c-4.72-1.02-10.28-1.37-14.65,0.23c-3.62,1.33-5.43-0.65-7.34-1.66
				c-2.88-1.52-4.93-4.56-7.5-6.77c-1.19-1.02-2.73-1.67-4.17-2.34c-0.73-0.34-1.69-0.19-2.41-0.53c-4.09-1.95-8.15-3.99-12.33-6.05
				c2.96-2.09,5.56-4.51,10.73-1.86c2.72,1.39,7.71-0.38,11.14-1.92c9.46-4.25,18.83-1.38,26.93,2c9.44,3.95,18.02,3.28,27.23,1.48
				c1.51-0.29,3.55-0.28,4.72,0.52c7.6,5.2,16.45,4.27,24.86,5.49c2.55,0.37,5.09,0.8,7.64,1.15c12.28,1.69,24.78,2.49,36.78,5.34
				c8.34,1.98,16.23,1.58,24.45,0.94c12.39-0.97,24.81-1.97,37.23-2.01c4.87-0.02,9.67,2.96,14.64,3.79
				c6.73,1.13,13.61,1.39,20.39,2.24c5.14,0.64,10.24,1.6,15.36,2.43c1.12,0.18,2.35,0.79,3.32,0.5c3.42-1,5.67-0.18,7.22,3.19
				c0.38,0.81,2.27,0.83,3.32,1.45c1.5,0.89,4.01,2,4.02,3.04c0.02,1.81-1.36,5.1-2.45,5.23c-6.82,0.8-6.69,6.93-9.13,11.03
				c-0.65,1.09-0.79,2.48-1.05,3.35c-2.86,0-5.46-0.38-7.87,0.13c-1.8,0.38-3.35,1.93-5.02,2.94c-1.87,1.13-3.73,3.06-5.63,3.1
				c-1.87,0.04-3.88-0.64-5.25-3.65c-1.92-4.25-5.8-7.92-9.59-10.86C281.37,778.09,277.85,779.34,274.29,779.51z M206.33,769.58
				c1.68-1.25,3.29-2.44,4.9-3.64c-1.24-1.5-2.29-4.03-3.75-4.29c-3.9-0.7-7.98-0.55-11.98-0.44c-0.81,0.02-1.94,1.23-2.24,2.13
				c-0.18,0.53,0.78,1.94,1.52,2.23C198.41,766.98,202.14,768.16,206.33,769.58z"/>
			<path onClick={() => props.onFill(98)} fill={props.fillColors[98]}  d="M382.99,704.34c-5-9.06-5.65-9.43-12.14-6.82c-0.35-0.61-0.58-1.48-1.15-1.9
				c-2.26-1.67-4.62-3.2-6.94-4.79c-1.13,2.01-2.26,4.03-3.79,6.76c-0.87-1.24-1.96-2.44-2.64-3.84c-1.51-3.07-3.42-3.03-5.23-0.6
				c-1.63,2.19-2.78,4.74-4.14,7.13c-3.62-0.03-7.85,0.6-10.19-3.99c-0.27-0.53-3.05-0.65-3.51-0.05c-3.91,5-8.83,2.9-13.73,2.64
				c-5.01-0.26-10.07,0.64-15.12,0.84c-2.47,0.1-5.04,0.16-7.41-0.43c-3.96-0.99-7.34-2.9-11.4,0.88c-3.81,3.55-9.08,1.54-13.84,0.98
				c-3.45-0.41-7-0.07-10.5,0.07c-0.95,0.04-2.22,1.07-2.76,0.73c-6.45-4.08-14.31,0.05-20.89-3.71c-1.33-0.76-3.75,0.34-5.65,0.69
				c-1.18,0.22-2.32,0.66-3.47,1.01c-0.19-0.75-0.39-1.5-0.58-2.25c4.8-1.03,9.86-1.41,14.34-3.23c6.92-2.81,14.44-1.95,21.52-5.99
				c6.18-3.53,13.35-8.93,21.95-6.24c0.45,0.14,1.06,0.12,1.48-0.07c5.23-2.36,11.13-0.44,15.89-1.51c8.6-1.92,14.7,1.47,21.02,5.13
				c4.83,2.8,9.11,1.68,13.73,0.4c1.56-0.43,3.37-1.19,4.78-0.79c8.1,2.29,14.08-1.63,20.13-6.64c6.47,5.67,13.1,0.19,19.77,0.01
				c4.25-0.11,8.58-0.41,12.69-1.41c3.06-0.74,5.91-2.59,8.7-4.21c1.66-0.97,3.02-2.45,4.79-3.92c2.44,3.8,4.6,3.15,7.98,1.19
				c3.61-2.09,8.16-2.69,12.38-3.54c4.74-0.96,9.56-1.79,14.38-2.05c2.67-0.15,5.41,1.04,8.13,1.52c3.11,0.55,6.23,0.98,9.42,0.93
				c5.74,2.24,10.15,7.85,18.14,7.74c2.04,4.85,5.86,8.29,12.76,7.98c1.6-0.07,3.67,3.44,4.82,5.68c0.28,0.54-2.02,3.01-3.55,3.77
				c-4.63,2.3-9.46,4.21-14.22,6.25c-0.3,0.13-0.74,0.28-0.97,0.16c-9.08-5-15.81,3.22-23.82,4.28c-1.05,0.14-2.07,1-2.96,1.71
				c-2.31,1.86-4.54,3.14-7.59,1.27c-1.01-0.62-2.72-0.7-3.9-0.33c-4.22,1.32-7.45,0.39-10.42-2.98c-0.62-0.71-3.28-0.07-4.76,0.54
				c-3.24,1.34-6.24,3.27-9.36-0.37c-0.71-0.83-2.41-1.17-3.64-1.14c-5.52,0.15-8.31-3.39-10.93-7.47c-0.71-1.11-2.12-2.49-3.18-2.47
				c-1.06,0.03-2.85,1.55-2.99,2.6C393.49,701.17,388.22,702.4,382.99,704.34z"/>
			<path onClick={() => props.onFill(99)} fill={props.fillColors[99]}  d="M399.72,426.03c-3.2,5.44-5.89,10.34-8.89,15.04c-2.57,4.03-5.87,6.98-10.64,8.86
				c-4.82,1.9-5.45,9.42-1.44,14.17c-7.04-0.07-12.65,1.67-14.33,9.9c-0.83-1.62-1.48-3.38-2.56-4.81c-0.9-1.18-2.31-1.97-3.49-2.93
				c-0.54,1.47-1.63,2.97-1.53,4.39c0.53,7.73,1.35,15.44,2.08,23.16c0.1,1.1,0.25,2.2,0.51,4.43c-2.64-2.18-4.77-3.48-6.28-5.3
				c-2.2-2.65-4.5-3.59-8.08-3.62c-2.82-0.02-5.98-1.85-8.39-3.68c-8.97-6.8-19.63-9.87-29.64-14.44
				c-9.33-4.26-18.01-9.95-26.81-15.29c-1.05-0.63-1.77-3.28-1.36-4.6c2.82-9.17,5.69-18.35,9.23-27.25c0.83-2.1,4.26-4.41,6.54-4.45
				c7.83-0.15,15.7,0.38,23.49,1.29c2,0.23,4.31,2.44,5.48,4.35c2.31,3.77,5.13,5.1,8.53,2.29c5.56-4.59,11.17-4.99,17.49-1.93
				c1.36,0.66,3.55,0.86,4.83,0.22c4.66-2.32,9.36-2.74,14.34-1.59c2.2,0.51,4.46,1.08,6.69,1.05c2.93-0.03,5.87-0.56,8.79-0.99
				c0.88-0.13,1.76-1.03,2.53-0.9C390.69,424.08,394.54,424.96,399.72,426.03z M346.76,445.4c-2.26,3.89-5.42,5.75-10.18,6.54
				c-2.68,0.45-5.84,1.11-7.68,2.85c-2.41,2.29-3.73,5.73-5.83,9.19c1.7,0.5,3.69,1,5.62,1.67c2.03,0.71,4.1,1.41,5.97,2.44
				c6.38,3.5,8.24,2.96,12.95-2.89c2.01-2.49,4.82-4.35,7.35-6.4c1.28-1.04,2.92-1.66,4.08-2.8c4.8-4.7,4.42-8.1-1.14-11.68
				c-2.69-1.73-2.58-5.44,0.36-7.37c2.08-1.36,4.48-2.26,6.44-3.75c1.17-0.89,1.79-2.52,2.65-3.82c-1.6-0.55-3.46-1.93-4.75-1.52
				c-5.02,1.61-10.33,3.09-14.62,5.99c-3.47,2.34-5.71,2.77-9.13,0.54c-1.34-0.87-3.64-0.29-5.5-0.36c0.6,1.88,0.63,4.62,1.91,5.48
				C338.83,441.9,342.91,443.5,346.76,445.4z"/>
			<path onClick={() => props.onFill(100)} fill={props.fillColors[100]}  d="M824.32,722.41c-2.81-7.05-3.76-7.74-8.54-5.43c-0.57-3.12-1.05-6.16-1.7-9.17
				c-0.59-2.76-1.56-4.39-5.01-5.5c-2.7-0.87-5.31-5.46-5.81-8.72c-0.45-2.95,1.51-6.66,3.28-9.49c4.25-6.82,9-13.31,13.55-19.94
				c0.19-0.27,0.38-0.82,0.53-0.8c5.87,0.49,6.65-4.59,8.91-8.07c0.35-0.54,1.06-1.35,1.45-1.27c3.57,0.69,6.75-0.7,10.09-1.26
				c2.75-0.46,6.09,2.81,9.2,4.35c0.99,0.49,2.43,1.33,3.09,0.97c2.01-1.12,3.41-1.06,5.05,0.65c0.34,0.36,2.26-0.26,3.03-0.92
				c2.85-2.47,5.6-5.07,8.23-7.77c2.57-2.64,5.39-3.84,8.57-1.44c2.38,1.8,4.12,1.69,5.87-0.67c2.11-2.83,4.25-2.56,7.19-0.76
				c2.8,1.72,6.07,5.01,9.61,0.59c0.33-0.41,2.29-0.03,3.07,0.55c2.38,1.78,4.49,3.9,6.81,5.77c1.88,1.51,4.1,2.63,5.8,4.3
				c1.9,1.88,3.37,4.18,5.03,6.3c-1.84,1.07-3.68,2.14-5.52,3.21c-5.14,3-11.73,3.74-14.94,9.76c-0.34,0.63-1.29,1.23-2.01,1.3
				c-3.52,0.34-6.45,0.66-7.83,5.09c-0.89,2.86-3.96,5.03-6.12,7.61c-7.97-3.64-14.58,3.68-22.42,3.93c-0.8-0.56-1.57-1.17-2.41-1.67
				c-5.87-3.48-5.8-3.43-9.49,2.36c-0.6,0.94-2.23,1.17-3.11,2.01c-2.41,2.31-4.74,4.73-7,7.2c-3.63,3.98-4.9,9.91-10.67,12.22
				C827.92,718.57,826.29,720.75,824.32,722.41z"/>
			<path onClick={() => props.onFill(101)} fill={props.fillColors[101]}  d="M406.11,418.59c-14.34,3.63-14.34,3.63-22.24,0.28c-1.8,6.17-5.13,4.07-9.8,2.8
				c-5.19-1.41-11.15,0.01-16.77,0.16c-3.38,0.09-7.05,0.97-10.08-0.04c-5.45-1.81-10.09-1.44-14.48,2.13
				c-3.07,2.5-5.06,2.45-7.47-1.34c-3.52-5.52-9.4-6.12-15.49-5.54c-2.64,0.25-5.33,0.04-7.06,0.04c4.52-5.15,9.11-10.39,14.39-16.4
				c-9.16-3.07-13.9,1.8-18.53,8.07c0.22-9.51-0.92-10.5-9.1-6.12c-5.11,2.74-9.66,6.87-13.68,11.12c-1.32,1.4-0.21,5.11-0.21,6.28
				c-3.55,6.08-6.6,11.29-9.75,16.68c-0.36-0.53-0.82-0.9-0.81-1.26c0.15-7.63-4.57-11.38-12.13-9.13
				c-6.96,2.08-13.62,5.45-20.69,6.79c-4.16,0.78-9.07-0.71-13.25-2.25c-5.67-2.08-10.96-5.18-15.88-7.59
				c13.28-7.71,26.36-15.3,39.36-22.85c0.15,0.26,1.16,3.06,1.82,2.98c2.11-0.25,4.57-1.1,6.04-2.57c3.44-3.46,6.39-7.42,10.37-10.7
				c-0.12,1.08-0.24,2.16-0.36,3.24c-0.49,4.49,1.95,7.15,6.4,6.51c4.3-0.62,8.55-1.98,11.02,3.5c0.25,0.56,3.59,0.51,4.8-0.3
				c4.22-2.84,7.38-6.43,7.74-12.08c0.48-7.75,3.18-10.81,10.91-12.59c-1.72,2.36-2.96,3.96-4.1,5.65c-1.11,1.64-2.82,3.29-2.99,5.06
				c-0.27,2.78,0.53,5.67,0.87,8.52c3.02-0.83,6.13-1.43,9.03-2.59c1.23-0.49,2.01-2.14,3.13-3.41c11.43,7.2,11.62-6.87,18.06-9.73
				c0.11,0.63,0.34,1.1,0.25,1.5c-0.66,2.95-1.9,6.71,1.93,7.64c2.75,0.67,6.1-0.85,9.12-1.66c1.83-0.49,3.92-2.54,5.19-2.05
				c4.39,1.68,7.69,0.53,9.6-1.64c3.66,1.04,6.45,1.69,9.13,2.65c1.53,0.55,3.51,1.25,4.19,2.49c1.76,3.21,4.13,4.66,7.68,4.28
				c4.95-0.52,9.4,0.38,13.74,3.2c1.95,1.26,5.07,1.15,7.61,0.98c7.71-0.5,9.35,1.14,9.76,8.81
				C403.56,411.63,405.15,415.08,406.11,418.59z M354.74,410.44c0.8,1.4,1.74,4.93,4.01,6.35c1.46,0.91,4.82-1.4,7.39-2.06
				c5.31-1.38,4.7-6.78,6.93-10.21c2.3-3.54,0.54-6.71-3.71-7.76C364.22,395.49,354.7,403.35,354.74,410.44z M345.83,416.33
				c1.05-0.33,3.25-0.67,5.09-1.67c2.56-1.38,3.22-4.46,0.74-5.58c-5.05-2.27-4.6-6.14-4.61-10.29c0-1.17-0.78-2.35-1.2-3.52
				c-1.31,0.48-2.79,0.72-3.9,1.5c-3.62,2.53-3.81,6.31-4.22,10.43C337.19,412.87,339.16,416.19,345.83,416.33z"/>
			<path onClick={() => props.onFill(102)} fill={props.fillColors[102]}  d="M774.66,560.38c8.39-8.28,16.76-16.53,25.11-24.81c0.82-0.82,1.74-1.68,2.17-2.71
				c0.38-0.91,0.15-2.08,0.2-3.14c-0.93-0.04-2-0.38-2.77-0.05c-2.41,1.04-4.7,2.34-7.97,4.02c2.57-3.45,4.57-6.15,6.57-8.84
				c-0.44-0.56-0.89-1.12-1.33-1.68c-2.24,1.02-4.73,1.68-6.66,3.11c-11.49,8.51-22.91,17.11-34.22,25.85
				c-10.37,8.01-19.96,17.2-32.83,21.33c-1.26,0.4-2.51,0.83-3.78,1.18c-0.24,0.07-0.57-0.21-0.73-0.27
				c11.26-9.79,22.56-19.6,33.81-29.46c0.19-0.16-0.14-0.91-0.48-2.75c-22.77,10.17-43.84,22.84-65.35,34.61
				c-0.24-0.47-0.49-0.95-0.73-1.42c9.45-7.77,18.89-15.55,28.34-23.32c-0.35-0.77-0.71-1.54-1.06-2.31
				c-2.03,0.29-4.32,0.08-6.06,0.96c-6.51,3.28-12.84,6.89-19.23,10.41c-4.28,2.35-8.53,4.74-12.96,7.2
				c-0.4-1.24-0.57-1.54-0.58-1.84c-0.1-2.62-0.18-5.25-0.27-7.87c-2.56,0.79-5.38,1.14-7.65,2.45
				c-10.36,5.96-20.51,12.3-30.89,18.22c-2.01,1.15-4.73,1.06-7.12,1.54c-0.15-0.52-0.3-1.04-0.45-1.56
				c3.16-1.68,6.67-2.93,9.38-5.16c2.34-1.93,4.57-4.74,5.4-7.58c1.45-4.95-0.35-6.28-5.28-4.73c-1.42,0.44-2.76,1.12-4.15,1.7
				c2.97-6.86,6.26-7.91,13.33-5.65c2.29,0.73,5.32-0.79,7.99-1.4c2.24-0.52,4.41-1.48,6.67-1.76c2.76-0.35,5.63,0.05,8.37-0.38
				c1.17-0.18,2.93-1.53,3.06-2.53c0.65-5.09,4.83-7.01,8.37-7.07c5.54-0.1,10.35-0.82,14.59-5.92c0.39,2.16,0.5,3.25,0.79,4.3
				c1.2,4.35,4.02,5.34,7.91,2.83c4.73-3.06,9.42-6.18,14.22-9.13c1.19-0.73,2.73-1.32,4.08-1.25c3.69,0.19,7.37,0.75,12.27,1.31
				c3.33,4.57,5.6,4.97,12.22,0.57c3.48-2.31,6.37-5.02,11.12-5.59c3.06-0.37,5.66-4.23,8.55-6.43c3.33-2.53,6.85-4.84,10.07-7.49
				c0.95-0.78,1.44-2.39,1.7-3.7c0.69-3.44,1.13-6.92,2.6-10.38c-0.93,5.1,4.94,8.65,2.5,14.16c-1.24,2.8,1.72,4.29,5.76,3.05
				c3.94-1.21,7.83-2.77,11.86-3.44c3.01-0.5,6.26,0.6,9.31,0.21c3.58-0.46,7.05-1.75,10.47-2.66c-1.14,2.1-2.98,4.75-4.01,7.69
				c-0.69,1.97-0.2,4.34-0.24,6.54c1.89,0.03,3.79,0.24,5.66,0.06c3.69-0.36,7.49-0.48,11-1.53c6.57-1.98,12.96-4.55,19.1-6.77
				c-0.78,5.1-1.59,10.39-2.4,15.67c0.52,0.19,1.05,0.37,1.57,0.56c-3,1.84-6.01,3.69-10.21,6.27c1.11-3.05,1.69-4.64,2.27-6.24
				c-0.24-0.48-0.48-0.95-0.72-1.43c-5.22,1.88-10.43,3.77-16.44,5.94c1.82-3.02,3.3-5.48,4.78-7.93c-0.29-0.35-0.57-0.71-0.86-1.06
				c-1.61,0.49-3.32,0.77-4.8,1.52c-2.96,1.51-5.66,3.57-8.69,4.87c-3.35,1.44-6.97,2.24-10.44,3.41c-1.72,0.58-3.45,1.23-5.02,2.13
				C792.49,549.91,783.62,555.14,774.66,560.38z"/>
			<path onClick={() => props.onFill(103)} fill={props.fillColors[103]}  d="M670.33,497.97c-1.46-7.98,3.67-20.67,10.12-23.46c2.98-1.29,7.39-1.23,10.42,0.01
				c5.63,2.3,9.86,1.76,13.77-2.84c1.28-1.51,2.79-2.96,4.48-3.95c3.46-2.02,4.36-5.02,4.64-8.77c0.38-5.04,0.07-10.82,7.11-11.92
				c4.89-0.76,9.57-4.34,14.82-0.99c0.95,0.61,2.56,0.66,3.72,0.37c4.27-1.06,8.44-2.55,12.73-3.52c3.87-0.87,7.56-1.08,9.94-5.39
				c1.69-3.05,7.24-2.9,9.66,0.07c4.15,5.08,7.46,10.85,11.65,15.89c3.82,4.6,6.57,10.1,12.5,13.05c3.46,1.72,5.86,5.59,8.88,8.63
				c-5.83,0.97-10.3,1.75-14.78,2.41c-0.93,0.14-1.95-0.1-2.88-0.34c-4.36-1.11-9-1.66-13-3.55c-8.52-4.03-16.85,2.04-25.21-0.17
				c-0.44-0.12-1.07-0.04-1.45,0.2c-6.1,3.96-12.16,7.97-18.25,11.95c-2.09,1.37-4.06,3.09-6.35,3.94
				c-5.41,2.01-10.89,4.08-16.52,5.21c-5.13,1.03-10.63,1.72-15.73,0.96c-5.67-0.85-10.8,0.07-16.08,1.46
				C673.26,497.55,671.95,497.69,670.33,497.97z"/>
			<path onClick={() => props.onFill(104)} fill={props.fillColors[104]}  d="M91.15,362.95c4.84-9.78,5.62-19.26,5.66-28.99c0.03-7.07,1.34-7.8,8.46-7.25
				c4.4,0.34,8.96-0.09,12.15,4.17c0.63,0.85,2.71,0.68,4.14,0.83c1.12,0.11,2.38-0.34,3.39,0c4.89,1.64,9.44,1.8,14.82,0.92
				c9.34-1.53,19.07-0.68,28.64-0.76c1.31-0.01,3.07-0.11,3.86,0.64c4.62,4.37,10.21,4.98,16.14,5c0.63,0,1.47,2.13,1.79,3.38
				c0.15,0.59-0.6,1.52-1.13,2.15c-6.89,8.24-10.81,17.78-12.58,28.32c-0.59,3.51-2.25,4.61-6.3,3.9c-7.96-1.39-16.1-1.74-24.17-2.46
				c-5.86-0.52-11.36-1.07-17.23,2.07c-3.26,1.74-8.93,0.39-12.96-1.08C107.54,370.78,99.63,366.75,91.15,362.95z"/>
			<path onClick={() => props.onFill(105)} fill={props.fillColors[105]}  d="M556.29,370.68c0.39,2.88,0.05,5.69,1.16,7.69c4.17,7.5,1.26,14.74,0.27,22.23
				c-1.15,8.65-4.81,15.34-10.96,21.55c-6.28,6.33-11.14,14.05-16.91,20.92c-1.6,1.91-4.04,3.56-6.41,4.3
				c-7.25,2.27-12.38,6.53-15.91,13.35c-1.78,3.44-4.66,6.53-7.64,9.08c-7.37,6.29-12.58,14.06-17.1,22.5
				c-3.53,6.59-7.29,13.08-11.33,19.37c-1.48,2.3-4.05,3.9-6.98,6.62c0.85-7.91,10.79-12.73,2.91-21.26
				c7.86-16.59,15.02-34.34,24.46-50.79c7.29-12.7,17.6-23.63,26.22-35.6c5.73-7.96,11.69-15.94,16-24.67
				C538.83,376.23,546.7,372.58,556.29,370.68z"/>
			<path onClick={() => props.onFill(106)} fill={props.fillColors[106]}  d="M654.95,696.62c4.37,2.74,6.7,1.32,9.45-4.93c1.83,0.36,3.49,1.13,4.99,0.91
				c5.7-0.84,9.53-3.76,9.47-10.12c7.05,1.34,9.14,0.13,11.86-6.43c0.41-0.99,1.4-2.45,2.16-2.47c4.34-0.12,7.42-2.39,10.54-5.03
				c2.09-1.77,4.71-2.92,7.21-4.41c3.2,4.21,4.66,4.54,9.98-0.22c2.71-2.43,4.92-3.51,8.17-1.57c0.63,0.37,1.77,0.59,2.33,0.27
				c4.75-2.72,9.42-5.58,13.96-8.3c1.91,0,4.32,0.66,6.08-0.12c6.17-2.74,12.12-5.98,18.82-9.37c0.1,0.05,1.18,0.48,2.09,1.15
				c1.32,0.98,3.75,2.73,3.56,3.1c-1.02,2.08-2.34,4.33-4.19,5.61c-5.29,3.67-11.2,6.51-16.21,10.5c-1.93,1.54-2.09,5.31-3.33,8.85
				c-4.4-0.26-8.3,1.39-11.08,6.47c-2.43,4.43-7.25,4.15-11.8,2.51c-1.26-0.46-2.79-0.38-4.17-0.24c-1.89,0.19-3.76,0.63-5.62,1.07
				c-0.93,0.22-2.2,0.39-2.65,1.04c-2.94,4.25-6.21,7.88-11.83,8.2c-0.14,0.01-0.38,0.15-0.4,0.27c-1.3,5.31-5.97,6.74-10.03,9
				c-2.37,1.32-4.19,3.98-6.64,4.68c-2.7,0.78-5.08,1.11-6.05,4.16c-0.16,0.5-1.2,1.21-1.55,1.07c-7.34-2.87-9.18,4.49-11.7,7.74
				c-2.78,3.59-2.4,9.64-3.33,14.63c-0.18,0.95-0.03,1.97-0.03,3.58c-12.07,0-23.9,0-36.26,0c1.23-4.39-0.4-8.75,4.89-12.4
				c3.3-2.29,8.39-6.15,7.27-12.45c-0.19-1.09,1.26-3.48,2.16-3.59C650.76,708.83,651.85,702.11,654.95,696.62z"/>
			<path onClick={() => props.onFill(107)} fill={props.fillColors[107]}  d="M412.58,668.47c-2.84-3.93-6.18-1.33-9.09,1.11c-3.33,2.79-6.3,5.14-11.25,4.65
				c-3.61-0.36-7.38,1.59-11.13,1.84c-2.28,0.15-4.92-0.5-6.9-1.64c-2.38-1.37-4.78-3.32-6.21-5.62c-2.82-4.54-5.8-4.19-9.96-2.03
				c-2,1.04-4.78,0.57-7.32,0.8c-2.51-4.1-9.01-6.67-13.58-5.83c-2.05,0.38-4.55,0-6.47-0.87c-5.15-2.34-11.43-0.09-16.26-4.71
				c-1.34-1.28-5.85,0.94-8.94,1.23c-1.88,0.18-3.85-0.11-5.73-0.45c-1.94-0.35-3.81-1.12-5.75-1.54c-3.49-0.77-7.19-0.74-9.41-4.59
				c-0.43-0.75-4.15-0.66-5.33,0.3c-3.1,2.52-5.58,1.59-6.78-1.23c-1.54-3.64-3.77-3.52-6.3-2.11c-2.92,1.64-5.31,1.4-8.26-0.06
				c-3.86-1.91-7.15-0.99-8.21,3.53c-2.5-0.95-4.8-1.82-7.28-2.77c0.86-3.92,4.12-2.73,6.61-3.25c2.41-0.51,4.76-1.34,6.94-2.81
				c3.28,0.21,6.95,1.89,8.37-3.65c7.01,3.8,14,2.47,21.11,2.63c8.14,0.19,16.8-3.9,24.73,1.45c1.02,0.69,3.17,0.64,4.31,0.02
				c2.69-1.47,4.84-1.16,7.36,0.38c1.03,0.63,2.8,0.07,4.23,0.02c0.89-0.03,2.22-0.5,2.59-0.08c3.08,3.4,5.54,1.73,7.39-0.32
				c2.97,0.16,5.73,1.14,7.65,0.26c6.1-2.78,11.89-2.42,17.82,0.07c1.06,0.45,2.26,1.15,3.29,1c7.88-1.13,15.46,0.48,23,2.34
				c6.43,1.59,13.25-0.26,19.62,2.97c3.14,1.59,7.29,3.98,10.98-0.1c0.46-0.51,2.15-0.01,3.24,0.16c4,0.64,8,1.33,12.84,2.15
				c1.11,2.06,3.27,3.92,7.03,1.86c0.55-0.3,1.69,0.47,2.56,0.73c1.38,0.42,2.75,1.09,4.16,1.17c3.04,0.18,6.09,0.06,9.06,1.21
				c-2,1.27-3.94,2.64-6.01,3.79c-1.2,0.67-2.56,1.28-3.91,1.44c-4.21,0.5-8.44,0.86-12.67,1.09c-1.85,0.1-3.73-0.21-5.59-0.36
				c-0.64-0.05-1.38-0.49-1.89-0.29C422.52,664.26,417.81,666.28,412.58,668.47z"/>
			<path onClick={() => props.onFill(108)} fill={props.fillColors[108]}  d="M152.61,934.94c0.57-3.5,1.18-6.99,1.68-10.5c0.16-1.14,0.03-2.33,0.03-3.5
				c0.01-10.52-0.65-11.17-11.26-11.09c-0.66,0.01-1.31,0-2.33-0.88c5.58-2.64,11.17-5.26,16.73-7.94
				c13.18-6.34,26.31-12.79,39.56-18.99c1.91-0.89,4.49-1.21,6.55-0.78c12.19,2.54,24.34,5.26,36.43,8.26
				c2.03,0.5,3.65,2.64,6.38,4.72c-19.5,9.18-37.83,17.82-56.16,26.45c0.06,0.64,0.12,1.27,0.18,1.91
				c7.71,2.04,15.41,4.09,24.37,6.46c-5.32,6.56-10,12.47-14.9,18.17c-0.6,0.7-2.52,0.73-3.65,0.43
				c-14.75-3.97-29.47-8.06-44.19-12.14c-0.25-0.07-0.41-0.47-0.62-0.72C151.81,934.85,152.21,934.9,152.61,934.94z"/>
			<path onClick={() => props.onFill(109)} fill={props.fillColors[109]}  d="M131.52,865.05c5.04-3.32,10.5-5.93,17.45-6.11c25.76-0.66,51.5-1.95,77.24-2.99
				c1.65-0.07,3.3-0.01,5.58-0.01c0.19,2.29,0.29,4.53,0.56,6.75c0.46,3.74,1.9,6.88,6.16,7.23c3.59,0.29,5.85-0.8,5.82-5.29
				c-0.05-10.05,0.16-10.12,9.94-10.96c3.97-0.34,7.98-0.54,11.96-0.39c1.79,0.06,3.5,1.25,5.31,1.7c2.69,0.68,5.5,1.87,8.13,1.61
				c8.66-0.86,14.76,2.19,18.47,10.19c1.58,3.41,3.58,6.63,5.73,10.55c-6.64,0.5-12.53,0.98-18.43,1.39
				c-4.97,0.34-9.96,0.86-14.93,0.8c-3-0.04-5.97-1.19-8.99-1.57c-6.32-0.8-12.88-3.48-18.46,2.33c-0.65,0.68-2.17,0.58-3.31,0.74
				c-11.67,1.61-23.13,0.92-34.57-2.15c-3.02-0.81-7.07-0.39-9.85,1.03c-6.89,3.52-13.96,4.92-21.6,4.97
				c-8.47,0.06-16.95,0.56-25.4,1.16c-3.38,0.24-5.24-0.99-6.54-3.97c-1.59-3.65-3.32-7.28-5.31-10.72
				C135.28,869.2,133.45,867.43,131.52,865.05z M254.24,865.35c1.5,1.26,3.39,2.07,3.88,3.39c2.16,5.93,6.8,5.8,11.72,5.42
				c4.12-0.31,7.77-7.28,5.47-10.68C271.04,857.18,261.57,854.36,254.24,865.35z M161.33,869.6c1.61,1.43,3.55,2.35,4.18,3.84
				c1.92,4.54,5.23,5.24,9.62,4.92c5.14-0.38,7.98-2.05,8.07-7.8c0.04-2.34-3.32-5.71-5.92-6.93
				C170.73,860.57,167,862.29,161.33,869.6z"/>
			<path onClick={() => props.onFill(110)} fill={props.fillColors[110]}  d="M480.6,504.67c7.59-13.84,12.83-28.2,27.67-34.68c7.84-3.42,15.96-5.34,24.77-2.97
				c2.48,0.67,6.42-0.52,8.41-2.29c5.38-4.81,11.52-4.86,18.02-4.83c3.42,0.02,6.84-1.23,10.26-1.25c4.01-0.02,8.01,0.68,12.02,1.07
				c-0.81,3.64-1.14,7.48-2.53,10.89c-3.17,7.79-2.46,9.62,5.54,11.6c1.43,0.35,2.99,0.71,4.14,1.56c3.27,2.41,6.26,5.03,4.71,9.9
				c-1.61,5.06-5.88,4.99-9.38,4.02c-17.35-4.78-33.81,0.42-50.47,3.78c-0.93,0.19-2.05,0.29-2.9-0.03
				c-11.14-4.3-21.79-0.74-32.62,1.6C492.88,504.21,487.25,504.11,480.6,504.67z"/>
			<path onClick={() => props.onFill(111)} fill={props.fillColors[111]}  d="M85.83,273.31c-2.37,6.55-4.55,11.9-6.21,17.4c-1.48,4.88-2.4,9.93-3.53,14.91
				c-0.48,2.1-0.9,4.21-1.33,6.33c-0.87,4.31-2.08,8.59-2.52,12.95c-0.82,8.09-4.97,14.3-10.35,19.9c-0.51,0.53-1.97,0.78-2.61,0.44
				c-5.12-2.69-10.34-5.26-15.11-8.49c-1.95-1.32-2.73-4.28-4.26-6.32c-1.32-1.76-2.72-3.62-4.51-4.82
				c-3.29-2.21-6.11-4.14-5.13-8.97c1.94-9.57,3.46-19.22,5.15-28.84c0.17-0.98,0.66-2.08,0.39-2.93c-1.59-4.95-3.42-9.82-5.05-14.76
				c-1.44-4.36-2.93-8.72-4.03-13.17c-0.85-3.45,0.43-5.96,4.05-7.37c2.4-0.93,4.49-2.65,7.59-4.56c1.37,4.6,2.55,8.51,3.7,12.42
				c1.3,4.43,3.47,8.82,3.65,13.3c0.23,5.86-1.08,11.79-1.82,17.68c-0.58,4.58-1.29,9.15-1.95,13.72c0.8,0.24,1.59,0.48,2.39,0.72
				c1.68-3.38,3.25-6.81,5.06-10.12c2.67-4.87,5.56-9.62,8.24-14.49c1.74-3.15,3.36-6.38,4.87-9.65c2.22-4.81,4.02-9.83,6.5-14.5
				c1.68-3.16,10.46-6.01,13.47-4.61c0.98,0.46,1.86,2.28,1.84,3.46c-0.05,3.1,1.34,6.78-2.98,8.6c-3.97,1.67-7.9,3.53-11.61,5.71
				c-1.29,0.76-1.87,2.75-2.77,4.18c1.41,0.63,2.81,1.72,4.25,1.79C75.63,273.47,80.09,273.31,85.83,273.31z"/>
			<path onClick={() => props.onFill(112)} fill={props.fillColors[112]}  d="M596.31,487.82c-3.06-5.34-7.21-8.12-12.65-8.9c-3.6-0.51-3.63-2.64-2.71-5.44
				c1.61-4.87,3.47-9.7,4.51-14.7c0.82-3.97,3.01-5.89,6.59-6.33c5.29-0.65,9.94-2.01,14.63-5.13c3.43-2.28,8.93-2.22,13.43-1.94
				c5.47,0.34,10.55,0.97,16.05-1.13c3.26-1.24,7.63,0.43,12.87,0.91c4.39-2.73,10.08-5.22,16.83-0.36c1.46,1.05,4.78-0.06,7.1-0.7
				c5.3-1.44,5.3-1.22,6.26,3.95c1,5.38,0.18,9.94-2.52,14.74c-1.4,2.47-1.03,6.1-0.98,9.19c0.06,3.72-1.39,5.67-5.14,5.74
				c-1.83,0.04-3.66,0.11-5.49,0.03c-16.36-0.66-32.51,0.78-48.43,4.75c-2.21,0.55-4.68,0.93-6.87,0.54
				C604.61,482.13,600.24,483.11,596.31,487.82z"/>
			<path onClick={() => props.onFill(113)} fill={props.fillColors[113]}  d="M452.61,876.51c-8.16,1.04-16.29,2.59-24.47,3.01c-11.12,0.57-22.28,0.19-33.42,0.42
				c-2.63,0.05-5.23,0.87-7.85,2.24c10.09,4.77,21.05,5.09,31.57,7.63c-31.34,1.46-62.6,0.86-93.56,4.82
				c-0.07,0.81-0.13,1.62-0.2,2.44c2.22,0.69,4.41,1.51,6.66,2.04c2.41,0.57,4.87,0.86,7.3,1.62c-7.3,0-14.61,0.1-21.91-0.03
				c-5.48-0.1-10.95-0.61-16.43-0.68c-2.96-0.04-4.35-1.06-4.01-4.12c0.13-1.15,0.03-2.33,0.04-3.5c0.07-9.19,0.07-9.28,9.17-10.24
				c3.93-0.42,4.46-2.17,2.7-5.29c-0.9-1.59-1.82-3.17-2.69-4.78c-0.77-1.43-1.47-2.91-2.79-5.52c50.6,0.35,100.35,0.37,149.73,7.8
				C452.51,875.07,452.56,875.79,452.61,876.51z"/>
			<path onClick={() => props.onFill(114)} fill={props.fillColors[114]}  d="M801.19,232.82c2.81,0.42,3.57,0.32,4.08,0.63c9.91,6.14,21.57,7.29,32.28,11.13
				c8.62,3.09,18.1,3.78,27.17,5.66c10.12,2.1,20.46,2.76,29.85,8.38c6.11,3.65,13.61,4.98,21.11,7.57
				c-11.97,0.16-23.14-0.93-34.24-2.75c-1.43-0.23-3.1-0.71-4.1-1.67c-8.09-7.69-17.83-8.29-28.18-7.23
				c-3.41,0.35-6.95-0.53-10.64-0.18c2.26,4.6,6.3,7.01,10.51,7.24c11.09,0.6,20.23,6.18,29.8,10.62c8.28,3.84,16.73,6,25.92,6.07
				c3.91,0.03,8.09,1.42,11.63,3.22c6.95,3.52,13.56,7.72,20.24,11.74c1.2,0.72,2.11,1.94,2.88,4.27c-2.13,0-4.32-0.28-6.39,0.09
				c-1.61,0.29-3.09,1.38-4.62,2.11c0.79,1.36,1.27,3.17,2.44,4.01c3.89,2.8,8.03,5.26,12.23,7.95c-1.87,3.33-3.93,3.75-7.69,2.6
				c-4.7-1.43-8.77-5.85-14.58-3.61c-0.87,0.34-2.62-2.34-4.22-3.01c-4.27-1.77-8.66-3.29-13.05-4.75
				c-4.57-1.52-7.84-3.78-10.08-8.65c-1.6-3.47-5.69-6.72-9.4-8.1c-9.62-3.58-19.74-5.78-29.48-9.06
				c-12.41-4.18-24.71-8.75-36.91-13.51c-1.87-0.73-3.27-3.18-4.44-5.1c-2.97-4.86-5.9-9.77-8.43-14.87
				C803.34,240.59,802.62,237.17,801.19,232.82z"/>
			<path onClick={() => props.onFill(115)} fill={props.fillColors[115]}  d="M728.77,740.16c-14.1-0.43-28.69-0.95-43.28-1.26c-4.13-0.09-8.3,0.19-12.39,0.76
				c-4.17,0.58-6.01-0.8-5.47-5.03c0.66-5.23-0.29-10.98,5.09-14.68c1.11-0.76,1.54-2.51,2.45-4.09c4.02,0.65,7.3-0.61,9.85-4.34
				c0.91-1.33,3.46-1.48,5.19-2.31c3.59-1.7,7.43-3.08,10.59-5.39c2.24-1.64,3.51-4.6,5.48-7.33c4.9-0.18,10.56-3.95,11.89-7.57
				c3.24-1.09,5.82-1.96,8.06-2.72c2.55,0.21,4.97,0.41,7.4,0.6c0.32,0.03,0.85-0.15,0.95,0c4.8,6.68,11.09,0.79,16.53,2
				c0.96,0.22,2.45,0.25,2.76,0.86c2.75,5.33,7.8,5.65,13.05,5.84c-5.66,2.03-7.07,7.12-9.27,11.35c-1.59,3.05-2.75,4.69-6.22,2.15
				c-3.01-2.2-5.96-1.71-7.99,1.75c-2.73,4.65-5.71,9.19-8.11,14.01C732.86,729.7,730.96,734.93,728.77,740.16z"/>
			<path onClick={() => props.onFill(116)} fill={props.fillColors[116]}  d="M371.56,678.7c-2.21-0.8-4.58-1.3-6.58-2.47c-2.51-1.47-4.03-1.17-5.91,1.09
				c-3.75,4.5-8.51,6.56-14.57,5.32c-2.17-0.44-4.79-0.43-6.78,0.4c-6.16,2.58-11.55,1.2-16.89-2.12c-2.39-1.49-4.9-2.89-7.53-3.86
				c-1.55-0.57-3.97-0.95-5.07-0.14c-3.64,2.68-6.85,1.12-10.3-0.02c-1.48-0.49-3.68-1.2-4.63-0.52c-6.34,4.56-12.38,3.34-18.99,0.52
				c-2.53-1.08-6.07,0.33-9.14,0.33c-1.65,0-3.33-0.43-4.93-0.91c-2.41-0.72-4.76-1.64-7.53-1.44c-1.99-1.7-3.8-3.71-6.03-4.98
				c-1.26-0.72-4.37-0.8-4.67-0.19c-2.55,5.16-6.8,2.52-10.45,2.78c-3.27,0.23-6.51,0.87-9.78,1.23c-1.03,0.11-2.11-0.25-3.16-0.18
				c-2.42,0.17-5.43-0.36-7.13,0.87c-6.15,4.48-12.22,3.36-18.45,0.81c-1.65-0.68-3.08-1.89-4.81-2.99c0.4-1.38,0.42-3.21,1.34-4.08
				c0.71-0.68,2.65-0.48,3.85-0.09c1.15,0.38,2.57,2.43,2.97,2.2c6.36-3.75,15.94,1.54,20.5-7.33c0.59-1.14,2.42-1.6,3.57-2.51
				c1.27-1,3.38-2.1,3.45-3.26c0.35-5.49,5.44-6.25,8.37-9.11c0.41-0.4,1.36-0.75,1.79-0.55c4.24,2.01,8.86,3.56,12.51,6.38
				c4.08,3.14,4.9,3.39,6.5-1.5c0.2-0.61,0.44-1.22,0.82-2.28c1.33,0.33,2.63,0.45,3.74,0.98c3.16,1.52,6.25,4.57,9.25-0.32
				c0.14-0.24,2.19,0.16,2.74,0.79c4.13,4.76,7.8,5.16,11.06,2.15c5.06,1.69,9.37,3.13,13.69,4.56c0.31,0.1,0.74-0.02,0.97,0.16
				c5,3.87,10.21,3.3,15.57,0.87c0.69-0.31,2.03-0.51,2.35-0.14c5.35,6.18,13.47,2.83,19.83,5.8c2.68,1.25,6.39,0.2,9.62,0.4
				c1.11,0.07,2.2,0.72,3.25,1.22c0.61,0.29,9.45,4.47,9.92,4.51c0.66,0.05,1.54,0.39,1.95,0.09c8.41-6.15,9.62,3.79,14.45,5.57
				C372.03,677.39,371.79,678.04,371.56,678.7z"/>
			<path onClick={() => props.onFill(117)} fill={props.fillColors[117]}  d="M819.2,719.85c3.03,6.84,6.04,13.04,8.48,19.46c0.96,2.51,0.64,5.52,0.91,8.43
				c-3.94,0-6.74,0.08-9.54-0.02c-4.11-0.15-7.73-0.01-7.15-6.47c0.4-4.51-1.65-9.29-2.92-13.87c-0.39-1.42-1.84-2.54-2.8-3.8
				c-0.95,1.17-2.37,2.21-2.78,3.55c-1.79,5.88-3.3,11.86-5.19,18.86c-6.37-0.34-13.8-0.4-21.1-1.42c-1.53-0.21-2.7-3.94-3.76-6.17
				c-1.01-2.12-1.68-4.41-2.5-6.63c-1.56,2.09-3.4,4.03-4.59,6.32c-0.99,1.91-1.22,4.22-2.84,6.54c-0.38-1.22-0.57-2.56-1.19-3.65
				c-1.4-2.45-3.01-4.77-4.53-7.14c-2.1,2.4-4.3,4.72-6.26,7.24c-0.73,0.94-0.6,2.61-1.39,3.44c-1.8,1.89-3.75,2.08-5.08-0.69
				c-0.57-1.2-0.92-2.53-1.62-3.64c-0.74-1.18-1.77-2.17-2.67-3.25c-0.89,1.08-1.83,2.13-2.67,3.26c-1.36,1.83-2.66,3.72-4.84,5.08
				c0.15-6.38-0.2-12.45,3.43-17.86c3.02-4.5,5.52-9.36,8.33-14c1.34-2.21,2.95-3.31,5.75-1.78c5.02,2.77,6.64,2.02,9.21-3.08
				c1.61-3.2,3.57-6.23,5.55-9.65c3.19,2.72,5.35,5.16,9.81,2.24c2.85-1.87,7.63-0.62,11.46-1.24c2.16-0.35,4.25-1.51,6.21-2.59
				c1.39-0.76,2.52-2,4.52-3.66c2.68,3.72,5.11,7.11,7.56,10.49c0.58,0.79,1.13,2.14,1.82,2.23c5.15,0.66,5.39,4.99,5.61,8.39
				C812.78,720.06,815.33,720.87,819.2,719.85z"/>
			<path onClick={() => props.onFill(118)} fill={props.fillColors[118]}  d="M572.8,721.64c-4.35,1.59-8.16,2.42-11.39,4.28c-5.71,3.28-11.64,4.17-18.12,4.14
				c-36.1-0.18-72.19-0.08-108.29-0.1c-1.88,0-3.76-0.22-5.64-0.34c-0.15-0.52-0.3-1.05-0.46-1.57c2.33-1.27,4.58-2.73,7.01-3.75
				c3.26-1.36,5.28-3.56,7.6-6.34c4.45-5.32,7.85-12.4,16.13-13.65c2.74-0.41,5.23-2.42,7.88-3.6c0.81-0.36,1.87-0.68,2.67-0.47
				c7.53,1.94,15,4.14,22.56,5.95c2.24,0.54,4.72,0.08,7.78,0.08c2.79,3.47,6.43,2.99,11.37,1.57c3.91-1.12,8.75,1.04,13.19,1.69
				c2.95,0.43,5.92,0.78,8.89,1.13c0.31,0.04,0.77-0.3,0.95-0.17c7.05,5.01,12.29-2.52,19.04-2.2c4.05,4.07,4.04,4.06,9.13-0.81
				c0.46-0.44,1.05-0.92,1.63-1.01c6.42-0.92,11.62,5.9,9.17,11.96C573.41,719.65,573.05,720.91,572.8,721.64z"/>
			<path onClick={() => props.onFill(119)} fill={props.fillColors[119]}  d="M33.87,794.97c-2.72-2.01-5.44-4.01-8.68-6.4c5.15-3.26,10.79-6.82,15.5-9.8c4.6-1.11,8.71-1.34,12.02-3.1
				c4.06-2.16,7.42-2.26,10.55,0.82c3.44,3.38,4.99,1.83,6.02-2.03c4.14,2.4,8.07,4.72,12.05,6.94c1.07,0.6,2.44,1.48,3.44,1.24
				c3.68-0.88,7.19-2.53,10.9-3.16c1.93-0.33,5.84,0.67,5.92,1.41c0.71,6.08,5.04,8.29,9.64,10.74c2.93,1.56,5.38,4.03,8.58,6.51
				c-0.04,0.33-0.61,1.6-0.24,2.49c0.64,1.55,1.85,2.87,2.82,4.28c0.97-0.97,2.2-1.78,2.85-2.93c1.22-2.15,1.99-4.56,3.17-6.74
				c0.77-1.41,1.91-3.67,2.93-3.69c4.9-0.11,6.25-3.3,7.63-7.08c0.45-1.23,2.36-1.91,3.61-2.85c0.84,1.22,1.58,2.54,2.54,3.65
				c1.41,1.63,2.72,3.59,4.53,4.58c5.05,2.75,5.19,2.96,1.36,6.85c-0.67,0.68-1.06,1.66-1.55,2.51
				c-4.87,8.45-10.88,13.31-21.92,12.25c-9.89-0.95-20.08,1.37-30.14,2.05c-8.58,0.58-17.17,0.95-25.76,1.26
				c-0.93,0.03-2.42-0.71-2.78-1.5c-3.07-6.67-7.6-10.87-15.46-10.63c-5.57,0.17-5.53-5.57-7.9-8.56c-3.81-4.81-4.4-5.37-8.92-1.52
				c-1.63,1.39-3.12,2.94-4.67,4.42C32.58,796.32,33.23,795.64,33.87,794.97z"/>
			<path onClick={() => props.onFill(120)} fill={props.fillColors[120]}  d="M818.27,772.1c0-4.32,0-7.97,0-12.45c1.3-0.1,3.6-0.27,7.19-0.54c-1.69,8.33-3.2,15.75-4.65,22.87
				c7.36,5.24,15.35,4.01,23.16,2.98c1.96-0.26,3.51-2.82,5.51-3.78c2.48-1.2,5.19-2.32,7.88-2.6c3.36-0.35,6.82,0.2,10.69,0.39
				c0.01,6.63,5.69,6.56,10.32,6.84c5.4,0.33,10.89-0.25,16.28-0.9c1.61-0.19,2.98-2.04,4.54-2.99c1.15-0.7,2.41-1.57,3.67-1.66
				c4.72-0.32,9.26,0.04,13.41-3.69c1.89-1.7,6.03-1.32,9.1-1.14c3.05,0.19,6.18,1.61,6.04-3.49c-0.01-0.27,1.31-0.58,2.86-1.21
				c-0.72,6.58,0.05,12.69-5.24,17.28c-1.99,1.73-3.88,3.74-5.29,5.96c-4.38,6.86-10.52,8.29-18.3,7.88
				c-20.05-1.06-40.14-1.31-60.21-2.09c-4.62-0.18-9.22-1.08-13.8-1.82c-3.36-0.54-6.74-0.69-6.67-5.97c0.02-1.6-3.81-4.16-6.24-4.73
				c-9.19-2.16-18.52-3.77-27.83-5.39c-5.06-0.88-10.18-1.75-15.29-1.93c-8.44-0.29-16.92,0.41-25.33-0.08
				c-12.23-0.71-24.42-2.09-36.62-3.27c-1.38-0.13-2.7-0.86-4.01-2.22c4.5-0.7,9.8-3.25,13.32-1.71c6.9,3,14.26,0.76,20.52,3.09
				c8.26,3.07,15.61,3.21,23.44-0.23c1.21-0.53,2.92,0.19,4.37,0.03c3.13-0.34,6.23-0.88,9.35-1.33c-0.56-2.44-1.1-4.88-1.68-7.32
				c-0.15-0.63-0.4-1.24-0.88-2.67c5.22-0.7,10.17-1.36,15.87-2.12c0,2.99-0.05,5.48,0.01,7.97c0.09,3.76,2.83,3.98,5.59,4.04
				c3.24,0.08,6.51,0.36,9.71,0.01C812.21,773.79,815.29,772.78,818.27,772.1z"/>
			<path onClick={() => props.onFill(121)} fill={props.fillColors[121]}  d="M943.78,683.92c-0.3-1.13-0.56-2.21-0.88-3.27c-1.02-3.44-2.78-4.56-5.6-1.62
				c-3.47,3.63-6.87,7.32-10.54,10.77c-1.65,1.34-3.52,2.49-4.92,4.06c-3.14,3.53-6.12,7.21-9.04,10.92
				c-2.67,3.4-5.19,6.92-7.78,10.39c-0.59,0.8-1.16,1.61-1.63,2.26c-5.28-2.74-5.28-2.74-9.15,2.72c-0.44-1.3-1-2.28-1.1-3.3
				c-0.67-7.01-3.11-8.25-9.5-5.63c-5.31,2.18-10.98,3.48-16.39,5.42c-1.66,0.6-3.78,1.71-4.39,3.14c-2.26,5.32-5.86,8.54-11.25,9.2
				c-1.73-4.04-3.43-8.02-5.14-11.99c-0.61,0-1.22,0-1.83,0c-1.63,5.1-3.26,10.21-5.1,15.97c-1.63-2.04-2.89-3.61-4.96-6.2
				c-1.66,3.9-2.99,7.01-4.45,10.44c-1.71-3.22-3.38-6.36-5.51-10.36c3.23-2.57,6.93-4.91,9.82-8c3.31-3.55,5.83-7.81,8.81-11.68
				c1.55-2.01,3.16-4.03,5.04-5.71c2.22-1.98,4.77-3.59,8.3-6.19c2.17,0.54,5.57,5.31,10.32,1.67c3.82,4.41,5.45-0.02,8.11-1.68
				c1.87-1.17,4.62-0.93,6.05-1.16c1.9,1.69,3.12,2.77,4.34,3.86c0.95-1.45,1.91-2.9,2.84-4.36c0.76-1.2,1.39-2.5,2.23-3.64
				c1.53-2.05,3.18-4,5.06-5.45c2.99-2.37,5.81-5.02,9.01-7.05c3.9-2.48,8.11-4.49,12.21-6.64c3.09-1.62,6.24-3.11,9.33-4.71
				c3.39-1.76,6.59-4.16,10.19-5.15c1.61-0.45,3.98,1.83,6,2.89c1.36,0.71,2.7,1.46,4.07,2.16c1.03,0.52,2.46,0.7,3.07,1.51
				C956.08,676.38,955.37,678.46,943.78,683.92z"/>
			<path onClick={() => props.onFill(122)} fill={props.fillColors[122]}  d="M475.18,671.89c-3.05-1.83-5.69-3.41-9.15-5.48c3.33-1.28,6.17-2.35,8.99-3.45
				c4.84-1.89,9.61-4,14.56-5.57c1.4-0.44,3.53,0.27,4.91,1.13c4.31,2.7,8.24,6.06,12.71,8.42c1.42,0.75,4.56-0.19,6.04-1.41
				c4.89-4.06,10.76-3.59,16.39-4.42c2.44-0.36,5.31,0,7.23-1.2c6.47-4.03,13.79-2.42,20.64-3.7c6.09-1.13,12.33-1.7,18.52-1.84
				c1.88-0.04,3.79,2.23,5.73,3.36c1.47,0.85,3.42,2.56,4.46,2.14c4.48-1.8,8.51,0.29,12.72,0.69c0.8,0.08,2.11,0.04,2.31,0.47
				c2.56,5.45,5.84,3.43,9.09,0.93c2.91-2.24,4.98-0.7,7.38,1.04c1.8,1.31,4.11,1.88,6.09,2.97c1.8,0.99,3.45,2.23,5.17,3.36
				c-0.79,1.15-1.39,2.56-2.46,3.35c-0.62,0.46-2.01,0.04-3-0.21c-1.06-0.28-2.48-1.51-3.01-1.17c-5.05,3.23-9.93,6.71-14.15,10.72
				c-2.57,0.12-5.14,0.24-7.43,0.34c-2.64-0.72-5.23-1.43-7.81-2.12c-0.32-0.09-0.8,0-0.95-0.19c-4.01-4.94-9.49-4.64-14.94-4.1
				c-2.03,0.2-4,0.93-5.99,1.48c-0.45,0.13-0.83,0.74-1.25,0.75c-9.05,0.27-18.04,3.54-27.15,0.67c-0.51-0.16-0.99-0.8-1.25-1.33
				c-1.44-2.96-3.17-3.09-6.02-1.51c-1.66,0.92-4.1,0.41-6.19,0.55c-1.25,0.08-2.51,0.1-3.75,0.3c-3.93,0.64-7.89,1.19-11.76,2.11
				c-1.44,0.34-2.89,1.4-3.94,2.51c-4.71,4.97-7.3,5.02-12.21,0.49c-1.31-1.21-3.43-2.33-5.09-2.21c-3.88,0.29-6.45-1.18-7.95-4.51
				C480.98,671.5,478.85,669.34,475.18,671.89z"/>
			<path onClick={() => props.onFill(123)} fill={props.fillColors[123]}  d="M338.69,979.19c-1.82-6.42-3.64-12.85-5.46-19.27c-0.7,0.02-1.4,0.04-2.09,0.06
				c-0.78,2.02-1.99,3.98-2.25,6.06c-0.76,6.27-3.88,10.7-8.49,14.99c-2.67,2.49-3.5,7.21-4.6,11.09c-1.39,4.88-2.2,9.93-3.43,15.66
				c-1.75-2.05-3.59-3.32-4.12-5c-1.62-5.12-4.56-6.09-8.66-3.6c-1.58-2.28-3.21-4.11-4.22-6.24c-0.72-1.52-0.99-3.47-0.8-5.16
				c0.49-4.28,1.69-8.51,1.96-12.79c0.21-3.45,0.53-6.28,5.59-6.78c-5.74-7.87-7.31-15.73-3.38-22.94
				c-1.08-5.98-2.01-11.11-3.15-17.37c13.78,3.85,25.87,7.23,37.96,10.61c2.08,0.58,4.13,1.35,6.25,1.65
				c4.68,0.66,7.58,2.39,7.37,7.93c-0.06,1.7,2.1,3.95,3.8,5.17c3.22,2.31,6.98,3.87,10.25,6.11c4.1,2.81,4.9,6.26,2.12,10.32
				c-2.98,4.34-6.51,8.28-10.45,13.2c-1.74-4.71-2.97-8.38-4.49-11.94c-0.49-1.16-1.73-2-2.63-2.99c-0.77,0.96-1.78,1.81-2.25,2.89
				c-1.11,2.57-1.95,5.26-2.9,7.89C339.96,978.89,339.32,979.04,338.69,979.19z"/>
			<path onClick={() => props.onFill(124)} fill={props.fillColors[124]}  d="M772.59,809.34c1.29,1.38,2.25,2.4,4.33,4.61c-9.87,0.95-18.71,1.8-28.01,2.69
				c1.08-10.84-6.77-4.71-11-8.06c1.55-1.47,3.15-2.98,4.73-4.5c0.68-0.65,1.96-1.53,1.83-1.98c-0.26-0.91-1.17-2.03-2.04-2.29
				c-3-0.91-6.11-1.49-9.6-2.29c1.28-1.04,2.4-1.95,4.3-3.5c-5.94-4.17-12.46-0.82-18.85-3.17c1.24-0.43,1.7-0.71,2.17-0.73
				c16.76-0.69,33.53-2.03,50.27-1.79c11.02,0.15,22.02,2.42,32.98,4.09c3,0.46,6.2,1.51,4.73,6.36c-0.35,1.16,2.54,4.38,4.45,4.96
				c6,1.83,12.19,3.42,18.41,4.04c9.07,0.91,18.22,0.93,27.34,1.27c15.92,0.59,31.83,1.14,49.34,1.76c-2.99,3.41-4.85,5.82-7.06,7.87
				c-0.63,0.59-2.59,0.58-3.36,0.03c-3.95-2.83-7.08-1.65-10.9,0.72c-3.19,1.99-7.24,3.02-11.03,3.49
				c-4.24,0.52-8.62-0.15-12.94-0.15c-5.81,0.01-6.36,0.64-5.81,7.51c-2.38-0.76-4.55-0.94-6.06-2.03
				c-7.83-5.65-15.41-11.63-23.23-17.3c-3.74-2.71-7.34-1.29-10.43,3.16c-1.06,1.52-3.05,3.44-4.56,3.4
				c-4.37-0.12-8.72-1.09-13.06-1.88c-0.67-0.12-1.27-0.98-1.77-1.6C792.5,807.4,780.94,805.01,772.59,809.34z"/>
			<path onClick={() => props.onFill(125)} fill={props.fillColors[125]}  d="M460.21,382.34c2.55,5.95,4.14,6.18,8.43,1.62c3.45-3.68,6.26-3.93,8.95-0.12
				c3.43,4.86,8.34,3.48,12.59,3.09c4.25-0.39,9.06-6.69,9.04-10.83c-0.04-7.1-1.97-13.59-6.11-19.59c-1.88-2.72-2.04-6.56-3.44-9.69
				c-1.3-2.92-3.22-5.56-5.56-9.48c2.53-5.03-3.83-9.14-5.7-14.5c0.83-0.26,1.04-0.38,1.08-0.34c5.51,5.87,10.99,5.96,17.29,0.8
				c2.04-1.68,5.61-1.49,8.61-2.19c0.1,1.39,0.19,1.61,0.12,1.69c-11.37,12.29-5.35,26.67-4.73,40.37c0.05,1.18,1.44,3.06,2.43,3.22
				c1.03,0.16,2.61-1.15,3.44-2.21c1.39-1.78,2.38-3.88,4.01-6.62c0.08,8.51-0.2,15.85-7.06,20.99c-1.02,0.76-1.8,2-2.36,3.18
				c-6.01,12.57-14.56,23.19-24.63,32.68c-5.47,5.16-6.73,12.15-7.89,19.01c-0.8,4.7-2.71,8.28-6.24,11.44
				c-6.13,5.48-11.93,11.32-18.28,17.4c3.14-12.08,2.49-13.85-6.62-16.17c1.9-1.56,3.15-2.88,4.64-3.76
				c5.29-3.12,10.83-5.85,15.95-9.23c2.4-1.58,4.09-4.28,6.05-6.51c1.73-1.97,3.6-3.87,5.01-6.06c1.07-1.65,2.6-4.26,2-5.45
				c-0.81-1.59-3.45-3.01-5.39-3.13c-6.5-0.43-10.25,2.38-11.1,7.25c-4.81,0-9.62-0.42-14.31,0.15c-3.05,0.37-5.9,2.23-8.88,3.32
				c-3.29,1.2-6.62,2.29-10.19,2.9c1.93-5.75,2.07-11.14,8.82-14.6c6.74-3.46,11.65-10.6,17.18-16.31c3.3-3.4,6.17-7.21,9.4-10.68
				C457.59,383.11,459.05,382.87,460.21,382.34z"/>
			<path onClick={() => props.onFill(126)} fill={props.fillColors[126]}  d="M297.83,922.23c4.9,1.33,9.76,2.87,14.72,3.94c15.89,3.44,31.98,6.08,47.66,10.28
				c8.85,2.37,17.2,6.89,25.46,11.06c4.9,2.47,5.62,5.87,1.97,9.1c-7.23,6.4-7.72,15.91-12.05,23.59c-1.8,3.2-4.12,6.11-6.35,9.05
				c-0.68,0.89-1.87,1.37-2.76,2.12c-6.72,5.59-6.3,8.17,0.78,13.07c4.69,3.25,8.68,7.52,12.89,11.44
				c10.47,9.73,20.88,19.53,31.32,29.29c0.73,0.68,1.59,1.21,2.32,1.89c3.94,3.7,3.7,6.23-0.89,8.86
				c-14.37,8.21-29.52,14.44-45.75,17.92c-6.07,1.3-12.1,2.77-18.11,4.16c1.58-4.04,2.65-7.56,4.36-10.75
				c0.6-1.12,2.71-1.57,4.21-2.04c10.6-3.3,21.47-5.91,31.79-9.93c10.61-4.13,11.75-12.84,3.16-20.43
				c-11.47-10.12-23.18-19.94-34.89-29.79c-2.29-1.93-4.44-3.57-2.88-6.97c0.19-0.41,0.16-1.06-0.04-1.47
				c-3.86-8.23,0.69-13.29,6.02-18.97c10.74-11.44,9.58-16.89-4.27-24.35c-4.01-2.16-7.16-4.29-7.1-9.51
				c0.05-4.35-3.73-4.95-6.95-5.84c-13.76-3.81-27.52-7.6-41.26-11.49c-1.56-0.44-2.94-1.54-4.4-2.34
				C297.15,923.49,297.49,922.86,297.83,922.23z"/>
			<path onClick={() => props.onFill(127)} fill={props.fillColors[127]}  d="M912.66,224.56c-6.48-3.61-12.99-7.16-19.42-10.84c-19.49-11.14-38.86-22.48-58.47-33.39
				c-4.72-2.62-10.17-3.96-15.36-5.66c-1.37-0.45-3.01-0.07-4.52-0.07c-0.19,0.47-0.37,0.94-0.56,1.4
				c6.93,4.75,13.85,9.5,20.78,14.24c-0.19,0.34-0.39,0.68-0.58,1.02c-19.98-9.43-39.97-18.85-60.47-28.52
				c0.71-0.21,1.02-0.27,1.31-0.39c1.66-0.67,3.31-1.36,4.97-2.04c-1.37-1.43-2.47-3.55-4.14-4.17
				c-18.65-6.95-37.45-13.51-56.06-20.57c-3.82-1.45-7.01-4.54-10.58-6.93c3-7.17,3.67-6.86,11.23-4.74
				c19.92,5.6,39.1,13.09,57.5,22.41c9.27,4.69,18.78,7.56,28.96,8.29c4.94,0.35,9.94-0.34,14.91-0.38
				c5.8-0.05,14.35,8.67,14.76,14.44c0.1,1.42,1.39,3.35,2.67,4.01c7.82,4.04,15.84,7.69,23.66,11.72c1.78,0.92,3.6,2.59,4.44,4.37
				c2.57,5.49,7.01,8.5,12.12,11.31c9.3,5.12,18.3,10.79,27.38,16.31c2.7,1.64,5.26,3.51,7.88,5.27
				C914.26,222.64,913.46,223.6,912.66,224.56z"/>
			<path onClick={() => props.onFill(128)} fill={props.fillColors[128]}  d="M15.74,1088.01c1.6,0.67,3.17,1.46,4.82,1.97c1.38,0.43,3.14,1.16,4.26,0.67
				c8.66-3.74,17.13-1.72,25.71,0.24c1.8,0.41,3.86-0.52,5.81-0.59c8.02-0.29,15.78-1.92,23.66-3.35
				c11.47-2.08,22.79,1.94,32.67,8.63c0.92,0.63,1.36,2.21,1.69,3.45c1.32,5.01,4.49,7.29,9.61,8.28
				c8.62,1.67,13.54,5.27,15.71,15.07c0.53,2.41-0.11,3.77-2.55,3.4c-2.98-0.45-5.91-1.3-8.83-2.08c-3.47-0.92-6.9-2.68-10.38-2.77
				c-13.09-0.35-26.19-0.14-39.29-0.18c-4.62-0.01-8.07-1.73-11.47-5.31c-4.9-5.16-11.65-7.21-18.56-4.13
				c-4.58,2.04-7.61-0.29-10.79-2.37c-4.64-3.04-9.2-6.26-13.46-9.81c-3.49-2.91-6.5-6.41-9.72-9.64
				C14.99,1088.99,15.36,1088.5,15.74,1088.01z"/>
			<path onClick={() => props.onFill(129)} fill={props.fillColors[129]}  d="M76.27,699.98c1.05-1.61,2.01-3.07,2.71-4.14c3.22,0.26,6.14,0.5,9.72,0.8c2.67,4.38,8.66,2.09,11.1-7.09
				c2.77,0.94,5.47,1.91,8.21,2.75c0.89,0.28,2.29,0.68,2.77,0.25c6.74-6,15.96-4.21,23.55-7.52c0.79-0.35,3,1.18,3.73,2.35
				c1.49,2.4,2.68,2.93,5.61,1.93c3.26-1.11,6.03-1.98,8.83-4.49c1.93-1.73,6.73,0.19,9.97-0.62c7.37-1.84,14.54-4.5,21.88-6.51
				c2.12-0.58,4.56-0.32,6.81-0.04c5.78,0.72,11.53,1.79,17.32,2.41c1.03,0.11,2.13-1.5,3.33-1.83c2.12-0.58,4.32-0.91,6.51-1.09
				c1.05-0.08,2.81,0.98,3.13,0.6c4.55-5.45,10.14-1.31,15.18-1.92c2.7-0.33,5.3-1.48,9.29-2.66c2.28,2.3,5.91,6.3,11.9,4.92
				c4.4,5.88,9.77,0.73,15.2,2.5c-2.71,1.27-5.41,2.55-8.12,3.8c-0.29,0.14-0.71,0.06-0.96,0.23c-11.81,8.19-26.86,5.42-39.25,11.68
				c-0.86,0.43-1.92,0.49-2.9,0.64c-3.77,0.58-7.54,1.12-12.19,1.8c-1.25-1.64-3.47-4.08-6.51-0.1c-0.25,0.33-0.85,0.65-1.23,0.59
				c-4.56-0.77-9.14-1.43-13.63-2.5c-2.76-0.65-5.23-1.42-6.34,0.84c-2.74,0-4.95-0.24-7.09,0.05c-3.69,0.51-7.58,2.52-10.96,1.82
				c-8.87-1.83-17.16-1.3-25.43,2.34c-1.58,0.7-3.73,0.14-5.61,0.12c-1.45-0.02-3.07-0.55-4.32-0.08c-4.47,1.68-10.51,1.21-14.53-1.5
				c-0.27-0.18-0.68-0.56-0.82-0.49c-4.8,2.62-9.5-3.7-15.32,0.82c-3.53,2.74-10.68,4.24-16.21-0.02
				C80.46,699.75,78.25,700.19,76.27,699.98z"/>
			<path onClick={() => props.onFill(130)} fill={props.fillColors[130]}  d="M703.28,596.98c-4.88,0.19-9.76,0.53-14.63,0.5c-3.91-0.02-7.81-0.66-11.72-0.74
				c-3.6-0.07-7.26,0.85-10.23-2.37c-0.66-0.71-2.48-0.37-3.77-0.46c-3.37-0.23-6.73-0.42-10.1-0.66c-1.14-0.08-2.28-0.36-3.42-0.37
				c-6.1-0.03-12.21,0.06-18.31-0.02c-9.34-0.12-18.7-0.01-28.01-0.63c-5.31-0.35-10.5-2.34-15.82-2.9c-7.2-0.76-14.46-0.91-21.7-1.2
				c-1.8-0.07-3.65,0.7-5.42,0.5c-7.86-0.87-15.71-1.9-23.54-3c-1.07-0.15-2.04-1.03-3.07-1.53c-1.93-0.93-3.91-2.66-5.82-2.59
				c-6.59,0.24-11.56-2.97-16.57-6.5c-0.86-0.61-2.23-0.74-3.34-0.69c-9.39,0.44-18.78,1.19-28.18,1.31
				c-2.28,0.03-4.92-1.52-6.79-3.08c-2.11-1.76-3.54-4.35-5.49-6.86c3.77-3.69,1.27-5.37-1.56-8.47
				c-10.92-12-26.29-15.04-40.04-21.33c-2.1-0.96-4.25-1.81-6.4-2.72c8.6-3.79,14.13,3.26,20.97,5.59c7.14,2.43,14.84,3.8,21.32,7.4
				c5.66,3.15,12.61,4.31,16.99,9.77c0.57,0.71,1.78,1.51,2.54,1.38c6.03-1.06,8.65,4.53,13.09,6.44c2.51,1.08,5.42,1.22,8.14,1.81
				c2.69,0.59,5.35,1.39,8.07,1.77c4.14,0.59,8.44,0.45,12.46,1.45c8,1.99,16.53,0.3,24.32,4.89c3.67,2.16,9.27,1.07,14,1.4
				c3.92,0.27,7.86,0.44,11.78,0.68c1.82,0.11,3.74,0.75,5.43,0.35c4.45-1.06,8.46-1.01,11.98,2.42c0.62,0.6,1.86,0.71,2.83,0.78
				c4.84,0.32,9.73,0.24,14.52,0.91c3.24,0.45,6.32,2.68,9.51,2.77c6.44,0.19,12.67,3.44,19.4,0.89c1.59-0.6,4.31,1.85,6.54,2.82
				c1.78,0.78,4.16,2.54,5.31,1.96c3.97-2.02,7,0.24,10.39,1.19c3.92,1.1,7.81,3.26,11.99,0.75c0.24-0.15,0.66-0.07,0.97-0.02
				c9.75,1.7,19.51,3.39,29.24,5.16c0.75,0.14,1.39,0.85,2.08,1.29C703.23,597.04,703.28,596.98,703.28,596.98z"/>
			<path onClick={() => props.onFill(131)} fill={props.fillColors[131]}  d="M532.35,445.91c4.31-5.54,7.89-10.95,12.27-15.6c8.64-9.18,17.72-17.93,26.63-26.87
				c0.59-0.59,1.36-1.07,1.74-1.77c6.51-11.75,17.49-10.82,28.42-10.16c2.79,0.17,5.6,0.14,8.4,0.22c2.31,0.06,5.47-0.78,6.75,0.41
				c2.72,2.52,3.77,6.24,2.09,10.08c-1,2.29-2.59,4.45-3.02,6.83c-2.11,11.88-12.32,16.7-20.13,23.57c-2.58,2.27-5.71,3.94-8.72,5.66
				c-1.05,0.6-2.46,0.66-3.73,0.77c-8.2,0.72-16.48,0.92-24.58,2.26c-4.01,0.67-7.61,3.56-11.52,5.14c-2.1,0.85-4.46,1.49-6.69,1.44
				C538.03,447.86,535.81,446.84,532.35,445.91z"/>
			<path onClick={() => props.onFill(132)} fill={props.fillColors[132]}  d="M793.58,227.2c-7.07,0-14.14-0.09-21.21,0.02c-8.61,0.15-17.21,0.52-25.81,0.7
				c-4.79,0.1-9.59-0.12-14.37,0.01c-5.97,0.17-12.01,1.33-17.88,0.69c-16.36-1.78-32.42,1.12-48.6,2.1
				c-6.5,0.39-11.15,2.75-13.64,9.66c-0.49-1.03-1.41-2.07-1.41-3.1c0.05-6.65,0.08-13.33,0.65-19.95c0.15-1.68,1.95-4.06,3.51-4.6
				c3.05-1.04,6.46-1.19,9.74-1.36c3.79-0.21,7.59-0.02,11.39-0.05c17.41-0.15,34.81-0.16,52.21-0.54
				c12.93-0.28,25.86-0.95,38.78-1.68c4.15-0.24,7.25,1.12,10.32,3.78c5.26,4.57,10.83,8.77,16.27,13.12
				C793.54,226.4,793.56,226.8,793.58,227.2z"/>
			<path onClick={() => props.onFill(133)} fill={props.fillColors[133]}  d="M270.99,272.65c-33.15,0.27-65.84,1.13-98.51,0.64c-32.61-0.5-65.21-2.35-97.97-4.05
				c6.25-2.78,11.83-7.36,19.27-6.97c13.37,0.7,26.54,0.08,39.13-5.17c1.35-0.56,2.9-0.88,4.36-0.88c7.32,0,14.01-2.06,20.51-5.33
				c13.62-6.85,26.62-2.25,40.63,2.04c-1.5,1.23-2.12,1.71-2.71,2.23c-3.28,2.83-3.05,4.98,1.18,6.25c3.6,1.08,7.47,1.38,11.24,1.81
				c8.39,0.94,16.79,1.85,25.2,2.55c3.09,0.26,6.32,0.32,9.33-0.3c9.93-2.05,18.97-0.45,27.15,5.63
				C270.16,271.36,270.37,271.82,270.99,272.65z"/>
			<path onClick={() => props.onFill(134)} fill={props.fillColors[134]}  d="M210.2,1182.22c0.51-4.04,1.02-8.07,1.53-12.11c3.99,5.63,0.58,13.66,8.33,18.69
				c2.52-5.19,4.92-10.13,7.76-15.99c0.51,4.83,1.49,9.19,1.25,13.48c-0.2,3.57,0.88,6.1,4.06,5.94c1.8-0.09,4.3-2.77,4.99-4.79
				c1.57-4.63,2.22-9.58,3.3-14.71c5.44,6.93,7.36,14.78,8.32,22.9c0.07,0.61-2.27,2.11-3.54,2.17c-4.97,0.26-9.97,0.11-14.96,0.11
				c-27.6,0-55.19,0.01-82.79,0c-7.24,0-7.77-1.21-7.55-8.73c0.1-3.29-2.7-6.53-0.56-10.22c1.56-2.69,2.67-5.64,4.65-9.93
				c1.3,2.51,2.13,4.12,3.51,6.78c3.43-4.9,5.89-8.41,8.52-12.16c1.65,3.74,3.14,7.11,4.75,10.76c8.84-3.67,8.93-10.94,9.37-18.41
				c0.55,1.1,1.41,2.15,1.6,3.31c0.63,3.72,0.59,7.61,1.71,11.16c0.5,1.59,3.04,3.24,4.84,3.48c1.26,0.16,3.26-1.77,4.13-3.24
				c1.1-1.86,1.4-4.2,2.04-6.33c0.51-0.24,1.01-0.47,1.52-0.71c0.21,0.66,0.58,1.32,0.6,1.98c0.15,5.87,4.8,7.9,8.82,10.5
				c3.94,2.55,4.04,2.54,7.11-1.3c1.77,2.81,3.55,5.62,5.32,8.44C209.28,1182.91,209.74,1182.57,210.2,1182.22z"/>
			<path onClick={() => props.onFill(135)} fill={props.fillColors[135]}  d="M361.83,625.26c-5.89-3.78-8.74-3.17-15.09,0.36c-5.4,3-11.46,5.82-17.47,6.45
				c-6.41,0.67-12.43-2.42-16.06-10.42c3.25-0.22,6.3,0.21,8.78-0.78c2.65-1.06,5.62-3,6.9-5.4c1.8-3.34,2.97-3.77,5.92-1.53
				c3.64,2.76,7.52,5.2,11.75,0.57c0.63-0.69,2.42-1.11,3.22-0.72c6.32,3.07,10.08-1.17,14.35-4.52c1.06-0.84,3.29-1.22,4.49-0.68
				c6.91,3.06,6.86,3.17,10.11-4.19c1.83,1,3.59,2.02,5.4,2.93c1.4,0.7,3.21,2.09,4.27,1.69c2.73-1.05,3.95-0.05,5.89,1.71
				c1.02,0.93,3.96-0.27,7.17-0.64c2.13,3.05,6.92,3.83,11.79,5.47c8.73,2.95,16.74,8.03,25.03,12.26
				c7.04,3.59,13.28,8.31,22.24,7.81c5.15-0.29,10.52,3.49,15.8,5.43c1.05,0.39,2.09,0.83,3.17,1.12c2.41,0.64,4.84,1.19,7.65,1.87
				c-4.14,5.4-6.65,5.32-13.97,2.92c-8.44-2.77-17.17-4.71-25.81-6.83c-6.55-1.61-13.41-2.27-19.69-4.57
				c-7.16-2.62-13.68-0.31-20.5,0.51c-2.8,0.33-5.72-0.18-8.57-0.45c-1.13-0.11-2.25-0.93-3.32-0.84c-6.95,0.58-12.5-2.13-17.7-6.54
				c-2.62-2.22-5.96-3.96-9.26-4.88C366.55,622.86,364.15,624.52,361.83,625.26z"/>
			<path onClick={() => props.onFill(136)} fill={props.fillColors[136]}  d="M838.46,632.61c-1.72,1.1-2.18,1.64-2.55,1.59c-4.61-0.64-9.35-0.89-13.77-2.2
				c-5.68-1.69-5.79,2.46-7.01,6.58c-3.64-0.79-7.09-1.54-10.54-2.3c-0.64-0.14-1.49-0.67-1.87-0.43c-3.24,2.07-6.67,0.99-10.01,0.67
				c-6.76-0.66-8.21,6.55-13.53,8.64c-4.49-3-9.18-5.8-15.55-3.12c-2.22,0.93-5.74,0.62-8.37-2.28c-1.84-2.02-6.03-1.81-8.09-3.74
				c-4.76-4.47-9.54-5.58-15.82-3.67c-2.4,0.73-5.79-0.85-8.42-2.06c-5.26-2.43-10.26-1.55-15.44,0.05c-1.51,0.47-3.29,0.06-4.95,0
				c-1.91-0.07-3.82-0.23-5.74-0.31c-0.41-0.02-1.09,0.37-1.21,0.23c-3.4-4.3-8.65-0.65-12.12-2.63c-4.89-2.79-11.08,1.18-15.35-3.69
				c-0.68-0.78-2.7-0.56-4.1-0.51c-3.37,0.11-6.81,0.9-10.09,0.45c-4.14-0.57-8.11-2.22-12.21-3.16c-2.19-0.5-4.5-0.49-6.83-1.85
				c0.68-0.37,1.34-1.03,2.03-1.06c6.12-0.28,12.24-0.43,18.35-0.66c2.15-0.08,4.3-0.39,6.44-0.37c2.26,0.02,4.51,0.35,6.76,0.48
				c1.44,0.08,2.88,0.04,4.32,0.06c3.49,0.04,6.99,0.06,10.48,0.15c0.64,0.02,1.27,0.43,1.91,0.48c4.88,0.37,9.81,0.34,14.62,1.12
				c4.56,0.73,8.81,1.1,12.95-1.47c0.72-0.45,2.11,0.02,3.14,0.28c0.77,0.19,1.83,1.25,2.11,1.04c5.81-4.27,11.27,0.65,16.89,0.54
				c5.3-0.1,10.61-0.23,15.9-0.13c4.18,0.08,8.36,0.55,12.54,0.75c5.57,0.27,11.14,0.44,16.7,0.67c0.47,0.02,1.2,0.03,1.35,0.3
				c3.21,5.83,8.29,1.16,12.37,2.18c4.14,1.02,8.52,1,12.72,1.81c4.93,0.95,9.78,2.32,14.67,3.49
				C826.44,629.78,831.7,631.02,838.46,632.61z"/>
			<path onClick={() => props.onFill(137)} fill={props.fillColors[137]}  d="M676.43,922.33c15.79-1.41,31.56,2.32,47.12,8.29c11.15,4.27,22.79,7.24,34.07,11.19
				c14.96,5.24,26.49,15.46,37.51,26.23c-3.05,5.17-2.92,7.66,1.97,9.83c7.39,3.29,15.18,5.73,22.93,8.11
				c1.37,0.42,3.36-1.2,6.28-2.35c3.19,2.19,10.14,0.24,13.47,6.82c-4.08,2.37-7.98,4.15-12.69,2.57c-2.2-0.74-4.45-1.34-6.65-2.11
				c-10.6-3.72-21.44-5.41-32.74-5.19c-8.41,0.16-8.3-0.42-10.96-8c-4.09-11.65-11.36-19.86-23.7-23.66
				c-8.96-2.76-17.13-7.69-27.11-7.2c-7.05,0.35-14.18-0.55-21.24-1.23c-3.61-0.35-7.16-1.44-10.72-2.25
				c-4.06-0.92-6.89-2.85-8.77-7.08C683,931.31,679.42,926.96,676.43,922.33z"/>
			<path onClick={() => props.onFill(138)} fill={props.fillColors[138]}  d="M686.3,173.02c8.77-0.49,17.53-1.29,26.3-1.31c3.52-0.01,5.99-0.15,6.16-3.53
				c5.57-0.31,10.51-0.83,15.43-0.72c1.76,0.04,4.67,1.24,5.05,2.52c4.05,13.63,15.25,21.48,24.4,30.98
				c1.36,1.41,2.83,2.72,5.24,5.02c-39.92,1.11-78.69,2.19-117.62,3.27c-1.23-5.95,0.29-8.46,7.28-8.93
				c10.43-0.71,20.89-0.92,31.34-1.21c9.46-0.26,18.92-0.33,28.37-0.51c7.43-0.13,14.87-0.23,22.3-0.49c1.54-0.05,3.06-0.72,4.59-1.1
				c-0.01-0.49-0.03-0.97-0.04-1.46c-1.26-0.17-2.53-0.51-3.78-0.48c-6.59,0.18-13.18,0.44-19.76,0.72
				c-6.97,0.3-13.94,0.86-20.91,0.96c-12.89,0.19-25.79-0.02-38.67,0.25c-3.55,0.07-7.08,1.23-11.79,2.11
				c3.04-4.67,7.05-6.51,11.17-6.68c11.05-0.46,22.12-0.13,33.18-0.32c7.31-0.12,14.61-0.74,21.91-0.83c4.3-0.06,8.6,0.67,12.9,0.66
				c1.94,0,3.87-0.94,5.81-1.45c-1.47-1.75-2.7-4.67-4.46-5.04c-6.46-1.36-13.14-2.72-19.66-2.43c-5.84,0.26-10.94-1.13-15.83-3.51
				c-3.24-1.58-5.92-4.32-8.86-6.54L686.3,173.02z"/>
			<path onClick={() => props.onFill(139)} fill={props.fillColors[139]}  d="M597.79,687.15c-3.17,2.7-5.79,5.04-8.53,7.23c-1.62,1.29-3.75,2.07-5.08,3.58
				c-1.61,1.83-3.3,4.05-3.77,6.34c-1.03,4.95-1.76,5.41-5.94,2.94c-2.67-1.57-5.69-3.35-8.61-3.47c-2.4-0.1-4.89,2.12-7.83,3.55
				c-2.62-4.54-7-2.42-11.19-0.45c-3.45,1.62-6.81,1.38-10.51,1.53c-7.38,0.3-13.33-3.15-20-4.42c-0.96-0.18-2.12-0.41-2.95-0.05
				c-4.24,1.82-8.43,4.31-11.99-1.16c-0.23-0.35-1.25-0.22-1.91-0.25c-4.19-0.19-8.38-0.36-12.57-0.54
				c-0.07-0.59-0.14-1.18-0.21-1.77c0.64-0.59,1.23-1.25,1.94-1.75c7.22-5.13,13.94-11.44,21.89-14.9c4.98-2.17,11.13-5.6,17.5-2.64
				c0.72,0.33,1.92,0.71,2.38,0.37c3.33-2.5,5.47-0.68,8.92,0.57c5.47,1.97,12.15,1.25,18.22,0.68c8.2-0.77,16.52-4.17,24.39-3.2
				C587.2,679.97,594.31,681.97,597.79,687.15z"/>
			<path onClick={() => props.onFill(140)} fill={props.fillColors[140]}  d="M606.28,657.59c-2.53-0.37-5.26-0.26-7.54-1.22c-4.27-1.8-8.28-4.35-12.8-0.75
				c-0.09,0.07-0.69-0.24-0.79-0.48c-2.56-6.37-8.75-4.14-13.35-5.61c-0.85-0.27-2.17-0.22-2.85,0.27c-4.56,3.28-7.65,0.09-10.94-2.3
				c-0.53-0.39-0.97-1.15-1.52-1.22c-4.46-0.56-9.36-2.38-13.29-1.15c-5.52,1.73-9.39-0.72-12.94-3.14
				c-4.13-2.81-7.71-4.78-13.03-4.39c-4.36,0.32-8.88-1.37-13.3-2.31c-0.77-0.16-1.37-1.06-2.07-1.58c-1.95-1.44-3.71-3.5-5.9-4.18
				c-8.12-2.53-16.39-4.6-24.6-6.84c0.02-0.46,0.03-0.93,0.05-1.39c7.35,0,14.74-0.41,22.06,0.12c7.4,0.53,14.83,1.54,22.06,3.17
				c9.15,2.06,18.09,5.01,27.16,7.41c1.34,0.36,2.92-0.16,4.38-0.26c1.48-0.1,3.95-0.76,4.28-0.19c3.14,5.43,7.38,0.88,10.97,1.5
				c4.2,0.73,8.35,1.73,12.54,2.53c1.77,0.34,3.58,0.61,5.37,0.63c2.69,0.02,5.32-0.33,6.69,2.93c0.36,0.86,1.77,1.65,2.8,1.8
				c5.9,0.87,11.84,1.47,17.74,2.33c1.52,0.22,2.98,1,4.41,1.64c1.59,0.71,3.04,1.99,4.68,2.28c5.82,1.01,11.43,3.39,17.67,1.81
				c1.8-0.46,4.32,1.39,6.36,2.46c4.53,2.37,8.83,5.22,13.49,7.29c3.09,1.37,6.68,1.57,9.93,2.64c1.35,0.44,2.4,1.78,3.17,3.58
				c-7.22-7.13-13.11,0.42-19.32,1.41c-3.99-5.14-8.75-5.83-14.45-2.29c-0.84,0.52-2.25,0.96-3.02,0.6
				c-4.17-1.91-8.54-3.63-12.21-6.29c-3.38-2.45-6.02-3.01-9.24-0.24c-0.7,0.6-1.82,0.73-2.74,1.07
				C606.22,658.68,606.25,658.14,606.28,657.59z"/>
			<path onClick={() => props.onFill(141)} fill={props.fillColors[141]}  d="M642.56,677.92c-4.38-2.08-6.58-0.38-7.63,3.56c-0.65,2.43-0.47,5.47-4.09,5.91
				c-0.32,0.04-0.69,0.73-0.83,1.18c-2.27,7.33-4.67,14.63-6.66,22.04c-0.64,2.39-0.33,4.47-4,4.11c-1.39-0.14-3.73,1.81-4.41,3.35
				c-2.58,5.87-4.65,11.96-6.71,17.43c-17.87,0-35.87,0-53.88,0c-0.17-0.74-0.33-1.48-0.5-2.23c6.05-2.29,12-4.86,18.17-6.74
				c3.14-0.96,5.08-1.5,5.56-5.53c0.41-3.47,2.98-6.68,4.62-10c0.22-0.44,0.77-0.81,0.81-1.24c0.59-7.37,7.81-9.69,11.29-14.85
				c0.49-0.73,1.61-1.01,2.41-1.55c2.37-1.58,4.72-3.18,8-5.38c4.36,1.85,5.23-2.82,5.94-5.14c4.17-2.33,7.13-3.98,10.63-5.94
				c3.51,1.98,8.16,0.38,11.15-4.43c1.34-2.15,2.99-4.42,5.07-5.7c1.27-0.78,4.64-0.43,5.27,0.57c1.16,1.81,1.17,4.47,1.29,6.8
				C644.12,675.16,643.27,676.24,642.56,677.92z"/>
			<path onClick={() => props.onFill(142)} fill={props.fillColors[142]}  d="M520.42,402.61c-3.9,4.87-7.47,9.09-10.77,13.52c-14.51,19.47-28.06,39.45-38.16,61.75
				c-7.06,15.59-18.48,28.35-31.1,39.87c-0.78,0.71-2.19,0.73-3.31,1.07c-0.13-1.01-0.58-2.11-0.31-3c0.61-2.01,1.61-3.9,2.38-5.87
				c1.11-2.85,2.17-5.73,3.23-8.6c0.17-0.45,0.14-1.21,0.42-1.33c5.27-2.28,2.7-6.79,3.51-10.53c1.33-6.13,3.05-13.45,7.28-17.42
				c9.21-8.67,12.76-19.58,16.29-30.82c0.84-2.68,1.61-5.49,1.76-8.28c0.61-11.52,8.33-18.62,16.21-25.15
				c2.96-2.45,8.01-2.47,12.17-3.21c0.39-0.07,2.13,2.9,1.78,3.98c-1.37,4.25-3.38,8.28-5.02,12.45c-0.38,0.96-0.24,2.13-0.33,3.21
				c1.19-0.09,2.63,0.23,3.54-0.33c4.54-2.79,7.47-6.72,8.99-12.03C511.04,404.68,513.03,403.29,520.42,402.61z"/>
			<path onClick={() => props.onFill(143)} fill={props.fillColors[143]}  d="M427.52,298.42c0-7.59-1.13-15.02,0.32-21.92c1.4-6.67,5.45-12.79,7.64-19.98
				c-1.64,1.91-3.5,3.67-4.85,5.77c-1.48,2.31-2.48,4.93-4.08,7.19c-0.43-12.98,8.37-20.21,17.62-27.87
				c1.47,2.41,3.67,4.59,4.17,7.11c1.58,7.91,5.99,14.27,9.94,21c2.32,3.95,3.46,8.58,5.26,12.84c0.4,0.95,1.29,2.27,2.04,2.34
				c0.83,0.07,2.22-0.93,2.58-1.79c1.91-4.61,3.65-9.25,1.75-14.44c-1.66-4.54-0.12-7.26,4.57-8.51c3.04-0.81,6.16-1.74,9.24-1.76
				c1.54-0.01,4.05,1.62,4.46,3.01c1.72,5.71,2.74,11.62,4.18,17.42c0.28,1.14,1.33,3.03,1.88,2.98c1.28-0.12,2.68-1,3.62-1.97
				c0.72-0.75,1.26-2.19,1.1-3.2c-1.43-8.99,1.98-17.36,3.05-26.02c0.15-1.23-0.09-2.51,0.15-3.83c1.67,7.66,8.2,13.46,6.69,22.6
				c-0.93,5.62,1.62,11.81,2.7,18.32c-2.92-0.31-6.1-0.71-9.3-0.98c-7.23-0.61-14.5-1.92-21.69-1.56
				c-11.92,0.6-24.28-1.36-35.52,4.84c-1.93,1.06-4.56,0.81-6.55,1.81C435.05,293.55,431.85,295.77,427.52,298.42z"/>
			<path onClick={() => props.onFill(144)} fill={props.fillColors[144]}  d="M711.85,514.84c-2.35-3.22-4.52-1.25-7.85,0.55c-6.89,3.72-9.44,9.7-11.46,16.48
				c-0.66,2.2-1.97,4.21-2.96,6.31c-1.32,2.83-3.09,4.05-6.6,3.69c-6.63-0.67-12.26,3.57-13.74,9.72c-2.71,0-5.49,0-8.67,0
				c0.42-5.71,0.51-11.3,1.29-16.79c1.31-9.12,2.92-18.21,4.73-27.24c0.41-2.04,1.83-5.05,3.39-5.48c5.78-1.62,11.8-3.14,17.75-3.23
				c7.93-0.12,15.5,0.62,23.34-2.81c8.26-3.61,17.43-5.19,26.28-7.37c3.54-0.87,4.93,1.61,3.51,4.39c-4,7.79-8.94,14.82-17.97,17.63
				C719.45,511.76,716.13,513.22,711.85,514.84z"/>
			<path onClick={() => props.onFill(145)} fill={props.fillColors[145]}  d="M239.99,801.96c-1.4,0.36-2.78,0.9-4.21,1.04c-12.39,1.22-24.77,2.49-37.18,3.38
				c-1.57,0.11-3.66-1.58-4.85-2.99c-2.44-2.88-3.22-6.92-8.14-7.91c-1.83-0.37-2.55-5.04-4.29-7.37c-2.7-3.6-5.88-6.84-8.77-10.3
				c-1.67-2-5.59-3.26-2.51-7.55c3.74,6.14,8.38,3.67,13.31,1.68c0.62,4.8,3.27,4.77,7.68,4.68c5.52-0.11,11.47-1.47,16.99,2.16
				c2.88,1.9,8,0.7,10.49,2.81c5.87,4.98,12.44,5.26,19.15,4.49c4.3-0.49,9.09-0.51,12.18-5.13c1.12-1.67,4.32-1.94,6.9-2.97
				c2.92,4.11,7.12,4.68,11.94,2.94c0.69-0.25,2.02-0.42,2.23-0.09c2.77,4.26,5.86,3.1,9.26,0.17c3.91,5.01,7.48,9.85,11.38,14.41
				c1.17,1.37,3.65,1.61,4.85,2.97c1.22,1.38,1.59,3.51,2.61,5.97c-2.04,0.45-3.7,1.11-5.38,1.14c-3.15,0.05-6.31-0.49-9.45-0.36
				c-5.16,0.22-9.2-0.93-12.97-5.15c-2.52-2.83-7.12-3.81-10.27-5.37c0.39-0.45,1.55-1.79,2.71-3.14c-1.92-1.07-3.78-2.88-5.77-3.04
				c-4.54-0.38-9.15,0.23-13.73,0.12c-5.86-0.15-8.08,4-10.05,8.31c-0.32,0.69,0.82,2.58,1.75,3.17c0.98,0.62,2.53,0.33,3.83,0.44
				C239.77,800.95,239.88,801.45,239.99,801.96z"/>
			<path onClick={() => props.onFill(146)} fill={props.fillColors[146]}  d="M404.77,272.99c2.24,6.53,1.85,13.05,0.17,19.64c-0.28,1.09,0.08,2.7,0.76,3.61
				c3.14,4.22,3.22,4.17,0.4,7.95c-8.85-2.67-17.3-5.29-25.8-7.71c-1.32-0.38-3.02-0.14-4.34,0.37c-1.09,0.42-1.87,1.65-2.79,2.51
				c0.87,0.84,1.74,1.68,2.62,2.52c0.75,0.72,1.5,1.43,1.78,2.61c-5.63-2.4-5.63-2.4-10.61,4.73c-1.44-0.98-2.88-1.96-4.12-2.8
				c0-3.49,0-6.69,0-11.53c-3.22,2.36-5.39,3.91-7.51,5.52c-4.05,3.05-5.78,2.67-8.78-1.23c-0.59-0.77-3.46-0.97-4.04-0.33
				c-1.61,1.82-2.52,4.24-3.81,6.37c-0.89,1.48-1.95,2.86-3.08,4.51c-4.58-7.45-1.06-14.97,6.56-16.22c2.04-0.33,3.81-2.59,5.6-4.08
				c1.45-1.2,2.76-2.58,4.13-3.89c0.39,0.29,0.78,0.57,1.17,0.86c-0.23,1.82-0.45,3.64-0.68,5.46c9.14,1.35,15.02,0.41,16.98-11.5
				c0.42-2.57,1.72-5.01,2.74-7.46c1.39-3.36,0.06-5.61-3.38-4.63c-3.51,1-6.66,3.26-10.51,5.24c1.14-1.5,2.12-2.82,3.12-4.12
				c1.36-1.78,3.16-3.36,4-5.36c1.64-3.9,0-6.15-4.28-6.04c-3.97,0.1-7.99,0.35-11.88,1.1c-1.41,0.27-3.37,2.05-3.57,3.36
				c-0.42,2.76-1.56,3.86-4.16,3.77c-10.45-0.36-16.26,7.45-23.12,12.39c0.06-0.99,0.94-3.5,0.08-4.45c-1.04-1.15-3.58-1.45-5.4-1.31
				c-7.27,0.55-14.51,1.42-21.56,2.15c2.8-4.32,6.07-9.02,12.88-10.36c11.81-2.33,23.46-5.47,35.26-7.8
				c5.67-1.12,11.61-2.01,17.31-1.56c12.14,0.94,23.21-2.12,34.33-6.45c6.02-2.35,12.66-3.07,19.03-4.52
				c0.29,0.85,0.58,1.69,0.87,2.54c-3.08,1.3-6.08,2.91-9.27,3.85c-7.75,2.27-15.64,4.08-23.37,6.41c-1.51,0.46-2.81,2.29-3.72,3.79
				c-0.58,0.96-0.8,2.96-0.22,3.6c0.83,0.89,2.57,1.34,3.89,1.27c1.61-0.08,3.14-1.05,4.76-1.36c5.84-1.11,11.67-2.32,17.55-3.07
				c3.74-0.48,6.77-0.78,8.89-4.92c1.17-2.28,5.05-3.17,8.75-5.27c0.86,7.54,1.6,14.09,2.5,21.92c-4.68-3.45-8.01-6-11.45-8.39
				c-1.21-0.84-2.69-1.76-4.05-1.77c-1.9-0.01-3.81,0.8-5.71,1.26c0.9,1.71,1.75,3.44,2.7,5.11c0.74,1.3,1.61,2.52,2.42,3.77
				c-6.55,0.08-13.21-0.51-19.58,0.61c-2.41,0.42-4.13,4.81-5.35,6.36c-2.92,0.65-5.52,0.48-6.98,1.69
				c-3.61,3.01-4.99,7.12-3.9,11.77c3.92,0,7.49-0.66,10.71,0.14c5.89,1.46,11.52,3.93,17.36,5.64c1.42,0.42,3.92,0.08,4.65-0.88
				c0.78-1.03,0.06-3.21,0-4.88c-0.05-1.43-0.53-3.03-0.06-4.27C403.68,283.99,406.3,278.91,404.77,272.99z"/>
			<path onClick={() => props.onFill(147)} fill={props.fillColors[147]}  d="M182.75,308.65c-8.74-3.12-16.51-3.5-24.53-1.95c-2.4,0.47-4.95,0.67-7.38,0.44
				c-5.75-0.54-11.45-1.4-14.61,4.49c-9.71-6.4-19.58-10.39-31.51-10.86c1.59-2.74,2.81-5,4.18-7.18
				c3.42-5.44,6.82-10.88,10.44-16.19c0.64-0.93,2.26-1.73,3.41-1.7c16.43,0.42,32.86,1.21,49.3,1.38c4.86,0.05,6.74,2.08,6.85,6.3
				C179.11,291.88,175.34,300.81,182.75,308.65z"/>
			<path onClick={() => props.onFill(148)} fill={props.fillColors[148]}  d="M789.63,481.49c-0.12,1.75-0.12,3.52-0.4,5.25c-0.16,0.94-0.9,1.78-1.22,2.71
				c-0.79,2.28-1.48,4.59-2.21,6.88c2.3,0.23,4.61,0.46,6.75,0.68c-2.2,3.71-4.6,7.77-7.2,12.18c-0.34-2.39-0.32-4.66-1.08-6.62
				c-0.44-1.14-2.12-2.45-3.27-2.47c-1.07-0.02-2.18,1.55-3.24,2.46c-0.23,0.2-0.43,0.59-0.42,0.89
				c0.47,12.48-11.26,16.27-17.21,24.16c-0.57,0.75-1.7,1.68-2.44,1.58c-6.31-0.87-9.58,4.32-14.13,6.91
				c-3.02,1.72-5.45,2.58-6.5-2.21c-0.2-0.91-3.29-2.19-4.37-1.72c-3.16,1.36-5.56,2.43-9.17,0.21c-1.68-1.04-5.37,1.2-9.74,2.38
				c3.45-4.25,5.77-8.4,9.23-11.08c4.5-3.49,8.29-7.04,9.8-12.7c0.42-1.57,1.28-3.08,2.17-4.47c3.77-5.83,7.45-11.73,11.54-17.32
				c1.42-1.94,3.74-3.67,6-4.43c3.58-1.2,7.44-1.79,11.22-2.11c5.13-0.43,8.57,2.74,11.08,8.84c0.82,1.98,2.55,3.58,3.87,5.36
				c1.78-1.58,4.14-2.84,5.22-4.81c1.84-3.36,2.87-7.17,4.25-10.79C788.65,481.34,789.14,481.41,789.63,481.49z"/>
			<path onClick={() => props.onFill(149)} fill={props.fillColors[149]}  d="M668.69,442.16c-4.85-9.41-5.82-19.32-5.14-29.57c0.18-2.76,0.7-4.27,3.79-4.55
				c2.78-0.25,5.64-0.86,8.21-1.92c5.32-2.19,8.9-0.94,12.01,4c3.78,6,7.85,11.55,10.37,18.51c2.47,6.82,7.76,12.67,12.07,18.75
				c2.57,3.63,1.8,14.45-1.65,17.31c-0.89,0.74-2,1.3-2.7,2.18c-4.47,5.59-9.44,7.47-16.48,4.13c-2.79-1.33-6.74-0.22-11.22-0.22
				c0.88-3.34,1.06-6.79,2.63-9.39c3.73-6.17,1.12-12.35,0.71-18.49c-0.05-0.8-2.55-2.06-3.89-2.02
				C674.34,440.97,671.29,441.74,668.69,442.16z"/>
			<path onClick={() => props.onFill(150)} fill={props.fillColors[150]}  d="M114.76,1012.07c-1.43-9.94-9.85-9.97-16.63-12.03c-6.2-1.88-9.72-11.12-6.58-16.7
				c2.6-4.62,6.01-8.25,12.03-9.06c2.28-0.31,4.36-3.49,6.16-5.67c5.15-6.25,11.65-3.48,17.84-2.98c1.77,0.14,3.82,0.83,5.28,0.21
				c7.8-3.33,14.55-0.62,19.93,4.45c3.82,3.61,5.98,8.99,9.39,14.38c-2.02,0-3.54,0-5.06,0c-10.18-0.02-18.5,4.11-25.25,11.54
				c-0.95,1.04-1.34,2.62-1.9,3.99c-1.6,3.87-2.21,8.36-8.37,7.86C119.64,1007.89,117.48,1010.38,114.76,1012.07z"/>
			<path onClick={() => props.onFill(151)} fill={props.fillColors[151]}  d="M182.31,1045.2c-5.26-1.48-10.03-3.41-14.97-4.08c-7.42-1.01-14.96-1.54-22.44-1.49
				c-5.48,0.03-11.03,2.22-16.42,1.8c-5.54-0.44-11.06-2.55-16.34-4.58c-3.31-1.27-4.31-6.71-2.82-10.59
				c0.83-2.15,1.23-4.48,2.17-6.57c0.61-1.35,1.7-2.58,2.85-3.55c1.74-1.48,3.54-3.29,5.62-3.89c5.57-1.59,10.71-5.14,17.1-3.49
				c3.49,0.9,7.34,1.23,10.91,0.81c4.04-0.48,7.87-1.69,10.77,2.38c0.18,0.25,0.53,0.54,0.81,0.55c10.85,0.31,13.58,10.99,20.4,16.43
				c2.12,1.69,2.01,6.36,2.61,9.72C182.85,1040.36,182.45,1042.2,182.31,1045.2z"/>
			<path onClick={() => props.onFill(152)} fill={props.fillColors[152]}  d="M904.32,322.01c-12.13-1.26-23.22-8.84-36.14-6.21c13.43,10.23,29.49,13.77,45.18,18.2
				c-0.02,0.48-0.04,0.97-0.06,1.45c-1.73,0-3.48,0.2-5.17-0.03c-6.54-0.89-13.1-1.77-19.59-2.99c-2.31-0.43-5.19-1.22-6.5-2.89
				c-3.58-4.56-8.15-5.07-13.33-5.09c-3.4-0.01-6.83-0.69-10.18-1.42c-3.65-0.8-5.74-3-6.87-6.91c-0.73-2.54-3.17-5.12-5.53-6.51
				c-3.25-1.91-5.12-4.13-6.3-7.73c-3.93-12.02-8.41-23.83-18.26-32.71c0.28-0.31,0.55-0.61,0.83-0.92c4.56,1.75,9.08,3.66,13.7,5.23
				c7.98,2.72,16.03,5.24,24.06,7.82c7.89,2.54,15.85,4.9,23.66,7.65c4.23,1.49,7.18,4.27,8.17,9.19c0.66,3.3,2.74,6.32,4.76,10.72
				c-2.4-0.5-4.89-0.25-5.89-1.35c-4.79-5.31-11.25-7.84-17.42-10.55c-4.65-2.04-10.01-2.47-15.06-3.62c-0.37,0.73-0.73,1.47-1.1,2.2
				c1.96,1.6,3.69,4.03,5.93,4.67c8.22,2.36,15.31,5.93,20.14,13.45c0.97,1.51,3.95,1.83,6.08,2.46c5.09,1.5,10.22,2.86,15.33,4.28
				C904.62,320.94,904.47,321.47,904.32,322.01z"/>
			<path onClick={() => props.onFill(153)} fill={props.fillColors[153]}  d="M375.84,504.99c0.18-1.03,0.06-2.44,0.64-3.43c5.54-9.43,11.5-18.63,16.68-28.25
				c2.39-4.44,3.41-9.64,4.8-14.57c0.67-2.38,0.88-4.91,1.1-7.4c1.28-14.05,13.82-22.2,24.15-23.08c1.3-0.11,2.66-0.37,3.85-0.9
				c3.85-1.69,6.68-0.78,9.72,2.24c2.74,2.72,6.45,4.46,10.29,7c-2.41,1.25-4.55,2.09-6.38,3.37c-1.84,1.29-3.67,2.8-4.96,4.61
				c-2.28,3.21-1.35,5.11,2.78,5.52c4.87,0.48,5.69,1.86,4.29,6.42c-0.73,2.38-1.01,5.22-2.52,6.95c-1.47,1.68-4.16,2.78-6.48,3.17
				c-12.66,2.09-25.26,3.3-34.43,14.57C392.44,489.71,383.91,496.94,375.84,504.99z"/>
			<path onClick={() => props.onFill(154)} fill={props.fillColors[154]}  d="M553.74,957.6c1.11-0.09,2.23-0.11,3.32-0.29c4.45-0.74,6.42-3.84,4.52-7.89
				c-1.47-3.13-3.63-5.94-5.55-8.85c-5.06-7.7-2.61-14.63,6.54-15.56c7.99-0.82,16.29-0.39,24.27,0.77
				c12.26,1.78,24.37,4.65,36.88,7.12c-5.93,12.66-12.08,25.37-6.07,38.95c-21.27-4.56-42.57-9.13-63.87-13.69
				C553.77,957.97,553.75,957.78,553.74,957.6z M611.41,953.11c0.54-0.33,2.57-0.86,3.01-2.01c0.55-1.45,0.47-4.26-0.48-5
				c-6.19-4.82-12.61-9.37-19.2-13.63c-1-0.65-3.72,0.14-4.78,1.17c-0.86,0.84-0.89,3.17-0.46,4.58
				C591.59,944.98,603.33,953.23,611.41,953.11z M588.77,938.66c-0.46-3.33-2.99-4.76-6.51-4.66c-4.55,0.12-9.04,4.5-8.58,8.56
				c0.37,3.23,2.21,5.58,5.8,5.57C583.26,948.12,588.91,942.57,588.77,938.66z M563.73,935.73c-1.74-1.21-2.89-2.62-3.86-2.51
				c-1.03,0.11-2.82,1.98-2.63,2.55c0.39,1.23,1.7,2.64,2.91,2.96C560.85,938.93,562.08,937.18,563.73,935.73z"/>
			<path onClick={() => props.onFill(155)} fill={props.fillColors[155]}  d="M943.91,421.06c-1.4,11.8-4.31,14.38-15.23,14.71c-4.62,0.14-9.31,0.43-13.78,1.46
				c-1.74,0.4-2.98,3.03-4.44,4.64c1.74,0.93,3.39,2.23,5.25,2.71c3.03,0.78,6.19,1.01,9.14,2.67c-7.35,0.7-14.68,1.57-22.04,2.01
				c-3.32,0.19-4.72-3.91-2.63-7.13c1.99-3.06,4.26-5.96,6.01-9.16c0.68-1.24,0.24-3.09,0.31-4.67c-1.67,0.2-3.69-0.14-4.95,0.69
				c-5.5,3.63-10.71,7.68-16.17,11.37c-1.55,1.05-3.6,1.37-6.02,2.25c0.9-3.34,1.61-5.98,2.54-9.42c-2.3,0.41-3.69,0.24-4.43,0.86
				c-5.58,4.69-12.89,5.02-19.1,7.84c-10.79,4.92-20.83,11.42-32.39,14.81c-2.4,0.7-4.06,3.76-6.2,5.56
				c-2.25,1.89-4.68,3.58-7.03,5.36c-0.57-0.49-1.14-0.98-1.7-1.47c3.21-6.06,6.04-12.38,9.82-18.06c1.59-2.4,5.01-3.91,7.91-5.05
				c9.11-3.58,18.3-6.97,27.57-10.08c6.88-2.31,13.93-4.13,20.93-6.08c8.72-2.44,17.33-5.66,26.22-6.98
				C916.8,417.92,930.26,418.58,943.91,421.06z"/>
			<path onClick={() => props.onFill(156)} fill={props.fillColors[156]}  d="M216.02,1021.39c-5.65-0.92-10.71-1.91-15.82-2.53c-7.76-0.93-14.13-6.97-13.96-14.85
				c0.15-6.79-4.43-9.88-8.79-12.82c-1.81-1.22-5.38,0.18-8.03,0.38c-3.52-5.49-7.33-11.42-11.13-17.34
				c-2.16-3.38-1.52-5.01,2.41-5.04c8.96-0.08,18.07-1.74,26.63,2.91c2.07,1.13,5.03,1.02,7.54,0.87c14-0.82,19.55,7,20.91,19.02
				C216.92,1001.94,216.02,1012.11,216.02,1021.39z"/>
			<path onClick={() => props.onFill(157)} fill={props.fillColors[157]}  d="M535.22,370.02c-2.44-6.01-4.95-4.23-7.44-2.12c-6,5.07-11.99,10.17-17.98,15.25
				c-0.35-0.24-0.71-0.48-1.06-0.72c0.57-1.98,1.27-3.93,1.7-5.95c1.19-5.67,2.19-11.38,3.4-17.04c2-9.36,3.99-18.72,6.31-28
				c0.42-1.67,2.13-3.69,3.72-4.27c5.4-1.99,11-3.4,16.41-5c0.41,0.62,0.7,0.87,0.75,1.16c0.3,1.78,0.55,3.57,0.82,5.36
				c2.45-1.7,5.52-2.93,7.24-5.2c3.55-4.69,7.73-5.81,12.5-2.65c1.55,1.03,3.52,1.43,5.94,2.37c-2.17,4-4.56,8.28-6.83,12.63
				c-3.21,6.18-5.6,12.94-9.68,18.46C546.82,359.94,541.02,364.37,535.22,370.02z"/>
			<path onClick={() => props.onFill(158)} fill={props.fillColors[158]}  d="M185.86,1096.93c16.88,1.09,32.76,5.53,48.61,10.41c9.92,3.05,20.3,4.57,30.42,6.96
				c9.17,2.16,18.27,4.65,27.43,6.86c11.13,2.69,22.32,5.14,33.44,7.86c18.54,4.53,37.06,9.2,55.59,13.79
				c17.22,4.27,34.41,8.7,51.69,12.72c21.8,5.07,43.73,9.56,65.5,14.74c16.45,3.92,32.71,8.61,49.1,12.8
				c8.51,2.18,17.11,4.03,25.66,6.06c10.97,2.6,21.93,5.22,32.89,7.83c-0.04,0.44-0.07,0.87-0.11,1.31
				c-8.06-0.43-16.44,0.4-24.14-1.49c-67.26-16.53-134.4-33.52-201.56-50.44c-62.65-15.78-125.28-31.64-187.92-47.48
				C190.25,1098.29,188.06,1097.58,185.86,1096.93z"/>
			<path onClick={() => props.onFill(159)} fill={props.fillColors[159]}  d="M648.87,275.36c2.75,9.06-2.11,17.1-10.23,19.62c-5.71,1.77-11.72,3.43-15.96,8.53
				c-1.22,1.46-4.08,2.16-6.18,2.15c-5.71-0.02-11.23-0.01-16.3,3.35c-1.25,0.83-3.49,0.03-5.22,0.28c-2.91,0.42-5.79,1.04-8.66,1.7
				c-0.78,0.18-1.45,1.09-2.19,1.12c-6.09,0.28-11.42,2.03-15.75,6.67c-0.76,0.81-3.37,0.82-4.53,0.16
				c-8.14-4.66-12.68-3.72-17.57,4.01c-0.27,0.42-0.56,0.83-1.44,2.13c-0.53-6.83-3.95-6.41-8.09-4.88
				c-3.06,1.13-6.28,1.85-10.32,3.02c4.16-8.02,9.71-12.93,17.47-15.29c1.43-0.43,3.25-0.28,4.3-1.12c8.31-6.7,18.23-3.8,27.45-5.26
				c5.95-0.94,11.42-4.93,17.12-7.52c7.66-3.48,15.39-5.9,24-3.37c1.41,0.41,3.91-1.08,5.19-2.4
				C629.42,280.57,638.69,277.11,648.87,275.36z"/>
			<path onClick={() => props.onFill(160)} fill={props.fillColors[160]}  d="M796.91,236.31c1.78,4.14,3.22,7.52,4.93,11.5c-1.72,0-3.45-0.1-5.16,0.02
				c-13.86,0.9-27.7,2.19-41.57,2.66c-8.59,0.29-17.23-0.59-25.84-1.03c-3.28-0.17-6.55-0.7-9.83-0.87
				c-5.96-0.3-11.93-0.47-17.89-0.66c-2.66-0.09-5.55-0.8-7.94-0.01c-9.64,3.19-20.13,0.19-29.38,3.58
				c-6.91,2.54-15.54,3.41-19.61,12.86c-0.44-3.16-0.71-5.08-0.98-6.99c-0.52-3.75,0.72-6.29,4.82-6.43c4.76-0.16,7.22-2.56,9.14-6.9
				c0.84-1.9,4.39-3.29,6.88-3.63c9.7-1.3,19.46-2.21,29.21-2.98c3.61-0.28,7.28,0.4,10.91,0.36c7.12-0.08,14.24-0.38,21.37-0.55
				c14.23-0.33,28.45-0.69,42.68-0.9C778.26,236.2,787.85,236.31,796.91,236.31z"/>
			<path onClick={() => props.onFill(161)} fill={props.fillColors[161]}  d="M243.61,1061.38c1.79-2.53,4.46-4.76,4.07-6.09c-0.59-2.02-3.06-3.9-5.17-5
				c-5.19-2.7-10.64-4.92-16.64-7.63c0.41-0.79,1.27-2.62,2.3-4.35c0.5-0.84,1.67-1.41,1.89-2.26c1.19-4.68,4.19-7.67,7.93-10.59
				c4.19-3.26,7.8-7.36,11.25-11.44c2.7-3.18,5.89-3.87,9.72-3.79c7.78,0.15,14.98,4.73,17.48,11.86c0.56,1.6-0.39,3.75-0.68,5.64
				c-0.29,1.84-1.45,4.05-0.8,5.43c2.87,6.04,0.82,11.5-1.09,17.13c-0.91,2.68-2.04,4.17-5.18,4.98c-3.72,0.97-6.86,4.05-10.56,5.3
				C253.49,1062.15,248.55,1063.9,243.61,1061.38z"/>
			<path onClick={() => props.onFill(162)} fill={props.fillColors[162]}  d="M668.6,688.72c-1.4-0.87-2.85-2.47-4.19-2.38c-1.01,0.07-2.15,2.18-2.73,3.59
				c-0.95,2.34-1.46,4.36-4.86,2.66c-0.94-0.47-3.66,0.89-4.43,2.12c-1.55,2.46-2.17,5.49-3.49,8.13c-0.53,1.07-2.05,2.6-2.81,2.44
				c-6.86-1.44-6.81,4.26-7.94,7.98c-1.5,4.91-4,8.4-8.38,11.22c-1.89,1.21-3.17,4.78-3.15,7.27c0.03,5.19-2.21,6.6-6.89,6.3
				c-2.4-0.16-4.85,0.41-7.65,0.68c-0.93-8.09,3.47-14.07,5.8-20.6c6.43-0.03,7.91-1.39,8.43-7.5c0.67-7.96,3.84-14.79,8.86-21
				c1.66-2.06,2.11-5.11,3.35-8.32c4.57,0.19,7.33-2.36,9.09-7.46c0.9-2.62,4.47-4.81,7.33-6.12c2.72-1.24,6.05-2.59,9.03,0.77
				c0.77,0.86,3.79,0.35,5.43-0.34c3.6-1.52,6.85-2.78,10.88-0.83c1.36,0.66,4.02-0.53,5.67-1.54c2.65-1.63,4.72-2.74,8.12-1.16
				c1.94,0.9,4.99-0.56,8.66-1.14c-0.26-0.21,0.63,0.5,1.61,1.28c-2.9,1.65-5.44,3.9-8.33,4.52c-4.09,0.88-6.97,2.24-8.31,6.51
				c-0.56,1.78-2.31,3.18-3.58,4.83c-4.41-4.33-6.17-3.57-8.14,1.2c-1.3,3.14-4.07,5.68-6.18,8.48
				C669.4,689.79,669,689.25,668.6,688.72z"/>
			<path onClick={() => props.onFill(163)} fill={props.fillColors[163]}  d="M475.12,649.59c-4.44,1.4-8.85,2.89-13.33,4.14c-1.17,0.33-2.6,0.08-3.83-0.23
				c-3.4-0.83-6.53-3.68-10.36-1.12c-0.3,0.2-1.27-0.8-1.99-0.99c-2.13-0.55-4.28-0.97-6.62-0.76c-2.13-2.79-4.67-4.11-8.76-2.91
				c-2.01,0.59-4.53-0.38-6.79-0.82c-2.97-0.58-5.86-2.46-8.57,1.12c-0.58,0.76-4.11,0.3-5.45-0.71c-6.49-4.86-14.17-1.78-21.06-3.6
				c-1.4-0.37-3.15,0.12-4.35-0.51c-5.09-2.69-10.13-2.61-15.78-1.86c-4.1,0.55-8.54-2.01-12.9-2.67c-2.69-0.41-5.69-0.4-8.25,0.39
				c-3.22,0.99-6.14,2.56-9.29,0.05c-0.22-0.18-0.83-0.26-0.94-0.13c-3.48,4.15-7.92-0.37-11.72,1.28c-0.88,0.39-2.19-0.02-3.25-0.27
				c-0.95-0.23-2.04-1.3-2.7-1.05c-8.51,3.32-16.71-3.82-25.45-0.43c-4.02,1.56-8.94,0.95-13.45,0.99c-1.29,0.01-3.01-0.83-3.75-1.85
				c-0.51-0.71,0.08-2.5,0.6-3.62c0.53-1.17,1.56-2.11,2.36-3.13c6.19,4.15,6.95,3.89,9.86-3.22c6.97,2.94,14.39,0.84,17.71-5.69
				c3.12,2.9,6.12,5.6,9.01,8.41c5.35,5.22,14.19,6.37,20.8,2.85c4.65-2.47,9.35-4.91,14.25-6.8c2.55-0.98,5.32-1.94,8.35,0.59
				c1.52,1.27,4.97,0.22,8.54,0.22c6.16,3.08,13.09,8.08,20.78,9.96c7.86,1.93,16.29,4.12,24.79,0.82
				c5.63-2.19,11.39-1.35,16.64,2.16c1.08,0.72,2.81,0.57,4.25,0.64c1.12,0.06,2.33-0.43,3.38-0.19c10.37,2.43,20.72,4.91,31.04,7.52
				c2.18,0.55,4.22,1.67,6.33,2.53C475.17,650.34,475.14,649.97,475.12,649.59z"/>
			<path onClick={() => props.onFill(164)} fill={props.fillColors[164]}  d="M378.41,508.3c3.56-4.51,6.55-8.85,10.09-12.65c5.54-5.94,11.46-11.53,17.24-17.25
				c0.94-0.93,1.88-2.17,3.04-2.51c8.04-2.3,16.05-5.02,24.26-6.35c5.78-0.94,10.78-1.98,14.91-6.6c4.83-5.39,10.31-10.2,15.51-15.26
				c0.55,0.34,1.1,0.69,1.65,1.03c-2.85,5.42-5.06,11.33-8.74,16.1c-3.11,4.03-7.98,6.68-11.97,10.07c-1.88,1.6-3.58,3.44-5.27,5.25
				c-8.13,8.76-15.82,17.98-24.49,26.16c-4.71,4.43-11.07,7.11-16.72,10.53c-0.68,0.41-1.78,0.91-2.31,0.63
				C390.16,514.66,384.78,511.72,378.41,508.3z"/>
			<path onClick={() => props.onFill(165)} fill={props.fillColors[165]}  d="M731.8,285.25c8.3-8.44,3.69-16.84,1.51-25.64c3.72,0,6.79,0.11,9.84-0.02c6.9-0.29,13.79-0.81,20.69-1.04
				c11.76-0.39,23.52-0.83,35.29-0.83c3.99,0,6.39,5.08,5.59,10.36c-0.61,4-3.26,5.55-7.95,5.02c-2.47-0.28-5-0.05-7.5-0.05
				c-0.06,0.46-0.13,0.91-0.19,1.37c3.05,0.75,6.09,1.49,9.14,2.24c0.18,0.44,0.35,0.89,0.53,1.33c-1.52,0.49-3.05,1.43-4.56,1.4
				c-5.64-0.12-11.27-0.6-16.91-0.85c-1.23-0.05-2.48,0.32-3.72,0.49c-0.06,0.41-0.12,0.83-0.18,1.24c1.38,0.31,2.75,0.79,4.15,0.9
				c5.29,0.42,10.59,0.62,15.87,1.12c1.24,0.12,2.4,1.05,3.12,2.34c-5.52,0-11.05-0.22-16.55,0.07c-3.73,0.2-7.42,1.11-11.13,1.69
				c-0.96,0.15-2.03,0.57-2.87,0.3c-9.61-3.14-19.21-2.38-28.89-0.4C735.76,286.57,734.23,285.77,731.8,285.25z"/>
			<path onClick={() => props.onFill(166)} fill={props.fillColors[166]}  d="M786.4,518.9c1.88-8.24,3.75-15.38,9.68-20.64c4.75-4.21,7.96-9.37,9.06-15.67
				c1.52-8.76,6.61-13.49,15.34-15.2c8.71-1.71,17.25-4.25,27.09-6.74c-1.68,4.19-2.8,7.59-4.37,10.77
				c-5.67,11.46-11.25,22.99-17.43,34.17c-1.59,2.88-5.28,4.58-7.94,6.89c-2.83,2.46-5.63,2.4-9.43,2.02
				c-5.37-0.54-10.99,1.29-16.47,2.28C790.25,517.08,788.69,518,786.4,518.9z"/>
			<path onClick={() => props.onFill(167)} fill={props.fillColors[167]}  d="M826.34,440.52c-0.96-9.66-1.17-10.22,8.04-13.67c16.87-6.33,34.06-11.81,51.05-17.85
				c18.02-6.41,36.6-7.83,55.51-6.82c5.73,0.31,10.86,5.75,9.34,11.18c-0.54,1.94-4.34,4.21-6.64,4.2
				c-8.27-0.04-16.54-1.6-24.79-1.43c-8.91,0.19-17.86,1.25-26.65,2.76c-5.29,0.91-10.3,3.44-15.46,5.16
				c-16.17,5.38-32.36,10.71-48.54,16.06C827.61,440.32,826.98,440.39,826.34,440.52z"/>
			<path onClick={() => props.onFill(168)} fill={props.fillColors[168]}  d="M561.51,339.99c3.94-7.06,7.82-14.16,11.88-21.16c0.69-1.19,2.06-2.57,3.3-2.78
				c7.16-1.23,14.39-2.12,21.58-3.17c0.97-0.14,2.03-0.39,2.8-0.93c4.91-3.49,10.21-3.86,16.11-3.37c2.69,0.22,5.93-2.14,8.38-4.04
				c4.91-3.82,10.3-6.1,16.65-7.54c-1.25,8.75-2.42,16.88-3.63,25.34c-5.26,0-10.55-0.4-15.75,0.1c-6.74,0.64-11.79,4.3-14.6,10.6
				c-1.64,3.69-3.74,5.15-8.15,4.17c-3.56-0.79-7.5,0.06-11.27,0.34c-4.27,0.31-8.53,0.8-12.79,1.19
				C571.32,339.16,566.61,339.55,561.51,339.99z"/>
			<path onClick={() => props.onFill(169)} fill={props.fillColors[169]}  d="M315.82,276.3c-0.8,1.74-1.7,3.29-2.21,4.97c-0.34,1.13-0.11,2.44-0.13,3.67
				c1.37-0.18,2.76-0.29,4.11-0.57c0.42-0.09,0.76-0.62,1.13-0.95c4.69-4.1,9.1-8.62,14.2-12.13c2.45-1.68,6.19-1.7,9.39-2
				c3.12-0.3,5.51-0.84,5.93-4.61c0.11-0.96,1.36-2.43,2.25-2.57c3.89-0.6,7.84-0.8,13.56-1.31c-1.81,2.82-2.74,4.48-3.89,5.97
				c-0.58,0.75-2.02,1.07-2.24,1.83c-0.73,2.53-2.39,6.2-1.32,7.55c2.3,2.91,4.48-0.41,6.51-1.9c1.61-1.19,3.31-2.26,5.57-3.8
				c-2.01,15.48-4.35,18.97-11.22,18.65c-1.04-2.25-1.62-5.62-2.94-5.93c-3.71-0.89-6.98,0.56-9.14,4.33
				c-0.87,1.51-3.08,2.34-4.82,3.22c-1.83,0.92-3.77,1.76-5.75,2.22c-3.29,0.76-6.65,1.19-10.34,1.82c-0.14-0.9-0.16-1.67-0.39-2.37
				c-0.44-1.3-0.9-3.55-1.55-3.63c-1.41-0.16-3.71,0.35-4.31,1.38c-2.85,4.81-6.74,5.78-12.1,5.65c-2.72-0.07-5.69,2.88-8.24,4.86
				c-1.92,1.49-3.34,3.61-5.65,5.11c1.52-4.02,3.03-8.04,4.55-12.05c-0.69-0.52-1.38-1.04-2.06-1.56c-2.3,1.94-4.57,3.92-6.91,5.81
				c-2.31,1.87-4.55,3.88-7.1,5.33c-0.89,0.51-3.61-0.21-3.77-0.81c-0.4-1.56-0.48-4.21,0.45-4.94c5.13-4,7.51-9.59,9.93-15.27
				c0.41-0.95,1.45-1.83,2.41-2.32C296.67,276.46,307.11,274.98,315.82,276.3z"/>
			<path onClick={() => props.onFill(170)} fill={props.fillColors[170]}  d="M683.61,940.85c-3.55-0.45-5.83-0.89-8.12-1c-13.25-0.64-22.37,7.25-21.99,20.43
				c0.28,9.7-2.87,17.06-10,23.22c-1.89,1.63-3.17,2.09-5.58,0.77c-8.74-4.83-11.23-11.96-7.59-24.61
				c1.55-5.41,3.86-10.61,5.88-15.88c1.88-4.91,3.44-9.81-0.5-15.45c12.73-1.88,24.1-7.96,36.67-3.38c2.11,0.77,4.66,1.09,3.68,4.54
				c-0.18,0.64,0.88,1.67,1.43,2.47C679.33,934.66,681.2,937.35,683.61,940.85z"/>
			<path onClick={() => props.onFill(171)} fill={props.fillColors[171]}  d="M662.7,439.61c-6.73,0.37-14.18,0.7-21.61,1.2c-6.67,0.45-13.33,1.04-19.99,1.68
				c-5.2,0.5-10.44,0.87-15.54,1.91c-2.4,0.49-4.38,2.8-6.78,3.6c-3.52,1.17-7.24,1.71-10.95,2.54c-1.04-7.34,1.22-11.92,7.78-14.82
				c3.64-1.61,6.53-4.9,9.77-7.41c0.76-0.59,1.49-1.46,2.34-1.62c12.27-2.34,23.58-7.09,34.64-12.82c4.46-2.31,9.66-3.26,14.6-4.53
				c2.81-0.72,4.49,0.54,4.1,3.74c-0.08,0.66-0.08,1.33-0.04,1.99C661.55,423.01,662.1,430.95,662.7,439.61z"/>
			<path onClick={() => props.onFill(172)} fill={props.fillColors[172]}  d="M742.3,650.81c-3.93,2.17-7.87,4.34-11.94,6.58c-5.08-1.01-10.55-2.06-15.99-3.23
				c-1.2-0.26-2.37-0.86-3.44-1.51c-2.86-1.74-5.39-3.34-9.11-1.31c-1.78,0.97-4.65-0.24-7.03-0.21c-10.24,0.14-20.1-2.01-30.11-3.85
				c-6.78-1.24-14.11,0.45-21.19,0.86c-0.64,0.04-1.47,0.6-1.89,0.36c-7.38-4.19-15.96-0.9-23.47-3.85
				c-2.34-0.92-4.22-3.38-6.56-3.85c-6.16-1.22-12.46-1.7-18.69-2.57c-1.09-0.15-2.58-0.46-3.08-1.23
				c-2.52-3.91-6.25-3.75-10.14-3.59c-1.42,0.06-2.86-0.46-4.28-0.76c-4.35-0.92-8.66-2.29-13.06-2.68
				c-5.98-0.53-12.04-0.25-18.05-0.49c-1.23-0.05-2.43-0.89-3.65-1.36c0.04-0.48,0.08-0.95,0.11-1.43
				c6.25,0.29,12.97,2.16,18.65,0.49c8.75-2.56,16.14,2.71,24.4,1.95c4.38-0.4,8.93,1.49,13.44,2.04c6.88,0.83,13.8,1.4,20.7,2.07
				c0.15,0.01,0.33-0.17,0.46-0.14c7.07,1.68,14.13,3.39,21.2,5.05c8.15,1.92,16.31,3.8,24.48,5.67c0.46,0.11,1.1,0.13,1.47-0.11
				c5.88-3.72,12.24-0.7,18.33-1.34c0.78-0.08,1.6,0.24,2.4,0.32c3.78,0.36,7.57,0.99,11.35,1c8.22,0.03,16.44-0.44,24.65-0.3
				c3.51,0.06,7.08,0.84,10.49,1.78c3.17,0.88,6.17,2.41,9.24,3.65C742.1,649.5,742.2,650.15,742.3,650.81z"/>
			<path onClick={() => props.onFill(173)} fill={props.fillColors[173]}  d="M103.39,1058.08c5.12-3.66,9.84-5.42,15.45-4.97c6.65,0.53,7.84-1.3,5.19-7.48
				c22.94-6.89,44.97-2.71,67.57,5.65c-4.11,1.21-7.63,2.21-11.13,3.28c-6.82,2.09-13.71,3.98-20.36,6.51
				c-1.48,0.56-2.6,3.33-2.9,5.23c-0.15,0.93,1.96,2.22,2.87,3.16c-8.15,0.71-17.17,1.53-26.2,2.23c-1.27,0.1-2.72-0.1-3.85-0.64
				C121.37,1066.92,112.77,1062.66,103.39,1058.08z"/>
			<path onClick={() => props.onFill(174)} fill={props.fillColors[174]}  d="M251.11,395.03c-1.57-3.98-4.28-3.63-7.18-1.74c-1.52,0.99-2.83,2.31-4.15,3.57
				c-5.42,5.17-11.04,9.8-18.28,12.58c-6.89,2.64-13.13,6.92-19.91,9.91c-5.64,2.49-8.59,1.46-13.65-3.39
				c1.07-0.56,1.98-1.21,2.99-1.52c7.07-2.19,12.24-6.35,14.96-13.48c2.14-5.61,6.46-8.9,11.92-11.31c3.49-1.54,6.48-4.3,9.54-6.72
				c12.5-9.88,27-17.7,43.13-11.52c-4.36,9.66-12.94,15.77-19.42,23.56L251.11,395.03z"/>
			<path onClick={() => props.onFill(175)} fill={props.fillColors[175]}  d="M650.92,393.3c-8.44,1.34-14.37,5.5-19.17,12.77c-2.39,3.63-8.79,4.61-14.79,7.46
				c1.36-3.29,2.24-5.58,3.24-7.81c1.74-3.85,3.12-7.45,0.95-11.86c-2.42-4.92-5.26-7.52-10.9-5.23c-1.18,0.48-2.63,0.34-3.96,0.35
				c-8.23,0.03-16.45,0.01-24.99,0.01c1.6-3.66,3.12-8.02,5.4-11.94c0.86-1.48,3.42-2.06,5.3-2.83c3.44-1.4,6.94-2.63,10.42-3.92
				c0.92-0.34,1.98-1.16,2.73-0.94c5.78,1.68,11.22-0.59,16.8-1.19c6.59-0.71,13.2-1.24,19.81-1.61c4.64-0.26,9.53-0.94,12.74,2.91
				c-3.08,3.45-5.5,6.7-8.49,9.26c-1.45,1.24-4.02,1.1-6.03,1.75c-3.58,1.16-7.22,2.22-10.61,3.83c-1.16,0.55-2.46,2.81-2.17,3.89
				c0.29,1.12,2.36,2.36,3.76,2.5c4.29,0.44,8.63,0.32,12.94,0.54c2.28,0.11,4.55,0.46,6.83,0.7
				C650.79,392.4,650.86,392.85,650.92,393.3z"/>
			<path onClick={() => props.onFill(176)} fill={props.fillColors[176]}  d="M295.99,943.09c-31.42,1.82-61.56,3.56-93.31,5.39c5.7-6.79,10.56-12.68,15.57-18.45
				c0.52-0.61,1.84-0.68,2.8-0.73c19.42-1.04,38.83-2.15,58.27-2.89c2.18-0.08,5.02,1.45,6.54,3.15
				C289.26,933.36,292.04,937.73,295.99,943.09z"/>
			<path onClick={() => props.onFill(177)} fill={props.fillColors[177]}  d="M101.5,258.65c6.63-1.3,13.32-2.39,19.87-4.01c2.8-0.69,5.52-2.23,7.92-3.9
				c3.67-2.55,7.39-3.91,11.99-4.01c5.34-0.11,11.16-0.07,14.38-5.99c0.41-0.76,1.93-0.99,2.99-1.31c2.64-0.79,5.31-2.12,7.97-2.13
				c9.58-0.03,18.49-5.92,28.99-0.68c5.82,2.91,12.39,7.02,20.38,5.78c4.42-0.69,9.34,1.74,14.02,2.79c0.46,0.1,1.23,0.36,1.27,0.64
				c1.3,7.98,10.2,9.11,13.95,16.1c-2.89,0.54-5.69,1.67-8.38,1.45c-11.72-0.98-23.4-2.34-35.1-3.61c-1.45-0.16-2.86-0.61-5-1.07
				c1-0.96,1.49-1.48,2.03-1.93c3.33-2.82,3.39-5.2-0.57-6.61c-13.76-4.88-27.31-7.66-42.09-1.96c-17.38,6.7-35.73,10.4-54.32,12.2
				C101.71,259.82,101.6,259.23,101.5,258.65z"/>
			<path onClick={() => props.onFill(178)} fill={props.fillColors[178]}  d="M787.18,427.34c2.17,0.24,5,0.48,7.82,0.88c1.8,0.25,3.59,1.06,5.36,1c2.15-0.07,4.28-0.8,6.42-1.24
				c-2.22-2.19-4.57-6.29-6.63-6.15c-3.54,0.24-5.6-1.12-7.99-2.84c-3.25-2.34-6.49-4.32-8.28,1.76c-2.09-1.21-3.73-2.35-5.52-3.17
				c-2.65-1.21-5.57-4.21-7.75,0.77c-0.2,0.46-1.76,0.38-2.7,0.44c-4.73,0.28-9.47,0.53-14.83,0.83c0.22-3.44-2.62-8.29,1.8-12.87
				c1.16-1.2,0.96-3.71,1.4-5.7c0.81,0.4,2.13,1.06,2.66,1.32c0,2.4-0.5,5.67,0.08,5.87c4.09,1.44,8.38,2.41,12.69,3.01
				c0.44,0.06,1.35-3.35,2.27-5.74c5.86-0.92,12.13-0.82,17.93,3.85c3.02,2.44,6.83,4.15,10.55,5.42c4.99,1.7,7.49-0.56,7.76-5.82
				c0.33-6.26,1.13-12.5,1.35-18.76c0.12-3.41-0.54-6.84-0.91-10.91c4.3,3.28,6.03,7.49,5.66,12.38c-0.27,3.43-1.96,6.78-2.09,10.2
				c-0.14,3.82-0.01,8,1.42,11.44c2.09,5.06,5.85,4.99,8.94,0.49c1.02-1.49,1.99-3.01,3.86-4.21c-1.77,7.29-3.64,14.55-5.26,21.88
				c-0.46,2.07-0.07,4.31-0.26,6.46c-0.33,3.75-1.69,6.27-6.25,5.83c-2.23-0.21-4.7,0.23-6.8,1.04c-3.89,1.5-6.17,0.55-8.81-2.82
				C796.94,436.74,791.92,432.25,787.18,427.34z"/>
			<path onClick={() => props.onFill(179)} fill={props.fillColors[179]}  d="M2.8,960.08c10.58,0.71,20.09,1.34,29.6,1.99c4.96,0.34,9.98,0.35,14.85,1.21
				c2.19,0.39,4.29,2.25,6.08,3.85c7.44,6.65,8.93,13.44,4.6,21.43c-0.85,1.57-2.66,3.34-4.29,3.65c-5.18,0.98-10.48,1.35-15.74,1.89
				c-0.97,0.1-2.16-0.47-2.93-0.08c-5.03,2.51-7.88,0.41-9.39-4.36c-2.63-8.32-9.85-11.88-16.83-14.57
				c-5.23-2.01-6.53-4.58-5.98-9.43C2.99,963.74,2.8,961.78,2.8,960.08z"/>
			<path onClick={() => props.onFill(180)} fill={props.fillColors[180]}  d="M254.19,296.71c1.07-6.24,1.98-12.21,3.24-18.11c0.2-0.95,1.97-2.13,3.08-2.2
				c3.64-0.25,7.33,0.2,10.96-0.12c1.73-0.15,3.36-1.43,5.03-2.2c-0.92-1.62-1.47-3.74-2.83-4.78c-3.79-2.89-7.92-5.34-12.19-8.13
				c3.62-11.14,9.76-22.17,5.1-35.36c2.03-0.55,4.01-1.15,6.03-1.62c3.65-0.85,5.85,0.51,6.09,4.39c0.27,4.48,2.44,7.51,6.03,10.28
				c8.3,6.4,8.91,12.24,3.86,21.28c-2.82,5.05-4.4,10.79-7.19,15.85c-3.41,6.19-7.26,12.18-11.43,17.89
				c-1.18,1.61-4.05,2.42-6.27,2.71C260.33,297.03,256.85,296.71,254.19,296.71z"/>
			<path onClick={() => props.onFill(181)} fill={props.fillColors[181]}  d="M735.2,311.28c-6.81,0-12.63,0-18.46,0c0.06-0.19,0.11-0.38,0.17-0.57c-4.25-0.6-8.5-1.68-12.75-1.71
				c-7.52-0.06-7.58,0.21-7.61-7.08c-0.01-3.15,0.18-6.31,0.49-9.45c0.35-3.57,1.43-6.4,5.99-6.38c1.16,0.01,2.37-1.88,3.47-2.98
				c0.31-0.31,0.32-0.93,0.46-1.41c4.17-13.85,7.44-15.97,22.22-14.41c3.71,0.39,5.85,4.42,4.6,9.27c-0.44,1.7-1.42,3.41-2.57,4.75
				c-2.26,2.63-3.78,4.89,0.55,6.94c0.62,0.3,1.25,1.24,1.33,1.95C733.86,297.25,734.52,304.31,735.2,311.28z"/>
			<path onClick={() => props.onFill(182)} fill={props.fillColors[182]}  d="M871.5,388.37c1.94-5.45,3.56-10.59,5.61-15.56c1.99-4.83,5.96-7.38,11.07-8.23
				c4.24-0.7,8.47-1.46,12.69-2.23c0.8-0.15,1.84-0.23,2.29-0.75c6.86-7.9,17.62-5.62,25.89-9.89c0.7-0.36,3.44,1.29,3.68,2.36
				c0.79,3.43,2.48,4.98,5.97,5.58c6.55,1.13,9.86,8.81,6.37,14.72c-0.58,0.98-2.79,1.39-4.24,1.37c-8.33-0.11-16.66-0.75-24.99-0.59
				c-9.22,0.17-17.95,2.55-25.99,7.37c-1.79,1.07-4.38,0.68-6.47,1.37C879.51,385.16,875.75,386.74,871.5,388.37z"/>
			<path onClick={() => props.onFill(183)} fill={props.fillColors[183]}  d="M193.45,1094.21c15.72-6.29,30.99-12.09,47.27-14.9c9.64-1.67,19.2-3.84,28.84-5.55
				c5.34-0.94,10.89-0.87,16.11-2.18c7.45-1.87,14.65-4.69,22.02-6.89c3.61-1.08,7.35-1.7,11.83-2.71c-0.85,4.95-4.77,5.59-7.5,6.8
				c-2.63,1.17-5.9,0.79-8.81,1.44c-5.49,1.23-10.97,2.54-16.35,4.17c-1.76,0.53-4.22,1.87-4.59,3.3c-1.24,4.72-4.77,5.82-8.63,7.14
				c-6.12,2.1-12.05,4.79-18.2,6.78c-3.74,1.21-7.93,1.08-11.59,2.43c-4.52,1.66-8.69,4.28-13.04,6.41
				c-1.51,0.74-3.57,2.24-4.63,1.75c-9.83-4.54-20.32-5.88-30.87-7.09C194.87,1095.06,194.47,1094.72,193.45,1094.21z"/>
			<path onClick={() => props.onFill(184)} fill={props.fillColors[184]}  d="M850.95,897.66c3.66,0.54,7.16,2.19,9.64,1.22c12.38-4.88,24.6-2.08,36.88-0.18
				c6.39,0.99,12.74,2.28,19.13,3.3c1.6,0.25,3.62,0.54,4.88-0.18c3.46-1.97,5.56-0.32,7.77,1.98c3.52,3.66,2.84,4.33-0.79,7.73
				c-10.73,10.08-23.52,8.87-36.13,6.86c-9.42-1.5-18.56-4.76-27.81-7.28c-6.05-1.65-9.25-6.75-13.04-11.08
				C850.88,899.34,851.02,897.99,850.95,897.66z"/>
			<path onClick={() => props.onFill(185)} fill={props.fillColors[185]}  d="M465.67,493.99c-1.48,3.02-2.97,6.04-5.02,10.21c2.99-0.56,4.63-0.87,6.65-1.26
				c-0.1,1.02,0.11,2.1-0.32,2.81c-4.33,7.17-7.35,15.25-15.3,19.78c-3.24,1.85-5.56,5.26-8.65,7.46c-1.22,0.87-4.06,1.19-5.03,0.39
				c-7.49-6.18-15.75-6.23-25.23-3.63c-0.38-8.98-8.17-7.01-14.07-8.93c20.61-10,33-28.21,48.33-43.68
				c-6.85,5.57,0.53,15.58-7.08,20.73c-0.84,7.48-4.54,13.72-8.53,19.83c7.13,7.16,6.04,7.09,11.93,1.04
				c7.74-7.95,14.92-16.45,22.34-24.72L465.67,493.99z"/>
			<path onClick={() => props.onFill(186)} fill={props.fillColors[186]}  d="M354.66,1061.84c-1.82-2.7-3.64-5.41-5.59-8.29c2.71-1.41,5.89-2.78,8.75-4.64
				c4.81-3.14,5.81-7,2.35-11.36c-2.83-3.56-4.32-7.18-4.53-11.67c-0.23-5.05-1.04-10.07-1.42-15.12c-0.08-1.02,0.62-2.09,1.27-4.09
				c5.24,4.57,9.93,8.7,14.68,12.78c5.93,5.09,11.82,10.22,17.87,15.16c10.7,8.74,9.99,14.84-3.02,19.59
				c-9.93,3.62-20.22,6.24-30.35,9.3C354.67,1062.94,354.67,1062.39,354.66,1061.84z"/>
			<path onClick={() => props.onFill(187)} fill={props.fillColors[187]}  d="M811.04,317.32c-25.4-3.63-49.55-3.56-73.61,9.5c0-4.35,0-8.17,0-11.99c0-3.26,0.15-6.53-0.02-9.78
				c-0.21-3.92,2.86-5.3,5.36-5.32c8.18-0.06,16.37,0.64,24.56,1c1.58,0.07,3.58,0.53,4.69-0.22c3.56-2.4,7.06-1.84,10.94-1.21
				c5.7,0.92,11.51,1.46,17.28,1.55c5.75,0.08,8.49,2.76,9.21,8.22C809.81,311.75,810.47,314.41,811.04,317.32z"/>
			<path onClick={() => props.onFill(188)} fill={props.fillColors[188]}  d="M526.2,513.94c-3.41,6.87-6.56,13.22-9.72,19.58c-12.12-3.23-23.22-0.45-34.53,2.43
				c-6.46,1.65-13.43,1.41-20.18,1.73c-5.19,0.25-10.4,0.05-15.6,0.05c-0.17-0.49-0.35-0.98-0.52-1.47c1.05-1.11,2.02-2.32,3.17-3.31
				c11.78-10.17,24.15-18.21,41.01-16.61c5.76,0.54,11.8-2.74,17.81-3.63C513.58,511.83,519.74,510.39,526.2,513.94z"/>
			<path onClick={() => props.onFill(189)} fill={props.fillColors[189]}  d="M592.44,502.15c2.57-4.8,4.71-9.44,7.49-13.65c1.03-1.56,3.75-3.21,5.4-2.95
				c10.73,1.69,20.54-3.28,30.85-3.83c11.36-0.61,22.78-0.14,32.85-0.14c-0.58,4.33-1.04,9.52-2.13,14.58
				c-0.28,1.31-2.39,2.79-3.91,3.17c-10.83,2.66-21.71,1.43-32.65,0.4c-4.45-0.42-9.23,0.29-13.57,1.52
				C608.97,503.43,601.29,504.33,592.44,502.15z"/>
			<path onClick={() => props.onFill(190)} fill={props.fillColors[190]}  d="M323.65,356.11c3.93-6.29,7.98-12.63,11.74-19.13c0.24-0.41-1.66-2.07-2.71-3.33
				c0.84-8.31,5.44-12.53,14.25-13.88c3.93-0.6,7.27-4.84,10.98-7.27c1.53-1.01,3.33-1.61,5.01-2.39c0.76,1.89,1.88,3.71,2.22,5.67
				c2.06,11.96-1.05,23.47-3.45,35.01c-0.24,1.15-1.89,2.03-2.94,2.97c-0.19,0.17-0.68-0.05-0.97,0.06
				c-7.15,2.7-14.28,5.21-22.21,3.57c-1.3-0.27-2.97,1.22-4.11,1.74C328.61,358.03,325.95,357,323.65,356.11z"/>
			<path onClick={() => props.onFill(191)} fill={props.fillColors[191]}  d="M730.25,187.39c-26.1,1.99-52.2,0.31-78.54,4.08c0.65-7.83,1.08-16.4,2.35-24.85
				c0.22-1.46,4-3.28,6.21-3.39c12.08-0.6,24.19-0.81,36.28-0.96c11.29-0.15,22.59-0.09,33.89-0.05c1.63,0.01,3.25,0.44,4.88,0.68
				c-0.04,0.64-0.09,1.28-0.13,1.92c-4.5,0-8.99-0.01-13.49,0c-2.74,0.01-5.66-0.81-7.18,2.85c-0.33,0.8-2.61,1.11-3.98,1.09
				c-10.22-0.12-20.43-0.54-30.65-0.45c-2.31,0.02-4.6,1.64-6.9,2.52c1.77,1.53,3.41,3.25,5.35,4.51c1.01,0.65,2.62,0.28,3.83,0.72
				c2.15,0.78,4.2,1.83,6.29,2.78c6.36,2.9,11.2,8.52,19.8,7.1C715.31,184.78,722.89,186.79,730.25,187.39z"/>
			<path onClick={() => props.onFill(192)} fill={props.fillColors[192]}  d="M640.43,256.24c0.6,4.1,1,6.82,1.4,9.56c-8.27,5.34-19.04,6.47-23.81,13.97c-5.87,0.58-10.51,1.73-15,1.33
				c-9.39-0.85-15.1,6.66-22.88,9.06c-5.8,1.79-12.22,1.58-18.37,2.19c-1.94,0.19-4.15-0.43-5.8,0.3
				c-8.69,3.87-17.2,8.16-25.87,12.07c-1.95,0.88-4.31,0.87-6.42,1.25c-1.72-6.9-0.26-9.15,5.46-10.95c2.39-0.75,4.21-3.3,6.6-4.11
				c9.32-3.13,18.6-7.21,28.22-8.46c11.28-1.46,21.81-3.39,30.67-11c0.85-0.73,2.4-0.76,3.65-0.9c2.28-0.26,4.58-0.32,6.86-0.56
				c3.07-0.32,6.19-0.45,9.16-1.17c2.8-0.68,5.54-1.83,8.13-3.13C628.26,262.79,633.98,259.65,640.43,256.24z"/>
			<path onClick={() => props.onFill(193)} fill={props.fillColors[193]}  d="M351.59,728.46c-2.91,2.09-5.3,3.98-7.88,5.56c-1.47,0.9-3.59,2.22-4.85,1.77
				c-6.62-2.35-12.05,0.07-17.66,3.16c-5.61,3.09-11.38,6.01-17.36,8.27c-2.49,0.94-6.42,1.03-8.47-0.33
				c-6.63-4.42-11.69-1.16-16.51,2.6c-2.58,2.01-4.8,2.63-7.7,1.6c-6.01-2.14-12.14-4.01-17.96-6.56c-2.02-0.89-3.3-3.47-4.67-5
				c1.81-0.51,3.91-0.63,5.41-1.63c2.76-1.85,4.79-3.21,8.24-0.58c2.15,1.64,5.75,1.46,8.73,1.82c0.75,0.09,2.01-0.67,2.38-1.39
				c4.47-8.64,12.05-9.12,20.43-8.43c3.55,0.29,7.26-0.38,10.79-1.13c3.94-0.84,6.97,0.39,10.13,2.56c5.05,3.46,7.97,2.91,11.36-2.26
				c2.5-3.82,5.05-4.37,9.54-3.24c4.2,1.06,8.32,4.59,13.25,2.01C349.39,726.93,350.73,728.06,351.59,728.46z"/>
			<path onClick={() => props.onFill(194)} fill={props.fillColors[194]}  d="M215.87,977.34c5.01,2.39,9.81,4.79,14.72,6.96c2.04,0.9,4.26,1.59,6.47,1.85
				c7.32,0.85,12.92,6.25,13.93,13.61c0.17,1.26,1.09,2.43,1.22,3.69c0.16,1.5,0.48,3.65-0.34,4.5c-5.32,5.54-10.9,10.84-16.45,16.16
				c-2.59,2.48-5.31,4.82-8.36,7.59c-7.8-3.4-9.24-10.49-7.85-19.29c0.68-4.31,0.35-8.88-0.13-13.26
				C218.34,992.26,217.09,985.43,215.87,977.34z"/>
			<path onClick={() => props.onFill(195)} fill={props.fillColors[195]}  d="M829.61,424.99c-1.98-4.18-1.17-8.68,2.9-10.53c5.53-2.51,11.31-4.57,17.11-6.38
				c9.43-2.95,19.01-5.44,28.47-8.33c10.88-3.32,21.48-7.98,32.58-10.01c11.8-2.16,24.01-3.05,36.14-1.1c3.14,0.5,4.42,1.74,3.8,4.9
				c-0.4,2.07-0.62,4.19-0.97,6.7C906.33,393.76,868.42,411.25,829.61,424.99z"/>
			<path onClick={() => props.onFill(196)} fill={props.fillColors[196]}  d="M93.02,1142.42c-5.42-1.18-8.27-3.34-8.42-8.21c-0.03-1.04-1.19-2.05-1.83-3.08
				c-0.86,0.87-1.88,1.63-2.52,2.63c-0.64,1-0.9,2.25-1.33,3.39c-0.65-0.02-1.3-0.05-1.95-0.07c-0.23-4.44-0.47-8.88-0.72-13.73
				c12,0,23.38,0.04,34.76-0.02c6.19-0.03,11.96,0.56,17.56,4.12c2.85,1.82,7.34,1.1,11.1,1.44c0.61,0.06,1.26-0.45,1.91-0.51
				c4.72-0.46,14.78,7.68,14.86,12.48c0.03,1.84-1.14,4.15-2.5,5.49c-4.51,4.46-16.98,8.72-23.55,8.72
				c-1.05-8.05-2.04-16.01-3.18-23.96c-0.11-0.77-1.39-2.08-1.74-1.96c-0.93,0.31-2.13,1.12-2.38,1.98
				c-1.01,3.48-1.63,7.08-2.56,10.59c-0.59,2.23-1.46,4.39-2.6,7.74c-1.8-8.46-0.13-16.37-6.81-23.68c-2.4,5.22-4.46,9.7-6.78,14.75
				c-1.06-5.17-1.98-9.66-3.03-14.79c-4.64,1.12-3.57,5.48-4.94,8.06C94.89,1136.55,94.06,1139.64,93.02,1142.42z"/>
			<path onClick={() => props.onFill(197)} fill={props.fillColors[197]}  d="M589.08,370.67c8.07-13.04,16.15-26.07,24.38-39.37c5.35,2.28,10.29,4.04,13.39,9.22
				c2.27,3.8,5.49,7.05,8.44,10.42c0.82,0.94,2.29,2.16,3.26,2.01c8.3-1.33,7.51,5.78,10.06,10.12c-19.73,3.58-40,0.35-58.62,9.2
				C589.68,371.73,589.38,371.2,589.08,370.67z"/>
			<path onClick={() => props.onFill(198)} fill={props.fillColors[198]}  d="M359.63,473.35c2.32,1.96,3.76,3.18,5.2,4.39c1.22-1.63,2.88-3.09,3.55-4.92c1.35-3.7,3.23-6,7.57-5.68
				c1.56,0.12,3.33-0.41,4.75-1.13c2.1-1.08,2.83-2.63,0.71-4.7c-3.63-3.53-3.19-8.02,1.35-9.63c8.34-2.96,12.19-9.47,15.02-17.11
				c0.39-1.06,1.2-1.98,1.91-2.9c2.19-2.84,3.74-5.69,0.35-8.53c3.6-1.04,6.92-2,10.85-3.13c1.44,1.56,0.85,9.38-2.6,11.23
				c-10.21,5.47-11.23,15.26-12.73,24.98c-1.1,7.12-3.07,13.68-7.54,19.75c-4.49,6.1-7.46,13.31-11.23,19.95
				c-3.7,6.51-6.02,7.08-12.11,2.9c-1.2-0.82-2.31-2.49-2.51-3.91C361.17,487.93,360.49,480.9,359.63,473.35z"/>
			<path onClick={() => props.onFill(199)} fill={props.fillColors[199]}  d="M90.2,359.9c-9.48-4.19-17.75-7.84-26.21-11.58c8.12-13.41,14.7-27.54,28.25-36.13
				c4.46-2.83,6.45-6.49,7.05-11.76c0.78-6.84,1.9-13.81,4.18-20.26c2.41-6.81,5.32-7.4,12.7-4.65c-0.11,5.28-3.09,9.26-6.43,12.9
				c-7.25,7.9-11.6,16.89-11.74,27.79c-0.03,2.29-0.91,4.55-1.26,6.84c-0.93,6.02-1.65,12.07-2.72,18.06
				C92.89,347.5,91.44,353.85,90.2,359.9z"/>
			<path onClick={() => props.onFill(200)} fill={props.fillColors[200]}  d="M365.1,797.6c1.39,4.05-0.03,5.36-3.87,5.34c-18.42-0.08-36.84,0.09-55.26-0.12
				c-1.9-0.02-3.89-1.8-5.6-3.09c-0.37-0.28,0.18-2.79,0.48-2.83c3.56-0.42,4.49-3.04,6-5.7c0.54-0.96,4.49-0.92,6.25-0.06
				c3.54,1.73,4.88,0.09,6.06-2.67c0.33-0.76,0.5-1.59,0.82-2.35c1.41-3.28,1.87-6.98,6.43-8.25c1.94-0.54,3.27-3.47,4.73-5.43
				c0.9-1.21,1.51-2.65,2.79-4.97c3.02,9.33,8.4,14.45,17.13,16C353.58,792.2,358.91,797.76,365.1,797.6z"/>
			<path onClick={() => props.onFill(201)} fill={props.fillColors[201]}  d="M511.34,752.45c-7.09,0.77-14.19,1.51-21.28,2.32c-2.74,0.31-4.47,1.44-3.44,3.59
				c-15.55,10.47-33.33,6.57-50.37,8.16c-2.87,0.27-5.78,1.6-8.39,2.97c-4.17,2.19-8.63,0.28-10.07-4.06
				c-1.71-5.13-2.43-11.2-9-13.55c34.19,0,68.37,0,102.56,0C511.35,752.07,511.34,752.26,511.34,752.45z"/>
			<path onClick={() => props.onFill(202)} fill={props.fillColors[202]}  d="M687.78,403.62c6.3-3.21,13.48-6.93,20.72-10.53c3.72-1.85,10.1,1.28,11.3,6.88
				c1.56,7.26,3.92,13.74,8.15,19.92c1.59,2.32,0.27,6.74-0.05,10.18c-0.06,0.65-1.59,1.44-2.56,1.68
				c-4.33,1.07-8.86,1.53-13.02,3.03c-5.34,1.93-8.46-0.01-10.8-4.56C696.87,421.2,692.21,412.19,687.78,403.62z"/>
			<path onClick={() => props.onFill(203)} fill={props.fillColors[203]}  d="M188.44,329.37c-3.87-1.79-7.58-4.29-11.64-5.19c-5.88-1.31-11.99-1.74-18.03-2.02
				c-4.42-0.21-8.89,0.34-13.32,0.73c-2.75,0.24-5.45,0.91-8.2,1.19c-1.57,0.16-3.19,0.02-4.76-0.18c-3.23-0.41-6.44-0.94-9.66-1.42
				c-0.64-0.1-1.61,0-1.87-0.38c-3.86-5.7-9.98-4.4-15.4-4.92c-3.09-0.29-4.85-1.24-4.15-4.51c0.68-3.2-1.26-8.26,4.4-8.39
				c5.42-0.12,11.43-1.65,15.61,3.99c0.59,0.8,2.5,0.5,3.66,1.01c2.24,0.98,4.92,1.68,6.45,3.38c2.88,3.22,5.08,3.27,7.62-0.06
				c2.14-2.81,4.84-3.35,8.35-2.92c3.71,0.45,7.56-0.28,11.33-0.19c6.21,0.15,12.66-0.41,18.55,1.13c5.28,1.38,10.99,11.2,12.03,17.2
				C189.08,328.33,188.76,328.85,188.44,329.37z"/>
			<path onClick={() => props.onFill(204)} fill={props.fillColors[204]}  d="M232.03,342.09c-0.65,3.15-0.73,6.57-2.04,9.41c-5.61,12.16-11.27,24.31-17.54,36.13
				c-3.22,6.07-7.06,12.15-11.93,16.88c-4.07,3.96-10.02,5.99-15.2,8.75c-0.49,0.26-1.58-0.29-2.23-0.71
				c-3.87-2.53-7.7-5.13-12.36-8.26c3.9-1.14,6.99-2,10.04-2.97c1.89-0.6,3.81-1.23,5.59-2.09c6.92-3.36,9.13-9.07,5.65-15.75
				c-0.72-1.38-3.44-1.7-5.38-2.57c5.42-6.42,11.03-13.06,16.64-19.7c0.31,0.13,0.62,0.25,0.93,0.38c-1.65,4.02-3.67,7.94-4.83,12.09
				c-0.77,2.76-0.33,5.86-0.43,8.81c2.48-1.57,5.02-3.07,7.41-4.76c0.76-0.54,1.4-1.53,1.69-2.44c3.76-11.74,12.52-20.27,19.15-30.17
				c0.99-1.48,1.58-3.24,2.36-4.87C230.37,340.86,231.2,341.47,232.03,342.09z"/>
			<path onClick={() => props.onFill(205)} fill={props.fillColors[205]}  d="M487.96,353.81c-15.02-0.64-29.25,1.02-42.92,5.98c-0.31-8.69,2.07-10.83,12.91-11.18
				c1.75-0.06,3.5-0.45,5.22-0.82c3.27-0.71,6.51-1.56,9.78-2.28c1.15-0.26,2.33-0.33,3.5-0.48c-0.01-0.65-0.02-1.31-0.02-1.96
				c-3.59,0-7.24-0.46-10.74,0.11c-4.47,0.73-8.81,2.24-13.19,3.49c-3.08,0.88-6.12,1.91-9.75,3.05c-0.11-0.44-0.57-1.72-0.72-3.04
				c-0.43-3.92-0.63-7.86-1.1-11.78c-0.59-4.96,0.96-6.86,5.94-6.55c3.05,0.19,6.07,0.85,9.13,1.08c1.02,0.08,2.22-0.33,3.1-0.88
				c0.8-0.5,1.22-1.55,1.9-2.28c2.68-2.89,10.14-2.61,15.18,1.06c3.29,2.4,6.87,5.04,4.37,10.32c-0.49,1.04,0.32,3.62,1.31,4.27
				C486.37,344.85,488.32,348.23,487.96,353.81z"/>
			<path onClick={() => props.onFill(206)} fill={props.fillColors[206]}  d="M653.45,514.02c-1.71,3.18-2.89,5.44-4.14,7.65c-0.48,0.85-1.54,1.57-1.63,2.43
				c-0.7,6.7-1.63,13.41-1.65,20.12c-0.01,4.37-0.79,7.93-5.22,8.87c-6.62,1.4-9.32,7.14-13.57,11.18c-0.53,0.51-0.78,2.25-0.41,2.57
				c0.74,0.65,2.15,1.23,3.01,0.96c3.11-0.98,6.11-2.33,10.37-2.76c-0.87,1.79-1.27,4.41-2.7,5.23c-5.59,3.23-11.46,5.98-17.34,8.68
				c-0.97,0.45-2.85,0.18-3.59-0.52c-0.58-0.55-0.41-2.45,0.06-3.45c2.95-6.26,6.9-12.16,6.65-19.51c-0.1-2.93-0.41-5.85-0.38-8.78
				c0.02-2.45-0.03-5.04,0.72-7.31c1.5-4.52,3.59-8.83,5.31-13.28c0.97-2.51,1.54-5.23,5.17-4.99c0.55,0.04,1.21-0.49,1.7-0.9
				C642.94,514.21,642.94,514.2,653.45,514.02z"/>
			<path onClick={() => props.onFill(207)} fill={props.fillColors[207]}  d="M336.96,832.77c-9.32,0.51-18.66,4.03-27.91-1.65c-1.38-0.85-3.53-0.6-5.32-0.62
				c-6.76-0.06-13.53-0.03-19.3-0.03c-2.09-4.63-4.02-8.92-6.29-13.96c29.68-1.16,59.79-2.92,89.89,0.38
				c0.03,0.49,0.06,0.99,0.09,1.48c-6.85,0.8-13.71,1.57-20.56,2.4c-3.29,0.4-6.61,0.67-9.82,1.41c-5.39,1.25-5.94,3.39-2.13,7.24
				c0.56,0.57,1.1,1.16,1.66,1.74C337.16,831.7,337.06,832.23,336.96,832.77z"/>
			<path onClick={() => props.onFill(208)} fill={props.fillColors[208]}  d="M895.51,850.36c1.32-1.66,2.34-3.46,3.81-4.73c9.19-8,9.85-19.09,11.84-30c0.76-4.16,5.04-7.68,7.72-11.49
				c0.51,0.12,1.01,0.23,1.52,0.35c0.48,1.58,1.05,3.13,1.41,4.73c0.63,2.86,1.57,5.76,1.53,8.63c-0.04,2.3-1.39,4.57-2.06,6.89
				c-0.75,2.58-1.37,5.2-2.07,7.8c-0.33,1.24-0.48,2.61-1.14,3.66c-3.04,4.82-6.24,9.55-9.62,14.69c2.37,2.86,4.65,5.54,6.83,8.3
				c0.58,0.74,1.3,1.78,1.2,2.59c-1.08,9.01,1.5,18.3-3.19,27.08c-4.1,7.68-5.33,8.65-13.8,5.72
				C906.41,879.9,908.07,872.17,895.51,850.36z"/>
			<path onClick={() => props.onFill(209)} fill={props.fillColors[209]}  d="M219.77,238.72c2.97-7.15,7.15-11.32,14.15-12.33c8.03-1.15,15.98-2.9,24.14-0.8
				c5.64,1.45,8.76,4.78,7.54,10.29c-1.74,7.89-4.29,15.62-6.9,23.29c-0.39,1.15-3.16,2.41-4.5,2.11c-2.32-0.51-4.94-1.73-6.43-3.5
				c-4.23-5.04-12-6.86-13.3-14.72c-0.16-0.95-2.93-1.67-4.6-2.12C226.72,240.1,223.48,239.52,219.77,238.72z"/>
			<path onClick={() => props.onFill(210)} fill={props.fillColors[210]}  d="M770.09,738.48c2.21,11.3,4.41,22.59,6.73,34.45c0.24-0.14-0.65,0.77-1.11,0.59
				c-5.92-2.35-11.33,0-17.02,1.2c-4.36,0.92-9.05,1.19-13.34-2.03c-1.6-1.2-4.79-0.37-7.25-0.33c-2.46,0.05-4.91,0.27-7.65,0.43
				c3.34-10.53,6.53-20.57,9.72-30.61c0.38,0.03,0.76,0.05,1.14,0.08c0.94,4.53,1.79,9.08,2.89,13.56c0.28,1.14,1.15,2.48,2.13,2.96
				c0.61,0.31,2.28-0.55,2.85-1.34c0.92-1.28,1.37-2.9,1.92-4.42c1.85-5.14,3.65-10.3,5.83-16.47c3.22,4.38,0.52,10.91,6.41,11.04
				C768.82,747.72,768.29,742.17,770.09,738.48z"/>
			<path onClick={() => props.onFill(211)} fill={props.fillColors[211]}  d="M364.54,768.93c-1.98-2.68-3.55-4.56-4.85-6.61c-3.05-4.83-7.27-7.55-12.93-8.7
				c-2.88-0.59-5.4-2.83-8.21-4.01c-1.23-0.52-3.03-0.8-4.15-0.28c-4.08,1.89-7.86,4.47-12.01,6.19c-2.83,1.17-6.12,2-9.13,1.82
				c-9.71-0.59-19.39-1.69-29.08-2.66c-1.62-0.16-3.21-0.61-5-0.96c5.34-5.94,9.84-6.94,15.58-3.34c3.88,2.43,7.45,0.48,10.67-0.89
				c7.7-3.28,15-7.48,22.71-10.73c2.45-1.03,5.67-0.26,8.54-0.19c0.78,0.02,1.84,0.98,2.27,0.71c4.85-3.1,8.94,0.44,13.32,1.37
				c0.6,0.13,1.5,0.18,1.72,0.57c4.19,7.43,12.74,7.06,18.93,10.83c1.75,1.07,2.85,3.54,3.65,5.6c0.18,0.45-2.44,2.93-3.33,2.7
				c-5.24-1.31-6.29,2.2-7.48,5.8C365.58,766.75,365.27,767.3,364.54,768.93z"/>
			<path onClick={() => props.onFill(212)} fill={props.fillColors[212]}  d="M863.12,1009.04c-2.01-0.47-4.05-0.85-6.02-1.43c-18.81-5.57-38.36-7.15-57.15-3.18
				c-17.56,3.71-35.25,3.59-52.75,6.2c-0.61,0.09-1.26-0.12-2-0.47c13.87-3.19,26.19-8.55,32.92-21.87
				c19.78-0.84,38.66,3.39,57.11,10.27c9.28,3.46,18.75,6.39,28.13,9.57C863.29,1008.43,863.21,1008.73,863.12,1009.04z"/>
			<path onClick={() => props.onFill(213)} fill={props.fillColors[213]}  d="M403.78,264.66c3.25,2.03,6.61,3.93,9.72,6.15c1.73,1.23,2.92,3.21,4.62,4.52
				c4.33,3.33,5.87,7.49,5.58,12.98c-0.27,4.92,0.55,9.91,1.07,14.85c0.11,1.04,0.79,2.25,1.58,2.94c3.34,2.9,2.58,6.05,0.93,9.38
				c-1.67,3.38-4.52,3.45-7.6,2.72c-3.92-0.93-7.8-1.98-11.71-2.94c-0.62-0.15-1.53-0.39-1.9-0.09c-4.32,3.52-8.12,0.26-12.15-0.7
				c-2-0.48-4.25,0.17-6.29-0.2c-2.83-0.53-5.87-1.08-8.16-2.62c-0.73-0.49,0.73-4.11,1-6.32c0.23-1.91,0.21-3.85,0.33-6.5
				c6.96,2.76,14.66,5.87,22.4,8.86c2.61,1.01,5.12,0.77,6.02-2.37c0.99-3.42,4.69-6.52,0.21-10.68c-1.59-1.48-0.51-5.82-0.67-8.86
				c-0.21-3.95-0.71-7.92-0.59-11.86c0.12-3.98-1.07-6.76-5.51-7.66C403.03,265.73,403.4,265.19,403.78,264.66z"/>
			<path onClick={() => props.onFill(214)} fill={props.fillColors[214]}  d="M337,981.54c5.76,9.45,7.41,19.67,8.76,29.96c1.19,9.02,2.1,18.07,3.47,27.06
				c0.45,2.95-0.45,5.01-2.96,5.29c-5.46,0.6-11.04,1-16.46,0.44c-1.7-0.18-3.94-3.53-4.36-5.73c-0.61-3.13,0.04-6.57,0.49-9.82
				c1.3-9.28,2.59-18.57,4.26-27.79C331.39,994.39,330.88,987.17,337,981.54z"/>
			<path onClick={() => props.onFill(215)} fill={props.fillColors[215]}  d="M929.66,328.16c9.02-6.74,17.88-13.7,27.12-20.12c4.92-3.42,7.28-2.42,8.34,3.47
				c1.28,7.15,1.89,14.45,2.39,21.72c0.28,4.03-5.71,6.68-10.57,4.97c-7.21-2.55-8.72-1.66-9.47,5.97
				c-0.47,4.77-0.65,9.56-0.96,14.32c-2.85-0.63-6.43-0.36-7.7-1.95c-2.43-3.04-4.23-6.99-4.98-10.83c-0.6-3.08,0.91-6.52,1.21-9.82
				C935.36,332.35,934.83,329.15,929.66,328.16z"/>
			<path onClick={() => props.onFill(216)} fill={props.fillColors[216]}  d="M588.92,509.92c-3.66,5.78-5.89,11.56-13.28,12.17c-4,0.33-7.9,1.11-10.23,5.14
				c-0.45,0.79-1.88,1.34-2.9,1.43c-13.82,1.12-27.65,2.13-42,3.21c2.63-5.12,5.15-10.63,8.27-15.77c1.07-1.77,3.52-3.33,5.59-3.74
				c8.29-1.64,16.64-3.23,25.04-3.96c5.52-0.48,11.18,0.57,16.78,0.89C580.04,509.52,583.91,509.68,588.92,509.92z"/>
			<path onClick={() => props.onFill(217)} fill={props.fillColors[217]}  d="M406.66,318.24c0.81,0.35,1.49,0.42,1.66,0.75c2.59,4.93,5.12,9.89,7.63,14.86
				c0.45,0.88,0.49,2.38,1.14,2.69c8.33,3.87,8.44,15.19,17.42,18.9c-0.76,0.57-1.41,0.95-1.93,1.46c-1.29,1.28-2.52,2.62-3.78,3.94
				c1.44,0.64,2.9,1.21,4.31,1.92c3.15,1.58,6.68,2.83,7.17,6.84c-6.6,0-12.94,0-19.29,0c-0.33,0-0.84,0.19-0.96,0.04
				c-5.23-6.48-11.66,0.51-17.98-2.22c0-6.58-0.01-13.85,0.01-21.12c0.01-3.77,0.37-7.57,0.06-11.31
				C401.61,328.92,403,323.51,406.66,318.24z"/>
			<path onClick={() => props.onFill(218)} fill={props.fillColors[218]}  d="M663.96,502.48c0.55,5.4-1.25,7.66-7.12,7.51c-9.05-0.23-18.13,0.56-27.2,1.04
				c-3.72,0.2-6.52,1.63-8.01,5.58c-0.54,1.45-2.86,2.85-4.55,3.13c-6.04,0.98-12.72,0.18-18.11,2.49
				c-6.18,2.65-12.55,6.6-14.74,14.09c-0.99,3.38-2.37,6.23-6.98,6.71c2.17-8.81,4.16-17.32,9.39-24.75c1.96-2.78,3.13-6.11,5-8.96
				c0.9-1.37,2.66-3.22,3.93-3.14c8.92,0.53,17.35-1.05,25.97-3.45c5.14-1.43,11.15,0.48,16.77,0.46c7.41-0.02,14.83-0.46,22.24-0.7
				C661.69,502.45,662.83,502.48,663.96,502.48z"/>
			<path onClick={() => props.onFill(219)} fill={props.fillColors[219]}  d="M852.38,356.39c-3.19,5.17-6.58,9.23-12.04,11.09c-6.99,2.38-9.97,8.68-7.3,15.45
				c2.91,7.4,0.82,16.22-4.88,21.77c-2.11,2.06-3.95,4.48-5.57,6.96c-2,3.06-4.03,2.59-4.92-0.28c-0.89-2.85-1.46-6.37-0.59-9.08
				c2.95-9.15,3.49-17.53-3.77-25.1c-1-1.04-0.86-3.39-0.86-5.13c0-1.96,0.63-3.92,0.65-5.89c0.09-6.89,0.05-6.63,6.69-7.1
				c9.4-0.67,18.77-1.78,28.16-2.66C849.2,356.29,850.49,356.39,852.38,356.39z"/>
			<path onClick={() => props.onFill(220)} fill={props.fillColors[220]}  d="M235.79,329.77c-0.64,1.41-1.54,2.77-1.88,4.25c-1.09,4.74-4.68,6.3-8.61,5.54
				c-8.1-1.58-15.82-0.35-23.63,1.49c-2.48,0.59-5.12,0.53-7.84,0.78c-0.1-2.19-0.15-3.66-0.24-5.12c-0.16-2.6-1.38-6.8-0.31-7.51
				c3.17-2.08,7.16-3.32,10.98-3.89c5.55-0.82,11.25-1.19,16.85-0.97c6.21,0.25,12.37,1.49,18.57,2.09c3.09,0.3,6.23,0.08,9.34,0.25
				c4.31,0.23,8.62,0.6,12.92,0.9c0.18,0.73,0.36,1.45,0.53,2.18c-3.13,1.88-6.05,4.4-9.44,5.51c-6.11,2.01-10.29,5.04-12.29,11.65
				c-1.85,6.09-5.34,11.61-10.86,14.55c2.62-10.24,5.29-20.67,7.96-31.09C237.17,330.19,236.48,329.98,235.79,329.77z"/>
			<path onClick={() => props.onFill(221)} fill={props.fillColors[221]}  d="M219.77,348.88c-6.24,10.56-12.08,20.44-17.92,30.32c-0.45-0.23-0.91-0.46-1.36-0.68
				c1.55-3.84,3.13-7.67,4.63-11.54c0.72-1.86,1.57-3.74,1.81-5.68c0.16-1.29-0.26-3.31-1.14-3.88c-0.8-0.52-2.87,0.11-3.86,0.89
				c-1.52,1.21-2.56,3.02-3.86,4.53c-4.12,4.78-8.35,9.47-12.34,14.35c-1.48,1.81-2.42,4.05-3.6,6.1c2.22,0.62,4.45,1.23,6.87,1.91
				c0.29,1.77,0.75,4.12,1,6.5c0.07,0.69-0.28,1.57-0.73,2.14c-3.01,3.87-20.45,8.92-25.07,7.3c-0.44-0.15-0.79-0.55-1.61-1.15
				c1.36-1.87,2.42-4,4.06-5.5c9.02-8.21,11.88-19.31,14.61-30.5c0.51-2.09,1.03-4.19,1.76-6.21c1.59-4.39,4.08-6.72,9.48-6.78
				c7.22-0.08,14.4-1.88,21.63-2.72C216.01,348.06,217.98,348.67,219.77,348.88z"/>
			<path onClick={() => props.onFill(222)} fill={props.fillColors[222]}  d="M182.63,1027.37c-3.77-4.14-7.55-8.27-11.29-12.44c-0.65-0.73-1-2.26-1.66-2.37
				c-7.51-1.21-13-8.45-21.83-6.41c-4.82,1.12-10.23-0.29-15.07-0.55c-1.61-7.37,3.59-10.22,7.18-12.8
				c5.4-3.88,11.81-6.03,19.01-5.18c4.66,0.55,6.71,2.94,7.84,6.93c0.7,2.49,1.59,4.09,4.43,1.78c1.49-1.21,4.45-2.99,5.1-2.46
				c2.57,2.07,5.48,4.64,6.41,7.61c1.37,4.34,0.5,9.05,2.74,13.67c1.43,2.95-0.59,7.57-1.06,11.45
				C183.83,1026.86,183.23,1027.12,182.63,1027.37z"/>
			<path onClick={() => props.onFill(223)} fill={props.fillColors[223]}  d="M412.35,427.49c4.62-4.51,3.24-7.82,0.19-10.37c-4.82-4.02-6.76-8.84-6.48-15.1
				c0.16-3.52-0.71-7.16-1.58-10.64c-1.45-5.79-0.34-7.93,5.59-7.95c8.76-0.03,17.53,0.45,26.29,0.78c2.8,0.1,3.63,1.51,3.9,4.48
				c1.04,11.49-5.46,17.55-14.73,21.91c-3.11,1.46-5,3.14-5.43,7.32C419.75,421.21,419.22,426.35,412.35,427.49z"/>
			<path onClick={() => props.onFill(224)} fill={props.fillColors[224]}  d="M836.61,382.82c-3.25-5.66-1.24-10.86,5.06-12.74c1.8-0.54,3.31-1.97,5.05-2.75
				c2.52-1.13,5.07-2.33,7.74-3c15.26-3.83,30.69-7.08,45.78-11.5c4.39-1.29,8.39-5.28,11.6-8.89c7.49-8.44,7.76-8.96,20.04-11.41
				c0.97,5.02-0.24,9.88-1.7,14.39c-0.52,1.61-3.94,2.59-6.21,3.25c-4.9,1.42-9.93,2.41-14.89,3.65c-1.11,0.28-2.55,0.54-3.14,1.34
				c-4.32,5.85-11.07,5.23-17.03,6.45c-5.23,1.06-9.86,2.54-12.98,7.33c-0.87,1.33-2.74,2.31-4.35,2.77
				c-5.89,1.7-11.95,2.86-17.79,4.7C847.99,378.23,842.38,380.64,836.61,382.82z"/>
			<path onClick={() => props.onFill(225)} fill={props.fillColors[225]}  d="M576.98,220.4c1.83-7.48,4.47-10.36,11.59-12.62c0.69,2.16,1.78,4.29,1.98,6.51
				c0.44,4.75,1.16,8.83,5.7,11.98c1.48,1.02,1.1,5.86,0.43,8.68c-2.21,9.28-5.04,18.42-7.41,27.67c-0.98,3.8-8.05,8.87-13.62,9.22
				c-2.97,0.19-5.94,0.3-10.27,0.52c1.86-5.63,3.62-10.97,5.39-16.3c1.03-3.12,2.27-6.18,3.06-9.36c0.7-2.82,1.49-4.61,4.71-6.05
				c3.51-1.57,6.4-5.15,8.79-8.42c1.71-2.35-3.58-10.27-7.07-11.28C579.31,220.68,578.31,220.62,576.98,220.4z"/>
			<path onClick={() => props.onFill(226)} fill={props.fillColors[226]}  d="M591.78,262.52c3.42-11.13,3.05-23.19,12.63-31.8c1.73-1.56,2.13-5.5,1.86-8.21
				c-0.68-6.9-0.22-7.98,6.59-9.58c2.09-0.49,4.18-0.94,7.14-1.61c1.12,10.5,3.71,20.61,1.91,31.09c-0.21,1.23-1.21,2.32-1.81,3.5
				c-1.64,3.19-3.54,6.29-4.79,9.63c-0.89,2.4-1.46,3.57-4.59,3.78C604.62,259.73,598.58,261.3,591.78,262.52z"/>
			<path onClick={() => props.onFill(227)} fill={props.fillColors[227]}  d="M810.08,299.62c7.16-1.06,13.45-0.63,19.29,3.72c4.78,3.56,10.16,6.28,15.11,9.64
				c1.53,1.04,3.3,2.77,3.59,4.43c1.78,9.94,6.83,18.3,12.06,26.62c0.33,0.53,0.5,1.16,0.9,2.1c-4.13,1.19-7.7,0.94-10.76-1.93
				c-3.15-2.95-6.98-5.55-9.12-9.13c-5.14-8.58-12.99-12.65-22.06-14.97c-3.62-0.92-5.24-2.71-5.84-6.3
				C812.46,309.08,811.18,304.45,810.08,299.62z"/>
			<path onClick={() => props.onFill(228)} fill={props.fillColors[228]}  d="M725.98,952.49c-10.61-0.62-21.21-1.42-31.83-1.73c-2.43-0.07-4.9,1.53-7.35,2.36
				c2.09,1.39,4,3.51,6.29,4.05c9.69,2.28,19.51,3.95,29.21,6.16c2.1,0.48,3.86,2.34,5.85,3.44c2.13,1.18,4.23,2.69,6.53,3.2
				c6.15,1.36,12.44,2.12,18.61,3.42c2.57,0.54,4.98,1.85,7.46,2.81c-0.18,0.51-0.37,1.02-0.55,1.54c-1.28-0.34-2.54-0.73-3.83-1.02
				c-10.52-2.33-21.04-4.69-31.58-6.92c-1.59-0.34-3.37-0.35-4.96-0.02c-1.3,0.27-2.45,1.29-3.66,1.97c0.77,1.13,1.42,2.37,2.34,3.36
				c0.64,0.69,1.65,1.03,3.71,2.25c-5.63,0-9.9,0-13.28,0c-0.5-3.43,0.15-7.94-1.79-10c-2.66-2.83-7.13-4.6-11.11-5.41
				c-8.93-1.81-18.01-2.91-27.07-3.97c-3.88-0.45-7.85-0.08-12.22-0.08c-1.2-3.43,0.48-6.79,3.29-9.55c4.38-4.3,9.85-6.54,15.92-5.57
				c15.24,2.44,30.41,5.29,45.6,8.01c1.59,0.29,3.13,0.86,4.69,1.3C726.17,952.23,726.07,952.36,725.98,952.49z"/>
			<path onClick={() => props.onFill(229)} fill={props.fillColors[229]}  d="M273.06,305.95c-2.89-0.47-5.77-1.01-8.67-1.38c-2.78-0.35-5.57-0.52-8.36-0.77
				c-7.62-0.67-15.24-1.38-22.86-1.99c-2.61-0.21-5.24-0.33-7.85-0.21c-5.79,0.26-11.58,0.58-17.35,1.09
				c-2.75,0.25-5.47,0.96-8.18,1.55c-2.2,0.48-4.37,1.08-7.68,1.91c2.16-7.08,4.47-12.8,9.4-17c1.26-1.07,3.32-1.55,5.05-1.66
				c6.94-0.42,13.91-0.99,20.85-0.76c8.31,0.28,16.59,1.31,25.56,2.08c-0.9,2.09-1.64,4.01-2.53,5.87c-1.94,4.05-0.85,5.45,3.97,5.39
				c4.93-0.06,9.9-0.19,14.78,0.35c1.82,0.2,3.45,2.19,5.16,3.36C273.91,304.5,273.49,305.22,273.06,305.95z"/>
			<path onClick={() => props.onFill(230)} fill={props.fillColors[230]}  d="M272.55,308.1c-0.65,2.34-0.8,3.15-1.1,3.91c-3.55,8.88-3.52,8.73-12.7,6.71
				c-2.99-0.66-6.21-0.24-9.31-0.49c-6-0.49-11.99-1.11-17.98-1.68c-2.92-0.28-5.84-0.9-8.75-0.83c-5.8,0.14-11.62,0.4-17.36,1.11
				c-3.55,0.44-6.97,1.84-10.46,2.76c-2.85,0.75-5.29,1.21-6.77-2.63c-1.4-3.64,0.31-6.02,2.82-6.95c5.52-2.04,11.3-3.64,17.11-4.61
				c5.34-0.88,10.87-1.09,16.29-0.88c6.7,0.27,13.37,1.33,20.06,1.97c3.12,0.3,6.25,0.41,9.38,0.59
				C259.78,307.42,265.78,307.73,272.55,308.1z"/>
			<path onClick={() => props.onFill(231)} fill={props.fillColors[231]}  d="M402.86,396.8c-3.75,0-7.52,0.1-11.28-0.05c-1.6-0.06-3.2-0.61-4.78-1c-4.87-1.23-9.75-2.43-14.6-3.77
				c-1.63-0.45-4.13-0.91-4.54-2c-1.41-3.74-3.97-4.71-7.44-4.27c-3.55,0.45-4.37-1.41-4.09-4.42c0.02-0.17-0.01-0.34,0.01-0.5
				c1.97-12.83,1.43-16.28,16.74-11.82c1.75,0.51,3.53,1.4,5.28,1.38c8.77-0.13,14.86,5,20.71,10.46c1.32,1.23,2.08,3.38,2.48,5.24
				C402.09,389.57,402.38,393.19,402.86,396.8z"/>
			<path onClick={() => props.onFill(232)} fill={props.fillColors[232]}  d="M651.81,288.64c0.25-5.36,1.16-10.06,0.47-14.52c-0.62-4,1.26-6.49,3.72-7.94
				c7.15-4.24,15.08-6.66,23.43-6.9c4.8-0.14,6.35,2.5,4.11,6.89c-5.15,10.05-3.69,10.26,7.08,10.29c3.9,0.01,7.82,2.04,11.66,3.37
				c0.68,0.23,1.45,1.59,1.34,2.29c-0.1,0.63-1.3,1.25-2.13,1.56c-6.63,2.41-13.43,1.7-20.31,1.65c-5.74-0.05-11.48,1.99-17.26,2.73
				C659.81,288.56,655.63,288.47,651.81,288.64z"/>
			<path onClick={() => props.onFill(233)} fill={props.fillColors[233]}  d="M928.02,898.39c2.05-3.4,4.01-6.65,6.2-10.29c1.28,0.35,2.7,0.67,4.07,1.13
				c7.23,2.42,14.47,4.8,21.63,7.39c1.41,0.51,3.29,1.75,3.51,2.93c0.21,1.18-1.08,3.13-2.26,3.95c-2.56,1.8-5.38,3.29-8.25,4.56
				c-9.1,4.03-18.22,8.02-27.42,11.82c-10.9,4.5-22.07,6.75-34.03,5.12c-11.32-1.54-22.76-2.19-34.15-3.27
				c-0.77-0.07-1.52-0.42-2.56-0.71c1.03-3.19,1.97-6.14,3.05-9.49c5.19,1.95,10.32,4.08,15.57,5.8c14.25,4.67,29,6.94,43.62,3.52
				c6.15-1.44,11.5-6.64,16.9-10.56c0.85-0.62,0.67-3.89-0.14-5.3C932.4,902.62,930.15,900.75,928.02,898.39z"/>
			<path onClick={() => props.onFill(234)} fill={props.fillColors[234]}  d="M846.2,725.14c1.71,7.98,3.09,14.77,4.7,21.51c0.23,0.97,1.62,1.65,2.48,2.47
				c0.52-1.09,1.28-2.12,1.53-3.27c0.83-3.82,1.47-7.68,2.19-11.52c0.63-0.04,1.27-0.08,1.9-0.12c0.74,2.13,1.34,4.33,2.26,6.38
				c0.69,1.54,1.78,2.9,2.69,4.33c1.33-1.05,2.66-2.1,4.43-3.49c0.89,5.19,0.92,10.83,2.95,15.62c2.12,5.01,0.96,9.29-0.29,13.77
				c-0.47,1.67-1.9,3.77-3.39,4.3c-2.37,0.86-5.15,0.67-7.76,0.73c-1.48,0.04-3.02-0.11-4.44-0.5c-2.66-0.74-6.93,4.87-7.98-1.8
				c-0.48-3.06-0.02-6.25-0.1-9.39c-0.03-1.11-0.36-2.22-0.61-3.33c-1.62-7.3-4.02-14.54-4.59-21.92
				C841.87,734.76,844.56,730.37,846.2,725.14z"/>
			<path onClick={() => props.onFill(235)} fill={props.fillColors[235]}  d="M751.85,360.05c-5.07-0.37-10.15-0.62-15.2-1.13c-9.87-1-19.79-1.77-29.58-3.33
				c-10.6-1.69-21.07-4.2-31.61-6.29c-1.78-0.35-3.75-0.85-5.39-0.39c-1.21,0.34-3.04,2.41-2.82,3.23
				c2.03,7.55-4.17,10.51-7.96,14.73c-0.47,0.52-2.85,0.33-3.26-0.28c-2.67-3.9-5.73-7.75-7.3-12.12c-1.29-3.6-2.46-4.83-6.27-4.23
				c-1.76,0.28-4.39-0.64-5.58-1.96c-4.08-4.52-7.69-9.45-12.25-15.21c2.29,0.44,3.49,0.57,4.62,0.9
				c12.07,3.51,23.96,7.96,36.24,10.38c16.88,3.33,34.09,4.97,51.11,7.67c5.69,0.9,11.17,3.02,16.83,4.17
				c6.12,1.24,12.35,2.01,18.53,2.98C751.91,359.47,751.88,359.76,751.85,360.05z"/>
			<path onClick={() => props.onFill(236)} fill={props.fillColors[236]}  d="M730.67,158.7c-23.3,0.56-46.09,1.11-69.63,1.67c0.63-6.3,0.83-11.74,1.81-17.02
				c0.76-4.1,5.55-5.53,8.54-2.36c5.3,5.63,11.35,6.54,18.59,5.46c3.61-0.54,7.47,0.82,11.24,1.12c3.77,0.29,7.63,0.86,11.33,0.37
				C721.81,146.72,726.19,149,730.67,158.7z"/>
			<path onClick={() => props.onFill(237)} fill={props.fillColors[237]}  d="M112.36,831.65c1.03,5.91,0.45,6.54-4.64,5.62c-10.68-1.92-10.65-1.91-15.95,7.74
				c-3.2,5.83-6.47,11.63-9.77,17.4c-2.6,4.54-6.12,4.78-9.31,0.57c-3.72-4.9-7.6-9.72-10.85-14.92c-2.84-4.54-6.2-7.14-11.73-6.47
				c-1.2,0.14-2.48-0.39-3.68-1.32c2.56,0,5.22,0.45,7.64-0.14c2.04-0.49,3.78-2.19,5.68-3.33c1.17-0.71,2.34-1.88,3.58-1.96
				C79.68,833.69,96.05,832.69,112.36,831.65z"/>
			<path onClick={() => props.onFill(238)} fill={props.fillColors[238]}  d="M907.51,821.35c0.51,10.65-7.31,20.2-14.96,27.91c3.58,9.3,6.74,17.24,9.62,25.28
				c0.5,1.39-0.04,3.24-0.37,4.82c-0.63,3.05-0.81,6.47-2.38,8.98c-1.46,2.34-4.26,4.63-6.87,5.26c-2.85,0.69-6.17-0.56-9.31-0.97
				c0-0.72-0.2-1.37,0.03-1.64c6.45-7.38,4.64-15.6,2.77-24c-1.19-5.33-0.07-10.85-2.91-16.26c-1.27-2.41,0.66-8.14,3-10.51
				C893.23,833.04,901.25,826.76,907.51,821.35z"/>
			<path onClick={() => props.onFill(239)} fill={props.fillColors[239]}  d="M945.08,752.84c0.41-2.45,0.83-4.89,1.22-7.34c0.05-0.32-0.17-0.69-0.08-0.98
				c2.22-6.93,4.46-13.85,7.11-22.03c3.34,7.28,2.08,14.57,8.12,19.14c-0.78,1.15-1.57,2.32-2.36,3.49c0.37-0.09,0.74-0.18,1.11-0.28
				c3.13,12.08,6.27,24.17,9.41,36.29c-3.24,0.29-5.8,0.65-8.37,0.72c-3.38,0.09-6.11-0.78-7.1-4.7c-0.47-1.86-1.6-3.68-2.85-5.18
				c-2.43-2.94-5.19-3.42-7.95-0.23c-2.05,2.37-4.14,4.71-6.39,7.27c0.69-10.75,1.42-12.43,8.07-21.91c0.63-0.9-0.43-3-0.71-4.54
				C944.56,752.66,944.82,752.75,945.08,752.84z"/>
			<path onClick={() => props.onFill(240)} fill={props.fillColors[240]}  d="M519.24,642.49c-6.68,1.36-13.27,3.36-19.97,3.81c-3.42,0.23-7.04-2.24-10.53-3.6
				c-5.81-2.27-11.59-4.64-17.38-6.95c-0.15-0.06-0.36,0.03-0.49-0.05c-6.21-3.54-13.17-3.14-19.91-4.13
				c-3.15-0.46-5.92-3.22-8.95-4.75c-7.6-3.83-15.27-7.52-22.87-11.37c-1.14-0.58-1.99-1.71-3.95-3.46
				c6.2,0.77,11.13,1.39,16.06,2.01c0.33,0.04,0.72,0.02,0.97,0.19c5,3.29,9.38,5.14,14.51-0.73c2.45-2.81,6.24,0.13,9.06,2.41
				c6.63,5.36,12.88,11.21,21.98,12.66c4.6,0.73,8.88,3.33,13.36,4.93c1.15,0.41,2.56,0.06,3.78,0.37c1.12,0.29,2.64,0.73,3.09,1.58
				c2.73,5.08,6.05,6.26,12.37,5.42C513.18,640.46,516.23,641.88,519.24,642.49z"/>
			<path onClick={() => props.onFill(241)} fill={props.fillColors[241]}  d="M130.21,413.05c4.06,0.6,8.11,1.2,12.17,1.8c0.14-0.49,0.29-0.97,0.43-1.46
				c-3.49-3.76-8.22-5.61-13.13-6.44c-9.67-1.63-17.84-6.09-25.31-12.14c-1.08-0.87-2.04-1.88-3.43-3.18
				c9.54,0.77,17.77,2.71,24.96,8.63c3.89,3.19,9.24,4.52,13.62,7.2c6.18,3.8,12.02,8.17,18.11,12.13c2.9,1.88,5.92,4.37,9.12,4.81
				c6.4,0.89,11.51,3.78,16.56,7.41c1.5,1.08,3.83,1.02,5.78,1.49c-0.4-1.95-0.55-4.01-1.28-5.83c-0.76-1.91-2.09-3.59-3.62-6.13
				c10.43,2.6,19.89,4.32,24.87,14.61c1.76,3.64,5.77,6.19,9.61,10.12c-2.92-0.68-5.01-0.68-6.56-1.6
				c-11.44-6.71-24.81-7.23-36.88-11.76c-15.22-5.71-30.23-11.97-45.33-18C129.98,414.17,130.09,413.61,130.21,413.05z"/>
			<path onClick={() => props.onFill(242)} fill={props.fillColors[242]}  d="M179.23,1095c-10.54-3.39-21.14-6.6-31.6-10.21c-15.06-5.19-30.29-10.03-44.95-16.18
				c-16.15-6.77-26.6-18.96-29.39-36.85c-2.33-14.91,2.59-27.09,13.93-37.1c2.76,1.09,2.64,2.23,1.38,4.79
				c-3.29,6.65-6.79,13.41-8.6,20.53c-1.14,4.46,0.26,9.74,1.21,14.51c0.56,2.84,2.84,5.3,3.69,8.13
				c2.79,9.29,10.01,14.24,17.85,18.35c11.75,6.17,23.57,12.28,35.68,17.69c9.33,4.17,19.18,7.19,28.77,10.77
				c4.12,1.54,8.2,3.21,12.29,4.83C179.4,1094.51,179.31,1094.75,179.23,1095z"/>
			<path onClick={() => props.onFill(243)} fill={props.fillColors[243]}  d="M585.43,442.51c-0.81,4.7-1.53,8.89-2.17,12.62c-11.2,0.85-21.99,1.32-32.68,2.67
				c-3.67,0.46-7.04,3.13-10.59,4.7c-1.87,0.83-3.85,2.1-5.75,2.05c-4.05-0.09-8.09-1.39-12.11-1.29c-3.86,0.09-7.69,1.33-11.56,2.07
				c1.53-8.02,7.9-13.82,17.38-14.96c3.96-0.48,8.09,0.55,12.14,0.52c2.52-0.01,6.04,0.2,7.38-1.27c4.54-4.99,10.41-5.54,16.29-6.25
				c5.37-0.64,10.79-0.88,16.19-1.19C581.53,442.09,583.15,442.36,585.43,442.51z"/>
			<path onClick={() => props.onFill(244)} fill={props.fillColors[244]}  d="M660.13,1015.59c4.62-0.91,9.28-0.83,12.85-2.78c4.3-2.35,9.2-5.72,11.07-9.89
				c2.43-5.42,7.32-7.28,10.78-11.01c2.67-2.87,5.87-5.23,9.43-8.32c-0.68,2.61-1.77,4.95-1.77,7.29c0,2.52,1.02,5.05,1.6,7.57
				c2.36-1.15,5.33-1.76,6.92-3.59c1.98-2.27,3.58-5.44,3.97-8.4c0.72-5.49,3.49-6.1,8.53-4.58c-0.51,1.39-0.84,2.75-1.48,3.94
				c-2.82,5.23-7.78,8.97-9.84,14.87c-0.76,2.18-3.45,3.85-5.54,5.37c-6.09,4.42-12.28,8.69-18.52,12.9
				c-2.53,1.71-5.1,3.55-7.93,4.55C671.55,1026.55,665.7,1024.21,660.13,1015.59z"/>
			<path onClick={() => props.onFill(245)} fill={props.fillColors[245]}  d="M453.03,662.26c8.19-1.79,13.14-11.26,23.51-8.59c2.7,0.69,6.29-2.29,9.53-3.39
				c2.21-0.75,4.51-1.43,6.82-1.67c4.68-0.49,9.43-0.38,14.08-1.03c5.63-0.79,11.21-3.08,16.75-2.91c4.29,0.13,8.44,3.24,12.78,4.64
				c1.91,0.62,4.13,0.25,6.2,0.42c3.59,0.3,7.18,0.64,10.75,1.12c0.18,0.02,0.45,1.82,0.1,2.47c-0.29,0.54-1.62,1.07-2.19,0.85
				c-7.29-2.94-12.59,3.55-18.97,3.88c-3.67,0.19-7.3,1.07-10.65,1.07c-4.24,0-7.02,1.58-9.73,3.81c-2.33,1.92-3.75,1.7-5.92-0.19
				c-3.8-3.3-7.61-6.97-12.09-8.95c-2.23-0.99-5.95,1.42-8.99,2.27c-2.55,0.71-5.17,1.22-7.64,2.15c-3.17,1.2-6.12,3-9.32,4.08
				c-3.93,1.31-7.99,2.27-12.05,3.07c-0.81,0.16-1.9-1.09-2.86-1.68C453.1,663.19,453.07,662.73,453.03,662.26z"/>
			<path onClick={() => props.onFill(246)} fill={props.fillColors[246]}  d="M943.8,732.96c0,4.87,1.03,10.03-0.24,14.54c-1.87,6.63-5.3,12.84-8.32,19.12
				c-0.38,0.79-2.26,1.24-3.43,1.2c-3.31-0.11-5.64,0.52-4.68,4.23c-4.11,0.24-7.84,0.47-11.38,0.67
				c-5.17-13.57-1.91-26.57,2.27-39.67c0.98,3.59,1.69,7.27,3.05,10.71c0.6,1.52,2.49,2.54,3.8,3.78c0.88-1.55,2-3.01,2.61-4.66
				c1.36-3.71,2.47-7.52,3.68-11.28c0.48,0.02,0.97,0.04,1.45,0.07c1.4,2.73,2.7,5.53,4.26,8.16c0.74,1.24,2.01,2.16,3.03,3.22
				c0.98-1.24,2.46-2.35,2.8-3.75c0.48-1.96,0.12-4.14,0.12-6.22C943.16,733.03,943.48,732.99,943.8,732.96z"/>
			<path onClick={() => props.onFill(247)} fill={props.fillColors[247]}  d="M647.86,195.56c0,11.24,0.5,22.52-0.22,33.71c-0.34,5.31-2.89,10.51-4.74,15.66
				c-0.42,1.17-1.95,2.17-3.19,2.78c-7.09,3.44-14.24,6.74-22.33,10.53c3.04-7.17,6.01-13.98,8.84-20.86
				c3.03-7.36,5.52-14.97,8.99-22.11c3.36-6.91,7.66-13.35,11.55-20C647.13,195.36,647.5,195.46,647.86,195.56z"/>
			<path onClick={() => props.onFill(248)} fill={props.fillColors[248]}  d="M465.08,383.38c-1.71-4.51-7.61-4.92-10.74-1.19c-3.34,3.98-6.78,7.89-10.18,11.83
				c-0.54-0.09-1.07-0.17-1.61-0.26c0.51-6.55,0.74-13.14,1.73-19.61c0.24-1.58,2.83-3.61,4.64-3.99c12.74-2.7,25.52-5.75,38.71-3.93
				c4.01,0.55,7.86,1.78,8.75,6.73c0.93,5.12-1.67,8.89-5.81,10.4c-2.91,1.06-7.64,0.86-9.96-0.9
				C473.53,377.11,471.73,376.76,465.08,383.38z"/>
			<path onClick={() => props.onFill(249)} fill={props.fillColors[249]}  d="M203.68,830.52c6.55-2.51,14.15,0.55,20.26-4.99c-8.79-4.17-18.38-2.92-27.71-4.48
				c3.81-0.58,7.61-1.48,11.44-1.67c16.6-0.82,33.21-1.42,49.82-2.12c3.77-0.16,7.1-0.26,6.7,5.3c-0.09,1.22,2.42,2.73,3.86,3.94
				c1.63,1.37,3.42,2.56,4.87,4.46C249.65,832.55,226.66,834.12,203.68,830.52z"/>
			<path onClick={() => props.onFill(250)} fill={props.fillColors[250]}  d="M217.54,386.85c2.93-8.42,4.35-17.24,12.66-22.06c7.29-4.22,11.05-11.19,14.19-18.74
				c1.05-2.52,3.2-5.28,5.57-6.38c4.47-2.07,9.42-3.21,14.26-4.35c1.91-0.45,4.06,0.12,6.11,0.22c0.26,0.5,0.53,1.01,0.79,1.51
				c-3.39,3.92-6.8,7.82-10.17,11.76c-1.29,1.51-3.01,2.89-3.65,4.66c-3.39,9.35-11.13,14.13-18.9,19.11
				c-5.23,3.35-10.01,7.41-15.08,11.03C221.52,384.88,219.47,385.78,217.54,386.85z"/>
			<path onClick={() => props.onFill(251)} fill={props.fillColors[251]}  d="M505.64,380.96c0.09,1.61-0.06,2.93,0.29,4.09c0.31,1.01,1.03,2.34,1.87,2.6
				c0.72,0.23,1.98-0.69,2.78-1.37c5.86-4.99,11.67-10.05,17.48-15.07c4.11,3.56,10.45,0.51,20.74-9.92
				c-0.57,5.36-1.44,10.07-7.79,11.76c-2.06,0.55-3.82,3.07-5.21,5.05c-2.19,3.12-4.01,6.5-5.87,9.83
				c-3.39,6.06-7.29,11.24-14.87,12.59c-4.42,0.79-6.73,4.45-8.22,8.61c-1.13,3.17-2.56,6.24-3.86,9.36
				c-0.44-0.17-0.88-0.34-1.32-0.52c0.93-2.7,2.27-5.35,2.67-8.13c0.32-2.23,0.31-5.44-1.01-6.73c-1.29-1.27-4.41-0.76-6.73-0.84
				c-1.23-0.04-2.47,0.34-5.08,0.74C496.58,395.1,500.99,388.21,505.64,380.96z"/>
			<path onClick={() => props.onFill(252)} fill={props.fillColors[252]}  d="M307.7,336.53c-5.83,11.02-16.47,16.64-26.21,23.28c-0.61,0.42-1.54,0.6-2.29,0.53
				c-6.94-0.62-13.87-1.32-20.71-1.98C262.82,340.4,293.27,326.59,307.7,336.53z"/>
			<path onClick={() => props.onFill(253)} fill={props.fillColors[253]}  d="M327.68,387.97c0.37-2.43,0.91-4.85,1.03-7.29c0.06-1.17-0.37-2.97-1.16-3.41
				c-0.77-0.43-2.71,0.15-3.42,0.93c-2.68,2.93-5.01,6.19-7.61,9.21c-1.17,1.36-2.66,2.46-4.65,4.26c-0.5-2.75-0.79-4.3-1.15-6.28
				c-6.57,0.6-6.6,10.74-15.14,9.19c1.03-2.75,1.56-5.49,2.97-7.64c3-4.58,6.45-8.88,9.75-13.26c3.56-4.73,10.84-5.96,15.5-2.2
				c2.29,1.85,3.75,1.6,6.14,0.33c2.34-1.24,5.22-1.47,8.03-2.19c0.52,1.74,0.88,2.95,1.3,4.35c5.94-0.8,9.26-8.09,16.69-6.18
				c-2.47,5.67-0.16,12.88-7.36,15.99c-1.35,0.58-3.13,1.15-4.39,0.74c-3.64-1.18-6.29-0.16-9.1,2.22c-1.67,1.41-4.2,1.81-6.35,2.66
				C328.4,388.91,328.04,388.44,327.68,387.97z"/>
			<path onClick={() => props.onFill(254)} fill={props.fillColors[254]}  d="M137.86,1074.1c7.08-0.6,14.15-1.36,21.23-1.62c0.95-0.03,2.82,2.44,2.85,3.79
				c0.18,6.74,0.35,7.94,7.9,8.24c7.77,0.31,15.5,1.64,23.26,2.36c1.76,0.16,3.64-0.12,5.36-0.6c4.95-1.39,9.94-2.74,14.74-4.55
				c9.27-3.51,18.42-7.32,27.62-11.02c0.46-0.18,1.21-0.42,1.25-0.73c0.98-6.44,6.31-4.21,10.04-5.05c1.77-0.4,3.64-0.4,5.4-0.85
				c8.92-2.26,8.91-2.29,8.2,7.79c-9.42,1.72-19.2,2.79-28.56,5.39c-14.84,4.13-29.44,9.12-44.02,14.11
				c-4.15,1.42-7.88,1.93-12.03,0.41c-12.12-4.44-24.31-8.66-36.44-13.08c-2.44-0.89-4.7-2.28-7.05-3.43
				C137.7,1074.87,137.78,1074.49,137.86,1074.1z"/>
			<path onClick={() => props.onFill(255)} fill={props.fillColors[255]}  d="M806.97,257.25c11.5,15,25.68,26.78,28.72,46.55c-1.68-0.89-3.01-1.35-4.05-2.18
				c-5.64-4.52-12.13-5.68-19.17-5.61c-2.03,0.02-4.16-1.65-6.08-2.8c-0.94-0.57-1.44-1.81-2.25-2.63c-4.98-5.01-5.88-8.94-1.34-14
				c4.46-4.98,5.39-10,3.72-16.05C806.37,259.97,806.67,259.28,806.97,257.25z"/>
			<path onClick={() => props.onFill(256)} fill={props.fillColors[256]}  d="M276.09,1053.3c4.71-14.46,5.26-29.74,15.22-42.58c0.97,1.82,1.75,2.86,2.1,4.03
				c2.28,7.56,6.34,14.49,5.45,23.1c-0.58,5.57,0.99,11.52,2.39,17.1c1.14,4.57-2.14,4.57-4.5,5.51c-2.52,1.01-4.36,0.66-4.84-2.8
				c-0.16-1.11-2.37-2.44-3.84-2.75C284.37,1054.11,280.57,1053.85,276.09,1053.3z"/>
			<path onClick={() => props.onFill(257)} fill={props.fillColors[257]}  d="M687.87,1097.92c-1.91,3.62-3.79,7.82-6.26,11.65c-1.36,2.11-3.01,1.77-4.43-0.8
				c-4.25-7.67-8.93-15.09-13.21-22.73c-3.24-5.78-6.75-11.53-8.92-17.72c-1.01-2.89,0.69-6.85,1.66-10.18
				c0.12-0.43,4.22-0.69,4.85,0.17c8.27,11.22,16.28,22.63,24.25,34.07C686.71,1093.68,686.96,1095.43,687.87,1097.92z"/>
			<path onClick={() => props.onFill(258)} fill={props.fillColors[258]}  d="M475.04,511.8c2.62-1.52,5.34-4.49,7.83-4.3c11.04,0.82,21.06-4.34,31.77-4.67
				c5.15-0.16,10.33,0.95,15.5,1.5c1.12,0.12,2.3,0.62,3.35,0.42c10.38-1.98,20.67-4.56,31.12-5.94c5.26-0.7,10.81,0.63,16.21,1.19
				c2.77,0.29,5.5,0.9,8.34,1.38c0.28,4.71-3.31,7.88-9.24,5.84c-12.76-4.37-25-0.96-37.38,1.01c-4.54,0.72-8.97,2.06-13.47,3.06
				c-0.59,0.13-1.36,0.09-1.89-0.17c-9.98-5.03-19.85-0.89-29.73,0.36c-7.12,0.9-14.2,2.12-21.29,3.2
				C475.78,513.72,475.41,512.76,475.04,511.8z"/>
			<path onClick={() => props.onFill(259)} fill={props.fillColors[259]}  d="M518.57,304.62c-11.93-0.97-23.61-2.48-35.31-2.69c-9.57-0.17-19.46,0.02-28.68,2.24
				c-8,1.93-16.96,3.41-22.82,11.14c0.11-10.02,4.45-13.1,13.65-16.72c9.28-3.65,18.53-4.89,28.05-4.89c11.7,0,23.44,0.66,35.09,1.76
				C514.66,296.05,518.03,300.13,518.57,304.62z"/>
			<path onClick={() => props.onFill(260)} fill={props.fillColors[260]}  d="M313.64,1093.93c-0.96-4.49-1.71-8.93-2.94-13.24c-0.44-1.53-2.05-2.72-3.12-4.06
				c-0.91,1.23-2.11,2.34-2.68,3.71c-1.21,2.86-2.18,5.82-3.07,8.8c-0.57,1.9-0.83,3.9-1.38,6.64c-3.53-0.35-7.59-0.67-11.62-1.23
				c-0.69-0.1-1.42-1.13-1.82-1.9c-1.3-2.47-2.66-4.93-3.6-7.55c-1.42-3.95,2.3-8.05,7.45-9.02c5.25-0.98,10.4-2.47,15.63-3.57
				c1.64-0.35,3.41-0.05,5.08-0.29c1.42-0.2,2.77-0.8,4.17-1.12c1.67-0.39,4.28-1.52,4.91-0.85c3.61,3.87,6.99,8.03,9.9,12.45
				c0.7,1.06-0.67,3.5-1.16,5.29c-0.06,0.21-0.56,0.31-0.87,0.43C323.76,1090.18,319.02,1091.93,313.64,1093.93z"/>
			<path onClick={() => props.onFill(261)} fill={props.fillColors[261]}  d="M201.29,854.02c-18.06,0.69-35.48,1.33-52.89,2.02c-9.78,0.39-10.82-0.32-14.19-10.62
				c3.99-0.33,7.87-0.81,11.76-0.95c13.12-0.47,26.23-0.89,39.35-1.2C193.88,843.07,196.56,844.89,201.29,854.02z"/>
			<path onClick={() => props.onFill(262)} fill={props.fillColors[262]}  d="M746.42,1006.01c0.84-4.3,1.28-7.64,2.21-10.83c0.54-1.83,1.58-3.74,2.94-5.05
				c3.88-3.76,8.07-7.21,12.07-10.84c0.66-0.6,1.61-1.82,1.4-2.28c-1.93-4.27-1.76-9.56-7.07-12.15
				c-7.88-3.85-15.47-8.32-23.19-12.51c-0.97-0.53-1.98-0.96-2.68-2.45c2.98,0.66,6.07,1,8.91,2.04c5.42,1.98,10.72,4.29,16.02,6.59
				c10.19,4.44,15.17,13.05,17.8,23.22c1.3,5.03-0.29,9.92-4.45,12.97c-5.25,3.85-10.94,7.14-16.71,10.18
				C751.55,1006,748.68,1005.7,746.42,1006.01z"/>
			<path onClick={() => props.onFill(263)} fill={props.fillColors[263]}  d="M63.06,982.04c1.75-7.42-3.59-11.67-7.19-16.55c-3.41-4.62-8.29-5.7-14.04-5.69
				c-8,0.01-16.01-1.16-24.01-1.86c-4.02-0.35-8.05-0.62-12.03-1.22c-4.32-0.65-3.06-4.23-2.82-6.75c0.3-3.2,2.62-2.59,4.89-1.89
				c1.71,0.53,3.72,0.27,5.28,1.01c4.4,2.11,7.61,2.55,12.78-0.32c6.72-3.73,15.4-4.07,22.61,0.93c4.95,3.44,9.88,7.1,14.06,11.41
				C68.37,967.09,68.26,975.45,63.06,982.04z"/>
			<path onClick={() => props.onFill(264)} fill={props.fillColors[264]}  d="M691.55,1095.22c-9.05-12.69-18-24.99-26.64-37.5c-4.21-6.09-3.42-8.93,3.34-13.99
				c6.49,7.33,13.12,14.66,19.56,22.15c2.7,3.13,5.14,6.51,7.48,9.93C700.02,1082.7,698.33,1090.56,691.55,1095.22z"/>
			<path onClick={() => props.onFill(265)} fill={props.fillColors[265]}  d="M807.33,255.23c-2.6-0.13-5.2-0.27-7.8-0.39c-14.42-0.7-28.67,0.63-43.01,2.4
				c-8.71,1.07-17.78-0.86-26.69-1.48c-1.65-0.12-3.27-0.6-4.91-0.64c-8.62-0.18-17.26-0.51-25.87-0.27
				c-3.85,0.11-7.66,1.44-11.5,2.18c-1.11,0.22-2.23,0.47-3.36,0.49c-4.02,0.08-8.04-0.05-12.05,0.12
				c-8.02,0.34-14.87,3.42-20.72,8.94c-0.58,0.55-2.45,0.66-2.89,0.19c-0.57-0.6-0.76-2.29-0.31-3c1.05-1.65,2.33-3.76,3.97-4.32
				c9.61-3.27,18.71-9.3,29.7-5.99c1.27,0.38,2.86-0.25,4.28-0.49c4.31-0.72,8.59-1.94,12.91-2.11c6.46-0.26,12.96,0.06,19.42,0.41
				c10.14,0.55,20.27,1.87,30.4,1.84c9.37-0.02,18.72-1.7,28.11-2.05c9.09-0.34,18.22-0.02,27.32,0.3c1.25,0.04,2.44,1.7,3.66,2.62
				C807.77,254.4,807.55,254.81,807.33,255.23z"/>
			<path onClick={() => props.onFill(266)} fill={props.fillColors[266]}  d="M515.99,315.93c-2.94,0.94-5.92,2.78-8.81,2.65c-7.19-0.34-13.33,1.66-19.41,6.98
				c-3.83-4-7.48-7.8-12.16-12.68c0,3.97,0,6.79,0,10.1c-6.66-0.03-13.82-4.69-18.06,2.78c-5.39,0-10.28,0.22-15.12-0.13
				c-1.48-0.11-3.48-1.48-4.06-2.8c-0.4-0.9,0.9-3.51,2.02-3.99c8.76-3.74,17.67-7.58,27.37-7.39c15.3,0.3,30.59,1.25,45.88,1.96
				c0.61,0.03,1.21,0.41,1.81,0.62C515.63,314.66,515.81,315.29,515.99,315.93z"/>
			<path onClick={() => props.onFill(267)} fill={props.fillColors[267]}  d="M120.74,377.85c5.47,1.42,9.81-0.45,14.56-2.45c2.87-1.21,6.8-0.04,10.24,0.28
				c8.56,0.8,17.12,1.71,25.67,2.61c0.61,0.06,1.19,0.43,2.76,1.03c-1.9,3.18-3.52,6.14-5.4,8.93c-0.91,1.36-2.36,2.34-3.45,3.59
				c-6.2,7.11-6.24,7.22-14.85,2.93c-7.87-3.92-15.67-8.02-23.39-12.23C124.64,381.33,122.78,379.43,120.74,377.85z"/>
			<path onClick={() => props.onFill(268)} fill={props.fillColors[268]}  d="M373.64,305.64c0.87,2.61,1.97,4.41,1.97,6.22c0.02,6.4,0.36,12.93-0.73,19.18
				c-1.12,6.42,2.88,10.53,4.73,15.69c2.34,6.53,3.7,13.41,5.93,21.81c-8.87-1.71-16.16-3.12-23.45-4.53
				c1.9-9.27,4.23-18.57,5.59-28.01c0.87-6.01,0.38-12.23,0.37-18.35C368.04,313.09,369.82,309.49,373.64,305.64z"/>
			<path onClick={() => props.onFill(269)} fill={props.fillColors[269]}  d="M876.97,302.03c3.81,3.14,9.39,3.3,12.41,8.34c0.92,1.53,6.14,0.85,9.27,0.38
				c0.65-0.1,0.77-3.76,1.16-5.98c6.21,2.25,13.98,2.13,18.83,8.69c0.31,0.43,1.35,0.77,1.8,0.57c6.04-2.69,10.21,2.48,17.05,3.51
				c-6.5,5.08-11.83,9.43-17.4,13.46c-1.14,0.83-3.24,0.95-4.7,0.6c-8.68-2.12-17.44-4.09-25.91-6.91c-5.11-1.7-9.75-4.82-15.44-7.74
				c7.33,1.99,13.8,3.77,20.28,5.5c3.68,0.98,7.35,2.03,11.08,2.74c1.05,0.2,2.31-0.69,3.47-1.08c-0.42-1.18-0.51-3.01-1.33-3.43
				c-5.23-2.7-10.42-6.14-16.04-7.25c-4.38-0.87-7.24-2.37-9.65-5.78c-1.43-2.02-3.21-3.79-4.84-5.67L876.97,302.03z"/>
			<path onClick={() => props.onFill(270)} fill={props.fillColors[270]}  d="M279.65,401.49c-1.84-5.94-6.38-4.83-10.45-4.73c-6.34,0.15-6.62-0.23-5.29-6.32
				c1.78-8.14,6.67-14.49,12.28-20.13c3.22-3.23,7.81-3.89,12.48-2.01c5.04,2.04,10.22,3.75,14.83,5.42
				c-0.52,0.22-2.19,1.24-3.99,1.68c-7.72,1.87-11.3,7.14-12.03,14.63C286.99,395.1,284.59,398.68,279.65,401.49z"/>
			<path onClick={() => props.onFill(271)} fill={props.fillColors[271]}  d="M299.98,257.8c-1.76-3.67-3.47-6.71-4.65-9.95c-0.43-1.18-0.1-3.49,0.76-4.14
				c3.1-2.36,6.65-4.13,9.88-6.33c2.47-1.68,4.8-3.6,7.04-5.59c1.67-1.5,2.81-3.73,4.66-4.87c6.87-4.21,11.56-1.59,12.01,6.43
				c0.02,0.33,0.02,0.66,0.03,1c0.38,14.13,0.33,13.92-13.55,17.47C310.65,253.23,305.42,255.75,299.98,257.8z"/>
			<path onClick={() => props.onFill(272)} fill={props.fillColors[272]}  d="M813.29,835.95c0.51,3.18,1.05,6.35,1.5,9.54c0.3,2.14,0.31,4.32,0.71,6.43c1.32,6.95,2.5,7.76,9.49,8.36
				c11.67,1,22.51,4.06,30.94,12.98c3.16,3.34,3.79,6.86,0.34,9.93c-1.96,1.74-5.06,2.14-7.52,3.39c-3.78,1.92-4.64-1.54-6.62-3.22
				c-8.97-7.61-17.63-15.69-27.26-22.36c-5.37-3.72-7.64-7.49-6.44-13.52c0.77-3.86,1.95-7.65,2.94-11.47
				C812.01,835.99,812.65,835.97,813.29,835.95z"/>
			<path onClick={() => props.onFill(273)} fill={props.fillColors[273]}  d="M785.34,619.03c3.03-0.28,6.23-0.04,9.03-0.99c2.8-0.95,5.2-4.48,7.76-4.46
				c12.26,0.08,24.52,0.99,36.78,1.57c1.32,0.06,2.7,0.09,3.97-0.21c3.54-0.82,6.34-0.22,8.62,2.98c0.78,1.09,2.82,1.27,4.27,1.89
				c1.6,0.69,3.19,1.43,3.88,1.74c-2.95,3.08-5.37,5.6-8.35,8.7c0.23,0.14-0.58-0.57-1.52-0.91c-2.39-0.86-5.4-2.85-7.18-2.09
				c-6.38,2.74-11.81,1.23-17.55-1.63c-2.44-1.22-5.52-1.1-8.28-1.75c-5.38-1.27-10.67-3.12-16.11-3.84
				c-4.98-0.66-10.12-0.14-15.18-0.14C785.43,619.61,785.39,619.32,785.34,619.03z"/>
			<path onClick={() => props.onFill(274)} fill={props.fillColors[274]}  d="M674.81,1111.56c-1.85,3.87-3.61,8.55-6.22,12.7c-1.85,2.95-3.71,1.63-4.87-1.26
				c-3.03-7.53-6.39-14.92-9.28-22.5c-3.08-8.06-6.08-16.18-8.42-24.48c-0.64-2.28,1.15-5.3,2.11-7.88c0.08-0.21,3.03-0.04,3.45,0.67
				c7.29,12.27,14.43,24.63,21.5,37.02C673.88,1107.22,674.06,1108.97,674.81,1111.56z"/>
			<path onClick={() => props.onFill(275)} fill={props.fillColors[275]}  d="M460.07,539.81c6.35,0,12.75,0.53,19.02-0.16c5.84-0.64,11.51-2.78,17.32-3.88
				c3.56-0.68,7.28-1.05,10.87-0.79c2.61,0.19,7.09,1.31,7.32,2.61c0.71,4.12,3.78,8.54,0.27,12.8c-0.63,0.76-1.33,1.46-2.1,2.3
				c-9.1-4.19-18.24-1.13-27.39-0.47c-1.91,0.14-3.97-1.01-5.87-1.79c-1.19-0.49-2.14-1.54-3.32-2.09
				c-5.4-2.51-10.84-4.94-16.27-7.39C459.98,540.57,460.02,540.19,460.07,539.81z"/>
			<path onClick={() => props.onFill(276)} fill={props.fillColors[276]}  d="M304.21,1001.06c2.58,4.99,5.45,9.61,7.38,14.6c1.18,3.05,0.85,6.67,1.28,10.02
				c0.95,7.45,2.38,14.87,2.78,22.34c0.32,5.93-5.17,9.1-10.49,6.54c-0.88-0.42-1.55-2.05-1.66-3.19
				c-0.59-6.37-0.52-12.84-1.58-19.13c-0.97-5.74-2.66-11.46-4.79-16.89C294.39,1008.39,296.19,1003.76,304.21,1001.06z"/>
			<path onClick={() => props.onFill(277)} fill={props.fillColors[277]}  d="M586.47,231.67c-4.69,2.84-8.33,4.81-11.69,7.19c-1.33,0.95-2.4,2.66-2.95,4.25
				c-2.2,6.35-4.15,12.79-6.2,19.2c-1,3.14-2,6.29-3.21,10.1c-1.31,0.17-3.18,0.42-5.98,0.8c0.9-3.13,1.2-5.8,2.38-8
				c5.64-10.5,6.36-21.5,5.36-33.21c-0.6-7-2.43-14.55,1.64-21.45c1.83-3.1,6.9-4.77,9.65-2.61c0.69,0.54,1.13,1.97,0.99,2.88
				c-0.3,1.95-1.02,3.84-1.61,5.74c-1.84,5.88-1.68,5.44,4.13,7.64C581.51,225.16,583.21,228.29,586.47,231.67z"/>
			<path onClick={() => props.onFill(278)} fill={props.fillColors[278]}  d="M274.85,453.09c-7.36-3.74-15.95-2.84-21.51-9.5c-0.91-1.09-3-1.23-4.57-1.73
				c-4.77-1.54-9.56-3.05-14.34-4.57c-0.03-0.77-0.05-1.53-0.08-2.3c4.56-1.48,9.03-3.36,13.7-4.34c4.44-0.93,9.07-0.92,14.42-1.39
				c-0.27,2.79-0.77,4.29-0.46,5.59c0.41,1.73,1.44,3.3,2.21,4.94c1.68-0.83,3.94-1.23,4.9-2.57c1.72-2.38,2.61-5.34,3.92-8.03
				c1.78-3.66,6.3-6,8.62-4.29c3.45,2.54,1.6,5.36,0.36,8.4C279.49,439.56,277.39,445.99,274.85,453.09z"/>
			<path onClick={() => props.onFill(279)} fill={props.fillColors[279]}  d="M379.62,315.77c6.27,1.16,12.16,2.1,17.96,3.42c1.18,0.27,2.93,1.96,2.92,2.98
				c-0.1,7.4-0.53,14.79-1,22.18c-0.1,1.58-0.8,3.13-1.12,4.34c-3.9,0.26-7.32,0.55-10.76,0.69c-0.9,0.04-2.36-0.09-2.65-0.63
				c-2.51-4.68-6.12-9.29-6.85-14.29C377.27,328.63,378.97,322.41,379.62,315.77z"/>
			<path onClick={() => props.onFill(280)} fill={props.fillColors[280]}  d="M641.64,1074.53c0.53,0.94,1.17,1.84,1.58,2.83c4.86,11.97,9.1,24.23,14.69,35.84
				c4.42,9.18,2.34,16.96-2.08,24.95c-0.4,0.71-0.97,1.35-1.56,1.93c-0.33,0.32-0.86,0.43-1.84,0.88c-0.91-1.71-2.22-3.28-2.64-5.07
				c-2.99-12.74-5.86-25.5-8.63-38.29c-1.05-4.86-2.03-9.77-2.49-14.71c-0.25-2.7,0.63-5.51,1.01-8.27
				C640.34,1074.59,640.99,1074.56,641.64,1074.53z"/>
			<path onClick={() => props.onFill(281)} fill={props.fillColors[281]}  d="M363.18,233.71c5.92,6.25,10.39,10.96,15.88,16.76c-4.28,0.77-7.95,1.74-11.68,2.03
				c-11.21,0.88-22.51,0.91-33.63,2.41c-8.44,1.14-16.62,4.1-24.96,6.11c-2.5,0.6-5.11,0.72-7.67,1.06c-0.13-0.45-0.25-0.9-0.38-1.35
				c5.48-2.14,10.82-4.76,16.47-6.28c5.96-1.61,12.13-2.31,15.87-9.1c5.11,4.69,10.13,1.15,15.06-0.46c2.12-0.69,4.02-2.79,5.45-4.68
				C356.7,236.13,360.18,233.23,363.18,233.71z"/>
			<path onClick={() => props.onFill(282)} fill={props.fillColors[282]}  d="M872.38,781.6c2.43-13.77,4.75-26.95,7.07-40.13c0.42,0.01,0.83,0.02,1.25,0.03
				c0.9,2.89,1.8,5.78,3.03,9.75c1.73-2.12,2.94-3.61,5.08-6.23c2.34,12.53,4.53,24.26,6.74,36.13
				C887.74,784.74,879.99,783.15,872.38,781.6z"/>
			<path onClick={() => props.onFill(283)} fill={props.fillColors[283]}  d="M880.26,451.67c-2.82,4.15-5.54,8.37-8.48,12.43c-5.19,7.16-12.19,11.84-20.31,15.22
				c-2.26,0.94-3.75,3.66-5.71,5.44c-2.87,2.6-5.85,5.09-8.78,7.63c-0.43-0.34-0.86-0.67-1.28-1.01c1.64-3.27,3.33-6.51,4.92-9.8
				c3.01-6.25,5.9-12.55,8.95-18.78c2.17-4.44,4.91-8.16,10.42-8.95c6.35-0.9,12.65-2.22,18.97-3.36
				C879.39,450.89,879.82,451.28,880.26,451.67z"/>
			<path onClick={() => props.onFill(284)} fill={props.fillColors[284]}  d="M661.06,962.21c-0.94,1.95-1.88,3.91-2.94,6.11c4.73,6.03,4.73,6.06-3.2,14.12
				c6.16,5.29,6.16,5.29-1.05,12.15c1.36,2.04,4.19,5.24,3.74,5.78c-2.27,2.82-5.26,5.12-8.25,7.27c-2.26,1.62-4.8,2.91-7.37,4.01
				c-1.35,0.58-3.3,1-4.46,0.44c-1.57-0.77-3.68-2.5-3.76-3.91c-0.33-6.22,0.5-12.23,5.6-16.84c3.45-3.11,6.55-6.6,9.84-9.89
				c4.02-4.01,6.57-8.6,6.97-14.46c0.14-2.03,1.62-3.96,2.48-5.94C659.47,961.44,660.26,961.83,661.06,962.21z"/>
			<path onClick={() => props.onFill(285)} fill={props.fillColors[285]}  d="M28.24,1063.54c-7.09-2.43-13.58-4.34-19.8-6.9c-5.43-2.24-6.18-7.2-2.36-11.83
				c0.71-0.86,1.94-1.86,1.86-2.7c-0.75-8.44,6.09-14.41,7.07-22.25c0.01-0.07,0.26-0.12,0.64-0.27c4.4,5.11,7.41,10.65,8.06,17.78
				c0.61,6.78,2.7,13.42,4.04,20.14C28.1,1059.22,28.05,1061.02,28.24,1063.54z"/>
			<path onClick={() => props.onFill(286)} fill={props.fillColors[286]}  d="M694.18,306.16c-9.45,0.96-17.99,1.83-27.93,2.84c-2.22-2.65-7.39-6.12-5.52-12.69
				c0.61-2.15,2.65-4.94,4.59-5.49c8.39-2.37,17.05-3.53,25.79-2.44c1.14,0.14,2.84,2.05,2.92,3.22
				C694.37,296.56,694.18,301.54,694.18,306.16z"/>
			<path onClick={() => props.onFill(287)} fill={props.fillColors[287]}  d="M226.08,960.86c9.41-0.53,18.81-1.08,28.22-1.6c10.78-0.6,21.6-0.89,32.34-1.89
				c6.14-0.57,7.15,4.11,10.31,8.17c-2.29,0.46-3.95,1.02-5.64,1.11c-18.92,1.03-37.84,1.94-56.76,2.98
				c-5.58,0.31-5.67-5.24-9.05-7.23C225.69,961.89,225.88,961.37,226.08,960.86z"/>
			<path onClick={() => props.onFill(288)} fill={props.fillColors[288]}  d="M806.01,408.15c-6.77-10.2-12.71-20.01-15.55-31.45c-0.88-3.54-2.43-6.91-3.65-10.29
				c6.84-1.3,14.72-2.81,23.49-4.48c-0.51,4.19-1.11,8.85-1.62,13.52c-0.28,2.61-0.54,5.23-0.53,7.85c0.01,2.46,0.78,4.96,0.52,7.36
				C808.06,396.2,807.02,401.7,806.01,408.15z"/>
			<path onClick={() => props.onFill(289)} fill={props.fillColors[289]}  d="M855.52,833.25c1.6-2.45,8.02-1.49,4.59-7.86c5.02,0.27,9.11,0.4,13.18,0.73
				c5.71,0.48,10.9-0.67,15.35-4.45c2.9-2.46,5.63-2.54,8.68,1.15c-5.89,5.9-11.83,11.82-17.73,17.78c-2.56,2.59-5.25,5.1-7.49,7.96
				c-1.69,2.17-3.62,3-5.33,1.02C862.49,844.62,856.04,841.07,855.52,833.25z"/>
			<path onClick={() => props.onFill(290)} fill={props.fillColors[290]}  d="M331.14,297.2c0,5.99,0,11.34,0,17.19c-15.5-2.15-30.97-7.86-45.28,0.52c-3.57-1.51-6.65-2.81-10.74-4.53
				c1.25-1.22,2.35-3.08,3.94-3.69c8.49-3.29,8.53-3.18,13.45-9.07c-2.07,4.37-4.14,8.75-6.21,13.12c0.54,0.53,1.08,1.07,1.62,1.6
				c3.14-2.26,6.88-4.02,9.29-6.9c4.13-4.93,8.43-7.75,15.27-7.5c2.78,0.1,5.69-3.4,8.23-5.06c-0.01,5.27-0.02,5.23,6.21,4.36
				C328.34,297.03,329.82,297.2,331.14,297.2z"/>
			<path onClick={() => props.onFill(291)} fill={props.fillColors[291]}  d="M519.58,550.47c-0.4-3.11-0.41-5.96-1.18-8.59c-1.07-3.71,0.78-5.89,3.74-6.28
				c12.62-1.66,25.29-2.9,37.95-4.25c0.41-0.04,0.87,0.33,1.55,0.61c-2.23,4.68-4.35,9.28-6.65,13.8c-0.37,0.72-1.46,1.41-2.29,1.51
				c-9.84,1.19-19.68,2.28-29.54,3.34C521.9,550.74,520.6,550.51,519.58,550.47z"/>
			<path onClick={() => props.onFill(292)} fill={props.fillColors[292]}  d="M845.34,781.59c-5.59-0.17-11.83,3.15-18.4-0.04c-2.79-1.35-3.21-2.92-1.78-5.49
				c0.95-1.71,1.96-3.51,2.34-5.39c1.63-8.28,2.98-16.62,4.56-24.91c0.61-3.17,1.56-6.28,2.39-9.57c7.4,4.67,6.53,12.31,7.69,18.9
				C843.64,763.65,844.27,772.37,845.34,781.59z"/>
			<path onClick={() => props.onFill(293)} fill={props.fillColors[293]}  d="M331.24,325.98c-0.89,3.12-1.19,5.35-2.15,7.23c-1.67,3.25-1.84,5.41,3.9,5.5
				c-3.13,3.85-5.67,7.01-8.25,10.13c-0.85,1.02-1.88,1.88-2.72,2.91c-5.84,7.16-9.61,8-18.06,4.02c-1.62-0.76-3.28-1.44-5.45-2.39
				c2.21-3,4.13-5.65,6.09-8.27c3.74-5.02,7.83-9.81,11.17-15.08C319.64,323.9,325.14,327.15,331.24,325.98z"/>
			<path onClick={() => props.onFill(294)} fill={props.fillColors[294]}  d="M819.77,965.41c3.07-0.14,5.36-0.28,7.65-0.34c2.15-0.05,4.31-0.01,6.84-0.01
				c-0.37,0.69-0.51,1.09-0.76,1.4c-0.86,1.06-1.77,2.08-2.66,3.12c1.26,0.55,2.48,1.41,3.8,1.59c3.78,0.52,7.6,0.68,11.39,1.14
				c1.75,0.21,3.45,0.79,6,1.41c-2.19,1.61-3.67,2.7-5.95,4.37c2.45,0.96,4.19,1.67,5.95,2.32c1.87,0.69,5.34,1.21,5.39,2
				c0.46,6.49,6.46,5.3,10.18,7.92c-2.24,3.47,0.15,4.58,3.03,5.73c3.46,1.37,6.79,3.08,10.02,4.94
				C861.14,1001.19,836.53,986.79,819.77,965.41z"/>
			<path onClick={() => props.onFill(295)} fill={props.fillColors[295]}  d="M637.2,1149.51c-1.22-6.92-2.83-13.49-3.47-20.15c-1.39-14.37-2.36-28.79-3.28-43.2
				c-0.14-2.27,0.9-4.61,1.39-6.91c0.72-0.03,1.45-0.06,2.17-0.09c0.68,1.81,1.57,3.57,2,5.44c3.23,14.05,6.22,28.15,9.59,42.17
				C647.49,1134.64,644.58,1143.64,637.2,1149.51z"/>
			<path onClick={() => props.onFill(296)} fill={props.fillColors[296]}  d="M831.38,410.78c2.4-7.62,4.33-14.85,7.02-21.78c0.96-2.47,3.38-5.02,5.78-6.15
				c4.77-2.24,9.98-3.56,15.04-5.17c3.22-1.03,4.61,0.23,3.55,3.48c-2.17,6.64-4.41,13.26-6.88,19.78c-0.52,1.36-1.99,2.86-3.35,3.31
				C845.7,406.56,838.75,408.54,831.38,410.78z"/>
			<path onClick={() => props.onFill(297)} fill={props.fillColors[297]}  d="M737.28,297.37c-2.29-7.07-0.96-9.47,6.51-9.3c7.36,0.17,14.71,1.23,22.06,1.85
				c0.95,0.08,2.09,0.19,2.87-0.23c9.1-4.9,18.61-2.41,27.71-0.75c3.64,0.66,6.58,5.2,9.84,7.98c-0.34,0.59-0.69,1.18-1.03,1.77
				c-7.97-0.87-15.94-1.75-23.91-2.61c-1.32-0.14-2.94-0.76-3.92-0.22c-7.82,4.27-15.62-0.95-23.43,0.15
				C748.63,296.77,743.19,296.91,737.28,297.37z"/>
			<path onClick={() => props.onFill(298)} fill={props.fillColors[298]}  d="M279.71,839.24c-3.12-5.98-2.54-14.02-10.92-15.43c0.69-3.07-3.19-10.5-6.03-11.38
				c-4.51-1.4-9.23-2.14-13.67-3.71c-2.37-0.84-4.34-2.81-6.72-4.43c5.46-4.68,5.4-4.56-0.31-7.46c-1.11-0.56-1.57-2.41-3.2-5.08
				c7.47,0,13.5,0,19.53,0c0.29,0.21,0.59,0.42,0.88,0.63c-2.1,1.17-4.2,2.35-6.3,3.52c0.34,0.62,0.68,1.23,1.02,1.85
				c1.84,0.18,3.68,0.49,5.52,0.53c8.53,0.18,10.92,2.87,13.27,10.9c1.37,4.71,0.66,10.45,5.72,14.12c1.45,1.06,1.01,4.92,1.18,7.52
				C279.85,833.45,279.71,836.11,279.71,839.24z"/>
			<path onClick={() => props.onFill(299)} fill={props.fillColors[299]}  d="M830.08,981.14c-4.33,0.3-9.02-6.08-12.05,2.22c-7.2-2.76-14.21-5.45-22.47-8.62
				c5.11-3.13,3.7-6.05,1.5-8.14c-5.88-5.58-12.07-10.83-18.18-16.16c-1.46-1.27-3.1-2.35-4.35-3.97
				C795.99,953.11,815.25,963.22,830.08,981.14z"/>
			<path onClick={() => props.onFill(300)} fill={props.fillColors[300]}  d="M769.35,409c-2.62-0.6-4.82-1.11-6.64-1.53c0.12-2.26,0.9-5.33,0.19-5.73c-3.06-1.72-6.54-2.7-9.94-3.74
				c-0.12-0.04-1.65,2.69-1.28,3.2c1.93,2.6,0.17,3.8-1.02,5.92c-1.29,2.27-0.88,5.51-1.22,8.43c-0.13,0.06-0.6,0.38-0.68,0.3
				c-4.51-4.51-11.98-4.88-15.24-11.48c-0.96-1.95-4.65-2.51-7.04-3.79c-1.58-0.85-3.07-1.85-4.6-2.78c0.2-0.52,0.41-1.04,0.61-1.56
				c1.75,0,3.51-0.06,5.26,0.01c16.04,0.62,32.09,1.09,48.11,1.97c6.59,0.36,13.15,1.48,19.68,2.56c1.41,0.23,2.85,1.58,3.86,2.78
				c2.12,2.53,3.96,5.3,6.3,8.49c-5.11,0.35-8.77-2.18-12.08-4.83c-5.59-4.47-11.81-5.76-18.69-5.04
				C769.83,402.7,769.63,402.89,769.35,409z"/>
			<path onClick={() => props.onFill(301)} fill={props.fillColors[301]}  d="M329.43,317.5c-1.44,4.78-4.31,5.15-8.11,6.33c-8.88,2.75-17.28-1.57-25.79,0.13
				c-5.3,1.06-10.55,2.35-16.43,3.68c-2.31-0.97-5.56-2.33-9.44-3.95c0.87-2.08,1.67-4.73,3.03-7.06c1.77-3.03,6.57-3.28,8.9-0.19
				c1.95,2.59,3.68,2.32,5.91,1.01c8.04-4.71,16.84-4.69,25.62-3.61C318.55,314.5,323.86,316.21,329.43,317.5z"/>
			<path onClick={() => props.onFill(302)} fill={props.fillColors[302]}  d="M404.88,793.29c-3.38-4.95-6.77-9.89-10.13-14.86c-0.76-1.12-1.63-2.24-2.04-3.5
				c-1.56-4.8-3.4-5.31-6.8-1.73c-0.53,0.56-1,1.16-1.79,2.09c-0.5-0.96-0.69-1.57-1.08-2.02c-0.96-1.12-2.01-2.16-3.03-3.24
				c-1.01,1.2-2.39,2.24-2.97,3.62c-1.45,3.44-2.54,7.02-3.82,10.68c-6.06-5.31-9.28-4.29-13.44,4.74c-1.81-1.76-4.81-3.44-4.68-4.83
				c0.21-2.31,2.38-4.48,3.88-6.6c0.28-0.39,1.29-0.2,1.9-0.43c1.44-0.54,3.42-0.79,4.15-1.86c1.46-2.15,2.52-4.69,3.23-7.21
				c0.97-3.4,1.85-5.15,6.39-4.72c2.63,0.25,5.65-3.56,8.77-5.74c2.96,1.78,7.22,3.45,7.38,8.77c0.01,0.28,0.31,0.78,0.52,0.8
				c7.84,0.81,6.51,9.57,11.38,13.43c2.71,2.16,2.23,8.34,3.19,12.71C405.55,793.38,405.22,793.34,404.88,793.29z"/>
			<path onClick={() => props.onFill(303)} fill={props.fillColors[303]}  d="M564.69,570.55c2.39-7.08,8.78-10.41,15.04-8.52c1.13,0.34,2.65,0.17,3.76-0.31
				c3.79-1.67,7.44-3.63,11.19-5.38c3.27-1.53,5.88-3.38,4.29-8.12c6.55,0,12.56-0.14,18.56,0.06c2.92,0.1,3.86,1.99,3.26,4.88
				c-0.63,3.08-0.93,6.23-1.45,9.34c-0.94,5.62-3.69,7.56-10.15,6.88c0-3.68,0.09-7.41-0.04-11.13c-0.06-1.67-0.56-3.33-0.86-5
				c-1.38,0.7-2.82,1.31-4.14,2.1c-9.13,5.46-18,11.46-27.51,16.16C573.66,572.98,568.97,570.99,564.69,570.55z"/>
			<path onClick={() => props.onFill(304)} fill={props.fillColors[304]}  d="M291.7,933.76c-0.85-3.02-2.42-6.05-2.38-9.07c0.05-4.88-1.96-6.14-6.34-5.63
				c-3.63,0.42-7.29,0.47-10.95,0.68c-3.16,0.18-6.96-1.05-7.39,5.02c-0.42-0.43-0.84-0.86-1.26-1.29c0-7.57-0.12-15.14,0.05-22.71
				c0.08-3.49,3.18-2.99,4.83-2.15c8.15,4.12,16.15,8.55,24.11,13.03c0.78,0.44,1.37,1.92,1.39,2.93c0.13,6.27,0.06,12.55,0.06,18.82
				C293.12,933.51,292.41,933.63,291.7,933.76z"/>
			<path onClick={() => props.onFill(305)} fill={props.fillColors[305]}  d="M798.58,232.99c-4.26,0.28-8.52,0.73-12.78,0.81c-22.37,0.4-44.74,0.52-67.11,1.09
				c-20.26,0.52-40.51,1.49-61.96,2.31c2.17-1.22,3.72-2.79,5.37-2.92c14.82-1.14,29.66-2.32,44.51-2.9
				c12.75-0.5,25.54-0.13,38.3-0.32c11.91-0.17,23.81-0.54,35.71-0.89c4.64-0.14,9.27-0.63,13.9-0.61c1.53,0.01,3.05,1.13,4.58,1.74
				C798.94,231.88,798.76,232.44,798.58,232.99z"/>
			<path onClick={() => props.onFill(306)} fill={props.fillColors[306]}  d="M215.1,1047.67c-8.59-1.55-17.45,4.42-26.4-0.51c-0.84-0.46-2.29-0.98-2.32-1.54
				c-0.33-5.35-0.98-10.78-0.39-16.07c0.45-4.08,2.54-7.97,3.94-12.06c8.48,3.85,12.33,11.64,16.73,19.1
				C208.92,1040.46,212.08,1043.78,215.1,1047.67z"/>
			<path onClick={() => props.onFill(307)} fill={props.fillColors[307]}  d="M673.75,1027.63c2.64,0.52,5.43,0.64,7.88,1.62c9.82,3.89,19.55,7.99,29.25,12.16
				c1.19,0.51,2.74,1.81,2.87,2.89c0.81,6.98-1.56,13.14-4.87,19.09c-12.19-11.62-23.89-22.76-35.58-33.9
				C673.44,1028.87,673.6,1028.25,673.75,1027.63z"/>
			<path onClick={() => props.onFill(308)} fill={props.fillColors[308]}  d="M520.55,312.45c1.03-1.25,2.42-3.74,3.02-3.56c6.32,1.88,9.5-3.7,14.15-5.63
				c6.07-2.52,11.92-5.76,18.18-7.6c3.96-1.16,8.54-0.69,12.79-0.31c9.19,0.81,15.91-4.63,23.44-8.34
				c6.98-3.45,14.41-5.34,22.45-3.17c1.64,0.44,4.47-0.72,5.7-2.09c6.27-7.02,14.73-9.74,23.21-12.35c1.43-0.44,3.42,0.93,4.98,2.07
				c-11.51,3.38-22.17,7.34-29,14.89c-5.9,0.59-10.51,1.7-15.01,1.37c-9.66-0.71-15.64,6.92-23.7,9.51
				c-5.04,1.62-10.67,1.42-16.05,1.89c-2.61,0.23-5.59-0.68-7.8,0.29c-8.7,3.82-17.08,8.37-25.76,12.25c-2.9,1.29-6.32,1.4-9.51,2.05
				C521.28,313.3,520.92,312.87,520.55,312.45z"/>
			<path onClick={() => props.onFill(309)} fill={props.fillColors[309]}  d="M388.05,775.63c2.05,2.27,3.93,4.3,5.74,6.4c0.63,0.73,1.52,1.65,1.5,2.45c-0.09,2.74,1.07,4.03,3.54,5.21
				c1.34,0.64,1.83,3.08,3.52,6.23c-7.33,0.68-13.3,1.4-19.3,1.74c-4.11,0.23-8.25-0.02-12.37-0.19c-0.84-0.03-1.72-0.53-2.47-0.99
				c-1.85-1.12-3.63-2.34-6.45-4.18c1.32-2.19,3.32-5.48,5.71-9.42c2.19,3.01,3.69,5.08,6.01,8.27c2.46-5.83,4.54-10.78,6.73-15.97
				c1.11,1.26,2.16,2.45,3.57,4.05C385.5,777.78,387.08,776.44,388.05,775.63z"/>
			<path onClick={() => props.onFill(310)} fill={props.fillColors[310]}  d="M730.92,264.86c-4.28,0-8.56-0.12-12.83,0.03c-7.93,0.28-9.76,1.76-12,9.22
				c-0.32,1.06-0.84,2.06-0.89,2.19c-7.25-1.26-13.98-2.42-20.95-3.63c1.1-3.63,1.71-8.21,3.9-11.83c1.22-2.01,5.07-3.34,7.74-3.37
				c10.27-0.11,20.56,0.21,30.81,0.93c1.86,0.13,3.53,2.9,5.29,4.44C731.64,263.51,731.28,264.18,730.92,264.86z"/>
			<path onClick={() => props.onFill(311)} fill={props.fillColors[311]}  d="M323.17,759.57c4.2-2.33,8.47-4.71,13.28-7.38c5.03,2.31,11.3,4.31,16.51,7.86
				c3.97,2.7,6.6,7.37,9.46,10.74c-3.71,2.93-6.93,5.47-9.39,7.4c-2.45-0.27-4.41-0.4-6.34-0.72c-4.17-0.69-6.82-3.26-8.51-7.03
				c-1.38-3.09-1.53-7-7.04-6.33C328.91,764.4,326.27,761.44,323.17,759.57z"/>
			<path onClick={() => props.onFill(312)} fill={props.fillColors[312]}  d="M268.73,1104.07c8.53-8.03,18.58-7.48,28.59-5.51c4.07,0.8,2.22,4.25,1.25,6.93
				c-1,2.74-1.66,5.6-2.55,8.38c-1.22,3.85,1.46,4.33,4.03,4.42c5.21,0.17,10.43,0.05,14.92,0.05c1.99,2.4,3.4,4.1,5.38,6.51
				c-11.35-2.86-22.04-5.53-32.72-8.26c-1.6-0.41-3.12-1.57-4.68-1.58c-4.77-0.04-7.57-2.12-9.31-6.62
				C272.99,1106.66,270.57,1105.62,268.73,1104.07z"/>
			<path onClick={() => props.onFill(313)} fill={props.fillColors[313]}  d="M119.97,928.89c1.97,6.65,6.01,10,12.97,9.15c0.33-1.05,0.76-2.39,1.45-4.58
				c1.08,1.01,2.48,1.68,2.56,2.49c0.31,3.14,0.22,6.31,0.24,9.47c0.02,3.33,0.01,6.66,0,9.99c-0.03,7.51-2.36,9.17-9.82,6.98
				c-0.48-0.14-0.95-0.32-1.43-0.41c-7.74-1.39-7.74-1.39-7.74-9.51c0-7.45,0-14.9,0-22.35
				C118.78,929.72,119.37,929.31,119.97,928.89z"/>
			<path onClick={() => props.onFill(314)} fill={props.fillColors[314]}  d="M701.77,1078.28c-10.23-11.84-20.28-23.57-30.44-35.19c-3.81-4.36-3.91-5.67,1.06-10.87
				c10.48,10.35,21.09,20.57,31.35,31.14C706.76,1066.46,705.1,1074.25,701.77,1078.28z"/>
			<path onClick={() => props.onFill(315)} fill={props.fillColors[315]}  d="M64.57,370.84c-18.6-13.23-37.2-26.46-55.87-39.74c0.35-2.23,0.73-4.66,1.16-7.41
				c1.66,1.06,3.43,2.58,5.48,3.41c4.84,1.97,9.8,3.65,14.73,5.37c2.14,0.74,4.78,0.74,6.43,2.03c4.38,3.43,8.34,7.4,12.7,11.36
				c-0.33,0.9-0.9,2.5-1.49,4.11c-1.28-0.37-2.79-0.82-4.3-1.26c-0.29,0.39-0.59,0.78-0.88,1.17c4.9,9.42,15.4,12.88,22.63,19.83
				C64.97,370.09,64.77,370.46,64.57,370.84z"/>
			<path onClick={() => props.onFill(316)} fill={props.fillColors[316]}  d="M124.48,913.13c-5.92,3.77-10.65,6.38-16.73,5.59c-9.22-1.2-18.48-2.05-27.72-3.05
				c-3.89-0.42-6.36-1.94-8.1-6.05c-1.23-2.91-5.14-4.69-7.1-6.34c0.65,0.07,2.71,0.37,4.79,0.47c5.27,0.25,10.55,0.51,15.83,0.58
				c4.44,0.05,8.27,0.51,12.58,2.96c4.92,2.81,11.48,2.75,17.32,3.95C118.01,911.78,120.67,912.34,124.48,913.13z"/>
			<path onClick={() => props.onFill(317)} fill={props.fillColors[317]}  d="M314.48,1020.17c-1.58-11.87,3.7-22.45,5.74-33.51c0.27-1.44,2.02-2.61,3.09-3.91
				c0.84,1.3,1.74,2.56,2.5,3.9c3.34,5.85,1.7,11.86,0.68,17.9c-0.4,2.39,0.15,4.93-0.21,7.33c-0.67,4.5-1.84,8.92-2.51,13.42
				c-0.66,4.48-0.89,9.03-1.47,13.53c-0.12,0.93-0.96,1.77-1.46,2.64c-0.6-0.44-1.6-0.78-1.73-1.33
				C317.49,1033.5,316,1026.83,314.48,1020.17z"/>
			<path onClick={() => props.onFill(318)} fill={props.fillColors[318]}  d="M270.48,735.45c-5.5,0.05-10.46-4.57-16.55-1.43c-2.88,1.48-6.47,1.58-9.74,2.3
				c-0.19-0.86-0.37-1.73-0.56-2.59c4.22-0.96,8.55-1.6,12.62-2.99c3.03-1.03,5.72-3.05,8.57-4.61c2.16-1.19,4.22-2.65,6.51-3.48
				c5.05-1.82,10.12-4.06,15.35-4.68c3.71-0.44,7.73,1.39,11.57,2.39c4.88,1.27,9.4,4.24,14.87,1.51c5.58-2.79,6.95,2.29,10.11,6.96
				c-2.7,0-4.94,0.7-6.04-0.12c-4.75-3.56-9.71-4.08-15.43-3.14c-5.1,0.84-10.4,0.61-15.61,0.71
				C279.28,726.41,274.76,730.31,270.48,735.45z"/>
			<path onClick={() => props.onFill(319)} fill={props.fillColors[319]}  d="M194.8,1076.65c-0.01,7.56-0.22,7.7-6.97,6.94c-6.1-0.69-12.21-1.35-18.33-1.82
				c-2.8-0.22-4.42-1.2-4.28-4.2c0.01-0.17-0.04-0.33-0.03-0.5c0.22-3.32-0.69-8.7,0.89-9.58c4.91-2.74,10.46-6.31,16.63-2.07
				c2.03,1.4,4.39,2.4,6.71,3.26C193.56,1070.2,195.9,1072.65,194.8,1076.65z"/>
			<path onClick={() => props.onFill(320)} fill={props.fillColors[320]}  d="M834.56,603.49c1.96,0.33,3.91,0.72,5.88,0.98c3.28,0.43,6.61,0.61,9.85,1.25
				c1.84,0.36,3.73,1.18,5.28,2.24c6.92,4.75,14.52,3.53,22.03,2.62c8.27-1,15.23,2.34,22.42,5.79c-2.06,2.82-3.87,4.42-7.26,2.85
				c-1.5-0.7-3.63-0.34-5.4-0.05c-2.49,0.4-4.97,2.38-7.18-0.73c-0.26-0.37-1.91-0.04-2.74,0.34c-2.87,1.32-5.58,3.64-8.53,3.97
				c-2.19,0.25-4.68-1.97-7.02-3.12c-1.9-0.94-3.75-1.96-5.65-2.88c-0.41-0.2-1.07,0.1-1.46-0.12c-4.79-2.73-9.57-5.49-14.33-8.28
				c-1.93-1.13-3.82-2.32-5.73-3.48C834.68,604.42,834.62,603.96,834.56,603.49z"/>
			<path onClick={() => props.onFill(321)} fill={props.fillColors[321]}  d="M122.34,1042.26c-2.24,2.59-3.88,5.14,0.21,8.04c-8.26-1.6-14.75,1.33-21.05,5.1
				c-0.61,0.37-1.64,0.39-2.36,0.17c-4.05-1.21-13.36-13.79-13.33-18.29c0.01-0.94,1.31-2.51,2.21-2.67c2.04-0.36,4.3-0.4,6.29,0.11
				C103.43,1037.04,112.49,1039.59,122.34,1042.26z"/>
			<path onClick={() => props.onFill(322)} fill={props.fillColors[322]}  d="M342.09,1079.37c-2.78-8.52-5.36-10.13-14.41-9.95c-1.5,0.03-4.16-1.43-4.34-2.54
				c-0.49-2.83-0.89-6.49,0.49-8.66c3.52-5.52,9.86-5.21,15.35-4.61c3.45,0.38,6.71,2.93,9.91,4.75c0.79,0.45,1.45,1.85,1.48,2.84
				C350.82,1068.63,348.25,1074.81,342.09,1079.37z"/>
			<path onClick={() => props.onFill(323)} fill={props.fillColors[323]}  d="M528.12,315.26c-1.52,2.06-2.05,3.17-2.9,3.88c-8.73,7.22-9.74,17.96-12.97,27.77
				c-1.75,5.32-4.73,10.23-7.15,15.33c-0.55-0.12-1.1-0.24-1.65-0.36c-0.55-6.16-0.58-12.42-1.8-18.44
				c-1.12-5.56,0.13-9.87,3.29-14.35C510.41,321.29,517.84,317.2,528.12,315.26z"/>
			<path onClick={() => props.onFill(324)} fill={props.fillColors[324]}  d="M596.91,349.78c-2.22,4.03-3.66,7.59-5.97,10.42c-1,1.23-3.78,1.19-5.79,1.38
				c-3.23,0.31-6.5,0.23-9.73,0.51c-7.3,0.62-14.59,1.37-21.76,2.05c-1.66-7.11-0.15-9.92,5.67-11.63c0.95-0.28,1.93-0.5,2.91-0.6
				c9.31-1,18.62-2.04,27.95-2.89C592.07,348.84,594.01,349.42,596.91,349.78z"/>
			<path onClick={() => props.onFill(325)} fill={props.fillColors[325]}  d="M646.77,324c4.23-7.22,7.59-12.97,11.47-19.58c2.39,2.79,4.08,4.52,5.49,6.44
				c1.77,2.42,3.71,2.96,6.42,1.56c5.75-2.97,15.42-3.6,22.07-1.53c-0.76,3.01-1.53,6.02-2.27,8.91c-5.01,0-9.85-0.54-14.51,0.14
				c-5.42,0.79-10.62,2.99-16.03,3.94C655.84,324.51,652.07,324,646.77,324z"/>
			<path onClick={() => props.onFill(326)} fill={props.fillColors[326]}  d="M657.62,1018.01c-9.1,15.99-18.79,30.63-36.17,40.54c1.57-3.76,2.59-6.2,3.72-8.91
				c5.93,1.98,6.92-1.36,7.7-5.98c0.67-4.01,2.4-7.87,3.9-11.7c2.25-5.75,4.43-11.55,7.21-17.05c0.93-1.85,3.42-3.42,5.5-4
				c1.3-0.36,3.46,1.07,4.74,2.24C655.64,1014.44,656.5,1016.35,657.62,1018.01z"/>
			<path onClick={() => props.onFill(327)} fill={props.fillColors[327]}  d="M76.32,1139.76c0.4,12.08-6.22,22.24-5.13,33.93c-1.19-3.38-2.38-6.77-3.74-10.61
				c3.8-1.58,1.97-3.91,0.7-6.85c-1.66-3.86-2.39-8.13-3.39-12.26c-0.36-1.51-0.33-3.11-0.48-4.67c-0.6-0.17-1.21-0.34-1.81-0.51
				c-0.8,1.99-1.69,3.96-2.37,5.99c-0.77,2.29-1.36,4.64-3.1,6.89c-0.8-3.9-1-8.07-2.59-11.61c-1.39-3.09-4.3-5.51-6.72-8.06
				c-0.18-0.19-2.12,0.74-2.66,1.54c-2.02,2.95-3.8,6.06-5.37,8.63c-0.8-4.08-1.77-9-3-15.3C50.62,1131.39,64,1135.75,76.32,1139.76z
				"/>
			<path onClick={() => props.onFill(328)} fill={props.fillColors[328]}  d="M387.1,739c1.41,4.36,3.62,6.48,6.36,4.92c3.54,1.27,5.91,2.04,8.2,2.98c0.97,0.4,2.53,1.14,2.55,1.77
				c0.22,5.72,4.17,12.17-3.02,16.61c-0.85,0.53-1.16,1.94-1.53,2.6c-1.7-1.05-3.46-2.14-5.22-3.24c-0.47-0.29-1.36-0.6-1.34-0.86
				c0.45-6.51-6.3-6.46-9.19-9.92c-0.69-0.83-3.22-0.12-4.23-0.83c-2.72-1.86-5.43-3.72-8.3-5.69
				C376.01,744.9,381.34,742.07,387.1,739z"/>
			<path onClick={() => props.onFill(329)} fill={props.fillColors[329]}  d="M848.41,348.06c-8.97,2.81-16.82,3.04-23.69-3.63c-1.51-1.46-3.5-2.82-5.49-3.32
				c-6.06-1.51-10.99-4.07-13.98-10.04c-1.39-2.78-2.37-2.46-8.06,0.25c-0.31-0.31-0.63-0.62-0.94-0.93c1.19-1,2.25-2.48,3.62-2.87
				c1.47-0.42,3.48-0.28,4.83,0.43c6.72,3.55,13.81,3.91,21.2,3.51c4.96-0.27,9.11,1.59,12.27,6.12
				C840.86,341.41,844.75,344.4,848.41,348.06z"/>
			<path onClick={() => props.onFill(330)} fill={props.fillColors[330]}  d="M729.97,512.41c-2.13,2.41-3.88,5.4-6.48,7.1c-4.48,2.93-7.68,6.5-10,11.34
				c-2.75,5.74-6.8,10.58-13.04,13.75c-1.48-3.48-2.89-6.81-4.31-10.15c0.18-7.82,3.31-14.81,12.57-17.76
				c2.33,2.07,4.83,1.92,8.31-0.66c3.31-2.45,7.89-3.19,11.9-4.68C729.28,511.71,729.63,512.06,729.97,512.41z"/>
			<path onClick={() => props.onFill(331)} fill={props.fillColors[331]}  d="M876.53,439.08c0.07,1.4-0.56,4.33,0.46,5.11c1.3,1,4.3,0.97,5.91,0.13c5.38-2.8,10.5-6.11,15.64-9.34
				c1.48-0.93,2.71-2.28,4.58-3.89c-0.43,6.93-9.85,9.3-6.32,17.19c-1.21-0.06-1.99,0.05-2.67-0.17
				c-13.92-4.36-27.27,0.57-39.47,5.19c-9.85,3.73-19.63,7.5-29.8,10.25c-0.44,0.12-0.95-0.04-1.42-0.07
				c-0.24-0.43-0.48-0.85-0.72-1.28c2.96-1.67,5.74-3.93,8.91-4.89c7.84-2.37,15.48-4.8,21.88-10.32c1.9-1.64,4.72-2.34,7.22-3.15
				C866.13,442.09,871.62,440.54,876.53,439.08z"/>
			<path onClick={() => props.onFill(332)} fill={props.fillColors[332]}  d="M798.27,913.26c-1.96-1.76-4.26-3.27-5.78-5.35c-1.42-1.96-3.01-4.7-2.65-6.77
				c0.28-1.6,3.29-3.44,5.37-3.89c10.34-2.26,20.08,0.36,29.25,5.01c3.94,2,2.81,6.22,1.76,9.34c-0.41,1.21-3.64,2.15-5.53,2.07
				c-7.47-0.32-14.92-1.1-22.38-1.71C798.3,912.38,798.29,912.82,798.27,913.26z M806.02,901.2c-1.65,1.81-2.63,2.89-3.61,3.97
				c1.72,1.3,3.3,2.96,5.23,3.7c0.71,0.27,2.26-1.65,3.42-2.57C809.57,904.78,808.08,903.28,806.02,901.2z M791.36,905.03
				c1.04,0.87,1.71,1.87,2.35,1.84c0.66-0.02,1.8-1.03,1.8-1.62c0-0.65-0.96-1.74-1.63-1.85
				C793.27,903.31,792.49,904.26,791.36,905.03z"/>
			<path onClick={() => props.onFill(333)} fill={props.fillColors[333]}  d="M695.86,659.85c-3.52,0.53-7.34,0.38-10.47,1.8c-2.57,1.16-4.16,1.72-7.08,0.71
				c-6.56-2.26-13.54-3.25-20.17-5.34c-4.11-1.3-7.89-3.68-11.56-6.15c12.16,1.38,24.48-1.3,36.2,3.41c2.75,1.11,6.4-0.18,9.63-0.05
				c4.18,0.17,8.35,2,12.5-0.44c0.64-0.37,1.91,0.16,2.82,0.47c3.89,1.35,7.76,2.78,12.09,4.34c-1.13,1-2.69,2.4-4.57,4.07
				c-2.08-4.36-4.46-6.2-8.31-2.89c-0.43,0.37-1.7,0.36-2.15,0c-3.49-2.77-6.15-1.12-8.63,1.88
				C696.06,661.06,695.96,660.45,695.86,659.85z"/>
			<path onClick={() => props.onFill(334)} fill={props.fillColors[334]}  d="M535.91,235.35c2.39,2.09,4.07,3.99,6.14,5.27c5.02,3.11,5.81,7.8,5.28,13.02
				c-0.1,0.97-0.81,2.35-1.61,2.71c-6.45,2.94-12.99,5.7-19.52,8.48c-0.57,0.24-1.24,0.24-2,0.38c-0.47-4.97-1.32-9.74-1.16-14.48
				c0.06-1.7,2.21-3.79,3.93-4.9C531.03,243.22,535.7,241.33,535.91,235.35z"/>
			<path onClick={() => props.onFill(335)} fill={props.fillColors[335]}  d="M104.85,1033.72c-6.79,0.81-13.64-6.23-20.94-0.4c-3.71-7.47-2.01-13.86,1.08-19.72
				c0.79-1.49,4.99-2.43,7.03-1.77c5.18,1.68,10.1,4.25,14.91,6.86c0.81,0.44,0.95,2.94,0.69,4.35
				C107,1026.35,105.96,1029.59,104.85,1033.72z"/>
			<path onClick={() => props.onFill(336)} fill={props.fillColors[336]}  d="M626.96,1083.66c0.71,7.23,1.49,14.45,2.12,21.68c0.69,7.95,2.01,15.94,1.69,23.86
				c-0.41,10.22-2.98,20.12-8.56,28.84c-0.56-0.1-1.11-0.2-1.67-0.3c0.34-3.62,0.43-7.29,1.06-10.85
				c3.35-18.88,1.67-37.81,1.02-56.75c-0.08-2.34,1.29-4.72,1.99-7.09C625.39,1083.26,626.18,1083.46,626.96,1083.66z"/>
			<path onClick={() => props.onFill(337)} fill={props.fillColors[337]}  d="M866.9,398.67c0.81-1.62,1.19-3.94,2.5-4.73c5.96-3.6,11.49-8.69,19.4-7.14c0.57,0.11,1.4-0.13,1.83-0.52
				c8.15-7.18,18.1-8.16,28.27-8.06c1.55,0.01,3.08,1.46,4.62,2.24c-1.54,1.84-2.75,4.64-4.7,5.33c-3.81,1.34-8.16,1.08-12.07,2.23
				c-12.9,3.79-25.69,7.9-38.53,11.9C867.78,399.5,867.34,399.08,866.9,398.67z"/>
			<path onClick={() => props.onFill(338)} fill={props.fillColors[338]}  d="M40.34,1116.26c4.69-1.03,8.27-1.57,11.7-2.64c5.74-1.79,9.7,0.93,13.38,4.71
				c0.68,0.7,1.29,1.52,2.09,2.05c5.87,3.92,6.85,6.41,5.16,14.29c-1.66-0.09-3.54,0.19-5.14-0.36c-6.72-2.31-13.81-4.1-19.85-7.65
				C44.24,1124.65,42.58,1119.61,40.34,1116.26z"/>
			<path onClick={() => props.onFill(339)} fill={props.fillColors[339]}  d="M719.93,145.68c-7.54-0.64-14.26-1.33-20.99-1.73c-4.26-0.25-8.56-0.28-12.81-0.03
				c-5.14,0.3-9.56-0.62-12.59-5.72c8.59-4.54,17.64-6.3,27.23-5.45c0.99,0.09,1.96,0.36,2.94,0.44
				C713.72,133.96,714.85,134.75,719.93,145.68z"/>
			<path onClick={() => props.onFill(340)} fill={props.fillColors[340]}  d="M440.11,363.37c-1.72-1.1-3.43-2.21-5.16-3.32c3.78-5.48,3.37-6.38-1.97-9.1c-1.73-0.88-2.65-3.31-3.98-5
				c-1.93-2.46-3.84-4.93-5.85-7.32c-0.62-0.73-1.82-1.04-2.3-1.82c-2.92-4.83-5.71-9.74-8.55-14.63c7.42-4.57,16.87-0.99,19.48,7.04
				c2.55,7.84,5.89,15.43,8.45,23.27c1.05,3.24,0.97,6.84,1.41,10.28C441.13,362.97,440.62,363.17,440.11,363.37z"/>
			<path onClick={() => props.onFill(341)} fill={props.fillColors[341]}  d="M707.3,437.67c8.47-0.91,16.14-1.48,23.73-2.63c6.65-1.01,13.2-2.65,19.79-4.03
				c1.78-0.37,3.68-1.4,5.28-1.05c1.45,0.32,3.79,2.45,3.59,3.22c-0.57,2.21-1.83,5.4-3.54,5.94c-6.18,1.96-13,4.9-18.92,3.81
				c-5.82-1.07-10.67-0.07-15.92,0.96c-0.65,0.13-1.27,0.38-1.9,0.59C711.95,446.97,711.95,446.97,707.3,437.67z"/>
			<path onClick={() => props.onFill(342)} fill={props.fillColors[342]}  d="M938.84,437.78c-3.35,12.48-6.46,24.69-10,36.77c-0.84,2.88-2.82,5.59-4.84,7.87
				c-1.01,1.15-3.3,1.17-5.01,1.7c-0.28-0.49-0.56-0.98-0.85-1.47c1.68-2.4,3.41-4.76,5.02-7.2c2.55-3.87,1.71-5.8-2.95-6.19
				c-3.55-0.3-7.14-0.06-10.72-1.06c3.93,0,7.87,0.19,11.77-0.08c1.92-0.14,3.78-1.14,5.66-1.76c-1.03-1.54-1.76-3.44-3.14-4.54
				c-2.76-2.2-1.69-3.96,0.58-4.87c5.12-2.04,4.24-6.57,4.37-10.38c0.04-1.14-2.51-2.95-4.18-3.44c-3.27-0.96-6.75-1.22-11.6-2
				C922.1,437.3,930.51,438.96,938.84,437.78z"/>
			<path onClick={() => props.onFill(343)} fill={props.fillColors[343]}  d="M201.35,276.17c6.37,0,12.77-0.12,19.15,0.03c9.56,0.22,19.12,0.74,28.68,0.88
				c3.11,0.05,5.12,0.44,5.07,4.21c-0.06,3.86-2.58,3.65-5.13,3.56c-9.34-0.32-18.68-0.8-28.02-0.94c-4.12-0.06-8.25,0.72-12.38,0.81
				C201.43,284.87,201.22,284.54,201.35,276.17z"/>
			<path onClick={() => props.onFill(344)} fill={props.fillColors[344]}  d="M211.86,730.93c0.9-3.17,3.23-2.88,5.88-2.23c2.93,0.71,5.6,1.17,7.98-2.01c1.06-1.41,4.27-2.08,6.26-1.72
				c4.82,0.86,7.79-1.8,11.09-4.38c1.41-1.1,3.24-2.28,4.91-2.32c6.18-0.14,12.38,0.18,18.61,1.13
				C249.99,731.41,232.24,737.29,211.86,730.93z"/>
			<path onClick={() => props.onFill(345)} fill={props.fillColors[345]}  d="M881.65,854.66c0.42,1.51,0.99,2.99,1.22,4.52c0.92,6.17,1.54,12.4,2.63,18.54
				c0.87,4.86-5.39,13.78-10.27,14.24c-3.12,0.3-6.22,0.82-10.21,1.36c4.99-13.35,9.78-26.14,14.56-38.93
				C880.27,854.48,880.96,854.57,881.65,854.66z"/>
			<path onClick={() => props.onFill(346)} fill={props.fillColors[346]}  d="M618.82,1085.75c0.4,3.38,1.11,6.75,1.15,10.14c0.17,13.64,0.24,27.29,0.13,40.94
				c-0.06,7.01-3.25,12.59-9.89,16.67c2.33-22.59,4.65-45.19,6.98-67.78C617.74,1085.73,618.28,1085.74,618.82,1085.75z"/>
			<path onClick={() => props.onFill(347)} fill={props.fillColors[347]}  d="M795.08,425.02c-3.3,0.04-6.63-0.16-9.88,0.23c-1.17,0.14-2.18,1.69-3.26,2.6
				c0.97,1.16,1.75,2.61,2.95,3.43c3.12,2.11,6.44,3.93,9.25,6.77c-7.6-1.95-16.28,2.86-22.68-4.18c-0.5-0.56-1.54-1.04-2.24-0.94
				c-7.33,0.99-9.11-5.05-12.46-9.34c3.11-3.6,6.82-2.22,10.37-2.12c2.73,0.08,5.48-0.33,8.25-1.19c4.31-0.41,8.19,7.5,13.07-0.46
				c2.26,1.8,4.42,3.53,6.59,5.26L795.08,425.02z"/>
			<path onClick={() => props.onFill(348)} fill={props.fillColors[348]}  d="M407.26,236.13c-6.17,1.08-12.29,2.19-18.43,3.2c-3.88,0.63-7.81,0.96-11.67,1.68
				c-3.54,0.66-7.02-1.3-7.86-4.84c-0.9-3.79,1-5.64,4.53-6.16c8.5-1.25,16.98-2.61,25.51-3.62
				C403.09,225.95,405.19,228.75,407.26,236.13z"/>
			<path onClick={() => props.onFill(349)} fill={props.fillColors[349]}  d="M625.44,977.65c-1.34-1.43-3.08-2.65-3.96-4.33c-5.48-10.48-2.72-30.57,5.5-38.96
				c1.54-1.57,4.23-2.02,6.4-2.98c0.56,2.25,1.51,4.48,1.57,6.74c0.05,1.71-0.89,3.51-1.63,5.17c-4.75,10.72-8.53,21.64-6.66,33.78
				C626.26,977.27,625.85,977.46,625.44,977.65z"/>
			<path onClick={() => props.onFill(350)} fill={props.fillColors[350]}  d="M525.33,291.21c3.73-2.35,7.25-5.15,11.23-6.97c12.09-5.53,24.51-9.54,38.14-9.71
				c4.58-0.06,9.75-2.51,13.55-5.35c7.51-5.63,16.26-5.34,24.66-7.02c3.44-0.69,6.89-1.79,10.08-3.27
				c5.79-2.69,11.37-5.81,17.04-8.76c0.38,0.5,0.77,0.99,1.15,1.49c-1.1,1.04-2.05,2.37-3.34,3.09c-6.2,3.45-12.49,6.75-18.77,10.07
				c-0.72,0.38-1.5,0.8-2.28,0.89c-6.38,0.69-12.78,1.23-19.15,2c-1.72,0.21-3.66,0.65-4.99,1.67c-7.52,5.75-15.5,9.37-25.33,9.67
				c-13.06,0.41-25.24,4.63-36.22,11.96c-0.41,0.28-0.83,0.62-1.29,0.73c-1.27,0.28-2.57,0.44-3.85,0.65
				C525.75,291.97,525.54,291.59,525.33,291.21z"/>
			<path onClick={() => props.onFill(351)} fill={props.fillColors[351]}  d="M716.32,391.46c4.95-4.67,10.69-3.08,16.12-3.12c3.96-0.03,7.92,0.36,11.87,0.64
				c15.63,1.08,31.26,2.09,46.86,3.43c1.72,0.15,3.26,2.24,4.89,3.43c-0.27,0.8-0.55,1.59-0.82,2.39
				C769.19,392.86,742.5,395.89,716.32,391.46z"/>
			<path onClick={() => props.onFill(352)} fill={props.fillColors[352]}  d="M723.32,403.62c4.56-0.53,7.33,0.18,8.87,4.04c0.62,1.55,2.1,3,3.55,3.94c3.91,2.57,8.26,4.53,11.97,7.35
				c2.55,1.94,4.31,4.92,7.16,8.3c-7.82,1.55-15.14,3.01-22.3,4.43c-0.45-0.49-0.87-0.74-0.85-0.96c0.42-7.83-1.38-14.89-6.61-21.01
				C723.97,408.38,723.99,406.05,723.32,403.62z"/>
			<path onClick={() => props.onFill(353)} fill={props.fillColors[353]}  d="M665.11,988.03c7.77,3.84,7.76,4.21,0.28,12.25c0.04,0.15,0.04,0.36,0.14,0.45
				c2.06,1.8,6.74,1.81,4.38,6.59c-2.05,4.15-6.55,5.96-10.94,4.21c-1.34-0.53-2.53-1.44-5.53-3.2c2.12-1.28,4.21-1.8,4.75-3.01
				c1.15-2.56,1.56-5.47,2.14-8.26c0.35-1.68,0.33-3.44,0.69-5.12c0.4-1.9,2.05-4.33,1.38-5.51c-2.19-3.85-0.72-6.73,1.09-9.92
				c0.61-1.08,0.73-2.58,0.67-3.87c-0.17-3.95-0.53-7.89-0.85-12.37c2.45,0.19,4,0.3,6.59,0.5c-2.05,1.78-3.29,2.86-4.59,3.99
				c7.02,4.89,7.02,4.89,0.17,10.98C671.83,980.74,671.83,980.74,665.11,988.03z"/>
			<path onClick={() => props.onFill(354)} fill={props.fillColors[354]}  d="M760.72,971.66c-8.02-1.36-15.17-2.51-22.29-3.83c-3.41-0.63-7.32-0.29-8.65-5.12
				c-0.29-1.05-3.44-1.52-5.34-1.89c-10.7-2.09-21.41-4.06-32.05-6.47C713.64,949.61,749.46,958.59,760.72,971.66z"/>
			<path onClick={() => props.onFill(355)} fill={props.fillColors[355]}  d="M912.14,775.9c-1.47,0.56-1.91,0.82-2.38,0.9c-11.94,1.86-13.77,0.47-12.56-11.41
				c0.99-9.76,2.89-19.42,4.38-29.12c0.67-0.02,1.33-0.04,2-0.06C906.41,749.35,909.24,762.5,912.14,775.9z"/>
			<path onClick={() => props.onFill(356)} fill={props.fillColors[356]}  d="M662.18,514c-1.35,10.66-2.78,21.43-4.07,32.21c-0.6,5.07-4.69,6.77-8.29,8.78
				c-2.21,1.23-4.08-0.1-2.79-2.44c2.19-3.97,0.89-7.95,1.33-11.83c0.73-6.37-0.94-13.87,5.26-18.98c0.91-0.75,0.85-2.75,1.1-4.2
				C655.6,512.38,656.4,511.93,662.18,514z"/>
			<path onClick={() => props.onFill(357)} fill={props.fillColors[357]}  d="M439.74,381.09c-11.7,0-23.07,0.18-34.41-0.22c-1.49-0.05-3.5-2.84-4.15-4.74
				c-0.73-2.15-0.3-4.69,3.12-4.93c3.13-0.22,6.23-0.7,9.35-1.06c0.33-0.04,0.85-0.19,0.96-0.05c4.29,5.87,9.62,0.9,14.41,1.4
				c1.63,0.17,3.33-0.09,4.91,0.26C440.45,373.17,441.01,374.15,439.74,381.09z"/>
			<path onClick={() => props.onFill(358)} fill={props.fillColors[358]}  d="M602.3,229.4c-2.46-2.85-4.41-5.14-6.38-7.41c-0.54-0.62-1.53-1.14-1.62-1.8
				c-0.85-5.84-2.49-11.78-2.06-17.55c0.54-7.26,4.57-9.48,14.94-9.93c-0.92,6.44-1.95,12.73-2.69,19.06
				c-0.48,4.06-0.43,8.18-0.85,12.25C603.42,225.99,602.68,227.92,602.3,229.4z"/>
			<path onClick={() => props.onFill(359)} fill={props.fillColors[359]}  d="M102.2,848.39c6.66-4.91,12.23-9.06,17.87-13.12c0.73-0.52,1.91-0.89,2.74-0.71
				c9.48,2.12,18.92,4.35,28.26,7.36c-4.96,0-9.93,0.15-14.88-0.05c-3.9-0.16-6.89,1.13-8.61,4.62c-2.61,5.3-6.51,6.27-11.91,4.56
				C111.77,849.83,107.6,849.42,102.2,848.39z"/>
			<path onClick={() => props.onFill(360)} fill={props.fillColors[360]}  d="M552.39,216.41c-1.91,3.35-3.65,6.82-5.78,10.02c-1.57,2.35-2.8,4.58,0.27,6.27
				c5.29,2.91,5.6,8.19,6.3,13.05c0.45,3.13,0.68,5.55,3.79,7.45c1.37,0.84,2.43,4.16,1.9,5.8c-1.65,5.09-4.12,9.92-6.68,14.87
				c0.2-1.13,0.31-2.28,0.61-3.38c1.02-3.8,3.61-7.39-1.22-11.04c-1.54-1.16-0.97-5.33-1.11-8.13c-0.26-5.07-1.61-9.58-6.16-12.42
				c-5.49-3.43-7.33-8.52-6.32-14.57c0.98-5.86,6.1-6.85,10.77-8.14c0.79-0.22,1.59-0.42,2.39-0.62
				C551.55,215.85,551.97,216.13,552.39,216.41z"/>
			<path onClick={() => props.onFill(361)} fill={props.fillColors[361]}  d="M797.79,770.89c-0.43-6.98,2.49-12.74,4.93-18.73c1.02-2.51,0.22-5.72,0.45-8.6
				c0.29-3.57,0.8-7.11,1.22-10.67c0.55-0.01,1.1-0.01,1.65-0.02c0.84,2.85,2.02,5.64,2.44,8.55c1.09,7.52,1.23,15.18,6.24,21.69
				c0.97,1.26,0.38,4.89-0.81,6.32c-1.07,1.28-3.95,1.27-6.06,1.4C804.38,771.04,800.88,770.89,797.79,770.89z"/>
			<path onClick={() => props.onFill(362)} fill={props.fillColors[362]}  d="M671.68,962.46c3.13,0.14,6.26,0.28,9.39,0.42c0.1,0.42,0.21,0.84,0.31,1.26
				c-1.36,1.21-2.72,2.42-4.13,3.67c7.15,4.96,7.15,4.96,0.59,11.69c1.67,2.45,4.62,4.87,4.47,7.07c-0.17,2.39-3.22,4.58-5.22,7.13
				c0,0,0.07,0.62,0.4,0.81c6.89,4.15,6.8,5.83-0.03,10.5c-1.96,1.34-3.24,3.68-4.56,5.24c-0.18-3.59-0.36-7.16-0.54-10.73
				c1.75-4.17,2.65-8.37-1.67-12.48c3.45-3.86,4.3-7.71,0.51-11.45C674.74,969.91,674.74,969.69,671.68,962.46z"/>
			<path onClick={() => props.onFill(363)} fill={props.fillColors[363]}  d="M99.78,323.14c0.51-1.04,0.66-2.03,1.21-2.38c2.84-1.8,13.81-0.12,16.6,1.95c1.7,1.26,3.97,1.87,6.07,2.42
				c2.21,0.59,4.53,1,6.81,1.08c3.17,0.11,6.36-0.15,9.54-0.3c0.47-0.02,0.93-0.46,1.4-0.46c10.22-0.08,20.43-0.17,30.65-0.11
				c1.24,0.01,2.47,0.85,3.7,1.34c3.74,1.49,7.5,2.93,11.2,4.51c1.1,0.47,2.03,1.37,3.04,2.07c-0.36,0.76-0.73,1.53-1.09,2.29
				c-4.47-1.53-9.08-2.75-13.38-4.68c-6.69-3-13.52-2.67-20.49-1.87c-3.12,0.36-6.26,0.48-9.39,0.74c-3.56,0.3-7.11,0.68-10.67,0.95
				c-1.31,0.1-2.83,0.38-3.91-0.14c-5.23-2.51-11.34-1.22-16.59-5.24C111.22,322.81,105.28,323.81,99.78,323.14z"/>
			<path onClick={() => props.onFill(364)} fill={props.fillColors[364]}  d="M182.08,1056.99c8.95-2.42,16.4-4.62,23.96-6.33c1.85-0.42,4.19,0.46,6.09,1.26
				c4.06,1.71,7.99,3.75,13.36,6.33c-9.7,2.63-17.86,5.31-26.22,6.84c-2.55,0.47-5.71-2.16-8.52-3.51
				C188.23,1060.37,185.81,1058.98,182.08,1056.99z"/>
			<path onClick={() => props.onFill(365)} fill={props.fillColors[365]}  d="M419.36,792.02c-9.89,1.71-11.45,0.5-12.37-8.22c-0.2-1.91-0.78-4.02-1.88-5.53
				c-1.43-1.94-4.88-2.73-2.73-6.25c1.29-2.11,2.46-4.29,3.64-6.46c0.46-0.85,0.67-1.85,1.18-2.66c0.81-1.26,1.78-2.41,2.68-3.61
				c0.95,1.03,2.08,1.96,2.8,3.13c0.68,1.1,1.07,2.44,1.34,3.73C415.8,774.64,417.53,783.14,419.36,792.02z"/>
			<path onClick={() => props.onFill(366)} fill={props.fillColors[366]}  d="M817.01,446.38c-3.73,8.71-7.17,16.73-10.81,25.24c-6.98-6.17-13.48-11.91-21.07-18.61
				c6.84-0.44,12.27,0.08,17.15-1.33C807.16,450.27,811.19,446.09,817.01,446.38z"/>
			<path onClick={() => props.onFill(367)} fill={props.fillColors[367]}  d="M2.25,943.63c1.11-1.31,1.82-2.81,3-3.4c1.9-0.94,4.06-1.46,6.17-1.84c3.09-0.54,4.17-1.85,1.06-6
				c12.03,4.12,23.85,3.57,33.87,11.48c-6.18,0.3-11.36,0.3-16.47,0.91c-2.66,0.32-5.16,1.84-7.79,2.58
				c-1.43,0.4-3.08,0.68-4.48,0.36C12.84,946.63,8.15,945.23,2.25,943.63z"/>
			<path onClick={() => props.onFill(368)} fill={props.fillColors[368]}  d="M623.08,199.31c-0.23-1.29-0.38-1.77-0.38-2.25c-0.12-11.01-1.26-10.67,9.17-12.27
				c4.24-0.65,8.4-1.75,12.63-2.44c1.39-0.22,2.89,0.23,4.24,0.36c0.94,7.36-2.56,11.91-9.49,13.09c-2.76,0.47-5.43,1.45-8.17,2.06
				C628.53,198.43,625.93,198.8,623.08,199.31z"/>
			<path onClick={() => props.onFill(369)} fill={props.fillColors[369]}  d="M509.51,255.17c-0.93-1.8-2.33-3.51-2.71-5.42c-1.12-5.58-5.28-8.14-10.15-5.25
				c-3.65,2.16-6.88,5.14-10.02,8.04c-4.61,4.25-6.06,4.33-8.92-1.04c-2.46-4.63-4.57-9.48-6.33-14.42c-0.4-1.12,1.3-2.98,2.04-4.5
				c1.04,0.9,2.31,1.65,3.05,2.75c0.71,1.06,0.77,2.53,1.3,3.74c2.3,5.31,6.91,6.47,10.93,2.44c3.34-3.35,6.08-4.88,9.8-0.41
				c0.8,0.97,3.62,0.48,5.47,0.28c2.4-0.25,4.08-0.28,4.6,2.84c0.58,3.45,1.85,6.78,2.81,10.17
				C510.75,254.64,510.13,254.9,509.51,255.17z"/>
			<path onClick={() => props.onFill(370)} fill={props.fillColors[370]}  d="M693.98,319.22c-0.43-5.88,1.24-7.63,6.41-7.39c3.11,0.14,7.32-1.42,9.15,0.08
				c5.59,4.62,11.46,1.45,17.08,1.81c7.42,0.48,8.65,1.29,8.02,8.67c-6.72,0-14.27,1.94-19.95-0.5
				C707.7,318.91,700.93,321,693.98,319.22z"/>
			<path onClick={() => props.onFill(371)} fill={props.fillColors[371]}  d="M778.63,493.78c-2.24-7.86-5.87-14.85-15.02-13.77c-6.88,0.81-14.93-0.15-19.46,6.77
				c-3.75-0.32-7.35-0.63-10.12-0.87c3.56-2.39,7.64-6.62,12.53-8.04c6.73-1.97,14.07-2.15,21.18-2.44
				c3.97-0.16,7.99,1.26,12.01,1.53c3.83,0.26,5.13,2.3,4.34,5.58c-0.92,3.77-2.43,7.41-3.68,11.1
				C779.8,493.69,779.21,493.73,778.63,493.78z"/>
			<path onClick={() => props.onFill(372)} fill={props.fillColors[372]}  d="M288.4,1008.62c-2.87,5.45-5.54,10.53-8.12,15.41c-2.8-7.77-7.12-14.51-16.81-16.21
				c1.68-5.13,4.75-8.49,9.3-9.33C280.82,997.02,285.13,1002.34,288.4,1008.62z"/>
			<path onClick={() => props.onFill(373)} fill={props.fillColors[373]}  d="M306.42,1197.52c-6.96,0-13.49,0-20.99,0c1.96-7.48,3.82-14.56,5.92-22.57c1.37,3.58,2.41,6.3,3.51,9.16
				c6.93-1.76,2.53-8.22,6.38-11.6C302.96,1180.78,304.64,1188.92,306.42,1197.52z"/>
			<path onClick={() => props.onFill(374)} fill={props.fillColors[374]}  d="M623.21,183.01c-0.85-8.36,1.19-11.93,8.61-13.33c5.02-0.95,10.23-0.94,15.35-1.31
				c2.92-0.21,3.45,1.82,2.94,3.91c-0.62,2.52,2.16,6.57-2.57,7.35C639.58,180.94,631.56,181.87,623.21,183.01z"/>
			<path onClick={() => props.onFill(375)} fill={props.fillColors[375]}  d="M231.65,1104.69c5.16-7.71,11.86-7.97,18.41-8.4c0.92-0.06,2.43,0.8,2.8,1.63
				c3.06,6.68,9.9,7.47,15.44,10.31c1.64,0.84,2.96,2.31,4.43,3.5c-0.29,0.59-0.57,1.17-0.86,1.76
				C258.79,1110.63,245.71,1107.77,231.65,1104.69z"/>
			<path onClick={() => props.onFill(376)} fill={props.fillColors[376]}  d="M518.05,310.88c-29.76-2.74-59.81-8.09-87.35,9.76c4.61-4.55,8.44-9.84,15.46-10.52
				c1.56-0.15,3.07-1.1,4.53-1.83c6.49-3.25,13.33-3.33,20.29-2.4c0.82,0.11,1.76,0.77,2.39,0.52c10.67-4.24,21.38-0.68,32.04-0.13
				c4.39,0.23,8.72,1.59,13.08,2.44C518.35,309.45,518.2,310.16,518.05,310.88z"/>
			<path onClick={() => props.onFill(377)} fill={props.fillColors[377]}  d="M4.08,976.25c9.21,3.28,17.13,8.11,20.62,18.12c1.21,3.47,0.36,5.35-3.73,5.44
				c-2.85,0.06-5.69,0.84-8.78,1.33c-3.13-7.6-6.5-15.76-9.86-23.93C2.91,976.89,3.49,976.57,4.08,976.25z"/>
			<path onClick={() => props.onFill(378)} fill={props.fillColors[378]}  d="M586.87,538.31c1.79-6.38,5.59-9.12,11.01-9.05c6.03,0.07,12.07-0.09,18.1-0.14
				c1.32-0.01,2.79-0.39,3.93,0.05c1.55,0.6,2.85,1.84,4.26,2.81c-1.59,2.11-3.09,5.97-4.79,6.06
				C608.76,538.59,598.1,538.31,586.87,538.31z"/>
			<path onClick={() => props.onFill(379)} fill={props.fillColors[379]}  d="M315.58,365.77c3.08-7.01,6.95-8.2,12.93-4.22c1.03,0.69,2.7,0.71,4.03,0.58
				c3.61-0.36,7.21-0.89,10.8-1.48c2.78-0.45,5.56-0.98,8.29-1.68c2.71-0.7,5.35-1.66,9.11-2.84c-1.25,5.24-1.42,8.54-7.11,8.33
				c-3.43-0.13-6.97,2.41-10.58,3.79c-5.38-4.91-10.35,0.88-15.73,0.68C323.69,367.95,319.82,366.91,315.58,365.77z"/>
			<path onClick={() => props.onFill(380)} fill={props.fillColors[380]}  d="M852.9,889c0,1.15,0.19,1.88-0.03,2.03c-5.49,3.85-4.9,9.17-4.19,14.88c0.3,2.39-0.95,5.2-2.13,7.5
				c-2.65,5.2-4.44,5.66-9.06,3.94c-0.59-6.72-1.28-12.82-1.52-18.95c-0.05-1.2,1.34-3.05,2.53-3.59
				C843.1,892.7,847.87,890.99,852.9,889z"/>
			<path onClick={() => props.onFill(381)} fill={props.fillColors[381]}  d="M356.84,1038.28c3.42,3.78,3.55,5.71-0.43,8.14c-5.19,3.18-10.32,5.24-17.22,4.23
				c-5.77-0.85-13.1-1.27-17.76,5.25c-1.37,1.91-4.08,3.26-6.44,3.99c-5.32,1.62-10.81,2.69-16.23,3.99
				c-0.16-0.54-0.33-1.08-0.49-1.63c4.93-1.65,10-2.98,14.74-5.08c2.07-0.92,3.93-3.15,5-5.24c1.07-2.11,1.04-4.78,1.63-7.93
				c2.14,0.55,4.21,0.61,5.72,1.55c7.01,4.38,14.5,3.59,21.47,1.07C350.67,1045.23,353.53,1041.15,356.84,1038.28z"/>
			<path onClick={() => props.onFill(382)} fill={props.fillColors[382]}  d="M561.31,250.82c-4.12-0.51-5.02-1.89-5.44-5.01c-0.62-4.53-2.46-8.94-4.13-13.26
				c-0.45-1.16-2.19-1.83-4.02-3.26c7-3.67,3.65-14.84,13.59-16.79C561.31,225.29,561.31,237.8,561.31,250.82z"/>
			<path onClick={() => props.onFill(383)} fill={props.fillColors[383]}  d="M445.17,367.85c-0.48-4.62,2.68-6.78,6.01-7.32c10.72-1.75,21.52-3.36,32.35-4.02
				c4.32-0.26,8.3,2.73,10.58,8.14C477.09,360.36,461.45,364.6,445.17,367.85z"/>
			<path onClick={() => props.onFill(384)} fill={props.fillColors[384]}  d="M947.93,814.19c1.23,12.22-10.61,18.79-25.01,19.75c-1.6-5.15,0.26-10.13,5.56-12.49
				C934.76,818.67,941.35,816.61,947.93,814.19z"/>
			<path onClick={() => props.onFill(385)} fill={props.fillColors[385]}  d="M198.42,969.27c0-5.3-0.14-10.41,0.13-15.51c0.05-0.85,1.8-2.09,2.89-2.24c0.75-0.1,2.21,1.21,2.5,2.15
				c0.95,3.09,0.53,7.87,2.45,9.07c2.47,1.54,6.71,0.23,10.18,0.16c0.95-0.02,1.91,0,3.21,0c0,4.25,0,8.14,0,11.94
				C211.42,977.31,206.02,970.17,198.42,969.27z"/>
			<path onClick={() => props.onFill(386)} fill={props.fillColors[386]}  d="M628.21,1046.39c-5.71-8.92-4.42-25.77,2.85-34.28c3.21,1.56,6.43,3.12,9.28,4.5
				C636.26,1026.62,632.23,1036.5,628.21,1046.39z"/>
			<path onClick={() => props.onFill(387)} fill={props.fillColors[387]}  d="M307.55,1083.37c1.92,7.27,3.81,14.56,5.79,21.81c0.29,1.07,1.26,1.96,1.53,3.03
				c0.44,1.76,1.35,4.11,0.6,5.27c-0.83,1.28-3.27,2-5.05,2.07c-3.35,0.12-6.72-0.4-10.61-0.69c2.26-10.29,4.61-21.02,6.97-31.75
				C307.04,1083.2,307.29,1083.28,307.55,1083.37z"/>
			<path onClick={() => props.onFill(388)} fill={props.fillColors[388]}  d="M72.99,1085.31c-3.98,2.54-7.89,2.89-12.09-0.08c-8.09-5.73-16.28-11.31-24.48-16.88
				c-5.24-3.55-5.28-3.49-5.53-10.09C44.75,1067.17,58.79,1076.19,72.99,1085.31z"/>
			<path onClick={() => props.onFill(389)} fill={props.fillColors[389]}  d="M136.83,910.92c0,7.22,0,13.29,0,20.3c-8.8-2.36-17.02-4.56-25.25-6.76c-0.12-0.4-0.25-0.79-0.37-1.19
				C119.49,919.28,127.76,915.29,136.83,910.92z"/>
			<path onClick={() => props.onFill(390)} fill={props.fillColors[390]}  d="M433.79,422.72c6.95,0,12.32-0.36,17.62,0.12c3.93,0.35,6.47-0.07,7.1-4.55c0.33-2.33,7.12-4.75,9-3.26
				c0.52,0.42,0.86,2.04,0.52,2.44c-4.32,4.93-8.59,9.93-13.26,14.52c-5.3,5.2-9.37-0.32-13.54-2.41
				C438.76,428.34,436.98,425.74,433.79,422.72z"/>
			<path onClick={() => props.onFill(391)} fill={props.fillColors[391]}  d="M602.27,340.17c-1.68,5.66-4.53,7.89-9.83,5.96c-1.18-0.43-2.64-0.33-3.94-0.17
				c-10.68,1.35-21.35,2.78-32.03,4.18c-0.28-0.37-0.56-0.74-0.85-1.1c2.06-1.81,3.88-4.68,6.23-5.22
				c5.37-1.23,11.01-1.23,16.53-1.89c3.75-0.45,7.45-1.42,11.2-1.7C593.71,339.93,597.88,340.17,602.27,340.17z"/>
			<path onClick={() => props.onFill(392)} fill={props.fillColors[392]}  d="M313.46,362.06c-2.95,8.25-10.32,10.61-19.01,5.83c-3.62-1.99-7.9-2.77-11.78-4.07
				c4.22-7.4,10.92-9.39,19.6-5.65C305.76,359.67,309.46,360.68,313.46,362.06z"/>
			<path onClick={() => props.onFill(393)} fill={props.fillColors[393]}  d="M347.27,734.08c1.62-0.4,2.95-0.75,4.3-1.04c1.09-0.23,2.73,0.01,3.21-0.64c2.61-3.48,5.11-5.39,9.16-1.68
				c0.56,0.51,3.07-1.12,5.06-1.91c4.68,1.27,1.24,11.36,9.78,10.28c-2.92,1.59-5.8,3.24-8.76,4.76c-4.11,2.11-7.55,0-10.88-2.02
				C355.39,739.54,351.77,737.03,347.27,734.08z"/>
			<path onClick={() => props.onFill(394)} fill={props.fillColors[394]}  d="M467.04,279.91c-4.19-7.25-8.11-13.41-11.38-19.9c-3.27-6.48-6.01-13.24-8.64-20.01
				c-0.45-1.16,0.78-2.98,1.21-4.4c4.84,6.32,9.4,11.87,13.52,17.74C467.27,261.2,467.94,270.1,467.04,279.91z"/>
			<path onClick={() => props.onFill(395)} fill={props.fillColors[395]}  d="M222.15,1045.52c-13.43-2.17-12.67-15.94-20.79-22.4c0.66-0.54,1.46-1.66,1.98-1.54
				c7.03,1.63,13.23,4.67,18.68,9.67C227.73,1036.49,222.25,1040.69,222.15,1045.52z"/>
			<path onClick={() => props.onFill(396)} fill={props.fillColors[396]}  d="M641.97,322.38c0.72-9.26,0.56-18.73,5.36-27.18c1-1.76,3.51-3.08,5.59-3.71c3.3-1,5.13-0.03,4.93,4.25
				c-0.21,4.61-1.85,8.09-4.58,11.65c-2.67,3.49-4.43,7.68-6.71,11.49c-0.9,1.5-2.16,2.79-3.26,4.17
				C642.86,322.84,642.41,322.61,641.97,322.38z"/>
			<path onClick={() => props.onFill(397)} fill={props.fillColors[397]}  d="M625.1,229.61c-0.84-8.12-1.8-16.24-2.36-24.38c-0.07-1.05,1.73-2.9,2.98-3.26
				c4.86-1.38,9.84-2.31,14.65-3.38c-4.79,10.41-9.52,20.7-14.25,31C625.79,229.59,625.44,229.6,625.1,229.61z"/>
			<path onClick={() => props.onFill(398)} fill={props.fillColors[398]}  d="M360.28,300.78c-1.16,8.09-9.13,15.86-16.29,16.91c-2.64,0.39-5.1,2.03-7.63,3.13
				c-1.65,0.72-3.28,1.5-4.92,2.25c-0.45-0.52-0.9-1.04-1.35-1.56c2.82-3.55,5.76-7.01,8.43-10.68c1.73-2.38,3.07-5.05,4.57-7.58
				C351.36,305.88,351.36,305.88,360.28,300.78z"/>
			<path onClick={() => props.onFill(399)} fill={props.fillColors[399]}  d="M74.88,322.14c0-1.13-0.27-2.35,0.04-3.39c4.15-13.75,8.31-27.5,12.69-41.17
				c0.41-1.27,2.31-2.05,3.52-3.06c0.74,1.47,1.93,2.89,2.1,4.43c0.18,1.55-0.14,3.68-1.12,4.74c-6.19,6.75-5.26,15.79-8.03,23.6
				c-1.92,5.41-4.95,10.43-7.48,15.62C76.02,322.65,75.45,322.39,74.88,322.14z"/>
			<path onClick={() => props.onFill(400)} fill={props.fillColors[400]}  d="M346.08,974.27c8.82,20.77,4.1,42.03,8,62.34c-0.61,0.1-1.23,0.2-1.84,0.3
				c-0.95-4.99-2.02-9.96-2.83-14.97c-1.52-9.38-3.12-18.75-4.21-28.18c-0.55-4.77-4.72-9.04-1.98-14.23
				C344.04,978,344.88,976.48,346.08,974.27z"/>
			<path onClick={() => props.onFill(401)} fill={props.fillColors[401]}  d="M790.24,389.83c-11.18-1.15-22.36-2.3-34.1-3.51c0.82-1.45,0.92-1.9,1.15-1.98
				c9.45-3.18,18.86-7.91,29.02-4.69c2.42,0.77,3.52,5.73,5.23,8.76C791.11,388.89,790.67,389.36,790.24,389.83z"/>
			<path onClick={() => props.onFill(402)} fill={props.fillColors[402]}  d="M282.89,422.25c0.32,0.11-0.79-0.24-1.85-0.68c-3.95-1.64-4.46-5.09-1.14-7.69
				c4.12-3.22,8.21-6.47,12.39-9.6c0.7-0.52,1.79-0.53,3.25-0.92c-0.28,1.28-0.73,2.11-0.57,2.78c0.48,1.93,1.21,3.79,1.84,5.68
				c1.65-0.86,3.51-1.47,4.91-2.63c2.79-2.32,5.32-4.94,8.66-6.59c-1.3,2.33-2.33,4.89-4,6.92c-1.27,1.55-3.27,2.61-5.11,3.56
				C295.63,415.99,289.91,418.76,282.89,422.25z"/>
			<path onClick={() => props.onFill(403)} fill={props.fillColors[403]}  d="M731.29,384.31c1.02-0.34,2.03-0.74,3.07-1.02c7.83-2.09,15.68-4.08,23.48-6.24
				c7.65-2.12,15.22-4.56,22.9-6.56c4.84-1.26,6.03-0.07,6.67,6.09c-2.85-0.24-5.65-0.89-8.36-0.59c-4.01,0.45-7.94,1.55-11.9,2.42
				c-1.76,0.39-3.51,0.83-5.23,1.37c-2.17,0.69-4.85,0.92-6.35,2.36c-7.52,7.2-16.04,2.58-24.06,3.56
				C731.44,385.23,731.37,384.77,731.29,384.31z"/>
			<path onClick={() => props.onFill(404)} fill={props.fillColors[404]}  d="M107.21,840.96c-3.22,2.48-6.45,4.95-10,7.68c7.82,6.99-3.36,11.85-0.39,18.97
				c-4.93-0.69-8.75-1.23-13.48-1.89c5.02-8.81,9.22-17.11,14.45-24.72c1.23-1.79,5.9-1.22,8.98-1.74
				C106.91,839.83,107.06,840.39,107.21,840.96z"/>
			<path onClick={() => props.onFill(405)} fill={props.fillColors[405]}  d="M860.93,849.26c-6.55,2.99-12.53,5.73-18.53,8.44c-1.49,0.67-3.18,1.99-4.49,1.71
				c-2.33-0.51-5.56-1.48-6.41-3.23c-1.72-3.55,1.4-5.2,4.4-6.28c4.83-1.74,9.61-3.66,14.5-5.19
				C855.27,843.18,858.64,845.41,860.93,849.26z"/>
			<path onClick={() => props.onFill(406)} fill={props.fillColors[406]}  d="M607.75,210.92c0.89-7.99,1.37-15.37,2.8-22.57c0.28-1.4,4.19-2.31,6.56-2.84
				c0.47-0.11,2.15,2.51,2.25,3.95c0.33,4.6-0.01,9.25,0.29,13.86c0.3,4.56-2.05,6.14-6.05,6.53
				C611.86,210.01,610.15,210.46,607.75,210.92z"/>
			<path onClick={() => props.onFill(407)} fill={props.fillColors[407]}  d="M255.1,1094.69c7.62-2.83,14.73-5.99,22.13-8.06c5.29-1.48,4.78,4.04,6.07,6.11
				c-6.09,3.39-11.95,6.73-17.9,9.91C262.59,1104.15,258.05,1100.83,255.1,1094.69z"/>
			<path onClick={() => props.onFill(408)} fill={props.fillColors[408]}  d="M251.18,983.64c6.64-0.45,11.77-1.1,16.89-1.04c4.58,0.05,6.06-1.71,5.91-6.19
				c-0.21-6.37,0.17-6.41,6.83-6.38c2.57,0.01,4.05,0.55,4.06,3.55c0.01,2.69-1.31,6.4,3.83,6.25c4.88-0.14,4.37-3.49,4.42-6.66
				c0.01-0.65,0.06-1.3,0.15-2.94c0,5.02,0,9.03,0,13.94c-3.83-3.49-7.04-4.86-10.02-0.37c-0.36,0.54-1.46,0.8-2.23,0.84
				c-7.78,0.44-15.56,0.93-23.34,1.1C255.88,985.78,254.05,984.61,251.18,983.64z"/>
			<path onClick={() => props.onFill(409)} fill={props.fillColors[409]}  d="M582.11,376.11c-0.28,1.3-0.33,1.79-0.5,2.24c-3.29,8.74-3.29,8.74-12.39,6.31
				c-2.43-0.65-5.78-0.58-7.06-2.17c-1.69-2.11-2.16-5.5-2.31-8.39c-0.06-1.11,2.47-3.54,3.35-3.33
				C569.57,372.26,575.82,374.27,582.11,376.11z"/>
			<path onClick={() => props.onFill(410)} fill={props.fillColors[410]}  d="M260.77,911.98c0,2.48-0.22,4.99,0.05,7.45c0.44,4.12-1.2,5.33-5.28,5.46c-4.66,0.15-4.61-2.42-4.56-5.62
				c0.07-4.47-0.3-8.98,0.16-13.4c0.2-2,1.56-4.25,3.08-5.62c1.26-1.14,3.52-1.18,5.34-1.7c0.4,1.66,1.04,3.31,1.16,5
				C260.92,906.34,260.77,909.16,260.77,911.98z"/>
			<path onClick={() => props.onFill(411)} fill={props.fillColors[411]}  d="M236.84,721.78c-2.1,0-4.36-0.49-6.27,0.11c-2.93,0.93-5.62,2.61-8.42,3.97
				c-0.29,0.14-0.68,0.38-0.92,0.29c-5.83-2.06-10.05,0.97-14.74,3.89c-4.53,2.82-8.32-0.84-12.74-3.96
				c4.63-1.84,8.74-3.2,12.61-5.07c4.82-2.33,9.35-5.81,14.44-0.48c0.56,0.58,2.57,0.66,3.2,0.13c4.39-3.68,8.59-0.85,12.85-0.36
				C236.84,720.8,236.84,721.29,236.84,721.78z"/>
			<path onClick={() => props.onFill(412)} fill={props.fillColors[412]}  d="M555.74,923.66c-6.61,6.71-7.1,10.69-2.02,18.46c1.36,2.08,3.04,3.99,4.13,6.2
				c0.73,1.48,1.44,4.28,0.78,4.83c-1.25,1.04-3.62,1.49-5.3,1.14c-8.63-1.77-11.49-6.51-9.29-15.59
				C545.66,932.03,549.16,926.54,555.74,923.66z"/>
			<path onClick={() => props.onFill(413)} fill={props.fillColors[413]}  d="M835.71,224.98c-2.6-0.4-5.25-0.58-7.78-1.23c-13.95-3.61-28.09-6.7-41.72-11.32
				c-6.59-2.23-12.18-7.41-18.22-11.26c0.29-0.54,0.58-1.08,0.86-1.62c1.47,0.59,2.94,1.16,4.39,1.78c1.52,0.66,3.05,1.32,4.53,2.06
				c13.7,6.87,27.87,12.28,43.01,15.21c5.12,0.99,9.95,3.54,14.91,5.37C835.7,224.32,835.71,224.65,835.71,224.98z"/>
			<path onClick={() => props.onFill(414)} fill={props.fillColors[414]}  d="M721.21,971.62c11.7,2.44,23.4,4.88,35.45,7.39c-3.77,7.2-5.88,8.87-11.81,6.6
				c-8.4-3.22-16.32-7.7-24.44-11.65C720.68,973.18,720.94,972.4,721.21,971.62z"/>
			<path onClick={() => props.onFill(415)} fill={props.fillColors[415]}  d="M551.01,265.44c-0.94,3.9-1.31,6.56-2.28,8.97c-0.53,1.31-1.92,2.54-3.23,3.23
				c-4.61,2.4-9.32,4.6-14.05,6.75c-1.87,0.85-3.88,1.37-5.93,2.07c-0.61-5.95,2.37-11.23,6.91-13.29c3.9-1.78,7.65-3.89,11.56-5.65
				C545.85,266.67,547.94,266.32,551.01,265.44z"/>
			<path onClick={() => props.onFill(416)} fill={props.fillColors[416]}  d="M428.92,304.36c2.61-5.7,7.26-8.8,12.65-10.91c15.64-6.12,32.03-5.73,48.36-5.14
				c7.48,0.27,14.92,1.68,22.23,3.52c-14.32-0.34-28.64-1.08-42.95-0.89C454.55,291.13,440.74,294.71,428.92,304.36z"/>
			<path onClick={() => props.onFill(417)} fill={props.fillColors[417]}  d="M326.2,1197.69c-3.4,0-7.19,0-11.71,0c-0.15-3.34-0.69-6.57-0.29-9.67c0.28-2.21,2.23-4.17,2.73-6.4
				c0.85-3.79,1.16-7.71,1.74-11.93c3.23,1.03,4.95,2.6,4,6.39c-0.31,1.22,0.7,2.75,1.07,4.15c0.28,1.08,0.59,2.16,0.73,3.26
				C325.06,1188.08,325.59,1192.67,326.2,1197.69z"/>
			<path onClick={() => props.onFill(418)} fill={props.fillColors[418]}  d="M84.9,314.27c1.67-6.47,3.31-13.96,5.59-21.25c1.53-4.9,4.58-9.36,5.8-14.29
				c0.88-3.58,2.32-3.81,4.67-3.25c-1.67,9.59-3.26,19.07-5.06,28.52c-0.25,1.33-1.29,2.76-2.36,3.65
				C90.41,310.26,87.08,312.62,84.9,314.27z"/>
			<path onClick={() => props.onFill(419)} fill={props.fillColors[419]}  d="M741.04,330.22c2.8,3.31,5.46,7.02,8.73,10.08c1.66,1.56,4.33,2.3,6.67,2.8
				c5.47,1.17,11.02,1.98,16.47,3.33c-16.13,0.48-31.55-3.32-47-7.75C730.82,335.94,735.6,333.27,741.04,330.22z"/>
			<path onClick={() => props.onFill(420)} fill={props.fillColors[420]}  d="M142.02,913.54c0.99,1.3,2.82,2.59,2.84,3.9c0.21,13.66,0.18,27.32,0.05,40.98
				c-0.01,1.07-1.33,2.13-2.05,3.2c-0.84-0.79-2.41-1.58-2.42-2.38c-0.15-14.94-0.11-29.89-0.11-44.84
				C140.89,914.11,141.45,913.82,142.02,913.54z"/>
			<path onClick={() => props.onFill(421)} fill={props.fillColors[421]}  d="M836.58,995.77c3.91-3.72,7.59-2.59,11.93-1.01c9.94,3.6,20.2,6.32,30.25,9.63
				c2.58,0.85,4.88,2.56,7.82,4.15C878.87,1010.53,856.31,1004.6,836.58,995.77z"/>
			<path onClick={() => props.onFill(422)} fill={props.fillColors[422]}  d="M584.12,571.21c7.03-4.11,14.01-8.19,21.51-12.58c0.17,3.19,1.1,6.28,0.31,8.84
				c-1.67,5.44,1.33,5.54,5.05,5.42c0.8-0.03,1.6-0.12,2.5-0.19C611.87,577.27,609.6,577.14,584.12,571.21z"/>
			<path onClick={() => props.onFill(423)} fill={props.fillColors[423]}  d="M15.29,1148.78c0.29-3,0.65-6,0.85-9.02c0.07-1.09-0.15-2.23-0.4-3.31c-1.38-6.01-2.82-12.01-4.18-18.02
				c-0.08-0.37,0.39-0.86,0.58-1.27c8.49,0.36,11.64,2.51,11.92,8.1c0.03,0.66,0.26,1.55-0.06,1.96c-4.29,5.39-3.33,12.03-4.49,18.17
				c-0.25,1.32-1.48,2.45-2.25,3.67C16.6,1148.96,15.95,1148.87,15.29,1148.78z"/>
			<path onClick={() => props.onFill(424)} fill={props.fillColors[424]}  d="M553.22,550.52c-3.94,4.41-6.61,7.96-12.65,6.81c-3.75-0.71-7.89,0.41-11.83,0.93
				c-5.06,0.66-10.09,1.53-15.51,2.36c0.24-4.46,2.94-6.36,6.28-6.83c10.01-1.42,20.08-2.47,30.13-3.62
				C550.41,550.08,551.23,550.31,553.22,550.52z"/>
			<path onClick={() => props.onFill(425)} fill={props.fillColors[425]}  d="M211.9,771.49c3.67,0,7.79-0.43,11.75,0.16c2.47,0.37,6.58-1.21,7.06,3.8c0.06,0.6,2.89,0.97,4.45,1.39
				c3.55,0.96,7.1,1.89,10.55,3.4c-6.77,4.05-13.81,4.24-20.78,2.12c-2.63-0.8-4.85-3.18-7.08-5.05
				C215.78,775.54,213.94,773.5,211.9,771.49z"/>
			<path onClick={() => props.onFill(426)} fill={props.fillColors[426]}  d="M134.46,725.98c-0.98-1.58-1.95-3.17-3.62-5.87c5.01-0.11,9.84-0.19,14.67-0.35
				c1.59-0.05,3.24-0.11,4.76-0.53c4.54-1.28,9.04-2.75,13.55-4.15c0.34,0.52,0.69,1.05,1.03,1.57c-1.4,1.03-2.68,2.67-4.21,2.97
				c-4.62,0.9-8.33,2.24-9.75,7.53c-0.48,1.77-2.93,3.92-4.74,4.21c-2.4,0.38-4.28-1.3-5.07-4.34c-0.29-1.12-3.78-1.41-5.81-2.08
				C135.01,725.28,134.74,725.63,134.46,725.98z"/>
			<path onClick={() => props.onFill(427)} fill={props.fillColors[427]}  d="M191.85,322.55c5.83-0.9,11.66-1.83,17.49-2.67c2.12-0.31,4.37-0.09,6.4-0.66
				c7.99-2.26,15.64,0.8,23.46,1.22c3.95,0.21,7.9,0.26,11.84,0.58c4.85,0.4,9.68,1.02,14.52,1.54c0.03,0.62,0.06,1.25,0.09,1.87
				c-1.11,0.26-2.24,0.82-3.33,0.73c-8.86-0.7-17.97-0.45-26.5-2.56c-10.66-2.63-21.01,0.75-31.47-0.33
				c-1.99-0.21-4.13,0.92-6.19,1.45c-1.92,0.49-3.84,1-5.77,1.5C192.21,324.33,192.03,323.44,191.85,322.55z"/>
			<path onClick={() => props.onFill(428)} fill={props.fillColors[428]}  d="M276.78,1197.66c-3.8,0-7.41,0-10.3,0c0.56-6.1,1.11-12.17,1.67-18.25c0.91-2.78,6.25-4.22,2.21-9.12
				c-0.23-0.28,1.04-1.8,1.61-2.75c0.49,0.23,0.97,0.45,1.46,0.68c0.25,3.94,0.47,7.89,0.79,11.82c0.08,0.95,0.21,2.43,0.78,2.7
				c4.67,2.22,3.67,5.26,1.9,8.93C276.19,1193.15,276.78,1195.23,276.78,1197.66z"/>
			<path onClick={() => props.onFill(429)} fill={props.fillColors[429]}  d="M567.14,558.64c-2.41,4.58-3.75,8-5.84,10.88c-2.78,3.83-15.38,1.81-16.1-2.75
				c-0.34-2.12,2.21-6.43,4.09-6.85C554.66,558.71,560.36,559,567.14,558.64z"/>
			<path onClick={() => props.onFill(430)} fill={props.fillColors[430]}  d="M855.41,894.47c9.66-15.45,12.96-33.97,23.55-48.97c0.64,0.29,1.28,0.59,1.91,0.88
				c-0.53,1.27-1.09,2.53-1.6,3.81c-3.49,8.75-6.99,17.49-10.45,26.25c-2.3,5.81-4.35,11.73-6.97,17.38
				c-0.56,1.19-3.16,1.43-4.81,2.11C856.49,895.44,855.95,894.96,855.41,894.47z"/>
			<path onClick={() => props.onFill(431)} fill={props.fillColors[431]}  d="M827.91,857.31c-8.69-0.27-8.77-0.46-9.83-7.82c-0.88-6.12-2.08-12.19-3.31-19.25
				c1.51,0.67,3.88,0.98,4.95,2.34C825.33,839.66,827.9,847.86,827.91,857.31z"/>
			<path onClick={() => props.onFill(432)} fill={props.fillColors[432]}  d="M54.09,875.27c-4.26,0.7-6.44-0.08-6.36-4.37c0.15-7.65-0.07-15.32,0.18-22.97
				c0.04-1.23,1.75-2.4,2.68-3.59c1.17,1.04,3.31,2.06,3.34,3.13C54.21,856.59,54.09,865.72,54.09,875.27z"/>
			<path onClick={() => props.onFill(433)} fill={props.fillColors[433]}  d="M65.16,252.84c-5.04,9.17-4.65,20.51-13.08,27.42c-0.59,0.48-0.74,1.49-1.67,1.86
				c0.53-8.36,5.39-16.24-0.71-24.44C55.26,255.94,60.21,254.39,65.16,252.84z"/>
			<path onClick={() => props.onFill(434)} fill={props.fillColors[434]}  d="M776.31,748.99c6.06,0.27,12.15,0.34,18.19,0.96c1.53,0.16,2.89,2.05,4.32,3.15
				c-1.34,1.64-2.42,4.31-4.07,4.73c-4.44,1.14-9.1,1.59-13.7,1.79C777.52,759.76,775.92,756.06,776.31,748.99z"/>
			<path onClick={() => props.onFill(435)} fill={props.fillColors[435]}  d="M904.72,338.93c-2.15,0.1-4.38,0.64-6.43,0.23c-8.43-1.69-16.8-3.71-25.21-5.49
				c-1.38-0.29-2.92-0.14-4.34,0.12c-2.54,0.46-12.39-3.78-13.56-6.05c-0.36-0.71-0.5-1.53-0.58-1.78
				c6.04,0.45,12.06,0.67,18.02,1.45c2.1,0.27,4.6,1.42,5.92,3c3.09,3.7,6.76,4.82,11.38,4.91c4.91,0.1,9.8,1,14.71,1.55
				C904.66,337.56,904.69,338.24,904.72,338.93z"/>
			<path onClick={() => props.onFill(436)} fill={props.fillColors[436]}  d="M462.57,241.69c3.29,5.13,6.58,10.25,10.04,15.64c-0.94,0.53-2.31,1.29-3.5,1.96
				c-5.7-7.86-11.21-15.47-17.23-23.77c14.46-0.57,20.71,6.78,23.38,20.18c-4.09-5.9-7.36-10.62-10.63-15.34
				C463.94,240.8,463.25,241.25,462.57,241.69z"/>
			<path onClick={() => props.onFill(437)} fill={props.fillColors[437]}  d="M267.3,882.5c0.26,2.79,0.47,5.07,0.68,7.3c7.75,1.64,8.76,1.02,10.73-5.63c1.31,1.68,2.57,4.64,4.74,5.64
				c1.63,0.75,5.03-0.41,6.51-1.87c1.25-1.23,0.86-4.12,2.27-6.12c0,8.39,0,16.79,0,25.18c-0.47,0.01-0.94,0.03-1.41,0.04
				c0-4.96,0-9.92,0-14.66c-9.15-2.04-10.15-1.34-11.52,7.91c-0.52-0.88-0.95-1.29-1-1.74c-0.78-6.66-4.14-8.68-9.99-5.51
				c-3.5,1.9-5.04,0.77-4.83-2.65C263.65,887.67,261.21,883.43,267.3,882.5z"/>
			<path onClick={() => props.onFill(438)} fill={props.fillColors[438]}  d="M189.39,969.99c-8.46-2.9-2.55-10.3-5.61-14.45c-6.37-0.34-7.83,0.9-8.75,7.13
				c-0.16,1.05-1.19,1.97-1.82,2.94c-0.64-0.97-1.7-1.88-1.84-2.91c-1-7.27-0.46-8.05,6.49-10.22c3.33-1.04,6.67-2,10.02-2.97
				c0.29-0.08,0.64,0.05,1.51,0.14C189.39,956.05,189.39,962.54,189.39,969.99z"/>
			<path onClick={() => props.onFill(439)} fill={props.fillColors[439]}  d="M558.63,545.66c5.38-15.31,10.29-17.18,19.08-14.54c-1.59,4.38-1.98,10.32-5.08,12.69
				C569.46,546.23,563.62,545.16,558.63,545.66z"/>
			<path onClick={() => props.onFill(440)} fill={props.fillColors[440]}  d="M596.66,552.23c-6.6,1.76-10.95,8.79-19.21,5.8c-1.82-0.66-4.38,0.72-7.05,1.25
				c1.33-3.44,0.66-8.01,5.3-9.09C590.15,546.84,593.34,547.15,596.66,552.23z"/>
			<path onClick={() => props.onFill(441)} fill={props.fillColors[441]}  d="M683.05,967.55c0.74-1.45,1.41-2.75,2.17-4.22c2.53,0.45,4.9,0.87,8.64,1.54
				c-2.79,2.33-4.55,3.81-6.14,5.14c1.79,2.03,4.97,4.07,4.94,6.04c-0.04,2.11-3.11,4.17-4.88,6.26c5.14,5.72,4.7,10.47-1.32,12.39
				c-0.92,0.29-2.46,0.2-3.01-0.39c-0.5-0.53-0.46-2.27,0.05-2.95c3.08-4.14,2.56-8.01-0.71-11.84
				C685.94,975.54,687.36,971.54,683.05,967.55z"/>
			<path onClick={() => props.onFill(442)} fill={props.fillColors[442]}  d="M86.23,1008.84c1.47-2.66,2.42-4.73,3.73-6.55c0.53-0.75,2.02-1.54,2.75-1.3
				c5.44,1.76,11.03,3.33,16.04,5.98c1.58,0.84,1.5,4.82,2.18,7.36c-2.76-0.09-6.54,0.95-8.1-0.47
				C98.23,1009.71,93.04,1008.85,86.23,1008.84z"/>
			<path onClick={() => props.onFill(443)} fill={props.fillColors[443]}  d="M257.27,1008.77c-3.4-8.64-3.24-17.42-10.53-23.1c1.89,0.62,3.83,1.13,5.68,1.88
				c4.01,1.62,8.14,3.06,11.86,5.19c1.35,0.77,2.83,4.08,2.28,4.9C264.06,1001.37,260.85,1004.62,257.27,1008.77z"/>
			<path onClick={() => props.onFill(444)} fill={props.fillColors[444]}  d="M186.38,308.64c0.11-1.72-0.12-3.55,0.4-5.14c2.27-6.99,4.18-13.91,2.43-21.4
				c-0.82-3.5,1.62-4.41,4.67-4.83c3.39-0.48,4.85,1.23,4.73,4.04c-0.13,2.81-0.73,5.75-1.82,8.34c-2.11,5.01-4.72,9.81-7.1,14.71
				c-0.74,1.52-1.38,3.08-2.07,4.63C187.21,308.87,186.79,308.76,186.38,308.64z"/>
			<path onClick={() => props.onFill(445)} fill={props.fillColors[445]}  d="M923.71,805.38c1.73-2.16,3.23-4.57,5.24-6.43c7.05-6.53,14.27-12.87,21.62-19.46
				c2.05,3.79,1.32,7.9-1.84,10.37c-7.62,5.96-15.78,11.22-23.72,16.77C924.58,806.21,924.14,805.79,923.71,805.38z"/>
			<path onClick={() => props.onFill(446)} fill={props.fillColors[446]}  d="M292.39,1007.91c-3.67-4.11-7.15-8.01-10.99-12.32c1.63-3.49,3.59-7.68,5.54-11.86
				c0.56-0.06,1.12-0.12,1.69-0.18c1.03,2.72,2.08,5.44,3.07,8.17c0.33,0.91,0.28,2.03,0.79,2.8
				C297.11,1001.45,297.14,1001.44,292.39,1007.91z"/>
			<path onClick={() => props.onFill(447)} fill={props.fillColors[447]}  d="M258.97,894.6c-3.09,1.71-6.34,3.18-9.23,5.18c-11.32,7.81-22.51,15.82-33.82,23.64
				c-1.49,1.03-3.45,1.38-5.19,2.04c-0.24-0.53-0.47-1.06-0.71-1.58c10.13-7.11,20.25-14.25,30.41-21.33
				c3.8-2.65,7.54-5.46,11.62-7.62c2.02-1.07,4.72-0.84,7.12-1.19C259.1,894.01,259.03,894.3,258.97,894.6z"/>
			<path onClick={() => props.onFill(448)} fill={props.fillColors[448]}  d="M243.5,923.63c-1.82-0.68-3.54-1.76-5.34-1.9c-3.12-0.26-6.34-0.27-9.4,0.27c-1.75,0.31-3.23,2-4.93,2.87
				c-1.81,0.93-3.74,1.63-5.62,2.43c-0.21-0.47-0.42-0.94-0.62-1.42c8.42-5.94,16.85-11.88,25.92-18.28
				C243.5,913.74,243.5,919.09,243.5,923.63z"/>
			<path onClick={() => props.onFill(449)} fill={props.fillColors[449]}  d="M694.99,969.97c1.69-5.19,3.92-6.04,7.81-3.48c4.07,2.68,4.2,5.48,0.84,10.32
				c-2.57,3.72-4.87,7.63-7.9,12.42c-2.05-4.3-3.85-6.61-1.01-10.39C696.18,976.91,694.99,972.99,694.99,969.97z"/>
			<path onClick={() => props.onFill(450)} fill={props.fillColors[450]}  d="M697.97,1017.31c2.94-4.82,5.69-4.65,9.43-3.08c6.97,2.93,14.22,5.2,21.63,7.84
				c-2.08,3.05-5.07,5.16-9.12,3.75C712.63,1023.27,705.52,1020.27,697.97,1017.31z"/>
			<path onClick={() => props.onFill(451)} fill={props.fillColors[451]}  d="M393.01,365.85c-5.26,0.08-7.83-3.04-7.85-10.22c-0.01-5.38,4.57-3.37,7.14-3.6
				c5.68-0.51,7.35,1.6,7.58,8.23C400.06,365.17,399.35,365.75,393.01,365.85z"/>
			<path onClick={() => props.onFill(452)} fill={props.fillColors[452]}  d="M706.06,1010.61c2.07-1.85,3.73-3.35,5.49-4.93c7.81,2.88,15.8,5.82,25.22,9.3
				c-2.94,1.38-4.88,3.1-6.77,3.04C721.47,1017.75,713.86,1014.25,706.06,1010.61z"/>
			<path onClick={() => props.onFill(453)} fill={props.fillColors[453]}  d="M700.55,322.7c4.08-0.72,9.16-1.24,13.32,3.07c0.47,0.49,2.39-0.65,3.64-0.63
				c5.01,0.07,10.03,0.19,15.02,0.6c0.79,0.07,1.45,1.75,2.17,2.69c-1,0.19-1.99,0.55-2.99,0.54c-3.14-0.03-6.28-0.11-9.41-0.34
				c-8.17-0.6-16.35-1.2-24.5-2.02c-1.46-0.15-2.81-1.31-4.21-2c1.47-0.76,2.92-1.57,4.43-2.24
				C698.4,322.2,698.98,322.48,700.55,322.7z"/>
			<path onClick={() => props.onFill(454)} fill={props.fillColors[454]}  d="M509.62,238.17c-4.27-0.52-8.64,1.75-12.96-1.89c-1.38-1.16-5.96-0.02-8.05,1.48
				c-5.88,4.22-7.71,3.79-9.38-3.48c-0.14-0.62-0.08-1.28-0.17-2.83c5.74,0.32,11.25,0.57,16.74,0.94c3.14,0.21,6.35,0.26,9.37,1.03
				c1.93,0.49,3.57,2.16,5.33,3.3C510.21,237.21,509.91,237.69,509.62,238.17z"/>
			<path onClick={() => props.onFill(455)} fill={props.fillColors[455]}  d="M925.81,811.99c6.5-9.25,17.35-12.61,25.24-20.06c2.47,5.9,1.86,8.92-2.88,11.03
				C940.83,806.22,933.28,809,925.81,811.99z"/>
			<path onClick={() => props.onFill(456)} fill={props.fillColors[456]}  d="M271.01,296.59c14.11-10.4,13.31-29.02,23.86-41.24c3.11,5.25,2.13,7.86-1.57,12.69
				c-4.18,5.46-6.94,11.98-10.7,17.78c-2.83,4.37-6.28,8.34-9.45,12.48C272.44,297.73,271.72,297.16,271.01,296.59z"/>
			<path onClick={() => props.onFill(457)} fill={props.fillColors[457]}  d="M546.3,956.71c-8.29-0.55-16.58-1.09-24.86-1.64c-0.11-0.5-0.22-1-0.33-1.5
				c6.24-3.19,12.48-6.39,19.22-9.84c-1.09,5.3,2,8.92,6.77,11.58C546.83,955.78,546.57,956.24,546.3,956.71z"/>
			<path onClick={() => props.onFill(458)} fill={props.fillColors[458]}  d="M721.59,1030.35c-2.4,3.35-5.78,3.13-8.89,1.88c-7.36-2.97-14.57-6.32-22.11-9.63
				c4.49-4.47,4.41-4.31,8.92-2.19C706.69,1023.79,713.98,1026.93,721.59,1030.35z"/>
			<path onClick={() => props.onFill(459)} fill={props.fillColors[459]}  d="M233.62,983.13c0.74-4.12,0.37-9.34,2.12-10.19c3.22-1.58,8.14-1.63,11.45-0.21
				c1.61,0.69,1.17,6.14,1.81,10.4C243.64,983.13,239.31,983.13,233.62,983.13z"/>
			<path onClick={() => props.onFill(460)} fill={props.fillColors[460]}  d="M741.78,1006.34c0.58,4.09-2.22,5.21-5.42,4.41c-7.14-1.78-14.14-4.13-21.8-6.43
				c1.2-2.34,1.9-3.69,2.52-4.9C725.35,1001.74,733.26,1003.96,741.78,1006.34z"/>
			<path onClick={() => props.onFill(461)} fill={props.fillColors[461]}  d="M950.09,385.86c-8.02-0.45-16.05-0.91-24.3-1.37c0.39-4.63,2.83-6.88,6.91-6.14
				c6.2,1.14,14.4-2.76,17.97,5.62C950.48,384.6,950.29,385.23,950.09,385.86z"/>
			<path onClick={() => props.onFill(462)} fill={props.fillColors[462]}  d="M597.46,524.98c6.8-0.65,13.62-1.13,20.38-2.07c2.01-0.28,4.63-1.38,5.6-2.95
				c3.99-6.46,4.01-6.68,12.74-4.91c-1.62,1.52-2.73,2.61-3.9,3.64c-1.72,1.5-4.16,2.63-5.06,4.51c-1.42,2.98-2.85,3.52-6.11,3.13
				c-4.85-0.58-9.85-0.01-14.78,0.15c-2.88,0.09-5.76,0.33-8.64,0.5C597.61,526.31,597.53,525.65,597.46,524.98z"/>
			<path onClick={() => props.onFill(463)} fill={props.fillColors[463]}  d="M716.34,1037.55c-2.85,0.41-4.89,1.37-6.38,0.81c-9.04-3.34-17.95-7.03-27.48-10.83
				c3.63-3.74,6.04-3.73,9.36-1.94C699.34,1029.65,707.16,1033.12,716.34,1037.55z"/>
			<path onClick={() => props.onFill(464)} fill={props.fillColors[464]}  d="M488.91,254.14c1.6-1.44,3.6-3.43,5.83-5.12c0.78-0.59,2.33-0.84,3.14-0.45c0.6,0.29,0.73,1.86,0.8,2.88
				c0.07,1.15-0.1,2.32-0.27,3.48c-1.06,7.42-2.14,14.84-3.16,21.82C493.37,270.03,491.29,262.6,488.91,254.14z"/>
			<path onClick={() => props.onFill(465)} fill={props.fillColors[465]}  d="M581.11,543.82c3.81-0.87,7.57-2.17,11.43-2.53c6.68-0.62,13.41-0.83,20.12-0.84
				c2.76,0,6.81-0.95,6.88,4.76c-12.96,0-25.46,0-37.97,0C581.41,544.75,581.26,544.28,581.11,543.82z"/>
			<path onClick={() => props.onFill(466)} fill={props.fillColors[466]}  d="M843.41,859.65c5.51-2.09,10.95-4.37,16.55-6.14c1.7-0.54,3.91,0.15,5.77,0.66
				c0.88,0.24,2.14,1.46,2.09,2.14c-0.07,0.82-1.21,1.9-2.13,2.26c-4.45,1.73-8.88,3.72-13.52,4.68c-2.67,0.55-5.71-0.68-8.58-1.1
				C843.53,861.32,843.47,860.48,843.41,859.65z"/>
			<path onClick={() => props.onFill(467)} fill={props.fillColors[467]}  d="M801.94,448.46c-6.91,4.24-17.28,1.17-21.58-6.4C789.62,438.99,797.75,441.4,801.94,448.46z"/>
			<path onClick={() => props.onFill(468)} fill={props.fillColors[468]}  d="M196.8,920.89c2.12-1.08,3.7-1.95,5.32-2.71c9.12-4.3,18.25-8.57,27.95-11.97
				c-4.32,3.09-8.63,6.18-12.96,9.26c-1.9,1.35-4.08,2.38-5.7,4C207.16,923.71,202.56,923.94,196.8,920.89z"/>
			<path onClick={() => props.onFill(469)} fill={props.fillColors[469]}  d="M830.71,848.68c-1.46-3.73,3.63-8.31,12.91-11.6c5.77-2.05,8.12-1.22,9.65,3.41
				C845.88,843.16,838.49,845.85,830.71,848.68z"/>
			<path onClick={() => props.onFill(470)} fill={props.fillColors[470]}  d="M826.69,836.49c-3.44-2.89-3.9-5.65-1.53-8.95c1.24-1.74,1.95-3.86,3.18-5.61
				c0.95-1.36,2.89-3.63,3.44-3.38c2.13,0.96,4.19,2.53,5.58,4.4c0.43,0.58-1.11,3.01-2.15,4.25
				C832.54,830.34,829.65,833.29,826.69,836.49z"/>
			<path onClick={() => props.onFill(471)} fill={props.fillColors[471]}  d="M653.61,1055.83c-0.57,2.83-0.57,4.45-1.25,5.69c-0.62,1.15-1.98,1.89-3.01,2.81
				c-0.6-1.07-1.34-2.08-1.78-3.21c-1.54-4.03-3.28-8.03-4.3-12.19c-0.46-1.88,0.57-4.14,0.93-6.22c1.46,1.04,3.4,1.77,4.27,3.17
				C650.59,1049.33,652.23,1053.09,653.61,1055.83z"/>
			<path onClick={() => props.onFill(472)} fill={props.fillColors[472]}  d="M216.99,1050.44c8.79-5.33,16.42-2.48,23.79,2.39C233.62,1057.96,226.61,1057.24,216.99,1050.44z"/>
			<path onClick={() => props.onFill(473)} fill={props.fillColors[473]}  d="M872.5,376.06c-0.98,3.1-1.84,6.23-2.96,9.27c-1.7,4.6-3.45,9.2-5.48,13.66
				c-0.66,1.44-2.27,2.44-3.45,3.64c-0.57-0.35-1.13-0.7-1.7-1.05c1.06-3.18,2.21-6.33,3.16-9.55c1.4-4.74,2.37-9.63,4.14-14.21
				c0.58-1.51,3.07-2.29,4.69-3.4C871.44,374.97,871.97,375.51,872.5,376.06z"/>
			<path onClick={() => props.onFill(474)} fill={props.fillColors[474]}  d="M929.37,849.46c0.02,4.17,3.57,8.4-1.88,10.94c-4.65,2.17-9.33-0.56-13.21-7.75
				C919.19,851.62,923.91,850.62,929.37,849.46z"/>
			<path onClick={() => props.onFill(475)} fill={props.fillColors[475]}  d="M154.85,939.28c5.34,1.46,10.09,2.76,16.28,4.45c-4.51,1.61-7.73,2.92-11.05,3.9
				c-2.86,0.84-3.96,2.53-3.88,5.44c0.13,4.53,0.03,9.07,0.03,13.6c-0.46-0.01-0.92-0.01-1.39-0.02
				C154.85,957.78,154.85,948.91,154.85,939.28z"/>
			<path onClick={() => props.onFill(476)} fill={props.fillColors[476]}  d="M661.71,1044.5c-2.26-3.08-4.29-5.88-6.37-8.64c-3.59-4.76-3.53-5.16,1.63-8.73
				C666.28,1033.35,668.32,1040.18,661.71,1044.5z"/>
			<path onClick={() => props.onFill(477)} fill={props.fillColors[477]}  d="M729,331.44c-11.68,6.41-23.2,1.97-34.8,0.16c-0.02-0.63-0.04-1.25-0.07-1.88c4.71-0.09,10-1.6,13.98,0.06
				c6.13,2.55,12.01,1.1,17.97,1.51C727.05,331.36,728.02,331.39,729,331.44z"/>
			<path onClick={() => props.onFill(478)} fill={props.fillColors[478]}  d="M409.07,241.25c-9.02,2.36-18.03,4.72-27.43,7.18c-0.83-1.16-2-2.78-3.62-5.03
				c10.57-1.43,20.71-2.81,30.85-4.19C408.94,239.89,409.01,240.57,409.07,241.25z"/>
			<path onClick={() => props.onFill(479)} fill={props.fillColors[479]}  d="M894.25,342.74c-3.62,0-7.28,0.37-10.83-0.09c-5.41-0.7-10.82-1.72-16.09-3.13
				c-3.06-0.82-5.84-2.66-8.74-4.05c0.33-0.62,0.66-1.24,0.99-1.86c1.26,0.85,2.45,1.81,3.79,2.51c1.1,0.59,2.48,1.44,3.52,1.21
				c9.69-2.18,18.24,2.87,27.49,3.27C894.33,341.33,894.29,342.04,894.25,342.74z"/>
			<path onClick={() => props.onFill(480)} fill={props.fillColors[480]}  d="M524.01,275.78c-0.48-5.05,2.03-8.63,7.97-10.32c3.25-0.93,6.08-3.24,9.21-4.7
				c1.6-0.74,3.42-1.16,5.18-1.34c0.63-0.06,1.76,0.91,1.94,1.6c0.15,0.56-0.62,1.74-1.27,2.06c-5.15,2.58-10.45,4.87-15.53,7.56
				C528.72,272.12,526.26,274.21,524.01,275.78z"/>
			<path onClick={() => props.onFill(481)} fill={props.fillColors[481]}  d="M521,260.18c-4.6-0.52-7.54-1.33-7.32-6.48c0.12-2.81-1.43-5.66-1.91-8.54c-0.24-1.44,0.25-3,0.4-4.5
				c1.29,0.51,2.89,0.69,3.82,1.57C521.13,247.12,519.94,253.69,521,260.18z"/>
			<path onClick={() => props.onFill(482)} fill={props.fillColors[482]}  d="M281.07,332.05c1.11-0.95,2.1-2.14,3.35-2.82c5.47-2.95,21.8-3.19,28.22-0.33
				c-0.65,1.42-1.3,2.84-2.16,4.72c-9.74-4.12-19.6-3.02-29.47-1.63L281.07,332.05z"/>
			<path onClick={() => props.onFill(483)} fill={props.fillColors[483]}  d="M849.44,831.22c-7.84,3.93-14.38,7.2-20.88,10.45c0.46-3.96,8.8-14.63,12.04-14.3
				C843.54,827.68,846.3,829.77,849.44,831.22z"/>
			<path onClick={() => props.onFill(484)} fill={props.fillColors[484]}  d="M543.09,559.47c-0.6,6.24-2.81,8.16-7.46,6.99c-3.5-0.88-7.05-1.6-10.61-2.23
				c-1.22-0.21-2.54-0.01-3.79,0.16c-2.07,0.27-4.11,0.67-6.17,1.01c-0.09-0.73-0.19-1.47-0.28-2.2
				C524.01,561.98,533.25,560.77,543.09,559.47z"/>
			<path onClick={() => props.onFill(485)} fill={props.fillColors[485]}  d="M790.03,493.58c1.15-4.45,1.44-8.99,3.54-12.41c1.15-1.87,5.37-1.83,8.5-2.74
				C802.18,488.6,799.99,491.16,790.03,493.58z"/>
			<path onClick={() => props.onFill(486)} fill={props.fillColors[486]}  d="M225.69,343.14c-1.96,1.49-3.32,3.28-4.2,3.07c-9.42-2.24-18.4,0.37-27.49,1.99
				c-1.22,0.22-2.51,0.03-3.92,0.03C192.3,343.79,214.64,340.39,225.69,343.14z"/>
			<path onClick={() => props.onFill(487)} fill={props.fillColors[487]}  d="M576.38,389.1c-4.44,9.38-5.69,9.92-14.96,6.91c0.79-2.67,1.18-7.46,2.48-7.72
				C567.6,387.55,571.67,388.65,576.38,389.1z"/>
			<path onClick={() => props.onFill(488)} fill={props.fillColors[488]}  d="M346.57,241.41c-4.41,3.79-9.1,4.09-13.18,0.85c-1.36-1.08-0.72-4.7-1-7.23
				C344.41,234.73,344.48,234.76,346.57,241.41z"/>
			<path onClick={() => props.onFill(489)} fill={props.fillColors[489]}  d="M55.5,898.18c-8.71,1.64-10.94-1.1-8.96-10.98C56.77,887.22,57.04,885.3,55.5,898.18z"/>
			<path onClick={() => props.onFill(490)} fill={props.fillColors[490]}  d="M77.03,883c-16.81-1.76-33.61-3.51-50.42-5.27c0.05-0.54,0.1-1.08,0.16-1.62
				c16.84,1.56,33.69,3.13,50.53,4.69C77.21,881.54,77.12,882.27,77.03,883z"/>
			<path onClick={() => props.onFill(491)} fill={props.fillColors[491]}  d="M183.58,302.51c-2.74-3.31-3.8-6.57-2.28-10.98c0.95-2.77,0.02-6.14,0.34-9.19
				c0.15-1.44,1.27-2.78,1.95-4.17c0.81,1.17,2.05,2.22,2.37,3.51C187.75,288.82,187.23,295.78,183.58,302.51z"/>
			<path onClick={() => props.onFill(492)} fill={props.fillColors[492]}  d="M277.32,363.65c-1.52,3.33-3.47,5.1-7.64,4.23c-5.34-1.11-10.84-1.45-15.96-2.08
				C259.06,360.92,269.39,359.8,277.32,363.65z"/>
			<path onClick={() => props.onFill(493)} fill={props.fillColors[493]}  d="M225.81,1071.78c-1.05-6.26,3.31-9.7,13.3-10.58c1.84,5.95,1.47,6.73-4.25,8.5
				c-2.53,0.79-5.09,1.5-7.66,2.19C226.78,1072,226.27,1071.82,225.81,1071.78z"/>
			<path onClick={() => props.onFill(494)} fill={props.fillColors[494]}  d="M518.83,297.45c-6.04-7.07-9.12-30-5.7-35.77c0.16,0.05,0.46,0.12,0.45,0.15
				c-1.39,10.51,5.04,19.54,5.58,29.67C519.24,293.05,519,294.62,518.83,297.45z"/>
			<path onClick={() => props.onFill(495)} fill={props.fillColors[495]}  d="M408.73,251.96c-2.13,6.45-7.17,6.85-12.96,6.95c-4.19,0.07-8.35,1.65-12.52,2.6
				c-1.69,0.39-3.34,0.9-5.27,0.5c9.39-6.01,20.24-7.56,30.8-9.99L408.73,251.96z"/>
			<path onClick={() => props.onFill(496)} fill={props.fillColors[496]}  d="M553.74,555.48c3.17-7.4,5.09-8.17,16.41-6.89C569.72,555.94,568.99,556.66,553.74,555.48z"/>
			<path onClick={() => props.onFill(497)} fill={props.fillColors[497]}  d="M227.15,1061.97c-9.37,2.52-18.7,5.21-28.15,7.39c-1.74,0.4-4.2-0.9-5.92-2.03
				c-9.63-6.35-19.51-8.47-30.15-2.56c-0.75,0.42-1.72,0.44-2.59,0.64c-0.15-0.48-0.3-0.95-0.45-1.43c4.12-1.35,8.45-2.26,12.31-4.15
				c4.83-2.36,8.71-0.89,12.45,1.85c8.76,6.41,17.91,7.32,27.95,3.13c4.51-1.88,9.51-2.62,14.28-3.88
				C226.96,1061.28,227.06,1061.62,227.15,1061.97z"/>
			<path onClick={() => props.onFill(498)} fill={props.fillColors[498]}  d="M159.35,952.53c9.42,1.17,9.74,1.7,8.36,12.29c-1.38,0.39-2.79,0.88-4.23,1.16
				c-2.53,0.49-4.22-0.19-4.15-3.23C159.41,959.47,159.35,956.19,159.35,952.53z"/>
			<path onClick={() => props.onFill(499)} fill={props.fillColors[499]}  d="M950.98,806.83c-1.49,1.58-2.69,3.82-4.52,4.62c-5.6,2.43-11.42,4.34-17.2,6.32
				c-0.68,0.23-1.65-0.36-2.48-0.57c0.42-0.91,0.6-2.28,1.31-2.66c2.91-1.55,5.93-2.92,9-4.14c4.26-1.69,8.59-3.17,12.89-4.75
				C950.31,806.05,950.64,806.44,950.98,806.83z"/>
			<path onClick={() => props.onFill(500)} fill={props.fillColors[500]}  d="M39.48,882.05c7.33,0.77,14.67,1.47,21.99,2.4c0.72,0.09,1.75,1.56,1.8,2.44
				c0.2,3.46,0.24,6.96-0.04,10.41c-0.07,0.85-1.55,1.58-2.38,2.37c-0.69-0.76-1.58-1.43-2.02-2.32c-0.33-0.67-0.04-1.63-0.12-2.45
				c-0.33-3.21,0.06-8.78-1.21-9.19c-4.33-1.42-9.25-1.03-13.95-1.31c-1.48-0.09-2.96-0.14-4.44-0.21
				C39.24,883.47,39.36,882.76,39.48,882.05z"/>
			<path onClick={() => props.onFill(501)} fill={props.fillColors[501]}  d="M926.8,873.03c-1.76-0.43-3.97-0.35-5.16-1.42c-1.35-1.21-2.11-3.39-2.41-5.26
				c-0.09-0.56,2.18-1.71,3.51-2.21c1.2-0.46,2.63-0.3,3.91-0.61c3.4-0.84,5.01,0.39,5.11,3.93
				C931.85,870.94,931.23,873.55,926.8,873.03z"/>
			<path onClick={() => props.onFill(502)} fill={props.fillColors[502]}  d="M638.75,1049.73c7.14,9.03,8.05,17.95,2.49,22.37C640.72,1064.24,636.58,1057.36,638.75,1049.73z"/>
			<path onClick={() => props.onFill(503)} fill={props.fillColors[503]}  d="M652.14,1035.6c2.6,4.36,5.4,8.43,7.45,12.86c0.62,1.35-0.83,3.66-1.33,5.54
				c-1.19-1.09-2.63-2.02-3.53-3.31c-1.79-2.58-3.04-5.55-4.96-8.02C647.53,1039.78,647.44,1037.72,652.14,1035.6z"/>
			<path onClick={() => props.onFill(504)} fill={props.fillColors[504]}  d="M745.21,999.8c-3.87,3.94-16.16,2.11-26.04-4.08c2.03-0.63,3.4-1.57,4.51-1.32
				C730.76,996.03,737.79,997.91,745.21,999.8z"/>
			<path onClick={() => props.onFill(505)} fill={props.fillColors[505]}  d="M148.86,964.74c-2.61-8.69-0.59-17.72-1.29-26.56c0.47-0.21,0.93-0.41,1.4-0.62
				c0.73,1.08,2.06,2.14,2.08,3.23c0.18,7.9,0.1,15.8,0.1,23.7C150.39,964.58,149.63,964.66,148.86,964.74z"/>
			<path onClick={() => props.onFill(506)} fill={props.fillColors[506]}  d="M269.76,1059.7c9.97-0.52,10.93,0.44,9.53,9.3C270.36,1070.95,268.06,1068.8,269.76,1059.7z"/>
			<path onClick={() => props.onFill(507)} fill={props.fillColors[507]}  d="M821.19,829.01c-2.09-0.8-3.59-1.38-5.78-2.22c2.04-4.03,3.78-7.91,5.98-11.5
				c0.53-0.87,3.26-1.51,3.84-0.98c1,0.9,1.88,3.11,1.43,4.22C825.26,822.01,823.21,825.25,821.19,829.01z"/>
			<path onClick={() => props.onFill(508)} fill={props.fillColors[508]}  d="M634.86,1055.02c1.17,7.51,4.94,14.42,0.97,21.44c-0.5,0.05-0.99,0.1-1.49,0.16
				c-0.48-2.63-0.91-5.28-1.46-7.9c-0.68-3.24-1.38-6.47-2.2-9.68C629.8,1055.59,631.23,1054.57,634.86,1055.02z"/>
			<path onClick={() => props.onFill(509)} fill={props.fillColors[509]}  d="M742.08,996.36c-5.92-1.61-11.34-3-16.69-4.61c-0.79-0.24-1.27-1.48-1.89-2.25
				c0.78-0.44,1.64-1.37,2.32-1.25c6.15,1.11,12.27,2.36,18.37,3.73c0.64,0.14,1.04,1.38,1.55,2.11
				C744.5,994.86,743.27,995.62,742.08,996.36z"/>
			<path onClick={() => props.onFill(510)} fill={props.fillColors[510]}  d="M260.53,881.91c0,3.22,0,6.16,0,9.03c-2.96,0-5.68,0-8.46,0C249.51,882.05,251.08,880.21,260.53,881.91z"
				/>
			<path onClick={() => props.onFill(511)} fill={props.fillColors[511]}  d="M280.94,987.42c-1.42,6.91-3.44,8.8-8.55,8.16c-0.96-0.12-1.99-0.81-2.72-1.51
				c-1.7-1.63-3.24-3.43-5.12-5.45C269.73,988.24,275.11,987.84,280.94,987.42z"/>
			<path onClick={() => props.onFill(512)} fill={props.fillColors[512]}  d="M293.43,954.62c-3.24-2.08-9.37,4.25-10.1-3.77c-0.54-5.97,4.05-4.62,7.13-4.5
				C296.79,946.6,292.71,951.49,293.43,954.62z"/>
			<path onClick={() => props.onFill(513)} fill={props.fillColors[513]}  d="M205.18,1071.63c2.4,8.32,1.52,9.85-6.56,11.39C194.87,1075.35,197.03,1071.6,205.18,1071.63z"/>
			<path onClick={() => props.onFill(514)} fill={props.fillColors[514]}  d="M90.63,886.82c3.93-1.09,5.82-0.14,5.78,3.54c-0.03,2.81,0.25,5.67-0.19,8.42
				c-0.18,1.11-1.77,2.4-2.97,2.75c-0.57,0.17-2.42-1.61-2.5-2.6C90.45,895,90.63,891.04,90.63,886.82z"/>
			<path onClick={() => props.onFill(515)} fill={props.fillColors[515]}  d="M918.14,874.5c4.99,0.74,8.24,1.1,11.43,1.79c0.83,0.18,2.02,1.39,2.03,2.15
				c0.02,1.34-0.69,3.84-1.17,3.85c-3.25,0.12-6.7,0.24-9.69-0.78C919.52,881.1,919.29,877.81,918.14,874.5z"/>
			<path onClick={() => props.onFill(516)} fill={props.fillColors[516]}  d="M216.56,951.17c1.04,8.91,0.74,9.21-8.44,8.83C205.53,951.99,207.2,950.18,216.56,951.17z"/>
			<path onClick={() => props.onFill(517)} fill={props.fillColors[517]}  d="M936.79,840.95c-6.33,5.8-13.52,7.82-21.68,7.86c-0.47-4.49,2.48-5,5.45-5.45
				C925.75,842.58,930.94,841.82,936.79,840.95z"/>
			<path onClick={() => props.onFill(518)} fill={props.fillColors[518]}  d="M622,1061.57c3.22,6.78,2.73,17.38-0.97,21.04C618.48,1079.32,619.1,1065.84,622,1061.57z"/>
			<path onClick={() => props.onFill(519)} fill={props.fillColors[519]}  d="M271.07,955.38c0.6-3.19-3.49-8.27,3.41-8.14c2.8,0.05,6.84-1.54,6.24,3.97
				C279.97,958.12,274.47,954.07,271.07,955.38z"/>
			<path onClick={() => props.onFill(520)} fill={props.fillColors[520]}  d="M125.81,864.76c0-4.18,0-7.24,0-11.28c4.14,0.97,7.7,1.8,11.26,2.63c0.04,0.68,0.08,1.36,0.13,2.04
				C133.65,860.21,130.09,862.27,125.81,864.76z"/>
			<path onClick={() => props.onFill(521)} fill={props.fillColors[521]}  d="M853.93,865.54c3.23-1.21,7.12-2.66,12.48-4.66c-2.09,5.34-3.6,9.18-5.15,13.12
				C858.75,871.1,856.53,868.54,853.93,865.54z"/>
			<path onClick={() => props.onFill(522)} fill={props.fillColors[522]}  d="M327.61,1072.88c5.76-1.03,9.8,0.65,10.85,4.19c1.03,3.45-0.18,6.01-3.25,7.08
				C332.76,1080.51,330.33,1076.92,327.61,1072.88z"/>
			<path onClick={() => props.onFill(523)} fill={props.fillColors[523]}  d="M251.04,956.53c0.14-3.45-3.29-7.98,3.06-8.23c3.07-0.12,6.9-0.98,6.36,4.18
				C259.75,959.26,254.18,954.84,251.04,956.53z"/>
			<path onClick={() => props.onFill(524)} fill={props.fillColors[524]}  d="M329.51,986.16c-6.38-7.35,2.4-12.68,2.34-20.63C334.11,973.68,336.51,980.57,329.51,986.16z"/>
			<path onClick={() => props.onFill(525)} fill={props.fillColors[525]}  d="M230.29,980.71c-7.12-3.15-8.32-6.17-6.33-15.69C227.69,969.67,233.27,973.12,230.29,980.71z"/>
			<path onClick={() => props.onFill(526)} fill={props.fillColors[526]}  d="M663.4,326.05c11.85-4.66,25.18-4.1,26.19,0C680.85,326.05,672.13,326.05,663.4,326.05z"/>
			<path onClick={() => props.onFill(527)} fill={props.fillColors[527]}  d="M832.22,898.42c4.64,9.34,2.74,17.15-3.13,16.97c0-4.19-0.21-8.4,0.12-12.56
				C829.33,901.5,830.9,900.28,832.22,898.42z"/>
			<path onClick={() => props.onFill(528)} fill={props.fillColors[528]}  d="M239.39,950.31c-0.72,2.28-0.78,5.48-2.33,6.6c-1.6,1.15-4.82,0.74-6.99,0.01
				c-0.74-0.25-1.23-4.76-0.49-5.24c2.38-1.54,5.39-2.11,8.16-3.06C238.29,949.18,238.84,949.75,239.39,950.31z"/>
			<path onClick={() => props.onFill(529)} fill={props.fillColors[529]}  d="M361.65,1183.41c0.81,4.55,1.63,9.09,2.52,14.09c-2.61,0-4.85,0-8.03,0c1.25-4.77,2.5-9.55,3.75-14.32
				C360.48,1183.25,361.06,1183.33,361.65,1183.41z"/>
			<path onClick={() => props.onFill(530)} fill={props.fillColors[530]}  d="M551.96,367.32c11.69,0.06,23.23-2.91,34.94-3.05c0.05,1.02,0.1,2.03,0.16,3.05
				C575.36,367.32,563.66,367.32,551.96,367.32z"/>
			<path onClick={() => props.onFill(531)} fill={props.fillColors[531]}  d="M705.34,995.33c-1.19-7.93,2.11-14.41,7.42-14.6C712.29,989.31,710.4,993.02,705.34,995.33z"/>
			<path onClick={() => props.onFill(532)} fill={props.fillColors[532]}  d="M926.75,796.24c6.79-7.57,13.58-15.14,20.79-23.18C948.14,778.45,947.76,778.87,926.75,796.24z"/>
			<path onClick={() => props.onFill(533)} fill={props.fillColors[533]}  d="M77.26,896.15c-11.57-0.41-11.53-0.06-10.18-6.15C75.74,888.44,78.61,890.18,77.26,896.15z"/>
			<path onClick={() => props.onFill(534)} fill={props.fillColors[534]}  d="M918.83,838.98c7.32-2.13,14.64-4.27,22.18-6.46C938.74,837.61,929.53,840.76,918.83,838.98z"/>
			<path onClick={() => props.onFill(535)} fill={props.fillColors[535]}  d="M115.6,908.75c-0.96-1.26-2.54-2.43-2.74-3.8c-0.43-2.93-0.32-5.98-0.05-8.95
				c0.08-0.94,1.38-1.76,2.12-2.64c0.74,0.92,2.06,1.81,2.12,2.78c0.24,3.93,0.1,7.89,0.1,11.84
				C116.64,908.23,116.12,908.49,115.6,908.75z"/>
			<path onClick={() => props.onFill(536)} fill={props.fillColors[536]}  d="M627.96,1059.21c1.19,6.88,4.82,13.71,0.53,20.69c-0.5,0.02-1,0.03-1.5,0.05
				c-0.31-6.9-0.62-13.79-0.93-20.69C626.7,1059.24,627.33,1059.23,627.96,1059.21z"/>
			<path onClick={() => props.onFill(537)} fill={props.fillColors[537]}  d="M80.98,868.69c4.56-0.26,7.6,0.44,7,5.79c-0.58,5.19-4.02,2.86-7,3.11
				C80.98,874.58,80.98,871.86,80.98,868.69z"/>
			<path onClick={() => props.onFill(538)} fill={props.fillColors[538]}  d="M163.44,949.72c7.21-2.53,13.06-6.45,20.38-1.91C177.15,949,171.44,953.68,163.44,949.72z"/>
			<path onClick={() => props.onFill(539)} fill={props.fillColors[539]}  d="M105.85,890.95c1.99,1.36,3.87,2,3.93,2.8c0.31,3.8,0.19,7.65,0.04,11.47c-0.02,0.5-1.35,1.48-1.63,1.34
				c-0.89-0.44-2.2-1.27-2.24-2.01C105.74,900.44,105.85,896.33,105.85,890.95z"/>
			<path onClick={() => props.onFill(540)} fill={props.fillColors[540]}  d="M185.96,722.19c9.02-5.62,12.71-6.21,15.09-3.17c-2.91,1.66-5.87,3.36-8.85,5.04
				C188.75,726,188.75,725.99,185.96,722.19z"/>
			<path onClick={() => props.onFill(541)} fill={props.fillColors[541]}  d="M147.75,932.65c0-6.4-0.06-11.47,0.12-16.53c0.01-0.36,2.32-1.22,2.68-0.89
				C152.34,916.85,150.96,929.5,147.75,932.65z"/>
			<path onClick={() => props.onFill(542)} fill={props.fillColors[542]}  d="M44.35,861.27c-3.99-0.38-7.99-0.76-12.28-1.17c2.17-6.87,6.9-4.43,10.71-3.75
				c1.16,0.21,1.97,2.36,2.94,3.62C45.26,860.4,44.8,860.84,44.35,861.27z"/>
			<path onClick={() => props.onFill(543)} fill={props.fillColors[543]}  d="M873.98,238.56c-5.49-1.91-11.04-3.66-16.45-5.76c-5.56-2.16-10.99-4.66-16.27-7.62
				c11.07,3.96,22.14,7.92,33.21,11.88C874.31,237.56,874.15,238.06,873.98,238.56z"/>
			<path onClick={() => props.onFill(544)} fill={props.fillColors[544]}  d="M281.02,331.99c-2.39,1.11-4.78,2.22-6.5,3.03c-3.48-1.53-6.4-2.81-9.29-4.09
				c1.95-3.56,7.31-4.13,10.81-1.15c1.35,1.15,3.34,1.54,5.04,2.28C281.07,332.05,281.02,331.99,281.02,331.99z"/>
			<path onClick={() => props.onFill(545)} fill={props.fillColors[545]}  d="M100.21,885.09c7.83,1.55,15.66,3.11,23.5,4.66c-0.14,0.72-0.27,1.45-0.41,2.17
				c-7.85-1.54-15.69-3.07-23.54-4.61C99.91,886.57,100.06,885.83,100.21,885.09z"/>
			<path onClick={() => props.onFill(546)} fill={props.fillColors[546]}  d="M912.54,896.48c2.67-5.22,4.91-5.61,12.51-2.18C921.87,898.81,918.54,899.39,912.54,896.48z"/>
			<path onClick={() => props.onFill(547)} fill={props.fillColors[547]}  d="M125.41,832.28c8.4-3.75,13.35-2.62,16.44,3.62C135.95,834.6,130.68,833.44,125.41,832.28z"/>
			<path onClick={() => props.onFill(548)} fill={props.fillColors[548]}  d="M670.25,1028.27c-0.77,1.47-1.51,2.86-2.44,4.62c-3.18-2.95-6.07-5.53-8.81-8.27
				c-0.49-0.49-0.31-1.66-0.43-2.52c0.99,0.01,2.21-0.34,2.94,0.11C664.3,1023.94,666.93,1025.92,670.25,1028.27z"/>
			<path onClick={() => props.onFill(549)} fill={props.fillColors[549]}  d="M770.26,897.68c6.05-0.47,10.84-0.85,15.86-1.24C783.08,903.05,779.08,903.48,770.26,897.68z"/>
			<path onClick={() => props.onFill(550)} fill={props.fillColors[550]}  d="M916.96,888.31c1.76-4.06,5.24-4.41,12.34-1.24C927.88,891.66,924.73,891.98,916.96,888.31z"/>
			<path onClick={() => props.onFill(551)} fill={props.fillColors[551]}  d="M282.13,1068.3c0.66-2.81,1.14-5.68,2.12-8.37c0.18-0.5,3.46-0.5,3.5-0.32c0.53,2.31,1.37,4.88,0.76,6.99
				c-0.37,1.28-3.1,1.88-4.78,2.78C283.2,1069.02,282.66,1068.66,282.13,1068.3z"/>
			<path onClick={() => props.onFill(552)} fill={props.fillColors[552]}  d="M101.14,890.33c0.63,1.05,1.73,2.05,1.82,3.14c0.26,3.11,0.22,6.25,0.05,9.37
				c-0.05,0.92-0.94,1.79-1.45,2.68c-0.68-0.92-1.88-1.82-1.93-2.78c-0.22-3.91-0.09-7.83-0.09-11.75
				C100.06,890.78,100.6,890.56,101.14,890.33z"/>
			<path onClick={() => props.onFill(553)} fill={props.fillColors[553]}  d="M121.69,894.46c0.81,0.92,2.22,1.79,2.31,2.77c0.29,3.43,0.2,6.9,0.03,10.34
				c-0.03,0.67-1.08,1.29-1.67,1.93c-0.63-0.64-1.79-1.25-1.82-1.91c-0.17-4.2-0.09-8.4-0.09-12.6
				C120.87,894.81,121.28,894.63,121.69,894.46z"/>
			<path onClick={() => props.onFill(554)} fill={props.fillColors[554]}  d="M846.98,918.9c1.9-4.12,3.8-8.25,5.79-12.58c3.32,4.09,1.75,8.06-0.37,11.58
				c-0.8,1.33-3.34,1.59-5.09,2.35C847.2,919.8,847.09,919.35,846.98,918.9z"/>
			<ellipse onClick={() => props.onFill(555)} fill={props.fillColors[555]}  cx="194.09" cy="960.06" rx="1.02" ry="13.49"/>
			<path onClick={() => props.onFill(556)} fill={props.fillColors[556]}  d="M221.46,1067.21c1.67,7.91,1.13,9.08-4.56,9.92C214.62,1069.89,215.7,1067.55,221.46,1067.21z"/>
			<path onClick={() => props.onFill(557)} fill={props.fillColors[557]}  d="M146.39,898.79c-6.29-1.25-12.58-2.49-18.86-3.74c0.15-0.78,0.31-1.55,0.46-2.33
				c6.3,1.24,12.6,2.47,18.91,3.71C146.73,897.21,146.56,898,146.39,898.79z"/>
			<path onClick={() => props.onFill(558)} fill={props.fillColors[558]}  d="M284.96,844.63c-1.31,3.2,2.22,7.53-3.59,8.12c-3.23,0.33-3.26-1.81-3.32-4.09
				C277.92,843.92,281.12,844.62,284.96,844.63z"/>
			<path onClick={() => props.onFill(559)} fill={props.fillColors[559]}  d="M518.36,279.12c-0.68-5.18-1.35-10.37-2.12-16.24c4.59,2.74,4.59,2.74,3.92,16.04
				C519.56,278.98,518.96,279.05,518.36,279.12z"/>
			<path onClick={() => props.onFill(560)} fill={props.fillColors[560]}  d="M40.73,844.98c-5.38,5.8-10.76,11.6-16.14,17.39c-0.47-0.44-0.95-0.88-1.42-1.33
				c5.36-5.77,10.72-11.55,16.09-17.32C39.75,844.14,40.24,844.56,40.73,844.98z"/>
			<path onClick={() => props.onFill(561)} fill={props.fillColors[561]}  d="M287.71,905.11c-4.22-1.87-6.09-6.53-4.31-9.48c0.4-0.66,1.75-1.36,2.33-1.15c0.82,0.3,1.81,1.4,1.88,2.24
				C287.86,899.32,287.71,901.96,287.71,905.11z"/>
			<ellipse onClick={() => props.onFill(562)} fill={props.fillColors[562]}  cx="239.66" cy="857.98" rx="1" ry="11.88"/>
			<path onClick={() => props.onFill(563)} fill={props.fillColors[563]}  d="M827.36,754.37c-3.94,0.57-7.89,1.15-12.08,1.76c0-1.95,0-3.21,0-5.01c4.14,0.49,8.05,0.96,11.96,1.43
				C827.28,753.15,827.32,753.76,827.36,754.37z"/>
			<path onClick={() => props.onFill(564)} fill={props.fillColors[564]}  d="M509,561.48c-4.44-2.74-10.64,3.94-14.58-3.05C499.7,558.27,504.93,556.78,509,561.48z"/>
			<path onClick={() => props.onFill(565)} fill={props.fillColors[565]}  d="M95.66,878.24c-6.49,0.32-5.02-3.79-4.81-7.24C96.51,869.76,97.28,870.93,95.66,878.24z"/>
			<path onClick={() => props.onFill(566)} fill={props.fillColors[566]}  d="M218.75,957.57c1.6-2.96,2.27-5.99,4.09-7.1c2.11-1.28,3.1,0.92,3.24,3.31
				C226.33,957.96,224.02,958.36,218.75,957.57z"/>
			<path onClick={() => props.onFill(567)} fill={props.fillColors[567]}  d="M89.52,1143.43c0.98,1.87,1.88,2.86,1.96,3.9c0.21,2.83,0.07,5.68,0.07,9.48
				C86.53,1153.04,86.23,1150.61,89.52,1143.43z"/>
			<path onClick={() => props.onFill(568)} fill={props.fillColors[568]}  d="M645.24,248.1c0.67-2.62,1.3-5.11,1.99-7.83c2.24,1.58,4.15,2.93,6.15,4.34
				C652.3,247.5,652.3,247.5,645.24,248.1z"/>
			<path onClick={() => props.onFill(569)} fill={props.fillColors[569]}  d="M83.92,897.89c-1.65-2.49-3.28-3.91-3.04-4.85c0.29-1.16,2.13-2.63,3.33-2.65
				c1.21-0.02,2.45,1.57,3.67,2.45C86.89,894.11,85.89,895.37,83.92,897.89z"/>
			<path onClick={() => props.onFill(570)} fill={props.fillColors[570]}  d="M249.31,953.24c-2.21,1.66-3.47,2.61-4.74,3.56c-0.82-1.3-2.42-2.7-2.27-3.86
				c0.19-1.37,1.89-2.54,2.94-3.79C246.3,950.2,247.35,951.26,249.31,953.24z"/>
			<path onClick={() => props.onFill(571)} fill={props.fillColors[571]}  d="M511.69,556.26c-7.81-0.45-14.48-0.83-21.15-1.21C497.4,554.1,504.4,550.97,511.69,556.26z"/>
			<path onClick={() => props.onFill(572)} fill={props.fillColors[572]}  d="M100.1,870.38c5.49,1.08,10.98,2.16,16.48,3.24c-0.12,0.64-0.24,1.28-0.35,1.91
				c-5.51-1.01-11.01-2.02-16.52-3.03C99.84,871.8,99.97,871.09,100.1,870.38z"/>
			<path onClick={() => props.onFill(573)} fill={props.fillColors[573]}  d="M69.79,863.84c-2.99-0.3-4.65-0.22-6.13-0.69c-1.39-0.44-2.69-1.35-3.81-2.31
				c-0.21-0.19,0.6-2.3,0.99-2.32c1.74-0.07,3.67-0.08,5.19,0.63C67.21,859.72,67.88,861.37,69.79,863.84z"/>
			<path onClick={() => props.onFill(574)} fill={props.fillColors[574]}  d="M121.33,864.51c-6.56-1.28-13.11-2.56-19.67-3.84c0.1-0.52,0.2-1.04,0.29-1.56
				c6.56,1.26,13.12,2.52,19.68,3.79C121.53,863.44,121.43,863.98,121.33,864.51z"/>
			<path onClick={() => props.onFill(575)} fill={props.fillColors[575]}  d="M438.53,341.41c-1.75-5.8-3.5-11.61-5.24-17.41c0.79-0.2,1.57-0.41,2.36-0.61c1.33,5.95,2.67,11.9,4,17.85
				C439.27,341.3,438.9,341.36,438.53,341.41z"/>
			<path onClick={() => props.onFill(576)} fill={props.fillColors[576]}  d="M878.47,345.83c-4.54-1.24-9.91,2.74-13.69-3.55c4.64,0.7,9.27,1.4,13.91,2.1
				C878.61,344.87,878.54,345.35,878.47,345.83z"/>
			<path onClick={() => props.onFill(577)} fill={props.fillColors[577]}  d="M154.51,899.2c-1.17,0.5-2.36,1-3.99,1.69c0-4.13,0-7.81,0-11.49c0.63-0.1,1.27-0.2,1.9-0.31
				C153.09,892.31,153.75,895.52,154.51,899.2z"/>
			<path onClick={() => props.onFill(578)} fill={props.fillColors[578]}  d="M209.83,1079.59c-0.27-1.41-0.8-2.53-0.62-3.53c0.4-2.23,1.15-4.39,1.75-6.58
				c0.62,0.59,1.84,1.28,1.77,1.77C212.33,1074.02,214.83,1077.87,209.83,1079.59z"/>
			<path onClick={() => props.onFill(579)} fill={props.fillColors[579]}  d="M140.14,891.63c-6.54-0.46-5.49-3.76-5.31-6.33c0.05-0.65,1.44-1.21,2.21-1.81
				C137.96,885.9,138.88,888.31,140.14,891.63z"/>
			<path onClick={() => props.onFill(580)} fill={props.fillColors[580]}  d="M48.14,269.71c-1.13-3.98-2.27-7.96-3.4-11.94c0.43-0.14,0.86-0.28,1.29-0.42
				c2.21,3.64,5.45,6.91,3.54,11.99C49.09,269.46,48.62,269.58,48.14,269.71z"/>
			<path onClick={() => props.onFill(581)} fill={props.fillColors[581]}  d="M132.06,906.21c-6.74-0.07-4.04-4.34-4.87-7.93C133.75,899.02,131.37,903.13,132.06,906.21z"/>
			<path onClick={() => props.onFill(582)} fill={props.fillColors[582]}  d="M127.58,869.79c-3.15,2.09-1.51,8.45-9.21,7.88c2.94-3.65,5.24-6.49,7.53-9.34
				C126.46,868.81,127.02,869.3,127.58,869.79z"/>
			<path onClick={() => props.onFill(583)} fill={props.fillColors[583]}  d="M133.32,886.02c-1.59,1.73-2.52,2.74-3.45,3.74c-0.85-1.29-2.26-2.52-2.37-3.87
				c-0.1-1.13,1.32-2.39,2.06-3.59C130.63,883.35,131.69,884.4,133.32,886.02z"/>
			<path onClick={() => props.onFill(584)} fill={props.fillColors[584]}  d="M269.02,952.13c-1.59,1.53-2.64,2.55-3.7,3.57c-0.68-1.24-1.99-2.53-1.89-3.7
				c0.12-1.34,1.48-2.57,2.3-3.85C266.62,949.22,267.51,950.3,269.02,952.13z"/>
			<path onClick={() => props.onFill(585)} fill={props.fillColors[585]}  d="M52.63,838.35c-1.6-1.23-3.2-2.47-4.8-3.7c1.25-0.82,3.1-2.62,3.64-2.28c1.38,0.85,2.17,2.65,3.2,4.07
				C53.99,837.07,53.31,837.71,52.63,838.35z"/>
			<path onClick={() => props.onFill(586)} fill={props.fillColors[586]}  d="M87.84,259.42c0-3.93,0-6.01,0-8.09c0.36-0.26,0.72-0.53,1.09-0.79c1.41,1.47,2.83,2.93,4.24,4.4
				C91.7,256.17,90.23,257.41,87.84,259.42z"/>
			<path onClick={() => props.onFill(587)} fill={props.fillColors[587]}  d="M134.1,900.22c5.79-0.45,5.26,2.5,4.87,4.88c-0.14,0.85-1.66,1.48-2.54,2.21
				C135.69,905.06,134.95,902.8,134.1,900.22z"/>
			<path onClick={() => props.onFill(588)} fill={props.fillColors[588]}  d="M155.51,894.52c1.17-2.38,2.1-4.27,3.03-6.17c0.85,0.92,2.25,1.74,2.44,2.79
				C161.48,893.94,160.39,895.68,155.51,894.52z"/>
			<path onClick={() => props.onFill(589)} fill={props.fillColors[589]}  d="M786.91,906.31c1.19,1.28,2.31,2.65,3.6,3.82c1.18,1.07,2.56,1.94,3.62,4.15
				c-1.27-0.14-3.03,0.2-3.71-0.51c-1.86-1.91-3.26-4.26-4.84-6.44C786.02,906.98,786.46,906.65,786.91,906.31z"/>
			<path onClick={() => props.onFill(590)} fill={props.fillColors[590]}  d="M783.93,911.58c1.61,1.87,2.76,3.2,4.32,5c-3.15,0.73-5.8,1.34-9.48,2.18
				C780.72,916.04,782.15,914.06,783.93,911.58z"/>
			<path onClick={() => props.onFill(591)} fill={props.fillColors[591]}  d="M44.1,865.84c-5.02-0.49-10.05-0.97-15.07-1.46c0.04-0.47,0.08-0.93,0.12-1.4
				c5.04,0.44,10.08,0.87,15.12,1.31C44.21,864.8,44.15,865.32,44.1,865.84z"/>
			<path onClick={() => props.onFill(592)} fill={props.fillColors[592]}  d="M181.92,958.9c0,1.59,0.23,3.23-0.08,4.76c-0.2,0.98-1.19,1.79-1.83,2.68c-0.57-0.97-1.66-1.97-1.61-2.91
				c0.1-1.72,0.87-3.4,1.36-5.09C180.48,958.53,181.2,958.71,181.92,958.9z"/>
			<path onClick={() => props.onFill(593)} fill={props.fillColors[593]}  d="M108.25,884.02c-0.82-1.41-1.95-2.75-2.3-4.27c-0.13-0.58,1.52-1.57,2.36-2.37
				c0.72,1.85,1.45,3.7,2.17,5.55C109.74,883.29,109,883.66,108.25,884.02z"/>
			<path onClick={() => props.onFill(594)} fill={props.fillColors[594]}  d="M40.11,869c4.3-0.76,5.43,0.9,4.89,3.67c-0.15,0.79-1.67,1.32-2.57,1.96C41.68,872.82,40.93,871,40.11,869
				z"/>
			<path onClick={() => props.onFill(595)} fill={props.fillColors[595]}  d="M285.04,923.38c-5.16,0-10.32,0-15.47,0c-0.01-0.21-0.03-0.41-0.04-0.62c5.12,0,10.24,0,15.36,0
				C284.94,922.96,284.99,923.17,285.04,923.38z"/>
			<path onClick={() => props.onFill(596)} fill={props.fillColors[596]}  d="M75.07,871.57c0.95,1.13,2.23,2.14,2.69,3.44c0.21,0.59-1.2,1.76-1.88,2.67
				c-0.99-1.44-1.98-2.88-2.97-4.32C73.63,872.76,74.35,872.17,75.07,871.57z"/>
			<path onClick={() => props.onFill(597)} fill={props.fillColors[597]}  d="M579.26,206.71c2.11-3.35,5.86-3.73,9.98-3.48c-2.96,1.68-5.93,3.36-8.89,5.04
				C579.99,207.75,579.62,207.23,579.26,206.71z"/>
			<path onClick={() => props.onFill(598)} fill={props.fillColors[598]}  d="M34.45,867.37c1.01,1.31,2.22,2.53,2.87,4c0.14,0.31-1.77,1.53-2.73,2.33c-0.81-1.59-1.63-3.18-2.44-4.77
				C32.92,868.41,33.69,867.89,34.45,867.37z"/>
			<path onClick={() => props.onFill(599)} fill={props.fillColors[599]}  d="M584.68,370.17c-1.87,3.66-2.43,3.63-10.18-0.72C577.79,369.69,581.1,369.92,584.68,370.17z"/>
			<path onClick={() => props.onFill(600)} fill={props.fillColors[600]}  d="M270.19,970.9c0,2.82,0,5.64,0,8.47c-0.41-0.01-0.83-0.01-1.24-0.02c0-2.88,0-5.76,0-8.63
				C269.36,970.77,269.78,970.83,270.19,970.9z"/>
			<path onClick={() => props.onFill(601)} fill={props.fillColors[601]}  d="M288.19,884.74c-0.86,0.67-2,2.07-2.51,1.86c-1.03-0.41-2.41-1.75-2.34-2.6c0.07-0.94,1.49-2.24,2.54-2.5
				c1.06-0.26,2.39,0.56,3.6,0.91C289.05,883.19,288.62,883.97,288.19,884.74z"/>
			<path onClick={() => props.onFill(602)} fill={props.fillColors[602]}  d="M251.9,980.24c0-2.85,0-5.71,0-8.56c0.45,0,0.91-0.01,1.36-0.01c0,2.88,0,5.77,0,8.65
				C252.81,980.29,252.35,980.26,251.9,980.24z"/>
			<path onClick={() => props.onFill(603)} fill={props.fillColors[603]}  d="M71.4,874.78c-0.88,0.68-1.77,1.36-2.65,2.04c-0.7-1.06-1.82-2.08-1.93-3.2
				c-0.07-0.68,1.36-2.02,2.26-2.15c1.19-0.18,2.52,0.52,3.79,0.84C72.37,873.13,71.88,873.95,71.4,874.78z"/>
			<path onClick={() => props.onFill(604)} fill={props.fillColors[604]}  d="M67.15,898.58c3.42,0.37,6.85,0.73,10.27,1.1c-0.08,0.66-0.16,1.32-0.23,1.99
				c-3.43-0.41-6.86-0.81-10.29-1.22C66.98,899.83,67.06,899.21,67.15,898.58z"/>
			<path onClick={() => props.onFill(605)} fill={props.fillColors[605]}  d="M276.21,885.14c-1.72,1.23-2.93,2.6-3.47,2.37c-1-0.42-2.07-1.71-2.23-2.77
				c-0.11-0.69,1.43-2.38,2.04-2.29C273.6,882.6,274.49,883.79,276.21,885.14z"/>
			<path onClick={() => props.onFill(606)} fill={props.fillColors[606]}  d="M57.06,848.41c0,8.32,0,16.63,0,24.95c-0.06,0-0.13,0-0.19,0c0-8.36,0-16.71,0-25.07
				C56.94,848.33,57,848.37,57.06,848.41z"/>
			<path onClick={() => props.onFill(607)} fill={props.fillColors[607]}  d="M60.86,866.09c3.74,0.31,7.49,0.61,11.23,0.92c-0.04,0.52-0.08,1.03-0.12,1.55
				c-3.74-0.37-7.48-0.74-11.21-1.1C60.79,867,60.82,866.54,60.86,866.09z"/>
			<path onClick={() => props.onFill(608)} fill={props.fillColors[608]}  d="M25.51,893.14c3.16,0.49,6.32,0.98,9.49,1.48c-0.07,0.59-0.14,1.17-0.21,1.76c-3.2-0.35-6.4-0.7-9.61-1.05
				C25.3,894.6,25.4,893.87,25.51,893.14z"/>
			<path onClick={() => props.onFill(609)} fill={props.fillColors[609]}  d="M346.5,1197.95c-1.35-0.96-2.7-1.93-4.42-3.15c1.19-1.58,2.2-2.92,3.63-4.8c0.72,2.89,1.27,5.11,1.83,7.34
				C347.19,1197.54,346.85,1197.75,346.5,1197.95z"/>
			<path onClick={() => props.onFill(610)} fill={props.fillColors[610]}  d="M243.46,887.08c-3.36-0.64-6.72-1.27-10.08-1.91c3.5-0.27,7.25-2.44,10.35,0.15
				C243.64,885.91,243.55,886.49,243.46,887.08z"/>
			<path onClick={() => props.onFill(611)} fill={props.fillColors[611]}  d="M257.88,979.5c0-2.65,0-5.29,0-7.94c0.45,0,0.91,0.01,1.36,0.01c0,2.65,0,5.29,0,7.94
				C258.79,979.51,258.34,979.5,257.88,979.5z"/>
			<path onClick={() => props.onFill(612)} fill={props.fillColors[612]}  d="M265.07,971.56c0,2.57,0,5.14,0,7.71c-0.4,0-0.8,0.01-1.2,0.01c0-2.57,0-5.15,0-7.72
				C264.27,971.56,264.67,971.56,265.07,971.56z"/>
			<path onClick={() => props.onFill(613)} fill={props.fillColors[613]}  d="M100.85,882.56c-0.51-2.04-1.03-4.09-1.54-6.13c0.74-0.24,1.48-0.47,2.22-0.71
				c0.64,2.01,1.27,4.01,1.91,6.02C102.57,882.02,101.71,882.29,100.85,882.56z"/>
			<path onClick={() => props.onFill(614)} fill={props.fillColors[614]}  d="M112.81,878.03c2.16,2.63,3.48,4.23,5.3,6.45C111.68,885.61,112.85,882.15,112.81,878.03z"/>
			<path onClick={() => props.onFill(615)} fill={props.fillColors[615]}  d="M738.31,988.08c-3.73-0.92-7.46-1.84-11.19-2.76c0.16-0.63,0.33-1.26,0.49-1.9
				c3.6,1.14,7.2,2.28,10.8,3.43C738.38,987.26,738.34,987.67,738.31,988.08z"/>
			<path onClick={() => props.onFill(616)} fill={props.fillColors[616]}  d="M30.63,869.79c-1.13,1.44-1.85,2.34-2.57,3.25c-0.8-0.99-2.06-1.89-2.25-2.99
				c-0.14-0.78,1.16-1.82,1.81-2.74C28.48,868.02,29.35,868.74,30.63,869.79z"/>
			<path onClick={() => props.onFill(617)} fill={props.fillColors[617]}  d="M61.3,876.68c-0.61-1.88-1.22-3.77-1.82-5.65c0.77-0.27,1.54-0.54,2.31-0.8c0.62,1.88,1.25,3.76,1.87,5.64
				C62.87,876.14,62.08,876.41,61.3,876.68z"/>
			<path onClick={() => props.onFill(618)} fill={props.fillColors[618]}  d="M131.85,868.63c1.31,2.53,2.07,4.01,3.23,6.25c-2.51-0.17-4.16-0.27-6.6-0.44
				C129.67,872.38,130.43,871.07,131.85,868.63z"/>
			<path onClick={() => props.onFill(619)} fill={props.fillColors[619]}  d="M164.03,893.34c0.53-1.64,1.06-3.28,1.58-4.92c0.75,0.41,2.19,0.97,2.14,1.21
				c-0.33,1.53-0.99,2.98-1.54,4.46C165.48,893.84,164.75,893.59,164.03,893.34z"/>
			<path onClick={() => props.onFill(620)} fill={props.fillColors[620]}  d="M128.38,877.22c2.9,0.5,5.81,1,8.71,1.51c-0.08,0.61-0.15,1.23-0.23,1.84c-2.91-0.58-5.81-1.16-8.72-1.75
				C128.23,878.29,128.3,877.76,128.38,877.22z"/>
			<path onClick={() => props.onFill(621)} fill={props.fillColors[621]}  d="M703.23,597.04c4.92,0.47,9.84,0.93,15.49,1.47c-5.71,0.26-10.89,2.86-15.44-1.53
				C703.28,596.98,703.23,597.04,703.23,597.04z"/>
			<path onClick={() => props.onFill(622)} fill={props.fillColors[622]}  d="M80.93,899.6c2.06-0.17,4.12-0.34,6.18-0.51c0.1,0.64,0.19,1.28,0.29,1.92c-2.08,0.25-4.17,0.49-6.25,0.74
				C81.08,901.04,81.01,900.32,80.93,899.6z"/>
			<path onClick={() => props.onFill(623)} fill={props.fillColors[623]}  d="M121.9,886.79c-0.44-1.4-1.02-2.79-1.21-4.23c-0.04-0.28,1.45-0.76,2.23-1.15
				c0.45,1.54,0.9,3.07,1.36,4.61C123.49,886.28,122.69,886.54,121.9,886.79z"/>
			<path onClick={() => props.onFill(624)} fill={props.fillColors[624]}  d="M579.53,526.54c-2.8,0.24-5.6,0.48-8.4,0.72c-0.06-0.47-0.12-0.93-0.18-1.4c2.72-0.46,5.45-0.91,8.17-1.37
				C579.26,525.18,579.4,525.86,579.53,526.54z"/>
			<path onClick={() => props.onFill(625)} fill={props.fillColors[625]}  d="M247.09,922.8c0-5.71,0-11.41,0-17.12c0.17,0,0.34,0,0.5,0c0,5.77,0,11.55,0,17.32
				C247.43,922.94,247.26,922.87,247.09,922.8z"/>
			<path onClick={() => props.onFill(626)} fill={props.fillColors[626]}  d="M561.03,399.95c1.98,0.12,3.96,0.25,5.93,0.37c0.02,0.55,0.05,1.11,0.07,1.66
				c-2.04,0.12-4.07,0.25-6.11,0.37C560.96,401.55,560.99,400.75,561.03,399.95z"/>
			<path onClick={() => props.onFill(627)} fill={props.fillColors[627]}  d="M115.23,871.03c-0.77-1.2-1.54-2.41-2.31-3.61c0.63-0.37,1.66-1.19,1.82-1.04
				c1.01,0.91,1.83,2.04,2.71,3.09C116.71,869.99,115.97,870.51,115.23,871.03z"/>
			<path onClick={() => props.onFill(628)} fill={props.fillColors[628]}  d="M130.48,935.25c-1.93-0.73-3.87-1.47-5.8-2.2c0.21-0.58,0.42-1.17,0.63-1.75c1.94,0.63,3.89,1.27,5.83,1.9
				C130.92,933.88,130.7,934.56,130.48,935.25z"/>
			<path onClick={() => props.onFill(629)} fill={props.fillColors[629]}  d="M248.12,881.6c0,3.05,0,6.1,0,9.14c-0.3,0-0.59,0-0.89,0.01c0-3.04,0-6.09,0-9.13
				C247.53,881.61,247.83,881.61,248.12,881.6z"/>
			<path onClick={() => props.onFill(630)} fill={props.fillColors[630]}  d="M143.44,894.57c-0.29-1.14-0.92-2.33-0.75-3.39c0.12-0.74,1.36-1.81,2.07-1.79
				c0.75,0.02,1.68,1.06,2.09,1.88c0.16,0.33-0.87,1.25-1.35,1.9C144.81,893.64,144.13,894.11,143.44,894.57z"/>
			<path onClick={() => props.onFill(631)} fill={props.fillColors[631]}  d="M238.76,925.59c-3.24,0-6.47,0-9.71,0c-0.02-0.22-0.05-0.45-0.07-0.67c3.26-0.24,6.51-0.49,9.77-0.73
				C238.75,924.65,238.75,925.12,238.76,925.59z"/>
			<path onClick={() => props.onFill(632)} fill={props.fillColors[632]}  d="M67.82,885.08c3.07,0.32,6.13,0.64,9.2,0.97c-0.04,0.37-0.07,0.75-0.11,1.12
				c-3.04-0.22-6.08-0.44-9.12-0.66C67.8,886.03,67.81,885.55,67.82,885.08z"/>
			<path onClick={() => props.onFill(633)} fill={props.fillColors[633]}  d="M23.02,893.39c-1.68,0.67-2.62,1.36-3.47,1.27c-0.62-0.06-1.14-1.15-1.7-1.78
				c0.82-0.54,1.61-1.41,2.49-1.52C20.91,891.28,21.63,892.29,23.02,893.39z"/>
			<path onClick={() => props.onFill(634)} fill={props.fillColors[634]}  d="M109.17,869.27c-1.08-0.64-2.15-1.29-3.23-1.93c0.72-0.68,1.45-1.36,2.17-2.03
				c0.76,0.86,1.53,1.72,2.29,2.59C109.99,868.35,109.58,868.81,109.17,869.27z"/>
			<path onClick={() => props.onFill(635)} fill={props.fillColors[635]}  d="M101.18,863.25c0.58,1.07,1.21,2.13,1.65,3.26c0.04,0.09-1.16,0.67-1.79,1.03
				c-0.57-1.12-1.14-2.23-1.72-3.35C99.94,863.87,100.56,863.56,101.18,863.25z"/>
			<path onClick={() => props.onFill(636)} fill={props.fillColors[636]}  d="M104.56,851.83c0.19,1.44,0.39,2.88,0.58,4.32c-0.53,0.1-1.06,0.21-1.59,0.31
				c-0.31-1.43-0.61-2.85-0.92-4.28C103.28,852.07,103.92,851.95,104.56,851.83z"/>
			<path onClick={() => props.onFill(637)} fill={props.fillColors[637]}  d="M109.08,857.53c-0.16-1.42-0.31-2.84-0.47-4.27c0.57-0.07,1.15-0.13,1.72-0.2
				c0.14,1.42,0.28,2.84,0.42,4.27C110.2,857.4,109.64,857.46,109.08,857.53z"/>
			<path onClick={() => props.onFill(638)} fill={props.fillColors[638]}  d="M114.94,858.6c-0.17-1.4-0.33-2.8-0.5-4.21c0.59-0.07,1.18-0.15,1.77-0.22c0.16,1.4,0.31,2.8,0.47,4.2
				C116.09,858.45,115.52,858.53,114.94,858.6z"/>
			<path onClick={() => props.onFill(639)} fill={props.fillColors[639]}  d="M854.8,731.56c-0.46,1.66-0.92,3.32-1.38,4.99c-0.41-0.1-0.82-0.19-1.23-0.29
				c0.4-1.68,0.81-3.35,1.21-5.03C853.86,731.34,854.33,731.45,854.8,731.56z"/>
			<path onClick={() => props.onFill(640)} fill={props.fillColors[640]}  d="M119.28,831.36c0.08-1.25,0.16-2.5,0.23-3.75c0.5,0.01,1,0.02,1.49,0.03c0,1.29,0,2.57,0,3.86
				C120.43,831.45,119.85,831.4,119.28,831.36z"/>
			<path onClick={() => props.onFill(641)} fill={props.fillColors[641]}  d="M289.49,970.16c0,1.9,0,3.79,0,5.69c-0.28,0.01-0.57,0.01-0.85,0.02c0-1.9,0-3.79,0-5.69
				C288.93,970.18,289.21,970.17,289.49,970.16z"/>
			<path onClick={() => props.onFill(642)} fill={props.fillColors[642]}  d="M87.04,882.53c-1.85,0-3.69,0-5.54,0c-0.03-0.35-0.06-0.69-0.09-1.04c1.86-0.16,3.72-0.32,5.58-0.48
				C87.01,881.52,87.02,882.02,87.04,882.53z"/>
			<path onClick={() => props.onFill(643)} fill={props.fillColors[643]}  d="M91.43,881.12c1.52,0.24,3.03,0.48,4.55,0.71c-0.09,0.54-0.17,1.09-0.26,1.63
				c-1.53-0.25-3.07-0.5-4.6-0.74C91.22,882.19,91.32,881.65,91.43,881.12z"/>
			<path onClick={() => props.onFill(644)} fill={props.fillColors[644]}  d="M391.3,369.38c2.09,0,4.18,0,6.27,0c0.02,0.29,0.04,0.58,0.06,0.87c-2.08,0.16-4.16,0.33-6.24,0.49
				C391.37,370.29,391.34,369.83,391.3,369.38z"/>
			<ellipse onClick={() => props.onFill(645)} fill={props.fillColors[645]}  cx="235.56" cy="856.52" rx="0.11" ry="7.29"/>
			<path onClick={() => props.onFill(646)} fill={props.fillColors[646]}  d="M175.63,888.8c-1.23,0.7-2.47,1.41-3.7,2.11c-0.29-0.5-0.57-1.01-0.86-1.51c1.24-0.69,2.48-1.38,3.73-2.07
				C175.08,887.81,175.36,888.31,175.63,888.8z"/>
			<path onClick={() => props.onFill(647)} fill={props.fillColors[647]}  d="M130.96,852.04c-0.52-1.22-1.04-2.43-1.56-3.65c0.62-0.26,1.23-0.52,1.85-0.78
				c0.52,1.22,1.03,2.43,1.55,3.65C132.18,851.53,131.57,851.79,130.96,852.04z"/>
			<path onClick={() => props.onFill(648)} fill={props.fillColors[648]}  d="M146.46,902.83c-1.06,0.55-2.13,1.11-3.19,1.66c-0.26-0.5-0.51-1-0.77-1.5c1.07-0.54,2.14-1.09,3.21-1.63
				C145.96,901.85,146.21,902.34,146.46,902.83z"/>
			<path onClick={() => props.onFill(649)} fill={props.fillColors[649]}  d="M120.05,859.55c-0.11-1.3-0.21-2.6-0.32-3.89c0.57-0.05,1.13-0.1,1.7-0.15c0.11,1.3,0.22,2.59,0.33,3.89
				C121.2,859.45,120.62,859.5,120.05,859.55z"/>
			<path onClick={() => props.onFill(650)} fill={props.fillColors[650]}  d="M686.35,172.96c-2.8-0.13-5.59-0.27-8.39-0.4c0.1-0.58,0.19-1.15,0.29-1.73c2.68,0.73,5.37,1.46,8.05,2.19
				C686.3,173.02,686.35,172.96,686.35,172.96z"/>
			<path onClick={() => props.onFill(651)} fill={props.fillColors[651]}  d="M408.78,252.02c1.94-1.26,3.87-2.52,5.81-3.79c0.28,0.58,0.56,1.17,0.84,1.75
				c-2.23,0.66-4.47,1.32-6.7,1.98C408.73,251.96,408.78,252.02,408.78,252.02z"/>
			<path onClick={() => props.onFill(652)} fill={props.fillColors[652]}  d="M274.2,898.06c-1.1-0.57-2.2-1.15-3.34-1.74c0.36-0.49,0.59-0.97,0.7-0.94c1.17,0.3,2.31,0.69,3.47,1.05
				C274.75,896.97,274.47,897.52,274.2,898.06z"/>
			<path onClick={() => props.onFill(653)} fill={props.fillColors[653]}  d="M877.03,301.96c-3.11-1.04-6.22-2.09-9.33-3.13c0.14-0.35,0.29-0.69,0.43-1.04
				c2.95,1.41,5.89,2.82,8.84,4.23C876.97,302.03,877.03,301.96,877.03,301.96z"/>
			<path onClick={() => props.onFill(654)} fill={props.fillColors[654]}  d="M38.93,851.73c1.71,0,3.42,0,5.12,0c-0.01,0.38-0.02,0.77-0.03,1.15c-1.71-0.08-3.41-0.15-5.12-0.23
				C38.91,852.35,38.92,852.04,38.93,851.73z"/>
			<path onClick={() => props.onFill(655)} fill={props.fillColors[655]}  d="M422.69,264.9c0.39-1.91,0.78-3.81,1.16-5.72c0.4,0.09,0.79,0.18,1.19,0.28
				c-0.52,1.88-1.05,3.75-1.57,5.63C423.21,265.02,422.95,264.96,422.69,264.9z"/>
			<path onClick={() => props.onFill(656)} fill={props.fillColors[656]}  d="M251.05,394.96c-1.38,1.92-2.77,3.84-4.15,5.75c-0.39-0.36-0.79-0.73-1.18-1.09
				c1.8-1.53,3.6-3.06,5.4-4.59C251.11,395.03,251.05,394.96,251.05,394.96z"/>
			<path onClick={() => props.onFill(657)} fill={props.fillColors[657]}  d="M688.98,330.75c-1.89-0.37-3.78-0.75-5.67-1.12c0.02-0.17,0.03-0.35,0.05-0.52
				c1.89,0.1,3.78,0.21,5.67,0.31C689.01,329.87,688.99,330.31,688.98,330.75z"/>
			<path onClick={() => props.onFill(658)} fill={props.fillColors[658]}  d="M7.77,896.09c-0.44-1.18-0.97-1.88-0.81-2.36c0.19-0.6,0.89-1.21,1.5-1.41c0.33-0.11,0.94,0.62,1.43,0.96
				C9.46,893.84,9.03,894.41,7.77,896.09z"/>
			<path onClick={() => props.onFill(659)} fill={props.fillColors[659]}  d="M795.03,425.06c2.32,0,4.63,0,6.94,0c-0.08,0.58-0.15,1.15-0.23,1.73c-2.22-0.59-4.45-1.19-6.67-1.78
				C795.08,425.02,795.03,425.06,795.03,425.06z"/>
			<path onClick={() => props.onFill(660)} fill={props.fillColors[660]}  d="M131.11,910.38c-1.03,0.37-2.06,0.75-3.09,1.12c-0.17-0.41-0.33-0.82-0.5-1.23
				c1.05-0.41,2.11-0.82,3.16-1.23C130.83,909.49,130.97,909.94,131.11,910.38z"/>
			<path onClick={() => props.onFill(661)} fill={props.fillColors[661]}  d="M27.94,890.26c1.8,0,3.6,0,5.41,0c0.01,0.12,0.02,0.25,0.02,0.37c-1.88,0-3.77,0-5.65,0
				C27.8,890.51,27.87,890.38,27.94,890.26z"/>
			<path onClick={() => props.onFill(662)} fill={props.fillColors[662]}  d="M910.1,743.84c0.24,1.19,0.48,2.38,0.72,3.58c-0.5,0.04-1,0.08-1.5,0.12c0.12-1.13,0.24-2.26,0.36-3.38
				C909.82,744.05,909.96,743.94,910.1,743.84z"/>
			<path onClick={() => props.onFill(663)} fill={props.fillColors[663]}  d="M21.66,875.32c-1.12-0.47-2.24-0.94-3.36-1.42c0.08-0.25,0.15-0.51,0.23-0.76
				c1.24,0.23,2.49,0.46,3.73,0.7C22.05,874.33,21.86,874.82,21.66,875.32z"/>
			<path onClick={() => props.onFill(664)} fill={props.fillColors[664]}  d="M45.41,846.8c-0.68,0.73-1.37,1.47-2.05,2.2c-0.34-0.29-0.68-0.58-1.02-0.87
				c0.62-0.79,1.23-1.58,1.85-2.37C44.6,846.11,45,846.45,45.41,846.8z"/>
			<path onClick={() => props.onFill(665)} fill={props.fillColors[665]}  d="M81.52,886.22c1.66-0.09,3.31-0.18,4.97-0.27c0.02,0.26,0.05,0.53,0.07,0.79
				c-1.64,0.22-3.28,0.43-4.93,0.65C81.6,887,81.56,886.61,81.52,886.22z"/>
			<path onClick={() => props.onFill(666)} fill={props.fillColors[666]}  d="M14.18,891.78c0.06,0.91,0.13,1.82,0.19,2.73c-0.33,0.02-0.66,0.04-0.99,0.06c0-0.91,0-1.83,0-2.74
				C13.65,891.81,13.92,891.8,14.18,891.78z"/>
			<path onClick={() => props.onFill(667)} fill={props.fillColors[667]}  d="M465.71,494.02c0.83-1.65,1.67-3.3,2.5-4.96c0.29,0.18,0.58,0.37,0.87,0.55
				c-1.14,1.46-2.27,2.92-3.41,4.38C465.67,493.99,465.71,494.02,465.71,494.02z"/>
			<path onClick={() => props.onFill(668)} fill={props.fillColors[668]}  d="M42.78,896.03c0-1.67,0-3.35,0-5.02c0.08,0,0.16,0,0.24,0c0,1.67,0,3.35,0,5.02
				C42.94,896.04,42.86,896.04,42.78,896.03z"/>
			<path onClick={() => props.onFill(669)} fill={props.fillColors[669]}  d="M558.95,407.36c0.38-0.65,0.76-1.31,1.14-1.96c0.24,0.13,0.47,0.27,0.71,0.4c-0.4,0.65-0.79,1.3-1.19,1.95
				C559.39,407.62,559.17,407.49,558.95,407.36z"/>
			<path onClick={() => props.onFill(670)} fill={props.fillColors[670]}  d="M279.71,1028.66c-0.39,0.87-0.77,1.73-1.16,2.6c-0.26-0.11-0.51-0.23-0.77-0.34
				c0.43-0.85,0.85-1.71,1.28-2.56C279.28,1028.46,279.49,1028.56,279.71,1028.66z"/>
			<path onClick={() => props.onFill(671)} fill={props.fillColors[671]}  d="M274.76,846.36c-0.06,1.1-0.13,2.19-0.19,3.29c-0.06-1.03-0.12-2.07-0.18-3.1
				C274.51,846.49,274.64,846.43,274.76,846.36z"/>
			<path d="M597.32,744.66c-17.26,0-34.52,0-51.77,0c0-0.45,0-0.9,0-1.34c17.26,0,34.52,0,51.78,0
				C597.33,743.76,597.32,744.21,597.32,744.66z"/>
			<path onClick={() => props.onFill(672)} fill={props.fillColors[672]}  d="M615.35,1215.93c-29.75,6.57-57.15,19.28-84.44,32.33c-6.74,3.23-13.71,5.96-20.5,9.09
				c-9.14,4.21-18.8,4.61-28.36,3.03c-15.16-2.5-30.15-3.36-45.58-2c-12.1,1.07-24.54-0.21-36.69-1.68
				c-22.41-2.71-44.37-0.55-66.42,3.33c-20.87,3.67-41.39,10.69-62.97,8.6c-15.36-1.49-30.66-3.71-45.92-6.03
				c-21.26-3.24-42.31-2.56-63.46,1.3c-9.91,1.81-20.1,2.37-30.19,2.71c-8.14,0.27-15.57,2.23-23.13,5.14
				c-14.83,5.71-29.72,11.14-46.22,10.43c-16.6-0.71-33.25-0.16-49.88-0.16c-0.18-0.58-0.36-1.16-0.54-1.74
				c9.56-3.72,18.95-8.01,28.72-11.04c14.84-4.6,30.19-6.85,45.74-5.91c13,0.79,25.37-2.19,37.95-4.47
				c26.65-4.82,53.47-5.9,80.33-1.63c5.73,0.91,11.33,2.64,17.07,3.49c9.31,1.38,18.89,0.79,26.19-5.29
				c11.62-9.68,24.04-8.93,37.17-5.52c6.42,1.67,12.72,3.86,19.2,5.23c13.8,2.92,27.24,0.07,40.23-4.17
				c19.47-6.35,38.96-6.76,59.07-3.84c10.89,1.58,22.23,1.71,33.19,0.62c12.62-1.25,24.43,0.03,36.11,4.72
				c5.06,2.03,10.33,3.71,15.65,4.93c9.01,2.08,16.56-0.9,21.79-8.45c3.15-4.56,7.27-6.86,12.29-8.68c7.48-2.71,14.71-6.12,22.01-9.3
				c11.88-5.18,23.52-11.03,35.69-15.38c5.88-2.1,12.76-1.94,19.17-1.86c5.59,0.06,11.17,1.44,16.76,2.23L615.35,1215.93z"/>
			<path onClick={() => props.onFill(673)} fill={props.fillColors[673]}  d="M46.35,1215.28c15.71,0,31.42,0,47.14,0c3.83,0,7.65,0.04,11.48,0.02c9.33-0.03,17.83,0.12,26.87,5.59
				c9.58,5.79,21.9,7.07,33.03,10.26c1.42,0.41,3.01,0.25,4.42,0.67c19.26,5.68,34.48-1.98,48.37-14.44
				c1.33-1.19,3.43-2.02,5.22-2.11c14.45-0.73,28.91-1.24,43.37-1.81c1.16-0.05,2.33-0.04,3.49-0.02
				c44.68,0.65,89.36,1.47,134.05,1.87c16.95,0.15,33.91-0.85,50.87-0.99c10.14-0.08,20.28,0.63,30.43,0.71
				c5.97,0.04,11.94-0.71,17.91-0.68c7.6,0.03,15.19,0.55,22.78,0.74c4.97,0.12,9.94,0.02,15.39,0.78
				c-5.76,1.91-11.41,4.25-17.29,5.65c-19.5,4.64-39.46,4.57-59.34,5.53c-5.46,0.27-11.24,2.05-16.12,4.59
				c-11.58,6.01-22.84,6.9-34.14,0.06c-18.97-11.49-39.8-11.38-60.49-8.57c-9.99,1.36-19.75,1.19-29.64,1.35
				c-4.29,0.07-8.73,0.37-12.82,1.54c-18.62,5.32-37.03,2.64-55.16-1.84c-8.57-2.11-16.44-0.55-24.59,0.77
				c-0.62,0.1-1.3,0.46-1.75,0.91c-9.58,9.55-22.57,8.39-34.36,11.01c-1.76,0.39-3.89,0.25-5.24,1.22c-4.73,3.39-9.56,3.01-14.74,1.5
				c-16.28-4.75-32.75-5.42-49.28-1.53c-10.91,2.56-19.12-3.06-26.4-9.38c-6.31-5.48-9.84-6.97-17.66-3.65
				c-5.76,2.44-11.61,4.07-16.64-2.12c-1.28-1.58-3.96-2.26-6.14-2.81c-4.39-1.12-8.88-1.88-13.32-2.79
				C46.12,1216.63,46.24,1215.95,46.35,1215.28z"/>
			<path onClick={() => props.onFill(674)} fill={props.fillColors[674]}  d="M806.18,1214.29c-16.35,12.75-33.54,18.26-52.75,17.73c-14.95-0.42-29.73,0.08-44.57,3.38
				c-8.65,1.92-18.18,0.69-27.22-0.16c-19.31-1.81-38.53-4.55-57.84-6.38c-7.91-0.75-15.97-0.39-23.93-0.05
				c-3.74,0.16-7.67,0.88-11.09,2.35c-26.21,11.3-52.34,22.8-78.43,34.36c-8.71,3.86-17.92,4.75-26.89,2.99
				c-17.7-3.47-35.24-3.89-52.94-0.61c-9.2,1.71-18.37,0.74-27.1-2.1c-24.11-7.87-47.77-5.18-71.62,1.39
				c-20.32,5.6-40.76,11.68-62.21,9.47c-14.19-1.46-28.46-3.17-42.35-6.27c-22.8-5.09-45.31-5.46-68.17-0.62
				c-8.35,1.77-17.15,2.12-25.73,1.98c-9.69-0.16-18.49,1.31-26.06,7.76c-5.79,4.94-12.56,2.72-19.21,1.92
				c4.77-1.43,9.89-2.19,14.26-4.42c12.87-6.57,26.47-7.53,40.61-7.97c12.53-0.39,24.98-3.28,37.48-4.97
				c15.31-2.07,30.5-0.42,45.69,1.59c16.46,2.18,32.91,5.44,49.42,5.85c12.75,0.32,25.67-2.64,38.39-4.85
				c21.91-3.81,43.61-9.21,66.07-8.38c8.44,0.31,16.84,1.73,25.29,2.2c10.24,0.56,20.61,1.72,30.73,0.69
				c16.26-1.66,32.17-0.41,48.1,2.29c10.37,1.76,20.12-0.17,29.59-4.49c18.74-8.55,37.34-17.45,56.38-25.27
				c13.17-5.41,26.82-9.85,40.55-13.62c9.44-2.59,19.23-1.24,28.22,3.09c9.04,4.36,18.54,5.81,28.48,6.21
				c8.93,0.36,17.83,1.67,26.7,2.88c14.79,2.03,29.26-0.47,43.49-3.66c13.73-3.08,26.99-8.26,40.72-11.4
				C786.94,1215.18,796.13,1215.25,806.18,1214.29z"/>
			<path onClick={() => props.onFill(675)} fill={props.fillColors[675]}  d="M109.11,1280.81c4.26-1.85,8.57-5.4,12.77-5.26c20.79,0.68,40.91-4.12,61.35-6.27
				c17.26-1.82,34.02,2.27,50.89,5.25c13.4,2.36,26.93,4.24,40.48,5.32c16.3,1.3,31.89-3.74,47.62-7.11
				c13.46-2.89,26.83-6.37,40.41-8.41c12.41-1.86,24.81-0.22,36.94,3.55c13.26,4.13,26.77,4.32,40.42,1.62
				c12.58-2.49,25.19-1.49,37.64,1.07c13.68,2.82,26.6,1.48,39.23-4.88c11.54-5.8,23.5-10.76,36.24-16.52
				c-1.21,1.29-1.73,1.95-2.35,2.51c-5.28,4.76-10.25,9.95-15.96,14.14c-9.09,6.66-18.84,12.05-30.89,9.67
				c-2.11-0.42-4.29-0.78-6.42-0.71c-17.85,0.58-35.69,1.46-53.54,1.82c-5.38,0.11-10.79-1.12-16.18-1.75
				c-2.29-0.27-4.94-1.48-6.8-0.72c-10,4.06-19.49-1.21-29.1-1c-13.52,0.3-26.7-4.14-40.55-0.93c-7.78,1.8-14.98,3.29-21.31,8.37
				c-1.56,1.26-4.38,1.34-6.61,1.31c-27.11-0.33-54.22-1.26-81.32-1.09c-42.23,0.25-84.46,1.23-126.7,1.89c-1.82,0.03-3.65,0-5.47,0
				C109.64,1282.06,109.38,1281.44,109.11,1280.81z"/>
			<path onClick={() => props.onFill(676)} fill={props.fillColors[676]}  d="M764.2,1234.17c2.77-0.32,5.54-0.67,8.31-0.97c7.53-0.82,14.24-3.12,20.78-7.47
				c12.19-8.1,25.25-12.75,40.82-11.04c15.29,1.68,30.9,0.31,46.37,0.36c27.28,0.08,54.57,0.22,81.85,0.34
				c0.59,0,1.19,0.08,3.24,0.22c-3.69,2.48-6.05,4.69-8.84,5.84c-11.91,4.9-23.64,5.6-36.69,2.51c-16.05-3.79-32.28-9.4-49.34-6.27
				c-6.36,1.16-12.68,2.57-19.01,3.89c-1.3,0.27-2.75,0.36-3.82,1.03c-7.71,4.81-15.75,8.38-25.14,7.91
				c-0.94-0.05-2.54,0.91-2.82,1.74c-1.64,4.87-5.64,4.64-9.42,4.52c-15.42-0.49-30.84-1.12-46.26-1.7
				C764.22,1234.77,764.21,1234.47,764.2,1234.17z"/>
			<path onClick={() => props.onFill(677)} fill={props.fillColors[677]}  d="M663.4,1281.84c6.45-7.21,14.9-9.18,23.53-9.38c7.58-0.17,15.21,1.1,22.79,2
				c12.5,1.47,24.75,0.15,37.15-1.64c7.56-1.09,15.5,0.41,23.27,0.83c4.95,0.27,9.88,0.82,14.83,0.99
				c7.29,0.26,14.58,0.32,21.88,0.42c2.82,0.04,5.82,0.56,8.44-0.18c13.2-3.74,24.23,2.3,35.26,6.96
				C788.53,1281.84,726.27,1281.84,663.4,1281.84z"/>
			<path onClick={() => props.onFill(678)} fill={props.fillColors[678]}  d="M464.37,1279.99c6.47-0.9,12.92-2.27,19.43-2.58c6.43-0.3,13.06-0.3,19.34,0.93
				c12.7,2.48,22.97-2.85,32.42-9.68c4.97-3.6,9.65-4.11,14.87-3.07c15.57,3.11,31.07,5.1,46.94,1.85c4.26-0.87,8.9,0.17,13.36,0.25
				c1.44,0.02,2.99,0.06,4.32-0.41c8.45-2.94,16.87-5.99,25.28-9.07c8.01-2.93,8.21-3.57,8.55-12.33c0.06-1.57,2.14-3.62,3.79-4.47
				c5.75-2.94,14.1-2.99,21.32-0.46c-3.27,1.32-6.26,2.33-9.07,3.7c-2.98,1.46-4.76,3.95-3.46,7.34c1.7,4.44-0.73,6.3-4.27,7.29
				c-7.99,2.24-16.41,3.36-23.98,6.56c-12.39,5.23-25.36,4.99-38.22,5.9c-11.55,0.82-23.18,1.26-34.75,0.95
				c-5.91-0.16-11.27,0.48-16.24,3.43c-6.6,3.91-13.35,5.2-21.22,4.99c-17.76-0.48-35.55,0.47-53.33,0.83c-1.63,0.03-3.25,0-4.88,0
				C464.49,1281.3,464.43,1280.65,464.37,1279.99z"/>
			<path onClick={() => props.onFill(679)} fill={props.fillColors[679]}  d="M628.39,1236.43c9.11,0,17.79,0,26.46,0c0.05,0.23,0.09,0.47,0.14,0.7c-1.86,0.89-3.77,1.69-5.57,2.69
				c-2.86,1.59-4.65,3.77-3.41,7.33c1.33,3.84-0.53,6.05-3.92,7.3c-9.32,3.45-18.66,6.83-27.99,10.24c-0.31,0.11-0.65,0.16-0.98,0.16
				c-17.16-0.01-34.33,0.23-51.47-0.2c-5.89-0.15-11.74-1.96-18.64-3.19c8.89-7.22,16.72-14.57,28.22-16.2
				c-1.86,12.41-1.08,13.44,11.54,12.95c10.1-0.39,20.2-1.1,30.26-2.12c3.37-0.34,6.76-1.76,9.86-3.28
				C630.36,1249.13,631.91,1244.57,628.39,1236.43z"/>
			<path onClick={() => props.onFill(680)} fill={props.fillColors[680]}  d="M688.24,1256.86c5.76-2.84,11.43-1.98,17.22,0.74c5.4,2.54,10.93,4.82,16.46,7.08
				c1.18,0.48,2.62,0.62,3.91,0.52c7.92-0.58,15.88-0.9,23.73-1.99c16.56-2.31,33.05-1.08,49.56-0.3c0.04,0.45,0.07,0.9,0.11,1.35
				c-5.04,0.62-10.06,1.61-15.11,1.76c-7.97,0.23-15.96-0.23-23.94-0.13c-2.77,0.03-5.77,0.32-8.25,1.41
				c-14.62,6.42-29.81,5.82-45.06,3.91c-12.6-1.57-25.14-3.32-37.19,2.48c-2.21,1.06-4.9,2.16-6.12,4.05
				c-3.69,5.73-8.85,5.41-14.89,4.58c5.36-5.45,9.29-11.98,17.86-12.43c2.34-0.12,4.51-2.13,6.9-2.72c2.94-0.73,6.02-0.93,9.05-1.27
				c2.76-0.31,5.64-0.16,8.27-0.9c1.42-0.4,2.44-2.24,3.64-3.44c-1.42-0.95-2.81-1.94-4.26-2.82c-0.51-0.31-1.18-0.36-1.78-0.53
				C688.3,1257.76,688.27,1257.31,688.24,1256.86z"/>
			<path onClick={() => props.onFill(681)} fill={props.fillColors[681]}  d="M615.39,1215.98c11.28-0.17,22.62-1.12,33.82-0.24c6.93,0.55,13.53,4.4,20.48,5.83
				c9.73,2.01,19.59,3.66,29.47,4.5c8.96,0.76,17.12-2.5,25.09-6.79c4.94-2.66,10.85-4.34,16.45-4.75
				c10.07-0.75,20.24-0.21,30.36-0.21c0.06,0.49,0.12,0.97,0.18,1.46c-8.55,2.77-16.94,6.45-25.7,8.1
				c-14.3,2.69-28.83,5.47-43.29,5.61c-16,0.16-32.06-2.34-48.03-4.21c-4.67-0.55-9.27-2.58-13.65-4.5
				c-8.08-3.56-16.51-4.84-25.22-4.83C615.35,1215.93,615.39,1215.98,615.39,1215.98z"/>
			<path onClick={() => props.onFill(682)} fill={props.fillColors[682]}  d="M334.35,1280.71c2.87-1.47,5.62-3.89,8.62-4.24c10.91-1.3,21.61-4.34,32.98-1.46
				c7.86,2,16.34,1.52,24.54,2.23c1.96,0.17,3.89,0.83,5.85,0.94c3.04,0.17,6.1,0.09,9.16,0.08c4.38-0.02,9.07-1.11,13.07,0.1
				c9.59,2.88,18.77,0.32,28.12-0.54c1.9-0.17,3.8-0.32,5.7-0.48c0.14,0.65,0.28,1.3,0.42,1.95c-4.28,1.05-8.56,3.01-12.83,2.99
				c-20.25-0.08-40.5-0.9-60.75-1.05c-13.78-0.1-27.56,0.47-41.34,0.7c-4.4,0.07-8.8,0.01-13.2,0.01
				C334.58,1281.53,334.47,1281.12,334.35,1280.71z"/>
			<path onClick={() => props.onFill(683)} fill={props.fillColors[683]}  d="M581.28,1255.68c-1.16,0-2.34,0.1-3.49-0.02c-4.88-0.52-6.18-2.64-3.94-7.08
				c3.83-7.63,9.98-12.96,17.98-15.63c10.86-3.61,21.44-1.64,31.19,3.76c6.61,3.66,6.53,9.6-0.34,12.78
				c-5.6,2.59-11.9,4.3-18.04,4.97c-7.7,0.83-15.56,0.2-23.35,0.2C581.29,1255.01,581.28,1255.34,581.28,1255.68z M598.25,1250.22
				c0.03,0.2,0.06,0.4,0.09,0.61c4.88-0.74,9.83-1.17,14.61-2.31c3.4-0.82,6.73-2.6,6.73-6.9c0.01-4.09-3.31-5.38-6.4-6.7
				c-0.61-0.26-1.25-0.43-1.89-0.61c-9.75-2.78-19.22,0.13-23.66,7.68c-1.05,1.79-1.67,4.68-0.93,6.41c0.56,1.3,3.53,1.88,5.51,2.18
				C594.24,1250.85,596.27,1250.37,598.25,1250.22z"/>
			<path onClick={() => props.onFill(684)} fill={props.fillColors[684]}  d="M215.11,1216.94c-4.55,2.89-8.9,6.2-13.72,8.55c-4.89,2.38-10.21,3.92-15.4,5.6
				c-1.68,0.55-3.68,0.88-5.36,0.5c-13.23-2.99-26.47-5.96-39.57-9.43c-4.59-1.22-8.81-3.82-12.95-6.45
				c4.64-1.52,8.56-1.23,13.01,0.65c8.47,3.58,17.44,5.97,26.24,8.77c1.4,0.45,3.01,0.25,4.4,0.71c11.4,3.81,20.99-1.07,29.61-7.3
				C205.76,1215.36,209.5,1214.19,215.11,1216.94z"/>
			<path onClick={() => props.onFill(685)} fill={props.fillColors[685]}  d="M658.11,1214.64c22.3,0,44.6,0,66.64,0c-3.99,4.24-11.43,7.47-19.4,8.26
				c-16.39,1.63-31.67-3.84-47.36-6.69C658.02,1215.7,658.06,1215.17,658.11,1214.64z"/>
			<path onClick={() => props.onFill(686)} fill={props.fillColors[686]}  d="M146.17,1214.98c18.04,0,36.08,0,54.28,0c-0.01-0.02,0.28,0.44,0.26,0.45c-8,5.74-16.18,10.71-26.76,7.99
				c-7.82-2.02-15.74-3.67-23.59-5.58c-1.54-0.37-2.97-1.18-4.46-1.79C145.99,1215.7,146.08,1215.34,146.17,1214.98z"/>
			<path onClick={() => props.onFill(687)} fill={props.fillColors[687]}  d="M633.24,1233.21c-4.29-0.21-8.58-0.41-12.87-0.62c0.03-0.49,0.06-0.98,0.1-1.47
				c4.2,0.37,8.4,0.73,12.61,1.1C633.13,1232.55,633.18,1232.88,633.24,1233.21z"/>
			<path onClick={() => props.onFill(688)} fill={props.fillColors[688]}  d="M568.25,1242.04c2.46-0.77,4.93-1.54,7.39-2.3c0.1,0.33,0.2,0.66,0.29,0.99c-2.48,0.7-4.96,1.4-7.44,2.1
				C568.41,1242.57,568.33,1242.3,568.25,1242.04z"/>
			<path onClick={() => props.onFill(689)} fill={props.fillColors[689]}  d="M197.93,85.32c22.49-8.75,45.63-14.53,69.89-13.94c9.75,0.24,19.44,2.77,29.19,3.1
				c12.47,0.42,24.15,2.85,35.73,7.66c13.48,5.6,26.98,2.34,40.38-2.12c27.21-9.06,52.88-21.7,78.79-33.71
				c13.07-6.06,26.02-7.47,39.75-4.78c13.18,2.58,26.28,2.18,39.65,1.23c12.47-0.89,25.25,0.06,37.69,1.7
				c20.92,2.77,41.45,1.01,61.96-2.76c14.37-2.64,28.67-5.68,43.05-8.29c14.28-2.59,28.45-0.71,42.66,1.26
				c16.94,2.35,33.92,5.32,50.95,5.86c13.64,0.43,27.38-2.4,41.08-3.72c9.24-0.89,18.49-2.12,27.76-2.35
				c8.29-0.21,15.93-1.88,23.68-4.91c15.74-6.17,31.64-11.6,49.17-10.63c15.75,0.87,31.59,0.18,47.39,0.18
				c0.15,0.43,0.3,0.86,0.46,1.28c-7.08,3.11-14.01,6.62-21.26,9.25c-14.47,5.24-29.56,8.54-44.91,8.01
				c-17.49-0.6-34.31,2.56-51.37,5.39c-28.02,4.65-56.17,5.86-84.12-1.18c-14.67-3.7-28.24-2.15-40.45,7.75
				c-4.82,3.91-10.97,4.88-17.07,4.02c-6.07-0.85-12.36-1.5-18.01-3.65c-17.46-6.64-34.79-6.09-52.3-0.71
				c-14.86,4.56-29.87,8.11-45.59,6.53c-6.11-0.61-12.18-1.85-18.3-2.14c-10.24-0.49-20.59-1.32-30.75-0.39
				c-12,1.1-23.44,0.67-34.75-3.82c-6.14-2.44-12.53-4.49-18.99-5.82c-8.88-1.83-15.73,1.97-21.15,9.09c-1.93,2.54-4.46,5.12-7.3,6.4
				c-15.59,7.04-31.29,13.83-47.08,20.39c-7.15,2.97-14.49,5.69-21.98,7.57c-4.02,1.01-8.55,0.01-12.84-0.1
				c-14.94-0.4-29.88-0.71-44.82-1.3c-3.39-0.13-6.81-1-10.1-1.93c-17.58-4.97-35.15-10.42-53.73-7.83
				c-5.1,0.71-10.21,2.99-14.77,5.53c-6.15,3.43-12.42,5.38-19.43,5.3c-9.42-0.1-18.85-0.03-28.27-0.03
				C197.84,86.26,197.88,85.79,197.93,85.32z"/>
			<path onClick={() => props.onFill(690)} fill={props.fillColors[690]}  d="M922.01,85.68c-15.52,0-31.05,0.04-46.57-0.07c-1.2-0.01-2.59-0.76-3.55-1.58
				c-6.68-5.71-14.69-7.67-23.12-7.96c-20.19-0.68-39.83-4.74-59.45-9.05c-2.06-0.45-4.54-0.75-6.41-0.04
				c-11.38,4.31-23.95,6.38-32.27,16.74c-0.98,1.21-3.08,2.03-4.71,2.13c-12.78,0.73-25.57,1.35-38.36,1.76
				c-4.29,0.14-8.61-0.53-12.92-0.64c-6.28-0.17-12.56-0.2-18.84-0.23c-22.76-0.13-45.51-0.3-68.27-0.33
				c-21.44-0.03-42.87,0.2-64.31,0.13c-19.62-0.06-39.24-0.39-58.85-0.5c-4.14-0.02-8.29,0.54-12.43,0.52
				c-8.25-0.04-16.5-0.35-24.76-0.45c-6.45-0.08-12.91-0.02-19.13-0.02c5.07-4.76,12.09-5.41,18.99-6.92
				c18.96-4.13,38.24-3.8,57.41-5.18c5.62-0.4,11.48-2.38,16.52-5c11.18-5.79,22.08-6.11,32.73,0.33
				c19.16,11.61,40.21,11.82,60.94,8.5c12.21-1.95,24.16,0.33,36.11-1.53c9.49-1.48,19.03-4,28.5-3.78
				c11.35,0.26,22.6,3.44,33.97,4.42c7.63,0.65,15.41-0.32,23.11-0.62c0.61-0.02,1.36-0.36,1.79-0.8
				c9.79-9.93,23.17-8.63,35.26-11.33c1.28-0.29,2.89-0.06,3.84-0.76c5.45-3.99,10.97-3.21,17.05-1.45
				c16.46,4.78,33.07,4.39,49.83,1.03c3.68-0.74,8.07,0.6,11.81,1.9c6.09,2.12,11.48,5.32,16.01,10.39c3.38,3.78,8.38,3.85,13.33,1.3
				c6.18-3.19,12.38-4.64,17.86,1.96c1.26,1.52,4.06,1.92,6.23,2.48c4.29,1.11,8.65,1.98,12.98,2.95
				C922.22,84.58,922.11,85.13,922.01,85.68z"/>
			<path onClick={() => props.onFill(691)} fill={props.fillColors[691]}  d="M880.08,19.69c-11.61,3.83-23.21,7.66-34.82,11.47c-0.78,0.26-1.64,0.48-2.45,0.43
				c-17.76-1.02-35.04,2.75-52.46,5.21c-16.79,2.37-33.49,0.91-50.16-1.63c-12.81-1.95-25.65-3.67-38.5-5.3
				c-15.95-2.03-31.42,1.33-46.92,4.41c-22.98,4.56-45.91,9.72-69.58,8.53c-7.29-0.36-14.53-1.7-21.81-2.04
				c-11.57-0.53-23.24-1.53-34.73-0.62c-13.07,1.04-25.9,0.77-38.69-1.69c-13.16-2.53-25.43-0.82-37.81,4.73
				c-26.37,11.82-53.09,22.86-79.79,33.91c-15.76,6.53-31.52,6.96-47.29-0.81c-4.49-2.21-9.83-3.11-14.88-3.72
				c-12.68-1.54-25.4-2.9-38.14-3.74c-21.36-1.4-42.04,2.49-62.19,9.36c-13.58,4.63-27.1,8.76-41.8,7.88
				c-8.39-0.5-16.87,0.42-25.31,0.65c-0.92,0.03-1.86-0.3-2.84-1.69c5.95-0.55,12.01-2.08,17.81-1.41c7.3,0.85,13.23-1.29,18.53-5.5
				c5.46-4.33,11.43-6.69,18.26-7.07c11.93-0.66,23.88-0.98,35.78-1.94c9.23-0.74,18.37-2.65,27.6-3.28c8.74-0.6,17.62-0.9,26.32-0.1
				c20.48,1.87,40.87,4.85,61.36,6.56c7.66,0.64,15.66-0.27,23.26-1.74c19-3.67,37.16-9.9,54.42-18.9
				c11.48-5.98,23.49-10.97,35.32-16.26c10.68-4.77,21.84-4.01,32.84-1.8c14.08,2.83,27.95,2.05,42.11,0.41
				c9.58-1.11,20.06-1.65,29.09,1.06c25.45,7.63,50.09,5.61,75.11-1.4c19.33-5.42,38.85-11.3,59.26-9.26
				c16.17,1.62,32.32,4.06,48.25,7.25c20.2,4.05,40.12,4.28,60.37,0.42c10.2-1.94,20.76-2.54,31.16-2.68
				c9.01-0.13,17.16-1.63,24.1-7.64C866.69,16.7,873.56,19.62,880.08,19.69z"/>
			<path onClick={() => props.onFill(692)} fill={props.fillColors[692]}  d="M3.78,84.48c3.09-1.81,6.02-4.03,9.3-5.37c11.51-4.69,22.92-5.4,35.29-1.74
				c12.09,3.57,24.88,6.06,37.43,6.42c14.99,0.43,29.97-1.89,43.46-10.04c3.43-2.08,8.24-2.13,12.46-2.57
				c3.32-0.34,6.13-0.6,7.76-4.17c0.52-1.13,2.56-2.09,3.96-2.18c5.8-0.38,11.63-0.72,17.42-0.46c10.78,0.47,21.54,1.37,32.41,2.7
				c-4,0.53-8.15,0.54-11.95,1.7c-5.3,1.62-10.72,3.48-15.38,6.37c-5.66,3.52-11.05,5.89-18.02,5.85
				c-7.88-0.04-15.78,1.71-23.66,2.73c-5.4,0.7-10.79,2.08-16.19,2.11c-37.91,0.19-75.81,0.1-113.72,0.1
				C4.17,85.44,3.98,84.96,3.78,84.48z"/>
			<path onClick={() => props.onFill(693)} fill={props.fillColors[693]}  d="M119.21,19.25c61.68,0,123.36,0,186.12,0c-2.84,2.05-5.15,4.25-7.88,5.6c-10.68,5.31-21.98,4.4-33.23,2.6
				c-14.61-2.34-28.98-1.47-43.57,1c-7.33,1.24-15.18-0.53-22.8-0.99c-3.8-0.23-7.58-0.84-11.38-0.86
				c-12.27-0.06-24.67-1.05-36.76,0.44C138.08,28.48,128.86,23.65,119.21,19.25z"/>
			<path onClick={() => props.onFill(694)} fill={props.fillColors[694]}  d="M303.47,53.09c-6.41-2.47-12.69-4.85-18.92-7.36c-0.58-0.23-0.78-1.31-1.25-1.92
				c-1.32-1.73-2.69-3.42-4.21-5.34c-0.43,0.5-0.19,0.03-0.03,0.06c9.94,1.53,18.83-2.68,27.81-5.63c3.97-1.3,7.82-4.41,10.56-7.67
				c4.05-4.82,9.12-5.87,14.65-5.93c22.62-0.23,45.24-0.3,67.85-0.4c9.29-0.04,18.58-0.01,27.96,0.5
				c-6.16,3.75-12.05,6.55-19.98,6.22c-13.19-0.55-26.46,1.03-39.7,1.58c-4.27,0.18-8.56-0.1-12.85-0.1c-1.79,0-3.67-0.15-5.36,0.33
				c-10.2,2.89-20.36,5.94-30.53,8.94c-2.39,0.7-4.79,1.35-7.16,2.13c-8.45,2.78-9.03,3.69-8.21,12.33
				C304.16,51.26,303.87,51.74,303.47,53.09z"/>
			<path onClick={() => props.onFill(695)} fill={props.fillColors[695]}  d="M279.74,44.39c-5.66,2.61-11.36,1.83-17.14-0.83c-5.45-2.51-10.96-4.92-16.56-7.08
				c-1.77-0.69-3.94-0.72-5.88-0.54c-11.37,1.05-22.71,2.8-34.11,3.24c-12.03,0.46-24.1-0.28-36.15-0.49
				c-0.04-0.54-0.08-1.08-0.11-1.62c3.82-0.66,7.61-1.7,11.45-1.89c6.14-0.29,12.31-0.18,18.45,0.07c8.06,0.33,15.54-2.04,23.3-3.58
				c12.01-2.38,24.71-2.25,37.05-1.68c12.42,0.58,24.83,3.46,36.83-1.86c3.25-1.44,6.8-3.31,8.91-6c3.62-4.62,7.7-4.04,13.41-3.05
				c-4.87,4.76-7.7,10.38-14.41,11.54c-2.11,0.36-4.37,0.71-6.2,1.71c-4.82,2.64-9.63,4.47-15.19,2.64
				c-0.74-0.24-1.78-0.25-2.46,0.09c-2.41,1.2-4.71,2.59-7.06,3.91C275.64,40.58,277.39,42.21,279.74,44.39z"/>
			<path onClick={() => props.onFill(696)} fill={props.fillColors[696]}  d="M753.58,84.34c3.26-2.4,6.23-5.43,9.84-7.08c6.47-2.96,13.29-5.16,20.02-7.51
				c1.33-0.46,2.99-0.45,4.41-0.16c10.24,2.08,20.39,4.76,30.7,6.32c10.82,1.64,21.79,2.37,32.71,3.26
				c8.74,0.71,12.16,1.84,16.58,6.71c-17.26,0-33.98,0.04-50.69-0.08c-1.33-0.01-2.97-0.81-3.92-1.78
				c-6.31-6.42-14.57-9.13-22.89-9.91c-8.57-0.81-16.48,3.2-23.45,8.24C761.37,86.35,761.51,86.54,753.58,84.34z"/>
			<path onClick={() => props.onFill(697)} fill={props.fillColors[697]}  d="M504.93,22.2c3.35-1,6.7-2.84,10.05-2.85c21.92-0.06,43.84,0.37,65.77,0.46
				c11.13,0.04,22.26-0.32,33.39-0.44c6.47-0.07,12.95-0.01,19.93,0.82c-2.77,1.51-5.42,4.01-8.33,4.36
				c-10.91,1.35-21.59,4.63-33,1.6c-7.7-2.05-16.07-1.53-24.12-2.29c-2.61-0.25-5.16-1.15-7.77-1.35c-2.56-0.19-5.31-0.35-7.71,0.35
				c-4.76,1.4-9.18,1.86-14.06,0.06c-8.46-3.12-17.08-0.93-25.63,0.21c-2.7,0.36-5.44,0.46-8.16,0.67
				C505.17,23.28,505.05,22.74,504.93,22.2z"/>
			<path onClick={() => props.onFill(698)} fill={props.fillColors[698]}  d="M242.77,86.47c9.88-7.84,20.98-9.72,32.55-8.14c10.98,1.49,21.8,4.09,32.69,6.26
				c1.1,0.22,2.12,0.85,4.58,1.88C288.5,86.47,265.88,86.47,242.77,86.47z"/>
			<path onClick={() => props.onFill(699)} fill={props.fillColors[699]}  d="M810.8,86.05c-15.24,0-29.2,0-43.05,0C776,75.2,799.24,74.48,810.8,86.05z"/>
			<path d="M55.18,919.69c13.69,3.09,27.47,5.84,41.03,9.42c7.34,1.94,8.34,7.87,2.77,13.2c-4.69,4.48-10.11,6.9-16.75,4.45
				c-12.58-4.65-25.24-9.13-37.71-14.06c-2.26-0.89-3.81-3.6-5.69-5.48c2.09-1.66,3.94-3.9,6.31-4.82c3.02-1.17,6.45-1.26,9.7-1.82
				C54.96,920.28,55.07,919.98,55.18,919.69z M41.06,927.75c2.29,1.24,3.77,2.26,5.39,2.87c11.97,4.47,24,8.8,35.96,13.29
				c5.09,1.91,9.58,0.79,13.42-2.61c1.59-1.41,3.37-3.91,3.15-5.67c-0.21-1.62-2.79-3.77-4.67-4.2c-11.62-2.65-23.28-5.24-35.04-7.1
				C53.52,923.42,47.34,923.09,41.06,927.75z"/>
			<path d="M263.06,464.12c-1.7-1.93-3.44-3.68-4.89-5.63c-0.6-0.81-0.6-2.06-0.87-3.11c1.19-0.31,2.45-1.02,3.56-0.84
				c1.59,0.27,3.1,1.14,4.58,1.86c5.96,2.93,11.91,5.9,17.86,8.86c4.09,2.03,6.91,4.35,4.53,9.77c-0.61,1.38,1.05,4.62,2.59,5.88
				c1.95,1.6,4.81,2.11,7.32,2.98c5.04,1.75,8.65,4.92,10.06,10.2c1.94,7.3,9.15,7.57,14.24,10.48c3.53,2.02,7.97,2.39,11.7,4.14
				c6.87,3.21,13.59,6.77,20.21,10.48c1.26,0.71,1.72,2.83,2.54,4.3c-1.82,0.18-3.98,1.1-5.41,0.44
				c-19.86-9.16-40.72-16.23-59.21-28.32c-4.68-3.06-10.13-4.95-15.29-7.23c-14.4-6.35-28.87-12.57-43.22-19.02
				c-1.52-0.68-2.5-2.59-3.73-3.93c1.96-0.2,4.11-1.02,5.84-0.49C248.64,468.96,251.44,468.98,263.06,464.12z M245.72,471.16
				c19.26,9.07,40.8,19.21,61.79,29.09c-0.29-0.59-1.24-1.85-1.57-3.25c-1.6-6.88-6.42-10.12-12.76-11.8
				c-1.12-0.3-2.21-0.71-3.28-1.16c-4.97-2.11-6.12-4.99-5.05-10.34c0.32-1.6-0.54-4.49-1.75-5.15c-6.46-3.54-13.2-6.57-19.85-9.78
				c-0.16,0.16-0.31,0.33-0.47,0.49c1.72,2.02,3.44,4.04,6.27,7.35C259.62,468.45,251.38,470.05,245.72,471.16z M323.92,508.23
				c-0.12,0.44-0.23,0.89-0.35,1.33c7.61,2.95,15.23,5.89,22.84,8.84c-1.26-1.62-2.68-3.09-4.41-3.76
				C336.03,512.35,329.95,510.34,323.92,508.23z"/>
			<path onClick={() => props.onFill(700)} fill={props.fillColors[700]}  d="M124.58,132.17c9.39,3.22,18.09,6.2,26.8,9.19c-0.02,0.32-0.03,0.65-0.05,0.97
				c-14.73,0.34-29.51,0.04-44.17,1.2c-14.69,1.16-29.27,3.77-44.06,5.22c7.87-1.82,15.76-3.57,23.61-5.47
				c4.51-1.09,9-2.32,13.44-3.69c6.61-2.04,11.88-5.77,15.06-12.19c2.15-4.35,5.86-6.73,10.66-7.27c8.91-1,17.85-1.75,26.73-2.97
				c4.06-0.56,8.08-1.76,11.95-3.14c1.23-0.44,2.76-2.49,2.62-3.61c-0.14-1.12-2.09-2.66-3.43-2.88c-6.54-1.1-13.15-1.8-19.73-2.71
				c-1.87-0.26-3.7-0.76-5.48-2.2c1.38-0.13,2.76-0.38,4.13-0.38c51.07-0.03,102.13-0.02,153.2-0.1c2.96,0,4.57,0.72,6.13,3.59
				c2.86,5.26,8.39,5.28,13.64,5.18c3.31-0.06,6.7-0.67,9.91-0.16c3.84,0.61,7,1.98,6.16,7.49c-0.94,6.15,7.82,11.23,14.27,8.17
				c4.28-2.03,8.35-4.94,11.78-8.22c6.05-5.78,12.22-8.04,20.27-4.21c5.5,2.62,11.2,1.08,15.26-2.95c5.37-5.34,11.82-6.12,18.02-4.35
				c19.63,5.6,39.83,4.89,59.82,6.55c2.74,0.23,5.45,0.71,8.18,1.52c-7.8,2.16-16.33,0.26-24.98,4.76c2.47,1.52,3.81,2.79,5.37,3.22
				c9.58,2.63,19.22,5.09,28.84,7.56c0.79,0.2,1.81,0.41,2.46,0.08c6.3-3.15,12.25-0.24,18.31,0.92c3.32,0.64,6.37,0.73,7.99,5.1
				c1.51,4.06,10.55,3.55,12.79-0.48c1.37-2.46,2.67-3.95,5.72-3.6c3.09,0.35,3.15,2.55,4.05,4.76c0.68,1.67,2.75,3.29,4.54,3.87
				c2.46,0.8,5.29,0.98,7.89,0.74c6.24-0.59,11.29,1.42,15.9,5.58c2.08,1.88,4.96,2.86,8.41,4.77c-1.89,0.39-2.99,0.74-4.11,0.84
				c-11.12,1.03-22.03,0.74-33.15-1.54c-7.96-1.63-16.49-0.46-24.77-0.49c-10.43-0.04-20.86,0.09-31.28-0.06
				c-15.62-0.22-31.23-0.72-46.85-0.92c-12.45-0.16-24.9-0.03-37.35-0.03c0-0.42,0-0.83,0-1.25c10.13-1.9,20.2-4.31,30.42-5.53
				c6.84-0.82,13.88,0.26,20.82,0.05c2.57-0.08,5.09-1.4,7.64-2.16c-2.18-1.42-4.18-3.44-6.57-4.18
				c-14.58-4.47-29.55-4.37-44.47-2.51c-13.83,1.73-27.52,4.87-41.38,6.07c-10.02,0.87-20.27,0.08-30.33-0.86
				c-14.32-1.34-27.42,1.3-39.34,9.46c-1.37,0.93-2.86,1.69-4.31,2.49c-0.42,0.23-0.92,0.34-2.15-0.14c1.35-1.59,2.89-3.07,4.01-4.81
				c1.08-1.68,2.53-3.73,2.33-5.45c-0.14-1.22-2.71-2.22-4.27-3.19c-0.94-0.59-2.51-0.68-2.97-1.47c-3.28-5.58-8.82-6.93-14.45-7.6
				c-12.53-1.49-25.16-2.18-37.65-3.94c-18.44-2.61-36.79,0.09-55.18,0.13c-1.13,0-2.26,0.99-3.4,1.51c0.76,1.16,1.25,2.85,2.33,3.4
				c4.87,2.49,9.92,4.64,14.88,6.97c1.73,0.81,3.38,1.8,5.07,2.71c-0.2,0.54-0.39,1.08-0.59,1.62c-7.22-2.33-14.62-4.22-21.63-7.08
				c-16.3-6.64-32.95-6.99-49.93-3.88c-0.48,0.09-0.96,0.29-1.4,0.52C126.13,130.96,125.76,131.3,124.58,132.17z"/>
			<path onClick={() => props.onFill(701)} fill={props.fillColors[701]}  d="M63.78,152.46c21.55-3.48,42.98-7.7,65.07-6.82c8.23,0.33,16.54-0.82,24.79-1.52
				c1.58-0.13,3.07-1.3,4.6-1.99c0-0.46,0-0.93,0-1.39c-6.98-2.45-13.97-4.89-20.95-7.34c0.11-0.58,0.22-1.15,0.34-1.73
				c6.48,0,13.15-0.96,19.41,0.23c9.38,1.78,18.47,5.09,27.7,7.71c4.47,1.27,8.91,2.68,13.46,3.53c1.85,0.35,3.95-0.61,5.94-0.97
				c-1.16-1.83-1.91-4.32-3.56-5.36c-3.48-2.19-7.44-3.59-11.18-5.36c-1.58-0.75-3.1-1.6-4.56-2.36c20.54-4.7,41-2.3,61.44-0.42
				c9.91,0.91,19.87,1.73,29.64,3.5c3.67,0.67,6.85,3.97,10.3,6c2.21,1.3,4.49,2.46,6.74,3.68c-2.09,3.07-4.28,6.02-6.13,9.19
				c-0.54,0.93-0.54,3.22-0.01,3.52c1.09,0.61,2.83,0.71,4.05,0.28c2.01-0.7,3.98-1.77,5.7-3.05c12.71-9.5,27.33-11.34,42.28-9.25
				c16.63,2.33,32.73,0.1,48.97-2.72c19.36-3.37,38.81-5.75,58.41-1.59c1.24,0.26,2.44,0.73,3.9,1.18c-0.54,0.4-0.78,0.73-0.99,0.71
				c-14.26-1.45-28.13,0.52-41.87,4.34c-3.6,1-7.62,0.44-11.38,1c-1.38,0.21-2.59,1.59-3.88,2.43c1.32,1.05,2.53,2.81,3.96,3.01
				c5.26,0.72,10.58,1.18,15.88,1.28c19.95,0.36,39.91,0.46,59.86,0.82c5.14,0.09,10.26,1.14,15.39,1.13
				c9.92-0.01,19.83-0.65,29.75-0.65c8.28,0,16.82-0.62,24.79,1.12c10.65,2.32,21.12,1.43,31.7,1.33c1.99-0.02,3.98,0.3,5.97,0.46
				c0.01,0.55,0.03,1.1,0.04,1.66c-6.94,0.89-13.86,1.94-20.83,2.64c-7.73,0.77-15.46,2.14-23.16-0.87
				c-2.13-0.83-5.14-0.64-7.33,0.19c-10.48,3.93-21.24,1.83-31.44,0.31c-14.92-2.21-29.8-3.07-44.65-1.88
				c-15.63,1.25-31.1,2.36-46.68-0.34c-2.66-0.46-6.04,0.28-8.39,1.66c-4.43,2.59-8.19,2.93-12.34,0.18
				c-1.88-1.24-4.08-2.52-6.23-2.72c-14.22-1.3-28.46-3.18-42.7-3.16c-11.71,0.02-23.49,1.94-35.1,3.87
				c-11.66,1.94-22.78-0.08-34.03-2.45c-7.04-1.48-13.9-1.41-21.1,0.63c-4.24,1.21-9.47-0.3-14.09-1.32
				c-21.88-4.85-43.72-5.48-65.76-0.92c-3.94,0.82-8.42-0.79-12.62-1.49c-2.27-0.38-4.46-1.7-6.7-1.72
				c-12.3-0.1-24.62-0.3-36.89,0.34c-5.34,0.28-10.53,3.26-15.88,3.6c-9.82,0.61-19.7,0.18-29.56,0.18
				C63.84,154,63.81,153.23,63.78,152.46z"/>
			<path onClick={() => props.onFill(702)} fill={props.fillColors[702]}  d="M554.45,173.25c-0.01,9.06-4.25,15.61-12.3,19.39c-8.1,3.8-15.96,2.72-22.72-3.16
				c-6.36-5.54-8.76-12.63-6.96-21.07c0.7-3.25,2.08-4.36,5.53-3.78c2.47,0.42,5.13-0.37,7.71-0.56c4.14-0.3,8.27-0.75,12.41-0.76
				c2.44,0,4.87,1.02,7.31,1.06C553.51,164.5,554.4,165.28,554.45,173.25z"/>
			<path d="M686.13,64.1c-2.79,0.41-5.59,1.19-8.37,1.16c-13.41-0.15-26.82-0.43-40.22-0.86c-5.3-0.17-10.6-0.68-15.86-1.39
				c-1.37-0.18-2.56-1.66-3.83-2.54c1.04-0.9,1.95-2.29,3.15-2.62c8.47-2.34,16.93-4.77,25.52-6.56c8.85-1.84,16.99,2.06,25.16,4.54
				c5.06,1.54,9.77,4.25,14.64,6.43C686.26,62.88,686.19,63.49,686.13,64.1z M677.64,61.58c-10.84-8.59-40.11-9.57-53.05,0
				C642.56,61.58,660.53,61.58,677.64,61.58z"/>
			<path d="M282.12,1236.63c4.26-0.21,8.51-0.54,12.77-0.61c5.6-0.09,11.21-0.18,16.8,0.08c11.72,0.54,23.44,1.24,35.14,2.08
				c1.2,0.09,2.3,1.54,3.45,2.36c-0.79,0.89-1.45,2.36-2.4,2.59c-10.09,2.45-20.26,6.6-30.35,6.46c-9.68-0.13-19.32-4.67-28.91-7.51
				c-2.36-0.7-4.45-2.31-6.67-3.5C282.01,1237.93,282.06,1237.28,282.12,1236.63z M290.92,1239.9c15.9,8.67,33.48,9.29,51.43,1.49
				c0.01-0.5,0.01-0.99,0.02-1.49C324.79,1239.9,307.2,1239.9,290.92,1239.9z"/>
			<path d="M374.44,39.67c4.48,0,9-0.31,13.44,0.13c2.23,0.22,5.73,1.41,6.13,2.85c0.55,2-0.64,5.26-2.21,6.94
				c-7.19,7.7-16.59,11.28-26.84,11.04c-7.68-0.18-15.45-1.79-22.92-3.74c-2.6-0.68-6.12-4.25-6.15-6.53
				c-0.02-2.19,3.63-5.83,6.21-6.39c10.6-2.33,21.41-3.74,32.14-5.49C374.3,38.88,374.37,39.28,374.44,39.67z M351.46,57.24
				c0.15-0.27,0.3-0.54,0.45-0.81c-0.75-0.34-1.65-0.51-2.22-1.04c-1.36-1.29-3.6-2.78-3.55-4.13c0.05-1.44,2.12-3.09,3.66-4.11
				c1.31-0.86,3.12-1.22,4.74-1.33c6.45-0.45,12.91-0.89,19.36-0.92c1.65-0.01,4.38,1.23,4.69,2.4c0.37,1.39-1.02,3.63-2.25,4.96
				c-1.3,1.4-3.17,2.64-5,3.11c-4.46,1.13-9.05,1.77-12.63,2.43c1.97,0,4.96,0.12,7.94-0.02c8.41-0.39,15.91-3.19,22.05-9.05
				c1.22-1.16,1.81-2.97,2.69-4.48c-1.72-0.66-3.46-1.92-5.17-1.89c-10.12,0.15-20.27,0.21-30.33,1.12
				c-5.16,0.47-10.33,2.24-15.2,4.18c-3.42,1.36-3.28,4.15,0.12,5.73C344.23,54.95,347.9,55.97,351.46,57.24z M376.33,47
				c-9.76,1.88-18.76-0.67-27.65,3.92C357.1,57.12,370.06,55.63,376.33,47z"/>
			<path d="M729.24,1256.93c-3.51-0.82-7.21-1.17-10.48-2.57c-4-1.7-3.98-5.35-0.39-7.6c5.46-3.42,13.57-2.59,17.89,2.22
				c1.27,1.41,1.52,3.74,2.24,5.64c-1.89,0.7-3.73,1.58-5.68,2.04c-1.07,0.25-2.3-0.19-3.45-0.32
				C729.33,1256.54,729.29,1256.74,729.24,1256.93z M718.94,1250.42c-0.04,0.62-0.07,1.25-0.11,1.87c5.42,0.63,10.84,1.26,16.99,1.97
				C732.1,1246.81,724.76,1245.61,718.94,1250.42z"/>
			<path d="M238.44,44.1c3.67,0.83,7.56,1.17,10.96,2.63c4.23,1.81,4.08,5.54,0.1,7.83c-5.21,3-13.18,2.33-17.16-1.91
				c-1.42-1.52-1.83-3.99-2.71-6.03c1.89-0.67,3.75-1.44,5.68-1.97c0.93-0.25,1.98-0.04,2.98-0.04
				C238.35,44.45,238.4,44.27,238.44,44.1z M232.2,47.31c4.7,7.3,12.05,8.31,17.48,2.79C244.34,46.68,238.67,47.4,232.2,47.31z"/>
			<path d="M871.4,1270.45c-2.42-0.53-4.94-0.8-7.24-1.65c-3.62-1.35-6.2-4.33-5.17-8.05c0.78-2.79,3.2-5.68,5.69-7.29
				c3.05-1.97,6.58-0.82,8.81,2.22c1.54,2.11,2.89,4.49,3.72,6.96C879.13,1268.36,877.41,1270.46,871.4,1270.45z M876.06,1267.52
				c-1.86-4.13-2.72-7.37-4.63-9.76c-1.07-1.33-4.35-2.24-5.81-1.56c-1.85,0.86-4.02,3.62-3.89,5.4c0.13,1.79,2.64,4.05,4.62,4.89
				C868.63,1267.47,871.47,1267.12,876.06,1267.52z"/>
			<path d="M109.51,39.78c0.13,4.57-3.67,8.38-8.57,8.59c-5.02,0.22-10.23-5.91-10.61-12.52c-0.19-3.33,1.47-4.86,4.74-5.01
				C103.51,30.47,109.34,34.04,109.51,39.78z M93.78,34.34c-0.24,5.98,3.09,11.34,6.84,11.37c3.11,0.02,6.36-3.15,6.16-6.02
				C106.5,35.51,102.39,33.77,93.78,34.34z"/>
			<path d="M880.05,44.16c3.47-0.61,5.52-1.41,7.5-1.26c20.08,1.55,39.28-2.01,57.88-9.51c1.59-0.64,3.72-0.8,5.32-0.29
				c1.61,0.51,2.87,2.14,4.28,3.27c-1.31,0.96-2.62,1.93-3.93,2.88c-1.55,1.13-3.19,2.14-4.63,3.39c-2.11,1.82-3.76,4.48-6.15,5.6
				c-6.13,2.87-12.41,6.4-18.93,7.17c-5.7,0.67-11.79-2.1-17.71-3.28c-3.51-0.7-7.03-1.44-10.59-1.84
				C888.64,49.8,884.38,49.12,880.05,44.16z M889.18,45.42c-0.06,0.42-0.12,0.83-0.19,1.25c0.75,0.26,1.53,0.83,2.23,0.75
				c8.79-1.08,16.29,3.48,24.39,5.39c2.36,0.56,5.21-0.03,7.62-0.78c4.25-1.31,8.5-2.84,12.46-4.86c2.99-1.53,5.58-3.88,8.25-6
				c2.13-1.69,4.11-3.56,6.16-5.35C930.38,41.54,910.63,48.71,889.18,45.42z"/>
			<path d="M69.35,1258.16c-15.77-1.6-30.03,4.36-44.6,8.86c-2.72,0.84-5.82,0.82-8.7,0.63c-1-0.07-2.55-1.8-2.63-2.87
				c-0.07-0.88,1.56-2.03,2.62-2.81c2.02-1.48,4.24-2.69,6.23-4.2c2.22-1.68,4.02-4.12,6.46-5.25c5.69-2.64,11.56-5.05,17.56-6.84
				c2.63-0.78,5.91-0.31,8.66,0.44c7.13,1.94,13.82,5.4,21.6,4.89c2.46-0.16,5.14,1.2,7.52,2.3c1.1,0.5,1.72,2.05,2.55,3.12
				c-1.11,0.58-2.2,1.62-3.33,1.67C78.65,1258.27,74,1258.16,69.35,1258.16z M80.41,1256.04c0-0.48,0-0.97,0-1.45
				c-2.18-0.34-4.35-0.7-6.54-1.02c-2.88-0.42-5.9-0.38-8.61-1.3c-8.99-3.06-17.85-6.9-26.88-0.19c-0.51,0.38-1.24,0.46-1.87,0.67
				c-10.14,3.32-10.33,3.46-17.27,13.5C39.33,1258.36,59.13,1252.84,80.41,1256.04z"/>
			<path onClick={() => props.onFill(703)} fill={props.fillColors[703]}  d="M94.43,595.77c-1.45-2.71-2.68-4.99-4.44-8.29c5.25,2.34,9.64,4.3,14.03,6.26
				c-0.07,0.26-0.14,0.53-0.21,0.79c-1.59,0.12-3.19,0.18-4.78,0.38C97.57,595.1,96.13,595.45,94.43,595.77z"/>
			<path d="M206.33,769.58c-4.19-1.43-7.92-2.6-11.56-4.02c-0.73-0.29-1.7-1.69-1.52-2.23c0.31-0.9,1.43-2.11,2.24-2.13
				c4-0.11,8.08-0.26,11.98,0.44c1.46,0.26,2.51,2.79,3.75,4.29C209.62,767.14,208.01,768.33,206.33,769.58z M206.95,765.76
				c0.06-0.57,0.12-1.14,0.18-1.71c-2.67-0.24-5.34-0.49-8.02-0.73c-0.04,0.44-0.07,0.88-0.11,1.32
				C201.65,765.02,204.3,765.39,206.95,765.76z"/>
			<path d="M346.76,445.4c-3.85-1.9-7.93-3.5-11.49-5.89c-1.28-0.86-1.31-3.6-1.91-5.48c1.86,0.08,4.15-0.51,5.5,0.36
				c3.42,2.23,5.66,1.8,9.13-0.54c4.28-2.89,9.6-4.37,14.62-5.99c1.29-0.41,3.15,0.96,4.75,1.52c-0.87,1.3-1.48,2.93-2.65,3.82
				c-1.97,1.49-4.37,2.38-6.44,3.75c-2.93,1.93-3.05,5.64-0.36,7.37c5.56,3.58,5.94,6.97,1.14,11.68c-1.16,1.14-2.8,1.76-4.08,2.8
				c-2.53,2.05-5.34,3.9-7.35,6.4c-4.71,5.85-6.57,6.4-12.95,2.89c-1.87-1.03-3.94-1.73-5.97-2.44c-1.93-0.68-3.92-1.18-5.62-1.67
				c2.1-3.46,3.42-6.9,5.83-9.19c1.83-1.74,4.99-2.4,7.68-2.85C341.34,451.15,344.5,449.3,346.76,445.4z M329.43,462.5
				c2.51,1.23,5.29,2.11,7.48,3.77c2.88,2.19,5.23,1.43,6.79-0.98c3.1-4.78,7.32-7.95,12.28-10.47c1.08-0.55,1.81-1.89,2.54-2.98
				c1.47-2.18,1.37-3.55-1.48-4.91c-5.32-2.54-5.38-9.15-0.4-12.3c1.93-1.22,3.94-2.31,5.91-3.46c-5.65,1.71-10.63,4.6-15.82,7.02
				c-1.65,0.77-3.92,0.48-5.86,0.27c-1.2-0.13-2.32-1.03-3.48-1.58c-0.16,0.62-0.33,1.24-0.49,1.85c3.19,0.74,6.4,1.39,9.55,2.28
				c1.51,0.42,3.86,1.04,4.11,2c0.35,1.41-0.42,3.58-1.46,4.79c-3.56,4.16-8.08,6.64-13.67,7.39c-1.52,0.2-2.95,2.13-4.11,3.52
				C330.42,459.76,330.03,461.22,329.43,462.5z"/>
			<path d="M354.74,410.44c-0.04-7.09,9.48-14.95,14.61-13.68c4.24,1.04,6.01,4.21,3.71,7.76c-2.23,3.44-1.62,8.84-6.93,10.21
				c-2.56,0.66-5.93,2.97-7.39,2.06C356.49,415.37,355.54,411.84,354.74,410.44z M357.86,410.11c0.41,0.86,0.97,2.83,2.21,4.16
				c0.36,0.38,3.15-0.69,4-1.76c2.47-3.09,4.66-6.45,6.57-9.92c0.2-0.36-2.1-2.96-3.41-3.12
				C364.03,399.09,357.92,405.72,357.86,410.11z"/>
			<path d="M345.83,416.33c-6.68-0.14-8.64-3.47-8.08-9.13c0.41-4.12,0.59-7.89,4.22-10.43c1.11-0.77,2.59-1.01,3.9-1.5
				c0.42,1.17,1.19,2.34,1.2,3.52c0.01,4.15-0.44,8.02,4.61,10.29c2.48,1.12,1.81,4.2-0.74,5.58
				C349.08,415.66,346.88,416.01,345.83,416.33z M343.48,400.42c-0.51-0.1-1.02-0.19-1.52-0.29c-0.29,2.24-0.49,4.49-0.9,6.71
				c-0.4,2.19-2.1,4.9,1.24,5.84c2.32,0.65,4.97,0.13,7.48,0.13C345.56,408.79,345.56,408.79,343.48,400.42z"/>
			<path d="M254.24,865.35c7.33-10.99,16.8-8.17,21.06-1.87c2.3,3.4-1.34,10.37-5.47,10.68c-4.92,0.38-9.56,0.51-11.72-5.42
				C257.63,867.42,255.74,866.61,254.24,865.35z M263.91,869.19c0.18,0.48,0.36,0.95,0.54,1.43c1.95,0,4.8,0.79,5.64-0.18
				c1.4-1.62,1.52-4.35,2.18-6.61c-0.46-0.21-0.93-0.41-1.39-0.62C268.55,865.2,266.23,867.19,263.91,869.19z M259.64,862.9
				c0.28,0.8,0.55,1.61,0.83,2.41c2.3-0.81,4.61-1.63,6.91-2.44c-0.28-0.8-0.56-1.6-0.84-2.39
				C264.24,861.29,261.94,862.09,259.64,862.9z"/>
			<path d="M161.33,869.6c5.68-7.31,9.4-9.03,15.94-5.98c2.6,1.21,5.96,4.59,5.92,6.93c-0.09,5.75-2.93,7.42-8.07,7.8
				c-4.39,0.32-7.7-0.38-9.62-4.92C164.88,871.96,162.94,871.03,161.33,869.6z M178.68,866.57c-3.24,3.19-5.52,5.44-8.46,8.33
				C178.55,876.31,178.72,876.14,178.68,866.57z M174.62,867.49c-0.25-0.85-0.49-1.71-0.74-2.56c-2.46,0.8-4.93,1.61-7.39,2.41
				c0.21,0.72,0.42,1.44,0.63,2.16C169.62,868.83,172.12,868.16,174.62,867.49z"/>
			<path d="M404.77,272.99c1.53,5.91-1.09,10.99-3.07,16.25c-0.47,1.24,0.01,2.84,0.06,4.27c0.06,1.68,0.78,3.85,0,4.88
				c-0.73,0.96-3.23,1.29-4.65,0.88c-5.84-1.72-11.47-4.18-17.36-5.64c-3.22-0.8-6.79-0.14-10.71-0.14
				c-1.09-4.66,0.29-8.77,3.9-11.77c1.45-1.21,4.05-1.04,6.98-1.69c1.23-1.55,2.94-5.94,5.35-6.36c6.37-1.12,13.03-0.52,19.58-0.61
				C404.83,273.07,404.77,272.99,404.77,272.99z M371.98,288.75c9.48,2.76,18.37,5.34,27.26,7.92c0.12-0.25,0.24-0.5,0.37-0.75
				c-1.35-1.26-2.71-2.52-3.7-3.45c1.9-3.5,3.77-6.53,5.25-9.74c2.8-6.06,1.69-7.7-4.8-7.55c-0.99,0.02-1.99,0-2.98,0.06
				c-4.83,0.31-9.75,0.29-10.7,6.95c-0.14,0.99-3.08,2.67-3.93,2.28C372.99,281.84,372.84,286.07,371.98,288.75z"/>
			<path d="M611.41,953.11c-8.08,0.12-19.81-8.13-21.9-14.89c-0.44-1.41-0.4-3.74,0.46-4.58c1.05-1.03,3.77-1.82,4.78-1.17
				c6.59,4.27,13,8.82,19.2,13.63c0.95,0.74,1.03,3.55,0.48,5C613.98,952.25,611.95,952.78,611.41,953.11z M592.17,935.85
				c2.54,8.92,12.99,15.81,19.7,12.98C609.03,943.08,596.77,935.01,592.17,935.85z"/>
			<path d="M588.77,938.66c0.14,3.91-5.5,9.46-9.28,9.47c-3.59,0.01-5.43-2.34-5.8-5.57c-0.46-4.06,4.02-8.44,8.58-8.56
				C585.77,933.9,588.31,935.33,588.77,938.66z M581.99,935.45c-2.15,2.67-3.89,4.46-5.04,6.58c-0.24,0.45,1.7,2.08,2.63,3.17
				c2.13-1.89,4.25-3.77,6.38-5.66C584.99,938.54,584.02,937.55,581.99,935.45z"/>
			<path d="M563.73,935.73c-1.64,1.45-2.87,3.2-3.59,3.01c-1.2-0.32-2.52-1.73-2.91-2.96c-0.18-0.58,1.61-2.44,2.63-2.55
				C560.84,933.11,561.98,934.52,563.73,935.73z"/>
			<path d="M806.02,901.2c2.06,2.08,3.55,3.59,5.04,5.1c-1.16,0.92-2.71,2.84-3.42,2.57c-1.93-0.74-3.51-2.4-5.23-3.7
				C803.4,904.08,804.38,903.01,806.02,901.2z"/>
			<path d="M791.36,905.03c1.12-0.77,1.91-1.72,2.52-1.62c0.67,0.11,1.63,1.19,1.63,1.85c0,0.58-1.14,1.59-1.8,1.62
				C793.07,906.89,792.4,905.89,791.36,905.03z"/>
			<path d="M598.25,1250.22c-1.98,0.15-4.01,0.63-5.93,0.35c-1.99-0.3-4.96-0.87-5.51-2.18c-0.73-1.72-0.12-4.62,0.93-6.41
				c4.43-7.55,13.91-10.46,23.66-7.68c0.64,0.18,1.28,0.35,1.89,0.61c3.08,1.32,6.4,2.6,6.4,6.7c-0.01,4.3-3.33,6.08-6.73,6.9
				c-4.78,1.15-9.73,1.58-14.61,2.31C598.31,1250.62,598.28,1250.42,598.25,1250.22z M588.68,1248.02
				c6.87-0.42,13.15-0.32,19.23-1.37c3.18-0.55,6.02-3.14,9.01-4.8c-2.88-1.81-5.55-4.5-8.69-5.26
				C599.17,1234.41,591.07,1238.99,588.68,1248.02z"/>
			<path onClick={() => props.onFill(704)} fill={props.fillColors[704]}  d="M41.06,927.75c6.28-4.66,12.46-4.33,18.21-3.42c11.76,1.87,23.42,4.45,35.04,7.1
				c1.88,0.43,4.46,2.58,4.67,4.2c0.23,1.76-1.56,4.26-3.15,5.67c-3.83,3.4-8.33,4.52-13.42,2.61c-11.96-4.49-23.99-8.82-35.96-13.29
				C44.82,930.01,43.35,928.99,41.06,927.75z"/>
			<path onClick={() => props.onFill(705)} fill={props.fillColors[705]}  d="M245.72,471.16c5.66-1.1,13.9-2.71,23.34-4.55c-2.82-3.31-4.55-5.33-6.27-7.35
				c0.16-0.16,0.31-0.33,0.47-0.49c6.64,3.21,13.39,6.23,19.85,9.78c1.21,0.66,2.06,3.56,1.75,5.15c-1.07,5.35,0.08,8.22,5.05,10.34
				c1.07,0.45,2.16,0.87,3.28,1.16c6.34,1.68,11.17,4.92,12.76,11.8c0.33,1.41,1.28,2.67,1.57,3.25
				C286.52,490.37,264.97,480.22,245.72,471.16z"/>
			<path onClick={() => props.onFill(706)} fill={props.fillColors[706]}  d="M323.92,508.23c6.03,2.11,12.11,4.12,18.08,6.41c1.73,0.66,3.15,2.14,4.41,3.76
				c-7.61-2.95-15.23-5.89-22.84-8.84C323.69,509.11,323.8,508.67,323.92,508.23z"/>
			<path onClick={() => props.onFill(707)} fill={props.fillColors[707]}  d="M677.64,61.58c-17.12,0-35.08,0-53.05,0C637.53,52.01,666.8,52.99,677.64,61.58z M662.66,60.13
				c-7.92-5.5-18.21-5.64-23.86,0C647.05,60.13,654.5,60.13,662.66,60.13z"/>
			<path onClick={() => props.onFill(708)} fill={props.fillColors[708]}  d="M290.92,1239.9c16.29,0,33.87,0,51.45,0c-0.01,0.5-0.01,0.99-0.02,1.49
				C324.4,1249.2,306.82,1248.57,290.92,1239.9z M328.85,1242.84c0.01-0.55,0.01-1.11,0.02-1.66c-7.5,0-15,0-23.49,0
				C313.63,1246.31,321.16,1246.22,328.85,1242.84z"/>
			<path onClick={() => props.onFill(709)} fill={props.fillColors[709]}  d="M351.46,57.24c-3.56-1.27-7.23-2.29-10.65-3.88c-3.4-1.58-3.54-4.37-0.12-5.73
				c4.87-1.93,10.04-3.71,15.2-4.18c10.06-0.91,20.21-0.96,30.33-1.12c1.71-0.03,3.45,1.23,5.17,1.89c-0.88,1.51-1.47,3.32-2.69,4.48
				c-6.15,5.86-13.64,8.66-22.05,9.05c-2.98,0.14-5.97,0.02-7.94,0.02c3.58-0.66,8.17-1.3,12.63-2.43c1.83-0.46,3.71-1.7,5-3.11
				c1.22-1.32,2.61-3.56,2.25-4.96c-0.31-1.17-3.04-2.41-4.69-2.4c-6.46,0.03-12.92,0.47-19.36,0.92c-1.62,0.11-3.42,0.47-4.74,1.33
				c-1.55,1.01-3.61,2.67-3.66,4.11c-0.05,1.34,2.19,2.84,3.55,4.13c0.56,0.53,1.47,0.71,2.22,1.04
				C351.76,56.7,351.61,56.97,351.46,57.24z"/>
			<path onClick={() => props.onFill(710)} fill={props.fillColors[710]}  d="M376.33,47c-6.27,8.63-19.23,10.12-27.65,3.92C357.57,46.33,366.56,48.88,376.33,47z"/>
			<path onClick={() => props.onFill(711)} fill={props.fillColors[711]}  d="M718.94,1250.42c5.82-4.81,13.16-3.61,16.87,3.85c-6.14-0.71-11.56-1.34-16.99-1.97
				C718.87,1251.67,718.9,1251.04,718.94,1250.42z"/>
			<path onClick={() => props.onFill(712)} fill={props.fillColors[712]}  d="M232.2,47.31c6.47,0.09,12.15-0.63,17.48,2.79C244.25,55.62,236.9,54.61,232.2,47.31z"/>
			<path onClick={() => props.onFill(713)} fill={props.fillColors[713]}  d="M876.06,1267.52c-4.58-0.4-7.42-0.05-9.7-1.02c-1.98-0.85-4.49-3.1-4.62-4.89
				c-0.13-1.78,2.04-4.54,3.89-5.4c1.46-0.68,4.74,0.23,5.81,1.56C873.34,1260.15,874.2,1263.39,876.06,1267.52z"/>
			<path onClick={() => props.onFill(714)} fill={props.fillColors[714]}  d="M93.78,34.34c8.61-0.56,12.72,1.18,13.01,5.35c0.19,2.87-3.05,6.04-6.16,6.02
				C96.87,45.68,93.54,40.31,93.78,34.34z"/>
			<path onClick={() => props.onFill(715)} fill={props.fillColors[715]}  d="M889.18,45.42c21.45,3.3,41.2-3.87,60.92-9.6c-2.05,1.79-4.03,3.67-6.16,5.35c-2.67,2.11-5.26,4.47-8.25,6
				c-3.96,2.02-8.2,3.55-12.46,4.86c-2.42,0.74-5.26,1.34-7.62,0.78c-8.1-1.91-15.6-6.46-24.39-5.39c-0.7,0.09-1.48-0.48-2.23-0.75
				C889.06,46.25,889.12,45.83,889.18,45.42z"/>
			<path onClick={() => props.onFill(716)} fill={props.fillColors[716]}  d="M80.41,1256.04c-21.28-3.21-41.08,2.32-61.16,10.2c6.94-10.04,7.13-10.18,17.27-13.5
				c0.63-0.21,1.36-0.29,1.87-0.67c9.03-6.71,17.89-2.87,26.88,0.19c2.7,0.92,5.73,0.88,8.61,1.3c2.18,0.32,4.36,0.68,6.54,1.02
				C80.41,1255.07,80.41,1255.56,80.41,1256.04z"/>
			<path onClick={() => props.onFill(717)} fill={props.fillColors[717]}  d="M206.95,765.76c-2.65-0.37-5.3-0.74-7.94-1.12c0.04-0.44,0.07-0.88,0.11-1.32
				c2.67,0.24,5.34,0.49,8.02,0.73C207.07,764.62,207.01,765.19,206.95,765.76z"/>
			<path onClick={() => props.onFill(718)} fill={props.fillColors[718]}  d="M329.43,462.5c0.61-1.28,1-2.74,1.87-3.79c1.17-1.39,2.6-3.32,4.11-3.52c5.59-0.75,10.11-3.23,13.67-7.39
				c1.04-1.21,1.81-3.38,1.46-4.79c-0.24-0.96-2.6-1.58-4.11-2c-3.15-0.88-6.36-1.53-9.55-2.28c0.16-0.62,0.33-1.24,0.49-1.85
				c1.16,0.55,2.28,1.45,3.48,1.58c1.94,0.21,4.21,0.5,5.86-0.27c5.2-2.42,10.17-5.32,15.82-7.02c-1.97,1.15-3.98,2.24-5.91,3.46
				c-4.99,3.15-4.92,9.76,0.4,12.3c2.85,1.36,2.95,2.73,1.48,4.91c-0.73,1.09-1.46,2.44-2.54,2.98c-4.96,2.51-9.18,5.68-12.28,10.47
				c-1.56,2.4-3.91,3.17-6.79,0.98C334.72,464.61,331.94,463.73,329.43,462.5z"/>
			<path onClick={() => props.onFill(719)} fill={props.fillColors[719]}  d="M357.86,410.11c0.06-4.39,6.17-11.02,9.38-10.64c1.31,0.15,3.61,2.76,3.41,3.12
				c-1.91,3.47-4.09,6.83-6.57,9.92c-0.85,1.07-3.65,2.14-4,1.76C358.83,412.94,358.27,410.98,357.86,410.11z"/>
			<path onClick={() => props.onFill(720)} fill={props.fillColors[720]}  d="M343.48,400.42c2.09,8.37,2.09,8.37,6.3,12.38c-2.5,0-5.16,0.52-7.48-0.13c-3.34-0.93-1.65-3.65-1.24-5.84
				c0.41-2.22,0.61-4.47,0.9-6.71C342.46,400.23,342.97,400.33,343.48,400.42z"/>
			<path onClick={() => props.onFill(721)} fill={props.fillColors[721]}  d="M263.91,869.19c2.32-2,4.64-3.99,6.96-5.99c0.46,0.21,0.93,0.41,1.39,0.62c-0.66,2.26-0.77,5-2.18,6.61
				c-0.84,0.97-3.69,0.18-5.64,0.18C264.27,870.14,264.09,869.66,263.91,869.19z"/>
			<path onClick={() => props.onFill(722)} fill={props.fillColors[722]}  d="M259.64,862.9c2.3-0.81,4.6-1.61,6.9-2.42c0.28,0.8,0.56,1.6,0.84,2.39c-2.3,0.81-4.61,1.63-6.91,2.44
				C260.19,864.51,259.91,863.7,259.64,862.9z"/>
			<path onClick={() => props.onFill(723)} fill={props.fillColors[723]}  d="M178.68,866.57c0.04,9.57-0.13,9.74-8.46,8.33C173.16,872.01,175.44,869.76,178.68,866.57z"/>
			<path onClick={() => props.onFill(724)} fill={props.fillColors[724]}  d="M174.62,867.49c-2.5,0.67-5,1.34-7.5,2.01c-0.21-0.72-0.42-1.44-0.63-2.16c2.46-0.8,4.93-1.61,7.39-2.41
				C174.13,865.78,174.38,866.63,174.62,867.49z"/>
			<path onClick={() => props.onFill(725)} fill={props.fillColors[725]}  d="M371.98,288.75c0.86-2.68,1.01-6.91,6.77-4.27c0.85,0.39,3.79-1.3,3.93-2.28
				c0.94-6.66,5.86-6.64,10.7-6.95c0.99-0.06,1.99-0.04,2.98-0.06c6.49-0.14,7.6,1.49,4.8,7.55c-1.48,3.21-3.35,6.24-5.25,9.74
				c0.99,0.92,2.34,2.19,3.7,3.45c-0.12,0.25-0.24,0.5-0.37,0.75C390.35,294.09,381.46,291.51,371.98,288.75z"/>
			<path onClick={() => props.onFill(726)} fill={props.fillColors[726]}  d="M592.17,935.85c4.6-0.84,16.85,7.24,19.7,12.98C605.16,951.66,594.71,944.77,592.17,935.85z"/>
			<path onClick={() => props.onFill(727)} fill={props.fillColors[727]}  d="M581.99,935.45c2.03,2.09,3,3.09,3.97,4.09c-2.13,1.89-4.25,3.77-6.38,5.66
				c-0.93-1.09-2.87-2.72-2.63-3.17C578.1,939.91,579.84,938.12,581.99,935.45z"/>
			<path onClick={() => props.onFill(728)} fill={props.fillColors[728]}  d="M588.68,1248.02c2.39-9.03,10.48-13.61,19.55-11.43c3.13,0.75,5.8,3.45,8.69,5.26
				c-2.99,1.67-5.82,4.25-9.01,4.8C601.84,1247.7,595.56,1247.6,588.68,1248.02z"/>
			<path d="M662.66,60.13c-8.17,0-15.61,0-23.86,0C644.45,54.49,654.74,54.64,662.66,60.13z"/>
			<path d="M328.85,1242.84c-7.69,3.38-15.22,3.47-23.47-1.66c8.49,0,15.99,0,23.49,0C328.87,1241.73,328.86,1242.29,328.85,1242.84z
				"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M775.15,1215c-2.99,1.33-5.92,2.84-9,3.97c-17.75,6.51-36,10.83-54.9,11.95c-6.12,0.36-12.33,0.01-18.46-0.47
				c-12.33-0.98-24.65-2.09-36.93-3.55c-4.24-0.51-8.62-1.63-12.44-3.5c-15.2-7.45-30.48-7.5-45.96-1.38
				c-17.5,6.92-35.04,13.75-52.38,21.06c-11.66,4.91-22.89,10.82-34.5,15.85c-10.25,4.44-21.11,4.56-31.9,2.51
				c-13.44-2.55-26.79-3.53-40.46-1.57c-13.99,2.01-28,0.3-41.91-1.87c-20.33-3.17-40.37-0.33-60.4,2.99
				c-19.62,3.25-38.82,10.14-58.88,9.25c-16.69-0.74-33.36-3.29-49.91-5.85c-22.79-3.53-45.26-3.07-67.95,1.36
				c-11.54,2.25-23.59,1.74-35.38,2.85c-3.89,0.37-7.72,1.72-11.48,2.94c-8.36,2.71-16.66,5.6-24.96,8.5
				c-1.1,0.38-2.01,1.29-3.01,1.96"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M971.31,100c-322.94,0-645.87,0-968.81,0"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M971.31,17.5c-25.79-0.17-51.58-0.31-77.37-0.52c-7.99-0.06-15.97-0.33-23.96-0.48c-3.87-0.07-7.07,0.68-10.12,3.87
				c-5.52,5.76-12.95,8.35-20.82,7.5c-13.63-1.46-26.77,1.25-39.93,3.7c-15.87,2.96-31.63,2.36-47.43-0.08
				c-19.3-2.98-38.52-6.78-57.92-8.76c-17.38-1.77-34.18,3.75-50.85,7.93c-16.15,4.05-32.13,8.99-48.95,8.17
				c-8.36-0.41-16.85-1.77-24.92-4c-14-3.87-27.8-4.2-41.99-1.44c-11.44,2.23-23.12,1.67-34.39-1.03
				c-15.78-3.78-30.22-1.11-44.5,6.03c-13.91,6.96-28.13,13.33-42.4,19.54c-17.81,7.75-36.41,13.06-55.88,13.31
				c-9.46,0.12-18.94-2.67-28.45-3.69c-13.28-1.41-26.62-2.34-39.92-3.58c-10.97-1.03-21.61,0.47-32.5,2.14
				c-12.73,1.95-26.01,0.13-38.84,1.65c-11.77,1.4-24.43,0.23-34.58,9.11c-4.85,4.24-10.81,6.07-17.89,5.39
				c-6.37-0.61-12.96,0.98-19.43,1.83c-0.93,0.12-1.68,1.57-2.51,2.4"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M883.96,19c-3.49,1.5-6.93,3.15-10.5,4.46c-4.94,1.81-10.15,2.97-14.92,5.13c-6.43,2.91-13.02,4.03-20,4.52
				c-13.01,0.92-26.09,1.82-38.91,4.05c-20.77,3.62-41.31,2.06-61.92-0.66c-15.3-2.02-30.58-4.31-45.94-5.84
				c-5.39-0.54-11.02,0.5-16.45,1.39c-21.32,3.52-42.59,7.36-63.9,10.91c-15.31,2.55-30.67,2.11-45.9-0.54
				c-13.5-2.34-26.91-2.5-40.45-0.49c-13.1,1.94-26.07-0.33-38.91-2.55c-11.45-1.98-22.05-0.12-32.48,4.58
				c-28.78,12.97-57.04,27.13-87.3,36.65c-13.81,4.34-26.8,3.24-40.02-1.99c-6.04-2.39-12.51-4.07-18.94-4.98
				c-10.58-1.51-21.3-1.97-31.92-3.18c-17.69-2.02-34.88,0.98-51.89,5.14c-9.29,2.27-18.34,5.52-27.45,8.47
				c-1,0.32-1.69,1.6-2.52,2.43"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M925.89,86.5c-4.04-4.81-9.34-6.7-15.47-7.08c-2.2-0.14-5.29-0.56-6.33-2.03c-5.39-7.61-12.3-5.02-18.57-2.73
				c-8.42,3.06-9.3,2.01-16.27-3.87c-7.58-6.4-16.79-12-27.69-9.19c-17.27,4.45-34.1,2.79-50.97-1.98c-2.77-0.78-6.57-1.2-8.84,0.09
				c-7.58,4.29-16.14,3.57-24.12,5.61c-7.23,1.85-13.74,3.98-20.17,8.32c-5.99,4.05-14.27,4.7-21.69,2.77
				c-15.93-4.16-31.99-6.58-48.43-4.81c-5.88,0.63-11.61,2.81-17.49,3.27c-7.44,0.59-14.98-0.18-22.45,0.22
				c-8.84,0.48-17.64,1.56-26.46,2.42c-15.08,1.47-29.56-0.11-42.96-7.95c-2.62-1.53-5.72-2.2-8.44-3.59
				c-10.71-5.48-20.48-2.1-30.54,2.37c-5.42,2.41-11.51,4.09-17.41,4.49c-18.51,1.27-37.07,1.28-55.44,4.94
				c-7.81,1.56-14.78,4.17-21.42,8.23"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M554.03,163.5c4.07,13.67,1.33,22.41-8.98,29.01c-7.75,4.96-15.82,4.99-23.4,0.38c-8.55-5.21-12.58-13.08-10.56-23.39
				c0.39-1.99,0.73-3.99,1.19-6.55c-1.48-0.16-2.83-0.37-4.18-0.45c-17.47-1.01-34.93-2.38-52.41-2.85
				c-8.79-0.24-17.63,1.68-26.45,1.7c-8.15,0.02-16.35-0.89-24.44-2c-3.28-0.45-5.74-0.2-8.01,2.14c-3.35,3.43-7.96,3.03-10.79,0.78
				c-4.31-3.43-8.74-3.52-13.65-3.87c-9.34-0.66-18.65-2.78-27.96-2.69c-12.49,0.13-25.02,1.31-37.42,2.91
				c-13.16,1.69-26,2.18-38.99-1.47c-7.48-2.11-15.12-2.04-23.03,0.48c-4.34,1.38-9.85-1.08-14.86-1.61
				c-13.3-1.4-26.6-3.44-39.93-3.76c-8.95-0.21-17.94,2.31-26.96,3.11c-3.26,0.29-6.64-0.92-9.97-1.36
				c-5.32-0.71-10.63-1.68-15.97-1.93c-7.14-0.33-14.31-0.31-21.45,0.01c-4.01,0.18-8.17,0.71-11.95,2.01
				c-14,4.8-27.95,3.11-41.95,0.38c-0.54-0.11-1-0.64-1.5-0.97"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M573.5,1244c-5.11,0.11-9.96,0.65-15.05,2.27c-17.91,5.67-33.87,15.71-51.37,22.14c-9.2,3.38-18.98,2.91-28.39,0.98
				c-14.69-3.01-29.21-3.33-43.96-0.47c-12.69,2.46-25.04,0.27-37.46-3.25c-19.43-5.5-38.9-3.74-58.39,1.2
				c-17.13,4.34-34.5,7.86-51.9,11.02c-13.53,2.45-27.02-0.59-40.39-2.48c-14.54-2.05-28.91-5.26-43.45-7.29
				c-13.92-1.95-27.64,0.55-41.45,2.68c-11.99,1.85-24.25,1.94-36.41,2.69c-7.88,0.48-14.34,3.46-18.97,10.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M575,1214.5c-2.83,1.5-5.59,3.15-8.5,4.47c-13.28,6.06-26.6,12.04-39.93,18.01c-2.11,0.95-4.49,1.4-6.44,2.58
				c-3.79,2.29-8.49,4.17-10.79,7.59c-5.79,8.6-15.5,11.32-26.19,7.9c-10.08-3.22-20.14-6.82-30.49-8.76
				c-6.37-1.2-13.28,0.11-19.91,0.78c-12.78,1.28-25.42,0.17-37.93-2.13c-17.46-3.2-34.28-0.61-50.92,4.57
				c-14.88,4.63-29.96,6.99-45.41,3.46c-9.2-2.1-18.21-5.2-27.5-6.76c-6.35-1.06-12.93-0.15-18.45,4.27
				c-12.85,10.29-27.14,10.96-42.43,6.61c-11.69-3.32-23.76-4.02-35.91-3.64c-9.31,0.28-18.64,0.32-27.94-0.08
				c-3.36-0.14-6.61-2.36-10-2.73c-3.76-0.41-7.78-0.38-11.44,0.47c-12.9,3.01-23.78-0.44-33.5-9.06c-4.35-3.86-9.77-4.63-15.44-4.05
				c-8.45,0.86-15.98-0.16-22.16-7.32c-2.63-3.04-7.37-4.38-11.34-6.02c-0.9-0.37-3.77,1.2-3.75,1.84
				c0.16,8.18-5.86,11.09-11.69,13.94c-5.69,2.78-11.53,5.29-17.48,7.46c-2.6,0.95-5.62,0.77-8.45,1.1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M960.83,19c-7.65,3.83-15.03,8.43-23.02,11.35c-19.43,7.09-39.56,10.04-60.35,8.81c-6.43-0.38-13.01,0.92-19.45,1.89
				c-10.68,1.62-21.29,3.7-31.96,5.42c-14.67,2.37-49.72,1.64-61.41-0.89c-8.22-1.78-16.58-3.21-24.95-3.94
				c-6.59-0.58-13.32,1.13-18.35,5.47c-9.82,8.48-20.69,9.44-32.54,6.38c-7.66-1.98-15.25-4.24-22.97-5.94
				c-14.34-3.16-28.41-0.32-41.85,4.14c-18.82,6.26-37.71,7.23-56.95,3.73c-11.66-2.12-23.37-2.91-34.92-0.78
				c-14.79,2.74-28.09-1.44-41.47-6.6c-9.27-3.57-20-7.53-27.09,1.72c-8.2,10.69-20.13,12.99-30.83,18.15
				c-12.47,6.02-25.25,11.42-37.89,17.08"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M102.32,1059c10.32,4.83,21.22,8.74,30.75,14.81c6.92,4.4,14.26,6.89,21.66,9.68c7.64,2.88,15.33,5.62,22.94,8.55
				c2.3,0.89,6.29,0.29,5.18,5.01c-0.04,0.18,2.46,1.07,3.82,1.44c14.3,3.86,28.58,7.83,42.93,11.49
				c25.26,6.44,50.57,12.68,75.86,19.03c36.44,9.15,72.87,18.33,109.31,27.5c37.77,9.5,75.53,19.01,113.3,28.49
				c14.13,3.55,28.3,6.95,42.4,10.59c1.65,0.42,3.01,1.92,4.51,2.92"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M490.4,1198.5c-4.41-2-8.67-4.46-13.26-5.91c-11.39-3.61-22.84-7.13-34.43-10.01c-18.72-4.65-37.62-8.58-56.36-13.16
				c-9.25-2.26-18.23-5.65-27.49-7.82c-9.87-2.31-20-3.5-29.91-5.67c-13.2-2.9-26.31-6.25-39.45-9.44
				c-12.98-3.15-26.02-6.1-38.91-9.57c-9.75-2.62-19.25-6.19-28.98-8.87c-18.25-5.03-36.68-9.39-54.86-14.66
				c-14.95-4.33-29.63-9.61-44.47-14.33c-1.51-0.48-3.31-0.06-4.97-0.06"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1.5,344c8.32,5.83,16.74,11.53,24.93,17.53c10.41,7.63,22.67,11.07,34.52,15.33c5.44,1.96,10.29,4.5,13.31,9.21
				c4.07,6.36,9.96,10.05,16.62,12.32c13.86,4.72,25.25,12.6,35.02,23.5c6.35,7.08,13.72,13.26,20.92,19.52
				c2.35,2.05,5.51,3.25,8.42,4.56c22.77,10.21,45.48,20.59,68.42,30.41c9.36,4.01,19.25,6.77,28.9,10.11
				c5.74,1.99,10.3,5.44,12.96,11.01c3.1,6.5,8.91,10.09,15.06,12.32c12.32,4.49,21.87,12.47,31.55,20.98
				c12.92,11.37,28,19.15,44.66,24.5c12.01,3.86,23,10.83,34.57,16.13c7.53,3.45,15.33,6.29,22.91,9.62
				c1.97,0.87,5.28,1.19,4.02,4.93"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M207.14,66.5c-2.99-0.67-5.96-1.69-8.99-1.94c-10.3-0.84-20.62-1.62-30.94-1.97c-5.47-0.18-10.98,0.54-16.47,0.95
				c-0.69,0.05-1.84,0.54-1.92,0.98c-1.31,7.3-7.54,4.04-11.56,5.49c-3.66,1.32-7.32,2.67-10.98,4c-4.16,1.51-8.23,3.39-12.5,4.41
				c-6.91,1.66-13.91,3.46-20.95,3.93c-11.54,0.76-22.95-0.71-33.97-4.74c-2.75-1-5.98-0.61-8.94-1.13
				c-6.01-1.06-12.05-3.52-17.96-3.2c-10.66,0.58-21.03,3.37-29.42,10.78c-0.51,0.45-0.77,1.88-0.44,2.38
				c0.41,0.62,1.56,1.02,2.39,1.02c12.98,0.06,25.95-0.02,38.93,0.06c20.46,0.12,40.93,0.18,61.39,0.53
				c15.14,0.26,30.24-0.42,45.46,1.05c13.29,1.28,26.91-1.13,40.39-1.51c6.31-0.18,12.64,0.88,18.96,0.84
				c9.65-0.06,19.3-0.93,28.95-0.84c10.32,0.1,20.63,1.14,30.95,1.35c12.14,0.25,24.29,0.25,36.43,0.01
				c15.81-0.31,31.61-1.22,47.42-1.33c7.15-0.05,14.3,1.66,21.46,1.73c5.48,0.06,10.96-1.51,16.47-1.77
				c6.97-0.33,13.97-0.14,20.96-0.06c8.49,0.1,16.97,0.29,25.46,0.5c3.99,0.1,7.99,0.48,11.98,0.46c9.65-0.07,19.3-0.51,28.95-0.42
				c14.14,0.14,28.29,0.97,42.43,0.86c18.8-0.15,37.61-1.41,56.4-1.29c23.96,0.15,47.91,1.54,71.88,1.78
				c24.79,0.25,49.58-0.22,74.37-0.42c15.97-0.13,31.94-0.32,47.92-0.49c15.47-0.16,30.95-0.28,46.42-0.51
				c17.8-0.27,35.6-0.85,53.41-0.9c7.98-0.02,15.98,1.41,23.96,1.29c10.66-0.17,21.29-1.61,31.95-1.75
				c8.64-0.12,17.3,0.94,25.95,1.35c5.98,0.28,11.98,0.36,17.97,0.52"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M395.31,847.5c12.49,7.8,26.56,4.43,39.94,5.3c8.86,0.58,17.35,2.61,25.99,3.82c11.94,1.67,24.24,0.95,36.38,0.8
				c9.32-0.11,18.63-1.11,27.95-1.38c16.47-0.48,32.94-0.82,49.41-0.96c4.2-0.04,8.41,0.91,12.62,1.4c0,0.75,0.01,1.49,0.01,2.24
				c-3.88,0.43-7.75,1.04-11.63,1.24c-11.14,0.6-22.29,1.04-33.44,1.53c-2.13,0.09-4.01-0.29-4.8,2.93
				c-0.31,1.29-3.97,2.24-6.19,2.49c-13.46,1.5-26.95,2.65-40.42,4.13c-6.41,0.7-12.77,1.87-19.16,2.83
				c-0.06,0.74-0.11,1.49-0.17,2.23c2.78,0.8,5.53,2.13,8.35,2.31c13.13,0.87,26.28,1.37,39.42,2.11c6.33,0.36,12.65,0.96,18.97,1.49
				c0.84,0.07,1.84,0.07,2.47,0.52c10.91,7.88,24.19,7.39,36.45,10.5c4.7,1.19,9.29,2.78,13.93,4.18c-0.08,0.78-0.16,1.55-0.24,2.33
				c-4.73,0.82-9.43,2.04-14.19,2.38c-18.62,1.34-37.27,2.26-55.89,3.63c-9.07,0.67-18.1,2-28.37,3.17
				c5.28,6.51,11.64,5.81,16.89,7.74c1.92,0.71,4.64,0.38,5.87,1.64c7.51,7.67,17.44,5.96,26.56,7.43c1.7,0.27,3.33,0.97,5,1.47"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M971.31,1214c-2.83-0.17-5.66-0.47-8.49-0.48c-42.26-0.2-84.52-0.39-126.78-0.49c-8.48-0.02-16.97,0.34-25.46,0.44
				c-1.99,0.02-3.99-0.43-5.99-0.45c-20.3-0.2-40.6-0.33-60.89-0.53c-12.98-0.13-25.95-0.46-38.93-0.46
				c-14.81,0.01-29.69-0.55-44.4,0.69c-15.68,1.33-31.3,0.35-46.94,0.66c-7.81,0.15-15.63-1.16-23.46-1.3
				c-5.98-0.11-11.97,0.76-17.97,0.88c-13.81,0.28-27.62,0.44-41.43,0.48c-3.16,0.01-6.32-0.9-9.48-0.9
				c-9.98,0.02-19.97,0.44-29.95,0.41c-17.8-0.04-35.61-0.49-53.41-0.41c-17.14,0.08-34.28,0.96-51.41,0.86
				c-20.8-0.12-41.59-1.02-62.39-1.38c-18.13-0.32-36.27-0.62-54.4-0.46c-16.81,0.15-33.61,0.93-50.41,1.42
				c-1.99,0.06-3.99-0.02-5.99,0.01c-4.53,0.06-8.07,0.79-12.29,4.1c-7.81,6.11-17.02,11.06-28.17,7.5
				c-8.33-2.66-16.98-4.28-25.39-6.71c-1.89-0.55-3.36-2.55-5.02-3.88"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M636.89,18c-2.69,5.34-7.58,7.37-12.98,8.47c-3.29,0.67-6.64,1.4-9.98,1.45c-6.65,0.1-13.31-0.23-19.96-0.43
				c-3-0.09-6-0.21-8.98-0.51c-7.66-0.77-15.31-1.68-22.96-2.46c-2.82-0.29-5.84-1.06-8.44-0.37c-5.12,1.35-9.73,2.15-15.08,0.02
				c-8.11-3.22-16.56-0.51-24.9,0.21c-4.1,0.35-8.31-0.57-12.47-0.87c-1.16-0.08-2.36-0.19-3.49,0.01
				c-12.65,2.23-25.24,1.87-37.96,0.12c-7.89-1.09-14.68,3.16-21.48,6.77c-4.66,2.47-8.39,7.1-14.44,6.59
				c-2.99-0.25-6.07,0.04-8.97-0.58c-14.25-3.04-28.52-4.13-42.94-1.44c-1.16,0.22-2.39,0.67-3.48,0.46
				c-11.17-2.24-21,2.13-30.97,6.03c-4.61,1.8-9.42,3.11-13.93,5.11c-3.1,1.37-4.54,3.72-2.62,7.46c1.57,3.06,0.16,4.99-3.4,5.49
				c-10.8,1.49-21.14-0.88-31.45-3.55c-5.51-1.43-10.94-3.93-16.49-4.18c-4.26-0.2-8.75,2.07-12.93,3.77
				c-4.95,2.01-9.49,5.27-14.57,6.67c-3.12,0.86-7.61,0.3-10.28-1.45c-7.17-4.71-14.87-5.44-22.55-3.62
				c-9.61,2.28-18.28,0.99-27.02-3.13c-17.64-8.32-35.07-6.22-52.4,1.42c-15.71,6.93-31.99,11.87-49.39,10.95
				c-10.84-0.57-21.63-2.64-32.45-2.75c-15.85-0.15-31.43,2.19-45.9,9.36C1.55,73.27,1.33,74,1,74.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M432.25,18c-4.33-0.5-8.65-1.43-12.98-1.43c-27.45,0.03-54.9,0.33-82.36,0.4c-10.98,0.03-21.96-0.53-32.94-0.39
				c-7.99,0.1-15.98,1.38-23.96,1.32c-12.81-0.11-25.62-1.29-38.43-1.28c-12.98,0-25.95,1.23-38.93,1.28
				c-10.31,0.04-20.63-1.24-30.95-1.28c-8.48-0.03-16.97,1.23-25.46,1.3c-8.98,0.08-17.97-0.9-26.95-0.84
				c-19.13,0.12-38.27,0.92-57.4,0.84c-18.8-0.08-37.6-0.9-56.4-1.43C4.57,16.46,3.66,16.17,2.75,16"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M355.38,1133.5c5.27-9.22-3.4-9.86-8.38-12.59c2.58-5.91,7.98-5.48,12.61-6.47c0.13-0.54,0.26-1.08,0.39-1.63
				c-5.62-2.81-11.23-5.62-16.85-8.44c-0.04-0.48-0.08-0.96-0.12-1.43c4.02-1.3,8.05-2.59,12.91-4.16c-3.63-1.82-6.7-3.36-9.78-4.9
				c0-0.39-0.01-0.78-0.01-1.17c7.57-2.07,15.06-4.45,22.71-6.15c20.38-4.52,40.08-10.82,58.38-21.07
				c10.73-6.01,11.46-14.86,2.56-23.06c-12.38-11.41-24.35-23.26-36.49-34.94c-1.53-1.47-2.91-3.1-4.51-4.47
				c-4.11-3.5-4.15-5.14,0.55-7.46c3.92-1.93,6.74-3.83,6.28-9.09c-0.13-1.48,3.47-4.32,5.71-4.76c5.03-0.99,10.27-1.03,15.43-1.15
				c2.98-0.07,4.73-0.58,3.5-4.06c-1.36-3.85,2.19-4.02,4.01-4.36c6.26-1.18,12.62-2.52,18.94-2.49c16.31,0.08,32.61,0.8,48.91,1.39
				c5.34,0.2,10.06,1.65,13.66,6.33c1.19,1.55,4.44,1.81,6.82,2.05c3.02,0.31,6.15-0.09,7.57,3.83c-11.24,4.95-23.89-0.4-35.12,4.16
				c-0.06,0.65-0.13,1.3-0.19,1.95c3.42,0.55,6.82,1.44,10.26,1.58c9.31,0.36,18.63,0.58,27.95,0.49c4.66-0.05,6.39,3.71,8.81,6.24
				c1.31,1.37-0.15,3.28-2.82,3.4c-7.33,0.32-14.64,1.1-21.97,1.4c-8.48,0.35-16.97,0.28-25.45,0.6c-2.52,0.09-5.01,0.86-7.93,2.41
				c1.48,0.68,2.93,1.85,4.44,1.95c5.98,0.38,11.97,0.47,17.96,0.58c11.98,0.2,23.96,0.34,35.94,0.53c0.83,0.01,1.82,0.08,2.47,0.52
				c9.52,6.44,20.57,4.1,30.96,5.51c2.33,0.32,4.57,1.23,8.16,2.23c-6.38,4.69-12.24,3.5-17.65,4.18c-6.8,0.85-13.63,1.47-20.46,2
				c-2.83,0.22-4.23,0.77-3.17,4.1c0.24,0.77-1.6,2.91-2.85,3.34c-6.6,2.24-13.17,4.59-20.45,3.22c-2.7-0.51-5.69-0.07-8.46,0.44
				c-13,2.39-26,4.79-38.91,7.58c-2.69,0.58-5.02,2.87-7.52,4.37c2.33,1,4.58,2.57,7,2.9c12.45,1.71,24.96,3,37.41,4.66
				c3.31,0.44,6.51,1.66,9.76,2.51c0.03,0.67,0.06,1.35,0.09,2.02c-2.78,0.8-5.52,2.01-8.35,2.32c-8.13,0.89-16.32,1.19-24.43,2.19
				c-2.97,0.37-7.51-0.75-7.68,4.88c-0.02,0.71-2.42,1.68-3.83,1.94c-3.12,0.56-6.37,0.46-9.45,1.18c-1.8,0.42-3.35,1.89-5.02,2.89
				c1.33,2,2.21,5.01,4.07,5.83c10.63,4.75,21.8,6.87,33.36,4.12c14.69-3.49,29.46-4.56,44.43-2.44c1.68,0.24,3.4,0.45,4.98,1.03
				c4.98,1.82,5.88,3.96,4,8.96c-0.45,1.19-0.87,2.41-1.51,3.49c-2,3.4-0.89,6.03,2.54,6.91c6.06,1.54,12.25,3.05,18.45,3.46
				c8.31,0.55,16.06,2.38,24.06,4.76c8.64,2.56,18.19,2.06,27.34,2.89"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M971.31,1227c-5.82,2-11.59,4.19-17.48,5.96c-16,4.82-32.69,6.69-48.84,3.67c-23.91-4.47-45.89-0.05-67.43,9.41
				c-18.47,8.11-36.6,9.06-54.98-0.42c-6.43-3.31-13.56-3.89-20.92-2.22c-8.77,2-17.18,1.86-25.55-3.2
				c-8.53-5.15-16.45-0.04-23.23,4.95c-5.54,4.08-10.61,4.22-16.61,1.91c-5.2-2-10.68-3.23-15.92-5.14
				c-2.48-0.91-4.69-2.59-7.01-3.93"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M321.94,926c7.82,1.83,15.56,4.22,23.48,5.38c15.44,2.26,30.02,6.55,42.89,15.65c4.36,3.08,4.61,5.62,1.51,9.97
				c-0.27,0.38-0.66,0.67-0.99,1.01c-6.54,6.89-8.19,16.07-11.63,24.44c-1.25,3.04-4.05,5.51-6.39,8.01c-1.2,1.28-3.07,1.9-4.42,3.08
				c-4.25,3.71-4.09,6.49,1.02,8.89c5.87,2.75,9.78,7.35,14.08,11.95c7.3,7.82,15.46,14.84,23.33,22.12
				c3.73,3.44,7.78,6.53,11.42,10.06c3.96,3.84,3.26,7.56-1.45,10.47c-20.57,12.73-43.5,18.52-66.88,23.03
				c-1.57,0.3-3.04,1.22-4.5,1.96c-9.95,5.06-19.89,10.15-30.6,15.63c1.58,3.75,3.28,7.8,5.39,12.84c-1.58,3.14-0.27,8.49,2.13,11.12
				c8.06,8.83,19.47,8.35,29.59,11.3c10.68,3.12,21.58,5.5,32.42,8.09c15.62,3.73,31.28,7.34,46.91,11.02
				c24.79,5.83,49.62,11.49,74.36,17.56c16.38,4.02,32.58,8.79,48.95,12.87c10.73,2.68,21.64,4.63,32.42,7.1
				c8.02,1.83,16,3.89,23.94,6.07c1.03,0.28,1.69,1.9,2.52,2.9"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M322.94,613c-8.15-3.83-16.08-8.28-24.5-11.38c-22.99-8.49-45.24-18.57-67.41-28.97c-23.99-11.25-49.41-18.62-75.32-24.06
				c-12.71-2.67-25.54-5.04-38.44-6.37c-9.04-0.93-18.28,0.23-27.43,0.21c-2.33,0-4.66-0.93-6.99-1.43"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M971.31,1285c-27.78-0.5-55.57-1.05-83.36-1.47c-10.48-0.16-20.96,0.04-31.44-0.05c-9.32-0.07-18.64-0.54-27.95-0.4
				c-6.66,0.1-13.31,1.31-19.97,1.33c-9.82,0.02-19.63-0.89-29.45-0.84c-17.64,0.09-35.27,0.85-52.91,0.84
				c-11.31-0.01-22.63-1.33-33.94-1.3c-13.31,0.03-26.62,1.23-39.93,1.31c-20.63,0.13-41.26-0.35-61.89-0.39
				c-12.81-0.02-25.63,0.57-38.43,0.4c-17.81-0.24-35.61-1.39-53.41-1.33c-26.95,0.09-53.91,1.16-80.86,1.29
				c-14.97,0.07-29.95-1.28-44.92-1.3c-20.3-0.04-40.6,0.94-60.89,0.82c-33.44-0.19-66.88-1.28-100.33-1.29
				c-22.46,0-44.92,1.63-67.38,1.75c-20.3,0.1-40.59-0.92-60.89-1.32c-2.99-0.06-5.99,0.91-8.98,0.9
				c-20.8-0.08-41.59-0.29-62.39-0.45c-3.33-0.03-6.66,0-9.98,0"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M222.11,654c-21.96-5.67-43.96-11.2-65.88-17.03c-27.66-7.36-55.74-13.53-82.71-22.93c-21.11-7.35-42.71-12.33-64.06-18.5
				C6.73,594.75,3.83,594.5,1,594"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M862,17.5c-4.49-0.33-8.99-1.01-13.48-0.94c-24.13,0.38-48.25,1.06-72.38,1.41c-22.79,0.33-45.59,0.62-68.38,0.46
				c-21.63-0.16-43.26-1.22-64.89-1.34c-18.13-0.1-36.27,0.88-54.4,0.82c-15.47-0.05-30.94-1.16-46.42-1.32
				c-10.98-0.11-21.96,0.66-32.94,0.9c-18.8,0.4-37.6,0.75-56.4,0.98c-6.32,0.08-12.64-0.32-18.97-0.42
				c-0.58-0.01-1.16,0.62-1.75,0.95"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M960.33,255c-1.33,0.67-2.94,2.19-3.95,1.87c-9.61-3.1-19.53-5.67-27.47-12.39c-8.11-6.86-18.39-7.99-28.02-10.26
				c-11.43-2.69-21.67-7.3-31.46-13.61c-6.8-4.38-14.16-7.93-21.42-11.55c-4.4-2.19-8.96-2.65-13.52,0.33
				c-1.17,0.76-3.44,0.6-4.89,0.04c-16.52-6.38-32.84-13.28-49.49-19.3c-10.23-3.7-20.92-6.13-31.4-9.13"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M545.05,1213.5c-3.83,2.5-7.36,6.33-11.53,7.25c-12.32,2.7-24.85,4.72-37.39,6.12c-12.75,1.42-25.64,1.45-38.4,2.82
				c-4.45,0.48-8.86,2.78-12.96,4.88c-9.86,5.04-19.33,4.11-29.01-0.52c-10.17-4.87-20.13-10.61-31.92-10.53
				c-9.82,0.07-19.94-1.15-29.37,0.85c-11.45,2.44-22.74,0.54-34.01,1.68c-4.86,0.49-9.71,1.38-14.47,2.48
				c-17.13,3.96-33.87,1.08-50.39-3.18c-8.09-2.08-15.78-0.75-23.45,0.86c-2.95,0.62-5.1,4.76-8.08,5.59
				c-9.51,2.66-19.25,4.5-28.9,6.69c-1.85,0.42-3.95,0.57-5.44,1.57c-4.03,2.71-8.25,2.63-12.52,1.38
				c-17.2-5.02-34.4-6.16-51.97-1.64c-4.33,1.11-9.02,0.49-13.54-2.14c-5.66-3.29-11.7-5.35-16.14-10.96
				c-2.54-3.21-7.73-3.17-12.27-0.85c-2.98,1.52-6.55,2.02-9.93,2.56c-2.75,0.45-5.23,0.17-7.09-2.86c-2.14-3.48-5.73-3.62-9.84-3.95
				c-4.62-0.37-9.01-3.62-13.51-5.62"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M910.41,810.5c-7.82-0.67-15.63-1.57-23.46-1.95c-19.79-0.97-39.61-1.42-59.38-2.72c-6.19-0.41-12.24-2.88-18.3-4.4
				c1.73-7.64,1.31-8.83-4.68-10.36c-14.4-3.68-28.99-5.24-43.92-4.15c-13.11,0.96-26.28,1.07-39.42,1.62
				c-2.02,0.08-4.02,0.5-7.08,0.9c6.55,6.53,14.53,1.56,20.55,5.08"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M788.63,1176.5c-5.99-2.67-12.02-5.25-17.95-8.05c-1.81-0.85-3.35-2.28-5.01-3.45c1.83-1.17,3.57-3.13,5.51-3.36
				c8.28-0.99,16.63-1.38,24.93-2.2c2.32-0.23,4.58-1.19,6.86-1.82c-0.04-0.81-0.09-1.62-0.13-2.44c-3.91-0.56-7.8-1.42-11.72-1.63
				c-7.48-0.39-14.97-0.38-22.46-0.58c-1-0.03-2.51,0.05-2.92-0.54c-4.58-6.52-11.65-5.14-18.04-5.97c-2.43-0.32-4.83-0.93-7.24-1.41
				c-0.03-0.81-0.07-1.62-0.1-2.43c3.44-0.71,6.86-1.79,10.34-2.05c7.8-0.58,15.64-0.64,23.45-1.15c1.99-0.13,3.93-1.17,5.89-1.79
				c-0.1-0.86-0.21-1.73-0.31-2.59c-6.35-0.18-12.7-0.48-19.05-0.51c-4.99-0.02-9.98,0.43-14.97,0.37c-1.17-0.01-2.33-1.24-3.5-1.9
				c1-1.17,1.92-3.25,3.01-3.34c4.12-0.34,6.94-1.71,9.23-5.5c0.89-1.47,4.71-1.38,7.23-1.61c5.14-0.48,10.33-0.57,15.46-1.15
				c1.24-0.14,2.34-1.56,3.51-2.4c-1.16-1-2.19-2.45-3.53-2.87c-1.31-0.42-3.21,0.78-4.39,0.24c-1.88-0.85-3.56-2.37-5-3.91
				c-1.69-1.82-3.04-3.96-4.54-5.96"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M778.64,1029.5c6.39,5.42,14.3,5.32,21.96,5.98c12.64,1.08,25.29,2,37.93,3.05c1.35,0.11,2.82,0.36,3.97,1.01
				c11.62,6.64,24.79,7.64,37.45,10.5c6.09,1.38,11.45,3.8,13.1,10.93c0.49,2.12,2.38,3.92,4.23,6.8c-21.53,5.46-43.6,2.8-65.71,8.33
				c6.4,5.68,12.93,4.84,18.93,5.89c6.65,1.17,13.33,2.22,19.94,3.61c1.16,0.24,2.02,1.89,3.02,2.89c-1.33,0.5-2.65,1.38-4,1.43
				c-4.65,0.18-9.32-0.05-13.97,0.12c-2.06,0.08-4.11,0.76-5.99,1.14c0,0.97-0.06,1.25,0.01,1.29c7.19,4,13.26,10.39,22.45,10.07
				c5-0.18,10.18-0.7,14.5,2.92c0.47,0.4,1.31,0.36,1.97,0.53c-0.29,0.66-0.59,1.32-0.88,1.98c-5.71,0.45-11.43,0.9-18.04,1.43
				c4.08,5.58,9.42,4.78,13.93,4.97c15.96,0.67,31.94,0.69,47.91,1.18c7.33,0.22,14.66,0.75,21.95,1.56c2.57,0.29,5.01,1.82,7.5,2.78
				c-0.14,0.53-0.29,1.06-0.43,1.58c-3.04,0.35-6.08,0.71-9.21,1.07c1.37,8.72,12.02,3.03,14.8,11.37
				c-11.26,0.35-21.95,0.68-33.41,1.03c1.5,2,2.45,3.25,4.1,5.45c-8.57,0.63-16.46,1.22-24.35,1.8c4.13,4.85,9.48,5.07,14.57,5.22
				c14.8,0.42,29.61,0.43,44.42,0.59"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M458.2,140.5c-21.43-0.04-42.57,2.4-63.41,7.36c-0.07,0.45-0.14,0.89-0.21,1.34c3.07,0.44,6.13,1.2,9.21,1.25
				c22.96,0.43,45.92,0.59,68.88,1.13c6.67,0.16,13.31,1.77,19.97,1.79c8.65,0.03,17.31-1.35,25.95-1.24
				c9.66,0.11,19.29,1.38,28.95,1.82c8.97,0.41,18,0.89,26.94,0.32c3.96-0.25,6.18,0.65,8.44,4.6c-4,0.57-7.71,1.16-11.42,1.62
				c-6.98,0.87-13.96,2.02-20.97,2.38c-4.12,0.21-8.32-0.85-12.47-1.38c-2.17-0.28-4.7-1.55-6.42-0.83
				c-6.18,2.58-12.55,2.87-19.03,2.84"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M967.81,1215c-7.08,8.3-17.26,10.42-27.01,12.76c-4.49,1.08-9.66-0.05-14.4-0.85c-6.72-1.14-13.35-2.8-19.98-4.43
				c-13.34-3.28-26.7-5.68-40.44-2.49c-3.97,0.92-8.05,1.46-11.96,2.58c-3.58,1.03-7.37,2.05-10.42,4.06
				c-5.44,3.58-11.19,5.16-17.55,4.86c-2.83-0.14-4.84,0.29-6.19,3.43c-0.65,1.51-3.46,2.96-5.29,2.95
				c-13.81-0.08-27.64-0.2-41.42-0.96c-11.18-0.62-21.96-4.5-33.46-3.57c-9.73,0.79-19.32,1.36-29.01,3.28
				c-9.13,1.81-18.89,0.45-28.37,0.37c-7.32-0.06-14.72-1.03-21.93-0.2c-4.17,0.48-8.14,3.53-11.93,5.86
				c-0.91,0.56-1.33,3.02-0.98,4.33c1.21,4.49,0.16,7.05-4.57,8.62c-9.59,3.18-19,6.91-28.48,10.4c-0.49,0.18-0.99,0.46-1.5,0.48
				c-9.98,0.36-19.99,1.28-29.94,0.86c-10.84-0.46-21.64-2.14-32.45-3.36c-3.34-0.38-6.66-0.98-9.98-1.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M971.31,265.5c-16.7-2.2-32.29-6.98-47.13-15.64c-9.35-5.45-20.74-7.4-31.25-10.8c-1.01-0.32-2.31,0.27-3.47,0.43
				c-0.57,0.57-1.14,1.13-1.71,1.7c2.57,1.77,4.97,3.85,7.73,5.25c8.57,4.33,17.23,8.5,25.94,12.52c4.64,2.14,9.33,3.97,11.01,9.53
				c0.33,1.1,1.34,1.98,1.94,3.02c3.29,5.72,9.77,8.27,12.5,15.29c2.54,6.51,11.15,8.03,17.94,10.24c2.25,0.73,4.34,1.96,6.5,2.96"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M971.31,1268c-18.74,0.52-36.89-6.19-55.94-3.83c-8.46,1.05-16.48-3.3-21.9-10.68c-3.94-5.36-9.65-8-15.49-10.48
				c-6.64-2.83-12.55,0.27-18.43,2.59c-2.87,1.13-5.34,3.28-8.03,4.9c-6.47,3.88-12.65,7.54-20.97,7.32
				c-6.95-0.19-13.92,2.93-20.97,3.52c-6.92,0.58-13.97-0.06-20.95-0.36c-14.35-0.62-28.66-1.24-42.95,1.41
				c-6.36,1.18-12.95,1.15-19.45,1.58c-1.15,0.08-2.7,0.12-3.43-0.53c-4.45-4.01-10.63-3.7-15.52-6.46
				c-4.87-2.75-10.07-3.81-15.47-2.84c-1.98,0.35-3.68,2.2-5.51,3.37c1.66,0.83,3.36,1.62,4.98,2.53c0.59,0.33,1.01,0.97,1.74,1.7
				c-2.64,3.13-6.15,3.42-9.69,2.72c-4.34-0.86-8.26-0.38-12.01,2.03c-1.36,0.88-2.95,1.89-4.47,1.95
				c-10.44,0.43-15.17,8.85-21.46,15.08"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M274.02,639c3.58,1.83,7.43,0.64,10.93,0.98c8.5,0.83,17.4-4,25.5,1.53c0.19,0.13,0.42,0.5,0.49,0.47
				c5.09-2.56,10.33,0.26,15.5-0.36c1.78-0.21,3.73,0.9,6.37,1.61c0.72-0.58,2.12-1.71,3.53-2.83c0.11,0.49,0.22,0.99,0.33,1.48
				c3.41-0.46,7.04-0.34,10.19-1.5c6.37-2.36,11.76,0.81,17.53,2.07c1.59,0.35,3.32,0.08,4.98,0.05c3.86-0.07,7.52-1.09,11.56,0.84
				c6.18,2.95,13.02,3.06,19.9,2.33c1.89-0.2,4.08,1.33,5.95,2.38c4.92,2.74,8.48,2.48,10.06,0.27c6.09,0.07,12.14-1.52,16.15,0.67
				c3.86,2.1,7.11,2.79,11.15,3.52c2.24,0.4,3.32,1.53,6.24,0.43c2.38-0.9,6.16,1.91,9.33,3.08"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1,175c1.33-0.17,2.89,0.05,3.96-0.56c5.68-3.28,10.77-2.56,16.65,0.26c3.18,1.53,8.1,0.79,11.79-0.35
				c10.6-3.3,19.53,2.13,28.95,4.82c1.85,0.53,3.12,3.11,4.66,4.74c4.7-2.11,9.28-4.41,13.19-0.24c1.08,1.15,0.16,4.17,0.16,6.94
				c1.95,0.25,4.47,0.57,6.99,0.91c1,0.14,2.41-0.04,2.93,0.55c6.75,7.64,14.15,3.98,21.53,1.46c4.13-1.41,8.27-2.86,12.5-3.91
				c1.33-0.33,3,0.47,4.46,0.91c3.68,1.11,7.33,2.3,10.98,3.49c2.5,0.81,5.27,1.22,7.42,2.6c3.45,2.2,6.55,2.21,10.06,0.43
				c5.63-2.85,11.44-0.9,13.96,4.47"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M198.65,209c-4.82,0.17-9.65,0.46-14.47,0.46c-4.66,0-9.32-0.32-13.98-0.45c-1.73-0.05-3.49-0.41-3.71,2.47
				c-0.06,0.78-2.76,1.86-4.28,1.92c-3.82,0.14-7.66-0.09-11.47-0.43c-12.78-1.12-25.31,1.37-37.95,2.3
				c-15.24,1.12-30.6,0.61-45.9,0.71c-12.81,0.08-25.62,0.07-38.43-0.01c-7.49-0.05-14.97-0.29-22.46-0.5
				C4.32,215.44,2.66,215.17,1,215"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M453.71,140.5c-2.59-4.67-7.59-3.59-11.51-4.36c-17.22-3.35-34.31-0.9-51.39,1.8c-11.27,1.78-22.58,3.55-33.95,4.37
				c-6.92,0.5-14.01-0.47-20.94-1.39c-15.29-2.02-28.94,1.78-41.48,10.51c-1.93,1.34-4.4,1.91-6.62,2.84
				c-0.37-0.47-0.74-0.93-1.11-1.4c2.09-2.63,4.23-5.22,6.25-7.9c0.8-1.06,1.35-2.32,2.02-3.48c-1.16-0.67-2.4-1.24-3.48-2.02
				c-1.72-1.25-3.23-2.83-5.03-3.93c-2.73-1.67-5.5-3.97-8.48-4.38c-13.43-1.86-26.92-3.22-40.41-4.64
				c-7.64-0.8-15.32-2.02-22.95-1.87c-11.18,0.23-22.33,1.53-33.5,2.37c-0.16,0.48-0.32,0.96-0.48,1.44
				c7.54,3.78,15.09,7.56,22.63,11.34c-0.23,0.77-0.46,1.53-0.69,2.3c-1.98-0.2-4.05-0.09-5.91-0.67c-7.03-2.19-14-4.59-20.98-6.95
				c-12.33-4.16-24.97-5.76-37.93-4.42c-1.22,0.13-2.34,1.26-3.5,1.93"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M130.27,1006.5c-0.14-6.22,5.75-13.84,11.42-16.63c6.93-3.41,14.01-4.49,21.56-3.27c0.24-1.08,0.63-1.77,0.43-2.08
				c-5.27-7.95-9.17-17.29-18.98-20.88c-2.64-0.97-6.44-1.15-8.83,0.06c-5.32,2.67-10.36,0.3-15.01-0.54
				c-7.79-1.41-11.03,3.3-15.08,7.8c-0.85,0.95-2.22,1.75-3.46,1.98c-7.28,1.32-12.4,7.27-13.04,15.06
				c-0.13,1.54-0.85,3.49-1.98,4.44c-27.33,22.85-17.5,61.84,10.69,75.25c17.65,8.4,36.76,13.71,55.26,20.3
				c7.93,2.82,15.95,5.41,23.96,7.98c1.56,0.5,3.31,0.38,4.98,0.55"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M863.99,347.5c-2-3.67-4.21-7.23-5.94-11.02c-2.69-5.91-5.45-11.85-7.38-18.03c-1.15-3.67-2.77-6.57-6.18-7.86
				c-4.15-1.57-5.46-4.24-6.72-8.51c-2.81-9.51-6.15-19.02-13.18-26.61c-4.17-4.5-8.22-9.15-11.92-14.04
				c-1.57-2.07-2.3-4.87-3.04-7.44c-0.91-3.19-1.56-5.01-6.06-4.71c-12.43,0.83-24.95,0.42-37.42,0.81
				c-3.84,0.12-7.68,1.9-11.48,1.79c-9.66-0.28-19.3-1.24-28.95-1.85c-2.49-0.16-4.99,0.06-7.48-0.04
				c-7.16-0.29-14.31-0.85-21.46-0.9c-3.32-0.02-7.28-0.02-9.81,1.67c-2.56,1.71-4.19,1.39-6.69,0.86c-2.55-0.54-5.42-0.63-7.94-0.02
				c-6.23,1.52-12.5,3.16-18.42,5.58c-2.75,1.12-5.31,3.81-6.88,6.43c-1.36,2.27-2.87,3.45-5.14,4.45
				c-6.06,2.67-12.17,5.32-17.92,8.57c-2.89,1.63-5.05,4.56-7.5,5.47c-3.18-0.2-6.41-0.85-9.53-0.5c-8.29,0.93-16.16,3.11-23,8.37
				c-6.69,5.14-14.71,3.99-22.43,4.04c-1.83,0.01-3.86-0.52-5.46,0.09c-7.23,2.79-15.17,4.79-21.23,9.27
				c-4.76,3.51-9.12,4.07-14.24,4.14"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1.5,422.5c2.4,4.68,5.41,8.02,11.51,7.19c1.66-0.23,3.69,1.73,5.45,2.83c2.54,1.59,4.81,4.07,7.55,4.77
				c2.69,0.69,4.33,0.65,4.79,4.17c0.16,1.28,4.3,2.06,6.64,3.04c5.99,2.51,11.97,5.01,17.97,7.5c1.31,0.54,2.62,1.19,4,1.47
				c5.14,1.08,10.38,1.77,15.43,3.15c2.35,0.64,4.34,3.65,6.52,3.69c9.54,0.18,16.4,8.39,25.95,8.3c0.82-0.01,1.54,1.76,2.52,2.34
				c1.2,0.71,2.93,1.8,3.91,1.41c3.03-1.22,4.68-1.35,5.33,2.59c0.12,0.71,3.97,0.78,6.61,1.24c0.31,0.96,0.87,2.66,1.5,4.61
				c3.52-1.87,5.43-1.8,7.58,2.44c0.86,1.7,5.24,5.62,10.02,4.44c1.64-0.4,3.81,1.33,6.38,2.34c-4.51,3.7-8.87,3.22-12.35,1.35
				c-21.78-11.71-45.67-16.75-69.51-20.99c-13.55-2.41-25.87-7.78-38.88-11.34c-1.9-0.52-4.04-0.73-5.95-0.4
				c-0.97,0.17-2.51,2.05-2.33,2.81c0.27,1.17,1.67,2.68,2.83,2.92c2.88,0.6,6.71-1.65,7.96,3.13c1.16-0.33,2.33-0.67,3.49-1
				c-0.18,0.37-0.36,0.74-0.54,1.11c5.01,2.3,9.92,4.81,15.04,6.83c5.98,2.36,12.48,2.96,18.1,6.84c2.79,1.93,7.2,1.41,10.82,2.27
				c2.64,0.62,5.19,1.67,8.73,2.85c-0.02,0.02,0.95-0.85,2.22-2c6.1,2.6,12.54,5.4,19.03,8.06c0.85,0.35,2.17,0.41,2.94-0.01
				c3.86-2.11,5.74,0.28,7.81,1.71c2.4,0.45,4.8,0.88,7.2,1.35c2.41,0.48,4.82,0.99,8.16,1.68c1.08,3.51,4.55,4.83,9.32,4.44
				c1.6-0.13,3.72,0.79,4.91,1.95c4.13,4.03,10.93,3.89,14.16,9.36c0.56,0.95,2.81,1.8,3.84,1.44c4.97-1.73,9.49,1.05,12.27,3.42
				c5.92,5.05,13.43,4.91,19.71,8.21c7.41,3.89,13.47-1.01,19.96-3.04c0.9-0.28,1.64-1.03,2.51-1.46c5.78-2.9,11.07-0.41,13.02,5.98
				c0.34,1.1,1.08,2.57,1.99,2.91c2.75,1.01,4.66,2.36,4.48,5.61c-0.02,0.32,0.23,0.88,0.49,0.97c7.47,2.72,8.73,11.23,14.51,15.47
				c0.65,0.48,2.05-0.19,2.92,0.13c0.95,0.36,1.73,1.22,2.51,1.94c1.04,0.96,2.18,2.97,2.98,2.83c5.52-0.99,8.81,3.15,13.01,5.16"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M483.16,115c-2.99-1-5.94-2.65-8.99-2.89c-13.96-1.09-27.98-1.43-41.91-2.74c-8.22-0.78-16.33-2.78-24.46-4.4
				c-5.42-1.08-10.06,0.01-13.99,4.02c-4.69,4.78-10.55,5.98-16.4,3.38c-7.49-3.33-13.3-1.58-19.09,3.56
				c-3.99,3.54-8.32,6.93-13.03,9.33c-2.11,1.08-5.95,0.32-8.25-0.96c-2.51-1.41-4.8-3.66-3.79-7.84c1.06-4.4-2.9-6.13-6.34-6.83
				c-3.04-0.61-6.3,0-9.46-0.16c-3.17-0.17-6.35-0.47-9.48-1c-4.1-0.69-5.9-3.32-5.5-7.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M599.95,793c-2.99,0.56-5.97,1.11-8.96,1.67c-0.18,0.38-0.37,0.76-0.55,1.14c2.84,1.06,5.69,2.09,8.51,3.21
				c3.18,1.26,6.32,2.62,9.47,3.94c-0.13,0.79-0.25,1.57-0.38,2.36c-11.69,0.57-23.38,1.14-35.07,1.72c-0.2,0.56-0.4,1.12-0.6,1.69
				c2.54,1.26,4.97,3.21,7.64,3.65c5.08,0.84,10.29,1.01,15.45,1.11c13.97,0.28,27.98-0.15,41.92,0.69
				c13.68,0.82,27.3,2.78,40.93,4.42c2.63,0.32,5.16,1.48,7.67,3.05c-13.93,4.74-28.72,1.58-43.45,5.34c5.34,4.78,11.41,5.32,17.32,6
				c10.74,1.24,21.68,1.33,31.46,7.02c5.16,3,10.48,5.72,15.73,8.57c-0.03,0.47-0.07,0.94-0.1,1.41c-6.07,0.85-12.14,1.7-18.99,2.65
				c4.82,5.31,10.51,5.67,15.8,4.76c15.02-2.57,28.73,1.09,41.91,7.73c2.46,1.24,4.37,3.58,6.53,5.41c-2.16,1.67-4.19,3.56-6.53,4.93
				c-2.22,1.3-4.76,2.06-7.58,3.22c3.15,4.85,7.86,5.54,12.13,6.23c9.43,1.53,19,2.15,28.41,3.76c3.76,0.64,8.56,0.72,10.01,5.86"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M301.47,866c5.82-0.33,11.65-0.91,17.47-0.94c9.32-0.05,18.64,0.19,27.95,0.46c14.64,0.43,29.29,0.91,43.92,1.51
				c8.49,0.35,17,0.69,25.45,1.54c12.34,1.24,24.64,2.89,36.93,4.5c1.07,0.14,2.01,1.26,3,1.93"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M738.21,165c-5.49,0.5-11.03,1.65-16.46,1.27c-3.59-0.25-5.49-0.29-5.16,4.23c-14.15,0-27.97,0-41.78,0
				c-0.24,0.43-0.48,0.85-0.72,1.28c9.79,3.02,19.36,6.42,27.32,13.54c9.09-2.61,27.27-0.68,32.87,4.16
				c-1.44,0.41-2.49,0.97-3.55,0.98c-9.32,0.07-18.64-0.09-27.95,0.07c-10.15,0.18-20.32,1.21-30.44,0.78
				c-7.67-0.33-14.94,0.06-21.97,3.19"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M136.26,101c-1.93,4.28,2.06,4.53,4.02,4.85c6.42,1.07,12.96,1.4,19.44,2.2c2.12,0.26,4.16,1.09,6.76,1.8
				c-2.98,4.56-6.99,5.12-10.76,5.59c-9.79,1.2-19.63,2-29.44,3.1c-5.12,0.57-9.62,2.73-11.91,7.51
				c-4.2,8.75-11.85,11.98-20.53,13.99c-9.67,2.23-19.31,4.63-28.95,6.99c-2.6,0.64-5.6,0.85-5.99,4.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M797.36,227c-7.74,0.67-15.46,1.61-23.21,1.93c-10.47,0.44-20.96,0.43-31.44,0.56c-5.66,0.07-11.31,0.01-16.97,0.01
				c-20.84,0.02-41.69-0.08-62.39,2.99c-6.29,0.93-7.11,1.73-7.49,8.01"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M194.16,207c-9.26-2.98-18.29-5.57-28.5-3.8c-7.89,1.36-16.27-0.48-24.4-0.09c-13.16,0.62-26.28,2.3-39.44,2.79
				c-13.62,0.5-27.3,0.51-40.92-0.02c-10.55-0.41-21.02-1.81-31.47,1.07c-4.12,1.14-5.42-0.15-4.97-3.94"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M799.61,235c-4.66,0.17-9.32,0.38-13.98,0.49c-14.64,0.36-29.28,0.93-43.92,0.94c-13.98,0.01-27.96-1.03-41.92-0.77
				c-9,0.17-17.98,1.8-26.95,2.86c-6.89,0.81-13.76,1.76-20.64,2.65c-0.09,0.55-0.19,1.09-0.28,1.64c-1.18-1.77-2.36-3.54-3.54-5.31"
				/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M884.96,46c2.33,1,4.88,3.18,6.95,2.79c7.41-1.4,13.59,2.32,19.91,4.48c5.66,1.93,10.15,1.34,15.1-1.2
				c2.64-1.36,5.71-1.88,8.43-3.12c2.77-1.26,5.85-2.46,7.89-4.56c3.12-3.21,4.73-8.22,11.41-6.39c-3.11-4.79-6.45-3.92-9.79-3
				c-11.48,3.17-22.84,6.92-34.49,9.27c-6.39,1.29-13.26,0.16-19.92,0.28c-1.84,0.03-3.66,0.62-5.49,0.95"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M341.41,1199c-5.32,0.33-10.65,0.96-15.97,0.95c-24.29-0.05-48.58-0.41-72.87-0.41c-24.29,0-48.58,0.41-72.87,0.44
				C120.62,1200.05,61.56,1200,2.5,1200"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M144.25,1253.5c-6.82,1.33-13.62,2.8-20.47,3.97c-11.74,2.01-23.26,5.43-35.44,4.6c-21.18-1.44-41.68,2.27-61.34,10.06
				c-6.81,2.7-13.03,6.87-19.52,10.37"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M465.69,1277.5c-3.99-0.33-8.11-1.45-11.95-0.85c-9.64,1.52-19,3.02-28.56-0.94c-2.58-1.07-6.54-0.25-9.32,0.96
				c-3.64,1.59-6.59,0.51-10.09,0.04c-7.92-1.06-15.6-3.8-23.97-2.47c-4.6,0.73-9.6-1.24-14.44-1.69c-3.13-0.3-6.52-0.76-9.44,0.08
				c-8,2.29-17.2,0.51-23.97,6.89c-1.02,0.96-1.69,2.31-2.52,3.47"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M344.9,1187.5c-1,1.67-2.2,3.25-2.93,5.03c-0.63,1.54-1.52,3.81-0.86,4.85c0.86,1.35,3.07,2.3,4.81,2.52
				c3.11,0.39,6.31,0.11,9.47,0.11c42.26,0,84.52,0,126.78,0c28.78,0,57.57,0,86.35,0c12.15,0,24.29,0.05,36.44-0.04
				c2-0.01,3.99-0.62,5.99-0.96"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M618.42,1199.25c19.13,0.25,38.27,0.67,57.4,0.72c44.76,0.11,89.51,0.03,134.27,0.03c53.74,0,107.48,0,161.22,0"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M635.89,407.5c-2.22-1.27-1.98-2.72-0.52-4.52c3.53-4.35,8.14-6.75,13.49-8.02c1.64-0.39,3.19-1.16,6.12-2.26
				c-9.53-4.94-18.85-1.74-27.59-4.34c4.17-5.62,9.75-6.5,15.49-6.83c2.81-0.17,4.15-1.16,5.77-3.87c2-3.36,6.09-5.44,9.19-8.18
				c2.73-2.42,5.41-4.91,7.98-7.5c3.24-3.25,4.8-6.97,1.71-12.21c6.5,0.81,12.19,1.25,17.76,2.28c9.69,1.79,19.24,4.51,28.98,5.8
				c12.07,1.6,24.27,2.2,36.42,3.08c2,0.14,4.05-0.49,6.08-0.76c0.02-0.49,0.04-0.98,0.05-1.46c-4.21-0.74-8.44-1.36-12.62-2.24
				c-7.51-1.58-14.96-3.43-22.48-4.93c-3.94-0.78-7.99-0.97-11.97-1.56c-15.48-2.3-31.07-4.04-46.38-7.19
				c-11.19-2.3-21.97-6.57-33-9.75c-5.14-1.48-10.42-2.46-16.98-3.98c-4.1,5.91-8.99,12.94-12.88,18.53
				c-7.14,0.53-13.09,0.93-19.04,1.42c-2.84,0.24-5.66,0.68-8.49,0.98c-4.99,0.52-10.04,0.69-14.96,1.59
				c-5.88,1.07-7.61,7.34-11.97,10.45c-3.77,2.68-7.32,5.67-11.03,8.43c-0.43,0.32-1.59,0.33-1.92,0c-4.17-4.26-6.54,0.16-8.96,2.13
				c-5.31,4.3-10.26,9.05-16.54,14.68c0-3.76,0-6.26,0-8.76"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M823.57,178c1.33,0.17,2.87-0.05,3.96,0.56c19.51,10.76,38.96,21.62,58.43,32.44c8.14,4.52,16.29,9.02,24.46,13.49
				c1.37,0.75,2.88,1.27,5.14,2.25c-0.41-5.11-2.78-8.08-6.17-10.2c-9.25-5.77-18.53-11.52-27.96-16.98
				c-5.3-3.07-10.37-6.09-13.02-12.03c-0.69-1.55-2-3.19-3.46-3.95c-8.06-4.17-16.25-8.11-24.43-12.06c-1.67-0.8-4.12-0.84-1.63-3.65
				c0.37-0.41-1.16-2.55-1.86-3.87c-5.43-10.3-9.93-12.74-20.96-11.51c-11.17,1.24-21.71-0.98-31.96-5.46
				c-15.58-6.81-31.19-13.55-46.94-19.94c-5.92-2.41-12.42-3.38-18.38-5.72c-4.47-1.75-7.63-0.92-9.44,3.16
				c-2.36,5.33-5.73,6.84-11.59,6.16c-7.71-0.89-15.22,1.38-22.46,4.26c-2.38,0.95-4.98,1.37-7.47,2.05c-4.6,1.24-7.3,3.83-7.58,8.99
				c-0.26,4.85-1.24,9.67-1.91,14.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M218.62,555.5c-5.82-1.33-11.68-2.55-17.46-4.02c-12.83-3.26-25.63-6.65-38.44-9.96c-1.64-0.42-3.35-1.05-4.98-0.95
				c-11.95,0.68-23.31-3.26-34.97-4.25c-9.69-0.82-19.62,0.92-29.42,1.75c-3.53,0.3-6.99,1.27-10.49,1.94"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M40.43,928c2.83,1.83,5.45,4.21,8.52,5.4c11.39,4.41,22.9,8.5,34.41,12.57c5.46,1.92,14.37-2.15,16.96-7.47
				c1.71-3.51,0.38-6.61-4.52-7.9c-10.22-2.68-20.53-5.21-30.95-6.93c-5.83-0.96-11.99-0.55-17.92,0.01
				c-2.43,0.23-4.68,2.48-7.01,3.82"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M430.75,20c-4.16,2.17-8.11,5.36-12.53,6.24c-5.44,1.09-11.27,0.09-16.92,0.3c-9.82,0.36-19.65,0.73-29.44,1.55
				c-6.03,0.5-11.97,2.28-18-0.03c-0.67-0.26-1.66,0.22-2.47,0.45c-12.32,3.49-24.63,6.97-36.93,10.52c-1.71,0.49-3.32,1.36-5,1.97
				c-4.2,1.51-5.74,3.97-4.09,8.54c1.37,3.8-0.3,5.16-4.39,3.94c-2.87-0.86-5.65-2-8.5-2.97c-5.66-1.92-10.88-5.58-17.52-3.17
				c-2.3,0.83-5.5,0.44-7.88-0.46c-6.62-2.49-12.99-5.63-19.53-8.33c-1.68-0.69-3.67-1.12-5.46-0.99c-9.66,0.69-19.3,2.19-28.96,2.3
				c-15.47,0.17-30.95-0.98-46.41-0.69c-8.5,0.16-16.97,2.15-25.46,3.34c-0.35,0.05-0.69,0.5-1,0.46
				c-12.93-1.64-21.44,8.01-31.94,12.53c-14.33,6.16-23.55,3.57-33.54-8.43c-6.03-7.24-14.38-10.87-23.86-9.97
				c-13.06,1.23-25.69-1.21-38.45-3.02C8.72,33.55,4.82,34,1,34"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M971.31,1096c-8.15-0.33-16.33-0.35-24.45-1.11c-5.27-0.5-10.24-2.23-11.09-8.87c-0.12-0.92-1.42-2.09-2.42-2.43
				c-2.59-0.9-3.72-2.76-2.83-5.02c0.58-1.46,2.37-3.12,3.88-3.42c5.09-1.02,10.27-1.62,15.45-2.09c4.34-0.39,7.65-1.63,8.57-6.53
				c0.21-1.12,1.45-2.15,2.43-2.99c3.33-2.88,2.94-6.75-1.03-8.49c-1.92-0.84-4-1.31-5.97-2.04c-2.79-1.03-5.88-1.84-5.03-5.99
				c0.06-0.29-0.23-0.87-0.48-0.96c-7.06-2.64-8.49-13.02-17.96-12.66c-5.63,0.21-11.35-0.63-16.94-1.51
				c-1.8-0.28-3.35-2.19-5.01-3.36c1.66-1.17,3.22-3.12,5.01-3.37c10.45-1.43,20.95-2.45,31.43-3.65c1.84-0.21,3.7-0.52,5.48-1.01
				c2.92-0.79,4.21-2.93,2.42-5.42c-3.83-5.34-1.65-9.07,3.11-12.45c-0.04-0.5-0.07-0.99-0.11-1.49c-5.8-1.71-11.6-3.41-17.4-5.12"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M870.98,86.5c-5.64-8.16-14.47-8.48-22.96-8.92c-19.47-1-38.53-4.34-57.41-9.04c-1.92-0.48-4.2-1.03-5.94-0.43
				c-10.6,3.62-22.03,5.6-30.43,13.95c-1.38,1.37-2.05,3.45-3.05,5.2"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M945.85,420c-12.67-2.94-25.63-3.37-38.41-2.21c-7.56,0.68-15.27,1.76-22.64,5.4c-7.28,3.59-15.93,4.3-23.78,6.9
				c-10.59,3.5-20.76,8.49-31.53,11.2c-15.06,3.79-15.58,16.67-19.41,27.72"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M376.84,733.5c2-0.5,3.99-1.43,5.99-1.44c53.91-0.08,107.81-0.21,161.72,0.15c5.94,0.04,10.88-1.2,16.01-3.62
				c3.78-1.78,7.98-2.66,11.91-4.15c1.15-0.44,2.65-1.45,2.91-2.47c1.14-4.52,0.99-9.52,5.59-12.49c0.27-0.17,0.5-0.65,0.49-0.98
				c-0.24-8.86,9.47-10.13,12.57-16.47c0.29-0.6,1.62-1.05,2.42-0.98c3.54,0.32,4.17-1.72,4-4.56"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M649.87,274c-12.62,1.12-23.14,6.38-31.73,16.21c-3.35-3.57-7.47-2.75-11.71-1.75c-2.44,0.57-5,0.61-7.47,1.08
				c-1.54,0.3-3.13,0.74-4.48,1.5c-4.55,2.56-8.73,6.2-13.58,7.71c-5.15,1.6-10.91,1.65-16.39,1.61c-3.77-0.03-7.03-0.22-10.59,1.96
				c-4.01,2.45-8.83,3.64-13.38,5.15c-7.74,2.59-11.69,9.29-16.41,15.07c-6.14,7.51-9.01,16.49-10.54,25.96
				c-1.1,6.84-6.51,11.08-9.51,16.78c-0.57-0.05-1.14-0.09-1.71-0.14c-0.58-6.55-0.3-13.33-1.96-19.59
				c-2.26-8.52,1.2-14.86,5.81-20.97c1.65-2.18,4.62-3.33,6.86-5.11c1.93-1.54,3.68-3.31,5.51-4.97"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M703.77,774.5c8.64,5.77,18.75,6.16,28.46,6.79c11.59,0.74,23.4-1.24,34.9-0.02c17.41,1.83,34.67,5.21,51.91,8.37
				c4.7,0.86,4.65,1.76,2.99,6.46c5.7,4.65,12.82,4.77,19.51,5.26c11.94,0.88,23.95,0.69,35.92,1.19c12.32,0.51,24.62,1.6,36.94,1.74
				c2.81,0.03,6.37-2.15,8.34-4.4c4.76-5.45,8.79-11.54,13.12-17.38"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M949.35,402.5c-3.83-0.67-7.63-1.66-11.48-1.95c-21.88-1.6-42.79,3.32-63.37,9.98c-11.21,3.63-22.36,7.47-33.43,11.54
				c-4.66,1.71-9.05,4.18-13.49,6.45c-2.81,1.44-4.24,3.46-3.13,7.01c0.55,1.77-0.21,3.96-0.37,5.96"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M248.07,261.75c-2.83-2.75-5.65-5.51-8.49-8.25c-2.54-2.45-6.5-3.82-6.11-8.51c0.05-0.59-1.48-1.79-2.39-1.92
				c-6.97-0.97-13.96-1.74-20.95-2.57c-0.49-0.06-1.28,0.2-1.45-0.04c-3.72-5.14-10.3-3.82-15.2-6.66c-3.38-1.96-8.48-1.34-12.79-1.2
				c-3.51,0.11-7,1.17-10.48,1.91c-1.69,0.36-3.32,1.03-5,1.46c-2.15,0.55-4.34,0.96-6.48,1.53c-1.52,0.41-3.9,0.57-4.35,1.55
				c-2.73,5.96-8.24,5.7-13.13,5.77c-5.19,0.08-9.65,1.25-13.45,4.68c-2.89,2.6-6.16,3.53-10,3.57c-2.5,0.03-4.97,1.09-7.49,1.37
				c-4.31,0.48-8.65,0.71-12.97,1.04"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M843.53,256c8.49,0.5,16.98,0.89,25.45,1.6c1.56,0.13,3.56,1.2,4.4,2.47c2.47,3.77,6.01,4.5,10.08,5.03
				c4.55,0.58,9,1.91,13.49,2.9c0.83,0.18,1.67,0.52,2.49,0.47c4.33-0.27,8.65-0.73,12.98-0.93c2.21-0.1,4.44,0.23,6.66,0.36
				c0.14-0.62,0.28-1.25,0.42-1.87c-6.02-2.01-12.04-4.04-18.07-6.01c-0.95-0.31-2.21-0.04-2.95-0.57
				c-11.39-8.11-24.97-8.29-37.87-11.35c-9.67-2.29-20.2-1.81-29.13-7.47c-1.93-1.23-4.91-0.76-7.4-1.14
				c-1.84-0.28-3.8-0.33-5.47-1.05c-4.57-1.99-9.24-3.9-13.45-6.53c-5.23-3.28-10.3-6.94-14.98-10.97
				c-8.84-7.61-17.33-15.63-26.03-23.41c-1.85-1.66-4.15-2.84-5.94-4.56c-7.17-6.9-13.72-14.1-17.22-23.89
				c-1.72-4.79-5.68-8.81-8.78-13.06c-4.41-6.06-9.13-11.9-13.4-18.05c-3.12-4.51-7.8-5.92-12.53-7.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1,492c8.86,1.48,17.25,3.7,25.62,7.65c12.69,5.99,26.18,11.01,39.86,13.91c10.35,2.19,21.54,0.31,32.35,0.5
				c3.67,0.07,7.38,0.67,10.97,1.47c22.92,5.14,45.27,11.81,66.49,22.26c8.22,4.05,17.57,5.77,26.34,8.74
				c4.19,1.42,9.48,1.08,11.49,6.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M591.47,499.5c-6.16-0.83-12.32-2.39-18.47-2.32c-7.99,0.09-16.02,1.2-23.95,2.42c-5.24,0.8-10.32,2.62-15.5,3.85
				c-0.91,0.21-2.1-0.04-2.96-0.48c-9.93-5.09-19.99-1.79-29.37,0.89c-7.7,2.21-15.07,1.09-22.56,1.64"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M676.82,1242c-3.49,1.17-7.03,2.22-10.47,3.53c-3.17,1.21-5.01,3.33-3.49,6.96c1.43,3.42,0.58,5.9-3.01,6.98
				c-8.63,2.58-17.32,4.96-25.94,7.55c-5.04,1.52-9.98,3.34-14.99,4.95c-1.45,0.47-2.98,0.93-4.49,0.98
				c-7.48,0.26-14.97,0.41-22.46,0.53c-11.65,0.2-23.29,0.35-34.94,0.5c-8.1,0.11-14.67,3.66-20.46,9"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M515.1,752c-2-0.5-3.99-1.42-5.99-1.44c-31.11-0.26-62.22-0.38-93.34-0.58c-2.83-0.02-5.66-0.31-8.49-0.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M274.52,273c-4.86-4.87-9.94-8.4-17.42-9.4c-5.84-0.78-10.98-0.33-16.56,0.7c-3.8,0.7-7.95-0.48-11.94-0.8
				c-8.32-0.67-16.65-1.21-24.95-2.06c-3.1-0.32-6.13-1.35-10.36-2.33c2.95-2.37,4.78-3.84,7.71-6.2c-4.41-1.72-7.77-3.49-11.35-4.33
				c-12.4-2.91-24.7-4.36-36.49,2.8c-3,1.82-6.88,2.26-10.41,3.09c-6.8,1.6-13.67,2.89-20.45,4.56c-8.12,2-16.21,3.13-24.47,0.96"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M448.72,475.5c-3.37-1.08-4.69,1.39-6.54,3.46c-7.71,8.6-15.2,17.46-23.55,25.39c-5.4,5.13-12.2,8.78-18.3,13.18
				c-1.32,0.95-2.35,2.3-3.52,3.47c1.83,1.17,3.65,3.29,5.49,3.31c4.06,0.04,6.8-0.52,8.5,5.04c0.94,3.06,6.97,4.9,11,6.58
				c9.04,3.76,18.61,6.41,27.3,10.81c6.25,3.16,11.43,8.44,17.09,12.77c0.66,0.51,1.33,1,2.23,1.68c-0.9,1.26-1.8,2.52-2.73,3.83
				c1.66,3,2.7,6.75,5.16,8.79c2.55,2.11,6.43,3.3,9.82,3.53c5.11,0.35,10.3-0.51,15.46-0.81c1.33-0.08,2.69,0.16,3.99-0.04
				c6.35-1,12.13-0.67,17.12,4.39c1.4,1.42,4.52,1.13,6.84,1.64c2.17,0.48,4.69,0.45,6.42,1.6c4.39,2.94,8.8,3.79,14.07,3.09
				c3.18-0.42,6.61,1.23,9.96,1.77c2.97,0.48,6.14,1.47,8.95,0.89c9.12-1.89,17.7,0.33,26.51,1.99c6.84,1.28,13.95,1.16,20.93,1.65
				c0.66,0.05,1.39-0.17,1.99,0.03c9.35,3.18,18.99,0.55,28.48,1.18c6.13,0.41,12.3,0.74,18.46,1.16c3.21,0.22,5.88-0.38,9.22,1.76
				c2.83,1.82,7.75,0.32,11.74,0.4c2.17,0.04,4.32,0.36,6.49,0.46c1.66,0.08,3.33,0.05,4.99-0.01c0.5-0.02,1.37-0.56,1.44-0.44
				c3.06,4.63,7.73,0.82,11.67,2c5.26,1.57,11.51-0.2,17.33-0.53"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M640.38,324.5c1.09-9.38,2.17-18.76,3.34-28.84c-1.58,0.19-4.54-0.14-6.77,0.98c-4.72,2.38-9.15,5.39-13.51,8.42
				c-2.52,1.75-4.79,2.35-8.03,2.2c-4.28-0.2-8.63,1.46-12.97,2.23c-4.15,0.73-8.31,1.37-12.48,2.01c-4.52,0.7-8.34,4.56-13.55,2.73
				c-0.77-0.27-2.61,2.42-3.91,3.78c-3.46,3.64-7.2,3.99-10.99,0.99c-3.86-3.06-10.25-1.39-13.08,2.93
				c-1.23,1.89-3.56,3.07-5.39,4.57"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M838.54,255c13.63,7.28,29.58,8.49,42.92,16.5c6.78,4.07,14.22,5.38,21.96,4.94c4.97-0.29,9.3,0.81,13.52,3.5
				c5.66,3.59,11.64,6.68,17.41,10.09c1.94,1.15,3.82,2.48,5.45,4.03c0.84,0.81,1.78,2.89,1.42,3.31c-0.9,1.04-2.49,1.94-3.86,2.02
				c-2.61,0.15-5.26-0.32-7.89-0.53c-0.17,0.58-0.34,1.16-0.51,1.75c5.71,3.88,11.43,7.76,17.14,11.64"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M277.02,815c20.63-0.5,41.26-1.27,61.89-1.38c9.31-0.05,18.64,1.16,27.95,1.93c1.74,0.14,3.43,0.93,5.15,1.42
				c0.02,0.63,0.05,1.25,0.07,1.88c-12.6,1.79-25.19,3.58-37.79,5.37c-0.41,0.72-0.83,1.43-1.24,2.15c2.6,1.24,5.2,2.47,7.8,3.71
				c-0.35,0.82-0.69,1.64-1.04,2.46c-2.79,0.99-5.55,2.68-8.39,2.83c-6.13,0.3-13.22,1.7-18.27-0.75
				c-10.17-4.93-20.18-0.94-30.14-2.1c-0.33-0.04-0.66,0-1,0"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M759.68,1106c5.7-0.74,11.39-1.48,17.09-2.22c0.2-0.5,0.41-1,0.61-1.5c-2.13-1.54-4.26-3.09-7.35-5.33
				c10.69-2.67,19.91-2.36,29.07-0.89c13.02,2.09,25.95,4.67,38.95,6.9c4.82,0.83,6.84,5.14,3.99,9.03
				c-3.73,5.09-2.29,8.56,3.99,9.49c2.65,0.39,5.34,0.57,7.98,1.06c1.22,0.22,3.16,0.73,3.37,1.49c1.7,6.17,6.85,4.81,11.11,5.48
				c2.58,0.41,5.12,1.01,7.83,1.56c-1.24,5.26-5.04,4.56-7.84,4.82c-6.79,0.64-13.64,0.73-20.45,1.13c-1.38,0.08-2.73,0.59-5.44,1.22
				c6.09,5.07,12.14,4.25,17.91,4.22c10.65-0.06,21.3-0.41,31.94-0.37c1.8,0.01,3.59,1.34,5.38,2.06c-0.09,0.71-0.17,1.42-0.26,2.12
				c-18.73,1.59-37.47,3.19-56.2,4.78c-0.07,0.89-0.13,1.78-0.2,2.67c2.95,0.76,5.88,2.05,8.85,2.16c7.81,0.31,15.65-0.13,23.45,0.23
				c2.84,0.13,5.61,1.7,8.41,2.62c-0.1,0.65-0.21,1.3-0.31,1.95c-6.39,0.89-12.78,1.78-20.02,2.78c3.62,3.94,7.3,5.02,11.43,5.03
				c4.33,0.01,8.68-0.3,12.97,0.11c2.72,0.26,5.33,1.57,8,2.39c3.32,1.02,6.65,2.02,9.98,3c1.32,0.38,2.98,0.28,3.94,1.07
				c5.96,4.92,12.98,4.71,20.02,4.42c5.64-0.23,11.07-0.06,14.3,4.95c-1.22,1.5-3.26,2.88-3.14,4.05c0.49,5.02-2.47,5.4-6.16,5.56
				c-3.39,0.14-4.54,2.26-3.99,5.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M855.01,1283.5c-0.5-0.67-0.86-1.7-1.52-1.94c-8.12-3.08-16.26-6.14-24.46-8.98c-1.49-0.52-3.3-0.1-4.97-0.07
				c-7.32,0.15-14.64,0.33-21.96,0.49c-7.82,0.17-15.67,0.86-23.45,0.36c-8.36-0.54-16.65-2.19-24.97-3.36"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M832.55,329.5c-7.51,0.25-15.05,2.5-22.5-0.92c-2.93-1.34-6.26-2.24-9.45-2.39c-1.78-0.08-3.9,1.55-5.36,2.93
				c-0.58,0.55,0.21,2.54,0.38,3.87c1-0.17,2.07-0.16,2.98-0.54c1.36-0.58,2.6-1.44,4.19-2.35c0.85,1.06,1.82,1.87,2.27,2.91
				c2.05,4.7,5.97,6.72,10.47,8.62c4.91,2.08,9.45,5.08,14.01,7.9c5.04,3.11,17.33,2.38,21.98-1.53"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M971.31,1235c-18.68,8.86-38.53,11.19-58.9,10.47c-3-0.11-5.99-0.81-8.99-0.89c-1.39-0.04-2.81,0.73-5.31,1.44
				c8.66,9.53,18.3,13.42,29.77,12.96c8.41-0.34,16.92-1.58,24.94,2.06c6.16,2.8,12.31,1.16,18.48,0.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M295.98,926.5c15.64,4.33,31.25,8.77,46.94,12.93c3.94,1.05,5.77,3.1,5.46,7.07c-0.22,2.76,0.65,4.38,3.45,5.63
				c3.72,1.66,7.2,3.99,10.5,6.42c4.83,3.56,5.57,7.02,1.94,11.88c-3.73,4.99-8.22,9.4-12.38,14.07"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M142.75,413.5c-1.66-0.33-3.77-0.14-4.91-1.1c-4.03-3.39-7.73-1.23-12.1,1.25c5.5,2.45,10.91,5.14,16.53,7.3
				c14.25,5.48,28.43,11.26,42.97,15.84c8.55,2.7,17.29,4.6,25.45,8.59c3.07,1.5,6.77,1.68,10.42,1.74
				c-4.42-6.18-11.98-9.16-13.47-17.13"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1,463.5c4.16,2.67,8.32,5.33,12.48,8c25.99,16.72,55.82,25.46,82.84,40.02c1.09,0.59,2.01,1.48,3.01,2.23"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M836.54,968c2.63-3.4,1.82-5.11-2.5-4.99c-3.99,0.12-8.05-0.05-11.96,0.58c-5.02,0.81-8.77-0.83-13.12-3.38
				c-9.42-5.52-19.05-11.72-29.42-14.34c-23.35-5.91-46.04-13.71-68.76-21.45c-12.09-4.12-24.46-4.49-36.96-4.36
				c-0.84,0.01-1.66,0.94-2.5,1.44"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M626.91,739.5c0.33-2.5,0.33-5.11,1.09-7.47c0.77-2.37-1.19-7.1,4.4-6.03c0.94-4.53,6.7-5.98,6.73-11.5
				c0.02-3.1,0.77-6.85,6.23-7.26c5.96-0.45,5.33-7.42,7.98-11.26c0.49-0.71,0.59-1.68,1.06-3.14c1.42,1.26,2.53,2.25,4.41,3.94
				c2.12-3.25,4.08-6.28,6.33-9.74c0.72,1.64,1.32,3,2.24,5.11c2.56-1.5,5.76-2.6,7.8-4.78c1.99-2.13,2.81-5.36,4.22-8.27
				c4.01,3.89,7.99,2.74,9.43-2.09c1.08-3.63,2.74-6.52,7.49-5.63c1,0.19,2.36-1.14,3.46-1.9c1.06-0.74,1.94-1.76,3.03-2.45
				c1.25-0.79,2.64-1.36,3.97-2.03c-1.33-1.5-2.44-3.37-4.09-4.34c-0.77-0.45-2.54,0.92-3.9,1.31c-1.47,0.43-3.66,1.49-4.37,0.89
				c-3.65-3.07-6.46-0.71-9.03,1.22c-2.33,1.76-3.86,1.64-6.65,0.71c-2.7-0.89-6.61-0.02-9.28,1.4c-2.69,1.44-3.93,1.8-6.19-0.62
				c-3.51-3.76-6.97,0.24-10.36,1.03c-1.94,0.45-3.61,2.01-4.96,2.8c-2.19-1.96-4.01-4.99-6.07-5.16c-5.78-0.49-5.82-0.02-9.38,4.25
				c-2.45-1.6-4.96-3.23-7.75-5.05c-5.89,4.07-6.54-5.62-11.65-5.44c-2.39,1.36-5.05,2.86-7.92,4.49c-1.26-3.14-3.05-5.21-7.26-4.21
				c-1.67,0.4-3.88-1.47-6.71-2.68c-0.46,0.29-1.98,1.81-3.76,2.23c-1.31,0.3-3.54-0.38-4.35-1.42c-3.25-4.16-9.49-6.1-14.08-3.92
				c-0.67,0.32-1.63,1.13-1.95,0.93c-6.3-4-11.01,1.86-16.52,2.49c-1.44,0.17-2.97-0.77-4.49-0.87c-1.31-0.09-3.06-0.2-3.92,0.53
				c-4.23,3.54-8.69,5.16-14.05,2.93c-0.28-0.12-0.78-0.16-0.97,0.01c-5.22,4.43-14.18,0.39-17.47,8.89
				c-5.66-4.53-10.34-8.84-15.63-12.18c-1.56-0.99-5.35,0.07-7.23,1.43c-3.18,2.29-5.86,3.52-10.12,3.21
				c-3.02-0.22-6.27,4.27-9.46,4.34c-5.13,0.11-9.89,4.98-15.66,0.55c-2.51-1.93-6.77-2.74-10.91-0.48
				c-2.84,1.54-6.87,0.85-10.35,1.24c-5.18,0.59-10.87-0.7-14.98,3.99c-2.96,3.38-4.32,0.31-5.94-2.23
				c-1.44,1.11-2.63,1.79-3.52,2.75c-5.31,5.79-12.42,5.86-19.48,6.58c-4.55,0.47-8.96,2.43-13.5,2.73
				c-3.5,0.23-7.11-1.08-11.72-1.89c-4.13,5.04-10.06,9.21-18.75,6.18c-1.38-0.48-3.44,0.23-4.93,0.94c-6,2.85-11.27,1.27-16.53-2
				c-2.64-1.64-5.57-2.87-8.48-3.98c-1.16-0.44-2.67-0.26-3.95-0.01c-1.06,0.21-2.32,1.63-2.96,1.34c-9.14-4.2-18.26,3.16-26.9,0.3
				c-5.49-1.82-10.26-2.46-15.6-0.77c-0.66,0.21-1.78-0.42-2.45-0.96c-2.28-1.86-4.23-4.69-7.59-1.55c-0.28,0.26-1.81-0.37-2.38-0.96
				c-1.98-2.02-3.8-4.2-6.08-6.77c-4.04,7.31-9.5,5.6-15.48,4.05c-2.33-0.6-5.36,1.53-9.18,2.77c-2.78-1.76-6.75-3.66-9.76,1.97
				c-0.25,0.47-0.97,0.98-1.46,0.97c-3.33-0.07-6.83,0.32-9.95-0.58c-5.95-1.72-11.68-4.2-17.51-6.37"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M866.49,533c1.69,0.37,3.38,0.75,6.57,1.45c-6.7,5.03-12.39,9.27-18.05,13.56c-8.2,6.21-16.64,11.19-27.44,12.21
				c-12.39,1.17-24.29,5.05-35.47,10.77c-0.6,0.3-1.32,0.47-1.99,0.5c-13.71,0.54-25.36,8.25-38.41,11.11
				c-1.32,0.29-2.35,1.9-3.51,2.9"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M115.3,19c10.08,5.7,20.22,10.47,32.45,9.65c12.77-0.85,25.62-0.9,38.42-0.51c9.17,0.28,18.31,1.86,27.46,2.86"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M155.23,142c-8.65,0.83-17.29,1.85-25.96,2.46c-13.96,0.99-27.98,1.36-41.91,2.67c-8.88,0.83-17.64,2.92-26.47,4.34
				c-1.62,0.26-3.32,0.04-4.99,0.04"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M918.4,926c3.49,1,6.93,2.38,10.49,2.93c12.95,2.01,25.96,3.66,38.91,5.67c1.51,0.23,2.81,1.82,4.2,2.78
				c-0.22,0.47-0.45,0.94-0.67,1.41c-13.2,0.94-26.39,1.87-39.59,2.81c-0.07,0.93-0.15,1.86-0.22,2.79c4.45,1.04,8.84,2.56,13.35,3
				c8.78,0.86,17.63,1.1,26.44,1.6"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M125.28,1214c2.07,5.22,7.08,6.99,11.53,8.32c10.82,3.22,21.9,5.53,32.89,8.17c2.47,0.59,4.98,1.06,7.49,1.5
				c2.32,0.4,4.92,1.52,6.94,0.88c11.88-3.81,24.35-6.64,32.96-16.89c0.4-0.47,0.36-1.31,0.53-1.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M359.37,59.5c11.31,0.23,22.03-1.51,30.39-10.06c1.57-1.6,2.39-3.94,3.55-5.94c-2.16-0.83-4.34-2.42-6.49-2.38
				c-11.82,0.24-23.65,0.55-35.43,1.51c-5.67,0.46-11.42,2-15.41,7.66c5.82,7.94,14.53,7.88,22.89,8.7"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M346.4,972c1.83,4.83,4.29,9.54,5.31,14.54c0.99,4.83,0.39,9.97,0.76,14.96c0.09,1.21,0.99,2.65,1.95,3.46
				c5.91,4.93,11.99,9.65,17.91,14.57c5.73,4.75,11.33,9.65,16.98,14.48c1.34,1.15,2.91,2.15,3.95,3.53
				c4.94,6.51,6.29,12.39-3.56,16.19c-12.04,4.65-24.85,7.27-37.33,10.77"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M744.7,196c-7.78,1.19-15.41,2.45-23.5,1.25c-6.3-0.94-12.94,0.57-19.43,0.71c-12.31,0.28-24.63,0.11-36.93,0.66
				c-4.86,0.22-9.66,1.87-14.48,2.87"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M307.46,17.5c-5.35,9.67-14.79,11.94-24.46,12.3c-8.45,0.31-16.97-1.05-25.45-1.81c-14.52-1.3-28.84-0.34-42.93,3.52"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M577.99,153.5c-0.95-2.29-0.23-5.92-5.05-4.76c-1.43,0.34-3.77-2.23-5.4-3.78c-4.14-3.94-8.93-5.07-14.52-4.57
				c-2.94,0.26-6.06-0.26-8.94-1.04c-1.22-0.33-2.87-2.19-2.87-3.36c-0.01-3.41-1.77-4.83-4.65-4.92c-2.63-0.09-5.96-1.14-6.99,2.92
				c-0.98,3.85-4,3.7-6.98,3.48c-2.52-0.18-4.75-0.45-5.2-3.96c-0.15-1.18-2.66-2.44-4.31-2.94c-3.58-1.1-7.26-2.03-10.97-2.49
				c-2.6-0.32-5.31,0.22-7.97,0.41c-2,0.14-4.14,0.89-5.97,0.4c-10.42-2.8-21.59-3.19-30.97-9.41"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M752.94,1269c-7.59,3.18-15.59,4.66-23.71,4.4c-14.15-0.47-28.29-2.23-42.43-2.17c-10.08,0.04-20.15,2.17-25.96,12.27"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M735.22,747.5c-2.57-0.66-4.58-1.65-6.82-3.63c-2.58-2.28-7.65-2.22-11.65-2.28c-15.47-0.23-30.95,0-46.42-0.16
				c-3.67-0.04-7.32-1.36-10.98-1.34c-7.32,0.03-14.64,0.79-21.96,0.85c-8.32,0.08-17.05,1.3-24.87-0.76
				c-18.1-4.76-36.33-2.9-54.49-3.05c-33.6-0.28-67.22,0.21-100.82,0.37"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M426.26,890.5c-11.15,3.41-22.61,1.57-33.94,1.99c-18.8,0.7-37.6,1.26-56.4,2.08c-3.18,0.14-6.33,1.26-9.49,1.93"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M342.4,571c2.5,0.5,5.12,0.67,7.46,1.56c6.56,2.49,12.82,5.97,19.55,7.68c4.14,1.05,8.92-0.6,13.41-0.69
				c4.32-0.09,8.84-0.45,12.93,0.62c5.51,1.45,10.54,4.82,16.07,6.11c4.42,1.04,9.27,0.17,13.93,0.23c1.67,0.02,3.49-0.08,4.96,0.54
				c9.29,3.91,18.42,8.04,28.99,7.05c4.01-0.38,8.39-1.25,11.67,3.24c1.27,1.73,4.74,2.13,7.3,2.57c3.28,0.56,6.66,0.56,11.52,0.9
				c2.28-3.62,6.1-0.68,9.94,1.17c1.14,0.55,2.79,1.77,3.41,1.38c3.79-2.35,5.71-0.13,7.27,3.01c3.53-1.32,7.14-4.67,9.35,1.09
				c0.14,0.37,1.38,0.68,1.91,0.48c6.92-2.61,13.37,0.39,19.99,1.49c1.43,0.24,3.23,0.16,4.44-0.52c5.3-2.98,9.66-0.7,14.02,2.08
				c0.51,0.32,1.09,1.04,1.49,0.94c8.49-2.03,16.12,5.04,25.1,1.94c3.16-1.09,7.82,2.38,11.86,3.58c3.16,0.94,6.47,3.51,9.53-0.42
				c0.4-0.51,1.6-0.49,2.44-0.52c1.66-0.06,3.32,0.02,6.02,0.05c-0.01-0.02,0.67,1.24,1.45,2.71c3.77-2.09,7.48-3.68,11.56-0.88
				c0.52,0.36,1.91-0.72,2.93-0.88c3.8-0.59,7.46-3.79,11.53-0.59c0.43,0.34,2.02,0.02,2.38-0.48c2.39-3.43,5.06-2.5,8.09-1.04
				c1.08,0.52,2.63,0.01,3.95,0.1c3.67,0.24,7.49-2.25,11.09,0.87c0.6,0.52,2.54-0.64,3.89-0.86c2.48-0.4,6.07-1.88,7.27-0.78
				c2.97,2.7,5.8,1.19,8.7,1.24c0.66,0.01,1.74-0.65,1.93-0.42c2.79,3.28,6.79,0.81,10.16,2.16c2.41,0.97,5.96-0.43,8.85-1.3
				c2.82-0.85,5.44-2.42,6.78,0.2c3.71-0.24,6.78-0.9,9.7-0.5c3.73,0.51,7.32,1.96,11.01,2.82c0.91,0.21,2.03-0.14,2.97-0.47
				c3.68-1.3,13.14-1.23,17-0.03c1.21,0.38,2.7,0.3,3.97,0.02c6.94-1.57,13.64,0.92,20.47,1.04"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M100.82,318.5c4.16,0.17,8.32,0.27,12.48,0.54c1.35,0.09,3.58,0.29,3.85,1.02c2.2,5.96,7.48,2.58,11.12,3.96
				c4.02,1.52,7.9,3.46,12.06,0.08c0.8-0.65,2.59-0.09,3.92-0.09c4.16,0,8.33,0.21,12.48-0.04c8.08-0.49,16.11-2.49,23.18,4.32
				c2.07,1.99,6.77,1.23,10.26,1.73"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M479.66,650.5c-8.32-2.5-16.6-5.13-24.97-7.45c-4.62-1.28-9.51-2.34-14.04-3.05c-6.5-1.02-12.54-3.68-18.91-4.28
				c-4.72-0.45-9.61,1.32-14.46,1.71c-2.96,0.24-5.99-0.2-8.97-0.46c-0.85-0.08-1.91-1.14-2.46-0.88c-5.99,2.87-10.35-2.26-15.44-3.4
				c-4.02-0.9-7.38-4.7-11.07-7.16c-0.73-0.49-2.15-1.2-2.41-0.95c-3.63,3.63-6.55,1.32-10.18-0.3c-1.98-0.88-5.39,0.66-7.83,1.81
				c-5.45,2.57-10.55,5.9-16.09,8.24c-1.86,0.79-4.57-0.61-6.92-0.8c-6.77-0.56-10.49-5.45-14.48-10.01
				c-3.72-4.25-4.56-4.17-7.48,0.49c-1.59,2.54-9.23,3.83-11.97,1.99c-0.99-0.67-1.94-1.42-3.34-2.45c-1.19,3.05-2.26,5.82-3.39,8.72
				c-2.21-1.51-4.11-2.8-7-4.77c-2.13,4.32-4.02,8.14-6.09,12.33c-2.63-1.15-5.43-2.37-8.67-3.78c-1.63,2.84-3.3,7.13-8.3,2.22
				c-1.06,5.94-5.4,4.64-9.1,5.41c-2.53,0.53-4.7,2.81-7.03,4.31"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M702.78,749c-4.33-0.67-8.64-1.83-12.98-1.91c-19.13-0.37-38.26-0.46-57.4-0.56c-4.49-0.02-8.99,0.19-13.47,0.53
				c-1.9,0.14-3.76,0.87-5.79,2.48c2.65,1.39,5.29,2.78,8.88,4.67c-5.53,3.57-10.94,3.03-16.06,2.7c-7.44-0.47-14.69-0.71-22.02,1.43
				c-3.37,0.99-7.28-0.02-10.93,0.23c-3.01,0.21-5.99,0.94-8.99,1.44"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M413.28,920c-10.98,0-21.96-0.01-32.94,0.02c-0.83,0-1.66,0.44-2.5,0.46c-14.97,0.36-29.95,0.61-44.92,1.08
				c-3.51,0.11-6.99,0.94-10.48,1.44"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M310.96,922.75c-3.83-0.75-7.63-1.67-11.49-2.2c-3.25-0.44-4.11-2.49-3.4-5.03c0.99-3.56-0.03-5.51-3.58-6
				c-3.47-0.48-3.56-2.86-3.51-5.53c0.07-3.83,0.02-7.67,0.02-11.57c-2.83,0.4-5.16,0.73-7.26,1.03c-0.25,3.05-0.49,6.04-0.73,9.04"
				/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1.5,541.5c-0.01,6.77,5.56,7.25,10,8.42c12.26,3.21,23.93,7.62,34.99,13.96c7.27,4.17,15.32,6.95,22.87,10.67
				c11.55,5.68,23.02,11.55,34.42,17.54c1.79,0.94,3.04,2.92,4.53,4.42"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M98.83,325c4.16,0.17,8.32,0.31,12.48,0.52c0.67,0.03,1.66,0.09,1.95,0.52c3.62,5.35,9.75,3.16,14.51,5
				c3.56,1.37,7,2.4,10.49-0.04c0.19-0.13,0.33-0.47,0.5-0.48c8.15-0.19,16.3-0.5,24.46-0.47c5.45,0.02,10.32,1.32,15.2,4.62
				c3.13,2.11,8.43,1,12.75,1.33"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M396.56,520c-1.41-0.83-2.78-1.77-4.25-2.48c-2.29-1.09-4.77-1.81-6.95-3.07c-3.43-1.98-7.72-3.58-9.77-6.61
				c-3.17-4.69-7.82-5.34-12.25-6.75c-4.72-1.51-9.39-2.7-12.02-7.54c-0.51-0.93-2.01-2.05-2.91-1.93
				c-7.71,0.97-12.19-4.92-18.03-8.01c-6.44-3.41-13.26-6.09-19.92-9.09c-6.66-3-13.43-5.77-19.92-9.09
				c-3.58-1.83-6.53-4.9-10.07-6.83c-4.3-2.35-8.94-4.09-13.43-6.08c-4.77-2.12-10.86-1.44-14.07-6.95
				c-0.51-0.88-2.22-1.44-3.41-1.49c-7.23-0.3-12.74-4.78-19.01-7.46c-6.95-2.97-14.26-5.12-21.43-7.57
				c-0.4-0.14-1.36,0.53-1.43,0.43c-4.42-7.06-12.64-6.44-19.07-9.35c-1.68-0.76-3.98-0.11-6.83-0.11c2.5,4.2,4.33,7.27,6.16,10.34
				c-0.19,0.41-0.39,0.82-0.58,1.23c-1.23-0.36-2.69-0.41-3.65-1.13c-5.08-3.83-10.19-7.02-17-7.18c-2.53-0.06-5.41-2.35-7.42-4.34
				c-6.5-6.47-14.54-10.36-22.51-14.47c-5.63-2.9-10.89-6.51-16.54-9.36c-3.25-1.64-6.89-2.64-10.45-3.53
				c-4.01-1-8.14-1.56-12.21-2.31"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M925.89,899c2,1.83,4.12,3.55,5.96,5.53c2.18,2.36,2.26,4.62-0.43,7.01c-11.13,9.86-24.08,10.13-37.99,8.68
				c-12.95-1.35-24.65-5.9-36.41-10.71"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M427.26,731c-0.22-6.24,5.75-5.89,8.93-8.56c3.87-3.24,7.41-6.9,10.96-10.51c0.25-0.26-0.35-1.94-0.96-2.37
				c-3.48-2.41-7.11-4.21-11.03-0.22c-2.57-1.95-5.04-3.82-7.59-5.76c-4.46,1.82-9.12,6.02-13.8-0.07c-0.2-0.27-0.69-0.54-0.98-0.48
				c-8.4,1.74-11.3-4.72-14.99-10.02c-0.05-0.08-0.32-0.01-0.99-0.01c-0.26,0.53-0.9,1.24-0.95,2c-0.49,6.9-6.27,8.23-11.05,10.43
				c-2.51,1.15-3.71-0.16-4.57-2.9c-1.92-6.07-3.75-6.49-9.43-3c-2.41-2.55-4.85-5.11-8.05-8.5c-0.64,3.5-1.14,6.24-1.89,10.31
				c-3.04-2.74-5.41-4.89-8.52-7.7c-1.4,3.18-2.84,6.45-4.36,9.88c-3.27-3.12-10.46,0.57-11.98-8.35c-1.41,2.35-1.78,3.63-2.63,4.24
				c-1.12,0.8-2.58,1.37-3.95,1.53c-8.95,1.01-18.22-4.59-27.04,1.41c-0.98,0.67-3.37-0.24-4.87-0.92
				c-3.74-1.69-6.86-4.08-11.14-0.09c-4.35,4.05-10.11,1.63-15.34,1.19c-2.81-0.24-5.76-0.52-8.46,0.07
				c-4.12,0.9-8.07,2.53-10.75-2.23c-5.42,2.45-8.22,1.93-15.31-0.66c-1.86-0.68-4.71,1.38-7.64,2.36
				c-4.38-4.88-10.01-4.87-16.31-1.71c-1.4,0.7-3.64-0.28-5.5-0.49c-0.5-0.98-1-1.97-1.5-2.95c-0.65,1.68-1.3,3.36-1.69,4.34
				c-6.87-1.21-13.04-2.29-19.77-3.47c-0.71,0.94-1.78,2.35-3.14,4.15c-1.05-1.6-1.96-2.99-2.51-3.83c-3.31,0.98-6.52,1.97-9.75,2.86
				c-1.13,0.31-2.35,0.6-3.48,0.48c-5.17-0.57-10.35-1.99-15.47-1.73c-3.88,0.19-7.65,2.49-11.5,3.75c-1.45,0.48-3.74,1.49-4.36,0.89
				c-4.09-3.87-5.88-0.27-7.86,1.93c-5.03-1.57-9.82-3.07-15.1-4.72c-0.08,0.12-0.94,1.38-2.2,3.22c-2.64-5.34-5.59-6.54-10.03-2.43
				c-1.61,1.49-4.66,1.41-6.88,3.16c-9.94-2.96-19.88-5.92-28.94-8.62c-3.65,2.56-6.59,4.64-9.94,6.99
				c-0.94-1.57-1.56-2.44-2.01-3.39c-1.59-3.35-2.73-6.03-7.22-2.25c-1.85,1.56-8.14,1.41-8.9,0.05c-2.85-5.04-5.87-1.62-8.77-1.21
				c-5.47-6.09-6.15-6.12-11.69-0.46c-1.76-1.03-3.56-2.08-5.36-3.13"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M522.09,401c-3.83,5.33-7.68,10.65-11.48,16c-12.75,17.97-26.88,35.19-34.79,56.05c-5.27,13.9-15.06,24.35-24.17,35.39
				c-3.96,4.8-8.58,9.06-12.99,13.66c-2.14-1.68-3.74-2.93-4.44-3.48c-0.78-7.46,8.54-10.74,6.05-19.06
				c4.68-1.45,4.58-4.99,4.72-9.57c0.24-7.9,3.17-15.39,9.24-20.97c6.95-6.4,9.5-14.69,12.2-23.46c4.34-14.1,6.36-29.24,19.92-39.46
				c6.52-4.91,9.95-13.94,14.76-21.12c1.21-1.8,1.92-4.3,3.57-5.38c6.34-4.16,6.92-10.57,7.39-17.11c0.15-2.16,0.02-4.33,0.02-6.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M658.35,1139.5c1.83,0.33,3.65,0.88,5.49,0.97c13.98,0.69,26.32,7.88,39.92,10.06c4.68,0.75,6.1,4.42,6.44,8.48
				c0.38,4.44,2.28,7.36,6.5,9.1c1.61,0.66,2.71,2.56,4.04,3.89c-1.83,1.17-3.53,2.97-5.52,3.37c-3.91,0.78-8.5-1.23-11.44,3.14
				c-0.08,0.11-0.01,0.33-0.01,0.94c2.8,3.57,7.39,3.63,11.49,4.49c5.94,1.24,12.07,1.65,17.93,3.17c6.57,1.7,11.79,5.66,15,11.89"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M422.26,257.5c-2.19-3.75-1.07-9.62-5.04-10.3c-13.05,3.74-24.52,6.85-35.85,10.43c-2.52,0.8-4.51,3.26-6.75,4.96
				c0.26,0.6,0.52,1.21,0.78,1.81c1.81-0.13,3.79,0.17,5.39-0.48c6.6-2.67,13.3-4.27,20.5-4.04c2.58,0.09,4.88-0.51,6.81-3.69
				c1.79-2.94,6.33-4.2,9.65-6.2"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M518.1,314c-1.33-0.67-2.62-1.81-4-1.93c-11.14-0.97-22.36-2.89-33.41-2.2c-8.3,0.52-16.85-1.12-24.97,1.66
				c-5.88,2.01-11.68,4.24-17.47,6.5c-2.75,1.07-2.86,3.14-1.5,5.47c0.98,1.68,1.99,3.34,2.99,5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M863.99,298c0.83,0.67,1.57,1.71,2.51,1.94c7.03,1.69,12.85,4.91,16.52,11.53c1.4,2.52,3.97,2.9,6.91,3.2
				c6.67,0.67,13.18,2.37,17.32,9.3c-2.77-0.49-5.61-0.73-8.3-1.51c-7.03-2.03-13.93-4.51-21.01-6.32c-2.11-0.54-4.62,0.53-6.95,0.87
				"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M519.59,299c-10.64-7.38-22.99-6.69-34.94-6.83c-10.47-0.13-21.04,0.63-31.41,2.05c-5.49,0.76-11.41,2.81-15.76,6.1
				c-3.34,2.52-9,4.99-7.44,11.73c0.51,2.19-1.45,4.95-2.29,7.45"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M537.06,628c3.65-3.92,8.62-2.16,12.99-2.96c1.45-0.27,2.99-0.11,4.48-0.02c1.34,0.08,2.68,0.23,3.99,0.51
				c0.86,0.18,2.1,1.17,2.44,0.89c5.73-4.67,11.04,0.5,16.53,0.65c4.84,0.14,9.67,0.78,14.47,1.46c5.76,0.81,11.47,1.9,17.77,2.96
				c2.24,0,5.29-0.39,8.18,0.11c3.08,0.53,6.08,1.73,8.98,2.94c2.76,1.15,6.9-3.94,8.33,2.58c3-3,5.15-2.45,6.46,1.73
				c1.23-1.52,1.97-2.44,2.72-3.36c0.14,0,0.41-0.06,0.45,0.01c3.75,6.62,11.47,2.95,16.6,6.32c1.43,0.94,4.22-0.12,6.38-0.34
				c2.83-0.29,5.79-1.35,8.46-0.84c4.65,0.9,9.51-0.57,14.15,2.1c1.98,1.14,5.6-1.02,8.33-0.64c8.5,1.16,16.93-2.96,25.53,0.23
				c3.82,1.42,8.29,1,12.4,1.75c1.27,0.23,2.74,1.33,3.43,2.45c1.73,2.79,3.07,0.83,4.57-0.05"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M623.91,1060c0.5,4.67,1.09,9.33,1.47,14c0.27,3.28-1.75,6.77,2.84,9.34c1.53,0.86,0.99,5.65,1.16,8.66
				c0.88,15.5,1.39,31.02,2.7,46.48c0.4,4.72,2.91,9.26,4.45,13.88c0.53,0.06,1.05,0.13,1.58,0.19c3.26-4.35,6.51-8.7,9.77-13.05"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M204.14,1213.5c-2.83-0.17-5.66-0.47-8.49-0.48c-14.14-0.05-28.28-0.08-42.43-0.01c-19.63,0.11-39.26,0.37-58.9,0.47
				c-15.64,0.08-31.28,0.01-46.92,0.02c-3.01,0-3.01,0.01-5.24,2.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M103.32,302c3.33,0.33,6.65,0.88,9.98,0.94c3.27,0.05,6.29,0.07,8.03,3.54c0.33,0.67,1.46,1.59,1.92,1.44
				c5.78-1.86,8.79,2.06,11.93,7.03c4.63-7.91,10.91-7.04,17.57-6.11c3.19,0.45,6.62-1.18,9.96-1.26c6.42-0.17,13.01-1.37,18.64,3.73
				c1.12,1.01,4.81,0.14,6.75-0.92c7.07-3.83,14.06-6.92,22.54-6.07c4.89,0.49,9.96-0.88,14.96-1.29c1.98-0.17,4.08-0.42,5.97,0.04
				c8.73,2.12,17.45,2.75,26.49,2.18c5.24-0.33,10.63,1.76,15.96,2.76"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M859.5,295.5c2,0.33,3.99,0.66,5.99,1c1.83,0.32,3.84,0.29,5.46,1.06c6.78,3.24,14.31,5.07,19.25,11.75
				c1.1,1.48,5.38,0.6,9.35,0.89c-5.25-8.25-5.04-18.76-14.64-22.58c-7.75-3.08-15.96-5-23.9-7.65c-12.18-4.06-24.28-8.37-36.47-12.4
				c-1.65-0.55-3.64-0.08-5.47-0.08"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M672.33,479.5c-17.24-0.42-34.44-0.47-51.44,3.39c-4.66,1.06-9.62,0.96-14.45,1.06c-5.35,0.11-8.28,3.28-10.47,7.56
				c-3.06,5.99-5.14,12.2-6.28,18.95c-0.58,3.43-4.2,6.29-6.17,9.57c-2.05,3.41-5.4,2.87-8.5,3.6c-2.64,0.62-6.96-0.66-7.02,4.37"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M837.04,162c12.73-0.45,24.51,2.28,34.98,9.94c2.18,1.6,4.9,2.5,7.46,3.52c1.41,0.56,3.04,0.55,4.47,1.07
				c5.55,2.04,11.04,4.02,16.91,5.28c4.33,0.93,8.68,4.06,11.93,7.31c9.48,9.5,17.92,20.09,27.73,29.21
				c8.05,7.49,17.53,13.44,26.26,20.22c1.69,1.31,2.87,3.28,4.28,4.95"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M971.31,52.5c-14.06-2.24-25.08,5.56-36.26,11.82c-3.34,1.87-4.55,7.57-6.74,11.51c0.28,0.54,0.55,1.07,0.83,1.61
				c-1.92-0.65-4.09-0.92-5.7-2.01c-4.55-3.08-9.48-5.41-13.89-8.97c-3.91-3.16-10.96-3.32-16.6-3.22c-5.85,0.1-11.61,0.1-15.88-3.84
				c-10.37-9.56-21.94-11.92-35.59-9.17c-5.66,1.14-11.92-0.73-17.91-1.23"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M218.62,826c-3.33,0.67-6.65,1.38-9.99,1.98c-2.69,0.48-5.41,0.83-8.11,1.24c-0.07,0.66-0.13,1.32-0.2,1.99
				c3.27,0.76,6.53,2.14,9.81,2.19c15.97,0.23,31.94,0.19,47.91,0.06c5.66-0.05,11.31-0.62,16.97-0.95"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M411.28,243c-12.61,1.06-24.26,5.51-35.98,9.89c-2.88,1.08-6.31,1.24-9.44,1.03c-14.21-0.92-27.88,0.83-41.51,5.34
				c-8.05,2.66-16.89,2.91-25.38,4.24"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M269.03,1071.5c-3.99,1.17-7.94,2.51-11.99,3.47c-14.46,3.42-29.06,6.26-43.37,10.2c-8.08,2.22-15.69,6.16-23.51,9.33"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M423.26,275c-2.16-1-4.49-1.76-6.45-3.05c-3.78-2.49-7.22-5.51-11.1-7.8c-1.09-0.64-3.24,0.52-4.9,0.85c1,1,1.95,2.06,3,2.99
				c4.34,3.82,3.22,9.2,3.94,14.02c0.42,2.8,0.61,5.67,0.45,8.49c-0.08,1.37-1.21,2.68-1.99,4.28c1.33,1.01,2.74,2.08,4.6,3.5
				c-1.5,3.07-3.01,6.15-4.69,9.6c-6.06-4.61-11.61-7.32-18.29-7.49c-2.02-0.05-3.95-2.05-6.01-2.23c-1.93-0.18-3.98,0.85-5.98,1.35"
				/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M744.2,1007.5c12.61,0.62,21.9-6.13,29.79-14.65c4.38-4.73,3.13-11.1,0.66-16.85c-4.32-10.07-10.93-17.53-21.95-20.56
				c-7.25-1.99-14.18-5.44-21.52-6.71c-7.81-1.35-15.97-0.55-23.91-1.35c-5.39-0.54-10.61-2.57-16-3.27
				c-4.12-0.53-5.77-3.62-7.9-6.18c-1.95-2.35-3.29-5.22-5.13-8.24c2.55-3.2-1.27-3.57-3.38-5.27c-7.58-6.11-15.67-2.91-23.49-1.31
				c-6.94,1.42-13.87,3.36-20.43,6.01c-7.32,2.96-14.14,0.29-20.99-0.79c-12.4-1.95-24.65-4.84-36.96-7.34"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M559.03,921.5c-7.39-0.86-11.36,4.11-13.81,9.58c-4.39,9.81-11.4,15.8-21.61,18.49c-1.45,0.38-2.85,1.42-3.97,2.48
				c-1.91,1.82-1.67,4.12,0.97,4.34c9.79,0.8,19.62,1.12,29.44,1.62"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M267.03,322c-6.32-0.67-12.65-1.26-18.96-2.02c-6-0.73-11.96-1.96-17.98-2.38c-4.79-0.33-9.65,0.49-14.47,0.34
				c-8.84-0.28-17.37,0.81-25.46,4.56"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M920.9,487c-2.33,6.17-4.82,12.28-6.95,18.51c-2.55,7.5-9.05,11.24-13.98,16.47c-0.6-0.27-1.19-0.54-1.79-0.81
				c0.58-2.22,1.24-4.43,1.73-6.67c0.43-1.98,0.71-3.99,1.02-6c0.51-3.33,0.88-6.69,1.54-9.99c0.67-3.37,2.01-6.63,2.38-10.02
				c0.65-5.88-3.28-9.21-8.87-7.41c-4.95,1.6-9.69,3.89-14.48,5.96c-0.32,0.14-0.35,0.95-0.52,1.45"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M865.49,563c-5.37-4.07-8.19,2.38-12.43,3.23c-5.02,1.01-9.74,3.67-14.5,5.81c-2.44,1.1-4.58,3.49-7.03,3.78
				c-5.09,0.6-10.29,0.21-15.45,0.16c-1.17-0.01-2.66-0.86-3.44-0.39c-7.42,4.49-16.43,2.2-24.05,5.83c-1.56,0.74-3.63,0.35-5.45,0.6
				c-3.33,0.45-6.66,0.98-9.98,1.49c-0.84,0.13-1.8,0.69-2.47,0.44c-5.06-1.92-9.31-0.11-13.53,2.5c-1.11,0.69-2.96,1.4-3.91,0.95
				c-8.08-3.9-16.01,0.52-24.02,0.35c-7.3-0.15-14.66,1.64-21.94,2.83c-2.08,0.34-4.01,1.59-6,2.42"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M442.73,464.5c0.5-2.33,0.81-4.73,1.54-6.99c1.74-5.41,0.09-8.29-5.53-8.99c-3.27-0.41-3.65-2.3-1.42-3.92
				c4.58-3.33,9.52-6.19,14.42-9.07c4.93-2.9,9.81-5.64,12.09-11.48c0.71-1.81,3.05-2.91,4.31-4.59c0.96-1.28,1.39-2.96,2.05-4.46
				c-1.66-0.5-3.34-1.44-4.99-1.39c-2.17,0.06-4.4,0.71-6.46,1.47c-1,0.37-2.55,1.85-2.39,2.38c1.84,6.04-2.88,3.87-5.13,3.95
				c-4.48,0.15-9.04-0.75-13.45-0.22c-2.78,0.34-5.24,2.81-8.04,3.73c-3.55,1.17-7.36,1.54-10.94,2.62
				c-12.39,3.75-20.15,11.78-21.4,24.97c-0.94,9.84-3.75,18.53-9.86,26.64c-5.75,7.64-9.85,16.52-14.67,24.85"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M796.11,523.5c-1.33,1.5-2.39,3.83-4.04,4.36c-9.56,3.11-16.21,10.4-23.89,16.17c-10.92,8.2-21.44,16.94-32.56,24.84
				c-6.09,4.33-13.09,7.36-20.88,7.66c-0.68,0.03-1.33,0.63-2,0.96"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M314.95,87.5c-1.33-1.17-2.51-3.06-4.02-3.39c-12.6-2.7-25.19-5.63-37.93-7.47c-12.1-1.75-22.99,2.53-32.92,9.35"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M666.84,501c-2.66,0.17-5.33,0.5-7.99,0.47c-9.82-0.09-19.63-0.31-29.45-0.46c-2.83-0.04-6.05-0.9-8.42,0.15
				c-9.41,4.16-19.14,4.07-29.02,3.34"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M733.72,159.5c-1.16,0.33-2.33,0.95-3.49,0.96c-12.98,0.07-25.96-0.04-38.93,0.09c-4.16,0.04-8.31,0.8-12.48,0.91
				c-6.32,0.16-12.65,0.23-18.96-0.01c-6.1-0.23-7.81,1.62-8.1,7.55c-0.38,7.86-1.55,15.67-2.38,23.51"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M555.53,1248c-0.17,0.5-0.18,1.2-0.52,1.47c-11.41,8.76-20.21,21.08-34.45,25.92c-4.08,1.39-8.82,2.61-12.9,1.9
				c-13.41-2.33-26.69-1.32-39.94,0.44c-2.54,0.34-4.69,3.61-7.02,5.52"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M519.1,752.5c-10.17,1.36-20.34,2.72-31.03,4.14c1.23,1.43,2.05,2.38,2.6,3.02c-14.39,5.5-29.16,9.23-44.95,8.01
				c-5.75-0.45-11.78-1.43-17.24,3.52c-2.28,2.06-7.7,0.65-11.69,0.82"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M765.17,197.75c4.16,1.25,8.67,1.85,12.42,3.87c14.36,7.71,29.76,11.94,45.4,16.18c17.48,4.73,34.37,11.65,51.46,17.78
				c2.19,0.79,4.02,2.59,6.02,3.93"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M185.18,672c0.67-1.17,1.19-2.45,2.03-3.47c1.2-1.45,2.43-3.24,4.03-3.82c0.91-0.33,2.6,1.43,3.91,2.3
				c0.8,0.53,1.55,1.15,2.54,1.9c1.57-1.42,2.96-2.67,4.38-3.95c5.82,3.41,8.39,2.8,12.6-2.42c1.31-1.62,3.25-2.74,4.94-4.03
				c0.95-0.73,2.88-1.4,2.84-2c-0.29-4.6,3.5-5.64,5.95-8.18c4.15-4.31,7.11-3.51,10.7,0.14c0.91,0.92,2.26,1.44,3.46,2.02
				c2.39,1.15,4.83,2.21,8.34,3.8c-0.11,0.62-0.08-0.12,0.15-0.78c2.06-5.9,2.84-6.18,8.48-3.53c1.83,0.86,3.74,3.37,5.65-0.39
				c0.51-0.99,3.11-0.92,4.1,0.01c2.58,1.84,5.16,3.69,8.02,5.74c1.74-2.11,3.02-3.66,4.95-5.99c2.85,7.5,9.97,5.63,14.52,8.45
				c5.13,3.19,9.14,1.1,13.66,0.18c1.02-0.21,2-0.65,3.42-1.12c2.64,6.3,8.97,3.26,13.6,5.02c3.75,1.43,7.54,1.86,12.02,1.09
				c4.08-0.7,9.46,1.8,10.51,7.44c4.47-2.64,10.15,0.05,12.54-3.15c4.3,3.58,8.3,6.9,12.3,10.23"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M640.88,255.5c-6.32,3-12.79,5.74-18.91,9.11c-3.11,1.71-5.7,3.59-9.58,2.52c-1.62-0.45-3.62,0.61-5.46,0.85
				c-2.99,0.4-5.99,0.67-8.98,1.02c-1.17,0.14-2.41,0.13-3.47,0.55c-0.95,0.38-1.71,1.24-2.52,1.94c-7.88,6.9-17.31,8.77-27.45,9.14
				c-4.36,0.16-8.75,1.56-12.96,2.93c-5.75,1.88-11.77,3.55-16.85,6.66c-3.76,2.3-7.53,3.72-11.62,4.77"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M264.54,327.5c-5.66-0.67-11.32-1.29-16.97-2.01c-6.16-0.78-12.29-1.93-18.48-2.39c-4.29-0.32-8.64,0.34-12.97,0.38
				c-8.32,0.07-16.59,0.36-24.46,3.52"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M728.23,1215c-13.3,10.03-28.16,11.64-43.92,7.98c-9.67-2.24-19.3-4.65-28.95-6.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M229.6,466c2.83,1,5.73,1.83,8.46,3.05c2.92,1.3,5.64,3.04,8.51,4.44c19.26,9.41,39.87,16,57.95,27.92
				c2.05,1.35,4.93,1.43,7.43,2.09"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M312.46,503.5c10.18,6.98,21.16,12.32,32.94,16.01c3.01,0.94,5.99,1.99,8.99,2.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M258.55,814c-2.22-3.17-5.34-3.76-8.99-3.6c-1.64,0.07-4.38-0.45-4.81-1.48c-2.4-5.65-7.21-4.06-11.15-3.81
				c-10.67,0.68-21.32,1.74-31.94,2.99c-2.74,0.32-5.32,1.94-8,2.88c-1.29,0.45-2.65,0.94-3.99,0.98
				c-17.63,0.56-35.27,1.03-52.91,1.54c-11.31,0.32-22.82-0.49-33.89,1.32c-11.04,1.81-21.99,1.64-32.99,2.34
				c-7.71,0.49-15.27,2.97-22.98,3.71c-7.93,0.75-16,0-23.93,0.77c-6.89,0.66-13.66,2.49-20.46,3.94c-0.45,0.1-0.68,1.26-1.01,1.93"
				/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M956.33,675.5c-2.33-3.67-4.6-7.37-7.06-10.95c-0.34-0.5-1.59-0.44-2.43-0.53c-0.82-0.08-1.91,0.33-2.46-0.07
				c-8.73-6.29-12.74-6-20.51,1.54c-2.21,2.15-4,5.09-8.02,3.63c-0.87-0.32-2.87,0.56-3.35,1.44c-2.42,4.39-9.62,2.96-10.64,8.94
				c-0.07,0.41-0.97,1.01-1.44,0.96c-5.69-0.57-7.61,3.73-10.01,7.54c-1.6,2.53-3.41,4.93-5.37,7.73c-0.53-1.07-1.07-2.14-1.66-3.33
				c-4.89,0.17-10.29-0.96-12.67,5.77c-1.3-1.61-2.19-2.7-2.63-3.24c-2.71,0.6-5.11,1.89-7.05,1.38c-2.29-0.6-4.18-2.75-6.86-4.67
				c-0.71,2.07-0.93,3.65-1.75,4.83c-0.84,1.22-2.23,2.08-3.43,3.04c-2.31,1.86-5.83,3.25-6.67,5.62c-0.95,2.67-3.57,4.18-4.01,5.96
				c-1.74,6.96-10.26,7.14-12.36,13.4c-0.14,0.4-1.57,0.36-2.4,0.53"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M970.81,418.5c-5.82-1-11.64-2.72-17.47-2.75c-4.07-0.02-6.81,3.49-8.01,7.75c-4.43,15.69-8.92,31.35-13.47,47
				c-1.81,6.24-3.82,12.44-10.47,15.99c-1.38-0.68-3.18-1.57-5.58-2.75c2.53-3.81,5.01-7.55,8.28-12.48c-6.48,0-12.32,0-18.17,0
				c5.48-8.02,13.01-3.42,19.47-4.75c0.67-0.67,1.33-1.33,2-2c-1.33-0.33-2.93-0.3-3.95-1.06c-4.4-3.26-4.26-4.6,0.45-7.94
				c1.17-0.83,2.31-1.69,4.7-3.43c-2.46-1.22-4.31-2.89-6.2-2.93c-4.82-0.1-9.65,0.79-14.47,0.78c-5.32-0.01-10.99,0.43-15.88-1.21
				c-4.93-1.66-9.12-0.04-13.56,0.39c-5.86,0.57-11.68,1.77-17.47,2.92c-6.97,1.38-11.41,5.2-13.68,12.41
				c-1.84,5.81-5.54,11.02-8.26,16.57c-3.74,7.63-7.25,15.37-11.03,22.98c-2.85,5.74-6.06,11.3-8.91,17.04
				c-1.11,2.25-1.67,4.78-2.48,7.18c0.45,0.58,0.89,1.15,1.34,1.73c12.19-3.48,24.39-6.96,37.14-10.6
				c-0.93,6.25-6.91,12.17-1.11,18.42"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M838.04,305.5c-3.99-1.83-7.97-3.71-11.98-5.49c-6.3-2.79-12.41-4.22-19.1-0.24c-2.14,1.27-5.87,0.03-8.85-0.3
				c-6.5-0.74-12.98-1.64-19.47-2.47c-0.33-0.04-0.71-0.11-0.99,0.01c-9.92,4.25-20.02-1.44-29.95,0.53
				c-3.71,0.74-7.33,1.96-10.99,2.96"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M771.66,206.5c-3.83,0.5-7.64,1.25-11.48,1.45c-9.31,0.49-18.63,0.73-27.95,1.04c-2.33,0.08-4.66-0.03-6.99,0.02
				c-17.3,0.32-34.61,0.67-51.91,0.98c-3.99,0.07-7.99,0.01-11.98,0.01c-1.33,0-2.71-0.22-3.99,0.04c-6.5,1.32-7.59,3.36-8.07,12.46
				c-0.25,4.77-1.26,9.5-1.92,14.25"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M912.91,338.5c-1.16-0.17-2.47-0.76-3.47-0.43c-6.04,2.02-11.9,4.64-18.02,6.32c-6.02,1.66-12.25,2.65-18.45,3.53
				c-3.94,0.56-7.98,0.3-11.97,0.6c-5.87,0.45-11.05-0.25-15.06-5.46c-3.44-4.47-7.59-8.38-11.39-12.57
				c-5.63-6.21-13.42-7.78-20.96-10.03c-19.16-5.71-38.41-3.71-57.34,0.34c-7.4,1.58-14.44,6.08-20.92,10.35
				c-7.22,4.75-14.52,7.31-23.07,5.83c-10.48-1.82-20.97-3.62-31.43-5.56c-0.92-0.17-1.68-1.27-2.51-1.93"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M16.97,1264c1.83-1.33,4.33-2.28,5.36-4.08c2.57-4.48,6.63-6.35,11.1-7.95c3.37-1.2,6.56-3.13,10.02-3.84
				c3.71-0.76,7.73-1.09,11.43-0.5c6.88,1.11,12.88,5.66,20.52,4.6c2.99-0.42,6.34,1.77,9.53,2.77c-0.11,0.66-0.22,1.33-0.32,1.99
				c-3.58,0-7.19,0.32-10.73-0.06c-12.92-1.37-25.33,0.56-37.45,5.03c-4.44,1.64-8.93,3.18-13.49,4.46
				c-2.53,0.71-5.51,1.78-6.97-1.94"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M513.11,289.5c-6.16-0.67-12.3-1.66-18.47-1.93c-10.3-0.44-20.63-0.37-30.94-0.58c-9.5-0.19-17.98,3.63-26.38,7.19
				c-4.24,1.79-7.69,5.45-12.3,8.86c2.09,1.53,3.41,2.5,4.73,3.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M417.27,773c1.16,4.67,2.38,9.32,3.47,14.01c0.34,1.48,0.35,3.03,0.64,5.67c-1.03,0.41-2.77,1.33-4.61,1.78
				c-3.45,0.83-6.96,1.55-10.48,1.93c-0.92,0.1-2.26-1.01-2.93-1.92c-1.83-2.5-0.92-7.24-6.05-7.13c-0.31,0.01-0.42-2.62-1.04-3.8
				c-2-3.86-6.35-6.25-5.98-11.64c-0.46,0.09-0.92,0.17-1.38,0.26c-1.51,1.8-3.02,3.59-4.85,5.77c-1.2-2.06-2.3-3.94-3.93-6.74
				c-2.24,5.55-4.31,10.67-6.69,16.57c-1.83-2.23-3.46-4.23-5.82-7.11c-2.94,4.02-5.73,7.84-8.43,11.53
				c2.55,4.53,2.55,4.53,7.07,3.04c0.67,3.1,1.32,6.1,2.13,9.79c-3.18,0-5.35,0-7.51,0c-15.31,0-30.62-0.18-45.92,0.09
				c-5.83,0.1-11.64,1.62-17.47,1.81c-7.14,0.24-14.31-0.19-21.46-0.47c-0.85-0.03-2.08-0.74-2.43-1.47
				c-2.75-5.76-7.14-8.48-13.55-8.01c-1.48,0.11-2.99-0.29-4.49-0.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M616.43,1231.5c2.99,1.5,6.49,2.44,8.89,4.61c6.21,5.61,5.21,11.53-2.97,15.23c-5.26,2.38-11.16,3.87-16.91,4.5
				c-8.75,0.96-17.62,0.96-26.44,1.13c-7.61,0.14-9.38-2.64-5.92-9.43c1.62-3.19,3.93-6.03,5.93-9.03"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M719.75,395c4.99,0,9.99-0.13,14.97,0.03c5.99,0.19,11.98,0.73,17.97,0.94c7.65,0.27,15.32,0.1,22.95,0.62
				c4.86,0.33,9.7,2.46,14.47,2.2c7.69-0.41,11.43,3.76,14.56,9.66c0.49,0.93,1.93,1.37,2.92,2.04"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M770.16,162c2.82-0.55,5.64-1.11,9.45-1.86c-1.66-1.11-2.71-2.12-3.98-2.61c-17.12-6.56-34.25-13.07-51.42-19.47
				c-1.32-0.49-2.97-0.06-4.47-0.06"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1.5,65.5c7.71-5.73,16.86-7.62,25.97-8.31c12.09-0.92,24.28-0.5,36.42-0.71c1.7-0.03,3.4-0.38,6.23-0.72
				C61.16,44.95,50.5,41.1,38.45,42.2c-8.68,0.8-16.79-0.04-25.01-2.6C9.57,38.4,5.2,36.67,1.5,40.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M27.95,996.5c8.65-1,17.32-1.91,25.95-3.05c3.19-0.42,7.78-6.98,8.07-9.95c0.83-8.57-4.27-14.11-9.64-19.42
				c-1.43-1.41-3.85-2.23-5.92-2.5c-6.79-0.9-13.63-1.49-20.46-2.05c-2.64-0.22-5.34,0.18-7.98-0.08
				c-5.17-0.51-10.32-1.29-15.48-1.95"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M559.53,923c-2.44,3.21-8.02,4.07-7.42,9.49c0.26,2.38,1,4.8,2,6.97c0.99,2.16,2.81,3.92,3.86,6.06c1.25,2.54,3.45,5.8,2.73,7.86
				c-1.11,3.16-4.85,3.24-8.19,2.21c-0.67-0.21-1.7,0.78-2.84,1.35c3.15,5.04,8.34,4.87,12.86,5.48c16.96,2.28,33.8,5.29,49.84,11.25
				c8.67,3.22,16.72,8.13,24.99,12.39c0.73,0.38,1.03,1.61,1.53,2.44"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M472.68,781.5c-3.25,1.48-6.49,2.96-9.74,4.43c-0.02,0.5-0.04,1-0.07,1.5c8.06,2.49,16.11,4.99,25.47,7.88
				c-6.77,4.35-12.73,5.12-18.66,5.1c-10.65-0.04-21.29-0.7-31.95-0.85c-3.19-0.04-6.38,0.76-9.9,2.42
				c3.22,2.57,6.43,5.13,10.84,8.66c-7.38,0.92-13.47,1.67-19.55,2.42c-0.08,0.43-0.15,0.86-0.23,1.29c1.13,0.7,2.27,1.4,4.24,2.62
				c-5.3,1.6-9.33,2.81-13.36,4.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M311.46,503c-2-3.83-3.89-7.73-6.02-11.48c-2.72-4.8-7.77-6.21-12.46-7.99c-7.57-2.86-7.53-2.81-5.59-11.05
				c0.27-1.15-0.51-3.37-1.45-3.86c-8.05-4.2-16.24-8.13-24.42-12.07c-0.3-0.14-0.92,0.4-1.93,0.86c1.96,2.51,3.96,5.05,6.15,7.86
				c-8.12,2.6-15.98,5.74-24.69,3.29c-0.53-0.15-1.31,0.6-1.98,0.94"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M132.27,668.5c-3.33-5.58-8.85-8.03-14.99-7.09c-1.74,0.27-3.63-0.68-5.48-0.88c-2.48-0.27-5.08-0.87-7.47-0.43
				c-6.81,1.25-8.5,1.43-15.99-0.09c-0.32-0.06-0.7-0.1-0.99,0.01c-6.88,2.58-12.57,0.57-17.23-5.46c-3.24,4.27-5.84,3.54-9.34,1.11
				c-3.67-2.54-8.11-4.17-12.42-5.47c-1.52-0.46-3.68,1.22-6.15,2.15c-2.38-1.78-5.47-3.64-7.13,0.69c-3.15-0.63-5.78-1.15-8.4-1.67
				c-0.19,0.44-0.37,0.88-0.56,1.32c-0.89-1.72-1.78-3.45-2.76-5.35c-6.63,1.91-13.38,3.85-19.41-1.78C3.6,645.21,2.66,645.5,2,645.5
				"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M258.55,815c-4.66,0.5-9.31,1.18-13.98,1.47c-14.97,0.92-29.95,1.59-44.91,2.64c-2.55,0.18-5,1.73-7.49,2.65"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M129.27,1157.5c6.66-2.17,13.46-3.96,19.93-6.6c7.89-3.22,9.87-6.5,8.52-11.9c-1.52-6.1-11.65-12.13-17.98-11.68
				c-3.96,0.29-8.08,0.93-12.15-2.08c-3.06-2.26-7.78-3-11.8-3.11c-13.3-0.36-26.62-0.14-39.93-0.12c-0.5,0-1,0.31-1.5,0.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M766.66,161.5c5.82,2.5,11.73,4.82,17.45,7.53c16.68,7.91,33.28,15.99,49.96,23.91c0.95,0.45,2.3,0.06,3.47,0.06"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M618.42,1083c4.59,3.1,2.6,8.12,2.76,12.01c0.59,14.31,0.57,28.67,0.05,42.98c-0.18,4.88-2.48,9.67-3.81,14.51"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M971.31,60.5c-13.13-1.92-22.67,6.04-33.2,11.28c0.06,0.7,0.11,1.4,0.17,2.1c1.69,0.04,3.86,0.78,5.01,0
				c8.71-5.91,18.18-6.22,28.03-4.39"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M809.59,256.5c-5.99-0.17-11.98-0.36-17.97-0.49c-3.16-0.07-6.33-0.14-9.48,0.01c-14.81,0.68-29.49,4.04-44.43,2.04
				c-1.94-0.26-3.99,0.27-5.98,0.44"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M770.16,735.5c1.16,4.5,2.05,9.1,3.59,13.46c1,2.82-1.35,6.49,2.34,8.63c0.56,0.32,0.78,2.04,0.49,2.88
				c-1.23,3.52,0.21,6.21,2.01,9.06c1.97,3.12,0.4,6.3-2.86,5.23c-8.15-2.67-15.23,3.21-23.05,1.8c-2.67-0.48-5.33,0.35-7.69-2.38
				c-1.08-1.25-4.45-0.64-6.78-0.65c-2-0.01-4,0.47-5.99,0.41c-1.51-0.05-3-0.61-4.49-0.94c0.67-1.33,1.59-2.6,1.95-4.01
				c1.05-4.15,4.38-7.76,3.05-12.49c-0.04-0.15-0.06-0.36,0-0.49c2.5-5.57,5.01-11.14,7.96-17.67c1.97,4.19,3.49,7.43,5.01,10.67"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M618.92,60.5c5.49,0.83,10.96,2.16,16.48,2.4c13.96,0.62,27.95,0.83,41.92,1.04c2.82,0.04,5.66-0.61,8.48-0.95"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M748.2,546c-2.99,1.17-6.08,2.14-8.96,3.54c-5.56,2.69-11.08,5.48-16.47,8.5c-6.58,3.68-13.09,7.49-19.46,11.5
				c-6.33,3.98-12.72,7.31-20.48,7.61c-3.03,0.12-6,1.86-8.99,2.86"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M233.09,853.5c0.17,2.5-0.05,5.11,0.61,7.47c0.69,2.47,1.99,4.87,3.51,6.94c0.55,0.75,2.78,0.93,3.79,0.44
				c0.86-0.42,1.38-2.14,1.52-3.35c0.48-4.32,1.15-8.69,0.86-12.98c-0.13-1.94-0.55-5.04-4.3-5.11c-4.12-0.08-6.05,1.52-5.9,6.08
				c-4.39,0.34-8.72,0.74-13.07,1c-17.8,1.03-35.6,2.05-53.41,2.99c-5.98,0.31-12,0.74-17.96,0.43c-8.63-0.44-14.93,3.86-20.97,9.09"
				/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M365.36,430.5c-2.33,1.67-4.8,3.17-6.94,5.05c-1.57,1.39-3.5,3.13-3.84,4.98c-0.44,2.38,0.54,4.57,3.75,5.61
				c4.18,1.36,3.71,5.46-0.96,9.33c-5.54,4.6-11.19,9.06-17.13,13.86c-4.3-2.02-9.23-4.34-13.42-6.3c0.81-2.02,1.29-4.62,2.74-6.44
				c1.2-1.51,3.49-2.87,5.37-2.96c5.18-0.25,9.19-2.37,12.44-6.17c0.86-1,1.36-2.31,2.03-3.48c-1.33-0.5-2.66-1.42-3.99-1.43
				c-5.23-0.04-8.94-2.18-11.12-6.86c0.22-0.49,0.44-0.97,0.67-1.46c2.99,1.41,5.98,2.82,8.24,3.88c5.86-2.86,11.2-5.66,16.73-8.04
				c1.54-0.67,3.62-0.1,5.45-0.1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M775.65,919.5c5.13,2.46,9.72-0.44,14.47-1.53c4.36-1,8.67-2.26,12.98-3.47c8.33-2.33,16.05,1.16,23.95,2.58
				c13.36,2.4,26.53,6.3,39.97,7.65c15.52,1.57,31.27,5.18,46.85,1.12c8.21-2.14,16.09-5.65,23.97-8.91
				c7.43-3.07,14.55-6.9,22.04-9.79c2.81-1.09,6.28-0.38,9.42-0.73c0.65-0.07,1.17-1.25,1.76-1.91"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M867.49,1010c-3.16,0-6.61,0.83-9.44-0.14c-16.64-5.71-33.85-5.2-50.95-4.65c-7.53,0.24-14.92,3.3-22.48,4.17
				c-13.6,1.57-27.26,2.58-40.92,3.49c-2.56,0.17-4.44,0.16-6.05,2.57c-2.74,4.1-6.19,5.93-11.46,3.65
				c-7.48-3.24-15.26-5.77-22.92-8.6"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M616.93,415.5c-2.59-4.26,0.58-7.76,2.2-10.9c2.58-5,1.91-8.81-1.09-13.18c-1.79-2.61-3.63-2.73-6.09-1.91
				c-0.7,0.23-1.36,0.98-2,0.94c-10.52-0.78-21.01-0.74-31.45,1.05"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M730.73,763c-8.65-1.5-17.27-3.28-25.96-4.43c-11.11-1.46-22.29-2.37-33.43-3.62c-1.04-0.12-2-0.95-3-1.45"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M355.38,55.5c7.48,1.51,14.4,0.33,20.42-4.55c0.79-0.64,1.67-2.22,1.38-2.89c-0.39-0.92-1.87-2.01-2.84-1.97
				c-7.33,0.32-14.67,0.67-21.95,1.57c-1.8,0.22-3.35,2.5-5.01,3.83c1.83,1.17,3.64,2.38,5.51,3.47c0.57,0.33,1.32,0.36,1.98,0.53"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M302.47,377c-8.14-0.23-13.06,4.26-13.83,14.01c-0.53,6.62-4.57,9.49-9.94,12.69c-0.85-7.28-5.97-5.94-10.16-5.62
				c-7.21,0.54-7.66,0.25-6.53-6.58c0.22-1.3,0.03-2.66,0.03-3.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M2,788.5c3.14-2.03,6.29-4.05,9.33-6.01c9.59,2.66,15.32,9.55,20.3,17.34c3.16-2.82,6.51-5.83,10.38-9.29
				c1.87,3.79,2.86,7.35,5.08,9.8c1.86,2.06,5.09,3.77,7.84,3.97c7.42,0.53,12.47,5,12.47,12.18"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M6.49,322c0.13,2.85,5.36,6-1.23,8.31c9.27,6.67,17.93,12.99,26.69,19.17c9.77,6.9,19.41,14.03,29.52,20.39
				c5.97,3.75,13.1,5.59,18.39,10.63"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M797.61,287c-6.82-0.33-13.64-0.83-20.47-0.91c-2.49-0.03-4.99,0.89-7.48,1.42c-1.34,0.29-2.88,1.28-3.96,0.89
				c-9.48-3.35-18.93-2.37-28.51-0.54c-2.06,0.39-4.45-0.97-7.3-1.68c1.97-3.14,3.96-5.24,4.73-7.72c2.3-7.4-1.52-13.86-4.05-20.41
				c-0.36-0.94-2.83-1.45-4.34-1.5c-9.48-0.29-18.98-0.77-28.44-0.39c-4.2,0.16-8.55,3.58-12.42,2.95c-4.34-0.7-8.18-0.81-12.57-0.49
				c-5.93,0.43-10.58,3.55-15.88,5.11c-2.73,0.8-4.73,4.09-7.06,6.26"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M842.53,613c-2.59-2.49-3.71-7.29-9.01-5.59c-0.61,0.2-1.76-0.77-2.45-1.43c-4.69-4.46-8.56-4.63-14.56-2.68
				c-3.81,1.24-8.02,1.3-12.47,0.58c-5.06-0.81-11.04,0.26-15.8,2.37c-6.5,2.88-13.13,1.51-18.42-0.23
				c-8.2-2.7-16.53-2.14-24.65-3.88c-1.97-0.42-5.51,1.99-6.26,1.18c-5.02-5.44-14.15,0.64-18.22-6.79
				c-0.22-0.41-1.27-0.42-1.94-0.52c-8.82-1.35-17.64-3.74-26.45-3.66c-6.78,0.06-12.59-3.8-18.95-3.16
				c-7.35,0.73-14.13-3.32-20.44-2.95c-6.51,0.38-10.1-3.11-15.02-4.73"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M811.09,833.5c-1.66,5.33-3.73,10.58-4.88,16.02c-1.13,5.39,1.36,9.31,6.34,12.04c10.73,5.88,19.67,14.06,27.94,22.98
				c1.29,1.39,2.04,3.3,3.04,4.96"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M276.02,455c2.99-8.17,5.92-16.36,9.02-24.49c1.38-3.61-0.57-6.75-3.08-7.18c-2.75-0.47-6.69,0.89-8.73,2.87
				c-2.35,2.29-2.99,6.25-4.78,9.24c-0.79,1.32-2.57,2.05-3.9,3.05c-0.33-1.5-1.02-3.02-0.93-4.5c0.25-3.91-4.03-7.98-8.52-6.88
				c-6.78,1.67-13.38,4.09-19.96,6.47c-1.26,0.46-2.03,2.23-3.03,3.4"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M124.78,828.5c3.49-0.17,7.05,0.03,10.47-0.58c5.17-0.92,6.01,3.58,8.53,6.06c1.51,1.49,3.13,3.04,4.99,3.97
				c12.36,6.22,25.57,2.44,38.41,3c4.19,0.18,8.19-0.51,10.62,4.48c1.56,3.21,4.51,5.74,6.84,8.57"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M681.31,62c-15.63-11.1-45.98-11.85-60.89-1.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M771.16,945c1.33,1.5,2.45,3.29,4.03,4.45c8.21,6.06,16.81,11.66,22.74,20.78c-1.24,0.87-2.38,1.67-3.94,2.77
				c5.02,6.61,12.59,7.39,19.14,9.9c2.58,0.99,5.62,0.77,8.44,1.11"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M589.47,508.5c-16.02-0.92-32.08-3.7-47.93,1.45c-1.71,0.55-3.72,0.12-5.46,0.61c-2.73,0.77-6.68,1.18-7.76,3.08
				c-3.79,6.64-6.56,13.86-9.73,20.86"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M743.21,490c4.5-8.3,13.14-7.33,20.49-8.87c4.65-0.98,9.67,3.04,11.47,7.87c0.86,2.3,1.93,4.51,3.22,7.46
				c6.01-4.76,7.04-10.9,7.49-16.97c0.09-1.2-3.1-3.82-4.73-3.78c-8.66,0.19-17.15-1.46-26-0.95c-9.88,0.57-16.79,5.63-22.46,10.76
				c-6.43,5.81-14.13,4.56-20.31,8.18c-7.29,4.26-15,4.74-22.53,3.55c-7.67-1.22-14.12,1.53-21.01,3.27"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M180.69,670.5c-1.33,0.33-3.25,1.4-3.9,0.88c-3.61-2.9-6.89-2.14-11.15-1.2c-4.85,1.07-10.38,0.25-15.36-0.86
				c-4.12-0.92-7.56-4.46-12.17-0.5c-1.52,1.31-4.84,0.46-7.33,0.69c-8.82,0.83-17.64,1.03-26.46,0c-0.49-0.06-1.25-0.22-1.46,0.02
				c-4.22,5.01-9.23,1.73-14.02,1.61c-2.67-0.06-5.37,1.32-8.17,2.09c-2.84-4.14-8.43-4.86-12.87-1.85
				c-1.14,0.77-3.26,0.24-4.91,0.09c-2.34-0.22-4.66-0.66-6.99-0.96c-1.66-0.21-3.44-0.03-4.97-0.58c-2.85-1.03-5.46-3.43-8.55-0.48
				c-0.35,0.33-1.59,0.33-1.91,0c-5.35-5.54-13.51-4.07-19.57-7.87c-0.76-0.48-2.31,0.13-3.43,0.43c-4,1.08-7.19,0.75-8.6-3.99
				c-0.22-0.73-1.97-0.91-2.87-1.56c-1.73-1.25-3.35-2.64-5.01-3.97"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M350.39,1241c-12.15-1.17-24.28-2.55-36.44-3.42c-7.96-0.57-15.97-0.48-23.95-0.51c-1.33,0-2.66,0.93-3.99,1.43"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M44.42,254c6.08,2.92,11.38-0.64,16.98-1.96c7.11-1.67,14.24-3.64,21.47-4.34c5.02-0.48,9.68,2.19,13.46,5.79"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M94.34,259.25c-8.34,3.13-16.67,6.26-25.99,9.76c2.17,1.53,2.92,2.5,3.52,2.41c11.72-1.62,23.13,3.86,34.99,1.26
				c3.9-0.85,8.27,0.62,12.44,0.79c18.47,0.72,36.95,1.14,55.4,2.12c9.26,0.49,18.31,0.23,27.49-1.39
				c5.97-1.06,12.54-1.34,18.38,0.03c13.76,3.23,27.34,0.46,40.98,0.23c4.66-0.08,9.32-0.63,13.98-0.96"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M517.1,536c-9.9-5.17-20.33-2.69-29.84-0.09c-8.59,2.35-17.1,1.77-25.57,3.09c-1.84,0.29-3.66,0.66-5.49,0.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M969.81,300.5c-4.16,1.67-8.65,2.8-12.41,5.12c-6.91,4.26-13.49,9.08-20.01,13.92c-6.57,4.88-11.29,12.02-19.41,15.18
				c-2.38,0.92-3.83,4.39-5.56,6.8c-4.98,6.96-11.87,11.1-20,12.92c-13.75,3.08-27.76,5.17-41.34,8.84
				c-5.6,1.52-10.89,5.48-15.39,9.41c-3.53,3.08-2.76,7.73-0.24,11.86c0.83,1.36,1.4,3.48,0.97,4.92c-2.04,6.8-2.06,14.75-9.84,18.61
				c-0.93,0.46-1.26,2.02-2.06,2.91c-1.41,1.57-2.97,3.02-4.47,4.52c-1.5-1.67-3.6-3.11-4.33-5.06c-0.85-2.24-1.27-5.21-0.52-7.39
				c3.19-9.37,4.72-18.18-4.05-25.64c-0.75-0.64-0.61-2.59-0.54-3.91c0.24-4.67,0.63-9.34,0.96-14"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M282.51,1238c2.33,1,4.63,2.07,6.99,2.99c13.38,5.21,26.6,11.12,41.46,5.11c4.07-1.64,8.63-2.02,12.92-3.16
				c0.94-0.25,1.68-1.27,2.51-1.94"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M248.32,894.25c-3.28-5.68-9.02-6.58-14.72-7.78c-9.68-2.04-19.34-4.16-28.94-6.54c-3.29-0.82-5.99-0.57-9.05,0.97
				c-4.12,2.08-8.72,3.18-12.89,5.17c-4.63,2.2-8.97,5.03-13.53,7.39c-2.38,1.23-4.93,2.15-7.47,3.02c-6.08,2.07-6.97,1.4-6.98-4.98
				c0-1,0-2,0-3"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1,386.5c5.82,4.33,11.4,9.07,17.52,12.92c12.13,7.62,24.55,14.79,36.91,22.04c1.46,0.85,3.3,1.05,4.96,1.55"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M253.56,749c-5.56,2.94-11.46,2.95-17.47,1.99c-0.67-0.11-1.51-0.67-1.97-0.44c-7.17,3.62-15.13,0.48-22.55,2.72
				c-2.35,0.71-6.27-0.21-8.26-1.12c-7.48-3.42-15.69-1.91-23.13-4.62c-2.42-0.88-5.16-2.31-7.44-1.87
				c-9.05,1.76-17.56-2.2-26.49-1.9c-4.45,0.15-9.25-1.51-13.39-3.44c-3.99-1.87-7.37-1.59-11.59-1.14
				c-6.92,0.75-14.16,3.12-21.1-1.96c-3.18-2.34-8.15-2.26-12.31-3.21c-2.48-0.57-4.99-1-7.48-1.52c-0.51-0.11-1.08-0.58-1.49-0.45
				c-6,1.96-11.98,3.98-17.94,5.99c-2.01-1.24-3.98-2.45-5.9-3.64c-3.13,2.1-6.12,4.11-9.11,6.11"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1,1239.5c1.33,0.5,2.79,1.63,3.97,1.4c9.42-1.85,17.1-7.34,25.28-11.78c-0.09-0.54-0.19-1.08-0.28-1.62
				c-2.01,0-4.59-0.81-5.92,0.14c-7.11,5.04-14.88,5.54-23.06,4.36"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M898.43,449.5c-1.53-4.36-0.72-7.96,2.93-11.08c1.4-1.2,2.21-3.19,3.01-4.95c0.47-1.03,0.39-2.31,0.55-3.47
				c-1.16,0.17-2.63-0.05-3.44,0.57c-2.32,1.79-4.12,4.39-6.6,5.83c-4.62,2.68-9.58,4.79-14.43,7.06c-0.55,0.26-1.32,0.09-1.98,0.01
				c-0.35-0.04-0.67-0.31-1.14-0.55c0.71-2.13,1.43-4.28,2.12-6.44c0.04-0.12-0.27-0.35-0.33-0.42c-0.48-0.24-0.98-0.64-1.12-0.54
				c-5.44,3.79-11.39,6-17.96,7.13c-3.16,0.54-5.99,2.9-9.02,4.32c-6.13,2.88-12.17,6.05-18.51,8.39c-4.31,1.59-8.46,3.08-10.88,7.16
				c-1.67,2.83-4,3.98-6.96,5.17c-3.18,1.28-5.74,4.13-8.57,6.3"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M632.4,1077.5c3.66,0.33,4.37,3.04,5.02,5.99c3.1,14.18,6.35,28.33,9.41,42.52c0.63,2.93,0.93,5.99,0.99,8.99
				c0.07,3.6,0.72,7.33,4.58,7.68c1.83,0.17,4.51-2.66,5.87-4.75c6.77-10.38,4.43-20.82-1.54-30.35
				c-6.17-9.84-8.86-20.82-12.32-31.59c-0.31-0.95-0.86-2.36-0.44-2.94c6.18-8.51,0.65-15.95-2.15-23.53
				c-1.39-3.77-1.36-6.24,2.47-8.68c2.67-1.7,4.42-4.85,6.59-7.35"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M888.95,562.5c2.69,3.65,11.5,6.63,15.49,5.58c1.71-0.45,3.77-0.51,5.44,0.01c4.64,1.43,9.79,0.33,14.14,3.74
				c2,1.56,5.63,0.84,8.31,1.78c2.49,0.87,4.66,2.63,7.03,3.89c5.12,2.73,6.37,2.1,8.04-3.47c0.44-1.45,1.41-3.56,2.49-3.79
				c1.29-0.28,3.01,1,4.41,1.82c0.66,0.39,1.24,1.94,1.49,1.86c6.47-2.16,10.28,3.65,15.5,5.1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M328.43,324c0.67-1.5,1.27-3.03,2.01-4.49c1.99-3.97,3.62-7.8,2.08-12.54c-1.72-5.3,0.28-12.2,4.08-13.39
				c5.64-1.77,9.68-4.3,13.42-8.47c0.83-0.93,3.18-0.5,5.49-0.78c-0.51,2.55-0.87,4.35-1.23,6.15c9.15,1.62,12.06-1.96,13.63-9.47
				c0.72-3.44,1.97-6.77,3.32-11.32c-2.19,0.45-4.53,0.43-6.3,1.42c-2.46,1.37-4.49,3.51-6.96,5.52c-2.2-4.11-0.23-6.24,2.41-8.12
				c0.79-0.56,2.06-0.82,2.41-1.55c0.9-1.89,1.41-3.96,2.08-5.96c-2-0.5-3.98-1.34-5.99-1.41c-2.15-0.07-4.32,0.81-6.49,0.85
				c-3.17,0.07-6.31-0.39-5.66,4.58c0.09,0.67-2.19,2.41-3.32,2.36c-10.72-0.5-17.32,6.27-24,13.08c-0.9,0.92-2.29,1.37-3.46,2.04"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M946.85,377c0.17-3.5,0.38-7,0.49-10.5c0.15-4.8-2.45-7.27-6.98-7.99c-3.22-0.51-6.01-0.89-6.19-5.5
				c-0.11-2.71-2.15-4.04-5.85-2.7c-6.07,2.2-12.58,3.18-18.89,4.71c-0.86,0.21-2.04,0.41-2.44,1.02c-4.1,6.28-11.04,6.05-17.05,6.76
				c-9.6,1.15-16.75,8.89-17.03,17.69c-0.05,1.52-1.41,2.95-1.87,4.52c-0.52,1.78-0.71,3.65-1.04,5.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M305.97,567c5.49,4.17,10.92,8.42,16.5,12.47c3.02,2.19,6.94,3.46,9.34,6.14c7.48,8.37,18.67,10.66,27.12,17.34
				c1.53,1.21,3.62,1.72,5.45,2.55"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M237.09,502c3.85,1.47,8.36,1.86,9.22,7.46c0.17,1.09,3.82,1.29,5.17,2.62c2.93,2.89,5.45,6.22,8.06,9.42
				c3.37,4.13,6.63,8.36,10.02,12.47c0.81,0.99,1.95,2.36,2.97,2.4c3.25,0.11,4.06,1.73,4.07,4.62c0.01,1.17,0.6,2.72,1.47,3.44
				c8.24,6.77,16.36,13.72,25.04,19.88c2.83,2.01,7.15,2.17,10.86,2.63c13.1,1.63,23.47,8.62,33.48,16.5
				c1.35,1.06,3.28,1.39,4.95,2.05"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M881.46,449.5c-1.16,3.17-1.63,6.9-3.62,9.4c-6.72,8.41-13.12,17.24-24.26,20.82c-2.79,0.89-4.47,4.81-7.12,6.7
				c-3.93,2.82-8.26,5.08-12.42,7.58"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M647.37,670c-0.67,1.67-1.61,3.28-1.94,5.01c-0.84,4.44-3.12,5.52-7.55,3.01c-0.73,2.18-1.18,4.16-2.04,5.96
				c-0.85,1.78-2.07,3.39-3.22,5.01c-0.43,0.61-1.48,0.91-1.67,1.53c-2.08,6.64-4.56,13.22-5.88,20.02c-1.06,5.47-2.1,6.79-8.14,4.61
				c-2.5,7.8-5,15.58-7.5,23.36"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M232.1,492.5c4.4,5.57,4.13,12.74,6.54,18.98c2.24,5.8,5.59,10.67,10.23,15.22c8.99,8.82,16.89,18.75,25.07,28.37
				c0.81,0.95,0.42,2.93,0.59,4.43"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M460.2,875c-4.33,1.17-8.6,2.65-12.99,3.42c-6.44,1.13-12.94,2.13-19.46,2.48c-7.96,0.42-15.97,0.05-23.95,0.15
				c-1.5,0.02-3,0.62-4.49,0.95"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1,355.5c6.16,4.17,12.19,8.53,18.49,12.46c11.22,6.99,22.62,13.69,33.9,20.57c1.39,0.85,2.52,2.12,4.47,3.79
				c-3.94,0.49-6.97,0.86-10.62,1.32c4.06,4.91,9.04,5.46,14.15,5.89c5.17,0.43,10.44,0.78,14.21,5.65
				c-1.99,0.97-3.86,1.89-6.65,3.26c4.47,2.54,8.52,4.66,12.39,7.09c5.08,3.19,10.03,6.59,14.96,10.02c0.61,0.43,0.7,1.61,1.04,2.44"
				/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M174.7,377.5c-9.98-1-20.01-1.69-29.93-3.12c-5.63-0.81-10.68-1.52-15.65,2.42c-1.27,1.01-4.51-0.42-6.83-0.81
				c-2.97-0.49-2.96-0.53-5.24,2.01"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M114.8,829.5c-17.3,1.17-34.61,2.34-51.91,3.49c-1.66,0.11-3.33,0.01-4.99,0.01"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M632.9,619c1.83,1,3.56,2.5,5.51,2.9c4.67,0.95,9.68,0.09,14,3.03c1.18,0.8,3.77,1.52,4.31,0.94c3.17-3.35,3.38,1.39,5.55,1.71
				c2.58-4.53,5.2-3.06,8.72-0.29c1.73,1.37,5.1,0.64,7.73,0.86c0.14-0.46,0.27-0.92,0.41-1.38c0.86,1.26,1.73,2.52,2.21,3.23
				c5.42-1.12,10.25-2.11,14.04,2.93c4.08-2.9,4.08-2.9,10.4,0.56c5.38-3.65,10.8-3.57,17.06-1.81c3.55,1,7.46,5.19,12.07,1.01
				c1.04-0.94,4.36,0.64,7.66,1.24c2.18,2.02,5.3,4.92,8.21,7.62c1.18-0.7,2.01-1.19,2.14-1.27c3.6,1.92,6.68,3.56,9.75,5.2"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M586.48,441.5c-2.33-0.33-4.69-1.07-6.98-0.92c-7,0.46-14.06,0.82-20.94,2.1c-3.68,0.68-7.33,2.73-10.42,4.96
				c-2.5,1.81-4.6,3.03-7.58,1.86c-13.19-5.14-29.65,1.96-33.44,17.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M865.99,401.5c-0.81-6.33,0.4-8.49,5.98-11.01c3.53-1.59,6.9-4.47,10.51-4.76c8.43-0.66,14.72-6.99,22.94-7.73
				c5.33-0.48,10.64-1.25,15.97-1.32c1.31-0.02,3.97,2.56,3.76,3.25c-0.87,2.83-0.6,6.85-4.77,7.54c-6.64,1.11-13.31,2.01-19.96,3.05
				c-1.19,0.19-2.35,0.59-3.49,0.99c-10.99,3.82-21.74,8.68-33.01,11.21c-11.62,2.6-22.55,6.99-33.88,10.28"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M669.33,663c-1.83-1-3.61-2.11-5.5-2.98c-3.94-1.79-8.26-3.25-12.14-4.47c-5.7-1.79-9.36-6.92-15.3-7.59
				c-0.86-0.1-1.86-1.13-2.47-0.89c-5.7,2.22-11.09,0.05-16.48-1.17c-1.64-0.37-3.02-1.9-4.51-2.9c-0.5-0.33-0.97-0.88-1.5-0.97
				c-5.82-0.89-11.65-1.65-17.46-2.56c-2.16-0.34-5.18,1.52-6.11-2.45c-0.2-0.85-1.81-1.78-2.89-1.96
				c-7.97-1.29-15.95-2.49-23.96-3.49c-1.6-0.2-3.32,0.58-4.98,0.94c-0.19,0.04-0.47,0.32-0.47,0.48c0,0.17,0.3,0.33,0.46,0.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M777.15,985.5c4.99,0.67,10.02,2.11,14.97,1.84c11.65-0.62,22.32,2.91,32.92,6.74c6.18,2.23,11.24,0.91,16-3.08"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M721.74,338c3.16,1.33,6.21,3.27,9.5,3.89c12.76,2.43,25.56,4.77,38.43,6.47c6.89,0.91,13.96,0.47,20.95,0.63"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M465.19,445c-5.66,5.17-11.42,10.23-16.94,15.53c-4.94,4.75-9.88,8.62-17.54,7.17c-2.42-0.46-5.28,1.48-7.95,2.29
				c-2.82,0.86-5.66,1.65-8.48,2.51c-2.01,0.62-4.57,0.75-5.9,2.08c-9.82,9.83-19.4,19.9-29.02,29.93c-0.66,0.68-1.02,1.65-1.52,2.48
				"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M706.77,663.5c1.07-1.43,2.14-2.86,3.9-5.21c1.47,3.2,2.28,4.96,3.5,7.62c2.47-2.07,4.85-3.91,7.04-5.96
				c0.76-0.72,1.04-1.95,1.54-2.95c-1.16-0.5-2.48-1.63-3.47-1.39c-4.2,1.02-7.7-0.09-11.03-2.57c-0.99-0.74-2.92-1.79-3.37-1.42
				c-5.17,4.26-10.64-0.52-15.5,0.67c-4.55,1.12-8.1-0.53-12.05-1.3c-4-0.78-7.96-2.13-11.99-2.36c-4.29-0.24-8.64,0.56-12.97,0.86
				c-2.83,0.2-5.66,0.34-8.48,0.51"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M797.61,399c-1.33-2.67-2.93-5.24-3.93-8.02c-1.71-4.76-3.02-9.66-4.57-14.47c-0.75-2.36-1.25-4.94-2.58-6.94
				c-1.96-2.96-4.45-0.87-6.91-0.13c-12.6,3.81-25.26,7.44-37.92,11.03c-2.6,0.74-5.42,0.74-7.94,1.63c-1.99,0.7-3.69,2.24-5.52,3.41
				"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M39.93,898c2.99,0.5,6.03,0.83,8.97,1.54c4.09,0.99,8.86,0.37,11.59,4.9c0.46,0.76,2.21,0.92,3.4,1.03
				c4.13,0.36,6.77,1.88,7.61,6.51c0.77,4.25,4.72,4.69,8.36,5.03c8.49,0.8,16.98,1.6,25.45,2.53c1.78,0.2,3.5,0.96,5.25,1.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M853.01,323c6.58,2.65,13.45,3.05,20.46,3.18c2.18,0.04,5.07,1.63,6.34,3.43c2.78,3.96,6.55,3.97,10.62,4.5
				c5.88,0.76,11.66,2.23,17.48,3.4"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M194.16,1051.5c-4.16-1.83-8.18-4.13-12.51-5.39c-7.04-2.06-14.23-3.7-21.45-5c-4.06-0.73-8.34-0.87-12.45-0.51
				c-7.68,0.67-15.35,1.7-22.95,2.99c-4.02,0.68-4.03,1.97,0.09,6.59c-1.73,0.68-3.3,1.96-4.57,1.71
				c-6.95-1.36-12.62,1.24-17.99,5.12"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M285,425.5c3.93-7.07,10.51-9.37,17.51-7.11c2.26,0.73,5.03,0.5,7.44,0.04c5.82-1.13,11.85,0.91,14.91,6.13
				c2.06,3.52,4.11,3.75,7.08,1.46c5.08-3.91,10.36-5.66,16.52-2.11c1.45,0.83,3.85,1.07,5.39,0.48c4.94-1.89,9.73-3.28,15.04-1.44
				c2.38,0.83,5.08,0.75,7.44,1.63c3.58,1.33,6.4,0.08,7.82-3.58c5.77,1.41,11.4,2.79,18.37,4.49c-6.19,6.07-6.66,13.26-11.81,18.4
				c-2.65,2.65-5.11,4.95-8.36,6.62c-6.49,3.33-6.92,8.98,0.43,13.96c-4.79,0.82-9.1,0.39-11.71,2.31c-2.89,2.12-4.23,6.37-6.41,9.97
				c-1.61-2.28-3.19-4.51-4.77-6.74"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M357.38,367.5c-1.13,6.28-3.78,12.41-2.21,19.5c2.19,0,4.2,0.01,6.2,0c2.17-0.01,4.14-0.21,4.6,2.99
				c0.44,3.09,2.93,3.74,5.89,3.57c5.22-0.31,10.32-0.24,14.58,3.84c1.12,1.07,3.54,0.9,5.39,1.09c3.98,0.41,7.98,0.69,11.98,1.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M664.84,441c-5.72,0.65-11.74-1.37-17.13,2.79c-1.22,0.94-4.53-1.32-6.82-1.19c-6.34,0.37-12.64,1.35-18.98,1.86
				c-4.64,0.37-9.32,0.29-13.97,0.6c-1.03,0.07-2.26,0.7-2.95,1.48c-3.26,3.64-7.76,3.77-12.03,4.42c-6.24,0.95-7.91,2.22-9.09,8.02
				c-0.97,4.77-2.72,9.42-4.44,14c-1.5,4.01-0.69,6.8,3.56,7.39c5.82,0.81,9.56,3.94,12.46,8.63"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M110.31,435c-18.3-8-36.6-16.01-54.93-23.95c-0.35-0.15-1.04,0.49-2.55,1.26c4.21,4.78,8.13,9.23,12.05,13.69"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M965.82,743.25c-4.21-2.16-6.33-5.86-7.48-10.25c-1.1-4.18-2.18-8.37-3.45-13.24c-6.57,8.94-10.78,17.88-9.21,28.68
				c0.2,2.7-6.72,2.12-2.82,6.56"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M596.96,742.5c-1.33,0.5-2.66,1.43-3.99,1.43c-15.97,0.1-31.94,0.07-47.92,0.07"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M590.47,936c3.33,8.54,9.3,14.02,18.48,15.4c1.55,0.23,3.31-0.9,4.98-1.4c-0.67-1.5-0.94-3.45-2.07-4.42
				c-4.49-3.86-9.04-7.75-14-10.93c-2.19-1.4-5.63-2.71-7.89,0.85"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M877.47,239c-5.99-1.17-12.08-1.96-17.93-3.63c-4.18-1.19-7.98-3.64-12.02-5.35c-5.57-2.36-11.28-3.63-17.42-4.34
				c-7.47-0.85-14.64-4.15-22.01-6.15c-6.27-1.7-12.62-3.11-18.97-4.48c-1.24-0.27-2.65,0.27-3.98,0.44"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M469.68,573c-3.63-0.8-8.99,1.37-9.07-5.5c-0.01-0.84-1.31-2.43-1.9-2.38c-4.43,0.41-5.15-7.03-10.61-4.93
				c-0.61,0.24-2.84-3.72-4.6-6.13c-5.93,6.7-12.07-0.94-18.25,0.39c-1.59,0.34-3.31,0.05-5.12,1.22
				c-8.07-6.33-16.14-12.66-24.21-18.98c0.25-0.18,0.5-0.36,0.74-0.54c-1.61-0.38-3.3-0.58-4.82-1.2c-1.43-0.58-2.89-1.41-3.98-2.48
				c-5.15-5.07-10.67-4.73-16.57-1.39c4.34,4.97,7.91,11.12,13.21,14.67c9.68,6.49,14.17,17.53,23.29,24.25"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M477.17,781c-18.82-4.18-37.98-0.59-56.9-2.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M209.14,731c2.54-3.43,5.32-5.53,10.05-3.68c1.36,0.53,3.57-1.19,5.42-1.81c2.65-0.88,5.9-3.05,7.86-2.22
				c4.06,1.7,6.14-0.51,7.46-2.87c2.28-4.06,5.85-4.72,9.63-4.85c7.47-0.26,14.73,0.11,21.56,4.28c4.15,2.54,7.34-0.49,9.15-4.35
				c1.39,0.56,3.27,1.75,3.63,1.39c5.33-5.21,10.26-0.1,15.03,0.85c5.02,1,9.37,4.43,15.08,1.89c3.94-1.76,9.97,2.49,10.28,6.88
				c0.09,1.27-1.27,3-2.44,3.88c-0.93,0.7-3.16,1.09-3.82,0.51c-6.2-5.53-13.38-4.2-20.56-3.43c-3.31,0.35-6.68,0.72-9.97,0.46
				c-8.05-0.64-12.73,3.86-16.18,9.59c-6.45,0.48-12.2-7.31-18.94-0.19c-1.26,1.34-4.81,0.5-7.31,0.67"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M379.34,283.5c-1.5-0.67-3.72-2.31-4.37-1.83c-3.15,2.34-5.31,5.51-4.62,9.83c0.05,0.33,0.01,0.67,0.01,0.73
				c10.67-2.13,19.41,4.11,29.37,6.25c2.15-2.63,2.15-2.63-1.92-6.49c3.67-5.44,7.13-10.94,5.5-18.75
				c-5.86,0.42-11.71,0.57-17.45,1.44c-1.36,0.21-2.44,2.75-3.5,4.33c-0.95,1.42-1.69,2.98-2.52,4.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M670.33,138c5.58,6.72,12.4,9.15,21,6.61c1.94-0.58,4.55-0.35,6.41,0.49c8.34,3.77,16.67,0.74,25,0.4"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M831.05,604.5c3.16-4.15,6.94-3.61,10.99-1.51c2.56,1.33,4.79,1.83,8.09,0.92c2.6-0.71,6.18,2.33,9.38,3.56
				c2.11,0.81,4.3,1.87,6.48,1.94c7.48,0.24,15.05-0.58,22.43,0.33c4.97,0.61,9.68,3.43,14.5,5.27"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M253.56,286.5c-4.99,0.33-9.98,0.67-14.97,0.99c-0.66,0.04-1.43,0.23-1.98-0.03c-10.3-4.78-21-0.42-31.46-1.43
				c-1.14-0.11-2.8-0.14-3.42,0.53c-5.48,5.95-10.49,12.22-10.56,20.93"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M329.43,990c-3.13-5.33-5.79-10.39-1.17-16.62c2.1-2.84,2.4-7.02,3.51-10.59c0.43,0.02,0.86,0.04,1.29,0.06
				c1.12,5.22,2.37,10.42,3.23,15.68c0.17,1.03-1,2.51-1.91,3.45c-4.93,5.07-5,11.77-5.86,18.05c-1.39,10.06-0.42,20.35-4.81,30.11
				c-1.53,3.4,0.06,8.21,0.23,12.38"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M964.32,744c-1.95,3.76,0.04,7.34,0.48,11c0.59,4.88,4.43,8.96,2.77,14.57c-0.6,2.03,1.87,4.88,2.69,7.44
				c0.57,1.76,0.71,3.65,1.04,5.49c-1.66,0-3.33,0-4.99,0c-3.99,0-7.99,0-11.98,0"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M879.47,619.5c-0.86,1.73-1.73,3.47-2.59,5.2c-0.2-0.37-0.41-0.73-0.61-1.1c-2.09,0.47-4.32,0.63-6.25,1.47
				c-3.08,1.33-5.04,1.17-7.16-1.97c-1.9-2.82-3.82-7.16-9.14-4.18c-2.67-5.84-6.94-6.14-12.73-5.21c-4.76,0.77-9.92-1.19-14.93-1.65
				c-3.13-0.29-6.32-0.05-9.48-0.05c-4.16,0-8.35-0.31-12.47,0.1c-2.88,0.29-7.1,0.72-8.19,2.58c-2.06,3.5-4.88,2.05-7.3,2.8
				c-1.46,0.45-3.12,0.44-4.42,1.14c-0.54,0.29-0.85,2.21-0.43,2.79c0.42,0.58,1.88,0.63,2.86,0.56c7.07-0.54,14-1.58,21.04,1.36
				c4.61,1.92,9.91,2.18,14.9,3.16c0.48,0.09,1.23-0.2,1.46,0.04c4.23,4.64,10.78,1.96,15.51,4.97"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M155.73,796.5c-0.2-4.02-1.42-7.04-5.96-8.13c-1.88-0.45-3.17-2.13-3.29-4.86c-0.07-1.57-2.76-3.04-4.99-5.28
				c-1.89,3.3-3.53,5.68-4.67,8.29c-1.25,2.86-2.73,4.02-5.82,1.42c-2.79,5.27-5.4,10.22-8.76,16.58c-0.47-2.76-0.93-4.15-0.89-5.52
				c0.04-1.78-1.45-1.86-2.82-4.14c-2.97-4.91-7.72-5.27-12.12-7.06c-1.35-0.55-2.04-3.39-2.52-5.32c-1.03-4.2-5.01-6.08-9.08-4.59
				c-2.24,0.82-4.87,0.61-7.32,0.86c-0.08-0.44-0.16-0.89-0.24-1.33c-0.92,1.39-1.84,2.77-2.83,4.27c-3.09-1.94-6.42-3.43-8.95-5.77
				c-4.13-3.83-6.53-4.07-9.21,0.33c-2.07-1.43-4.12-2.85-6.81-4.71c-5.74,0.21-11,7.58-18.73,3.62c-1.14,1.87-2.2,3.6-2.58,4.22
				c-2.53,0.72-4.32,0.84-5.63,1.69c-3.29,2.13-6.37,4.6-9.53,6.93"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M419.27,426.5c-0.84-3.94,4.49-6.66,2.24-11.12c-0.2-0.39,3.02-2.63,4.76-3.86c3.62-2.56,7.66-4.64,10.89-7.6
				c5.28-4.85,10.08-10.23,15.03-15.43c0.81-0.86,1.39-1.96,2.01-2.98c2.72-4.47,3.57-4.86,8.99-4c0,1.33,0,2.67,0,5.24
				c2.1-1.75,3.38-2.66,4.48-3.75c4.64-4.65,6.56-4.67,11,0.01c3.15,3.32,10.05,3.82,13.98,1c5.07-3.64,7.57-10.14,4.26-14.32
				c-2.09-2.65-6.41-4.88-9.76-4.92c-8.98-0.11-18,0.79-26.94,1.83c-6.46,0.75-13.26,0.96-18.54,5.84c-0.37,0.34-1.68-0.11-2.42-0.46
				c-6.27-2.93-12.56-1.86-19.02-0.69c-1.5,0.27-3.49-2.15-4.41-2.76c-5.29,0.52-10.15,1-15.02,1.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M402.8,845.5c-0.56-1.3-1.12-2.6-1.89-4.39c7.89-0.4,15.41-0.79,22.92-1.17c0.11-0.73,0.23-1.46,0.34-2.19
				c-3.13-0.92-6.28-1.78-9.38-2.77c-6.17-1.96-12.32-3.99-18.47-5.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M812.58,87c-1.32-5.87-6.86-6.39-11.01-8.43c-12.21-6.01-29.64-1.72-37.4,8.93"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M633.9,929c3.8,2.99,4.05,7.43,2.34,10.92c-6.25,12.72-9.73,25.84-8.33,40.08"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M791.12,480c-0.98,4.87-1.95,9.74-2.95,14.68c6.17,2.07,13.07-1.92,14.9-9.19c0.33-1.29,0.17-2.72,0.55-3.99
				c2.52-8.43,0.1-12.13-7.35-16.77c-4.7-2.93-8.35-8.05-11.51-12.83c-4.17-6.3-7.7-13.22-14.26-17.15c-2.18-1.31-8.3-0.56-8.96,0.91
				c-2.95,6.56-9.04,5.18-13.87,6.76c-3.26,1.07-6.73,1.47-11.09,2.38c-6.99-4.27-14.07,0.32-21.33,3.19c-0.49,0.19-1,0.34-1.5,0.5"
				/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M259.55,263c2.33-7.5,4.81-14.96,6.9-22.52c0.7-2.52,0.79-5.35,0.5-7.97c-0.47-4.13-2.33-7.33-6.89-8.56
				c-10.69-2.89-21.25-1.03-31.38,1.83c-6.02,1.7-9.39,7.69-11.56,13.72"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M803.1,1263c-5.32,1.5-10.58,3.89-15.99,4.3c-9.43,0.71-18.95,0.13-28.43,0.27c-1.67,0.02-3.33,0.93-4.99,1.43"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M810.09,377c-0.33,1.33-0.95,2.67-0.94,4c0.02,4,0.48,8,0.39,12c-0.11,4.67-0.72,9.33-0.92,14c-0.28,6.76-2.54,8.55-8.47,5.91
				c-3.04-1.35-5.8-3.44-8.5-5.44c-6.56-4.87-13.63-4.74-20.9-3.14c0.41,2.29,0.7,3.94,1.2,6.74c-4.82-1.21-8.66-2.17-12.49-3.13
				c-0.24-0.63-0.48-1.26-0.71-1.89c1.65-0.69,3.29-1.37,4.94-2.06c-0.27-0.5-0.54-1.01-0.8-1.51c-3.26-1.31-6.53-2.63-9.79-3.94
				c1.17,3.57,3.4,5.89-0.8,8.59c-2.68,1.72-0.95,13.94,1.34,16.44c3.12,3.4,5.73,7.27,8.56,10.93"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M758.68,979.5c-13.08-5.59-26.74-7.98-41.96-8.61c3.19,3.45,5.36,5.78,7.52,8.11"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M809.59,1214.5c-1.5,1.17-2.89,2.5-4.51,3.47c-5.44,3.25-10.66,7.24-16.53,9.31c-7.33,2.59-15.23,3.54-22.88,5.23
				c-1.85,0.41-3.66,0.99-5.49,1.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M336.91,979.5c4.66,1.73,4.8,6.74,5.68,10.08c2.8,10.56,5.68,21.21,5.46,32.42c-0.11,5.31,1.35,10.69,2.38,15.99
				c0.82,4.22-0.86,7.24-5.04,8c-7.07,1.29-13.99,1.98-20.55-2.36c-1.58-1.05-4.24-0.46-6.4-0.63"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M823.07,512c-6.97,5.26-15.15,3.38-22.94,4.17c-4.78,0.48-9.39,2.65-15.47,4.48c0.62-2.87,1.15-5.29,1.68-7.71
				c-0.26,0.09-0.52,0.18-0.78,0.26c-0.64-2.57-1.18-5.17-1.96-7.69c-0.44-1.4-1.29-2.68-1.96-4.01c-1,1-2.58,1.85-2.89,3.03
				c-1.01,3.91-1.56,7.95-2.15,11.17c-2.19,1.22-4.31,1.98-5.89,3.37c-3.01,2.63-5.68,5.63-8.55,8.43c-1.26,1.23-2.83,3.5-3.95,3.32
				c-6.82-1.08-9.95,5-15.01,7.18c-3.05,1.31-6.01,2.51-7.12-2.46c-0.2-0.88-2.7-2.23-3.27-1.89c-3.33,1.96-6.34,3.28-9.65-0.06
				c-0.41-0.42-2.07-0.01-2.89,0.46c-5.04,2.91-9.98,6-15.01,8.94c-1.92,1.12-3.98,2.02-5.98,3.02c-0.67-2-1.34-4-1.99-6
				c-0.21-0.65-0.34-1.33-0.75-2.96c-1.86,0.87-4.14,1.23-5.13,2.55c-2.3,3.08-4.71,4.08-8.62,3.54c-5.98-0.82-11.49,3.88-12,9.78
				c-2.1,0.19-4.31,0.74-6.45,0.52c-4.49-0.45-8.87-1.89-12.65,2.43c-0.87,0.99-3.83,0.15-5.82,0.15c0.17-1.83,0.36-3.66,0.49-5.5
				c0.19-2.5,0.35-5,0.5-7.5c0.18-3-0.16-6.14,0.61-8.97c1.27-4.67,0.25-10.06,5.19-13.78c1.64-1.24,1.07-5.43,1.58-8.75
				c2.93,0,5.76,0,8.59,0"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M214.13,32.5c-3.99,0.5-8.01,1.55-11.98,1.4c-11.08-0.43-22.16-1.57-32.93,2.16c-1.15,0.4-2.02,1.61-3.02,2.44"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M683.81,403.5c5.83,7.83,12.4,14.84,15.41,24.87c2.05,6.85,8.34,12.38,12.46,18.69c1.06,1.63,1.43,3.93,1.49,5.95
				c0.06,2.32-0.36,4.72-0.97,6.98c-0.52,1.92-1.22,4.03-2.51,5.44c-2.37,2.6-5.38,4.6-7.87,7.11c-2.6,2.63-5.62,2.49-8.5,1.4
				c-10.47-3.99-14.48-2-20.74,6.89c-3.84,5.45-3.83,10.81-4.39,16.66c-0.39,4.07-2.43,7.96-3.28,12.03
				c-0.96,4.61-1.4,9.32-2.06,13.99c-0.02,0.16,0.02,0.34-0.01,0.5c-1.66,9.33-3.32,18.67-4.99,28"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M183.68,1094.5c12.95,2.8,26.31,3,39.01,7.8c11.79,4.45,24.02,8.46,36.42,10.33c11.53,1.74,22.78,3.87,33.92,7.26
				c8.32,2.53,16.92,4.18,25.44,6.03c1.55,0.34,3.31-0.26,4.98-0.43"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M87.35,273c-2.5,7.17-5.08,14.3-7.45,21.51c-1.07,3.26-1.66,6.67-2.55,9.98c-0.94,3.51-2.02,6.99-2.96,10.51
				c-0.44,1.64-1.25,3.45-0.92,4.98c2.18,10.05-3.48,16.99-9.07,24.02c-1.04,1.3-2,2.66-3,4"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M934.37,557c-2.99-4.83-6.05-9.63-8.95-14.52c-0.98-1.65-1.55-3.53-2.31-5.31c-0.71,0.04-1.41,0.07-2.12,0.11
				c-0.2,1.74-0.29,3.5-0.62,5.22c-0.39,2.02-0.97,4.01-1.47,6.01c-1.83-0.67-3.93-0.97-5.44-2.08c-3.21-2.35-6.05-3.94-9.62-0.51
				c-0.95,0.91-3.27,1.49-4.35,0.96c-4.86-2.36-8.38-0.24-12.12,2.52c-1.16,0.86-3.21,0.5-6.09,0.86c6.11-7,11.1-12.73,16.53-18.95
				c-4.74-1.04-6.73-0.44-10.37,2.19c-6.97,5.03-13.74,10.39-21.04,14.87c-4.71,2.89-10.19,4.58-15.42,6.55
				c-1.11,0.42-2.63-0.25-3.96-0.41"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M76.37,1085.5c-6.49-4-13.12-7.79-19.43-12.05c-9.11-6.14-18-12.62-26.99-18.95"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M913.41,509.5c3.49,2.33,6.88,4.86,10.55,6.88c1.08,0.6,3.19,0.23,4.38-0.46c4.9-2.82,4.79-2.92,6.08,2.57
				c0.23,0.98,1.04,1.83,2.32,3.96c4.03-5.58,7.5-10.39,11.02-15.26c2.85,5.21,0.49,10.37-1.49,15.28c-2.71,6.71-2.72,6.61,3.04,10.1
				c6.75,4.09,14.12,6.49,21.5,8.93"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M298.48,417c0.33-0.5,0.56-1.28,1.01-1.46c4.29-1.61,8.26-3.22,9.65-8.5c0.52-1.97,3.14-3.38,5.06-5.3
				c-8.81-3-11.11,5.53-17.58,9.93c0.22-4.03,0.37-6.88,0.56-10.36c-5.49,1.49-10.53,2.85-14.17,7.19c-0.53,0.64-0.86,1.64-1.52,1.95
				c-6.16,2.84-5.91,7.37-3.47,12.55"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M59.9,1088c-3.67-0.13-6.86,1.69-11.05,0.85c-7.77-1.54-15.99-3.95-23.99,0.46c-1.25,0.69-3.94-1.02-5.87-1.84
				c-2.3-0.99-4.5-2.25-6,1.03"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M351.39,901c-18.63,0.17-37.27,0.33-55.9,0.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M653.36,512.5c-2.33,0.17-4.66,0.49-6.99,0.47c-4.49-0.05-8.98-0.32-13.48-0.46c-4.59-0.14-9.16-0.35-10.03,5.99
				c-0.37,2.72-2.49,2.97-4.94,3.08c-6.17,0.27-12.35,0.67-18.45,1.59c-1.66,0.25-3.02,2.5-4.52,3.84"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M970.81,551.5c-1.16-0.33-2.34-0.98-3.49-0.95c-6.89,0.22-12.41-2.78-17.49-7.03c-1.94-1.63-4.05-3.04-5.58-4.17
				c-2.74,2.05-5.04,3.39-6.81,5.23c-1.23,1.28-2.52,3.3-2.43,4.92c0.32,6.02,1.19,12.01,1.86,18.01"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M313.95,327.5c-11.85-3.26-23.46-3.73-33.83,2.51c-3.29-1.27-6.18-2.41-9.09-3.51c-3.78-1.42-6.48,0.34-7.89,3.54
				c-1.83,4.14-5.17,5.49-9.07,6.49c-5.63,1.44-9.87,3.8-11.75,10.4c-1.86,6.54-5.72,12.58-12.22,16.07
				c-8.81,4.72-10.82,13.9-14.43,22.02c-0.46,1.04-0.37,2.32-0.54,3.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M103.82,274c-0.67,1.83-1.41,3.64-1.98,5.51c-0.75,2.48-1.55,4.96-1.98,7.5c-0.96,5.64-1.57,11.35-2.62,16.98
				c-0.3,1.6-1.25,3.41-2.47,4.44c-4,3.36-8.26,6.41-12.42,9.57"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M651.86,1066c4.95,13.62,14.35,24.64,21.38,37.05c1.71,3.02,3.9,6.96,3.24,9.88c-1.08,4.78-4,9.3-6.86,13.43
				c-0.78,1.13-4.12,0.48-6.28,0.65"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M230.6,1060c-1.01,3.56-4.31,3.72-7,4.45c-7.95,2.15-15.87,4.67-23.98,5.77c-3.6,0.49-8.3-1.14-11.31-3.4
				c-7.36-5.52-14.37-5.79-22.14-1.4c-2.05,1.16-4.63,1.37-6.98,2.03c-1.87-2.72-1.37-4.61,2.03-5.46c0.51-0.13,1-0.33,1.5-0.49
				c4.99-1.67,10.19-5.09,14.92-4.53c5.23,0.61,10.22,4.58,14.98,7.61c5.2,3.31,9.98,1.59,15.04-0.03
				c6.26-2.01,12.57-3.99,18.97-5.45c4.38-1,8.96-1.1,13.45-1.6"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M244.57,1054c-7.04-4.35-13.14-10.47-23.13-6.98c-2.54,0.89-7.74-3.62-10.64-6.68c-2.78-2.92-4.1-7.21-6.19-10.82
				c-0.83-1.43-2.06-2.63-2.94-4.03c-0.79-1.25-1.36-2.65-2.02-3.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M489.15,646c-2.37-3.58-5.14-6.42-9.98-5.51c-0.32,0.06-0.89,0.13-0.96-0.01c-1.88-3.56-5.81-2.26-8.43-3.65
				c-5.64-3.01-11.5-3.31-17.56-3.35c-1.17-0.01-2.54,0.03-3.46-0.53c-7.54-4.57-14.86-9.55-22.55-13.84
				c-4.13-2.3-9.48-2.17-12.92-6.11"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M298.98,259.5c8.32-2.83,16.67-5.57,24.95-8.53c6.67-2.39,7.25-3.52,7.02-10.47c-0.12-3.83,0.83-7.92-0.21-11.44
				c-1.8-6.11-6.04-7.8-11.71-4.91c-2.92,1.49-4.97,4.63-7.59,6.82c-2.01,1.68-4.31,3.02-6.46,4.54c-4,2.82-7.99,5.66-11.98,8.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M869.49,316.5c8.51,3.99,16.6,8.88,25.93,11.1c7.1,1.69,14,4.24,20.99,6.4"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M250.06,367.5c4.07-8.2,12-6.75,18.97-7.42c2.11-0.2,4.39,0.36,6.46,0.99c1.45,0.44,3,1.36,3.86,2.54
				c0.32,0.44-1.03,2.33-1.86,3.36c-2.76,3.39-5.63,6.69-8.46,10.02c-6.65,7.83-13.24,15.72-20.03,23.44
				c-1.06,1.2-3.03,1.6-5.36,2.76c0.45-3.15,0.72-5.1,1-7.05c-0.42-0.13-0.84-0.26-1.26-0.39c-1.1,0.91-2.26,1.76-3.28,2.76
				c-4.98,4.85-10.28,8.93-16.91,11.67c-8.29,3.42-16.05,8.15-24.03,12.32"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M345.4,396.5c-7.4,2.13-4.92,9.2-6.99,14c4.06,5.83,8.2,6.13,14.19,0.3c-7.67-1.4-6.99-7.47-7.7-12.8"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M670.83,570c0.83-3.33,1.66-6.67,2.5-10c-2.99,1.33-6.14,2.41-8.96,4.05c-9.88,5.72-19.66,11.62-29.47,17.47
				c-0.39,0.23-0.72,0.98-1,0.94c-6.66-0.94-13.32-1.9-19.97-2.99c-1.54-0.25-2.97-1.08-4.5-1.43c-1.97-0.45-3.99-0.68-5.98-1.04
				c-0.35-0.06-0.77-0.55-0.99-0.45c-9.03,4.06-16.93-6.47-25.97-2.04c-0.13,0.07-0.35,0.06-0.49,0c-8.21-3.13-16.61-1.34-24.96-1
				c-0.66,0.03-1.78,0.25-1.93-0.03c-3.29-6.07-9.57-4.08-14.56-5.4c-3.74-0.99-8.35-2.99-11.29-1.67
				c-3.88,1.75-6.83,0.16-10.16,0.04c-0.68-0.02-1.33-0.93-2-1.43"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M444.23,239c2.5,5.33,4.86,10.74,7.52,15.98c3.01,5.91,6.38,11.64,9.41,17.54c1.74,3.38,3.12,6.94,4.81,10.77
				c3.25-4.41,4.02-9.2,2.67-14.28c-1.58-5.95,2.56-12.53,8.5-11.8c5.49,0.68,8.59-1.69,12.11-5.1c2.8-2.71,6.5-4.52,9.89-6.59
				c3.45-2.11,5.99,0.41,5.73,2.95c-0.52,5.04,3.22,6.45,5.74,9.03"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M251.06,50c-4.49-4.11-13.24-5.4-20.71-2.92c1.51,4.84,5.29,7.16,9.75,7.79c4.33,0.61,8.9,0.36,11.46-4.37"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M563.52,530.5c-9.15,0.5-18.31,0.87-27.45,1.55c-4.85,0.36-9.66,1.22-14.48,1.96c-4.42,0.68-5.82,3.16-4.53,7.5
				c1.51,5.1,0.8,9.57-3.31,13.64c-1.58,1.57-1.48,4.85-2.15,7.35"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M21.96,1024c-2.3-2.54-4.6-5.07-7.13-7.86c-0.34,0.71-0.93,1.75-1.34,2.86c-1.86,4.99-3.73,9.98-5.48,15
				c-0.82,2.34-1.39,4.76-2.25,7.76c-1.11,3.02-2.67,6.57-3.62,10.27c-0.28,1.09,0.85,3.34,1.91,3.84c4.52,2.12,9.32,3.65,13.9,5.66
				c6.55,2.87,13.36,5.37,19.39,9.11c8.18,5.08,15.52,11.52,23.67,16.66c2.53,1.6,7.39,2.31,9.71,0.94
				c9.47-5.59,18.79-2.96,28.06-0.6c4.54,1.16,8.88,3.54,12.94,5.98c2.1,1.27,4.33,3.65,4.86,5.92c0.93,3.97,3.29,5.39,6.7,5.93
				c7.49,1.18,13.33,4.95,16.83,11.6c1.34,2.55,1.14,5.93,1.64,8.93"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M626.41,931c-2.5,5.83-5.06,11.64-7.47,17.51c-3.01,7.31-2.88,14.63-0.08,22.02c0.57,1.51,0.39,3.31,0.56,4.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M914.91,805c-2.2,3.1-4.4,6.2-6.61,9.3c0.36-0.03,0.72-0.05,1.07-0.08c-0.82,6.09-0.36,12.76-2.77,18.13
				c-3.01,6.71-8.38,12.35-12.81,18.57c4.14,7.32,8.94,14.94,10.1,24.08c0.14,1.13-0.25,2.34-0.46,3.49
				c-0.65,3.5-0.89,7.17-2.13,10.45c-0.93,2.45-3.18,4.39-4.85,6.56"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M298.48,1001.5c-1.21-4.75-6.07-7.77-5.77-13.51c0.11-2.05-3.04-4.27-5.64-7.62c-2.22,4.45-3.85,7.81-5.56,11.13
				c-1.57,3.05-2.52,6.3-7.5,5.67c-5.46-0.68-8.62,3.44-10.92,7.85c-1.54,2.97-3.67,3.99-7.02,4.15c-2.37,0.11-5.16,1.3-6.9,2.93
				c-4.29,4.02-7.34,10.06-12.26,12.52c-6.19,3.09-6.79,9.07-10.16,13.48c-1.59,2.08-1.79,5.23-2.63,7.9"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M396.81,608.5c1.12-0.72,2.24-1.43,3.94-2.52c0.75,0.84,2.28,2.81,4.08,4.49c2.59,2.41,4.91,2.97,8.62,0.82
				c2.82-1.63,7.26-1.26,10.79-0.64c5.34,0.94,10.5,2.86,16.55,4.6c1.25-0.79,3.67-2.17,5.92-3.78c3.58-2.55,7.21-0.43,8.81,1.67
				c6.23,8.22,14.82,6.67,23.15,6.88c9.01,0.23,17.97-0.34,27.05,1.51c3.74,0.76,8.33,0.43,11.8,2.13
				c8.15,3.99,17.59,2.86,25.62,7.17c1.41,0.76,3.91-0.8,5.91-0.77c2.35,0.04,5.45-1.35,6.49,2.42"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M94.83,274.5c-0.01,5-0.57,9.36-4.19,13.75c-2.68,3.24-2.54,8.78-3.82,13.25c-1.28,4.47-2.12,8.9-4.5,13.26
				c-1.83,3.34-2.97,7.06-5.98,9.72c-0.36,0.32-0.98,0.35-1.48,0.52"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M857.51,355.5c-7.65,0.5-15.3,1.05-22.96,1.48c-4.82,0.27-9.69,0.02-14.46,0.61c-4.55,0.56-9.28,1.26-13.42,3.07
				c-5.39,2.36-10.72,3.64-16.54,3.37c-3-0.14-6.35-0.63-7.49,3.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M624.41,1047.5c-5.76,8.11-6,17.61-7.06,26.99c-1.37,12.2-3.55,24.31-4.84,36.52c-1.5,14.13-2.44,28.32-3.51,42.5
				c-0.09,1.18,0.61,2.42,1.08,4.09c2.46-1.97,4.41-3.53,7.08-5.68c0.92,3.32,1.75,6.32,2.67,9.66c5.82-3.11,7.5-8.04,9.1-13.07
				c0.7-2.21,1.64-4.34,2.47-6.51"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M2.5,946.5c6.04,0.97,12.09,1.94,19.05,3.06c6.5-5.66,20.26-6.39,28.36-0.54c4.05,2.92,8.25,5.7,11.93,9.05
				c6.74,6.12,9.16,16.79,3,25.4c-0.44,0.61-1.61,0.7-2.44,1.04"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M787.13,1176.5c-4.52,1.03-9.05,2.07-13.57,3.1c-0.11,0.48-0.21,0.95-0.32,1.43c4.46,1.66,8.92,3.33,13.39,4.97
				c9.15,3.35,18.32,6.63,27.43,10.07c1,0.38,1.69,1.61,2.52,2.44"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M344.9,735.5c-3,1.83-5.89,2.42-9.55,1.19c-2.49-0.84-5.92-0.62-8.36,0.45c-7.49,3.28-14.71,7.18-22.02,10.86
				c-3.93,1.97-7.57,2.09-11.57-0.35c-1.77-1.08-4.92-1.3-6.82-0.48c-3.59,1.56-6.73,4.16-10.06,6.32"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M881.46,843c2.69,3.67-0.07,6.81-1.64,9.42c-4.54,7.53-7.28,15.61-9.48,24.05c-1.05,4.04-3.83,7.59-5.24,11.58
				c-0.81,2.27-0.45,4.96-0.62,7.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M279.51,363c5.66-3.5,12.15-6.15,16.77-10.7c6.45-6.35,11.83-13.91,17.04-21.38c2.79-4,5.15-6.48,10.62-6.15
				c4.9,0.29,10.63,1.17,14.46-3.76c0.14-0.18,0.31-0.46,0.5-0.48c8.79-1.08,15.5-5.75,21.56-11.92c1.75-1.78,6.19,1.49,6.32,4.91
				c0.24,6.49,0.69,13.07-0.08,19.48c-1.06,8.74-3.1,17.37-4.89,26.01c-0.47,2.24-1.61,4.34-2.44,6.51"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M661.35,1055c8.32,11.67,16.73,23.27,24.92,35.03c4.39,6.3,2.28,17.51-4.01,21.89c-1.28,0.89-3.27,0.75-4.94,1.08"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M135.26,1073.5c8.49-0.83,16.97-1.71,25.46-2.48c3.52-0.32,3.23,2.38,2.94,4.47c-0.78,5.57,0.92,7.75,7.04,7.7
				c8.64-0.08,17.49,2.59,25.91,1.47c12.53-1.67,11.65-0.51,10.53-14.66"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M604.95,792.5c-3.16-0.83-6.29-2.19-9.49-2.4c-9.47-0.61-18.96-0.74-28.44-1.14c-1.01-0.04-2-0.63-3-0.96"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M761.67,973c0.17-0.83,0.76-2.13,0.44-2.43c-8.62-7.92-18.46-14.18-29.93-16.33c-8.93-1.68-18.25-1.34-27.41-1.7
				c-5.32-0.21-10.65-0.04-17.63-0.04c2.2,1.32,3.11,2.21,4.17,2.45c10.63,2.41,21.29,4.69,31.93,7.06c2.47,0.55,4.95,0.03,6.41,3.82
				c0.8,2.08,5.82,2.82,9.08,3.59c6.6,1.56,13.33,2.59,19.93,4.16c1.82,0.43,3.37,2,1.66,1.08c-2.38,3.94-4.3,8.27-7.24,11.73
				c-3.75,4.42-5.75,3.56-10.94,0.73c-11.28-6.15-23.1-10.74-36.36-6.63"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M117.79,378c12.16,10.64,27.17,16.11,41.39,23.07c11.43,5.6,20.42-1.17,29.93-5.21c1.93-0.82,4.75-2.62,1.72-6.02
				c-0.92-1.03,0.17-3.85,0.4-6.6c-2.57,0-5.06,0-8.83,0c7.79-9.01,14.66-16.97,21.53-24.92c0.4,0.05,0.8,0.09,1.2,0.14
				c0,1.34,0.36,2.81-0.06,4.01c-1.5,4.23-3.43,8.3-4.85,12.55c-0.62,1.85-0.41,3.97-0.58,5.97"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M330.92,316.5c-13.14-3.59-26.32-6.84-39.91-2.45c-2.31,0.74-4.4,2.18-7,3.51c-1.37-5.36-5.87-4.53-9.98-4.54
				c-0.5,0-1.31,0.17-1.45,0.5c-1.74,3.97-3.37,7.98-5.04,11.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M896.94,302.5c10.53,1.59,19.46,5.68,23.1,10.69c7.08-4.38,11.88,2.32,17.83,3.81"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M166.21,772c-3.94-2.11-6.9-6.12-11.98-6.03c-1.49,0.03-3-0.45-4.49-0.43c-1.83,0.01-3.84,0.87-5.46,0.36
				c-7.02-2.17-14.12-3.49-21.49-3.45c-2.16,0.01-4.86,0.08-6.37-1.1c-2.74-2.14-4.31-1.51-7.24-0.15c-2.68,1.24-7.62,1.25-9.62-0.47
				c-4.24-3.66-8.15-1.28-12.21-1.23c-2.11,0.03-4.22,0.13-6.41,0.83c-3.6-2.59-6.99-3.13-11.15,0.05c-2.77,2.12-5.84,3.62-10,1.3
				c-4.28-2.38-8.06-0.4-10.44,3.92c-3.93-2.6-7.85-3.21-11.97-0.19c-0.95,0.69-3.01,1.03-3.87,0.47c-4.28-2.81-8.08-2.26-12.11,0.52
				c-1.09,0.75-3.19,1.01-4.39,0.49c-5.18-2.27-10.02,0.53-15.02,0.6"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M667.34,1041.5c11.25,11.07,22.16,22.43,30.42,36.01c4.57,7.52,0.96,17.19-7.46,20.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M822.57,984.5c-0.33-1.17-0.67-2.33-1-3.5c1.16,0,2.41-0.27,3.48,0.05c4.03,1.21,9.27,1.45,11.76,4.16
				c8.56,9.33,20.47,10.91,31.18,15.28c6.34,2.59,14.04,1.16,19.45,6.53c0.84,0.84,1.47,1.89,2.9,3.76c-2.99,0.3-5.19,0.76-7.37,0.68
				c-4.17-0.15-8.41-0.21-12.45-1.09c-2.66-0.58-4.91-2.85-7.54-3.8c-7.93-2.84-15.96-5.37-23.93-8.08c-2.04-0.7-4-1.65-6-2.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M621.92,332.5c4.33,5.17,8.84,10.19,12.9,15.56c2.32,3.07,4.6,4.75,8.59,3.56c0.93-0.28,3.28,0.83,3.31,1.39
				c0.39,7.22,5.43,11.59,9.63,16.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M685.81,942c-2.99-0.17-6.01-0.16-8.98-0.53c-12.23-1.55-23.81,7.43-21.79,19.58c1.91,11.46-5.22,20-14.21,26.37
				c-5.9,4.17-8.31,9.71-9.02,16.57c-0.23,2.23-1.16,4.64-2.47,6.46c-5.15,7.11-7.43,14.79-5.95,23.56c0.56,3.3,0.34,6.75,1.16,9.96
				c0.52,2.03,2.3,3.74,3.52,5.59c0.52-0.1,1.04-0.19,1.56-0.29c0.59-1.59,1.24-3.16,1.77-4.77c1.53-4.66,2.78-9.42,4.56-13.97
				c2.08-5.31,5.75-9.87,6.22-16c0.14-1.81,3.48-4.27,5.75-4.83c4.66-1.15,8.39-3.36,10.86-7.24c2.42-3.8-0.19-7.47-4.92-7.45"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M898.93,821.5c-6.05-4.4-6.42-4.52-12.45,0.03c-6.19,4.67-13.27,2.46-19.99,2.94c-2.66,0.19-5.35,0.03-8.94,0.03
				c1.09,2.26,1.61,3.33,2.13,4.39c-2.33,1.44-4.5,2.77-6.66,4.11"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M875.47,488c4.33,0.5,8.7,0.75,12.95,1.64c1.51,0.32,2.74,2.15,4,3.38c0.76,0.74,1.22,1.8,2.03,2.46
				c4.54,3.68,4.57,7.38,0.53,11.58c-2.94,3.06-5.29,6.69-8.07,9.92c-1.84,2.14-3.69,4.45-6.01,5.94
				c-6.34,4.07-12.92,7.75-19.41,11.58"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M52.41,552.5c0.66,1.33,1.33,2.67,2.74,5.52c0.75-1.52,1.06-3.18,1.79-3.39c1.53-0.45,4.13-0.84,4.82-0.02
				c4.84,5.75,11.23,6.94,18.83,2.46c0.62,1.72,0.76,3.82,1.88,4.8c1.23,1.08,3.36,1.13,5.23,1.13c2.05-0.11-0.09,6.31,4.72,2.62
				c0.12-0.09,1.77,1.43,2.37,2.4c2.22,3.57,5.42,4.59,9.47,5.24c2.56,0.41,4.55,3.68,7.1,5.12c1.3,0.73,3.26,0.28,4.93,0.36
				c0-0.25,0.06-0.52-0.02-0.74c-0.08-0.2-0.31-0.34-0.48-0.51"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M600.95,765c-3.33,0-6.66,0-9.98,0"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M590.47,765c-2.66,0.5-5.99,0.17-7.85,1.67c-4.48,3.6-9.19,2.76-14.11,2.39c-3.49-0.27-7.02-0.05-10.48-0.05
				c0,1.33-0.08,1.93,0.01,1.96c6.04,1.79,10.65,7.07,17.46,7.07c5.66,0,11.31,0.32,16.97,0.46c2.34,0.06,5.26-0.25,3.86,3.46
				c-0.5,1.32-2.49,2.85-3.87,2.92c-8.31,0.43-16.64,0.35-24.94,0.74c-1.45,0.07-2.83,1.56-4.25,2.39"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M583.48,458.5c-0.83-0.33-1.66-0.94-2.5-0.96c-12.08-0.23-24.15-0.38-35.94,2.95c-0.15,0.04-0.43-0.06-0.48,0.02
				c-4.91,6.77-11,5.64-18.06,3.79c-3.89-1.02-8.6,0.89-12.89,1.72c-14.74,2.85-21.82,14.18-28.46,25.97
				c-4.14,7.35-7.85,15-12.7,21.84c-1.98,2.79-6.43,3.83-9.76,5.66"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M816.08,820c-4.99-0.83-10-1.57-14.96-2.56c-1.57-0.31-3.5-0.87-4.42-2.01c-5.37-6.67-11.84-8.52-20.06-5.48
				c-0.53,0.2-1.31-0.29-1.98-0.45"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M37.93,937c-1.61-6.81-3.77-13.12-11.97-14.57c-0.76-0.13-1.47-1.18-1.99-1.94c-5.08-7.42-13.65-6.37-18-2
				c-1.41,1.42-2.98,2.68-4.48,4.01"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M41.93,1215c-13.31-0.83-26.62-1.67-39.93-2.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M971.31,348.5c-0.06-6.96-3.67-9.79-10.5-8.59c-1.56,0.27-3.48-0.24-4.94-0.97c-4.06-2.03-6.52-0.71-7.02,4.06
				c-0.67,6.42-1.33,12.83-2,19.25"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M476.67,231c0.83,2.5,1.5,5.07,2.53,7.49c2.02,4.75,4.62,5.31,8.45,2.01c5.47-4.71,5.55-4.61,11.55-0.6
				c1.04,0.69,2.93,0.04,4.43,0.11c2,0.1,3.99,0.32,5.99,0.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M274.02,631c-3.36-5.03-5.62-5.2-13.42-0.75c0.32-5.88-3.13-7.33-8.04-7.37c-0.84-0.01-1.96-1.41-2.45-2.41
				c-2.41-4.93-5.76-5.86-9.65-3.97c-2.62,0-5.42-0.6-7.84,0.11c-5.98,1.76-16.53-1.03-21-6.12"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M250.06,880c0,3.67,0.23,7.35-0.11,10.99c-0.13,1.4-1.25,3.12-2.43,3.93c-5.38,3.67-10.92,6.66-17.27,8.99
				c-12.49,4.6-24.11,11.59-36.09,17.59"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M742.21,328.5c1.16,1.5,2.34,2.99,3.49,4.5c1.51,1.99,2.66,4.46,4.59,5.87c2.1,1.54,4.82,2.45,7.41,3.05
				c5.27,1.23,10.66,1.96,15.94,3.15c1.29,0.29,2.35,1.59,3.51,2.42"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M109.31,839.5c-2.66-0.67-5.31-1.77-7.99-1.84c-1.64-0.04-4.07,1.06-4.87,2.41c-5.06,8.52-9.77,17.26-14.6,25.93"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M264.04,225c2-0.83,3.93-1.92,6-2.45c7.37-1.9,8.61-0.87,10.55,6.93c0.69,2.79,1.35,5.57,4.8,7.25c2.87,1.39,5.61,4.41,6.89,7.36
				c1.64,3.79,2.38,8.14,0.26,12.43c-3.99,8.09-7.4,16.47-11.64,24.42c-2.56,4.82-6.23,9.04-9.36,13.57
				c-0.76,1.1-1.35,2.32-2.01,3.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M650.87,166.5c-7.32,0.67-14.69,1.03-21.95,2.11c-4.64,0.69-7.57,3.57-7.58,8.89c0,3.61-1.65,5.9-5.91,6.09
				c-6.45,0.29-7.36,2.47-7.17,8.91c0.17,5.64-0.92,11.37-1.89,16.98c-0.95,5.5-2.61,10.74-1.27,16.59c0.38,1.66-3.32,4.26-5.15,6.42
				"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M923.89,665c-1.97-3.92-3.98-7.18-8.88-8.82c-3.54-1.19-6.34-5.13-9.03-8.23c-3.33-3.84-5.93-3.17-8.33,0.73
				c-5.54-1.46-10.87-9.68-15.97,0.11c-2.41-1.57-4.55-2.96-6.99-4.55c-5.07,4.35-10.19,8.74-15.54,13.34
				c-0.66-0.95-1.41-2.01-2.41-3.44c-1.4,0.95-2.81,1.92-4.22,2.87c-4.7-4.9-9.62-8.8-16.47-3.51c-5.35-3.15-7.3-1.82-10.38,5.99
				c-2.99,1.6-5.39,2.11-6.48,3.59c-5.06,6.83-9.94,13.8-14.49,20.98c-1.89,2.98-2.75,6.61-4.09,9.94"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M661.85,408c0.17,5.33,0.13,10.68,0.57,15.99c0.28,3.37,1.22,6.68,1.94,10c0.41,1.85,1.52,3.72,1.38,5.5
				c-0.43,5.2,1.77,5.03,5.63,3.61c2.6-0.96,5.55-0.96,8.48-1.41c0.55,4.46,1.37,8.14,1.34,11.81c-0.02,2.5-0.62,5.41-2,7.42
				c-2.83,4.12-2.77,8.19-1.37,12.59"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M729.73,432.5c0.17-3.5,0.55-7.01,0.4-10.5c-0.07-1.53-1.08-3.1-1.92-4.49c-2.83-4.65-5.89-8.73-5.83-15.02
				c0.05-5.31-4.83-9.32-9.69-11.86c-1.17-0.61-3.58,0.05-4.87,0.93c-15.84,10.82-33.89,14.41-52.46,16.45
				c-7.62,0.84-13.92,4.84-20.55,8.32c-6.78,3.55-14.09,7.56-21.42,8.13c-6.25,0.48-9.34,3.2-13.03,6.94
				c-1.81,1.84-4.53,2.83-6.91,4.07c-6.29,3.3-8.85,9.47-6.98,17.01"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M737.71,686c0.66,1.26,1.32,2.52,2.1,4c4.29-5.33,14.12-3.2,18.87,3.5c7.75-1.02,9.62-0.16,10.98,5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M713.76,390.5c4.93-4.45,10.73-4.69,16.98-4.09c7.29,0.7,14.63,0.74,21.95,1.09c2.83,0.13,5.67,0.22,8.48,0.52
				c7.33,0.77,14.63,1.75,21.97,2.43c3.13,0.29,6.32,0.05,9.48,0.05"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M589.47,364.5c-4.34-2.33-8.81-2.62-13.48-1.02c-8,2.74-16.31,0.87-24.45,1.52"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M682.31,576.5c1.16-1.33,2.15-2.9,3.52-3.96c7.42-5.75,14.97-11.34,22.4-17.08c0.82-0.64,1.18-1.88,2.36-3.84
				c-2.24,0.41-3.71,0.32-4.79,0.93c-8.7,4.91-17.25,10.11-26.04,14.84c-2.27,1.22-5.27,1.11-7.93,1.61"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M118.29,274.5c-0.84,5.83-3.62,10.74-7.52,14.97c-7.19,7.81-11.38,16.65-11.07,27.53c0.09,2.98-1.24,5.99-1.88,9
				c-0.85,4-2.36,8-2.36,12c0,8.92-1.99,17.18-6.13,25"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M637.89,1233c-3.83-1-7.62-2.7-11.49-2.86c-9.63-0.42-19.3-0.23-28.94-0.07c-2.17,0.04-4.71,0.36-6.42,1.53
				c-7.33,5-15.89,6.6-24,9.46c-1.15,0.41-2.02,1.61-3.02,2.44"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M411.28,320c-1.12,2.54-0.51,4.49,1.42,6.57c2.01,2.17,3.32,4.99,5.1,7.41c1.54,2.09,3.33,3.99,4.96,6.03
				c1.7,2.13,3.36,4.31,4.99,6.5c1.36,1.81,2.5,3.83,4.05,5.44c1.22,1.27,2.94,2.05,4.44,3.05c-1.33,1.33-2.67,2.66-3.99,4
				c-0.33,0.34-0.62,0.71-0.71,0.82c3.1,1.92,6.33,3.92,9.93,6.15c6.64-8.59,16.35-8.5,25.71-9.5c5.01-0.53,9.98-1.36,14.98-1.96
				c6.91-0.83,13.23,5.17,13.23,12.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M650.87,365c-8.49,0.5-16.98,0.87-25.45,1.56c-3.69,0.3-7.33,1.27-10.98,1.96c-0.19,0.04-0.41,0.49-0.49,0.46
				c-9.84-4.38-17.51,5.88-26.96,4.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M522.59,280.5c-0.17-6.83-0.09-13.68-0.56-20.5c-0.57-8.12-0.77-15.35-7.9-22.53c-7.8-7.86-16.2-5.84-24.51-7.36
				c-3.87-0.7-7.98,0.1-11.95-0.18c-4.25-0.29-7.12,1.19-9.09,5c-0.45,0.87-2.35,1.66-3.37,1.44c-3.39-0.71-6.84-1.54-9.96-2.98
				c-3.78-1.74-6.49-0.47-9.01,2.1c-6.02,6.14-11.99,12.32-17.98,18.49c-0.49,0.51-0.93,1.09-1.51,1.49
				c-6.44,4.42-6.63,5.01-3.98,13.01"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M671.83,1030.5c10.32,9.83,20.86,19.44,30.87,29.58c6.59,6.67,5.49,15.33-1.92,21.92"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M240.58,719.5c-5.97-0.85-11.83-5.75-18.19-0.36c-1.52-0.84-3.31-1.54-4.74-2.69c-2.94-2.36-5.28-1.85-8.1,0.45
				c-2.02,1.65-4.57,4.27-7.13-0.28c-0.73-1.31-4.09-1.4-6.28-1.53c-1.58-0.09-3.2,0.67-5.24,1.15c-1.74,1.19-3.9,2.9-6.3,4.12
				c-0.52,0.27-2.16-1.01-2.87-1.91c-1.05-1.33-1.44-3.23-2.6-4.41c-4.15-4.24-9.53-3.74-12.31,1.51c-1.77,3.35-3.13,5.47-7.6,5.67
				c-5.01,0.22-6.36,5.43-8.59,9.21c-0.81,1.36-1.1,4.51-4.44,2.64c-2.39-1.33-6.39-0.45-5.98-5.08c0.04-0.45-0.41-0.95-0.83-1.85
				c-1.64,0.87-3.42,1.82-5,2.66c-2.18-2.91-4.4-5.86-6.61-8.81"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M554.03,549.5c-4.66,0-9.34-0.29-13.97,0.07c-7.67,0.59-15.31,1.6-22.97,2.43"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M309.96,335.5c-13.14-7.11-32.11-3.36-41.98,7.95c-4.32,4.95-9.74,8.98-11.9,15.56c-0.2,0.6-0.03,1.32-0.03,1.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M309.96,385.5c-1.38,2.43-1.78,5.15,1.08,6.29c1.35,0.54,4.12-0.62,5.3-1.89c3.29-3.52,6.08-7.51,9.07-11.31
				c0.53,0.15,1.06,0.3,1.59,0.45c-0.19,1.82-0.59,3.65-0.52,5.46c0.06,1.68,0.18,4.45,1.03,4.78c1.88,0.73,4.85,1.09,6.28,0.06
				c3.84-2.75,7.18-5.68,12.17-2.44c0.48,0.31,1.95-0.85,2.92-1.4c1.68-0.96,3.33-1.99,5-2.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M366.86,312c1.88-2.59,3.77-5.17,5.65-7.76c0.56-0.09,1.12-0.18,1.68-0.27c1.05,3.01,2.86,5.98,2.98,9.03
				c0.23,5.81-0.09,11.72-0.94,17.48c-1.04,7.09,2.99,12.06,6.09,17.52c0.66,1.17,1.33,2.33,2,3.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M527.58,513.5c-2.99-1-5.98-2.8-8.99-2.85c-8.86-0.15-17.43,1.29-26.06,3.99c-5.91,1.85-12.87,0.35-19.36,0.35"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M869.98,853.5c-3.01-1.35-5.3-3.36-7.77-5.7c-4.73-4.48-7.5-9.74-10.21-15.29c-0.26-0.54-0.54-1.17-1.01-1.48
				c-3.63-2.38-8.51-3.91-10.65-7.23c-2.61-4.03-5.01-6.93-9.77-7.91c-1.31-0.27-2.35-1.89-3.52-2.89c-3.13-2.68-5.64-2.14-7.48,1.5
				c-2.92,5.79-5.79,11.6-8.24,16.5c1.48,2.97,2.92,5.14,3.65,7.52c0.73,2.38,0.81,4.97,1.08,7.47c1.23,11.62,1.26,11.33,12.98,13.08
				c12,1.8,23.03,5.97,30.36,16.49c0.91,1.31,1.25,4.22,0.43,5.3c-1.67,2.19-1.34,5.56-5.76,6.44c-6.4,1.28-13.03,3.74-18.36,7.43
				c-6.52,4.51-11.69,6.72-19.32,2.06c-3.93-2.4-9.8-2.22-14.79-2.18c-9.82,0.08-19.64,0.82-29.45,1.43
				c-1.41,0.09-2.78,0.96-4.86,1.72c5.49,4.62,11.58,4.96,17.34,6.23"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M177.69,601.5c-3.54,4.5-4.65,4.35-7.1-0.27c-4.11-0.63-8.2-0.31-11.26-1.94c-4.33-2.3-6.58-1.79-7.98,3.18
				c-2.83-2.39-9.23-0.05-7.61-6.98c-3.12-0.27-3.95-5.96-8.24-3.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M754.69,423.5c3.19-4.63,7.7-4.48,12.48-3.52c2.03,0.41,4.64,2.94,5.63-1.45c0.09-0.41,2.39-0.84,3.31-0.44
				c2.93,1.27,5.69,2.92,8.77,4.56c0.73-1.58,1.45-3.13,2.16-4.65c4.92,0.05,6.91,8.32,13.34,4.49c1.77,1.15,3.68,2.4,5.6,3.64
				c-0.46,0.62-0.91,1.25-1.37,1.87c-6.97,1.1-14.1-4.91-22.53,0.59c11.51,2.95,15.56,12.71,22.51,19.41"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M645.38,295c1.16-1.5,2.1-3.95,3.54-4.3c2.83-0.69,6.08-0.77,8.9-0.07c4.31,1.07,0.67,4.21,0.97,6.38
				c0.27,1.96-0.68,4.45,0.21,5.9c2.02,3.29,4.76,6.16,7.42,9.01c0.38,0.41,2.04-0.01,2.89-0.46c8.06-4.26,16.42-3.79,24.99-2.02
				c3.42,0.7,7.07,1.39,10.45,0.9c4.68-0.68,8.39,0.14,11.5,3.66c6.42-3.51,12.73-2.77,18.97,0.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M91.84,998.5c-2.55,3.34-5.1,6.68-8.33,10.9c5.39,1.01,9.86,1.18,13.74,2.77c4.04,1.65,7.31,5.12,12.05,5.33"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M576.5,206c4.55,4.04,0.87,7.84-0.59,11.46c-1.44,3.57-1.15,5.14,3.09,5.14c1.33,0,3.08,0.91,3.9,1.97
				c1.49,1.91,2.43,4.26,3.97,7.1c-4.03,1.98-4.53,8.18-10.87,8.14c-1.15-0.01-2.55,3.94-3.44,6.21c-1.21,3.08-2.1,6.3-3.04,9.48
				c-1.7,5.82-3.34,11.66-5,17.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M971.31,608.5c-0.15,3.73,1.52,8.05-4.95,8.82c-1.87,0.22-3.28,4.31-5.28,7.17c-1.35-1.92-2.15-3.07-3.43-4.9
				c-1.83,2.44-3.3,4.44-4.81,6.4c-3.72,4.84-8.37,5.3-15.28,1.33c-0.38,1.48-0.74,2.87-0.87,3.36c-3.05,1.09-5.58,2-9.14,3.28
				c-0.95-1.48-2.55-3.97-4.16-6.47"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M650.37,212.5c-0.17-5.67-0.31-11.33-0.52-17c-0.04-1.03-0.37-2.05-1.83-3.62c-1.71,2.54-3.44,5.07-5.12,7.63
				c-0.41,0.62-0.52,1.49-1.03,1.97c-7.47,7.11-8.48,17.44-13,26c-1.05,1.98-2.62,3.69-3.95,5.52"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M750.69,544.5c-7.26,9.93-18.24,15.41-27.42,23.04c-2.88,2.4-5.36,5.29-8.02,7.96"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M829.55,534.5c-6.49,3.5-12.73,9.27-19.52,10.02c-13.2,1.44-21.46,11.07-32.41,15.95c-2.05,0.91-4.31,1.37-6.47,2.04"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M736.22,811c3.49,0.25,6.98,0.49,11.01,0.77c-0.16,1.27-0.35,2.75-0.64,4.98c8.09,0.81,16.04,1.86,24.08-0.71
				c3.56-1.14,7.3-1.72,10.96-2.55"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M413.78,246.5c-2.66-6.17-5.42-12.29-7.94-18.52c-1.18-2.91-3.09-3.91-6.03-3.49c-9.48,1.35-19.44,1.32-28.31,4.41
				c-7.88,2.75-17.58,3.76-22.3,12.99c-0.96,1.87-4.51,2.34-6.78,3.63c-2.67,1.52-4.82,1.01-7.07-0.95
				c-0.75-0.65-2.26-0.41-3.43-0.58"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M314.95,283.5c0.88-2.82,1.76-5.64,3.07-9.84c-7.45,0.83-14.26,1.48-21.03,2.39c-2.7,0.36-5.33,1.28-7.99,1.95"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M166.71,714c-5.91-1.32-11.14,1.11-16.47,3c-0.99,0.35-2.18,1.19-2.96,0.92c-5.86-2.03-11.41-0.77-17.02,1.03
				c-1.21,0.39-3.47,0.58-3.85-0.02c-3.54-5.63-6.78-1.46-10.3,0.47c-2.8-3.87-5.88-2.78-9.33-0.96c-4.2,2.22-9.84,0.24-13.59,4.43
				c-0.27,0.3-2.62-1.25-4.55-2.22c-2.56,5.78-8.85-0.02-13.05,3.81c-1.99-4.43-4.43-5.81-8.86-2.67c-1.77,1.26-5.18,0.21-7.89,0.21
				c-0.63,1.33-1.26,2.67-2.32,4.92c-1.56-1.68-3.06-2.9-4.06-4.45c-1.91-2.94-4.12-2.58-6.53-0.96c-3.16,2.13-6.21,3.54-8,0.42
				c-5.42,1.01-9.98,1.87-14.91,2.79c-7.81-5.08-10.2-5.08-13.01,0.22c-2.31-0.48-4.6-0.95-7.61-1.57c-0.23-0.46-0.82-1.66-1.41-2.86
				"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M598.46,349.5c-1.83,3.67-3.66,7.33-5.49,11c-4.82,9.67-9.35,19.5-14.6,28.93c-2.81,5.05-6.15,10.25-10.49,13.91
				c-14.26,12.01-25.59,26.5-36.78,41.18c-0.52,0.69-0.69,1.65-1.02,2.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M584.48,375.5c-5.99-1.83-11.93-3.84-17.98-5.46c-12.93-3.46-25.97,2.08-32.46,13.45c-2.52,4.42-5.26,8.71-7.98,13
				c-1.6,2.52-3.55,3.88-6.95,4.22c-4.63,0.45-9.13,2.55-10.45,7.81c-1.63,6.48-4.53,11.81-11.03,14.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M788.13,378c-7.63-0.48-15.15-2-22.58,2.29c-3.8,2.2-9.66,0.61-11.86,6.21"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M67.88,251c-0.33,1.5-0.49,3.07-1.03,4.49c-1.92,5.03-3.3,10.43-6.13,14.9c-3.45,5.45-8.15,10.11-12.31,15.11"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M248.07,779.5c-0.55,5.54-5.68,4.06-8.54,4.76c-9.4,2.28-18.05,0.48-24.43-7.73c-0.27-0.35-0.96-0.48-1.47-0.5
				c-9.14-0.49-18.28-0.96-27.85-1.45c-0.41-0.98-1.21-2.89-2.06-4.92c-6.63,4.44-7.9,4.29-13.51-1.14
				c-5.54,5.25-4.42,8.47,2.9,11.71c2.73,1.21,4.58,4.67,6.52,7.34c1.16,1.59,1.76,3.61,2.55,5.46c0.79,1.86,1.51,3.75,2.31,5.75
				c1.4-0.49,2.54-0.9,4.12-1.46c2.02,4.06,4.04,8.13,6.06,12.2"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M272.03,370.5c-5.16-0.83-10.29-1.89-15.48-2.45c-11.32-1.24-19.5,6.24-27.67,11.86c-4.98,3.43-9.25,7.5-15.15,9.86
				c-3.27,1.31-6.59,4.95-7.87,8.31c-3.36,8.79-9.52,13.57-18.17,15.98c-0.97,0.27-1.68,1.43-2.51,2.18"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M98.33,390.5c6.98,8.14,15.47,13.83,25.93,16.58c2.47,0.65,4.68,2.26,7.01,3.42"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M879.97,733.5c-2,7.5-3.98,15-6.01,22.49c-0.15,0.55-1.05,1.18-0.92,1.48c3.82,8.8-2.31,16.33-3.13,24.77
				c8.38,3.34,16.96,3.02,25.49,1.06c1-0.23,2.41-3.64,1.88-4.73c-4.99-10.32-1.41-20.12,1.08-30.1c0.8-3.22,0.52-6.69,1.16-9.96
				c0.34-1.76,1.58-3.35,2.41-5.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M620.42,547c1.15-6.86-0.62-8.41-7.99-8.06c-8.97,0.42-17.97,0.39-26.95,0.56"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M402.8,796c-7.3,4.65-15.51,3.22-23.46,3.51c-3.66,0.13-7.32,0.33-10.98,0.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M24.96,251c0.06,7.86,2.31,14.87,5.47,22.23c2.4,5.58,5.37,12.27,2.36,19.91c-2.77,7.03-2.63,15.21-3.87,22.86
				c-0.24,1.46-0.96,2.84-1.46,4.26"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M680.81,1026.5c10.7,6.49,21.15,13.65,34.44,13.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M187.67,277c0.53,9.71,1.63,19.43-3.38,28.57c-0.84,1.54-0.12,3.93-0.12,5.93"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M702.78,750c-1.66,0.33-3.31,0.83-4.99,0.97c-10.48,0.89-20.96,1.7-31.44,2.5c-0.48,0.04-1-0.31-1.5-0.47"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M421.77,890.75c-1.06-3.51-3.74-4.18-6.99-4.29c-4.5-0.16-9.85,0.79-13.26-1.31c-4.47-2.75-8.67-2.5-13.19-2.65"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M812.58,750c-0.53,5.45,1.32,10.05,4.92,13.61c-0.56,1.33-1.34,2.35-1.36,3.39c-0.1,3.95-3.35,6.65-7.06,6.06
				c-2.95-0.47-5.98-0.35-8.97-0.58c-1.89-0.15-3.76-0.47-4.49-0.56c0-4.31,0-7.61,0-10.92"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M732.72,266c-6.16,0.17-12.33,0.11-18.46,0.6c-2.99,0.24-5.57,1.59-6.08,5.39c-0.43,3.22-1.23,6.5-2.56,9.45
				c-0.69,1.53-2.72,3.12-4.38,3.41c-3.19,0.57-4.84,1.74-5.01,5.15c-0.33,6.51-0.95,13-1.45,19.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M631.4,668.5c-1.98,2.76-3.96,5.53-6.5,9.06c-1.73-2.49-2.61-3.77-3.77-5.43c-1.41,1.7-2.31,2.79-3.21,3.87
				c-0.83,1-2.01,3-2.44,2.85c-6.93-2.42-6.29,4.5-9.04,7.64c-2.33-2.37-4.66-3.88-6.64-0.31c-3.27-1.68-4.83-6.11-9.37-4.28
				c-0.54,0.22-1.93-0.7-2.41-1.44c-2.69-4.12-6.73-2.64-10.5-3.2c-4.1-0.61-6.89,2.31-10.03,2.39c-3.72,0.09-6.98,0.79-10.53,1.62
				c-3.59,0.85-7.6-0.08-11.43-0.29c-1.8-0.1-3.59-0.34-6.34-0.6c-0.26-0.5-1.14-2.22-2.22-4.32c-1.89,1.02-3.62,2.04-5.43,2.9
				c-0.74,0.35-1.95,0.8-2.43,0.46c-3.88-2.72-7.42-0.95-11.04,0.55c-1.11,0.46-2.5,1.23-3.45,0.92c-2.71-0.9-4.37-0.36-6.14,2.02
				c-1.22,1.63-3.42,2.77-5.4,3.5c-2.59,0.96-5.27,1.29-5.1,5.09c0.03,0.62-1.8,1.43-2.86,1.97c-1.79,0.92-4.05,1.28-5.41,2.59
				c-6.75,6.5-14.08,4.45-20.7,2.21c-7.64,3.76-14.72,7.24-21.8,10.72"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M107.81,1035c-3.25-6.28,2.7-11.47,2.19-17.52c-0.07-0.88,2.46-1.99,3.8-2.98c1.82-1.34,3.61-3.72,5.5-3.81
				c6.55-0.3,12.08-7.4,19.62-2.43c2.13,1.4,6.17-0.07,9.33-0.27c3.91-0.24,8.25-2.45,10.99,2.5c0.11,0.2,0.32,0.48,0.49,0.48
				c12.25,0.55,14.86,13.34,23.46,18.52"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M735.22,325c-6.6-2.26-13.22-3.99-20.39-0.44c-5.36-6.18-12.85-2.85-19.55-3.43c-6.94-0.6-13.98-0.42-20.95-0.02
				c-4.03,0.23-8.03,1.41-11.95,2.5c-0.87,0.24-1.36,1.89-2.03,2.89"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M48.42,272.5c-3.19-5.76-5.33-11.64-4.69-18.52c0.22-2.39-2.05-4.91-2.74-7.5c-0.9-3.41-3.78-3.02-6.06-3.43
				C23.97,241.1,12.98,239.33,2,237.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M321.44,18.5c-3.33,3.67-6.52,7.47-10.06,10.92c-1.19,1.16-3.23,1.49-4.92,2.06c-1.8,0.6-3.78,0.76-5.46,1.57
				c-6.2,2.95-12.45,4.89-19.52,3.61c-1.87-0.34-4.07,1.21-6.4,1.99c1.25,3.71,9.01,1.1,6.43,7.36"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M440.23,344c0-2.17,0.45-4.45-0.08-6.48c-2.13-8.23-1.43-11.02,8.08-10.16c2.86,0.26,6.01-2.28,8.42,1.19
				c5.98-6.12,14.04-7.3,20.52-3.06c5.45,3.56,6.77,8.37,3.99,14.5c6.54,2.77,9.32,8,8.49,16"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M226.61,342.5c-2.66,4-5.41,7.95-7.97,12.01c-3.74,5.95-7.57,11.86-10.94,18.02c-3.43,6.26-3.26,6.44-8.05,8.47"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M116.8,928.5c0,11.17,0,22.33,0,33.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M971.06,661.5c-2.44,5.07-4.88,10.15-7.49,15.58c-3.57-2.89-6.02-2.62-8.87,0.8c-2.75,3.3-6.67,5.61-10.71,8.87
				c-1.16-2.32-2.39-4.78-3.62-7.25c-7.08,2.06-9.74,8.06-12.05,14.6c-2.59-1.76-4.37-2.8-5.55,0.86c-0.78,2.4-2.7,4.51-4.39,6.52
				c-5.15,6.15-10.42,12.19-16.04,18.74c-1.14-1.64-2.02-2.92-3.32-4.78c-1.45,2.98-2.45,5.05-4.08,8.4
				c-1.44-2.82-2.65-4.76-3.42-6.86c-0.63-1.72-0.73-3.64-1.06-5.48c-2.33,0.83-4.68,1.62-6.98,2.53c-0.75,0.3-1.37,0.93-2.05,1.41
				c-0.1-0.44-0.2-0.89-0.3-1.33c-1.88,0.47-3.78,0.89-5.64,1.42c-0.7,0.2-1.32,0.9-2.01,0.94c-8.82,0.56-11.6,8.67-16.97,13.54"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M409.29,237.5c-8.15,1-16.33,1.83-24.44,3.09c-3.08,0.47-6,1.91-9,2.91"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M2.99,543.5c9.69,2.16,19.38,4.31,29.65,6.6c0.4-0.79,1.09-2.19,1.79-3.59c0.5-0.5,1-1.01,1.5-1.51
				c2.03,3.79,4.06,7.59,6.09,11.38c0.73-0.56,1.46-1.13,2.19-1.69c0.64-1.01,1.29-2.03,1.56-2.46c2.2-0.26,4.17-0.49,6.13-0.73"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M971.31,994c-11.05-1.82-22.02-1.29-32.94,1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M55.9,844.5c-0.17,10.83-0.33,21.67-0.5,32.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M195.16,764c3.66,1.17,7.28,2.53,11.01,3.37c0.97,0.22,2.3-1.2,3.46-1.87c-0.83-0.83-1.54-1.88-2.52-2.46
				c-4.04-2.4-8.06-2-11.96,0.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M544.55,559.5c-4.33-0.17-8.95-1.38-12.91-0.27c-6.97,1.96-14.88,0.07-21.11,5.17c-0.72,0.59-2.59-0.16-3.91-0.42
				c-1.18-0.23-2.79-1.31-3.43-0.88c-6.09,4.17-9.06-1.66-12.38-4.32c-2.48-1.99-3.81-4.97-7.68-3.33c-0.36,0.15-1.14-0.14-1.46-0.48
				c-3.42-3.62-8.69-4.22-12.27-6.81c-6.43-4.65-13.64-6.27-20.72-8.6c-4.73-1.56-9.55-2.48-13.52-6c-4.23-3.76-15.47-4.36-19.4-1.57
				"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M830.55,740c-3.63-7.43-7.26-14.86-10.98-22.47c-2.79,1.57-5.76,2.91-5.58-3.03c0.07-2.32-0.75-4.71-1.46-6.98
				c-0.46-1.47-0.31-4.61-3.52-2.13c-0.39,0.3-2.26-0.64-2.85-1.44c-1.84-2.54-3.7-5.15-4.99-7.98c-2.3-5.06-5.17-3.57-8.01-0.93
				c-2.51,2.34-4.59,4.57-8.62,2.67c-1.07-0.51-3.35,1.55-5.78,2.79c0.55,0,0.17,0.11-0.11-0.02c-4.31-1.86-9.32,0.22-13.54-2.38
				c-0.18-0.11-1.43,1.13-1.91,1.92c-1.58,2.62-3.02,5.32-4.52,7.98c-1.81,3.2-3.34,5.94-7.59,2.12c-3.51-3.16-7.39,0.56-7.54,2.91
				c-0.36,5.49-5.52,7.27-7.25,11.82c-1.84,4.84-4.43,9.24-4.69,14.66c-0.07,1.54-1.55,3.01-2.38,4.51"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M589.47,206c1,4.17,1.88,8.37,3.05,12.48c0.5,1.76,1.21,3.82,2.51,4.93c4.73,4.03,5.47,7.8,2.99,13.12
				c-4.19,8.99-7.08,18.39-7.06,28.47"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M324.43,980.5c-6.74,1.61-6.1,7.7-7.46,12.51c-1.6,5.64-3.02,11.33-4.52,16.99c-0.18,0.66-0.55,1.36-0.47,2
				c0.95,7.17,0.55,14.97,3.37,21.32c2.69,6.05,1.74,11.43,1.57,17.18c-0.11,3.76-1.8,5.94-5.97,5.55c-1.47-0.14-2.99,0.28-4.49,0.45
				c-2.63,0.3-4.29-0.67-4.49-3.5c-0.5-7-0.52-14.08-1.65-20.98c-0.92-5.62-2.69-11.24-4.97-16.47c-2.68-6.16,0.54-14.45,7.14-15.95
				c1-0.23,2.73,0.58,3.39,1.46c2.12,2.84,3.91,5.94,5.82,8.94"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M320.44,1067c-2.47,1.22-4.93,2.44-8,3.95c-3.49-3.37-7.36,0.73-11.47,1.57c-2.69,0.55-5.29,1.62-8,1.92
				c-6.7,0.73-10.66,1.82-11.27,8.58c-0.24,2.72,1.87,5.62,2.75,8.49c0.39,1.27,0.37,2.66,0.54,3.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M13.98,1004c1.12-3.82,4.41-2.8,6.99-2.95c6-0.35,7.12-2.22,5.05-7.57c-4.23-10.94-12.77-16.27-23.52-18.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M971.31,896c-14.12,0.18-26.15-7.53-39.43-10.49c-2.48-0.55-4.97-1.13-7.49-1.49c-7.16-1.02-7.16-0.98-9.48,5.98
				c-2.33,6.98-8.07,9.13-16.98,7.06c-11.89-2.76-23.85-4.74-35.95-0.64c-2.14,0.73-4.63,0.41-6.96,0.58c0.17-2.33,0.34-4.67,0.49-7
				c0.04-0.66,0.01-1.33,0.01-2"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M695.29,288c-8.18-1.12-16.3-3.21-24.5,0.41c-3.43,1.52-7.28,2.09-10.94,3.09"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M483.66,701c5.2,0.58,9.34,7.51,15.56,2.62c0.29-0.23,1.83,1.42,2.92,1.84c2.11,0.81,5.22,2.63,6.32,1.83
				c6.12-4.44,11.62-0.54,17.07,0.91c2.95,0.78,5.37,3.27,8.56,0.82c0.21-0.16,0.74-0.15,0.97,0c6.77,4.43,11.71-1.67,17.53-2.75
				c1.71-0.32,4.02,2.61,6.4,4.29c0.58-0.73,1.43-1.57,2.01-2.58c1.82-3.17,3.11-4.39,7.22-1.83c2.96,1.85,6.24,3.25,8.77,5.84"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M464.19,666c1.16,1.17,2.18,2.56,3.53,3.45c2.3,1.51,4.82,2.7,7.78,4.32c0.44-0.3,1.54-1.07,3.67-2.54
				c0.28,0.64,0.61,1.46,0.99,2.26c2.32,4.81,4.62,8.88,11.56,7.41c1.76-0.37,4.57,4.26,6.9,6.59"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M349.89,520c-2.83-2.5-5.29-5.8-8.57-7.32c-5.69-2.64-11.88-4.23-17.91-6.12c-0.86-0.27-1.98,0.27-2.98,0.44"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M486.65,256.5c1,1.33,2.51,2.53,2.91,4.02c1.77,6.59,3.23,13.27,5.19,21.6c1.48-2.9,3.66-5.2,3.18-6.55
				c-2.91-8.16,1.79-15.38,2.4-23.06c0.21-2.6,0.34-4.4-1.7-6.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M82.86,1132.5c-0.17,1.67,0.1,3.55-0.6,4.95c-1.16,2.37-2.94,4.44-5.4,6.05c0.17,1.83-0.27,4.06,0.61,5.43
				c2.77,4.28-3.41,4.14-3.13,7.07c0.26,2.66,1.5,6.19,0.28,7.82c-2.45,3.27-1.55,5.51-0.03,7.52c-1.55,3.36-2.88,6.26-4.22,9.16"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M551.04,264c-7.32,3.17-14.94,5.81-21.83,9.74c-3.12,1.78-4.79,6.1-7.12,9.26"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M797.61,534c-1.33,2.17-2.28,4.73-4.06,6.43c-6.48,6.18-13.66,11.68-19.77,18.19c-5,5.33-11.08,8.72-17.13,12.33
				c-0.81,0.48-1.97,0.38-2.97,0.54"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M361.37,355.5c-4.62-0.48-8.7,0.56-12.59,3.35c-1.32,0.95-3.9,0.29-5.88,0.12c-4.27-0.35-8.72-1.8-11.05,1.68
				c-4.09-1.18-7.5-2.17-10.91-3.15"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M261.54,897c-5.61-1.2-9.06,2.72-12.97,5.5c-11.49,8.15-22.96,16.33-34.44,24.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M476.67,314.5c0.66,3.8,8.26,12.2,11.46,11.83c2.27-0.26,4.71-1.77,6.44-3.4c4.19-3.98,8.77-3.18,13.55-1.92"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M688.3,1022c9.45,6.08,19.51,10.79,30.45,13.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M443.23,364c0.52-6.09-0.89-11.87-3.02-17.49c-1.35-3.56-3.04-6.98-4.44-10.52c-1.11-2.79-2.02-5.66-3.02-8.49
				c-2.38-6.74-3.94-7.39-10.98-7.63c-5.21-0.18-10.38-1.6-16.53-2.63c-3.85,5.12-5.85,11.7-3.95,19.27c0.12,0.47,0.11,1.02,0,1.49
				c-2.46,10.94,0.67,22.05-1.04,32.99c-0.09,0.56-0.95,1.01-1.44,1.51"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M730.73,509c-6.14,2.46-12.28,4.92-19.01,7.62c-0.36-0.26-1.82-1.31-3-2.16c-10.69,4.28-14.46,12.88-15.92,23.04"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M222.11,348.5c-7.15-3.41-14.28-1.36-21.48-0.07c-4.09,0.73-8.31,0.74-12.47,1.07"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M520.59,263c-3.11-1.45-6.22-2.89-10.76-5.01c0.97,8.35-1.39,15.83,1.86,22.97c2.09,4.59,2.99,9.12,1.92,14.04"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M940.36,435.5c-6.82,0.67-13.64,1.44-20.47,1.96c-4.42,0.34-5.48,0.74-8.97,5.15c4.43,0.49,8.48,0.74,12.45,1.46
				c2.82,0.51,5.56,1.66,3.01,5.43"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M2,316.5c1.29,2.8,2.98,4.54,6.44,5.22c3.04,0.6,5.75,3.03,8.52,4.79c6.13,3.92,12.66,5.91,19.98,4.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M35.44,1126c7.82,2.17,15.65,4.29,23.45,6.52c3.69,1.06,7.3,2.39,10.99,3.46c3.8,1.1,7.65,2.02,11.47,3.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M577.49,546c4.04-2.93,7.91-5.5,8.77-11.46c0.61-4.24,7.37-6.68,12.2-6.61c7.32,0.1,14.64-0.48,21.96-0.36
				c5.73,0.09,6.98,2.73,3.99,7.43c-1.16,1.83-2.33,3.67-3.49,5.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M863,624.5c-1.16,0.33-2.31,0.79-3.5,0.96c-1.14,0.17-2.32,0.04-3.99,0.04c0,1.5,0.19,2.55-0.05,3.49
				c-0.31,1.22-0.74,3.14-1.49,3.34c-1.52,0.4-3.73,0.43-4.87-0.43c-5.41-4.07-5.73-3.96-9.64,1.55c-1.96,2.77-4.62,5.19-8.46,2.12
				c-0.61-0.49-1.94-0.07-2.94-0.07c-1.83,0-4.23,0.74-5.38-0.14c-3.43-2.63-4.64-1.87-5.15,2.13c-0.32,2.53-1.63,3.45-4.47,2.61
				c-3.39-1-7.01-1.23-11.76-2c-0.41,0.31-1.96,1.47-3.45,2.6c-3.68-3.24-8.03-2.6-11.28,0.73c-2.46,2.52-5.58,4.4-8.41,6.57"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M730.23,488.5c2.33-0.5,4.66-1.38,6.99-1.37c1.67,0.01,3.87,0.78,4.83,2c0.81,1.03,0.67,3.43,0.06,4.82
				c-1.11,2.51-2.92,4.7-4.37,7.05c-2.36,3.82-4.48,7.79-7.05,11.46c-2.23,3.19-4.5,6.61-7.53,8.91c-5.39,4.1-8.61,9.29-10.39,15.63"
				/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M809.09,1012.5c-2.82,0.72-5.63,1.45-8.45,2.17c-0.55,0.48-1.09,0.97-1.64,1.45c2.03,0.46,4.06,0.92,6.09,1.39
				c-0.46,0.84-0.92,1.69-1.38,2.53c-8.04,0.65-16.07,1.31-25.38,2.06c2.73,2.34,4.52,3.87,6.3,5.4"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M237.59,330.5c-0.83,1.17-1.99,2.22-2.44,3.52c-2.25,6.48-4.73,7.46-11.55,6.64c-5.39-0.65-10.98-0.03-16.45,0.4
				c-5.01,0.39-9.99,1.27-14.98,1.94"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M152.73,938.5c-0.17,9.67-0.33,19.33-0.5,29"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1.5,302.5c4.48,3.68,7.2,9.11,13.4,11.23c4.89,1.67,9.1,5.38,13.54,8.29c2.59,1.69,3.3,5.92,7.51,5.55
				c0.45-0.04,1.41,0.91,1.43,1.43c0.35,8.11,6.93,10.31,12.51,13.54c1.18,0.68,2.02,1.95,3.02,2.95"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M184.68,312.5c1.33,2.83,2.49,5.77,4.03,8.48c3.48,6.13,3.14,12.99,3.15,19.52c0,3-3.51,5.91-5.14,9.02
				c-2.32,4.42-5.34,8.77-6.35,13.52c-3.08,14.57-10.16,26.74-20.15,37.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M950.84,776c-7.49,7.17-14.91,14.4-22.52,21.43c-1.23,1.14-3.27,1.4-4.93,2.07"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M139.26,912c-0.17,12.83-0.34,25.67-0.49,38.5c-0.04,3.83-0.01,7.67-0.01,11.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M126.28,913.5c-3.16-1.17-6.25-2.65-9.5-3.43c-5.26-1.26-10.6-2.36-15.97-2.96c-2.96-0.33-3.43-1.98-3.44-4.11
				c0-3.5,0.38-7,0.44-10.5c0.15-9.28,0.45-6.72-7.49-7.85c-7.22-1.03-14.63-0.65-21.94-1.19c-8.67-0.65-17.3-1.69-25.96-2.41
				c-1.97-0.16-3.99,0.28-5.99,0.45"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M550.54,276.5c0-1.33-0.43-2.87,0.08-3.96c2.15-4.62,2.25-8.71-0.9-13.16c-1.23-1.74-0.73-4.88-0.63-7.37
				c0.2-5.01-1.55-8.85-5.47-12.08c-2.98-2.45-5.39-5.59-8.05-8.42"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M9.98,1003c8.32,2.43,12.92,10.16,12.68,20.5c-0.13,5.31,1.75,10.67,2.81,15.99c1.45,7.34,3.03,14.66,4.43,22.01
				c0.21,1.1-0.28,2.32-0.45,3.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M74.37,1136.5c0.73-4.96,2.23-10.33-1.61-14.39c-3.63-3.84-7.76-7.99-12.5-9.73c-3.8-1.39-9.1-0.12-13.26,1.33
				c-4.13,1.43-7.8-0.53-9.29-2.42c-4.71-5.94-10.98-9.25-17.25-12.76c-1.03-0.58-2.34-1.06-3.47-0.97
				c-4.34,0.34-8.67,0.87-12.97,1.49c-0.89,0.13-1.67,0.95-2.5,1.45"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M650.37,181.5c-3.66,0-7.4-0.47-10.96,0.11c-5.89,0.94-11.66,2.56-17.49,3.89"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M696.29,1017c8.92,5.24,18.14,9.71,28.45,11.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M56.9,901c0.23-4.83,0.45-9.66,0.65-13.85c-0.81-0.82-0.99-1.13-1.15-1.13c-3.99,0.14-9.61-1.18-11.52,0.9
				c-1.91,2.08-0.12,7.58,0.04,11.58"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M604.95,340c-5.22-1.99-10.31-2.44-16.02-1.21c-7.13,1.54-14.6,1.48-21.91,2.25c-2.36,0.25-4.66,0.96-6.99,1.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M835.05,728.5c1.83,4,3.3,8.23,5.62,11.92c1.57,2.5,2.16,4.66,2.01,7.59c-0.15,2.97,0.92,5.98,1.34,8.99
				c1.18,8.35,2.3,16.71,3.52,25.58c-8.04,1.64-16.58,3.99-25.15-1.59c2.37-7.17,4.82-14.82,4.17-22.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M952.84,803.5c-8.98,3.5-17.97,7-26.95,10.5c-0.49,0.19-1,0.34-1.5,0.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M856.51,332c3.28,1.17,6.56,2.35,10.52,3.76c0.9-0.14,3.03-1.01,4.93-0.67c7.86,1.43,15.67,3.19,23.46,4.96
				c0.93,0.21,1.68,1.27,2.51,1.94"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M233.09,904c-1.33,1.5-2.46,3.27-4.03,4.45c-5.73,4.29-11.65,8.33-17.41,12.58c-1.5,1.11-2.69,2.63-4.02,3.97"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M450.71,234c2,5.73,5.08,10.64,9.33,15.14c3.41,3.62,5.48,8.52,8.14,12.86"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M923.89,386c9.48,0.33,18.97,0.67,28.45,1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M279.51,366c6.08-0.27,11.85,0.33,16.54,4.93c0.68,0.66,2.26,0.51,3.43,0.55c2.66,0.07,5.32,0.02,7.99,0.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M179.69,277c0.36,6.04,1.78,11.88-0.6,18.15c-0.92,2.41,2.6,6.52,4.1,9.85"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M942.36,612.5c-1.83-1.67-3.5-3.58-5.52-4.95c-4.79-3.24-7.54,2.24-11.94,3.04c-0.94-1-2.08-2.19-3.92-4.14
				c-0.88,1.89-1.67,3.75-2.6,5.54c-0.55,1.06-1.12,2.47-2.04,2.86c-0.67,0.28-2.14-0.64-2.89-1.38c-3.09-3.07-7.7-2.66-10.01,1.04
				c-1.03,1.65-1.79,3.52-3.05,4.95c-1.99,2.26-3.77,3.65-7.58,1.86c-2.92-1.37-7.78-1.62-9.74,3.61c-1.54-1.1-2.81-2.01-4.09-2.92"
				/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M147.24,935.5c-0.33,4.17-0.76,8.33-0.97,12.5c-0.27,5.16-0.36,10.33-0.52,15.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M848.77,459c-4.74,1.17-9.46,2.45-14.23,3.47c-4.63,0.99-9.31,1.7-13.97,2.53"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M362.87,775c0.5-1,1.56-2.1,1.41-2.98c-1.11-6.16-11.87-15.29-18.34-16.67c-2.85-0.61-5.32-2.92-8.04-4.31
				c-0.86-0.44-2.22-0.84-2.93-0.47c-4.41,2.35-8.69,4.92-13.02,7.42"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M963.82,305c3.28,4.69,3.74,9.97,3.57,15.5c-0.08,2.65,0.89,5.33,0.85,8c-0.05,3.67-0.59,7.33-0.93,11"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M952.34,789.5c-7.99,5.5-15.86,11.17-24,16.43c-4.84,3.13-6.68,6.47-2.99,11.59c0.45,0.63,0.37,1.64,0.54,2.47"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M476.17,344c-9.06-1.34-16.97,4.41-25.96,4.28c-2.3-0.03-4.65,3.39-6.98,5.22"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M57.9,1155.5c-4.92-1.79-1.44-7.17-4.32-10.16c-1.32-1.37-0.29-4.79-1.32-6.76c-1.02-1.97-3.28-3.3-5.83-5.69
				c-2.2,4.61-4.34,9.11-6.49,13.61"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M50.91,354c-0.23-1.83-0.46-3.67-0.79-6.28c1.18-0.79,2.86-1.93,3.45-2.33c8.92,3.82,17.02,6.89,24.74,10.73
				c5.13,2.55,9.51,6.59,14.58,9.3c7.81,4.16,15.93,7.75,23.91,11.59"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M191.17,971c0-7.52,0-15.03,0-21.5c10.49,0,19.48,0.32,28.45-0.08c11-0.49,21.96-1.65,32.95-2.4c13.14-0.9,26.29-1.7,39.43-2.5
				c1.82-0.11,3.66-0.02,6.59-0.02c-2.59-3.73-4.29-6.89-6.66-9.44c-3.78-4.06-6.17-8.35-4.28-14.06c-6.47,0-12.3-0.17-18.11,0.16
				c-1.06,0.06-2.01,2.18-3,3.34"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M319.94,1060.5c-4.33,1-8.61,2.29-12.99,2.92c-4.69,0.68-9.47,0.74-14.21,1.08"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M775.15,747c6.82,0.33,13.65,0.97,20.46,0.87c3.39-0.05,5.75,1.7,5.22,4.07c-0.7,3.09-2.92,7.6-5.31,8.19
				c-5.88,1.46-12.22,1.04-18.38,1.37"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M148.74,934c3.49-1.34,4.37-3.88,4.08-7.51c-0.36-4.48-0.09-9.01-0.09-12.96c-9.05-2.1-17.5-4.07-25.95-6.03"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M607.94,554.5c-6.71,4.79-13.07,10.06-20.91,13.13c-2.5,0.98-4.38,3.53-6.54,5.37"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M250.56,893.5c4.45-1.93,8.67-1.91,11.48,0.59c0,10.89,0,20.9,0,30.91"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M732.22,332.25c-1.83-0.75-3.6-1.8-5.5-2.19c-5.75-1.21-11.48,0.05-17.6-1.51c-8.94-2.29-18.83-0.91-28.31-0.98
				c-0.66,0-1.33,0.93-2,1.43"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M672.33,328.75c-12.31-1.58-24.6-3.37-36.95-4.65c-4.59-0.48-9.36-0.14-13.94,0.57c-1.99,0.31-3.69,2.49-5.52,3.83"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M553.54,214c-0.36,6.2-3.31,11.15-6.78,14.76c2.87,4.59,5.6,8.9,8.24,13.26c0.29,0.49,0.22,1.37,0.01,1.97
				c-1.92,5.66,1.49,7.7,6.02,9.01"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M863.5,376c2.68,2.83,0.74,5.75-0.06,8.48c-1.82,6.22-3.94,12.35-5.93,18.52"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M748.2,652c6.85-0.73,12.32-4.55,17.98-7.98c3.33-2.02,9.47-0.28,10.97,2.98c1.24,2.7-1.71,9.27-4.99,9.26
				c-4.21-0.01-4.71,2.71-5.9,5.26c-4.71-1.36-11.55,3.96-12.03,9.48c-0.26,2.97,0.54,5.75-4.59,4.75c-3.24-0.63-5.66,2.54-7.57,5.66
				c-1.81,2.96-4.65,5.39-8.88,3.67c-0.42-0.17-1.33,0.87-2.34,1.58c-2.88-4.78-5.83-3.86-8.64,0.64c-1.35-0.9-2.58-1.72-4.53-3.02
				c0.62,6.8-6.56,6.36-8.51,10.92c-3.23-1.87-5.08-1.12-6.01,2.76c-0.64,2.65-1.42,6.6-6.32,3.44c-2.5,3.88-5.67,7.17-11.04,7.23
				c-0.84,0.01-1.6,1.93-2.52,2.84c-1.09,1.08-2.88,3.12-3.39,2.85c-7.49-3.88-7.94,4.43-10.69,7.06c-2.6,2.49-2.12,8.26-2.84,12.61
				c-0.35,2.14-0.37,4.33-0.53,6.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M280.51,1084.5c-8.98,3.5-17.97,7-26.95,10.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M12.98,936.5c-3.66,1-7.32,1.99-11.48,3.12c0,11.04,0.37,22.23-0.12,33.37c-0.4,9.11,5.2,15.98,7.54,24.02
				c0.45,1.54,0.06,3.31,0.06,4.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M271.03,851c-8.98,0.33-17.97,0.67-26.95,1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M606.94,190.5c-6.1,0.54-10.99,2.07-15.91,7.06c-4.12,4.17-10.97,7.49-18.03,7.43c-3.33-0.03-5.75,1.06-8.72,3.17
				c-4.59,3.27-10.64,4.74-16.26,6.24c-9.68,2.58-11.66,4.26-12.4,14.1c-0.68,9.22-5.06,15.12-14.03,17.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M756.68,427.5c-5.32,1.33-10.6,2.92-15.98,3.95c-11.45,2.17-22.95,4.05-34.43,6.05"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M340.41,372.5c9.11-8.62,20.09-7.63,30.91-5.34c6.28,1.33,12.05,3.68,18.6,0.55c2.57-1.23,6.23-0.2,9.39-0.2"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M642.38,197.5c-1.83,0-3.72-0.29-5.48,0.07c-2.88,0.59-5.62,1.88-8.51,2.38c-7.17,1.23-8.79,3.48-7.5,10.56
				c1.45,7.98,3.26,15.96,3.78,24.01c0.25,3.91-1.1,8.75-3.49,11.8c-3.32,4.25-4.63,8.69-5.25,13.69"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M47.42,946c-2-2.5-3.53-6.41-6.07-7.25c-9.11-2.98-18.55-4.95-27.88-7.21c-0.75-0.18-1.65,0.29-2.48,0.45"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M723.24,402c4.6-0.27,8.84,0.13,10.49,5.5c0.11,0.35,0.24,0.82,0.5,0.98c5.31,3.2,10.63,6.36,15.96,9.52"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1.5,732c3.64-1.6,6.95-2.73,10.17,1.35c0.91,1.16,4.14,0.65,6.3,0.62c4.65-0.08,9.2-0.72,12.66,3.9
				c1.02,1.36,4.45,2.03,6.25,1.43c3.16-1.05,5.37-0.28,8.11,1.08c3.07,1.52,7.53,1.17,9.72,3.33c3.78,3.74,10.17,2.97,12.75,8.26
				c0.36,0.73,1.73,0.9,2.4,1.56c1.42,1.41,2.69,2.97,4.02,4.47"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M35.44,244c-4.22,4.42-8.75,7.32-15.5,6.16c-5.68-0.98-9.57,3.57-13.34,6.96c-2.42,2.18-3.77,5.54-5.6,8.38"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M732.22,1021c-4.99,5.67-10.12,11.22-14.87,17.08c-1.17,1.45-1.57,3.93-1.52,5.92c0.23,8.46-1.19,16.21-8.07,22"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M652.86,1009c2,2.67,3.98,5.34,5.99,8c7.94,10.48,16.99,12.97,28.94,4.49c6.03-4.28,12.94-7.81,17.72-13.21
				c5.45-6.15,9.59-2.88,14.76-0.86c6.02,2.35,12.28,4.08,18.44,6.08"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M190.67,821.5c7.32,0.83,14.65,1.59,21.96,2.53c3.68,0.48,7.32,1.3,10.99,1.97"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M84.35,1011c-4.92,8.74-5.05,17.59-0.49,26.5c3.62,7.09,6.28,14.91,13.98,18.99c1.12,0.59,2.32,1.01,3.49,1.51"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M396.31,610.5c-1.33-1.33-2.93-3.99-3.95-3.78c-5.89,1.2-9.52-3.77-14.93-4.9c-1.11,2.24-2.25,4.51-3.43,6.88
				c-1.85-0.97-3.43-2.34-5.17-2.57c-1.92-0.25-4.59-0.15-5.86,0.99c-3.87,3.48-6.76,7.85-13.27,4.2c-1.48-0.83-5.25,2.43-8.71,4.19
				c-4.29-0.21-8.48-10.63-14.57-1.51"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M340.91,370.5c-0.33-0.83-0.65-1.67-1.01-2.49c-0.09-0.2-0.32-0.34-0.51-0.52c-4.19,1.17-8.39,2.35-12.95,3.63
				c-6.57-5.29-14.01-4.46-19.95,2.92c-3.51,4.35-6.71,8.96-10.01,13.48c-2.21,3.03-2.12,6.23-1.12,9.42
				c6.64,0.46,8.78-4.99,12.59-7.93"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M644.38,266.5c-2.5-5.49-3.38-11.12-0.53-18.49c-3.84,1.28-6.57,1.82-8.92,3.06c-4.94,2.61-9.67,5.63-14.52,8.41
				c-0.9,0.51-1.96,0.89-2.98,0.99c-6.82,0.73-13.7,1.01-20.43,2.2c-2.84,0.5-5.38,2.79-8,4.35c-1.23,0.73-2.32,1.68-3.51,2.48
				c-6.26,4.16-13.28,4.06-20.46,4.18c-4.67,0.08-9.6,1.16-13.92,2.97c-8.85,3.7-17.29,8.35-26.06,12.26
				c-2.64,1.18-2.37,2.77-2.95,5.03c-1.09,4.24-1.94,8.26-1,12.56"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M164.21,987c1.64,2.75,3.29,5.5,4.98,8.34c1.22-0.77,2.74-2.15,4.53-2.76c4.09-1.41,10.64,4.29,10.93,9.43
				c0.25,4.37,0.68,8.35,2.63,12.67c2.07,4.57-0.9,10.06-3.09,14.83c-0.59,1.29-1.83,3.32-1.38,3.89c3.33,4.17,0.69,8.79,1.37,13.11"
				/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M855.51,842c-8.49,3.17-16.97,6.33-25.46,9.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M88.85,993c3.73,8.12,11.64,9.26,18.93,11.62c1.59,0.51,2.89,2.41,3.98,3.92c1.08,1.49,1.72,3.29,2.55,4.96"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M423.26,269.5c1,9.67,1.98,19.34,3.03,29c0.09,0.86,0.63,1.67,0.97,2.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M712.76,1003.5c1.5-2.17,2.6-5.46,4.61-6.16c1.76-0.62,4.46,1.96,6.89,2.6c6.28,1.67,12.63,3.06,18.95,4.56"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M155.73,969.5c4.82-0.83,9.63-2.09,14.48-2.37c4.12-0.24,8.3,0.65,12.72,1.06c0-4.03,0-7.86,0-11.63
				c-7.91,0.2-6.12,5.86-6.24,10.44"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M971.31,458c-4.99-3.83-9.94-7.72-15.01-11.45c-1.05-0.77-2.55-0.94-3.84-1.38c-0.37,0.45-0.75,0.89-1.12,1.34
				c2.83,2.83,5.28,6.31,8.6,8.31c3.18,1.92,3.27,3.86,2.04,7.44c6.53,0.85,6.34,7.25,9.33,11.25"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M721.24,992c8.32,1.83,16.64,3.67,24.96,5.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M873.48,373.5c-12.36,2.15-24.83,3.95-35.44,11.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M548.05,257.5c-8.32,3.5-16.64,7-24.96,10.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M233.09,340c-1,5-1.87,10.03-3.04,14.99c-0.76,3.22-1.95,6.34-2.95,9.51"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M378.34,314c6.49,1.17,13,2.21,19.45,3.58c1.78,0.38,3.34,1.75,5.01,2.67"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M41.43,841.5c-3.16,2.33-6.75,4.26-9.39,7.09c-4.35,4.67-8.08,9.91-12.08,14.91"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M274.52,1114c-0.64-5.66-4.78-7.65-9.49-8.99c-4.4-1.25-8.69-2.89-10.4-7.54c-1.32-3.59-4.04-3.27-6.56-2.9
				c-7.16,1.06-14.2,2.6-18.48,9.43"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M29.45,568.5c1,2,1.56,5.08,3.11,5.7c1.63,0.66,4.31-0.82,6.32-1.8c0.9-0.44,1.28-1.96,2.51-4.03c0.76,3.03,1.27,5.1,1.86,7.43
				c6.38-3.05,11.15-0.21,15.71,3.75c0.91-0.58,1.84-1.17,3.07-1.96c0.68,2.7,1.27,5.05,1.86,7.4"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M405.29,569c-5.52,0.32-9.93-2-13.43-6.04c-1.34-1.54-2.53-1.55-4.51-1.74c-3.75-0.36-7.54-2.02-10.96-3.8
				c-5.8-3.02-11.55-5-18.01-2.42"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M353.88,1005.5c-2.16,6.73,0.79,13.31,0.53,20c-0.2,5.25,0.91,9.85,5.31,13.67c2.15,1.87,1.97,5.39-1.35,7.33
				c-3.99,2.33-7.99,4.67-11.98,7"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M266.54,1060.5c-4.16,0.83-8.29,1.89-12.49,2.43c-3.79,0.49-7.64,0.4-11.47,0.57"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M852.02,833.5c-9.03,1.38-16.77,5.59-23.96,11"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M273.02,1165.5c1.16,2.37,4.54,4.19,1.66,7.64c-0.27,0.32,1.78,2.57,2.92,4.16c0.46,1.5-5.8,4.36,0.87,5.43
				c0.77,0.12,2.06,4.29,1.36,5.12c-3.23,3.88-1.14,7.76-0.82,11.65"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M243.58,986c1.66,1.33,3.66,2.41,4.93,4.05c4.04,5.24,5.19,11.57,6.05,17.95"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M154.73,842c-5.99,0.5-11.97,1.3-17.97,1.39c-3.6,0.05-5.73,1.67-8.04,4.06c-5.33,5.5-5.56,12.12-4.93,19.06"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M43.92,843c1.5,0,2.99-0.02,4.49,0c8.66,0.11,9.45,0.36,11.98,8.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M60.39,852c1,1.17,1.77,2.87,3.04,3.4c4.17,1.77,6.31,4.96,7.45,9.1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M864.24,851c-6.41,2.33-12.86,4.55-19.18,7.08c-1.55,0.62-2.69,2.25-4.03,3.42"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M346.4,1175.5c-1.86,7.48,1.26,14.38,2.5,21.5c0.12,0.68,0.33,1.33,0.49,2"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M655.86,961.5c6.72-4.15,13.57-1.61,20.47-0.56c7.28,1.11,14.63,1.74,21.95,2.56c4.05,0.45,7.28,2.13,8.96,6.02
				c1.27,2.94,0.44,5.4-1.92,7.55c-0.51,0.46-0.38,1.61-0.55,2.45"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M262.04,1008.5c3.14,2.95,8.02,1.69,11.2,5.83c5.07,6.58,5.92,8.05,2.94,15.24c-0.49,1.18,1.17,3.27,1.83,4.93"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M209.14,775.5c-0.3-2.54-1.95-5.81,3-5.5c5.66,0.35,11.31,0.7,16.97,0.99c2.07,0.1,4.25-0.14,4.04,3.02
				c-0.02,0.31,0.63,0.97,0.95,0.95c7.69-0.35,14.97,8.59,22.57,0.57c4.83,5.75,6.91,6.14,14.16,2.84c1.18,1.33,2.43,2.74,3.67,4.13
				c0.37,0,0.78,0.13,1.02-0.02c5.47-3.51,8.23-2.45,11.06,3.99c0.55,1.24,0.25,4.47,3.47,2.61c0.16-0.09,1.42,0.96,1.38,1.42
				c-0.39,4.91,3.72,4.74,6.56,6.01"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M486.15,115.5c-7.99,1-15.98,1.96-23.95,3.05c-0.88,0.12-1.67,0.95-2.5,1.45"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M205.64,951c0,3.5,0,7,0,10.86c5.5-0.29,10.99-0.57,16.47-0.86"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M367.86,398c5.59,1.63,6.17,2.6,3.44,6.97c-1.83,2.93-1.62,7.28-5.93,8.56c-2.21,0.66-4.33,1.64-6.5,2.47
				c-0.83-3-3.07-6.52-2.19-8.89c1.62-4.34,5.25-7.79,10.18-9.11"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M440.73,383.5c-12.48,0.1-24.96-3.22-37.43,0"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M685.81,260c-1.11,4.72-2.23,9.44-3.56,15.07c7.6-0.14,16.04-0.99,23.02,4.43"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M613.43,578.25c2-3.92,4.5-7.67,5.86-11.79c1.7-5.16,2.66-10.59,3.44-15.98c0.14-0.98-2.15-3.31-3.31-3.31
				c-7.49,0.01-14.97,0.5-22.46,0.84"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M869.49,559.5c-4.56,4.37-4.72,4.96-2.85,11.5c5.01,0,10.16,0,14.82,0c2.97-4.13,5.99-8.31,9-12.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M124.78,1043c-1.66-1-3.23-2.58-5.01-2.88c-3.97-0.68-7.48-1.68-11.13-3.84c-3.57-2.11-8.62-1.54-12.78-2.89
				c-4.18-1.36-7.84-0.99-11.52,1.11"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M949.85,813c1.78,5.56-1.15,9.73-3.95,14.03c-0.96,1.47-1.48,3.27-2.02,4.98c-1.13,3.58-2.54,6.56-7,7.06
				c-6.85,0.76-13.65,1.94-20.47,2.94"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M354.88,728.5c-3.02-2.06-5.24-5.51-10.15-2.8c-1.82,1-5.69-0.99-8.28-2.29c-4.54-2.28-8.11-0.99-11.52,2.08"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M290.99,1172.5c-1.82,5.27-3.64,10.55-5.57,16.14c-0.28,3.27-0.6,7.06-0.92,10.86"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M249.07,903c0.17,7.5,0.33,15,0.5,22.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M737.71,1253c-3.83-6-9.07-8.44-15.95-5.93c-1.84,0.67-3.35,2.26-5.02,3.43c1.5,1.17,2.87,3.08,4.52,3.36
				c5.45,0.94,11.07,3.58,16.45-0.36"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M28.95,201.5c-8.98,1-17.97,2-26.95,3"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M272.03,1165.5c-1.46,1.7-2.92,3.4-4.39,5.12c0.91,0.87,1.94,1.84,2.96,2.81c-3.31,3.46-4.12,5.69-4.06,11.07
				c0.02,1.41-2.54,9.28-3.42,11.53c-0.32,0.81,0.26,1.97,0.42,2.97"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M516.1,261.5c-1.46,7.79-1.85,15.35,4.49,21.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M563.52,210.5c-1.96,8.67-0.67,17.29-0.2,26.01c0.52,9.71-0.59,19.36-6.11,28.1c-1.77,2.8-1.5,6.89-2.17,10.39"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M124.28,1135.5c-1.3,3.04-0.9,7.11-1.98,9.26c-1.92,3.84-2.17,8.2-4.51,11.74"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M971.31,370c-2.99-0.17-6.08-0.85-8.96-0.35c-2.78,0.48-6.35,1.46-7.82,3.49c-3.87,5.34-9.04,4.22-14.17,4.4
				c-4.83,0.16-9.65,0.63-14.48,0.96"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M8.98,1108.5c-1.33,3.33-2.78,6.63-3.97,10.01c-2.04,5.81-4.13,11.65-3.52,17.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M970.81,968.5c-3.4,0.88-6.8,1.77-10.75,2.79c2.79,5.36,7.18,5.47,11.25,6.21"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M241.08,1057.5c0,0.17-0.02,0.34,0,0.5c1.84,10.41,1.55,11.01-8,13.96c-2.92,0.9-5.98,1.37-8.97,2.04"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M727.23,770.5c-7.58-0.09-15.1,0.12-21.96,4"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M294.49,252c5.27,5.43,5.12,11.5,0.61,16.6c-3.55,4.01-6.47,8.71-9,13.45c-2.92,5.47-5.1,11.17-10.48,15.08
				c-3.1,2.25-0.21,5.56,0.4,8.37"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M869.98,777c-7.25,1.49-14.78-1.02-21.96,1.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M830.55,969.5c8.76-1.25,20.38,0.93,24.1,4.64c-1.48,4.05-4.71,3.93-8.12,3.36"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M773.65,280c8.15,0.5,16.3,1,24.46,1.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M463.19,502.5c1-0.67,1.93-1.49,3.02-1.94c0.71-0.3,1.64-0.06,4.11-0.06c-1.99,4.66-3.83,8.24-5.08,12.02
				c-2.96,8.98-10.87,13.09-17.49,18.53c-2.07,1.7-3.7,3.95-5.53,5.95"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M276.52,881.5c0,2.33,0,4.67,0,7c-2.33,0.33-4.66,0.66-7.49,1.07c0-2.57,0-5.07,0-7.57"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M942.86,830.5c-7.15,1.83-14.4,3.37-21.43,5.59c-5.89,1.86-10.49,13.09-7.99,18.4c0.51,1.08,1.09,2.35,2.01,2.95
				c2.94,1.92,2.85,4.38,2.59,7.57c-0.48,5.96-0.14,11.99-0.14,17.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M275.02,680.5c-4,6.15-12.63,4.38-17.05,9.94c-0.39,0.49-1.97-0.15-2.91,0.1c-9.99,2.65-20.85,2.03-29.96,7.97"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M286.5,969.5c0,2-0.22,4.04,0.09,5.99c0.17,1.08,1.24,2.01,1.91,3.01c1-1,2.6-1.86,2.86-3.03c0.47-2.06,0.13-4.3,0.13-6.47"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M150.74,678.5c-0.77,2.57-1.54,5.14-2.47,8.24c-2.82-1.26-5.41-3.72-6.5,0.71c-2.97-3.99-4.96-7.4-10.19-3.19
				c-2,1.61-6.19,0.27-9.28,0.84c-1.97,0.36-3.75,1.76-5.78,2.77c-0.86-0.59-1.96-1.35-3.46-2.39c-0.95,1.9-1.76,3.5-1.93,3.85
				c-4.62-0.5-8.46-1.04-12.31-1.22c-0.63-0.03-1.46,1.47-1.96,2.39c-1.25,2.29-2.36,4.65-4.18,8.3c-1.78-3.85-2.8-6.09-3.83-8.32"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M183.18,968.5c3.49,1,6.98,2.78,10.48,2.82c5.55,0.07,10.85,0.41,15.43,3.74c1.77,1.29,3.58,3.04,4.43,4.98
				c2.21,5.04,4.81,9.94,3.75,15.98c-0.65,3.69,0.39,7.65,0.3,11.47c-0.14,5.5-0.62,11-0.95,16.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M283.01,986.5c-7.15,0.5-14.31,1-21.46,1.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M331.42,767.5c-4.17-4.89-4.51-5.05-8.74-3.93c-0.97-5.11-3.29-6.81-8.82-4.84c-2.95,1.05-6.88-0.87-10.39-1.2
				c-6.48-0.61-13.06-0.56-19.44-1.67c-5.77-1.01-11.33-3.23-17-4.84c-2.13-0.61-4.3-1.11-6.49-1.5c-4.11-0.74-7.51-1.93-10.13-5.92
				c-3.81-5.79-10.1-8.4-16.83-9.51c-1.05-0.17-2.27,1.07-3.49,1.36c-1.45,0.35-3.45,1.07-4.41,0.43c-5.97-3.98-12.75-1.71-19.04-2.9
				c-2.34-0.44-4.72-0.8-6.97-1.54c-3.55-1.17-7.24-2.22-10.41-4.1c-2.11-1.25-3.41-3.86-5.08-5.86"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M658.85,303.5c-7.2,5.26-9.5,13.67-13.48,21"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M569.51,321c-2.83,5.5-5.65,11-8.49,16.5c-2.15,4.17-4.35,8.32-6.45,12.52c-0.27,0.53-0.04,1.32-0.04,1.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M676.82,993.5c3.71-0.26,6.8,1.06,6.78,5c-0.01,2.17-1.95,4.45-3.34,6.46c-1.05,1.53-2.73,2.61-3.91,4.06
				c-2.02,2.47-4.32,3.73-7.51,2.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M117.3,833.5c7.71-1.28,14.65,2.25,21.97,3.43c1.76,0.28,3.65-0.27,5.48-0.43"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M562.52,572.5c1.59-10.57,11.54-16.33,18.73-11.17c4.49-2.15,8.96-4.05,13.16-6.43c1.52-0.87,2.39-2.9,3.55-4.4
				c-1.83-1.33-3.53-3.49-5.52-3.81c-3.21-0.53-6.62,0.19-9.95,0.3c-2.33,0.08-4.66,0.01-6.99,0.01"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M51.41,355c0.6,5.73,6.33,6.33,9.5,9.48c2.59,2.58,5.31,5.02,7.97,7.52"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M125.78,132c5.84-0.85,10.53,1.89,15.55,4.35c4.82,2.36,10.24,3.48,15.4,5.15"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M399.3,771.5c-0.83-1.33-1.48-2.85-2.56-3.93c-0.7-0.7-2.03-0.77-4.23-1.52c0.68-5.61-5.5-7.77-10.2-11.6
				c-2.2,2.49-4.62,5.23-7.15,8.09c-4.06-1.37-7.76-1.96-7.91,4.46c-0.03,1.35-1.55,2.67-2.38,4"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M197.16,950.5c0,6.83,0,13.67,0,20.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M305.47,879.5c-0.5,0.17-0.99,0.45-1.5,0.48c-9.48,0.7-9.42,0.69-9.5,10.52c-0.05,6.33-0.31,12.67-0.48,19"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M146.74,913c0.17,6.83,0.33,13.67,0.5,20.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M629.9,565.5c3.77-1.06,7.54-2.12,11.41-3.22c0.99,6.46-2.01,9.51-6.88,11.8c-3.69,1.74-7.03,4.25-10.52,6.42"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M628.41,1057c1.16,5.5,2.41,10.99,3.47,16.51c0.67,3.5-0.65,6.34-3.47,8.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M360.87,1176.5c1.5,6.67,3,13.33,4.48,20c0.22,0.99,0.34,2,0.51,3"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M971.31,387.5c-0.67-0.67-1.34-1.91-2-1.91c-4.82,0.05-9.58,1.72-14.49,0.48c-0.7-0.18-1.65,0.6-2.48,0.93"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M347.89,780.5c-7.37-2.21-11.23-7.81-13.47-14.99c-0.43,0.03-0.86,0.05-1.29,0.08c-1.07,2.14-2.12,4.28-3.22,6.41
				c-0.45,0.86-0.84,2.25-1.51,2.42c-7.62,1.93-9.16,8.33-11,14.58c-0.18,0.6-0.96,1.01-0.7,0.75c-3.57-0.29-7.07-1.66-9.1-0.49
				c-3.67,2.11-6.94,5.43-9.34,8.95c-0.71,1.05,2.04,4.47,3.2,6.8"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M634.89,1053c3.01,8.35,6.98,16.65,1.48,25.49c-0.05,0.07-0.32,0.01-0.48,0.01"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M296.48,354c3.33,1.83,6.59,3.8,10.02,5.42c1.09,0.51,2.63,0.02,3.95,0.09c2,0.11,3.99,0.32,5.99,0.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M29.45,1115.5c3.75,7.46,7.4,14.94,8.03,23.5c0.21,2.85,0.95,5.67,1.45,8.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M125.28,1135.5c0.83,3.33,1.79,6.64,2.47,10.01c1.01,5.04-2.33,11.08,3.5,15.03c0.35,0.23,0.65,1.13,0.48,1.44
				c-3.09,5.47,1.89,7.21,4,10.11c3.03,8.74,1.78,18.2,5.03,26.91"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M881.96,344.5c-6.66-1.17-13.31-2.33-19.97-3.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M907.42,815c-8.15,8.33-16.3,16.67-24.46,25c-2.47,2.52-4.99,5-7.48,7.5c-6.77,6.78-7.1,16.59-11.49,24.5
				c-0.94,1.7-1.99,3.34-2.99,5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M187.17,947.5c-5.82-1.83-11.6-3.83-17.48-5.45c-5.6-1.55-11.34-2.58-16.93-4.13c-1.49-0.41-2.69-1.91-4.02-2.91"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M244.57,906c0.33,6.67,0.67,13.33,1,20"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M510.61,559.5c-6.24-3.75-12.75-4.02-19.47-1.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M191.67,949c-1.33-0.5-2.86-1.69-3.96-1.38c-4.24,1.2-8.43,2.69-12.49,4.43c-4.45,1.91-8.56,1.19-12.48-1.09
				c-3.79-2.2-5.13-0.91-5.01,3.04c0.14,4.5,0.33,9,0.49,13.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M62.89,1140.5c-1.63,7.35,2.33,13.26,5.27,19.61c-1.18,0.59-2.18,1.09-3.1,1.55c1.89,4.99,3.85,10.16,5.81,15.34"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M286,926c-2.99-0.33-6.01-1.07-8.98-0.93c-19.14,0.87-38.27,1.93-57.4,2.95c-1,0.05-2.48-0.05-2.92,0.54
				c-5.11,6.75-10.06,13.61-15.05,20.44"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M819.07,445.5c-5.14-1.88-10.2,0.12-13.78,2.77c-4.7,3.48-8.95,4.09-14.19,2.8c-1.88-0.46-3.97-0.07-5.97-0.07"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1.5,843.5c1.16,5.67,2.35,11.33,3.47,17c0.16,0.8,0.02,1.66,0.02,2.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M289.99,308.5c0.83-2.33,1.64-4.68,2.5-7c0.74-1.97,1.55-3.91,2.32-5.87c-0.37-0.38-0.74-0.77-1.11-1.15
				c-1.41,1.17-3.42,2.07-4.11,3.57c-2.05,4.47-5.17,6.77-10.09,6.99c-4.63,0.21-6.06,3.31-5.99,7.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M332.42,325.5c-0.33,2.33,0.03,5.12-1.14,6.91c-2.59,3.96,0.51,3.81,3.46,4.75c-1.7,2.78-3,5.52-4.86,7.8
				c-4.33,5.3-9.09,10.25-13.34,15.61c-1.52,1.92-2.08,4.6-3.08,6.93"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M625.91,1235c10.7-1.21,21.39-2.25,31.94,1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M932.38,886c-1.66,3.17-3.05,6.53-5.05,9.46c-3.51,5.12-8.81,5.89-17.41,3.04"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M971.31,809c-4.49,0.5-8.98,1.1-13.48,1.45c-1.81,0.14-3.66-0.28-5.49-0.45"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M807.59,300.5c1.5,6.33,2.99,12.67,4.49,19"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M815.08,758.5c3.79-0.6,7.58-1.21,12.81-2.04c0.21-0.56,0.94-2.51,1.67-4.46c-2-0.67-3.96-1.47-6-1.96
				c-1.61-0.38-3.31-0.45-4.98-0.53c-7.31-0.35-7.1-0.36-8.08-8c-0.42-3.24-2.04-6.31-2.85-9.52c-0.7-2.78-1.04-5.66-1.54-8.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M490.15,404c2.33-0.17,4.66-0.45,6.99-0.46c1.83-0.01,4.31-0.35,5.31,0.65c1,1,0.98,3.59,0.59,5.3c-0.86,3.74-2.23,7.36-3.4,11.02
				"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M887.95,743c3.55,3.43,3.77,8.02,4.57,12.49c0.35,1.92,1.58,3.68,2.41,5.51"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M522.59,642c-2.97,4.69-8.02,2.18-12,3.44c-6.51,2.05-13.12,3.68-19.97,1.13c-0.82-0.31-2.31-0.09-2.92,0.49
				c-5.58,5.27-12.65,4.96-19.52,5.46c-0.84,0.06-1.78,0.12-2.48,0.52c-4.2,2.43-8.41,4.86-12.45,7.54c-1,0.66-1.38,2.26-2.05,3.42"
				/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M715.75,980c-1.66,4.17-3.08,8.46-5.09,12.45c-1.07,2.12-3.2,3.71-4.85,5.55c-0.68-0.16-1.36-0.32-2.04-0.47
				c0-2.34-0.18-4.7,0.04-7.02c0.34-3.52,0.96-7,1.46-10.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M926.89,330.5c7.18,1.75,7.21,1.75,5.98,10c-0.42,2.84-0.99,5.67-1.49,8.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M295.49,968.5c0,4.17-0.18,8.34,0.06,12.5c0.15,2.58-0.85,4.07-3.06,5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M796.61,440c-4.99-0.17-9.98-0.37-14.97-0.47c-1.16-0.02-2.33,0.31-3.49,0.47"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M300.48,1169c1.16,3.5,2.3,7.01,3.51,10.5c0.41,1.2,0.78,2.5,1.53,3.47c1.54,1.99,3.26,3.7,0.53,6.12
				c-0.4,0.36,0.23,1.92,0.42,2.91c0.49,2.5,0.99,5,1.49,7.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M637.89,514c-1.66,2.79-2.03,6.92-7.01,6.15c-0.66-0.1-1.76,1.77-2.44,2.87c-1.1,1.77-2.02,3.65-3.02,5.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M542.06,327.5c0.17-0.83,0.35-1.66,0.5-2.5c0.73-4.03-0.2-5.63-4.52-3.57c-2.83,1.35-5.91,2.25-8.97,2.98
				c-1.38,0.33-2.97-0.25-4.47-0.42"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M227.1,1170.5c2.14,3.01,4.54,5.54,2.78,10.11c-0.82,2.13,1.95,5.33,0.84,8.44c-0.12,0.33,1.5,1.77,2.38,1.83
				c0.96,0.06,2.61-0.65,2.88-1.41c1.39-3.92,2.6-7.92,3.53-11.98c0.53-2.29,0.38-4.74,1.48-7.29c1.75,2.87,3.88,4.84,5.24,8.01
				c1.55,3.6,4.04,7.14,4.77,11.3c0.57,3.21,0.71,6.49,1.04,9.74"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M469.68,238c1.83,4,3.7,7.98,5.47,12.01c0.93,2.12,1.68,4.33,2.51,6.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M804.6,257.5c0.5,2.5,1.54,5.04,1.35,7.49c-0.2,2.71-1.22,5.49-2.43,7.97c-1.31,2.68-3.99,4.84-4.72,7.59
				c-0.84,3.16-1.71,6.75,2.19,9.61c2.76,2.02,4.44,5.51,6.6,8.34"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M745.2,650c-0.83,0.83-1.59,1.76-2.51,2.48c-1.6,1.24-3.25,2.46-5.01,3.46c-0.63,0.36-2.22-0.23-2.38,0.1
				c-2.86,5.94-7.66,3.63-12.07,2.96"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M88.35,692.25c-2.07,1.85-3.83,4.25-7.08,1.35c-1.3-1.16-3.41-1.47-4.52,1.35c-0.69,1.75-2.54,3.05-3.87,4.55"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M541.06,940c0.17,0.17,0.43,0.31,0.48,0.5c1.65,6.34,4.46,11.64,8.5,16"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M237.09,848c-0.33,6.08-0.67,12.17-1,18.25"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M348.89,239.5c-3.02-8.16-10.31-6.25-16.47-7"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M947.35,827.5c3.49,0.5,6.99,0.98,10.48,1.51c2.54,0.38,5.07,0.82,8.27,1.34c-1.63,2.43-2.71,4.05-4.45,6.66c3.66,0,6.66,0,9.66,0
				"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M939.86,839c-1.76,4.98-5.24,7.73-10.48,8.53c-5.51,0.84-10.99,1.97-16.48,2.97"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M179.69,872.5c0,1.33,0,2.67,0,4.25c-3.33,0-6.66,0-8.89,0c-2.58-3.17-4.59-5.65-6.64-8.18c5.94-5.83,8.31-6.44,14.02-3.05
				c2.64,1.56,4.77,3.44,1.51,6.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M417.27,824.5c-6.99,1.17-13.98,2.33-20.96,3.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M346.4,1079.5c0.33-1.33,0.23-3.36,1.07-3.89c4.92-3.11,3.56-8.2,4.45-12.61c1.25-6.25-4.16-7.93-7.66-9.71
				c-3.11-1.58-7.5-0.68-11.33-0.77c-1.16-0.03-2.33,0.47-3.49,0.44c-4.73-0.11-8.15,4.3-8.68,9.01c-0.53,4.66,1.65,7.34,4.1,10.59
				c3.36,4.46,7.83,8.37,8.58,14.43"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M256.55,796c1.62-1.38,3.24-2.77,6.11-5.22c-5.1-0.67-9.34-1.27-13.6-1.75c-0.8-0.09-1.65,0.36-2.49,0.45
				c-4.65,0.53-9.25,1.09-8.98,7.51"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M186.18,418.75c-4.66-3.75-9.31-7.51-13.98-11.24c-1.3-1.04-2.66-2.01-3.99-3.01"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M817.08,965c5.16,5.83,10.32,11.67,15.47,17.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M273.52,299.5c-7.47-0.63-14.95-1.25-22.74-1.91c3.18-6.64,6.17-13.67,4.27-21.59"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M867.49,860.5c-6.58-0.61-11.86,2.45-16.97,6"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M634.89,407.5c-11.12,2.01-19.24,8.92-26.95,16.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M288,311.5c5.82-4.83,11.63-9.68,17.49-14.47c0.48-0.39,1.4-0.66,1.96-0.48c5.2,1.6,8.9,0.22,11.08-5.01
				c0.4-0.95,1.85-1.46,3.66-2.79c0,3.09,0,5.42,0,7.02c3.94,0.26,7.59,0.5,11.23,0.74"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M717.25,996.5c0.83-1.33,1.62-2.7,2.5-4c4.48-6.6,4.44-6.4,12.49-4.6c3.36,0.75,6.97,0.43,10.46,0.6"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M971.31,803c-5.82,0.17-11.65,0.33-17.47,0.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M467.19,617.5c-1.07,4.04,1.22,6.21,4.51,7.45c4.08,1.54,8.27,2.84,12.48,3.98c1.55,0.42,3.3,0.07,5.83,0.07
				c0.36,0.5,1.27,1.74,2.19,2.99c6.25-1.22,7.22-0.6,8.54,6.02c13.94-0.31,27.04,3.14,38.83,11.35c1.55-1.11,2.94-2.1,3.39-2.42
				c3.54,0.65,6.26,1.92,8.55,1.39c4.09-0.95,5.19,1.11,6.03,4.18"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M289.99,1010c-4.77,5.11-7.04,11.52-8.92,18.02c-2.25,7.76-4.12,15.63-6,23.5c-0.38,1.58-0.05,3.32-0.05,4.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M857.51,538c-3.33,1.17-6.68,2.26-9.97,3.53c-2.25,0.87-4.4,1.98-7.41,3.35c0.21-2.73,0.31-4.05,0.41-5.38"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M281.51,842c-2.66,0.12-5.31,0.24-8.32,0.38c-0.5,2.59-1.92,5.74-1.45,8.58c0.64,3.86,4.86,4.18,7.77,3.89
				c11.89-1.15,17.71,3.99,22,13.63c1.69,3.81,3.64,7.51,5.47,11.26"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M241.08,926c-0.67-1-1.31-2.84-2-2.86c-3.98-0.12-8,0.05-11.96,0.51c-0.63,0.07-1.02,2.18-1.52,3.35"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M910.41,752c0,2.5-0.37,5.07,0.08,7.49c0.9,4.88,2.47,9.64,3.34,14.53c0.53,3.02-1.6,4.39-4.41,4.46
				c-3.66,0.09-7.32,0.02-10.98,0.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M581.49,523c-1.33,4.5-2.83,8.96-3.96,13.51c-1.8,7.28-5.13,13.89-8.46,20.52c-0.63,1.26-0.4,2.97-0.57,4.47"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M805.1,730.5c-2.97,4.03-4.9,8.27-3.6,13.53c0.24,0.98-0.89,2.31-1.39,3.47"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M573.5,547.5c-5.66,0-11.31,0-16.97,0"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M360.37,308c0.61-3.12,1.23-6.23,2.13-10.81c-6.78,3.86-11.97,11.71-18.49,2.7c-4.49,3.03-3.53,10.59-10.09,11.61"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M436.24,258c-2.33,3.33-4.66,6.67-6.99,10c-1.16,1.67-2.33,3.33-3.49,5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M586.98,369.5c-5.41-1.12-10.89-3.24-15.97,1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M381.83,739c-4.35-4.47-8.71-8.95-13.22-13.58c-1.7,1.28-2.95,2.23-4.16,3.14c-3.68-2.26-7.12-4.26-9.71,1.38
				c-0.42,0.92-2.53,1.06-3.87,1.56c-1.94,0.73-3.88,1.46-7.33,2.77c3.04,2.03,4.59,3.83,6.36,4.08c3.39,0.49,6.23,0.37,6.75,5.12
				c0.17,1.56,5.23-0.89,6.45,2.95c0.14,0.43,3.81,0.01,5.73-0.47c7.01-1.73,12.45-6.7,18.88-9.63c3.5,6.15,3.5,6.15,8.23,3.6
				c1.72,2.63,2.76,6.36,7.43,4.85c0.32-0.1,1.93,3.71,2.9,5.73c0.87,1.82,1.2,4.7,2.58,5.32c7.12,3.22,7.39,9.31,7.41,15.67"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M513.11,555c-5.99-4.74-12.84-2.87-19.46-2.44c-2.19,0.14-4.33,0.94-6.49,1.44"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M971.31,627.5c-3.83,2.83-7.61,5.73-11.49,8.48c-3.43,2.43-6.97,4.69-10.47,7.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M261.29,867.75c-1.46-1.01-2.93-2.03-4.39-3.05c5.71-7.66,9.69-7.19,17.23-0.07c-0.75,2.62-1.43,4.99-2.26,7.87
				c-1.5,0-3.25,0.32-4.82-0.08c-1.92-0.49-3.68-1.58-5.51-2.42c1.16-1.33,2.26-2.74,3.51-3.98c1.42-1.41,2.98-2.68,4.47-4.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M512.86,238c-5.03,1.93-2.79,5.62-2.29,9.01c0.52,3.47,0.71,6.99,1.04,10.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M270.03,720.5c-4.49,2.83-8.77,6.13-13.54,8.36c-4,1.86-8.62,2.37-12.89,3.71c-1.15,0.36-2.02,1.6-3.02,2.43"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M227.1,1034.5c-4.49-3.67-9.11-7.19-13.44-11.04c-3.74-3.32-8.17-2.56-12.5-3.08c-2.91-0.35-5.69-1.82-8.49-2.9
				c-1.38-0.53-2.67-1.31-4-1.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M644.38,553.5c-7.48,1.4-12.27,6.25-15.97,12.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M47.92,256c1.16,2.67,3.04,5.27,3.29,8.02c0.25,2.75-1.13,5.65-1.8,8.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M398.81,351c-3.99,0-8-0.23-11.97,0.08c-2.78,0.21-4.18,1.66-3.07,4.95c1.54,4.57,2.57,9.31,3.82,13.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M48.91,273.75c-0.33,3.75-0.67,7.5-1,11.25"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M118.79,893.5c0,5.5,0,11,0,16.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M559.03,370c-0.17,2.67-1.45,6.04-0.3,7.88c4.65,7.41,1.8,14.73,0.8,22.12c-0.52,3.83-1,7.67-1.5,11.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M655.36,242c0.38,3.79-0.34,6.68-4.99,7.05c-2.35,0.19-4.66,0.95-6.99,1.45"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M795.61,495c-0.33,1-0.49,2.11-1.03,2.98c-2.77,4.54-5.63,9.02-8.46,13.52"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M814.58,175.5c3.93,0.74,7.53,1.73,10.1,5.41c1.49,2.14,4,3.85,6.41,4.99c4.71,2.22,6.97,6.21,8.94,10.59"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M853.51,894.5c-4,0.35-5.38,1.84-3.59,6.04c2.18,5.11-0.01,9.91-2.42,14.45c-1.02,1.91-2.3,3.68-3.47,5.51"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M123.78,930c-2.54,7.74,4.91,4.76,7.69,7.7c0.53-2.14,0.92-3.67,1.3-5.2"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M111.81,892c-0.33,5.33-0.67,10.67-1,16"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M134.27,909.5c-9.82,5-19.63,10-29.45,15"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M727.73,797.5c5.66,1.5,11.31,3,16.97,4.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M841.53,538.5c-3.99,1.33-7.96,2.76-11.99,3.95c-1.58,0.46-3.31,0.38-4.98,0.55"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M249.07,984c0.33-3.67,0.86-7.33,0.85-11c0-0.84-1.74-2.13-2.87-2.39c-1.73-0.41-3.73-0.48-5.45-0.04
				c-7.84,1.99-13.59-0.57-17.49-7.56"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M944.35,755.5c-3.07,1.34-2.94,5.36-4.31,6.67c-5.32,5.1-3.19,11.71-5.13,17.35c-0.19,0.55,0.29,1.32,0.45,1.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M826.56,900.5c2.78,5.43,2.45,10.75-0.5,16"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M433.74,425c5.16,3.67,10.32,7.33,15.47,11"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M124.78,893.5c-5.49-1-11.01-1.86-16.46-3.06c-2.86-0.63-4.05,0.16-4.02,3.06c0.04,4.5,0.01,9,0.01,13.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M118.29,1152c-4.44-2.84-2.16-7.71-3.36-11.54c-1.16-3.72-1.25-7.77-2.88-11.73c-2.38,7.31-8.96,12.62-8.23,20.77"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M358.87,466.5c0,5.83-0.25,11.68,0.08,17.5c0.24,4.19,1.29,8.33,1.89,12.51c0.16,1.14,0.03,2.33,0.03,3.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M880.97,852c2.93,2.95,3.7,6.23,3.2,10.52c-0.47,4.05-0.34,8.95,1.56,12.32c2.69,4.79,0.07,7.97-1.34,11.62
				c-0.96,2.48-2.59,4.69-3.92,7.03"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M661.85,60c-7.25-1.92-14.57-2.18-21.96-1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M578.49,529c-0.17,0-0.33,0-0.5,0c-13.64-0.28-14.11,0.15-20.02,13.48c-2.14,4.82-4.93,9.36-7.43,14.03"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M407.79,758c-2.5,4.5-5.31,8.86-7.33,13.57c-0.83,1.94-1.26,4.68,1.74,6.59c1.73,1.11,2.82,3.71,3.51,5.87
				c1.15,3.56,1.75,7.31,2.58,10.97"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M619.92,183c0.5,5,0.99,10,1.5,15c0.12,1.17,0.33,2.33,0.49,3.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M25.46,864.5c0.99-2.54,2.22-3.86,5.52-3.14c4.71,1.03,9.62,1.14,14.45,1.64"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M157.73,798c-2.44-1.5-4.83-2.13-6.47,1.01c-2.49,4.78-5.23,9.26-10.48,11.55c-0.52,0.23-0.69,1.27-1.02,1.94"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M274.52,807c0,1.33-0.23,2.72,0.05,3.99c0.48,2.21,1.32,4.33,1.92,6.52c0.17,0.62-0.21,1.79,0.06,1.93
				c7.07,3.62,4.63,9.91,4.47,15.57c-0.06,2.16-0.01,4.33-0.01,6.38c6.16,1.51,6.4,2.18,4.99,12.62"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M577.99,389c-3.16-1-6.27-2.26-9.5-2.9c-1.89-0.38-3.97,0.23-5.97,0.4"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M255.55,981c0-3.5-0.01-7,0.02-10.5c0-0.17,0.31-0.33,0.58-0.6c14.22-0.76,28.55-1.54,43.49-2.34c-1.88-3.45-2.87-6.39-4.79-8.44
				c-1.52-1.61-4.23-3.05-6.35-2.97c-13.82,0.54-27.62,1.57-41.44,2.32c-5.81,0.32-11.65,0.49-17.47,0.49
				c-6.1-0.01-7.92,1.55-7.98,7.54c-0.04,3.5-0.01,7-0.01,10.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M272.53,970c0,3.5,0,7,0,11.5c-7.49,0-14.97,0-22.46,0"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M645.87,267c5.12,4.12,7.07,12.45,4.52,19.01c-0.49,1.27-0.69,2.66-1.02,3.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M814.08,828c7.38,1.78,11,6.99,12.45,14.01c1.13,5.47,2.02,10.99,3.02,16.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M646.37,1040.5c2.66,4.33,5.23,8.73,8.05,12.96c0.74,1.11,2.55,2.6,3.37,2.34c1.39-0.44,3.45-2.23,3.36-3.28
				c-0.29-3.22-1.28-6.42-2.38-9.49c-0.54-1.5-1.97-2.66-2.9-4.04c-1.55-2.3-4.11-4.63-4.18-7c-0.06-2.12,2.68-4.31,4.16-6.49
				c1.35-1.99,2.67-4,4-6"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M276.02,900c0.2-2,0.4-4,0.7-6.91c-3.39,1.14-7.09-1.65-8.18,3.41"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1,1076.5c1.83,1.83,3.84,3.52,5.45,5.53c3.94,4.91,7.69,9.97,11.52,14.97"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M343.4,901c-2.33-0.83-4.65-1.7-6.99-2.49c-2.15-0.72-4.32-1.34-6.49-2.01"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M29.45,1110.5c-0.5,1.83-0.77,3.77-1.54,5.48c-1.59,3.53-3.71,6.72-2.65,11.07c0.33,1.35-2.32,3.18-2.68,4.97
				c-0.84,4.1-1.22,8.3-1.54,12.48c-0.1,1.3,0.6,2.66,0.93,3.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M971.31,635.5c-3.73,4.41-8.92,4.24-13.98,4.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M281.51,881.5c0,2.18,0,4.36,0,6.71c4.55,0.54,8.05,1.34,7.69-5.22c-0.23-4.17,3.15-4.19,6.29-2.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M971.31,406c-3.6,0.81-6.94,0.3-10.54-0.79c-2.85-0.86-6.26,0.13-9.42,0.29"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M180.69,1057c7.99-2.5,15.97-5,23.96-7.5c3.69-1.16,6.81-0.44,10.06,1.9c3.85,2.77,8.25,4.77,12.41,7.1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M291.99,1063.5c-0.74-1.97-1.48-3.94-2.44-6.5c-2.03,0-4.54,0-7.04,0"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M274.02,1057c-2.16,0.5-6.09,0.77-6.19,1.54c-0.5,3.75-0.04,7.63,0.31,11.45c0.04,0.41,1.56,0.97,2.39,0.96
				c4.99-0.07,9.99-0.17,14.97-0.49c3.13-0.2,5.32-2,6.49-4.96"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M470.68,486c-0.67,2.33-1.17,4.73-2.04,6.98c-0.98,2.57-2.29,5.02-3.45,7.52"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M367.86,747.5c0.67,0.83,1.4,2.44,1.99,2.38c5.43-0.52,7.26,3.52,9.49,7.12"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M172.7,675.5c-1.69,5.23-7.47,8.51-13.47,7.47c-2.86-0.5-5.66-1.3-8.49-1.97"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M38.43,890c-4.62-0.51-9.24-1.03-14.22-1.58c-0.32,3.22-0.54,5.4-0.76,7.58"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M951.84,388c-0.17,4,0.15,8.1-0.63,11.97c-0.82,4.1-1.6,7.82,1.04,11.59c0.63,0.89,0.09,2.6,0.09,3.93"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M464.69,242c3.33,4.67,6.66,9.33,9.98,14"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M929.38,770.5c0,1.19,0,2.38,0,3.31c-5.34,0.24-10.15,0.46-14.97,0.69"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M845.53,718.5c-1.93,5.92-1.26,12.81-6.49,17.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M204.14,719.5c-0.83,0.83-1.88,2.52-2.46,2.37c-5.33-1.35-7.42,3.04-10.52,5.63"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M244.07,882.5c-4.82,0.5-9.65,1-14.47,1.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M319.94,1160.5c0.06,3.84-0.36,7.57,3.82,10.27c1.14,0.73,0.17,4.73,0.17,7.5c0.69,0.35,1.88,0.95,2.04,1.02
				c0.43,4.14,0.78,7.48,1.12,10.83c0.48-0.02,0.96-0.03,1.44-0.05c-0.75,1.13-1.49,2.25-1.84,2.78c1.04,2.35,1.88,4.25,2.73,6.15"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M584.98,764c-5.99-0.67-11.98-1.3-17.97-2.03c-1.02-0.12-2-0.64-3-0.97"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M46.42,838c0-2.5,0-5,0-7.5c2.5,0,4.6-0.39,6.46,0.12c1.65,0.45,4.27,1.87,4.28,2.89c0.02,2.45,0.12,5.99-4.25,5.51
				c-1.99-0.22-3.99-0.49-5.99-0.48c-4.87,0.02-5.53,1.14-3.48,5.51c-2.49,2.98-5,5.97-7.5,8.96"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M10.98,1115.5c-1.13,2.17-1.36,4-0.2,6.59c1.23,2.76,0.6,6.3,1.29,9.39c0.51,2.28,1.84,4.38,2.82,6.6
				c-3.09,2.85-1.87,9.74,2.79,14.11c0.94-1.24,1.86-2.47,2.79-3.69"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M279.51,997c3.33,3.5,6.63,7.03,10,10.49c1.07,1.1,2.31,2.01,3.48,3.01"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M312.95,1095.5c-1.16-4-2.33-8-3.49-12c-0.46-1.58-0.9-3.16-1.97-4.7c-0.97,5.46-6.37,9.54-4.12,15.73
				c0.21,0.57-0.9,1.63-1.4,2.47"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M830.05,406.5c-0.5,3-1.18,5.98-1.45,9c-0.35,3.98-0.38,8-0.55,12"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M337.41,570c6.28,2.11,10.03,7.1,14.03,11.95c2.69,3.26,5.93,6.05,8.93,9.05"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M872.98,993.5c2.99,2,6.12,3.84,8.91,6.09c0.95,0.77,1.07,2.58,1.57,3.91"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M932.38,729c-2.66,5.17-5.32,10.33-7.99,15.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M325.43,1072c8-3.34,13.13-0.49,14.51,7.99c0.09,0.54,0.63,1.01,0.97,1.51"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1,556c2.7,0.28,4.67,4.52,7.99,1.5c1.28,1.84,2.56,3.68,3.85,5.53c4.91-3.12,5.54-2.77,12.82,7.75c0.95-0.77,1.87-1.52,2.8-2.28"
				/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M9.48,1113.5c3.49,1.33,7.01,2.61,10.47,4.02c1.72,0.7,3.34,1.64,5,2.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M226.61,1166.5c-0.83,3.67-1.4,7.42-2.58,10.97c-0.96,2.87-2.65,5.5-4.06,8.32c-3.05-2.53-6.72-16.6-6.34-23.29"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M2,896.5c10.48,0.5,20.96,1.04,31.45,1.45c1.32,0.05,2.66-0.61,3.99-0.95"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M830.05,816.5c-2.66,4.5-5.32,9-7.99,13.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M657.85,968c5.84,2.73,6.49,5.04,3,10.5c-0.85,1.32-1.67,2.67-2.5,4"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M839.04,826c-4.16,4.33-8.32,8.67-12.48,13"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M734.72,289c0.67,6.17,1.61,12.32,1.91,18.5c0.25,5.15-0.4,10.33-0.34,15.49c0.03,2.01,0.92,4,1.42,6"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M59.4,902.5c2.83-0.17,5.67-0.57,8.48-0.46c9.65,0.39,19.3,0.96,28.95,1.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M24.46,891.5c4.49,0.33,8.98,0.67,13.48,1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M620.42,209.5c-4.49,1-8.98,2-13.48,3"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M677.82,968c3.47-1.27,5.96,1.21,6.2,3.53c0.28,2.66-1.62,5.56-2.78,8.99c4.11,4.53,4.11,4.53-0.92,12.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M917.9,730c-3.44,4.04-4.98,8.54-4.67,14.01c0.15,2.55-1.56,5.2-2.43,7.81c-0.46-0.05-0.93-0.09-1.39-0.14
				c-2.33-7.39-4.66-14.79-6.99-22.18"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M196.66,1071.5c-0.17,4.33-0.33,8.67-0.5,13"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M244.57,889c0.17-1,0.52-2.01,0.47-3c-0.36-6.84-0.39-6.83,6.52-7c10.62-0.26,10.63-0.26,10.46,10c-0.03,1.5-0.31,3-0.48,4.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M314.45,1182.5c-1,0.83-2.88,1.71-2.85,2.49c0.17,3.85,0.85,7.67,1.35,11.51"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M787.13,903.5c2,2.17,4.11,4.24,5.95,6.53c1.36,1.68,2.37,3.64,3.53,5.47"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M367.36,232.5c-0.55,7.38,6.12,9.38,9.94,13.54c1.13,1.23,1.71,2.96,2.54,4.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M804.1,478.5c-4.42-0.96-8.81-2.61-13.07,0.89c-0.9,0.74-2.91,0.11-4.4,0.11"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M801.1,231c-2.61,6.74,0.45,12.17,3.99,17.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M65.89,897c4.33,0.5,8.65,1,12.98,1.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M542.55,568.5c0.83-2.33,1.21-5,2.62-6.91c1.42-1.92,3.8-4.24,5.88-4.37c5.45-0.33,10.97,0.44,16.46,0.78"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M943.36,729c-1.33,4.17-2.66,8.33-3.99,12.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M665.84,988c3.98-0.7,6.2,1.05,6.45,5c0.2,3.24-3.41,6.99-6.45,7"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M426.76,256c-1,4.17-2,8.33-2.99,12.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M169.7,954c0,4.17,0,8.33,0,12.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M919.4,873c1.83,0.33,3.66,0.65,5.49,1c8.11,1.54,10.43-0.89,8.46-9c-0.3-1.22-0.97-2.34-1.47-3.51"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1.5,362.5c3.26,3.92,6.52,7.84,10.33,12.42c-5.27,0.98-8.26-1.19-10.83-4.42"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M63.89,586.5c1-1.33,1.76-2.99,3.04-3.94c2.92-2.16,4.69-1.13,5.51,2.42c0.57,2.45,1.76,4.76,3.04,8.09
				c1.94-2.62,3.4-4.59,5.14-6.94c0.97,1.37,1.86,2.61,2.86,4.01c1.42-0.53,2.9-1.09,4.38-1.64"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M173.2,944c-3.99,1.83-7.99,3.67-11.98,5.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M388.32,370c0.83,0.83,1.54,2.19,2.51,2.42c9.44,2.21,11.9,8.95,12.03,17.58c0.04,3.01,0.93,6,1.42,9"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M190.67,922c2.33,0.33,4.74,0.39,6.97,1.05c6.2,1.85,12.33,3.95,18.48,5.95"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M259.55,814.5c3.65,0.47,6.25,3.86,6.65,7.48c0.14,1.28,1.97,2.73,3.37,3.45c4.78,2.45,8.36,9.94,7.51,15.58
				c-0.62,4.12-0.72,8.32-1.05,12.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M931.88,848.5c0,1.17-0.14,2.35,0.02,3.5c1.26,9.05,1.74,9.88-10,10.5c-1,0.05-2,0.01-2.99,0.01"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M213.13,1167c-1.95,3.19-3.9,6.39-5.92,9.71c0.58,1.3,1.26,2.79,1.93,4.29"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M65.89,888c4.16,0.17,8.32,0.33,12.48,0.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M295.49,945.5c-0.33,4.17-0.67,8.33-1,12.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M915.41,890.5c3.99,0.83,7.99,1.67,11.98,2.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M232.59,972c0,1.5-0.06,3,0.01,4.5c0.12,2.67-0.43,7.23,0.63,7.65c3.28,1.29,7.59,2.16,10.73,1.03c3.18-1.15,5.55-0.43,8.07,0.4
				c2.95,0.97,5.68,2.58,8.51,3.91"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M687.8,970c7.65,2.07,8.96,5.01,4.5,10.01c-0.71,0.79-1.34,1.66-2,2.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M851.52,903.5c5.37,3.93,6.15,7.61,3,14c-0.72,1.47-1.34,3-2.01,4.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M654.86,982.5c2.16,1.17,5.66,1.89,6.22,3.59c1.23,3.73-1.87,6.28-4.72,8.41"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M665.84,976c3.39-1.67,5.1,1.19,6.28,3.13c0.68,1.11-0.6,3.61-1.35,5.34c-0.49,1.15-1.6,2.03-2.43,3.03"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M106.81,596c-3.99,0.5-7.98,1.02-11.98,1.47c-0.47,0.05-1.02-0.25-1.49-0.48c-1.6-0.8-4.55-8.98-4-10.99"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M299.98,1164.5c-0.97,5.54-1.93,11.08-2.9,16.62c-0.48,0.08-0.96,0.17-1.44,0.25c-1.22-2.96-2.43-5.92-3.65-8.87"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M922.89,629c-3.08,3.57-6.17,7.13-9.31,10.77c-2.11-1.29-4.06-4.68-5.76,0.2c-0.77,2.2-2.57,4.03-3.9,6.03"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M14.47,872.5c4.33,2.33,8.47,5.17,13.03,6.86c4.4,1.63,8.94,2.62,11.93,6.64"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M971.31,1013c-1.66,1-3.33,2-4.99,3c1.66,1,3.33,2,4.99,3"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M329.43,1242.5c-5.49,0-10.98,0.09-16.47-0.05c-2.17-0.05-4.33-0.62-6.49-0.95"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M319.94,1166.5c-2.59,0.92-3.73,2.46-3.61,5.5c0.15,3.81-0.55,7.66-0.88,11.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M123.29,832.5c0.5-2.45,1.6-5.39-1.1-6.81c-1.23-0.64-3.72,0.52-5.32,1.43c-0.75,0.43-1.27,2.37-0.98,3.35
				c1.39,4.61-1.08,6.82-4.53,9.11c-4.01,2.67-7.6,5.98-12.65,10.04c7.78-0.52,9.06,3.12,8.11,8.37"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M322.94,618c-1.16-0.5-2.71-1.74-3.43-1.37c-2.99,1.53-5.72,3.54-8.55,5.37"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M74.87,868c-4.26-5.17-10.31-2.34-15.47-3.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M86.35,249c0,3.83,0,7.67,0,11.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M770.66,731.5c-0.5,1.17-1.11,2.3-1.47,3.51c-0.74,2.48-1.04,5.15-2.13,7.44c-0.76,1.6-2.55,2.72-3.88,4.05
				c-0.83-1.33-1.92-2.57-2.44-4.02c-0.82-2.31-1.25-4.76-1.84-7.16c-0.62-0.15-1.24-0.3-1.86-0.44c-1.95,3.71-3.74,7.51-5.92,11.07
				c-0.88,1.44-2.59,2.38-3.92,3.55"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M607.44,556.5c0,4.67,0,9.34,0,14.54c2.32,0.13,5.15,0.29,7.99,0.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M269.53,997.5c-0.72-4.57-3.9-6.52-7.99-7.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M135.26,590.5c-0.58-0.96-1.15-1.92-1.85-3.09c-1.67,0.69-3.61,1.49-5.58,2.3c-2.15-7.48-2.32-7.58-7.04-4.21c-1.5-2-3-4-4.5-6"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M867.99,732.5c1.09,7.49,2.34,14.93,6.49,21.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M529.58,314.5c-3.66,0.17-7.32,0.33-10.98,0.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M917.4,726.5c3,5.39,1.93,12.46,6.99,17"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M647.37,237.5c-1.66,3.67-3.33,7.33-4.99,11"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M198.65,275.5c0.83,3.67,1.66,7.33,2.5,11"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M656.86,1024.5c2.33,2.33,4.83,4.52,6.95,7.03c5.46,6.43,4.68,10.78-2.46,14.97"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M262.54,333c3.35,1.46,8.65-1.44,9.98,4.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M932.87,874.5c0,3.5,0,7,0,10.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M694.79,311c-4.43,5.06-2.53,11.24-2.99,17"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M853.01,740c0.83-1.5,1.59-3.05,2.52-4.48c0.71-1.08,1.57-2.73,2.49-2.82c0.91-0.08,2.36,1.27,2.9,2.34
				c1.39,2.72,2.4,5.63,3.57,8.46"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M669.33,1000.5c2.02,1.71,4.06,3.71,2.42,6.45c-2.11,3.53-4.83,6.66-9.41,7.03c-1.32,0.11-2.66,0.02-3.99,0.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M197.16,608.5c-0.83-0.5-2.25-1.58-2.41-1.41c-2.5,2.55-3.96,0.76-5.59-1.07c-2.73-3.07-4.33-1.55-5.47,1.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M214.63,1068.5c0,3.5,0,7,0,10.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M971.31,257c-4.99-1.5-9.98-3-14.97-4.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M140.75,893c2.23-8.63-3.01-15.29-6.07-22.46c-1.51-3.55-3.69-3.22-5.95-0.07c-4.25,5.91-2.72,12.59-2.93,19.03
				c-0.24,7.33-0.35,14.67-0.52,22"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M266.54,970c0.17,3.5,0.33,7,0.5,10.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M441.73,373c-0.17,5.67-0.43,11.33-0.45,17c0,2,0.61,4,0.94,6"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M223.61,1065.5c0,1.67,0,3.33,0,5c0,6.9-0.49,7.57-6.99,8.98c-2.81,0.61-5.65,1.02-8.48,1.52"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M213.13,977.5c4.93-2.34,8.96-0.13,13,2.46c1.68,1.07,3.63,1.71,5.46,2.54"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M89.84,869c-0.17,7-0.31,14-0.51,21c-0.11,4-0.32,8-0.49,12"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M971.06,604c-0.75-1.29-1.51-2.59-2.31-3.97c-3.41,2.45-6.29,4.52-10.18,7.31c-1.46-1.64-3.35-3.74-5.23-5.84"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M964.32,896c2.78,3.9-0.92,6.84-1.5,10.25"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M833.05,897.5c5.13,6.54,3.72,13.25,0.5,20"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M261.54,970.5c0,3.33,0,6.67,0,10"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M374.35,299.5c2.02,0.76,4.04,1.53,6.51,2.46c-1.11,1.85-2.06,3.44-3.02,5.04"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M345.9,970c-1.83,4-3.66,8-5.49,12"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M590.47,199.5c0.8,3.32-0.06,5.64-3.47,7.07c-2.8,1.17-5.35,2.93-8.01,4.43"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M99.83,1127.5c-0.04,5.28-0.44,10.65,3.49,15"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M218.62,950c0.17,3.33,0.33,6.67,0.5,10"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M713.76,749.5c-3.33,0-6.66,0-9.98,0"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M362.37,776.5c-0.24-0.91-0.47-1.82-0.71-2.72c-0.74-0.41-1.48-0.82-2.23-1.24c-2.35,2.99-4.7,5.97-7.05,8.96"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M853.01,978c2.29,3.06,8.03,0.93,8.98,6"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M846.03,722.5c1.33,3.17,2.66,6.33,3.99,9.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M155.73,1162.5c-2.12,3.19-4.25,6.39-6.49,9.76c-1.4-1.51-2.68-2.9-4.34-4.68c-2.27,3.22-4.45,6.32-6.64,9.42"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M789.62,896.5c-3.16,5.5-6.12,11.13-9.57,16.44c-1.64,2.51-4.24,4.39-6.4,6.56"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M631.4,1009.5c3.33,1.67,6.66,3.33,9.98,5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M158.22,898c-2.99,1.5-5.99,3-8.98,4.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M133.27,898.5c0.17,3.17,0.33,6.33,0.5,9.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M291.99,1162.5c-0.33,3.08-0.67,6.17-1,9.25"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M248.57,948c0.17,3.17,0.33,6.33,0.5,9.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M303.97,1057c-2.83,2-5.66,4-8.49,6"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M560.52,397.5c3.16,0.83,6.32,1.67,9.48,2.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M655.36,1056c-0.67,2.33-1.14,4.74-2.05,6.98c-1.07,2.63-3.05,3.84-5.94,2.52"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M282.01,946.5c0,3.17,0,6.33,0,9.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M932.38,727.5c1.83,3.5,3.66,7,5.49,10.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M29.45,860.5c2.14-4.37,4.37-8.36,10.51-6.09c1.61,0.6,3.63,0.09,5.46,0.09"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M96.83,880c-23.96-1.83-47.92-3.67-71.87-5.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M183.68,674c-2.88,3.02-5.78,5.97-10.48,3.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M791.62,529c-1.75,6.5-6.72,10.13-11.98,13.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M61.89,1147c-1.16,2.67-2.33,5.33-3.49,8"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M127.28,875c2.99,0.33,5.99,0.67,8.98,1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M227.6,949.5c0,3,0,6,0,9"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M88.35,888.5c-2.99,0.17-5.99,0.33-8.98,0.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M863.99,986c2.94,1.84,5.89,3.69,9.65,6.05c-2.52,0.52-3.59,0.73-4.66,0.95"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M133.77,882c-0.33,3-0.67,6-1,9"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M791.12,274c2.99,0.5,5.99,1,8.98,1.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M240.58,948.5c0,3,0,6,0,9"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M78.36,870.5c0.33,2.67,0.9,5.33,0.95,8c0.14,6.5,0.05,13,0.05,19.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M971.31,725.5c-0.33,2.67-0.67,5.33-1,8"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M360.87,1180.5c-2.33,4.09-4.66,8.19-6.27,11.02c-0.31,3.47-0.51,5.73-0.72,7.98"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M209.14,611.5c-3.07-5.79-8.01-6.65-11.48-2"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M108.81,924c4.49,1.67,8.91,3.6,13.5,4.93c5.07,1.47,10.3,2.4,15.45,3.57"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M949.35,378c1.16,2.83,2.33,5.67,3.49,8.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M64.89,884.5c0,5.5,0,11,0,16.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M953.84,604c-1.31,1.85-2.61,3.7-3.92,5.55c-1.35-0.96-2.63-1.88-4.21-3.01c-1.22,1.81-2.29,3.39-3.35,4.96"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M98.83,1138c-5.55,4.35-7.34,11.27-4.23,15.2c-1.3,2.36-2.53,4.58-3.75,6.8"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M177.19,866c-2.83,2.25-5.66,4.5-8.49,6.75"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M87.85,1143c-0.96,3.51-1.92,7.02-2.79,10.22c1.14,1.84,2.11,3.64,3.34,5.25c0.55,0.72,1.62,1.04,2.45,1.54"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M175.19,1158.5c-0.48,1.84-0.97,3.67-1.42,5.38c1.49,2.24,2.95,4.43,4.41,6.62"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1.5,995.5c2.58,2.5,5.16,5,7.74,7.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M11.98,936c-1.66-4.93-5.81-7.23-9.98-9.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M45.92,843.5c0.17,7.5,0.35,15,0.49,22.5c0.06,3.33,0.01,6.67,0.01,10"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M186.18,1162c-1.16,1.67-2.52,3.23-3.45,5.02c-1.55,2.96-3.33,5.06-7.03,3.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M80.36,897.5c2.66,0,5.32,0,7.99,0"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1,870c2-0.17,4.57-1.21,5.89-0.35c5.35,3.53,10.69,1.51,16.31,0.86c0.16-1.85,0.31-3.71,0.45-5.3
				c7.45,0.78,14.61,1.54,21.78,2.29"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M412.28,429.5c0-1.33-0.39-2.82,0.07-3.97c2.08-5.19,1.71-6.18-4.06-9.03"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M800.61,531.5c-3.83,1.33-7.65,2.67-11.48,4"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M263.54,896c7.82,3.67,15.64,7.34,23.47,10.98c0.61,0.28,1.32,0.35,1.99,0.52"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M969.31,733.5c0.45,2.05-0.24,4.4-2.5,8.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M784.13,909.5c2.33,2.5,4.66,5,6.99,7.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M59.4,856.5c-0.33,6.83-0.67,13.67-1,20.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M111.81,877c-0.33,2.67-0.67,5.33-1,8"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M177.69,887c-4.33-0.17-8.65-0.49-12.98-0.47c-7.49,0.04-14.97,0.3-22.46,0.47"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M99.33,861.5c4.66,1,9.36,1.84,13.96,3.06c2.42,0.64,4.68,1.94,7,2.94"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M162.72,888c0,2.5,0,5,0,7.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M857.51,732c-3.62-3.13-3.62-3.13-7.45,0.94c0.54,2.99,1,5.53,1.46,8.06c-0.17,0-0.37,0.06-0.49-0.01
				c-0.2-0.12-0.34-0.32-0.51-0.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M104.82,876.5c0,2.5,0,5,0,7.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M803.1,1007c2.5,1.83,4.99,3.67,7.49,5.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M289,880.5c-5.49,0-10.98-0.11-16.47,0.04c-3.17,0.09-6.32,0.62-9.48,0.96"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M921.89,801.5c0.67,2.58,1.33,5.17,2,7.75"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M42.93,350c3.99,1.83,7.99,3.67,11.98,5.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M23.46,890c-2.33-0.21-4.66-0.42-7.49-0.68c0,5.84,0,11.01,0,16.18"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M186.18,1157.5c0.79,7.72,5.17,13.02,11.48,17c0.82,0.52,1.66,1,2.49,1.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M78.86,868c1.33-0.5,2.75-1.59,3.98-1.41c9.18,1.33,18.31,2.94,27.47,4.39c2.81,0.45,5.65,0.68,8.48,1.02"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M127.28,851.5c1.5,0.5,3,1.43,4.49,1.42c3.99-0.04,6.31,1.64,6.99,5.58"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M153.73,1161.5c3.42-0.04,6.03,0.75,6.08,5c0.01,0.84,1.24,1.67,1.9,2.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M126.78,896.5c7.56,1.53,15.11,3.06,22.67,4.59c0.02,0.45,0.04,0.91,0.06,1.36c-3.92,2.35-7.83,4.7-11.75,7.05"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M178.69,1158c-5.21,1.36-6.08-2.34-6.72-6.32c-0.52-0.03-1.04-0.06-1.56-0.08c-0.9,3.3-2.61,6.63-2.49,9.89
				c0.14,3.71,0.85,3.69-1.21,6.51"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M639.89,1047.5c-4.16,3-8.29,6.04-12.49,8.98c-2.07,1.45-3.66,4.46-6.98,2.52"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M507.62,18c-2,1.83-3.99,3.67-5.99,5.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M687.8,982c4.91,0.48,4.83,4.7,5.99,8"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M880.47,738c2.6,3.76,4.94,7.6,3.99,12.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M126.78,880.5c3.99,0.5,7.99,1,11.98,1.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M747.7,990.5c0.6,6.93-3.23,12.69-4.96,19.01c-0.21,0.77-0.03,1.66-0.03,2.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M72.37,871c-0.17,2.33-0.33,4.67-0.5,7"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M97.33,885.5c1.33-0.5,2.76-1.6,3.98-1.4c7.85,1.31,15.66,2.9,23.48,4.4"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M126.78,891c6.99,1.31,13.98,2.62,21.26,3.98c0.23-2.13,0.46-4.31,0.7-6.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M38.43,1113c1.83,4.5,3.66,9,5.49,13.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M124.78,879.5c-5.47,1.5-9.59-2.6-14.48-3.49c-3.82-0.7-7.65-1.34-11.48-2.01"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M131.77,846c1,2.17,2,4.33,2.99,6.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M855.01,356c-1.5,2.17-2.99,4.33-4.49,6.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M77.37,869c-5.99,2.01-11.98,0.52-17.97,0"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M296.48,920c-0.67,5.33-1.33,10.67-2,16"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M101.82,857.5c-1.33,1.83-3.72,3.62-3.8,5.51c-0.32,6.81,0.14,13.66,0.31,20.49"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M101.32,850.5c-0.79,4.76,0.34,7.68,5.98,8.12c5.2,0.41,10.33,1.88,15.48,2.88"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M550.54,359c-0.33,3.67-0.67,7.33-1,11"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M694.29,964.5c-1,2-2,4-2.99,6"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M118.79,854c-0.33,2-0.67,4-1,6"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1,1111.5c1.66,1,3.33,2,4.99,3"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M98.83,889c2,0.5,3.99,1,5.99,1.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M835.05,732.5c-2.61,4.67-5.8,9.14-4.57,15.02c0.08,0.38-0.92,0.98-1.42,1.48"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M129.77,868.5c-5.72-3.46-8.44-2.49-10.52,3.49c-0.73,2.09-1.95,4.01-2.95,6.01"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M520.09,308.5c-0.5,2-1,4-1.5,6"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M123.29,854c-5.32-0.83-10.65-1.67-15.97-2.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M665.84,965c3.47-1.27,5.9,1.2,6.19,3.54c0.26,2.16-2.03,4.63-3.19,6.96"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M826.06,537.5c-1.46,2.77-1.34,6.71-5.49,7.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M255.06,970.5c-1.83,0.17-3.66,0.33-5.49,0.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M83.35,1140c3.49,1.5,6.99,3,10.48,4.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M740.21,803c-1.83,2.5-3.66,5-5.49,7.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M261.54,947.5c0,3,0,6,0,9"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M233.59,798.5c3.17,0,6.34,0,10.1,0c-1.4,2.69-2.25,4.35-3.11,6"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M281.51,1058c0,4,0,8,0,12"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M661.85,960.5c2.05,3.15-0.41,5.44-1.5,8"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M15.47,890.5c-4.82,0.17-9.65,0.33-14.47,0.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M408.29,820.5c1.83,1.33,3.66,2.67,5.49,4"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M268.53,947c0.17,3,0.33,6,0.5,9"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M4.99,891.5c0,3,0,6,0,9"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M1,783.5c0.17,1.33,0.33,2.67,0.5,4"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M671.83,1027.5c-1.5,2.5-2.99,5-4.49,7.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M8.98,689.5c-0.92,3.17-1.83,6.33-2.75,9.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M943.86,725.5c0.67,2.33,1.33,4.67,2,7"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M206.14,1175c-2.66-3.68-4.55-2.7-5.99,1"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M745.2,648c1,0.83,2,1.67,2.99,2.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M909.91,744c-0.67,0.17-1.33,0.33-2,0.5"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M550.04,733.5c0,0.67,0,1.33,0,2"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M614.93,1214c0.17,0.67,0.33,1.33,0.5,2"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M866.49,1254c-4.26,1.23-7.21,3.97-6.33,8.47c0.84,4.3,4.46,6.2,8.83,5.99c2.51-0.12,4.99-0.63,7.49-0.96
				c-0.83-2.83-1.56-5.7-2.52-8.49c-1.12-3.25-3.39-5.09-6.96-5.01"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M581.49,935.5c-4.27,1.57-7.43,5.46-6.49,8c1.62,4.36,6.63,4.2,10.42-0.55c1.07-1.34,2.4-4.1,1.85-4.78
				c-1.13-1.4-3.45-1.84-5.28-2.67"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M601.45,1234.5c-5.05,1.27-9.67,3.33-12.42,8.03c-1,1.71-1.34,3.82-2.48,7.24c9.79-0.84,18.14-1.2,26.34-2.52
				c2.1-0.34,3.7-3.75,5.53-5.75c-1.83-1.67-3.4-4.04-5.55-4.84c-3.44-1.27-7.26-1.5-10.92-2.16"/>
			<path fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
				M93.84,32c-3.24,3.97-1.64,10.36,3.49,14c3.09,2.19,7.65,0.82,9.98-3c2.33-3.82,0.57-7.8-4.51-9.43
				c-2.71-0.87-5.64-1.07-8.46-1.57"/>
		
			</g>
			</svg>
		</div>
    )
}