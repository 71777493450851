import React from 'react'
import {Navbar, Nav} from 'react-bootstrap'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

function NavbarBase() {

    const {token} = useSelector(state => state.auth);

    return (
        <div>
            {(!localStorage.getItem("token")) ? 
            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
            <Navbar.Brand><Link to = '/'><h1>Color By Nature</h1></Link></Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav>
                    <Nav.Link><Link className = 'text-decoration-none' to = '/'>Home</Link></Nav.Link>
                    <Nav.Link><Link className = 'text-decoration-none' to = '/gallery'>Image Gallery</Link></Nav.Link>
                    <Nav.Link><Link className = 'text-decoration-none' to = '/login'>Login/Create Account</Link></Nav.Link>
                </Nav>
            </Navbar.Collapse>
            </Navbar>
             : 
            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
            <Navbar.Brand><Link to = '/'><h1>Color By Nature</h1></Link></Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav>
                    <Nav.Link><Link className = 'text-decoration-none' to = '/'>Home</Link></Nav.Link>
                    <Nav.Link><Link className = 'text-decoration-none' to = '/gallery'>Image Gallery</Link></Nav.Link>
                    <Nav.Link><Link className = 'text-decoration-none' to = '/mygallery'>My Gallery</Link></Nav.Link>
                    <Nav.Link><Link className = 'text-decoration-none' to = '/logout'>Logout</Link></Nav.Link>
                </Nav>
            </Navbar.Collapse>
            </Navbar>}
        </div>
    )
}

// const mapStateToProps = state => ({
//     token: state.token
// });


export default NavbarBase
// export default connect(mapStateToProps)(NavbarBase)