import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import ColorPlate from "./ColorPlate";
import LoadingSpeaner from "../LoadingSpeaner";
import ColoredInImage from "../Images/ColoredInImage";
import { resetArray } from "./array";
import { API_URL } from "../../GlobalConfig";

const ColorPage = () => {
  let { id, component } = useParams();
  const [image, setImage] = useState({});
  const [color, setColor] = useState("blue");
  const [fetched, setFetched] = useState(false);
  const [user, setUser] = useState({});
  const [fillColor, setFillColor] = useState("");
  const [reset, setReset] = useState(false);
  const [details, setDetails] = useState({});
  // const [colorReset, setColorReset] = useState(false)

  const token = localStorage.getItem("token");
  const history = useHistory();
  // let location = useLocation();

  // console.log("component", component)

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      history.push("/login");
    }

    const userData = localStorage.getItem("user");
    setUser(JSON.parse(userData));

    fetch(`${API_URL}/allimage`)
      .then((r) => r.json())
      .then((data) => {
        const imageDetails = data.find((detail) => detail.id === Number(id));
        setDetails(imageDetails);
      });

    var formdata = new FormData();
    formdata.append("user_id", user.id);
    formdata.append("image_id", id);
    formdata.append("fill_colors", JSON.stringify(resetArray));

    const config = {
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      headers: { Authorization: `Bearer ${token}` },
      body: formdata,
    };

    fetch(`${API_URL}/checkimage`, config)
      .then((r) => r.json())
      .then((data) => {
        // console.log("checkimage on post",data)
        setImage(data);
        if (data.length > 0) {
          setFillColor(JSON.parse(data[0].fill_colors));
        }
        setFetched(true);
      });
  }, []);

  if (!fetched) {
    return <LoadingSpeaner />;
  }

  if (reset === true) {
    return <LoadingSpeaner />;
  }

  if (fetched && !image) {
    return <h1>Image Not found</h1>;
  }

  const onFillColor = (i) => {
    // var fillColor = JSON.parse(image[0].fill_colors)
    let newFillColors = fillColor.slice(0);
    newFillColors[i] = color;

    // console.log("posting fill color", newFillColors[i]);
    setFillColor(newFillColors);
    var formdata = new FormData();
    formdata.append("image_id", id);
    formdata.append("fill_colors", JSON.stringify(newFillColors));

    fetch(`${API_URL}/color_image`, {
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      headers: { Authorization: `Bearer ${token}` },
      body: formdata,
    }).then((r) => r.json());
    // .then(data => {
    //     console.log("On post api response", data)
    // })
  };

  const resetImage = () => {
    setReset(true);
    // console.log("reset image")
    var formdata = new FormData();
    formdata.append("image_id", id);
    formdata.append("fill_colors", JSON.stringify(resetArray));

    fetch(`${API_URL}/color_image`, {
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      headers: { Authorization: `Bearer ${token}` },
      body: formdata,
    })
      .then((r) => r.json())
      .then((data) => {
        // console.log("On reset response", data)
        setImage(data);
        if (data.length > 0) {
          setFillColor(JSON.parse(data[0].fill_colors));
          setReset(false);
        }
      });
  };

  return (
    <div className="mr-3 ml-3">
      <div className="row flexreverse mt-4">
        <div className="col-md-3">
          <ColorPlate currentColor={color} changeColor={setColor} />
        </div>
        <div className="col-md-6">
          <ColoredInImage
            size="large"
            component={component}
            onFill={onFillColor}
            fillColors={fillColor}
          />
          <div className="row m-2">
            <div className="col-9">
              <p className="m-2">
                * This image will save automatically, so color away! 👍
              </p>
            </div>
            <div className="col-3">
              <button onClick={resetImage} className="btn btn-danger">
                Reset image
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-12 image-description">
          <h2 id="description-heading">{component}</h2>
          <hr />
          <p>{details?.description}</p>
        </div>
      </div>
    </div>
  );
};

export default ColorPage;
