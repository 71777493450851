import React from 'react'
import { useDispatch } from 'react-redux';
import { Redirect, withRouter } from "react-router-dom";

function Logout() {
    const dispatch = useDispatch();
    dispatch({
        type: 'CLEAR_TOKEN'
    });

    return (
        <Redirect to = '/' push = {true}/>
    )
}

export default withRouter(Logout)
