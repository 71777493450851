import React from "react";
import "./Arches.css";

export default function Arches(props) {
  return (
    <div className={`arches-container ${props.size}`}>
      <svg xmlns="http://www.w3.org/2000/svg" id={props.id}>
        <g className="arches-image">
          <path
            d="M1164,1501c-387.666,0-775.333,0-1163,0C1,1001,1,501,1,1.001C388.666,1,776.333,1,1163.999,1
                C1164,501,1164,1001,1164,1501z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(0)}
            fill={props.fillColors[0]}
            d="M26.095,579.962c-7.828,3.065-15.656,6.13-23.744,9.297c0-59.865,0-119.614,0-179.106
                c19.343-3.866,38.659-5.343,58.614-3.139c-0.583,2.24-1.017,3.903-1.63,6.258c12.478-1.971,24.5-3.869,35.375-5.586
                c0.771,2.422,0.848,4.511,1.649,4.841c2.838,1.169,5.941,2.473,8.914,2.405c12.152-0.275,24.297-1.509,36.436-1.402
                c5.231,0.046,10.668,1.941,15.614,3.967c9.834,4.028,19.921,4.793,30.361,4.431c4.897-0.17,10.021,0.23,14.716,1.543
                c8.562,2.394,16.847,2.535,25.618,1c6.19-1.083,12.759,0.025,19.16,0.108c3.856,0.05,7.714,0.009,11.572,0.009
                c0.057-0.441,0.114-0.883,0.172-1.324c-2.094-0.267-4.205-0.438-6.278-0.818c-6.496-1.188-13.34-1.522-19.394-3.876
                c-15.046-5.851-30.682-6.936-46.478-7.61c-6.326-0.271-11.668-2.439-15.157-8.869c6.27,1.486,12.094,3.623,18.049,4.086
                c7.179,0.559,14.692,0.712,21.654-0.813c8.365-1.833,16.09-1.771,23.902,1.5c3.067,1.283,6.186,2.452,9.313,3.586
                c4.699,1.704,9.359,2.003,14.097-0.125c1.611-0.724,3.554-1.046,5.331-0.998c4.948,0.134,9.215-0.783,13.539-3.802
                c5.782-4.037,12.838-2.844,19.444-1.99c7.67,0.99,15.288,2.391,23.379,3.686c0.593-5.018-1.055-8.673-4.213-11.558
                c-6.603-6.026-14.098-9.933-23.442-9.249c-1.139,0.083-2.861-0.735-3.472-1.68c-3.592-5.557-8.558-8.214-15.033-7.828
                c-2.03-5.543-3.738-11.074-6.041-16.347c-11.111-25.435-31.805-37.019-58.384-39.631c-10.216-1.004-20.288,0.049-29.895,4.105
                c-11.921,5.035-19.371,13.737-22.003,26.475c-0.201,0.973-0.478,1.933-0.779,2.88c-0.093,0.293-0.382,0.524-0.708,0.95
                c-7.568-3.005-15.068-3.914-22.084,1.691c-0.572,0.458-1.753,0.207-2.65,0.197c-3.54-0.037-7.08-0.097-10.62-0.176
                c-1.045-0.024-2.17,0.083-3.118-0.259c-3.323-1.196-6.586-2.562-10.882-4.264c-4.395-12.417-10.475-25.219-27.763-25.326
                c-17.535-0.109-32.749,4.587-40.005,23.09c-1.776-0.89-3.196-1.694-4.688-2.33c-5.703-2.432-12.273-1.205-15.827,3.677
                c-5.845,8.03-7.683,16.817-3.498,26.341c0.676,1.538,0.748,3.343,1.181,5.413c-5.206-1.528-9.328-2.738-14.305-4.199
                c0-1.215,0-2.968,0-4.722c0-49.499-0.002-98.999,0.003-148.498c0.001-5.879,0.48-6.011,6.22-5.652
                c5.778,0.36,11.604,0.201,17.398-0.009c7.307-0.265,14.6-1.072,21.906-1.185c15.471-0.237,30.926-0.366,45.877-5.145
                c6.803-2.174,13.437-1.871,19.915,1.265c10.347,5.008,20.894,6.39,31.902,2.047c1.917-0.756,4.27-0.347,6.403-0.62
                c10.598-1.36,21.189-2.77,31.783-4.161c0.048-0.494,0.096-0.988,0.144-1.482c-0.981-0.756-1.888-1.643-2.955-2.247
                c-6.057-3.436-12.204-6.712-18.216-10.222c-5.359-3.127-11.003-4.909-17.188-3.801c-9.976,1.789-18.207-0.565-26.306-7.21
                c-5.681-4.66-13.555-6.648-20.457-9.814c-3.881-1.781-7.745-3.604-11.674-5.274c-6.17-2.623-12.389-5.133-17.839-7.383
                c6.671-1.481,14.222-0.834,21.633,1.578c6.181,2.013,11.995,2.087,18.471,0.042c9.675-3.055,19.236,0,27.96,4.358
                c5.896,2.946,11.369,6.922,16.577,11.016c5.899,4.636,11.707,6.867,19.307,4.235c10.506-3.639,21.196-2.19,31.359,1.496
                c11.406,4.137,22.546,9.025,33.731,13.752c9.352,3.952,18.977,5.848,29.177,5.596c5.26-0.131,10.541,1.244,15.828,1.404
                c8.405,0.254,16.826-0.069,25.234,0.104c5.403,0.111,11.008-0.128,16.144,1.227c7.664,2.021,15.14,3.791,23.103,2.391
                c5.808-1.021,11.093,0.189,16.14,3.503c9.101,5.975,18.991,7.375,29.665,4.697c3.188-0.8,6.906-0.369,10.201,0.339
                c6.16,1.322,11.731,1.417,15.338-1.032c-4.663-0.571-8.67-1.022-12.663-1.572c-1.476-0.202-3.15-0.269-4.341-1.02
                c-12.844-8.099-27.309-12.421-42.229-18.113c10.587-1.598,19.926-1.812,29.106,2.839c3.844,1.947,8.531,3.019,12.853,3.072
                c9.573,0.12,18.815,0.941,27.907,4.408c8.504,3.242,17.064,4.334,24.679-3.107c1.719-1.68,5.327-2.193,7.974-1.999
                c5.601,0.411,11.13,1.712,16.707,2.521c5.691,0.824,7.972,3.876,6.615,9.425c-5.747-0.516-11.479-1.475-17.207-1.462
                c-18.886,0.042-37.26,16.33-39.772,35.065c-0.3,2.232-0.02,4.692,0.611,6.867c1.063,3.666,2.978,5.241,7.423,3.654
                c23.828-8.505,48.349-8.62,73.006-4.847c10.238,1.567,19.128,6.572,27.477,12.565c2.835,2.034,5.659,4.403,8.87,5.493
                c2.66,0.903,6.315,1.093,8.776-0.04c8.517-3.92,16.737-3.939,25.014,0.179c6.11,3.04,12.04,6.451,18.208,9.362
                c7.312,3.451,13.88,4.596,18.924-4.15c1.743-3.022,4.804-3.289,7.962-2.408c6.479,1.807,11.878,1.495,15.565-5.522
                c2.604-4.956,7.957-5.358,12.834-4.368c7.315,1.486,14.49,3.657,21.736,5.492c1.881,0.477,3.804,0.786,7.388,1.514
                c-3.371-8.733-5.641-16.485-13.293-20.834c-8.964-5.094-18.512-7.159-28.852-4.834c-2.084,0.469-4.483-0.464-6.736-0.75
                c0.043-0.603,0.087-1.205,0.131-1.808c12.799-1.041,25.588-2.604,38.4-2.838c4.438-0.081,9.486,2.342,13.302,4.993
                c10.479,7.283,21.603,9.344,33.691,5.837c14.111-4.094,27.624-3.88,40.247,4.53c3.185,2.121,6.482,4.181,9.28,6.756
                c17.879,16.457,39.104,20.149,62.337,16.982c6.75-0.92,13.599-1.299,20.415-1.509c8.257-0.254,15.325,2.825,22.32,7.333
                c8.736,5.631,18.078,10.5,27.609,14.665c9.217,4.027,19.13,4.21,28.889,1.298c4.889-1.459,9.416-1.182,13.911,1.429
                c8.027,4.664,15.914,4.736,24.011-0.405c10.103-6.415,20.7-5.996,31.544-1.452c6.568,2.752,13.379,4.952,20.159,7.158
                c3.719,1.209,5.036-0.349,4.632-4.329c-1.146-11.291-11.695-22.057-24.91-24.855c-2.759-0.584-5.596-0.844-8.411-1.102
                c-4.776-0.438-9.496-0.53-12.909-4.979c-1.155-1.506-3.718-2.531-5.721-2.685c-8.487-0.647-17.006-0.885-26.015-1.295
                c-0.758-12.876-6.565-24.315-14.858-34.571c-9.218-11.4-20.85-18.635-35.955-16.368c-6.306,0.945-12.439,4.326-18.152,7.5
                c-4.125,2.292-7.426,6.069-11.516,9.542c-9.409-20.522-27.369-20.953-46.35-19.453c-3.2-10.28-12.433-9.917-20.624-11.665
                c-3.927-0.839-7.712-2.353-11.546-3.606c-0.783-0.257-1.528-1.088-2.24-1.029c-5.4,0.439-7.488-3.438-10.141-7.077
                c-11.236-15.412-34.896-13.459-44.004,3.493c-0.225,0.418-0.529,0.794-1.229,1.83c-4.004-15.572-9.426-29.794-25.149-36.455
                c-14.588-6.181-29.251-7.562-43.672,0.903c-7.428-9.516-16.938-16.177-27.285-21.682c-12.542-6.671-25.548-6.606-38.722-1.997
                c-2.67,0.935-5.324,1.93-7.931,3.027c-5.56,2.343-10.626,2.924-16.621-0.037c-8.23-4.065-16.649-1.757-23.157,4.27
                c-4.841,4.481-9.332,8.717-16.687,8.478c-1.67-6.531-3.825-13.827-11.089-17.179c-7.438-3.433-14.234,0.307-21.864,4.295
                c0.824-12.1-6.49-18.522-14.603-24.74c-3.352-2.569-5.29-6.925-8.504-9.748c-2.727-2.396-6.458-3.609-9.554-5.637
                c-1.42-0.93-2.726-2.382-3.496-3.896c-4.127-8.108-7.005-17.08-12.257-24.35c-23.241-32.17-66.13-18.691-79.929,2.896
                c-0.35,0.547-0.704,1.091-1.531,2.369c-1.364-3.129-2.19-5.934-3.699-8.306c-3.804-5.98-7.063-12.66-12.082-17.442
                c-10.073-9.599-21.559-8.118-30.297,2.676c-0.729,0.9-1.395,1.854-2.094,2.789c-3.124-1.228-6.069-2.995-9.202-3.439
                c-3.001-0.427-6.196,0.522-9.795,0.921c-3.168-5.306-6.41-11.096-10.002-16.659c-4.638-7.185-10.504-12.974-19.334-14.685
                c-11.858-2.298-20.529,3.495-28.328,11.361c-1.156,1.167-2.155,2.489-3.835,4.449c-3.199-21.109-14.703-34.208-34.845-39.458
                c319.302,0,638.605,0,958.327,0c0,195.559,0,390.955,0,586.947c-2.788-0.96-5.521-1.906-8.258-2.842
                c-8.177-2.793-16.49-5.245-24.501-8.454c-11.718-4.693-22.987-3.09-34.375,0.993c-1.655,0.595-3.821,0.038-5.695-0.296
                c-10.604-1.888-21.17-4.017-31.807-5.693c-2.1-0.331-4.538,0.732-6.672,1.543c-3.882,1.476-7.545,3.604-11.502,4.781
                c-3.499,1.042-7.281,1.117-10.925,1.701c-3.088,0.495-6.374,0.587-9.192,1.766c-8.434,3.527-16.644,7.585-25.034,11.221
                c-1.556,0.674-4.241,1.179-5.23,0.354c-14.811-12.348-32.862-18.811-49.556-27.729c-7.771-4.151-15.756-8.148-22.8-13.356
                c-9.246-6.836-17.927-4.07-27.495,1.497c-2.678,1.559-4.929,3.853-7.072,4.297c-10.329-4.369-20.687-8.673-30.97-13.146
                c-3.188-1.387-6.659-2.719-9.117-5.02c-2.479-2.322-5.026-5.635-5.525-8.828c-2.327-14.9-6.202-28.717-17.543-39.816
                c-3.331-3.262-4.162-9.101-6.064-13.789c-0.982-2.423-1.199-5.432-2.797-7.282c-7.325-8.482-15.021-16.647-22.573-24.934
                c-0.894-0.98-2.126-1.87-2.536-3.036c-3.032-8.618-10.269-13.207-17.021-18.356c-3.181-2.424-5.845-5.517-8.959-8.037
                c-2.131-1.725-4.637-2.984-7.806-4.975c1.893-0.347,2.961-0.848,3.896-0.665c6.479,1.268,11.987-1.326,16.938-4.794
                c3.034-2.125,3.176-5.995,1.119-9.015c-3.085-4.528-3.299-8.812-1.49-13.957c1.147-3.263,0.563-7.135,0.755-10.733
                c-0.486-0.104-0.973-0.206-1.459-0.31c0.982-0.832,1.931-1.709,2.953-2.489c3.522-2.687,3.47-5.513,0.115-8.16
                c-2.169-1.712-3.002-3.311-2.49-6.33c0.359-2.121-0.656-5.42-2.256-6.739c-3.759-3.098-7.941-6.225-12.485-7.705
                c-8.029-2.617-16.471-3.955-24.709-5.953c-3.532-0.856-6.928-2.401-10.49-2.975c-6.54-1.053-13.14-1.952-19.748-2.317
                c-14.465-0.801-27.761-6.084-41.509-9.802c-8.555-2.313-17.793-2.034-26.692-3.171c-2.866-0.366-5.606-1.635-8.444-2.325
                c-8.877-2.158-17.676-5.054-26.688-6.117c-9.01-1.064-18.241-0.208-27.374-0.297c-16.024-0.154-32.054-0.697-48.069-0.43
                c-6.072,0.102-12.111,2.011-18.171,3.063c-10.294,1.789-20.66,3.241-30.863,5.447c-5.695,1.231-11.102,3.764-16.679,5.585
                c-3.732,1.219-7.715,1.817-11.272,3.397c-7.21,3.203-9.432,12.187-5.068,18.351c-7.092,4.435-11.508,10.408-14.993,18.333
                c-3.254,7.397-5.285,16.158-12.668,21.416c-1.134,0.808-2.289,2.359-2.419,3.667c-0.597,5.984-4.655,9.25-8.947,12.53
                c-4.13,3.156-8.323,6.302-11.994,9.95c-1.338,1.33-1.292,4.084-1.8,6.207c-0.513,2.141-0.183,5.187-1.512,6.312
                c-7.9,6.686-9.635,16.931-15.071,24.987c-8.889,13.175-11.79,28.812-17.614,43.247c-0.731,1.814-0.968,3.881-1.15,5.855
                c-1.835,19.846-6.933,39.387-5.599,59.534c0.05,0.755-0.435,1.545-0.904,3.086c-7.927-3.951-15.674-7.545-23.156-11.622
                c-6.97-3.798-13.627-6.128-20.92-0.732c-4.279,3.166-8.791,3.485-13.928,0.972c-4.574-2.238-9.737-3.318-14.705-4.678
                c-1.313-0.359-2.969-0.164-4.286,0.297c-8.304,2.909-16.897,5.277-24.731,9.162c-8.057,3.996-15.343,2.975-22.834-0.435
                c-6.054-2.755-12.118-5.551-17.908-8.806c-5.172-2.907-10.02-3.01-15.162,0.176c12.829-4.446,20.448,6.746,30.858,9.895
                c-6.205,1.287-12.009,4.212-18.458,0.126c-5.003-3.171-10.862-4.976-16.296-7.485c-5.584-2.578-11.274-2.742-16.084,1.038
                c-11.353,8.919-22.977,8.974-35.319,2.482c-11.894-6.255-23.295-4.871-34.362,2.416c-4.439,2.923-9.226,5.321-13.877,7.918
                c-6.097,3.402-12.098,6.298-19.568,6.488c-5.173,0.132-10.27,3.22-15.4,4.987c1.506,1.83,0.152,2.793-1.243,3.397
                c-6.099,2.645-12.363,4.878-18.629,7.086C29.04,581.681,27.28,581.554,26.095,579.962z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(1)}
            fill={props.fillColors[1]}
            d="M1025.994,1153.736c2.563,6.047,5.184,12.07,7.674,18.146
                c2.764,6.743,4.041,13.472,1.938,20.887c-1.224,4.315-0.605,9.152-0.797,13.345c-9.42-1.396-18.762-2.895-28.142-4.096
                c-2.529-0.323-3.64-1.148-3.374-3.688c0.52-4.965,0.822-9.96,1.542-14.894c1.281-8.781-0.237-16.631-7.254-23.055
                c10.386,12.808,1.866,27.04,3.938,40.537c-14.278,0-28.218,0-42.436,0c-1.364-5.589-2.786-10.867-3.919-16.208
                c-1.148-5.415-3.489-10.01-7.912-13.469c-3.063-2.397-4.224-5.045-2.423-9.105c1.899-4.285-2.575-9.48-9.237-9.223
                c0.976,0.482,2.04,0.842,2.91,1.47c3.22,2.323,5.46,4.71,2.394,9.023c-2.358,3.317-0.604,6.946,2.101,8.87
                c9.72,6.913,9.216,18.254,13.468,28.396c-10.262,1.849-19.984,1.765-29.693,0.918c-23.704-2.067-47.416-4.081-71.079-6.55
                c-21.52-2.244-43.072-4.466-64.446-7.763c-30.909-4.768-61.565-11.245-92.528-15.572c-24.041-3.36-48.384-4.563-72.595-6.695
                c-21.226-1.868-42.433-4.026-63.688-5.461c-32.666-2.204-63.312-13.756-95.116-19.853c-21.375-4.098-42.875-7.607-64.403-10.814
                c-17.471-2.604-34.742-0.201-52.149,2.405c-8.691,1.302-18.11-1.333-27.073-2.91c-47.683-8.391-95.322-17.027-142.983-25.542
                c-19.826-3.543-39.591-7.547-59.527-10.311c-15.108-2.095-30.432-2.765-45.686-3.589c-3.479-0.188-7.11,1.449-10.579,2.541
                c-1.531,0.481-3.579,1.377-4.118,2.632c-4.35,10.128-13.997,8.727-21.9,9.389c-11.896,0.997-23.938,0.26-36.361,0.26
                c0-32.2,0-64.24,0-96.724c13.379,2.416,26.599,4.92,39.862,7.176c33.31,5.667,66.625,11.31,99.965,16.798
                c42.381,6.978,84.761,13.965,127.199,20.586c15.278,2.383,30.691,4.078,46.104,5.322c5.103,0.411,10.651-0.876,15.545-2.638
                c6.542-2.356,7.72-6.169,5.404-12.666c-0.978-2.743-1.942-5.491-2.978-8.42c7.394-0.382,14.185,0.09,20.567-1.308
                c5.646-1.235,11.259-3.916,16.1-7.142c4.081-2.72,6.124-7.427,4.75-13.78c14.738,7.398,29.474,14.804,44.219,22.189
                c3.194,1.601,3.26,4.156,2.52,7.188c-1.523,6.246-5.066,10.894-10.95,13.399c-5.344,2.275-10.906,4.032-16.338,6.109
                c-5.742,2.195-11.648,4.091-17.118,6.843c-10.975,5.521-13.14,18.885-4.533,27.624c2.753,2.796,6.095,5.325,9.634,6.98
                c16.021,7.495,32.209,14.634,48.689,21.558c-1.356-0.964-2.604-2.162-4.086-2.854c-11.599-5.403-23.305-10.58-34.846-16.103
                c-5.524-2.643-11.264-5.246-16.029-8.983c-10.444-8.193-8.697-21.403,3.412-26.88c6.947-3.143,14.363-5.23,21.462-8.062
                c4.922-1.962,9.841-4.064,14.438-6.675c5.84-3.315,8.009-9.21,8.787-15.483c0.199-1.6-0.935-3.883-2.199-5.036
                c-2.435-2.221-5.208-4.269-8.18-5.669c-15.168-7.148-30.444-14.066-46.321-20.545c0.805,1.057,1.558,2.156,2.422,3.16
                c3.58,4.159,3.772,7.22,0.356,11.548c-4.69,5.943-11.394,7.986-18.496,8.561c-5.624,0.454-11.314,0.209-16.972,0.101
                c-3.438-0.065-4.662,1.165-3.394,4.521c0.941,2.488,1.77,5.02,2.654,7.529c2.106,5.972,1.123,9.051-4.993,10.597
                c-5.713,1.444-11.913,2.478-17.698,1.848c-20.02-2.182-40.018-4.757-59.905-7.925c-56.718-9.035-113.399-18.308-170.051-27.753
                c-24.793-4.134-49.46-9.022-74.231-13.3c-4.1-0.707-5.95-2.05-5.257-6.299c0.259-1.589,0.042-3.255,0.042-5.569
                c7.564,0.976,14.6,1.845,21.625,2.794c36.29,4.899,72.574,9.842,108.867,14.717c11.547,1.552,23.083,3.434,34.688,4.227
                c7.365,0.503,14.889-0.404,22.257-1.342c2.688-0.342,5.132-2.601,8.527-4.44c-5.122-4.567-9.454-8.431-14.371-12.814
                c10.692,0,20.274-0.04,29.856,0.009c17.805,0.09,35.636,0.838,53.402,0.062c7.381-0.321,15.067-2.919,21.815-6.149
                c8.735-4.183,9.564-9.972,2.261-16.073c-10.045-8.392-20.397-16.822-31.8-23.082c-14.35-7.877-29.893-13.58-44.921-20.222
                c-0.255,0.554-0.51,1.108-0.765,1.662c3.175,1.384,6.306,2.88,9.531,4.133c21.51,8.355,42.146,18.298,60.075,33.124
                c2.688,2.223,5.205,4.684,7.592,7.23c3.874,4.132,4.025,6.862-0.875,9.627c-5.393,3.043-11.485,6.204-17.455,6.605
                c-16.735,1.127-33.563,1.052-50.355,1.072c-11.987,0.015-23.975-0.604-35.963-0.882c-1.737-0.04-3.482,0.251-5.224,0.389
                c-0.284,0.515-0.568,1.028-0.852,1.542c4.537,4.377,9.074,8.753,13.95,13.456c-5.073,4.106-11.277,4.758-17.245,4.32
                c-13.431-0.984-26.842-2.457-40.199-4.208c-35.814-4.694-71.596-9.633-107.389-14.492c-8.877-1.205-17.746-2.479-26.788-3.744
                c0-11.756,0-23.034,0-35.087c2.05,0,3.972,0.104,5.879-0.017c14.934-0.938,29.919-1.444,44.769-3.136
                c5.549-0.632,11.203-3.369,16.061-6.361c8.401-5.174,8.346-11.612,0.237-17.153c-5.071-3.466-10.645-6.186-15.884-9.417
                c-3.108-1.916-6.37-3.815-8.898-6.379c-2.8-2.839-4.843-6.425-7.771-10.45c30.867-10.319,61.446-7.442,91.723-8.865
                c-3.978-0.246-7.954-0.526-11.934-0.732c-25.2-1.305-50.289-1.122-74.924,5.433c-6.426,1.71-7.811,3.844-3.997,9.302
                c3.154,4.515,7.406,8.526,11.881,11.787c5.485,3.998,11.847,6.767,17.58,10.454c8.031,5.167,8.237,10.746-0.403,14.695
                c-7.869,3.598-16.606,6.086-25.195,7.125c-11.525,1.395-23.274,0.946-34.929,1.227c-1.296,0.031-2.603-0.351-4.157-0.577
                c0-25.943,0-51.702,0-78.026c13.843,1.27,26.053,9.674,39.994,8.507c0.148-0.44,0.296-0.882,0.444-1.322
                c-2.286-1.092-4.586-2.156-6.855-3.282c-4.208-2.089-8.171-4.271-9.954-9.219c-0.544-1.511-2.789-2.853-4.53-3.405
                c-5.218-1.655-10.627-2.71-15.835-4.391c-1.41-0.455-3.286-2.234-3.379-3.519c-0.382-5.287-0.16-10.617-0.16-16.897
                c7.2,1.962,13.948,3.47,20.464,5.667c7.089,2.39,14.011,5.306,20.917,8.202c7.749,3.249,14.625,7.479,19.763,14.62
                c7.423,10.315,19.2,13.965,30.753,16.023c34.7,6.181,69.014,15.065,104.511,16.126c3.472,0.104,6.952,0.333,10.395,0.768
                c1.209,0.152,2.342,1.053,3.471,1.682c2.621,1.463,5.089,3.283,7.846,4.401c14.333,5.816,29.809,4.608,44.704,7.026
                c36.788,5.97,73.512,12.43,110.431,17.443c16.723,2.271,33.857,1.395,50.783,2.344c4.685,0.263,9.638,1.203,13.847,3.167
                c5.983,2.791,11.924,6.185,16.947,10.428c16.699,14.104,35.68,23.924,56.306,30.469c22.655,7.188,45.307,14.725,68.447,19.952
                c20.821,4.704,42.112,3.658,63.51,0.461c12.19-1.822,21.874-7.345,31.91-12.968c7.644-4.284,8.232-9.01,5.34-17.303
                c-2.934-8.413-6.979-16.957-1.873-26.316c1.24-2.274-0.168-5.882,0.158-8.819c0.146-1.305,1.558-3.514,2.419-3.525
                c5.55-0.073,11.107,0.332,16.66,0.612c4.296,0.216,8.622,0.223,12.876,0.77c12.154,1.564,24.249,3.627,36.423,4.989
                c14.723,1.647,29.537,2.486,44.245,4.229c11.351,1.345,22.599,3.547,33.893,5.36c9.668,1.553,19.771,1.831,28.896,4.959
                c11.69,4.007,23.555,5.946,35.625,6.942c16.755,1.383,33.563,2.179,50.363,2.919c7.106,0.313,14.248-0.183,21.374-0.241
                c1.307-0.011,2.96-0.134,3.87,0.56c5.971,4.552,12.955,4.729,19.954,5.1c9.297,0.494,18.596,1.007,27.874,1.764
                c1.233,0.101,2.995,1.443,3.415,2.607c4.007,11.104,7.964,22.233,11.498,33.493c1.014,3.231,1.114,6.964,0.752,10.372
                c-0.958,8.999,1.208,17.138,5.469,24.946c1.988,3.644,4.021,7.348,5.328,11.26c2.624,7.858,2.31,15.874-1.003,23.454
                c-9.073,20.767-8.358,41.764-2.087,62.994c0.949,3.213,1.806,6.453,2.704,9.681c0.578-0.128,1.156-0.257,1.734-0.385
                c-0.548-2.545-0.965-5.126-1.665-7.628c-4.53-16.189-7.46-32.502-3.221-49.224c1.263-4.981,2.969-9.881,4.854-14.668
                c4.376-11.12,3.379-21.703-1.963-32.291c-2.898-5.744-5.062-11.883-7.218-17.965c-0.688-1.943-0.669-4.34-0.31-6.414
                c1.393-8.023-0.174-15.507-3.207-22.918c-3.651-8.919-6.989-17.967-10.578-27.256c2.671-1.612,4.722-1.294,7.171,1.603
                c4.484,5.303,9.302,10.391,14.458,15.037c5.817,5.242,10.991,10.285,8.024,19.189c-0.558,1.672,0.125,4.021,0.847,5.811
                c3.288,8.155,6.007,16.68,10.39,24.223c8.568,14.745,12.983,30.923,18.285,46.858c1.815,5.455,4.819,10.517,7.303,15.746
                c0.355,0.747,1.018,1.38,1.247,2.151c4.633,15.598,10.766,30.904,11.345,47.386c0.277,7.913,2.469,14.78,5.887,21.847
                c7.739,16.003,14.799,32.346,21.77,48.705c2.633,6.179,4.265,12.784,6.336,19.2c1.101,3.411-2.896,13.582-6.128,15.119
                c-0.89,0.423-2.226,0.085-3.317-0.118c-16.645-3.101-31.223-11.22-45.885-19.083c-1.849-0.991-3.709-2.533-4.789-4.296
                c-6.9-11.265-18.187-15.735-29.928-19.185c-4.516-1.327-5.855-3.344-5.094-7.713c0.797-4.573,1.271-9.208,1.736-13.831
                c0.212-2.114,0.676-4.499-0.029-6.36c-3.409-8.996-7.16-17.863-10.851-26.751c-0.277-0.668-1.03-1.139-1.562-1.701
                C1026.616,1153.313,1026.306,1153.525,1025.994,1153.736z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(2)}
            fill={props.fillColors[2]}
            d="M1118.42,1499.695c-372.748,0-744.162,0-1115.95,0c0-71.4,0-142.594,0-214.241
                c12.55,1.055,24.804,2.004,37.042,3.129c46.797,4.302,93.62,8.194,140.681,6.328c8.445-0.335,16.895-1.647,25.233-3.123
                c3.658-0.647,7.282-2.443,10.504-4.404c6.834-4.158,8.637-9.31,6.606-17.073c-1.011-3.863-2.324-7.663-3.088-11.572
                c-1.229-6.285,0.279-8.145,6.628-8.746c10.104-0.957,20.218-1.801,30.313-2.832c2.124-0.217,4.236-0.896,6.267-1.61
                c12.003-4.222,15.167-12.061,9.606-23.401c-1.532-3.126-2.977-6.312-4.189-9.573c-1.496-4.025,0.018-7.104,4.061-8.181
                c6.25-1.662,12.58-3.588,18.974-4.053c35.16-2.56,70.235-2.498,104.911,5.2c2.939,0.652,5.954,0.962,8.935,1.431
                c0.137-0.402,0.274-0.805,0.412-1.207c-0.795-0.665-1.502-1.495-2.397-1.973c-38.969-20.778-79.598-37.38-122.987-46.106
                c-17.725-3.565-35.938-4.781-53.961-6.77c-72.425-7.989-145.14-4.21-217.738-5.223c-1.801-0.025-3.603-0.003-5.793-0.003
                c0-5.911,0-11.298,0-17.617c12.619,0,25.213,0.327,37.78-0.122c7.617-0.272,15.238-1.492,22.753-2.859
                c1.872-0.341,3.38-2.859,4.983-4.449c2.155-2.138,3.889-5.548,6.423-6.288c6.626-1.935,13.675-4.101,20.401-3.684
                c17.414,1.079,34.797,3.119,52.097,5.473c26.718,3.634,53.554,6.852,79.982,12.061c36.229,7.141,72.074,16.215,108.191,23.956
                c11.51,2.467,23.372,3.266,35.058,4.936c27.535,3.934,54.991,8.253,81.24,17.995c2.005,0.744,3.946,1.658,5.917,2.494
                c0.029,0.467,0.059,0.934,0.088,1.4c-5.482,0.878-10.966,1.756-17.477,2.798c1.637,2.079,2.569,4.122,4.157,5.128
                c5.599,3.545,11.129,7.454,17.211,9.934c27.733,11.305,55.689,22.062,83.468,33.259c8.01,3.228,15.767,7.117,23.492,10.998
                c2.88,1.447,5.338,3.735,9.494,6.723c-4.776,2.501-8.111,5.273-11.845,5.999c-9.446,1.837-19.049,2.965-28.63,3.998
                c-19.693,2.123-39.477,3.54-59.088,6.23c-6.687,0.918-13.362,4.086-19.335,7.474c-7.301,4.141-8.406,10.663-3.14,17.333
                c3.424,4.336,7.757,8.451,12.541,11.136c23.153,12.992,47.493,23.454,73.074,30.584c41.505,11.566,83.171,22.554,124.77,33.78
                c15.739,4.247,31.521,8.346,47.188,12.844c2.822,0.811,5.15,3.288,7.76,4.912c5.468,3.402,10.578,7.956,16.532,9.892
                c18.181,5.908,36.563,11.311,55.103,15.979c36.628,9.223,73.548,17.304,110.095,26.824c35.898,9.353,71.622,19.443,107.168,30.062
                c35.36,10.565,70.404,22.191,105.583,33.366C1116.122,1498.429,1116.661,1498.803,1118.42,1499.695z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(3)}
            fill={props.fillColors[3]}
            d="M397.921,562.029c-1.825-1.576-3.65-3.152-5.542-4.786
                c0.311-0.38,0.573-0.807,0.933-1.122c2.126-1.864,5.468-3.332,6.178-5.622c1.76-5.672,2.759-11.723-0.876-17.348
                c-1.08-1.67-2.155-3.437-2.728-5.312c-0.487-1.6-0.25-3.42-0.333-5.142c1.563,0.026,3.157-0.125,4.682,0.135
                c1.244,0.211,2.406,0.912,3.552,1.375c3.136-5.221,3.035-8.598-0.521-14.276c2.093,0,3.895,0,5.696,0c1.568,0,3.137,0,4.705,0
                c0.144-0.522,0.287-1.044,0.431-1.566c-2.985-1.322-5.956-2.679-8.96-3.96c-2.997-1.277-6.026-2.479-9.039-3.714
                c2.131-5.163,0.684-14.794-2.895-17.745c-0.825-0.681-3.503,0.31-4.986,1.141c-2.107,1.181-3.898,2.926-4.765,3.603
                c-4.291-4.803-7.752-8.675-11.165-12.493c2.482-2.624,5.039-5.099,7.322-7.803c2.189-2.595,4.654-5.162,3.451-9.222
                c-0.103-0.348,2.551-2.2,3.904-2.178c6.815,0.111,13.62,0.695,20.436,0.929c2.063,0.07,4.147-0.45,5.045-0.558
                c1.264-6.52,2.561-12.054,3.341-17.659c0.477-3.42,1.112-7.319-0.054-10.335c-1.348-3.488-0.05-6.329-0.177-9.381
                c-0.139-3.346-2.724-6.636-4.442-9.844c-0.492-0.918-1.675-1.465-3.716-3.155c3.786,0,6.261,0,8.368,0
                c2.218,1.091,4.385,2.156,6.955,3.42c-0.017,0.103,0.135,1.177-0.316,1.585c-4.888,4.424-0.42,8.862,0.205,13.154
                c0.158,1.09,3.427,1.728,5.267,2.572c0.114-1.407,0.486-2.852,0.291-4.214c-0.482-3.362-0.969-5.908,3.934-6.722
                c4.533-0.752,5.229-5.329,5.878-9.352c0.441-2.741,1.164-5.437,1.08-8.767c-1.036,2.223-2.687,4.367-2.98,6.685
                c-0.6,4.734-0.632,8.722-6.999,10.116c-4.46,0.978-3.488,6.017-2.615,10.027c-0.669-0.11-1.456-0.136-1.472-0.257
                c-0.512-4.173-5.004-7.771-1.142-12.657c2.328-2.947,0.424-4.996-5.772-5.668c2.201-1.541,3.891-2.428,5.191-3.709
                c1.687-1.66,3.063-3.635,4.574-5.475c-2.188-0.867-4.33-2.317-6.572-2.492c-6.466-0.502-7.477,1.25-4.189,8.633
                c-2.349,0.5-4.413,0.909-6.463,1.386c-1.355,0.314-2.688,0.725-4.032,1.092c0.54,1.233,0.743,2.898,1.678,3.629
                c4.876,3.812,7.608,7.898,4.523,14.404c-0.741,1.562,1.451,4.28,1.371,6.433c-0.195,5.242-0.913,10.465-1.457,15.692
                c-0.346,3.327-0.754,6.648-1.172,10.296c-12.369-0.68-25.511-1.402-39.405-2.166c5.66-9.601,11.51-19.537,17.381-29.461
                c0.673-1.137,2.183-2.251,2.105-3.286c-0.911-12.277,10.56-15.109,16.621-22.026c1.077-1.229,2.907-1.914,3.7-3.258
                c3.716-6.299,7.19-12.74,10.496-18.674c13.113,0,25.968,0,38.822,0c0.039-0.341,0.078-0.683,0.116-1.023
                c-12.264-0.727-24.528-1.453-37.886-2.244c5.081-8.15,6.906-18.018,14.664-23.268c5.138-3.478,12.258-4.025,18.231-5.808
                c0.852,2.642,1.502,4.66,2.343,7.27c-4.286,6-8.58,12.939-18.826,9.805c8.626,3.527,14.187-0.688,19.085-6.636
                c1.965,1.171,3.483,2.948,5.932,3.495c2.878,0.645,6.061,1.321,4.601,5.84c-0.383,1.185,1.401,2.296,2.299,3.399
                c0.991,1.22,2.85,1.912,2.836,4.541c-9.986,0.98-19.933,1.681-27.33-0.756c3.562,1.079,9.697,1.793,15.949,1.482
                c0.932-0.047,2.021-0.084,2.708,0.344c8.248,5.123,17.574,5.116,26.758,5.672c2.167,0.132,4.189,0.768,6.299,1.06
                c2.763,0.382,4.285,1.863,2.852,4.802c7.044,2.287,13.249,5.124,19.662,7c6.837,2.001,11.129,7.119,16.55,10.823
                c1.776,1.214,3.394,2.657,5.413,3.465c8.681,3.473,16.064,8.809,23.311,14.694c3.831,3.113,8.261,6.271,13.726,7.132
                c2.621,0.412,4.046,3.145,5.664,5.288c2.821,3.736,4.753,8.313,8.913,10.72c2.013-1,1.07-2.282,0.866-3.256
                c-0.889-4.241-1.198-8.475-0.826-12.812c0.438-5.096-1.728-9.221-5.868-12.028c-2.884-1.956-4.432-3.775-3.514-7.655
                c0.624-2.634-1.411-5.382-1.82-8.183c-0.304-2.086-1.023-4.14-0.5-7.398c5.404,4.018,12.553,5.55,15.294,11.009
                c4.749,9.462,11.081,17.799,16.451,26.787c0.341,0.569,0.176-0.178,0.199,0.444c0.399,10.941,10.921,15.169,15.534,23.338
                c1.598,2.828,4.411,4.655,6.829,0.158c3.777,2.374,5.601,6.146,7.737,7.72c-2.072-1.592-3.92-5.266-7.174-7.473
                c-4.097,3.075-4.381,3.311-7.67-0.649c-4.335-5.22-8.537-10.533-13.38-15.303c-0.607-0.599-0.992-1.395-1.067-2.221
                c-0.951-10.368-9.389-16.721-13.598-25.339c-0.418-0.855-1.759-1.338-1.881-2.273c-1.296-9.968-11.092-10.461-17.031-16.431
                c-0.939,3.861,0.083,6.532,0.559,9.186c0.543,3.025,2.282,5.744,1.104,9.168c-0.531,1.543,1.08,3.212,2.774,4.307
                c4.985,3.22,7.649,7.587,6.878,13.85c-0.596,4.844,0.437,9.667,1.575,14.534c-3.005,0.799-4.029-1.324-5.056-2.739
                c-1.75-2.411-3.621-4.739-5.278-7.224c-2.01-3.011-4.192-5.043-8.306-6.085c-4.18-1.058-8.532-3.849-11.818-7.05
                c-3.611-3.518-8.402-5.438-11.629-9.346c-0.412-0.5,0.239-0.141-0.373-0.31c-10.107-2.796-18.026-9.091-25.646-15.896
                c-1.131-1.01-2.704-1.264-4.115-1.753c-4.731-1.639-6.268-4.96-5.047-9.824c1.453-5.789,4-11.025,7.4-15.931
                c-2.507-1.833-5.37-1.385-7.488-2.248c2.083,0.72,4.736,0.61,7.382,1.952c-4.004,7.186-8.618,14.206-7.234,23.559
                c-5.802-1.112-10.684-4.032-16.078-5.201c-0.326-0.07-0.782-0.258-0.819-0.502c-1.21-7.931-7.594-5.167-12.147-5.666
                c-6.041-0.661-12.017-1.625-17.953-2.842c-1.854-0.381-3.902-0.801-5.02-2.737c1.999-2.836,5.37,0.666,7.267-1.886
                c0.187-2.082-1.815-2.768-2.774-4.007c-0.752-0.972-2.451-2.027-2.223-2.943c1.373-5.516-2.784-5.801-6.128-6.801
                c-1.89-0.564-2.974-2.028-4.312-3.232c1.632-1.795,3.107-3.464,0.932-6.016c-2.12-2.487-0.388-3.779,1.989-3.858
                c5.449-0.184,10.909,0.03,16.363-0.06c1.414-0.023,3.436-0.079,4.131-0.953c4.787-6.023,10.681-2.957,16.094-1.997
                c7.601,1.347,15.102,3.282,22.615,5.087c4.964,1.192,10.081,2.102,14.76,4.033c5.857,2.416,11.456,4.105,17.861,2.346
                c5.991-1.646,11.926-1.613,18.125,0.394c6.883,2.228,13.985,2.092,20.869-1.438c2.031-1.042,5.343-0.749,7.63,0.105
                c5.098,1.905,9.832,2.678,14.55-0.717c0.516-0.371,1.262-0.736,1.838-0.656c5.407,0.753,10.795,1.641,16.2,2.41
                c3.555,0.507,7.126,1.209,10.694,1.235c4.65,0.035,9.304-0.684,13.957-0.716c1.63-0.011,4.135,0.578,4.75,1.705
                c2.667,4.888,8.63,8.312,6.738,15.396c-0.596,2.229,0.384,4.88,0.624,7.164c-6.446-1.134-12.706-2.234-18.967-3.336
                c-0.104,0.502-0.21,1.003-0.315,1.504c7.205,1.535,14.409,3.071,21.448,4.571c0-5.157,0.104-9.672-0.07-14.176
                c-0.046-1.196-0.785-2.468-1.488-3.516c-2.264-3.376-4.759-6.603-6.897-10.052c-0.321-0.518,0.678-2.717,1.25-2.796
                c10.03-1.377,19.826,0.442,29.652,1.786c0.007,0.42,0.014,0.84,0.021,1.26c-4.192,0.258-8.386,0.516-12.578,0.772
                c7.572,3.053,14.658,1.711,15.882-3.315c6.877,1.41,13.804,2.82,20.725,4.256c2.491,0.517,3.375,1.991,3.353,4.609
                c-0.034,3.851,0.499,7.703,0.703,11.559c0.15,2.822-1.291,3.093-3.795,2.662c-5.227-0.899-10.517-1.448-15.794-2.022
                c-1.164-0.127-2.383,0.235-3.583,1.204c8.192,1.089,16.385,2.179,25.17,3.346c-0.409-6.694-0.802-13.116-1.218-19.92
                c4.263-0.261,10.327,1.361,8.916-7.798c-1.573,7.381-6.141,6.848-10.57,5.255c-9.881-3.551-20.04-4.576-30.429-4.312
                c-1.461,0.037-2.932-0.321-4.265-0.48c-0.296-3.871-0.994-7.477-0.719-11.007c0.265-3.396,1.371-6.993,5.812-7.194
                c3.312-0.15,6.723-0.274,9.954,0.321c11.057,2.038,22.054,4.398,33.27,6.676c-0.224,2.238-0.379,3.798-0.534,5.358
                c0.241,0.129,0.481,0.257,0.723,0.386c0.92-1.492,1.841-2.984,3.444-5.583c8.902,3.557,18.294,7.056,27.402,11.182
                c1.609,0.729,2.034,4.074,3.001,6.201c-11.628-3.585-22.701-6.999-33.773-10.413c-0.173,0.562-0.347,1.124-0.52,1.687
                c6.821,1.964,13.585,4.168,20.479,5.826c7.448,1.791,13.215,5.875,18.256,12.226c-2.111,1.507-4.313,3.079-6.844,4.885
                c0.271,0.581,0.612,1.675,1.232,2.576c2.831,4.118,3.08,8.601,0.384,12.521c-2.328,3.386-1.809,6.022-0.1,9.184
                c4.07,7.532,4.011,7.564-3.125,12.179c-4.188,2.708-10.521,0.896-14.456-2.152c-3.001-2.325-7.585-3.709-11.385-3.556
                c-3.077,0.125-3.831-0.412-4.944-2.878c-1.372-3.037-2.764-7.041-7.311-4.825c2.17,1.883,4.226,3.666,6.282,5.45
                c-7.479,0.167-10.421-3.834-9.365-10.333c0.313-1.929-0.265-4.003-0.434-6.011c-0.547,0.042-1.094,0.084-1.641,0.126
                c0,4.753,0,9.507,0,14.541c-6.832-1.513-13.836-3.062-20.84-4.613c-0.159,0.588-0.317,1.176-0.477,1.763
                c3.072,0.83,6.145,1.66,9.218,2.49c-0.007,0.143-0.014,0.285-0.021,0.429c-1.704-0.218-3.408-0.437-5.113-0.654
                c-0.082,0.42-0.164,0.841-0.247,1.262c2.783,0.574,5.619,0.967,8.336,1.766c3.93,1.156,8.968,0.97,9.496,6.888
                c0.014,0.148,0.134,0.38,0.25,0.413c6.984,2.027,7.446,10.092,12.722,13.786c1.443,1.011,2.104,3.139,3.124,4.754
                c-0.441,0.366-0.883,0.732-1.325,1.098c-4.826-4.001-9.259-8.663-14.584-11.824c-5.782-3.433-12.22-6.083-18.7-7.911
                c-4.929-1.391-8.437-2.758-8.896-8.536c-0.298-3.736-1.024-7.608-2.508-11.005c-0.689-1.578-3.639-2.169-5.722-2.828
                c5.505,4.307,5.305,10.992,6.658,16.573c1.118,4.611-0.224,9.837-0.57,14.783c-0.336,4.794-1.05,9.575-1.134,14.37
                c-0.114,6.559-2.123,8.776-8.517,8.312c-6.536-0.475-13.063-1.071-19.671-0.627c7.272,1.002,14.544,2.004,21.815,3.006
                c-0.439,0.405-0.88,0.812-1.32,1.218c0.872,2.736,1.806,5.454,2.599,8.212c0.803,2.793,3.99,5.361,1.514,8.047
                c-4.257-0.853-8.038-1.609-12.771-2.557c0.761,3.052,1.479,4.62,1.459,6.179c-0.026,2.139-0.048,4.614-1.089,6.326
                c-1.702,2.795-2.056,5.56,0.76,6.885c10.596,4.986,14.823,13.773,15.966,24.717c0.065,0.629,0.457,1.246,0.792,1.812
                c0.144,0.243,0.531,0.343,0.818,0.513c-0.777-5.477-1.049-11.077-2.544-16.33c-0.716-2.516-3.535-4.631-5.782-6.462
                c-3.159-2.575-6.673-4.718-8.868-6.237c1.008-5.377,1.779-9.494,2.731-14.57c1.611-0.808,1.611-0.808,8.089,2.714
                c4.373-3.996,4.611-6.06,0.421-12.387c-2.498-3.771-0.978-5.909,1.512-8.955c2.183-2.669,3.325-6.487,4.028-9.979
                c0.659-3.274,0.142-6.787,0.634-10.236c4.432,6.264,5.78,13.259,5.752,20.628c-1.114,7.072-3.747,14.275,2.025,20.704
                c0.871,0.971,1.742,2.285,1.676,3.554c-0.521,9.966,2.241,19.651,2.132,27.983c-1.866-6.508-0.528-14.923-2.479-23.161
                c-0.952-4.018-0.174-8.157-3.966-11.192c-1.185-0.948-0.747-2.939-0.562-4.295c0.622-4.562-1.24-9.306,1.035-13.72
                c1.011,1.857,2.647,3.632,2.907,5.589c0.66,4.959,0.622,9.968,4.594,13.874c0.739,0.727,0.371,2.487,0.743,3.7
                c0.971,3.164,2.069,6.289,3.105,9.395c6.202-1.938,5.978-6.05,5.654-10.481c3.77,2.046,2.878,4.748,1.796,7.342
                c-1.732,4.149-3.795,7.813,0.478,12.208c1.995,2.054,1.78,6.256,2.566,9.484c0.66-0.213,1.319-0.427,1.979-0.64
                c-1.786-4.183-3.877-8.271-5.212-12.594c-0.553-1.789,0.039-4.317,0.936-6.094c2.217-4.39,1.815-8.059-1.128-12.035
                c-1.951-2.636-2.934-5.988-4.715-8.878c-0.37,6.479,3.917,12.996-0.116,19.704c-1.248-0.769-1.715-0.891-1.768-1.112
                c-2.324-9.77-7.936-18.615-7.876-29.085c0.032-5.633-0.478-11.283-1.031-16.897c-0.167-1.698-1.179-3.366-2.015-4.936
                c-2.053-3.855-4.229-7.646-6.355-11.462c0.452-0.508,0.904-1.016,1.356-1.523c7.722,2.985,15.9,5.16,23.036,9.182
                c7.196,4.056,13.357,9.946,20.373,15.342c0.668-4.336,1.201-7.982-2.754-11.173c-2.16-1.743-3.275-4.741-5.019-7.057
                c-1.066-1.417-2.433-2.608-3.656-3.909c-1.719-1.827-3.43-3.661-5.145-5.491c0.354-0.479,0.708-0.956,1.062-1.435
                c7.613,2.167,15.235,4.309,22.829,6.544c0.715,0.21,1.755,0.97,1.799,1.54c0.378,4.82,3.629,6.771,7.632,8.132
                c0.581,0.197,1.39,0.754,1.467,1.244c1.038,6.519,7.044,8.348,11.07,12.099c4.701,4.379,8.853,9.342,13.403,13.891
                c4.232,4.229,9.565,7.649,12.825,12.494c5.338,7.933,12.998,14.496,14.765,24.8c0.665,3.878,3.049,7.894,5.704,10.923
                c11.342,12.938,16.396,28.246,18.191,45.733c-3.698-0.447-7.303-0.684-10.825-1.353c-6.606-1.255-9.354-0.258-12.99,5.345
                c-1.268,1.951-2.219,4.126-3.616,5.97c-1.185,1.562-2.684,3.818-4.251,4.003c-8.979,1.058-10.786,8.685-13.675,14.873
                c-2.452,5.255-3.526,11.153-5.211,16.767c0.483,0.144,0.967,0.287,1.45,0.431c1.218-3.862,2.147-7.847,3.723-11.558
                c2.132-5.025,4.689-9.878,7.227-14.72c0.573-1.094,1.751-2.535,2.76-2.628c6.352-0.586,8.807-5.31,11.396-10.045
                c4.109-7.512,6.066-7.702,14.527-6.089c12.654,2.412,23.135,10.325,35.046,13.577c11.646,3.179,19.326,10.707,27.616,17.795
                c5.203,4.449,9.723,10.189,13.106,16.168c6.465,11.425,11.904,23.431,17.742,35.208c0.186,0.374,0.074,0.896,0.122,1.696
                c-3.266-1.796-6.106-3.921-9.301-4.964c-3.493-1.141-7.317-1.256-10.99-1.861c-3.415-0.563-6.933-0.828-10.206-1.858
                c-5.706-1.796-6.312-3.033-3.606-8.221c1.844-3.532,0.076-5.095-2.211-7.06c-4.606-3.958-9.072-8.079-13.084-11.673
                c2.424,6.482,9.076,10.358,14.296,15.108c0.075,0.66,0.249,1.05,0.129,1.296c-4.271,8.747-3.729,10.312,5.615,12.873
                c3.589,0.983,7.557,0.539,11.187,1.424c7.699,1.878,16.122,2.682,19.622,11.825c1.716,4.486,4.114,8.707,5.986,13.14
                c2.266,5.363,5.726,9.49,10.431,13.047c3.769,2.849,6.819,6.779,9.746,10.571c2.798,3.625,4.156,7.318,1.131,12.149
                c-3.106,4.96-1.377,9.043,2.452,13.584c3.237,3.84,6.528,8.085,8.183,12.727c5.021,14.089,8.982,28.555,13.936,42.671
                c2.062,5.877,5.619,11.226,8.444,16.841c2.895,5.751,5.743,11.524,8.566,17.311c0.507,1.039,0.563,2.349,1.212,3.262
                c6.976,9.838,10.81,20.892,13.092,32.622c0.288,1.481,1.708,2.811,2.777,4.063c8.16,9.562,14.735,19.948,18.36,32.169
                c4.708,15.867,10.083,31.537,14.693,47.431c1.464,5.044,1.276,10.55,2.067,15.811c1.16,7.713,2.552,15.391,3.787,23.093
                c1.35,8.409-4.19,17.743-11.736,21.624c-9.671,4.974-18.957,2.97-28.444-0.109c-4.388-1.425-8.886-2.507-13.316-3.803
                c-1.591-0.466-3.499-0.701-4.633-1.732c-5.874-5.345-13.116-7.148-20.538-8.889c-1.756-0.411-3.07-2.799-4.534-4.329
                c-0.92-0.961-1.74-2.019-3.027-3.527c-0.154,9.951,7.238,9.931,13.032,12.002c3.031,1.084,5.872,2.701,8.803,4.07
                c3.717,1.736,7.297,3.945,11.189,5.087c5.947,1.744,12.112,2.75,18.038,4.989c-8.293-0.661-16.641-0.944-24.86-2.119
                c-5.136-0.734-10.071-2.845-15.115-4.27c-1.573-0.443-3.202-0.906-4.816-0.951c-19.436-0.544-38.881-0.805-58.305-1.592
                c-8.964-0.362-17.996-1.131-26.798-2.776c-9.425-1.763-19.253-3.45-27.765-7.542c-13.992-6.727-29.068-10.259-43.155-16.505
                c-1.067-0.473-2.484-0.22-3.737-0.199c-4.831,0.079-9.662,0.282-14.491,0.245c-1.087-0.009-2.15-0.849-3.254-1.216
                c-1.965-0.654-5.4-0.868-5.669-1.92c-0.96-3.746-1.625-7.907-0.95-11.65c0.944-5.239,6.445-6.748,10.377-3.101
                c3.335,3.094,6.704,3.048,10.976,2.666c4.105-0.367,8.401,1.409,12.211,2.164c-3.596-3.59-8.439-4.223-13.947-3.479
                c-2.235,0.303-5.197-0.06-6.9-1.348c-7.188-5.436-10.48-5.133-15.951,1.914c1.418-3.035,2.837-6.071,4.277-9.151
                c-5.494,4.113-7.415,10.354-5.293,17.059c2.48,7.834-0.126,10.839-8.145,9.109c-3.074-0.663-6.062-1.808-9.02-2.914
                c-4.362-1.632-8.372-2.195-12.874,0.34c-2.087,1.176-5.426,0.299-8.177,0.09c-4.919-0.374-9.825-0.913-14.73-1.447
                c-5.698-0.621-10.943-1.611-16.328-4.679c-6.253-3.562-7.269-7.669-5.404-14.019c-0.594,0.128-1.188,0.256-1.78,0.383
                c0,4.135,0,8.269,0,13.209c0.011-0.001-1.22,0.42-2.315,0.191c-1.604-0.333-3.139-1.067-4.652-1.744
                c-9.11-4.073-8.981-4.07-7.895-14.109c0.765-7.074,2.057-13.822,6.563-19.684c0.876-1.14,0.526-3.412,0.363-5.125
                c-0.82-8.616,1.994-15.1,8.371-21.502c4.676-4.695,10.843-7.416,14.881-13.281c2.629-3.818,3.546-5.892,1.955-9.898
                c-0.39-0.982,1.386-3.265,2.672-4.35c1.276-1.076,3.191-1.396,4.334-1.852c-0.959-3.471-1.758-6.362-2.557-9.254
                c-0.226-0.062-0.451-0.126-0.677-0.188c0,2.804,0,5.608,0,8.606c-6.582,2.569-6.938,3.64-3.49,12.647
                c-5.236,4.821-10.366,9.934-15.923,14.528c-4.006,3.313-7.324,6.815-9.654,11.601c-2.179,4.472-2.727,8.774-1.857,13.498
                c0.229,1.248,0.457,2.722,0.02,3.826c-1.751,4.417-4.455,8.57-5.511,13.121c-1.429,6.161-1.668,12.599-2.396,18.74
                c-3.307-2.86-3.33-6.562-1.996-11.116c1.415-4.832,1.731-9.979,2.625-14.97c2.485-13.881,4.586-27.85,7.665-41.598
                c2.86-12.773,7.32-25.194,10.06-37.987c1.903-8.89,1.974-18.161,3.063-27.238c1.441-12.013,3.336-23.976,4.527-36.011
                c0.651-6.578,2.265-12.245,6.183-17.893c4.967-7.16,2.088-15.395-0.171-23.057c-0.644-2.184-1.912-4.306-3.324-6.12
                c-12.552-16.135-14.169-34.511-11.814-53.977c1.314-10.874,1.747-21.865,2.227-32.819c0.081-1.86-1.184-4.214-2.56-5.626
                c-4.876-5.001-9.989-9.78-15.157-14.484c-10.991-10.003-21.674-20.205-28.633-33.679c-2.203-4.267-5.212-8.121-7.914-12.123
                c-3.801-5.628-8.686-9.619-15.776-10.174c-0.324-0.025-0.634-0.235-0.96-0.267c-18.056-1.701-23.478-15.604-27.271-29.22
                c-0.451-1.623-1.718-3.078-2.805-4.454c-2.979-3.772-6.415-7.231-9.045-11.226c-8.995-13.665-18.49-26.672-32.604-35.809
                c-6.768-4.382-12.564-10.264-18.916-15.562c4.447-3.858,4.193-9.934,0.423-15.276c-2.254-3.194-4.099-6.679-6.716-10.222
                c0.639,2.381,0.963,4.906,1.981,7.112c2.092,4.532,4.555,8.893,6.913,13.417c-1.863,1.314-3.197,2.256-5.184,3.657
                c3.403,3.654,6.445,7.348,9.937,10.55c3.424,3.141,7.057,6.202,11.032,8.565c12.726,7.566,20.66,19.501,29.099,31.014
                c0.586,0.799,1.04,1.7,1.505,2.58c0.146,0.275,0.126,0.638,0.254,1.365c-6.435-0.969-12.783-1.71-19.035-2.942
                c-4.205-0.828-5.055-3.615-4.069-7.777c0.551-2.329,0.352-5.407-0.774-7.451c-3.028-5.498-6.772-10.6-10.148-15.912
                c-0.81-1.273-1.289-2.756-1.921-4.143c-0.696,0.598-1.394,1.195-2.09,1.793c2.51,4.65,4.771,9.458,7.601,13.905
                c2.688,4.226,5.73,7.808,3.331,13.562c-1.585,3.801,2.421,8.96,6.788,10.214c1.411,0.406,2.857,0.69,4.145,0.996
                c-5.912,1.514-11.836,3.091-17.799,4.5c-1.033,0.244-2.395,0.082-3.334-0.41c-12.083-6.33-25.49-7.18-38.567-9.214
                c-6.913-1.075-13.981-1.155-20.979-1.685c-0.039,0.48-0.079,0.961-0.118,1.441c11.562,1.317,23.124,2.634,34.877,3.973
                c-2.818,7.458-5.362,14.191-7.906,20.925c0.464,0.202,0.927,0.404,1.39,0.606c0.614-1.183,1.32-2.328,1.821-3.558
                c0.751-1.846,1.376-3.743,2.024-5.629c4.229-12.303,10.003-14.162,21.192-6.816c-3.956,3.651-7.642,7.689-10.102,12.381
                c-2.908,5.544-6.933,8.702-13.052,9.245c-1.822,0.161-3.535,0.623-5.21,1.347c2.082-1.003,4.331-1.362,6.554-1.616
                c5.483-0.627,8.829-3.741,11.376-8.453c2.642-4.889,6.525-8.929,10.31-12.984c1.304,0.463,2.608,0.926,3.743,1.329
                c-3.926,5.068-7.229,10.042-11.267,14.321c-3.235,3.427-7.308,6.086-11.127,8.927c-1.305,0.971-2.99,1.417-4.468,2.17
                c-2.735,1.396-4.188,6.36-8.654,2.904c-2.547-1.97-5.172-3.938-8.025-5.387c-3.486-1.771-7.15-3.826-10.915-4.278
                c-6.904-0.83-13.938-0.648-20.921-0.751c-6.636-0.099-13.274-0.021-20.308-0.597c11.953-4.15,12.595-15.901,17.723-24.589
                c1.934-3.276,4.973-6.077,7.987-8.496c4.045-3.246,6.731-9.587,5.052-14.353c-1.346-3.814-4.256-3.301-6.898-1.683
                c-3.101,1.899-5.954,4.202-7.692,5.452c-1.167-2.039-3.043-6.269-5.801-9.813c-1.92-2.466-5.025-2.915-8.061-0.758
                c-4.517,3.209-7.537-1.677-11.245-2.671c-0.593-0.158-0.378-4.428-0.006-6.71c0.255-1.562,1.506-2.939,2.183-4.456
                c2.887-6.474,3.373-12.746-1.417-18.694c-2.195-2.727-2.088-5.269,0.74-7.831c1.307-1.184,1.784-3.282,2.906-5.494
                c8.112,1.46,16.526,2.974,24.807,4.464c-1.499-8.158-2.927-15.924-4.354-23.689c-1.779,7.262,0.324,14.17,2.21,21.343
                c-8.145-1.465-15.931-2.864-23.314-4.191c-1.022-3.783-2.202-7.436-2.884-11.179c-0.165-0.908,1.422-2.059,2-3.2
                c0.566-1.119,0.875-2.369,1.297-3.562c-0.533-0.135-1.066-0.269-1.601-0.403c-0.039,0.714,0.088,1.992-0.141,2.06
                c-6.14,1.803-2.78,5.067-2.068,8.769c0.983,5.117,2.668,10.679-1.369,15.855c-1.201,1.54-1.255,5.515-0.094,7.093
                c5.159,7.009,3.663,13.641,0.249,20.585c-0.927,1.885-1.298,4.077-1.715,6.171c-0.606,3.048,0.457,5.041,3.686,5.971
                c4.179,1.202,7.779,4.415,12.676,0.615c2.574-1.997,8.113,3.102,8.667,7.519c0.205,1.643,0.143,3.321,0.171,4.983
                c0.024,1.445,0.005,2.891,0.005,4.335c0.405,0.231,0.812,0.463,1.217,0.694c1.214-1.497,2.399-3.017,3.646-4.485
                c2.114-2.487,4.094-5.138,6.514-7.287c0.675-0.6,2.699,0.319,4.105,0.542c-0.812,3.696-0.432,9.424-2.649,10.727
                c-8.388,4.926-11.559,12.912-15.135,20.937c-5.456,12.242-12.466,16.111-26.055,15.022c1.393,0.76,2.786,1.521,3.919,2.14
                c-5.832,8.535-11.6,17.363-17.832,25.851c-1.771,2.41-4.856,3.882-7.409,5.683c-3.592,2.533-7.047,5.387-10.958,7.288
                c-2.154,1.047-5.12,0.424-7.72,0.553L397.921,562.029z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(4)}
            fill={props.fillColors[4]}
            d="M1162.617,1313.506c0,46.625,0,93.042,0,140.058
                c-10.718-3.29-21.019-6.434-31.309-9.612c-34.945-10.795-69.983-21.3-104.795-32.51c-23.938-7.709-47.857-15.292-72.798-19.044
                c-11.349-1.707-22.718-3.958-34.232-1.552c-2.543,0.531-5.592,1.289-7.208,3.046c-3.059,3.324-6.134,2.793-9.909,2.18
                c-49.941-8.11-99.976-15.667-149.845-24.197c-51.276-8.771-101.503-21.311-149.662-41.794
                c-21.208-9.021-43.825-14.687-65.632-22.363c-9.529-3.354-18.862-7.482-27.816-12.157c-4.068-2.124-7.522-6.046-10.301-9.85
                c-3.01-4.12-1.823-7.495,3.148-8.677c12.592-2.995,25.275-5.715,38.041-7.83c14.604-2.42,29.479-3.368,43.948-6.351
                c7.056-1.455,14.2-5.097,20-9.476c11.861-8.955,11.196-25.167-0.667-34.18c-13.471-10.234-29.25-15.73-44.951-21.269
                c-13.148-4.638-26.425-8.914-39.569-13.937c9.91,1.363,19.857,2.494,29.722,4.131c38.74,6.428,77.52,12.652,116.16,19.645
                c39.348,7.119,77.616,18.69,115.943,29.87c37.683,10.991,75.148,22.73,112.87,33.583c11.942,3.436,24.346,5.416,36.644,7.414
                c7.816,1.269,15.607,0.612,22.336-4.7c1.213-0.957,3.454-1.134,5.122-0.916c26.074,3.402,52.136,6.907,78.195,10.432
                c41.857,5.661,82.391,16.989,122.811,28.787C1160.115,1312.603,1161.337,1313.07,1162.617,1313.506z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(5)}
            fill={props.fillColors[5]}
            d="M444.433,1184.085c6.406-1.512,12.2-2.878,17.994-4.245
                c0.03-0.717,0.061-1.434,0.091-2.15c-2.578-1.288-5.028-3.048-7.755-3.789c-18.76-5.093-37.401-10.854-56.428-14.67
                c-18.242-3.659-37.009-4.635-55.348-7.9c-16.032-2.854-31.854-7-47.657-11.02c-44.122-11.222-88.887-18.826-134.02-24.475
                c-19.649-2.459-39.237-5.564-58.947-7.313c-8.273-0.734-16.873,0.804-25.166,2.227c-5.263,0.902-9.896,3.759-11.799,9.626
                c-0.374,1.153-2.414,2.273-3.832,2.503c-19.448,3.153-39,4.37-59.072,2.94c0-6.498,0-12.729,0-19.681
                c1.888,0,3.808-0.044,5.726,0.007c15.492,0.41,30.998,1.06,46.208-2.951c3.056-0.806,6.758-0.671,6.459-5.638
                c-0.058-0.963,1.769-2.553,3.025-2.987c4.045-1.397,8.298-3.564,12.351-3.333c16.586,0.944,33.266,1.768,49.657,4.286
                c36.841,5.663,73.575,12.066,110.271,18.627c32.429,5.798,64.762,12.14,97.104,18.408c8.794,1.705,17.342,1.865,26.171-0.17
                c16.457-3.792,33.09-2.178,49.737-0.846c30.234,2.419,59.156,11.042,88.434,18.002c12.772,3.036,25.316,7.332,38.229,9.402
                c16.231,2.602,32.716,3.726,49.124,5.101c33.518,2.81,67.123,4.741,100.564,8.256c19.312,2.03,38.327,6.749,57.561,9.71
                c27.8,4.279,55.623,8.486,83.534,11.93c25.92,3.198,52.136,4.274,77.915,8.264c27.443,4.246,54.863,2.05,82.286,2.857
                c19.712,0.581,39.231,2.44,57.916,9.363c9.239,3.423,18.334,6.94,23.93,16.051c1.663,2.709,4.517,5.054,7.348,6.617
                c16.953,9.366,34.337,17.746,53.738,20.831c10.996,1.749,21.16,5.795,30.805,11.202c1.055,0.592,2.19,2.175,2.205,3.312
                c0.155,12.648,0.099,25.3,0.099,38.636c-6.036-1.609-11.667-2.828-17.115-4.607c-54.88-17.928-111.654-26.347-168.636-33.742
                c-3.798-0.493-7.578-1.203-11.391-1.475c-6.108-0.435-11.77-1.312-17.844,2.828c-7.685,5.238-17.117,2.748-25.814,1.292
                c-28.341-4.745-55.259-14.629-82.662-22.834c-57.683-17.271-115.163-35.482-174.371-46.967
                c-36.417-7.063-73.124-12.644-109.741-18.65c-13.626-2.235-27.361-3.813-41.056-5.627c-2.418-0.319-4.879-0.313-7.319-0.458
                c-0.157,0.455-0.315,0.911-0.473,1.366c2.065,0.956,4.062,2.11,6.205,2.838c18.58,6.312,37.392,12.008,55.707,19.005
                c11.265,4.305,21.908,10.328,32.604,16.007c11.953,6.347,12.787,25.073,0.169,33.431c-10.573,7.003-22.476,8.733-34.535,10.416
                c-18.778,2.619-37.556,5.25-56.297,8.116c-3.565,0.545-7.053,1.987-10.413,3.402c-4.426,1.865-6.102,5.146-3.714,9.219
                c2.454,4.188,5.561,8.856,9.608,11.076c10.002,5.485,20.349,10.85,31.194,14.195c36.602,11.29,72.383,24.771,108.124,38.438
                c26.23,10.03,53.722,15.479,81.185,20.641c31.23,5.869,62.644,10.771,93.992,16.007c30.202,5.043,60.423,9.975,90.639,14.939
                c0.63,0.104,1.291,0.013,2.499,0.013c-2.313-3.721-0.022-5.438,2.816-5.758c7.042-0.795,14.224-1.984,21.208-1.382
                c29.541,2.549,57.675,11.218,85.784,20.2c44.024,14.068,88.288,27.387,132.412,41.145c8.918,2.781,7.11,0.602,7.217,10.074
                c0.13,11.639,0.03,23.281,0.03,35.681c-4.312,0-8.261-0.26-12.166,0.047c-14.544,1.144-28.29-1.768-42.005-6.552
                c-21.36-7.451-43.018-14.057-64.57-20.951c-68.968-22.064-138.808-40.841-209.479-56.671
                c-28.078-6.29-55.801-14.214-83.567-21.823c-11.506-3.154-23.109-6.512-31.032-16.817c-1.478-1.921-4.979-2.544-7.682-3.226
                c-22.737-5.73-45.668-10.762-68.231-17.101c-42.448-11.925-84.869-24.018-126.927-37.229
                c-15.449-4.854-29.998-12.751-44.628-19.932c-5.235-2.57-10.082-6.582-14.068-10.896c-5.715-6.185-4.512-11.627,2.607-16.092
                c10.092-6.329,21.59-7.44,33.042-8.536c21.548-2.061,43.137-3.75,64.641-6.197c5.434-0.618,10.991-2.831,15.8-5.539
                c5.534-3.115,5.643-6.601,0.179-9.685c-10.249-5.784-20.758-11.275-31.62-15.771c-28.134-11.645-56.539-22.631-84.778-34.024
                c-3.674-1.481-7.05-3.737-10.477-5.782C446.695,1186.384,445.707,1185.211,444.433,1184.085z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(6)}
            fill={props.fillColors[6]}
            d="M497.312,146.186c3.354-1.658,6.353-3.473,9.58-4.674
                c8.812-3.278,14.306-1.118,18.64,7.141c2.496,4.758,4.699,9.325-0.378,14.267c-1.933,1.881-2.365,5.385-3.276,8.214
                c-2.055,6.379-3.981,12.798-6.051,19.493c-15.653-3.745-24.421,3.573-29.666,15.397c-8.265-1.458-15.998-3.061-23.806-4.107
                c-4.582-0.614-8.75,0.778-12.349,4.154c-3.743,3.512-8.55,3.356-13.237,2.272c-8.069-1.866-16.078-4-24.166-5.773
                c-2.563-0.562-5.314-0.57-7.956-0.421c-5.954,0.337-11.284-1.191-16.886-3.318c-5.883-2.234-12.526-2.851-18.898-3.281
                c-5.035-0.341-10.162,0.669-15.522,2.022c13.609,7.58,28.86,11.307,43.561,19.268c-10.813,3.338-20.391,3.894-28.992-1.623
                c-7.806-5.006-15.731-6.27-24.709-5.21c-4.498,0.531-9.572,0.192-13.751-1.41c-8.989-3.446-17.787-3.124-26.992-1.611
                c-4.828,0.794-9.915,0.337-14.858,0.021c-8.429-0.54-16.824-1.699-25.257-2.079c-10.736-0.483-20.478-4.068-30.081-8.427
                c-12.879-5.846-25.639-12.348-39.945-13.689c-6.495-0.608-13.382-0.237-19.696,1.306c-6.918,1.69-12.266,0.547-17.471-3.975
                c-8.789-7.633-18.566-13.468-30.188-15.789c-6.633-1.325-13.021-1.614-19.652,0.728c-3.191,1.128-7.336,0.956-10.667,0.018
                c-8.944-2.519-17.796-3.682-27.114-2.512c-5.035,0.632-10.287-0.68-15.394-0.361c-6.787,0.423-13.528,1.581-20.289,2.423
                c0.006,0.624,0.013,1.247,0.02,1.871c1.778,0.215,3.552,0.529,5.337,0.631c11.778,0.665,23.641,0.659,35.3,2.2
                c5.298,0.7,10.179,4.47,15.281,6.774c3.002,1.356,5.959,3.08,9.125,3.751c8.867,1.878,16.781,5.621,23.394,11.688
                c6.474,5.938,13.87,7.743,22.181,6.097c7.498-1.484,14.165,0.076,20.42,4.255c4.245,2.835,8.592,5.518,13.45,8.625
                c-4.474,0.923-8.557,1.973-12.705,2.579c-9.497,1.388-19.003,3.257-28.549,3.567c-6.107,0.198-12.691-1.07-18.354-3.399
                c-8.974-3.691-17.551-4.347-26.713-1.399c-13.286,4.274-27.089,4.13-40.847,4.335c-7.142,0.106-14.275,0.764-21.417,0.903
                c-8.411,0.164-16.827,0.038-25.67,0.038c0-8.454,0-16.593,0-24.73c0-14.997,0.222-29.999-0.125-44.988
                c-0.116-4.996,1.811-6.51,6.277-6.389c5.66,0.152,11.326,0.109,16.985,0.284c3.282,0.102,5.925-0.263,8.157-3.387
                c3.168-4.434,7.998-5.451,12.886-2.696c4.603,2.595,8.604,6.235,13.1,9.046c4.011,2.508,8.688,6.805,12.447,6.229
                c6.594-1.01,13.746-3.955,18.725-8.309c4.695-4.104,8.693-5.413,14.25-4.152c2.491,0.565,5.014,0.992,7.959,1.567
                c-4.259-9.891-13.78-14.161-23.829-10.001c-5.771,2.389-10.819,1.723-16.629,1.229c-6.876-0.585-10.254-3.973-13.954-8.999
                c-9.742-13.233-21.807-23.601-38.984-25.362c-5.499-0.564-11.173,0.581-17.001,0.959c0-2.733,0-6.003,0-10.243
                c4.108,1.595,7.927,2.925,11.617,4.546c6.017,2.644,11.761,5.309,18.263,0.666c1.845-1.318,5.173-1.07,7.728-0.764
                c3.61,0.433,7.152,1.513,10.688,2.451c4.882,1.296,8.769,3.005,13.101-3.006c2.857-3.964,9.874-5.743,15.35-6.753
                c4.5-0.831,9.591,0.46,14.204,1.65c3.979,1.025,5.879,0.954,7.06-3.702c0.607-2.398,3.637-4.697,6.117-6.009
                c7.106-3.759,14.882-4.527,22.832-3.78c3.125,0.294,6.257,0.521,7.822,0.65c3.304-4.51,5.979-7.907,8.354-11.503
                c1.319-1.996,2.586-4.226,3.083-6.522c0.876-4.05,3.085-6.054,7.017-5.06c4.739,1.199,9.323,3.013,14.941,4.883
                c-0.821-1.691-1.218-2.977-1.974-3.996c-7.671-10.339-9.465-21.348-2.655-32.618C173.009,6.6,184.386,3.018,197.369,4.419
                c22.968,2.479,36.146,15.368,39.5,38.16c0.391,2.656,0.053,5.419,0.997,8.514c0.655-1.476,1.418-2.913,1.947-4.433
                c4.277-12.292,21.56-21.88,33.161-17.333c5.127,2.011,9.958,6.096,13.54,10.394c4.42,5.304,7.47,11.768,10.964,17.815
                c1.449,2.507,2.845,3.607,5.94,2.297c6.111-2.588,11.336-0.053,16.263,4.358c1.313-1.889,2.323-3.338,3.33-4.789
                c6.523-9.4,16.212-11.133,25.577-4.637c6.138,4.257,9.727,10.346,12.592,17.019c1.922,4.475,3.997,8.883,5.683,12.611
                c5.332-5.694,9.559-11.819,15.229-15.976c20.503-15.024,53.795-16.988,71.136,17.108c2.772,5.451,4.577,11.405,6.675,17.184
                c0.885,2.437,1.826,3.498,4.907,3.538c2.561,0.033,5.562,1.928,7.532,3.853c3.556,3.471,6.404,7.654,9.706,11.4
                c2.459,2.791,12.136,17.785,13.382,21.146c0.288,0.778,0.615,1.548,0.99,2.287C496.641,145.374,496.992,145.747,497.312,146.186z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(7)}
            fill={props.fillColors[7]}
            d="M677.65,269.798c-7.033-1.831-13.408-3.665-19.867-5.129
                c-8.967-2.032-16.768-1.012-22.049,8.017c-0.927,1.585-5.089,2.429-7.331,1.878c-6.852-1.68-11.859-0.425-15.801,5.862
                c-2.422,3.863-6.815,3.302-10.443,1.733c-5.638-2.439-11.164-5.199-16.528-8.198c-10.81-6.042-21.668-8.358-33.21-1.938
                c-1.175,0.653-3.289,0.436-4.609-0.164c-2.705-1.229-5.372-2.735-7.696-4.578c-14.785-11.725-31.931-16.125-50.382-16.182
                c-9.648-0.03-19.382-0.074-28.925,1.111c-9.335,1.16-18.477,3.874-28.238,6.024c-2.939-3.627-2.097-8.212-0.757-12.661
                c6.497-21.576,27.742-32.813,50.396-26.77c2.065,0.551,4.123,1.13,6.629,1.818c0-1.481,0.2-2.603-0.029-3.628
                c-3.37-15.02,8.567-27.241,23.743-24.122c2.922,0.601,4.299,0.155,5.584-2.887c2.773-6.563,5.514-12.956,6.688-20.155
                c1.315-8.066,6.354-11.619,14.517-12.82c1.696-0.25,3.583-1.53,4.72-2.89c8.544-10.222,16.771-11.899,29.193-7.054
                c3.422,1.335,8.091,0.435,11.938-0.485c7.409-1.771,14.507-5.086,21.973-6.336c16.808-2.812,29.843,5.741,42.172,15.628
                c1.997,1.602,3.859,3.372,5.641,4.939c-7.388-2.114-14.554-4.68-21.938-6.163c-12.523-2.517-20.674,3.678-22.283,15.216
                c-7.424-2.409-14.84-5.832-22.611-7.13c-17.432-2.909-36.043,10.743-34.691,27.667c0.131,1.632-0.254,3.32-0.512,4.965
                c-0.957,6.1-1.482,6.454-7.493,5.733c-5.27-0.633-9.788,0.765-13.651,4.633c-1.199,1.2-3.273,1.645-5.03,2.148
                c-4.437,1.273-9.205,1.775-13.322,3.696c-6.99,3.26-8.47,8.132-5.62,15.26c0.919,2.299,1.955,4.551,2.665,6.196
                c-6.744-1.023-13.649-2.644-20.614-2.965c-6.847-0.315-13.15,2.171-17.733,7.67c-2.419,2.902-3.737,6.17-1.117,9.942
                c-0.154-5.064,1.957-9.141,6.23-11.183c4.111-1.964,8.857-4.053,13.18-3.782c6.458,0.404,12.808,2.763,19.163,4.439
                c1.618,0.428,3.085,1.423,4.621,2.157c0.378-0.338,0.756-0.676,1.134-1.014c-1.552-3.547-3.1-7.095-4.655-10.64
                c-3.505-7.984-1.866-11.946,6.311-15.179c1.081-0.428,2.184-0.852,3.314-1.1c5.828-1.281,12.086-0.381,16.771-6.202
                c1.596-1.982,7.253-1.572,10.783-0.876c3.552,0.7,5.148-0.36,5.946-3.283c0.916-3.35,1.882-6.783,2.062-10.221
                c0.774-14.785,10.847-25.013,25.75-25.791c11.328-0.592,16.483,1.354,31.088,12.014c0.204-2.125,0.426-3.767,0.508-5.415
                c0.48-9.637,6.744-15.389,16.313-14.712c9.833,0.694,18.74,4.213,27.224,9.099c1.389,0.8,3.872,1.229,5.116,0.527
                c24.855-14.004,54.753-2.096,62.912,25.227c0.561,1.877,0.947,3.806,1.586,6.403c-17.223-11.669-34.87-19.441-55.062-10.786
                c-12.598,5.4-19.236,16.649-24.366,28.868c9.319-14.091,18.334-28.678,37.715-29.579c17.638-0.82,32.143,6.943,45.537,18.643
                c0.898-1.892,1.684-3.115,2.082-4.454c2.804-9.427,10.232-12.369,18.897-13.882c8.89-1.551,16.158,0.697,21.424,8.292
                c1.328,1.914,2.532,3.916,3.896,5.804c0.652,0.902,1.422,2.135,2.347,2.36c9.133,2.222,18.307,4.278,27.476,6.349
                c4.889,1.103,9.27,2.612,10.667,8.39c0.227,0.934,2.789,1.787,4.3,1.839c5.962,0.203,12.046-0.655,17.88,0.229
                c9.562,1.451,18.438,4.694,22.65,14.787c0.806,1.93,2.189,3.618,3.521,5.764c4.01-3.561,7.475-6.879,11.188-9.892
                c16.084-13.048,35.321-11.803,49.767,3.118c10.384,10.727,14.439,20.231,17.356,40.715c3.874-0.499,7.747-1.371,11.627-1.406
                c5.03-0.045,10.09,0.383,15.089,0.997c2.225,0.273,5.17,0.865,6.34,2.41c3.772,4.98,8.975,5.111,14.29,5.449
                c10.022,0.637,18.916,3.798,25.578,11.728c3.27,3.894,5.365,8.265,5.059,14.358c-3.729-1.087-7.072-1.865-10.271-3.039
                c-4.841-1.775-9.556-3.894-14.37-5.747c-9.748-3.754-19.49-3.56-28.349,2.007c-8.601,5.404-16.602,5.309-25.388,0.653
                c-2.964-1.571-7.326-2.374-10.45-1.466c-20.59,5.986-38.578-0.604-54.401-12.531c-11.939-8.999-24.317-11.361-38.409-9.911
                c-8.605,0.887-17.264,1.377-25.912,1.72c-14.79,0.585-27.758-4.329-39.302-13.455c-4.958-3.919-9.948-7.868-15.293-11.214
                c-10.749-6.729-22.291-9.107-34.907-5.806c-8.676,2.271-17.54,4.555-26.544,1.458c-2.962-1.019-6.115-2.062-8.553-3.918
                c-8.685-6.611-18.438-8.138-28.869-6.794c-10.896,1.403-21.745,3.454-32.787,1.389c-0.367-0.069-0.834,0.389-1.261,0.604
                c4.224,7.208,7.752,8.898,15.505,7.501C658.11,246.812,672.582,254.8,677.65,269.798z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(8)}
            fill={props.fillColors[8]}
            d="M394.465,1202.202c-29.997-6.517-60.167-5.986-90.294-5.089
                c-9.743,0.291-19.58,1.781-29.104,3.924c-10.508,2.363-12.151,6.977-7.552,16.88c1.05,2.263,2.308,4.431,3.317,6.71
                c3.77,8.518,1.611,14.962-7.418,17.754c-7.692,2.378-15.932,3.09-23.978,4.183c-5.428,0.738-10.94,0.857-16.415,1.255
                c-4.329,0.314-6.48,3.022-6.136,6.971c0.416,4.753,1.446,9.494,2.643,14.129c2.547,9.858,0.577,15.02-9.16,18.248
                c-7.333,2.432-15.171,4.053-22.877,4.528c-34.98,2.157-69.919,0.667-104.806-2.331c-25.051-2.152-50.095-4.398-75.164-6.321
                c-4.286-0.329-5.542-1.57-5.524-5.915c0.168-41.316,0.114-82.634,0.074-123.951c-0.003-3.205-0.233-5.617,4.491-5.585
                c47.645,0.324,95.302-0.166,142.932,0.794c26.769,0.54,53.496,3.304,80.234,5.183c22.941,1.611,45.75,4.213,67.975,10.468
                c32.768,9.221,64.49,21.142,94.688,36.955C393.1,1201.36,393.774,1201.797,394.465,1202.202z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(9)}
            fill={props.fillColors[9]}
            d="M605.11,800.885c-2.233-2.855-3.428-4.382-4.862-6.216
                c0.674-1.092,1.413-2.289,2.67-4.323c-12.466-1.99-24.521-3.915-37.089-5.921c3.591-7.159,14.577-3.253,14.86-12.957
                c4.58-0.825,9.275-2.255,13.994-2.335c3.771-0.064,7.583,1.464,11.352,2.387c1.595,0.391,3.093,1.195,4.692,1.546
                c10.481,2.3,20.897,4.146,30.672,9.506c5.472,3,12.912,2.434,19.475,3.41c4.164,0.619,8.346,1.12,12.52,1.674
                c0.229,0.555,0.459,1.11,0.688,1.665c-1.338,0.947-2.78,1.777-3.992,2.864c-3.293,2.953-3.818,6.71-4.418,11.066
                c-1.31,9.524,1.096,16.007,10.474,19.55c1.663,0.628,2.687,3.133,3.833,4.884c0.87,1.328,1.466,2.835,2.185,4.263
                c0.671-0.627,1.342-1.254,2.014-1.881c-2.319-3.317-4.639-6.635-7.115-10.178c1.345-2.158,3.101-4.978,4.856-7.796
                c-0.301-0.181-0.602-0.36-0.902-0.541c-0.621,1.062-1.343,2.08-1.846,3.196c-1.625,3.607-4.062,4.164-7.714,2.852
                c-3.764-1.354-4.718-3.807-4.213-7.344c0.28-1.957,0.864-3.915,0.831-5.864c-0.163-9.853,2.266-12.904,12.022-14.78
                c1.616-0.312,3.211-0.734,5.669-1.304c-13.283-1.884-25.759-3.617-38.219-5.458c-1.948-0.288-4.137-0.615-5.68-1.691
                c-8.765-6.11-19.018-7.428-29.055-9.56c-2.542-0.54-4.881-2.087-7.426-2.549c-4.491-0.815-9.071-1.572-13.61-1.552
                c-3.413,0.015-6.819,1.285-10.419,2.036c-0.398-3.507,1.675-4.581,4.439-4.296c7.752,0.801,15.487,1.785,23.213,2.818
                c11.854,1.585,23.714,3.146,35.528,4.991c3.577,0.559,6.975,2.185,10.521,3.043c8.509,2.061,17.135,3.676,25.573,5.98
                c13.474,3.681,11.537,3.777,10.997,17.25c-0.159,3.985-2.175,7.954,3.373,9.896c0.511,0.179,0.813,2.44,0.439,3.447
                c-8.826,23.692-12.423,48.58-16.883,73.271c-0.021,0.119-0.207,0.21-0.594,0.58c-4.095-1.771-8.204-4.041-12.601-5.346
                c-8.535-2.531-17.315-4.24-25.84-6.804c-10.657-3.205-20.99-7.609-31.769-10.256c-14.185-3.482-28.765-5.329-42.993-8.657
                c-19.423-4.543-38.675-9.82-58.002-14.772c-0.426-0.109-0.884-0.092-1.491,0.505c3.954,1.111,7.907,2.227,11.862,3.333
                c28.029,7.843,56.125,15.241,84.974,19.752c10.376,1.622,20.22,6.471,30.423,9.448c6.312,1.842,12.995,2.458,19.236,4.476
                c8.514,2.753,16.787,6.264,25.09,9.631c0.675,0.274,1.209,2.001,1.084,2.958c-0.658,5.035-1.022,10.2-2.499,15.01
                c-1.693,5.52-2.036,10.321,2.105,14.884c1.887,2.078,3.352,4.538,5.248,7.157c-5.21,0.856-5.978,4.293-5.942,8.727
                c0.024,3.05-1.393,6.086-1.608,9.168c-0.327,4.662-0.782,9.456-0.054,14.013c0.801,5.002,2.894,9.796,4.402,14.688
                c1.193,3.868-0.663,6.784-3.714,8.429c-10.256,5.527-19.503,13-32.475,12.571c-9.889-0.327-19.836,2.143-29.794,2.649
                c-11.23,0.573-21.388-2.878-31.268-8.524c-9.467-5.409-19.784-9.397-29.904-13.584c-23.871-9.874-46.836-21.461-68.88-34.942
                c-4.363-2.669-6.596-5.604-5.526-10.741c0.325-1.564,0.219-3.218,0.315-4.935c15.219,3.464,30.669,5.357,44.92,10.545
                c35.218,12.819,72.066,19.944,107.411,32.161c0.721,0.249,1.505,0.311,2.677-0.523c-1.178-0.55-2.306-1.271-3.539-1.624
                c-28.127-8.056-56.268-16.062-84.397-24.104c-3.034-0.867-6.23-1.493-8.995-2.921c-14.432-7.45-30.476-9.079-45.88-13
                c-3.852-0.98-7.794-1.605-11.143-2.281c-4.426-10.206-8.693-20.045-12.615-29.087c4.879-3.076,10.703-5.835,15.359-9.924
                c5.425-4.764,3.062-11.77,2.724-18.596c1.767,0.551,3.113,0.905,4.413,1.386c10.759,3.979,21.666,7.61,32.218,12.08
                c11.312,4.792,22.302,10.348,33.39,15.66c1.47,0.704,3.118,1.66,3.952,2.967c4.564,7.152,11.891,9.558,19.292,12.4
                c11.467,4.404,22.616,9.632,33.901,14.511c0.824,0.356,1.648,0.714,2.962,0.17c-1.318-0.855-2.548-1.916-3.97-2.532
                c-11.575-5.021-23.113-10.142-34.817-14.847c-6.105-2.454-11.171-5.636-15.938-10.493c-4.565-4.652-11.338-7.27-17.373-10.32
                c-18.129-9.165-37.217-15.962-56.376-22.597c-1.472-0.51-3.042-3.155-3.112-4.867c-0.278-6.771-0.229-13.581,0.201-20.345
                c0.269-4.245,1.431-8.434,2.132-12.317c17.314-0.336,33.894,1.655,50.096,6.481c10.428,3.106,20.93,2.574,31.652,1.477
                c6.298-0.645,12.826,1.47,19.268,1.622c25.239,0.596,47.752,12.559,72.423,15.625c-29.485-7.902-58.972-15.805-88.458-23.707
                c-0.155,0.513-0.311,1.026-0.466,1.539c2.662,0.754,5.325,1.508,7.988,2.263c-0.023,0.407-0.047,0.815-0.07,1.224
                c-13.639-0.65-27.342-0.7-40.897-2.139c-10.864-1.153-21.509-4.254-32.334-5.966c-4.992-0.789-10.182-0.38-15.282-0.408
                c-3.122-0.017-4.431-1.48-4.595-4.56c-0.21-3.938-0.742-7.856-1.253-12.957c26.609,6.986,52.25,13.72,77.892,20.452
                c0.107-0.411,0.215-0.823,0.322-1.235c-25.828-7.026-51.657-14.054-77.895-21.191c1.464-8.903-3.716-15.806-9.391-24.391
                c14.849,3.663,28.364,6.997,41.88,10.331c0.072-0.132,0.145-0.264,0.218-0.396c-1.723-0.657-3.398-1.502-5.175-1.942
                c-12.088-2.994-24.253-5.698-36.256-8.998c-2.68-0.737-4.811-3.468-7.195-5.281c0.254-0.482,0.508-0.966,0.761-1.449
                c16.996,3.414,34.045,6.589,50.977,10.298c25.487,5.584,50.883,11.585,76.349,17.27
                C598.684,801.346,601.287,800.885,605.11,800.885z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(10)}
            fill={props.fillColors[10]}
            d="M951.723,667.59c2.546-1.913,7.631-3.359,7.9-9.123
                c10.135-0.782,20.296-1.566,30.457-2.352c0.016-0.56,0.031-1.12,0.047-1.68c-2.32-0.214-4.641-0.428-6.961-0.646
                c14.905-6.033,45.437-12.18,68.207-6.249c-7.772,3.554-15.619,7.141-23.465,10.729c0.186,0.782,0.371,1.565,0.557,2.349
                c4.305-0.261,8.712-0.022,12.891-0.888c8.266-1.71,16.379-4.141,24.605-6.055c4.522-1.053,9.14-1.708,13.728-2.46
                c23.489-3.852,46.839-8.267,69.18-16.838c4.36-1.673,8.753-3.258,13.702-5.096c0,70.279,0,139.331,0,209.726
                c-15.751-2.549-30.994-4.858-46.179-7.507c-31.288-5.458-62.559-11.022-93.802-16.732c-5.534-1.012-10.963-2.761-16.301-4.58
                c-1.343-0.458-2.451-2.364-3.141-3.849c-2.776-5.985-5.34-12.069-8.005-18.106c-0.267-0.604-0.792-1.098-1.025-1.709
                c-8.755-22.992-24.068-43.19-28.253-68.225c-1.284-7.677-5.605-14.868-8.731-22.198c-0.685-1.606-1.96-3.204-3.386-4.207
                c-4.952-3.487-5.273-7.857-3.581-13.205C951.133,675.637,951.11,672.271,951.723,667.59z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(11)}
            fill={props.fillColors[11]}
            d="M2.375,751.429c0-37.604,0-74.68,0-112.126c92.465-7.064,184.876-4.048,277.212-4.524
                c-0.003-0.491-0.007-0.983-0.011-1.475c-11.594,0-23.189,0-35.187,0c0.201-1.208,0.196-1.993,0.463-2.671
                c4.683-11.9,9.346-23.809,14.159-35.656c0.787-1.938,1.922-4.004,3.486-5.298c3.356-2.777,5.458-1.974,6.496,2.287
                c1.774,7.279,6.204,11.328,13.62,12.721c2.5,0.47,5.147,2.112,6.951,3.972c6.257,6.451,12.232,13.182,18.157,19.943
                c0.882,1.006,0.919,2.754,1.64,5.12c13.62-0.556,28.346-1.156,44.099-1.799c-0.479,3.516-0.095,6.781-1.389,9.118
                c-5.487,9.912-4.213,20.619-4.179,31.151c0.005,1.484,2.112,3.241,3.621,4.374c2.098,1.575,4.549,2.68,6.98,4.061
                c-0.819,3.909-2.958,5.904-6.705,6.492c-2.282,0.358-4.57,0.967-6.728,1.797c-13.848,5.33-17.456,10.731-17.593,25.563
                c-0.05,5.312-0.477,10.619-0.774,16.817c-6.279-2.812-12.188-5.442-18.077-8.113c-0.902-0.409-1.656-1.232-2.582-1.489
                c-10.581-2.941-21.104-6.152-31.807-8.562c-5.998-1.351-13.094,2.525-15.196,8.828c-2.93,8.787-9.73,9.791-17.065,10.592
                c-0.681,0.074-1.494-0.481-2.155-0.892c-4.761-2.957-8.434-8.02-15.104-7.356c-0.765,0.076-2.211-2.334-2.624-3.795
                c-2.53-8.938-10.177-12.856-17.147-17.183c-1.827-1.134-5.27-0.396-7.702,0.353c-3.227,0.994-4.079,3.499-2.57,6.782
                c1.133,2.465,1.873,5.109,3.063,8.442c-3.868,0-6.741,0.508-9.368-0.082c-17.272-3.885-33.801,0.486-50.163,4.812
                c-16.543,4.372-32.674,10.305-49.219,14.668c-7.916,2.087-16.382,2.044-24.58,3.114c-8.854,1.156-17.709,2.337-26.52,3.778
                C26.118,747.118,14.401,749.304,2.375,751.429z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(12)}
            fill={props.fillColors[12]}
            d="M1162.657,871.901c0,102.057,0,204.307,0,307.09c-1.501-0.202-2.897-0.32-4.264-0.588
                c-4.733-0.925-9.419-2.196-14.189-2.8c-10.478-1.324-20.05-4.479-28.036-11.759c-2.057-1.874-4.559-3.708-7.168-4.427
                c-4.952-1.366-5.854-4.659-5.809-9c0.184-17.844-4.867-34.982-11.847-50.682c-6.116-13.758-10.695-27.816-15.653-41.873
                c-2.313-6.562-5.592-12.788-8.501-19.136c-3.938-8.593-8.049-17.108-11.843-25.764c-1.041-2.375-2.27-5.443-1.609-7.655
                c3.137-10.502-2.822-16.61-9.755-22.612c-5.138-4.448-10.01-9.204-15.91-14.666c9.807-2.414,15.651-8.094,18.648-16.326
                c1.181-3.243,1.682-7.084,1.258-10.499c-1.68-13.523-3.766-26.999-5.889-40.463c-0.589-3.733-1.827-7.363-2.937-11.702
                c5.442,0,10.532,0.001,15.622,0c7.439-0.002,14.467-0.523,21.877-3.172c6.184-2.209,14.051,0.212,21.163,0.71
                c5.953,0.417,11.889,1.346,17.84,1.428c4.31,0.06,8.02-1.7,10.116-6.069c0.616-1.286,2.359-2.105,3.705-2.95
                c2.2-1.382,4.498-2.607,6.4-4.506c-4.5,2.44-10.088,2.188-13.562,7.566c-1.562,2.419-6.743,3.361-10.309,3.423
                c-5.259,0.091-10.527-1.362-15.819-1.862c-5.459-0.517-10.95-0.878-16.428-0.892c-1.995-0.005-3.995,1.179-5.99,1.828
                c-2.349,0.765-4.675,2.124-7.044,2.2c-8.653,0.279-17.321,0.212-25.979,0.044c-1.107-0.021-2.756-1.175-3.172-2.202
                c-1.114-2.744-1.688-5.708-2.488-8.581c-1.067-3.833-1.857-7.773-3.273-11.474c-3.522-9.199-7.332-18.288-10.917-27.149
                C1067.865,848.819,1115.123,860.326,1162.657,871.901z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(13)}
            fill={props.fillColors[13]}
            d="M643.864,648.458c6.576,0,13.153,0,20.519,0c-0.825-1.983-1.226-2.945-2.161-5.195
                c10.096-1.323,19.832-2.6,30.233-3.963c0,4.444,0.116,7.562-0.019,10.668c-0.854,19.58,2.077,38.203,13.895,54.522
                c4.231,5.844,5.385,12.488,5.255,19.385c-0.044,2.333-0.824,5.254-2.362,6.853c-5.872,6.105-6.976,13.371-6.384,21.284
                c0.109,1.458,0.016,2.931,0.016,4.818c-1.526-0.449-2.576-0.883-3.667-1.061c-8.306-1.355-16.623-2.634-24.928-3.993
                c-5.396-0.883-10.863-1.528-16.144-2.884c-6.269-1.608-12.055-1.605-18.546-0.007c-6.017,1.481-12.814,0.332-19.193-0.357
                c-6.099-0.659-12.062-2.538-18.16-3.231c-2.644-0.301-5.455,1.136-8.224,1.567c-1.294,0.201-2.74,0.136-3.992-0.233
                c-9.254-2.729-18.409-6.159-28.278-2.828c-0.732,0.248-1.658,0.143-2.446-0.024c-18.166-3.845-36.277-7.978-54.513-11.456
                c-6.647-1.269-13.646-0.595-20.382-1.542c-6.35-0.894-12.526-2.952-18.848-4.135c-7.507-1.405-15.059-2.688-22.648-3.472
                c-2.805-0.29-4.074-1.086-4.9-3.667c-3.589-11.211-7.334-22.372-11.179-34.023c2.172-0.86,4.078-2.123,6.088-2.316
                c5.729-0.551,8.92-3.716,10.773-8.892c2.591-7.231,7.435-12.701,13.028-17.958c4.612-4.335,7.786-10.187,11.709-15.28
                c0.901-1.172,2.14-2.656,3.42-2.883c31.11-5.497,62.509-6.664,94.021-5.959c0.325,0.007,0.648,0.137,2.318,0.512
                c-2.929,1.459-4.949,2.476-6.979,3.475c-2.069,1.019-4.147,2.02-6.666,3.244c3.768,3.644,1.352,5.634-1.481,7.507
                c-2.75,1.817-5.499,3.635-8.684,5.739c3.332,4.762,7.933,5.325,12.516,5.336c12.831,0.029,25.662-0.191,38.493-0.305
                c2.479-0.021,4.96-0.003,7.565,0.779c-4.753,1.144-9.505,2.286-14.979,3.603c1.51,1.682,2.502,2.786,4.145,4.616
                c-16.708,6.42-34.094,8.243-51.242,12.501c2.67,4.706,6.79,6.116,11.007,6.802c11.484,1.866,23.022,3.401,34.538,5.077
                c8.23,1.197,16.443,2.537,24.695,3.551c3.201,0.394,6.49,0.064,10.312,0.064c-2.202-5.382-6.856-7.146-10.885-9.407
                c-4.009-2.25-8.326-3.95-13.864-6.521c6.506-2.363,11.985-4.354,17.793-6.463c-0.711-1.684-1.322-3.133-2.448-5.802
                c5.109-0.835,9.931-2.401,14.707-2.271c20.93,0.57,41.846,1.633,62.983,1.736C674.947,659.085,659.777,653.546,643.864,648.458z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(14)}
            fill={props.fillColors[14]}
            d="M390.105,555.56c0.264,4.991,4.906,4.689,7.819,6.472
                c-0.004-0.002-0.173-0.146-0.177-0.146c3.403,3.067,6.489,3.303,9.837-0.395c1.711-1.89,4.079-3.549,6.474-4.387
                c6.629-2.321,11.29-6.597,15.091-12.404c4.349-6.646,9.177-12.991,14.053-19.268c0.94-1.21,3.084-2.14,4.642-2.097
                c5.792,0.16,11.57,0.78,18.125,1.846c-1.26,0.461-2.521,0.921-3.163,1.155c10.523,12.282,20.387,23.793,30.251,35.305
                c0.298-0.259,0.596-0.519,0.895-0.777c-9.408-11.278-18.815-22.557-28.22-33.831c5.901-4.859,30.529-4.316,37.353,1.077
                c-1.15,4.711-2.383,9.095-3.223,13.553c-0.29,1.534,0.009,3.443,0.703,4.855c4.54,9.234,2.092,18.881,1.612,28.341
                c-0.123,2.428-1.999,5.302-3.924,7.006c-6.67,5.909-12.9,12.042-16.28,20.521c-0.298,0.747-0.711,1.643-1.341,2.034
                c-11.565,7.182-12.553,19.802-15.54,31.141c-1.449,5.497-3.004,10.066-7.528,13.966c-7.567,6.521-14.588,13.722-17.888,23.606
                c-1.283,3.844-4.224,5.056-7.965,6.442c-9.2,3.408-17.698,1.246-26.141-1.773c-12.281-4.393-24.627-2.721-37.068-1.438
                c-3.04,0.313-6.346,0.088-9.257-0.791c-7.329-2.213-8.011-3.737-7.343-11.345c0.74-8.42,0.318-17.033,4.584-24.794
                c0.079-0.143,0.173-0.336,0.138-0.475c-2.311-9.188,3.321-17.432,3.317-26.063c-0.004-8.28-2.804-16.6-4.709-24.824
                c-1.234-5.331-3.284-10.473-4.534-15.802c-0.545-2.32,0.099-4.898,0.038-7.356c-0.472-19.057,1.67-37.868,4.994-56.598
                c0.838-4.722,0.413-9.689,1.449-14.349c1.497-6.737,7.453-8.391,12.504-3.594c4.095,3.89,8.575,6.013,14.297,5.85
                c2.198-0.062,4.425,0.88,6.639,1.364c0.15-0.493,0.301-0.986,0.451-1.479c-2.722-0.732-5.449-2.089-8.166-2.067
                c-5.483,0.043-9.213-2.527-13.108-5.994c-2.155-1.918-5.851-2.105-9.235-3.196c2.465-7.582,4.898-15.854,7.863-23.929
                c2.075-5.651,7.331-4.534,11.821-4.85c1.192,5.943-1.019,11.279-5.79,14.425c-5.598,3.691-5.686,4.539-0.942,9.242
                c0.472,0.469,0.906,1.07,1.484,1.322c4.237,1.844,5.089,4.852,2.804,9.016c0.346,0.395,0.69,0.788,1.036,1.183
                c4.122-2.065,8.244-4.131,12.768-6.397c1.381,4.138,7.326,7.63,3.474,14.262c-0.377,0.649,2.116,3.604,3.797,4.658
                c2.284,1.433,5.07,2.065,7.416,3.96c-2.484,0.213-4.97,0.425-7.455,0.638c-0.317,0.305-0.634,0.609-0.951,0.915
                c5.052,2.885,8.081,6.507,5.428,12.194c-0.154,0.038-0.46,0.187-0.76,0.175c-2.766-0.107-6.385-1.401-8.114-0.125
                c-3.467,2.558-0.375,5.859,0.713,8.914c1.527,4.287,3.188,8.682,3.668,13.15c0.672,6.259-2.625,10.454-8.836,12.117
                c0.809-7.447-2.901-12.402-8.057-14.974c1.225,1.626,4.259,3.386,6.305,6.235c1.805,2.516,2.344,4.982,0.876,7.452
                c-2.261,3.805-1.216,6.32,2.347,8.294c2.114,1.171,3.974,2.822,6.219,3.701c5.042,1.974,8.135,5.646,9.443,9.8
                c-1.157-6.243-7.191-8.619-12.666-11.611c-1.697-0.928-3.285-2.065-4.802-3.26C387.181,559.414,387.426,556.908,390.105,555.56z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(15)}
            fill={props.fillColors[15]}
            d="M361.876,681.093c17.169-3.125,33.737-1.889,50.057,3.093
                c0.158,0.049,0.326,0.104,0.488,0.1c11.385-0.355,14.293,8.201,16.29,16.57c3.622,15.174,8.222,30.152,16.683,43.07
                c8.902,13.592,14.717,29.128,26.417,41.024c5.969,6.069,8.67,13.501,8.561,22.934c-0.153,13.137-0.317,26.107-2.025,39.189
                c-0.968,7.412,0.366,15.262,1.523,22.781c1.435,9.326-3.858,17.523-13.371,18.926c-15.117,2.229-30.201,1.545-45.287-0.635
                c-13.004-1.879-26.054-3.444-39.083-5.145c-12.705-1.657-25.417-3.259-38.109-5.005c-2.782-0.384-5.575-1.052-8.217-1.997
                c-2.472-0.884-3.151-2.293-2.955-5.595c0.434-7.264-2.177-14.403-8.104-18.853c-4.553-3.417-6.139-7.466-5.699-12.366
                c0.757-8.43,2.56-16.769,3.231-25.2c0.655-8.222,1.275-16.6,0.428-24.755c-1.431-13.782,0.735-27.054,5.213-39.736
                c3.131-8.864,2.951-17.437,2.888-26.431c-0.07-10.121-1.303-21.226,9.1-27.878c3.361-2.15,7.594-3.218,11.58-4.051
                C358.953,689.574,360.419,688.454,361.876,681.093z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(16)}
            fill={props.fillColors[16]}
            d="M231.493,421.039c-8.331,2.705-16.846,3.578-25.336,0.895
                c-7.278-2.301-14.491-2.949-22.121-2.388c-10.057,0.741-19.722-0.994-28.943-5.885c-4.021-2.132-9.318-2.629-14.018-2.562
                c-11.482,0.161-22.95,1.153-34.431,1.65c-2.725,0.117-5.477-0.397-8.437-1.707c1.387-0.869,2.773-1.738,4.16-2.607
                c-13.407-4.912-25.981,0.973-39.176,1.828c1.948-3.28-0.384-5.07-3.229-5.398c-5.06-0.585-10.206-0.47-15.317-0.542
                c-1.807-0.025-3.994,0.984-5.363,0.276c-6.163-3.188-11.937-0.966-17.966,0.3c-6.072,1.274-12.309,1.76-18.918,2.651
                c0-8.834,0-16.415,0-25.431c5.073,1.3,9.637,2.469,13.674,3.503c3.656-5.438,6.621-12.616,11.905-16.991
                c6.299-5.216,13.529,0.195,20.294,2.553c1.286-1.657,2.419-3.279,3.717-4.758c3.58-4.078,8.664-5.178,13.076-3.603
                c2.462,0.88,5.392,1.402,7.923,0.974c11.434-1.938,21.518,3.128,25.746,13.931c1.531,3.911,3.968,5.308,7.782,5.744
                c7.585,0.867,13.979-1.026,19.573-6.516c8.408-8.25,11.803-8.242,21.25-0.854c4.56-4.167,8.393-1.013,12.64,2.015
                c1.508-2.098,3.031-4.015,4.337-6.069c4.52-7.104,9.99-12.904,19.32-11.441c10.391,1.63,16.668,8.357,20.183,17.953
                c0.279,0.761,0.438,1.565,0.685,2.339c0.039,0.12,0.252,0.185,0.384,0.274c2.229-4.469-3.107-13.815-10.759-18.845
                c-12.171-7.998-19.779-5.302-33.447,11.109c-4.778-0.146-8.677-0.205-12.566-0.428c-0.884-0.051-1.808-0.551-2.591-1.039
                c-7.951-4.952-12.457-4.772-19.083,1.919c-5.555,5.609-11.769,8.849-19.637,7.19c-2.147-0.452-4.774-2.342-5.699-4.279
                c-5.67-11.87-15.704-17.25-28.915-15.134c-1.697,0.272-3.671,0.108-5.268-0.503c-6.864-2.63-11.993-1.414-17.083,4.037
                c-1.023,1.096-3.034,2.382-4.19,2.075c-17.675-4.695-17.66-4.753-27.608,9.227c-3.924-5.637-2.636-17.702,2.512-23.533
                c4.348-4.925,10.112-5.039,19.259-0.269c0.578-0.967,1.201-1.925,1.744-2.926c8.169-15.067,25.405-22.82,42.346-19.222
                c6.655,1.414,11.658,4.997,14.732,10.908c2.73,5.249,4.894,10.794,7.463,16.558c3.983-2.687,7.608-1.772,11.167,1.251
                c0.775,0.657,2.617,0.127,3.959,0.034c2.745-0.189,5.812-1.333,8.146-0.466c3.044,1.132,4.902,1.094,7.531-0.936
                c5.092-3.93,10.906-3.378,16.646-1.271c1.514,0.556,2.979,1.248,4.846,2.04c0.415-1.651,0.761-2.876,1.029-4.116
                c3.898-18.007,13.046-27.269,31.2-30.413c31.828-5.514,72.221,7.76,80.47,54.459c0.054,0.306,0.296,0.578,0.703,1.335
                c1.543-0.145,3.441-0.824,4.994-0.357c2.775,0.834,5.803,1.838,7.908,3.697c5.335,4.714,5.153,4.92,12.675,5.616
                c9.212,0.854,18.333,7.354,20.472,15.361c-5.692-0.976-10.966-2.229-16.308-2.704c-6.573-0.585-13.215-0.823-19.804-0.581
                c-2.453,0.09-4.861,1.915-7.246,3.042c-0.732,0.347-1.307,1.434-1.974,1.452c-9.615,0.272-18.883,5.763-28.762,1.96
                c-3.418-1.315-6.794-2.75-10.256-3.938c-6.404-2.196-12.982-3.466-19.577-1.125c-10.687,3.793-21.246,2.938-31.923,0.029
                c-5.446-1.483-11.014-2.53-16.548-3.673c-1.167-0.241-2.427-0.035-4,1.037c0.951,0.707,1.894,1.428,2.855,2.12
                c3.049,2.195,5.919,4.728,9.207,6.477c3.723,1.98,7.704,4.062,11.783,4.654c6.719,0.976,13.685,0.256,20.406,1.225
                c8.756,1.262,17.375,3.476,26.054,5.279C231.663,420.019,231.578,420.529,231.493,421.039z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(17)}
            fill={props.fillColors[17]}
            d="M209.286,820.732c2.373-6.062,4.217-10.858,6.137-15.624
                c0.492-1.222,0.923-2.827,1.896-3.435c4.935-3.084,5.535-7.88,5.711-12.979c0.032-0.928,0.25-2.136,0.857-2.708
                c3.381-3.188,6.909-6.219,11.08-9.917c-0.986-2.023-2.784-4.829-3.704-7.897c-0.637-2.124-0.938-5.165,0.122-6.839
                c4.634-7.317,4.782-12.972-0.332-19.729c-0.9-1.19-1.808-2.375-2.713-3.562c0.488-0.119,0.975-0.238,1.463-0.356
                c-0.642-3.115-1.283-6.231-2.135-10.371c8.724,6.166,17.568,11.439,25.144,18.147c4.37,3.869,6.947,9.914,9.836,15.248
                c1.809,3.339,8.166,7.855,11.443,6.138c3.041-1.594,6.417-4.103,5.981-8.665c-0.662-6.921,0.606-13.007,6.831-17.336
                c0.99-0.688,0.778-2.949,1.391-4.367c1.568-3.628,2.36-8.582,5.166-10.45c4.123-2.747,9.151-4.082,14.496,0.537
                c4.018,3.472,10.028,4.863,15.359,6.505c3.386,1.043,4.356,2.651,3.375,5.716c-1.96,6.119-4.032,12.201-6.174,18.649
                c-3.158-0.716-6.226-1.268-9.207-2.119c-4.706-1.346-5.519-3.949-1.913-7.501c2.75-2.707,3.527-4.77,0.821-7.948
                c-0.774-0.909-0.119-3.035-0.119-4.785c-3.796,0.795-3.653,3.265-1.803,5.688c1.831,2.399,1.541,4.102-0.507,5.968
                c-0.602,0.547-1.475,1.379-1.406,1.989c0.703,6.235-5.592,9.593-6.206,16.587c5.336,1.239,10.932,2.539,16.527,3.84
                c0.141-0.52,0.281-1.038,0.422-1.557c-4.678-1.131-9.354-2.261-14.32-3.46c0.418-1.604,0.458-3.306,1.262-4.489
                c1.292-1.899,2.253-4.077,5.811-3.027c9.692,2.862,9.492,2.604,9.217,12.528c-0.295,10.652,0.215,21.325-0.044,31.979
                c-0.18,7.419-1.062,14.82-1.632,22.256c-3.38-0.203-6.168-0.428-8.961-0.525c-6.557-0.23-8.184,1.029-9.942,7.256
                c-0.536,1.898-0.996,3.998-2.144,5.509c-2.525,3.319-4.946,5.872-10.288,6.013c-5.615,0.147-11.155,3.526-16.705,5.557
                c-2.443,0.895-4.773,2.129-7.253,2.89c-4.118,1.262-8.471,1.88-12.439,3.478c-5.012,2.017-5.616,4.596-3.329,9.508
                c0.956,2.054,1.622,4.242,2.487,6.551c-6.322,2.73-12.308,5.186-18.17,7.907c-2.687,1.248-5.984,2.431-7.524,4.663
                c-4.487,6.505-10.792,10.566-17.241,14.558c-1.154,0.714-2.712,1.312-4.005,1.181c-15.717-1.593-31.523-2.694-47.094-5.227
                c-13.76-2.237-27.226-6.245-40.879-9.205c-6.119-1.326-12.378-2.003-18.987-3.039c2.423-7.504,8.674-9.804,14.061-13.17
                c5.269-3.294,10.16-7.189,15.42-10.498c3.693-2.322,7.546-4.67,11.656-5.975c4.365-1.386,9.111-1.586,13.702-2.227
                c0.926-0.129,2.16-0.267,2.815,0.205c11.689,8.414,25.895,10.203,38.984,14.908c4.886,1.757,9.828,3.358,14.744,5.031
                c0.183-0.526,0.365-1.053,0.548-1.579c-8.821-3.112-17.552-6.521-26.489-9.254c-9.076-2.775-18.212-5.214-25.917-11.202
                c6.83-6.79,18.253-6.698,22.851-16.696c3.359,2.772,6.386,5.271,9.412,7.768c0.286-0.252,0.571-0.505,0.857-0.758
                c-1.542-1.677-2.961-3.491-4.651-5.003c-5.342-4.779-5.382-4.735-1.49-11.31c5.339,1.215,10.643,2.421,16.565,3.769
                c-1.196,3.168-2.318,6.143-3.943,10.447c5.651,1.634,11.59,3.35,17.529,5.066c0.185-0.581,0.369-1.162,0.554-1.743
                c-5.034-1.453-10.068-2.906-15.103-4.359c3.258-9.737,5.939-11.864,15.69-10.535c10.02,1.366,19.923,3.59,29.875,5.45
                c14.847,2.776,29.691,5.562,43.494,8.149c0.438,0.304-0.23-0.437-1.048-0.685c-9.217-2.802-18.423-7.661-27.697-7.806
                C240.955,828.197,226.374,820.007,209.286,820.732z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(18)}
            fill={props.fillColors[18]}
            d="M183.133,2.389c-20.274,7.038-30.065,26.745-19.708,46.316
                c-2.815-0.582-5.302-1.248-7.833-1.586c-4.825-0.645-8.673,1.533-9.249,6.21c-0.503,4.094-2.849,6.436-5.3,9.139
                c-1.956,2.157-3.418,4.763-4.967,6.98c-7.488,0.476-15.19,0.34-22.652,1.611c-7.686,1.309-14.881,4.312-16.657,12.322
                c-7.264-0.507-14.293-1.636-21.251-1.292c-6.258,0.308-11.661,3.512-15.851,8.537c-0.869,1.043-3,1.743-4.372,1.519
                c-3.76-0.615-7.406-1.896-11.133-2.751c-5.482-1.257-10.744-1.173-16.027,1.508c-2.051,1.04-5.214,0.953-7.511,0.233
                c-5.539-1.737-10.915-4.043-16.238-6.387c-1.034-0.455-2.179-2.125-2.182-3.237C2.126,55.521,2.197,29.528,2.261,3.536
                c0-0.288,0.261-0.575,0.539-1.147C62.437,2.389,122.198,2.389,183.133,2.389z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(19)}
            fill={props.fillColors[19]}
            d="M181.591,796.103c0.877,9.534,0.53,18.416-3.885,26.773
                c-0.831,1.573-1.153,3.406-1.848,5.062c-0.563,1.342-0.995,3.154-2.063,3.803c-10.257,6.215-19.886,14.149-32.185,15.702
                c-7.767,0.979-14.06,3.315-20.064,8.589c-5.485,4.817-12.739,7.558-18.792,11.812c-4.181,2.938-7.647,6.895-12.666,11.537
                c-8.505-1.151-18.155-4.245-24.857-13.51c-1.928-2.666-4.536-4.831-6.671-7.36c-0.706-0.835-0.91-2.095-1.343-3.161
                c1.274-0.143,2.733-0.763,3.792-0.352c6.661,2.586,13.229,5.41,19.838,8.128c0.689,0.283,1.453,0.386,2.621-0.508
                c-2.869-1.297-5.731-2.609-8.609-3.889c-3.495-1.553-6.988-3.11-10.507-4.607c-7.196-3.062-7.41-3.594-4.274-11.686
                c3.716,1.166,7.471,2.344,11.227,3.522c0.155-0.496,0.31-0.991,0.465-1.486c-2.544-0.885-5.05-1.912-7.643-2.618
                c-3.422-0.933-6.279-2.052-3.582-7.093c-0.696,0.108-1.393,0.217-2.089,0.326c-0.945,6.914-1.89,13.828-2.688,19.666
                c-18.011-6.341-35.188-12.389-53.257-18.75c0-6.817,0-15.324,0-24.526c2.354,6.459,6.894,8.646,12.765,7.491
                c9.508-1.871,18.002,1.878,26.801,4.147c3.637,0.938,7.358,1.55,11.189,1.561c-12.729-2.165-24.379-9.253-37.952-7.788
                c-7.259,0.783-11.741-2.905-12.971-9.798c-0.203-1.139,1.12-3.166,2.282-3.828c6.483-3.691,13.134-7.09,19.729-10.585
                c0.293-0.155,0.601-0.361,0.915-0.389c8.705-0.752,17.411-1.485,27.033-2.298c-3.778,6.267-6.89,11.427-10.355,17.176
                c9.921,4.583,20.882,3.051,31.493,4.081c0.065-0.457,0.131-0.913,0.197-1.37c-9.541-0.932-19.083-1.863-29.531-2.883
                c4.009-5.899,7.436-11.221,11.214-16.28c0.829-1.109,2.932-1.852,4.409-1.792c7.312,0.292,14.604,1.148,21.916,1.327
                c4.711,0.114,9.613,0.077,14.133-1.064c13.715-3.463,27.77-4.929,40.402,1.562c13.007,6.683,26.314,5.522,39.794,5.776
                C177.749,796.557,179.532,796.266,181.591,796.103z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(20)}
            fill={props.fillColors[20]}
            d="M196.529,722.867c-2.393,3.175-4.273,5.998-6.492,8.524
                c-2.356,2.681-3.552,5.308-2.528,9.043c1.136,4.143,1.668,8.46,2.321,12.723c0.809,5.277-0.512,9.85-3.143,14.692
                c-2.508,4.617-3.205,10.293-4.276,15.588c-0.647,3.202-0.489,6.567-0.739,10.593c-3.68,0-7.125,0.024-10.571-0.005
                c-12.085-0.101-23.716-0.684-35.324-6.023c-11.337-5.215-23.988-7.605-36.904-7.33c-9.634,0.205-19.287-0.556-28.934-0.819
                c-4.535-0.125-8.24,1.117-11.213,5.124c-1.958,2.64-4.957,4.51-9.092,3.133c-2.141-0.713-4.819,0.192-7.255,0.353
                c-1.794,0.118-3.684,0.625-5.371,0.246c-7.468-1.679-14.054,0.601-19.575,5.14c-4.606,3.786-9.352,4.362-15.301,3.511
                c0-5.005-0.241-9.975,0.172-14.889c0.101-1.203,2.167-2.619,3.627-3.271c13.655-6.091,27.472-11.829,41.025-18.134
                c4.922-2.29,9.261-5.833,13.865-8.804c-0.266-0.553-0.532-1.106-0.797-1.659c-5.646,0.857-11.291,1.719-16.937,2.571
                c-0.987,0.149-2.053,0.575-2.958,0.356c-12.181-2.946-21.315,4.191-31.06,9.294c-2.042,1.069-4.028,2.248-6.947,3.886
                c0-3.685-0.296-6.766,0.154-9.732c0.167-1.102,1.953-2.526,3.221-2.806c14.936-3.294,29.892-6.506,44.897-9.466
                c3.2-0.631,6.622-0.323,9.93-0.18c15.456,0.669,29.782-4.201,44.353-8.39c19.315-5.553,38.809-10.544,58.383-15.099
                c5.322-1.238,11.222-0.22,16.838,0.101C185.029,721.431,190.132,722.182,196.529,722.867z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(21)}
            fill={props.fillColors[21]}
            d="M230.784,897.351c21.829,2.228,43.658,4.455,65.487,6.683
                c4.263,0.436,8.551,1.386,12.78,1.172c4.521-0.229,7.298-2.82,6.709-7.991c-0.242-2.119,0.288-4.315,0.283-6.476
                c-0.016-6.009-3.065-10.076-8.762-11.856c-0.635-0.199-1.267-0.521-1.913-0.559c-9.369-0.556-15.407-7.31-22.558-13.024
                c8.384,0,16.768,0,25.151,0c0.026-0.604,0.053-1.209,0.079-1.813c-17.41-1.927-34.819-3.854-52.229-5.781
                c0.042-0.567,0.084-1.135,0.126-1.702c6.732-2.876,13.633-2.107,20.659-0.881c6.94,1.211,13.954,1.998,20.935,2.971
                c0.097-0.571,0.195-1.144,0.292-1.716c-8.642-1.425-17.285-2.85-25.927-4.274c4.874-3.854,10.068-7.115,16.29-6.448
                c8.749,0.937,17.468,2.364,26.105,4.065c9.495,1.869,15.584,8.96,16.306,18.336c0.078,1.014-0.49,2.273-1.149,3.111
                c-1.726,2.191-3.667,4.212-5.138,7.085c1.731-0.954,3.463-1.909,5.482-3.022c6.211,6.117,14.69,5.525,22.598,6.726
                c6.248,0.948,12.987,0.303,18.752,2.424c9.167,3.373,18.12,7.506,23.456,17.044c2.543,4.546,7.574,7.674,11.365,11.555
                c3.527,3.611,6.839,7.434,10.368,11.043c2.856,2.923,5.896,5.669,9.854,9.449c-5.626,0-10.474-0.131-15.313,0.021
                c-25.586,0.808-50.114-6.297-75.108-9.922c-20.412-2.961-40.946-5.196-61.236-8.819c-6.362-1.136-12.028-5.905-18.163-8.681
                c-3.816-1.727-7.861-3.012-11.897-4.167c-4.575-1.309-9.255-2.25-13.89-3.352C230.646,898.15,230.715,897.75,230.784,897.351z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(22)}
            fill={props.fillColors[22]}
            d="M513.456,556.362c2-6.013,4.111-11.37,5.52-16.907
                c1.116-4.393,2.965-7.604,7.507-9.009c8.118-2.511,13.743-8.244,17.629-15.391c5.007-9.207,13.867-10.488,22.615-11.935
                c4.209-0.695,8.429-0.142,12.11-3.384c2.345-2.064,10.602,1.029,13.018,3.062c6.964,5.859,7.859,14.6,11.035,22.18
                c4.339,10.359,11.553,16.926,22.672,17.607c11.28,0.691,17.19,7.463,21.844,16.203c-7.579,6.611-15.034,13.393-22.799,19.799
                c-6.228,5.139-18.169,5.384-25.2,1.18c-6.133-3.667-12.54-6.896-18.538-10.764c-12.965-8.359-27.29-10.253-41.696-6.829
                C528.646,564.678,520.92,560.739,513.456,556.362z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(23)}
            fill={props.fillColors[23]}
            d="M26.095,579.962c8.124-3.104,16.249-6.208,24.373-9.312
                c7.262-1.57,14.531-3.111,21.783-4.728c2.073-0.462,4.271-0.807,6.116-1.771c8.85-4.623,17.613-9.411,26.377-14.196
                c8.059-4.399,16.243-5.725,24.774-1.354c1.031,0.528,2.226,0.737,3.253,1.271c12.771,6.636,25.019,6.75,36.941-2.254
                c4.428-3.345,9.393-3.346,14.594-0.726c6.359,3.204,13.031,5.786,20.976,9.252c-5.367,2.169-9.462,3.824-14.072,5.687
                c-0.22-3.231-0.383-5.64-0.669-9.837c-4.544,3.419-8.97,5.498-11.53,8.958c-4.148,5.606-9.444,8.675-15.786,10.59
                c-2.754-9.242-2.08-10.912,6.07-16.659c2.528-1.783,4.769-3.973,7.141-5.976c-0.265-0.499-0.53-0.998-0.794-1.497
                c-3.623,1.525-7.314,2.912-10.846,4.626c-2.23,1.082-4.265,2.592-6.314,4.017c-6.808,4.732-6.861,9.546-0.234,14.109
                c0.792,0.545,1.437,1.301,2.945,2.692c-19.137,3.822-33.351,19.08-53.651,19.194c0.343-4.652,0.634-8.596,0.924-12.538
                c-0.357-0.018-0.715-0.034-1.073-0.052c-0.464,3.968-0.928,7.935-1.251,10.692c-12.01,4.052-23.099,7.792-34.915,11.778
                c1.548-1.845,2.632-3.137,4.181-4.983c-14.214,3.051-27.675,5.939-41.136,8.828c-0.235-0.483-0.471-0.968-0.707-1.452
                c2.501-2.637,5.002-5.272,7.503-7.909c-0.209-0.341-0.418-0.682-0.628-1.021c-3.101,0.744-6.202,1.489-10.256,2.464
                c12.264-9.734,23.799-18.891,35.334-28.046c-0.322-0.393-0.645-0.784-0.967-1.177c-12.787,10.01-25.574,20.019-38.361,30.028
                c0.209,0.474,0.418,0.948,0.626,1.422c3.082-0.589,6.164-1.178,10.196-1.948c-2.618,2.869-4.728,5.182-6.838,7.495
                c0.141,0.455,0.282,0.911,0.423,1.367c13.346-0.958,26.408-3.728,39.728-6.828c-1.531,1.078-2.945,2.4-4.61,3.197
                c-15.28,7.306-29.153,16.801-41.958,27.756c-6.316,5.404-13.227,6.301-21.66,5.513c0-2.106,0-4.201,0-6.295
                c0-11.5,0.093-23.001-0.063-34.498c-0.04-2.911,0.886-4.516,3.54-5.753C12.485,586.866,19.263,583.357,26.095,579.962z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(24)}
            fill={props.fillColors[24]}
            d="M570.87,630.992c-24.444-2.457-48.829,0.328-73.227,1.928
                c-0.089-0.566-0.178-1.133-0.267-1.699c5.743-1.043,11.467-2.223,17.238-3.073c2.949-0.436,6.04-0.701,8.973-0.32
                c12.05,1.563,22.27-3.253,31.886-9.368c4.438-2.823,8.178-2.139,12.7-1.072c9.814,2.314,19.733,4.246,29.684,5.885
                c2.403,0.396,5.666-0.218,7.53-1.663c3.65-2.829,7.205-2.344,11.292-1.935c18.173,1.819,35.678,6.073,52.558,13.08
                c5.13,2.129,10.446,2.992,16.017,0.653c1.969-0.827,4.577-0.132,7.38-0.132c0.078,1.342,0.158,2.707,0.251,4.305
                c-1.131,0.176-1.914,0.319-2.704,0.416c-7.742,0.94-15.536,1.587-23.199,2.966c-2.606,0.47-4.812,2.915-7.336,4.189
                c-1.219,0.615-2.74,0.707-4.144,0.864c-5.696,0.64-11.4,1.212-17.102,1.81c-0.024,0.521-0.05,1.042-0.075,1.563
                c13.683,4.356,27.364,8.713,41.047,13.069c-0.068,0.319-0.138,0.64-0.206,0.959c-9.304,0-18.608,0.071-27.912-0.019
                c-10.787-0.104-21.573-0.44-32.359-0.44c-1.88,0-3.854,0.976-5.617,1.842c-2.719,1.335-4.631,3.143-1.52,5.845
                c-5.986,2.625-11.729,5.143-17.471,7.661c0.123,0.541,0.245,1.081,0.367,1.622c7.553,3.771,15.104,7.542,22.656,11.312
                c-0.218,0.675-0.436,1.35-0.653,2.024c-23.976-3.748-48.299-5.279-71.981-10.904c0.028-0.673,0.057-1.346,0.085-2.02
                c6.501-1.405,13.004-2.799,19.501-4.219c6.172-1.348,12.372-2.585,18.49-4.144c2.867-0.73,5.785-1.762,8.261-3.322
                c1.357-0.856,1.841-3.098,2.719-4.715c-0.391-0.36-0.781-0.722-1.172-1.082c6.363-1.504,12.728-3.007,19.091-4.51
                c-0.017-0.533-0.033-1.066-0.05-1.6c-2.117-0.282-4.233-0.807-6.351-0.809c-15.496-0.011-30.994,0.184-46.489,0.073
                c-3.824-0.027-7.644-0.938-12.139-1.532c3.753-5.822,12.985-5.826,11.283-14.618c4.289-2.173,8.926-4.522,13.563-6.871
                C571.269,632.326,571.069,631.659,570.87,630.992z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(25)}
            fill={props.fillColors[25]}
            d="M320.792,570.141c-0.655-0.31-1.616-1.067-1.923-0.86
                c-5.057,3.421-9.984,1.529-14.742-0.472c-5.171-2.174-10.158-4.789-15.845-6.392c1.914,1.191,3.765,2.499,5.75,3.556
                c18.211,9.694,36.466,19.309,54.662,29.029c6.068,3.242,8.692,8.744,7.709,15.5c-0.211,1.45-0.509,2.887-1.019,5.742
                c-10.23-7.514-20.979-13.47-29.002-21.992c-5.219-5.545-10.854-9.291-17.133-12.709c-9.5-5.171-19.181-10.008-28.655-15.223
                c-3.482-1.917-6.594-4.504-10.657-7.329c4.737-1.295,7.994-2.185,11.626-3.177c-0.765-1.879-1.271-3.123-2.029-4.984
                c6.731,1.766,12.768,3.349,19.18,5.03c-0.957,1.607-1.672,2.81-2.806,4.716c7.173,2.053,13.876,3.972,20.581,5.891
                c0.312-0.49,0.622-0.981,0.933-1.473c-2.226-1.377-4.452-2.754-6.679-4.13c0.432-0.506,0.862-1.011,1.294-1.516
                c11.398,3.037,24.065,2.251,33.388,11.739c-5.967-4.149-10.226-10.572-18.767-10.63c-5.299-0.036-10.589-2.511-15.862-3.966
                c-1.503-0.414-2.932-1.094-5.118-0.658c1.916,2.138,3.83,4.275,6.81,7.604c-5.476-1.471-9.514-2.555-13.905-3.734
                c0.96-1.494,1.729-2.689,3.063-4.767c-8.394-2.174-16.252-4.209-25.196-6.525c1.057,2.796,1.661,4.392,2.521,6.664
                c-3.327,0.528-6.482,1.028-11.489,1.823c3.053,3.252,4.859,6.315,7.54,7.838c13.45,7.635,27.103,14.913,40.747,22.202
                c3.097,1.655,5.596,3.381,7.469,6.72c1.774,3.166,5.047,5.705,8.108,7.894c7.7,5.501,15.653,10.649,23.46,15.909
                c-0.786,3.535-1.534,6.901-2.387,10.735c-0.987-0.657-2.053-1.239-2.973-1.998c-5.522-4.56-10.941-9.248-16.534-13.719
                c-5.219-4.173-10.008-8.662-17.577-9.155c-3.886-0.254-7.826-3.462-11.267-5.983c-9.224-6.761-18.191-13.871-27.261-20.842
                c-0.368,0.353-0.736,0.704-1.104,1.057c2.716,3.012,5.361,6.093,8.172,9.013c2.424,2.519,5.046,4.847,7.569,7.271
                c4.958,4.765,9.996,9.078,13.065,15.854c2.557,5.644,8.21,9.889,12.516,14.732c1.752,1.971,3.565,3.887,5.43,5.914
                c-7.133,2.933-9.207,2.178-13.656-3.026c-6.598-7.717-13.517-15.161-20.429-22.601c-0.891-0.959-2.538-1.64-3.867-1.698
                c-7.476-0.327-12.026-3.889-13.824-11.257c-0.484-1.985-2.432-5.384-3.102-5.243c-2.761,0.579-5.652,1.85-7.785,3.688
                c-1.617,1.395-2.313,3.975-3.156,6.13c-3.99,10.215-7.691,20.547-11.887,30.675c-3.102,7.488-4.379,7.817-13.285,5.587
                c11.956-19.991,23.856-39.888,36.262-60.632c-7.407-2.349-14.472-4.589-21.537-6.829c-0.063-0.477-0.126-0.954-0.189-1.431
                c2.212-1.204,4.33-3.189,6.654-3.471c7.579-0.916,12.263-5.514,15.713-11.589c2.299-4.046,4.616-5.123,9.093-3.092
                c4.962,2.25,10.317,3.711,15.606,5.119c1.68,0.447,4.154,0.254,5.488-0.716c10.524-7.649,20.004-5.24,29.625,1.964
                c3.024,2.265,7.222,2.993,10.917,4.327c6.896,2.49,7.234,3.754,7.957,10.953c0.724,7.225,2.67,14.33,4.163,21.47
                c0.368,1.761,1.056,3.455,1.901,6.151c-14.676-7.519-28.555-14.629-41.905-21.469c3.029-1.035,6.35-2.17,9.67-3.305
                c5.935,2.42,11.611,5.453,16.332,6.935C332.422,575.641,326.86,572.278,320.792,570.141z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(26)}
            fill={props.fillColors[26]}
            d="M28.994,633.616c1.343-0.782,2.953-1.308,3.99-2.385
                c17.734-18.42,39.905-28.994,63.944-36.249c2.36-0.713,4.984-1.437,7.344-1.116c11.267,1.531,20.725-3.528,30.446-7.782
                c8.8-3.852,17.537-7.871,26.464-11.405c12.96-5.131,26.007-10.059,39.128-14.761c10.667-3.823,21.375-6.995,33.051-3.413
                c2.808,0.861,6.716-1.171,9.912-2.405c8.173-3.157,16.23-6.614,24.334-9.951c0.382,0.583,0.765,1.166,1.148,1.749
                c-3.313,2.588-6.622,5.182-9.949,7.751c-0.246,0.19-0.672,0.135-0.941,0.308c-14.305,9.22-31.484,8.513-46.982,13.609
                c-7.422,2.44-14.876,4.785-22.309,7.195c-1.995,0.646-3.964,1.372-6.419,2.896c10.463-0.585,10.111,8.25,13.198,13.799
                c6.946,12.485,13.585,25.143,20.316,37.748c0.527,0.987,0.793,2.115,1.228,3.307c-3.851,1.584-5.749,0.239-7.68-3.081
                c-6.083-10.453-12.586-20.663-18.965-30.943c-0.865-1.395-1.949-2.653-3.725-5.043c-1.069,3.542-1.617,5.357-2.249,7.453
                c-5.121-1.437-6.675,1.661-7.163,5.977c-0.763,6.742-1.771,13.221-9.376,16.15c-1.414,0.545-2.644,2.671-3.128,4.312
                c-1.312,4.445-4.011,5.604-8.497,5.542c-16.657-0.23-33.32-0.037-49.981-0.013c-0.774,0.001-1.548-0.091-2.668-1.046
                c3.769-2.248,7.442-4.679,11.326-6.708c9.135-4.773,18.571-9.008,27.47-14.176c3.858-2.241,6.645-6.297,10.012-9.427
                c1.411-1.312,2.912-2.723,4.64-3.461c5.039-2.154,10.243-3.917,15.319-5.989c2.139-0.874,4.215-1.991,6.146-3.262
                c3.165-2.082,4.342-4.432,1.925-8.276c-2.27-3.61-4.385-4.34-8.039-2.229c-3.213,1.855-6.128,3.377-7.018,7.673
                c-1.202,5.806-6.302,3.03-10.129,4.171c0.354-1.506,0.56-2.382,0.789-3.357c-0.7,0-1.255-0.162-1.642,0.024
                c-13.026,6.286-25.989,12.706-39.083,18.849c-3.105,1.457-6.595,2.612-9.982,2.85c-8.29,0.58-15.448,3.867-22.198,8.25
                c-8.089,5.254-16.02,10.752-24.018,16.146c-0.824,0.556-1.633,1.584-2.467,1.603c-8.646,0.197-17.294,0.255-25.943,0.256
                c-0.481,0-0.962-0.835-1.443-1.28L28.994,633.616z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(27)}
            fill={props.fillColors[27]}
            d="M658.887,763.923c1.6,0.999,3.092,2.271,4.817,2.952
                c10.612,4.192,21.303,8.19,31.895,12.437c1.273,0.511,3.041,2.167,2.993,3.221c-0.379,8.275-1.145,16.533-1.805,24.974
                c-3.453-0.204-4.459-1.423-3.964-4.777c0.624-4.232,0.338-8.594,0.536-12.895c0.174-3.769-1.843-6.112-5.133-7.049
                c-7.125-2.028-14.342-3.734-21.534-5.518c-1.446-0.359-3.074-0.19-4.413-0.754c-13.86-5.832-28.702-6.59-43.313-8.612
                c-8.881-1.229-17.7-2.892-26.567-4.224c-2.119-0.318-4.323-0.194-6.481-0.104c-5.031,0.211-8.519,3.49-7.628,8.289
                c0.592,3.188-0.953,3.865-2.893,4.691c-2.439,1.041-5.165,1.51-7.439,2.802c-1.418,0.805-2.229,2.675-3.313,4.067
                c1.258,0.917,2.407,2.388,3.792,2.66c7.33,1.443,14.716,2.607,22.091,3.818c2.78,0.456,5.583,0.776,7.634,1.057
                c0.883,3.093,1.631,5.717,2.476,8.677c-0.313,0.02-1.218,0.271-2.032,0.099c-14.14-2.975-28.265-6.021-42.403-9.003
                c-3.526-0.744-5.805-2.433-7.081-6.099c-0.986-2.833-3.221-5.232-5.341-8.504c0.78-1.449,1.979-3.57,3.079-5.741
                c1.857-3.667,1.373-6.312-3.079-7.562c-8.875-2.492-17.441-6.506-27.129-2.428c-1.617,0.681-5.814-2.099-7.233-4.301
                c-1.722-2.672-3.215-4.217-6.422-4.435c-10.367-0.702-20.609-4.223-31.106-1.652c-4.787,1.172-9.43,2.934-14.97,4.691
                c-5.164-8.812-10.575-18.047-15.986-27.28c0.265-0.389,0.528-0.777,0.793-1.166c6.98,1.354,14.299,1.809,20.861,4.273
                c9.476,3.559,19.221,4.1,28.956,4.385c13.396,0.394,26.497,1.729,39.302,5.934c4.191,1.377,8.854,1.259,13.158,2.373
                c4.88,1.264,9.983,2.465,14.307,4.904c7.57,4.271,15.861,4.583,24.003,5.989c1.758,0.304,3.736,0.657,5.103,1.674
                c6.731,5.011,14.464,5.04,22.324,5.304c6.268,0.21,12.513,1.218,18.749,2.015c1.081,0.138,2.519,0.714,3.028,1.553
                c3.11,5.11,8.128,4.082,12.865,4.561c5.543,0.56,11.02,1.79,16.524,2.729c0.104-0.544,0.208-1.088,0.312-1.632
                c-6.565-1.157-13.153-2.206-19.687-3.522c-2.811-0.565-5.509-1.687-8.059-3.369c8.759-3.015,17.076-0.13,25.487,0.587
                L658.887,763.923z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(28)}
            fill={props.fillColors[28]}
            d="M565.647,581.77c6.19,2.095,11.992,3.254,16.985,5.926
                c8.686,4.647,17.863,5.159,27.307,5.457c9.269,0.293,18.514,1.265,27.78,1.662c3.62,0.155,7.276-0.709,10.908-0.643
                c8.307,0.152,16.616,0.478,24.905,1.028c1.4,0.093,2.895,1.464,4.019,2.575c4.731,4.679,9.203,9.628,14.047,14.184
                c2.754,2.59,4.325,5.059,3.263,9.042c-0.801,3.005-0.79,6.226-1.13,9.26c-10.35,2.87-20.229,3.236-30.139-1.375
                c-16.229-7.553-33.87-9.186-51.343-11.348c-1.267-0.156-3.016-0.245-3.841,0.456c-7.465,6.349-15.869,3.634-23.877,2.288
                c-9.205-1.547-9.158-1.958-10.33-11.287c-0.27-2.143-0.216-4.326-0.498-6.467C572.348,592.238,572.325,592.241,565.647,581.77z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(29)}
            fill={props.fillColors[29]}
            d="M922.2,622.591c18.773-6.678,37.68-7.229,56.836-6.524
                c9.178,0.338,18.42-1.634,27.636-1.667c12.138-0.044,24.307,0.439,36.411,1.36c9.582,0.729,19.059,2.865,28.641,3.567
                c4.518,0.331,9.183-1.192,13.762-1.983c0.944-0.163,1.775-1.089,2.71-1.188c9.682-1.033,19.396-1.787,29.057-2.979
                c11.959-1.475,22.71,4.109,34.162,5.627c3.608,0.478,6.886,3.732,10.202,5.889c0.644,0.418,1.216,1.776,1.007,2.444
                c-0.215,0.683-1.406,1.442-2.215,1.497c-10.234,0.689-20.472,1.426-30.721,1.809c-10.099,0.377-20.215,0.562-30.32,0.466
                c-9.777-0.093-19.551-0.954-29.325-0.938c-19.264,0.031-38.528,0.371-57.791,0.706c-18.942,0.33-37.882,0.848-56.824,1.213
                c-8.137,0.157-16.277,0.187-24.416,0.188C922.119,632.078,921.629,631.478,922.2,622.591z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(30)}
            fill={props.fillColors[30]}
            d="M1144.35,634.406c-9.227,2.406-18.37,5.213-27.697,7.13
                c-15.299,3.145-30.766,5.469-46.062,8.627c-7.279,1.503-14.263,4.391-21.502,6.153c-4.636,1.129-9.492,1.348-14.249,1.975
                c-0.188-0.565-0.374-1.13-0.562-1.695c7.452-2.845,14.904-5.689,22.356-8.534c0.084-0.404,0.167-0.809,0.251-1.213
                c-5.345-0.964-10.654-2.269-16.041-2.83c-18.489-1.929-36.438,1.231-54.158,6.248c-3.354,0.95-6.798,1.596-10.131,2.608
                c-0.776,0.236-1.27,1.402-2.335,2.663c-6.079,0.495-13.126,1.07-21.457,1.75c2.073,1.844,3.149,2.8,4.683,4.164
                c-2.729,1.975-5.213,3.772-8.318,6.021c-6.197-12.421-20.443-18.405-23.151-34.074c72.826-0.465,145.457-0.928,218.088-1.391
                C1144.159,632.808,1144.255,633.606,1144.35,634.406z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(31)}
            fill={props.fillColors[31]}
            d="M184.44,819.46c9.677-3.995,9.973-12.974,12.814-20.341
                c1.1-2.85,2.557-5.563,3.891-8.32c0.285-0.59,0.991-1.056,1.087-1.646c1.738-10.68,10.311-14.742,18.475-19.228
                c0.851-0.468,1.591-1.156,2.321-1.812c0.148-0.133,0.021-0.574,0.021-1.921c-9.262,6.055-18.586,9.831-22.866,21.632
                c-2.692,7.423-4.843,15.39-8.767,22.383c-1.419,2.529-4.807,3.954-8.261,6.632c0.774-14.107,1.247-26.561,2.281-38.967
                c0.278-3.336,1.748-6.863,3.583-9.717c3.375-5.248,4.353-10.738,3.304-16.682c-0.771-4.369-2.06-8.653-2.691-13.037
                c-0.226-1.569,0.38-3.619,1.325-4.933c3.396-4.72,7.098-9.219,11.044-14.262c3.637,1.601,8.589,3.78,13.542,5.961
                c0.347-0.573,0.695-1.146,1.042-1.718c-9.353-1.595-13.451-9.004-18.898-15.299c0.416,1.061,0.832,2.121,1.247,3.182
                c-0.441,0.408-0.883,0.816-1.325,1.226c-0.217-0.754-0.659-1.524-0.604-2.258c0.125-1.673,0.492-3.328,0.761-4.99
                c2.96,0.542,6.492,0.239,8.773,1.784c7,4.742,13.508,10.203,15.16,19.896c-2.959,0.734-6.658,1.652-10.691,2.653
                c5.072,4.492,9.422-1.272,15.455-0.143c-0.203,2.067-0.816,4.443-0.539,6.709c0.224,1.822,1.267,3.705,2.395,5.229
                c5.472,7.394,5.581,11.29,1.095,19.573c-0.944,1.744-0.842,4.505-0.26,6.52c0.855,2.958,2.576,5.666,3.721,8.05
                c-6.033,4.726-12.344,8.448-12.12,17.139c0.047,1.817-1.122,3.774-2.054,5.49c-2.879,5.295-6.441,10.284-8.758,15.803
                c-2.895,6.896-7.342,9.738-14.547,7.929C191.763,821.066,188.094,820.295,184.44,819.46z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(32)}
            fill={props.fillColors[32]}
            d="M459.747,322.734c0.929-0.63,1.799-1.681,2.796-1.819
                c5.5-0.767,11.055-1.152,16.545-1.97c7.366-1.096,14.777-2.098,22.014-3.799c13.825-3.252,27.659-5.574,41.908-3.62
                c1.474,0.202,3.108,0.507,4.463,0.093c13.544-4.143,27.021,0.145,40.522,0.156c1.817,0.001,3.646-0.12,5.452-0.332
                c5.977-0.699,11.829-1.391,17.287,2.463c1.56,1.102,4.16,0.646,6.232,1.123c3.094,0.713,6.143,1.619,9.757,2.589
                c-4.354,7.153-8.067,13.252-11.781,19.352l0.096,0.102c-3.407-2.413-6.251-3.752-9.595,0.79c-3.34,4.539-8.098,1.231-11.174-0.57
                c-4.511-2.643-8.31-5.271-13.774-2.781c-1.086,0.495-3.285-0.53-4.517-1.458c-3.4-2.561-6.869-2.508-10.86-1.72
                c-5.547,1.095-10.883,1.055-16.089-2.624c-2.481-1.753-6.997-2.225-10.015-1.33c-10.841,3.212-21.656,1.488-32.53,1.215
                c-8.536-0.214-17.09,0.28-25.636,0.459c0.017,0.655,0.032,1.311,0.049,1.966c4.245-0.295,8.486-0.742,12.735-0.841
                c4.32-0.101,8.799-0.521,12.931,0.419c4.216,0.958,8.096,3.398,13.167,5.654c-5.311,2.483-9.116,3.633-13.473,1.134
                c-1.846-1.06-4.335-1.191-6.562-1.357c-6.076-0.451-12.168-0.696-18.312-0.476c8.827,2.737,18.612,0.114,27.062,4.62
                c-0.085,0.532-0.171,1.065-0.256,1.598c-2.689-0.423-5.394-0.767-8.063-1.285c-6.321-1.227-12.765-4.625-18.471,1.77
                c-0.709,0.794-2.812,0.543-4.25,0.455c-8.733-0.534-17.46-1.16-25.329-1.697c7.185-2.865,7.137-10.584,8.193-17.763
                C459.698,323.482,459.524,323.311,459.747,322.734z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(33)}
            fill={props.fillColors[33]}
            d="M1162.902,868.786c-13.531-2.876-26.274-5.282-38.862-8.331
                c-20.01-4.846-39.909-10.149-59.869-15.204c-13.869-3.513-27.795-6.81-41.611-10.515c-2.226-0.597-4.508-2.463-5.904-4.371
                c-3.413-4.664-6.323-9.696-9.482-14.653c0.64-0.229,1.01-0.509,1.229-0.419c14.212,5.805,29.265,7.196,44.311,9.229
                c18.448,2.493,36.66,6.696,55.037,9.786c16.711,2.809,33.491,5.228,50.276,7.562c3.718,0.517,5.132,1.753,4.963,5.63
                C1162.685,854.467,1162.902,861.457,1162.902,868.786z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(34)}
            fill={props.fillColors[34]}
            d="M1086.841,584.879c-2.639,2.523-4.946,4.731-7.255,6.939
                c0.291,0.31,0.581,0.618,0.871,0.928c1.185-0.989,2.539-1.833,3.526-2.99c7.545-8.837,18.107-11.18,28.707-13.434
                c0.939-0.2,2.043-0.154,2.957,0.135c14.877,4.712,29.798,9.298,44.567,14.331c5.625,1.917,1.167,6.947,1.984,10.858
                c-4.97-2.797-9.939-5.595-14.908-8.393c-0.275,0.224-0.55,0.446-0.825,0.669c0.652,1.554,1.306,3.107,2.075,4.94
                c-0.496,0.235-1.018,0.678-1.563,0.712c-17.826,1.108-35.576,1.917-53.327-1.768c-9.223-1.914-18.792-2.637-28.227-2.843
                c-4.941-0.108-9.893,2.296-14.911,3.134c-4.494,0.749-9.087,0.906-14.771,1.429c1.755,1.273,2.562,1.938,3.44,2.485
                c9.93,6.19,20.863,9.921,32.031,13.091c1.358,0.385,2.634,1.062,3.948,1.603c-0.093,0.432-0.187,0.862-0.279,1.293
                c-4.527-0.825-9.177-1.239-13.555-2.56c-9.607-2.896-19.051-6.331-28.608-9.399c-11.794-3.788-23.637-7.427-37.119-11.65
                c10.77-4.219,20.229-8.538,30.087-11.542c5.413-1.65,11.502-1.084,17.033-1.498c-0.452,0.654-1.486,2.148-2.506,3.621
                c3.795,3.909,8.088,4.114,12.565,0.161c6.027-5.321,12.461-4.528,19.254-2.097c1.371,0.491,3.506,0.991,4.397,0.313
                C1080.433,580.311,1083.47,582.15,1086.841,584.879z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(35)}
            fill={props.fillColors[35]}
            d="M533.502,573.002c-0.817,2.282-1.305,4.764-2.538,6.793
                c-1.458,2.398-3.189,4.97-5.462,6.46c-4.219,2.768-5.4,6.9-5.42,11.162c-0.025,5.642-2.055,10.229-4.874,14.872
                c-1.846,3.039-2.81,6.613-4.19,10.003c-2.853-1.519-6.272-3.34-10.507-5.595c3.568-4.103,7.229-8.06,10.604-12.245
                c6.72-8.333,12.146-17.153,13.109-28.354c0.5-5.813-2.992-8.355-6.76-10.285c-1.335-0.684-4.513,2.159-6.806,3.467
                c-0.693,0.396-1.242,1.047-2.847,2.44c1.2-5.326,2.142-9.501,3.127-13.874c14.174,6.562,27.584,12.74,40.962,18.987
                c3.718,1.736,7.852,3.075,10.892,5.659c5.109,4.343,8.711,9.795,8.901,17.086c0.15,5.743,1.13,11.464,1.741,17.201
                c-0.361,0.085-0.754,0.317-0.961,0.205c-8.574-4.604-16.182-2.184-23.443,3.123c-9.625,7.034-20.807,5.922-31.733,5.752
                c-1.122-0.018-3.206-2.163-3.128-3.192c0.228-3.037,0.607-6.479,2.216-8.911c3.521-5.327,5.483-10.887,5.667-17.233
                c0.122-4.224,2.448-6.752,5.801-9.614c3.615-3.086,5.214-8.534,7.691-12.952C534.864,573.638,534.184,573.32,533.502,573.002z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(36)}
            fill={props.fillColors[36]}
            d="M462.065,894.981c3.845,9.166,7.836,18.275,11.426,27.54
                c0.852,2.199,0.594,4.935,0.434,7.396c-0.311,4.778-0.914,9.538-1.464,14.966c-1.853-0.649-3.896-1.095-5.677-2.035
                c-10.443-5.516-21.596-8.459-33.285-9.711c-6.906-0.739-11.563-5.153-15.851-10.152c-3.395-3.958-6.694-8.079-10.58-11.512
                c-4.707-4.158-10.315-7.25-11.557-14.347c-0.307-1.756-2.643-3.318-4.31-4.639c-2.566-2.032-5.36-3.778-9.304-6.509
                c10.979,1.185,20.471,2.234,29.97,3.228c15.365,1.606,30.736,3.158,46.1,4.789c1.413,0.15,2.784,0.7,4.175,1.064
                c-6.164,4.303-12.499,7.178-20.541,6.727c-3.943-0.222-8.285,2.611-11.837,5.137c-2.302,1.637-4.627,3.158-6.702,4.302
                c4.336-1.59,7.688-5.627,12.599-7.349c3.194-1.12,6.091-2.123,9.57-2.143C451.603,901.699,457.191,899.076,462.065,894.981z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(37)}
            fill={props.fillColors[37]}
            d="M2.097,138.833c0-9.496,0-18.776,0-28.057c0-10.586,0.97-11.546,11.688-11.941
                c13.625-0.504,24.54,5.503,33.755,14.736c4.778,4.787,8.44,10.671,12.894,15.811c1.31,1.512,3.5,2.805,5.459,3.116
                c8.988,1.433,17.785,3.509,26.921-1.124c4.552-2.308,10.393-0.704,15.22,4.654c-2.608-0.37-4.138-0.623-5.676-0.801
                c-4.899-0.565-8.44,1.217-12.479,4.426c-5.131,4.078-11.716,6.787-18.097,8.596c-2.445,0.693-6.25-2.432-9.092-4.33
                c-4.828-3.226-9.145-7.258-14.11-10.224c-6.81-4.066-11.993-2.828-17.448,2.768c-1.207,1.238-3.291,2.213-5.003,2.266
                C18.343,138.968,10.546,138.833,2.097,138.833z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(38)}
            fill={props.fillColors[38]}
            d="M1046.931,613.201c-7.733,0-15.468,0.039-23.201-0.01
                c-10.15-0.063-20.479-1.438-30.409-0.007c-14.998,2.161-29.883-0.108-44.771,0.911c-2.759,0.188-5.442,1.334-8.187,1.917
                c-5.828,1.238-11.657,2.496-17.53,3.479c-0.856,0.144-2.408-0.953-2.879-1.867c-5.691-11.037-11.239-22.148-17.227-34.037
                c20.863,6.471,40.773,15.065,60.948,22.904c0.174-0.378,0.349-0.757,0.523-1.135c-5.042-2.3-10.085-4.599-16.421-7.488
                c2.412-0.32,3.521-0.772,4.466-0.545c7.693,1.854,15.43,3.587,23.004,5.855c5.826,1.745,10.871,2.567,15.037-3.38
                c1.087-1.552,4.362-2.942,6.089-2.46c17.038,4.76,33.961,9.93,50.917,14.983C1047.17,612.615,1047.05,612.908,1046.931,613.201z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(39)}
            fill={props.fillColors[39]}
            d="M229.746,632.027c-0.284-9.549,5.517-19.801-7.342-23.906
                c2.172-9.512,2.172-9.512-4.786-13.445c7.124-8.22,14.238-16.43,21.353-24.64c-0.324-0.3-0.647-0.6-0.971-0.898
                c-7.497,8.431-14.993,16.862-22.484,25.288c0.162,0.374,0.211,0.845,0.432,0.946c5.034,2.337,8.727,5.102,5.018,11.45
                c-0.374,0.64,1.379,3.54,2.599,3.915c4.747,1.459,5.311,5.083,5.265,9c-0.035,2.975-0.658,5.938-0.904,8.915
                c-0.228,2.753-0.88,4.71-4.335,4.792c-3.48,0.082-5.102-1.104-5.582-4.642c-0.534-3.937-1.726-7.779-2.477-11.692
                c-0.273-1.421-0.651-3.234-0.034-4.339c2.463-4.411,0.559-7.85-1.725-11.496c-5.041-8.047-4.589-11.389,2.936-17.367
                c7.417-5.894,15.166-11.367,22.764-17.032c2.298-1.713,3.971-1.808,6.964-0.196c4.967,2.672,10.804,3.723,16.266,5.485
                c0.765,0.247,1.476,0.661,2.305,1.04C253.34,592.666,241.789,611.936,229.746,632.027z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(40)}
            fill={props.fillColors[40]}
            d="M617.623,348.21c-1.152-7.309-0.747-13.332,4.251-18.398
                c1.142-1.157,1.009-3.485,2.007-4.887c2.519-3.538,5.104-6.36,10.554-5.264c6.979,1.405,14.257,1.254,21.322,2.324
                c3.707,0.562,7.18,2.521,10.86,3.445c4.185,1.051,8.482,1.645,12.73,2.445c5.615,1.06,6.399,2.869,5.062,8.414
                c-0.62,2.569,0.247,5.511,0.512,8.277c0.341,3.575-1.132,4.018-4.473,3.513c-5.907-0.893-8.52-6.403-13.367-8.634
                c-2.823-1.3-5.537-3.286-8.493-3.76c-6.966-1.115-14.048-1.509-21.083-2.194c-0.052,0.462-0.104,0.923-0.156,1.384
                c5.801,0.676,11.602,1.892,17.403,1.898c8.459,0.01,13.75,4.421,19.081,12.202c-12.232,0-23.403,0-34.575,0
                c0.011,0.551,0.021,1.103,0.032,1.653c7.112,0,14.225,0,21.337,0c0.072,0.504,0.145,1.008,0.217,1.513
                c-4.017,0.186-8.033,0.499-12.051,0.523c-4.617,0.028-9.275,0.131-13.844-0.413c-4.124-0.491-8.148-1.812-12.654-2.869
                c5.865-1.092,11.41-2.124,15.788-2.938c-4.223-3.188-8.69-6.744-13.379-9.979c-1.281-0.885-3.226-0.809-5.862-1.395
                c4.864,3.853,8.727,6.911,13.151,10.416C627.013,346.431,622.463,347.293,617.623,348.21z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(41)}
            fill={props.fillColors[41]}
            d="M305.651,903.057c-12.81-1.542-25.164-3.13-37.544-4.48
                c-7.103-0.773-14.249-1.137-21.371-1.734c-2.596-0.218-5.243,0-5.983-3.773c-0.7-3.568-0.099-6.334,2.937-8.241
                c12.738-8.002,26.357-12.039,41.464-8.639c6.965,1.567,13.909,3.249,20.814,5.063c5.591,1.469,7.72,4.783,7.277,10.709
                c-0.087,1.16-0.373,2.348-0.225,3.478C313.994,902.897,310.241,902.941,305.651,903.057z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(42)}
            fill={props.fillColors[42]}
            d="M60.831,632.958c10.882-6.574,21.737-13.197,32.679-19.672
                c1.674-0.99,3.741-1.787,5.648-1.838c11.082-0.297,20.297-5.707,29.821-10.392c6.7-3.296,13.506-6.386,20.355-9.359
                c1.336-0.579,3.15-0.502,4.641-0.206c7.262,1.443,10.395-0.553,13.876-6.941c1.37-2.514,4.979-3.98,7.839-5.359
                c0.542-0.262,2.988,1.95,3.411,3.363c0.376,1.259-0.394,3.854-1.415,4.372c-5.598,2.847-11.338,5.476-17.209,7.705
                c-4.576,1.737-8.014,4.115-11.366,8.033c-4.047,4.73-10.03,8.014-15.591,11.196c-10.792,6.177-21.868,11.861-32.851,17.702
                c-1.143,0.608-2.437,1.221-3.689,1.289c-11.773,0.642-23.552,1.165-35.33,1.723C61.377,634.036,61.104,633.497,60.831,632.958z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(43)}
            fill={props.fillColors[43]}
            d="M1162.889,1233.787c-4.311-4.129-6.172-7.808-5.951-12.732
                c0.087-1.946-0.886-4.709-2.348-5.818c-5.815-4.415-11.774-8.749-18.124-12.323c-10.333-5.817-18.447-13.216-22.488-24.766
                c-1.792-5.123-4.766-9.833-6.717-15.438c2.874,2.051,5.987,3.839,8.575,6.202c6.914,6.314,15.287,8.187,24.238,9.237
                c6.567,0.771,13.046,2.466,19.477,4.096c1.314,0.333,3.18,2.201,3.195,3.383
                C1162.96,1201.267,1162.889,1216.909,1162.889,1233.787z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(44)}
            fill={props.fillColors[44]}
            d="M444.826,936.584c6.168,2.229,12.348,4.424,18.494,6.709
                c1.55,0.576,2.956,1.525,4.487,2.159c6.781,2.808,7.612,2.273,7.725-5.697c29.176,23.608,64.307,34.585,97.353,49.686
                c-0.039,0.423-0.079,0.847-0.118,1.27c-4.41,0-8.967,0.729-13.208-0.118c-28.544-5.703-55.646-15.6-81.271-29.374
                c-4.932-2.65-9.154-6.604-13.762-9.874c-6.619-4.697-13.292-9.318-19.942-13.973C444.665,937.108,444.746,936.846,444.826,936.584
                z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(45)}
            fill={props.fillColors[45]}
            d="M614.942,336.97c0.069,1.994,0.155,3.987,0.203,5.982
                c0.188,7.886-3.658,11.264-11.063,8.57c-5.596-2.036-10.207-1.378-15.757,0.318c-4.933,1.507-10.983,1.113-16.098-0.186
                c-5.885-1.494-11.384-1.075-17.207-0.66c-4.982,0.354-10.493,0.308-15.012-1.48c-6.917-2.736-13.606-5.187-21.162-4.76
                c-1.897,0.107-3.846-0.661-5.691-1.727c32.379,2.057,64.759,4.114,97.138,6.171c0.041-0.58,0.082-1.16,0.123-1.74
                c-31.834-1.985-63.669-3.971-95.939-5.983c2.325-2.01,4.09-3.536,5.855-5.062c-1.751-1.671-3.502-3.342-5.261-5.02
                c0.012-0.01,0.28-0.376,0.415-0.332c9.23,3,17.863-2.36,26.868-1.864c2.43,0.133,4.737,1.796,7.194,2.231
                c4.959,0.878,10.073,2.292,14.948,1.788c4.177-0.431,7.578-0.79,11.222,1.787c1.632,1.154,4.603,1.635,6.464,0.984
                c3.491-1.22,5.97-0.06,8.874,1.452c3.319,1.729,6.901,3.066,10.51,4.063c1.297,0.358,3.811-0.302,4.383-1.295
                c2.242-3.896,5.592-3.498,9.09-3.14C615.038,337.071,614.942,336.97,614.942,336.97z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(46)}
            fill={props.fillColors[46]}
            d="M1162.903,1264.988c-10.536-4.149-20.654-8.135-31.111-12.254
                c0.509-0.826,1.416-2.312,2.331-3.792c3.47-5.616,4.49-10.918,0.622-17.217c-2.317-3.774-2.193-8.969-3.664-13.358
                c-2.471-7.376-5.339-14.619-8.133-22.168c9.768,6.604,19.483,13.08,29.06,19.758c1.312,0.915,2.844,2.925,2.705,4.265
                c-0.593,5.723,2.245,9.784,5.328,14.059c1.513,2.098,2.624,4.946,2.735,7.507
                C1163.123,1249.741,1162.903,1257.72,1162.903,1264.988z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(47)}
            fill={props.fillColors[47]}
            d="M1052.267,607.172c0.783-0.731,1.376-1.285,2.412-2.253
                c-4.31-1.247-8.194-2.371-12.08-3.496c0.004-0.255,0.008-0.51,0.011-0.765c3.533-0.29,7.337,0.169,10.553-0.996
                c15.854-5.744,31.369-1.192,47.008,0.837c8.73,1.133,17.509,2.585,26.267,2.591c7.989,0.006,15.98-1.745,24.439-2.778
                c0.04-0.633,0.114-1.788,0.189-2.982c12.494,6.108,12.746,6.432,11.175,14.571c-7.353-0.784-14.734-1.584-22.121-2.354
                c-5.258-0.548-10.572-1.606-15.422,1.721c-0.507,0.348-1.335,0.556-1.917,0.417c-10.746-2.548-20.963,2.25-31.521,2.237
                c-1.151-0.001-2.637-0.208-3.399,0.396c-4.773,3.78-9.248,1.505-13.953-0.153C1066.885,611.689,1059.738,609.566,1052.267,607.172
                z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(48)}
            fill={props.fillColors[48]}
            d="M989.289,597.592c-2.766,5.861-7.412,5.928-12.979,4.291
                c-7.601-2.233-15.336-4.01-23.021-5.95c-0.958-0.241-2.221-0.743-2.908-0.356c-5.627,3.17-10.136-0.579-14.982-2.212
                c-11.295-3.807-22.689-7.353-33.802-11.634c-2.58-0.994-4.191-4.504-6.249-6.855c0.251-0.683,0.503-1.364,0.754-2.047
                c5.814-0.227,11.893-1.672,17.397-0.475C939.618,578.035,967.786,577.754,989.289,597.592z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(49)}
            fill={props.fillColors[49]}
            d="M243.892,735.172c3.954-1.224,7.677-1.692,10.591-3.48
                c2.61-1.601,5.057-4.33,6.307-7.128c3.826-8.564,7.371-10.389,16.415-7.614c5.838,1.79,11.75,3.337,18.583,5.263
                c-7.768,2.913-8.604,8.699-9.333,15.03c-0.282,2.443-2.88,4.926-4.961,6.792c-4.388,3.934-3.823,9.076-3.331,13.81
                c0.435,4.187-1.952,6.764-4.593,6.765c-2.982,0.001-7.514-1.872-8.67-4.246c-4.196-8.62-9.002-16.406-17.389-21.509
                c-0.842-0.512-1.568-1.235-2.28-1.93C244.775,736.479,244.444,735.907,243.892,735.172z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(50)}
            fill={props.fillColors[50]}
            d="M499.428,775.701c-7.524-0.909-15.034-2.385-22.578-2.593
                c-10.822-0.297-15.211-6.908-17.854-16.361c7.715-4.437,15.971-6.585,24.978-5.571c5.43,0.611,10.848,1.367,16.289,1.84
                c3.614,0.313,6.862,0.693,7.984,5.03c0.502,1.939,1.672,3.707,2.456,5.376c-2.755,3.018-7.018,7.688-11.28,12.359l0.427-0.009
                L499.428,775.701z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(51)}
            fill={props.fillColors[51]}
            d="M482.096,610.44c2.824-5.045,4.751-9.689,7.728-13.521
                c4.36-5.614,9.585-10.548,14.223-15.959c3.124-3.645,5.724-7.741,8.872-11.362c0.984-1.132,3.833-2.331,4.385-1.85
                c1.936,1.694,4.326,3.974,4.608,6.269c1.412,11.492-5.23,20.096-11.55,28.562c-3.659,4.901-8.047,9.259-12.604,14.425
                C492.625,614.853,487.207,612.583,482.096,610.44z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(52)}
            fill={props.fillColors[52]}
            d="M209.119,632.709c-15.215,0-29.731,0-44.611,0c1.121-4.8,2.975-8.069,7.371-10.248
                c2.306-1.143,4.16-4.215,5.12-6.82c1.521-4.129,2.122-8.598,3.209-13.35c3.631,1.137,6.862,1.488,6.546-4.771
                C197.419,608.11,203.138,620.185,209.119,632.709z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(53)}
            fill={props.fillColors[53]}
            d="M499.428,775.701c0,0-0.003,0.077-0.004,0.078c2.671-2.226,5.551-4.248,7.979-6.717
                c7.98-8.113,17.167-10.928,28.115-7.075c2.191,0.771,4.409,1.528,6.675,2.011c4.172,0.889,4.899,3.336,2.663,6.458
                c-3.193,4.461-3.787,8.056,1.322,11.667c1.428,1.01,1.621,3.768,2.725,6.609c-11.912-2.971-22.989-5.662-34.023-8.517
                C509.687,778.873,504.575,777.216,499.428,775.701z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(54)}
            fill={props.fillColors[54]}
            d="M187.174,577.135c17.373-4.506,33.248-14.037,52.604-12.416
                c-7.108,5.243-13.864,10.226-20.619,15.209c-1.069,0.789-2.162,1.551-3.193,2.388c-8.368,6.792-9.169,10.891-3.449,19.983
                c1.997,3.174,2.487,5.976,1.535,9.768c-0.821,3.27,0.237,7.01,0.517,11.351c-2.183-4.486-3.923-8.399-5.952-12.156
                c-3.392-6.28-7.008-12.438-10.446-18.694c-1.433-2.606-3.329-5.216-3.805-8.029C193.638,580.242,192.126,577.471,187.174,577.135z
                "
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(55)}
            fill={props.fillColors[55]}
            d="M658.959,764.012c3.155-0.479,6.314-1.374,9.465-1.342
                c5.051,0.052,10.097,0.719,15.144,1.125c0.144-0.562,0.286-1.125,0.43-1.688c-1.621-0.696-3.182-1.689-4.874-2.038
                c-8.306-1.71-16.602-3.581-24.992-4.708c-4.351-0.584-8.891,0.307-13.347,0.442c-5.099,0.156-10.22,0.551-15.295,0.237
                c-7.596-0.469-15.158-1.471-22.737-2.227c-0.657-0.065-1.367-0.127-1.988,0.045c-12.296,3.394-23.025-4.518-34.807-4.823
                c-0.623-0.016-1.252-0.416-1.845-0.707c-0.278-0.137-0.477-0.438-0.751-2.04c3.413,0,6.888-0.403,10.226,0.099
                c4.583,0.688,9.083,1.985,13.581,3.167c3.48,0.915,6.937,3.645,10.032-1.027c0.502-0.757,3.135-0.416,4.687-0.105
                c6.355,1.271,12.633,2.958,19.014,4.053c7.702,1.321,15.394,2.284,23.183-0.359c2.768-0.939,6.248-0.721,9.181-0.054
                c8.892,2.022,17.673,4.531,26.506,6.814c0.541,0.141,1.164-0.037,2.768-0.119c-1.872-1.371-2.963-2.17-4.053-2.97
                c0.062-0.277,0.124-0.555,0.186-0.832c3.787,0.685,7.593,1.284,11.356,2.079c3.716,0.786,7.391,1.771,11.354,2.733
                c-0.692,3.498-1.326,6.699-2.208,11.157c-13.434-2.334-26.858-4.667-40.284-7.001
                C658.887,763.923,658.959,764.012,658.959,764.012z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(56)}
            fill={props.fillColors[56]}
            d="M620.723,583.768c9.957-8.349,18.691-15.671,26.849-22.51
                c8.229,10.752,16.055,20.978,23.985,31.34c-0.473,0.074-1.476,0.388-2.473,0.367c-10.009-0.206-19.814-1.033-28.073-7.771
                c-3.939-3.213-8.656-2.877-13.362-2.094C625.789,583.41,623.89,583.473,620.723,583.768z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(57)}
            fill={props.fillColors[57]}
            d="M289.437,588.376c4.797,3.683,9.29,7.888,14.477,10.896
                c5.036,2.92,10.781,4.617,16.22,6.837c1.05,0.429,2.315,0.55,3.164,1.216c9.007,7.069,17.95,14.221,26.91,21.35
                c-0.093,0.454-0.187,0.908-0.28,1.362c-7.818,0.293-15.636,0.619-23.456,0.821c-0.671,0.018-1.492-0.66-2.025-1.222
                c-5.499-5.785-12.506-10.808-16.042-17.625C303.534,602.623,296.084,596.055,289.437,588.376z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(58)}
            fill={props.fillColors[58]}
            d="M953.955,577.807c-9.438-1.684-18.916-3.167-28.301-5.106
                c-9.802-2.025-19.468-4.196-29.539-1.288c-1.533,0.443-3.775-1.566-5.222-2.227c3.783-3.523,6.795-6.901,10.389-9.462
                c2.237-1.595,5.354-1.954,8.416-2.98c-0.318-1.354-0.753-3.199-1.298-5.52c15.516,8.552,30.636,16.886,45.757,25.22
                C954.09,576.898,954.022,577.352,953.955,577.807z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(59)}
            fill={props.fillColors[59]}
            d="M512.204,626.323c-14.893,1.83-27.193,10.655-42.355,9.33
                c3.011-8.604,3.682-17.689,9.295-26.101C488.351,618.963,502.646,616.598,512.204,626.323z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(60)}
            fill={props.fillColors[60]}
            d="M56.149,753.492c-17.255,10.909-35.338,17.353-53.118,24.646
                c-2.083-7.701-1.753-8.56,4.604-12.172c2.745-1.561,5.549-3.017,8.307-4.555c7.307-4.074,14.6-7.366,23.529-6.24
                C44.414,755.794,49.632,754.231,56.149,753.492z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(61)}
            fill={props.fillColors[61]}
            d="M573.501,566.838c12.62,7.333,25.928,15.066,40.14,23.325
                c-18.545,3.082-32.825-6.632-48.091-10.25C567.954,575.959,570.316,572.075,573.501,566.838z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(62)}
            fill={props.fillColors[62]}
            d="M836.508,944.398c-30.879-3.608-61.759-7.217-92.637-10.818
                c4.906-4.054,9.999-3.592,15.717-1.526c4.209,1.521,8.919,1.698,13.429,2.306c0.891,0.12,2.235-0.234,2.775-0.874
                c3.897-4.614,7.938-1.772,12.311-0.9c5.203,1.038,10.675,0.719,16.027,1.029c1.545,0.089,3.144,0.103,4.611,0.523
                c6.821,1.957,13.64,3.938,20.386,6.138c2.72,0.887,5.252,2.352,7.869,3.554C836.833,944.019,836.671,944.209,836.508,944.398z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(63)}
            fill={props.fillColors[63]}
            d="M210.415,898.466c4.538-2.749,8.43-4.206,13.99-0.637
                c5.255,3.374,11.915,5.641,18.139,6.147c7.654,0.623,13.446,3.972,19.163,9.088c-9.031-0.933-18.198-1.189-27.053-2.988
                C225.893,908.296,216.051,907.975,210.415,898.466z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(64)}
            fill={props.fillColors[64]}
            d="M532.392,564.648c10.702-2.251,21.559-3.015,32.382-1.02
                c5.666,1.045,6.812,5.862,3.023,10.377c-2.908,3.467-5.994,5.422-10.947,2.93c-7.978-4.012-16.299-7.342-24.475-10.96
                C532.381,565.533,532.387,565.091,532.392,564.648z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(65)}
            fill={props.fillColors[65]}
            d="M316.452,827.006c0,7.02,0,13.133,0,20.337c-6.647-1.49-13.315-2.985-20.047-4.495
                c2.289-4.815,4.217-9.3,6.573-13.548c0.63-1.136,2.513-2.046,3.912-2.192C310,826.782,313.168,827.006,316.452,827.006z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(66)}
            fill={props.fillColors[66]}
            d="M261.206,873.178c-5.566,2.712-12.455,5.717-18.984,9.364
                c-5.018,2.804-5.395,7.589-3.028,13.587c-6.659-0.987-12.611-1.87-18.775-2.785C226.003,881.636,247.168,871.875,261.206,873.178z
                "
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(67)}
            fill={props.fillColors[67]}
            d="M253.73,864.04c2.107,1.812,4.214,3.625,6.64,5.711
                c-7.922,0.413-8.379-0.132-10.19-11.918c5.751,1.278,11.285,2.548,16.838,3.728c3.089,0.656,6.272,0.949,9.288,1.833
                c1.429,0.419,2.568,1.834,3.83,2.808c3.11,2.401,6.215,4.808,9.667,7.479c-8.546,0.882-15.347-3.581-22.857-5.728
                c1.884,1.106,3.769,2.213,5.905,3.467C264.163,873.841,260.138,866.777,253.73,864.04z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(68)}
            fill={props.fillColors[68]}
            d="M2.329,872.159c0-2.878,0-5.665,0-9.281c6.619,1.845,12.828,3.522,18.995,5.344
                c1.046,0.309,2.11,1.064,2.823,1.905c3.025,3.562,5.921,7.236,8.883,10.892C23.288,878.207,13.039,875.25,2.329,872.159z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(69)}
            fill={props.fillColors[69]}
            d="M503.849,530.166c4.847,2.157,8.283,3.687,12.806,5.698
                c-3.662,8.287-7.213,16.322-10.413,23.563c-1.195-4.902-3.017-10.121-3.597-15.475
                C502.198,539.83,503.312,535.538,503.849,530.166z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(70)}
            fill={props.fillColors[70]}
            d="M1041.829,584.872c1.915-2.155,3.296-4.085,5.04-5.597
                c4.143-3.592,8.521-5.506,14.493-4.08c8.999,2.148,18.246,3.26,27.383,4.842c0.764,0.133,1.487,0.491,2.229,0.745
                c-0.044,0.545-0.087,1.09-0.131,1.636c-1.65-0.077-3.582,0.369-4.896-0.334c-3.25-1.738-6.196-3.156-9.674-0.518
                c-0.703,0.534-2.122,0.317-3.172,0.168c-3.703-0.524-7.371-1.45-11.088-1.693c-3.908-0.255-7.58-0.276-10.625,3.691
                C1049.718,585.909,1045.887,588.193,1041.829,584.872z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(71)}
            fill={props.fillColors[71]}
            d="M648.904,592.897c-4.955,0-8.566,0.23-12.136-0.051
                c-6.104-0.481-12.263-0.883-18.235-2.11c-3.236-0.664-6.135-2.968-9.205-4.542c0.373-0.742,0.512-1.21,0.618-1.203
                C622.745,585.893,636.486,581.546,648.904,592.897z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(72)}
            fill={props.fillColors[72]}
            d="M112.954,784.58c-1.563,0-3.149-0.172-4.684,0.033
                c-4.06,0.544-8.083,1.372-12.147,1.861c-4.833,0.581-9.706,1.377-14.542,1.266c-6.564-0.151-13.11-1.074-19.903-2.658
                c1.704-0.713,3.36-1.601,5.127-2.085c1.413-0.387,2.96-0.378,4.448-0.381c13.866-0.025,27.733-0.015,41.599-0.015
                C112.886,783.261,112.919,783.921,112.954,784.58z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(73)}
            fill={props.fillColors[73]}
            d="M460.271,323.248c-1.534,4.205-3.026,8.426-4.643,12.6
                c-0.268,0.69-1.009,1.269-1.651,1.731c-2.247,1.616-4.547,3.158-7.857,5.438c-0.761-1.148-2.104-3.175-3.37-5.087
                c0.432-2.014,0.197-5.016,1.586-6.485c2.316-2.452,5.594-4.061,8.614-5.762c2.14-1.206,4.523-1.981,6.798-2.949
                C459.922,322.906,460.096,323.077,460.271,323.248z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(74)}
            fill={props.fillColors[74]}
            d="M663.221,760.935c-3.987,0-7.979,0.127-11.96-0.032
                c-4.968-0.197-9.926-0.625-14.887-0.964c-6.44-0.439-12.874-1.073-19.322-1.285c-6.822-0.224-13.657-0.05-20.486-0.05
                c-0.032-0.598-0.065-1.195-0.098-1.793c2.064-0.277,4.16-0.938,6.187-0.767c9.389,0.796,18.748,1.964,28.142,2.681
                c2.911,0.223,5.895-0.783,8.851-0.828c7.861-0.12,15.727-0.022,23.59-0.012C663.231,758.901,663.226,759.918,663.221,760.935z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(75)}
            fill={props.fillColors[75]}
            d="M1162.619,613.814c0,3.377,0,5.921,0,9.628c-9.324-9.533-22.072-6.845-32.122-12.96
                C1141.163,611.589,1151.83,612.695,1162.619,613.814z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(76)}
            fill={props.fillColors[76]}
            d="M887.354,564.97c6.591-4.098,9.375-13.008,18.637-13.901
                c0.492,1.508,0.961,2.944,1.629,4.991c-5.909-1.838-7.726,2.554-10.215,5.586C894.701,564.938,891.43,565.923,887.354,564.97z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(77)}
            fill={props.fillColors[77]}
            d="M29.13,633.474c-2.507,1.013-5.014,2.026-7.521,3.04
                c-0.218-0.486-0.436-0.974-0.654-1.46c7.914-6.043,15.827-12.087,23.74-18.131c-0.305-0.268-0.609-0.535-0.914-0.804
                c-4.93,5.839-9.86,11.677-14.789,17.506C28.994,633.616,29.13,633.474,29.13,633.474z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(78)}
            fill={props.fillColors[78]}
            d="M672.814,768.19c8.98,1.727,17.255,3.317,25.968,4.992c0,0.971,0,2.732,0,5.371
                C690.067,775.076,681.791,771.773,672.814,768.19z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(79)}
            fill={props.fillColors[79]}
            d="M159.003,568.459c-3.714-1.844-5.115-7.033-2.523-8.978
                c4.141-3.106,8.689-5.67,13.562-7.942C164.438,555.876,159.243,560.305,159.003,568.459z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(80)}
            fill={props.fillColors[80]}
            d="M188.281,554.768c1.276,8.356,1.276,8.356-10.833,9.67
                C180.864,561.387,184.281,558.337,188.281,554.768z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(81)}
            fill={props.fillColors[81]}
            d="M714.863,930.352c-6.02-1.114-12.04-2.229-18.043-3.34
                c0.217-1.251,0.19-1.481,0.276-1.538c7.385-4.833,11.805,3.329,18.049,3.524C715.052,929.449,714.957,929.9,714.863,930.352z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(82)}
            fill={props.fillColors[82]}
            d="M418.87,402.937c-1.014-2.371-1.808-4.229-2.799-6.546c3.321,0,6.292,0,9.262,0
                c0.259,0.369,0.518,0.738,0.776,1.108C423.755,399.267,421.401,401.035,418.87,402.937z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(83)}
            fill={props.fillColors[83]}
            d="M894.911,552.887c-2.042,4.975-5.289,8.452-9.97,10.146
                c-0.338-0.421-0.676-0.841-1.014-1.261C887.589,558.811,891.25,555.849,894.911,552.887z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(84)}
            fill={props.fillColors[84]}
            d="M700.312,426.297c-1.814-3.904-3.629-7.809-5.443-11.713
                c0.434-0.205,0.867-0.409,1.3-0.613c1.982,3.794,3.965,7.588,5.947,11.382C701.515,425.668,700.914,425.982,700.312,426.297z"
          />
          <path
            fill-rule="evenodd"
            d="M792.356,1140.406c1.53,1.098,2.922,2.791,4.617,3.188c6.957,1.628,14.008,2.857,21.022,4.241
                c1.392,0.274,2.776,0.584,4.207,1.879c-0.97,0.153-2.001,0.603-2.899,0.415c-8.575-1.787-17.162-3.544-25.682-5.574
                c-16.067-3.828-32.084-7.873-48.15-11.708c-1.763-0.421-3.714-0.057-5.581,0.607c5.155,0.591,10.311,1.183,15.466,1.774
                c-0.032,0.387-0.065,0.773-0.098,1.161c-5.333-0.24-10.677-0.347-15.989-0.821c-1.21-0.108-2.306-1.492-3.454-2.289
                c1.109-1.062,2.149-2.934,3.341-3.036c3.788-0.325,7.635-0.056,11.452,0.119c1.604,0.073,3.194,0.483,6.175,0.963
                c-1.108-6.209-2.157-11.616-3.011-17.054c-0.473-3.008-0.62-6.065-1.015-10.146c-1.483-1.127-4.336-2.712-6.401-5.006
                c-2.723-3.021-5.854-6.302-6.932-10.016c-1.266-4.369-3.153-7.266-6.994-9.579c-8.684-5.229-15.921-12.061-20.935-21
                c-3.189-5.688-7.97-9.064-14.13-11.121c-4.692-1.566-9.189-3.832-13.586-6.146c-6.891-3.625-13.623-7.553-20.123-12.212
                c3.286,1.345,6.632,2.562,9.848,4.058c9.632,4.479,19.405,8.714,28.726,13.776c4.055,2.203,8.03,5.628,10.481,9.488
                c5.581,8.792,12.579,15.878,21.209,21.512c4.149,2.708,7.093,6.093,8.641,11.097c2.123,6.861,8.396,10.133,14.204,13.307
                c1.253,0.684,4.215-0.675,5.785-1.879c3.277-2.514,6.052-5.671,9.225-8.336c2.149-1.807,4.474-3.533,6.98-4.765
                c11.428-5.616,22.948-11.044,34.775-16.702c-8.69-7.039-16.854-13.75-25.127-20.321c-6.408-5.09-13.614-7.838-21.952-8.47
                c-10.584-0.802-21.094-2.579-32.03-3.121c2.869,2.248,6.21,4.104,8.489,6.842c2.758,3.313,4.979,7.209,6.779,11.151
                c2.395,5.242,4.16,10.772,5.663,16.612c-1.102-1.39-2.202-2.779-3.304-4.168c-1.95-0.845-2.903-2.182-2.15-4.359
                c-1.843-12.866-10.989-20.104-21.471-26.313c0.188-0.481,0.378-0.962,0.567-1.443c1.975-0.322,4.001-1.106,5.917-0.894
                c12.561,1.397,25.203,2.431,37.611,4.715c12.543,2.309,23.603,8.818,32.183,17.944c7.31,7.774,13.762,12.207,24.806,7.637
                c7.121-2.948,15.298-3.211,20.249-10.478c0.996-1.462,3.278-2.268,5.132-2.888c9.764-3.269,11.725-11.643,10.488-21.062
                c-0.322-2.455-1.3-4.817-1.821-7.255c-2.479-11.592,0.265-21.745,8.652-30.201c5.378-5.421,4.303-12.652,5.637-19.205
                c0.38-1.863,0.36-3.809,0.632-6.976c3.203,8.641,0.049,23.16-5.235,28.445c-7.706,7.708-11.423,22.872-3.973,33.938
                c8.605,12.779,4.472,24.155-10.051,29.472c-3.771,1.381-7.222,3.641-11.707,5.954c5.516,2.212,9.677,3.88,13.838,5.549
                c-0.173,0.454-0.346,0.907-0.52,1.361c-3.505-1.22-6.944-2.739-10.543-3.535c-1.82-0.402-4.821-0.216-5.756,0.942
                c-4.446,5.503-10.129,8.462-17.134,10.021c8.906,2.725,19.013,2.866,24.059,13.116c-1.789-0.761-3.481-1.463-5.16-2.197
                c-6.989-3.059-13.855-6.449-20.996-9.099c-6.629-2.46-19.127,3.091-22.661,9.306c11.188,5.532,22.391,11.07,33.593,16.608
                c-0.21,0.436-0.42,0.871-0.63,1.307c-4.393-2.161-8.77-4.354-13.181-6.478c-5.989-2.883-11.954-5.827-18.046-8.479
                c-1.369-0.596-3.218-0.087-4.756,0.938c10.581,5.22,21.161,10.44,31.741,15.66c-0.204,0.406-0.408,0.813-0.611,1.22
                c-8.746-4.133-17.433-8.401-26.278-12.311c-2.391-1.056-4.957-4.134-7.979-0.841c-3.86,4.207-4.06,7.903-2.086,12.679
                c1.378,3.337,2.312,7.32,1.897,10.833c-0.711,6.001-2.636,11.859-4.05,17.778c-3.03-0.572-2.86-2.867-2.532-5.034
                c0.449-2.962,0.934-5.923,1.384-8.886c0.736-4.844,0.905-9.688-1.763-14.083c-2.491-4.106-1.685-8.02,0.532-11.799
                c7.033-11.991,15.528-21.955,30.558-24.063c12.332-1.731,22.126-8.714,30.918-17.036c3.006-2.845,6.938-3.445,10.312-5.275
                c5.398-2.93,7.49-6.888,7.691-10.99c0.706,6.598-5.007,10.278-11.031,12.811c-3.759,1.581-7.088,3.262-9.941,6.111
                c-5.092,5.085-11.32,7.844-18.354,9.291c-11.995,2.47-23.37,6.323-33.181,14.235c-7.448,6.007-11.677,12.829-10.488,22.764
                c0.855,7.157,0.382,14.125-4.604,20.323c-3.111,3.868-1.645,7.222,3.056,8.9C787.313,1138.697,789.794,1139.68,792.356,1140.406z"
          />
          <path
            fill-rule="evenodd"
            d="M741.039,1103.577c-5.022-3.718-10.079-7.39-15.062-11.161
                c-25.209-19.082-51.822-35.608-82.313-45.001c-12.666-3.901-25.563-6.646-38.769-3.271c-4.902,1.253-9.438,3.915-14.177,5.846
                c-1.949,0.795-3.957,1.78-6.001,1.959c-31.787,2.783-62.75-1.405-92.998-11.317c-11.975-3.924-21.528-10.051-27.036-17.739
                c1.253,0.922,2.544,1.796,3.752,2.772c13.246,10.709,29.269,14.626,45.279,18.648c23.319,5.859,46.962,7.282,70.858,5.438
                c1.458-0.112,3.125-0.327,4.282-1.106c14.072-9.479,29.401-9.604,45.108-5.941c18.581,4.334,36.407,11.07,52.515,21.188
                c18.288,11.486,35.729,24.321,53.557,36.545c0.814,0.559,1.796,0.874,2.7,1.303
                C742.17,1102.351,741.604,1102.964,741.039,1103.577z"
          />
          <path
            fill-rule="evenodd"
            d="M964.861,1147.834c-3.993-0.358-8.013-0.532-11.973-1.11c-10.656-1.557-21.026-2.662-32.058-0.853
                c-21.215,3.479-42.397,0.207-63.365-3.926c-9.12-1.798-18.31-3.248-27.46-4.893c-1.706-0.307-3.379-0.789-5.067-1.19
                c0.079-0.484,0.157-0.969,0.236-1.452c6.711,0.984,13.496,1.617,20.117,3.02c21.68,4.595,43.441,8.268,65.716,7.478
                c3.478-0.124,7.11-0.027,10.383-1.002c12.066-3.592,23.708-0.017,35.479,1.534c2.712,0.356,5.413,0.795,8.119,1.195
                C964.946,1147.034,964.903,1147.434,964.861,1147.834z"
          />
          <path
            fill-rule="evenodd"
            d="M1023.108,1015.564c-23.305-3.367-46.998-0.764-70.673-4.598c1.433-6.909-2.571-10.084-7.227-11.521
                c-11.399-3.519-22.995-6.438-34.591-9.274c-4.714-1.153-9.632-1.471-14.457-2.168c0.062-0.554,0.124-1.107,0.187-1.661
                c4.899,0.542,9.891,0.664,14.68,1.713c10.223,2.239,20.438,4.634,30.467,7.601c6.228,1.843,12.271,4.565,13.771,12.268
                c0.174,0.895,2.601,1.852,4.026,1.924c15.465,0.783,30.944,1.286,46.406,2.111c5.878,0.313,11.721,1.262,17.58,1.921
                C1023.222,1014.441,1023.165,1015.003,1023.108,1015.564z"
          />
          <path
            fill-rule="evenodd"
            d="M1004.402,1068.107c0.759,11.196-3.387,20.454-13.234,24.862
                c-15.178,6.794-12.324,19.922-1.105,27.779c3.366,2.358,6.624,4.874,9.972,7.347c-2.883,6.548-9.737,6.309-14.378,9.397
                c0.258,6.453,6.18,8.663,9.078,13.069c-5.122-1.878-8.925-5.145-10.898-10.294c-1.2-3.131,0.149-5.028,3.158-6.157
                c3.368-1.264,6.666-2.715,11.184-4.572c-2.49-1.891-4.1-2.902-5.458-4.181c-4.083-3.843-8.699-7.332-11.938-11.806
                c-4.481-6.189-3.191-14.557,3.005-18.045C994.811,1089.302,1003.266,1081.667,1004.402,1068.107z"
          />
          <path
            fill-rule="evenodd"
            d="M685.51,1056.843c-22.782-16.021-48.947-24.632-74.584-34.384
                C637.707,1029.797,663.778,1038.674,685.51,1056.843z"
          />
          <path
            fill-rule="evenodd"
            d="M749.899,1064.768c0.717,1.453,1.434,2.906,2.15,4.359c0.425,1.927,0.87,3.851,1.246,5.787
                c0.043,0.222-0.305,0.52-1.019,1.643c-7.127-19.71-19.634-34.012-38.664-42.2C725.271,1035.272,738.295,1046.345,749.899,1064.768
                z"
          />
          <path
            fill-rule="evenodd"
            d="M737.703,1137.151c5.705,1.185,11.411,2.369,17.117,3.554c-0.159,0.721-0.318,1.44-0.477,2.16
                c-5.661-1.224-11.322-2.447-16.982-3.671C737.475,1138.514,737.589,1137.832,737.703,1137.151z"
          />
          <path
            fill-rule="evenodd"
            d="M459.195,354.796c2.755,1.119,5.527,2.199,8.261,3.37c2.184,0.936,4.245,1.776,3.391,5.086
                c-0.298,1.157,0.979,2.897,1.864,4.14c1.107,1.558,2.551,2.876,4.767,5.3c-3.537,0.42-5.842,0.693-8.146,0.967
                c-0.065,0.441-0.131,0.883-0.196,1.325c6.087,0.968,12.164,2.001,18.264,2.88c3.448,0.497,6.953,0.614,10.392,1.164
                c1.411,0.226,3.499,0.794,3.937,1.793c3.134,7.163,10.776,5.69,15.052,8.863c2.604-7.735,5.066-15.051,7.599-22.572
                c-4.103-0.904-8.569-1.888-13.037-2.872c0.079-0.366,0.158-0.732,0.237-1.099c5.828,1.117,11.656,2.235,18.287,3.506
                c-7.062,6.383-10.114,13.285-9.618,21.56c0.073,1.208,1.016,2.777,2.037,3.445c4.784,3.128,9.679,6.104,14.646,8.934
                c5.153,2.936,10.533,5.479,15.629,8.505c3.544,2.104,6.771,4.739,10.149,7.123c4.051,2.857,7.766,7.22,12.239,8.241
                c10.373,2.368,13.196,12.006,19.931,18.792c-0.367-7.072-0.424-13.336-1.166-19.518c-0.245-2.049-1.856-4.385-3.536-5.718
                c-3.579-2.839-5.927-5.471-5.422-10.744c0.393-4.1-1.553-8.412-2.377-12.646c-0.185-0.946-0.027-1.959-0.027-3.896
                c5.897,4.133,11.684,7.417,16.446,11.809c2.948,2.719,4.254,7.178,6.456,10.757c2.875,4.672,6.068,9.153,8.805,13.902
                c1.355,2.354,2.021,5.11,2.946,7.703c0.329,0.921,0.216,2.144,0.79,2.81c5.396,6.256,10.897,12.42,16.623,18.899
                c1.232-1.443,2.134-2.499,3.519-4.12c5.114,5.527,10.023,10.832,14.934,16.138c-0.04,0.037-0.079,0.074-0.119,0.111
                c-4.646-4.221-9.291-8.442-14.291-12.985c-2.988,4.455-5.426,2.184-7.685-0.372c-4.716-5.338-9.349-10.75-14.007-16.14
                c-0.324-0.375-0.745-0.786-0.825-1.233c-2.056-11.545-11.307-19.298-15.599-29.412c-3.387-7.98-10.329-9.535-16.593-13.572
                c0.601,2.841,1.282,5.668,1.776,8.526c0.383,2.211,0.27,4.536,0.867,6.673c0.449,1.604,1.332,3.479,2.619,4.391
                c5.428,3.849,7.497,8.857,7.163,15.473c-0.246,4.867,0.578,9.788,1.034,16.23c-2.595-1.935-4.452-2.892-5.72-4.358
                c-2.166-2.507-3.69-5.601-5.995-7.945c-2.063-2.1-4.737-3.648-7.283-5.21c-3.667-2.25-7.876-3.799-11.119-6.521
                c-7.54-6.332-15.508-11.758-24.621-15.61c-3.142-1.328-6.133-3.5-8.574-5.912c-9.087-8.979-21.23-11.114-32.787-15.49
                c1.056-4.704-2.728-4.669-5.979-5.076c-5.768-0.723-11.606-1.021-17.314-2.058c-13.003-2.362-25.945-5.058-38.907-7.649
                c-0.438-0.088-0.809-0.515-0.992-0.638c11.438,0.902,23.072,1.819,34.575,2.727c-1.453-4.26-2.517-7.827-3.95-11.238
                c-0.326-0.777-2.012-0.901-2.936-1.527c-2.252-1.529-4.425-3.177-6.629-4.776L459.195,354.796z"
          />
          <path
            fill-rule="evenodd"
            d="M838.134,619.81c-7.729-0.768-15.492-1.293-23.178-2.374c-6.772-0.953-9.985,2.345-9.463,9.311
                c0.443,5.916,0.175,11.942-0.411,17.857c-0.377,3.807-1.629,7.608-3.062,11.183c-0.458,1.143-2.968,2.413-4.166,2.116
                c-2.828-0.699-5.655-1.973-8.088-3.604c-8.467-5.675-16.833-11.398-27.501-12.101c-1.18-0.078-2.509-0.992-3.4-1.887
                c-10.87-10.899-24.155-16.829-39.166-19.307c-1.067-0.176-2.086-0.647-3.127-0.981c0.097-0.415,0.194-0.83,0.292-1.245
                c3.206,0.728,6.392,1.562,9.622,2.161c10.199,1.89,19.578,5.713,27.099,12.92c5.569,5.338,11.95,8.025,20.323,8.1
                c-2.008-4.6-4.039-9.252-6.07-13.904c-0.537,0.209-1.075,0.419-1.612,0.628c0.165,2.295,0.33,4.59,0.495,6.885
                c-0.543,0.046-1.085,0.093-1.628,0.14c-1.002-12.654-9.458-24.264-3.966-37.919c0.865-2.153,0.059-5.213-0.578-7.698
                c-4.007-15.643-8.76-30.992-16.794-45.187c-2.845-5.026-4.436-10.745-6.953-15.978c-2.623-5.449-5.099-6.018-11.278-2.36
                c-2.392-5.403-4.729-10.686-7.068-15.968c0.369-0.176,0.737-0.352,1.106-0.527c2.078,4.391,4.156,8.781,6.215,13.132
                c6.515-2.075,9.854-1.249,12.303,4.47c8.934,20.862,20.537,40.729,24.472,63.491c0.508,2.937,0.3,6.286-0.596,9.123
                c-2.516,7.975,1.028,20.813,6.643,27.151c3.024,3.413,4.09,8.515,6.302,12.716c1.008,1.915,2.351,3.915,4.065,5.147
                c4.314,3.101,8.833,5.953,13.46,8.567c2.277,1.286,4.979,1.821,6.878,2.483c4.33-11.295,5.809-21.954,4.281-33.01
                c-0.217-1.576,1.668-3.393,2.418-5.166c0.845-1.996,1.506-4.069,2.247-6.108c-0.416-0.275-0.832-0.55-1.247-0.825
                c1.845,0,3.689,0,6.024,0c-0.816,1.24-1.386,2.106-2.525,3.838c9.649,1.681,18.703,3.258,27.757,4.834L838.134,619.81z"
          />
          <path
            fill-rule="evenodd"
            d="M823.221,793.426c10.24,0.951,20.186,2.126,28.914,8.338c3.284,2.338,8.291,2.283,12.529,3.246
                c8.6,1.954,18.037,1.812,23.694,10.59c3.349,5.195,9.192,5.331,14.568,6.429c4.647,0.948,9.19,2.41,13.772,3.672
                c3.036,0.837,6.091,1.624,9.083,2.601c17.23,5.622,34.606,10.528,52.835,11.659c2.114,0.132,4.191,0.928,6.271,1.474
                c0.222,0.058,0.338,0.513,1.169,1.878c-36.906-4.556-73.01-9.013-109.113-13.469c0.05-0.691,0.1-1.382,0.149-2.073
                c7.251,0.478,14.538,0.648,21.742,1.516c7.419,0.894,14.753,2.486,22.131,3.735c7.28,1.232,14.567,2.418,21.852,3.624
                c0.101-0.498,0.201-0.995,0.302-1.492c-3.454-0.979-6.891-2.022-10.365-2.924c-11.834-3.071-23.799-5.711-35.462-9.326
                c-4.5-1.395-8.746-4.481-12.367-7.645c-5.987-5.231-13.187-6.607-20.5-8.054c-3.962-0.783-8.715-0.499-11.712-2.647
                c-7.445-5.339-15.734-7.695-24.38-9.556C826.597,794.628,824.923,793.959,823.221,793.426z"
          />
          <path
            fill-rule="evenodd"
            d="M795.212,782.326c-12.042,2.503-23.062-0.936-34.216-2.041c0.022-0.478,0.045-0.954,0.067-1.432
                c10.284,0.97,20.569,1.939,30.854,2.909c0.343-0.358,0.687-0.717,1.029-1.074c-6.143-7.371-7.255-16.02-7.811-26.545
                c-7.675-3.865-16.331-8.319-25.119-12.494c-0.807-0.383-2.998,0.661-3.63,1.637c-4.832,7.457-9.39,15.091-14.134,22.605
                c-2.128,3.371-4.488,6.595-7.236,10.604c5.954,5.967,12.13,12.155,18.692,18.732c-5.62,3.617-2.746,6.034,0.604,8.784
                c2.146,1.764,3.793,4.158,5.552,6.359c0.588,0.735,1.424,2.144,1.139,2.572c-3.927,5.891,0.723,10.419,2.096,15.518
                c0.484,1.802-0.532,4.007-0.973,6.695c2.856,0.456,6.192,1.137,9.563,1.494c5.961,0.632,11.937,1.208,17.921,1.545
                c5.827,0.328,11.703,0.421,15.902-5.934c-0.95-0.446-2.418-1.137-3.887-1.826c0.477-0.579,0.953-1.158,1.431-1.738
                c1.589,1.085,3.178,2.169,4.767,3.253c-1.135,1.438-2.282,2.864-3.4,4.314c-3.619,4.696-8.825,4.139-13.74,3.854
                c-8.633-0.501-17.251-1.305-25.856-2.173c-2.065-0.209-4.055-1.173-6.686-1.975c4.541-5.879,0.614-10.694-0.832-15.792
                c-0.349-1.229-0.869-3.052-0.285-3.796c3.506-4.466-0.112-6.858-2.516-9.59c-0.983-1.118-2.143-2.111-3.337-3.007
                c-3.34-2.505-4.973-5.123,0.416-8.923c-4.562-3.918-8.569-7.419-12.64-10.844c-6.312-5.311-6.441-9.009-0.443-14.853
                c0.458-0.447,0.97-0.892,1.27-1.439c3.868-7.053,7.691-14.13,11.561-21.182c0.477-0.869,1.207-1.596,1.727-2.444
                c2.608-4.266,5.697-5.812,10.612-3.072c6.952,3.876,14.219,7.187,21.309,10.821c2.441,1.253,3.853,3.001,2.619,6.085
                c-0.433,1.081-0.129,2.641,0.268,3.839c1.907,5.761,1.459,12.371,6.168,17.147C794.551,779.445,794.586,780.439,795.212,782.326z"
          />
          <path
            fill-rule="evenodd"
            d="M798.251,717.371c4.486,1.848,8.897,3.909,13.472,5.503c12.232,4.262,25.131,4.303,37.806,5.917
                c18.771,2.389,37.426,5.696,56.13,8.619c4.125,0.646,8.246,1.318,12.368,1.979c-0.073,0.533-0.147,1.067-0.221,1.601
                c-4.601-0.624-9.204-1.22-13.8-1.877c-25.666-3.668-51.328-7.365-76.995-11.024c-11.578-1.65-29.021-8.128-36.38-14.239
                c38.086,1.924,75.72,8.327,113.755,10.756c-0.051,0.584-0.103,1.168-0.153,1.752c-1.622-0.163-3.251-0.275-4.864-0.502
                c-1.646-0.232-3.265-0.736-4.914-0.848c-13.874-0.935-27.769-1.624-41.624-2.779c-6.399-0.533-12.701-2.136-19.089-2.908
                c-11.647-1.408-23.326-2.561-34.992-3.819C798.583,716.124,798.417,716.748,798.251,717.371z"
          />
          <path
            fill-rule="evenodd"
            d="M732.437,668.657c-1.703,1.174-3.469,2.266-5.097,3.536c-5.543,4.324-11.779,6.739-18.812,7.053
                c-3.31,0.147-5.081-1.011-4.393-4.725c0.391-2.111,0.559-4.313,0.463-6.458c-0.233-5.208,1.83-8.771,7.006-10.001
                c4.373-1.039,4.922-3.924,4.052-7.562c-1.457-6.091-3.031-12.153-4.556-18.228c0.481-0.125,0.963-0.251,1.444-0.376
                c1.807,7.284,3.939,14.515,5.154,21.896c0.255,1.55-2.518,4.326-4.507,5.378c-5.856,3.097-9.421,10.742-6.895,16.827
                c0.373,0.898,2.96,1.771,3.997,1.353c5.924-2.393,11.677-5.205,17.514-7.818c1.85-0.829,4.718-2.612,5.47-1.984
                c1.513,1.264,2.729,3.949,2.578,5.935c-0.439,5.772-1.516,11.508-2.535,17.224c-0.727,4.074,0.561,5.971,4.844,6.775
                c3.468,0.651,6.627,2.805,10.021,4.021c3.097,1.11,6.271,2.175,9.503,2.689c4.895,0.779,6.976,3.448,6.553,8.215
                c-0.322,3.638-1.032,7.254-1.135,10.893c-0.087,3.098,0.447,6.213,0.838,10.897c-4.772-1.403-8.905-2.576-13.01-3.836
                c-5.789-1.777-11.464-4.652-17.357-5.188c-8.527-0.773-11.443,4.676-8.148,12.663c1.813,4.396,3.424,8.997,4.194,13.66
                c0.487,2.948-0.742,6.181-2.027,9.222c1.908-8.871-0.896-16.579-4.481-24.402c-3.018-6.584,1.271-13.198,8.567-13.187
                c4.643,0.008,9.379,1.444,13.895,2.825c4.1,1.254,7.936,3.354,11.935,4.964c1.172,0.472,2.506,0.54,3.237,0.687
                c0.658-6.609,1.423-12.943,1.868-19.299c0.234-3.343-1.253-5.406-4.947-6.271c-4.275-1.001-8.322-2.949-12.502-4.392
                c-2.805-0.968-5.624-1.972-8.516-2.596c-3.438-0.742-5.426-2.323-4.902-6.112c0.612-4.431,1.288-8.856,1.756-13.304
                c0.354-3.366,0.426-6.763,0.624-10.146C733.564,669.21,733,668.934,732.437,668.657z"
          />
          <path
            fill-rule="evenodd"
            d="M759.32,889.423c0.042,6.564-1.82,8.225-7.484,6.411c-3.573-1.145-6.662-3.728-10.183-5.127
                c-3.624-1.44-7.461-2.51-11.303-3.143c-1.283-0.212-3.332,1.076-4.226,2.277c-2.046,2.748-4.272,2.838-6.944,1.403
                c-2.05-1.102-3.943-2.584-6.098-3.378c-4.11-1.516-6.944-3.178-5.688-8.59c0.749-3.232-0.814-6.985-0.145-10.26
                c0.334-1.631,3.411-3.905,5.146-3.82c5.257,0.258,10.577,1.112,15.664,2.498c7.048,1.92,10.499,0.213,12.107-6.918
                c0.102-0.448,0.477-0.834,0.932-1.594c1.001,0.526,2.115,0.848,2.871,1.553c5.081,4.742,10.061,9.593,15.136,14.341
                c1.077,1.009,2.302,2.096,3.655,2.531c4.298,1.382,7.391,3.387,7.565,8.585c0.045,1.318,2.194,2.653,3.519,3.819
                c1.28,1.127,2.744,2.045,4.127,3.055c-0.188,0.385-0.377,0.77-0.566,1.154C771.562,892.67,765.716,891.119,759.32,889.423z"
          />
          <path
            fill-rule="evenodd"
            d="M931.828,851.761c-15.178-1.702-30.345-3.508-45.535-5.078c-16.039-1.657-32.112-2.979-48.145-4.688
                c-5.091-0.543-10.187-1.543-15.124-2.903c-5.332-1.469-6.735-6.072-4.021-12.347c-2.486-1.032-4.981-2.068-7.478-3.104
                c0.1-0.434,0.199-0.866,0.3-1.299c2.861,0.589,5.847,0.872,8.512,1.949c0.815,0.33,1.462,3.143,0.969,4.298
                c-2.631,6.166-1.99,8.132,4.685,9.037c14.614,1.981,29.29,3.508,43.935,5.275c19.471,2.351,38.936,4.742,58.396,7.161
                c1.271,0.157,2.494,0.701,3.739,1.065C931.983,851.339,931.905,851.55,931.828,851.761z"
          />
          <path
            fill-rule="evenodd"
            d="M762.448,816.625c11.505,1.781,23.01,3.562,33.629,5.207c0.718-2.689,2.322-5.973,2.267-9.228
                c-0.062-3.644-1.463-7.314-2.6-10.872c-0.735-2.3-2.72-4.342-2.971-6.628c-0.824-7.531-6.434-8.702-12.634-9.386
                c0.167-0.408,0.333-0.817,0.5-1.227c3.047,0.271,6.179,0.213,9.104,0.961c1.342,0.344,2.649,2.095,3.329,3.516
                c1.896,3.962,3.992,7.956,5.006,12.179c1.126,4.688,4.713,9.306,1.123,14.61c-0.768,1.134-0.143,3.645,0.55,5.206
                c0.659,1.488,2.244,2.566,3.423,3.825c-0.203,0.516-0.406,1.031-0.609,1.548c-9.604-0.997-19.209-1.995-28.812-2.991
                c0.03-0.46,0.062-0.92,0.093-1.379c4.747,0.314,9.493,0.629,14.24,0.943c0.036-0.264,0.071-0.527,0.107-0.792
                c-8.684-1.209-17.366-2.418-26.049-3.627C762.246,817.869,762.347,817.247,762.448,816.625z"
          />
          <path
            fill-rule="evenodd"
            d="M875.888,824.059c-11.605-0.609-23.244-0.837-33.942-5.855c-8.211-3.853-16.911-6.521-23.944-12.972
                c-6.515-5.977-8.623-12.534-6.551-21.378c-3.062-0.847-6.094-1.685-9.126-2.522c0.094-0.483,0.188-0.967,0.283-1.449
                c3.204,0.599,6.467,1.003,9.576,1.923c0.84,0.249,1.626,2.021,1.736,3.161c0.419,4.308-1.065,9.932,1.136,12.691
                c4.111,5.153,9.893,9.362,15.707,12.69c12.957,7.415,27.092,11.582,42.059,12.702C873.862,823.127,874.866,823.71,875.888,824.059
                z"
          />
          <path
            fill-rule="evenodd"
            d="M763.89,440.552c4.445,2.521,8.683,5.884,13.503,7.459c10.521,3.437,12.137,7.065,10.886,17.984
                c-1.09,9.514-3.579,18.877-5.638,28.267c-2.02,9.215-4.526,18.328-6.334,27.582c-1.094,5.6-1.263,11.379-1.848,17.078
                c-0.526-0.04-1.052-0.08-1.578-0.12c0.174-1.977,0.182-3.984,0.549-5.925c2.281-12.03,4.637-24.046,6.979-36.064
                c2.189-11.233,4.808-22.404,6.449-33.717c1.148-7.909-1.666-10.86-9.576-12.109C770.479,449.912,765.764,446.542,763.89,440.552z"
          />
          <path
            fill-rule="evenodd"
            d="M838.257,619.914c-3.478-1.54-7.046-2.906-10.41-4.664c-4.496-2.35-8.827-5.014-13.243-7.519
                c-1.725-0.979-3.422-2.042-5.241-2.805c-6.654-2.79-13.354-5.468-20.232-8.269c-0.235,2.09-0.977,4.836-0.659,7.453
                c0.161,1.323,2.113,3.265,3.468,3.46c5.344,0.771,6.835,3.775,6.234,8.66c-0.132,1.069,0.417,2.224,0.649,3.33
                c-4.1,1.334-8.074,0.265-22.509-7.066c3.278,0.9,6.6,1.669,9.821,2.737c3.281,1.089,6.464,2.473,9.848,3.789
                c0.951-5.263,0.625-9.033-5.509-10.161c-2.88-0.529-4.299-2.832-3.8-6.48c0.345-2.514-1.185-5.296-1.932-7.943
                c-0.476-1.686-1.053-3.343-0.537-5.427c0.761,1.502,1.521,3.005,2.029,4.006c2.018,0.727,3.75,1.19,5.34,1.954
                c3.852,1.85,7.661,3.794,11.443,5.783c5.228,2.749,10.343,5.721,15.64,8.325c4.905,2.411,10.012,4.41,14.979,6.698
                c2.431,1.12,4.749,2.484,7.118,3.737c-0.203,0.371-0.405,0.741-0.607,1.112c-0.674-0.271-1.347-0.542-2.017-0.814
                C838.134,619.81,838.257,619.914,838.257,619.914z"
          />
          <path
            fill-rule="evenodd"
            d="M515.958,457.063c10.327,2.046,20.705,3.885,30.929,6.359c1.538,0.373,2.235,3.8,3.542,5.66
                c1.66,2.363,3.545,4.568,5.886,7.546c-18.866-3.638-36.521-7.043-54.177-10.447c16.667,0.323,33.053,2.399,48.966,8.374
                c-2.412-6.438-6.181-10.084-12.143-11.33c-7.747-1.619-15.55-2.974-23.328-4.441C515.741,458.211,515.85,457.637,515.958,457.063z
                "
          />
          <path
            fill-rule="evenodd"
            d="M884.51,924.099c11.337,1.603,22.686,3.129,34.003,4.86c2.924,0.447,6.034,1.026,8.565,2.434
                c5.989,3.33,11.732,2.539,18.061-0.404c-1.423-1.352-2.865-2.722-4.029-3.827c1.286-2.383,3.409-4.524,3.403-6.661
                c-0.014-5.165,3.128-4.479,6.215-4.142c1.733,0.189,3.418,0.818,5.014,2.084c-2.791-0.2-5.582-0.4-8.823-0.633
                c-0.406,3.427-0.782,6.593-1.262,10.636c0.258,0.408,1.244,1.963,2.394,3.775c-7.595,3.529-14.147,4.616-22.088,1.52
                c-8.214-3.203-17.407-3.931-26.199-5.597c-3.261-0.617-6.659-0.569-9.882-1.315c-1.906-0.441-3.59-1.842-5.374-2.812
                c-0.294,0.025-0.587,0.052-0.88,0.077C883.921,924.096,884.216,924.098,884.51,924.099z"
          />
          <path
            fill-rule="evenodd"
            d="M468.806,384.562c0,4.754,0,8.904,0,13.337c-7.767-1.783-15.022-3.448-22.277-5.114
                c0.055-0.362,0.109-0.725,0.165-1.087c6.182,0.981,12.363,1.962,19.206,3.048c0.312-4.688,0.648-9.718,0.977-14.629
                c5.997,5.82,15.058,4.525,23.01,6.935c5.558,1.685,9.85,11.538,6.53,15.598c1.164,1.483,2.333,2.974,3.79,4.831
                c-7.198-2.653-13.809-5.09-20.419-7.526c0.107-0.401,0.215-0.804,0.322-1.205c4.46,1.175,8.919,2.351,13.345,3.517
                c3.558-4.751,0.791-12.121-4.967-13.391C482.341,387.519,476.19,386.179,468.806,384.562z"
          />
          <path
            fill-rule="evenodd"
            d="M694.241,537.276c-3.936-0.479-7.859-1.106-11.809-1.405c-9.263-0.7-18.538-1.219-27.806-1.862
                c-3.801-0.264-7.6-0.589-11.385-1.017c-5.937-0.671-9.327-4.155-11.44-9.666c-1.515-3.949-3.914-7.582-6.11-11.241
                c-3.241-5.4-6.842-10.592-9.932-16.074c-2.562-4.544-4.607-9.379-6.078-14.511c1.175,1.945,2.345,3.894,3.525,5.836
                c5.677,9.337,11.384,18.654,17.008,28.022c1.098,1.829,2.269,3.763,2.725,5.801c1.359,6.072,4.838,9.803,11.05,10.455
                c5.436,0.57,10.923,0.623,16.374,1.068c11.389,0.929,22.769,1.966,34.151,2.959C694.424,536.187,694.333,536.731,694.241,537.276z
                "
          />
          <path
            fill-rule="evenodd"
            d="M466.144,349.058c4.718,0.203,9.155,0.584,11.046,6.173c0.547,1.617,3.302,2.601,5.181,3.627
                c2.629,1.438,5.405,2.604,8.08,3.962c1.903,0.966,3.981,1.78,5.565,3.146c3.678,3.171,7.104,6.634,10.632,9.979
                c-0.35,0.366-0.699,0.732-1.049,1.099c-5.422-0.34-11.083,0.146-16.173-1.334c-2.936-0.854-4.921-4.91-7.384-7.478
                c-2.111-2.202-4.272-4.357-6.451-6.492c-1.173-1.148-2.4-2.245-3.656-3.303c-1.764-1.486-3.662-2.822-5.341-4.396
                c-1.389-1.3-2.542-2.85-3.799-4.289c0.354-0.295,0.71-0.591,1.065-0.886c5.136,4.247,10.38,8.373,15.351,12.806
                c1.794,1.6,3.038,3.869,4.347,5.95c2.877,4.574,6.155,8.235,12.34,7.268c1.938-0.303,3.964-0.047,6.625-1.001
                c-2.942-2.243-5.856-4.524-8.84-6.711c-1.328-0.974-2.747-1.854-4.213-2.602c-2.491-1.273-5.07-2.374-7.58-3.613
                c-1.9-0.938-5.019-1.637-5.397-3.036C475.002,352.415,470.807,350.749,466.144,349.058z"
          />
          <path
            fill-rule="evenodd"
            d="M884.507,924.017c-2.937,4.334-2.639,5.536,1.928,7.883c3.343,1.719,6.676,3.454,9.507,6.208
                c-4.091-1.943-8.295-3.687-12.21-5.936c-1.394-0.801-3.17-3.071-2.903-4.248c0.993-4.38-1.997-5.27-4.76-5.996
                c-11.541-3.032-23.135-5.861-34.726-8.699c-4.115-1.007-8.276-1.829-12.279-2.705c4.499-2.827,43.816,6.729,55.436,13.599
                C884.51,924.099,884.507,924.017,884.507,924.017z"
          />
          <path
            fill-rule="evenodd"
            d="M524.636,440.519c1.712,4.189,3.425,8.38,5.137,12.569c-0.375,0.441-0.749,0.883-1.124,1.325
                c-3.651-0.664-7.34-1.17-10.943-2.031c-4.803-1.148-8.186-0.212-9.631,5.074c-0.282,1.031-1.933,2.509-2.827,2.427
                c-11.659-1.079-23.296-2.402-35.089-4.512c1.448-0.14,2.916-0.506,4.34-0.38c8.76,0.774,17.529,1.498,26.256,2.566
                c5.637,0.69,7.804-1.664,5.647-8.149c7.007,0.816,13.586,1.583,20.138,2.346c-0.668-3.963-1.283-7.608-1.897-11.255l-0.448-0.021
                L524.636,440.519z"
          />
          <path
            fill-rule="evenodd"
            d="M625.706,404.605c6.562,0.376,12.688,0.581,18.784,1.122c5.028,0.447,6.996,3.622,7.691,8.508
                c0.274,1.932,2.586,4.026,4.496,5.169c3.134,1.877,6.696,3.038,10.078,4.501c-0.273,0.598-0.547,1.194-0.82,1.791
                c-4.625-2.304-9.398-4.366-13.783-7.062c-1.304-0.802-1.266-3.545-2.289-5.106c-1.487-2.27-3.028-5.524-5.174-6.127
                c-4.188-1.177-8.81-0.807-12.657-1.04c0.175,2.866-0.036,5.403,0.564,7.73c0.715,2.773,1.525,5.916,3.362,7.921
                c3.564,3.892,7.875,7.102,11.876,10.593c-0.55,0.571-1.1,1.143-1.649,1.714c-3.511-3.307-6.896-6.763-10.567-9.88
                c-4.083-3.467-5.216-7.57-4.868-12.854C630.877,409.663,627.933,407.538,625.706,404.605z"
          />
          <path
            fill-rule="evenodd"
            d="M524.642,440.499c-5.362-0.536-10.782-0.785-16.043-1.835c-1.138-0.227-2.757-3.643-2.338-5.048
                c1.377-4.612-0.629-6.117-4.62-6.708c-6.219-0.922-12.427-1.924-18.642-2.88c-1.526-0.235-3.061-0.429-4.591-0.641
                c0.066-0.546,0.133-1.092,0.199-1.638c7.729,0.901,15.457,1.804,22.997,2.683c0-4.507,0-9.261,0-14.016
                c0.295-0.221,0.589-0.441,0.883-0.662c0.749,1.474,2.523,3.507,2.075,4.327c-2.406,4.412-1.254,7.698,1.435,11.626
                c1.978,2.89,2.298,6.915,3.334,10.36c4.688,1.367,9.989,2.913,15.298,4.455C524.636,440.519,524.642,440.499,524.642,440.499z"
          />
          <path
            fill-rule="evenodd"
            d="M682.642,374.95c1.874,0.881,3.667,2.128,5.64,2.567c4.366,0.973,8.913,1.211,13.211,2.387
                c2.882,0.788,5.423,2.758,8.191,4.036c2.322,1.072,4.752,1.91,7.135,2.852c-0.186,0.43-0.371,0.859-0.556,1.289
                c-2.926-1.42-5.813-2.925-8.788-4.232c-2.396-1.052-4.83-2.175-7.364-2.715c-4.375-0.934-8.828-1.765-13.277-1.948
                c-1.563-0.064-4.426,1.729-4.601,2.969c-0.612,4.349-0.321,8.825-0.352,13.253c-0.014,1.858,0.028,3.718,0.045,5.577
                c-2.035-0.023-4.082,0.069-6.101-0.123c-0.894-0.086-1.732-0.748-2.413-1.862c2.304,0.292,4.608,0.584,6.057,0.768
                c0.594-8.796,1.165-17.268,1.736-25.739C681.684,374.335,682.163,374.643,682.642,374.95z"
          />
          <path
            fill-rule="evenodd"
            d="M932.781,752.695c7.48,1.296,15.062,2.199,22.385,4.104c2.514,0.654,4.709,3.559,6.474,5.874
                c5.24,6.877,10.194,13.973,15.021,21.414c-1.451-1.193-3.145-2.188-4.31-3.616c-3.963-4.856-7.853-9.781-11.551-14.841
                c-3.064-4.193-6.459-7.21-12.062-7.791c-5.507-0.572-10.898-2.248-16.341-3.439C932.525,753.832,932.653,753.264,932.781,752.695z
                "
          />
          <path
            fill-rule="evenodd"
            d="M884.439,666.157c-21.393-2.804-42.786-5.606-64.338-8.431
                C826.109,655.856,877.195,661.986,884.439,666.157z"
          />
          <path
            fill-rule="evenodd"
            d="M670.858,550.418c3.447,6.803,7.138,13.495,10.274,20.438c3.174,7.026,6.954,13.742,9.967,20.896
                c3.348,7.951,10.582,14.281,16.219,21.241c1.239,1.531,2.873,2.744,3.921,4.769c-0.8-0.486-1.765-0.82-2.374-1.482
                c-5.745-6.239-11.679-12.334-17.006-18.917c-2.072-2.56-2.729-6.262-4.044-9.437c-0.622-1.504-1.2-3.033-1.933-4.482
                c-5.451-10.794-10.937-21.57-16.411-32.353C669.934,550.867,670.396,550.643,670.858,550.418z"
          />
          <path
            fill-rule="evenodd"
            d="M818.654,566.025c3.073-2.555,4.684-3.893,7.083-5.887c-6.295-1.155-11.842-2.172-17.389-3.189
                c0.071-0.436,0.143-0.87,0.214-1.306c6.742,0.962,13.483,1.923,20.226,2.884c0.068,0.519,0.136,1.038,0.204,1.557
                c-2.16,1.673-4.32,3.345-7.238,5.604c3.27,1.408,5.989,2.67,8.775,3.76c5.968,2.334,10.137,5.628,12.155,12.521
                c1.697,5.795,6.193,10.77,9.115,16.35c-4.884-4.873-9.579-10.101-11.06-17.075c-0.994-4.679-3.286-7.275-7.513-8.914
                C828.712,570.58,824.324,568.498,818.654,566.025z"
          />
          <path
            fill-rule="evenodd"
            d="M700.009,439.796c-0.016,4.149-0.019,8.3-0.051,12.449c-0.023,3.018,0.089,5.829,3.016,7.881
                c1.006,0.705,1.521,2.583,1.655,3.983c0.908,9.514,1.582,19.05,2.489,28.563c0.333,3.491,1.091,6.941,1.654,10.409
                c-0.438,0.101-0.877,0.201-1.315,0.301c-0.519-2.093-1.23-4.158-1.521-6.282c-1.255-9.188-2.655-18.369-3.438-27.601
                c-0.35-4.114-0.086-7.76-3.635-11.071c-2.648-2.472-1.004-16.432,1.284-18.501C700.148,439.923,700.009,439.796,700.009,439.796z"
          />
          <path
            fill-rule="evenodd"
            d="M795.654,763.193c21.516-1.18,39.872,11.077,60.495,14.09c-0.096,0.481-0.191,0.963-0.288,1.445
                c-0.756-0.047-1.545,0.031-2.264-0.159C834.3,773.467,815.01,768.336,795.654,763.193z"
          />
          <path
            fill-rule="evenodd"
            d="M932.489,797.447c-2.914-2.856-5.827-5.714-8.852-8.679c3.531-4.564,1.158-7.656-1.724-10.321
                c-12.414-11.481-26.636-19.551-43.491-22.48c-1.138-0.198-2.284-0.349-3.412-0.591c-0.279-0.06-0.499-0.395-1.781-1.476
                c5.894,1.086,10.958,1.192,15.33,2.985c11.625,4.767,23.294,9.715,32.626,18.531c3.534,3.34,7.706,6.598,6.058,12.649
                c-0.21,0.773,0.907,2.037,1.616,2.905c1.538,1.885,3.211,3.659,4.828,5.479C933.288,796.783,932.889,797.115,932.489,797.447z"
          />
          <path
            fill-rule="evenodd"
            d="M640.178,540.331c-21.318-13.534-33.171-35.337-48.434-54.159c0.421-0.357,0.843-0.715,1.265-1.071
                c4.824,6.227,9.654,12.449,14.473,18.681c5.084,6.574,9.812,13.466,15.333,19.649
                C628.182,529.439,634.357,534.727,640.178,540.331z"
          />
          <path
            fill-rule="evenodd"
            d="M531.014,357.744c-3.027,2.89-8.326,2.386-22.204-2.141c3.524,0.444,7.049,0.89,10.573,1.334
                c0.065-0.46,0.13-0.921,0.194-1.381c-12.4-1.871-24.802-3.743-37.202-5.614C498.999,349.497,514.812,353.685,531.014,357.744z"
          />
          <path
            fill-rule="evenodd"
            d="M917.003,649.586c-2.573-2.596-7.769-3.874-4.741-9.55c0.529-0.991-2.933-4.203-4.674-6.286
                c-1.518-1.815-3.279-3.431-4.713-5.303c-0.14-0.183,1.449-2.341,1.922-2.231c2.44,0.568,5.217,1.038,7.062,2.541
                c4.207,3.425,6.699,7.474,3.723,13.438C914.692,643.978,916.455,647.085,917.003,649.586z"
          />
          <path
            fill-rule="evenodd"
            d="M795.276,664.859c21.521,2.84,43.041,5.679,64.562,8.518c-21.974,1.286-43.081-5.315-64.781-6.611
                C795.13,666.131,795.203,665.495,795.276,664.859z"
          />
          <path
            fill-rule="evenodd"
            d="M885.036,668.804c6.845-0.177,13.352,0.119,19.427,4.233c2.96,2.004,7.276,2.232,11.049,2.758
                c6.544,0.912,13.143,1.43,19.719,2.113c-0.073,0.682-0.146,1.364-0.219,2.046c-1.5-0.1-3.001-0.178-4.498-0.304
                c-5.919-0.498-11.848-0.918-17.75-1.564c-2.255-0.247-4.932-0.401-6.586-1.676c-5.631-4.338-11.957-6.23-18.863-6.801
                C886.538,669.546,885.795,669.082,885.036,668.804z"
          />
          <path
            fill-rule="evenodd"
            d="M940.922,675.537c-18.557-5.039-35.981-9.77-52.325-14.208
                C894.335,659.759,930.441,668.82,940.922,675.537z"
          />
          <path
            fill-rule="evenodd"
            d="M744.204,598.887c-7.112-2.219-14.21-4.482-21.34-6.642c-4.84-1.466-9.431-3.046-14.9-2.56
                c-6.314,0.562-12.222-2.088-16.313-8.088c4.777,2.162,8.986,4.558,13.507,5.935c2.856,0.87,6.379-0.469,9.253,0.374
                c10.154,2.976,20.158,6.465,30.219,9.759C744.487,598.072,744.346,598.479,744.204,598.887z"
          />
          <path
            fill-rule="evenodd"
            d="M523.106,491.325c-10.67-1.33-21.219-2.646-31.768-3.96c0.057-0.54,0.113-1.08,0.17-1.62
                c9.598,1.136,19.194,2.271,29.042,3.437c-0.69-5.924-7.062-11.314-2.043-16.665C519.861,478.055,521.363,484.194,523.106,491.325z
                "
          />
          <path
            fill-rule="evenodd"
            d="M714.502,495.904c6.265,0.663,12.553,1.159,18.788,2.029c9.317,1.301,18.594,2.887,27.896,4.293
                c1.235,0.187,2.522,0.025,3.785,0.025c0.032,0.41,0.065,0.821,0.098,1.232c-1.677,0.093-3.399,0.486-5.021,0.23
                c-9.473-1.495-18.896-3.321-28.387-4.671c-5.695-0.81-11.485-0.956-17.233-1.4C714.453,497.063,714.478,496.484,714.502,495.904z"
          />
          <path
            fill-rule="evenodd"
            d="M407.804,500.212c13.309,3.946,27.108,6.883,36.44,18.874c-3.007-1.802-6.278-3.281-8.959-5.479
                c-4.339-3.559-8.85-6.054-14.55-7.039c-4.7-0.812-9.132-3.18-13.683-4.858C407.303,501.21,407.554,500.711,407.804,500.212z"
          />
          <path
            fill-rule="evenodd"
            d="M813.452,546.3c6.148,0.779,12.31,1.483,18.435,2.418c0.977,0.148,2.397,1.201,2.588,2.062
                c1.133,5.116,5.325,5.106,9.168,6.117c5.059,1.331,9.993,3.138,14.981,4.739c-0.153,0.499-0.306,0.997-0.458,1.495
                c-1.032-0.287-2.075-0.539-3.094-0.867c-3.946-1.271-7.805-3.051-11.848-3.727c-4.662-0.78-8.812-1.464-10.541-6.767
                c-0.289-0.885-2.43-1.418-3.795-1.654c-5.186-0.898-10.405-1.608-15.611-2.389C813.336,547.253,813.394,546.776,813.452,546.3z"
          />
          <path
            fill-rule="evenodd"
            d="M801.1,752.668c-7.327,0.245-12.159-3.944-16.535-8.916c-0.212-0.241-0.518-0.669-0.435-0.859
                c3.079-7.09-4.2-8.378-6.37-13.087c2.941,1.794,5.965,3.473,8.771,5.459c0.739,0.523,1.209,2.028,1.12,3.022
                c-0.505,5.68,1.62,9.316,7.303,11.869C796.971,751.062,799.049,751.834,801.1,752.668z"
          />
          <path
            fill-rule="evenodd"
            d="M544.344,502.141c-3.782,5.439-7.188,11.205-11.5,16.187c-2.299,2.655-5.977,4.444-9.368,5.749
                c-4.725,1.818-9.765,2.815-14.67,4.164c-0.15-0.435-0.302-0.87-0.453-1.305c4.035-1.317,7.987-3.25,12.125-3.808
                c6.181-0.833,10.059-3.669,13.266-9.073c2.662-4.485,7.073-7.934,10.717-11.837C544.468,502.222,544.344,502.141,544.344,502.141z
                "
          />
          <path
            fill-rule="evenodd"
            d="M679.591,580.405c-8.664-12.996-17.327-25.993-25.99-38.99c0.275-0.197,0.55-0.395,0.825-0.592
                c4.615,6.284,9.442,12.426,13.784,18.894c4.378,6.521,8.274,13.364,12.381,20.066C680.258,579.99,679.924,580.198,679.591,580.405
                z"
          />
          <path
            fill-rule="evenodd"
            d="M730.111,374.143c7.613,1.972,15.228,3.943,22.841,5.915c0.145,0.612,0.289,1.225,0.434,1.837
                c-1.399,0.441-2.889,1.437-4.182,1.226c-4.871-0.795-9.68-1.981-14.384-3.898c3.896,0.54,7.791,1.081,11.687,1.622
                c0.06-0.283,0.118-0.566,0.178-0.85c-5.646-1.462-11.292-2.924-16.938-4.386C729.868,375.119,729.989,374.631,730.111,374.143z"
          />
          <path
            fill-rule="evenodd"
            d="M818.874,909.89c-11.159-1.95-22.317-3.9-32.991-5.766c2.215-2.021,6.63-2.349,15.975-0.074
                c5.691,1.386,11.264,3.266,16.89,4.924C818.789,909.278,818.831,909.584,818.874,909.89z"
          />
          <path
            fill-rule="evenodd"
            d="M990.702,843.188c16.185-1.387,17.826-0.634,32.03,13.436c-0.238,0.339-0.476,0.678-0.713,1.017
                c-2.842-2.332-6.242-4.245-8.387-7.101c-2.882-3.835-6.272-5.46-10.871-5.741C998.724,844.552,994.721,843.747,990.702,843.188z"
          />
          <path
            fill-rule="evenodd"
            d="M735.757,357.875c0.701-0.575,1.381-1.617,2.107-1.651c5.64-0.261,24.539,6.251,28.645,9.497
                c-2.193,0-4.978,0.525-7.427-0.155c-2.22-0.618-3.902-3.144-6.124-3.787c-5.688-1.646-11.544-2.715-17.333-4.014L735.757,357.875z
                "
          />
          <path
            fill-rule="evenodd"
            d="M738.923,457.426c11.167,1.347,22.333,2.694,33.499,4.041c-0.063,0.531-0.127,1.062-0.191,1.593
                c-11.163-1.369-22.326-2.739-33.489-4.109C738.802,458.442,738.862,457.934,738.923,457.426z"
          />
          <path
            fill-rule="evenodd"
            d="M876.936,698.506c-11.905-2.508-23.81-5.017-35.714-7.524
                C853.141,693.371,865.821,692.178,876.936,698.506z"
          />
          <path
            fill-rule="evenodd"
            d="M745.038,474.575c10.206,2.793,20.411,5.585,30.617,8.377c-0.095,0.396-0.188,0.79-0.283,1.185
                c-10.226-2.537-20.452-5.074-30.678-7.611C744.809,475.875,744.923,475.226,745.038,474.575z"
          />
          <path
            fill-rule="evenodd"
            d="M477.962,519.928c-4.312-8.938-2.548-15.076,4.33-18.831c3.276-1.788,6.54-3.601,9.809-5.401
                c0.211,0.355,0.421,0.71,0.632,1.065c-3.529,2.196-6.908,4.703-10.631,6.495c-3.339,1.606-4.637,3.697-4.201,7.339
                C478.216,513.222,477.962,515.916,477.962,519.928z"
          />
          <path
            fill-rule="evenodd"
            d="M879.773,790.183c-5.945-3.885-7.191-8.602-5.559-16.64c0.711-3.501,0.777-7.134,1.091-10.255
                c2.526,1.864,4.089,4.739,1.831,9.455C874.59,778.061,875.536,782.65,879.773,790.183z"
          />
          <path
            fill-rule="evenodd"
            d="M716.834,566.867c1.126,4.052,1.769,7.848,3.287,11.251c0.856,1.92,3.1,4.455,4.817,4.533
                c5.979,0.273,10.265,3.281,14.078,7.572c-3.05-1.704-6.068-4.793-9.155-4.863c-11.049-0.254-12.487-7.845-13.671-16.024
                c-0.07-0.487-0.021-0.995,0.014-1.49C716.214,567.703,716.371,567.571,716.834,566.867z"
          />
          <path
            fill-rule="evenodd"
            d="M842.424,625.55c-0.415,2.583-0.31,5.397-1.341,7.706c-3.342,7.479-3.322,8.107,3.289,13.021
                c0.331,0.246,0.459,0.765,0.74,1.259c-6.783-1.49-9.096-5.363-7.304-12.394c0.837-3.286,2.044-6.479,3.084-9.714
                C841.403,625.469,841.913,625.51,842.424,625.55z"
          />
          <path
            fill-rule="evenodd"
            d="M811.53,599.094c7.504,2.355,15.008,4.712,22.512,7.067c-0.032,0.284-0.064,0.567-0.097,0.852
                c-2.836-0.533-5.68-1.026-8.505-1.61c-3.551-0.735-7.136-1.379-10.607-2.397c-1.594-0.468-2.952-1.737-4.417-2.644
                C810.787,599.938,811.159,599.517,811.53,599.094z"
          />
          <path
            fill-rule="evenodd"
            d="M499.931,518.434c1.431-4.522,0.709-10.527,8.176-10.873c0.698-0.032,1.336-2.372,1.915-3.679
                c0.762-1.716,1.437-3.471,2.642-4.932c-1.422,4.399,0.841,11.122-7.38,11.421c-0.796,0.028-1.646,2.139-2.235,3.393
                c-0.806,1.718-1.386,3.541-2.062,5.318C500.634,518.865,500.282,518.649,499.931,518.434z"
          />
          <path
            fill-rule="evenodd"
            d="M735.373,371.098c8.113,2.33,16.226,4.66,24.339,6.991c-0.185,0.652-0.369,1.305-0.554,1.957
                c-1.656-0.585-3.28-1.294-4.975-1.731c-4.467-1.152-9.013-2.025-13.43-3.337c-2.074-0.616-3.926-1.983-5.878-3.011
                C735.041,371.677,735.207,371.388,735.373,371.098z"
          />
          <path
            fill-rule="evenodd"
            d="M599.081,356.256c8.278,1.166,16.558,2.332,24.836,3.497c-0.052,0.337-0.103,0.674-0.154,1.01
                c-8.258-0.811-16.516-1.621-24.774-2.432C599.02,357.64,599.051,356.948,599.081,356.256z"
          />
          <path
            fill-rule="evenodd"
            d="M887.084,712.114c3.396,1.451,6.316,2.698,9.236,3.946c-10.159-0.353-12.193-2.508-8.298-8.811
                c-2.068-1.604-4.182-3.243-6.911-5.359C889.485,703.729,891.271,706.799,887.084,712.114z"
          />
          <path
            fill-rule="evenodd"
            d="M883.432,838.43c8.165,0.936,16.33,1.871,24.495,2.808c-0.066,0.562-0.132,1.125-0.197,1.688
                c-8.136-1.011-16.271-2.021-24.405-3.032C883.36,839.405,883.396,838.917,883.432,838.43z"
          />
          <path
            fill-rule="evenodd"
            d="M813.419,592.354c0-2.852-0.881-5.686,0.163-7.125c3.367-4.645,4.517-9.026-0.375-14.876
                c5.828,3.724,7.092,6.925,5.317,10.808C816.978,584.547,815.435,587.935,813.419,592.354z"
          />
          <path
            fill-rule="evenodd"
            d="M598.104,425.999c3.637,7.998,5.321,17.081,13.658,22.229
                C602.096,443.928,599.354,435.379,598.104,425.999z"
          />
          <path
            fill-rule="evenodd"
            d="M727.657,384.393c6.224,1.524,12.015,2.942,17.805,4.361c-0.114,0.482-0.228,0.966-0.342,1.448
                C739.46,388.226,732.597,391.39,727.657,384.393z"
          />
          <path
            fill-rule="evenodd"
            d="M535.817,414.426c-7.45-0.992-14.9-1.984-22.351-2.977c0.06-0.496,0.119-0.992,0.178-1.488
                c7.463,0.894,14.926,1.788,22.389,2.683C535.961,413.237,535.889,413.831,535.817,414.426z"
          />
          <path
            fill-rule="evenodd"
            d="M435.97,486.597c-7.405-0.587-14.652-1.161-21.363-1.693
                C417.549,481.684,429.021,482.164,435.97,486.597z"
          />
          <path
            fill-rule="evenodd"
            d="M489.362,466.545c-6.465-0.677-12.931-1.353-19.396-2.028c0.053-0.567,0.105-1.136,0.157-1.703
                c6.513,0.55,13.025,1.1,19.538,1.648C489.562,465.156,489.462,465.851,489.362,466.545z"
          />
          <path
            fill-rule="evenodd"
            d="M506.618,421.018c6.994,2.7,13.987,5.4,20.112,7.766
                C522.293,430.213,515.026,427.956,506.618,421.018z"
          />
          <path
            fill-rule="evenodd"
            d="M410.089,447.216c-6.928-0.648-13.855-1.298-20.783-1.946c0.038-0.469,0.077-0.938,0.115-1.407
                c6.905,0.508,13.81,1.016,20.715,1.523C410.12,445.996,410.104,446.606,410.089,447.216z"
          />
          <path
            fill-rule="evenodd"
            d="M902.954,802.714c-6.35-2.943-12.699-5.887-19.049-8.83c0.204-0.474,0.408-0.948,0.613-1.422
                c6.353,2.874,12.704,5.749,19.057,8.624C903.368,801.629,903.161,802.171,902.954,802.714z"
          />
          <path
            fill-rule="evenodd"
            d="M472.065,519.345c-4.714-8.031-4.04-13.741,2.331-19.344
                C469.148,505.967,471.082,512.65,472.065,519.345z"
          />
          <path
            fill-rule="evenodd"
            d="M418.28,387.451c7.009,0.336,14.018,0.673,21.027,1.01c-0.036,0.621-0.072,1.241-0.108,1.862
                c-6.962-0.459-13.924-0.918-20.887-1.377C418.302,388.447,418.291,387.949,418.28,387.451z"
          />
          <path
            fill-rule="evenodd"
            d="M694.344,341.034c5.051,2.716,10.102,5.432,14.76,7.937c-4.761,0.801-8.794-0.284-12.049-4.414
                c-0.832-1.056-4.723-0.221-2.562-3.421L694.344,341.034z"
          />
          <path
            fill-rule="evenodd"
            d="M832.716,847.679c-5.705-0.437-11.409-0.872-17.114-1.309c0.031-0.476,0.062-0.952,0.094-1.429
                c5.7,0.303,11.4,0.604,17.102,0.906C832.77,846.458,832.743,847.068,832.716,847.679z"
          />
          <path
            fill-rule="evenodd"
            d="M672.71,432.184c-5.639-0.492-11.278-0.984-16.918-1.477c0.031-0.465,0.063-0.931,0.095-1.396
                c5.65,0.318,11.301,0.636,16.95,0.954C672.795,430.905,672.753,431.544,672.71,432.184z"
          />
          <path
            fill-rule="evenodd"
            d="M700.02,477.498c-5.517-1.661-11.033-3.321-16.55-4.982c0.134-0.461,0.268-0.923,0.401-1.385
                c5.548,1.554,11.096,3.107,16.644,4.661C700.35,476.36,700.185,476.929,700.02,477.498z"
          />
          <path
            fill-rule="evenodd"
            d="M694.493,341.136c-0.494-0.421-0.989-0.842-1.484-1.263c0.079-0.341,0.157-0.683,0.235-1.024
                c6.035,1.195,12.071,2.391,18.107,3.586c-0.068,0.438-0.137,0.877-0.205,1.315c-5.607-0.907-11.214-1.814-16.812-2.719
                C694.344,341.034,694.493,341.136,694.493,341.136z"
          />
          <path
            fill-rule="evenodd"
            d="M667.238,428.921c-5.762-0.668-11.523-1.337-17.285-2.006c0.038-0.416,0.076-0.832,0.114-1.248
                c5.739,0.507,11.479,1.013,17.218,1.519C667.27,427.765,667.254,428.343,667.238,428.921z"
          />
          <path
            fill-rule="evenodd"
            d="M535.965,406.723c-4.93-0.743-9.858-1.486-14.787-2.23c0.105-0.604,0.211-1.208,0.315-1.812
                c4.925,0.753,9.849,1.505,14.772,2.258C536.165,405.533,536.064,406.128,535.965,406.723z"
          />
          <path
            fill-rule="evenodd"
            d="M866.778,579.767c5.966-5.976,5.966-5.976,0.691-12.877c3.799,1.54,6.078,3.896,5.324,7.43
                c-0.506,2.37-3.115,4.292-4.784,6.415C867.6,580.411,867.189,580.089,866.778,579.767z"
          />
          <path
            fill-rule="evenodd"
            d="M537.214,490.323c4.956,0.945,9.912,1.891,14.868,2.836c-0.107,0.52-0.214,1.04-0.321,1.561
                c-4.928-1-9.855-2-14.783-2.999C537.057,491.255,537.135,490.789,537.214,490.323z"
          />
          <path
            fill-rule="evenodd"
            d="M741.685,454.092c-4.614-0.493-9.228-0.985-13.842-1.478c0.067-0.591,0.134-1.182,0.201-1.772
                c4.609,0.528,9.219,1.058,13.828,1.587C741.81,452.982,741.747,453.537,741.685,454.092z"
          />
          <path
            fill-rule="evenodd"
            d="M765.773,594.636c4.82,1.095,9.64,2.189,14.459,3.284c-0.083,0.479-0.166,0.957-0.249,1.436
                c-4.836-0.949-9.673-1.898-14.509-2.847C765.574,595.885,765.674,595.26,765.773,594.636z"
          />
          <path
            fill-rule="evenodd"
            d="M772.502,477.656c-5.279-1.452-10.558-2.904-15.837-4.356c0.149-0.54,0.299-1.08,0.448-1.619
                c5.21,1.551,10.42,3.102,15.629,4.653C772.662,476.774,772.582,477.216,772.502,477.656z"
          />
          <path
            fill-rule="evenodd"
            d="M806.404,482.048c-4.59-1.799-9.18-3.598-13.77-5.396c0.213-0.552,0.424-1.104,0.637-1.655
                c4.563,1.845,9.128,3.69,13.692,5.536C806.777,481.037,806.591,481.542,806.404,482.048z"
          />
          <path
            fill-rule="evenodd"
            d="M735.625,357.765c3.771,1.264,7.543,2.527,11.314,3.791c-0.062,0.275-0.125,0.551-0.188,0.826
                c-5.272-1.063-10.545-2.127-15.816-3.19c0.043-0.328,0.085-0.656,0.128-0.985c1.569-0.107,3.139-0.215,4.701-0.327
                C735.757,357.875,735.625,357.765,735.625,357.765z"
          />
          <path
            fill-rule="evenodd"
            d="M455.587,487.53c-4.585-0.603-9.17-1.204-13.755-1.806c0.104-0.624,0.209-1.247,0.313-1.871
                c4.549,0.807,9.098,1.613,13.647,2.42C455.724,486.692,455.655,487.111,455.587,487.53z"
          />
          <path
            fill-rule="evenodd"
            d="M686.859,381.519c5.614,1.282,11.229,2.563,16.842,3.846c-0.09,0.398-0.18,0.796-0.269,1.194
                c-5.618-1.264-11.236-2.526-16.854-3.789C686.672,382.353,686.766,381.936,686.859,381.519z"
          />
          <path
            fill-rule="evenodd"
            d="M952.061,786.864c-4.967-2.854-9.935-5.709-14.902-8.564c0.233-0.408,0.467-0.815,0.699-1.224
                c5.035,2.764,10.069,5.527,15.104,8.291C952.661,785.866,952.361,786.365,952.061,786.864z"
          />
          <path
            fill-rule="evenodd"
            d="M430.997,515.815c-6.911-0.315-13.823-0.631-20.735-0.946c0.026-0.418,0.052-0.836,0.078-1.255
                c6.894,0.348,13.787,0.694,20.681,1.042C431.013,515.043,431.005,515.43,430.997,515.815z"
          />
          <path
            fill-rule="evenodd"
            d="M536.604,505.914c-2.916,4.057-5.832,8.113-8.749,12.17c-0.43-0.31-0.859-0.62-1.289-0.93
                c3.015-4.044,6.03-8.088,9.045-12.132C535.942,505.319,536.273,505.616,536.604,505.914z"
          />
          <path
            fill-rule="evenodd"
            d="M720.293,379.1c-4.365-0.598-8.731-1.195-13.098-1.792c0.084-0.526,0.167-1.054,0.251-1.579
                c4.347,0.717,8.693,1.435,13.04,2.151C720.422,378.286,720.357,378.692,720.293,379.1z"
          />
          <path
            fill-rule="evenodd"
            d="M914.608,664.055c-4.28-1.146-8.561-2.291-12.84-3.437c0.13-0.461,0.26-0.921,0.39-1.382
                c4.261,1.214,8.521,2.429,12.782,3.644C914.829,663.271,914.719,663.663,914.608,664.055z"
          />
          <path
            fill-rule="evenodd"
            d="M535.733,482.228c4.551,0.604,9.032,1.198,13.514,1.792c-0.088,0.536-0.177,1.072-0.265,1.607
                c-4.436-0.845-8.871-1.688-13.3-2.563C535.651,483.058,535.708,482.61,535.733,482.228z"
          />
          <path
            fill-rule="evenodd"
            d="M855.089,556.488c-4.379-1.729-8.758-3.458-13.138-5.187c0.126-0.354,0.251-0.707,0.376-1.061
                c4.434,1.51,8.867,3.021,13.3,4.53C855.448,555.344,855.269,555.916,855.089,556.488z"
          />
          <path
            fill-rule="evenodd"
            d="M333.771,95.258c-1.105-0.079-2.289,0.071-3.304-0.273c-6.27-2.134-12.346-5.146-18.773-6.441
                c-18.042-3.636-31.23,6.356-33.478,25.204c-0.734,6.157-0.115,12.476-0.115,18.473c-4.776-1.215-9.668-4.054-13.645-3.096
                c-5.996,1.445-3.842,7.789-3.727,13.247c-1.996-0.463-3.634-0.801-5.249-1.224c-4.81-1.261-9.547-3.228-14.428-3.686
                c-8.35-0.784-11.375,2.728-12.25,13.204c-1.262-0.396-2.39-0.804-3.546-1.104c-3.671-0.951-7.319-2.465-11.036-2.666
                c-8.577-0.464-12.028,2.197-14.826,10.293c-2.583,7.475-4.12,15.016-0.555,22.904c-1.279,0.268-2.009,0.68-2.486,0.482
                c-10.467-4.34-20.171-9.593-24.276-21.216c-5.16-14.605,6.169-29.591,23.005-30.384c2.782-0.131,5.576-0.019,8.883-0.019
                c-1.094-8.954,4.12-13.087,10.974-16.18c1.308-0.59,2.652-2.025,3.137-3.373c5.112-14.211,17.889-17.273,28.845-6.774
                c0.819,0.785,1.382,1.838,2.473,3.324c6.119-23.733,22.759-32.803,44.159-35.62c15.863-2.089,32.279,6.105,39.717,19.679
                c0.748,1.366,0.603,3.222,0.872,4.85c-3.044-2.947-6.108-5.875-9.217-8.754c-8.378-7.76-18.202-11.472-29.671-10.505
                c-9.306,0.785-17.979,3.578-25.698,8.833c-8.298,5.648-13.149,13.498-13.993,23.656c-0.186,2.238,0.32,5.018-2.646,5.841
                c-3.059,0.851-4.388-1.63-5.86-3.638c-1.676-2.284-3.361-4.561-5.754-6.182c-6.889-4.668-15.225-2.32-17.909,5.49
                c-1.358,3.952-3.454,6.603-7.187,8.304c-4.497,2.049-6.724,5.547-6.784,10.669c-0.061,5.166-1.513,6.412-6.885,5.794
                c-4.729-0.544-9.31-0.593-13.755,1.194c-6.762,2.72-11.906,7.091-12.364,14.837c-0.469,7.935,3.174,14.183,9.64,18.638
                c3.399,2.342,4.898,1.483,5.439-2.77c0.636-5.005,2.07-9.779,4.208-14.311c3.204-6.791,8.591-10.403,16.2-10.448
                c2.495-0.015,4.995-0.104,7.483,0.207c1.932,0.242,3.828-0.087,4.961-1.709c6.108-8.746,14.892-8.389,23.823-6.862
                c4.05,0.691,6.654-0.076,8.508-3.932c1.891-3.931,5.322-5.68,9.752-5.731c5.93-0.07,5.646-0.197,6.131-6.275
                c1.11-13.921,6.333-25.521,19.799-31.706C305.092,79.655,326.337,84.787,333.771,95.258z"
          />
          <path
            fill-rule="evenodd"
            d="M486.513,158.394c-2.94-4.308-4.941-8.987-8.422-11.896c-4.325-3.614-9.601-6.603-14.958-8.351
                c-8.013-2.613-13.741,0.008-18.47,6.959c-1.483,2.182-2.836,4.561-3.687,7.042c-1.215,3.544-2.987,4.876-6.976,4.341
                c-6.185-0.83-9.314,1.594-11.916,6.85c-0.817,1.65-3.02,2.986-4.879,3.656c-2.286,0.823-4.878,0.799-7.61,1.179
                c0.193,6.012-3.703,5.615-7.359,4.447c-5.368-1.715-10.635-3.806-15.813-6.04c-10.401-4.489-20.748-9.171-32.304-9.614
                c-3.27-0.126-6.725,0.116-9.83,1.06c-8.387,2.546-12.298,10.725-9.546,19.049c0.428,1.294,0.782,2.612,0.4,4.163
                c-2.999-3.312-4.56-7.115-3.805-11.662c1.609-9.698,9.435-15.738,20.718-15.543c11.36,0.197,21.792,4.14,32.065,8.614
                c5.785,2.52,11.645,4.891,17.578,7.031c1.979,0.714,4.135,1.429,5.573-1.991c0.67-1.594,4.376-2.388,6.802-2.648
                c3.232-0.348,5.418-1.052,6.831-4.444c1.877-4.509,5.795-6.691,10.769-6.714c1.818-0.008,3.637-0.001,5.617-0.001
                c4.94-10.925,10.412-21.29,25.107-18.595C474.846,137.569,485.159,147.505,486.513,158.394z"
          />
          <path
            fill-rule="evenodd"
            d="M391.367,145.369c-7.218-7.638-3.823-24.645,5.748-31.826c11.271-8.458,29.859-4.339,37.719,8.688
                c-9.598-8.714-20.284-14.92-33.107-8.531C388.355,120.362,388.922,132.729,391.367,145.369z"
          />
          <path
            fill-rule="evenodd"
            d="M520.816,939.487c12.521,3.677,25.427,6.392,37.485,11.222c29.196,11.695,59.926,18.819,89.05,30.657
                c5.163,2.099,10.688,3.309,16.046,4.929c-0.152,0.471-0.305,0.94-0.457,1.411c-47.441-15.833-94.884-31.665-142.325-47.498
                C520.683,939.968,520.749,939.728,520.816,939.487z"
          />
          <path
            fill-rule="evenodd"
            d="M651.682,901.975c-36.267-9.366-72.534-18.732-108.801-28.099c0.152-0.594,0.305-1.188,0.458-1.781
                c36.21,9.469,72.421,18.937,108.632,28.404C651.874,900.991,651.777,901.482,651.682,901.975z"
          />
          <path
            fill-rule="evenodd"
            d="M557.701,916.047c-24.289-7.513-48.578-15.025-72.867-22.538c0.136-0.44,0.272-0.881,0.408-1.321
                c24.321,7.362,48.642,14.726,72.963,22.089C558.037,914.866,557.869,915.457,557.701,916.047z"
          />
          <path
            fill-rule="evenodd"
            d="M645.804,842.524c-23.251-7.054-46.501-14.106-69.752-21.16c0.144-0.463,0.288-0.926,0.433-1.389
                c23.228,7.129,46.455,14.259,69.682,21.388C646.046,841.75,645.925,842.137,645.804,842.524z"
          />
          <path
            fill-rule="evenodd"
            d="M642.266,820.358c-0.664,1.85-1.152,3.213-1.98,5.523c-12.156-4.381-24.306-8.759-36.455-13.138
                c0.16-0.463,0.321-0.925,0.481-1.388c11.538,4.077,23.076,8.155,34.94,12.348c0.431-2.105,0.715-3.498,1.118-5.471
                c7.612,2.531,15.037,5,22.462,7.469c-0.094,0.301-0.188,0.601-0.281,0.901C655.844,824.539,649.138,822.474,642.266,820.358z"
          />
          <path
            fill-rule="evenodd"
            d="M495.338,798.588c16.17,4.711,32.34,9.421,48.51,14.131c-0.145,0.524-0.288,1.049-0.433,1.574
                c-16.199-4.615-32.399-9.229-48.599-13.845C494.99,799.828,495.164,799.208,495.338,798.588z"
          />
          <path
            fill-rule="evenodd"
            d="M655.892,915.035c-16.347-4.793-32.694-9.586-49.042-14.379c0.128-0.434,0.256-0.866,0.383-1.3
                c16.396,4.628,32.792,9.255,49.188,13.883C656.244,913.838,656.068,914.437,655.892,915.035z"
          />
          <path
            fill-rule="evenodd"
            d="M542.777,920.244c-15.217-4.337-30.434-8.673-45.649-13.009c0.101-0.355,0.2-0.711,0.301-1.067
                c15.267,4.117,30.533,8.234,45.8,12.351C543.078,919.094,542.928,919.669,542.777,920.244z"
          />
          <path
            fill-rule="evenodd"
            d="M600.553,891.983c13.418,3.867,26.835,7.735,40.253,11.603c-0.161,0.541-0.322,1.083-0.483,1.625
                c-13.376-4.006-26.752-8.011-40.127-12.017C600.314,892.791,600.434,892.387,600.553,891.983z"
          />
          <path
            fill-rule="evenodd"
            d="M600.497,975.955c-14.091-5.111-28.183-10.224-42.273-15.335c0.188-0.512,0.376-1.022,0.563-1.534
                c14.006,5.247,28.013,10.495,42.019,15.742C600.703,975.204,600.601,975.579,600.497,975.955z"
          />
          <path
            fill-rule="evenodd"
            d="M538.705,806.14c9.518,2.587,19.035,5.174,28.552,7.76c-0.163,0.603-0.327,1.204-0.49,1.806
                c-9.425-2.746-18.849-5.492-28.273-8.239C538.563,807.024,538.635,806.582,538.705,806.14z"
          />
          <path
            fill-rule="evenodd"
            d="M577.479,807.059c6.961,1.684,13.922,3.368,20.883,5.052c-0.121,0.49-0.241,0.981-0.362,1.472
                c-6.928-1.819-13.856-3.64-20.784-5.459C577.303,807.769,577.391,807.413,577.479,807.059z"
          />
          <path
            fill-rule="evenodd"
            d="M556.792,802.596c-5.386-1.553-10.771-3.106-16.158-4.66c0.081-0.277,0.161-0.556,0.241-0.833
                c5.501,1.622,11.001,3.243,16.502,4.865C557.182,802.178,556.987,802.387,556.792,802.596z"
          />
          <path
            fill-rule="evenodd"
            d="M462.129,542.211c-0.348,4.641-0.396,9.338-1.25,13.884c-0.216,1.15-2.911,2.24-4.627,2.604
                c-2.23,0.471-4.664-0.065-6.923,0.328c-1.298,0.227-3.563,1.851-3.458,2.14c1.756,4.807-3.055,7.265-3.753,11.08
                c-1.07,5.844-2.17,11.697-2.844,17.594c-0.547,4.786-0.559,9.64-0.664,14.466c-0.055,2.497-0.965,3.387-3.591,3.513
                c-6.298,0.302-12.576,1.036-18.73,1.584c5.81-3.968,13.025-2.911,20.406-3.563c0.34-6.271,0.4-12.56,1.103-18.775
                c0.626-5.536,1.833-11.021,3.032-16.473c0.743-3.381,1.747-6.737,2.995-9.966c1.725-4.461,6.022-4.866,9.673-4.333
                c5.393,0.787,6.343-1.773,6.371-6.086c0.019-2.712,0.34-5.422,0.525-8.134C460.973,542.119,461.551,542.165,462.129,542.211z"
          />
          <path
            fill-rule="evenodd"
            d="M390.105,555.56c-1.574,2.407-1.243,4.289,1.414,5.701c1.759,0.936,3.307,2.347,5.14,3.05
                c9.086,3.484,11.61,11.52,14.365,20.249c-0.852-0.542-1.411-0.695-1.479-0.971c-2.459-9.87-9.54-15.437-18.174-19.53
                c-4.419-2.095-5.472-5.093-3.665-9.734c0.56-1.436-0.562-3.878-1.528-5.49c-3.2-5.332-6.681-10.495-10.054-15.723
                c0.357-0.231,0.715-0.463,1.072-0.693c1.277,1.981,2.412,4.079,3.887,5.902c0.856,1.059,2.168,2.184,3.429,2.405
                c6.722,1.184,9.291,5.92,6.418,12.197c-0.409,0.895-0.638,1.872-0.948,2.804C389.983,555.72,390.105,555.56,390.105,555.56z"
          />
          <path
            fill-rule="evenodd"
            d="M375.824,557.377c4.9,5.575,9.8,11.15,14.436,16.426c0.855-0.072,2.055-0.544,2.653-0.136
                c0.892,0.609,1.488,1.777,1.987,2.814c5.213,10.837,10.381,21.695,14.913,32.994c-1.424-2.243-3.042-4.389-4.234-6.748
                c-3.441-6.81-6.671-13.727-10.083-20.551c-0.745-1.492-0.791-4.263-3.905-2.82c-0.397,0.185-1.523-0.629-1.979-1.232
                c-4.963-6.579-9.858-13.208-14.773-19.824C375.168,557.992,375.496,557.685,375.824,557.377z"
          />
          <path
            fill-rule="evenodd"
            d="M391.685,625.549c23.117,2.592,46.234,5.185,69.351,7.777
                C437.639,633.156,414.509,630.667,391.685,625.549z"
          />
          <path
            fill-rule="evenodd"
            d="M354.859,531.527c0.141,1.062,0.444,2.134,0.396,3.188c-0.249,5.353-0.693,10.575,5.688,13.314
                c1.419,0.608,2.383,3.183,2.8,5.022c0.75,3.311,0.928,6.751,0.264,10.462c-0.725-2.545-1.265-5.163-2.227-7.615
                c-0.981-2.504-1.764-5.977-3.713-7.033c-4.706-2.552-5.679-5.967-4.835-10.674c0.397-2.217,0.542-4.479,0.801-6.72
                C354.309,531.49,354.584,531.509,354.859,531.527z"
          />
          <path
            fill-rule="evenodd"
            d="M364.198,663.991c6.954-1.27,13.896-2.615,20.875-3.736c1.169-0.188,2.901,0.203,3.676,1.003
                c3.267,3.375,6.269,7.006,8.651,11.266c-2.468-2.353-5.389-4.392-7.289-7.137c-2.339-3.378-5.013-3.621-8.563-2.933
                c-5.661,1.1-11.388,1.865-17.086,2.772C364.374,664.814,364.286,664.403,364.198,663.991z"
          />
          <path
            fill-rule="evenodd"
            d="M385.135,584.622c-3.558-2.706-7.598-4.731-8.954-9.754c-0.866-3.205-3.101-6.04-3.739-9.644
                c4.198,6.509,8.396,13.019,12.595,19.527L385.135,584.622z"
          />
          <path
            fill-rule="evenodd"
            d="M359.222,514.601c5.074,0.242,10.149,0.485,15.224,0.728c-0.045,0.675-0.09,1.349-0.136,2.023
                c-5.069-0.311-10.14-0.62-15.21-0.931C359.141,515.814,359.182,515.207,359.222,514.601z"
          />
          <path
            fill-rule="evenodd"
            d="M430.111,615.752c6.334,0.806,12.668,1.611,19.002,2.416c-0.037,0.322-0.073,0.644-0.109,0.966
                c-6.346-0.703-12.692-1.406-19.038-2.109C430.015,616.601,430.062,616.176,430.111,615.752z"
          />
          <path
            fill-rule="evenodd"
            d="M359.954,510.433c4.522-0.297,9.045-0.594,13.567-0.891c0.051,0.631,0.103,1.261,0.153,1.891
                c-4.498,0.228-8.996,0.456-13.495,0.684C360.104,511.556,360.029,510.994,359.954,510.433z"
          />
          <path
            fill-rule="evenodd"
            d="M385.036,584.752c4.494,4.204,8.987,8.408,13.481,12.611c-0.502,0.479-1.005,0.959-1.508,1.438
                c-4.082-4.607-9.816-7.669-11.878-14.17C385.135,584.622,385.036,584.752,385.036,584.752z"
          />
          <path
            fill-rule="evenodd"
            d="M369.979,629.827c-1.203,3.594-2.407,7.188-3.61,10.782c-0.669-0.22-1.338-0.439-2.006-0.659
                c1.217-3.591,2.434-7.181,3.651-10.771C368.669,629.396,369.324,629.611,369.979,629.827z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(85)}
            fill={props.fillColors[85]}
            d="M407.941,880.017c-0.3,0.276-0.6,0.553-0.899,0.829
                c-1.162,1.491-2.849,1.497-4.263,0.926c-7.224-2.919-14.509-5.686-20.548-10.938c-2.864-2.491-4.225-5.688-4.291-9.039
                c-0.193-9.674-4.505-17.854-8.804-26.021c-2.447-4.65-4.91-8.91-0.759-13.855c1.355-1.614,0.511-3.406-0.553-4.982
                c-3.655-5.419-6.161-10.912-2.222-17.396c1.289-2.121-0.194-4.182-1.501-5.863c-1.718-2.212-1.915-4.625-2.103-7.325
                c-0.833-11.962,2.059-23.565,3.161-35.332c0.354-3.781,2.459-7.288,5.122-10.116c3.744-3.978,7.74-0.978,11.53,0.077
                c3.208,0.894,3.732,3.057,3.232,6.457c-1.189,8.086,1.423,12.072,8.762,15.992c7.938,4.241,16.712,6.428,24.758,10.378
                c1.942,0.954,3.884,1.909,5.804,2.909c8.437,4.399,9.061,6.905,2.496,13.771c-5.21,5.448-9.398,11.634-14.026,17.505
                c-2.023,2.566-2.264,4.715-0.171,7.538c4.093,5.52,6.605,11.898,8.771,18.385c0.941,2.817,1.013,5.698-0.592,8.323
                c-1.652,2.703-2.94,5.553-4.249,8.43c-1.885,4.143-3.003,8.261-2.133,12.82c0.216,1.126-0.112,2.349,0.059,3.489
                C415.41,872.902,413.449,877.343,407.941,880.017z"
          />
          <path
            fill-rule="evenodd"
            d="M407.941,880.017c5.642-3.721,6.421-9.285,5.646-15.323c-1.033-8.049,1.924-14.884,5.666-21.745
                c1.348-2.47,1.903-6.287,1.02-8.874c-2.021-5.913-4.729-11.702-7.97-17.05c-2.5-4.126-2.383-7.189,0.634-10.74
                c5.271-6.204,10.186-12.714,15.537-18.844c2.785-3.19,2.642-5.69-0.941-7.524c-6.635-3.396-13.411-6.52-20.19-9.622
                c-4.979-2.278-10.206-4.057-15.058-6.569c-7.162-3.71-9.844-9.099-8.14-16.917c0.704-3.229-0.622-4.23-2.897-5.206
                c-7.235-3.102-11.532-1.692-13.83,5.945c-1.892,6.287-2.432,13.018-3.201,19.596c-0.693,5.925-1.255,11.915-1.146,17.864
                c0.058,3.132,1.785,6.208,2.543,9.361c0.473,1.967,1.423,4.499,0.64,5.967c-2.954,5.538-1.618,10.307,1.678,14.896
                c2.174,3.024,2.225,5.441,0.493,9.049c-1.206,2.513-0.121,6.598,0.938,9.633c2.884,8.266,6.928,16.171,9.258,24.567
                c1.332,4.8,0.965,10.183,5.79,13.07c7.043,4.215,13.683,9.641,22.632,9.296c-1.556,0.641-3.562,2.245-4.593,1.764
                c-6.736-3.149-13.652-6.197-19.686-10.447c-2.838-1.999-5.071-6.464-5.446-10.036c-0.969-9.212-4.508-17.315-8.683-25.279
                c-2.653-5.062-5.955-9.874-1.145-15.562c0.579-0.684-0.01-2.93-0.715-4.03c-3.566-5.567-6.175-11.102-2.2-17.634
                c0.499-0.82,0.462-2.539-0.077-3.33c-5.954-8.735-3.684-18.196-2.609-27.546c0.587-5.119,1.166-10.246,2.024-15.322
                c0.464-2.75,1.163-5.565,2.353-8.067c3.524-7.411,6.567-8.634,14.429-6.084c4.153,1.347,6.932,3.327,5.329,8.721
                c-1.569,5.284,0.74,10.522,5.72,13.066c6.49,3.316,13.398,5.804,20.019,8.88c5.859,2.723,11.834,5.347,17.268,8.788
                c4.8,3.04,4.651,5.336,0.994,9.755c-5.195,6.278-10.281,12.647-15.566,18.849c-2.553,2.994-2.419,5.511-0.495,8.953
                c3.22,5.761,6.009,11.824,8.298,18.012c0.866,2.343,0.811,6.021-0.487,7.996c-5.377,8.181-7.563,16.926-6.467,26.545
                C415.974,874.693,413.627,878.418,407.941,880.017z"
          />
          <path
            fill-rule="evenodd"
            d="M437.841,745.304c-4.574-0.924-8.75-1.79-12.935-2.608c-13.219-2.586-26.423-5.255-39.671-7.682
                c-5.464-1.001-7.072-2.884-4.976-8.054c1.145-2.824,3.134-5.524,5.346-7.653c4.788-4.609,9.923-8.863,14.996-13.17
                c3.938-3.343,8.78-2.874,11.761,1.278c8.507,11.852,16.896,23.787,25.312,35.704C437.833,743.344,437.735,743.75,437.841,745.304z
                "
          />
          <path
            fill-rule="evenodd"
            d="M364.888,715.247c3.795,2.37,5.801,4.858,3.056,9.634c-2.252,3.915-2.866,8.736-4.592,13
                c-1.661,4.106-4.103,7.902-5.697,12.029c-4.158,10.763-6.04,21.98-5.72,34.518c-2.647-13.406,1.019-31.232,6.979-41.777
                c3.029-5.359,3.863-11.919,6.502-17.556C367.145,721.401,368.016,718.494,364.888,715.247z"
          />
          <path
            fill-rule="evenodd"
            d="M391.99,706.992c-5.498,4.356-10.997,8.713-16.522,13.092c-5.389-4.438-10.331-8.506-15.272-12.575
                c0.363-0.435,0.726-0.869,1.089-1.304c4.652,3.204,9.306,6.408,14.396,9.914c4.876-3.317,10.119-6.885,15.363-10.452
                C391.358,706.108,391.675,706.551,391.99,706.992z"
          />
          <path
            fill-rule="evenodd"
            d="M405.119,759.018c15.429,1.087,24.719,13.12,37.861,16.752c-0.112,0.458-0.225,0.915-0.338,1.373
                c-1.591-0.281-3.182-0.562-4.772-0.843c-1.777-0.313-3.556-0.627-5.735-1.011c0.87-0.323,1.36-0.505,3.241-1.204
                C424.983,768.91,415.498,764.187,405.119,759.018z"
          />
          <path
            fill-rule="evenodd"
            d="M398.742,753.836c8.943,1.812,17.887,3.624,26.83,5.436c-0.151,0.775-0.302,1.551-0.452,2.326
                c-8.916-1.848-17.831-3.695-26.747-5.543C398.496,755.315,398.619,754.575,398.742,753.836z"
          />
          <path
            fill-rule="evenodd"
            d="M436.995,793.472c10.837,1.802,21.673,3.604,32.51,5.405c-0.06,0.354-0.12,0.71-0.18,1.064
                c-10.915-1.571-21.831-3.143-32.747-4.714C436.717,794.643,436.856,794.057,436.995,793.472z"
          />
          <path
            fill-rule="evenodd"
            d="M464.544,829.373c-10.882-2.2-21.765-4.401-32.646-6.602c0.087-0.414,0.173-0.828,0.259-1.243
                c10.913,2.038,21.827,4.076,32.74,6.114C464.778,828.219,464.661,828.796,464.544,829.373z"
          />
          <path
            fill-rule="evenodd"
            d="M467.165,811.8c-6.219-1.032-12.438-2.065-18.655-3.099c0.091-0.519,0.183-1.037,0.273-1.555
                c6.137,1.174,12.272,2.349,18.409,3.522C467.184,811.046,467.174,811.423,467.165,811.8z"
          />
          <path
            fill-rule="evenodd"
            d="M466.558,848.468c-6.402-1.116-12.806-2.233-19.208-3.35c0.073-0.431,0.146-0.861,0.218-1.292
                c6.326,1.242,12.651,2.483,18.978,3.726C466.549,847.857,466.553,848.163,466.558,848.468z"
          />
          <path
            fill-rule="evenodd"
            d="M354.518,797.495c0,7.113,0,14.228,0,21.341c-0.232,0.021-0.466,0.04-0.698,0.06
                c0-7.057,0-14.114,0-21.172C354.052,797.647,354.285,797.571,354.518,797.495z"
          />
          <path
            fill-rule="evenodd"
            d="M257.307,388.624c-1.629-1.994-2.8-3.524-4.073-4.965c-3.078-3.483-6.536-6.038-11.629-5.504
                c-1.246,0.13-2.664-0.872-3.925-1.508c-2.345-1.182-4.558-3.34-6.96-3.549c-5.499-0.479-7.805-3.696-9.168-8.34
                c-0.467-1.59-0.95-3.18-1.53-4.73c-4.824-12.897-17.194-17.279-29.75-10.549c-1.25,0.67-2.465,1.403-3.897,2.222
                c-0.905-9.605,6.026-16.609,15.862-16.659c14.774-0.075,26.675,9.938,28.781,24.541c0.397,2.755,1.086,4.302,4.032,4.423
                c0.947,0.039,2,0.452,2.786,1.005c5.879,4.138,11.952,8.056,17.442,12.665C258.146,380.083,259.411,383.852,257.307,388.624z"
          />
          <path
            fill-rule="evenodd"
            d="M255.683,791.141c8.072,0.934,16.422,0.506,23.334,6.327c2.516,2.118,6.736,2.39,10.261,3.09
                c5.44,1.08,10.952,1.804,16.434,2.679c-0.024,0.402-0.048,0.805-0.072,1.207c-0.818,0.087-1.659,0.338-2.452,0.233
                c-5.913-0.786-11.833-1.539-17.713-2.532c-2.085-0.353-4.495-0.836-6.013-2.13c-6.558-5.59-14.751-6.281-22.568-8.169
                C256.458,791.741,256.085,791.381,255.683,791.141z"
          />
          <path
            fill-rule="evenodd"
            d="M288.019,816.761c-13.044-2.342-26.087-4.685-39.131-7.026c0.094-0.553,0.188-1.104,0.282-1.657
                c13.052,2.294,26.104,4.589,39.155,6.883C288.223,815.561,288.121,816.16,288.019,816.761z"
          />
          <path
            fill-rule="evenodd"
            d="M181.84,874.926c-14.133-2.467-28.047-5.584-40.539-12.94
                C154.814,866.299,168.327,870.612,181.84,874.926z"
          />
          <path
            fill-rule="evenodd"
            d="M312.739,786.9c-3.981-0.943-7.963-1.887-12.249-2.902c0.576-1.449,0.968-2.436,1.545-3.889
                c-6.552-2.444-12.944-4.829-19.336-7.215c0.165-0.465,0.33-0.93,0.495-1.396c6.198,2.237,13.439,3.193,18.259,7.121
                c3.76,3.065,7.243,5.971,12.236,6.99C313.372,786.04,313.056,786.471,312.739,786.9z"
          />
          <path
            fill-rule="evenodd"
            d="M254.32,797.554c7.279,1.307,14.559,2.614,21.838,3.921c-0.127,0.66-0.255,1.321-0.383,1.982
                c-7.248-1.444-14.495-2.889-21.742-4.333C254.129,798.601,254.225,798.077,254.32,797.554z"
          />
          <path
            fill-rule="evenodd"
            d="M266.87,777.076c7.996,1.699,15.993,3.398,23.989,5.098c-0.104,0.505-0.208,1.01-0.312,1.516
                c-7.962-1.735-15.924-3.47-23.886-5.205C266.73,778.016,266.8,777.546,266.87,777.076z"
          />
          <path
            fill-rule="evenodd"
            d="M282.651,767.385c7.532,1.929,15.064,3.857,22.597,5.786c-0.153,0.603-0.307,1.205-0.46,1.808
                c-7.504-2.04-15.007-4.08-22.511-6.119C282.401,768.367,282.526,767.876,282.651,767.385z"
          />
          <path
            fill-rule="evenodd"
            d="M299.958,810.977c-6.605-1.507-13.209-3.014-19.814-4.521c0.13-0.563,0.26-1.128,0.39-1.692
                c6.603,1.52,13.205,3.038,19.807,4.557C300.212,809.873,300.085,810.425,299.958,810.977z"
          />
          <path
            fill-rule="evenodd"
            d="M232.701,834.475c-7.674-1.36-15.349-2.72-23.023-4.08c0.064-0.367,0.129-0.734,0.193-1.101
                c7.668,1.289,15.337,2.578,23.006,3.868C232.818,833.6,232.759,834.037,232.701,834.475z"
          />
          <path
            fill-rule="evenodd"
            d="M245.373,833.219c4.618,0.296,9.236,0.592,13.854,0.889c-0.072,0.762-0.145,1.523-0.216,2.286
                c-4.557-0.524-9.113-1.049-13.669-1.572C245.351,834.287,245.362,833.753,245.373,833.219z"
          />
          <path
            fill-rule="evenodd"
            d="M71.755,821.786c3.063-5.684,7.767-1.142,11.643-1.847c1.535-3.901-0.139-10.102,5.779-10.861
                c4.232-0.543,8.739,1.051,13.185,1.7c1.433-2.594,1.468-4.741-1.109-7.195c-3.841-3.658,1.49-5.071,2.7-7.982
                c-1.551-1.282-3.144-2.6-5.914-4.889c16.175-2.979,29.724,3.475,43.833,5.612c-0.063,0.452-0.126,0.904-0.189,1.357
                c-12.52-2.378-25.04-4.756-37.56-7.133c-0.345,0.363-0.69,0.728-1.035,1.091c3.711,3.714,3.8,6.77,0.003,8.347
                c0.711,3.771,1.072,7.128,2.081,10.276c0.371,1.157,2.157,2.326,3.495,2.634c12.137,2.788,24.323,5.363,36.481,8.064
                c2.03,0.451,3.993,1.207,5.987,1.822c-0.105,0.416-0.211,0.832-0.317,1.248c-5.96-1.345-11.928-2.658-17.879-4.041
                c-12.612-2.933-25.236-5.82-37.816-8.886c-7.433-1.812-10.743,1.302-9.338,9.078c0.084,0.467-0.042,0.972-0.105,2.054
                c-4.485,0-8.945,0-13.404,0c-0.269,0.136-0.538,0.271-0.806,0.406C71.565,822.357,71.66,822.071,71.755,821.786z"
          />
          <path
            fill-rule="evenodd"
            d="M72.276,822.236c1.018,0.847,1.917,2.177,3.073,2.46c6.249,1.528,12.675,2.409,18.827,4.232
                c14.456,4.285,28.792,8.976,43.167,13.534c0.198,0.062,0.266,0.538,0.549,1.161c-10.923-3.488-21.487-7.321-32.304-10.199
                c-12.933-3.44-26.083-6.063-39.848-9.203c0.012-0.017-0.681,0.863-1.323,1.779c-4.324,6.163-3.806,8.266,3.154,11.853
                c2.504,1.29,5.22,2.164,7.752,3.404c2.495,1.222,4.88,2.668,7.313,4.016c-0.262,0.483-0.524,0.968-0.787,1.451
                c-6.805-3.857-13.733-7.519-20.358-11.665c-2.932-1.836-2.671-4.1-0.574-7.458c2.782-4.455,6.147-5.887,10.839-5.815
                C71.929,821.937,72.103,822.086,72.276,822.236z"
          />
          <path
            fill-rule="evenodd"
            d="M28.065,831.693c-4.855-5.105,1.425-6.401,3.511-9.011c-1.112,1.852-2.224,3.704-3.478,5.791
                c0.874,0.962,1.814,1.996,2.937,3.231c-1.441,0.994-2.637,1.819-4.708,3.248c5.35,1.206,9.983,2.251,14.616,3.296
                c-6.419,0.627-12.522,0.044-18.654-4.212C24.669,833.072,26.249,832.431,28.065,831.693z"
          />
          <path
            fill-rule="evenodd"
            d="M109.265,796.824c9.486,1.625,18.973,3.251,28.46,4.876c-0.09,0.514-0.181,1.027-0.271,1.541
                c-9.503-1.672-19.007-3.345-28.51-5.017C109.051,797.758,109.158,797.291,109.265,796.824z"
          />
          <path
            fill-rule="evenodd"
            d="M83.227,805.907c-4.799-0.806-9.598-1.611-14.396-2.417c0.033-0.255,0.065-0.51,0.098-0.765
                c4.83,0.546,9.659,1.091,14.488,1.636C83.353,804.877,83.29,805.392,83.227,805.907z"
          />
          <path
            fill-rule="evenodd"
            d="M158.146,728.564c-5.633,7.583-10.974,15.415-17.09,22.585c-1.69,1.981-5.632,2.329-8.64,2.844
                c-9.832,1.684-19.708,3.119-29.572,4.61c-16.136,2.439-29.575,10.669-42.606,19.784c-3.652,2.555-7.302,5.111-11.801,6.866
                c0.712-0.799,1.325-1.723,2.15-2.379c20.509-16.307,43.164-27.413,69.812-29.049c5.128-0.315,10.276-1.095,15.284-2.234
                c2.194-0.499,4.567-2.035,5.983-3.803c5.276-6.58,10.188-13.451,15.237-20.213C157.319,727.905,157.733,728.235,158.146,728.564z"
          />
          <path
            fill-rule="evenodd"
            d="M62.462,595.625c2.13-10.096,2.093-20.498,11.509-27.062c1.371,5.541,4.911,10.149,3.341,17.021
                C75.195,594.849,67.373,592.488,62.462,595.625z"
          />
          <path
            fill-rule="evenodd"
            d="M117.748,554.216c-4.323,7.504-8.646,15.008-13.233,22.969c-3.485-0.544-8.439-1.317-13.392-2.09
                c-0.155-0.891-0.31-1.782-0.464-2.673c2.447-1.246,4.894-2.493,7.756-3.952c0.034-0.271,0.165-1.312,0.447-3.562
                c3.22,1.792,5.754,3.202,8.038,4.474c2.269-4.025,4.92-8.973,7.861-13.74c0.594-0.963,2.287-1.247,3.473-1.845
                C118.071,553.937,117.91,554.076,117.748,554.216z"
          />
          <path
            fill-rule="evenodd"
            d="M118.232,553.797c0.426,0.702,0.914,1.375,1.269,2.112c3.68,7.638,9.586,10.621,18.39,9.348
                c1.065-0.154,2.185,0.069,3.785,0.14c-1.295-2.239-2.359-4.077-3.435-5.938c3.946,0.704,3.946,0.704,5.336,8.735
                c-4.609-0.747-8.977-1.874-13.388-2.079c-7.83-0.365-10.913-5.202-12.441-11.899C117.91,554.076,118.071,553.937,118.232,553.797z
                "
          />
          <path
            fill-rule="evenodd"
            d="M320.792,570.141c7.544,2.856,15.088,5.713,22.631,8.569c-0.197,0.474-0.395,0.946-0.592,1.42
                c-7.314-3.363-14.628-6.726-21.945-10.085C320.882,570.047,320.792,570.141,320.792,570.141z"
          />
          <path
            fill-rule="evenodd"
            d="M515.854,753.509c-7.869-1.648-15.748-3.252-23.6-4.981c-1.594-0.351-3.243-0.932-4.592-1.822
                c-4.722-3.121-9.322-6.425-13.61-9.409c7.479-0.824,16.05-3.573,24.071,2.17c0.132,0.094,0.297,0.197,0.448,0.2
                c16.477,0.328,29.231,12.74,45.517,13.988c3.977,0.305,7.793,2.696,11.546,4.544c-6.494-1.686-13.093-3.05-19.455-5.137
                c-7.963-2.611-15.886-4.927-23.549-8.996c-7.964-4.229-17.993-4.6-27.15-6.51c-1.21-0.253-2.602,0.367-3.965,0.593
                c8.061,8.726,9.339,8.877,20.521,10.038c4.867,0.506,9.505,3.22,14.247,4.93C516.14,753.247,515.997,753.378,515.854,753.509z"
          />
          <path
            fill-rule="evenodd"
            d="M186.291,800.387c0.249-4.898-1.369-10.322,4.746-13.539c0.76-0.4,1.537-1.492,1.623-2.333
                c0.821-8.001,5.608-13.852,10.05-20.072c1.743-2.44,1.543-6.269,2.231-9.464c1.254-5.824,2.338-7.112,6.18-8.343
                c-1.553,4.941-2.878,10.692-5.188,16.017c-1.796,4.141-4.921,7.688-7.254,11.619c-1.155,1.945-2.163,4.086-2.692,6.271
                c-0.931,3.835-0.91,7.912-5.839,9.497c-1.092,0.351-1.67,3.026-2.031,4.729c-0.404,1.901-0.292,3.912-0.403,5.875
                C187.239,800.558,186.765,800.473,186.291,800.387z"
          />
          <path
            fill-rule="evenodd"
            d="M200.217,728.046c9.229,1.559,14.254,7.276,13.545,15.881c-0.862-2.705-1.369-4.297-1.992-6.253
                c-0.522,0.643-0.895,0.923-1.023,1.288c-0.78,2.232-1.464,6.374-2.253,6.391c-2.95,0.062-5.979-1.079-8.877-2.064
                c-1.566-0.533-2.93-1.663-3.948-3.388c3.734,1.496,7.468,2.992,11.227,4.498C210.228,734.244,211.778,735.658,200.217,728.046z"
          />
          <path
            fill-rule="evenodd"
            d="M492.745,319.607c25.153-3.359,52.785,0.277,80.812,3.355
                C545.481,321.797,517.775,320.646,492.745,319.607z"
          />
          <path
            fill-rule="evenodd"
            d="M462.065,894.981c-3.997,5.378-9.821,7.78-16.045,7.721c-7.016-0.068-12.258,3.193-17.86,6.448
                c-3.848,2.235-8.144,3.701-12.239,5.511c-0.238-0.572-0.477-1.145-0.715-1.717c2.727-0.978,5.598-1.676,8.149-2.993
                c4.706-2.43,9.215-5.237,13.826-7.851c1.006-0.569,2.145-1.485,3.144-1.394c7.98,0.737,14.858-2.564,21.816-5.646
                C462.143,895.061,462.065,894.981,462.065,894.981z"
          />
          <path
            fill-rule="evenodd"
            d="M410.371,895.39c4.896,0.992,9.791,1.984,14.687,2.977c-0.106,0.468-0.213,0.935-0.319,1.402
                c-4.936-1.072-9.87-2.145-14.806-3.217C410.079,896.164,410.225,895.777,410.371,895.39z"
          />
          <path
            fill-rule="evenodd"
            d="M656.662,343.68c-4.234,0-8.469,0-12.704,0c-0.013-0.308-0.025-0.615-0.038-0.923
                c4.197,0,8.395,0,12.592,0C656.562,343.064,656.611,343.372,656.662,343.68z"
          />
          <path
            fill-rule="evenodd"
            d="M644.201,338.506c3.447,0,6.895,0,10.343,0c0.027,0.277,0.055,0.555,0.082,0.831
                c-3.487,0-6.976,0-10.463,0C644.176,339.061,644.188,338.783,644.201,338.506z"
          />
          <path
            fill-rule="evenodd"
            d="M548.482,335.266c-3.542,0-7.085,0-10.627,0c0.021-0.282,0.04-0.564,0.061-0.847
                c3.508,0,7.016,0,10.524,0C548.454,334.701,548.469,334.983,548.482,335.266z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(86)}
            fill={props.fillColors[86]}
            d="M792.356,1140.406c-4.151,2.735-7.936,0.432-11.656-1.051
                c-1.95-0.777-3.604-2.297-6.053-3.919c1.406-2.55,2.359-5.518,4.294-7.551c5.257-5.524,6.264-11.927,4.892-18.981
                c-1.845-9.479,1.509-16.746,8.672-22.938c10.37-8.965,22.391-14.12,35.656-16.839c3.901-0.801,7.942-1.465,11.538-3.055
                c3.52-1.555,6.581-4.169,9.787-6.398c1.609-1.119,2.913-2.924,4.666-3.551c8.694-3.109,13.976-8.72,14.447-18.259
                c0.039-0.792,0.347-1.57,0.6-2.657c4.375,8.205,1.823,17.963-6.205,20.671c-9.728,3.282-17.034,9.317-23.901,16.486
                c-1.828,1.908-4.733,2.97-7.329,3.913c-3.582,1.302-7.282,2.648-11.032,3.055c-14.084,1.524-21.669,11.117-28.319,22.038
                c-2.231,3.665-3.179,7.376-0.138,11.067c4.257,5.168,1.547,10.854,1.24,16.332c-0.221,3.941-0.835,7.86-1.266,11.778
                C792.258,1140.536,792.356,1140.406,792.356,1140.406z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(87)}
            fill={props.fillColors[87]}
            d="M756.452,1105.813c7.783,4.942,15.565,9.885,23.39,14.854
                c-2.581,4.438-5.066,8.896-7.793,13.201c-0.425,0.669-1.991,0.974-2.973,0.876c-10.021-0.999-10.921-2.181-11.191-12.344
                c-0.072-2.712-1.182-5.39-1.746-8.097c-0.518-2.482-0.941-4.982-1.407-7.476C755.305,1106.491,755.879,1106.152,756.452,1105.813z
                "
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(88)}
            fill={props.fillColors[88]}
            d="M780.182,1117.593c-6.389-4.233-12.776-8.467-19.228-12.741
                c17.435-16.105,18.481-16.825,26.741-18.004c-8.586,8.82-7.397,19.35-6.041,29.913
                C781.163,1117.038,780.673,1117.315,780.182,1117.593z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(89)}
            fill={props.fillColors[89]}
            d="M768.936,889.798c-1.935-0.542-3.528-0.985-5.121-1.435
                c-7.154-2.02-7.154-2.021-7.247,6.622c-3.998-1.628-7.802-2.604-10.968-4.592c-5.345-3.356-10.73-6.041-17.219-5.442
                c-1.254,0.115-3.289,0.33-3.573,1.049c-2.186,5.532-5.026,2.265-7.72,0.767c-7.592-4.223-7.583-4.236-6.457-12.652
                c0.064-0.479,0.222-0.979,0.148-1.44c-0.942-5.9-0.735-6.218,5.249-5.495c3.451,0.417,6.925,0.979,10.266,1.916
                c9.084,2.549,11.63,1.591,15.994-6.366c5.113,4.529,10.255,9.109,15.43,13.652c1.115,0.979,2.229,2.318,3.551,2.639
                C766.806,880.361,768.732,884.164,768.936,889.798z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(90)}
            fill={props.fillColors[90]}
            d="M914.216,636.54c-2.416-2.151-5.373-4.785-8.199-7.302
                C909.247,627.587,913.639,631.645,914.216,636.54z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(91)}
            fill={props.fillColors[91]}
            d="M333.771,95.258c-8.537-4.967-17.324-9.168-27.486-9.463
                c-14.033-0.406-23.554,5.824-28.356,19.021c-1.354,3.722-2.313,7.67-2.81,11.597c-0.472,3.727-0.102,7.56-0.102,11.476
                c-14.027-3.525-15.191-2.824-18.02,10.354c-23.181-5.555-27.852-4.397-31.117,6.634c-6.523,0-12.852-0.842-18.783,0.332
                c-3.288,0.65-6.988,4.116-8.614,7.282c-3.735,7.273-5.91,15.213-4.285,23.676c-11.556-2.646-22.51-15.131-20.659-25.688
                c3.262-18.609,17.63-20.049,33.255-18.627c0-1.356,0.099-2.301-0.016-3.218c-0.811-6.479,0.367-9.108,6.351-11.981
                c4.21-2.021,7.06-4.233,8.235-9.423c1.852-8.171,13.203-11.34,20.499-5.911c3.871,2.88,6.819,7,10.445,10.837
                c0.274-0.904,0.793-1.763,0.747-2.589c-0.54-9.56,3.638-17.088,10.225-23.469c15.707-15.218,42.827-17.861,59.223-5.192
                c4.681,3.616,7.803,9.25,11.64,13.958C334.018,94.995,333.895,95.126,333.771,95.258z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(92)}
            fill={props.fillColors[92]}
            d="M419.444,738.728c-12.357-2.315-24.715-4.631-38.634-7.239
                c1.955-3.59,2.976-7.001,5.19-9.219c5.042-5.048,10.601-9.593,16.087-14.177c3.219-2.69,6.421-2.061,8.889,1.493
                c7.255,10.446,14.472,20.917,22.452,32.463c-11.191-6.079-21.377-11.611-31.562-17.144c-0.26,0.43-0.52,0.859-0.779,1.289
                c6.474,3.613,12.948,7.226,19.422,10.838C420.154,737.597,419.8,738.162,419.444,738.728z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(93)}
            fill={props.fillColors[93]}
            d="M187.768,347.95c2.64-7.374,7.602-10.706,14.998-10.47
                c14.597,0.466,24.757,9.879,25.995,24.498c0.254,2.997,1.262,3.606,4.014,4.254c3.241,0.762,6.826,2.262,9.024,4.604
                c1.987,2.117,2.307,5.8,3.362,8.792c1.002-0.76,2.005-1.519,3.008-2.278c-4.438-1.803-8.878-3.602-13.313-5.413
                c-0.615-0.251-1.224-0.836-1.805-0.799c-7.684,0.497-8.804-5.144-10.613-10.688C217.389,344.98,205.479,340.647,187.768,347.95z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(94)}
            fill={props.fillColors[94]}
            d="M73.704,571.673c0.834,3.259,2.173,6.379,2.275,9.538
                c0.084,2.613-1.291,5.274-2.21,8.604c-1.854,0.764-4.433,1.825-7.614,3.136C65.738,584.635,66.322,577.226,73.704,571.673z"
          />
          <path
            fill-rule="evenodd"
            onClick={() => props.onFill(95)}
            fill={props.fillColors[95]}
            d="M93.233,572.348c2.602-0.985,5.203-1.971,7.805-2.955
                c-0.078-0.479-0.156-0.958-0.234-1.438c1.31,0.487,2.62,0.975,4.505,1.676c-1.213,2.163-2.026,5.325-3.288,5.516
                c-2.824,0.427-5.872-0.626-8.828-1.077C93.207,573.495,93.22,572.922,93.233,572.348z"
          />
          <path
            fill-rule="evenodd"
            d="M419.674,732.354c-5.914-3.199-11.829-6.397-17.744-9.596c0.187-0.362,0.372-0.724,0.559-1.085
                c6.001,3.036,12.003,6.072,18.004,9.108C420.22,731.305,419.947,731.829,419.674,732.354z"
          />
        </g>
      </svg>
    </div>
  );
}
